import cloneDeep from 'lodash-es/cloneDeep';
import { LogMessage } from '../services/logger.common';
const noCollapseProperty = '__noCollapse';
export class TrackChanges {
  changes;
  obj;
  previousObj;
  ignoreUndefined;
  collapse;
  shallowChanges;
  changesService;
  logger;
  constructor(trackChanges) {
    if (trackChanges != null) {
      if (!trackChanges.changesService) {
        throw new Error('Changes service not provided!');
      }
      if (!trackChanges.logger) {
        throw new Error('Logger service not provided!');
      }
      this.changesService = trackChanges.changesService;
      this.logger = trackChanges.logger;
      this.obj = trackChanges.obj;
      this.ignoreUndefined = trackChanges?.ignoreUndefined ?? false;
      this.collapse = trackChanges?.collapse ?? false;
      this.shallowChanges = trackChanges?.shallowChanges ?? false;
    }
    this.previousObj = cloneDeep(this.obj);
    this.changes = [];
  }
  observe() {
    if (this.obj != null) {
      const compareChanges = this.shallowChanges ? this.changesService.getShallowChanges(this.previousObj ?? {}, this.obj, this.ignoreUndefined) : this.changesService.getDeepChanges(this.previousObj, this.obj, this.ignoreUndefined);
      const currentChanges = Object.values(compareChanges);
      this.changes = this.changes.concat(currentChanges);
      this.previousObj = cloneDeep(this.obj);
      // collapse
      if (this.collapse) {
        const changes = this.changes.slice();
        this.changes = [];
        // find latest
        for (const change of changes) {
          if (change[noCollapseProperty] || changes.slice().reverse().find(c => c.name == change.name) === change) {
            this.changes.push(change);
          }
        }
        this.changeOldValue(changes);
        // remove changes with the same old and new value
        this.changes = this.changes.filter(c => c[noCollapseProperty] || c.oldValue !== c.newValue);
      }
    }
  }
  clear() {
    this.changes = [];
    this.previousObj = cloneDeep(this.obj);
  }
  set(obj) {
    this.obj = obj;
  }
  setOriginalProperty(property, value) {
    if (this.previousObj == null) {
      this.previousObj = {};
    }
    this.previousObj[property] = value;
  }
  print(name, ...args) {
    this.logger.logGroup(new LogMessage({
      message: name,
      args
    }), this.changes.map(change => new LogMessage({
      message: `${this.getChangeName(change)}%o => %o`,
      args: [change.oldValue, change.newValue]
    })));
  }
  addChange(change, noCollapse) {
    this.changes.push(change);
    change[noCollapseProperty] = noCollapse;
  }
  getChangeName(change) {
    if (change.name != null && change.name != '') {
      return `${change.name}: `;
    }
    return '';
  }
  changeOldValue(changes) {
    for (let i = 0; i < this.changes.length; i++) {
      const change = this.changes[i];
      const previousChange = this.changes.find((c, index) => index < i && c.name == change.name);
      if (previousChange != null) {
        change.oldValue = previousChange.newValue;
      } else {
        change.oldValue = changes.find(c => c.name == change.name)?.oldValue;
      }
    }
  }
}
