import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewEncapsulation } from '@angular/core';
import { ChangedConvertedProperties } from '../../services/design.service';
import { InfoDialogBaseComponent } from '../info-dialogs/info-dialog-base.component';
import { L10nDirective } from '../../directives/l10n.directive';
import { DesignVerificationChangesLoopComponent } from './design-verification-loop-helper/design-verification-loop-helper.component';

export interface DesignVerificationChangesInput {
    changedProperties: ChangedConvertedProperties[];
}

@Component({
    templateUrl: './design-verification-changes.component.html',
    styleUrls: ['./design-verification-changes.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [
        L10nDirective,
        DesignVerificationChangesLoopComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DesignVerificationChangesComponent extends InfoDialogBaseComponent<DesignVerificationChangesInput> implements OnInit {
    // when new property is added to 'Properties' interface, add it here
    // for not zone, punch openings, load combinatoins related it is section name && property name
    private designPropertiesTranslationKeys = new Map<string, string[]>([
        ['unitLength', ['SP.Compare.Units', 'SP.AddEditDesign.Units.Length']],
        ['unitArea', ['SP.Compare.Units', 'SP.AddEditDesign.Units.Area']],
        ['unitStress', ['SP.Compare.Units', 'SP.AddEditDesign.Units.Stress']],
        ['unitForce', ['SP.Compare.Units', 'SP.AddEditDesign.Units.Force']],
        ['unitMoment', ['SP.Compare.Units', 'SP.AddEditDesign.Units.Moment']],
        ['unitTemperature', ['SP.Compare.Units', 'SP.AddEditDesign.Units.Temperature']],
        ['unitForcePerLength', ['SP.Compare.Units', 'SP.AddEditDesign.Units.ForcePerLength']],
        ['unitDensity', ['SP.Compare.Units', 'SP.AddEditDesign.Units.Density']],
        ['unitAreaPerLength', ['SP.Compare.Units', 'SP.AddEditDesign.Units.AreaPerLength']],
        ['concreteMemberId', ['SP.Navigation.TabApplication', 'SP.Navigation.TabApplication.RegionApplication.ConcreteMember']],
        ['baseMaterialId', ['SP.Navigation.TabBaseMaterial', 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.BaseMaterial']],
        ['fcCylinder', ['SP.Navigation.TabBaseMaterial', 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.FcCyl']],
        ['fcCube', ['SP.Navigation.TabBaseMaterial', 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.FcCube']],
        ['aggregateSizeId', ['SP.Navigation.TabBaseMaterial', 'SP.Navigation.TabLoads.RegionDesign.AggregateSizeId']],
        ['plasticStrain', ['SP.Navigation.TabBaseMaterial', 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.UsePlasticStrain']],
        ['epsilonV', ['SP.Navigation.TabBaseMaterial', 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.EpsilonV']],
        ['shortTermTemperature', ['SP.Navigation.TabBaseMaterial.RegionTemperature', 'SP.Navigation.TabBaseMaterial.RegionTemperature.ShortTerm']],
        ['longTermTemperature', ['SP.Navigation.TabBaseMaterial.RegionTemperature', 'SP.Navigation.TabBaseMaterial.RegionTemperature.LongTerm']],
        ['slabWidth', ['SP.Navigation.TabBaseMaterial.RegionGeometry', 'SP.Compare.SlabWidth']],
        ['slabLength', ['SP.Navigation.TabBaseMaterial.RegionGeometry', 'SP.Compare.SlabLength']],
        ['slabHeight', ['SP.Navigation.TabBaseMaterial.RegionGeometry', 'SP.Compare.SlabHeight']],
        ['zonesNumberId', ['SP.Navigation.TabBaseMaterial.RegionZones', 'SP.Navigation.TabBaseMaterial.RegionZones.NumberOfZones']],
        ['defineOpening', ['SP.Navigation.TabBaseMaterial.RegionOpenings', 'SP.Navigation.TabBaseMaterial.RegionOpenings.DefineOpening']],
        ['openingLength', ['SP.Navigation.TabBaseMaterial.RegionOpenings', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength']],
        ['openingWidth', ['SP.Navigation.TabBaseMaterial.RegionOpenings', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth']],
        ['openingOriginX', ['SP.Navigation.TabBaseMaterial.RegionOpenings', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX']],
        ['openingOriginY', ['SP.Navigation.TabBaseMaterial.RegionOpenings', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY']],
        ['drillingTypeId', ['SP.Navigation.TabInstallationConditions.RegionInstallationConditions', 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.DrillingType']],
        ['holeTypeId', ['SP.Navigation.TabInstallationConditions.RegionInstallationConditions', 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.HoleType']],
        ['drillingAidId', ['SP.Navigation.TabInstallationConditions.RegionInstallationConditions', 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.DrillingAid']],
        ['depthOfRecess', ['SP.Navigation.TabInstallationConditions.RegionInstallationConditions', 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.DepthOfRecess']],
        ['longitudinalReinforcementYieldStrength', ['SP.Navigation.TabExistingReinforcement.RegionMaterials', 'SP.Navigation.TabExistingReinforcement.RegionMaterials.ReiforcementYieldStrength']],
        ['loadCombinations', ['SP.Report.Loads.LoadCombinations']],
        ['zoneLoads', ['SP.Report.Loads.Layout.Zone']],
        ['load', ['SP.Loads.Convert.Force']],
        ['ved', ['SP.Loads.Convert.Force.Ved']],
        ['medX', ['SP.Loads.Convert.Moments.Medx']],
        ['medY', ['SP.Loads.Convert.Moments.Medy']],
        ['zones', ['SP.Report.Loads.Layout.Zone']],
        ['length', ['SP.Compare.ZoneLength']],
        ['width', ['SP.Compare.ZoneWidth']],
        ['height', ['SP.Compare.ZoneHeight']],
        ['zone1StrengtheningElementDefinition', ['SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z1']],
        ['zone1InstallationDirection', ['SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection.z1']],
        ['zone1SpacingX', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z1']],
        ['zone1SpacingY', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z1']],
        ['zone1MinimumEdgeDistanceX', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z1']],
        ['zone1MinimumEdgeDistanceY', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z1']],
        ['zone1TransverseEccentricity', ['SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z1']],
        ['zone1Length', ['SP.Navigation.TabBaseMaterial.RegionZones.Z1Width']],
        ['zone2StrengtheningElementDefinition', ['SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z2']],
        ['zone2InstallationDirection', ['SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection.z2']],
        ['zone2SpacingX', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z2']],
        ['zone2SpacingY', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z2']],
        ['zone2MinimumEdgeDistanceX', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z2']],
        ['zone2MinimumEdgeDistanceY', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z2']],
        ['zone2TransverseEccentricity', ['SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z2']],
        ['zone2Length', ['SP.Navigation.TabBaseMaterial.RegionZones.Z2Width']],
        ['zone3StrengtheningElementDefinition', ['SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z3']],
        ['zone3InstallationDirection', ['SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection.z3']],
        ['zone3SpacingX', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z3']],
        ['zone3SpacingY', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z3']],
        ['zone3MinimumEdgeDistanceX', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z3']],
        ['zone3MinimumEdgeDistanceY', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z3']],
        ['zone3TransverseEccentricity', ['SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z3']],
        ['zone3Length', ['SP.Navigation.TabBaseMaterial.RegionZones.Z3Width']],
        ['zone4StrengtheningElementDefinition', ['SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z4']],
        ['zone4InstallationDirection', ['SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection.z4']],
        ['zone4SpacingX', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z4']],
        ['zone4SpacingY', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z4']],
        ['zone4MinimumEdgeDistanceX', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z4']],
        ['zone4MinimumEdgeDistanceY', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z4']],
        ['zone4TransverseEccentricity', ['SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z4']],
        ['zone4Length', ['SP.Navigation.TabBaseMaterial.RegionZones.Z4Width']],
        ['zone5StrengtheningElementDefinition', ['SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z5']],
        ['zone5SpacingX', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z5']],
        ['zone5SpacingY', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z5']],
        ['zone5MinimumEdgeDistanceX', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z5']],
        ['zone5MinimumEdgeDistanceY', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z5']],
        ['zone5TransverseEccentricity', ['SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z5']],
        ['zone6StrengtheningElementDefinition', ['SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z6']],
        ['zone6SpacingX', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z6']],
        ['zone6SpacingY', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z6']],
        ['zone6MinimumEdgeDistanceX', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z6']],
        ['zone6MinimumEdgeDistanceY', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z6']],
        ['zone6TransverseEccentricity', ['SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z6']],
        ['zone7StrengtheningElementDefinition', ['SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z7']],
        ['zone7SpacingX', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z7']],
        ['zone7SpacingY', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z7']],
        ['zone7MinimumEdgeDistanceX', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z7']],
        ['zone7MinimumEdgeDistanceY', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z7']],
        ['zone7TransverseEccentricity', ['SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z7']],
        ['zone8StrengtheningElementDefinition', ['SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z8']],
        ['zone8SpacingX', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z8']],
        ['zone8SpacingY', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z8']],
        ['zone8MinimumEdgeDistanceX', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z8']],
        ['zone8MinimumEdgeDistanceY', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z8']],
        ['zone8TransverseEccentricity', ['SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z8']],
        ['cover', ['SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.Cover']],
        ['effectiveHeight', ['SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeight']],
        ['minimumEdgeDistanceX', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX']],
        ['minimumEdgeDistanceY', ['SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY']],
        ['spacingX', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingX']],
        ['spacingY', ['SP.Navigation.TabPostInstalled.RegionZones.SpacingY']],
        ['installationDirectionId', ['SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection']],
        ['transverseEccentricity', ['SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity']],
        ['crossSectionalArea', ['SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalArea']],
        ['reinforcementContribution', ['SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.ReinforcementContribution']],
        ['defineOpening', ['SP.Navigation.TabBaseMaterial.RegionOpenings', 'SP.Navigation.TabBaseMaterial.RegionOpenings.DefineOpening']],
        ['openingLength', ['SP.Navigation.TabBaseMaterial.RegionOpenings', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength']],
        ['openingWidth', ['SP.Navigation.TabBaseMaterial.RegionOpenings', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth']],
        ['openingOriginX', ['SP.Navigation.TabBaseMaterial.RegionOpenings', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX']],
        ['openingOriginY', ['SP.Navigation.TabBaseMaterial.RegionOpenings', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY']],
        ['openings', ['SP.Navigation.TabBaseMaterial.RegionOpenings']],
        ['originX', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX']],
        ['originY', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY']],
        ['punchOpening1Length', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength.o1']],
        ['punchOpening2Length', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength.o2']],
        ['punchOpening3Length', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength.o3']],
        ['punchOpening1Width', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth.o1']],
        ['punchOpening2Width', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth.o2']],
        ['punchOpening3Width', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth.o3']],
        ['punchOpening1OriginX', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX.o1']],
        ['punchOpening2OriginX', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX.o2']],
        ['punchOpening3OriginX', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX.o3']],
        ['punchOpening1OriginY', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY.o1']],
        ['punchOpening2OriginY', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY.o2']],
        ['punchOpening3OriginY', ['SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY.o3']],
        ['fastenerId', ['SP.Navigation.TabPostInstalled.RegionStrengtheningElement', 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Size']],
        ['fastenerFamilyId', ['SP.Navigation.TabPostInstalled.RegionStrengtheningElement', 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Type']],
        ['fastenerFamilyGroupId', ['SP.Navigation.TabPostInstalled.RegionStrengtheningElement', 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Family']],
        ['loadTypeId', ['SP.Navigation.TabLoads.RegionLoadType', 'SP.Navigation.TabLoads.RegionLoadType.LoadType']],
        ['axialStress', ['SP.Navigation.TabLoads.RegionLoadType', 'SP.Navigation.TabLoads.RegionLoadType.AxialStress']],
        ['shearStrutAngle', ['SP.Navigation.TabLoads.RegionDesign', 'SP.InterfaceShear.ShearStrutAngle']],
        ['existingMemberVerificationId', ['SP.Navigation.TabLoads.RegionDesign', 'SP.Navigation.TabLoads.RegionDesign.ExistingMemberVerification']],
        ['nationalAnnex', ['SP.Navigation.TabLoads.RegionDesign', 'SP.Navigation.TabLoads.RegionDesign.UseNationalAnnex']],
        ['postInstalledReinforcementDesignId', ['SP.Navigation.TabLoads.RegionDesign', 'SP.Navigation.TabLoads.RegionDesign.PostInstalledReinforcementDesign']],
        ['reinforcementEffectiveness', ['SP.Navigation.TabLoads.RegionDesign', 'SP.Navigation.TabLoads.RegionDesign.PostInstalledReinforcementEffectiveness']],
        ['betaId', ['SP.Navigation.TabLoads.RegionLoadType', 'SP.Navigation.TabLoads.RegionLoadType.Beta']],
        ['beta', ['SP.Navigation.TabLoads.RegionLoadType', 'SP.Navigation.TabLoads.RegionLoadType.Beta']],
        ['regionId', ['SP.ApplicationSettings.Region']],
        ['designStandardId', ['SP.AddEditDesign.MethodAndApprovals', 'SP.DesignStandard']],
        ['gammaS', ['SP.AddEditDesign.ReductionFactors', 'SP.SafetyFactors.GammaS']],
        ['gammaC', ['SP.AddEditDesign.ReductionFactors', 'SP.SafetyFactors.GammaC']],
        ['alphaCC', ['SP.AddEditDesign.ConcreteMaterial', 'SP.ReductionFactors.AlphaCC']],
        ['e', ['SP.AddEditDesign.SteelMaterial', 'SP.SteelMaterial.E']],
        ['etaT', ['SP.AddEditDesign.ConcreteMaterial', 'SP.ReductionFactors.EtaT']],
        ['Kc', ['SP.AddEditDesign.InterfaceShearCalculation', 'SP.ApplicationSettings.ShearCalculation.Kc']],
        ['baseMemberId', ['SP.Navigation.TabApplication', 'SP.Navigation.TabApplication.RegionApplication.BaseMember']],
        ['compressionMemberId', ['SP.Navigation.TabApplication', 'SP.Navigation.TabApplication.RegionApplication.CompressionMember']],
        ['punchLength', ['SP.Navigation.TabApplication.RegionColumnDimensions', 'SP.Navigation.TabApplication.RegionColumnDimensions.Length']],
        ['punchWidth', ['SP.Navigation.TabApplication.RegionColumnDimensions', 'SP.Navigation.TabApplication.RegionColumnDimensions.Width']],
        ['punchDiameter', ['SP.Navigation.TabApplication.RegionColumnDimensions', 'SP.Navigation.TabApplication.RegionColumnDimensions.Diameter']],
        ['spanNegX', ['SP.Navigation.TabBaseMaterial.RegionGeometry', 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanNegX']],
        ['spanNegY', ['SP.Navigation.TabBaseMaterial.RegionGeometry', 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanNegY']],
        ['spanPosX', ['SP.Navigation.TabBaseMaterial.RegionGeometry', 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanPosX']],
        ['spanPosY', ['SP.Navigation.TabBaseMaterial.RegionGeometry', 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanPosY']],
        ['thickness', ['SP.Navigation.TabBaseMaterial.RegionGeometry', 'SP.Navigation.TabBaseMaterial.RegionGeometry.Thickness']],
        ['reinforcementYieldStrength', ['SP.Navigation.TabExistingReinforcement.RegionMaterials', 'SP.Navigation.TabExistingReinforcement.RegionMaterials.ReiforcementYieldStrength']],
        ['crossSectionalAreaX', ['SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement', 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalAreaPerSectionX']],
        ['coverX', ['SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement', 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CoverToCenterX']],
        ['effectiveHeightX', ['SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement', 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeightX']],
        ['crossSectionalAreaY', ['SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement', 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalAreaPerSectionY']],
        ['coverY', ['SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement', 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CoverToCenterY']],
        ['effectiveHeightY', ['SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement', 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeightY']],
        ['punchInstallationDirectionId', ['SP.Navigation.TabPostInstalled.ReinforcementPosition', 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection']],
        ['reinforcementArrangementId', ['SP.Navigation.TabPostInstalled.ReinforcementPosition', 'SP.Navigation.TabPostInstalled.ReinforcementPosition.ReinforcementArrangement']],
        ['radiiX', ['SP.Navigation.TabPostInstalled.ReinforcementPosition', 'SP.Navigation.TabPostInstalled.ReinforcementPosition.RadiiX']],
        ['radiiY', ['SP.Navigation.TabPostInstalled.ReinforcementPosition', 'SP.Navigation.TabPostInstalled.ReinforcementPosition.RadiiY']],
        ['radiiCorners', ['SP.Navigation.TabPostInstalled.ReinforcementPosition', 'SP.Navigation.TabPostInstalled.ReinforcementPosition.RadiiCorners']],
        ['radii', ['SP.Navigation.TabPostInstalled.ReinforcementPosition', 'SP.Navigation.TabPostInstalled.ReinforcementPosition.Radii']],
        ['minEdgeDistanceY', ['SP.Navigation.TabPostInstalled.ReinforcementPosition', 'SP.Navigation.TabPostInstalled.ReinforcementPosition.MinEdgeDistanceX']],
        ['minEdgeDistanceX', ['SP.Navigation.TabPostInstalled.ReinforcementPosition', 'SP.Navigation.TabPostInstalled.ReinforcementPosition.MinEdgeDistanceY']],
        ['firstPerimeterSpacing', ['SP.Navigation.TabPostInstalled.ReinforcementPosition', 'SP.Navigation.TabPostInstalled.ReinforcementPosition.SpacingFirstPerimeter']],
        ['subsequentPerimeterSpacing', ['SP.Navigation.TabPostInstalled.ReinforcementPosition', 'SP.Navigation.TabPostInstalled.ReinforcementPosition.SpacingBetweenSubsequentPerimeters']],
        ['reportStrengtheningApplication', ['SP.Report.CompanySection.StrengtheningApplication']],
        ['reportNotes', ['SP.Report.Notes']],
    ]);

    public ngOnInit(): void {
        this.getTranslatedProperties(this.modalInstance.input?.changedProperties);
    }

    public getTitle(): string {
        return this.translate('SP.General.Warning');
    }

    private getTranslatedProperties(properties: ChangedConvertedProperties[] | undefined) {
        properties?.forEach((property) => {
            if (property.children.length > 0) {
                this.getTranslatedProperties(property.children);
            }

            property.name = this.getDesignPropTranslation(property.name, property.propertyNumber);
        });
    }

    private getDesignPropTranslation(propName: string, propertyNumber: string | undefined) {
        let translated = '';

        if (!this.designPropertiesTranslationKeys.has(propName)) {
            return propertyNumber == undefined ? propName : propName + ' ' + propertyNumber;
        }

        const translationKey = this.designPropertiesTranslationKeys.get(propName);

        if (translationKey && propertyNumber) {
            translationKey.forEach((key) => {
                translated += propertyNumber ? `${this.translate(key)} ${propertyNumber}` : this.translate(key);
            });
            return translated;
        }

        if (translationKey) {
            translationKey.forEach((key) => {
                if (translationKey.lastIndexOf(key) !== translationKey.length - 1) {
                    translated += `${this.translate(key)} - `;
                }
                else {
                    translated += this.translate(key);
                }
            });

            return translated;
        }

        return propertyNumber ? `${propName} ${propertyNumber}` : propName;
    }
}

