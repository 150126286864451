import { HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectType, ProjectUser } from '@profis-engineering/pe-ui-common/entities/project';
import { DocumentGetResponse } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Documents';
import {
    ArchiveProjectResponseModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import {
    CantArchiveDesignBecauseLockedByOtherUser, CantArchiveProjectsBecauseDocumentInUse,
    CantDeleteProjectsBecauseDocumentInUse, CantOpenDesignBecauseLockedByOtherUser, CantRestoreProjectsBecauseDocumentInUse,
    DesignExternalMetaData as DesignExternalMetaDataCommon, DocumentAccessMode, DocumentServiceBase, IDesignListItem as IDesignListItemCommon
} from '@profis-engineering/pe-ui-common/services/document.common';
import { environment } from '../../environments/environment';
import { IBaseDesign } from '../entities/design';
import { Project } from '../entities/project';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { DocumentWebCommon, IGetDocumentResponse } from './document-web-common.service';
import { IDesignListItem } from './document.service';
import { ErrorHandlerService } from './error-handler.service';
import { GuidService } from './guid.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { ModulesService } from './modules.service';
import { RoutingService } from './routing.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentServiceWebV1 extends DocumentWebCommon implements DocumentServiceBase {

    constructor(
        loggerService: LoggerService,
        modulesService: ModulesService,
        routingService: RoutingService,
        apiService: ApiService,
        browserService: BrowserService,
        modalService: ModalService,
        localizationService: LocalizationService,
        guidService: GuidService,
        userService: UserService,
        errorHandlerService: ErrorHandlerService
    ) {
        super(
            'DocumentServiceWebV1',
            loggerService,
            modulesService,
            routingService,
            apiService,
            browserService,
            modalService,
            localizationService,
            guidService,
            userService,
            errorHandlerService
        );
    }


    public initialize(data: DocumentGetResponse, dataArchive: ArchiveProjectResponseModel[]): void {
        this.initializeCommon(data, dataArchive);
    }

    /**
     * Deletes (permanently) the project from internal store and call external document service.
     * @projectId project
     */
    public async removeArchivedProject(projectId: string): Promise<void> {
        const pToDeleteCandidate = Object.values(this._projectsArchive).find((item) => item.id == projectId);
        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        try {
            await this.removeExistingArchivedProject(projectId);

            // remove from project flat list of projects
            this._projectsArchive = this._projectsArchive.filter((x) => x.id != projectId);
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantDeleteProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    /**
     * Deletes (permanently) the project from internal store and call external document service.
     * @projectId project
     */
    public async removeProject(projectId: string): Promise<void> {
        const pToDeleteCandidate = this._projectsFlat[projectId];

        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        try {
            await this.removeExistingProject(projectId);

            // remove project from list of projects
            if (pToDeleteCandidate.parentId != null) {
                delete Object.values(this._projects).find((x) => x.id == pToDeleteCandidate.parentId).subProjects[projectId];
            }
            else {
                delete this._projects[projectId];
            }

            delete this._projectsFlat[projectId];
            delete this.internalRawProject.projects[projectId];
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantDeleteProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    /**
     * Convert (permanently) the project to company (shared) project and call external document service.
     * @projectId project
     */
    public async convertProject(projectId: string): Promise<void> {
        const pToConvertCandidate = this._projectsFlat[projectId];
        if (pToConvertCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        await this.convertExistingProject(projectId);

        // convert project from list of projects
        if (pToConvertCandidate.parentId != null) {
            Object.values(Object.values(this._projects).find((x) => x.id == pToConvertCandidate.parentId).subProjects).find((item) => item.id == projectId).isCompanyProject = true;
        }
        else if (pToConvertCandidate.subProjects != null && Object.values(pToConvertCandidate.subProjects).length > 0) {
            Object.values(this._projects).find((x) => x.id == pToConvertCandidate.id).subProjects[projectId].isCompanyProject = true;
            Object.values(this._projects).find((x) => x.id == pToConvertCandidate.id).isCompanyProject = true;
        }
        else {
            Object.values(this._projects).find((x) => x.id == pToConvertCandidate.id).isCompanyProject = true;
        }
    }

    public async getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>> {
        const url = `${environment.documentWebServiceUrl}documentcontent/GetMultipleDocumentThumbnail`;

        if (documentIds == null || documentIds.length == 0) {
            return this.documentThumbnails;
        }

        const missingImages: string[] = [];

        for (const id of documentIds) {
            if (this.documentThumbnails[id] == null && this.documentThumbnailPromise[id] == null) {
                missingImages.push(id);
            }
        }

        if (missingImages.length > 0) {
            const promise = this.apiService.request<unknown>(new HttpRequest('POST', url, missingImages), this.getApiOptions());
            for (const id of missingImages) {
                this.documentThumbnailPromise[id] = promise;
            }

            try {
                const response = await promise;
                const data: any = response.body;

                this.logServiceResponse('getDocumentThumbnails', data);

                for (const item of data) {
                    this.documentThumbnails[item.documentId] =
                        item.thumbnailImageContent != null && item.thumbnailImageContent != '' ? item.thumbnailImageContent : '';
                }

                return this.documentThumbnails;
            }
            finally {
                for (const id of missingImages) {
                    this.documentThumbnailPromise[id] = null;
                }
            }
        }
        else {
            return this.documentThumbnails;
        }
    }

    /**
     * Restores the project from internal store and call external document service.
     * @projectId project
     */
    public async restoreProject(projectId: string): Promise<string[]> {
        const pToRestoreCandidate = Object.values(this._projectsArchive).find((item) => item.id == projectId);
        if (pToRestoreCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        try {
            const x = await this.restoreExistingProject(projectId);

            // Add restored project and it's documents to the project menu
            if (x != null && x.projects != null && x.documents != null) {
                for (const key in x.projects) {
                    this.internalRawProject.projects[key] = x.projects[key];
                }
                for (const key in x.documents) {
                    this.internalRawProject.documents[key] = x.documents[key];
                }

                this.getInternal(this.internalRawProject);
            }

            // Remove project and all sub-projects from archive list
            this._projectsArchive = this._projectsArchive.filter((x) => x.id != projectId && x.parentId != projectId);
            return Promise.resolve([]);
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantRestoreProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async restoreExistingDesign(_documentId: string): Promise<void>{
        throw new Error('Method not implemented');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async removeArchivedDesign(_documentIds: string[]): Promise<void> {
        throw new Error('Method not implemented');
    }

    /*
     * Saves a new or existing project. Returns promise when the new id of the project is returned.
     */
    public async saveProject(project: Project): Promise<void> {
        if (project.name.startsWith('#$')) {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.IllegalChar.Title'),
                this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.IllegalChar.Message')
            );

            throw new Error('invalid-name');
        }
        else {
            if (project.name.length > 250) {
                throw new Error('Max length for project name is 250 characters!');
            }

            if (this._projectsFlat[project.id] != null) {
                // call edit project on document service
                await this.updateExistingProject(project);

                const internalProject = this.findProjectById(project.id);
                internalProject.name = project.name;
            }
            else {
                // call add new project on document service
                await this.addNewProject(project);

                this.internalRawProject.projects[project.id] = {
                    id: project.id,
                    name: project.name,
                    dateChanged: new Date(),
                    dateCreated: new Date(),
                    parentId: project.parentId,
                    owner: project.owner,
                    isCompanyProject: project.isCompanyProject,
                    expanded: project.expanded,
                    readOnly: project.readOnly,
                    expandedState: project.expandedState
                };
                this._projectsFlat[project.id] = project;
                if (project.parentId != null) {
                    this._projects[project.parentId].subProjects[project.id] = project;
                }
                else {
                    this._projects[project.id] = project;
                }
            }
        }
    }

    /*
     * Toggles expanded state for project.
     */
    public async toggleExpandProject(project: Project): Promise<void> {
        if (this._projectsFlat[project.id] == null) {
            throw new Error('Project with ID does not exist.');
        }

        await this.toggleExpandExistingProject(project);

        const internalProject = this.findProjectById(project.id);
        internalProject.name = project.name;
    }

    /**
     * Remove the project from internal store and call external document service.
     * @projectId project
     */
    public async archiveProject(projectId: string): Promise<void> {
        const pToDeleteCandidate = this.findProjectById(projectId);

        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }
        if (pToDeleteCandidate.projectType != ProjectType.common) {
            throw new Error('Only common projects can be deleted.');
        }

        // delete parent
        try {
            const resp: any = await this.archiveExistingProject(projectId);
            for (const index in resp) {
                const x = resp[index];
                const projId = x.projectid;
                this._projectsArchive.push({
                    archived: x.archived,
                    created: x.created,
                    designs: x.designs,
                    members: x.members,
                    id: x.projectid,
                    parentId: x.parentprojectid,
                    name: x.name
                });

                this.deleteProjectFunc(projId);
            }
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantArchiveProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;

                throw ex;
            }

            throw ex;
        }
    }

    public async catchDesignExclusive(response: unknown, design: IBaseDesign): Promise<never> {
        // handle document locked error
        if (response instanceof HttpErrorResponse && response.status == 423) {
            // check the detail of the conflict
            const d = await this.getDesignBasicDocumentInformation(design.id);
            const ex = new CantOpenDesignBecauseLockedByOtherUser();
            ex.username = d.lockedUser;

            throw ex;
        }

        throw response;
    }

    /**
     * Remove the design from internal store and call external document service.
     * @designId design
     */
    public async archiveDesign(designId: string): Promise<void> {

        const dToDeleteCandidate = this.findDesignById(designId);
        if (dToDeleteCandidate == null) {
            throw new Error('Design with ID does not exist.');
        }

        // delete design
        try {
            await this.archiveExistingDesign(designId);
            this.deleteDesignFunc(designId);
        }
        catch (ex) {
            // catch exception code that happens when design cannot be deleted because a file is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantArchiveDesignBecauseLockedByOtherUser) {
                const d = await this.getDesignBasicDocumentInformation(designId);
                ex.username = d.lockedUser;
            }

            throw ex;
        }
    }

    public deleteProjectLocal(projectId: string): void {
        Object.values(this.internalRawProject.projects).forEach((proj) => {
            if (proj.parentId == projectId) {
                this.deleteProjectFunc(proj.id);
            }
        });

        this.deleteProjectFunc(projectId);
    }

    public async moveDesign(documentId: string, projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}document/move`;
        this.logServiceRequest('moveDesign', url);

        const data = {
            documentId,
            projectId
        };

        try {
            const response = await this.apiService.request<IGetDocumentResponse>(new HttpRequest('POST', url, data), this.getApiOptions());

            this.logServiceResponse('moveDesign', response);

            const design = this.toIDesignListItem(response.body);
            this.deleteDesignFunc(design.id);
            this.addUpdateDesignInProject(design);

        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'move design');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409 && response.error.content.conflictType == 0) {
                    this.errorHandlerService.showExistingDesignNameModal(response, url, data);
                }
                else if (response.status == 409 && response.error.content.conflictType == 1) {
                    await this.modalService.openMaxDesignsLimitReached();
                }
                else if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, data);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: data
                    });
                }
            }

            throw response;
        }
    }

    public async copyDesign(documentId: string, documentName: string, projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}document/copy`;
        this.logServiceRequest('copyDesign', url);

        const data = {
            documentId,
            documentName,
            projectId
        };

        try {
            const response = await this.apiService.request<IGetDocumentResponse>(new HttpRequest('POST', url, data), this.getApiOptions());

            this.logServiceResponse('copyDesign', response);

            const newDesign = this.toIDesignListItem(response.body);
            this.addUpdateDesignInProject(newDesign);

        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'copy design');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409 && response.error.content.conflictType == 0) {
                    this.errorHandlerService.showExistingDesignNameModal(response, url, data);
                }
                else if (response.status == 409 && response.error.content.conflictType == 1) {
                    await this.modalService.openMaxDesignsLimitReached();
                }
                else if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, data);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: data
                    });
                }
            }

            throw response;
        }
    }

    public override findAllDesignsByProject(project: Project): { [id: string]: IDesignListItemCommon } {
        const parentProject = project.parentId ? this.findProjectById(project.parentId) : null;
        return parentProject == null ? null: parentProject.designs;
    }


    protected getInternal(data: DocumentGetResponse): void {
        this.internalRawProject = data;
        this._desingsFlat = {};
        this._draftsProject = null;
        this._projectsFlat = {};
        this._projects = {};

        let projects: { [projectId: string]: Project } = {};
        if (data != null && data.projects != null) {
            projects = Object.fromEntries(Object.entries(data.projects).map(([key, project]) => [key, new Project({
                id: project.id,
                name: this.getProjectName(project.name),
                parentId: project.parentId,
                projectType: this.getProjectType(project.name),
                designs: null,
                subProjects: null,
                changeDate: new Date(project.dateChanged.toString()),
                createDate: new Date(project.dateCreated.toString()),
                owner: project.owner,
                isCompanyProject: project.isCompanyProject,
                expanded: project.expanded,
                readOnly: project.readOnly
            })]));
        }

        let documents: { [documentId: string]: IDesignListItem } = {};
        if (data != null && data.documents != null) {
            documents = Object.fromEntries(Object.entries(data.documents).map(([key, document]): [string, IDesignListItem] => [key, {
                id: document.id,
                projectId: document.projectId,
                projectName: projects[document.projectId].name,
                changeDate: new Date(document.dateChanged.toString()),
                createDate: new Date(document.dateCreated.toString()),
                designName: document.name,
                locked: document.locked,
                lockedUser: document.lockedUserName,
                metaData: this.getDesignExternalMetadata(document.metadata),
                integrationDocument: undefined,
                owner: document.owner
            }]));
        }

        this._desingsFlat = documents;
        const allSubProjects = Object.values(projects).filter(projects => projects.parentId != null);
        const allParentProjects = Object.values(projects).filter(projects => projects.parentId == null);
        // add designs to projects
        for (const project of allParentProjects) {
            const designs: { [id: string]: IDesignListItem } = Object.fromEntries(Object.entries(documents).filter(([_key, document]) => document.projectId == project.id));

            project.directChildDesigns = designs;
            project.designs = designs;

            this._projectsFlat[project.id] = project;
            this._projects[project.id] = project;

            // drafts project
            if (project.projectType == ProjectType.draft) {
                this._draftsProject = project;
            }
        }

        // add designs to subProjects
        for (const project of allSubProjects) {
            const designs: { [id: string]: IDesignListItem } = Object.fromEntries(Object.entries(documents).filter(([_key, document]) => document.projectId == project.id));

            project.directChildDesigns = designs;
            project.designs = designs;

            this._projectsFlat[project.id] = project;
            if (this._projects[project.parentId] != null) {
                this._projects[project.parentId].subProjects[project.id] = project;

                Object.values(designs).forEach((design) => {
                    this._projects[project.parentId].designs[design.id] = design;
                });
            }
            else {
                this._projects[project.id] = project;
            }

        }

        // drafts project
        if (!Object.values(this._projectsFlat).some((project) => project.projectType == ProjectType.draft)) {
            const draftProject: Project = new Project({ name: '#$draft', projectType: ProjectType.draft });

            // call the actual service to add the project into store
            this.addNewProject(draftProject).then((project) => {
                this._projectsFlat[project.id] = project;
                this._projects[project.id] = project;
                this._draftsProject = project;
            });
        }
    }

    protected updateProject(designId: string, projectId: string): void {
        const design = this.findDesignById(designId);
        if (design == null) {
            throw new Error('Invalid design id.');
        }

        const project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project id.');
        }

        design.projectId = projectId;
        design.projectName = project.name;

        // remove design from project
        for (const pKey in this.projectsFlat) {
            const p = this.projectsFlat[pKey];
            delete p.directChildDesigns[design.id];
            delete p.designs[design.id];
        }

        // remove design from designs
        delete this._desingsFlat[design.id];

        // add design to project
        if (project.directChildDesigns == null) {
            project.directChildDesigns = {};
        }

        this.addUpdateDesignInProject(design);
    }

    /**
     * Adds user to the project
     * @param data - Project user data
     */
    protected async addUsersOnProjectByIdInternal(data: ProjectUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}User`;

        const sendData = {
            projectid: data.projectid,
            user: data.user
        };

        try {
            await this.apiService.request(new HttpRequest('POST', url, sendData), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add user to the project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Removes user from the project
     * @param data - Project user data
     */
    protected async removeUsersOnProjectByIdInternal(data: ProjectUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}User`;

        const sendData = {
            projectid: data.projectid,
            user: data.user
        };

        const request = new HttpRequest('DELETE', url, sendData, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });

        try {
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete user from the project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Make a small change to the already exclusively locked and open design, without closing or releasing the content.
     * @param id - design id
     * @param base64XmlContent - the design content in base64 encoded xml format
     * @param sessionId - id of the design change session
     * @param metaData -
     * @param openDesign - if it's called when you open design or close/save
     */
    protected async putSmallDesignChange(
        designId: string,
        projectId: string,
        designName: string,
        base64XmlContent: string,
        sessionId: string,
        metaData: DesignExternalMetaDataCommon,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode: DocumentAccessMode = DocumentAccessMode.Open
    ): Promise<void> {
        const url = `${environment.documentWebServiceUrl}documentcontent`;

        const rData = {
            unlock,
            key: sessionId,
            documentid: designId,
            projectid: projectId,
            name: designName,
            filecontent: base64XmlContent,
            metadata: this.toServiceMetaData(metaData),
            exclusiveLock,
            documentAccessMode
        };
        this.logServiceRequest('putSmallDesignChange', rData, url);

        const request = new HttpRequest('PUT', url, rData, {
            responseType: 'json'
        });

        try {
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'small design update failed');

            if (!this.handleSessionExpiredError(response, { designId }, [428])
                && !this.handleDesignFileLocked(response, { designId }, [423], false, documentAccessMode)
            ) {
                if (response instanceof HttpErrorResponse) {
                    if (response.status == 409) {
                        this.errorHandlerService.showExistingDesignNameModal(response, url, rData);
                    }
                    else if (response.status == 404) {
                        this.errorHandlerService.showProjectArchivedModal(response, url, rData);
                    }
                    else if (response.status != 401) {
                        this.modalService.openAlertServiceError({
                            response,
                            endPointUrl: url,
                            requestPayload: rData
                        });
                    }
                }
            }

            throw response;
        }
    }

    protected deleteDesignFunc(designId: string): void {
        const design = this.findDesignById(designId);
        const project = this.findProjectById(design.projectId);

        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        const deleteDoc = Object.keys(this.internalRawProject.documents).find((it) => this.internalRawProject.documents[it].id == designId);
        delete this.internalRawProject.documents[deleteDoc];

        // remove from project
        delete project.directChildDesigns[designId];
        delete project.designs[designId];

        // remove from all parent projects
        let parentX: Project = this.findProjectById(project.parentId);
        while (parentX != undefined && parentX != null) {
            delete parentX.designs[designId];
            parentX = this.findProjectById(parentX.parentId);
        }

        // remove from flat design list
        delete this._desingsFlat[designId];
    }

    protected getTimestamp(ticks?: number) {
        if (ticks != null) {
            return new Date(ticks);
        }

        return new Date();
    }

    protected addUpdateDesignInProjectAdditional(): void {
        // Nothing to do for V1.
    }


    private deleteProjectFunc(projId: string): void {
        delete this.internalRawProject.projects[projId];
        const deleteDocs = Object.keys(this.internalRawProject.documents).filter((it) => this.internalRawProject.documents[it].projectId == projId);
        deleteDocs.forEach((it) => this.deleteDesignFunc(it));

        // remove from project flat list
        delete this._projectsFlat[projId];

        // remove form hierarchical list - in line recursion
        delete this._projects[projId];

        const remove = (x: Project) => {
            delete x.subProjects[projId];

            for (const s in x.subProjects) remove(x.subProjects[s]);

        };
        for (const s in this._projects) remove(this._projects[s]);
    }

    private async toggleExpandExistingProject(project: Project): Promise<void> {
        project.expanded = !project.expanded;

        const url = `${environment.documentWebServiceUrl}project/setExpanded/${project.id}/${project.expanded}`;

        this.logServiceRequest('toggleExpandExistingProject', url);

        try {
            await this.apiService.request(new HttpRequest('PUT', url, null), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'put project setExpanded');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async removeExistingProject(projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/permanent/${projectId}`;

        this.logServiceRequest('removeExistingProject', url);

        try {
            await this.apiService.request(new HttpRequest('DELETE', url), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantDeleteProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async archiveExistingDesign(designId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}document/${designId}`;

        this.logServiceRequest('archiveExistingDesign', url);

        try {
            await this.apiService.request(new HttpRequest('DELETE', url), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'archive design');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantArchiveDesignBecauseLockedByOtherUser();
                }

                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public isProjectPresentInTargetProjectHierarchy(sourceProject: Project, targetProject: Project): boolean {
        throw new Error('Method not implemented.');
    }
}
