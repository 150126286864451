import type { LocaleSpecification, Moment } from 'moment';
import { Observable } from 'rxjs';

import { ApiOptions } from './api.common';
import { BimCadLibLanguage } from '../entities/code-lists/bim-cad-lib-language';

export interface ISanitizeTags {
    sub?: boolean;
    sup?: boolean;
    p?: boolean;
    br?: boolean;
    h1?: boolean;
    ol?: boolean;
    li?: boolean;
    b?: boolean;
    a?: boolean;
    i?: boolean;
}

export interface IGetStringOptions {
    tags?: ISanitizeTags;
    optional?: boolean;
    defaultString?: string;
}

export const enum LanguageCulture {
    pseudoLanguage = 'en-ZW'
}

export const enum LanguageCultureLcid {
    pseudoLanguage = 12297
}

export interface NumberFormat {
    NumberDecimalSeparator: string;
    NumberGroupSeparator: string;
    NumberGroupSizes: number[];
}

export type GetTranslationsHook = () => Promise<void>;

export const DefaultDateFormat = 'LLL';

/**
 * Localization service base
 */
export abstract class LocalizationServiceBase {
    public static readonly H1OlLi: Readonly<ISanitizeTags> = {
        h1: true,
        li: true,
        ol: true
    };

    public static readonly PBrB: Readonly<ISanitizeTags> = {
        p: true,
        br: true,
        b: true
    };

    public static readonly A: Readonly<ISanitizeTags> = {
        a: true
    };

    public static readonly SubSup: Readonly<ISanitizeTags> = {
        sub: true,
        sup: true
    };

    public static readonly I: Readonly<ISanitizeTags> = {
        i: true
    };

    public abstract get selectedLanguageLCID(): number;
    public abstract get selectedLanguage(): string;
    public abstract get selectedBimCadLibLanguage(): BimCadLibLanguage;
    public abstract get localizationChange(): Observable<void>;
    public abstract get separatorChange(): Observable<void>;

    public abstract hasTranslation(translationKey: string): boolean;
    public abstract getString(key: string, opts?: IGetStringOptions): string;
    public abstract sanitizeText(value: string, tags: ISanitizeTags): string;
    public abstract getKeyExists(key: string): boolean;
    public abstract numberFormat(): NumberFormat;
    public abstract isHtml(value: string): boolean;
    public abstract moment(date?: Date, language?: string): Moment;
    public abstract momentWithLocaleSpecification(date?: Date, language?: string, localeSpec?: LocaleSpecification): Moment;
    public abstract selectTranslations(language: string, keys: string[], options?: ApiOptions): Promise<void>;
    public abstract getLocalizedStringByCulture(key: string, culture: string, tags?: ISanitizeTags): string;

    /**
    * Add a hook to be triggered when the getTranslations function is called.
    * This allows for additional actions or modifications each time translations are fetched (e.g. when language is changed).
    */
    public abstract addGetTranslationsHook(hookFunc: GetTranslationsHook): void;
    abstract get onGetTranslationsHooks(): GetTranslationsHook[];
}

export class LocalizationServiceInjected extends LocalizationServiceBase {
    protected baseService!: LocalizationServiceBase;

    get onGetTranslationsHooks(): GetTranslationsHook[] {
        return this.baseService.onGetTranslationsHooks;
    }

    public get localizationChange(): Observable<void> {
        return this.baseService.localizationChange;
    }

    public get separatorChange(): Observable<void> {
        return this.baseService.separatorChange;
    }

    public setBaseService(baseService: LocalizationServiceBase) {
        this.baseService = baseService;

        this.hasTranslation = baseService.hasTranslation.bind(baseService);
        this.getString = baseService.getString.bind(baseService);
        this.sanitizeText = baseService.sanitizeText.bind(baseService);
        this.getKeyExists = baseService.getKeyExists.bind(baseService);
        this.numberFormat = baseService.numberFormat.bind(baseService);
        this.isHtml = baseService.isHtml.bind(baseService);
        this.moment = baseService.moment.bind(baseService);
        this.momentWithLocaleSpecification = baseService.momentWithLocaleSpecification.bind(baseService);
        this.selectTranslations = baseService.selectTranslations.bind(baseService);
        this.getLocalizedStringByCulture = baseService.getLocalizedStringByCulture.bind(baseService);
        this.addGetTranslationsHook = baseService.addGetTranslationsHook?.bind(baseService);
    }

    // LocalizationServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public get selectedLanguageLCID() {
        return this.baseService.selectedLanguageLCID;
    }

    public get selectedLanguage() {
        return this.baseService.selectedLanguage;
    }

    public get selectedBimCadLibLanguage() {
        return this.baseService.selectedBimCadLibLanguage;
    }

    public hasTranslation(_key: string) {
        return false;
    }

    public getString(_key: string, _opts?: IGetStringOptions) {
        return '';
    }

    public sanitizeText(_value: string, _tags: ISanitizeTags) {
        return '';
    }

    public getKeyExists(_key: string) {
        return false;
    }

    public numberFormat() {
        return {} as NumberFormat;
    }

    public isHtml(_value: string) {
        return false;
    }

    public moment(_date?: Date, _language?: string) {
        return {} as Moment;
    }

    public momentWithLocaleSpecification(_date?: Date, _language?: string, _localeSpec?: LocaleSpecification) {
        return {} as Moment;
    }

    public selectTranslations(_language: string, _keys: string[], _options?: ApiOptions): Promise<void> {
        return {} as Promise<void>;
    }

    public getLocalizedStringByCulture(_key: string, _culture: string, _tags?: ISanitizeTags): string {
        return '';
    }

    public addGetTranslationsHook(_loadTranslations: GetTranslationsHook): void {
        return;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
