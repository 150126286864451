import { CodeList } from './code-list';
import { HoleTypeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class HoleType extends CodeList {

    public static fromService(codeList: HoleTypeEntityC2C) {
        return new HoleType(CodeList.baseFromService('HoleTypeEntity', codeList));
    }

    public override cloneDeep(): HoleType {
        return super.cloneDeep() as HoleType;
    }
}
