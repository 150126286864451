import {
    EmbedmentDepthExistingEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class EmbedmentDepthExisting extends CodeList {
    public depth?: number;

    constructor(codeList: any) {
        super(codeList);

        if (codeList != null) {
            this.depth = codeList.depth;
        }
    }

    public static fromService(codeList: EmbedmentDepthExistingEntityC2C) {
        return new EmbedmentDepthExisting(CodeList.baseFromService('EmbedmentDepthExistingEntity', codeList, {
            id: codeList.id,
            depth: codeList.depth
        }));
    }

    public override cloneDeep(): EmbedmentDepthExisting {
        const codeList = super.cloneDeep() as EmbedmentDepthExisting;

        codeList.depth = this.depth;

        return codeList;
    }
}
