import { Directive, Input } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';

@Directive()
export abstract class InfoDialogBaseComponent<TInput = object> {
    @Input()
    public modalInstance!: ModalInstance<TInput>;

    constructor(protected localization: LocalizationService) {
    }

    public abstract getTitle(): string;

    public translate(key: string | undefined) {
        return this.localization.getString(key ?? '', { tags: { ...LocalizationService.SubSup, ...LocalizationService.SubSup, ...LocalizationService.PBrB } });
    }

    public close() {
        this.modalInstance.close();
    }
}
