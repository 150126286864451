@if (isLoaded) {
    <div class="section no-margin">
        <div class="section-row">
            <span class="local-regulations-disclaimer all"
                l10n="SP.AddEditDesign.LocalRegulationsDisclaimer"></span>
        </div>
    </div>

    <div class="section data" [class.no-margin]="collapseRegion.MethodAndApprovals">
        <p class="title-strike title" [class.no-margin]="collapseRegion.MethodAndApprovals">
            <span l10n="SP.AddEditDesign.MethodAndApprovals"></span>
            <pe-collapse class="section-collapse" [container]="'section-methods-approvals'"
                [collapsed]="collapseRegion.MethodAndApprovals"
                (collapsedChange)="collapseRegion.MethodAndApprovals = $any($event).detail">
            </pe-collapse>
        </p>

        @if (!collapseRegion.MethodAndApprovals) {
            <div class="section-row section-methods-approvals">
                <div class="section-column separator design-standard">
                    <!-- Design Standard -->
                    <div class="content">
                        <div class="control-column length-large-dropdown">
                            <pe-dropdown [id]="designStandardDropdown.id" [title]="designStandardDropdown.title"
                                [notSelectedText]="designStandardDropdown.notSelectedText"
                                [items]="designStandardDropdown.items"
                                [selectedValue]="designStandardDropdown.selectedValue"
                                (selectedValueChange)="onDesignStandardDropdownSelectedValueChange($any($event).detail)"
                                [validators]="designStandardDropdown.validators"
                                [disabled]="submitted || addEditType == AddEditType.edit">
                            </pe-dropdown>
                        </div>

                        @if (selectedDesignStandardDescription) {
                            <div class="design-standard-note">
                                {{selectedDesignStandardDescription}}
                            </div>
                        }
                    </div>
                </div>

                <div class="section-column separator technical-data">
                    <!-- TODO JANJ: Technical Data - CHECK if this is needed -->
                </div>

                <div class="section-column separator technical-data">
                    <!-- TODO JANJ: Technical Data - CHECK if this is needed -->
                </div>
            </div>
        }
    </div>

    <div class="section units" [class.no-margin]="collapseRegion.UnitsAndParams">
        <p class="title-strike title" [class.no-margin]="collapseRegion.UnitsAndParams">
            <span l10n="SP.AddEditDesign.Units"></span>

            <pe-collapse class="section-collapse" [id]="'section-units-params'" [container]="'section-units-params'"
                [collapsed]="collapseRegion.UnitsAndParams"
                (collapsedChange)="collapseRegion.UnitsAndParams = $any($event).detail"></pe-collapse>
        </p>

        <div class="control-row section-units-params">
            <div class="control-column length-dropdown">
                <pe-dropdown [id]="lengthDropdown.id" [title]="lengthDropdown.title" [items]="lengthDropdown.items"
                    [selectedValue]="lengthDropdown.selectedValue"
                    (selectedValueChange)="lengthDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted"></pe-dropdown>
            </div>

            <div class="control-column area-dropdown">
                <pe-dropdown [id]="areaDropdown.id" [title]="areaDropdown.title" [items]="areaDropdown.items"
                    [selectedValue]="areaDropdown.selectedValue"
                    (selectedValueChange)="areaDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted"></pe-dropdown>
            </div>

            <div class="control-column stress-dropdown">
                <pe-dropdown [id]="stressDropdown.id" [title]="stressDropdown.title" [items]="stressDropdown.items"
                    [selectedValue]="stressDropdown.selectedValue"
                    (selectedValueChange)="stressDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column force-dropdown">
                <pe-dropdown [id]="forceDropdown.id" [title]="forceDropdown.title" [items]="forceDropdown.items"
                    [selectedValue]="forceDropdown.selectedValue"
                    (selectedValueChange)="forceDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column moment-dropdown">
                <pe-dropdown [id]="momentDropdown.id" [title]="momentDropdown.title" [items]="momentDropdown.items"
                    [selectedValue]="momentDropdown.selectedValue"
                    (selectedValueChange)="momentDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column temperature-dropdown">
                <pe-dropdown [id]="temperatureDropdown.id" [title]="temperatureDropdown.title"
                    [items]="temperatureDropdown.items" [selectedValue]="temperatureDropdown.selectedValue"
                    (selectedValueChange)="temperatureDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column force-per-length-dropdown">
                <pe-dropdown [id]="forcePerLengthDropdown.id" [title]="forcePerLengthDropdown.title"
                    [items]="forcePerLengthDropdown.items" [selectedValue]="forcePerLengthDropdown.selectedValue"
                    (selectedValueChange)="forcePerLengthDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column density-dropdown">
                <pe-dropdown [id]="densityDropdown.id" [title]="densityDropdown.title" [items]="densityDropdown.items"
                    [selectedValue]="densityDropdown.selectedValue"
                    (selectedValueChange)="densityDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column area-per-length-dropdown">
                <pe-dropdown [id]="areaPerLengthDropdown.id" [title]="areaPerLengthDropdown.title"
                    [items]="areaPerLengthDropdown.items" [selectedValue]="areaPerLengthDropdown.selectedValue"
                    (selectedValueChange)="areaPerLengthDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>
        </div>
    </div>

    <div class="section safety-factors" [class.no-margin]="collapseRegion.SafetyFactor">
        <p class="title-strike title" [class.no-margin]="collapseRegion.SafetyFactor">
            <span l10n="SP.AddEditDesign.ReductionFactors"></span>

            <pe-collapse class="section-collapse" [id]="'section-safety-factors'" [container]="'section-safety-factors'"
                [collapsed]="collapseRegion.SafetyFactor"
                (collapsedChange)="collapseRegion.SafetyFactor = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-safety-factors">
            <app-add-edit-design-inputs-safety-factors [gammaS]="gammaS" (gammaSChange)="gammaS = $event"
                [gammaC]="gammaC" (gammaCChange)="gammaC = $event" [regionId]="selectedRegionId" [disabled]="submitted">
            </app-add-edit-design-inputs-safety-factors>
        </div>
    </div>

    <div class="section concrete-material" [class.no-margin]="collapseRegion.ConcreteMaterial">
        <p class="title-strike title" [class.no-margin]="collapseRegion.ConcreteMaterial">
            <span l10n="SP.AddEditDesign.ConcreteMaterial"></span>

            <pe-collapse class="section-collapse" [id]="'section-concrete-material'"
                [container]="'section-concrete-material'" [collapsed]="collapseRegion.ConcreteMaterial"
                (collapsedChange)="collapseRegion.ConcreteMaterial = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-concrete-material">
            <app-add-edit-design-inputs-concrete-material [alphaCC]="alphaCC" (alphaCCChange)="alphaCC = $event"
                [regionId]="selectedRegionId"
                [etaT]="etaT" (etaTChange)="etaT = $event"
                [disabled]="submitted" [designStandardId]="getDesignStandard()" [etaTValues]="getEtaTValues()">
            </app-add-edit-design-inputs-concrete-material>
        </div>
    </div>

    <div class="section steel-material" [class.no-margin]="collapseRegion.SteelMaterial">
        <p class="title-strike title" [class.no-margin]="collapseRegion.SteelMaterial">
            <span l10n="SP.AddEditDesign.SteelMaterial"></span>

            <pe-collapse class="section-collapse" [id]="'section-steel-material'" [container]="'section-steel-material'"
                [collapsed]="collapseRegion.SteelMaterial"
                (collapsedChange)="collapseRegion.SteelMaterial = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-steel-material">
            <app-add-edit-design-inputs-steel-material [e]="e" (eChange)="e = $event"
                [eUnit]="stressDropdown.selectedValue!" [regionId]="selectedRegionId" [designStandardId]="getDesignStandard()" [disabled]="submitted">
            </app-add-edit-design-inputs-steel-material>
        </div>
    </div>

    @if (isSIADesignStandard(getDesignStandard())) {
        <div class="section interface-shear-calculation-parameters" [class.no-margin]="collapseRegion.InterfaceShearCalculation">
            <p class="title-strike title" [class.no-margin]="collapseRegion.InterfaceShearCalculation">
                <span l10n="SP.AddEditDesign.InterfaceShearCalculation"></span>

                <pe-collapse class="section-collapse" [id]="'section-interface-shear-calculation'"
                    [container]="'section-interface-shear-calculation'"
                    [collapsed]="collapseRegion.InterfaceShearCalculation"
                    (collapsedChange)="collapseRegion.InterfaceShearCalculation = $any($event).detail">
                </pe-collapse>
            </p>
            <div class="content-group section-interface-shear-calculation">
                <app-add-edit-design-inputs-interface-shear-calculation [Kc]="Kc" (KcChange)="Kc = $event" [regionId]="selectedRegionId"
                    [disabled]="submitted" [designStandardId]="getDesignStandard()">
                </app-add-edit-design-inputs-interface-shear-calculation>
            </div>
        </div>
    }
}
