import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { DataService } from '../../services/data.service';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

type NumericTextBoxPropsBound = Pick<NumericTextBoxProps, 'id' | 'title' | 'placeholder' | 'unit' | 'minValue' | 'maxValue' | 'tooltip' | 'precision'>;

type ControlPropertyId = 'gammaS' | 'gammaC';

@Component({
    selector: 'app-add-edit-design-inputs-safety-factors',
    templateUrl: './add-edit-design-inputs-safety-factors.component.html',
    styleUrls: ['./add-edit-design-inputs-safety-factors.component.scss'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AddEditDesignInputsSafetyFactorsComponent implements OnInit, OnChanges {
    @Input()
    public gammaS: number | null = null;

    @Output()
    public gammaSChange = new EventEmitter<number | null>();

    @Input()
    public gammaC: number | null = null;

    @Output()
    public gammaCChange = new EventEmitter<number | null>();

    @Input()
    public disabled!: boolean;

    @Input()
    public regionId!: number;

    public gammaSTextBox!: NumericTextBoxPropsBound;
    public gammaCTextBox!: NumericTextBoxPropsBound;

    constructor(
        private localizationService: LocalizationService,
        private dataService: DataService
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['regionId'] != null && !changes['regionId'].firstChange) {
            this.onRegionChange();
        }
    }

    public ngOnInit(): void {
        this.initControls();
        this.onRegionChange();
    }

    private updateControl(control: NumericTextBoxPropsBound, appPropertyId: ControlPropertyId) {
        const regionId = this.regionId;
        const propertyValue = this.dataService.getPropertyDetail(appPropertyId, { regionId });

        control.maxValue = propertyValue.maxValue;
        control.minValue = propertyValue.minValue;
        control.placeholder = propertyValue.defaultValue ?? this.localizationService.getString('SP.Placeholder.Default');
        control.precision = propertyValue.precision;
    }

    private onRegionChange() {
        this.updateControl(this.gammaSTextBox, 'gammaS');
        this.updateControl(this.gammaCTextBox, 'gammaC');
    }

    private initControls() {
        this.gammaSTextBox = {
            id: 'safety-factors-gammaS',
            title: this.localizationService.getString('SP.SafetyFactors.GammaS'),
            tooltip: {
                title: this.localizationService.getString('SP.SafetyFactors.GammaS'),
                content: this.localizationService.getString('SP.SafetyFactors.GammaS.Tooltip')
            },
            unit: UnitType.None
        };

        this.gammaCTextBox = {
            id: 'safety-factors-gammaC',
            title: this.localizationService.getString('SP.SafetyFactors.GammaC'),
            tooltip: {
                title: this.localizationService.getString('SP.SafetyFactors.GammaC'),
                content: this.localizationService.getString('SP.SafetyFactors.GammaC.Tooltip')
            },
            unit: UnitType.None
        };
    }
}
