import kebabCase from 'lodash-es/kebabCase';
import upperFirst from 'lodash-es/upperFirst';

import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    CommonRegion, ICommonRegionConstructor
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    Separator as SeparatorEntity
} from '@profis-engineering/pe-ui-common/entities/code-lists/separator';
import {
    GeneralValue, UserSettings
} from '@profis-engineering/pe-ui-common/entities/user-settings';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    AppSettingsNumericTextBoxProps, getSpecialRegionName, QuickStartType, SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import {
    getNumberDecimalSeparator, getNumberGroupSeparator
} from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { sortByUnicode } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    CommonCodeList, CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import { NumberServiceBase } from '@profis-engineering/pe-ui-common/services/number.common';
import {
    UnitServiceBase as UnitService
} from '@profis-engineering/pe-ui-common/services/unit.common';
import {
    UserSettingsServiceBase
} from '@profis-engineering/pe-ui-common/services/user-settings.common';
import { IAdvancedCalculationDefaultValues } from '../entities/advanced-calculation-inputs';
import { ConcreteSafetyFactorGammaC } from '../entities/code-lists/concrete-safety-factor-gamma-C';
import { DesignMethodGroup } from '../entities/code-lists/design-method-group';
import { DesignStandard } from '../entities/code-lists/design-standard';
import { DesignType } from '../entities/code-lists/design-type';
import { IRegionConstructor, Region } from '../entities/code-lists/region';
import { UserSettingsPe } from '../entities/user-settings';
import { ProjectCodeList } from '../enums/project-code-list';
import {
    DesignMethodGroup as DesignMethodGroupEnum, DesignStandard as DesignStandardEnum,
    DesignType as DesignTypeEnum, SteelGuideline as SteelCalculationMethod
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    getAllowedDesignStandardsByDesignType, isHnaBasedDesignStandard
} from '../helpers/design-standard-helper';
import { getDesignTypeSpecificKey } from '../helpers/localization-helper';
import { findSteelCalculationMethod } from '../helpers/steel-guideline-helper';
import { IPropertyMetaData, PropertyMetaData } from '../properties/properties';
import { CodeListServiceBase } from '../services/code-list.service.base';

export const reductionFactorGP = 0.6;
export const reductionFactorSP = 0.8;
export const stoSteelSafetyFactorGammaM = 1.05;

export enum FactorType {
    DefaultAnchorPlateFactor = 100,
    DefaultSafetyFactorPermanentLoads = 200,
    DefaultSafetyFactorVariableLoads = 300,

    AnchorPlateFactor = 101,
    SafetyFactorPermanentLoads = 201,
    SafetyFactorVariableLoads = 301
}

// Data classes
export interface IConcreteFixingData {
    concreteRegion: number;

    concreteLength?: number;
    concreteArea?: number;
    concreteStress?: number;
    concreteForce?: number;
    concreteMoment?: number;
    concreteTemperature?: number;
    concreteForcePerLength?: number;
    concreteMomentPerLength?: number;
    concreteDensity?: number;
    concreteDesignStandardId?: number;
    concreteDesignMethodGroupId?: number;
    concreteAnchorPlateFactor?: number;
    concreteSafetyFactorForPermanentLoads?: number;
    concreteSafetyFactorForVariableLoads?: number;
    concreteEdgeDistanceDisplay?: number;
    concreteEtagEnOnly: boolean;
    concreteSteelCalculationMethod?: SteelCalculationMethod;
    concreteMinimumAnchorToProfileDistance?: number;
    concreteSteelSafetyFactorGammaM0?: number;
    concreteSteelSafetyFactorGammaM1?: number;
    concreteSteelSafetyFactorGammaM2?: number;
    concreteMaterialSafetyFactor?: number;
    concreteConcreteCapacityFactor?: number;
    concreteSteelCapacityFactor?: number;
    concreteWeldsCapacityFactor?: number;
    concreteMinimumConcreteCover?: number;
    concreteInSteelSafetyFactorGammaM0?: number;
    concreteInSteelSafetyFactorGammaM1?: number;
    concreteInSteelSafetyFactorGammaMw?: number;
    concreteInSteelSafetyFactorGammaMb?: number;
    concreteSafetyFactorGammaCId?: number;
    concreteBaseMaterialEdgeFrom?: number;
    concreteAnchorPlateFrom?: number;
}

export interface IHandrailFixingData {
    handrailRegion: number;

    handrailLength?: number;
    handrailLengthLarge?: number;
    handrailArea?: number;
    handrailStress?: number;
    handrailStressSmall?: number;
    handrailForce?: number;
    handrailMoment?: number;
    handrailTemperature?: number;
    handrailForcePerLength?: number;
    handrailDesignStandardId?: number;
    handrailDesignMethodGroupId?: number;
    handrailAnchorPlateFactor?: number;
    handrailEdgeDistanceDisplay?: number;
    handrailEtagEnOnly?: boolean;
    handrailMinimumAnchorToProfileDistance?: number;
    handrailSteelSafetyFactorGammaM0?: number;
    handrailSteelSafetyFactorGammaM1?: number;
    handrailSteelSafetyFactorGammaM2?: number;
    handrailDisplacementLimit?: number;
    handrailDisplacementLimitBridges?: number;
}

export interface IMasonryFixingData {
    masonryRegion: number;

    masonryLength?: number;
    masonryArea?: number;
    masonryStress?: number;
    masonryForce?: number;
    masonryMoment?: number;
    masonryTemperature?: number;
    masonryDesignStandardId?: number;
    masonryDesignMethodGroupId?: number;
    masonryTechnicalDataEtaData?: boolean;
    masonryTechnicalDataOSTInput?: boolean;
    masonryAnchorPlateFactor?: number;
    masonrySafetyFactorForPermanentLoads?: number;
    masonrySafetyFactorForVariableLoads?: number;
    masonryEdgeDistanceDisplay?: number;
    masonryMinimumAnchorToProfileDistance?: number;
}

export interface IMetalDeckFixing {
    metalDeckRegion: number;

    metalDeckLength?: number;
    metalDeckArea?: number;
    metalDeckStress?: number;
    metalDeckForce?: number;
    metalDeckMoment?: number;
    metalDeckTemperature?: number;
    metalDeckForcePerLength?: number;
    metalDeckMomentPerLength?: number;
    metalDeckDensity?: number;
    metalDeckDesignStandardId?: number;
    metalDeckDesignMethodGroupId?: number;
    metalDeckAnchorPlateFactor?: number;
    metalDeckSteelCalculationMethod?: SteelCalculationMethod;
    metalDeckMinimumAnchorToProfileDistance?: number;
    metalDeckMaterialSafetyFactor?: number;
}

export class AppSettingsHelper {
    constructor(
        protected localization: LocalizationServiceBase,
        protected userSettings: UserSettingsServiceBase<UserSettings>,
        protected codeList: CodeListServiceBase,
        protected commonCodeList: CommonCodeListServiceBase,
        protected numberService: NumberServiceBase,
        protected useDevFeatures: boolean
    ) { }

    // Properties
    // Design type
    public get designTypeConcrete() {
        return this.getDesignType(DesignTypeEnum.Concrete);
    }

    public get designTypeMasonry() {
        return this.getDesignType(DesignTypeEnum.Masonry);
    }

    public get designTypeHandrail() {
        return this.getDesignType(DesignTypeEnum.Handrail);
    }

    public get designTypeMetalDeck() {
        return this.getDesignType(DesignTypeEnum.MetalDeck);
    }

    // Methods
    // Region related
    public getAvailableRegions(designType?: DesignType) {
        // none and default regions
        const specialRegions = this.getSpecialRegions();
        const allRegions = this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[];
        const availableRegions = allRegions.filter((region) => designType?.allowedDesignStandardRegions?.some((row) => row.RegionId == region.id));

        // sort
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        const availableRegionsSorted = sortByUnicode(
            availableRegions.map(
                (region) => {
                    return {
                        region,
                        text: region.getTranslatedNameText(codeListDeps) ?? ''
                    };
                }
            ),
            'text'
        ).map(
            (o) => {
                return o.region;
            }
        );

        return [...specialRegions, ...availableRegionsSorted];
    }

    public getRegionById(regionId: number) {
        if (regionId < 0) {
            return this.getDefaultRegion(regionId);
        }

        const regionCodeList = this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[];
        return regionCodeList.find(r => r.id == regionId) ?? this.getDefaultRegion(regionId);
    }

    public getDefaultRegion(regionId: number) {
        return new Region({
            id: regionId,
            buildingCategoryUrl: '',
            windZoneImage: '',
            ACIDesignMethodGroup: 6,
            anchorPlateFactor: 1,
            safetyFactorForPermamentLoads: 1,
            safetyFactorForVariableLoads: 1,
            thickness: 4,
            isWeldConfigAllowed: false,
            isStressCheckAllowed: false,
            isDisplacementLimitAllowed: false,
            defaultHandrailDisplacement: 0,
            allowAdvancedBaseplate: false,
            isBridgesDisplacementLimitAllowed: false,
            defaultEurocodeDesignStandardSteelGuideLine: 0,
            allowAdvancedBaseplateMetalDeck: false,
            includeParallelDesignInReport: false,
            defaultSteelGuideline: 0,
            isSmartAnchorAvailable: false,
            allowAdvancedBaseplateHandrail: false,
            isDG1ExtensionAllowed: false,
            defaultDesignMethodGroupForRussianDesignStandard: 27,
            defaultMethodGroupForAustralianDesignStandard: 18,
            defaultMethodGroupForHongKongDesignStandard: 22,
            defaultDesignMethodGroupForCsaDesignStandard: 33,
            fireLearnMoreUrl: '',
            commonRegion: new CommonRegion({
                id: regionId,
                countryCode: getSpecialRegionName(regionId),
                supportPhone: '',
                supportHours: '',
                worldAreaId: 1,
                hiltiOnlineUrl: '',
                onlineTenderTextsUrl: '',
                hubId: 1,
                hiltiDataConsentUrl: '',
                bpRigidityCheckUrl: '',
                dlubalEnabled: false
            } as ICommonRegionConstructor)
        } as IRegionConstructor);
    }

    public getSpecialRegions() {
        const specialRegions: any[] = [];

        for (let i = 0; i < 2; i++) {
            specialRegions.push({
                id: -(i + 1),
                nameResourceKey: 'Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.' + getSpecialRegionName(-(i + 1))
            });
        }

        return specialRegions;
    }


    // Controls
    public createNumericTextBox(id: string, translationKey: string, value?: number, minValue?: number, maxValue?: number, disabled?: boolean) {
        const numericTextBox: AppSettingsNumericTextBoxProps = {
            id: `application-settings-${id}`,
            title: this.localization.getString(`Agito.Hilti.Profis3.ApplicationSettings.${translationKey}`),
            value,
            minValue,
            maxValue,
            disabled
        };

        return numericTextBox;
    }

    public createDropdownComponent<TValue>(id: string, translationKey: string, items?: DropdownItem<TValue>[], selectedValue?: TValue) {
        const dropdown: DropdownProps<TValue> = {
            id: `application-settings-${id}`,
            title: translationKey ? this.localization.getString(`Agito.Hilti.Profis3.ApplicationSettings.${translationKey}`) : undefined,
            items,
            selectedValue
        };

        return dropdown;
    }

    public createPeCodeListDropdown(codeList: ProjectCodeList, key: string) {
        const items = this.codeList.projectCodeLists[codeList].map(item => ({
            value: item.id,
            text: item.nameResourceKey ? this.localization.getString(item.nameResourceKey) : ''
        }) as DropdownItem<number>);

        return this.createDropdownComponent<number>(
            `${kebabCase(key)}-dropdown`,
            key,
            items
        );
    }

    public createCommonCodeListDropdown(codeList: CommonCodeList, key: string) {
        const items = this.commonCodeList.commonCodeLists[codeList].map(item => ({
            value: item.id,
            text: item.nameResourceKey ? this.localization.getString(item.nameResourceKey) : ''
        }) as DropdownItem<number>);

        return this.createDropdownComponent<number>(
            `${kebabCase(key)}-dropdown`,
            key,
            items
        );
    }

    public createAnchorPlateFactorComponent(id: string, translationKey: string) {
        return this.createZeroMinTwoMaxNumericTextBox(id, translationKey);
    }

    public createVariableLoadsComponent(id: string, translationKey: string) {
        return this.createZeroMinTwoMaxNumericTextBox(id, translationKey);
    }

    public createPermanentLoadsComponent(id: string, translationKey: string) {
        return this.createZeroMinTwoMaxNumericTextBox(id, translationKey);
    }

    public createGammaComponent(id: QuickStartType, key: string) {
        return this.createNumericTextBox(
            `steel-safety-factor-${id}-${key}`,
            `SteelSafetyFactorGamma${key}`,
            undefined,
            1,
            1.5
        );
    }

    public createMinimumAnchorToProfileDistanceComponent(id: string) {
        return this.createNumericTextBox(
            `minimum-anchor-to-profile-distance${id}`,
            'MinimumAnchorToProfileDistance',
            undefined,
            0,
            10000
        );
    }

    public createSteelCalculationComponent(id: QuickStartType, key: string, propertyMetaData: IPropertyMetaData) {
        return this.createNumericTextBox(
            `steel-calculation-${id}-${key}`,
            upperFirst(key),
            undefined,
            propertyMetaData.minValue,
            propertyMetaData.maxValue
        );
    }

    public createStoSteelSafetyFactorGammaMComponent(id: string) {
        return this.createNumericTextBox(
            id,
            'StoSteelSafetyFactorGammaM',
            stoSteelSafetyFactorGammaM,
            undefined,
            undefined,
            true
        );
    }

    public createInGammaComponent(id: QuickStartType, key: string) {
        return this.createNumericTextBox(
            `in-steel-safety-factor-${id}-${key}`,
            `InSteelSafetyFactorGamma${key}`,
            undefined,
            1,
            1.5
        );
    }


    // Design standard and method
    public getDesignStandardsByDesignType(designType: DesignType | undefined, designTypeRegionId: number, defaultRegionId?: number) {
        const allDesignStandards = this.codeList.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[];

        return getAllowedDesignStandardsByDesignType(allDesignStandards, designType, designTypeRegionId, defaultRegionId ?? SpecialRegion.Default);
    }

    public getDesignStandardById(designStandardId?: number) {
        const designStandards = this.codeList.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[];

        return designStandards.find((designStandard) => designStandard.id == designStandardId);
    }

    public getDesignStandardTitle(localization: LocalizationServiceBase, designTypeId: DesignTypeEnum, designStandard: DesignStandard) {
        if (!designStandard?.nameResourceKey) {
            return undefined;
        }

        return this.localization.getString(getDesignTypeSpecificKey(localization, designStandard.nameResourceKey, designTypeId));
    }

    public getDesignStandardDescription(localization: LocalizationServiceBase, designTypeId: DesignTypeEnum, designStandard?: DesignStandard) {
        if (!designStandard?.descriptionResourceKey) {
            return undefined;
        }

        return getDesignTypeSpecificKey(localization, designStandard.descriptionResourceKey, designTypeId);
    }


    public getDesignMethodGroupsByDesignType(designType: DesignType | undefined, designStandardId: number | undefined, designTypeRegionId: number, defaultRegionId?: number, etagEnOnlyChecked = false) {
        const regionId = designTypeRegionId < 0
            ? defaultRegionId
            : designTypeRegionId;
        const region = this.getRegionById(regionId ?? 0);

        if (region == null || designStandardId == undefined) {
            return [] as DesignMethodGroup[];
        }

        let designMethodGroups = (this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[])
            .filter((designMethodGroup) => designMethodGroup.designStandards?.includes(designStandardId));

        // filter design method groups by design type and region
        designMethodGroups = designMethodGroups.filter((designMethodGroup) => designType?.allowedDesignMethodGroupRegions?.some((row) => row.RegionId == regionId && row.DesignMethodGroups.includes(designMethodGroup.id ?? -1)));

        // In case of ETAG/EN guideline only, SOFA_Based should not be available
        if (etagEnOnlyChecked) {
            designMethodGroups = designMethodGroups.filter((designMethodGroup) => designMethodGroup.id != DesignMethodGroupEnum.SOFA_Based);
        }

        // We use ETAG029 just in background for old bricks and doesn't allow to create new designs with it
        designMethodGroups = designMethodGroups.filter((designMethodGroup) => designMethodGroup.id != DesignMethodGroupEnum.ETAG029);

        return designMethodGroups;
    }

    public getDesignMethodGroupById(designMethodGroupId?: number) {
        const designMethodGroups = this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[];

        return designMethodGroups.find((designMethodGroup) => designMethodGroup.id == designMethodGroupId);
    }

    public getDesignMethodGroupTitle(designMethodGroup: DesignMethodGroup, designStandardId?: number) {
        if (!designMethodGroup?.nameResourceKey) {
            return undefined;
        }

        if(designStandardId && designMethodGroup.designStandardDisplayKeyExtensions && designMethodGroup.designStandardDisplayKeyExtensions[designStandardId])
        {
            const displayKey = `Agito.Hilti.Profis3.CodeList.DesignMethodGroupEntity.${designMethodGroup.designStandardDisplayKeyExtensions[designStandardId]}`;
            return this.localization.getString(displayKey);
        }

        return this.localization.getString(designMethodGroup.nameResourceKey);
    }

    public getDesignMethodDescription(designMethodGroup?: DesignMethodGroup, designStandardId?: number) {
        if (!designMethodGroup) {
            return undefined;
        }

        if (designStandardId && designMethodGroup.designStandardDisplayKeyExtensions && designMethodGroup.designStandardDisplayKeyExtensions[designStandardId]) {
            const displayKey = `Agito.Hilti.Profis3.CodeList.DesignMethodGroupEntity.${designMethodGroup.designStandardDisplayKeyExtensions[designStandardId]}.Description`;
            return displayKey;
        }

        return designMethodGroup.descriptionResourceKey;
    }

    // Defaults
    // Concrete design
    public setDefaultConcreteUnits(concreteFixing: IConcreteFixingData, region: Region) {
        concreteFixing.concreteLength = region.defaultUnitLength;
        concreteFixing.concreteArea = region.defaultUnitArea;
        concreteFixing.concreteStress = region.defaultUnitStress;
        concreteFixing.concreteForce = region.defaultUnitForce;
        concreteFixing.concreteMoment = region.defaultUnitMoment;
        concreteFixing.concreteTemperature = region.defaultUnitTemperature;
        concreteFixing.concreteForcePerLength = region.defaultUnitForcePerLength;
        concreteFixing.concreteMomentPerLength = region.defaultUnitMomentPerLength;
        concreteFixing.concreteDensity = region.defaultUnitDensity;
    }

    public setDefaultDimensioningStartPreference(concreteFixing: IConcreteFixingData, region: Region) {
        concreteFixing.concreteBaseMaterialEdgeFrom = region.defaultMeasureBaseMaterialEdgeFrom;
        concreteFixing.concreteAnchorPlateFrom = region.defaultMeasureAnchorPlate;
    }

    public setDefaultConcreteDesignStandard(concreteFixing: IConcreteFixingData, region: Region) {
        const designStandards = this.getDesignStandardsByDesignType(this.designTypeConcrete, region.id ?? SpecialRegion.Default);

        if (region.defaultConcreteDesignStandardId != null
            && designStandards.some((designStandard) => designStandard.id == region.defaultConcreteDesignStandardId)) {
            concreteFixing.concreteDesignStandardId = region.defaultConcreteDesignStandardId;
        }
        else {
            const firstDesignStandard = designStandards != null && designStandards.length > 0 ? designStandards[0] : null;
            concreteFixing.concreteDesignStandardId = firstDesignStandard != null ? firstDesignStandard.id : undefined;
        }
    }

    public setDefaultConcreteDesignMethodGroup(concreteFixing: IConcreteFixingData, region: Region) {
        const designMethodGroups = this.getDesignMethodGroupsByDesignType(this.designTypeConcrete, concreteFixing.concreteDesignStandardId, region.id ?? SpecialRegion.Default, undefined, concreteFixing.concreteEtagEnOnly);

        if (region.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard && concreteFixing.concreteDesignStandardId == DesignStandardEnum.ETAG
            && designMethodGroups.some(designMethodGroup => designMethodGroup.id == region.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard)) {
            concreteFixing.concreteDesignMethodGroupId = region.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard;
        }
        else if (region.defaultDesignMethodGroupForEurocodeDesignStandard &&
            (concreteFixing.concreteDesignStandardId == DesignStandardEnum.EC2 || concreteFixing.concreteDesignStandardId == DesignStandardEnum.CN)
            && designMethodGroups.some(designMethodGroup => designMethodGroup.id == region.defaultDesignMethodGroupForEurocodeDesignStandard)) {
            concreteFixing.concreteDesignMethodGroupId = region.defaultDesignMethodGroupForEurocodeDesignStandard;
        }
        else if (region.defaultDesignMethodGroupForNZDesignStandard && concreteFixing.concreteDesignStandardId == DesignStandardEnum.NZ
            && designMethodGroups.some(designMethodGroup => designMethodGroup.id == region.defaultDesignMethodGroupForNZDesignStandard)) {
            concreteFixing.concreteDesignMethodGroupId = region.defaultDesignMethodGroupForNZDesignStandard;
        }
        else if (concreteFixing.concreteDesignStandardId == DesignStandardEnum.ACI) {
            concreteFixing.concreteDesignMethodGroupId = region.ACIDesignMethodGroup;
        }
        else if (concreteFixing.concreteDesignStandardId == DesignStandardEnum.STO) {
            concreteFixing.concreteDesignMethodGroupId = region.defaultDesignMethodGroupForRussianDesignStandard;
        }
        else if (concreteFixing.concreteDesignStandardId == DesignStandardEnum.HK) {
            concreteFixing.concreteDesignMethodGroupId = region.defaultMethodGroupForHongKongDesignStandard;
        }
        else if (concreteFixing.concreteDesignStandardId == DesignStandardEnum.CSA) {
            concreteFixing.concreteDesignMethodGroupId = region.defaultDesignMethodGroupForCsaDesignStandard;
        }
        else if (concreteFixing.concreteDesignStandardId == DesignStandardEnum.SATS) {
            concreteFixing.concreteDesignMethodGroupId = region.defaultMethodGroupForAustralianDesignStandard;
        }
        else if(concreteFixing.concreteDesignStandardId == DesignStandardEnum.IS) {
            concreteFixing.concreteDesignMethodGroupId = region.defaultDesignMethodGroupForISDesignStandard;
        }
        else if(concreteFixing.concreteDesignStandardId == DesignStandardEnum.MS) {
            concreteFixing.concreteDesignMethodGroupId = region.defaultDesignMethodGroupForMSDesignStandard;
        }
        else {
            concreteFixing.concreteDesignMethodGroupId = designMethodGroups[0]?.id;
        }
    }

    public setDefaultConcreteSteelCalculationMethod(concreteFixing: IConcreteFixingData, region: Region) {
        concreteFixing.concreteSteelCalculationMethod = findSteelCalculationMethod(this.codeList.projectCodeLists, concreteFixing.concreteDesignStandardId, region);
    }

    // Masonry design
    public setDefaultMasonryUnits(masonryFixing: IMasonryFixingData, region: Region) {
        masonryFixing.masonryLength = region.defaultUnitLength;
        masonryFixing.masonryArea = region.defaultUnitArea;
        masonryFixing.masonryStress = region.defaultUnitStress;
        masonryFixing.masonryForce = region.defaultUnitForce;
        masonryFixing.masonryMoment = region.defaultUnitMoment;
        masonryFixing.masonryTemperature = region.defaultUnitTemperature;
    }

    public setDefaultMasonryDesignStandard(masonryFixing: IMasonryFixingData, region: Region) {
        const designStandards = this.getDesignStandardsByDesignType(this.designTypeMasonry, region.id ?? SpecialRegion.Default);

        if (region.defaultMasonryDesignStandardId != null
            && designStandards.some((designStandard) => designStandard.id == region.defaultMasonryDesignStandardId)) {
            masonryFixing.masonryDesignStandardId = region.defaultMasonryDesignStandardId;
        }
        else {
            const firstDesignStandard = designStandards != null && designStandards.length > 0 ? designStandards[0] : null;
            masonryFixing.masonryDesignStandardId = firstDesignStandard != null ? firstDesignStandard.id : undefined;
        }
    }

    public setDefaultMasonryDesignMethodGroup(masonryFixing: IMasonryFixingData, region: Region) {
        const designMethodGroups = this.getDesignMethodGroupsByDesignType(this.designTypeMasonry, masonryFixing.masonryDesignStandardId, region.id ?? SpecialRegion.Default);

        const firstDesignMethodGroup = designMethodGroups != null && designMethodGroups.length > 0
            ? designMethodGroups[0]
            : null;

        masonryFixing.masonryDesignMethodGroupId = firstDesignMethodGroup != null ?
            firstDesignMethodGroup.id :
            undefined;
    }

    // Handrail design
    public setDefaultHandrailUnits(handrailFixing: IHandrailFixingData, region: Region) {
        handrailFixing.handrailLengthLarge = region.defaultUnitLengthLarge;
        handrailFixing.handrailLength = region.defaultUnitLength;
        handrailFixing.handrailArea = region.defaultUnitArea;
        handrailFixing.handrailStress = region.defaultUnitStress;
        handrailFixing.handrailStressSmall = region.defaultUnitStressSmall;
        handrailFixing.handrailForce = region.defaultUnitForce;
        handrailFixing.handrailMoment = region.defaultUnitMoment;
        handrailFixing.handrailTemperature = region.defaultUnitTemperature;
        handrailFixing.handrailForcePerLength = region.defaultUnitForcePerLength;
    }

    public setDefaultHandrailDesignStandard(handrailFixing: IHandrailFixingData, region: Region) {
        const designStandards = this.getDesignStandardsByDesignType(this.designTypeHandrail, region.id ?? SpecialRegion.Default);

        if (region.defaultHandrailDesignStandardId != null
            && designStandards.some((designStandard) => designStandard.id == region.defaultHandrailDesignStandardId)) {
            handrailFixing.handrailDesignStandardId = region.defaultHandrailDesignStandardId;
        }
        else {
            const firstDesignStandard = designStandards != null && designStandards.length > 0 ? designStandards[0] : null;
            handrailFixing.handrailDesignStandardId = firstDesignStandard != null ? firstDesignStandard.id : undefined;
        }
    }

    public setDefaultHandrailDesignMethodGroup(handrailFixing: IHandrailFixingData, region: Region) {
        const designMethodGroups = this.getDesignMethodGroupsByDesignType(this.designTypeHandrail, handrailFixing.handrailDesignStandardId, region.id ?? SpecialRegion.Default, undefined, handrailFixing.handrailEtagEnOnly);

        if (region.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard != null && handrailFixing.handrailDesignStandardId == DesignStandardEnum.ETAG
            && designMethodGroups.some(designMethodGroup => designMethodGroup.id == region.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard)) {
            handrailFixing.handrailDesignMethodGroupId = region.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard;
        }
        else if (region.defaultDesignMethodGroupForEurocodeDesignStandard != null && handrailFixing.handrailDesignStandardId == DesignStandardEnum.EC2
            && designMethodGroups.some(designMethodGroup => designMethodGroup.id == region.defaultDesignMethodGroupForEurocodeDesignStandard)) {
            handrailFixing.handrailDesignMethodGroupId = region.defaultDesignMethodGroupForEurocodeDesignStandard;
        }
        else if (handrailFixing.handrailDesignStandardId == DesignStandardEnum.ACI) {
            handrailFixing.handrailDesignMethodGroupId = region.ACIDesignMethodGroup;
        }
        else {
            const firstDesignMethodGroup = designMethodGroups != null && designMethodGroups.length > 0 ? designMethodGroups[0] : null;
            handrailFixing.handrailDesignMethodGroupId = firstDesignMethodGroup != null ? firstDesignMethodGroup.id : undefined;
        }
    }

    // Metal deck design
    public setDefaultMetalDeckUnits(metalDeckFixing: IMetalDeckFixing, region: Region) {
        metalDeckFixing.metalDeckLength = region.defaultUnitLength;
        metalDeckFixing.metalDeckArea = region.defaultUnitArea;
        metalDeckFixing.metalDeckStress = region.defaultUnitStress;
        metalDeckFixing.metalDeckForce = region.defaultUnitForce;
        metalDeckFixing.metalDeckMoment = region.defaultUnitMoment;
        metalDeckFixing.metalDeckTemperature = region.defaultUnitTemperature;
        metalDeckFixing.metalDeckForcePerLength = region.defaultUnitForcePerLength;
        metalDeckFixing.metalDeckMomentPerLength = region.defaultUnitMomentPerLength;
        metalDeckFixing.metalDeckDensity = region.defaultUnitDensity;
    }

    public setDefaultMetalDeckDesignStandard(metalDeckFixing: IMetalDeckFixing, region: Region) {
        const designStandards = this.getDesignStandardsByDesignType(this.designTypeMetalDeck, region.id ?? SpecialRegion.Default);

        if (region.defaultMetalDeckDesignStandardId != null
            && designStandards.some((designStandard) => designStandard.id == region.defaultMetalDeckDesignStandardId)) {
            metalDeckFixing.metalDeckDesignStandardId = region.defaultMetalDeckDesignStandardId;
        }
        else {
            const firstDesignStandard = designStandards != null && designStandards.length > 0 ? designStandards[0] : null;
            metalDeckFixing.metalDeckDesignStandardId = firstDesignStandard != null ? firstDesignStandard.id : undefined;
        }
    }

    public setDefaultMetalDeckDesignMethodGroup(metalDeckFixing: IMetalDeckFixing, region: Region) {
        const designMethodGroups = this.getDesignMethodGroupsByDesignType(this.designTypeMetalDeck, metalDeckFixing.metalDeckDesignStandardId, region.id ?? SpecialRegion.Default);

        if (metalDeckFixing.metalDeckDesignStandardId == DesignStandardEnum.ACI) {
            metalDeckFixing.metalDeckDesignMethodGroupId = region.ACIDesignMethodGroup;
        }
        else {
            const firstDesignMethodGroup = designMethodGroups != null && designMethodGroups.length > 0
                ? designMethodGroups[0]
                : null;
            metalDeckFixing.metalDeckDesignMethodGroupId = firstDesignMethodGroup != null
                ? firstDesignMethodGroup.id
                : undefined;
        }
    }

    public setDefaultMetalDeckSteelCalculationMethod(metalDeckFixing: IMetalDeckFixing, region: Region) {
        metalDeckFixing.metalDeckSteelCalculationMethod = findSteelCalculationMethod(this.codeList.projectCodeLists, metalDeckFixing.metalDeckDesignStandardId, region);
    }


    // Helpers
    public getLanguageById(id: number) {
        const languageCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.Language] as Language[];
        return languageCodeList.find((r) => r.id == id);
    }


    public updateSetting(setting: GeneralValue<number | null>, value: number | undefined) {
        setting.value = value ?? null;
    }


    public isConcreteDesignStandardEnBased(concreteSteelCalculationMethodId: number | undefined) {
        return concreteSteelCalculationMethodId == SteelCalculationMethod.EU;
    }

    public isConcreteDesignStandardAsBased(concreteSteelCalculationMethodId: number | undefined) {
        return concreteSteelCalculationMethodId == SteelCalculationMethod.AU;
    }

    public isConcreteDesignStandardAiscBased(concreteSteelCalculationMethodId: number | undefined) {
        return concreteSteelCalculationMethodId == SteelCalculationMethod.ACI ||
            concreteSteelCalculationMethodId == SteelCalculationMethod.KR ||
            concreteSteelCalculationMethodId == SteelCalculationMethod.TW;
    }

    public isConcreteDesignStandardStoBased(concreteSteelCalculationMethodId: number | undefined) {
        return concreteSteelCalculationMethodId == SteelCalculationMethod.STO;
    }

    public isConcreteDesignStandardInBased(concreteSteelCalculationMethodId: number | undefined) {
        return concreteSteelCalculationMethodId == SteelCalculationMethod.IN;
    }

    public isMinimumAnchorToProfileDistanceAvailable(designStandard?: DesignStandardEnum) {
        return designStandard != DesignStandardEnum.STO && !isHnaBasedDesignStandard(designStandard);
    }

    public defaultAdvancedCalculationDefaultValues(regionId: number) {
        const advancedCalculationDefVal: IAdvancedCalculationDefaultValues = {};

        const region = (this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[]).find((row) => row.id == regionId);
        advancedCalculationDefVal.alphaCC = region?.defaultAlphaCC;
        advancedCalculationDefVal.concreteInCompressionMethod = region?.defaultConcreteInCompressionMethod;

        return advancedCalculationDefVal;
    }

    public getConcreteSafetyFactorGammaCEntity(value?: number) {
        if (value == null || value == undefined) {
            // Return default
            value = PropertyMetaData.Option_ConcreteSafetyFactorGammaC.defaultValue;
        }

        const codeList = this.codeList.projectCodeLists[ProjectCodeList.ConcreteSafetyFactorGammaC] as ConcreteSafetyFactorGammaC[];
        return codeList.filter((item) => item.id == value)[0];
    }

    public getNumericTextBoxDecimalSeparatorById(decimalSeparatorId: number) {
        const decimalSeparators = this.commonCodeList.commonCodeLists[CommonCodeList.DecimalSeparator] as SeparatorEntity[];
        const decimalSeparator = decimalSeparators.find(x => x.id == decimalSeparatorId);

        return this.getNumericTextBoxDecimalSeparator(decimalSeparator);
    }

    public getNumericTextBoxDecimalSeparator(decimalSeparator: SeparatorEntity | undefined) {
        if (decimalSeparator) {
            return decimalSeparator.character;
        }

        return getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings);
    }

    public getNumericTextBoxGroupSeparatorById(groupSeparatorId: number) {
        const groupSeparators = this.commonCodeList.commonCodeLists[CommonCodeList.ThousandsSeparator] as SeparatorEntity[];
        const groupSeparator = groupSeparators.find(x => x.id == groupSeparatorId);

        return this.getNumericTextBoxGroupSeparator(groupSeparator);
    }

    public getNumericTextBoxGroupSeparator(groupSeparator: SeparatorEntity | undefined) {
        if (groupSeparator) {
            return groupSeparator.character;
        }

        return getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings);
    }

    public getMaterialSafetyFactorMinValue(regionId: number) {
        if (regionId == KnownRegion.UnitedStates || regionId == KnownRegion.Canada) {
            return 1;
        }

        return PropertyMetaData.Option_MaterialSafetyFactor.minValue;
    }

    public bindDefaultFactor(quickStartRegionId: number, defaultRegion: Region, factorType: FactorType, settings: UserSettingsPe) {
        let value = 0;

        const quickStartRegion = this.getRegionById(quickStartRegionId);
        const isQuickStartRegionDefault = quickStartRegion?.id == undefined || quickStartRegion.id < 0;

        switch (factorType) {
            // Quick start parameters
            case FactorType.AnchorPlateFactor:
                value = this.getDefaultFactor(isQuickStartRegionDefault, settings.application.defaults.anchorPlateFactor.value, defaultRegion.anchorPlateFactor, quickStartRegion?.anchorPlateFactor);
                break;

            case FactorType.SafetyFactorPermanentLoads:
                value = this.getDefaultFactor(isQuickStartRegionDefault, settings.application.defaults.permenentLoads.value, defaultRegion.safetyFactorForPermamentLoads, quickStartRegion?.safetyFactorForPermamentLoads);
                break;

            case FactorType.SafetyFactorVariableLoads:
                value = this.getDefaultFactor(isQuickStartRegionDefault, settings.application.defaults.variableLoads.value, defaultRegion.safetyFactorForVariableLoads, quickStartRegion?.safetyFactorForVariableLoads);
                break;
        }

        return value;
    }

    private getDefaultFactor(isQuickStartRegionDefault: boolean, settingsDefaultValue: number | null, regionDefaultValue: number, quickStartRegionValue?: number): number {
        // If quickstart region is selected Default or None then display the value from default user/region store
        // otherwise display the value from the selected quickstart region dropdown
        return isQuickStartRegionDefault || !quickStartRegionValue ? (settingsDefaultValue || regionDefaultValue) : quickStartRegionValue;
    }

    private getDesignType(type: DesignTypeEnum) {
        const designTypeCodeList = this.codeList.projectCodeLists[ProjectCodeList.DesignType] as DesignType[];
        return designTypeCodeList.find(designType => designType.id == type);
    }

    private createZeroMinTwoMaxNumericTextBox(id: string, translationKey: string): AppSettingsNumericTextBoxProps {
        return this.createNumericTextBox(
            id,
            translationKey,
            undefined,
            0,
            2
        );
    }
}

export class AppSettingsHelperWithUnit extends AppSettingsHelper {
    constructor(
        localization: LocalizationServiceBase,
        userSettings: UserSettingsServiceBase<UserSettings>,
        codeList: CodeListServiceBase,
        commonCodeList: CommonCodeListServiceBase,
        protected unit: UnitService,
        numberService: NumberServiceBase,
        useDevFeatures: boolean
    ) {
        super(localization, userSettings, codeList, commonCodeList, numberService, useDevFeatures);
    }

    public setSteelCalculationPlaceholder(value: number) {
        const defaultUnit = Unit.None;
        const internalUnit = this.unit.getInternalUnit(UnitGroup.None);

        const formattedValue = this.unit.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit);

        if (formattedValue == null) {
            return this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }

        return formattedValue;
    }

    public createCapacityFactorDropdownControl(id: QuickStartType, key: string) {
        const dropdown: DropdownProps<number> = {
            id: `application-settings-steel-calculation-${id}-${key}`,
            title: this.localization.getString(`Agito.Hilti.Profis3.AddEditDesign.${upperFirst(key)}`),
            items: [
                ({
                    value: reductionFactorSP,
                    text: this.unit.formatNumber(reductionFactorSP, 1)
                }) as DropdownItem<number>,
                ({
                    value: reductionFactorGP,
                    text: this.unit.formatNumber(reductionFactorGP, 1)
                }) as DropdownItem<number>
            ]
        };

        return dropdown;
    }
}
