import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { includeSprites } from '../.././sprites';
import { DeckType } from './../../entities/decking-code-list/enums/deck-type';
import { DefinitionOfSidelapConnectors } from './../../entities/decking-code-list/enums/definition-sidelap-connectors';
import { PanelType } from './../../entities/decking-code-list/enums/panel-type';
import { AreaModel } from './../../entities/decking-design/area-model';
import { DeckingDesign } from './../../entities/decking-design/decking-design';
import { IDeckingMessage } from './../../entities/decking-design/decking-message';
import { ZoneModel } from './../../entities/decking-design/zone-model';
import { CalculationState } from './../../entities/enums/calculation-state';
import { MessageType } from './../../entities/enums/message-types';
import { DesignSettings } from './../../entities/settings/design-settings';
import { DeckingDesignVerificationService } from './../../services/decking-design/calculation/verification/decking-design-verification.service';
import { DeckingDesignService } from './../../services/decking-design/decking-design.service';
import { DeckingScopeChecksService } from './../../services/decking-scope-checks/decking-scope-checks.service';
import { DeckingUserSettingsService } from './../../services/decking-user-settings/user-settings.service';
import { DeckingZonesService } from './../../services/decking-zones/decking-zones.service';
import { LocalizationService } from './../../services/external/localization.service';
import { UnitService } from './../../services/external/unit.service';
import { BaseDeckingMainRightComponent } from './base-decking-main-right-component';
import { DeckingReportGeneratorComponent } from '../decking-popup/decking-report-generator/decking-report-generator.component';
import { DeckingTrackingService } from 'src/decking/services/decking-tracking/decking-tracking.service';

@Component({
    selector: 'design-main-right',
    templateUrl: './decking-main-right.component.html',
    styleUrls: ['./decking-main-right.component.scss'],
    changeDetection:ChangeDetectionStrategy.Default
})
export class DeckingMainRightComponent extends BaseDeckingMainRightComponent implements OnInit, OnDestroy {
    public currentArea$: Observable<AreaModel>;
    public currentDeckingDesign$: Observable<DeckingDesign>;

    public currentZone$: Observable<ZoneModel>;
    public currentZoneLabel$: Observable<string>;

    private currentSettingsLengthUnit$: Observable<Unit>;
    private definitionOfSidelapConnectors$: Observable<DefinitionOfSidelapConnectors>;

    public currentDeckingDesignAreas: AreaModel[];
    public deckTypes = DeckType;
    public isTemplate: boolean;
    public currentSettings$: Observable<DesignSettings>;
    public shearStiffnessUnit$: Observable<Unit>;

    lengthUnit: Unit;
    forcePerLengthUnit: Unit;
    shearStiffnessUnit: Unit;
    lengthStringUnit: string;
    alongPanelEdge: number;
    alongPanelEnd: number;
    lengthUnitFt: Unit;
    lengthStringUnitFt: string;

    constructor(
        protected override localization: LocalizationService,
        public elementRef: ElementRef<HTMLElement>,
        private modalService: NgbModal,
        private deckingDesignService: DeckingDesignService,
        private deckingVerificationService: DeckingDesignVerificationService,
        private userSettingsService: DeckingUserSettingsService,
        private unitService: UnitService,
        private zonesService: DeckingZonesService,
        private deckingScopeChecksService: DeckingScopeChecksService,
        protected override  cdr: ChangeDetectorRef,
        private trackingService: DeckingTrackingService
    ) {
        super(localization, cdr);
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
            'sprite-info',
            'sprite-warning',
            'sprite-lines',
            'sprite-lines-expanded',
            'sprite-export-design',
            'sprite-duplicate-design',
            'sprite-openfile-d-light',
            'sprite-decking-shear',
            'sprite-decking-diaphragm-shear',
            'sprite-decking-shear-stiffness',
            'sprite-decking-tension',
            'sprite-decking-uplift',
            'sprite-decking-perimeter-spacing',
            'sprite-long-arrow-right-white',
            'sprite-decking-areas',
            'sprite-profis-file',
            'sprite-arrow-right',
            'sprite-specification-text'
        );

        this.currentSettingsLengthUnit$ = this.deckingDesignService.currentSettings$.pipe(map(settings => settings.length.id));
        this.definitionOfSidelapConnectors$ = this.deckingDesignService.currentSettings$.pipe(map(settings => settings.definitionOfSidelapConnectors.id));
        this.currentArea$ = this.deckingDesignService.currentArea$;
        this.currentDeckingDesign$ = this.deckingDesignService.currentDeckingDesign$;
        this.saving$ = this.deckingVerificationService.saving$;
        this.currentZoneLabel$ = this.deckingDesignService.currentZone$.pipe(map(currentZone => {
            return format(this.localization.getString('Agito.Hilti.Profis3.Decking.Results.ZoneDesign.Title'), currentZone?.name.value);
        }));
        this.currentZone$ = this.deckingDesignService.currentZone$;
        this.forcePerLengthUnit = this.getForcePerLengthUnit();
        this.lengthUnit = this.getLengthUnit();
        this.lengthUnitFt = Unit.ft;
        this.lengthStringUnit = this.unitService.getUnitStrings(this.lengthUnit)[0];
        this.lengthStringUnitFt = this.unitService.getUnitStrings(this.lengthUnitFt)[0];
        this.currentSettings$ = this.deckingDesignService.currentSettings$;
        this.shearStiffnessUnit$ = this.currentSettings$.pipe(map(s => s.shearStiffness.id), distinctUntilChanged());

        //Return all area Ids
        this.areas$ = this.deckingDesignService.currentAreasSummary$.pipe(map(areas => {
            return new Set(areas.map(area => area.id));
        }));
        //Add areas that contains zones with Incorrect design
        this.messages$ = combineLatest([this.areas$, this.currentArea$, this.currentDeckingDesign$, this.currentSettingsLengthUnit$, this.definitionOfSidelapConnectors$, this.currentZone$])
            .pipe(map(([designAreas, currentArea, currentDeckingDesign, currentSettingsLengthUnit, definitionOfSidelapConnectors, currentZone]) => {
                //Review if any area has been deleted and removed of incorrect areas array
                this.incorrectAreas.forEach((_areaName, areaId) => {
                    if (!designAreas.has(areaId)) {
                        this.incorrectAreas.delete(areaId);
                    }
                });

                if (this.incorrectAreas.has(currentArea.id)) {
                    this.incorrectAreas.delete(currentArea.id);
                    this.incorrectDeckTypeArea.delete(currentArea.id);
                }

                currentDeckingDesign.areas.forEach(area => {
                    if (this.zonesService.hasUnCalculatedZones(area)) {
                        this.incorrectAreas.set(area.id, area.name.value);
                    }
                });
                this.failingScopeCheckAreas.clear();
                this.incorrectDeckTypeArea.clear();
                this.isTemplate = currentDeckingDesign.isTemplate;
                currentDeckingDesign.areas.forEach(area => {
                    if (this.deckingScopeChecksService.areaHasFailingScopeChecks(area)) {
                        this.failingScopeCheckAreas.set(area.id, area.name.value);
                    }
                    if (this.hasValidDeckType(area)) {
                        this.incorrectDeckTypeArea.set(area.id, area.name.value);
                    }
                });

                this.updateAlongPanelDimensions(currentArea, currentZone);

                const scopeCheckResponses = Array.from(this.failingScopeCheckAreas).map(([_key]) => {
                    return this.deckingScopeChecksService.getAreaFailingScopeChecksMessages(currentDeckingDesign.areas.find(area => area.id === _key), currentSettingsLengthUnit, definitionOfSidelapConnectors, currentZone.frameFastener ? currentZone.frameFastener.id : 0);
                });

                let scopeCheckMessages: IDeckingMessage[] = [];

                let criticalMessages = Array.from(this.incorrectAreas).map(([_key, areaName]) => {
                    return {
                        Type: MessageType.Warning,
                        Title: this.getAreasMessage(areaName),
                        Text: this.getAreasMessage(areaName),
                        ButtonText: null,
                        ButtonClick: null,
                        disabled$: of(false)
                    } as IDeckingMessage;
                });

                const deckCheckMessages = Array.from(this.incorrectDeckTypeArea).map(([_key, areaName]) => {
                    return {
                        Type: MessageType.Warning,
                        Title: this.getAreasMessage(areaName),
                        Text: this.getDeckTypeMessage(areaName),
                        ButtonText: null,
                        ButtonClick: null,
                        disabled$: of(false)
                    } as IDeckingMessage;
                });
                scopeCheckResponses.forEach(schopeChecksResponse => {
                    scopeCheckMessages = scopeCheckMessages.concat(schopeChecksResponse.scopeCheckMessages);
                    criticalMessages = criticalMessages.concat(schopeChecksResponse.criticalMessages);
                });


                const notSolutionFoundMessage = this.AddNotFoundSolutionMessage(currentArea);


                if (notSolutionFoundMessage) {
                    return { criticalMessages: criticalMessages.concat(notSolutionFoundMessage), scopeCheckMessages: scopeCheckMessages, deckCheckMessages: deckCheckMessages };
                }
                return { criticalMessages: criticalMessages, scopeCheckMessages: scopeCheckMessages, deckCheckMessages: deckCheckMessages };
            }));

        this.disable$ = combineLatest([this.messages$.pipe(map(message => message.criticalMessages.length > 0)), this.saving$]).pipe(map(([disable, saving]) => disable || saving || this.isTemplate));

        this.savingSubscription = this.saving$.subscribe(() => {
            this.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.savingSubscription.unsubscribe();
    }

    public generateReport() {
        this.trackingService.addReportPreviewed();
        this.modalService.open(DeckingReportGeneratorComponent, {
            size: 'lg',
            backdrop: 'static',
        });
    }

    private getForcePerLengthUnit(): Unit {
        return this.userSettingsService.deckingSettings.forcePerLength.unitValue;
    }

    private getLengthUnit(): Unit {
        return this.userSettingsService.deckingSettings.length.unitValue;
    }

    private AddNotFoundSolutionMessage(area: AreaModel): any {
        const notSolutionZones = area.zones.filter(zone => zone.calculationState === CalculationState.NotSolution);
        if (notSolutionZones) {
            const notFoundMessage = notSolutionZones
                .map(zone => zone.name.value)
                .join(', ');
            if (notFoundMessage) {
                return {
                    Type: MessageType.Warning,
                    Title: '',
                    Text: format(this.localization.getString('Agito.Hilti.Profis3.Decking.Zones.NotSolutionFound'), area.name.value, notFoundMessage),
                    ButtonText: null,
                    ButtonClick: null
                };
            }
        }
    }

    private hasValidDeckType(area: AreaModel): boolean {
        let deckTypeCheck = false;
        const deckTypeSettings = {
            velcroPLP: 11,
            pattern: [70, 76, 75, 68, 67, 81],
            fastenerENP: 1
        };
        if (area.deckPanel.id === deckTypeSettings.velcroPLP && area.panelType.id === PanelType.Interlocking) {
            deckTypeCheck = area?.zones.filter(zone => deckTypeSettings.pattern.includes(zone?.pattern?.id) && zone?.frameFastener?.id === deckTypeSettings.fastenerENP).length > 0;
        }
        return deckTypeCheck;
    }

    private updateAlongPanelDimensions(area: AreaModel, zone: ZoneModel) {
        if(zone?.result?.alongPanelEdge?.value && zone?.result?.alongPanelEnd?.value) {
            const supportSpacing = area.zoneSpacing.value ? zone.s.value : area.beam.spacing.value;
            const ftSupportSpacing = this.unitService.convertUnitValueArgsToUnit(supportSpacing, Unit.mm, Unit.ft);
            const alongPanelEdgeValue = this.unitService.convertUnitValueArgsToUnit(zone.result.alongPanelEdge.value, Unit.ft, Unit.mm) / (3 * ftSupportSpacing);
            const ftAlongPanelEdgeValue = this.unitService.convertUnitValueArgsToUnit(alongPanelEdgeValue, Unit.mm, Unit.ft);
            const roundAlongPanelEdgeValue = Math.ceil(ftAlongPanelEdgeValue * 2) / 2;
            this.alongPanelEdge = this.unitService.convertUnitValueArgsToUnit(roundAlongPanelEdgeValue, Unit.ft, Unit.mm);
            this.alongPanelEnd = this.unitService.convertUnitValueArgsToUnit(Math.ceil(zone.result.alongPanelEnd.value * 2) / 2, Unit.ft, Unit.mm);
        }
    }
}
