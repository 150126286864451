<div class="concrete-material-inputs content-group">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <div class="control-concreteMaterial">
                @if (isSIADesignStandard(this.designStandardId)) {
                    <pe-dropdown [id]="etaTDropDown.id" [title]="etaTDropDown.title" [items]="etaTDropDown.items"
                        [selectedValue]="etaT" [tooltip]=""
                        (selectedValueChange)="etaTChange.emit(etaT = $any($event).detail)">
                    </pe-dropdown>
                }
                @else {
                    <pe-numeric-text-box [id]="alphaCCTextBox.id" [title]="alphaCCTextBox.title"
                        [placeholder]="alphaCCTextBox.placeholder" [value]="alphaCC"
                        (valueChange)="alphaCCChange.emit(alphaCC = $any($event).detail)" [unit]="alphaCCTextBox.unit"
                        [minValue]="alphaCCTextBox.minValue" [maxValue]="alphaCCTextBox.maxValue" [disabled]="disabled"
                        [tooltip]="alphaCCTextBox.tooltip" [precision]="alphaCCTextBox.precision">
                    </pe-numeric-text-box>
                }
            </div>
        </div>
    </div>
</div>
