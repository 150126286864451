import { BaseInput } from './base-input.js';
import { sceneExtensions } from '../babylon-extensions.js';
export class BaseHoverInput extends BaseInput {
  triggerPickingInfosChanged;
  previousPickingInfos = [];
  scene;
  constructor(eventNotifier, triggerPickingInfosChanged) {
    super(eventNotifier);
    this.triggerPickingInfosChanged = triggerPickingInfosChanged;
  }
  processHover(isCanvas, clientX, clientY, pickingInfos) {
    const previousPickingInfos = this.previousPickingInfos ?? [];
    if (isCanvas) {
      if (pickingInfos == null) {
        pickingInfos = this.pickAll(clientX, clientY);
      }
      // find picking info changes
      const pickedMeshes = [];
      for (let i = 0; i < previousPickingInfos.length; i++) {
        const previousMesh = previousPickingInfos[i].pickedMesh;
        const mesh = pickingInfos.length > i ? pickingInfos[i].pickedMesh : null;
        if (previousMesh != null && previousMesh !== mesh && !pickedMeshes.includes(previousMesh)) {
          pickedMeshes.push(previousMesh);
        }
      }
      for (let i = 0; i < pickingInfos.length; i++) {
        const mesh = pickingInfos[i].pickedMesh;
        const previousMesh = previousPickingInfos.length > i ? previousPickingInfos[i].pickedMesh : null;
        if (mesh != null && mesh !== previousMesh && !pickedMeshes.includes(mesh)) {
          pickedMeshes.push(mesh);
        }
      }
      // trigger picking info changed
      for (const mesh of pickedMeshes) {
        if (mesh.onPickingInfosChanged != null && mesh.isEnabled() && mesh.isVisible) {
          mesh.onPickingInfosChanged(pickingInfos, previousPickingInfos);
        }
      }
      // trigger global event
      if (pickedMeshes.length > 0) {
        this.triggerPickingInfosChanged(pickingInfos, previousPickingInfos);
      }
      this.previousPickingInfos = pickingInfos;
    } else {
      // find picking info changes
      const pickedMeshes = [];
      for (const previousPickingInfo of previousPickingInfos) {
        if (previousPickingInfo.pickedMesh != null) {
          pickedMeshes.push(previousPickingInfo.pickedMesh);
        }
      }
      // trigger picking info changed
      for (const mesh of pickedMeshes) {
        if (mesh.onPickingInfosChanged != null && mesh.isEnabled() && mesh.isVisible) {
          mesh.onPickingInfosChanged([], previousPickingInfos);
        }
      }
      // trigger global event
      if (pickedMeshes.length > 0) {
        this.triggerPickingInfosChanged([], previousPickingInfos);
      }
      this.previousPickingInfos = [];
    }
  }
  // pickAll is an expensive operation so it needs to be called wisely
  pickAll(absoluteX, absoluteY) {
    return sceneExtensions.pickAll(this.scene, absoluteX, absoluteY);
  }
  setCursor(cursor) {
    setTimeout(() => {
      document.body.style.cursor = cursor;
    }, 1);
  }
}
