import { CrackWidthEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';
export class CrackWidth extends CodeList {

    public static fromService(codeList: CrackWidthEntityC2C) {
        return new CrackWidth(CodeList.baseFromService('CrackWidthEntity', codeList));
    }

    public override cloneDeep(): CrackWidth {
        return super.cloneDeep() as CrackWidth;
    }
}
