import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial.js';
import { Color3 } from '@babylonjs/core/Maths/math.color.js';
import { CacheItem } from './cache-item.js';
export class MaterialCache extends CacheItem {
  static concreteColor = Color3.FromHexString('#cbcabf');
  static darkConcreteColor = Color3.FromHexString('#b1afa4');
  sceneCache;
  constructor(sceneCache) {
    super();
    this.sceneCache = sceneCache;
  }
  createStandardMaterial(key, properties, scene = this.sceneCache.default3d) {
    return this.create(key, () => {
      const material = new StandardMaterial(key, scene);
      Object.assign(material, properties);
      return material;
    });
  }
  getStandard(color) {
    const name = `Material.Standard.Color.${color}`;
    return this.create(name, () => {
      const material = new StandardMaterial(name, this.sceneCache.default3d);
      material.diffuseColor = Color3.FromHexString(color);
      return material;
    });
  }
}
