import '@babylonjs/core/Meshes/Builders/ribbonBuilder.js';
import { Path3D } from '@babylonjs/core/Maths/math.path.js';
import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { Mesh } from '@babylonjs/core/Meshes/mesh.js';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData.js';
import { CreateRibbonVertexData } from '@babylonjs/core/Meshes/Builders/ribbonBuilder.js';
const tempMatrix = [new Matrix()];
export function cloneVertexData(vertexData) {
  const clonedVertexData = new VertexData();
  if (vertexData.positions != null) {
    clonedVertexData.positions = vertexData.positions.slice();
  }
  if (vertexData.normals != null) {
    clonedVertexData.normals = vertexData.normals.slice();
  }
  if (vertexData.uvs != null) {
    clonedVertexData.uvs = vertexData.uvs.slice();
  }
  if (vertexData.uvs2 != null) {
    clonedVertexData.uvs2 = vertexData.uvs2.slice();
  }
  if (vertexData.uvs3 != null) {
    clonedVertexData.uvs3 = vertexData.uvs3.slice();
  }
  if (vertexData.uvs4 != null) {
    clonedVertexData.uvs4 = vertexData.uvs4.slice();
  }
  if (vertexData.uvs5 != null) {
    clonedVertexData.uvs5 = vertexData.uvs5.slice();
  }
  if (vertexData.uvs6 != null) {
    clonedVertexData.uvs6 = vertexData.uvs6.slice();
  }
  if (vertexData.colors != null) {
    clonedVertexData.colors = vertexData.colors.slice();
  }
  if (vertexData.matricesIndices != null) {
    clonedVertexData.matricesIndices = vertexData.matricesIndices.slice();
  }
  if (vertexData.matricesWeights != null) {
    clonedVertexData.matricesWeights = vertexData.matricesWeights.slice();
  }
  if (vertexData.matricesIndicesExtra != null) {
    clonedVertexData.matricesIndicesExtra = vertexData.matricesIndicesExtra.slice();
  }
  if (vertexData.matricesWeightsExtra != null) {
    clonedVertexData.matricesWeightsExtra = vertexData.matricesWeightsExtra.slice();
  }
  if (vertexData.indices != null) {
    clonedVertexData.indices = vertexData.indices.slice();
  }
  return clonedVertexData;
}
export function flipFaces(vertexData, flipNormals = false) {
  if (flipNormals && vertexData.normals != null) {
    for (let i = 0; i < vertexData.normals.length; i++) {
      vertexData.normals[i] *= -1;
    }
  }
  if (vertexData.indices != null) {
    let temp;
    for (let i = 0; i < vertexData.indices.length; i += 3) {
      temp = vertexData.indices[i + 1];
      vertexData.indices[i + 1] = vertexData.indices[i + 2];
      vertexData.indices[i + 2] = temp;
    }
  }
}
export function computeNormals(positions, indices, normals, positionsSection, indicesSection, normalsSection) {
  positionsSection = positionsSection ?? {
    start: 0,
    end: positions.length
  };
  indicesSection = indicesSection ?? {
    start: 0,
    end: indices.length
  };
  normalsSection = normalsSection ?? {
    start: 0,
    end: normals.length
  };
  let index = 0;
  let p1p2x = 0.0;
  let p1p2y = 0.0;
  let p1p2z = 0.0;
  let p3p2x = 0.0;
  let p3p2y = 0.0;
  let p3p2z = 0.0;
  let faceNormalx = 0.0;
  let faceNormaly = 0.0;
  let faceNormalz = 0.0;
  let length = 0.0;
  let i1 = 0;
  let i2 = 0;
  let i3 = 0;
  for (index = positionsSection.start; index < positionsSection.end; index++) {
    normals[index] = 0.0;
  }
  // indice triplet = 1 face
  for (index = indicesSection.start / 3; index < indicesSection.end / 3; index++) {
    // get the indexes of each vertex of the face
    i1 = indices[index * 3];
    i2 = indices[index * 3 + 1];
    i3 = indices[index * 3 + 2];
    // compute two vectors per face
    p1p2x = positions[i1 * 3] - positions[i2 * 3];
    p1p2y = positions[i1 * 3 + 1] - positions[i2 * 3 + 1];
    p1p2z = positions[i1 * 3 + 2] - positions[i2 * 3 + 2];
    p3p2x = positions[i3 * 3] - positions[i2 * 3];
    p3p2y = positions[i3 * 3 + 1] - positions[i2 * 3 + 1];
    p3p2z = positions[i3 * 3 + 2] - positions[i2 * 3 + 2];
    // compute the face normal with cross product
    faceNormalx = p1p2y * p3p2z - p1p2z * p3p2y;
    faceNormaly = p1p2z * p3p2x - p1p2x * p3p2z;
    faceNormalz = p1p2x * p3p2y - p1p2y * p3p2x;
    length = Math.sqrt(faceNormalx * faceNormalx + faceNormaly * faceNormaly + faceNormalz * faceNormalz);
    length = length === 0 ? 1.0 : length;
    // normalize this normal
    faceNormalx /= length;
    faceNormaly /= length;
    faceNormalz /= length;
    // accumulate all the normals per face
    normals[i1 * 3] += faceNormalx;
    normals[i1 * 3 + 1] += faceNormaly;
    normals[i1 * 3 + 2] += faceNormalz;
    normals[i2 * 3] += faceNormalx;
    normals[i2 * 3 + 1] += faceNormaly;
    normals[i2 * 3 + 2] += faceNormalz;
    normals[i3 * 3] += faceNormalx;
    normals[i3 * 3 + 1] += faceNormaly;
    normals[i3 * 3 + 2] += faceNormalz;
  }
  // last normalization of each normal
  for (index = normalsSection.start / 3; index < normalsSection.end / 3; index++) {
    faceNormalx = normals[index * 3];
    faceNormaly = normals[index * 3 + 1];
    faceNormalz = normals[index * 3 + 2];
    length = Math.sqrt(faceNormalx * faceNormalx + faceNormaly * faceNormaly + faceNormalz * faceNormalz);
    length = length === 0 ? 1.0 : length;
    faceNormalx /= length;
    faceNormaly /= length;
    faceNormalz /= length;
    normals[index * 3] = faceNormalx;
    normals[index * 3 + 1] = faceNormaly;
    normals[index * 3 + 2] = faceNormalz;
  }
}
export function mergeSection(sectionOne, sectionTwo) {
  return {
    positionsStart: sectionOne.positionsEnd + sectionTwo.positionsStart,
    positionsEnd: sectionOne.positionsEnd + sectionTwo.positionsEnd,
    indicesStart: sectionOne.indicesEnd != null && sectionOne.indicesStart != null ? sectionOne.indicesEnd + sectionOne.indicesStart : undefined,
    indicesEnd: sectionOne.indicesEnd != null && sectionTwo.indicesEnd != null ? sectionOne.indicesEnd + sectionTwo.indicesEnd : undefined,
    normalsStart: sectionOne.normalsEnd != null && sectionOne.normalsStart != null ? sectionOne.normalsEnd + sectionOne.normalsStart : undefined,
    normalsEnd: sectionOne.normalsEnd != null && sectionTwo.normalsEnd != null ? sectionOne.normalsEnd + sectionTwo.normalsEnd : undefined
  };
}
export function mergeCylinderSection(sectionOne, sectionTwo) {
  const start = sectionOne.bottom ?? sectionOne.top ?? sectionOne.side;
  const side = mergeSection(start, sectionTwo.side);
  const top = sectionTwo.top != null ? mergeSection(start, sectionTwo.top) : undefined;
  const bottom = sectionTwo.bottom != null ? mergeSection(start, sectionTwo.bottom) : undefined;
  return {
    side,
    top,
    bottom
  };
}
export function createSection(vertexData) {
  const section = {
    positionsStart: 0,
    positionsEnd: vertexData.positions?.length ?? 0
  };
  if (vertexData.indices != null) {
    section.indicesStart = 0;
    section.indicesEnd = vertexData.indices.length;
  }
  if (vertexData.normals != null) {
    section.normalsStart = 0;
    section.normalsEnd = vertexData.normals.length;
  }
  return section;
}
export function createDashedLines(lines) {
  const positions = [];
  const indices = [];
  let indicesIndex = 0;
  const dash = Vector3.Zero();
  for (const line of lines) {
    const direction = line.direction.clone().normalize();
    let progress = Vector3.Zero();
    const position = line.position ?? Vector3.Zero();
    const transformMatrix = line.transformMatrix ?? Matrix.Identity();
    while (progress.length() < line.direction.length()) {
      // dash
      let lineDashPosition = new Vector3(progress.x + position.x, progress.y + position.y, progress.z + position.z);
      Vector3.TransformCoordinatesToRef(lineDashPosition, transformMatrix, lineDashPosition);
      positions.push(lineDashPosition.x, lineDashPosition.y, lineDashPosition.z);
      direction.scaleToRef(line.dashSize, dash);
      progress.addInPlace(dash);
      if (progress.lengthSquared() > line.direction.lengthSquared()) {
        progress = line.direction.clone();
      }
      lineDashPosition = new Vector3(progress.x + position.x, progress.y + position.y, progress.z + position.z);
      Vector3.TransformCoordinatesToRef(lineDashPosition, transformMatrix, lineDashPosition);
      positions.push(lineDashPosition.x, lineDashPosition.y, lineDashPosition.z);
      indices.push(indicesIndex++, indicesIndex++);
      // gap
      direction.scaleToRef(line.dashGapSize, dash);
      progress.addInPlace(dash);
    }
  }
  const vertexData = new VertexData();
  vertexData.positions = positions;
  vertexData.indices = indices;
  return vertexData;
}
export function extrudeShape(shape, path, scale, rotation, cap, sideOrientation, invertUV) {
  scale = scale ?? 1;
  rotation = rotation ?? 0;
  cap = cap === 0 ? 0 : cap ?? Mesh.NO_CAP;
  invertUV = invertUV ?? false;
  // extruded shape creation
  const path3D = new Path3D(path);
  const newShapePaths = [];
  cap = cap < 0 || cap > 3 ? 0 : cap;
  const pathArray = extrusionPathArray(shape, path, path3D, newShapePaths, scale, rotation, null, null, cap, false);
  return CreateRibbonVertexData({
    pathArray,
    closeArray: false,
    closePath: false,
    sideOrientation,
    invertUV
  });
}
export function extrudeShapeCustom(shape, path, scaleFunction, rotationFunction, ribbonCloseArray, ribbonClosePath, cap, sideOrientation, invertUV) {
  scaleFunction = scaleFunction ?? (() => 1);
  rotationFunction = rotationFunction ?? (() => 0);
  ribbonCloseArray = ribbonCloseArray ?? false;
  ribbonClosePath = ribbonClosePath ?? false;
  cap = cap === 0 ? 0 : cap ?? Mesh.NO_CAP;
  invertUV = invertUV ?? false;
  // extruded shape creation
  const path3D = new Path3D(path);
  const newShapePaths = [];
  cap = cap < 0 || cap > 3 ? 0 : cap;
  const pathArray = extrusionPathArray(shape, path, path3D, newShapePaths, null, null, scaleFunction, rotationFunction, cap, true);
  return CreateRibbonVertexData({
    pathArray,
    closeArray: ribbonCloseArray,
    closePath: ribbonClosePath,
    sideOrientation,
    invertUV
  });
}
/**
 * Update normals and vertices to get a flat shading rendering.
 * Warning: This may imply adding vertices to the mesh in order to get exactly 3 vertices per face
 */
export function convertToFlatShaded(vertexData) {
  const kinds = getVerticesDataKinds(vertexData);
  const data = {};
  const newdata = {};
  let kindIndex;
  let kind;
  for (kindIndex = 0; kindIndex < kinds.length; kindIndex++) {
    kind = kinds[kindIndex];
    data[kind] = vertexData[kind];
    newdata[kind] = [];
  }
  const indices = vertexData.indices;
  const totalIndices = indices.length;
  // Generating unique vertices per face
  let index;
  for (index = 0; index < totalIndices; index++) {
    const vertexIndex = indices[index];
    for (kindIndex = 0; kindIndex < kinds.length; kindIndex++) {
      kind = kinds[kindIndex];
      const stride = getStrideSize(kind);
      for (let offset = 0; offset < stride; offset++) {
        newdata[kind].push(data[kind][vertexIndex * stride + offset]);
      }
    }
  }
  // Updating faces & normal
  const normals = [];
  const positions = newdata['positions'];
  for (index = 0; index < totalIndices; index += 3) {
    indices[index] = index;
    indices[index + 1] = index + 1;
    indices[index + 2] = index + 2;
    const p1 = Vector3.FromArray(positions, index * 3);
    const p2 = Vector3.FromArray(positions, (index + 1) * 3);
    const p3 = Vector3.FromArray(positions, (index + 2) * 3);
    const p1p2 = p1.subtract(p2);
    const p3p2 = p3.subtract(p2);
    const normal = Vector3.Normalize(Vector3.Cross(p1p2, p3p2));
    // Store same normals for every vertex
    for (let localIndex = 0; localIndex < 3; localIndex++) {
      normals.push(normal.x);
      normals.push(normal.y);
      normals.push(normal.z);
    }
  }
  vertexData.indices = indices;
  vertexData.normals = normals;
  // Updating vertex buffers
  for (kindIndex = 0; kindIndex < kinds.length; kindIndex++) {
    kind = kinds[kindIndex];
    vertexData[kind] = newdata[kind];
  }
}
function extrusionPathArray(shape, path, path3D, shapePaths, scale, rotation, scaleFunction, rotationFunction, cap, custom) {
  const tangents = path3D.getTangents();
  const normals = path3D.getNormals();
  const binormals = path3D.getBinormals();
  const distances = path3D.getDistances();
  let angle = 0;
  const returnScale = () => scale;
  const returnRotation = () => rotation;
  const rotate = custom ? rotationFunction : returnRotation;
  const scl = custom ? scaleFunction : returnScale;
  let index = cap === Mesh.NO_CAP || cap === Mesh.CAP_END ? 0 : 2;
  const rotationMatrix = tempMatrix[0];
  for (let i = 0; i < path.length; i++) {
    const shapePath = [];
    const angleStep = rotate(i, distances[i]);
    const scaleRatio = scl(i, distances[i]);
    for (let p = 0; p < shape.length; p++) {
      Matrix.RotationAxisToRef(tangents[i], angle, rotationMatrix);
      const planed = tangents[i].scale(shape[p].z).add(normals[i].scale(shape[p].x)).add(binormals[i].scale(shape[p].y));
      const rotated = shapePath[p] ? shapePath[p] : Vector3.Zero();
      Vector3.TransformCoordinatesToRef(planed, rotationMatrix, rotated);
      rotated.scaleInPlace(scaleRatio).addInPlace(path[i]);
      shapePath[p] = rotated;
    }
    shapePaths[index] = shapePath;
    angle += angleStep;
    index++;
  }
  // cap
  switch (cap) {
    case Mesh.NO_CAP:
      break;
    case Mesh.CAP_START:
      shapePaths[0] = capPath(shapePaths[2]);
      shapePaths[1] = shapePaths[2].slice(0);
      break;
    case Mesh.CAP_END:
      shapePaths[index] = shapePaths[index - 1];
      shapePaths[index + 1] = capPath(shapePaths[index - 1]);
      break;
    case Mesh.CAP_ALL:
      shapePaths[0] = capPath(shapePaths[2]);
      shapePaths[1] = shapePaths[2].slice(0);
      shapePaths[index] = shapePaths[index - 1];
      shapePaths[index + 1] = capPath(shapePaths[index - 1]);
      break;
    default:
      break;
  }
  return shapePaths;
}
function capPath(shapePath) {
  const pointCap = [];
  const barycenter = Vector3.Zero();
  let i;
  for (i = 0; i < shapePath.length; i++) {
    barycenter.addInPlace(shapePath[i]);
  }
  barycenter.scaleInPlace(1 / shapePath.length);
  for (i = 0; i < shapePath.length; i++) {
    pointCap.push(barycenter);
  }
  return pointCap;
}
function getVerticesDataKinds(vertexData) {
  const kinds = [];
  if (vertexData.positions != null) {
    kinds.push('positions');
  }
  if (vertexData.uvs != null) {
    kinds.push('uvs');
  }
  if (vertexData.uvs2 != null) {
    kinds.push('uvs2');
  }
  if (vertexData.uvs3 != null) {
    kinds.push('uvs3');
  }
  if (vertexData.uvs4 != null) {
    kinds.push('uvs4');
  }
  if (vertexData.uvs5 != null) {
    kinds.push('uvs5');
  }
  if (vertexData.uvs6 != null) {
    kinds.push('uvs6');
  }
  if (vertexData.colors != null) {
    kinds.push('colors');
  }
  if (vertexData.matricesIndices != null) {
    kinds.push('matricesIndices');
  }
  if (vertexData.matricesWeights != null) {
    kinds.push('matricesWeights');
  }
  if (vertexData.matricesIndicesExtra != null) {
    kinds.push('matricesIndicesExtra');
  }
  if (vertexData.matricesWeightsExtra != null) {
    kinds.push('matricesWeightsExtra');
  }
  return kinds;
}
function getStrideSize(kind) {
  switch (kind) {
    case 'positions':
      return 3;
    case 'normals':
      return 3;
    case 'uvs':
    case 'uvs2':
    case 'uvs3':
    case 'uvs4':
    case 'uvs5':
    case 'uvs6':
      return 2;
    case 'colors':
      return 4;
    case 'matricesIndices':
    case 'matricesIndicesExtra':
      return 4;
    case 'matricesWeights':
    case 'matricesWeightsExtra':
      return 4;
    default:
      return 0;
  }
}
