import { Color3, Color4 } from '@babylonjs/core/Maths/math.color';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { TooltipKey as BaseTooltipKey } from '@profis-engineering/gl-model/external/tooltip';
import { ICameraValues3d, LoadsVisibilityInfo } from '@profis-engineering/gl-model/gl-model';
import { DeepPartial } from 'ts-essentials';
import { ZoneNumber } from '../services/data.service';
import { ConcreteMemberId, StrengthDesignDetails, StrengthPropertyId, ZoneLoad } from '../services/design.service';
import { StrengthMeasurements } from './components/strength/measurements/strength-measurements';
import { StrengthBaseMaterial } from './components/strength/strength-base-material';
import { StrengthCoordinateSystem } from './components/strength/strength-coordinate-system';
import { Opening } from './components/strength/opening';
import { PostInstalledElement } from './components/strength/post-installed-element';
import { StrengthSceneCoordinateSystem } from './components/strength/strength-scene-coordinate-system';
import { BaseUpdate, GlModel, Model, ModelUpdateClass, ScreenshotSettings, UnitText2D } from './gl-model';

export interface StrengthModel extends Model {
    baseMaterial: BaseMaterialModel;
    zones: ZonesModel;
    postInstalledElement: PostInstalledElementModel;
    visibilityModel: VisibilityModel;
    opening: OpeningModel;
}

export type StrengthPartialModel = DeepPartial<StrengthModel>;
export type StrengthUnitText2D = UnitText2D<StrengthPropertyId>;

export interface VisibilityModel extends BaseMaterialVisibilityModel {
    AnchorSpacingDimensionsVisible: boolean;
    AnchorEdgeDistanceDimensionsVisible: boolean;
    ZonesDimensionsVisible: boolean;
    ConcreteDimensionsVisible: boolean;
}

export interface BaseMaterialVisibilityModel {
    ZonesVisible: boolean;
    ZonesNumberingVisible: boolean;
    TransparentConcrete: boolean;
}

export interface BaseMaterialModel {
    width: number;
    length: number;
    height: number;
    concreteMemberId: ConcreteMemberId;
}

export interface OpeningModel {
    defineOpening: boolean;
    openingOriginX: number;
    openingOriginY: number;
    openingLength: number;
    openingWidth: number;
}

export interface ZonesModel {
    numberOfZones: ZoneNumber;
    width: number;
    zone1Length: number;
    zone2Length: number;
    zone3Length: number;
    zone4Length: number;
    zone5Length: number;
    zone6Length: number;
    zone7Length: number;
    zone8Length: number;
    zone1Width: number;
    zone2Width: number;
    zone3Width: number;
    zone4Width: number;
    zone5Width: number;
    zone6Width: number;
    zone7Width: number;
    zone8Width: number;
    zoneLoads: ZoneLoad[];
}

export interface PostInstalledElementModel {
    zone1StrengtheningElementDefinition: boolean;
    zone1InstallationDirectionId: number | null;
    zone1SpacingX: number | null;
    zone1SpacingY: number | null;
    zone1MinimumEdgeDistanceX: number | null;
    zone1MinimumEdgeDistanceY: number | null;
    zone1EdgeDistanceX: number | null;
    zone1EdgeDistanceY: number | null;
    zone1TransverseEccentricity: number | null;
    zone2StrengtheningElementDefinition: boolean | null;
    zone2InstallationDirectionId: number | null;
    zone2SpacingX: number | null;
    zone2SpacingY: number | null;
    zone2MinimumEdgeDistanceX: number | null;
    zone2MinimumEdgeDistanceY: number | null;
    zone2EdgeDistanceX: number | null;
    zone2EdgeDistanceY: number | null;
    zone2TransverseEccentricity: number | null;
    zone3StrengtheningElementDefinition: boolean | null;
    zone3InstallationDirectionId: number | null;
    zone3SpacingX: number | null;
    zone3SpacingY: number | null;
    zone3MinimumEdgeDistanceX: number | null;
    zone3MinimumEdgeDistanceY: number | null;
    zone3EdgeDistanceX: number | null;
    zone3EdgeDistanceY: number | null;
    zone3TransverseEccentricity: number | null;
    zone4StrengtheningElementDefinition: boolean | null;
    zone4InstallationDirectionId: number | null;
    zone4SpacingX: number | null;
    zone4SpacingY: number | null;
    zone4MinimumEdgeDistanceX: number | null;
    zone4MinimumEdgeDistanceY: number | null;
    zone4EdgeDistanceX: number | null;
    zone4EdgeDistanceY: number | null;
    zone4TransverseEccentricity: number | null;
    zone5StrengtheningElementDefinition: boolean | null;
    zone5InstallationDirectionId: number | null;
    zone5SpacingX: number | null;
    zone5SpacingY: number | null;
    zone5MinimumEdgeDistanceX: number | null;
    zone5MinimumEdgeDistanceY: number | null;
    zone5EdgeDistanceX: number | null;
    zone5EdgeDistanceY: number | null;
    zone5TransverseEccentricity: number | null;
    zone6StrengtheningElementDefinition: boolean | null;
    zone6InstallationDirectionId: number | null;
    zone6SpacingX: number | null;
    zone6SpacingY: number | null;
    zone6MinimumEdgeDistanceX: number | null;
    zone6MinimumEdgeDistanceY: number | null;
    zone6EdgeDistanceX: number | null;
    zone6EdgeDistanceY: number | null;
    zone6TransverseEccentricity: number | null;
    zone7StrengtheningElementDefinition: boolean | null;
    zone7InstallationDirectionId: number | null;
    zone7SpacingX: number | null;
    zone7SpacingY: number | null;
    zone7MinimumEdgeDistanceX: number | null;
    zone7MinimumEdgeDistanceY: number | null;
    zone7EdgeDistanceX: number | null;
    zone7EdgeDistanceY: number | null;
    zone7TransverseEccentricity: number | null;
    zone8StrengtheningElementDefinition: boolean | null;
    zone8InstallationDirectionId: number | null;
    zone8SpacingX: number | null;
    zone8SpacingY: number | null;
    zone8MinimumEdgeDistanceX: number | null;
    zone8MinimumEdgeDistanceY: number | null;
    zone8EdgeDistanceX: number | null;
    zone8EdgeDistanceY: number | null;
    zone8TransverseEccentricity: number | null;
    withoutNutAndWasher: boolean;
    anchorDiameter: number;
    drillLength: number;
    depthOfRecess: number;
}

export type StrengthTooltipKey = BaseTooltipKey |
    'BaseMaterial';

export abstract class StrengthBaseUpdate extends BaseUpdate<StrengthPropertyId> {}
export type StrengthModelUpdateClass = ModelUpdateClass<StrengthPropertyId>;

export interface StrengthModelUpdate {
    BaseMaterialUpdate?: StrengthModelUpdateClass;
    ZonesUpdate?: StrengthModelUpdateClass;
    PostInstalledElementUpdate?: StrengthModelUpdateClass;
    OpeningUpdate?: StrengthModelUpdateClass;
}

export class StrengthGlModel extends GlModel<
    StrengthModel,
    StrengthPropertyId,
    StrengthTooltipKey,
    StrengthModelUpdate,
    StrengthDesignDetails
> {
    protected override initializeComponentDefinitions(modelUpdate: StrengthModelUpdate): void {
        this.componentDefinitions = [
            {
                componentType: StrengthBaseMaterial,
                options: {
                    updateModelCtor: modelUpdate?.BaseMaterialUpdate
                }
            }, {
                componentType: PostInstalledElement,
                options: {
                    updateModelCtor: modelUpdate?.PostInstalledElementUpdate,
                }
            }, {
                componentType: StrengthMeasurements,
            }, {
                componentType: StrengthSceneCoordinateSystem
            }, {
                componentType: StrengthCoordinateSystem
            }, {
                componentType: Opening,
                options: {
                    updateModelCtor: modelUpdate?.OpeningUpdate,
                }
            }
        ];
    }

    protected override calculateCameraValues3d(): ICameraValues3d {
        return {
            alpha: Math.PI / 6,
            beta: Math.PI / 4,
            radius: 12500,
            betaMin: -Math.PI / 2 + 0.01,
            betaMax: Math.PI / 2 - 0.01,
            radiusMin: 1000,
            radiusMax: 30000
        };
    }

    protected override setLights(): void {
        this.frontLight.direction = new Vector3(0.35, 4, 1);
        this.frontLight.specular = Color3.Black();
        this.frontLight.intensity = 0.575;

        this.backLight.direction = new Vector3(-0.35, -4, -1);
        this.backLight.specular = Color3.Black();
        this.backLight.intensity = 0.575;

        this.groundLight.direction = Vector3.Zero();
        this.groundLight.specular = Color3.Black();
        this.groundLight.diffuse = Color3.White();
        this.groundLight.groundColor = Color3.White();
        this.groundLight.intensity = 0.4;
    }

    protected override ensureScreenShotSettingsInternal(): Vector3 {
        // TODO TEAM: what is this magic number?
        return new Vector3(4420, -8840, -7650);
    }

    /**
     * Method sets visibility for scene meshes for generating screenshot
     */
    protected override prepareVisibilityForScreenshot(zoomed: boolean, info: LoadsVisibilityInfo, preview: boolean, extraInfo: ScreenshotSettings) {
        // no highlighting
        for (const highlightedDimension in this.model.highlightedDimensions) {
            this.model.highlightedDimensions[highlightedDimension] = false;
        }

        this.scene3d.clearColor = new Color4(1, 1, 1, 1);

        if (extraInfo.isThumbnail) {
            this.scene3d.clearColor = new Color4(0.933, 0.933, 0.933, 1);

            if (this.model.sceneCoordinateSystem != null) {
                this.model.sceneCoordinateSystem.hidden = true;
            }
        }
        else if (extraInfo.preview) {
            if (this.model.sceneCoordinateSystem != null) {
                this.model.sceneCoordinateSystem.hidden = true;
            }
        }

        // Shows or hides the coordinate system, along with some minor extra settings.
        // (+) In actual report, coordinate system should be visible.
        // (+) On report export dialog and home page, don't show coordinate system.
        this.model.sceneCoordinateSystem!.report = true;
        this.model.screenshot = true;

        // Enabling the visibility of all elements.
        this.model.visibilityModel.AnchorEdgeDistanceDimensionsVisible = !this.model.opening.defineOpening;
        this.model.visibilityModel.AnchorSpacingDimensionsVisible = !this.model.opening.defineOpening;
        this.model.visibilityModel.ConcreteDimensionsVisible = true;
        this.model.visibilityModel.TransparentConcrete = true;
        this.model.visibilityModel.ZonesDimensionsVisible = true;
        this.model.visibilityModel.ZonesVisible = true;
        this.model.visibilityModel.ZonesNumberingVisible = true;

        this.refreshComponents();
    }
}
