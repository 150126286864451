export function getCodeListTextDeps(localizationService, numberService, getLocalizedString) {
  return {
    localizationService,
    numberService,
    getLocalizedString: getLocalizedString
  };
}
export class CodeList {
  id;
  name;
  nameResourceKey;
  image;
  tooltipDisplayKey;
  tooltipTitleDisplayKey;
  tooltipType;
  tooltipDisabledDisplayKey;
  tooltipDisabledTitleDisplayKey;
  tooltipDisabledType;
  kbNumberRegion;
  kbTooltip;
  displayKey;
  tag;
  infoPopupCommand;
  constructor(codeList) {
    this.id = codeList.id;
    this.name = codeList.name;
    this.nameResourceKey = codeList.nameResourceKey;
    this.image = codeList.image;
    this.tooltipDisplayKey = codeList.tooltipDisplayKey;
    this.tooltipTitleDisplayKey = codeList.tooltipTitleDisplayKey;
    this.tooltipType = codeList.tooltipType;
    this.tooltipDisabledDisplayKey = codeList.tooltipDisabledDisplayKey;
    this.tooltipDisabledTitleDisplayKey = codeList.tooltipDisabledTitleDisplayKey;
    this.tooltipDisabledType = codeList.tooltipDisabledType;
    this.kbNumberRegion = codeList.kbNumberRegion;
    this.kbTooltip = codeList.kbTooltip;
    this.displayKey = codeList.displayKey;
    this.tag = codeList.tag;
    this.infoPopupCommand = codeList.infoPopupCommand;
  }
  getTranslatedNameText(codeListDeps) {
    if (this.name != null && this.name != '') {
      return this.name;
    }
    if (this.nameResourceKey != null && this.nameResourceKey != '') {
      if (codeListDeps.getLocalizedString) {
        return codeListDeps.getLocalizedString(this.nameResourceKey);
      }
      if (codeListDeps.localizationService == null) {
        throw new Error('codeListDeps.localizationService not set!');
      }
      return codeListDeps.localizationService.getString(this.nameResourceKey);
    }
    return undefined;
  }
  /* eslint-disable @typescript-eslint/no-unused-vars */
  getTranslatedDescriptionText(_serviceDeps) {
    return undefined;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
  cloneDeep() {
    const constructor = this.constructor;
    return new constructor({
      id: this.id,
      name: this.name,
      nameResourceKey: this.nameResourceKey,
      image: this.image,
      tooltipDisplayKey: this.tooltipDisplayKey,
      tooltipTitleDisplayKey: this.tooltipTitleDisplayKey,
      tooltipType: this.tooltipType,
      tooltipDisabledDisplayKey: this.tooltipDisabledDisplayKey,
      tooltipDisabledTitleDisplayKey: this.tooltipDisabledTitleDisplayKey,
      tooltipDisabledType: this.tooltipDisabledType,
      kbNumberRegion: this.kbNumberRegion,
      kbTooltip: this.kbTooltip,
      displayKey: this.displayKey,
      tag: this.tag,
      infoPopupCommand: this.infoPopupCommand
    });
  }
  static baseFromService(displayKeyPrefix, codeList, constructor) {
    const displayKey = this.getCodeListProperty(codeList, 'DisplayKey');
    const imageProperty = this.getCodeListProperty(codeList, 'Image');
    const tooltipTypeProperty = this.getCodeListProperty(codeList, 'TooltipType');
    const tooltipDisabledTypeProperty = this.getCodeListProperty(codeList, 'TooltipDisabledType');
    const nameResourceKey = displayKey != null && displayKey != '' ? `Agito.Hilti.Profis3.CodeList.${displayKeyPrefix}.${displayKey}` : undefined;
    const image = imageProperty != null && imageProperty != '' ? `sprite-${imageProperty}` : undefined;
    const tooltipType = tooltipTypeProperty != null ? tooltipTypeProperty : 0;
    const tooltipDisabledType = tooltipDisabledTypeProperty != null ? tooltipDisabledTypeProperty : 0;
    const tag = this.getCodeListProperty(codeList, 'Tag');
    return {
      id: this.getCodeListProperty(codeList, 'Id'),
      name: this.getCodeListProperty(codeList, 'DisplayName'),
      nameResourceKey,
      image,
      tooltipDisplayKey: this.getCodeListProperty(codeList, 'TooltipDisplayKey'),
      tooltipTitleDisplayKey: this.getCodeListProperty(codeList, 'TooltipTitleDisplayKey'),
      tooltipType,
      tooltipDisabledDisplayKey: this.getCodeListProperty(codeList, 'TooltipDisabledDisplayKey'),
      tooltipDisabledTitleDisplayKey: this.getCodeListProperty(codeList, 'TooltipDisabledTitleDisplayKey'),
      tooltipDisabledType,
      kbNumberRegion: this.getCodeListProperty(codeList, 'kbNumberRegion'),
      kbTooltip: this.getCodeListProperty(codeList, 'KBTooltip'),
      displayKey: this.getCodeListProperty(codeList, 'DisplayKey'),
      tag: tag != null && tag != '' ? `Agito.Hilti.Profis3.${displayKey}.Tag` : null,
      infoPopupCommand: this.getCodeListProperty(codeList, 'InfoPopupCommand'),
      ...(constructor ?? {})
    };
  }
  static getCodeListProperty(codeList, property) {
    return codeList[property];
  }
}
