import { Injectable } from '@angular/core';
import { IUserMenuFavorites } from '@profis-engineering/pe-ui-common/entities/favorites';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { FavoritesServiceInjected } from '@profis-engineering/pe-ui-common/services/favorites.common';
import { DesignType } from '../../shared/entities/tracking-data';
import {
    ConnectionType, DesignStandard
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';

const menu3DConcrete2ConcreteOverlayEU = 'menu3DConcrete2ConcreteOverlayEU';
const menu3DConcrete2ConcreteOverlayHNA = 'menu3DConcrete2ConcreteOverlayHNA';
const menu3DConcrete2ConcreteSplicesHNA = 'menu3DConcrete2ConcreteSplicesHNA';
const menu3DConcrete2ConcreteStructuralJointsHNA = 'menu3DConcrete2ConcreteStructuralJointsHNA';
const menu3DConcrete2ConcretePirEU = 'menu3DConcrete2ConcretePirEU';
const menu3DConcrete2ConcreteAS = 'menu3DConcrete2ConcreteAS';
const menu3DConcrete2ConcreteOverlayAS = 'menu3DConcrete2ConcreteOverlayAS';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService extends FavoritesServiceInjected {
    public initFavorites(data: IUserMenuFavorites) {
        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, ConnectionType.ConcreteOverlay, DesignStandard.ETAG),
            data?.favorites?.[menu3DConcrete2ConcreteOverlayEU]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, ConnectionType.ConcreteOverlay, DesignStandard.ACI),
            data?.favorites?.[menu3DConcrete2ConcreteOverlayHNA]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, ConnectionType.ConcreteOverlay, DesignStandard.CSA),
            data?.favorites?.[menu3DConcrete2ConcreteOverlayHNA]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, ConnectionType.Splices, DesignStandard.ACI),
            data?.favorites?.[menu3DConcrete2ConcreteSplicesHNA]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, ConnectionType.Splices, DesignStandard.CSA),
            data?.favorites?.[menu3DConcrete2ConcreteSplicesHNA]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, ConnectionType.StructuralJoints, DesignStandard.ACI),
            data?.favorites?.[menu3DConcrete2ConcreteStructuralJointsHNA]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, ConnectionType.StructuralJoints, DesignStandard.CSA),
            data?.favorites?.[menu3DConcrete2ConcreteStructuralJointsHNA]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, ConnectionType.Splices, DesignStandard.ETAG),
            data?.favorites?.[menu3DConcrete2ConcretePirEU]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, ConnectionType.Splices, DesignStandard.ASBased),
            data?.favorites?.[menu3DConcrete2ConcreteAS]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, ConnectionType.ConcreteOverlay, DesignStandard.ASBased),
            data?.favorites?.[menu3DConcrete2ConcreteOverlayAS]
        );
    }

    public getMenuRegionIdFavorites(id: string, designType: DesignType): string | undefined {
        if (designType == DesignType.Concrete2Concrete) {
            return id;
        }

        return undefined;
    }

    public getFavoritesTypeC2C(menuType: MenuType, designType: DesignType, designStandard: DesignStandard, connectionType: ConnectionType) {
        let favoritesType: string | undefined = undefined;

        if (menuType == MenuType.Menu3D && designType == DesignType.Concrete2Concrete) {
            if (designStandard == DesignStandard.ETAG)
                favoritesType = this.getFavoritesTypeEtagC2C(connectionType);
            else if (designStandard == DesignStandard.ASBased)
                favoritesType = this.getFavoritesTypeASC2C(connectionType);
            else
                favoritesType = this.getFavoritesTypeHnaC2C(connectionType);
        }

        return favoritesType;
    }

    private getFavoritesTypeEtagC2C(connectionType: ConnectionType) {
        switch (connectionType) {
            case ConnectionType.ConcreteOverlay:
                return menu3DConcrete2ConcreteOverlayEU;
            case ConnectionType.Splices:
            case ConnectionType.StructuralJoints:
                return menu3DConcrete2ConcretePirEU;
            default:
                throw new Error('Unsupported Etag C2C connectionType');
        }
    }

    private getFavoritesTypeASC2C(connectionType: ConnectionType) {
        switch (connectionType) {
            case ConnectionType.ConcreteOverlay:
                return menu3DConcrete2ConcreteOverlayAS;
            case ConnectionType.Splices:
            case ConnectionType.StructuralJoints:
                return menu3DConcrete2ConcreteAS;
            default:
                throw new Error('Unsupported Etag C2C connectionType');
        }
    }

    private getFavoritesTypeHnaC2C(connectionType: ConnectionType) {
        switch (connectionType) {
            case ConnectionType.ConcreteOverlay:
                return menu3DConcrete2ConcreteOverlayHNA;
            case ConnectionType.Splices:
                return menu3DConcrete2ConcreteSplicesHNA;
            case ConnectionType.StructuralJoints:
                return menu3DConcrete2ConcreteStructuralJointsHNA;
            default:
                throw new Error('Unsupported HNA C2C connectionType');
        }
    }

    private getInfo(menuType: MenuType, connectionType: ConnectionType, designStandard: DesignStandard) {
        const favoritesType = this.getFavoritesTypeC2C(menuType, DesignType.Concrete2Concrete, designStandard, connectionType);
        if (favoritesType == null) {
            throw new Error('Could not find favorites type.');
        }

        if (this.infoBase[favoritesType] == null) {
            this.infoBase[favoritesType] = {
                favoritesType,
                dbFavorites: [],
                favorites: [],
                setDefer: new Deferred<string[]>(),
                updateId: undefined,
                updating: false
            };
        }

        return this.infoBase[favoritesType];
    }
}
