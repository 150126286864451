import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewEncapsulation } from '@angular/core';
import drillingAidImage from '../../../../images/drilling-aid.png' with { loader: 'file' };
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';
import { imageUrl } from '../../../../image';
import { L10nDirective } from '../../../../directives/l10n.directive';
@Component({
    templateUrl: './info-dialog-drilling-aid.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [L10nDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InfoDialogDrillingAidComponent extends InfoDialogBaseComponent {
    public drillingAidImage = imageUrl(drillingAidImage);

    public getTitle(): string {
        return this.translate('SP.InstallationConditions.DrillingAid.InfoPopUp.Title');
    }
}
