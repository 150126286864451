import { PunchDesignDetails, PunchPerimeter } from '../../../services/design.service';
import { PunchBaseUpdate, PunchModel } from '../../../web-gl/punch-gl-model';
import { punchPropertyValueChanged, UpdateServices } from '../update';

export class PunchPostInstalledElementUpdate extends PunchBaseUpdate {
    @punchPropertyValueChanged('fastenerId')
    protected updateFastenerId(fastenerId: number, model: PunchModel, services: UpdateServices) {
        model.punchPostInstalledElement.anchorDiameter = services.dataService.fastenersById[fastenerId].size;
    }

    @punchPropertyValueChanged('defineStrengtheningElement')
    protected updateDefineStrengtheningElement(defineStrengtheningElement: boolean, model: PunchModel) {
        model.punchPostInstalledElement.defineStrengtheningElement = defineStrengtheningElement;
    }

    // TODO why is this called for every property change?
    @punchPropertyValueChanged()
    protected updateDrillLength(model: PunchModel, services: UpdateServices, designDetails: PunchDesignDetails) {
        const punchDefinitions3D = designDetails.calculateResult?.punchDefinitions3D;
        model.punchPostInstalledElement.perimeters = [];

        punchDefinitions3D?.perimeters?.forEach((perimeter: PunchPerimeter) => {
            model.punchPostInstalledElement.perimeters.push({
                id: perimeter.id,
                distanceToLoadingArea: perimeter.distanceToLoadingArea,
                points: [...perimeter.points]
            });
        });

        model.punchPostInstalledElement.controlPerimeters = designDetails.calculateResult?.punchDefinitions3D?.controlPerimeters;
        model.punchPostInstalledElement.outerPerimeters = designDetails.calculateResult?.punchDefinitions3D?.outerPerimeters;
        model.punchPostInstalledElement.influenceVectors = designDetails.calculateResult?.punchDefinitions3D?.influenceVectors;
    }
}
