import { DesignC2C, DesignExternalMetaDataC2C } from './design-c2c';
import { DesignType } from './tracking-data';

/**
 * Read the design meta-data from design entity for storage in document service
 * @param design
 */
export function getMetaDataFromDesign(design: DesignC2C): DesignExternalMetaDataC2C {
    const ret: DesignExternalMetaDataC2C = {
        id: design.id,
        name: design.designName,
        region: design.region.id,
        standard: design.designStandardId,
        designType: DesignType.Concrete2Concrete,
        approvalNumber: undefined,

        productName: design.productNameC2C,
        designMethod: 0,
        calculationType: 0,
        handrailConnectionType: 0
    };

    return ret;
}
