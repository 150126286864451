import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import {
    StrengthDesignDetails, UtilizationItem, UtilizationResults, UtilizationType, UtilizationValue
} from '../../../services/design.service';
import { LocalizationService } from '../../../services/localization.service';
import { NumberService } from '../../../services/number.service';
import { UnitService } from '../../../services/unit.service';
import { UserSettingsService } from '../../../services/user-settings.service';
import { includeSprites } from '../../../sprites';
import {
    ICollapsingSection, IZoneUtilCollapsingData, IZoneUtilGroupSection,
    IZoneUtilGroupSectionCalculated, IZoneUtilPropertyDetail, IZoneUtilPropertyGroup,
    IZoneUtilPropertyGroupCalculated
} from '../data/zone-utilizations';
import { CollapseState } from '../enums/collapse-state';
import { CollapsingControls, ZoneName } from '../enums/collapsing-control';
import { TabType } from '../enums/tab-type';
import { UtilizationSection } from '../enums/utilization-section';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { L10nDirective } from '../../../directives/l10n.directive';
import { ZoneUtilizationItemComponent } from '../zone-utilization-item/zone-utilization-item.component';

@Component({
    selector: 'app-zone-utilization-panel',
    templateUrl: './zone-utilization-panel.component.html',
    styleUrls: ['./zone-utilization-panel.component.scss'],
    imports: [
        NgbTooltip,
        L10nDirective,
        ZoneUtilizationItemComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ZoneUtilizationPanelComponent implements OnInit, OnChanges {
    @Input()
    public utilizations?: UtilizationResults[] = [];

    @Input()
    public design: Design | undefined;

    @Input()
    public designDetails: StrengthDesignDetails | undefined;

    public TabType: Record<keyof typeof TabType, TabType> = {
        Primary: TabType.Primary,
        Secondary: TabType.Secondary
    };

    public zones: ZoneName[] = [];
    public zoneUtilizationsAvailable = true;
    public tooltipText = 'SP.Main.Region.DragToMove';
    public state: CollapseState = CollapseState.Collapsed;
    public type: TabType = TabType.Primary;
    public showNewLabel = false;
    public collapsed = false;
    public baseHeader = 'SP.Utilizations';
    public showPercentage = false;

    // Combine all the sections with the appropriate properties
    public overlayUtilization: IZoneUtilPropertyGroup = {
        sections: [
            {
                id: 'ExistingMember',
                header: `${this.baseHeader}.ExistingMember`,
                section: UtilizationSection.ExistingMember,
                properties: [
                    this.utilizationProperty(`StrutResistance`, `<tspan>V<sub>Rd.max</sub></tspan>`, UtilizationType.ExistingMemberStrutResistance),
                    this.utilizationProperty(`ConcreteResistance`, 'V<sub>Rd.c</sub>', UtilizationType.ExistingMemberConcreteResistance),
                    this.utilizationProperty(`ShearReinforcement`, 'V<sub>Rd.s.ci</sub>', UtilizationType.ExistingMemberShearReinforcement),
                    this.utilizationProperty(`StrengtheningNeeded`, undefined, UtilizationType.ExistingMemberStrengtheningNeeded),
                ],
            },
            {
                id: 'StrengthenedMember',
                header: `${this.baseHeader}.StrengthenedMember`,
                section: UtilizationSection.StrengthenedMember,
                visible: false,
                properties: [
                    this.utilizationProperty(`StrutResistance`, 'V<sub>Rd.max</sub>', UtilizationType.StrengthenedMemberStrutResistance),
                    this.utilizationProperty(`PostInstalledReinforcement`, 'V<sub>Rd.s.pi</sub>', UtilizationType.StrengthenedMemberPostInstalledReinforcement),
                    this.utilizationProperty(`AdditionalTensileForceFromShear`, '∆F<sub>td</sub>', UtilizationType.AdditionalTensileForceFromShear),
                    this.utilizationProperty(`NumberOfAnchors`, undefined, UtilizationType.StrengthenedMemberNumberOfStrengtheningElements),
                    this.utilizationProperty(`DrillLength`, undefined, UtilizationType.StrengthenedMemberDrillLength),
                ],
            },
        ],
    };
    public zonesCollapsing: Partial<Record<ZoneName, IZoneUtilCollapsingData>> = {};
    public utilizationsCalculated: Partial<Record<ZoneName, IZoneUtilPropertyGroupCalculated>> = {};
    private zoneCalculationResults: Partial<Record<ZoneName, UtilizationResults>> = {};

    constructor(
        private localization: LocalizationService,
        private elementRef: ElementRef<HTMLElement>,
        private userSettingsService: UserSettingsService,
        private numberService: NumberService,
        private unitService: UnitService,
    ) {

    }

    public ngOnChanges(): void {
        this.updateValues();
    }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
            'sprite-tab-supplementary-reinforcement',
            'sprite-arrow-up',
            'sprite-arrow-down'
        );

        this.updateValues();
    }

    private updateValues() {
        this.zones = this.availableZones.splice(0, this.zonesNumber);
        this.initConcreteCollapsing();
        this.setZoneCalculationResults();
        this.checkDeltaUtilizationPropertyVariable();
    }

    /* ====================================== Utilization general functions ====================================== */
    public utilizationProperty(title: string, utilizationVariable?: string, utilizationType?: UtilizationType) {
        return {
            text: `${this.baseHeader}.` + title,
            utilizationVariable: utilizationVariable,
            utilizationType: utilizationType,
        };
    }

    public checkDeltaUtilizationPropertyVariable() {
        const deltaResult = this.overlayUtilization.sections.find(x => x.section === UtilizationSection.StrengthenedMember)?.properties.find(x => x.utilizationType === UtilizationType.AdditionalTensileForceFromShear);

        if (deltaResult !== undefined && this.designProperties?.designStandardId === 2) {
            deltaResult.utilizationVariable = 'F<sub>tVd</sub>';
        }
    }

    private get availableZones() {
        return [ZoneName.Zone1, ZoneName.Zone2, ZoneName.Zone3, ZoneName.Zone4, ZoneName.Zone5, ZoneName.Zone6, ZoneName.Zone7, ZoneName.Zone8];
    }

    public getUtilizationVariable(key: string | undefined) {
        if (key == undefined) {
            return undefined;
        }

        return key;
    }

    public getPropertyTitle(key: string | undefined, utilizationVariable: string | undefined) {
        if (key == undefined) {
            return undefined;
        }

        if (utilizationVariable === undefined) {
            return this.localization.getString(key);
        }

        return this.localization.getString(key) + ', ' + utilizationVariable;
    }

    public translate(key: string | undefined) {
        if (key == undefined) {
            return undefined;
        }

        return this.localization.getString(key);
    }

    public getTooltipText() {
        return this.localization.getString(this.tooltipText);
    }

    public typeClass(tabType: TabType): string {
        return tabType == TabType.Secondary ? 'secondary-type' : '';
    }

    public get zonesNumber() {
        return this.designDetails!.properties.zonesNumberId;
    }

    public get designProperties() {
        return this.designDetails!.properties;
    }

    public getHeaderTitle(zone: ZoneName) {
        return `Zone ${zone}`;
    }

    public getComponentId(zone: ZoneName,
        postfix: string,
        sectionIndex?: number,
        detailGroupIndex?: number,
        detailIndex?: number
    ) {
        let prefix = `Zone-${zone}`;

        if (sectionIndex != null) {
            prefix += `-section${sectionIndex + 1}`;
        }

        if (detailGroupIndex != null) {
            prefix += `-detailgroup${detailGroupIndex + 1}`;
        }

        if (detailIndex != null) {
            prefix += `-detail${detailIndex + 1}`;
        }

        return postfix != '' ? `${prefix}-${postfix}` : prefix;
    }

    public getZoneNameByName(zone: string): ZoneName {
        switch (zone) {
            case 'Zone 1':
                return ZoneName.Zone1;
            case 'Zone 2':
                return ZoneName.Zone2;
            case 'Zone 3':
                return ZoneName.Zone3;
            case 'Zone 4':
                return ZoneName.Zone4;
            case 'Zone 5':
                return ZoneName.Zone5;
            case 'Zone 6':
                return ZoneName.Zone6;
            case 'Zone 7':
                return ZoneName.Zone7;
            case 'Zone 8':
                return ZoneName.Zone8;
            default:
                throw new Error('Unknown zone name');
        }
    }

    /* ====================================== Utilization calculations functions ====================================== */
    private setZoneCalculationResults() {
        // if some scope check failed, then return empty result and not show result pane
        if (this.utilizations == null || this.utilizations.length === 0) {
            this.zoneUtilizationsAvailable = false;
        }
        else {
            this.zoneUtilizationsAvailable = true;
            this.utilizations?.forEach((zoneResult) => {
                const currentZone = this.getZoneNameByName(zoneResult.zoneId);
                this.zoneCalculationResults[currentZone] = zoneResult;
            });

            this.setUtilizationCalculated();
        }
    }

    private getValueNotPercentageValue(item: UtilizationItem): string {
        if (item.actualStrengtheningNeeded) {
            return '[YES]';
        }

        return '[NO]';
    }

    private checkUtilizationValue(value: UtilizationValue) {
        switch (value) {
            case UtilizationValue.InfinityMinus:
                return '-∞ %';
            case UtilizationValue.InfinityPlus:
                return '∞ %';
            case UtilizationValue.NaN:
                return 'NaN';
            case UtilizationValue.Value:
                return undefined;
        }
    }

    private getActualValueFromProperty(item: UtilizationItem) {
        if (item.actualValue === null && item.actualStrengtheningNeeded === null) {
            return this.checkUtilizationValue(item.utilizationValue);
        }

        if (item.utilizationType === UtilizationType.ExistingMemberStrengtheningNeeded) {
            return this.getValueNotPercentageValue(item);
        }

        if (item.utilizationType === UtilizationType.StrengthenedMemberDrillLength) {
            return this.formatValue(item.actualValue, false);
        }

        if (item.utilizationType === UtilizationType.AdditionalTensileForceFromShear) {
            return this.formatValueWithUnitGroup(item.actualValue, UnitGroup.Force);
        }

        if (item.utilizationType === UtilizationType.StrengthenedMemberNumberOfStrengtheningElements) {
            return item.actualValue.toString();
        }

        return this.formatValue(item.actualValue, true);
    }

    private checkUtilizationItemPercentageBarStyling(item: UtilizationItem) {
        if (item.actualValue > 100 || item.utilizationValue === UtilizationValue.InfinityPlus || item.utilizationValue === UtilizationValue.InfinityMinus) {
            return 'invalid-progress-percent-container';
        }

        return 'progress-percent-container';
    }

    private checkUtilizationItemNumberStyling(item: UtilizationItem) {
        if (item.utilizationType === UtilizationType.ExistingMemberStrengtheningNeeded) {
            if (item.actualStrengtheningNeeded) {
                return 'invalid-value-number';
            }

            return 'number';
        }

        if (item.isPercentage) {
            if (item.actualValue > 100 || item.utilizationValue === UtilizationValue.InfinityPlus || item.utilizationValue === UtilizationValue.InfinityMinus || item.utilizationValue === UtilizationValue.NaN) {
                return 'invalid-value-number';
            }

            return 'number';
        }

        return 'classic-value-number';
    }

    private getUtilizationItemPercentValue(item: UtilizationItem) {
        if (item.utilizationValue === UtilizationValue.InfinityPlus) {
            return Number.POSITIVE_INFINITY;
        }

        if (item.utilizationValue === UtilizationValue.InfinityMinus) {
            return Number.NEGATIVE_INFINITY;
        }

        if (item.utilizationValue === UtilizationValue.NaN) {
            return 0;
        }

        return item.actualValue;
    }

    private getValueProperties(zoneName: ZoneName, props: IZoneUtilPropertyDetail[]): IZoneUtilPropertyDetail[] {
        const propsCalculated: IZoneUtilPropertyDetail[] = [];

        const utilizationsZoneData = this.zoneCalculationResults[zoneName]!;

        for (const prop of props) {
            const detailCalculated = utilizationsZoneData.utilizationItems.find(x => x.utilizationType === prop.utilizationType);

            if (detailCalculated !== undefined) {
                const actualValue = this.getActualValueFromProperty(detailCalculated);

                if (!detailCalculated.isPercentage) {
                    propsCalculated.push({
                        text: prop.text,
                        utilizationVariable: prop.utilizationVariable,
                        utilizationType: prop.utilizationType,
                        utilizationValue: prop.utilizationValue!,
                        isPercentage: detailCalculated.isPercentage,
                        value: actualValue,
                        isVisible: actualValue !== undefined,
                        numberContainerStyle: this.checkUtilizationItemNumberStyling(detailCalculated),
                    });
                }
                else {
                    propsCalculated.push({
                        text: prop.text,
                        utilizationVariable: prop.utilizationVariable,
                        utilizationType: prop.utilizationType,
                        utilizationValue: prop.utilizationValue!,
                        isPercentage: detailCalculated.isPercentage,
                        percentValue: this.getUtilizationItemPercentValue(detailCalculated),
                        value: actualValue,
                        isVisible: actualValue !== undefined,
                        numberContainerStyle: this.checkUtilizationItemNumberStyling(detailCalculated),
                        percentBarStyle: this.checkUtilizationItemPercentageBarStyling(detailCalculated)
                    });
                }
            }
        }

        return propsCalculated;
    }

    private getStrengthenedMemberMaxUtilization(propsCalculated: IZoneUtilPropertyDetail[]) {
        const percentageUtilizations = propsCalculated.filter(x => x.isPercentage);
        if (!percentageUtilizations?.length) {
            return 0;
        }

        const max = Math.max(...percentageUtilizations.map(x => x.percentValue!));
        return this.roundValue(max);
    }

    private checkExistingMembersNotNull(properties: IZoneUtilPropertyDetail[], utilizationType: UtilizationType) {
        return properties.find(x => x.utilizationType === utilizationType) !== undefined;
    }

    private getExistingMemberMaxUtilization(propsCalculated: IZoneUtilPropertyDetail[]) {
        const onylPercentangeUtilizations = propsCalculated.filter(x => x.isPercentage);

        if (onylPercentangeUtilizations !== undefined && onylPercentangeUtilizations.length !== 0) {
            const concreteResistanceExists = this.checkExistingMembersNotNull(onylPercentangeUtilizations, UtilizationType.ExistingMemberConcreteResistance);
            const shearReinforcementExists = this.checkExistingMembersNotNull(onylPercentangeUtilizations, UtilizationType.ExistingMemberShearReinforcement);
            const strutResistanceExists = this.checkExistingMembersNotNull(onylPercentangeUtilizations, UtilizationType.ExistingMemberStrutResistance);

            let minConcreteShearResistance: number;

            if (concreteResistanceExists && shearReinforcementExists) {
                minConcreteShearResistance = this.getMaxUtilizationConcreteShearResistanceExists(onylPercentangeUtilizations);
            }
            else if (!concreteResistanceExists && shearReinforcementExists) {
                minConcreteShearResistance = propsCalculated.find(x => x.utilizationType === UtilizationType.ExistingMemberShearReinforcement)!.percentValue!;
            }
            else if (concreteResistanceExists && !shearReinforcementExists) {
                minConcreteShearResistance = propsCalculated.find(x => x.utilizationType === UtilizationType.ExistingMemberConcreteResistance)!.percentValue!;
            }

            if (strutResistanceExists) {
                const strutResistance = onylPercentangeUtilizations.find(x => x.utilizationType === UtilizationType.ExistingMemberStrutResistance)!.percentValue;
                return this.getMaxUtilizationStrutResistanceExists(minConcreteShearResistance!, strutResistance!);
            }

            return this.roundValue(minConcreteShearResistance!);
        }

        return 0;
    }

    public getMaxUtilizationConcreteShearResistanceExists(onylPercentangeUtilizations: IZoneUtilPropertyDetail[]) {
        if (onylPercentangeUtilizations.find(x => x.utilizationType === UtilizationType.ExistingMemberConcreteResistance)!.percentValue! <
            onylPercentangeUtilizations.find(x => x.utilizationType === UtilizationType.ExistingMemberShearReinforcement)!.percentValue!) {
            return onylPercentangeUtilizations.find(x => x.utilizationType === UtilizationType.ExistingMemberConcreteResistance)!.percentValue!;
        }

        return onylPercentangeUtilizations.find(x => x.utilizationType === UtilizationType.ExistingMemberShearReinforcement)!.percentValue!;
    }

    public getMaxUtilizationStrutResistanceExists(minConcreteShearResistance: number, strutResistance: number) {
        if (minConcreteShearResistance > strutResistance) {
            return this.roundValue(minConcreteShearResistance);
        }

        return this.roundValue(strutResistance);
    }

    public checkMaxUtilizationInvalid(value: number) {
        if (value > 100 || isNaN(value) || value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY) {
            return true;
        }

        return false;
    }

    private setUtilizationCalculated() {
        for (const zone of this.zones) {
            const sectionsCalculated: IZoneUtilGroupSectionCalculated[] = [];

            for (const section of this.overlayUtilization.sections) {
                // properties of each section
                const propsCalculated = this.getValueProperties(zone, section.properties);

                // sections for each zone
                const sectionCalculated: IZoneUtilGroupSectionCalculated = {
                    id: section.id,
                    section: section.section,
                    control: this.getSectionCollapsingControl(section.section, zone),
                    properties: propsCalculated,
                    header: section.header,
                    hasPercentage: true,
                };

                sectionsCalculated.push(sectionCalculated);
            }

            if (sectionsCalculated.length > 0) {
                // When whole zone is collapsed, show the max of both above. If Strengthened member section is not shown (no strengthening elements), take values only from the Existing member section.
                this.setMaxUtilizationsForZones(zone, sectionsCalculated);
            }
        }
    }

    private setMaxUtilizationsForZones(zone: ZoneName, sectionsCalculated: IZoneUtilGroupSectionCalculated[]) {
        // When whole zone is collapsed, show the max of both above. If Strengthened member section is not shown (no strengthening elements), take values only from the Existing member section.
        let maxUtilization = 0;
        for (const section of sectionsCalculated) {
            if (section.properties !== undefined && section.properties.length !== 0) {
                maxUtilization = this.setSectionMaxUtilization(section);
            }
        }

        this.utilizationsCalculated[zone] = {
            sections: sectionsCalculated,
            maxUtilizationZone: this.roundValue(maxUtilization),
            maxUtilizationInvalid: this.checkMaxUtilizationInvalid(maxUtilization)
        };
    }

    private setSectionMaxUtilization(section: IZoneUtilGroupSectionCalculated) {
        let maxUtilization = 0;
        if (section.section === UtilizationSection.ExistingMember) {
            section.maxUtilization = this.getExistingMemberMaxUtilization(section.properties);
            section.maxUtilizationInvalid = this.checkMaxUtilizationInvalid(section.maxUtilization);
            maxUtilization = section.maxUtilization!;
        }
        else {
            section.maxUtilization = this.getStrengthenedMemberMaxUtilization(section.properties);
            section.maxUtilizationInvalid = this.checkMaxUtilizationInvalid(section.maxUtilization);
            if (section.maxUtilization > maxUtilization) {
                maxUtilization = section.maxUtilization!;
            }
        }

        return maxUtilization;
    }

    /* ====================================== Collapsing control functions ====================================== */
    private getSectionCollapsingControl(section: UtilizationSection, zone: ZoneName): CollapsingControls {
        return section == UtilizationSection.ExistingMember
            ? this.getExistingMemberCollapsingControl(zone)
            : this.getStrengthenedMemberCollapsingControl(zone);
    }

    private initConcreteCollapsing() {
        for (const zone of this.zones) {
            if (this.zonesCollapsing[zone] == undefined) {
                const sectionId = this.getZoneCollapsingControl(zone);
                this.zonesCollapsing[zone] = {
                    header: this.userSettingsService.isSectionCollapsed(sectionId),
                    sections: this.getInitSectionCollapsingArray(zone)
                };
            }
        }
    }

    private getInitSectionCollapsingArray(zone: ZoneName) {
        const sections: ICollapsingSection[] = [];
        for (const section of this.overlayUtilization.sections) {
            const controlId = this.getSectionCollapsingControl(section.section, zone);
            sections.push({
                header: this.userSettingsService.isSectionCollapsed(controlId),
                section: section.section,
                groupDetails: section.properties.map(() => false),
            });
        }
        return sections;
    }

    public checkDefineStrengtheningElement(zone: ZoneName, section: UtilizationSection): boolean {
        if (section === UtilizationSection.ExistingMember) {
            return true;
        }

        switch (zone) {
            case ZoneName.Zone1:
                return this.designDetails!.properties.zone1StrengtheningElementDefinition;
            case ZoneName.Zone2:
                return this.designDetails!.properties.zone2StrengtheningElementDefinition!;
            case ZoneName.Zone3:
                return this.designDetails!.properties.zone3StrengtheningElementDefinition!;
            case ZoneName.Zone4:
                return this.designDetails!.properties.zone4StrengtheningElementDefinition!;
            case ZoneName.Zone5:
                return this.designDetails!.properties.zone5StrengtheningElementDefinition;
            case ZoneName.Zone6:
                return this.designDetails!.properties.zone6StrengtheningElementDefinition;
            case ZoneName.Zone7:
                return this.designDetails!.properties.zone7StrengtheningElementDefinition;
            case ZoneName.Zone8:
                return this.designDetails!.properties.zone8StrengtheningElementDefinition;
            default:
                return false;
        }
    }

    public getCollapseComponentId(
        zone: ZoneName,
        postfix: string,
        sectionIndex?: number,
        detailGroupIndex?: number,
        detailIndex?: number
    ) {
        const componentId = this.getComponentId(zone, postfix, sectionIndex, detailGroupIndex, detailIndex);
        return `control-${componentId}-collapse`;
    }

    public getZoneCollapsingControl(zone: ZoneName): CollapsingControls {
        switch (zone) {
            case ZoneName.Zone1:
                return CollapsingControls.OverlayZone1Utilization;
            case ZoneName.Zone2:
                return CollapsingControls.OverlayZone2Utilization;
            case ZoneName.Zone3:
                return CollapsingControls.OverlayZone3Utilization;
            case ZoneName.Zone4:
                return CollapsingControls.OverlayZone4Utilization;
            case ZoneName.Zone5:
                return CollapsingControls.OverlayZone5Utilization;
            case ZoneName.Zone6:
                return CollapsingControls.OverlayZone6Utilization;
            case ZoneName.Zone7:
                return CollapsingControls.OverlayZone7Utilization;
            case ZoneName.Zone8:
                return CollapsingControls.OverlayZone8Utilization;
            default:
                throw new Error('Cannot get collapsing control id of unknown zone.');
        }
    }

    private getExistingMemberCollapsingControl(zone: ZoneName): CollapsingControls {
        switch (zone) {
            case ZoneName.Zone1:
                return CollapsingControls.OverlayZone1ExistingMemberUtilization;
            case ZoneName.Zone2:
                return CollapsingControls.OverlayZone2ExistingMemberUtilization;
            case ZoneName.Zone3:
                return CollapsingControls.OverlayZone3ExistingMemberUtilization;
            case ZoneName.Zone4:
                return CollapsingControls.OverlayZone4ExistingMemberUtilization;
            case ZoneName.Zone5:
                return CollapsingControls.OverlayZone5ExistingMemberUtilization;
            case ZoneName.Zone6:
                return CollapsingControls.OverlayZone6ExistingMemberUtilization;
            case ZoneName.Zone7:
                return CollapsingControls.OverlayZone7ExistingMemberUtilization;
            case ZoneName.Zone8:
                return CollapsingControls.OverlayZone8ExistingMemberUtilization;
            default:
                throw new Error('Cannot get existing member collapsing control for unsupported zone.');
        }
    }

    private getStrengthenedMemberCollapsingControl(zone: ZoneName): CollapsingControls {
        switch (zone) {
            case ZoneName.Zone1:
                return CollapsingControls.OverlayZone1StrengthenedMemberUtilization;
            case ZoneName.Zone2:
                return CollapsingControls.OverlayZone2StrengthenedMemberUtilization;
            case ZoneName.Zone3:
                return CollapsingControls.OverlayZone3StrengthenedMemberUtilization;
            case ZoneName.Zone4:
                return CollapsingControls.OverlayZone4StrengthenedMemberUtilization;
            case ZoneName.Zone5:
                return CollapsingControls.OverlayZone5StrengthenedMemberUtilization;
            case ZoneName.Zone6:
                return CollapsingControls.OverlayZone6StrengthenedMemberUtilization;
            case ZoneName.Zone7:
                return CollapsingControls.OverlayZone7StrengthenedMemberUtilization;
            case ZoneName.Zone8:
                return CollapsingControls.OverlayZone8StrengthenedMemberUtilization;
            default:
                throw new Error('Cannot get strengthened member collapsing control for unsupported zone.');
        }
    }

    public onCollapsedChanged(collapsed: boolean, zone: ZoneName, zoneGroup: IZoneUtilPropertyGroupCalculated) {
        const sectionId = this.getZoneCollapsingControl(zone);
        (this.zonesCollapsing[zone]!).header = collapsed;
        zoneGroup.maxUtilizationInvalid = this.checkMaxUtilizationInvalid(zoneGroup.maxUtilizationZone!);
        this.userSettingsService.setSectionCollapsed(sectionId, collapsed);
    }

    public onSectionCollapseChanged(collapsed: boolean, zone: ZoneName, index: number, section: UtilizationSection, sectionGroup: IZoneUtilGroupSection) {
        const collapsingControl = this.getSectionCollapsingControl(section, zone);
        (this.zonesCollapsing[zone]!).sections[index].header = collapsed;
        sectionGroup.maxUtilizationInvalid = this.checkMaxUtilizationInvalid(sectionGroup.maxUtilization!);
        this.userSettingsService.setSectionCollapsed(collapsingControl, collapsed);
    }

    public getZoneCollapseState(zone: ZoneName): boolean {
        const sectionId = this.getZoneCollapsingControl(zone);
        return this.userSettingsService.isSectionCollapsed(sectionId);
    }

    public getSectionCollapseState(zone: ZoneName, index: number, section: UtilizationSection): boolean {
        const collapsingControl = this.getSectionCollapsingControl(section, zone);
        return this.userSettingsService.isSectionCollapsed(collapsingControl);
    }

    /* ====================================== Utilazation items value format functions ====================================== */

    public formatValue(value: number, isPercentage?: boolean) {
        if (Number.isNaN(value) || value == Number.POSITIVE_INFINITY || value == Number.NEGATIVE_INFINITY) {
            return this.numberService.format(value);
        }

        if (isPercentage) {
            const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Percentage);
            const internalUnit = this.unitService.getInternalUnit(UnitGroup.Percentage);
            const defaultPrecision = this.unitService.getPrecision(defaultUnit);
            const valueToFormat = this.unitService.convertUnitValueArgsToUnit(this.roundValue(value), internalUnit, defaultUnit, true);

            return this.unitService.formatNumber(valueToFormat, defaultPrecision) + ' ' + this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Percentage));
        }

        return this.formatValueWithUnitGroup(value, UnitGroup.Length);
    }

    public formatValueWithUnitGroup(value: number, unitGroup: UnitGroup) {
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup);
        const internalUnit = this.unitService.getInternalUnit(unitGroup);
        const defaultPrecision = this.unitService.getPrecision(defaultUnit);
        const valueToFormat = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unitService.formatNumber(valueToFormat, defaultPrecision) + ' ' + this.unitService.formatUnit(this.unitService.getDefaultUnit(unitGroup));
    }

    private roundValue(value: number) {
        return Math.ceil(value);
    }
}
