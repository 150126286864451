import {
    Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation
} from '@angular/core';
import { LocalizationService } from '../../services/localization.service';

@Component({
    templateUrl: './export-report-specification-text.component.html',
    styleUrls: ['./export-report-specification-text.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ExportReportSpecificationTextComponent implements OnChanges {
    @Input()
    public specificationText?: string;

    @Input()
    public submitted = false;

    @Output()
    public specificationTextChange = new EventEmitter<string>();

    public isLoaded = false;
    public specificationTextChanged = false;
    private specificationTextDefault?: string;


    constructor(
        public localizationService: LocalizationService
    ) { }


    public ngOnChanges(): void {
        if (!this.isLoaded) {
            this.specificationTextDefault = this.specificationText;
            this.isLoaded = true;
        }
    }

    public handleSpecificationTextChanged(specificationText: string) {
        this.handleSpecificationTextChangedInternal(specificationText, true);
    }

    public setDefaultSpecificationText() {
        this.handleSpecificationTextChangedInternal(this.specificationTextDefault, false);
    }

    private handleSpecificationTextChangedInternal(specificationText: string | undefined, specificationTextChanged: boolean) {
        this.specificationText = specificationText;
        this.specificationTextChanged = specificationTextChanged;
        this.specificationTextChange.emit(specificationText);
    }
}
