import { CodeList } from './code-list';
import { LocationFactorEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';


export class LocationFactor extends CodeList {

    public static fromService(codeList: LocationFactorEntityC2C) {
        return new LocationFactor(CodeList.baseFromService('LocationFactorEntityC2C', codeList, {
            nameResourceKey: codeList.displayKey,
            name: codeList.displayKey
        }));
    }

    public override cloneDeep(): LocationFactor {
        return super.cloneDeep() as LocationFactor;
    }
}
