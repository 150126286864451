import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { RoutingServiceBase } from '@profis-engineering/pe-ui-common/services/routing.common';
import { Userpilot } from 'userpilot';

@Injectable({
    providedIn: 'root'
})
export class RoutingService extends RoutingServiceBase {
    constructor(
        private router: Router,
        private ngZone: NgZone
    ) {
        super();
    }

    public get currentUrl() {
        return new URL(window.location.href);
    }

    public get currentPath() {
        return this.currentUrl.pathname;
    }

    public getUrl(url: string, queryParams?: { [key: string]: any }) {
        const retVal = this.router.parseUrl(url);
        retVal.queryParams = queryParams;
        return retVal.toString();
    }

    public async reloadWindow(url: string) {
        NgZone.assertInAngularZone();

        await this.router.navigateByUrl('/', { skipLocationChange: true });
        await this.router.navigate([url]);
        Userpilot.reload();
    }

    public async navigateToUrl(url: string) {
        NgZone.assertInAngularZone();

        const navigationSucceeded = await this.router.navigateByUrl(url);
        Userpilot.reload();

        return navigationSucceeded;
    }

    public async navigateToUrlWithData(url: string, dataToSend: { [key: string]: any }){
        const navigationSucceeded = await this.router.navigate([url], { state: dataToSend });
        Userpilot.reload();

        return navigationSucceeded;
    }
}
