<div class="resizer-row"
    *ngIf="isDataOk && dataLoaded"
    [class.loads-row]="!isAdd"
    [class.loads-row-first]="!isAdd && loadIndex == 0"
    [class.new-load]="isAdd"
    [class.dynamic]="helper.showDynamic"
    [class.per-bar]="helper.isPerBar"
    [class.anchor-theory]="isJointsHNA"
    [class.sustained-loads]="helper.isExtensionsOrJointsHNA"
    [class.decisive-row]="isPirDecisive"
    [class.overutilized]="isLCOverutilized(load)">
    <!-- Active -->
    <div class="column-selected" *ngIf="!helper.isPerBarPir">
        <pe-radio-button
            *ngIf="!isAdd"
            id="LC-table-select-combination-radio-button-{{loadIndex != null ? loadIndex : 'new-load'}}"
            class="selected-container selected-radio-button"
            [tooltip]="translate('Agito.Hilti.C2C.Loads.LoadSelect.TooltipText')"
            [disableTooltip]="disabled"
            [items]="selectedLoadItems"
            [selectedValue]="selectedLoadId"
            (selectedValueChange)="selectLoad()"
            [disabled]="disabled || submitted">
        </pe-radio-button>
    </div>

    <!-- Rank -->
    <div class="column-rank" *ngIf="!helper.isPerBarPir">
        <div class="name-container" [ngbTooltip]="load.name">
            <div class="text">{{ loadIndex != null ? loadIndex + 1 : 0 }}</div>
        </div>
    </div>

    <!-- Name -->
    <div class="column-name resizer-column bordered-column" [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.name) }">
        <div id="LC-table-combination-name-textbox-{{loadIndex != null ? loadIndex : 'new-load'}}"
            class="name-container"
            [ngbTooltip]="load.description != null ? load.description : ''">
            <pe-text-box
                id="{{loadIndex != null ? 'LC-table-combination-name-textbox-' + loadIndex : 'LC-table-add-new-combination-name-textbox'}}"
                class="input name-input clip"
                [borderTop]="'None'"
                [borderBottom]="'None'"
                [borderLeft]="'None'"
                [borderRight]="'None'"
                [marginBottom]="'None'"
                [display]="textBoxDisplay"
                [background]="textBoxBackground"
                [height]="nameInputHeight"
                [value]="load.name"
                (inputBlur)="loadNameChanged($any($event).detail)"
                [updateOnBlur]="true"
                [disabled]="helper.isPerBarPir">
            </pe-text-box>

            <div class="text clip" *ngIf="load.isWizardGenerated">{{load.name}}</div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <!-- Load type -->
    <!-- Size of this column cannot be edited by the user, so the element after it has to include the left-resizer. -->
    <div class="column-type" [ngStyle]="{ 'flex-basis': helper.columnTypeWidth + 'px' }" *ngIf="!helper.isLoadTypeZoneFatigue">
        <pe-toggle-button-group
            class="type-buttons"
            [model]="load.activeLoadType"
            (modelChange)="loadActiveLoadTypeChange($any($event).detail)"
            [items]="loadTypeToggleItems"
            [disabled]="submitted || load.isWizardGenerated || helper.isPerBarPir">
        </pe-toggle-button-group>
    </div>

    <!-- Loads legend -->
    <div class="column-loads C2C" *ngIf="helper.showDynamic">
        <ng-template #tedMinTooltip>
            <span [innerHtml]="tedVedMinTranslation"></span>
        </ng-template>

        <ng-template #tedMaxTooltip>
            <span [innerHtml]="tedVedMaxTranslation"></span>
        </ng-template>

        <div class="name-container" [ngbTooltip]="tedMinTooltip">
            <div class="text clip bordered-text" [innerHtml]="tedVedMinTranslation"></div>
        </div>

        <div class="dynamic-container" [ngbTooltip]="tedMaxTooltip">
            <div class="text clip bordered-text"><span [innerHtml]="tedVedMaxTranslation"></span></div>
        </div>
    </div>

    <!-- Per Bar Top/Bottom -->
    <div class="column-reinforcement" *ngIf="helper.isPerBar">
        <div id="column-reinforcement-top" class="name-container">
            <div class="text clip bordered-text">{{perBarTopTranslation}}</div>
        </div>

        <div id="column-reinforcement-bottom" class="name-container">
            <div class="text clip bordered-text"><span>{{perBarBottomTranslation}}</span></div>
        </div>
    </div>

    <div class="column-loads" *ngIf="helper.isExtensionsOrJointsHNA">
        <div class="name-container">
            <div class="text clip bordered-text">{{anchorTheoryDesignTranslation}}</div>
        </div>

        <div class="name-container">
            <div class="text clip bordered-text"><span>{{anchorTheorySustainedTranslation}}</span></div>
        </div>
    </div>

    <!-- Stress -->
    <div *ngIf="helper.columnVisibilities.tedX"
        class="column-stress column-tedx resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.tedX) }">
        <div class="column-stress">
            <div class="general-number">
                <div class="number-container">
                    <pe-numeric-text-box
                        id="{{loadIndex != null ? 'LC-table-tau-x-textbox-' + loadIndex : 'LC-table-add-new-combination-tau-x-textbox'}}"
                        class="input number-input clip"
                        [borderTop]="'None'"
                        [borderBottom]="'None'"
                        [borderLeft]="'None'"
                        [borderRight]="'None'"
                        [marginBottom]="'None'"
                        [display]="textBoxDisplay"
                        [background]="textBoxBackground"
                        *ngIf="helper.isShearStress"
                        [value]="load.tedX"
                        (valueChange)="loadValueChange('tedX', $any($event).detail)"
                        [showStepper]="false"
                        [unit]="helper.zoneUnit"
                        [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_TedX.id)"
                        [appendUnit]="false"
                        [updateOnBlur]="true"
                        [required]="true"
                        [minValue]="helper.tedXRange?.min"
                        [maxValue]="helper.tedXRange?.max"
                        [disabled]="submitted || load.isWizardGenerated">
                    </pe-numeric-text-box>
                </div>
            </div>
        </div>

        <div class="resizer-left" (mousedown)="beginColumnResizing($event)"></div>
        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.tedY"
        class="column-stress column-tedy resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.tedY) }">
        <div class="column-stress">
            <div class="general-number">
                <div class="number-container">
                    <pe-numeric-text-box
                        id="{{loadIndex != null ? 'LC-table-tau-y-force-textbox-' + loadIndex : 'LC-table-add-new-combination-tau-y-textbox'}}"
                        class="input number-input clip"
                        [borderTop]="'None'"
                        [borderBottom]="'None'"
                        [borderLeft]="'None'"
                        [borderRight]="'None'"
                        [marginBottom]="'None'"
                        [display]="textBoxDisplay"
                        [background]="textBoxBackground"
                        *ngIf="helper.isShearStress"
                        [value]="load.tedY"
                        (valueChange)="loadValueChange('tedY', $any($event).detail)"
                        [showStepper]="false"
                        [unit]="helper.zoneUnit"
                        [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_TedY.id)"
                        [appendUnit]="false"
                        [updateOnBlur]="true"
                        [required]="true"
                        [minValue]="helper.tedYRange?.min"
                        [maxValue]="helper.tedYRange?.max">
                    </pe-numeric-text-box>
                </div>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <!-- Forces -->
    <div *ngIf="helper.columnVisibilities.forceVx"
        class="column-force column-vx resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceVx) }">
        <div class="column-force">
            <div class="general-number">
                <div class="number-container">
                    <pe-numeric-text-box
                        id="{{loadIndex != null ? 'LC-table-Vx-force-textbox-' + loadIndex : 'LC-table-add-new-combination-Vx-textbox'}}"
                        class="input vx-input number-input clip"
                        [borderTop]="'None'"
                        [borderBottom]="'None'"
                        [borderLeft]="'None'"
                        [borderRight]="'None'"
                        [marginBottom]="'None'"
                        [display]="textBoxDisplay"
                        [background]="textBoxBackground"
                        *ngIf="!helper.isPerBar"
                        [value]="valueOrNullIfPropHiddenOrDisabled(propertyMetaData.Loads_C2C_ForceX.id, load.forceX)"
                        (valueChange)="loadValueChange('forceX', $any($event).detail)"
                        [showStepper]="false"
                        [unit]="helper.forceUnit"
                        [appendUnit]="false"
                        [updateOnBlur]="true"
                        [required]="true"
                        [minValue]="helper.forceXRange?.min"
                        [maxValue]="helper.forceXRange?.max"
                        [disabled]="isLoadPropertyDisabled(propertyMetaData.Loads_C2C_ForceX.id)">
                    </pe-numeric-text-box>
                </div>
            </div>
        </div>

        <div class="resizer-left" (mousedown)="beginColumnResizing($event)"></div>
        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.forceVy"
        class="column-force column-vy resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceVy) }">
        <div class="column-force">
            <div class="general-number">
                <div class="number-container">
                    <pe-numeric-text-box
                        id="{{loadIndex != null ? 'LC-table-Vy-force-textbox-' + loadIndex : 'LC-table-add-new-combination-Vy-textbox'}}"
                        class="input vy-input number-input clip"
                        [borderTop]="'None'"
                        [borderBottom]="'None'"
                        [borderLeft]="'None'"
                        [borderRight]="'None'"
                        [marginBottom]="'None'"
                        [display]="textBoxDisplay"
                        [background]="textBoxBackground"
                        [value]="load.forceY"
                        (valueChange)="loadValueChange('forceY', $any($event).detail)"
                        [showStepper]="false"
                        [unit]="helper.forceUnit"
                        [appendUnit]="false"
                        [updateOnBlur]="true"
                        [required]="true"
                        [minValue]="helper.forceYRange?.min"
                        [maxValue]="helper.forceYRange?.max"
                        [disabled]="isLoadPropertyDisabled(propertyMetaData.Loads_C2C_ForceY.id)">
                    </pe-numeric-text-box>
                </div>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.forceN"
        class="column-force column-n resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceN) }">
        <div class="general-number" *ngIf="!helper.isOverlayHNA">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-N-force-textbox-' + loadIndex : 'LC-table-add-new-combination-N-textbox'}}"
                    class="input n-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.forceZ"
                    (valueChange)="loadValueChange('forceZ', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.forceUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.forceZRange?.min"
                    [maxValue]="helper.forceZRange?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="general-number" *ngIf="helper.isPerBar">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-bottom-N-force-textbox-' + loadIndex : 'LC-table-add-new-combination-bottom-N-textbox'}}"
                    class="input n-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.bottomForceZ"
                    (valueChange)="loadValueChange('bottomForceZ', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.forceUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.bottomForceZRange?.min"
                    [maxValue]="helper.bottomForceZRange?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="anchor-theory-number" *ngIf="helper.isExtensionsOrJointsHNA">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-sustained-N-force-textbox-' + loadIndex : 'LC-table-add-new-combination-sustained-N-textbox'}}"
                    class="input n-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.sustainedForceZ"
                    (valueChange)="loadValueChange('sustainedForceZ', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.forceUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.sustainedForceZRange?.min"
                    [maxValue]="helper.sustainedForceZRange?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <!-- Moments -->
    <div *ngIf="helper.columnVisibilities.momentMx"
        class="column-moment column-mx resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMx) }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-Mx-moment-textbox-' + loadIndex : 'LC-table-add-new-combination-Mx-textbox'}}"
                    class="input mx-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    *ngIf="!helper.isOverlayHNA"
                    [value]="load.momentX"
                    (valueChange)="loadValueChange('momentX', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.momentXRange?.min"
                    [maxValue]="helper.momentXRange?.max"
                    [disabled]="submitted || load.isWizardGenerated  || helper.isPropertyDisabled(propertyMetaData.Loads_C2C_MomentX.id)">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-sustained-Mx-moment-textbox-' + loadIndex : 'LC-table-add-new-combination-sustained-Mx-textbox'}}"
                    class="input my-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    *ngIf="helper.isMomentSustainedEnabled"
                    [value]="load.sustainedMomentX"
                    (valueChange)="loadValueChange('sustainedMomentX', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.sustainedMomentXRange?.min"
                    [maxValue]="helper.sustainedMomentXRange?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.momentMy"
        class="column-moment column-my resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMy) }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-My-moment-textbox-' + loadIndex : 'LC-table-add-new-combination-My-textbox'}}"
                    class="input my-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    *ngIf="helper.isPirEuOrAus"
                    [value]="valueOrNullIfPropHiddenOrDisabled(propertyMetaData.Loads_C2C_MomentY.id, load.momentY)"
                    (valueChange)="loadValueChange('momentY', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.momentYRange?.min"
                    [maxValue]="helper.momentYRange?.max"
                    [disabled]="submitted || load.isWizardGenerated  || helper.isPropertyDisabled(propertyMetaData.Loads_C2C_MomentY.id)">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="anchor-theory-number">
            <div class="number-container"></div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.momentMz"
        class="column-moment column-mz resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMz) }">
        <div class="general-number">
            <div class="number-container"></div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <!-- Zones -->
    <div *ngIf="helper.columnVisibilities.zone"
        class="column-zone column-zone-generic resizer-column"
        [ngStyle]="helper.zoneOneFlex">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-zone-ted-' + loadIndex : 'LC-table-add-new-combination-zone-generic-textbox'}}"
                    class="input zone-generic-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.zoneGeneric"
                    (valueChange)="loadValueChange('zoneGeneric', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.zoneUnit"
                    [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_ZoneGeneric.id)"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.zoneGenericRange?.min"
                    [maxValue]="helper.zoneGenericRange?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number" *ngIf="helper.isLoadTypeZoneFatigue">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-zone-ted-dynamic-' + loadIndex : 'LC-table-add-new-combination-zone-generic-dynamic-textbox'}}"
                    class="input zone-generic-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.dynamicZoneGeneric"
                    (valueChange)="loadValueChange('dynamicZoneGeneric', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.zoneUnit"
                    [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_DynamicZoneGeneric.id)"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicZoneGenericRange?.min"
                    [maxValue]="helper.dynamicZoneGenericRange?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-left" (mousedown)="beginColumnResizing($event)"></div>
        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.zone1"
        class="column-zone column-zone-1 resizer-column"
        [ngStyle]="helper.zoneOneFlex">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-zone-1-' + loadIndex : 'LC-table-add-new-combination-zone-1-textbox'}}"
                    class="input zone-1-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.zone1"
                    (valueChange)="loadValueChange('zone1', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.zoneUnit"
                    [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_Zone1.id)"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.zone1Range?.min"
                    [maxValue]="helper.zone1Range?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number" *ngIf="helper.isLoadTypeZoneFatigue">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-zone-1-dynamic-' + loadIndex : 'LC-table-add-new-combination-zone-1-dynamic-textbox'}}"
                    class="input zone-1-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.dynamicZone1"
                    (valueChange)="loadValueChange('dynamicZone1', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.zoneUnit"
                    [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_DynamicZone1.id)"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicZone1Range?.min"
                    [maxValue]="helper.dynamicZone1Range?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-left" (mousedown)="beginColumnResizing($event)"></div>
        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.zone2"
        class="column-zone column-zone-2 resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.zone2) }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-zone-2-' + loadIndex : 'LC-table-add-new-combination-zone-2-textbox'}}"
                    class="input zone-2-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.zone2"
                    (valueChange)="loadValueChange('zone2', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.zoneUnit"
                    [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_Zone2.id)"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.zone2Range?.min"
                    [maxValue]="helper.zone2Range?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number" *ngIf="helper.isLoadTypeZoneFatigue">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-zone-2-dynamic-' + loadIndex : 'LC-table-add-new-combination-zone-2-dynamic-textbox'}}"
                    class="input zone-2-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.dynamicZone2"
                    (valueChange)="loadValueChange('dynamicZone2', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.zoneUnit"
                    [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_DynamicZone2.id)"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicZone2Range?.min"
                    [maxValue]="helper.dynamicZone2Range?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.zone3"
        class="column-zone column-zone-3 resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.zone3) }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-zone-3-' + loadIndex : 'LC-table-add-new-combination-zone-3-textbox'}}"
                    class="input zone-3-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.zone3"
                    (valueChange)="loadValueChange('zone3', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.zoneUnit"
                    [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_Zone3.id)"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.zone3Range?.min"
                    [maxValue]="helper.zone3Range?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number" *ngIf="helper.isLoadTypeZoneFatigue">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-zone-3-dynamic-' + loadIndex : 'LC-table-add-new-combination-zone-3-dynamic-textbox'}}"
                    class="input zone-3-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.dynamicZone3"
                    (valueChange)="loadValueChange('dynamicZone3', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.zoneUnit"
                    [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_DynamicZone3.id)"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicZone3Range?.min"
                    [maxValue]="helper.dynamicZone3Range?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.zone4"
        class="column-zone column-zone-4 resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.zone4) }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-zone-4-' + loadIndex : 'LC-table-add-new-combination-zone-4-textbox'}}"
                    class="input zone-4-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.zone4"
                    (valueChange)="loadValueChange('zone4', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.zoneUnit"
                    [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_Zone4.id)"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.zone4Range?.min"
                    [maxValue]="helper.zone4Range?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number" *ngIf="helper.isLoadTypeZoneFatigue">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-zone-4-dynamic-' + loadIndex : 'LC-table-add-new-combination-zone-4-dynamic-textbox'}}"
                    class="input zone-4-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [background]="textBoxBackground"
                    [value]="load.dynamicZone4"
                    (valueChange)="loadValueChange('dynamicZone4', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.zoneUnit"
                    [precision]="helper.getPrecision(helper.zoneUnit, propertyMetaData.Loads_C2C_DynamicZone4.id)"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicZone4Range?.min"
                    [maxValue]="helper.dynamicZone4Range?.max"
                    [disabled]="submitted || load.isWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <!-- PIR EU + PIR AS + PIR HNA Length -->
    <div id="LC-table-length-section-{{loadIndex != null ? loadIndex : 'new-load'}}"
        *ngIf="!isAdd && helper.showLength"
        class="column-length resizer-column right-side-border"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.length) }">
            <div
                id="LC-table-length-{{loadIndex != null ? loadIndex : 'new-load'}}"
                class="length-number clip notranslate"
                [class.invalid-value]="!load.maxInstallationLength || load.hasScopeChecksError"
            >
                {{ getLCMaxInstallationLengthText(load) }}
            </div>
    </div>

    <!-- Results -->
    <!-- isAdd == true -->
    <div *ngIf="isAdd && (helper.showLength || helper.columnVisibilities.utilization)"
        class="column-add resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.showLength
            ? helper.resizerColumnWidth?.length
            : helper.resizerColumnWidth?.utilization),
        'flex-grow': helper.showLength || helper.showUtilizations ? null : '0'}"
    >
        <div class="add-buttons">
            <pe-button
                id="LC-table-add-new-combination-confirm-button"
                class="confirm-add-button button button-default"
                [look]="'DefaultIcon'"
                (click)="addNewLoad()"
                [disabled]="submitted"
                [ngbTooltip]="translate('Agito.Hilti.C2C.Loads.ConfirmAdd')">

                <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-ok"></span>
            </pe-button>

            <pe-button
                id="LC-table-add-new-combination-remove-button"
                class="cancel-add-button button button-default"
                [look]="'DefaultIcon'"
                (click)="helper.addingNewLoad = false"
                [disabled]="submitted"
                [ngbTooltip]="translate('Agito.Hilti.C2C.Loads.CancelAdd')">

                <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-x"></span>
            </pe-button>
        </div>
    </div>

    <div class="column-messages" *ngIf="isAdd"></div>

    <div class="column-delete" *ngIf="isAdd"></div>

    <!-- HNA Overlay utilization -->
    <!-- isAdd == false -->
    <div id="LC-table-utilization-section-{{loadIndex != null ? loadIndex : 'new-load'}}"
        *ngIf="!isAdd && helper.isOverlayHNA"
        class="column-utilization resizer-column right-side-border"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.utilization) }">

        <ng-template #utilizationTooltip>
            <ul class="utilization-container">
                <li class="utilization-container-item" *ngIf="load.tension?.value != null">
                    <span
                        class="text">{{translate('Agito.Hilti.Profis3.Loads.Utilization.Tooltip.Tension').replace('{tension}', helper.formatPercentage(load.tension?.value))}}</span>
                </li>
                <li class="utilization-container-item" *ngIf="load.shear?.value != null">
                    <span
                        class="text">{{translate('Agito.Hilti.Profis3.Loads.Utilization.Tooltip.Shear').replace('{shear}', helper.formatPercentage(load.shear?.value))}}</span>
                </li>
            </ul>
        </ng-template>

        <div class="general-utilization">
            <div *ngIf="!isUtilizationCompact && design.isHnaJointsAnchorTheory"
                id="LC-table-utilization-tension-{{loadIndex != null ? loadIndex : 'new-load'}}"
                class="utilization-number clip notranslate"
                [class.invalid-value]="!helper.isTensionInvalid(load)"
                [class.neutral-value]="load.tension == null">{{load.tension?.percentage ?? noValueString}}</div>

            <div *ngIf="(!isUtilizationCompact && design.isHnaJointsAnchorTheory) || !design.isHnaJointsAnchorTheory"
                id="LC-table-utilization-shear-{{loadIndex != null ? loadIndex : 'new-load'}}"
                class="utilization-number clip notranslate"
                [class.invalid-value]="!helper.isShearInvalid(load)"
                [class.neutral-value]="load.shear == null">{{load.shear?.percentage ?? noValueString}}</div>

            <div *ngIf="design.isHnaJointsAnchorTheory"
                id="LC-table-utilization-max-{{loadIndex != null ? loadIndex : 'new-load'}}"
                class="utilization-number clip notranslate"
                [class.invalid-value]="!helper.isTotalInvalid(load)"
                [class.neutral-value]="load.maxUtilization == null"
                [ngbTooltip]="utilizationTooltip"
                [openDelay]="0"
                tooltipClass="loads-utilization-tooltip"
                [disableTooltip]="!(load.tension?.value != null || load.shear?.value != null)">
                {{load.maxUtilization?.percentage ?? noValueString}}</div>
        </div>
    </div>

    <!-- EU Overlay utilization -->
    <div id="LC-table-utilization-section-{{loadIndex != null ? loadIndex : 'new-load'}}"
        *ngIf="helper.isEuropeanDesignStandardOverlay"
        class="column-utilization resizer-column right-side-border"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.utilization) }">
        <div class="general-utilization">
            <div *ngIf="load.shear"
                id="LC-table-utilization-{{loadIndex != null ? loadIndex : 'new-load'}}"
                class="utilization-number-left-align clip">
                <span id="LC-table-utilization-zone-{{loadIndex != null ? loadIndex : 'new-load'}}"
                    *ngIf="!helper.hasMessages(load)">
                    {{ translate('Agito.Hilti.C2C.Navigation.TabProduct.Zone' + load.shear.zone) }}
                </span>

                &nbsp;

                <span id="LC-table-utilization-shear-{{loadIndex != null ? loadIndex : 'new-load'}}"
                    *ngIf="!helper.hasMessages(load)"
                    class="utilization-number-left-align-percentage notranslate"
                    [class.invalid-value]="!load.shear.status">{{load.shear.percentage}}</span>
            </div>
        </div>
    </div>

    <div class="column-messages" *ngIf="isAdd == false">
        <button
            id="LC-table-show-warning-icon-{{loadIndex != null ?  + loadIndex : 'new-load'}}-messages-button"
            type="button"
            class="messages-button"
            *ngIf="helper.hasMessages(load)"
            (click)="helper.showMessages(load)"
            [disabled]="submitted"
            [ngbTooltip]="translate('Agito.Hilti.C2C.Loads.Messages')">
            <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-warning"></span>
        </button>
    </div>

    <div class="column-delete" *ngIf="isAdd == false">
        <div class="tooltip-wrap"
            [disableTooltip]="submitted"
            [ngbTooltip]="translate('Agito.Hilti.C2C.Loads.Delete')">
            <button
                id="LC-table-bin-button-{{loadIndex != null ? loadIndex : 'new-load'}}"
                type="button"
                class="delete-button"
                (click)="deleteLoad(load)"
                [disabled]="load.id == selectedLoadId || load.isWizardGenerated || helper.haveSingleLoad || submitted || helper.isPerBarPir">
                <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-trash"></span>
            </button>
        </div>
    </div>

</div>
