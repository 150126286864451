import { CalculationModeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class CalculationMode extends CodeList {

    public static fromService(codeList: CalculationModeEntityC2C) {
        return new CalculationMode(CodeList.baseFromService('CalculationModeEntity', codeList));
    }

    public override cloneDeep(): CalculationMode {
        return super.cloneDeep() as CalculationMode;
    }
}
