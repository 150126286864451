import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { DesignPe } from '../../../shared/entities/design-pe';
import { ISmartDesignSearchSolutionsProps, SmartDesignSearchSolutions } from '../../components/main-menu/smart-design-search-solutions/SmartDesignSearchSolutions';
import { LocalizationService } from '../../services/localization.service';
import { SmartDesignSearchSolutionService } from '../../services/smart-design-search-solutions.service';
import { updateMainMenuControl } from './menu-helper';

export function initializeSmartDesignSearchSolutions(
    design: DesignPe,
    controlProps: ISmartDesignSearchSolutionsProps,
    localizationService: LocalizationService,
    smartDesignSearchSolutionService: SmartDesignSearchSolutionService,
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {

    controlProps.type = SmartDesignSearchSolutions;
    controlProps.hidden = true;
    controlProps.smartDesignSearchSolutionService = smartDesignSearchSolutionService;
    controlProps.optimizeButtonText = localizationService.getString('Agito.Hilti.Profis3.SmartBasePlate.SearchSolutions.Button');
    controlProps.optimizingButtonText = localizationService.getString('Agito.Hilti.Profis3.SmartBasePlate.SearchSolutions.Button2');
    controlProps.optimizing = false;

    controlProps.clicked = () => {
        smartDesignSearchSolutionService.optimize();
    };

    controlProps.cancelClicked = () => {
        smartDesignSearchSolutionService.optimizeDone();
    };

    controlProps.smartDesignSearchSolutionService.smartDesignOptimize.subscribe({
        next: (value: boolean) => {
             // change menu
             setState(menu => updateMainMenuControl<ISmartDesignSearchSolutionsProps>(menu, controlProps.controlId, { optimizing: value } as any));
        }
    });

    const onStateChanged = () => {
        return ({
            titleFormatted: localizationService.getString('Agito.Hilti.Profis3.SmartBasePlate.SearchSolutions.Button'),
            optimizing: smartDesignSearchSolutionService.Optimizing,
            hidden: smartDesignSearchSolutionService.SearchSolutionButtonHidden,
        } as any) as ISmartDesignSearchSolutionsProps;
    };

    return {
        controlProps,
        onStateChanged
    };
}
