import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CultureInfoService } from './culture-info.service';
import { LocalizationService } from './localization.service';

type ApprovalLanguageDirectory = 'EN' | 'DE';

type Language = 'default' | 'de';

@Injectable({
    providedIn: 'root'
})
export class ApprovalsService {
    constructor(
        private readonly localizationService: LocalizationService,
        private readonly cultureInfoService: CultureInfoService
    ) {}

    // As in pe-ui-approvals/approvals repository
    private readonly languageApprovalDirectoryMap: Record<Language, ApprovalLanguageDirectory> = {
        'default': 'EN',
        'de': 'DE',
    };

    public getApprovalNumber(approval: Approval | undefined): string {
        return approval?.number ?? this.localizationService.getString('SP.Approval.HiltiTechnicalData');
    }

    public getApprovalIssuedDateFormatted(approval: Approval | undefined): string | undefined {
        return approval?.issued ? this.cultureInfoService.formatShortDatePattern(approval.issued) : undefined;
    }

    public getApprovalValidDateFormatted(approval: Approval | undefined): string | undefined {
        return approval?.valid ? this.cultureInfoService.formatShortDatePattern(approval.valid) : undefined;
    }

    public openApprovalLink(approval: Approval | undefined) {
        if (!approval?.filename) {
            return;
        }

        const currentLanguage = this.cultureInfoService.currentLanguage;
        const approvalDirectory = this.languageApprovalDirectoryMap[currentLanguage as Language] ?? this.languageApprovalDirectoryMap.default;
        const approvalBasePath = `${environment.baseUrl}cdn/pe-ui-sp/approvals/${this.getValidApprovalDirectory(approvalDirectory, approval.supportedLanguages)}`;
        const path = `${approvalBasePath}/${approval.filename}`;

        window.open(path, '_blank');
    }

    private getValidApprovalDirectory(language: ApprovalLanguageDirectory, supportedLanguages: string[]): ApprovalLanguageDirectory {
        return supportedLanguages.includes(language) ? language : this.languageApprovalDirectoryMap.default;
    }
}

export interface Approval {
    number: string;
    designMethod: string;
    issued?: Date;
    valid?: Date;
    filename: string;
    supportedLanguages: string[];
}
