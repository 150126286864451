export class HtmlValue {
  html;
  constructor(html) {
    this.html = html;
  }
}
export const isElementInDocument = (element, document) => {
  let currentElement = element;
  while (currentElement?.parentNode) {
    if (currentElement.parentNode === document) {
      return true;
    } else if (currentElement.parentNode instanceof ShadowRoot) {
      currentElement = currentElement.parentNode.host;
    } else {
      currentElement = currentElement.parentNode;
    }
  }
  return false;
};
