import { CodeList } from './code-list';
import { OverlayPositionEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class OverlayPosition extends CodeList {

    public static fromService(codeList: OverlayPositionEntityC2C) {
        return new OverlayPosition(CodeList.baseFromService('OverlayPositionEntity', codeList));
    }

    public override cloneDeep(): OverlayPosition {
        return super.cloneDeep() as OverlayPosition;
    }
}
