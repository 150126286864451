import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';

// Import sprites info and images.
import mainSprite from './bundles/main.json';
import mainImage from './bundles/main.png' with { loader: 'file' };
import productDialogSprite from './bundles/product-dialog.json';
import productDialogImage from './bundles/product-dialog.png' with { loader: 'file' };
import productUiSprite from './bundles/product-ui.json';
import productUiImage from './bundles/product-ui.png' with { loader: 'file' };
import { imageUrl } from './image';

// Sprite types.
export type MainSprite = keyof typeof mainSprite;
export type ProductUiSprite = keyof typeof productUiSprite;
export type ProductDialogSprite = keyof typeof productDialogSprite;

// Add more sprites with |.
export type Sprite = MainSprite | ProductUiSprite | ProductDialogSprite;

export type SpriteClass = `pe-ui-sp-${Sprite}`;

const mainSpriteInfo = toSpriteInfo(mainSprite, 'main.png');
const productUiSpriteInfo = toSpriteInfo(productUiSprite, 'product-ui.png');
const productDialogSpriteInfo = toSpriteInfo(productDialogSprite, 'product-dialog.png');

export const mainSprites = Object.keys(mainSpriteInfo) as [MainSprite];
export const productUiSprites = Object.keys(productUiSpriteInfo) as [ProductUiSprite];
export const productDialogSprites = Object.keys(productDialogSpriteInfo) as [ProductDialogSprite];

// Add more sprites with ...
const spritesInfo: SpriteInfo = {
    ...mainSpriteInfo,
    ...productUiSpriteInfo,
    ...productDialogSpriteInfo
};

const imagesUrls: Record<string, string> = {
    'main.png': imageUrl(mainImage),
    'product-ui.png': imageUrl(productUiImage),
    'product-dialog.png': imageUrl(productDialogImage)
};

interface JsonSpriteDetails {
    x: number;
    y: number;
    w: number;
    h: number;
    tw: number;
    th: number;
}

interface SpriteDetails extends JsonSpriteDetails {
    image: string;
}

type SpriteInfo = Record<string, SpriteDetails>;

function toSpriteInfo(sprites: Record<string, JsonSpriteDetails>, image: string): SpriteInfo {
    return Object.fromEntries(Object.entries(sprites).map(([spriteName, sprite]) => {
        return [spriteName, {
            ...sprite,
            image
        }];
    }));
}

export function includeSprites(root: Node | null, ...sprites: Sprite[]): void {
    if (root != null) {
        let cssExists = false;
        let css = `
            .pe-ui-sp-sprite {
                display: block;
            }
        `;

        for (const sprite of sprites) {
            const spriteInfo = spritesInfo[sprite];
            if (spriteInfo == null) {
                console.warn(`Sprite with name '${sprite}' does not exist (missing json)`);
                continue;
            }

            const imageUrl = imagesUrls[spriteInfo.image];

            if (!imageUrl) {
                console.warn(`Sprite with name '${sprite}' does not exist (missing image)`);
                continue;
            }

            css += `
                .pe-ui-sp-sprite.pe-ui-sp-${sprite} {
                    background-image: url(${imageUrl});
                    background-position: -${spriteInfo.x}px -${spriteInfo.y}px;
                    width: ${spriteInfo.w}px;
                    min-width: ${spriteInfo.w}px;
                    height: ${spriteInfo.h}px;
                }
            `;

            cssExists = true;
        }

        if (cssExists) {
            const spriteStyle = document.createElement('style');
            spriteStyle.textContent = css;

            root.insertBefore(spriteStyle, root.firstChild);
        }
    }
}

export function getSpriteAsIconStyle(image: Sprite): IIconStyle {
    const spriteInfo = getSpriteInfo(image);
    if (spriteInfo != null) {
        const imageUrl = imagesUrls[spriteInfo.image];

        return {
            'background-image': `url(${imageUrl})`,
            'background-position': `-${spriteInfo.x}px -${spriteInfo.y}px`,
            'width': `${spriteInfo.w}px`,
            'min-width': `${spriteInfo.w}px`,
            'height': `${spriteInfo.h}px`,
        };
    }

    return {};
}

export function getSpriteAsIconStyleResponsive(image: Sprite): IIconStyle {
    const spriteInfo = getSpriteInfo(image);
    if (spriteInfo != null) {
        const imageUrl = imagesUrls[spriteInfo.image];

        const x = ((spriteInfo.x * 100) / (spriteInfo.tw - spriteInfo.w));
        const y = ((spriteInfo.y * 100) / (spriteInfo.th - spriteInfo.h));
        const bgWidth = spriteInfo.tw * 100 / spriteInfo.w;
        const paddingBottom = spriteInfo.h * 100 / spriteInfo.w;
        const width = spriteInfo.tw * 100 / spriteInfo.w;

        return {
            'background-image': `url(${imageUrl})`,
            'background-position': `${x}% ${y}%`,
            'background-size': `${bgWidth}% auto`,
            'width': `${width}%`,
            'max-width': '100%',
            'height': '0',
            'padding-bottom': `${paddingBottom}%`,
            'display': 'block'
        };
    }

    return {};
}

function getSpriteInfo(image: Sprite | string): SpriteDetails | undefined {
    if (!(image in spritesInfo)) {
        console.warn(`Sprite with name '${image}' does not exist (missing json)`);
        return undefined;
    }

    const spriteInfo = spritesInfo[image as Sprite];

    const imageUrl = imagesUrls[spriteInfo.image];
    if (!imageUrl) {
        console.warn(`Sprite with name '${image}' does not exist (missing image)`);
        return undefined;
    }

    return spriteInfo;
}
