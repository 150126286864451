import cloneDeep from 'lodash-es/cloneDeep';
import sortBy from 'lodash-es/sortBy';
import { Subscription } from 'rxjs';

import {
    AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional,
    ViewChild
} from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { TextBoxProps } from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import {
    IAddEditDesignComponent, IAddEditDesignFooterComponent, IDetailedDisplayDesignFromModule
} from '@profis-engineering/pe-ui-common/entities/add-edit-design-component';
import {
    getCodeListTextDeps, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    DesignType as DesignTypeCommon
} from '@profis-engineering/pe-ui-common/entities/code-lists/design-type';
import { Design as DesignCommon } from '@profis-engineering/pe-ui-common/entities/design';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { IDesignInfo } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { sortByUnicode } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    IDeckingDesignListInfo
} from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design-list-info';
import {
    ConnectionSetup,
    ICheckBotProjectInfo,
    SteelSetup
} from '@profis-engineering/pe-ui-shared/components/design-from-checkbot';
import {
    DesignType, IDesignTypeConstructor
} from '@profis-engineering/pe-ui-shared/entities/code-lists/design-type';
import { environment } from '../../../environments/environment';
import { Design } from '../../entities/design';
import { IDetailedDisplayDesign } from '../../entities/display-design';
import { Project } from '../../entities/project';
import { createDesignName } from '../../helpers/design-name-helper';
import { CommonCodeListService } from '../../services/common-code-list.service';
import {
    DesignTemplateService, IDesignTemplateFolderUpdate
} from '../../services/design-template.service';
import { DocumentService } from '../../services/document.service';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { DesignTypeId, ModulesService } from '../../services/modules.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { RoutingService } from '../../services/routing.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { designNameValidator, templateNameValidator } from '../../validators/design-name.validator';
import {
    DesignTemplateFolderDetail, TemplateFolderRequestModel
} from '../home-page/template-folder';
import { ModalDialogType } from './add-edit-design-models';

interface IModuleIAddEditDesignComponentInternal extends IAddEditDesignComponent, HTMLElement { }

interface IModuleIAddEditDesignComponentFooterInternal extends IAddEditDesignFooterComponent, HTMLElement { }

export interface IAddEditDesignComponentInput {
    design?: IDetailedDisplayDesign;
    designType?: DesignTypeId;

    unitLength?: Unit;

    project?: Project;
    region?: CommonRegion;

    onDesignEdited?: (design: unknown, project: Project) => Promise<boolean | void> | boolean | void;

    collapseAdvancedSettings?: boolean;
    step?: Step;
    afterOpenInstructions?: number[];

    selectedModuleDesignInfo?: IDesignInfo;
    addEditType?: AddEditType;
    anchorName?: string;
    approvalNumber?: string;
    designTemplateDocumentId?: string;
    projectDesign?: unknown;
}

const NewProjectId = '0';

export enum AfterOpenInstruction {
    openAdvancedSettings = 1,
    ScrollToRebarCalculationSection = 2
}


export enum Step {
    basic,
    connectionApplication
}

@Component({
    selector: 'app-add-edit-design',
    templateUrl: './add-edit-design.component.html',
    styleUrls: ['./add-edit-design.component.scss']
})
export class AddEditDesignComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    public checkBotProjectInfo: ICheckBotProjectInfo;

    @Input()
    public addEditTypeCheckBot = false;

    @ViewChild('componentInjector')
    public componentInjector: ElementRef<HTMLElement>;

    @ViewChild('componentFooterInjector')
    public componentFooterInjector: ElementRef<HTMLElement>;

    public submitted = false;
    public newProject: string;
    public newTemplateFolder: string;
    public designCheckbot: IAddEditDesignComponentInput = {};

    public nameTextBox: TextBoxProps;
    private initProjectId: string;
    public projectDropdown: DropdownProps<Project>;
    public templateFolderDropdown: DropdownProps<DesignTemplateFolderDetail>;
    public regionDropdown: DropdownProps<CommonRegion>;


    public step: Step;
    public regionId: number;

    public stepEnum = Step;
    public addEditTypeEnum = AddEditType;
    public designTypeIdEnum = {
        DiaphragmDesign: DesignTypeId.DiaphragmDesign
    };

    public requiredValidator = Validators.required;

    public designInfoData: IDesignInfo[] = [];
    public selectedModuleDesignInfo?: IDesignInfo = null;
    public deckingEnabled = false;

    private pendingSave = false;
    private _designType: DesignTypeCommon;

    private isViewInit = false;
    private designInfoProvided: Subscription;

    public isDeckingType: boolean;

    public loadingDeckingSettings = false;

    private readonly changeComponentModule: EventEmitter<IDesignInfo> = new EventEmitter();

    private oldTemplateFolder: DesignTemplateFolderDetail;
    private oldDesignName = '';

    constructor(
        public localization: LocalizationService,
        private readonly offline: OfflineService,
        private readonly user: UserService,
        private readonly commonCodeList: CommonCodeListService,
        private readonly document: DocumentService,
        private readonly modal: ModalService,
        private readonly userSettings: UserSettingsService,
        private readonly numberService: NumberService,
        @Optional() private readonly modalInstance: ModalInstance<IAddEditDesignComponentInput> | null,
        private readonly routingService: RoutingService,
        private readonly featureVisibilityService: FeatureVisibilityService,
        private readonly modulesService: ModulesService,
        private readonly templateService: DesignTemplateService,
    ) {
        this.selectedModuleDesignInfo = this.modalInstance?.input?.selectedModuleDesignInfo;
        this.isDeckingType = this.modalInstance?.input?.designType === DesignTypeId.DiaphragmDesign;
    }

    public get isNewHomePage() {
        return this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
    }

    public get isNewHomePageTemplate() {
        return this.isTemplate && this.isNewHomePage;
    }

    public get companyProjectDisabled() {
        return this.user.hasFreeLicense || this.user.hasfloatingLimitReached;
    }

    public get getCompanyDisabledTooltip() {
        if (!this.companyProjectDisabled) {
            return null;
        }

        return this.localization.getString('Agito.Hilti.Profis3.Convert.Disabled.ToolTip');
    }

    public get isCheckbotDesign() {
        return (this.design?.design as Design)?.designData?.projectDesign?.CreatedWithCheckbot;
    }

    public get isNameTextBoxDisabled() {
        if (this.isCheckbotDesign || this.isFromCheckBotDisplay) {
            return true;
        }

        if (!this.isNewHomePage || this.addEditType !== this.addEditTypeEnum.edit) {
            return false;
        }
        if (this.isTemplate) {
            const template = this.templateService.templateV2[this.design?.designTemplateDocumentId];
            const templateFolder = this.templateService.templatesFoldersFlat[template?.templateFolderId];
            const isDraftsFolder = template?.templateFolderId == null;
            const isFolderOwner = isDraftsFolder || templateFolder?.owner;

            return !(template?.owner && isFolderOwner);
        }
        return false;
    }

    public get addEditType(): AddEditType {
        if (this.addEditTypeCheckBot) {
            return AddEditType.addFromCheckbot;
        }

        if (this.modalInstance?.input?.addEditType != null) {
            return this.modalInstance.input.addEditType;
        }
        else {
            return (this.user.design != null
                ? AddEditType.edit
                : AddEditType.add);
        }
    }

    public get titleKey() {
        let titleKey = '';

        if (this.isTemplate || this.isCreateTemplate) {
            titleKey = 'EditTemplate';
        }
        else if (this.step == Step.connectionApplication) {
            return 'Agito.Hilti.C2C.AddEditDesign.ConnectionApplication';
        }
        else {
            titleKey = 'EditTitle';
        }

        return `Agito.Hilti.Profis3.AddEditDesign.${titleKey}`;
    }

    public get projectDropdownLabel() {
        return !this.isCompanyProject ? 'Agito.Hilti.Profis3.AddEditDesign.Project' : 'Agito.Hilti.Profis3.HomePage.CompanyProjectFolder';
    }

    public get deckingDesignType() {
        return new DesignType({ id: 106, name: "DiaphragmDesign" } as IDesignTypeConstructor)
    }

    public get designType() {
        if (this._designType == null) {
            let designTypeId = 0;
            if (this.design != null) {
                designTypeId = this.design.designType;
            }
            else {
                designTypeId = this.modalInstance?.input.designType ?? this.designInfoData[0].designTypeId;
            }

            if (designTypeId == this.designTypeIdEnum.DiaphragmDesign) {
                this._designType = this.deckingDesignType;
            }
            else {
                this._designType = this.modulesService.designTypes.find((desingTypeCodeListItem) => desingTypeCodeListItem.id == designTypeId);
            }
        }

        return this._designType;
    }

    public get design(): IDetailedDisplayDesign | IDetailedDisplayDesignFromModule | undefined {
        return this.addEditTypeCheckBot ? this.designCheckbot.design : this.modalInstance?.input?.design;
    }

    public get isTemplate() {
        return (this.design != null && this.design.displayDesignType == DisplayDesignType.template) ||
            (this.design == null && (this.user.design?.isTemplate ?? false));
    }

    public get isCreateTemplate() {
        return this.design != null && this.modalInstance?.input?.design?.isCreateTemplate;
    }

    public get isCompanyProject() {
        //design settings opened from module
        if (this.modalInstance?.input?.design?.id) {
            return this.document.findProjectByDesignId(this.modalInstance?.input?.design?.id)?.isCompanyProject;
        }
        if (this.modalInstance?.input?.design?.projectId) {
            return this.design != null && this.document.findProjectById(this.modalInstance?.input?.design?.projectId)?.isCompanyProject;
        }

        return this.design != null && this.modalInstance?.input?.design?.isCompanyProject;
    }

    public get openAdvancedSettings() {
        return this.modalInstance?.input?.collapseAdvancedSettings ?? false;
    }

    public get mockIsEditDiaphragmDesign() {
        return this.deckingEnabled && this.designType?.id === DesignTypeId.DiaphragmDesign && this.modalInstance?.input.unitLength === Unit.ft;
    }

    public get isProjectDropdownDisplay() {
        return !this.isTemplate && !this.isCreateTemplate && !this.offline?.isOffline && this.projectDropdown.selectedValue?.id != '0';
    }

    public get isTemplateFolderDdlDisplay() {
        return this.isFromCheckBotDisplay ? false : (this.isNewHomePageTemplate || this.isCreateTemplate) && !this.offline?.isOffline && this.templateFolderDropdown.selectedValue?.templateFolderId != '0';
    }

    public get isFromCheckBotDisplay() {
        return this.addEditType == AddEditType.addFromCheckbot;
    }

    public get showLayout4() {
        return this.offline.isOffline;
    }

    public get showLayoutAll() {
        return !this.offline.isOffline;
    }

    public get isProjectTextBoxDisplay() {
        return this.isFromCheckBotDisplay ? false : !this.isTemplate && !this.offline.isOffline && this.projectDropdown.selectedValue?.id == '0';
    }

    public get isTemplateFolderTextboxDisplay() {
        return this.isFromCheckBotDisplay ? false : (this.isNewHomePageTemplate || this.isCreateTemplate) && !this.offline.isOffline && this.templateFolderDropdown.selectedValue?.templateFolderId == '0';

    }

    public get isProjectDropDownDisabled() {
        if (this.isCheckbotDesign || this.isFromCheckBotDisplay) {
            return true;
        }

        if (!this.isNewHomePage || this.addEditType !== this.addEditTypeEnum.edit) {
            return false;
        }
        return false;
    }

    public get isTemplateFolderDropDownDisabled() {
        if (!this.isNewHomePage || this.addEditType !== this.addEditTypeEnum.edit) {
            return false;
        }

        const template = this.templateService.templateV2[this.design?.designTemplateDocumentId];
        const templateFolder = this.templateService.templatesFoldersFlat[template.templateFolderId];
        const isDraftsFolder = template?.templateFolderId == null;
        const isNotFolderOwner = !isDraftsFolder && !templateFolder?.owner;

        return !template?.owner || template?.isSharedByMe || isNotFolderOwner;
    }

    private get project() {
        if (this.design != null) {
            return this.document.findProjectById(this.design.projectId);
        }
        else {
            return this.document.findProjectById(this.user.design.projectId);
        }

    }

    private get selectedTemplateFolder() {
        return this.user.templateFolder;
    }

    private get regions() {
        let regions = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[];

        if (this.selectedModuleDesignInfo != null) {
            regions = regions.filter((region) => this.selectedModuleDesignInfo.availableRegions.some((row) => row == region.id));
        }
        else if (this.deckingEnabled && this.designType?.id === this.designTypeIdEnum.DiaphragmDesign) {
            regions = regions.filter((region) => {
                return this.featureVisibilityService.getFeatureValue('Decking_Regions', []).includes(region.countryCode);
             });
        }
        else if (this.designType != null) {
            // filter regions by design type
            regions = regions.filter((region) => this.designType.allowedDesignStandardRegions.some((row) => row.RegionId == region.id));
        }
        else {
            // Fallback: return all regions
        }

        // and sort by translated NameText
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        regions = sortByUnicode(
            regions.map(
                (region) => {
                    return {
                        region,
                        text: region.getTranslatedNameText(codeListDeps)
                    };
                }
            ),
            'text'
        ).map(
            (o) => {
                return o.region;
            }
        );

        return regions;
    }

    private get addEditDesignModuleComponent() {
        if (this.selectedModuleDesignInfo == null) {
            return null;
        }

        return this.componentInjector.nativeElement.querySelector<IModuleIAddEditDesignComponentInternal>(this.selectedModuleDesignInfo.componentTagName);
    }

    private get addEditDesignModuleFooterComponent() {
        if (this.selectedModuleDesignInfo == null) {
            return null;
        }

        return this.componentFooterInjector.nativeElement.querySelector<IModuleIAddEditDesignComponentFooterInternal>(this.selectedModuleDesignInfo.componentFooterTagName);
    }

    public ngOnInit(): void {
        this.deckingEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_Global') || environment.deckingEnabled;
        this.user.setIsCreateTemplate(this.isNewHomePage && this.isCreateTemplate);

        if (this.addEditType != AddEditType.addFromCheckbot) {
            // Do not close modal if save is pending (close on destroy)
            this.modalInstance?.setOnClosing(() => {
                return !this.pendingSave;
            });
        }
        this.designInfoProvided = this.modulesService.designInfoProvided.subscribe(() => this.loadDesignInfoData());
        this.initControls();

        this.changeComponentModule.subscribe((module: IDesignInfo) => {
            module.getDesignName = () => this.nameTextBox.value;
            this.designInfoData[this.designInfoData.findIndex((x => x.id == module.id))] = module;
            this.openAddEditComponentModule(module);
        });
    }

    public ngOnDestroy(): void {
        // Remove subscriptions
        this.designInfoProvided?.unsubscribe();
        this.changeComponentModule?.unsubscribe();
    }

    public loadDesignInfoData() {
        this.designInfoData = sortBy(this.modulesService.getDesignInfo(), s => s.order);
    }

    public isDesignInfoAvailable(designInfo: IDesignInfo) {
        return SafeFunctionInvokerHelper.safeInvoke(() => designInfo.isAvailable(this.regionDropdown.selectedValue.id), false);
    }

    public ngAfterViewInit() {
        this.isViewInit = true;
    }

    public isDiaphragmDesignDisabled() {
        const countryCode = this.regionDropdown.selectedValue.countryCode;

        return (
            this.isFromCheckBotDisplay ||
            this.addEditType === AddEditType.edit &&
            this.designType?.id !== DesignTypeId.DiaphragmDesign ||
            !(countryCode === 'ca' || countryCode === 'us' || countryCode === 'mx')
        );
    }

    public getNewDesignInfoButtonTooltip(designInfo: IDesignInfo) {
        return designInfo.getNewDesignButtonTooltip
            ? SafeFunctionInvokerHelper.safeInvoke(() => designInfo.getNewDesignButtonTooltip(this.regionDropdown.selectedValue.id), null)
            : null;
    }
    public designTypeClick(newDesignTypeId: number) {
        this.selectedModuleDesignInfo = null;
        this.componentInjector.nativeElement.innerHTML = '';
        this.componentFooterInjector.nativeElement.innerHTML = '';

        if ([AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType) && !this.submitted) {
            if (newDesignTypeId == this.designTypeIdEnum.DiaphragmDesign) {
                if (this.isDiaphragmDesignDisabled()) return;
                this._designType = this.deckingDesignType;
                this.isDeckingType = true;
            }
            else {
                this._designType = [AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)
                    ? this.modulesService.designTypes.find((desingTypeCodeListItem) => desingTypeCodeListItem.id == newDesignTypeId)
                    : this._designType;
                this.isDeckingType = false;
            }

            // Reset regions
            this.resetRegionValues();

            // Reset project name
            let selectedProjectDesigns: string[] = [];
            if (this.isNewHomePage) {
                selectedProjectDesigns = this.getSelectedFolderDocuments();
            }
            else {
                selectedProjectDesigns = Object.values(this.projectDropdown.selectedValue.designs).map((item) => item.designName);
            }

            this.nameTextBox.value = this.document.createUniqueName(
                createDesignName(this.localization, this.designType), selectedProjectDesigns
            );

            // Update available methods
            this.onRegionChanged(this.regionDropdown.selectedValue);
        }
    }

    public designInfoClick(designInfo: IDesignInfo) {
        if (this.isDesignInfoDisabled(designInfo)) {
            return;
        }

        this.openAddEditComponentModule(designInfo);
    }

    public designInfoImageStyle(designInfo: IDesignInfo) {
        return SafeFunctionInvokerHelper.safeInvoke(() => designInfo.imageStyle(this.regionDropdown.selectedValue.id), {});
    }

    public isDesignInfoDisabled(designInfo: IDesignInfo) {
        return (this.addEditType == AddEditType.edit && designInfo.designTypeId != this.designType?.id)
            ||
            !SafeFunctionInvokerHelper.safeInvoke(() => designInfo.isEnabled(this.regionDropdown.selectedValue.id), false)
            || (this.isFromCheckBotDisplay && designInfo.designTypeId !== DesignTypeId.Concrete);
    }

    public newProjectCancel() {
        this.onProjectDropdownSelectedValueChange(this.document.draftsProject);
        this.newProject = null;

        setTimeout(() => {
            (document.querySelector<HTMLElement>('.project-dropdown .dropdown-button')).focus();
        });
    }
    public newTemplateFolderCancel() {
        this.onTemplateFolderDropdownSelectedValueChange(null);
        this.newTemplateFolder = null;

        setTimeout(() => {
            (document.querySelector<HTMLElement>('.project-dropdown .dropdown-button')).focus();
        });
    }
    public async save(form: NgForm) {
        if (this.submitted || (form.enabled && !form.valid)){
            return;
        }

        this.submitted = true;
        this.pendingSave = true;
        this.submitModuleDesignInfo();

        const selectedProject = this.projectDropdown?.selectedValue;
        let project: Promise<Project>;
        project = Promise.resolve(selectedProject);
        const selectedTemplateFolder = this.templateFolderDropdown?.selectedValue;

        if (selectedProject != null && selectedProject.id == NewProjectId) {
            const newProject = new Project({
                name: this.newProject.trim(),
                owner: true,
                isCompanyProject: this.isCompanyProject
            });

            if (this.document.projectNameExists(newProject.name, null)) {
                this.openProjectRenameModal();
                this.submitted = false;
                this.pendingSave = false;
                return;
            }

            project = this.document
                .saveProject(newProject, ModalDialogType.project)
                .then(() => {
                    return newProject;
                });
        }

        // template
        await this.createNewTemplateFolder(selectedTemplateFolder);

        // Saving decking design
        if (this.deckingEnabled && this.designType?.id == this.designTypeIdEnum.DiaphragmDesign) {
            this.saveDeckingModuleComponentInfo(project, selectedProject, selectedTemplateFolder);
            return;
        }

        this.saveModuleComponentInfo(project, selectedTemplateFolder);
    }

    private async createNewTemplateFolder(selectedTemplateFolder: DesignTemplateFolderDetail) {
        const isTemplateAddEdit = this.isNewHomePageTemplate || this.isCreateTemplate;
        if (isTemplateAddEdit && selectedTemplateFolder != null
            && selectedTemplateFolder.templateFolderId == NewProjectId) {
            const newFolderTemplate: DesignTemplateFolderDetail = { templateFolderId: '0', name: this.newTemplateFolder.trim(), } as never;

            if (this.templateService.checkExistingTemplateFolder(newFolderTemplate.name, null)) {
                this.openTemplateFolderModal();
                this.submitted = false;
                this.pendingSave = false;

                return;
            }

            const templateFolderRequestModel: TemplateFolderRequestModel = {
                name: newFolderTemplate.name
            }
            const res = await this.templateService.createTemplateFolder(templateFolderRequestModel)
            selectedTemplateFolder.templateFolderId = res.templateFolderId;
        }
    }

    private submitModuleDesignInfo() {
        if (this.selectedModuleDesignInfo != null) {
            this.addEditDesignModuleComponent.submitted = true;
            if (this.addEditDesignModuleFooterComponent) {
                this.addEditDesignModuleFooterComponent.submitted = true;
            }
        }
    }

    private openProjectRenameModal() {
        this.modal.openConfirmChange({
            id: 'confirm-project-rename',
            title: this.localization.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Confirm'),
            onConfirm: (modal) => {
                modal.close();
            }
        });
    }

    private openTemplateFolderModal() {
        this.modal.openConfirmChange({
            id: 'confirm-template-folder-rename',
            title: this.localization.getString('Agito.Hilti.Profis3.HomePage.TemplateFolderExist.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.HomePage.TemplateSubExist.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Confirm'),
            onConfirm: (modal) => {
                modal.close();
            }
        });
    }


    private saveDeckingModuleComponentInfo(project: Promise<Project>, selectedProject: Project, selectedTemplateFolder?: DesignTemplateFolderDetail) {
        project.then(async (projectResolved) => {
            if (this.isNewHomePage) {
                this.user.setTemplateFolder(selectedTemplateFolder);
                //update template folder
                if (this.isNewHomePageTemplate &&
                    this.oldTemplateFolder?.templateFolderId != selectedTemplateFolder?.templateFolderId) {
                    await this.templateService.updateTemplateFolder({
                        designTemplateDocumentId: this.design?.designTemplateDocumentId,
                        templateFolderId: selectedTemplateFolder ? selectedTemplateFolder.templateFolderId : null,
                        templateName: this.nameTextBox.value
                    } as IDesignTemplateFolderUpdate);
                }
            }
            const deckingDesignListInfo = this.modulesService.getDesignListInfoByDesignType(DesignTypeId.DiaphragmDesign) as IDeckingDesignListInfo;
            deckingDesignListInfo.updateDeckignSettings(projectResolved?.id);
            this.projectDropdown.selectedValue = projectResolved;

            if (this.initProjectId !== selectedProject.id && this.addEditType === AddEditType.edit) {
                await this.document.updateDesignProject(this.design.id, projectResolved.id);
            }

            this.submitted = false;
            this.pendingSave = false;
            this.modalInstance?.close(this.nameTextBox.value);
        });
    }

    private saveModuleComponentInfo(project: Promise<Project>, selectedTemplateFolder?: DesignTemplateFolderDetail) {
        const projectDesign = this.modalInstance?.input?.design?.design?.projectDesign;

        project
            .then(async (project) => {
                if (this.selectedModuleDesignInfo != null) {
                    const saveResultData = await this.addEditDesignModuleComponent.save({
                        projectName: project?.name,
                        projectId: project?.id,
                        designName: this.nameTextBox.value,
                        anchorName: this.modalInstance?.input?.anchorName,
                        approvalNumber: this.modalInstance?.input?.approvalNumber,
                        designTemplateDocumentId: this.modalInstance?.input?.design?.designTemplateDocumentId,
                        projectDesign: projectDesign,
                        templateFolderId: selectedTemplateFolder?.templateFolderId
                    });

                    if (saveResultData.success) {
                        if (this.addEditDesignModuleFooterComponent) {
                            this.addEditDesignModuleFooterComponent.save();
                        }

                        this.handleDesignNameChange(saveResultData.design);
                        this.user.changeDesign(project, saveResultData.design as Design);

                        await this.updateDesignTemplateFolder(selectedTemplateFolder);

                        if (this.addEditType == AddEditType.edit && this.modalInstance?.input?.onDesignEdited != null) {
                            await this.modalInstance?.input?.onDesignEdited(saveResultData.detailedDisplayDesign as IDetailedDisplayDesign, project);
                        }
                        else if ([AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)) {
                            this.routingService.navigateToUrl(`${saveResultData.path}${saveResultData.designId}`);
                        }

                        this.pendingSave = false;
                        this.submitted = false;
                        this.close();
                    }
                    else {
                        this.pendingSave = false;
                        this.submitted = false;
                    }
                }
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.pendingSave = false;
                this.submitted = false;
            });
    }

    private handleDesignNameChange(design: DesignCommon) {
        // For Desktop we want to ensure that "Save as" is triggered if the design name gets changed.
        if (
            this.offline.isOffline
            && this.addEditType == AddEditType.edit
            && this.oldDesignName != this.nameTextBox.value
        ) {
            design.offlineDesignPath = '';  // Setting this causes "Safe as" popup to appear when saving.
        }
    }

    private async updateDesignTemplateFolder(selectedTemplateFolder: DesignTemplateFolderDetail) {
        if (this.isCreateTemplate) {
            this.user.design.templateName = this.nameTextBox.value;
            this.user.design.templateFolderId = selectedTemplateFolder ? selectedTemplateFolder.templateFolderId : null;
            this.user.setTemplateFolder(selectedTemplateFolder);
        }
        else if (this.isNewHomePageTemplate &&
            this.oldTemplateFolder?.templateFolderId != selectedTemplateFolder?.templateFolderId) {

            await this.templateService.updateTemplateFolder({
                designTemplateDocumentId: this.user.design.templateId,
                templateFolderId: selectedTemplateFolder ? selectedTemplateFolder.templateFolderId : null,
                templateName: this.nameTextBox.value
            } as IDesignTemplateFolderUpdate);
            this.user.setTemplateFolder(selectedTemplateFolder);
            this.oldTemplateFolder = selectedTemplateFolder;
        }
    }

    public close() {
        this.addEditDesignModuleComponent?.close?.();
        this.modalInstance?.close();
    }

    public onProjectDropdownSelectedValueChange(project: Project) {
        const oldProject = this.projectDropdown.selectedValue;
        this.projectDropdown.selectedValue = project;

        if (project != null && oldProject != null) {
            this.nameTextBox.validators = [
                this.requiredValidator,
                designNameValidator(
                    project,
                    this.addEditType == AddEditType.edit
                        ? this.project
                        : null,
                    this.addEditType == AddEditType.edit
                        ? this.design?.name || this.user.design?.designName
                        : null,
                    this.document,
                    this.localization.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Message'),
                    this.isNewHomePage
                )
            ];

            if (project.id == NewProjectId && oldProject.id != NewProjectId) {
                setTimeout(() => (document.querySelector<HTMLElement>('.modal-body .new-project-container .new-project input')).focus());
            }
        }
    }

    public onTemplateFolderDropdownSelectedValueChange(templateFolder: DesignTemplateFolderDetail) {
        this.oldTemplateFolder = this.templateFolderDropdown.selectedValue;
        if (!templateFolder) {
            this.templateFolderDropdown.selectedValue = null;
            setTimeout(() => (document.querySelector<HTMLElement>('.modal-body .new-project-container .new-project input')).focus());
            return;
        }
        this.templateFolderDropdown.selectedValue = templateFolder;

        if (templateFolder != null) {
            this.nameTextBox.validators = [
                this.requiredValidator,
                templateNameValidator(
                    templateFolder,
                    this.addEditType == AddEditType.edit
                        ? this.modalInstance?.input?.design?.design?.templateName
                        : null,
                    this.templateService,
                    this.localization.getString('Agito.Hilti.Profis3.HomePage.TemplateExist.Message'),
                    this.addEditType == AddEditType.edit
                        ? this.oldTemplateFolder
                        : null
                )
            ];

            if (templateFolder.templateFolderId == NewProjectId) {
                setTimeout(() => (document.querySelector<HTMLElement>('.modal-body .new-project-container .new-project input')).focus());
            }
        }
    }
    public onRegionDropdownSelectedValueChange(region: CommonRegion) {
        const oldRegion = this.regionDropdown.selectedValue;
        this.regionDropdown.selectedValue = region;

        // default design standard
        if (region !== oldRegion) {
            this.onRegionChanged(region);
        }
    }

    private initControls() {
        this.loadDesignInfoData();

        // get default user settings region or first region if the one in settings not fund
        this.initRegionDropdown();

        // controls
        this.initProjectAndName();

        this.step = this.modalInstance?.input?.addEditType == AddEditType.addFromQuickStart ? Step.connectionApplication : Step.basic;

        if (this.selectedModuleDesignInfo != null) {
            // After view is initialized we open component from module
            const viewLoadedCheckInterval = setInterval(() => {
                if (this.isViewInit) {
                    clearInterval(viewLoadedCheckInterval);
                    this.openAddEditComponentModule(this.selectedModuleDesignInfo);
                }
            }, 10);
        }
        else {
            if ([AddEditType.add, AddEditType.addFromCheckbot].includes(this.addEditType)) {
                const designInfo = this.designInfoData.find(x => this.isDesignInfoAvailable(x) && !this.isDesignInfoDisabled(x));

                const viewLoadedCheckInterval = setInterval(() => {
                    if (this.isViewInit) {
                        clearInterval(viewLoadedCheckInterval);
                        this.openAddEditComponentModule(designInfo);
                    }
                }, 10);
            }

            // focus name text box
            this.focusNameTextbox();
        }
    }

    private initProjectAndName() {
        this.oldDesignName = this.getName();
        this.nameTextBox = {
            id: 'add-edit-design-name',
            title: this.localization.getString(this.getNameTitleKey()),
            value: this.oldDesignName
        };

        const selectedProject = [AddEditType.add, AddEditType.addFromQuickStart, AddEditType.edit, AddEditType.addFromCheckbot].includes(this.addEditType)
            ? this.modalInstance?.input?.project || this.document.findProjectById(this.design?.projectId) || this.document.draftsProject
            : this.project;

        const projectItems = !this.isCompanyProject ? this.getProjectDropdownItemsNewHomePage() : this.getCompanyProjectDropdownItemsNewHomePage();

        const selectedProjectV2 = [AddEditType.add, AddEditType.addFromQuickStart, AddEditType.edit, AddEditType.addFromCheckbot].includes(this.addEditType)
            ? this.modalInstance?.input?.project || projectItems.find(x => x.value?.id == selectedProject?.id)?.value || this.document.draftsProject
            : this.project;


        this.projectDropdown = {
            id: 'add-edit-design-project-dropdown',
            title: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.Project'),
            items: [
                {
                    value: new Project({ id: NewProjectId }),
                    text: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.NewProject'),
                    isSpecial: true
                },
                {
                    value: this.document.draftsProject,
                    text: this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Designs.Drafts')
                },
                ...this.isNewHomePage ? projectItems : this.getProjectDropdownItems()
            ],
            selectedValue: this.isNewHomePage ? selectedProjectV2 : selectedProject
        };

        if (this.isCompanyProject && this.isNewHomePage) {
            this.projectDropdown = {
                id: 'add-edit-design-companyproject-dropdown',
                title: this.localization.getString('Agito.Hilti.Profis3.HomePage.CompanyProjectFolder'),
                items: [
                    {
                        value: new Project({ id: NewProjectId }),
                        text: this.localization.getString('Agito.Hilti.Profis3.HomePage.CompanyProjectFolder.New'),
                        isSpecial: true,
                        disabled: this.companyProjectDisabled,
                        tooltip: this.getCompanyDisabledTooltip
                    },
                    ...projectItems
                ],
                selectedValue: selectedProjectV2
            }
        }

        this.initProjectId = this.projectDropdown.selectedValue.id;

        if (this.isCreateTemplate || this.isNewHomePageTemplate) {
            const templateFoldersItems = this.getTemplateFolderDropdownItems();
            let selectedFolder;
            if (this.isCreateTemplate) {
                selectedFolder = this.selectedTemplateFolder ? templateFoldersItems.find(s => s.value.templateFolderId == this.selectedTemplateFolder.templateFolderId)?.value : null
            }
            if (this.isNewHomePageTemplate) {
                const template = this.templateService.findById(this.modalInstance?.input?.design?.designTemplateDocumentId);
                selectedFolder = template ? templateFoldersItems.find(s => s.value.templateFolderId == template.templateFolderId)?.value : null;
            }
            const newTemplate: DesignTemplateFolderDetail = { templateFolderId: '0' } as never;
            this.templateFolderDropdown = {
                id: 'add-edit-template-folder-dropdown',
                title: this.localization.getString('Agito.Hilti.Profis3.HomePage.TemplateFolder'),
                items: [
                    {
                        value: newTemplate,
                        text: this.localization.getString('Agito.Hilti.Profis3.HomePage.TemplateFolder.New'),
                        isSpecial: true
                    },
                    ...templateFoldersItems
                ],
                selectedValue: selectedFolder
            };
            this.oldTemplateFolder = selectedFolder;
        }


        if (this.offline.isOffline) {
            return;
        }

        this.nameTextBox.validationData = {
            showValidationErrors: true
        };
        if (this.isCreateTemplate || this.isTemplate) {
            const templateName = this.designType?.id === this.designTypeIdEnum.DiaphragmDesign ? this.modalInstance.input?.design.name : this.modalInstance.input?.design?.design?.templateName;
            this.nameTextBox.validators = [
                this.requiredValidator,
                templateNameValidator(
                    this.addEditType == AddEditType.add
                    ? this.templateFolderDropdown.selectedValue
                    : null,
                    this.addEditType == AddEditType.edit
                    ? templateName
                    : null,
                    this.templateService,
                    this.localization.getString('Agito.Hilti.Profis3.HomePage.TemplateExist.Message'),
                    this.addEditType == AddEditType.edit
                        ? this.oldTemplateFolder
                        : null
                )
            ];
        } else {
            this.nameTextBox.validators = [
                this.requiredValidator,
                designNameValidator(
                    this.addEditType == AddEditType.add ||
                        (this.designType?.id == DesignTypeId.DiaphragmDesign && !this.isNewHomePage)
                        ? this.projectDropdown.selectedValue
                        : null,
                    this.addEditType == AddEditType.edit
                        ? this.project
                        : null,
                    this.addEditType == AddEditType.edit
                        ? this.design?.name || this.user.design?.designName
                        : null,
                    this.document,
                    this.localization.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Message'),
                    this.isNewHomePage
                )
            ];
        }

    }

    private getCompanyProjectDropdownItemsNewHomePage() {
        return this.document.getProjectDropdownItemsNewHomePage(true);
    }

    private getNameTitleKey() {

        if (this.isTemplate || this.isCreateTemplate) {
            return 'Agito.Hilti.Profis3.AddEditDesign.TemplateName';
        }

        return 'Agito.Hilti.Profis3.AddEditDesign.Name';
    }

    private getName() {
        if (this.isFromCheckBotDisplay) {
            return this.modalInstance?.input?.design?.name;
        }

        if (this.isTemplate) {
            return this.modalInstance.input?.design?.design?.templateName || this.user.design?.templateName || this.design?.name;
        }

        if ([AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)) {
            if (this.selectedModuleDesignInfo != null) {
                return SafeFunctionInvokerHelper.safeInvoke(() => this.selectedModuleDesignInfo?.getDesignName(), '');
            }

            return this.document.createUniqueName(
                createDesignName(this.localization, this.designType),
                Object.values((this.modalInstance?.input?.project || this.document.draftsProject).designs).map((item) => item.designName)
            );
        }

        // Edit design with moduleDesignInfo
        if (this.selectedModuleDesignInfo != null) {
            return this.modalInstance.input?.design?.name || this.user.design?.designName;
        }

        return this.design?.name;
    }

    private focusNameTextbox() {
        if (this.addEditType != AddEditType.add) {
            return;
        }

        setTimeout(() => (document.querySelector<HTMLElement>('.modal-body .name-text-box .input'))?.focus());
    }

    private initRegionDropdown() {
        let selectedDefaultRegion: CommonRegion = null;

        if (this.userSettings.settings.application.general.regionId.value != null) {
            selectedDefaultRegion = this.regions.find((region) => region.id == this.userSettings.settings.application.general.regionId.value);
        }

        if (selectedDefaultRegion == null) {
            selectedDefaultRegion = this.regions.length > 0 ? this.regions[0] : undefined;
        }

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        let selectedRegion: CommonRegion;
        if (this.addEditType == AddEditType.addFromCheckbot) {
            selectedRegion = selectedDefaultRegion;
        }
        else if ([AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)) {
            selectedRegion = this.modalInstance?.input?.region
                || this.modalInstance?.input?.design?.region
                || selectedDefaultRegion;
        }
        else {
            const regionId = this.design?.region?.id || this.user.design?.region?.id;
            selectedRegion = (this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[]).find((row) => row.id == regionId);
        }
        this.regionDropdown = {
            id: 'add-edit-design-region-dropdown',
            title: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.Region'),
            items: this.regions.map(region => this.createDropdownRegion(region, codeListDeps)),
            selectedValue: selectedRegion
        };

        this.regionId = this.regionDropdown?.selectedValue?.id;
    }

    private resetRegionValues() {
        const selectedRegionId = this.regionDropdown.selectedValue.id;
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        this.regionDropdown.items = this.regions.map(region => this.createDropdownRegion(region, codeListDeps));

        const selectedRegion = this.regions.find((it) => it.id == selectedRegionId);
        const region = selectedRegion ?? this.regions[0];
        this.onRegionDropdownSelectedValueChange(region);
    }

    private createDropdownRegion(region: CommonRegion, codeListDeps: ICodeListTextDeps) {
        return {
            id: `add-edit-design-region-dropdown-${region.id}`,
            value: region,
            text: region.getTranslatedNameText(codeListDeps)
        };
    }

    private onRegionChanged(region: CommonRegion) {
        this.regionId = region.id;

        if (this.selectedModuleDesignInfo != null) {
            if (SafeFunctionInvokerHelper.safeInvoke(() => this.selectedModuleDesignInfo.isAvailable(this.regionId), false)) {
                this.addEditDesignModuleComponent.selectedRegionId = region.id;

                if (this.addEditDesignModuleFooterComponent) {
                    this.addEditDesignModuleFooterComponent.selectedRegionId = region.id;
                }
            }
            else {
                const designInfo = this.designInfoData.find(x => x.designTypeId == this.selectedModuleDesignInfo.designTypeId && SafeFunctionInvokerHelper.safeInvoke(() => x.isAvailable(this.regionId), false));
                this.openAddEditComponentModule(designInfo);
            }
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    private getProjectDropdownItemsNewHomePage() {
        return this.document.getProjectDropdownItemsNewHomePage();
    }

    private getTemplateFolderDropdownItems() {
        return this.flattenTemplateFolder(this.templateService.getSortedTemplateFolders(this.templateService.buildTemplateTree(this.templateService.templatesFoldersFlat)));
    }

    private flattenTemplateFolder(folders: Record<string, DesignTemplateFolderDetail>): DropdownItem<DesignTemplateFolderDetail>[] {
        const flattenedFolders: DropdownItem<DesignTemplateFolderDetail>[] = [];

        function flattenHelper(node: DesignTemplateFolderDetail, level: number) {
            const { subFolders } = node;
            flattenedFolders.push({ text: node.name, level: level, value: cloneDeep(node) })
            for (const childId in subFolders) {
                flattenHelper(subFolders[childId], level + 1);
            }

        }

        for (const key in folders) {

            flattenHelper(folders[key], 0);
        }

        for (const item in flattenedFolders) {
            flattenedFolders[item].value.subFolders = {};
        }

        return sortBy(flattenedFolders);
    }

    private getProjectDropdownItems() {
        const items: DropdownItem<Project>[] = [];

        for (const project of sortBy(this.document.projects, (project) => (project.name || '').toLowerCase())) {
            if (project.id != this.document.draftsProject.id) {
                items.push({
                    value: project,
                    text: project.name
                });

                for (const subProject of sortBy(project.subProjects, (subProject) => (subProject.name || '').toLowerCase())) {
                    items.push({
                        value: subProject,
                        text: subProject.name,
                        level: 1
                    });
                }
            }
        }

        return items;
    }

    private openAddEditComponentModule(designInfo: IDesignInfo) {
        if(designInfo == null) {
            return;
        }

        this.selectedModuleDesignInfo = designInfo;
        this._designType = new DesignType({ id: -1 } as IDesignTypeConstructor); // TODO: Remove when modularization is finished (DiaphragmDesign)

        const selectedRegion = this.regionDropdown.selectedValue;

        // create component
        const component = window.document.createElement(designInfo.componentTagName) as IModuleIAddEditDesignComponentInternal;

        if (designInfo.loadComponentImmidietly) {
            // attach component to the document immidietly
            this.componentInjector.nativeElement.replaceChildren(component);
        }

        // set component inputs
        component.selectedRegionId = selectedRegion.id;
        component.addEditType = this.addEditType;
        component.afterOpenInstructions = [];
        component.changeComponentModule = this.changeComponentModule;
        let instructions: number[] = [];
        if (this.openAdvancedSettings) {
            instructions = instructions.concat(AfterOpenInstruction.openAdvancedSettings);
        }
        if (this.modalInstance?.input) {
            instructions = instructions.concat(this.modalInstance.input?.afterOpenInstructions);
        }

        const design = this.modalInstance?.input?.design;
        const connectionSetup = {
            SteelSetup: {
                SteelSafetyFactorGammaM0: design?.steelSafetyFactorGammaM0,
                SteelSafetyFactorGammaM1: design?.steelSafetyFactorGammaM1,
                SteelSafetyFactorGammaM2: design?.steelSafetyFactorGammaM2,
                SteelCapacityFactor: design?.steelCapacityFactor,
                WeldsCapacityFactor: design?.weldsCapacityFactor,
                INSteelSafetyFactorGammaM0: design?.inSteelSafetyFactorGammaM0,
                INSteelSafetyFactorGammaM1: design?.inSteelSafetyFactorGammaM1,
                INSteelSafetyFactorGammaMb: design?.inSteelSafetyFactorGammaMb,
                INSteelSafetyFactorGammaMw: design?.inSteelSafetyFactorGammaMw,
            } as SteelSetup
        } as ConnectionSetup;

        if (this.isFromCheckBotDisplay) {
            this.checkBotProjectInfo = {
                designName: design?.name,
                projectName: design?.projectName,
                designStandard: design?.designStandard,
                Details: connectionSetup
            };
        }
        else {
            this.checkBotProjectInfo = null;
        }

        component.afterOpenInstructions = instructions;
        component.designTypeId = designInfo.designTypeId;
        component.displayDesignType = this.isTemplate ? DisplayDesignType.template : DisplayDesignType.design;
        component.checkBotProjectInfo = this.checkBotProjectInfo;
        component.submitted = this.submitted;
        if (this.modalInstance?.input) {
            component.detailedDisplayDesignFromModule = this.modalInstance.input?.design;
        }
        component.designInfo = designInfo;

        if (!designInfo.loadComponentImmidietly) {
            // attach component to the document after inputs are set
            this.componentInjector.nativeElement.replaceChildren(component);
        }

        // ensure footer component
        if (designInfo.componentFooterTagName != null) {
            // create footer component
            const footerComponent = window.document.createElement(designInfo.componentFooterTagName) as IModuleIAddEditDesignComponentFooterInternal;

            if (designInfo.loadComponentImmidietly) {
                // attach footer component to the document immidietly
                this.componentFooterInjector.nativeElement.replaceChildren(footerComponent);
            }

            // set footer component inputs
            footerComponent.selectedRegionId = selectedRegion.id;
            footerComponent.addEditType = this.addEditType;
            footerComponent.submitted = this.submitted;

            if (!designInfo.loadComponentImmidietly) {
                // attach footer component to the document after inputs are set
                this.componentFooterInjector.nativeElement.replaceChildren(footerComponent);
            }
        }

        // Set region dropdown
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        const allRegions = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        this.regionDropdown.items = allRegions
            .filter(region => designInfo.availableRegions.includes(region.id))
            .map(region => this.createDropdownRegion(region, codeListDeps));

        this.regionDropdown.selectedValue = allRegions.find(r => r.id == selectedRegion.id);

        if ([AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)) {
            // Set project name
            let selectedProjectDesigns: string[] = [];
            const designName = SafeFunctionInvokerHelper.safeInvoke(() => designInfo.getDesignName(), '');

            if (this.isNewHomePage) {
                selectedProjectDesigns = this.getSelectedFolderDocuments();
            } else {
                selectedProjectDesigns = Object.values(this.projectDropdown.selectedValue.designs).map((item) => item.designName);

            }
            this.nameTextBox.value = this.document.createUniqueName(designName, selectedProjectDesigns);
        }
    }

    public get formValid() {
        if (this.designType == null) {
            return false;
        }

        if (
            this.addEditType == AddEditType.add
            && this.selectedModuleDesignInfo != null
            && this.isDesignInfoDisabled(this.selectedModuleDesignInfo)
        ){
            return false;
        }

        if (this.isDiaphragmDesignDisabled()) {
            return true;
        }

        if (this.deckingEnabled && this.designType.id === this.designTypeIdEnum.DiaphragmDesign) {
            return !this.loadingDeckingSettings;
        }

        return true;
    }

    getLoadingDeckingStatus($event: boolean) {
        this.loadingDeckingSettings = $event;

    }

    getSelectedFolderDocuments(): string[] {
        let selectedFolderDocuments : string[] = [];
        //Push template names
        if (this.isCreateTemplate) {
            const templates = this.selectedTemplateFolder ? this.templateService.findTemplateFolderById(this.selectedTemplateFolder?.templateFolderId)?.templates : this.templateService.templateV2;
            selectedFolderDocuments = Object.values(templates).map((item) => item.DesignTemplateName);
        }
        //Push design names
        else {
            const designs = this.document.findAllDesignsByProject(this.projectDropdown.selectedValue);
            selectedFolderDocuments = Object.values(designs).map((item) => item.designName);
        }

        return selectedFolderDocuments;
    }
}
