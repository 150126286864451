<div class="area-content">
    <div class="content-group">
        <!--DECK PROPERTIES-->
        <div class="content">
            <pe-text-box
                class="area-name-input"
                [title]="'Agito.Hilti.Profis3.Decking.Substitution.Zones.AreaName.Tooltip.Title' | l10n : localization.selectedLanguage"
                [id]="'area_name_' + index"
                [disabled]="saving"
                [value]="substitutionArea.name.value"
                [maxLength]="60"
                [updateOnBlur]="true"
                SelectTextOnClick
                (valueChange)="substitutionArea.name.value=$any($event).detail; emitChanges(false)"
            >
            </pe-text-box>
            <div class="title-content">
                <p
                    class="control-label"
                    l10n="Agito.Hilti.Profis3.Decking.Areas.DeckProperties.Title"
                    [attr.id]="'areaOpt-deckProps-headerLabel-' + index"
                ></p>
                <pe-control-title [infoPopup]="zoneOrientationInfoPopup"></pe-control-title>
            </div>
            <div class="custom-radio-button deck-type-radio-button">
                <decking-radio-button
                    *ngIf="deckTypeControl?.id"
                    class="radio-button-group control"
                    [id]="deckTypeControl.id"
                    [title]="deckTypeControl.title"
                    [items]="deckTypeControl.items"
                    [look]="deckTypeControl.look"
                    [disabled]="saving"
                    [selectedValue]="substitutionArea.deckType"
                    (selectedValueChange)="onDeckTypeChanged($any($event).detail)"
                    [tooltip]="{title:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.DeckType.Tooltip.Title' | l10n:localization.selectedLanguage, content:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.DeckType.Tooltip.Text' | l10n:localization.selectedLanguage}"
                >
                </decking-radio-button>
            </div>

            <div
                class="content-controls"
                *ngIf="this.substitutionArea.deckType.id != deckTypes.SteelroofDeck"
            >
                <decking-dropdown
                    [id]="'areaOpt-deckProps-deckFillDrop-' + index"
                    [title]="
                        'Agito.Hilti.Profis3.Decking.Areas.DeckProperties.DeckFill'
                            | l10n : localization.selectedLanguage
                    "
                    [items]="deckFillItems"
                    [disabled]="saving"
                    [selectedValue]="substitutionArea.deckFill"
                    (selectedValueChange)="substitutionArea.deckFill=$any($event).detail; emitChangesDeckFill()"
                    class="control"
                    [tooltip]="{
                        title:
                            'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.DeckFill.Tooltip.Title'
                            | l10n : localization.selectedLanguage,
                        content:
                            'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.DeckFill.Tooltip.Text'
                            | l10n : localization.selectedLanguage
                    }"
                >
                </decking-dropdown>

                <div class="content-numeric-controls">
                    <decking-dropdown
                        [id]="'areaOpt-deckProps-compressiveDrop-' + index"
                        [title]="
                            'Agito.Hilti.Profis3.Decking.Areas.DeckProperties.CompressiveStrength'
                                | l10n : localization.selectedLanguage
                        "
                        [items]="compressiveStrengthItems"
                        [disabled]="saving || isDeckFillNoneSelected"
                        [selectedValue]="substitutionArea.compressiveStrength"
                        (selectedValueChange)="substitutionArea.compressiveStrength=$any($event).detail; emitChanges()"
                        class="control compressive-drop"
                        [notSelectedText]="' '"
                        [tooltip]="{
                            title:
                                'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.CompressiveStrength.Tooltip.Title'
                                | l10n : localization.selectedLanguage,
                            content:
                                'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.CompressiveStrength.Tooltip.Text'
                                | l10n : localization.selectedLanguage
                        }"
                    >
                    </decking-dropdown>

                    <decking-numeric-text-box
                        SelectTextOnClick
                        [id]="'areaOpt-deckProps-thicknessInput-' + index"
                        [title]="
                            'Agito.Hilti.Profis3.Decking.Areas.DeckProperties.FillThickness'
                                | l10n : localization.selectedLanguage
                        "
                        [value]="substitutionArea.fillThickness.value"
                        [minValue]="fillThicknessMinValue()"
                        [maxValue]="152.4"
                        [precision]="3"
                        [disabled]="
                            saving ||
                            isDeckFillNoneSelected ||
                            isInsulatingConcreteSelected
                        "
                        (valueChange)="substitutionArea.fillThickness.value=$any($event).detail; emitChanges()"
                        [unit]="lengthGeneralUnit$ | async"
                        [updateOnBlur]="true"
                        [required]="true"
                        [tooltip]="{
                            title:
                                'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.ThicknessOfFill.Tooltip.Title'
                                | l10n : localization.selectedLanguage,
                            content:
                                'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.ThicknessOfFill.Tooltip.Text'
                                | l10n : localization.selectedLanguage
                        }"
                    >
                    </decking-numeric-text-box>
                </div>
            </div>

            <div class="content-controls">
                <decking-dropdown
                    [id]="'areaOpt-deckProps-deckPanelDrop-' + index"
                    [title]="'Agito.Hilti.Profis3.Decking.Areas.DeckProperties.DeckPanel' | l10n:localization.selectedLanguage"
                    [items]="deckPanelItems$ | async"
                    [disabled]="saving"
                    [selectedValue]="substitutionArea.deckPanel"
                    (selectedValueChange)="onDeckPanelSelected($any($event).detail);"
                    class="control"
                    [tooltip]="{title:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.DeckPanel.Tooltip.Title' | l10n:localization.selectedLanguage, content:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.DeckPanel.Tooltip.Text' | l10n:localization.selectedLanguage}">
                </decking-dropdown>

                <decking-dropdown
                    [id]="'areaOpt-deckProps-panelTypeDrop-' + index"
                    [title]="'Agito.Hilti.Profis3.Decking.Areas.DeckProperties.PanelType' | l10n:localization.selectedLanguage"
                    [items]="panelTypeItems$ | async"
                    [disabled]="saving"
                    [selectedValue]="substitutionArea.panelType"
                    (selectedValueChange)="onPanelTypeSelected($any($event).detail);"
                    [infoPopup]="panelTypeInfoPopup"
                    class="control"
                    [tooltip]="{title:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.PanelType.Tooltip.Title' | l10n:localization.selectedLanguage, content:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.PanelType.Tooltip.Text' | l10n:localization.selectedLanguage}">
                </decking-dropdown>

                <decking-dropdown
                    [id]="'areaOpt-deckProps-panelWidthDrop-' + index"
                    [title]="'Agito.Hilti.Profis3.Decking.Areas.DeckProperties.PanelWidth' | l10n:localization.selectedLanguage"
                    [items]="panelWidthItems$ | async"
                    [disabled]="saving"
                    [selectedValue]="substitutionArea.panelWidth"
                    (selectedValueChange)="substitutionArea.panelWidth=$any($event).detail; onPanelWidthSelected()"
                    class="control"
                    [tooltip]="{title:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.PanelWidth.Tooltip.Title' | l10n:localization.selectedLanguage, content:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.PanelWidth.Tooltip.Text' | l10n:localization.selectedLanguage}">
                </decking-dropdown>
            </div>

        </div>

        <!--JOIST / BEAM PROPERTIES-->
        <div class="content">
            <p class="control-label"
                l10n="Agito.Hilti.Profis3.Decking.Areas.JoistBeamProperties.Title"
                [attr.id]="'areaOpt-beamProps-headerLabel-' + index"></p>

            <div class="content-controls">

                <decking-dropdown
                    [id]="'areaOpt-beamProps-supportDrop-' + index"
                    [title]="'Agito.Hilti.Profis3.Decking.Areas.JoistBeamProperties.SupportConstruction' | l10n:localization.selectedLanguage"
                    [items]="supportConstructionItems"
                    [disabled]="saving"
                    [selectedValue]="substitutionArea.supportConstruction"
                    (selectedValueChange)="substitutionArea.supportConstruction=$any($event).detail; emitChanges()"
                    class="control"
                    [tooltip]="{title:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.SupportConstruction.Tooltip.Title' | l10n:localization.selectedLanguage, content:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.SupportConstruction.Tooltip.Text' | l10n:localization.selectedLanguage}">
                </decking-dropdown>

                <div class="custom-radio-button">
                    <!-- Joist Spacing -->
                    <pe-radio-button enableTootip
                        *ngIf="joistSpacingControl?.id"
                        class="radio-button-group control"
                        [id]="joistSpacingControl.id"
                        [title]="joistSpacingControl.title"
                        [items]="joistSpacingControl.items"
                        [look]="joistSpacingControl.look"
                        [disabled]="saving"
                        [selectedValue]="joistSpacingControl.selectedValue"
                        (selectedValueChange)="joistSpacingControlSelectedValueChange($any($event).detail)"
                        [tooltip]="{title:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.JoistSpacing.Tooltip.Title' | l10n:localization.selectedLanguage, content:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.JoistSpacing.Tooltip.Text' | l10n:localization.selectedLanguage}">
                    </pe-radio-button>
                </div>
                <div class="content-numeric-controls">
                    <!-- Joist Beam Thickness -->
                    <decking-numeric-text-box
                        SelectTextOnClick
                        [id]="'areaOpt-beamProps-beamThicknessInput-' + index"
                        [title]="'Agito.Hilti.Profis3.Decking.Areas.JoistBeamProperties.JoistBeamThickness' | l10n:localization.selectedLanguage"
                        [defaultStepperValue]="0"
                        [value]="substitutionArea.beam.thickness.value"
                        [minValue]="1.5"
                        [maxValue]="426720"
                        [disabled]="saving"
                        (valueChange)="substitutionArea.beam.thickness.value=$any($event).detail; emitChanges()"
                        [unit]="joistBeamThicknessUnit$ | async"
                        [updateOnBlur]="true"
                        [required]="true"
                        [tooltip]="{title:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.JoistThicknessNumeric.Tooltip.Title' | l10n:localization.selectedLanguage, content:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.JoistThicknessNumeric.Tooltip.Text' | l10n:localization.selectedLanguage}"
                        [infoPopup]="diaphragmStrengthInfoPopup"
                        [precision]="3"
                        [display]="numericTextBoxDisplay">
                    </decking-numeric-text-box>

                    <!-- Joist Beam Spacing -->
                    <div [ngClass]="{ 'radio-hidden': substitutionArea.zoneSpacing.value }">
                        <decking-numeric-text-box
                            SelectTextOnClick
                            [id]="'areaOpt-beamProps-beamSpacingInput-' + index"
                            [title]="'Agito.Hilti.Profis3.Decking.Areas.JoistBeamProperties.JoistBeamSpacing' | l10n:localization.selectedLanguage"
                            [defaultStepperValue]="0"
                            [value]="substitutionArea.beam.spacing.value"
                            [minValue]="304.8"
                            [maxValue]="18288"
                            [disabled]="saving"
                            (valueChange)="substitutionArea.beam.spacing.value=$any($event).detail; emitChanges()"
                            [unit]="joistBeamSpacingUnit$ | async"
                            [updateOnBlur]="true"
                            [required]="true"
                            [tooltip]="{title:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.JoistSpacingNumeric.Tooltip.Title' | l10n:localization.selectedLanguage, content:'Agito.Hilti.Profis3.Decking.Substitution.Areas.DeckProperties.JoistSpacingNumeric.Tooltip.Text' | l10n:localization.selectedLanguage}"
                            [precision]="2"
                            [display]="numericTextBoxDisplay">
                        </decking-numeric-text-box>
                    </div>
                </div>
            </div>
        </div>

        <!--SIDELAP PROPERTIES-->
        <div *ngIf="isSidelapEnabled" class="content">
            <div class="content-controls">
                <pe-dropdown
                    #deckingSubstitutionDefinitionConnection
                    id="deckSubstitutionData-connectorsDrop"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.DefinitionOfSidelapConnectors' | l10n:localization.selectedLanguage"
                    [items]="substitutionSidelapConnectorItems"
                    [selectedValue]="substitutionArea.definitionOfSidelapConnectors.id"
                    (selectedValueChange)="onSubstitutionDefinitionOfSidelapConnectorChanged($any($event).detail);"
                    class="control"
                    [disabled]="saving || isTemplate">
                </pe-dropdown>
                <span class="control"></span>
            </div>

            <div class="sidelap-content-controls fixed-inputs" *ngIf="substitutionArea.definitionOfSidelapConnectors.index==sidelapConnectorsType.ByConnectionSpacing">
                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'deckSubstitutionData-sidelapFromInput'"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.From' | l10n:localization.selectedLanguage"
                    [unit]="ConnectorsUnitFromLength$ | async"
                    [value]="substitutionArea.sidelapsSpacingSettings.sidelapSpacingFrom.value"
                    [minValue]="ONE_INCH_IN_MM"
                    [maxValue]="substitutionSidelapsSpacingSetting.sidelapSpacingTo.value"
                    [required]="true"
                    (valueChange)="substitutionArea.sidelapsSpacingSettings.sidelapSpacingFrom.value=$any($event).detail; emitChanges()"
                    [disabled]="saving || isTemplate">
                </pe-numeric-text-box>

                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'deckSubstitutionData-sidelapToInput'"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.To' | l10n:localization.selectedLanguage"
                    [unit]="ConnectorsUnitFromLength$ | async"
                    [value]="substitutionArea.sidelapsSpacingSettings.sidelapSpacingTo.value"
                    [minValue]="substitutionSidelapsSpacingSetting.sidelapSpacingFrom.value"
                    [maxValue]="maxValueSubstitutionSidelapToInput"
                    [required]="true"
                    (valueChange)="substitutionArea.sidelapsSpacingSettings.sidelapSpacingTo.value=$any($event).detail; emitChanges()"
                    [disabled]="saving || isTemplate">
                </pe-numeric-text-box>

                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'deckSubstitutionData-sidelapIncrementInput'"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Increment' | l10n:localization.selectedLanguage"
                    [unit]="ConnectorsUnitFromLength$ | async"
                    [value]="substitutionArea.sidelapsSpacingSettings.sidelapSpacingIncrement.value"
                    [minValue]="ONE_INCH_IN_MM"
                    [maxValue]="substitutionSidelapsSpacingSetting.sidelapSpacingTo.value > maxValueSubstitutionSidelapIncrement ? maxValueSubstitutionSidelapIncrement :  substitutionSidelapsSpacingSetting.sidelapSpacingTo.value"
                    [required]="true"
                    (valueChange)="substitutionArea.sidelapsSpacingSettings.sidelapSpacingIncrement.value=$any($event).detail; emitChanges()"
                    [disabled]="saving || isTemplate">
                </pe-numeric-text-box>
            </div>

            <div class="sidelap-content-controls fixed-inputs" *ngIf="substitutionArea.definitionOfSidelapConnectors.index==sidelapConnectorsType.ByNumberOfConnections">
                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'deckSubstitutionData-sidelapFromInput'"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.From' | l10n:localization.selectedLanguage"
                    [unit]="''"
                    [value]="substitutionArea.sidelapsNumberSettings.numberOfSidelapsFrom.value" 
                    [increment]="1"
                    [minValue]="1" 
                    [maxValue]="substitutionSidelapsNumberSetting.numberOfSidelapsTo.value"
                    (valueChange)="substitutionArea.sidelapsNumberSettings.numberOfSidelapsFrom.value=$any($event).detail; emitChanges()"
                    [disabled]="saving || isTemplate">
                </pe-numeric-text-box>
                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'deckSubstitutionData-sidelapToInput'" 
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.To' | l10n:localization.selectedLanguage"
                    [unit]="''"
                    [value]="substitutionArea.sidelapsNumberSettings.numberOfSidelapsTo.value" 
                    [increment]="1"
                    [minValue]="substitutionSidelapsNumberSetting.numberOfSidelapsFrom.value" 
                    [maxValue]="50"
                    (valueChange)="substitutionArea.sidelapsNumberSettings.numberOfSidelapsTo.value=$any($event).detail; emitChanges()"
                    [disabled]="saving || isTemplate">
                </pe-numeric-text-box>
                <pe-numeric-text-box
                    SelectTextOnClick
                    [id]="'deckSubstitutionData-sidelapIncrementInput'"
                    class="control"
                    [title]="'Agito.Hilti.Profis3.Decking.ApplicationSettings.Increment' | l10n:localization.selectedLanguage"
                    [unit]="''"
                    [value]="substitutionArea.sidelapsNumberSettings.numberOfSidelapsIncrement.value" 
                    [increment]="1"
                    [minValue]="1"
                    [maxValue]="5"
                    (valueChange)="substitutionArea.sidelapsNumberSettings.numberOfSidelapsIncrement.value=$any($event).detail; emitChanges()"
                    [disabled]="saving || isTemplate">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
