export class Deferred {
  promiseValue;
  resolveValue = () => {};
  rejectValue = () => {};
  constructor() {
    this.promiseValue = new Promise((resolve, reject) => {
      this.resolveValue = resolve;
      this.rejectValue = reject;
    });
  }
  get promise() {
    return this.promiseValue;
  }
  resolve(value) {
    this.resolveValue(value);
  }
  reject(reason) {
    this.rejectValue(reason);
  }
}
