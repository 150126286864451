<pe-modal-header [title]="'SP.ProductSelection.Title' | l10n: localizationService.selectedLanguage"
    [modalInstance]="ngbActiveModal">
</pe-modal-header>

@if (dataLoaded) {
<div id="product-tab-select-dialog">

    <div class="modal-body-content">

        <div class="left-side">
        </div>

        <div class="right-side">

            <div class="products" id="select-product-dialog-list-section">

                <virtual-scroller #virtualProductsComponentRef [items]="products" [parentScroll]="scrollElement"
                    [scrollAnimationTime]="0" [style.height.px]="products.length * productElementRowHeight">

                    @for (product of virtualProducts; track product.id) {
                    <div id="select-product-{{product.id}}-row" class="product modal-grid-button"
                        [class.selected]="this.selectedProductId == product.id" (click)="selectProduct(product)">

                        <div class="image-container">
                            <div class="image-sub-container">
                                <span
                                    class="no-image sprite-responsive pe-ui-sp-sprite pe-ui-sp-sprite-product-ui-no-photo-available"></span>
                            </div>

                            @if (product.image) {
                            <div class="image-sub-container">
                                <span class="image sprite-responsive pe-ui-sp-sprite"
                                    [class]="'pe-ui-sp-' + product.image"></span>
                            </div>
                            }
                        </div>
                        <div class="text-container">
                            <div class="text-wrapper">
                                <p class="product-name">{{product.name}}</p>
                            </div>
                        </div>

                    </div>
                    }

                </virtual-scroller>

            </div>

        </div>

    </div>

</div>
}
