export class DefaultPropertyInfo {
  values = {};
  isPropertyDisabled() {
    return false;
  }
  isPropertyHidden() {
    return false;
  }
  propertyDisabledChanged() {
    return () => {
      // no event to remove
    };
  }
  setPropertyValue(property, value) {
    this.values[property] = value;
  }
  getPropertyValue(property) {
    return this.values[property];
  }
}
