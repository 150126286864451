import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ApprovalsService } from '../../services/approval.service';
import { DataService } from '../../services/data.service';
import { DesignService, StrengthDesignDetails, UtilizationItem, UtilizationType } from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { L10nPipe } from '../../pipes/l10n.pipe';

interface IInputElement {
    value: string;
    label: string;
}

const noImageAvailable = 'sprite-product-dialog-no-photo-available';

@Component({
    selector: 'app-export-report-summary-strength',
    templateUrl: './export-report-summary-strength.component.html',
    styleUrls: ['./export-report-summary-strength.component.scss'],
    imports: [L10nPipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ExportReportSummaryStrengthComponent implements OnInit {
    @Input()
    public collapsed = false;

    @Input()
    public designDetails!: StrengthDesignDetails;

    @Input()
    public modelImage = '';

    public baseMaterial!: IInputElement;
    public strengthElementType!: IInputElement;
    public strengthElementSize!: IInputElement;
    public approval!: IInputElement;
    public approvalIssuedValid!: string;
    public proof!: string;
    public drillLength!: string;
    public strengtheningElementImage!: string;

    constructor(
        public localizationService: LocalizationService,
        public approvalsService: ApprovalsService,
        public unitService: UnitService,
        public dataService: DataService,
        public designService: DesignService
    ) { }

    public ngOnInit(): void {
        this.initSummarySection();
    }

    public getApprovalIssuedValidTitle(): string {
        const approvalIssuedDateFormatted = this.approvalsService.getApprovalIssuedDateFormatted(this.designDetails.properties.approval);
        const approvalValidDateFormatted = this.approvalsService.getApprovalValidDateFormatted(this.designDetails.properties.approval);

        const issuedTranslation = this.localizationService.getString('SP.Approval.Issued');
        const validTranslation = this.localizationService.getString('SP.Approval.Valid');

        const issuedTitle = approvalIssuedDateFormatted ? issuedTranslation : '';
        const validTitle = approvalValidDateFormatted ? validTranslation : '';

        const combinedTranslation = [issuedTitle, validTitle].filter(Boolean).join(' - ');
        return combinedTranslation || `${issuedTranslation} - ${validTranslation}`;
    }

    private initSummarySection() {
        // TODO determine actual fields and add actual values (from nola DB) once available
        this.strengthElementType = {
            value: this.getFastenerFamilyRegionalName(this.designDetails.properties.fastenerFamilyId, this.designDetails.regionId),
            label: this.localizationService.getString('SP.ExportReport.Summary.StrengtheningElementType')
        };
        this.strengthElementSize = {
            value: this.dataService.fastenersById[this.designDetails.properties.fastenerId].name,
            label: this.localizationService.getString('SP.ExportReport.Summary.StrengtheningElementSize')
        };

        this.baseMaterial = {
            value: this.dataService.getItemName(this.dataService.baseMaterialsById[this.designDetails.properties.baseMaterialId]),
            label: this.localizationService.getString('SP.ExportReport.Summary.BaseMaterial')
        };

        this.approval = {
            value: this.approvalsService.getApprovalNumber(this.designDetails.properties.approval),
            label: this.localizationService.getString('SP.Approval.Title')
        };
        this.approvalIssuedValid = this.getApprovalIssuedValid();

        const postInstalledReinforcementDesignKey = this.dataService.postInstalledReinforcementDesignsById[this.designDetails.properties.postInstalledReinforcementDesignId].nameKey;
        const approvalName = this.localizationService.getString(postInstalledReinforcementDesignKey);
        this.proof = `${this.designDetails.properties.designMethodName} + ${approvalName}`;

        // eslint-disable-next-line @typescript-eslint/no-deprecated
        const utilizationResult = this.designDetails.calculateResult?.utilizationResults.find(x => this.getDrillLength(x.utilizationItems) != null);
        this.drillLength = this.getDrillLength(utilizationResult?.utilizationItems) ?? '-';

        this.setStrengtheningElementPhoto(this.designDetails.properties.fastenerFamilyGroupId);
    }

    private getDrillLength(utilizationItems: UtilizationItem[] | undefined): string | null {
        if (utilizationItems === undefined) {
            return null;
        }

        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Length);
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Length);
        const defaultPrecision = this.unitService.getPrecision(defaultUnit);

        const value = utilizationItems.find(x => x.utilizationType == UtilizationType.StrengthenedMemberDrillLength)?.actualValue;

        if (value == null) {
            return null;
        }

        const valueToFormat = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unitService.formatNumber(valueToFormat, defaultPrecision) + ' ' + this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Length));
    }

    private getFastenerFamilyRegionalName(fastenerFamilyId: number, regionId: number): string {
        const fastenerFamily = this.dataService.fastenerFamiliesById[fastenerFamilyId];

        return fastenerFamily.regionalNames?.[regionId] ?? fastenerFamily.name;
    }

    private setStrengtheningElementPhoto(fastenerFamilyGroupId: number) {
        const fastenerFamilyGroup = this.dataService.fastenerFamilyGroupsById[fastenerFamilyGroupId];

        if (!fastenerFamilyGroup?.image) {
            this.strengtheningElementImage = noImageAvailable;
        }
        else {
            this.strengtheningElementImage = 'sprite-product-dialog-' + fastenerFamilyGroup.image;
        }
    }

    private getApprovalIssuedValid(): string {
        const approvalIssuedDateFormatted = this.approvalsService.getApprovalIssuedDateFormatted(this.designDetails.properties.approval);
        const approvalValidDateFormatted = this.approvalsService.getApprovalValidDateFormatted(this.designDetails.properties.approval);

        const combinedIssuedValid = [approvalIssuedDateFormatted, approvalValidDateFormatted].filter(x => x != null && x != '').join(' - ');

        return combinedIssuedValid != null && combinedIssuedValid != '' ? combinedIssuedValid : '-';
    }
}
