import { Injectable } from '@angular/core';
import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { UserServiceBase } from '@profis-engineering/pe-ui-common/services/user.common';

import { ChangedConvertedProperties, DesignDetails } from './design.service';
import { TrackingDetails } from './tracking.service';

export interface InternalDesign extends Design {
    designDetails: DesignDetails;
    trackingDetails: TrackingDetails;
    convertChanges?: ChangedConvertedProperties[];
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private baseService!: UserServiceBase<InternalDesign>;

    public setBaseService(baseService: UserServiceBase<InternalDesign>): void {
        this.baseService = baseService;
    }

    public get design(): InternalDesign {
        return this.baseService.design;
    }

    public get hasfloatingLimitReached(): boolean {
        return this.baseService.hasfloatingLimitReached;
    }

    public get hasFreeLicense(): boolean {
        return this.baseService.hasFreeLicense;
    }

    public get hasOnlyBasic(): boolean {
        return this.baseService.hasOnlyBasic;
    }

    public changeDesign(project?: Project, design?: Design): void {
        this.baseService.changeDesign(project, design);
    }

    public releaseAllFloatingLicenses(ignoreErrors: boolean): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
        return Promise.resolve(this.baseService.releaseAllFloatingLicenses(ignoreErrors) as unknown as Promise<void>);
    }

    public getHeaders(url: string, forceIncludeAuthenticationHeaders: boolean) {
        return this.baseService.getHeaders(url, forceIncludeAuthenticationHeaders);
    }
}
