<div class="modal-header">
    <span class="logo sprite sprite-alert-important"></span>
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.Main.Unauthorized.Popup.Title"></h3>
</div>

<div class="modal-body">
    <p l10n="Agito.Hilti.Profis3.Main.Unauthorized.Popup.Message"></p>
</div>

<div class="modal-footer">
    <button id="default-logout" type="button" class="modal-button button button-CTA" (click)="logOut()">
        <span class="text" l10n="Agito.Hilti.Profis3.Main.Unauthorized.Popup.Login"></span>
    </button>
</div>
