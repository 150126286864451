import { ButtonSize, ButtonType, NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Command, commandFromService } from '../../command';
import { getSpriteAsIconStyle } from '../../sprites';
import { PunchRegion, PunchTab } from './static-menu.punch.definitions';

export function createSPMenu_PostInstalledElement(): PunchTab {
    return {
        DisplayKey: 'SP.Navigation.TabPostInstalled',
        Id: 4,
        Name: 'postinstalled-tab',
        Image: 'tab-supplementary-reinforcement',
        IconImage: getSpriteAsIconStyle('sprite-tab-supplementary-reinforcement'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_PostInstalledElement_ShearReinforcement(),
            createSPMenu_PostInstalledElement_ReinforcementPosition(),
        ]
    };
}

function createSPMenu_PostInstalledElement_ShearReinforcement(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement',
        Id: 40,
        Name: 'strengthening-element-section',
        Controls: [
            {
                ControlType: 'ItemButton',
                Name: 'postinstalled-family',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Family',
                UIPropertyId: 'fastenerFamilyGroupId',
                Command: commandFromService(Command.OpenFastenerSelectionPopup)
            },
            {
                ControlType: 'Dropdown',
                Name: 'postinstalled-type-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Type',
                CodelistName: 'fastenerFamilies',
                UIPropertyId: 'fastenerFamilyId'
            },
            {
                ControlType: 'Dropdown',
                Name: 'postinstalled-size-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Size',
                CodelistName: 'fasteners',
                UIPropertyId: 'fastenerId'
            },
            {
                ControlType: 'Button',
                Name: 'postinstalled-approval-button',
                ButtonSize: ButtonSize.Full,
                ButtonType: ButtonType.Default,
                DisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.ViewApproval',
                UIPropertyId: 'approval',
                Command: commandFromService(Command.OpenApproval)
            }
        ]
    };
}

function createSPMenu_PostInstalledElement_ReinforcementPosition(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition',
        Id: 41,
        Name: 'reinforcement-position-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.DefineStrengtheningElement',
                Name: 'define-strenghtening-element',
                UIPropertyId: 'defineStrengtheningElement'
            },
            {
                ControlType: 'Dropdown',
                Name: 'installation-direction-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection',
                CodelistName: 'installationDirections',
                UIPropertyId: 'punchInstallationDirectionId'
            },
            {
                ControlType: 'Dropdown',
                Name: 'reinforcement-arrangement-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.ReinforcementArrangement',
                CodelistName: 'reinforcementArrangements',
                UIPropertyId: 'reinforcementArrangementId'
            },
            {
                ControlType: 'TextBox',
                Name: 'radii-x',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.RadiiX',
                UIPropertyId: 'radiiX'
            },
            {
                ControlType: 'TextBox',
                Name: 'radii-y',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.RadiiY',
                UIPropertyId: 'radiiY'
            },
            {
                ControlType: 'TextBox',
                Name: 'radii-corners',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.RadiiCorners',
                UIPropertyId: 'radiiCorners'
            },
            {
                ControlType: 'TextBox',
                Name: 'radii',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.Radii',
                UIPropertyId: 'radii'
            },
            {
                ControlType: 'TextBox',
                Name: 'min-edge-distance-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.MinEdgeDistanceX',
                UIPropertyId: 'punchMinEdgeDistanceX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'min-edge-distance-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.MinEdgeDistanceY',
                UIPropertyId: 'punchMinEdgeDistanceY',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'spacing-first-perimeter',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.SpacingFirstPerimeter',
                UIPropertyId: 'firstPerimeterSpacing'
            },
            {
                ControlType: 'TextBox',
                Name: 'spacing-between-subsequent-perimeters',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.SpacingBetweenSubsequentPerimeters',
                UIPropertyId: 'subsequentPerimeterSpacing'
            }
        ]
    };
}
