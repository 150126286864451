import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    TrackBulkReportsRequest, TrackDownloadApplicationRequest, TrackFavouriteClicksRequest, TrackMarketingPopupRequest, TrackPopupLinkRequest,
    TrackProjectUsageFromUiRequest, TrackUserFirstLoginRequest, TrackVirtualTourRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Entities';
import { MarketingCampaignType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.ProductInformationService.Shared.Enums';
import { UserDetails } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Tracking';
import { getJSONDateWithTimezoneOffset } from '@profis-engineering/pe-ui-common/helpers/date-time-helper';
import { ButtonEventType, CommonTrackingServiceBase, TrackingUserData } from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { LocalizationService } from './localization.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class CommonTrackingService extends CommonTrackingServiceBase {
    constructor(
        private readonly userService: UserService,
        private readonly userSettingsService: UserSettingsService,
        private readonly apiService: ApiService,
        private readonly localizationService: LocalizationService,
        private readonly browserService: BrowserService
    ) {
        super();
    }

    public getTrackingUserData(timezoneOffset?: number) {
        // uai
        const uai = this.browserService.getBrowserData();

        // contry of residence
        const countryOfResidenceFn = () => {
            if (this.userService.authentication.countryOfResidence == null || this.userService.authentication.countryOfResidence == '') {
                return this.userService.authentication.subscription_info.AuthorizationEntryList[0].Country
            }
            return this.userService.authentication.countryOfResidence;
        };

        const data: TrackingUserData = {
            BrowserType: uai.browser,
            BuyingCustomerOriginId: this.userService.authentication.subscription_info.AuthorizationEntryList[0].CustomerOriginID, // will be set on server side if license is available
            BuyingCustomerType: '', // will be set on server side
            BuyingSalesOrg: '', // will be set on server side
            CountryOfResidence: countryOfResidenceFn(),
            CustomerCountry: this.userService.authentication.subscription_info.AuthorizationEntryList[0].Country,
            CustomerId: this.userService.authentication.customerId,
            CustomerOriginId: this.userService.authentication.subscription_info.AuthorizationEntryList[0].CustomerOriginID,
            CustomerType: '', // will be set on server side
            DiagnosticsAgreement: this.userSettingsService.settings.application.general.userPrivacy.value ?? false,
            IsTrial: this.userService.hasTrialLicense, // will be set on server side if license is available
            License: this.userService.authentication.license, // will be set on server side if license is available
            OperatingSystem: uai.os,
            SalesOrg: '', // will be set on server side
            TimezoneOffset: timezoneOffset,
            UserId: '00000000000000000000000000000000', // will be set on server side
            UserIpAddress: '', // will be set on server side
            UserName: '', // will be set on server side
        };

        return data;
    }

    public getUserDetails(timezoneOffset: number): UserDetails {
        const srcData = this.getTrackingUserData(timezoneOffset);

        return {
            BrowserType: srcData.BrowserType,
            OperatingSystem: srcData.OperatingSystem,
            TimezoneOffset: srcData.TimezoneOffset,
            IPAddress: srcData.UserIpAddress,
            UserName: srcData.UserName,
            UserId: srcData.UserId,
            DiagnosticsAgreement: srcData.DiagnosticsAgreement,
            CustomerId: srcData.CustomerId,
            CustomerOriginId: srcData.CustomerOriginId,
            SalesOrg: srcData.SalesOrg,
            CustomerType: srcData.CustomerType,
            License: srcData.License,
            CountryOfResidence: srcData.CountryOfResidence,
            CustomerCountry: srcData.CustomerCountry,
            BuyingCustomerOriginId: srcData.BuyingCustomerOriginId,
            BuyingSalesOrg: srcData.BuyingSalesOrg,
            BuyingCustomerType: srcData.BuyingCustomerType,
            IsTrial: srcData.IsTrial
        };
    }

    public async trackOnButtonClick(buttonEventType: ButtonEventType, regionDisplayKey?: string, externalAppName?: string): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);
        let regionName = 'N/A'
        if (regionDisplayKey != undefined) {
            await this.localizationService.selectTranslations('en-US', [`Agito.Hilti.Profis3.CodeList.RegionEntity.${regionDisplayKey}`]);
            regionName = this.localizationService.getLocalizedStringByCulture(`Agito.Hilti.Profis3.CodeList.RegionEntity.${regionDisplayKey}`, 'en-US');
        }

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackOnButtonClick`;
        const data = {
            ButtonEvent: buttonEventType as any,
            UserDetails: userDetails,
            UIVersion: environment.applicationVersion,
            RegionName: regionName,
            ExternalAppName: externalAppName
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnActivity(activities: { [key: string]: string } = {}, externalAppName?: string): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackOnActivity`;
        const data = {
            Activities: activities,
            UserDetails: userDetails,
            UIVersion: environment.applicationVersion,
            ExternalAppName: externalAppName
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnDownloadApplication(applicationId: string): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackDownloadApplication`;
        const data: TrackDownloadApplicationRequest = {
            ApplicationId: applicationId,
            UserDetails: userDetails,
            UIVersion: environment.applicationVersion,
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackFavoriteClicks(clicksCount: number): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackFavoritesClicks`;
        const data: TrackFavouriteClicksRequest = {
            UserDetails: userDetails,
            UIVersion: environment.applicationVersion,
            ClicksCount: clicksCount
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackPopupShown(eventPopupType: MarketingCampaignType, eventPopupName: string): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackMarketingPopupShown`;
        const data: TrackMarketingPopupRequest = {
            UserDetails: userDetails,
            UIVersion: environment.applicationVersion,
            EventPopupName: eventPopupName,
            EventPopupType: eventPopupType
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackPopupDontShowAgain(eventPopupType: MarketingCampaignType, eventPopupName: string): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackMarketingPopupDontShowAgain`;
        const data: TrackMarketingPopupRequest = {
            UserDetails: userDetails,
            UIVersion: environment.applicationVersion,
            EventPopupName: eventPopupName,
            EventPopupType: eventPopupType
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackPopupLinkClicked(eventPopupType: MarketingCampaignType, eventPopupName: string, eventPopupUrl: string): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackPopupLinkClicked`;
        const data: TrackPopupLinkRequest = {
            UserDetails: userDetails,
            UIVersion: environment.applicationVersion,
            EventPopupName: eventPopupName,
            EventPopupType: eventPopupType,
            EventPopupUrl: eventPopupUrl
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackBulkReports(totalReports: number, cbfemDesigns: number): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackBulkReports`;
        const data: TrackBulkReportsRequest = {
            TotalReports: totalReports,
            CBFEMDesigns: cbfemDesigns,
            UserDetails: userDetails,
            UIVersion: environment.applicationVersion,
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackVirtualTour(virtualTourName: string, finishedStep: number, allSteps: number): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackVirtualTour`;

        const data: TrackVirtualTourRequest = {
            VirtualTourName: virtualTourName,
            VirtualTourFinishedStep: finishedStep,
            VirtualTourAllSteps: allSteps,
            UIVersion: environment.applicationVersion,
            UserDetails: userDetails
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackUserFirstLogin(): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackUserFirstLogin`;
        const data: TrackUserFirstLoginRequest = {
            UserDetails: userDetails,
            UIVersion: environment.applicationVersion,
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async hideMail(checked: boolean): Promise<void> {
        const url = `${environment.peTrackingServiceUrl}Tracking/HideMail/${checked}`;

        const request = new HttpRequest('POST', url, null, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackUsageFromUI(projectType: string) {
        const url = `${environment.peTrackingServiceUrl}Tracking/TrackUsageFromUI`;
        //set date
        const date = getJSONDateWithTimezoneOffset();
        //set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);
        const data = {
            ProjectType: projectType,
            UserDetails: userDetails,
            UIVersion: environment.applicationVersion
        }

        const request = new HttpRequest<TrackProjectUsageFromUiRequest>('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }
}
