import { Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { VertexBuffer } from '@babylonjs/core/Meshes/buffer.js';
import { CreateLineSystem } from '@babylonjs/core/Meshes/Builders/linesBuilder.js';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh.js';
import { createDashedLines as createDashedLinesVertexData } from './vertex-data-helper.js';
export function createDashedLines(name, scene, lines) {
  const vertexData = createDashedLinesVertexData(lines);
  const mesh = new LinesMesh(name, scene);
  vertexData.applyToMesh(mesh, false);
  return mesh;
}
export function updateDashedLines(mesh, lines) {
  const vertexData = createDashedLinesVertexData(lines);
  // will create a new index buffer - might be expensive
  mesh.setIndices(vertexData.indices ?? []);
  // will create a new vertices buffer - might be expensive
  mesh.setVerticesData(VertexBuffer.PositionKind, vertexData.positions ?? [], false);
}
// points[shapes][flattened coordinates of points of shape]
export function createImageMeshLineSystemSingle(name, points, scene) {
  return CreateLineSystem(name, {
    lines: calculateVectors(points)
  }, scene);
}
// points[element][area][x,y,...]
export function createImageMeshLineSystemMultiple(name, points, scene) {
  return points.reduce((a, element, i) => {
    if (element != null) {
      return a.concat(CreateLineSystem(name + '.' + i.toString(), {
        lines: calculateVectors(element)
      }, scene));
    } else {
      return a;
    }
  }, []);
}
function calculateVectors(points) {
  const linesVectors = [];
  for (const shape of points) {
    const linePoints = [];
    for (let point = 0; point < shape.length; point += 2) {
      const nextPoint = point == shape.length - 2 ? 0 : point + 2;
      linePoints.push(new Vector3(shape[point], shape[point + 1], 0));
      linePoints.push(new Vector3(shape[nextPoint], shape[nextPoint + 1], 0));
    }
    linesVectors.push(linePoints);
  }
  return linesVectors;
}
