import {
    OverlayCrossSectionEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList, ICodeListC2CConstructor } from './code-list';

export class OverlayCrossSection extends CodeList {

    public static fromService(codeList: OverlayCrossSectionEntityC2C) {
        return new OverlayCrossSection(CodeList.baseFromService('OverlayCrossSectionEntity', codeList, {
            image: codeList.image
        } as ICodeListC2CConstructor));
    }

    public override cloneDeep(): OverlayCrossSection {
        return super.cloneDeep() as OverlayCrossSection;
    }
}