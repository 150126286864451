import { CodeList } from './code-list';
import { TransverseReinforcementOptionEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class TransverseReinforcementOption extends CodeList {

    public static fromService(codeList: TransverseReinforcementOptionEntityC2C) {
        return new TransverseReinforcementOption(CodeList.baseFromService('TransverseReinforcementOptionEntityC2C', codeList));
    }

    public override cloneDeep(): TransverseReinforcementOption {
        return super.cloneDeep() as TransverseReinforcementOption;
    }
}
