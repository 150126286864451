import * as React from 'react';

import {
    ContolsStyleSheets, IControlProps
} from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import {
    IMenuFavorites, IMenuProps, IRegion, ITab
} from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    NavigationTabWidth, TooltipType
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import {
    tabExpandId, tabFavoritesId, tabHomeId
} from '@profis-engineering/pe-ui-common/services/menu.common';
import { createKBLink } from '@profis-engineering/pe-ui-shared/entities/KB-link';
import { environment } from '../../../environments/environment';
import ReactTooltip from '../../../scripts/react-tooltip';
import { getMenuControlImageReactData } from '../../helpers/react-helpers';
import { IntegrationsDataService } from '../../services/integrations-data.service';
import { LocalizationService } from '../../services/localization.service';
import { isControlHidden } from './MainMenuHelper';

export interface IServices {
    localization: LocalizationService;
}

interface IPosition {
    left: number;
    top: number;
}

export class MainMenu extends React.PureComponent<IMenuProps> {
    private services: IServices;
    private designRegionId: number;

    constructor(props?: IMenuProps) {
        super(props);

        this.selectTab = this.selectTab.bind(this);
        this.favoriteChanged = this.favoriteChanged.bind(this);
        this.collapseChanged = this.collapseChanged.bind(this);
        this.regionsOrderChanged = this.regionsOrderChanged.bind(this);
        this.commitRegionsOrder = this.commitRegionsOrder.bind(this);
        this.services = {
            localization: this.props.localization as LocalizationService,
        };

        // insert react-tooltip css to shadow-root
        ReactTooltip.addGlobalStyle();
    }

    public override render() {
        const tabsOrder = this.getTabsOrder();
        setTimeout(ReactTooltip.rebuild);
        // select first available tab if selectedTab is returned empty from menu-service.ts
        if (this.props.menu.selectedTab == null && tabsOrder.length > 1) {
            const tab = tabsOrder.find((tab) => tab != tabFavoritesId && tab != tabHomeId);
            this.selectTab(this.props.menu.tabs[tab].controlId);
        }

        return (
            <div className='react-menu'>
                <NavigationTabs
                    tabs={this.props.menu.tabs}
                    tabsOrder={tabsOrder}
                    selectedTab={this.props.menu.selectedTab}
                    tabSelected={this.selectTab}
                    services={this.services}
                    menuExpanded={this.props.menu.menuExpanded}
                    designRegionId={this.designRegionId}
                    disableDragToMoveText={this.props.disableDragToMoveText}
                    favorites={this.props.favorites} />

                <Tabs
                    selectedTab={this.props.menu.selectedTab}
                    tabs={this.props.menu.tabs}
                    footer={this.props.menu.footer}
                    regions={this.props.menu.regions}
                    controls={this.props.menu.controls}
                    favoriteChanged={this.favoriteChanged}
                    collapseChanged={this.collapseChanged}
                    services={this.services}
                    profis3KBUrlAci={this.props.profis3KBUrlAci}
                    disableDragToMoveText={this.props.disableDragToMoveText}
                    tabsHidden={this.props.tabsHidden}
                    designRegionId={this.designRegionId}
                    regionsOrderChanged={this.regionsOrderChanged}
                    commitRegionsOrder={this.commitRegionsOrder}
                    favorites={this.props.favorites}
                    applyControlsStyle={this.props.applyControlsStyle} />

                <ReactTooltip
                    place='top'
                    effect='solid'
                    delayHide={0}
                    delayShow={600}
                    delayUpdate={0}
                    class='react-tooltip'
                />
            </div>

        );
    }

    private getTabsOrder() {
        return this.props.menu.tabsOrder.filter((id) => {
            if (id == tabExpandId) {
                return true;
            }

            if (id == tabHomeId) { // always show home tab
                return true;
            }

            if (id == tabFavoritesId) {
                return this.props.favorites?.isHidden ? false : true;
            }

            if (id == 'existingreinforcement-tab') {
                return this.props.menu.tabs[id]?.hidden ? false : true;
            }

            const regions = this.props.menu.tabs[id].regionsOrder.map((x) => this.props.menu.regions[x]);
            return regions.some((region) => region.controlsOrder.some((x) => !this.props.menu.controls[x].hidden));
        });
    }

    private selectTab(id: string) {
        this.props.setState(prevMenu => ({
            ...prevMenu,
            selectedTab: id
        }));
    }

    private favoriteChanged(id: string, favorite: boolean) {
        const oldFavorite = this.props.menu.regions[id].favorite;
        const oldFavoritesOrder = this.props.menu.tabs[tabFavoritesId].regionsOrder;

        const newFavoritesOrder = this.props.menu.tabs[tabFavoritesId].regionsOrder.slice();

        if (favorite && !newFavoritesOrder.includes(id)) {
            newFavoritesOrder.push(id);
        }
        else if (!favorite && newFavoritesOrder.includes(id)) {
            newFavoritesOrder.splice(newFavoritesOrder.indexOf(id), 1);
        }

        const setStateFn = (favorite: boolean, favoriteOrder: string[]) => {
            this.props.setState(prevMenu => ({
                ...prevMenu,
                regions: {
                    ...prevMenu.regions,
                    [id]: {
                        ...prevMenu.regions[id],
                        favorite
                    }
                },
                tabs: {
                    ...prevMenu.tabs,
                    [tabFavoritesId]: {
                        ...prevMenu.tabs[tabFavoritesId],
                        regionsOrder: favoriteOrder
                    }
                }
            }));
        };

        setStateFn(favorite, newFavoritesOrder);

        this.props.favorites.update(this.props.menu.menuType, newFavoritesOrder.map((id) => this.props.favorites.getRegionId(id))).catch(() => {
            setStateFn(oldFavorite, oldFavoritesOrder);
        });
    }

    private collapseChanged(id: string, collapsed: boolean) {
        this.props.setState(prevMenu => ({
            ...prevMenu,
            regions: {
                ...prevMenu.regions,
                [id]: {
                    ...prevMenu.regions[id],
                    collapsed
                }
            }
        }));

        if (this.props.collapseChanged != null) {
            this.props.collapseChanged(id, collapsed);
        }
    }

    private regionsOrderChanged(tab: string, regionsOrder: string[]) {
        this.props.setState(prevMenu => ({
            ...prevMenu,
            tabs: {
                ...prevMenu.tabs,
                [tab]: {
                    ...prevMenu.tabs[tab],
                    regionsOrder
                }
            }
        }));
    }

    private commitRegionsOrder() {
        const tabs = this.props.menu.tabsOrder.filter((tab) => tab != tabFavoritesId && tab != tabHomeId && tab != tabExpandId);
        const regions = tabs.map((tab) => this.props.menu.tabs[tab].regionsOrder).flat();

        // region ids are of the form 'region-<number-id>'
        this.props.regionOrderUpdate(regions, this.props.menu.menuType);

        // favorites regions are saved separately, 2d menu doesn't have favourites
        if (this.props.menu.menuType != MenuType.Menu2D && this.props.menu.tabs[tabFavoritesId] != null) {
            this.props.favorites.update(this.props.menu.menuType, this.props.menu.tabs[tabFavoritesId].regionsOrder.map((id) => this.props.favorites.getRegionId(id)));
        }
    }
}

interface INavigationTabsProps {
    tabs: { [id: string]: ITab };
    tabsOrder: string[];
    selectedTab: string;
    tabSelected: (id: string) => void;
    is2d?: boolean;
    services: IServices;
    menuExpanded: boolean;
    designRegionId: number;
    disableDragToMoveText: boolean;
    favorites: IMenuFavorites;
}

class NavigationTabs extends React.PureComponent<INavigationTabsProps> {

    private get tabs() {
        return this.props.tabsOrder
            .map((id) => this.props.tabs[id])
            .filter(tab => !tab.hidden);
    }

    private getTabDisabledTooltip(tab: ITab): string {
        if (tab.disabled) {
            return "Agito.Hilti.Profis3.Navigation.TabSmartAnchor.ToActivate.Tooltip";
        }
        return undefined;
    }

    public override render() {
        const tabsExpandedClass = this.props.menuExpanded ? 'tabs-menu-expanded' : '';
        return (
            <div className={`tab-menu ${tabsExpandedClass}`}>
                <div className='tabs-menu-scroll'>
                    {
                        this.tabs.map((tab) =>
                            <NavigationTab
                                controlId={tab.controlId}
                                image={tab.image}
                                imageStyle={tab.imageStyle}
                                selectedImage={tab.selectedImage}
                                selectedImageStyle={tab.selectedImageStyle}
                                bottomSeparator={tab.bottomSeparator}
                                selected={tab.controlId == this.props.selectedTab}
                                tabSelected={tab.tabSelected || this.props.tabSelected}
                                key={tab.controlId}
                                name={tab.displayKey}
                                is2d={tab.is2d}
                                services={this.props.services}
                                menuExpanded={this.props.menuExpanded}
                                designRegionId={this.props.designRegionId}
                                disableDragToMoveText={this.props.disableDragToMoveText}
                                favorites={this.props.favorites}
                                disabled={tab.disabled}
                                backgroundColor={tab.backgroundColor}
                                textColor={tab.textColor}
                                disabledTooltip={this.getTabDisabledTooltip(tab)}
                            />)
                    }
                </div>
            </div>
        );
    }
}

interface INavigationTabProps {
    controlId: string;
    image: string;
    imageStyle?: IIconStyle;
    selectedImage: string;
    selectedImageStyle?: IIconStyle;
    tabSelected: (id: string) => void;
    selected: boolean;
    bottomSeparator: boolean;
    name: string;
    is2d?: boolean;
    services: IServices;
    menuExpanded: boolean;
    designRegionId: number;
    disableDragToMoveText: boolean;
    favorites: IMenuFavorites;
    disabled? : boolean;
    backgroundColor?: string;
    textColor?: string;
    disabledTooltip?: string;
}

class NavigationTab extends React.PureComponent<INavigationTabProps> {
    private onClickFn: () => void;

    constructor(props?: INavigationTabProps) {
        super(props);

        this.onClickFn = this.onClick.bind(this);
    }

    public override render() {
        const buttonSelectedClass = this.props.selected ? 'tab-button-selected' : '';
        const disabled = (this.props.controlId == tabFavoritesId && this.props.favorites.isDisabled) || this.props.disabled;
        const expandTabWrapper = this.props.controlId == tabExpandId ? 'tab-expand-wrapper' : '';
        const expandTab = this.props.controlId == tabExpandId ? 'tab-expand' : '';

        let tooltip = '';
        if (!this.props.menuExpanded && this.props.controlId != tabExpandId) {
            let disabledTooltipText = this.props.services.localization.getString(this.props.disabledTooltip) ?? this.props.favorites.tooltipText
            tooltip = disabled ? disabledTooltipText : this.props.services.localization.getString(this.props.name);
        }

        return (
            <div className={`tooltip-wrapper ${expandTabWrapper}`} data-tip={tooltip} data-place='right'>
                <button className={`tab-button ${expandTab} ${buttonSelectedClass}`} type='button' onClick={this.onClickFn} disabled={disabled} id={this.props.controlId}>
                    <NavigationTabContent
                        image={this.props.image}
                        imageStyle={this.props.imageStyle}
                        selectedImage={this.props.selectedImage}
                        selectedImageStyle={this.props.selectedImageStyle}
                        selected={this.props.selected}
                        name={this.props.name}
                        is2d={this.props.is2d}
                        controlId={this.props.controlId}
                        services={this.props.services}
                        menuExpanded={this.props.menuExpanded}
                        backgroundColor={this.props.backgroundColor}
                        textColor={this.props.textColor}
                    />
                </button>
            </div>
        );
    }

    private onClick() {
        if (this.props.tabSelected != null) {
            this.props.tabSelected(this.props.controlId);
        }
    }
}

interface INavigationTabContentProps {
    image: string;
    imageStyle?: IIconStyle;
    selectedImage: string;
    selectedImageStyle?: IIconStyle;
    selected: boolean;
    name: string;
    is2d?: boolean;
    controlId?: string;
    services?: IServices;
    menuExpanded: boolean;
    backgroundColor?: string;
    textColor?: string;
}

class NavigationTabContent extends React.PureComponent<INavigationTabContentProps> {
    public override render() {
        const selected = this.props.controlId == tabExpandId && this.props.menuExpanded;

        const imageData = getMenuControlImageReactData(
            selected ? this.props.selectedImage : this.props.image,
            selected ? this.props.selectedImageStyle : this.props.imageStyle
        );

        const tabDescription = this.props.menuExpanded && this.props.controlId != tabExpandId ?
            this.props.services.localization.getString(`${this.props.name}.Description`)
            : '';

        return (
            <div className='tab-button-content' style={ this.props.backgroundColor != null ? { backgroundColor: this.props.backgroundColor } : null}>
                <div className='tab-button-image'>
                    <div className='image'>
                        <div className={`sprite ${imageData.spriteName}`} style={imageData.elementStyle}></div>
                    </div>
                </div>
                <div className='tab-description' style={ this.props.textColor != null ? { color: this.props.textColor } : null}>{tabDescription}</div>
            </div>
        );
    }
}

interface ITabsProps {
    selectedTab: string;
    tabs: { [id: string]: ITab };
    footer?: IFooterProps;
    regions: { [id: string]: IRegion };
    controls: { [id: string]: IControlProps };
    favoriteChanged: (id: string, favorite: boolean) => void;
    collapseChanged: (id: string, collapsed: boolean) => void;
    services: IServices;
    profis3KBUrlAci?: string;
    designRegionId: number;
    disableDragToMoveText: boolean;
    tabsHidden: boolean;
    regionsOrderChanged: (tab: string, regionsOrder: string[]) => void;
    commitRegionsOrder: () => void;
    favorites: IMenuFavorites;
    applyControlsStyle?: (control: React.ComponentClass<any> & ContolsStyleSheets) => void;
}

class Tabs extends React.PureComponent<ITabsProps> {

    public override render() {
        const footerControls = this.props.footer?.controls;
        let isFooterVisible = false;

        if(this.props.footer){
             isFooterVisible = Object.values(footerControls)?.some(s=> !s.hidden);
        }

        return (
            <div className={`tabs-wrapper ${this.props.tabsHidden ? 'collapsed' : ''}`}>
                <div className={`tabs-scroll ${isFooterVisible ? 'bottom-offset' : ''}`} >
                    <div className={`tabs ${this.sizeClass}`}>
                        {
                            Object.values(this.props.tabs).map(tab => {
                                const regions = tab.regionsOrder.map(id => this.props.regions[id]);

                                return (<Tab
                                    controlId={tab.controlId}
                                    selected={this.props.selectedTab == tab.controlId}
                                    regions={regions}
                                    controls={this.props.controls}
                                    favoriteChanged={this.props.favoriteChanged}
                                    collapseChanged={this.props.collapseChanged}
                                    services={this.props.services}
                                    profis3KBUrlAci={this.props.profis3KBUrlAci}
                                    disableDragToMoveText={this.props.disableDragToMoveText}
                                    designRegionId={this.props.designRegionId}
                                    key={tab.controlId}
                                    regionsOrderChanged={regionsOrder => this.props.regionsOrderChanged(tab.controlId, regionsOrder)}
                                    commitRegionsOrder={() => this.props.commitRegionsOrder()}
                                    favorites={this.props.favorites}
                                    applyControlsStyle={this.props.applyControlsStyle}
                                />);
                            })
                        }
                    </div>
                </div>

                    <Footer
                        controls={footerControls}
                        applyControlsStyle={this.props.applyControlsStyle}
                    />

            </div>
        );
    }

    private get sizeClass(): string {
        const selectedTab = this.props.tabs[this.props.selectedTab];
        return selectedTab?.width == NavigationTabWidth.Extended ? 'size-extended' : '';
    }
}

interface IFooterProps {
    controls?: { [id: string]: IControlProps };
    applyControlsStyle?: (control: React.ComponentClass<any> & ContolsStyleSheets) => void;
}

class Footer extends React.PureComponent<IFooterProps> {
    constructor(props?: IFooterProps) {
        super(props);
    }
    public override render() {
        const controls = this.props.controls;
        if(!controls){
            return (null);
        }

        return (
            <div className='sticky-footer'>
                {
                    Object.values(controls).map((ctl) => {
                        const control = ctl;
                        const props = {
                            ...control,
                            key: `${control.controlId}`,
                        };

                        const element = React.createElement(control.type, props);
                        if (this.props.applyControlsStyle != undefined) {
                            this.props.applyControlsStyle(control.type)
                        }

                        return element;
                    })
                }
            </div>
        );
    }
}

interface ITabProps {
    controlId: string;
    regions: IRegion[];
    controls: { [id: string]: IControlProps };
    selected: boolean;
    favoriteChanged: (id: string, favorite: boolean) => void;
    collapseChanged: (id: string, collapsed: boolean) => void;
    services: IServices;
    profis3KBUrlAci?: string;
    designRegionId: number;
    disableDragToMoveText: boolean;
    regionsOrderChanged: (regionsOrder: string[]) => void;
    commitRegionsOrder: () => void;
    favorites: IMenuFavorites;
    applyControlsStyle?: (control: React.ComponentClass<any> & ContolsStyleSheets) => void;
}

class Tab extends React.PureComponent<ITabProps> {
    private dragged: string;

    constructor(props?: ITabProps) {
        super(props);

        this.onDragOver = this.onDragOver.bind(this);
        this.dragStarted = this.dragStarted.bind(this);
        this.dragEnded = this.dragEnded.bind(this);
    }

    public override render() {
        if (!this.props.selected) {
            return null;
        }

        const favoriteTab = this.props.controlId == tabFavoritesId;

        return (
            <div data-control-id={this.props.controlId} className='tab'>
                <div
                    className='regions'
                    onDrop={this.onDrop}
                    onDragOver={this.onDragOver} >
                    {
                        this.props.regions.map((region) => {
                            return (<Region
                                controlId={region.controlId}
                                title={region.title}
                                favorite={region.favorite}
                                preventFavorites={region.preventFavorites}
                                collapsed={region.collapsed}
                                controls={this.props.controls}
                                controlsOrder={region.controlsOrder}
                                favoriteChanged={this.props.favoriteChanged}
                                collapseChanged={this.props.collapseChanged}
                                services={this.props.services}
                                key={`${favoriteTab ? 'favorite-' : ''}${region.controlId}`}
                                favoriteTab={favoriteTab}
                                dragStarted={this.dragStarted}
                                dragEnded={this.dragEnded}
                                is2d={region.is2d}
                                profis3KBUrlAci={this.props.profis3KBUrlAci}
                                kbNumber={region.kbNumber}
                                kbLinkTemplate={region.kbLinkTemplate}
                                kbTooltip={region.kbTooltip}
                                disabled={region.disabled}
                                disabledTooltip={region.disabledTooltip}
                                tooltipType={region.tooltipType}
                                infoClicked={region.infoClicked}
                                disableDragToMoveText={this.props.disableDragToMoveText}
                                designRegionId={this.props.designRegionId}
                                favorites={this.props.favorites}
                                isNew={region.isNew}
                                applyControlsStyle={this.props.applyControlsStyle}
                            />);
                        })
                    }
                </div>
            </div>
        );
    }

    private onDrop(event: React.DragEvent) {
        event.preventDefault();
    }

    private onDragOver(event: React.DragEvent) {
        if (this.dragged == null) {
            return;
        }

        event.preventDefault();
        const activeElement = (event.target as HTMLElement).closest('.region') as HTMLElement;
        if (activeElement != null && activeElement.dataset['dragId'] != this.dragged) {
            const regionsOrder = this.props.regions.map((region) => region.controlId);

            const oldIndex = regionsOrder.indexOf(this.dragged);
            const newIndex = regionsOrder.indexOf(activeElement.dataset['dragId']);

            // on which mouse position to swap elements
            const activeElementBounding = activeElement.getBoundingClientRect();
            let swapHeight = activeElementBounding.top + (activeElementBounding.height > 110 ? 110 : activeElementBounding.height) - 10; // 10 to move it slightly from border
            swapHeight = Math.max(swapHeight, 0);
            // if old is below new and if mouse is above height
            // or if old is above new and if mouse is below height
            if (oldIndex > newIndex && event.clientY < swapHeight
                || oldIndex < newIndex && event.clientY > swapHeight) {
                regionsOrder.splice(oldIndex, 1); // remove old order
                regionsOrder.splice(newIndex, 0, this.dragged); // add new order

                this.props.regionsOrderChanged(regionsOrder);
            }
        }
    }

    private dragStarted(region: string) {
        this.dragged = region;
    }

    private dragEnded(region: string) {
        this.dragged = null;

        if (this.props.commitRegionsOrder != null) {
            this.props.commitRegionsOrder();
        }
    }
}

interface IRegionProps {
    controlId: string;
    title: string;
    favorite: boolean;
    preventFavorites?: boolean;
    collapsed: boolean;
    controls: { [id: string]: IControlProps };
    controlsOrder: string[];
    services: IServices;
    favoriteChanged: (id: string, favorite: boolean) => void;
    collapseChanged: (id: string, collapsed: boolean) => void;
    favoriteTab: boolean;
    dragStarted: (region: string) => void;
    dragEnded: (region: string) => void;
    is2d?: boolean;
    kbNumber?: string;
    profis3KBUrlAci?: string;
    kbLinkTemplate?: string;
    kbTooltip?: string;
    disabled?: boolean;
    disabledTooltip?: string;
    infoClicked?: () => void;
    tooltipType?: TooltipType;
    tooltip?: string;
    tooltipTitle?: string;
    designRegionId: number;
    disableDragToMoveText: boolean;
    favorites: IMenuFavorites;
    isNew?: boolean;
    applyControlsStyle?: (control: React.ComponentClass<any> & ContolsStyleSheets) => void;
}

/* Control types that should not have any padding / marigin set (should have full width) for PE and C2C */
const FULL_WIDTH_CONTROLS: React.ComponentClass<any>[] = [];

class Region extends React.PureComponent<IRegionProps> {
    constructor(props?: IRegionProps) {
        super(props);

        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    public override render() {
        if (this.props.controlsOrder.every((id) => isControlHidden(this.props.controls[id]))) {
            return null;
        }

        const controlsStyle = this.props.collapsed ? { display: 'none' } as React.CSSProperties : null;
        return (
            <div
                data-control-id={this.props.controlId}
                className='region box-section'
                draggable={false}
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
                data-drag-id={this.props.controlId} >
                <RegionHeader
                    controlId={this.props.controlId}
                    title={this.props.title}
                    favorite={this.props.favorite}
                    preventFavorites={this.props.preventFavorites}
                    collapsed={this.props.collapsed}
                    favoriteChanged={this.props.favoriteChanged}
                    collapseChanged={this.props.collapseChanged}
                    services={this.props.services}
                    is2d={this.props.is2d}
                    kbNumber={this.props.kbNumber}
                    kbLinkTemplate={this.props.kbLinkTemplate}
                    kbTooltip={this.props.kbTooltip}
                    tooltipType={this.props.tooltipType}
                    infoClicked={this.props.infoClicked}
                    showAddOn={this.props.disabled}
                    designRegionId={this.props.designRegionId}
                    disableDragToMoveText={this.props.disableDragToMoveText}
                    profis3KBUrlAci={this.props.profis3KBUrlAci}
                    favorites={this.props.favorites}
                    isNew={this.props.isNew}
                />

                <div className='region-content'>
                    <div className={`controls box-section-content ${this.additionalControlsStyle}`} style={controlsStyle}>
                        {
                            this.props.controlsOrder.map((id) => {
                                const control = this.props.controls[id];
                                const props = {
                                    ...control,
                                    key: `${this.props.favoriteTab ? 'favorite-' : ''}${control.controlId}`,
                                    controls: (control as any).controlsOrder != null ? this.props.controls : undefined,  // HACK: so that group controls work
                                    applyControlsStyle: this.props.applyControlsStyle,
                                    services: this.props.services
                                };

                                const element = React.createElement(control.type, props);
                                if (this.props.applyControlsStyle != undefined) {
                                    this.props.applyControlsStyle(control.type)
                                }
                                return element;
                            })
                        }
                    </div>

                    {this.props.disabled ? <div className='region-disabled' data-tip={this.props.disabledTooltip}></div> : null}
                </div>
            </div>
        );
    }

    private get additionalControlsStyle(): string {
        const childControls = Object.values(this.props.controls).filter(cnt => this.props.controlsOrder.includes(cnt.controlId));
        const fullWidthChildren = childControls.filter(cnt => FULL_WIDTH_CONTROLS.includes(cnt.type));
        return fullWidthChildren.length > 0 ? 'full-width' : '';
    }

    private onDragStart(event: React.DragEvent) {
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('Text', this.props.controlId);

        const tooltip = (event.currentTarget as HTMLElement).closest('react-tooltip'); // hide tooltip when drag starts
        ReactTooltip.hide(tooltip as any);

        if (this.props.dragStarted != null) {
            this.props.dragStarted(this.props.controlId);
        }
    }

    private onDragEnd(event: React.DragEvent) {
        if (this.props.dragEnded != null) {
            this.props.dragEnded(this.props.controlId);
        }
    }
}

interface IRegionHeaderProps {
    controlId: string;
    title: string;
    favorite: boolean;
    preventFavorites?: boolean;
    collapsed: boolean;
    favoriteChanged: (id: string, favorite: boolean) => void;
    collapseChanged: (id: string, collapsed: boolean) => void;
    services: IServices;
    profis3KBUrlAci?: string;
    is2d?: boolean;
    kbLinkTemplate?: string;
    kbNumber?: string;
    kbTooltip?: string;
    infoClicked?: () => void;
    tooltipType?: TooltipType;
    tooltip?: string;
    tooltipTitle?: string;
    showAddOn?: boolean;
    designRegionId: number;
    disableDragToMoveText: boolean;
    favorites: IMenuFavorites;
    isNew?: boolean;
}

class RegionHeader extends React.PureComponent<IRegionHeaderProps> {
    private favoriteClickFn: () => void;
    private collapseClickFn: () => void;
    private onMouseEnterFn: (event: React.MouseEvent) => void;
    private onMouseLeaveFn: (event: React.MouseEvent) => void;
    private htmlTooltip: string;

    constructor(props?: IRegionHeaderProps) {
        super(props);

        this.favoriteClickFn = this.favoriteClick.bind(this);
        this.collapseClickFn = this.collapseClick.bind(this);
        this.onMouseEnterFn = this.onMouseEnter.bind(this);
        this.onMouseLeaveFn = this.onMouseLeave.bind(this);
        this.onKBTooltipClick = this.onKBTooltipClick.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
    }

    public override render() {
        const favoriteClass = this.props.favorite ? 'sprite-favorite-true' : 'sprite-favorite-false';
        const collapseClass = this.props.collapsed ? 'sprite-lines-expanded' : 'sprite-lines';
        const hideFavorites = this.props.preventFavorites || this.props.favorites.isHidden ? { display: 'none' } as React.CSSProperties : null;
        const disableFavorites = this.props.favorites.isDisabled;

        const dragToMoveTxt = this.props.disableDragToMoveText ? null : this.props.services.localization.getString('Agito.Hilti.Profis3.Main.Region.DragToMove');
        const addRemoveFromFavoritesTxt = this.props.favorites.tooltipText ?? this.props.services.localization.getString('Agito.Hilti.Profis3.Main.Region.AddRemoveFromFavorites');
        const showHideTxt = this.props.services.localization.getString('Agito.Hilti.Profis3.Main.Region.ShowHide');
        const kbTooltip = this.props.kbTooltip != null ? this.props.services.localization.getString(this.props.kbTooltip) : null;

        let titleClass = 'title';
        let infoClass = 'control-tooltip-popup sprite sprite-info-tooltip';
        let kbLinkClass = infoClass + ' kb-tooltip';

        if (this.props.tooltipType == TooltipType.Popup) {
            this.htmlTooltip = this.props.tooltip;
            titleClass += ' title-with-info';
            infoClass += ' info-margin';
            kbLinkClass += ' info-margin';

            if (this.props.services.localization.isHtml(this.htmlTooltip)) {
                this.htmlTooltip = this.htmlTooltip.replace(/agt-src="~/g, 'src="' + environment.baseUrl.replace(/\/+$/, ''));
            }
        }

        return (
            <div className='title-container box-section-header' onMouseEnter={this.onMouseEnterFn} onMouseLeave={this.onMouseLeaveFn}>
                <div className='drag-handle' data-tip={dragToMoveTxt} data-html={dragToMoveTxt != null ? true : null}>
                    <div>
                        <span className={titleClass}>{this.props.title}</span>

                        {this.props.kbNumber == null ? null :
                            <button
                                type='button'
                                className={kbLinkClass}
                                onClick={this.onKBTooltipClick}
                                data-tip={kbTooltip}
                                data-html={kbTooltip != null ? true : null}>
                            </button>
                        }

                        {this.props.tooltipType == TooltipType.Popup &&
                            <button
                                type='button'
                                className={infoClass}
                                onClick={this.onInfoClick}
                                data-tip={this.props.services.localization.getString('Agito.Hilti.Profis3.ControlTooltip.Popup')}>
                            </button>
                        }
                    </div>
                </div>

                {this.props.isNew &&
                    <div className='new-label'>
                        <span>{this.props.services.localization.getString('Agito.Hilti.Profis3.Main.New')}</span>
                    </div>
                }

                {this.props.showAddOn &&
                    <div className='add-on'>
                        {this.props.services.localization.getString('Agito.Hilti.Profis3.Main.Region.AddOn')}
                    </div>
                }

                <div className='tooltip-wrapper' data-tip={addRemoveFromFavoritesTxt} data-tooltip-content={addRemoveFromFavoritesTxt} >
                    <button className='favorite' type='button' onClick={this.favoriteClickFn} style={hideFavorites} disabled={disableFavorites}>
                        <span className={`sprite ${favoriteClass}`}></span>
                    </button>
                </div>
                <button className='region-collapse' type='button' onClick={this.collapseClickFn} data-tip={showHideTxt}>
                    <span className={`sprite ${collapseClass}`}></span>
                </button>
            </div>
        );
    }

    private onInfoClick(event: React.MouseEvent) {
        if (this.props.infoClicked != null) {
            this.props.infoClicked();
        }
    }

    private onMouseEnter(event: React.MouseEvent) {
        const region = (event.currentTarget as HTMLElement).closest('.region'); // enable draggable when mouse is over
        region.setAttribute('draggable', 'true');
    }

    private onMouseLeave(event: React.MouseEvent) {
        const region = (event.currentTarget as HTMLElement).closest('.region'); // disable draggable on mouse leave
        region.setAttribute('draggable', 'false');
    }

    private favoriteClick() {
        this.props.favoriteChanged(this.props.controlId, !this.props.favorite);
    }

    private collapseClick() {
        this.props.collapseChanged(this.props.controlId, !this.props.collapsed);
    }

    private onKBTooltipClick(event: React.MouseEvent) {
        window.open(createKBLink(this.props.kbLinkTemplate ?? this.props.profis3KBUrlAci, this.props.kbNumber), '_blank');
    }
}

export interface IImportExportProps extends IControlProps {
    importButtonText: string;
    exportButtonText: string;
    tryAgainButtonText: string;
    notConnectedText: string;
    errorText: string;
    oldVersionDetectedText: string;
    integrationsData: IntegrationsDataService;
}
