import {
    JointRoughnessEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class JointRoughness extends CodeList {

    public static fromService(codeList: JointRoughnessEntityC2C) {
        return new JointRoughness(CodeList.baseFromService('JointRoughnessEntity', codeList));
    }

    public override cloneDeep(): JointRoughness {
        return super.cloneDeep() as JointRoughness;
    }

}
