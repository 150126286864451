import cloneDeep from 'lodash-es/cloneDeep.js';
import { Texture } from '@babylonjs/core/Materials/Textures/texture.js';
import { textureExtensions } from './babylon-extensions.js';
import { randomString } from './random.js';
import { registerTextureLoader } from '@babylonjs/core/Materials/Textures/Loaders/textureLoaderManager.js';
export class DataImage {
  generateTexture;
  data;
  texture;
  image;
  texturePromise;
  imagePromise;
  constructor(generateTexture, texture, image) {
    this.generateTexture = generateTexture;
    this.texture = texture;
    this.image = image;
  }
  static fromDataUrl(dataUrl, invertY, texture, image) {
    return new DataImage((dataImage, scene) => {
      dataImage.data = {
        dataUrl
      };
      return DataImage.generateTextureFromDataUrl(dataUrl, invertY, scene);
    }, texture, image);
  }
  static generateTextureFromDataUrl(dataUrl, invertY, scene) {
    return new Promise((resolve, reject) => {
      const texture = Texture.CreateFromBase64String(dataUrl, `DataImage:${randomString(8)}`, scene, false, invertY, Texture.TRILINEAR_SAMPLINGMODE, () => {
        resolve(texture);
      }, () => {
        reject();
      });
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static updateWithDispose(dataImage, dataUrl, invertY) {
    if (dataImage != null) {
      dataImage.dispose();
    }
    dataUrl = dataUrl?.trim();
    return dataUrl ? DataImage.fromDataUrl(dataUrl, invertY) : undefined;
  }
  set _image(image) {
    this.image = image;
  }
  set _texture(texture) {
    this.texture = texture;
  }
  loadTexture(scene) {
    if (this.texture != null && textureExtensions.isDisposed(this.texture)) {
      this._texture = undefined;
    }
    if (this.texture != null) {
      return Promise.resolve(this.texture);
    }
    if (this.texturePromise != null) {
      return this.texturePromise;
    }
    this.texturePromise = this.generateTexture(this, scene);
    this.texturePromise.then(texture => {
      this._texture = texture;
    }).finally(() => {
      this.texturePromise = undefined;
    }).catch(error => console.error(error));
    return this.texturePromise;
  }
  loadImage() {
    if (this.image != null) {
      return Promise.resolve(this.image);
    }
    if (this.imagePromise != null) {
      return this.imagePromise;
    }
    const dataUrl = this.data?.dataUrl;
    if (dataUrl == null) {
      throw new Error('dataUrl not provided');
    }
    this.imagePromise = new Promise((resolve, reject) => {
      const image = new window.Image();
      image.onload = () => {
        this._image = image;
        this.imagePromise = undefined;
        resolve(image);
      };
      image.onerror = error => {
        this._image = undefined;
        this.imagePromise = undefined;
        reject(error);
      };
      image.src = dataUrl;
    });
    return this.imagePromise;
  }
  clone() {
    const dataImage = new DataImage(this.generateTexture, this.texture, this.image);
    dataImage.data = cloneDeep(this.data);
    return dataImage;
  }
  dispose() {
    if (this.texture != null) {
      // firefox bug - dispose after some time has passed
      setTimeout(() => {
        this.texture?.dispose();
        this._texture = undefined;
      }, 1234);
    }
  }
}
const ArrayBufferTextureLoaderName = 'ArrayBufferTextureLoader';
class ArrayBufferTextureLoader {
  supportCascades = false;
  loadCubeData() {
    throw new Error('not supported');
  }
  loadData(data, texture, callback, options) {
    const loaderOptions = options;
    callback(loaderOptions.width, loaderOptions.height, texture.generateMipMaps, false, () => {
      texture.getEngine()._uploadDataToTextureDirectly(texture, data);
    });
  }
}
const arrayBufferTextureLoader = new ArrayBufferTextureLoader();
registerTextureLoader(ArrayBufferTextureLoaderName, () => arrayBufferTextureLoader);
function createTextureInternal(image, scene, resolve, reject, loaderName, loaderOptions) {
  let texture;
  let isTextureLoaded = false;
  // eslint-disable-next-line prefer-const
  texture = new Texture('data:' + randomString(8), scene, false, true, Texture.TRILINEAR_SAMPLINGMODE, () => {
    isTextureLoaded = true;
    // texture variable might be undefined at this point
    if (texture != null) {
      resolve(texture);
    }
  }, reject, image, false, undefined, undefined, loaderOptions, undefined, loaderName);
  // onLoad for texture might already be done here so we can resolve the texture
  if (isTextureLoaded) {
    resolve(texture);
  }
}
export function createTexture(image, scene) {
  return new Promise((resolve, reject) => {
    if (typeof image == 'string') {
      createTextureInternal(image, scene, resolve, reject, undefined, undefined);
    } else if (image.buffer != null) {
      createTextureInternal(image.buffer, scene, resolve, reject, ArrayBufferTextureLoaderName, {
        height: image.height,
        width: image.width
      });
    } else {
      throw new Error('unknown image type');
    }
  });
}
