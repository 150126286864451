import { CodeList } from './code-list';
import { ReinforcmentAlphaCoefficientEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class ReinforcmentAlphaCoefficient extends CodeList {

    public static fromService(codeList: ReinforcmentAlphaCoefficientEntityC2C) {
        return new ReinforcmentAlphaCoefficient(CodeList.baseFromService('ReinforcmentAlphaCoefficientEntity', codeList));
    }

    public override cloneDeep(): ReinforcmentAlphaCoefficient {
        return super.cloneDeep() as ReinforcmentAlphaCoefficient;
    }
}
