import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApplicationError } from '@profis-engineering/pe-ui-common/entities/application-error';
import { DesignEvent } from '@profis-engineering/pe-ui-common/entities/design';
import { IDesignSectionExportComponentInput, IDesignSectionExportItem, Loading } from '@profis-engineering/pe-ui-common/entities/design-section-export';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { Feature } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { UploadDocumentModel } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import { DocumentIntegrationType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { IModalOpened, MODAL_DISMISS_REASON_BACKDROP, MODAL_DISMISS_REASON_ESC } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { environment } from '../../environments/environmentPe';
import { Region } from '../../shared/entities/code-lists/region';
import { DesignPe } from '../../shared/entities/design-pe';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import { IdeaProjectFileResponse } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import { UIProperty } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { ProjectDesignBaseEntity } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { AdvancedCalculationType, DesignType } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { PropertyMetaData } from '../../shared/properties/properties';
import { IExportReportSupportMethods } from '../components/export-report/export-report.component';
import { DocumentHelper } from '../helpers/document-helper';
import { getSpriteAsIconStyle, Sprite } from '../sprites';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { CalculationServicePE } from './calculation-pe.service';
import { CodeListService } from './code-list.service';
import { DocumentService } from './document.service';
import { ExportService } from './export.service';
import { FeaturesVisibilityInfoService } from './features-visibility-info.service';
import { ImportService } from './import.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { OfflineService } from './offline.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { TourService } from './tour.service';
import { TranslationFormatService } from './translation-format.service';
import { TrimbleConnectService } from './trimble-connect.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

export class LoadingPe extends Loading {
    public static readonly IDEAFileDownload = 10;
    public static readonly detailedOutput = 11;
}

@Injectable({
    providedIn: 'root'
})
export class DesignSectionService {
    public loading = false;
    public openingDesign = false;

    private duplicatingDesign = false;
    private exportReportModalOpened?: IModalOpened;
    private designSectionExportInput!: IDesignSectionExportComponentInput;


    constructor(
        private readonly localizationService: LocalizationService,
        private readonly offlineService: OfflineService,
        private readonly userService: UserService,
        private readonly modalService: ModalService,
        private readonly documentService: DocumentService,
        private readonly importService: ImportService,
        private readonly apiService: ApiService,
        private readonly browser: BrowserService,
        private readonly calculationServicePE: CalculationServicePE,
        private readonly featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private readonly userSettingsService: UserSettingsService,
        private readonly trimbleConnectService: TrimbleConnectService,
        private readonly exportService: ExportService,
        private readonly translationFormatService: TranslationFormatService,
        private readonly codeListService: CodeListService,
        private readonly sharedEnvironmentData: SharedEnvironmentService,
        private readonly tourService: TourService
    ) { }


    public get design() {
        return this.userService.design;
    }

    public get isDesignStateChanged() {
        return this.design.isStateChanged;
    }

    public get isDesignInReadOnlyMode() {
        return this.design.isTemplate || this.design.isReadOnlyDesignMode;
    }

    public get generateReportButtonDisabled() {
        if (this.design?.isTemplate) {
            return true;
        }

        if (this.isAsadVisible && !this.design.isAnchorAISolutionSelected && !this.design?.model[UIProperty.SmartAnchor_IsLastSelectedSolution]) {
            return true;
        }

        return this.design?.pendingCalculation || !this.design?.designData.reportData?.CanExportReport || this.loading;
    }

    public get saveButtonVisible() {
        return this.offlineService.isOffline;
    }

    public get saveButtonDisabled() {
        return this.isDesignInReadOnlyMode;
    }

    public get exportToIntegrationServicesButtonDisabled() {
        if (!this.sharedEnvironmentData.data?.integrationServicesServerEnabled) {
            return true;
        }

        if (this.design.pendingCalculation) {
            return true;
        }

        if (!this.featuresVisibilityInfoService.areIntegrationsEnabled) {
            return true;
        }

        if (this.design.isLedgerAngle) {
            return true;
        }

        if (this.isAsadVisible && !this.design.isAnchorAISolutionSelected) {
            return true;
        }

        return false;
    }

    public get exportButtonDisabled() {
        return this.isDesignInReadOnlyMode;
    }

    public get openDesignButtonDisabled() {
        return this.openingDesign || this.isDesignInReadOnlyMode;
    }

    public get isDetailedOutputDisabled() {
        return this.userService.design.designData.reportData?.DetailedCalculationOutput == null
            || this.userService.design.designData.reportData.DetailedCalculationOutput.length == 0
            || this.userService.design.designData.reportData.Shear?.ConcreteEdgeBreakout.Value == 0;
    }

    private get isAsadVisible() {
        return this.design.model[UIProperty.SmartAnchor_Enabled] as boolean;
    }

    // #region BIM/CAD
    private get exportCadDisabled() {
        return this.design.pendingCalculation
            || !this.design.designData.reportData?.CanExportReport
            || !this.browser.isOfflineOnLine
            || this.design.designType.id == DesignType.MetalDeck
            || this.design.isLedgerAngle
            || this.featuresVisibilityInfoService.isDisabled(Feature.Design_CADExport, this.design.region.id);
    }

    private get exportCadTooltipDisabled() {
        return this.browser.isOfflineOnLine
            && !this.exportCadDisabled;
    }

    private get exportCadTooltip() {
        if (this.design.designType.id == DesignType.MetalDeck) {
            return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.BimCadExportNotAvailableForConcreteOverMetalDeck');
        }

        if (this.design.isLedgerAngle) {
            return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.BimCadExportNotAvailableForLedgerAngle');
        }

        if (!this.browser.isOfflineOnLine) {
            return this.localizationService.getString('Agito.Hilti.Profis3.Browser.NoInternetConnection');
        }

        const advancedCalculation = this.design.properties.get(PropertyMetaData.AnchorPlate_AdvancedCalculation.id);
        if (this.design.baseplateDesignData == null && !advancedCalculation.disabled && !advancedCalculation.hidden) {
            // calculation was not done yet and no failed scopechecks
            return this.localizationService.getString('Agito.Hilti.Profis3.BimCadExport.NoCalculation.Disabled');
        }

        return this.featuresVisibilityInfoService.tooltip(Feature.Design_CADExport)
            ?? this.localizationService.getString('Agito.Hilti.Profis3.BimCadExport.Disabled');
    }
    // #endregion

    // #region Specification Text
    private get onlineTenderTextsUrl() {
        return (this.getRegionById(this.userSettingsService.settings.application.general.regionId.value as number) as Region)
            .onlineTenderTextsUrl;
    }

    private get isCustomSpecificationText() {
        if (this.onlineTenderTextsUrl != null) {
            return true;
        }

        return false;
    }

    private get specificationTextKey() {
        if (this.isCustomSpecificationText) {
            return 'Agito.Hilti.Profis3.Region.Link.OnlineTenderTextsUrl';
        }

        return 'Agito.Hilti.Profis3.DesignSectionExport.SpecificationText';
    }

    private get specificationTextDescriptionKey() {
        if (!this.isCustomSpecificationText) {
            return 'Agito.Hilti.Profis3.SpecificationText.Tooltip';
        }

        return null;
    }

    private get specificationTextTooltip() {
        return this.localizationService.getString('Agito.Hilti.Profis3.SpecificationText.Tooltip');
    }

    private get specificationTextDisabled() {
        return !this.userService.design.designData.reportData?.SpecificationText;
    }

    private get specificationTextTooltipEnabled() {
        return this.specificationTextDisabled && !this.isCustomSpecificationText;
    }
    // #endregion


    public getSprites(): Sprite[] {
        return [
            'sprite-long-arrow-right-white',
            'sprite-save-design',
            'sprite-export-design',
            'sprite-export-design-desktop',
            'sprite-openfile-d-light',
            'sprite-openfile-desktop',
            'sprite-virtual-tour-arrow-right',
            'sprite-specification-text-small',
            'sprite-specification-text-small-desktop'
        ];
    }

    public openGenerateReportPopup(exportReportSupportMethods: IExportReportSupportMethods, isRisaExport?: boolean, noAnimation?: boolean) {
        this.loading = true;

        const reportMessage = this.design.designData.reportData?.ReportOpenMessage;

        if (reportMessage != null && reportMessage != '') {
            this.modalService.openConfirmChange({
                id: 'confirm-export-modal',
                title: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ConfirmExportModal.Title'),
                message: reportMessage,
                confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ConfirmExportModal.ConfirmButton'),
                onConfirm: (modal) => {
                    modal.close();
                    this.openGenerateReportPopupInternal(exportReportSupportMethods, isRisaExport, noAnimation);
                }
            });
        }
        else {
            this.openGenerateReportPopupInternal(exportReportSupportMethods, isRisaExport, noAnimation);
        }

        setTimeout(() => this.tourService.onOpenCreateReport());
    }

    public closeGenerateReportPopup() {
        if (this.exportReportModalOpened != null) {
            this.exportReportModalOpened.close();
        }
    }

    public openDesignSectionExport() {
        this.modalService.openDesignSectionExport(this.designSectionExportInput);
    }

    public openGenerateReportFollowUpActionsPopup() {
        if (this.getGenerateReportFollowUpActionsHidden()) {
            return;
        }

        const modalOpened = this.modalService.openGenerateReportFollowUpActionsPopup(this.designSectionExportInput);
        modalOpened.closed.then(this.handleGenerateReportFollowUpActionsPopupClosed.bind(this));
    }

    public saveDesignOffline(): Promise<boolean> {
        const deferred = new Deferred<boolean>();
        const design = this.design;
        if (design.isStateChanged) {
            DocumentHelper.download(this.apiService, this.browser, this.localizationService, this.documentService, this.offlineService, design, true)
                .then((response) => {
                    if (response != null) {
                        design.setSavedStateIdx();
                        deferred.resolve(true);
                    }
                    else {
                        deferred.resolve(false);
                    }
                });
        }
        return deferred.promise;
    }

    public saveChangesBeforeImport(projectDesign: File | Blob, name?: string) {
        if (this.offlineService.isOffline && this.design.isStateChanged) {
            this.modalService.openConfirmChange({
                id: 'offline-design-perserve-changes',
                title: this.localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Title'),
                message: this.localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Message'),
                confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Confirm'),
                cancelButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Cancel'),
                onConfirm: (modal) => {
                    modal.close();
                    this.saveDesignOffline().then((saved: boolean) => {
                        if (saved) {
                            this.import(projectDesign, name);
                        }
                        else {
                            this.saveChangesBeforeImport(projectDesign, name);
                        }
                    });
                },
                onCancel: (modal) => {
                    modal.close();
                    this.design.savedStateIdx = this.design.currentStateIdx;
                    this.import(projectDesign, name);
                }
            });
        } else {
            this.import(projectDesign, name);
        }
    }

    public openSpecificationText(openedFromMainComponent = false){
        if (openedFromMainComponent) {
            this.design.usageCounter.SpecificationTextDesignSectionButtonClicked++;
        }
        else {
            this.design.usageCounter.SpecificationTextExportAsClicked++;
        }

        if (this.isCustomSpecificationText) {
            const tab = this.openInNewTab(this.onlineTenderTextsUrl);
            if (tab != null) {
                tab.focus();
            }

            return false;
        }

        const specificationText = this.translationFormatService.getLocalizedStringWithTranslationFormat(this.userService.design.designData.reportData?.SpecificationText);
        this.modalService.openSpecificationText(specificationText, true, this.onSpecificationTextCopiedToClipboard.bind(this));
        return true;
    }

    public duplicateDesign() {
        const retVal = new Deferred<boolean>();

        if (this.offlineService.isOffline) {
            DocumentHelper.download(this.apiService, this.browser, this.localizationService, this.documentService, this.offlineService, this.design, false).then(() => {
                this.design.setSavedStateIdx();
            });
            retVal.resolve(true);
            return retVal.promise;
        }

        if (this.duplicatingDesign) {
            retVal.resolve(true);
            return retVal.promise;
        }
        this.duplicatingDesign = true;

        this.design.usageCounter.DuplicateDesign++;

        const projectId = this.design.projectId;

        const projectDesign = { ...this.design.designData.projectDesign } as ProjectDesignBaseEntity;
        projectDesign.DesignName += ' (1)';

        this.calculationServicePE.createAndOpenFromProjectDesign(projectDesign, projectId, this.design.templateId, false)
            .finally(() => {
                this.duplicatingDesign = false;
                retVal.resolve(true);
            })
            .then((design) => {
                const openUrl = `${environment.baseUrl.replace(/\/+$/, '')}${UrlPath.main}${design.id}`;
                this.openInNewTab(openUrl);

                design.dispose();
            });

        return retVal.promise;
    }

    // #region Export items
    public initExportItems() {
        this.designSectionExportInput = {
            exportItems: [
                // PROFIS file to device
                this.getFileToDeviceExportItem(),
                // PROFIS File to Trimble Connect
                this.getFileToTrimbleConnectExportItem(),
                // Export PE File to Integration Services
                this.getFileToIntegrationServicesExportItem(),
                // 3D model export (to CAD)
                this.getCadModelExportExportItem(),
                // Quantity Calculator
                this.getQuantityCalculatorExportItem(),
                // Specification Text
                this.getSpecificationTextExportItem(),
                // IDEA file export
                this.getIdeaFileExportItem(),
                // Duplicate design
                this.getDuplicateDesignExportItem(),
                // Calculation Detailed Output
                this.getCalculationDetailedOutput()
            ]
        };
    }

    public getFileToDeviceExportItem(): IDesignSectionExportItem {
        return {
            condition: () => { return !this.offlineService.isOffline; },
            buttonClick: () => {
                const retVal = new Deferred<boolean>();
                const design = this.design;

                design.usageCounter.DownloadSourceFile++;

                DocumentHelper.download(this.apiService, this.browser, this.localizationService, this.documentService, this.offlineService, this.design)
                    .then(() => {
                        design.usageCounter.DesignExportedToDevice++;
                        retVal.resolve(true);
                    })
                    .catch((err) => {
                        if (err instanceof Error) {
                            console.error(err);
                        }

                        retVal.resolve(false);
                    });
                return retVal.promise;
            },
            buttonDisabled: () => { return this.design.pendingCalculation; },
            buttonLoading: LoadingPe.localFile,
            imageClass: 'sprite-profis-file',
            imageStyle: getSpriteAsIconStyle('sprite-profis-file'),
            messageKey: 'Agito.Hilti.Profis3.DesignSectionExport.ProfisFile',
            descriptionKey: 'Agito.Hilti.Profis3.DesignSectionExport.ProfisFile.Description',
            hasLoading: true
        };
    }

    public getFileToTrimbleConnectExportItem(): IDesignSectionExportItem {
        return {
            condition: () => true,
            tooltip: this.localizationService.getString('Agito.Hilti.Profis3.Browser.NoInternetConnection'),
            tooltipDisabled: () => !this.trimbleConnectService.isEnabled || this.browser.isOfflineOnLine,
            buttonClick: () => {
                const retVal = new Deferred<boolean>();
                const design = this.design;
                DocumentHelper.generateBlob(design, this.apiService, this.browser, this.documentService)
                    .then(projectDesign => {
                        retVal.resolve(true);

                        design.usageCounter.DesignExportedToTrimple++;
                        this.trimbleConnectService.uploadDesign(projectDesign)
                            .catch(err =>
                                (err != 'cancel-click' && err != MODAL_DISMISS_REASON_ESC && err != MODAL_DISMISS_REASON_BACKDROP)
                                    ? console.error(err)
                                    : undefined
                            );
                    }, () => null)
                    .catch((err) => {
                        if (err instanceof Error) {
                            console.error(err);
                        }

                        retVal.resolve(false);
                    });
                return retVal.promise;
            },
            buttonDisabled: () => {
                return !this.trimbleConnectService.isEnabled || !this.browser.isOfflineOnLine || (this.isAsadVisible && !this.design.isAnchorAISolutionSelected);
            },
            buttonLoading: LoadingPe.trimbleConnect,
            imageClass: 'sprite-trimble-file',
            imageStyle: getSpriteAsIconStyle('sprite-trimble-file'),
            messageKey: 'Agito.Hilti.Profis3.DesignSectionExport.TrimbleConnect',
            descriptionKey: 'Agito.Hilti.Profis3.DesignSectionExport.TrimbleConnect.Description' + (this.offlineService.isOffline ? '.Offline' : ''),
            hasLoading: true
        };
    }

    public getFileToIntegrationServicesExportItem(): IDesignSectionExportItem {
        return {
            condition: () => {
                return !this.offlineService.isOffline &&
                    this.sharedEnvironmentData.data?.integrationServicesServerEnabled &&
                    this.design.designType.id == DesignType.Concrete;
            },
            buttonClick: () => {
                // Track number of clicks.
                this.userService.design.usageCounter.DesignExportedToIntegrationServices++;
                return this.uploadFileToIntegrationServices();
            },
            buttonDisabled: () => {
                return this.exportToIntegrationServicesButtonDisabled;
            },
            buttonLoading: LoadingPe.integrationServicesExport,
            imageClass: 'sprite-3rd-party-plugins',
            imageStyle: getSpriteAsIconStyle('sprite-3rd-party-plugins'),
            messageKey: 'Agito.Hilti.Profis3.DesignSectionExport.IntegrationServices',
            descriptionKey: 'Agito.Hilti.Profis3.DesignSectionExport.IntegrationServices.Description',
            hasLoading: true
        };
    }

    public getCadModelExportExportItem(): IDesignSectionExportItem {
        return {
            condition: () => {
                return !this.featuresVisibilityInfoService.isHidden(Feature.Design_CADExport, this.design.region.id);
            },
            tooltip: () => this.exportCadTooltip,
            tooltipDisabled: () => this.exportCadTooltipDisabled,
            buttonClick: () => {
                this.modalService.openCadExport();
                return true;
            },
            buttonDisabled: () => { return (this.exportCadDisabled || (this.isAsadVisible && !this.design.isAnchorAISolutionSelected)); },
            buttonLoading: LoadingPe.cadFile,
            imageClass: 'sprite-cad-file',
            imageStyle: getSpriteAsIconStyle('sprite-cad-file'),
            messageKey: 'Agito.Hilti.Profis3.DesignSectionExport.CadFile',
            descriptionKey: 'Agito.Hilti.Profis3.DesignSectionExport.CadFile.Description',
            hasLoading: false
        };
    }

    public getQuantityCalculatorExportItem(): IDesignSectionExportItem {
        return {
            condition: () => {
                return !this.offlineService.isOffline &&
                    !(this.design.designType.id != DesignType.Handrail &&
                        this.design.calculationType != AdvancedCalculationType.Rigid &&
                        this.design.calculationType != AdvancedCalculationType.Realistic &&
                        this.design.calculationType != AdvancedCalculationType.BPRigidityCheck);
            },
            buttonClick: () => {
                this.design.usageCounter.QuantityCalculatorExport++;
                this.openInNewTab(`${this.sharedEnvironmentData.data?.externalQuantityCalculatorApplicationUrl}projectAndDesign/${this.design.id}`);
                return false;
            },
            buttonDisabled: () => { return this.design.pendingCalculation || !this.design.designData.reportData?.CanExportReport || (this.isAsadVisible && !this.design.isAnchorAISolutionSelected); },
            imageClass: 'sprite-quantity-calculator',
            imageStyle: getSpriteAsIconStyle('sprite-quantity-calculator'),
            messageKey: 'Agito.Hilti.Profis3.DesignSectionExport.QuantityCalculatorLink',
            descriptionKey: 'Agito.Hilti.Profis3.DesignSectionExport.QuantityCalculatorLink.Description',
            hasLoading: false
        };
    }

    public getSpecificationTextExportItem(): IDesignSectionExportItem {
        return {
            condition: () => true,
            tooltip: () => this.specificationTextTooltip,
            tooltipDisabled: () => this.specificationTextTooltipEnabled,
            buttonClick: () => {
                return this.openSpecificationText(false);
            },
            buttonDisabled: () => { return this.specificationTextDisabled || (this.isAsadVisible && !this.design.isAnchorAISolutionSelected); },
            buttonLoading: () => this.isCustomSpecificationText ? undefined : LoadingPe.specificationText,
            imageClass: 'sprite-specification-text',
            imageStyle: getSpriteAsIconStyle('sprite-specification-text'),
            messageKey: () => this.specificationTextKey,
            descriptionKey: this.specificationTextDescriptionKey ?? '',
            hasLoading: false
        };
    }

    public getIdeaFileExportItem(): IDesignSectionExportItem {
        return {
            condition: () => {
                return (this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation) &&
                    (environment.enableIDEAFileDownload || (this.sharedEnvironmentData.data?.useDevFeatures ?? false));
            },
            buttonClick: () => {
                const retVal = new Deferred<boolean>();
                const design = this.design;
                design.usageCounter.IdeaFileExport++;

                this.calculationServicePE.generateIdeaFile(design)
                    .then(async (response) => {
                        await this.handleIdeaProjectFileResponse(response.body, design.designName);
                        retVal.resolve(true);
                    })
                    .catch((err) => {
                        if (err instanceof Error) {
                            console.error(err);
                        }

                        retVal.resolve(false);
                    });

                return retVal.promise;
            },
            buttonDisabled: () => { return (!(this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation) || (this.isAsadVisible && !this.design.isAnchorAISolutionSelected)) ; },
            buttonLoading: LoadingPe.IDEAFileDownload,
            imageClass: 'sprite-profis-file',
            imageStyle: getSpriteAsIconStyle('sprite-profis-file'),
            messageKey: 'Agito.Hilti.Profis3.DesignSectionExport.IdeaFileText',
            descriptionKey: 'Agito.Hilti.Profis3.DesignSectionExport.IdeaFileText.Description',
            hasLoading: true
        };
    }

    public getDuplicateDesignExportItem(): IDesignSectionExportItem {
        return {
            condition: () => true,
            tooltip: this.localizationService.getString('Agito.Hilti.Profis3.Main.DuplicateDesign.Description'),
            buttonClick: () => {
                return this.duplicateDesign();
            },
            buttonDisabled: () => { return this.duplicatingDesign || this.isDesignInReadOnlyMode; },
            buttonLoading: LoadingPe.duplicateDesign,
            imageClass: 'sprite-duplicate-design',
            imageStyle: getSpriteAsIconStyle('sprite-duplicate-design'),
            messageKey: 'Agito.Hilti.Profis3.Main.DuplicateDesign',
            descriptionKey: 'Agito.Hilti.Profis3.Main.DuplicateDesign.Description',
            hasLoading: true
        };
    }

    public getCalculationDetailedOutput(): IDesignSectionExportItem {
        return {
            condition: () => { return true; },
            tooltip: this.localizationService.getString('Agito.Hilti.Profis3.Main.CalculationDetailedOutput.Description'),
            buttonClick: () => {
                this.design.usageCounter.CalculationDetailedOutput++;
                this.modalService.openDetailedCalculationOutput();
                return true;
            },
            buttonDisabled: () => { return this.isDetailedOutputDisabled; },
            buttonLoading: LoadingPe.detailedOutput,
            imageClass: 'sprite-profis-file',
            imageStyle: getSpriteAsIconStyle('sprite-profis-file'),
            messageKey: 'Agito.Hilti.Profis3.Main.CalculationDetailedOutput',
            descriptionKey: 'Agito.Hilti.Profis3.Main.CalculationDetailedOutput.Description',
            hasLoading: false
        };
    }
    // #endregion

    private getRegionById(regionId: number) {
        const regionCodeList = this.codeListService.projectCodeLists[ProjectCodeList.Region] as Region[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private openInNewTab(url: string) {
        return window.open(url, '_blank');
    }

    private openGenerateReportPopupInternal(exportReportSupportMethods: IExportReportSupportMethods, isRisaExport?: boolean, noAnimation?: boolean) {
        this.design.trigger(DesignEvent.exportReport);

        const exportReportModalOpened = this.modalService.openExportReport({
            createGlModelScreenshot2D: exportReportSupportMethods.createGlModelScreenshot2D,
            createGlModelScreenshot: exportReportSupportMethods.createGlModelScreenshot,
            createZoneDiagramScreenshot: exportReportSupportMethods.createZoneDiagramScreenshot,
            isExportRisa: isRisaExport,
            onModelImagesCreated: () => {
                // do nothing
            }
        }, noAnimation);
        exportReportModalOpened.closed.then(this.handleGenerateReportPopupClosed.bind(this));
        this.exportReportModalOpened = exportReportModalOpened;

        this.loading = false;
    }

    private handleGenerateReportPopupClosed(reportGenerated?: boolean) {
        if (reportGenerated === true) {
            this.openGenerateReportFollowUpActionsPopup();
        }
    }

    private async handleGenerateReportFollowUpActionsPopupClosed(doNotShowAgain?: boolean) {
        if (doNotShowAgain === true) {
            await this.setGenerateReportFollowUpActionsHidden();
            this.tourService.showDesignSectionExportLocationTour();
        }
    }

    private import(projectDesign: File | Blob, name?: string) {
        this.openingDesign = true;
        const currentProject = Object.values(this.documentService.projectsFlat).find((proj) => proj.id == this.design.projectId) as Project;
        return this.importService.import(currentProject, this.design, projectDesign, name as string, !this.offlineService.isOffline)
            .finally(() => {
                this.openingDesign = false;
            });
    }

    private uploadFileToIntegrationServices() {
        const design = this.design;
        const promise = new Deferred<void>();
        const retVal = new Deferred<boolean>();

        DocumentHelper.generateBlob(design, this.apiService, this.browser, this.documentService)
            .then((blob) => {
                if (blob == null) {
                    promise.reject('Could not generate PE document.');
                    retVal.resolve(false);
                    return;
                }

                this.exportService.blobToArrayBuffer(blob)
                    .then((arrayBuffer) => {
                        if (arrayBuffer == null) {
                            promise.reject('Could not transform blob to array buffer.');
                            retVal.resolve(false);
                            return;
                        }

                        const [filename, documentBytes] = this.getFilenameDocumentBytes(design, arrayBuffer);
                        const data = {
                            DocumentName: filename,
                            IntegrationType: DocumentIntegrationType.ProfisEngineer,
                            Content: documentBytes as any,
                            CustomerOriginId: this.userService.authentication.customerOriginId,
                            CountryCode: this.userService.authentication.country,
                        } as UploadDocumentModel;

                        const url = `${this.sharedEnvironmentData.data?.integrationServicesServerUrl}api/document/uploadDocument`;
                        const request = new HttpRequest('PUT', url, data, {
                            responseType: 'json'
                        });

                        this.uploadFileToIntegrationServicesInternal(request, promise, retVal);
                    }).catch((err) => {
                        if (err instanceof Error) {
                            console.error(err);
                        }

                        this.handleIntegrationServicesUploadError(
                            'Agito.Hilti.Profis3.ProjectAndDesign.Alerts.CannotUploadDocument.IntegrationServices.BlobTransformFailed.Description',
                            {
                                response: err,
                            },
                            promise,
                            'Error occurred transforming blob to array buffer.',
                            retVal
                        );
                    });

            }).catch((err) => {
                promise.reject(err);
                retVal.resolve(false);
            });
        return retVal.promise;
    }

    private uploadFileToIntegrationServicesInternal(request: HttpRequest<UploadDocumentModel>, promise: Deferred<void>, retVal: Deferred<boolean>) {
        this.apiService.request(request, { supressErrorMessage: true })
            .then((res) => {
                if (res.status != 200) {
                    this.handleIntegrationServicesUploadError(
                        'Agito.Hilti.Profis3.ProjectAndDesign.Alerts.CannotUploadDocument.IntegrationServices.UploadFailed.Description',
                        {
                            response: res,
                            endPointUrl: request.url,
                            requestPayload: request.body as object,
                            responsePayload: res
                        },
                        promise,
                        'Uploading document to integration services server failed.',
                        retVal
                    );
                    return;
                }

                retVal.resolve(true);
                this.modalService.openConfirmChange({
                    id: 'design-to-integration-services-uploaded',
                    title: this.localizationService.getString('Agito.Hilti.Profis3.ExportIntegrationServices.Sucess.Title'),
                    message: this.localizationService.getString('Agito.Hilti.Profis3.ExportIntegrationServices.Success.Description'),
                    confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.ExportIntegrationServices.Sucess.Button.Dismiss'),
                    onConfirm: (modal) => {
                        modal.close();
                    }
                });
            }).catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.handleIntegrationServicesUploadError(
                    'Agito.Hilti.Profis3.ProjectAndDesign.Alerts.CannotUploadDocument.IntegrationServices.UploadFailed.Description',
                    {
                        response: err,
                        endPointUrl: request.url,
                        requestPayload: request.body as object
                    },
                    promise,
                    'Uploading document to integration services server failed.',
                    retVal
                );
            });
    }

    private handleIntegrationServicesUploadError(message: string, applicationError: IApplicationError, promise: Deferred<void>, rejectionMessage: string, retVal: Deferred<boolean>) {
        retVal.resolve(false);

        this.modalService
            .openAlertError(
                this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesign.Alerts.CannotUploadDocument.IntegrationServices.Title'),
                this.localizationService.getString(message),
                applicationError
            )
            .closed
            .then(() => {
                promise.reject(rejectionMessage);
            });
    }

    private async handleIdeaProjectFileResponse(response: IdeaProjectFileResponse | null, designName: string) {
        const rigidBlob = this.browser.base64toBlob(response?.Rigid ?? '', 'application/ideaCon');
        const realisticBlob = response?.Realistic != null ? this.browser.base64toBlob(response.Realistic, 'application/ideaCon') : null;

        await this.browser.downloadBlob(rigidBlob, `${designName}.rigid.ideaCon`, false, false);

        if (realisticBlob != null) {
            await this.browser.downloadBlob(realisticBlob, `${designName}.realistic.ideaCon`, false, false);
        }
    }

    private getFilenameDocumentBytes(design: DesignPe, arrayBuffer: ArrayBuffer) {
        const documentBytes = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
        const filename = DocumentHelper.generateFileName(design, this.localizationService, this.documentService, this.offlineService);

        return [filename, documentBytes];
    }

    private getGenerateReportFollowUpActionsHidden() {
        return this.userSettingsService.settings.generateReportFollowUpActionsConcreteHidden?.value ?? false;
    }

    private async setGenerateReportFollowUpActionsHidden() {
        this.userSettingsService.settings.generateReportFollowUpActionsConcreteHidden.value = true;
        await this.userSettingsService.save();
    }

    private onSpecificationTextCopiedToClipboard() {
        this.design.usageCounter.SpecificationTextCopiedToClipboard++;
    }
}
