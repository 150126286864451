import { ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    BaseMaterialStructureEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class BaseMaterialStructure extends CodeList {

    public static fromService(codeList: BaseMaterialStructureEntityC2C) {
        return new BaseMaterialStructure(CodeList.baseFromService('BaseMaterialStructureEntity', codeList));
    }

    public override cloneDeep(): BaseMaterialStructure {
        return super.cloneDeep() as BaseMaterialStructure;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayKey;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
