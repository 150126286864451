import cloneDeepWith from 'lodash-es/cloneDeepWith.js';
import isEqualWith from 'lodash-es/isEqualWith.js';
import { Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
export function areEqual(values1, values2) {
  return isEqualWith(values1, values2, isEqual);
}
export function cloneComparableValues(value) {
  if (value != null && (typeof value == 'number' || typeof value == 'boolean' || typeof value == 'string')) {
    return value;
  } else if (value instanceof Vector2 || value instanceof Vector3) {
    return value.clone();
  } else {
    return undefined;
  }
}
export function copyComparableValues(linesMeshInfo, values, lines) {
  linesMeshInfo.values = cloneDeepWith(values, cloneComparableValues);
  linesMeshInfo.linesInfo = lines;
}
function isEqual(object1, object2) {
  if (object1 === object2) {
    return true;
  }
  if (object1 == null && object2 == null) {
    return true;
  }
  if (object1 == null || object2 == null) {
    return false;
  }
  if (object1 instanceof Vector2) {
    if (!(object2 instanceof Vector2)) {
      return false;
    }
    return object1.equals(object2);
  }
  if (object1 instanceof Vector3) {
    if (!(object2 instanceof Vector3)) {
      return false;
    }
    return object1.equals(object2);
  }
  return undefined;
}
