import { CodeList } from './code-list';
import { RoughnessEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class Roughness extends CodeList {

    public static fromService(codeList: RoughnessEntityC2C) {
        return new Roughness(CodeList.baseFromService('RoughnessEntity', codeList));
    }

    public override cloneDeep(): Roughness {
        return super.cloneDeep() as Roughness;
    }
}
