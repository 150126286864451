import { SpliceClassEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class SpliceClass extends CodeList {

    public static fromService(codeList: SpliceClassEntityC2C) {
        return new SpliceClass(CodeList.baseFromService('SpliceClassEntity', codeList, {
            id: codeList.id,
            displayKey: codeList.displayKey
        }));
    }

    public override cloneDeep(): SpliceClass {
        return super.cloneDeep() as SpliceClass;
    }

}
