import { ButtonGroupDisplay, ButtonGroupType, NavigationTabWidth, TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { getSpriteAsIconStyle } from '../../sprites';
import { PunchRegion, PunchTab } from './static-menu.punch.definitions';

export function createSPMenu_Loads(): PunchTab {
    return {
        DisplayKey: 'SP.Navigation.TabLoads',
        Id: 1,
        Name: 'loads-tab',
        Image: 'tab-loads',
        IconImage: getSpriteAsIconStyle('sprite-tab-loads'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_Loads_LoadType(),
            createSPMenu_Loads_Design()
        ]
    };
}

function createSPMenu_Loads_LoadType(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabLoads.RegionLoadType',
        Id: 10,
        Name: 'loadtype-section',
        Controls: [
            {
                ControlType: 'ToggleButtonGroup',
                ButtonGroupDisplay: ButtonGroupDisplay.Join,
                ButtonGroupType: ButtonGroupType.Single,
                ButtonGroupUnselectable: true,
                Name: 'loadtype-buttons',
                CodelistName: 'loadType',
                TooltipDisplayKey: 'SP.Navigation.TabLoads.RegionLoadType.LoadType',
                TooltipType: TooltipType.Normal,
                UIPropertyId: 'loadTypeId'
            },
            {
                CodelistName: 'beta',
                ControlType: 'Dropdown',
                Name: 'loadtype-beta-id-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabLoads.RegionLoadType.Beta',
                UIPropertyId: 'betaId'
            },
            {
                ControlType: 'TextBox',
                Name: 'loadtype-beta-input',
                Size: 50,
                TitleDisplayKey: 'SP.Navigation.TabLoads.RegionLoadType.Beta',
                UnitGroup: UnitGroup.None,
                UIPropertyId: 'beta'
            },
            {
                ControlType: 'TextBox',
                Name: 'loadtype-axial-stress',
                Size: 50,
                TitleDisplayKey: 'SP.Navigation.TabLoads.RegionLoadType.AxialStress',
                UnitGroup: UnitGroup.Stress,
                UIPropertyId: 'axialStress',
                TooltipDisplayKey: 'SP.Navigation.TabLoads.RegionLoadType.AxialStress.Tooltip',
                TooltipTitleDisplayKey: 'SP.Navigation.TabLoads.RegionLoadType.AxialStress.Tooltip.Title'
            },
        ]
    };
}

function createSPMenu_Loads_Design(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabLoads.RegionDesign',
        Id: 11,
        Name: 'design-section',
        Controls: [
            {
                CodelistName: 'designStandard',
                ControlType: 'Dropdown',
                Name: 'loadtype-designstandard-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabLoads.RegionDesign.ExistingMemberVerification',
                UIPropertyId: 'designStandardId'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabLoads.RegionDesign.UseNationalAnnex',
                Name: 'use-national-annex',
                UIPropertyId: 'nationalAnnex'
            },
            {
                CodelistName: 'postInstalledReinforcementDesign',
                ControlType: 'Dropdown',
                Name: 'loadtype-postinstalledreinforcementdesign-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabLoads.RegionDesign.PostInstalledReinforcementDesign',
                UIPropertyId: 'postInstalledReinforcementDesignId'
            },
        ]
    };
}
