import { Matrix, Quaternion, Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
export class GLTemp {
  vector2;
  vector3;
  matrix;
  quaternion;
  constructor(options) {
    this.vector2 = new Array(options.vector2 ?? 0).fill(undefined).map(() => Vector2.Zero());
    this.vector3 = new Array(options.vector3 ?? 0).fill(undefined).map(() => Vector3.Zero());
    this.matrix = new Array(options.matrix ?? 0).fill(undefined).map(() => Matrix.Zero());
    this.quaternion = new Array(options.quaternion ?? 0).fill(undefined).map(() => Quaternion.Zero());
  }
}
