import {
    InvokeOptions,
    SignalRConnectionBase, SignalRConnectionOptions, SignalRServiceBase
} from '@profis-engineering/pe-ui-common/services/signalr.common';
import { CalculateDesignRequestC2C, CalculationResultEntityC2C } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';

export abstract class SignalRConnectionC2C extends SignalRConnectionBase {

    public abstract get connectionUrl(): string;
    public abstract get requestData(): string | object;
    public abstract get responseData(): string | object;
    public abstract get requestId(): string;
    public abstract calculateDesignC2C(calculateDesignRequest: CalculateDesignRequestC2C, options?: InvokeOptions): Promise<CalculationResultEntityC2C>;
    public abstract setHubConnections(connectionOptions?: SignalRConnectionOptions): void;
}

/**
 * SignalRService base
 */
export abstract class SignalRServiceC2CBase extends SignalRServiceBase {
    public abstract common: SignalRConnectionC2C;
}
