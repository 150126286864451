import { ContolsStyleSheets, IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { buildHtmlTooltip } from '@profis-engineering/pe-ui-common/helpers/tooltip-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import isEqual from 'lodash-es/isEqual';
import { PointsTableType } from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Navigation';
import { LocalizationService } from '../../../services/localization.service';
import { UnitService } from '../../../services/unit.service';
import styles from './react-points-table.css';
import { PureComponent } from '../react-import';

const sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

interface IVector {
    x: number;
    y: number;
}

export interface ITableItem extends ITableItemBase {
    angle?: number;
    formattedAngle?: string;
}

// that should be same as defined in scss file
const ROW_HEIGHT = 32;
const ControlHeader = (window as any).pe.reactComponents.ControlHeader;

export interface IPointsTableProps extends IControlProps {
    items?: ITableItemBase[];
    tableType?: PointsTableType;
    isEditable?: boolean;
    canInsert?: boolean;
    addInput?: IPointTableAddInput;
    rowsPerScroll?: number;
    unit?: UnitService;
    valueChanged?: (item: IModifiedItem) => void;
    onCommit?: (item: IModifiedItem) => void;
    onDelete?: (index: number) => void;
    addInputChanged?: (item: IPointTableAddInput) => void;
    addInputCommit?: (item: IPointTableAddInput) => void;
}

export interface IModifiedItem {
    id: number;
    x?: string;
    y?: string;
    bond?: number;
    shape?: number;
    layerId?: string;
}

export interface IPointTableAddInput {
    x?: string;
    y?: string;
}

export interface ITableItemBase extends IPointsTableItem {
    id: number;
}

export interface IPointsTableItem {
    x: number;
    y: number;
    formattedX?: string;
    formattedY?: string;
    exactValueX?: string;
    exactValueY?: string;
    shape?: number;
    bond?: number;
    layerId?: string;
}

class _PointsTable extends PureComponent<IPointsTableProps> {
    private containerRef: HTMLDivElement | null = null;
    public static styleSheets = [sheet];

    constructor(props: IPointsTableProps) {
        super(props);
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const pointsTableId = `${this.props.controlId}-points-table`;
        const isPlatePointTable = this.props.tableType == PointsTableType.Plate;

        const items = this.prepareItems();
        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;
        const isDeletable = items.length > this.minimumNodesNumber;
        const scrollableClass = this.props.rowsPerScroll != null && this.props.rowsPerScroll > 0 ? 'scrollable' : '';

        return (
            <div data-control-id={this.props.controlId} className={`control react-points-table-control ${this.props.sizeClass} full-width`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={pointsTableId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className={'points-table-container ' + scrollableClass} style={{ ['--height' as any]: this.rowsHeight }} ref={(ref) => { this.containerRef = ref; }}>
                    <div className={'table-row header'} id={`${this.props.controlId}-header`}>
                        <div className={'id'} id={`${this.props.controlId}-header-id`}>#</div>
                        <div className={'number'} id={`${this.props.controlId}-header-x`}>X</div>
                        <div className={'number'} id={`${this.props.controlId}-header-y`}>Y</div>
                        {isPlatePointTable && <div className={'angle'}>α</div>}
                        <div className={'delete'}></div>
                    </div>

                    {
                        items.map(item =>
                            <PointsTableRow
                                controlName={this.props.controlId}
                                key={item.id}
                                item={item}
                                isEditable={this.props.isEditable!}
                                isDeleteable={isDeletable}
                                tooltip={this.props.tooltip!}
                                tooltipDisabled={this.props.tooltipDisabled!}
                                valueChanged={this.props.valueChanged!}
                                commitValue={this.props.onCommit!}
                                onDelete={this.props.onDelete!}
                                unit={this.props.unit as UnitService}
                                localization={this.props.localization as LocalizationService} />
                        )
                    }

                    {this.props.canInsert &&
                        <PointsTableInput
                            lastId={this.props.items != null ? this.props.items.length + 1 : 1}
                            parentControlName={this.props.controlId}
                            showAngle={isPlatePointTable}
                            isEditable={this.props.isEditable!}
                            item={this.props.addInput!}
                            valueChanged={this.props.addInputChanged!}
                            commitValue={this.props.addInputCommit!}
                            tableType={this.props.tableType}
                            unit={this.props.unit as UnitService}
                            localization={this.props.localization as LocalizationService} />
                    }
                </div>
            </div>
        );
    }

    private get rowsHeight(): string {
        return `${(this.props.rowsPerScroll ?? 0) * ROW_HEIGHT}px`;
    }

    private get minimumNodesNumber(): number {
        switch (this.props.tableType) {
            case PointsTableType.Plate:
                return 3;
            default:
                return 1;
        }
    }

    private formatNumber(internalValue: number) {
        const internalUnit = this.props.unit!.getInternalUnit(UnitGroup.Length);
        const defaultUnit = this.props.unit!.getDefaultUnit(UnitGroup.Length);

        const defaultValue = this.props.unit!.convertUnitValueArgsToUnit(internalValue, internalUnit, defaultUnit);
        return this.props.unit!.formatUnitValueArgs(defaultValue, defaultUnit);
    }

    private getExactValue(internalValue: number) {
        const maxPrecision = this.props.unit!.getDefaultPrecision() + 1; // Same as used in server code
        const unitValue = this.props.unit!.convertInternalValueToDefaultUnitValue(internalValue, UnitGroup.Length);
        const displayedUnitValue = this.props.unit!.formatUnitValue(unitValue);
        const exactUnitValue = this.props.unit!.formatUnitValue(unitValue, maxPrecision);

        if (displayedUnitValue?.length < exactUnitValue?.length) {
            return exactUnitValue;
        }

        return null;
    }

    private mapItems(): ITableItemBase[] {
        return this.props.items!.map((item, i) => {
            const formattedX = item.formattedX == null ? this.formatNumber(item.x) : item.formattedX;
            const formattedY = item.formattedY == null ? this.formatNumber(item.y) : item.formattedY;

            const exactValueX = item.exactValueX == null ? this.getExactValue(item.x) : item.exactValueX;
            const exactValueY = item.exactValueY == null ? this.getExactValue(item.y) : item.exactValueY;

            return {
                ...item,
                formattedX,
                formattedY,
                exactValueX,
                exactValueY,
                id: i
            } as ITableItemBase;
        });
    }

    private prepareItems(): ITableItemBase[] {
        const items = this.mapItems();

        if (this.props.tableType == PointsTableType.Plate) {
            this.calculateAngle(items);
        }

        return items;
    }

    private calculateAngle(items: ITableItem[]) {
        if (items != null && items.length >= 3) {
            const len = items.length;
            const defaultUnit = this.props.unit!.getDefaultUnit(UnitGroup.Angle);

            for (let i = 0; i < len; i++) {
                const firstPoint = items[((i - 1) % len + len) % len];
                const middlePoint = items[i];
                const lastPoint = items[((i + 1) % len + len) % len];

                const v1: IVector = { x: firstPoint.x - middlePoint.x, y: firstPoint.y - middlePoint.y };
                const v2: IVector = { x: lastPoint.x - middlePoint.x, y: lastPoint.y - middlePoint.y };

                middlePoint.angle = this.vectorAngle(v1, v2);
                middlePoint.formattedAngle = this.props.unit!.formatUnitValueArgs(middlePoint.angle, defaultUnit, 0);
            }
        }
        else {
            for (const point of items || []) {
                point.angle = undefined;
            }
        }
    }

    private vectorDot(vectorOne: IVector, vectorTwo: IVector) {
        return vectorOne.x * vectorTwo.x + vectorOne.y * vectorTwo.y;
    }

    private vectorDeterminant(vectorOne: IVector, vectorTwo: IVector) {
        return vectorOne.x * vectorTwo.y - vectorOne.y * vectorTwo.x;
    }

    private vectorAngle(vectorOne: IVector, vectorTwo: IVector) {
        const internalUnit = this.props.unit!.getInternalUnit(UnitGroup.Angle);
        const defaultUnit = this.props.unit!.getDefaultUnit(UnitGroup.Angle);
        const angle = (Math.PI - Math.atan2(this.vectorDeterminant(vectorOne, vectorTwo), this.vectorDot(vectorOne, vectorTwo)) + Math.PI) % (2 * Math.PI);

        return this.props.unit!.convertUnitValueArgsToUnit(angle, internalUnit, defaultUnit);
    }
}

interface IPointsTableRowProps {
    controlName: string;
    item: ITableItem;
    isEditable: boolean;
    isDeleteable: boolean;
    tooltip: string;
    tooltipDisabled: string;
    unit: UnitService;
    localization: LocalizationService;
    valueChanged: (item: IModifiedItem) => void;
    commitValue: (item: IModifiedItem) => void;
    onDelete: (index: number) => void;
}

class PointsTableRow extends PureComponent<IPointsTableRowProps> {
    constructor(props: IPointsTableRowProps) {
        super(props);

        this.onDelete = this.onDelete.bind(this);
        this.onXValueChanged = this.onXValueChanged.bind(this);
        this.onYValueChanged = this.onYValueChanged.bind(this);
        this.onShapeValueChanged = this.onShapeValueChanged.bind(this);
        this.onBondValueChanged = this.onBondValueChanged.bind(this);
        this.onCommitX = this.onCommitX.bind(this);
        this.onCommitY = this.onCommitY.bind(this);
        this.onCommitBond = this.onCommitBond.bind(this);
        this.onCommitShape = this.onCommitShape.bind(this);
    }

    public override render() {
        const classStr = 'table-row' + (!this.props.isEditable ? ' disabled' : '');
        const tooltip = this.props.isEditable ? buildHtmlTooltip(this.props.tooltip) : buildHtmlTooltip(this.props.tooltipDisabled);

        return (
            <div className={classStr}
                data-tip={tooltip}
                data-html={tooltip != null ? true : null}
                id={`${this.props.controlName}-row-${this.rowId}`}>
                <div className={'id'} id={`${this.props.controlName}-row-${this.rowId}-id`}>{this.rowId}</div>

                <PointsTableCellText
                    friendlyName={'x'}
                    parentControlName={this.props.controlName}
                    rowId={this.rowId}
                    value={this.props.item.formattedX!}
                    valueTitle={this.props.item.exactValueX!}
                    isEditable={this.props.isEditable}
                    valueChanged={this.onXValueChanged}
                    commitValue={this.onCommitX} />

                <PointsTableCellText
                    friendlyName={'y'}
                    parentControlName={this.props.controlName}
                    rowId={this.rowId}
                    value={this.props.item.formattedY!}
                    valueTitle={this.props.item.exactValueY!}
                    isEditable={this.props.isEditable}
                    valueChanged={this.onYValueChanged}
                    commitValue={this.onCommitY} />

                {this.props.item.angle != null && <div className={'angle'}>{this.props.item.formattedAngle}</div>}

                <div className={'delete'} id={`${this.props.controlName}-row-${this.rowId}-delete`}>
                    <button type='button' tabIndex={-1} onClick={this.onDelete} disabled={!this.props.isDeleteable || !this.props.isEditable}>
                        <span className={'sprite sprite-trash'}></span>
                    </button>
                </div>
            </div>
        );
    }

    private get rowId(): number {
        return this.props.item.id + 1;
    }

    private onXValueChanged(value: string) {
        this.props.valueChanged({ id: this.props.item.id, x: value, layerId: this.props.item.layerId });
    }

    private onYValueChanged(value: string) {
        this.props.valueChanged({ id: this.props.item.id, y: value, layerId: this.props.item.layerId });
    }

    private onBondValueChanged(value: number) {
        this.props.valueChanged({ id: this.props.item.id, bond: value, layerId: this.props.item.layerId });
    }

    private onShapeValueChanged(value: number) {
        this.props.valueChanged({ id: this.props.item.id, shape: value, layerId: this.props.item.layerId });
    }

    private onCommitX(value: string) {
        this.props.commitValue({ id: this.props.item.id, x: value, layerId: this.props.item.layerId });
    }

    private onCommitY(value: string) {
        this.props.commitValue({ id: this.props.item.id, y: value, layerId: this.props.item.layerId });
    }

    private onCommitShape(value: number) {
        this.props.commitValue({ id: this.props.item.id, shape: value, layerId: this.props.item.layerId });
    }

    private onCommitBond(value: number) {
        this.props.commitValue({ id: this.props.item.id, bond: value, layerId: this.props.item.layerId });
    }

    private onDelete() {
        if (this.props.onDelete != null) {
            this.props.onDelete(this.props.item.id);
        }
    }
}

interface IPointsTableCellTextProps {
    parentControlName: string;
    rowId: number;
    friendlyName: string;
    value: string;
    valueTitle: string;
    isEditable: boolean;
    valueChanged: (value: string) => void;
    commitValue: (value: string) => void;
}

interface IPointsTableCellTextState {
    textValue: string;
}

class PointsTableCellText extends PureComponent<IPointsTableCellTextProps, IPointsTableCellTextState> {
    constructor(props: IPointsTableCellTextProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onEnter = this.onEnter.bind(this);
        this.state = { textValue: this.props.value };
    }

    public override componentDidUpdate(prevProps: IPointsTableCellTextProps) {
        if (!isEqual(this.props, prevProps)) {
            this.setState({ textValue: this.props.value });
        }
    }

    public override render() {
        return (
            <input
                id={`${this.props.parentControlName}-row-${this.props.rowId}-${this.props.friendlyName}`}
                className={'number input'}
                value={this.state.textValue}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onKeyPress={this.onEnter}
                disabled={!this.props.isEditable}
                type='text' autoComplete={'off'}
                autoCorrect={'off'}
                autoCapitalize={'off'}
                spellCheck={false}
                title={this.props.valueTitle} />
        );
    }

    private onChange(event: React.FormEvent) {
        const value = (event.target as HTMLInputElement).value;
        this.setState({ textValue: value });
        this.props.valueChanged((event.target as HTMLInputElement).value);
    }

    private onBlur(event: React.FocusEvent) {
        this.props.commitValue(this.state.textValue);
    }

    private onEnter(event: React.KeyboardEvent) {
        if (event.key == 'Enter') {
            this.props.commitValue(this.state.textValue);
        }
    }
}

interface IPointsTableInputProps {
    parentControlName: string;
    lastId: number;
    showAngle: boolean;
    isEditable: boolean;
    item: IPointTableAddInput;
    unit: UnitService;
    localization: LocalizationService;
    valueChanged: (item: IPointTableAddInput) => void;
    commitValue: (item: IPointTableAddInput) => void;
    tableType?: PointsTableType;
}

class PointsTableInput extends PureComponent<IPointsTableInputProps> {
    private xInput: string | undefined;
    private yInput: string | undefined;

    private xIn: HTMLInputElement | undefined;
    private yIn: HTMLInputElement | undefined;

    constructor(props: IPointsTableInputProps) {
        super(props);
    }

    public override render() {
        if (!this.props.isEditable) {
            return null;
        }

        this.xInput = this.props.item != null && this.props.item.x != null ? this.props.item.x : '';
        this.yInput = this.props.item != null && this.props.item.y != null ? this.props.item.y : '';

        return (
            <div className={'table-row input-row'} id={`${this.props.parentControlName}-row-add`}>
                <div className={'id'} id={`${this.props.parentControlName}-row-add-id`}>{this.props.lastId}</div>

                <input
                    id={`${this.props.parentControlName}-row-add-x`}
                    data-id={`${this.props.tableType}-x`}
                    ref={(input) => { this.xIn = input!; }}
                    className={'number input'}
                    type='text'
                    value={this.xInput}
                    onChange={this.onChange.bind(this, 'x')}
                    onBlur={this.onBlur.bind(this, 'x')}
                    onKeyPress={this.onEnter.bind(this, 'x')}
                    autoComplete={'off'}
                    autoCorrect={'off'}
                    autoCapitalize={'off'}
                    spellCheck={false} />

                <input
                    id={`${this.props.parentControlName}-row-add-y`}
                    data-id={`${this.props.tableType}-y`}
                    ref={(input) => { this.yIn = input!; }}
                    className={'number input'}
                    type='text'
                    value={this.yInput}
                    onChange={this.onChange.bind(this, 'y')}
                    onBlur={this.onBlur.bind(this, 'y')}
                    onKeyPress={this.onEnter.bind(this, 'y')}
                    autoComplete={'off'}
                    autoCorrect={'off'}
                    autoCapitalize={'off'}
                    spellCheck={false} />

                {this.props.showAngle && <div className={'angle'}></div>}
                <div className={'delete'}><button type='button' data-id='focus'></button></div>
            </div>
        );
    }

    private get object() {
        return {
            x: this.xInput,
            y: this.yInput
        };
    }

    private onChange(key: 'x' | 'y', event: React.FormEvent) {
        const obj = this.object;
        obj[key] = (event.target as HTMLInputElement).value;
        this.props.valueChanged(obj);
    }

    private onBlur(key: string, event: React.FocusEvent) {
        this.props.commitValue(this.object);

        let id: string = '';
        const elt = event.relatedTarget as HTMLElement;
        if (elt != null) {
            id = elt.dataset['id']!;
        }

        if (id == 'focus' || id == `${this.props.tableType}-y`) {
            this.focus();
        }
    }

    private onEnter(key: string, event: React.KeyboardEvent) {
        if (event.key == 'Enter') {
            this.props.commitValue(this.object);
            this.focus();
        }
    }

    private focus() {
        if (this.xInput == '' && this.yInput != '') {
            this.xIn?.focus();
        }
        else if (this.xInput != '' && this.yInput == '') {
            this.yIn?.focus();
        }
        else if (this.xInput != '' && this.yInput != '') {
            this.xIn?.focus();
        }
    }
}

export const PointsTable: typeof _PointsTable & ContolsStyleSheets = _PointsTable;