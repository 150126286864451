import { DesignMethodConnectionTypeMethodGroupEntityC2C, DesignMethodEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList, ICodeListC2CConstructor } from './code-list';

export interface IDesignMethodC2CConstructor extends ICodeListC2CConstructor {
    id: number;
    displayKey: string;
    isResearchBased: boolean;
    description: string;
    designMethodConnectionTypeMethodGroups: DesignMethodConnectionTypeMethodGroupEntityC2C[];
}

export class DesignMethod extends CodeList {
    public isResearchBased?: boolean;
    public description?: string;
    public designMethodConnectionTypeMethodGroups?: DesignMethodConnectionTypeMethodGroupEntityC2C[];

    constructor(codeList: IDesignMethodC2CConstructor) {
        super(codeList);
        if (codeList != null) {
            this.id = codeList.id;
            this.displayKey = codeList.displayKey;
            this.isResearchBased = codeList.isResearchBased;
            this.description = codeList.description;
            this.designMethodConnectionTypeMethodGroups = codeList.designMethodConnectionTypeMethodGroups;
        }
    }

    public static fromService(codeList: DesignMethodEntityC2C) {
        return new DesignMethod(CodeList.baseFromService<IDesignMethodC2CConstructor>('DesignMethodEntity', codeList, {
            id: codeList.id,
            displayKey: codeList.displayKey,
            isResearchBased: codeList.isResearchBased,
            description : codeList.description,
            designMethodConnectionTypeMethodGroups: codeList.designMethodConnectionTypeMethodGroups
        }));
    }

    public override cloneDeep() {
        const codeList: DesignMethod = <DesignMethod>super.cloneDeep();
        codeList.id = this.id;
        codeList.displayKey = this.displayKey;
        codeList.isResearchBased = this.isResearchBased;
        codeList.description = this.description;
        codeList.designMethodConnectionTypeMethodGroups = this.designMethodConnectionTypeMethodGroups;

        return codeList;
    }
}
