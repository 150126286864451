import { ReinforcementTypeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class ReinforcementType extends CodeList {

    public static fromService(codeList: ReinforcementTypeEntityC2C) {
        return new ReinforcementType(CodeList.baseFromService('ReinforcementTypeEntity', codeList));
    }

    public override cloneDeep(): ReinforcementType {
        return super.cloneDeep() as ReinforcementType;
    }
}

