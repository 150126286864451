import { Vector2 } from '@babylonjs/core/Maths/math.vector.js';
import { BaseInput } from './base-input.js';
export class RotateInput extends BaseInput {
  angularSensibilityX;
  angularSensibilityY;
  betaMin;
  betaMax;
  noPreventDefault = false;
  viewType;
  moving = false;
  point;
  canvas;
  inputSettings;
  constructor(eventNotifier, inputSettings, canvas, angularSensibilityX, angularSensibilityY, viewType, betaMin, betaMax) {
    super(eventNotifier);
    this.canvas = canvas;
    this.viewType = viewType;
    this.inputSettings = inputSettings;
    this.angularSensibilityX = angularSensibilityX;
    this.angularSensibilityY = angularSensibilityY;
    this.betaMin = betaMin;
    this.betaMax = betaMax;
    this.point = Vector2.Zero();
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
  }
  getClassName = () => 'ArcRotateCameraRotateInput';
  getSimpleName = () => 'rotate';
  attachControl(noPreventDefault) {
    this.canvas.addEventListener('mouseup', this.mouseUp, false);
    this.canvas.addEventListener('mousemove', this.mouseMove, false);
    this.canvas.addEventListener('mousedown', this.mouseDown, false);
    this.noPreventDefault = noPreventDefault ?? false;
  }
  detachControl() {
    this.canvas.removeEventListener('mouseup', this.mouseUp, false);
    this.canvas.removeEventListener('mousemove', this.mouseMove, false);
    this.canvas.removeEventListener('mousedown', this.mouseDown, false);
  }
  mouseDown(event) {
    if (!this.enabled) {
      return;
    }
    const target = event.target;
    if (target === this.canvas && (event.button == this.inputSettings.rotateMouseButton && this.viewType == 1 /* ViewType.View3d */ || event.button == 0 /* MouseClickType.Left */ && this.viewType == 0 /* ViewType.View2d */)) {
      this.moving = true;
      this.point.x = event.clientX;
      this.point.y = event.clientY;
      if (!this.noPreventDefault) {
        event.preventDefault();
      }
    } else {
      this.moving = false;
    }
  }
  mouseUp(event) {
    if (!this.enabled) {
      return;
    }
    if (this.moving) {
      this.moving = false;
      if (!this.noPreventDefault) {
        event.preventDefault();
      }
    }
  }
  mouseMove(event) {
    if (!this.enabled) {
      return;
    }
    if (this.moving) {
      this.camera.alpha -= (event.clientX - this.point.x) / this.angularSensibilityX;
      this.camera.beta -= (event.clientY - this.point.y) / this.angularSensibilityY;
      // mod
      this.camera.alpha %= Math.PI * 2;
      this.camera.beta %= Math.PI * 2;
      // limit beta
      if (this.betaMin != null && this.camera.beta < this.betaMin) {
        this.camera.beta = this.betaMin;
      }
      if (this.betaMax != null && this.camera.beta > this.betaMax) {
        this.camera.beta = this.betaMax;
      }
      this.renderNextFrame?.();
      if (!this.noPreventDefault) {
        event.preventDefault();
      }
    }
    this.point.x = event.clientX;
    this.point.y = event.clientY;
  }
}
