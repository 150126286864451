import { Injectable } from '@angular/core';
import { IBaseDesign, Design } from '@profis-engineering/pe-ui-common/entities/design';
import { DeckingDesign } from '../../entities/decking-design/decking-design';
import { DeckingImportBaseService } from './decking-import-base.service';
import { environment } from 'src/environments/environmentDecking';
import { HttpRequest } from '@angular/common/http';
import { DeckingUrlPath } from 'src/decking/entities/module-constants';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { DeckingDesignService } from '../decking-design/decking-design.service';
import { ApiService } from '../external/api.service';
import { RoutingService } from '../external/routing.service';
import { ChangesService } from '../external/changes.service';
import { UserService } from '../external/user.service';
import { LocalizationService } from '../external/localization.service';
import { LoggerService } from '../external/logger.service';
import { DeckingDocumentService } from '../decking-document/decking-document.service';
import { ImportService } from '../external/import.service';
import { DeckingSubstitutionService } from '../decking-design/decking-substitution.service';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';
import { DeckingMainService } from '../decking-main/decking-main.service';

@Injectable({
  providedIn: 'root'
})
export class DeckingDesignImportService extends DeckingImportBaseService {

  constructor(
    apiService: ApiService,
    routingService: RoutingService,
    documentService: DeckingDocumentService,
    changesService: ChangesService,
    user: UserService,
    localization: LocalizationService,
    logger: LoggerService,
    importService: ImportService,
    private deckingDesignService: DeckingDesignService,
    private deckingSubstitutionService: DeckingSubstitutionService,
    private deckingMainService: DeckingMainService
  ) {
    super(apiService, routingService, documentService, changesService, user, localization, logger, importService);
  }

  public async importDFDesign(projectDesign: File, projectId: string) {
    const fileContent = await this.readFileAsArrayBuffer(projectDesign);
    const base64String = this.arrayBufferToBase64(fileContent);
    const rData = { base64: base64String };
    const url = `${environment.deckingCalculationServiceUrl}api/TransformDesign`;

    this.apiService.request<{ value: DeckingDesign }>(new HttpRequest('POST', url, rData))
      .then(async (response: any) => {
        const newDeckingDesign = response.body;
        if(newDeckingDesign.projectType == DeckingDesignModeType.SubstitutionBuilderMode){
          this.deckingMainService.setSelectedModeType(DeckingDesignModeType.SubstitutionBuilderMode);
          await this.deckingSubstitutionService.createNewSubstitutionFromExternalFile(projectId, newDeckingDesign);
          this.deckingSubstitutionService.launchedFromDashboard = true;
          this.routingService.navigateToUrl(DeckingUrlPath.mainDecking + newDeckingDesign.id);
        } else{
          this.deckingMainService.setSelectedModeType(DeckingDesignModeType.DesignMode);
          await this.deckingDesignService.createNewDesignFromExternalFile(projectId, newDeckingDesign, false);
          this.deckingDesignService.launchedFromDashboard = true;
          this.routingService.navigateToUrl(DeckingUrlPath.mainDecking + newDeckingDesign.id);
        }
      })
      .catch((response: any) => {
        this.logger.logServiceError(response, 'DeckingImportService', 'importDFDesign');
      });
  }

  public async createAndOpenFromProjectDesign(design: DeckingDesign, projectId: string) {
    await this.deckingDesignService.createNewDesignFromExternalFile(projectId, design, true, this.importService.importedFromDesign);
    this.openImportedProject(design);
  }

  public updateDesignFromExternalFile(oldDesign: IBaseDesign, projectDesign: DeckingDesign) {
    return this.documentService.openDesignExclusiveDecking(oldDesign)
      .then(async () => {
        let designInfo = null;
        if((projectDesign as any).designId){
          designInfo = (await this.deckingDesignService.getDeckingDesignById((projectDesign as any).designId));
        }
        const newDesign = designInfo && designInfo.isTemplate ? designInfo : projectDesign;
        const design = await this.deckingDesignService.replaceExistingDesign(oldDesign as IDesignListItem, newDesign, this.importService.importedFromDesign);
        this.openImportedProject(newDesign);
        return new Promise<Design>(() => {
          return design;
        });
      });
  }

  private openImportedProject(projectDesign: DeckingDesign) {
    if (!this.importService.importedFromDesign) {
      this.deckingDesignService.launchedFromDashboard = true;
      this.openImportSameWindow(projectDesign.id);
    } else {
      this.openImportNewWindow(projectDesign.id);
    }
  }
}
