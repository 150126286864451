<div class="design-section box-section box-section-white" *ngIf="componentVisible">
    <div class="box-section-content">
        <div class="design-section-row">
            <button id="export-button" type="button" class="generate-report-button button button-primary"
                [disabled]="designSectionService.generateReportButtonDisabled"
                (click)="openGenerateReportPopup()">
                <div class="generate-report-button-virtual-tour">
                    <div class="virtual-tour-arrow-right pe-ui-pe-sprite pe-ui-pe-sprite-virtual-tour-arrow-right"></div>
                    <div class="text" l10n="Agito.Hilti.Profis3.VirtualTour.Concrete.Step10.ClickHere"></div>
                </div>
                <pe-loading-button
                    [loading]="designSectionService.loading"
                    [color]="'#ffffff'">
                    <div class="generate-report-button-container">
                        <span class="text" l10n="Agito.Hilti.Profis3.Main.GenerateReport"></span>
                        <span class="pe-ui-pe-sprite pe-ui-pe-sprite-long-arrow-right-white"></span>
                    </div>
                </pe-loading-button>
            </button>
        </div>

        <div class="design-section-row">
            <!-- Save - Desktop only -->
            <button id="save-button" type="button" class="save-button button button-design-section"
                *ngIf="designSectionService.saveButtonVisible"
                [disabled]="designSectionService.saveButtonDisabled"
                (click)="designSectionService.saveDesignOffline()"
                [class.button-design-section-desktop]="offlineService.isOffline"
                [class.unsaved-changes]="designSectionService.isDesignStateChanged"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Save' | l10n: localizationService.selectedLanguage" placement="top">
                <div class="button-sprite-wrapper">
                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-save-design"></span>
                </div>
            </button>
            <!-- Save - Desktop only -->

            <!-- Export - Common -->
            <button id="export-button" type="button" class="export-button button button-design-section"
                [disabled]="designSectionService.exportButtonDisabled"
                (click)="designSectionService.openDesignSectionExport()"
                [class.button-design-section-desktop]="offlineService.isOffline"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.ExportReport' | l10n: localizationService.selectedLanguage" placement="top">
                <div class="button-sprite-wrapper">
                    <span *ngIf="!offlineService.isOffline" class="pe-ui-pe-sprite pe-ui-pe-sprite-export-design"></span>
                    <span *ngIf="offlineService.isOffline" class="pe-ui-pe-sprite pe-ui-pe-sprite-export-design-desktop"></span>
                </div>
            </button>
            <!-- Export - Common -->

            <!-- Specification Text - Common -->
            <button id="specification-text-button" type="button" class="specification-text-button button button-design-section"
                (click)="designSectionService.openSpecificationText(true)"
                [class.button-design-section-desktop]="offlineService.isOffline"
                [ngbTooltip]="'Agito.Hilti.Profis3.DesignSectionExport.SpecificationText' | l10n: localizationService.selectedLanguage" placement="top">
                <div class="button-sprite-wrapper">
                    <span *ngIf="!offlineService.isOffline" class="pe-ui-pe-sprite pe-ui-pe-sprite-specification-text-small"></span>
                    <span *ngIf="offlineService.isOffline" class="pe-ui-pe-sprite pe-ui-pe-sprite-specification-text-small-desktop"></span>
                </div>
            </button>
            <!-- Specification Text - Common -->

            <!-- Open design - Common -->
            <button id="open-button" type="button" class="open-button button button-design-section"
                [disabled]="designSectionService.openDesignButtonDisabled"
                (click)="selectOpenFile()"
                [class.button-design-section-desktop]="offlineService.isOffline"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.OpenDesign' | l10n: localizationService.selectedLanguage" placement="top">
                <div class="button-sprite-wrapper">
                    <pe-loading-button
                        [loading]="designSectionService.openingDesign">
                        <span *ngIf="!offlineService.isOffline" class="pe-ui-pe-sprite pe-ui-pe-sprite-openfile-d-light"></span>
                        <span *ngIf="offlineService.isOffline" class="pe-ui-pe-sprite pe-ui-pe-sprite-openfile-desktop"></span>
                    </pe-loading-button>
                </div>
            </button>
            <!-- Open design - Common -->

            <!-- Import design - Common -->
            <input class="open-design-input" #openDesignInput (change)="importFileSelected()" name="project" type="file"
                accept=".pe,.pa2" (click)="$event.stopPropagation();" />
            <!-- Import design - Common -->
        </div>
    </div>
</div>
