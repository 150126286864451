<div class="modal-header">
    <span class="logo sprite sprite-alert-important"></span>
    <h3 class="modal-title" [innerHtml]="title"></h3>

    <button type="button" id="alert-close-button" class="close" (click)="close()" *ngIf="!preventClose">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body">
    <div [innerHtml]="message"></div>

    <div class="learn-more-container" *ngIf="showLearnMore">
        <span l10n="Agito.Hilti.Profis3.ServerErrorAlert.LearnMore"></span>
        <button class="collapsible" (click)="toggleCollapse()">
            <span class="sprite" [class]="!isCollapsed ? 'sprite-lines' : 'sprite-lines-expanded'"></span>
        </button>
    </div>
    <div class="content" *ngIf="!isCollapsed && showLearnMore">
        <span [innerHtml]="detailedError"></span>
    </div>
</div>

<div class="modal-footer" *ngIf="showSupportButton || showLogoutButton">
    <button type="button" id="alert-support-button" *ngIf="showSupportButton"
        class="modal-button button button-default" l10n="Agito.Hilti.Profis3.Support.Button.Text"
        (click)="openSupport()"></button>
    <button type="button" id="alert-logout-button" *ngIf="showLogoutButton"
        class="modal-button button button-default" l10n="Agito.Hilti.Profis3.Logout.Button.Text"
        (click)="logout()"></button>
</div>
