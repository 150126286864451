import cloneDeep from 'lodash-es/cloneDeep';

import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import { UserServiceBase } from '@profis-engineering/pe-ui-common/services/user.common';

import {
    IAsadPreferencesFilteringParameters, IBaseplateFilterOutput
} from '../../../shared/entities/baseplate-inputs';
import { AnchorFamily } from '../../../shared/entities/code-lists/anchor-family';
import { AnchorSize } from '../../../shared/entities/code-lists/anchor-size';
import { AnchorType } from '../../../shared/entities/code-lists/anchor-type';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import {
    AsadIncludeFasteners, AsadOptimizeCase, AsadOptimizeCaseDetails, AsadOptimizeCaseOutput, AsadOutput, AsadPoint, AsadSearchAlgorithm, CorrosionMaterial, DesignPe, IDesignState
} from '../../../shared/entities/design-pe';
import { OptimizeDesignProgress } from '../../../shared/entities/signalr';
import {
    ScopeCheckResultItem
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    ScopeCheckFlags
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    UIProperty
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    ProjectDesignBaseEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    AnchorFilterType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { AsadMapper, ModelChangesData } from '../../../shared/helpers/asad-mapper';
import { DesignMethodGroupHelper } from '../../../shared/helpers/design-method-group-helper';
import { CalculationServiceBasePE } from '../../../shared/services/calculation.common';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { smartAnchorDefaultScopeChecks } from '../../helpers/smart-anchor-helper';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { SignalRService } from '../../services/signalr.service';
import { SmartAnchorOrderService } from '../../services/smart-anchor-order.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites, Sprite } from '../../sprites';
import { IAnchor } from '../info-section/info-section.component';
import { FeaturesVisibilityService } from '../../services/features-visibility.service';
import { IMainMenuComponent } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { SmartDesignSearchSolutionService } from '../../services/smart-design-search-solutions.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { TourService } from '../../services/tour.service';

export enum AnchorAIResultSort {
    SortBy = 0,
    Utilization = 1,
    BaseplateSizeOption = 2,
    NumberOfAnchorsOption = 3,
    EmbedmentDepthOption = 4,
    TcoCostOption = 5
}

@Component({
    templateUrl: './smart-baseplate-design.component.html',
    styleUrls: ['./smart-baseplate-design.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SmartBaseplateDesignComponent implements OnInit, OnDestroy {
    @Input()
    public collapsed!: boolean;

    @Input()
    public parentElement?: HTMLElement;

    @Input()
    public mainMenuElement?: ElementRef<IMainMenuComponent>;

    private smartDesignSearchSolutionServiceSubscription?: Subscription;
    public resultsFound = false;
    public optimized = false;
    public visibleOptimized: AsadOptimizeCase[] = [];
    public anchorAIResultSortDropdown!: DropdownProps<AnchorAIResultSort>;
    public cancelOptimization!: () => void;
    public collapsingControlsEnum = CollapsingControls;
    public appliedResult!: AsadOptimizeCase;
    filterParameter!: IAsadPreferencesFilteringParameters;
    private defaultUnit!: Unit;
    private internalUnit!: Unit;
    private precision!: number;
    private mapper!: AsadMapper;
    private initializedSpriteImages: string[] = [];
    private infoSectionHeight = 0;
    public anchors: IAnchor[] = [];
    showFilter = false;
    baseplateFilterOutput!: IBaseplateFilterOutput;
    expandedItemIndex = '';
    currentUnitValue = '';
    optimizeFirstStart!: number;
    onlyShowResultsWithTco = false;

    anchorFilterTypeList = [
        AnchorFilterType.SteelGalvanized,
        AnchorFilterType.SheradizedHotDipGalvanized,
        AnchorFilterType.SheradizedHotDipMechanicallyGalvanized,
        AnchorFilterType.StainlessSteel,
        AnchorFilterType.HighCorrosionResistantSteel
    ];
    showExpandedPanel = false;
    scaledHeight = '2556px';
    minHeight = '422px';
    filterPanelHeight = 'calc(100% - 71px)';
    startedSearch = false;
    cancelClicked = false;
    anchorAiInputs: UIProperty[] = [
        UIProperty.SmartAnchor_Category,
        UIProperty.SmartAnchor_Application,
        UIProperty.SmartAnchor_AnchorGroupsForAsad,
        UIProperty.SmartAnchor_UserSelectedAnchorGroupsForAsad,
        UIProperty.AnchorUserChosen,
        UIProperty.SmartAnchor_UserSelectedAnchorSizes,
        UIProperty.Profile_ProfileSizeId_Asad,
        UIProperty.BaseMaterial_ConcreteCharacteristic_Asad,
        UIProperty.BaseMaterial_ConcreteGrade_Asad,
        UIProperty.BaseMaterial_Thickness_Asad,
        UIProperty.BaseMaterial_EdgeXPositive_Asad,
        UIProperty.BaseMaterial_IsEdgeXNegativeReinforced_Asad,
        UIProperty.BaseMaterial_IsEdgeXPositiveReinforced_Asad,
        UIProperty.BaseMaterial_EdgeXNegative_Asad,
        UIProperty.BaseMaterial_IsEdgeYPositiveReinforced_Asad,
        UIProperty.BaseMaterial_EdgeYPositive_Asad,
        UIProperty.BaseMaterial_IsEdgeYNegativeReinforced_Asad,
        UIProperty.BaseMaterial_EdgeYNegative_Asad,
        UIProperty.SmartAnchor_OptimizationOptions_UtilizationSlider,
        UIProperty.SmartAnchor_OptimizationOptions_ProfilePosition,
        UIProperty.SmartAnchor_OptimizationOptions_AnchorPosition,
        UIProperty.OptimizeOnEmbedmentDepth,
        UIProperty.AnchorPlate_StandoffDistance_Asad,
        UIProperty.AnchorPlate_StandoffRestraintLevel_Asad,
        UIProperty.AnchorPlate_CustomGroutCompressiveStrength_Asad,
        UIProperty.AnchorPlate_StandoffType_Asad,
        UIProperty.ConstructionOptions_GalvanizedSteel,
        UIProperty.ConstructionOptions_Sheradized,
        UIProperty.ConstructionOptions_MechanicallyGalvanized,
        UIProperty.ConstructionOptions_StainlessSteel,
        UIProperty.ConstructionOptions_CorrosionResistant,
        UIProperty.BaseMaterial_TemperatureShortTerm_Asad,
        UIProperty.BaseMaterial_TemperatureLongTerm_Asad,
        UIProperty.BaseplateThickness,
        UIProperty.SupplementaryReinforcement_ConcreteReinforcement_Asad,
        UIProperty.SupplementaryReinforcement_EdgeReinforcement_Asad,
        UIProperty.SupplementaryReinforcement_IsSplittingReinforcement_Asad,
        UIProperty.BaseMaterial_DrillingMethod_Asad,
        UIProperty.BaseMaterial_HoleType_Asad,
        UIProperty.BaseMaterial_CubeStrength_Asad,
        UIProperty.BaseMaterial_CylinderStrength_Asad,
        UIProperty.Profile_CustomHeight_Asad,
        UIProperty.Profile_CustomWidth_Asad,
        UIProperty.Profile_CustomThickness_Asad,
        UIProperty.Profile_CustomFlangeThickness_Asad,
        UIProperty.Loads_LoadCombinations,
        UIProperty.Loads_LoadCombinationsCalculationType,
        UIProperty.Loads_DesignMethodHNA_Asad,
        UIProperty.BaseMaterial_IsLightweightConcrete_Asad,
        UIProperty.Loads_LoadCombinationHNAWizard,
        UIProperty.BaseMaterial_LightweightConcreteLambdaA_Asad,
        UIProperty.BaseMaterial_ReinforcementConditionShear,
        UIProperty.BaseMaterial_ReinforcementConditionTension,
        UIProperty.BaseMaterial_EdgeReinforcementHNA,
        UIProperty.BaseMaterial_EdgeYNegativeFromProfileCenter,
        UIProperty.BaseMaterial_EdgeXNegativeFromProfileCenter,
        UIProperty.BaseMaterial_EdgeYPositiveFromProfileCenter,
        UIProperty.BaseMaterial_EdgeXPositiveFromProfileCenter,
        UIProperty.BaseMaterial_Thickness,
        UIProperty.Loads_ForceX,
        UIProperty.Loads_ForceY,
        UIProperty.Loads_ForceZ,
        UIProperty.Loads_MomentX,
        UIProperty.Loads_MomentY,
        UIProperty.Loads_MomentZ,
        UIProperty.AnchorPlate_Thickness,
        UIProperty.BaseMaterial_EdgeYNegativeFromAnchor,
        UIProperty.BaseMaterial_EdgeXNegativeFromAnchor,
        UIProperty.BaseMaterial_EdgeYPositiveFromAnchor,
        UIProperty.BaseMaterial_EdgeXPositiveFromAnchor,
        UIProperty.BaseMaterial_EdgeYNegativeFromConcreteEdge,
        UIProperty.BaseMaterial_EdgeXNegativeFromConcreteEdge,
        UIProperty.BaseMaterial_EdgeYPositiveFromConcreteEdge,
        UIProperty.BaseMaterial_EdgeXPositiveFromConcreteEdge,
        UIProperty.AnchorPlate_StandoffDistance,
        UIProperty.BaseMaterial_IsEdgeYNegativeFromAnchorReinforced,
        UIProperty.BaseMaterial_IsEdgeYPositiveFromAnchorReinforced,
        UIProperty.BaseMaterial_IsEdgeXNegativeFromAnchorReinforced,
        UIProperty.BaseMaterial_IsEdgeXPositiveFromAnchorReinforced,
        UIProperty.Profile_ProfileFamilyId_Asad,
        UIProperty.Option_DesignMethodGroupId_Asad
    ];
    optimizeFirstEnd!: number;

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private modal: ModalService,
        private signalRService: SignalRService,
        private unit: UnitService,
        private calculationService: CalculationServicePE,
        private elementRef: ElementRef<HTMLElement>,
        private userSettingsService: UserSettingsService,
        private smartAnchorOrderService: SmartAnchorOrderService,
        private numberService: NumberService,
        private featuresVisibilityService: FeaturesVisibilityService,
        private smartDesignSearchSolutionService: SmartDesignSearchSolutionService,
        private tourService: TourService
    ) {
        this.mapper = new AsadMapper();

        this.onProgress = this.onProgress.bind(this);
    }

    public get design() {
        return this.userService.design;
    }

    public get optimizing() {
        return this.smartDesignSearchSolutionService?.Optimizing;
    }

    public set optimizing(value: boolean) {
        this.smartDesignSearchSolutionService.Optimizing= value;
    }

    public get isOptimizingDisabled() {
        return this.smartDesignSearchSolutionService?.IsOptimizingDisabled;
    }

    public get anchorPlateThickness(): number {
        return this.design?.model[UIProperty.AnchorPlate_Thickness] as number;
    }

    public get input() {
        return this.userService.design.designData.asadData.input;
    }

    public get output() {
        return this.userService.design?.designData.asadData.output;
    }

    public get isAsadCalculable() {
        return this.design?.model[UIProperty.SmartAnchor_Application] != null && this.isValidLoad && this.anySmartAnchorSelected;
    }

    public get isLoading() {
        return this.design?.loading;
    }

    private get isValidLoad(): boolean {
        return !this.alertScopeChecks.some(sc => smartAnchorDefaultScopeChecks().includes(sc.ScopeCheckId));
    }

    private get anySmartAnchorSelected() {
        if (this.design.smartAnchorUserChosenVisible) {
            return this.design.smartAnchorHiltiRecommendedAnchors?.length > 0;
        } else {
            return !!this.design.smartAnchorUserSelected;
        }
    }

    public get isAsadPlateHidden() {
        return this.design?.model[UIProperty.SmartAnchor_PlateHidden] as boolean;
    }

    public get alertScopeChecks(): ScopeCheckResultItem[] {
        return this.design?.designData.reportData?.ScopeCheckResultItems?.filter(sc => this.isScopecheckAlert(sc)) ?? [];
    }

    private isScopecheckAlert(scopeCheck: ScopeCheckResultItem) {
        return scopeCheck.IndicatesCalculationError || (scopeCheck.SpecialFlags & ScopeCheckFlags.DisplayAsCalculationError);
    }

    public get smartBaselateSectionContainer() {
        return this.elementRef.nativeElement.shadowRoot?.querySelector('#smart-baseplate-section') as HTMLElement;
    }

    public get smartBaselateFilterContainer() {
        return this.elementRef.nativeElement.shadowRoot?.querySelector('.filter-panel') as HTMLElement;
    }

    public get showLastSelectedSolution() {
        return this.design?.model[UIProperty.SmartAnchor_IsLastSelectedSolution] as boolean && this.visibleOptimized.length == 0 &&
            this.design?.model[UIProperty.SmartAnchor_Application] != null && this.alertScopeChecks.length == 0 && this.anySmartAnchorSelected;
    }

    public get lastSelectedSolution() {
        if (!this.design) {
            return {} as AsadOptimizeCase;
        }

        const codeList = DesignMethodGroupHelper.IsLrfdBased(this.design.designMethodGroup?.id)
            ? this.design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamily[]
            : this.design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];

        const familyId = this.design.model[UIProperty.AnchorLayout_AnchorFamily] as number;
        const anchor = (codeList).filter((anchor) => familyId == anchor.id && anchor.detailed != undefined && anchor.detailed != null);


        const caseDetails: AsadOptimizeCaseDetails = {
            optimizeCaseIndex: 1,
            fastenerId: this.design.model[UIProperty.AnchorLayout_Fastener] as number,
            layoutNumber: this.design.anchorLayout,
            numberOfAnchors: this.design.designData.projectDesign.CalculatedData.AnchorLayoutPoints.length as number,
            fastenerFamilyId: familyId,
            isAutoCleaningAllowed: anchor[0].detailed?.isAutomaticCleaningApproved ?? false,
            partialFamilyId: this.design?.anchorType?.id as number,
            variableEmbedmentDepth: this.design.model[UIProperty.AnchorLayout_EmbedmentDepthVariable] != null,
            corrosionMaterial: this.getCorrosionMaterial(),
            //to be set later on if needed
            isAtToolAllowed: false,
            engineeringValue: 1
        };

        const caseOutput: AsadOptimizeCaseOutput = {
            embedmentDepth: this.design.designData.projectDesign.CalculatedData.EmbedmentDepth as number,
            anchorCoordinates: this.getAnchorCoordinates(),
            anchorPlateHeight: this.design.model[UIProperty.AnchorPlate_Height] as number,
            anchorPlateWidth: this.design.model[UIProperty.AnchorPlate_Width] as number,
            profileEccentricityX: this.design.model[UIProperty.Profile_OffsetX] as number,
            profileEccentricityY: this.design.model[UIProperty.Profile_OffsetY] as number,
            maxUtilization: Math.max(this.design.designData.reportData.Combination.Decisive.Value,
                this.design.designData.reportData.Tension.Decisive.Value,
                this.design.designData.reportData.Shear.Decisive.Value) / 100,
            isEdgeXNegativeReinforced: this.design.model[UIProperty.BaseMaterial_IsEdgeXNegativeReinforced] as boolean,
            isEdgeXPositiveReinforced: this.design.model[UIProperty.BaseMaterial_IsEdgeXPositiveReinforced] as boolean,
            isEdgeYNegativeReinforced: this.design.model[UIProperty.BaseMaterial_IsEdgeYNegativeReinforced] as boolean,
            isEdgeYPositiveReinforced: this.design.model[UIProperty.BaseMaterial_IsEdgeYPositiveReinforced] as boolean,
            baseMaterialEdgeXNegative: this.design.model[UIProperty.BaseMaterial_EdgeXNegative] as number,
            baseMaterialEdgeXPositive: this.design.model[UIProperty.BaseMaterial_EdgeXPositive] as number,
            baseMaterialEdgeYNegative: this.design.model[UIProperty.BaseMaterial_EdgeYNegative] as number,
            baseMaterialEdgeYPositive: this.design.model[UIProperty.BaseMaterial_EdgeYPositive] as number,
            //to be set later on if needed
            area: (this.design.model[UIProperty.AnchorPlate_Height] as number) * (this.design.model[UIProperty.AnchorPlate_Width] as number),
            fillHoles: false,
            exitFlag: 1,
            constraintEvaluationsCount: 0,
            utilizations: {},
            calculationTime: 0,
            searchAlgorithm: AsadSearchAlgorithm.DirectSearch,
            TCO: 1,
            BaseplateCosts: 1,
            AnchorsCosts: 1,
            InstallationCosts: 1,
            InstallationTime: 0
        };

        const solution: AsadOptimizeCase = {
            optimizeCaseId: '1',
            calculationCount: 1,
            logs: '',
            optimizeCaseDetails: caseDetails,
            optimizeCaseOutput: caseOutput
        };

        return solution;
    }

    public get showSolutionsList() {
        return ((this.resultsFound && this.smartDesignSearchSolutionService.Optimizing) || this.optimized) && this.isAsadCalculable;
    }

    public get isLambdaEnabled() {
        return this.featuresVisibilityService.isFeatureEnabled('PE_SmartDesign_UseLambda');
    }

    ngOnInit(): void {
        this.design.setAnchorAISolutionSelectedOnce(false);
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-lines-expanded',
            'sprite-lines',
            'sprite-anchor-ai-40-1',
            'sprite-anchor-ai-40-2',
            'sprite-anchor-ai-40-3',
            'sprite-anchor-ai-40-4',
            'sprite-anchor-ai-40-6',
            'sprite-anchor-ai-40-8',
            'sprite-anchor-ai-40-9',
            'sprite-anchor-ai-40-12',
            'sprite-anchor-ai-40-16',
            'sprite-dots',
            'sprite-search',
            'sprite-search-red',
            'sprite-search-white',
            'sprite-info'
        );

        this.defaultUnit = this.unit.getDefaultUnit(UnitGroup.Length);
        this.internalUnit = this.unit.getInternalUnit(UnitGroup.Length);
        this.precision = this.unit.getPrecision(this.defaultUnit);
        this.currentUnitValue = this.unit.formatUnit(this.defaultUnit);
        // set startup filtering parameters
        this.clearFilter();

        this.onResize = this.onResize.bind(this);
        this.anchorAIResultSortDropdown = {
            id: 'sortByToggle',
            notSelectedText: this.localizationService.getString('Agito.Hilti.Profis3.SmartBasePlate.SortBy.Button'),
            items: this.getItemsForDropdown(),
        };

        this.onBeforeCalculate = this.onBeforeCalculate.bind(this);
        this.design.onBeforeCalculate(this.onBeforeCalculate);
        this.onStateChanged = this.onStateChanged.bind(this);
        this.design.onStateChanged(this.onStateChanged);
        this.loadAnchors();

        this.smartDesignSearchSolutionServiceSubscription = this.smartDesignSearchSolutionService.smartDesignOptimize.subscribe({
            next: (value: boolean) => {
                if (!this.optimizing && value) {
                    this.optimize();
                } else if (this.optimizing && !value) {
                    this.cancelOptimization();
                    this.smartDesignSearchSolutionService.optimizeDone();
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.smartDesignSearchSolutionServiceSubscription != null) {
            this.smartDesignSearchSolutionServiceSubscription.unsubscribe();
        }

        if (this.cancelOptimization) {
            this.cancelOptimization();
        }
    }


    private getAnchorCoordinates() {
        const anchorLayoutPoints: AsadPoint[] = [];
        this.design.designData.projectDesign.CalculatedData.AnchorLayoutPoints.forEach((anchorPoint) => {
            anchorLayoutPoints.push({ x: anchorPoint.X, y: anchorPoint.Y });
        });

        return anchorLayoutPoints;
    }

    private getCorrosionMaterial() {
        switch (this.design?.model[UIProperty.AnchorLayout_CorrosionMaterial]) {
            case CorrosionMaterial.Galvanized:
                return (CorrosionMaterial.Galvanized).charCodeAt(0);
            case CorrosionMaterial.MechanicallyGalvanized:
                return (CorrosionMaterial.MechanicallyGalvanized).charCodeAt(0);
            case CorrosionMaterial.Sheradized:
                return (CorrosionMaterial.Sheradized).charCodeAt(0);
            case CorrosionMaterial.Stainless:
                return (CorrosionMaterial.Stainless).charCodeAt(0);
            case CorrosionMaterial.HighCorrosionResistant:
                return (CorrosionMaterial.HighCorrosionResistant).charCodeAt(0);
            default:
                return CorrosionMaterial.Unknown;
        }
    }

    private loadAnchors() {
        const anchors = this.design?.designData?.designCodeLists[this.design?.globalMetaProperties?.productCodeListProperty] as AnchorFamily[];
        this.anchors = anchors.map((anchor): IAnchor => ({
            id: anchor.id,
            name: '',
            image: anchor.image as string,
            allowedInAnchorFilters: [],
            holeDiameters: [],
            fixtureThicknesMin: 0,
            fixtureThicknesMax: 0,
            defaultSortOrder: 0,
            tag: '',
            tested: false,
            isNew: false,
            internalPortfolioOnly: false,
            adaptiveTorquing: anchor.detailed?.isAutomaticCleaningApproved ?? false,
        }));

        this.anchors.forEach(anchor => {
            if (anchor.image && !this.initializedSpriteImages.includes(anchor.image)) {
                includeSprites(this.elementRef.nativeElement.shadowRoot, !anchor.tested, anchor.image as Sprite);
                this.initializedSpriteImages.push(anchor.image);
            }
        });
    }

    public get loadCalculationType() {
        return this.design.model[UIProperty.Loads_LoadCombinationsCalculationType] as number;
    }

    private onBeforeCalculate(design: Design, changes: Change[]) {
        if (this.loadCalculationType && this.loadCalculationType != 2) {
            this.anchorAiInputs = this.anchorAiInputs.filter(x => x != UIProperty.Loads_SelectedLoadCombination);
        }else {
            this.anchorAiInputs.push(UIProperty.Loads_SelectedLoadCombination);
        }
        const isFilterChanged = changes?.some(s => this.anchorAiInputs.includes(+s.name));
        if (isFilterChanged) {
            this.resetResult();
        }
    }

    private get mainMenuComponent() {
        return this.mainMenuElement?.nativeElement;
    }

    private onStateChanged(design: Design, state: IDesignState, oldState: IDesignState) {
        if (state.model[UIProperty.SmartAnchor_Enabled] && !oldState.model[UIProperty.SmartAnchor_Enabled]) {
            this.mainMenuComponent?.selectTab('tab-SmartAnchorTab');

            if (this.userService.isAsadEnabled && !this.userSettingsService.settings.smartdesignvirtualtourseen.value) {
                const smartDesignTour = this.tourService.smartDesignTour();
                smartDesignTour.oncomplete(() => { this.saveSmartDesignTour(); });
                smartDesignTour.onexit(() => { this.saveSmartDesignTour(); });
            }
        }
    }

    private async saveSmartDesignTour() {
        this.userSettingsService.settings.smartdesignvirtualtourseen.value = true;
        await this.userSettingsService.save();
    }

    formatLength(value?: number) {
        return this.unit.formatInternalValueAsDefault((value ?? 0), UnitGroup.Length);
    }

    expandCollapsePanel() {
        this.showExpandedPanel = !this.showExpandedPanel;

        if (!this.showExpandedPanel) {
            this.showFilter = false;
        }

        this.setDefaultHeight();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public getUtilization(x: AsadOptimizeCase) {
        return Math.round((x?.optimizeCaseOutput?.maxUtilization ?? 0) * 100);
    }

    public getImage(AnchorID?: number) {
        const anchor = (this.anchors).find((a) => AnchorID == a.id);
        return anchor?.image;
    }

    public getName(AnchorFamilyID?: number, AnchorID?: number, value?: number) {
        if (!this.design) {
            return '';
        }

        const codeListDeps = getCodeListTextDeps(this.localizationService);
        const anchorType: AnchorType = (this.design.designData.designCodeLists[DesignCodeList.AnchorType] as AnchorType[]).find((anchor) => anchor.id == AnchorFamilyID) as AnchorType;
        const anchorName = anchorType != null
            ? anchorType.getTranslatedNameText(codeListDeps) ?? ''
            : '';
        const anchorSize = (this.design.designData.designCodeLists[DesignCodeList.AnchorSize] as AnchorSize[]).find((anchor) => anchor.id == AnchorID);
        const size = anchorSize
            ? anchorSize.getTranslatedNameText(codeListDeps) ?? ''
            : '';
        const fullName = (anchorName + '<br>' + (size ? `${size} <span class="font-weight-initial">|</span> ` : '')).trim();
        const formatedHef = this.formatLength(value);
        const hef = ` hef = ${formatedHef}`;

        return fullName + hef;
    }

    mapToAnchorFilter = (corrosionMaterial?: CorrosionMaterial): AnchorFilterType => {
        switch (corrosionMaterial) {
            case (CorrosionMaterial.Stainless).charCodeAt(0):
                return AnchorFilterType.StainlessSteel;
            case (CorrosionMaterial.Galvanized).charCodeAt(0):
                return AnchorFilterType.SteelGalvanized;
            case (CorrosionMaterial.HighCorrosionResistant).charCodeAt(0):
                return AnchorFilterType.HighCorrosionResistantSteel;
            case (CorrosionMaterial.MechanicallyGalvanized).charCodeAt(0):
                return AnchorFilterType.SheradizedHotDipMechanicallyGalvanized;
            case (CorrosionMaterial.Sheradized.charCodeAt(0)):
                return AnchorFilterType.SheradizedHotDipGalvanized;
            default:
                return AnchorFilterType.Unknown;
        }
    };

    getSelectedConstructionOptionName(item: AsadOptimizeCase) {
        const key = this.smartAnchorOrderService.constructionOptions.find(x => x.id == (this.mapToAnchorFilter(item.optimizeCaseDetails?.corrosionMaterial)).toString());
        return key?.text;
    }

    public setSortByCondition(selectedSortBy: number, isSortByClicked?: boolean) {
        if (isSortByClicked) {
            this.design.usageCounter.SmartAnchor_SortClicked++;
        }
        this.anchorAIResultSortDropdown.selectedValue = selectedSortBy;
        switch (selectedSortBy) {
            case AnchorAIResultSort.Utilization: {
                this.design.usageCounter.SmartAnchor_SortBy = 'SortBy - Utilization(High to Low)';
                this.visibleOptimized.sort(({ optimizeCaseOutput: x }, { optimizeCaseOutput: y }) => (y?.maxUtilization ?? 0) - (x?.maxUtilization ?? 0));
                break;
            }
            case AnchorAIResultSort.BaseplateSizeOption: {
                this.design.usageCounter.SmartAnchor_SortBy = 'SortBy - Anchors Plate Size(Small to Large)';
                this.visibleOptimized.sort(({ optimizeCaseOutput: x }, { optimizeCaseOutput: y }) => (x?.area ?? 0) - (y?.area ?? 0));
                break;
            }
            case AnchorAIResultSort.NumberOfAnchorsOption: {
                this.design.usageCounter.SmartAnchor_SortBy = 'SortBy - Anchors (Low to High)';
                this.visibleOptimized.sort(({ optimizeCaseDetails: x }, { optimizeCaseDetails: y }) => (x?.numberOfAnchors ?? 0) - (y?.numberOfAnchors ?? 0));
                break;
            }
            case AnchorAIResultSort.EmbedmentDepthOption: {
                this.design.usageCounter.SmartAnchor_SortBy = 'SortBy - Embedment Depth(Low to High)';
                this.visibleOptimized.sort(({ optimizeCaseOutput: x }, { optimizeCaseOutput: y }) => (x?.embedmentDepth ?? 0) - (y?.embedmentDepth ?? 0));
                break;
            }
            case AnchorAIResultSort.TcoCostOption: {
                this.design.usageCounter.SmartAnchor_SortBy = 'SortBy - TCO Cost(Low to High)';
                this.visibleOptimized.sort(({ optimizeCaseOutput: x }, { optimizeCaseOutput: y }) => (x?.TCO ?? 0) - (y?.TCO ?? 0));
                break;

            }
        }
    }

    public openPreference() {
        this.design.usageCounter.SmartAnchor_PreferenceClicked++;
        this.showFilter = !this.showFilter;
    }

    public openSolutionDetail(optimizeCase: AsadOptimizeCase) {
        this.modal.openSolutionDetail(optimizeCase);
    }

    public getNumbersOfResults(preferences: IAsadPreferencesFilteringParameters): number {
        let numbers = 0;
        if (this.output) {
            Object.values(this.output.optimizeCases).filter(x => this.filterOptimizeCases(x)).forEach(optimizeCase => {
                if (this.isAnchorEnable(optimizeCase, preferences)) {
                    numbers++;
                }
            });
        }
        return numbers;
    }

    public filter(preferences: IAsadPreferencesFilteringParameters) {
        this.visibleOptimized = [];
        if (this.output) {
            Object.values(this.output.optimizeCases).filter(x => this.filterOptimizeCases(x)).forEach(optimizeCase => {
                if (this.isAnchorEnable(optimizeCase, preferences) && (this.onlyShowResultsWithTco ? optimizeCase.optimizeCaseOutput?.TCO != null : true)) {
                    this.visibleOptimized.push(optimizeCase);
                }
            });

            if (this.anchorAIResultSortDropdown.selectedValue) {
                this.setSortByCondition(this.anchorAIResultSortDropdown.selectedValue);
            }
        }

        this.design.usageCounter.SmartAnchor_FilteredSolutions = this.visibleOptimized.length;
    }

    public isAnchorEnable(optimizeCase: AsadOptimizeCase, preferences: IAsadPreferencesFilteringParameters): boolean {
        const { optimizeCaseOutput, optimizeCaseDetails } = optimizeCase;
        const codeList = DesignMethodGroupHelper.IsLrfdBased(this.design.designMethodGroup?.id)
            ? this.design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamily[]
            : this.design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];
        const anchor = (codeList).find((a) => optimizeCaseDetails?.fastenerFamilyId == a.id && a.detailed != undefined && a.detailed != null);

        if (preferences.isAdaptiveTorqueAllowed && !optimizeCaseDetails?.isAtToolAllowed) return false;

        if (preferences.isNoAutomaticCleaning && !optimizeCaseDetails?.isAutoCleaningAllowed) return false;

        if (preferences.isNoCleaningRequired && !anchor?.detailed?.isZeroCleaningAllowed) return false;

        if (this.isAnchorInGivenAnchorLayout(optimizeCaseDetails?.numberOfAnchors) &&
            this.isAnchorInGivenEmbedmentDepth(optimizeCaseOutput?.embedmentDepth, preferences.embedmentDepthMin) &&
            this.isAnchorInGivenBaseplateWidth(optimizeCaseOutput?.anchorPlateWidth) &&
            this.isAnchorInGivenBaseplateHeight(optimizeCaseOutput?.anchorPlateHeight) &&
            this.isAnchorInUtilizationRange(optimizeCaseOutput?.maxUtilization) &&
            this.isAnchorSizefiltered(optimizeCaseDetails?.fastenerId) &&
            this.isAnchorInGivenConstructionOptions(optimizeCaseDetails?.corrosionMaterial)) {
            return true;
        }

        return false;
    }

    private isAnchorInGivenAnchorLayout(numberOfAnchors?: number) {
        return (this.baseplateFilterOutput.selectedAnchorLayout.length == 0 || (numberOfAnchors != undefined && this.baseplateFilterOutput.selectedAnchorLayout.includes(numberOfAnchors)));
    }

    private isAnchorInGivenEmbedmentDepth(embedmentDepth?: number, embedmentDepthMin?: number) {
        return (this.baseplateFilterOutput.embedmentDepth == 0 || ((this.getNumber(embedmentDepth) <= this.baseplateFilterOutput.embedmentDepth) || (embedmentDepth == null && embedmentDepthMin == 0)));
    }

    private isAnchorInGivenBaseplateWidth(anchorPlateWidth?: number) {
        return (this.baseplateFilterOutput.baseplateWidth == 0 || (this.getNumber(anchorPlateWidth) <= this.baseplateFilterOutput.baseplateWidth));
    }

    private isAnchorInGivenBaseplateHeight(anchorPlateHeight?: number) {
        return (this.baseplateFilterOutput.baseplateHeight == 0 || (this.getNumber(anchorPlateHeight)) <= this.baseplateFilterOutput.baseplateHeight);
    }

    private isAnchorInGivenConstructionOptions(corrosionMaterial?: CorrosionMaterial) {
        return (this.baseplateFilterOutput.constructionOption.length == 0
            || this.baseplateFilterOutput.constructionOption.some(x => x == this.mapToAnchorFilter(corrosionMaterial)));
    }

    private isAnchorSizefiltered(anchorId?: number): boolean {
        const anchorSize = (this.design.designData.designCodeLists[DesignCodeList.AnchorSize] as AnchorSize[]).find((anchor) => anchor.id == anchorId);
        return this.baseplateFilterOutput.selectedAnchorSizes.length == 0 || this.baseplateFilterOutput.selectedAnchorSizes.some(s => s == anchorSize?.name);
    }

    private isAnchorInUtilizationRange(anchorUtilization?: number) {
        return (this.baseplateFilterOutput.maxUtilization == 0 || this.baseplateFilterOutput.minUtilization == 0 || (this.getNumber(anchorUtilization) >= this.baseplateFilterOutput.minUtilization && this.getNumber(anchorUtilization) <= this.baseplateFilterOutput.maxUtilization));
    }

    public async openFeedbackForm() {

        if (!this.userSettingsService.settings.searchSolutionsCount.value) {
            this.userSettingsService.settings.searchSolutionsCount.value = 1;
        }
        else {
            this.userSettingsService.settings.searchSolutionsCount.value += 1;
        }

        if (this.userSettingsService.settings.searchSolutionsCount.value == 5) {
            this.modal.openFeedBackForm();
        }

        await this.userSettingsService.save();
    }

    public runOptimization() {
        this.smartDesignSearchSolutionService.Optimizing = true;
        this.smartDesignSearchSolutionService.optimize();
        this.optimize();
    }

    public async optimize() {
        this.design.usageCounter.SmartAnchor_SearchSolutionsClicked++;
        if (!this.isAsadCalculable) {
            return;
        }

        this.openFeedbackForm();
        NgZone.assertInAngularZone();
        this.smartDesignSearchSolutionService.Optimizing = true;
        this.startedSearch = true;
        this.cancelClicked = false;
        this.anchorAIResultSortDropdown.selectedValue = undefined;
        this.optimizeFirstEnd = null as any;
        this.optimizeFirstStart = null as any;
        this.resetResult();

        const cancel = new Promise<void>(resolve => {
            this.cancelOptimization = () => resolve();
        }).then(() => {
            this.cancelClicked = true;
            this.smartDesignSearchSolutionService.optimizeDone();
        });

        const optimizationStart = performance.now();

        this.userService.design.designData.asadData = {
            input: {
                useLambda: this.userService.design.designData.asadData.input.useLambda || this.isLambdaEnabled,
                projectDesign: cloneDeep(this.userService.design.designData.projectDesign as ProjectDesignBaseEntity),
                generic: '',
                includeLogs: false,
                includeTimeEstimation: false,
                includeScValidation: false,
                timeEstimationRefresh: undefined as unknown as number,
                includeRandomErrors: false,
                includeFasteners: AsadIncludeFasteners.ui
            },
            output: {
                maxOptimizeCasesCount: 0,
                totalCalculationCount: 0,
                totalTime: 0,
                calculationCount: 0,
                runningOptimizationsCount: 0,
                logs: '',
                optimizationDate: Date.now(),
                optimizeMessages: [],
                timeEstimationUpdate: { progress: 0, remainingCalculationTime: 0 },
                timeEstimationCalculation: { progress: 0, estimatedCalculationCount: 0 },
                timeEstimationAverage: { progress: 0, estimatedCalculationCount: 0 },
                timeEstimations: [],
                optimizeCases: {},
                scopeCheckErrors: [],
                doneCasesCount: 0,
                totalCasesCount: 0,
                feasibleCasesCount: 0,
                errorCasesCount: 0
            }
        };

        const output = this.userService.design.designData.asadData.output;
        this.optimizeFirstStart = performance.now();
        try {
            const optimizeDesignOutput = await this.signalRService.asad.asadOptimize({
                UseLambda: this.input.useLambda,
                Design: this.userService.design.designData.projectDesign as ProjectDesignBaseEntity,
                Generic: this.input.generic,
                IsFromUI: true,
                IncludeLogs: false
            }, {
                cancel,
                onProgress: this.onProgress
            });

            if (optimizeDesignOutput.LastProgress != null) {
                this.onProgress(optimizeDesignOutput.LastProgress);
            }
        }
        catch {
            this.smartDesignSearchSolutionService.Optimizing = false;
        }
        finally {
            (output as AsadOutput).totalTime = performance.now() - optimizationStart;

            this.smartDesignSearchSolutionService.Optimizing = false;
            this.smartDesignSearchSolutionService.optimizeDone();
            if (this.resultsFound) {
                this.startedSearch = false;
                this.optimized = true;
            }
            this.cancelOptimization = () => null;
            this.visibleOptimized.sort((x, y) => {
                if (this.design?.model[UIProperty.OptimizeOnEmbedmentDepth]) {
                    return this.getNumber(x.optimizeCaseOutput?.embedmentDepth) - this.getNumber(y.optimizeCaseOutput?.embedmentDepth);
                }
                else {
                    return this.getNumber(x.optimizeCaseOutput?.area) - this.getNumber(y.optimizeCaseOutput?.area);
                }
            });
            if(this.design) {
                this.design.usageCounter.SmartAnchor_SolutionsFound = this.visibleOptimized.length;
                this.design.usageCounter.SmartAnchor_SolutionsTotalTime = Math.floor((output as AsadOutput).totalTime / 1000);
            }
            this.setFilterOptions();
            if (this.anchorAIResultSortDropdown.selectedValue) {
                this.setSortByCondition(this.anchorAIResultSortDropdown.selectedValue);
            }
        }
    }

    private resetResult() {
        this.optimized = false;
        this.smartBaselateSectionContainer.style.height = '68px';
        this.smartBaselateSectionContainer.style.minHeight = 'unset';
        this.showExpandedPanel = true;
        this.showFilter = false;
        this.resultsFound = false;
        this.clearFilter();
        this.design.setAnchorAISolutionSelected(false);
        this.design.model[UIProperty.SmartAnchor_IsLastSelectedSolution] = false;
        this.visibleOptimized = [];

        if (this.cancelOptimization) {
            this.cancelOptimization();
        }
    }

    itemClick(optimizeCase: AsadOptimizeCase) {
        this.expandedItemIndex = this.expandedItemIndex == optimizeCase.optimizeCaseId ? '' : optimizeCase.optimizeCaseId;
        if (this.expandedItemIndex != '') {
            this.design.usageCounter.SmartAnchor_SolutionClicked++;
        }
    }

    public async apply(optimizeCase: AsadOptimizeCase) {
        const { optimizeCaseOutput, optimizeCaseDetails } = optimizeCase;

        this.appliedResult = optimizeCase;
        this.design.setAnchorAISolutionSelectedOnce(true);
        const data = {
            ...optimizeCaseOutput,
            fastenerId: optimizeCaseDetails?.fastenerId ?? 0,
            variableEmbedmentDepth: optimizeCaseDetails?.variableEmbedmentDepth ?? false
        } as ModelChangesData;

        const modelChanges = this.mapper.createModelChanges(
            this.calculationService as unknown as CalculationServiceBasePE,
            this.userService as unknown as UserServiceBase<DesignPe>,
            data
        );

        modelChanges.addValue(UIProperty.SmartAnchor_PlateHidden, false);
        modelChanges.addValue(UIProperty.SmartAnchor_IsLastSelectedSolution, true);

        switch (optimizeCase.optimizeCaseDetails?.corrosionMaterial) {
            case (CorrosionMaterial.Galvanized).charCodeAt(0):
                modelChanges.addValue(UIProperty.AnchorLayout_CorrosionMaterial, CorrosionMaterial.Galvanized);
                break;
            case (CorrosionMaterial.Sheradized).charCodeAt(0):
                modelChanges.addValue(UIProperty.AnchorLayout_CorrosionMaterial, CorrosionMaterial.Sheradized);
                break;
            case (CorrosionMaterial.HighCorrosionResistant).charCodeAt(0):
                modelChanges.addValue(UIProperty.AnchorLayout_CorrosionMaterial, CorrosionMaterial.HighCorrosionResistant);
                break;
            case (CorrosionMaterial.MechanicallyGalvanized).charCodeAt(0):
                modelChanges.addValue(UIProperty.AnchorLayout_CorrosionMaterial, CorrosionMaterial.MechanicallyGalvanized);
                break;
            case (CorrosionMaterial.Stainless).charCodeAt(0):
                modelChanges.addValue(UIProperty.AnchorLayout_CorrosionMaterial, CorrosionMaterial.Stainless);
                break;
            default:
                modelChanges.addValue(UIProperty.AnchorLayout_CorrosionMaterial, 'Unknown');
                break;
        }

        await modelChanges.calculate();
        this.design.setAnchorAISolutionSelected(true);
    }

    private getNumber(value?: number) {
        return value ?? 0;
    }

    private onProgress(optimizeDesignProgress: OptimizeDesignProgress) {
        NgZone.assertInAngularZone();
        this.mapper.mapOptimizeDesignProgress(this.output, optimizeDesignProgress);

        this.visibleOptimized = Object.values((this.output as AsadOutput).optimizeCases)
            .filter(x => this.filterOptimizeCases(x))
            .sort((x, y) => {
                if (this.design.model[UIProperty.OptimizeOnEmbedmentDepth]) {
                    if (x.optimizeCaseOutput?.embedmentDepth != y.optimizeCaseOutput?.embedmentDepth) {
                        return this.getNumber(x.optimizeCaseOutput?.embedmentDepth) - this.getNumber(y.optimizeCaseOutput?.embedmentDepth);
                    }
                    else {
                        return this.getNumber(x.optimizeCaseOutput?.maxUtilization) - this.getNumber(y.optimizeCaseOutput?.maxUtilization);
                    }

                }
                else {
                    if (x.optimizeCaseOutput?.area != y.optimizeCaseOutput?.area) {
                        return this.getNumber(x.optimizeCaseOutput?.area) - this.getNumber(y.optimizeCaseOutput?.area);
                    }
                    else {
                        return this.getNumber(x.optimizeCaseOutput?.maxUtilization) - this.getNumber(y.optimizeCaseOutput?.maxUtilization);
                    }
                }
            });

        this.setFilterOptions();

        if (this.visibleOptimized.length > 0) {
            if (!this.optimizeFirstEnd) {
                this.optimizeFirstEnd = performance.now();
                const timetaken = Math.round(((this.optimizeFirstEnd - this.optimizeFirstStart) / 1000) * 100) / 100;
                this.design.usageCounter.SmartAnchor_FirstSolutionTime = timetaken;
            }

            this.filter(this.filterParameter);

            if (!this.resultsFound) {
                this.setResultHeight();
            }

            this.resultsFound = true;
        }
    }

    private setFilterOptions() {
        if (this.output) {
            const masterList = Object.values(this.output.optimizeCases).filter(x => this.filterOptimizeCases(x));
            const anchorSizeListSet = new Set<string>();
            masterList.forEach(anchor => {
                const size = (this.design.designData.designCodeLists[DesignCodeList.AnchorSize] as AnchorSize[]).find(s => s.id == anchor.optimizeCaseDetails?.fastenerId);
                if (size?.name) {
                    anchorSizeListSet?.add(size.name);
                }
            });

            const sortAlphaNum = (a: string, b: string) => a.localeCompare(b, 'en', { numeric: true });
            const sortedList = Array.from(anchorSizeListSet.values()).sort(sortAlphaNum);
            this.filterParameter.anchorSizeList = sortedList;
            this.filterParameter.anchorLayoutList = [...new Set(masterList.map(x => x.optimizeCaseDetails?.numberOfAnchors))]?.sort((n1: any, n2: any) => n1 - n2) as number[];
            this.filterParameter.selectedAnchorLayouts = this.baseplateFilterOutput.selectedAnchorLayout;
            this.filterParameter.embedmentDepthMax = Math.max(...masterList.map(x => this.getNumber(x.optimizeCaseOutput?.embedmentDepth)));
            this.filterParameter.embedmentDepthMin = Math.min(...masterList.map(x => this.getNumber(x.optimizeCaseOutput?.embedmentDepth)));
            this.filterParameter.utilizationMin = Math.min(...masterList.map(x => this.getNumber(x.optimizeCaseOutput?.maxUtilization)));
            this.filterParameter.utilizationMax = Math.max(...masterList.map(x => this.getNumber(x.optimizeCaseOutput?.maxUtilization)));
            this.filterParameter.baseplateWidthMin = Math.min(...masterList.map(x => this.getNumber(x.optimizeCaseOutput?.anchorPlateWidth)));
            this.filterParameter.baseplateWidthMax = Math.max(...masterList.map(x => this.getNumber(x.optimizeCaseOutput?.anchorPlateWidth)));
            this.filterParameter.baseplateHeightMin = Math.min(...masterList.map(x => this.getNumber(x.optimizeCaseOutput?.anchorPlateHeight)));
            this.filterParameter.baseplateHeightMax = Math.max(...masterList.map(x => this.getNumber(x.optimizeCaseOutput?.anchorPlateHeight)));
            this.filterParameter = JSON.parse(JSON.stringify(this.filterParameter));
        }
    }

    private setDefaultHeight() {
        this.smartBaselateSectionContainer.style.height = this.showExpandedPanel && this.resultsFound ? this.scaledHeight : 'auto';
        this.smartBaselateSectionContainer.style.minHeight = this.showExpandedPanel && this.resultsFound ? 'unset' : '0';

        if (this.showExpandedPanel && this.resultsFound) {
            this.setResultHeight();
        }
    }

    private setResultHeight() {
        setTimeout(() => {
            this.smartBaselateSectionContainer.style.height = this.scaledHeight;
            if (this.smartBaselateFilterContainer) {
                this.smartBaselateFilterContainer.style.height = this.filterPanelHeight;
                this.smartBaselateSectionContainer.style.minHeight = this.minHeight;
            }
        });
        this.smartBaselateSectionContainer.style.height = this.scaledHeight;
        if (this.smartBaselateFilterContainer) {
            this.smartBaselateFilterContainer.style.height = this.filterPanelHeight;
            this.smartBaselateSectionContainer.style.minHeight = this.minHeight;
        }
    }

    private getItemsForDropdown() {
        const items = [
            {
                value: AnchorAIResultSort.Utilization,
                text: this.localizationService.getString('Agito.Hilti.Profis3.SmartBasePlate.SortBy.UtilizationOption'),
            },
            {
                value: AnchorAIResultSort.BaseplateSizeOption,
                text: this.localizationService.getString('Agito.Hilti.Profis3.SmartBasePlate.SortBy.BaseplateSizeOption')
            },
            {
                value: AnchorAIResultSort.NumberOfAnchorsOption,
                text: this.localizationService.getString('Agito.Hilti.Profis3.SmartBasePlate.SortBy.NumberOfAnchorsOption')
            },
            {
                value: AnchorAIResultSort.EmbedmentDepthOption,
                text: this.localizationService.getString('Agito.Hilti.Profis3.SmartBasePlate.SortBy.EmbedmentDepthOption')
            }
        ];

        if (this.tcoEnabled) {
            items.push({
                value: AnchorAIResultSort.TcoCostOption,
                text: this.localizationService.getString('Agito.Hilti.Profis3.SmartBasePlate.SortBy.TcoCostOption')
            });
        }

        return items;
    }

    onResize() {
        setTimeout(() => {
            const infoSection = this.smartBaselateSectionContainer;
            this.infoSectionHeight = this.smartBaselateSectionContainer.offsetHeight;
            infoSection.style.height = this.infoSectionHeight - 2 + 'px';
            this.smartBaselateFilterContainer.style.height = this.infoSectionHeight - 68 + 'px';
        });

    }

    baseplateFilterChange(e: IBaseplateFilterOutput) {
        this.baseplateFilterOutput = e;
        this.filter(this.filterParameter);
    }

    private clearFilter() {
        this.expandedItemIndex = '';
        this.filterParameter = {
            embedmentDepthMax: 0,
            embedmentDepthMin: 0,
            numberOfAnchorsMax: 0,
            numberOfAnchorsMin: 0,
            baseplateSizeMin: 0,
            baseplateSizeMax: 0,
            utilizationMin: 0,
            utilizationMax: 0,
            isNoAutomaticCleaning: false,
            isNoCleaningRequired: false,
            isAdaptiveTorqueAllowed: false,
            anchorLayoutList: [],
            selectedAnchorLayouts: [],
            anchorSizeList: []
        };

        this.baseplateFilterOutput = {
            baseplateWidth: 0,
            baseplateHeight: 0,
            constructionOption: [],
            embedmentDepth: 0,
            maxUtilization: 0,
            minUtilization: 0,
            selectedAnchorLayout: [],
            selectedAnchors: [],
            selectedAnchorSizes: []
        };
    }

    calculateTcoPercentage(partialPrice: number | undefined, totalPrice: number | undefined) {
        if (!totalPrice || !partialPrice) {
            return -1;
        }
        return (partialPrice * 100 / totalPrice).toFixed(1);
    }

    filterOptimizeCases(optimizeCase: any): boolean {
        return (
            optimizeCase.optimizeCaseOutput != null &&
            optimizeCase.optimizeCaseDetails &&
            !!optimizeCase.optimizeCaseDetails.fastenerFamilyId &&
            (this.getUtilization(optimizeCase) > 0)
        );
    }

    public get tcoEnabled() {
        return this.featuresVisibilityService.isFeatureEnabled('PE_SmartAnchorEnableTco');
    }

    secondsToHumanReadible(seconds: number | undefined) {
        if(!seconds) {
            return 'no data';
        }
        const minutes = Math.floor(seconds / 60).toString();
        const secondsPartial = Math.floor(seconds % 60).toString();

        return minutes + 'm ' + secondsPartial + 's';
    }

    resultVisible(result: AsadOptimizeCase){
        if(this.onlyShowResultsWithTco) {
            return result.optimizeCaseOutput?.TCO != null;
        }
        return true;
    }
}
