export type MainMenuCommand =
    'OpenApproval' |
    'OpenDrillingAidPopup' |
    'OpenDepthOfRecessPopup' |
    'OpenCrossSectionalAreaPopup' |
    'OpenEffectiveHeightPopup' |
    'OpenCoverPopup' |
    'OpenInstallationDirectionPopup' |
    'OpenTransverseEccentricityPopup' |
    'OpenReinforcementEffectivenessPopup' |
    'OpenFastenerSelectionPopup';

export const enum Command {
    OpenApproval = 1,
    OpenDrillingAidPopup = 2,
    OpenDepthOfRecessPopup = 3,
    OpenCrossSectionalAreaPopup = 4,
    OpenEffectiveHeightPopup = 5,
    OpenCoverPopup = 6,
    OpenInstallationDirectionPopup = 7,
    OpenTransverseEccentricityPopup = 8,
    OpenReinforcementEffectivenessPopup = 9,
    OpenFastenerSelectionPopup = 10
}

const commandDictionary: Record<number, MainMenuCommand> = {
    [Command.OpenApproval]: 'OpenApproval',
    [Command.OpenDrillingAidPopup]: 'OpenDrillingAidPopup',
    [Command.OpenDepthOfRecessPopup]: 'OpenDepthOfRecessPopup',
    [Command.OpenCrossSectionalAreaPopup]: 'OpenCrossSectionalAreaPopup',
    [Command.OpenEffectiveHeightPopup]: 'OpenEffectiveHeightPopup',
    [Command.OpenCoverPopup]: 'OpenCoverPopup',
    [Command.OpenInstallationDirectionPopup]: 'OpenInstallationDirectionPopup',
    [Command.OpenTransverseEccentricityPopup]: 'OpenTransverseEccentricityPopup',
    [Command.OpenReinforcementEffectivenessPopup]: 'OpenReinforcementEffectivenessPopup',
    [Command.OpenFastenerSelectionPopup]: 'OpenFastenerSelectionPopup'
};

export function commandFromService(command: Command): MainMenuCommand {
    return commandDictionary[command];
}
