declare global {
    interface Window {
        dotnetManifestSP: Record<string, string> | undefined;
    }
}

export interface Feature {
    key: string;
    multivariantValue?: string;
    enabled: boolean;
}

interface FeatureFlagsConfig {
    enableFeatureQuery: boolean;
    features: Feature[];
}

interface ServicesConfiguration {
    featureFlagsConfig: FeatureFlagsConfig;
    showDevVersionTextInReport: boolean;
    disableStaticContentHashInReport: boolean;
}

interface InitializeOptions {
    validateScopes?: boolean;
    servicesConfiguration?: ServicesConfiguration;
}

interface DotnetProviderOptions {
    servicesConfiguration: ServicesConfiguration;
    dotnetValidateScopes: boolean;
}

function getHashUrl(uri: string): string {
    const dotnetManifest = window.dotnetManifestSP;
    if (dotnetManifest != null) {
        const hash = dotnetManifest[uri];
        const extensionIndex = uri.lastIndexOf('.');
        const extension = uri.substring(extensionIndex + 1);
        const nameWithoutExtension = uri.substring(0, extensionIndex);

        return `${nameWithoutExtension}.${hash}.${extension}`;
    }

    return uri;
}

export class DotnetProvider {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public exports: any;
    private dotnetInitializedPromise?: Promise<void>;

    constructor(
        private readonly options: DotnetProviderOptions
    ) { }

    private async initializeDotnet(): Promise<void> {
        const disableNoCacheFetch = this.getDisableNoCacheFetch();

        // start loading data
        const dataPromise = fetch(`cdn/pe-ui-sp/dotnet/${getHashUrl('data.dat')}`, {
            method: 'GET',
            cache: disableNoCacheFetch ? undefined : 'no-cache'
        });

        // load runtime
        const dotnetUrl = `/cdn/pe-ui-sp/dotnet/${getHashUrl('_framework/dotnet.js')}`;
        const { dotnet } = await import(/* @vite-ignore */ /* webpackIgnore: true */dotnetUrl) as typeof import('../types/dotnet');

        // start runtime
        const { getAssemblyExports, getConfig } = await dotnet
            .withConfig({
                disableNoCacheFetch,
                diagnosticTracing: true
            })
            .withResourceLoader((type, name, defaultUri) => {
                const frameworkIndex = defaultUri.lastIndexOf('/_framework/') + 1;
                const frameworkUri = defaultUri.substring(frameworkIndex);
                const baseUri = defaultUri.substring(0, frameworkIndex);
                const hashUri = getHashUrl(frameworkUri);

                const uri = `${baseUri}${hashUri}`;

                // disableNoCacheFetch for blazor.boot.json
                if (defaultUri.endsWith('/blazor.boot.json')) {
                    return fetch(uri, {
                        method: 'GET',
                        cache: disableNoCacheFetch ? undefined : 'no-cache'
                    });
                }

                return uri;
            })
            .create();
        const config = getConfig();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.exports = await getAssemblyExports(config.mainAssemblyName!);

        // wait for data load
        const dataResponse = await dataPromise;
        if (!dataResponse.ok) {
            throw new Error('sp data.dat error response', { cause: dataResponse });
        }

        const dataCacheBlob = await dataResponse.blob();
        const dataCacheBase64 = await this.blobToBase64(dataCacheBlob);

        // initialize with data
        const initializeOptions: InitializeOptions = {
            validateScopes: this.options.dotnetValidateScopes,
            servicesConfiguration: this.options.servicesConfiguration
        };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        this.exports.Hilti.SP.Wasm.Program.Initialize(dataCacheBase64, JSON.stringify(initializeOptions));
    }

    public async initialize(): Promise<void> {
        if (this.dotnetInitializedPromise == null) {
            this.dotnetInitializedPromise = this.initializeDotnet();
        }
        await this.dotnetInitializedPromise;
    }

    private getDisableNoCacheFetch(): boolean {
        return window.dotnetManifestSP != null;
    }

    private blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64WithMetadata = reader.result as string;
                const base64MetadataIndex = base64WithMetadata.lastIndexOf(',');
                const base64 = base64WithMetadata.substring(base64MetadataIndex + 1);

                resolve(base64);
            };
            reader.readAsDataURL(blob);
        });
    }
}
