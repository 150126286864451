import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { includeSprites } from '../../sprites';
import { LoadsComponentHelper } from '../../loads-component-helper';
import { LoadCombinationRowData, LoadsRowPunchComponent } from '../loads-row/loads-row-punch.component';
import { PunchLoadCombination, getLoadTypeName } from '../../services/design.service';
import { L10nDirective } from '../../directives/l10n.directive';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { L10nPipe } from '../../pipes/l10n.pipe';

export const DEFAULT_MAX_LOADS_HEIGHT = 334;

@Component({
    selector: 'app-loads-punch',
    templateUrl: './loads-punch.component.html',
    styleUrls: ['../loads-row/loads-base.scss', './loads-punch.component.scss'],
    imports: [
        L10nDirective,
        L10nPipe,
        NgbTooltip,
        LoadsRowPunchComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoadsPunchComponent implements OnInit, OnChanges {
    private static readonly loadsRowHeight: number = 32; // sync with css variable @loads-row-height
    private static readonly resizedAndBorderPadding: number = 6; // 4 resizer + 2 borders

    @Input()
    public resize3d!: (resetHighlightedComponents?: boolean) => void;

    @Input()
    public unitForce!: UnitType;

    @Input()
    public unitMoment!: UnitType;

    @Input()
    public betaId!: number | null;

    @Input()
    public loadCombinations!: PunchLoadCombination[];

    @Output()
    public loadCombinationsChange = new EventEmitter<PunchLoadCombination[]>();

    public collapsed = false;
    public helper: LoadsComponentHelper;

    constructor(
        public localizationService: LocalizationService,
        private unitService: UnitService,
        private elementRef: ElementRef<HTMLElement>,
    ) {
        this.helper = new LoadsComponentHelper(
            localizationService,
            unitService
        );
    }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
            'sprite-anchor-shock',
            'sprite-lines-expanded',
            'sprite-lines'
        );

        this.setMinimumHeight();
    }

    public ngOnChanges(): void {
        this.helper.unitForce = this.unitForce;
        this.helper.unitMoment = this.unitMoment;
        this.helper.betaId = this.betaId ?? 0;
    }

    public get selectedLoad() {
        return this.loadCombinations[0];
    }

    public get selectedLoadTypeName() {
        return this.localizationService.getString('SP.Loads.' + getLoadTypeName(this.selectedLoad.loadTypeId));
    }

    public get shearForceHeader() {
        return `${this.localizationService.getString('SP.Loads.Header.Punch.Force')} [${this.unitService.getUnitStrings(this.unitForce)[0]}]`;
    }

    public get momentsHeader() {
        return `${this.localizationService.getString('SP.Loads.Header.Punch.Moments')} [${this.unitService.getUnitStrings(this.unitMoment)[0]}]`;
    }

    // eslint-disable-next-line @typescript-eslint/class-literal-property-style
    public get titleTranslation() {
        return 'Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.ControlLoadCombinations.Title';
    }

    public get loadsContainer() {
        return this.elementRef.nativeElement.querySelector<HTMLElement>('.loads-container')!;
    }

    public formatShearForce(value: number) {
        if (value == null) {
            return null;
        }

        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Force);
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Force);
        const precision = this.unitService.getPrecision(defaultUnit);
        const defaultValue = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unitService.formatNumber(defaultValue, precision);
    }

    public formatMoment(value: number) {
        if (value == null) {
            return null;
        }

        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Moment);
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Moment);
        const precision = this.unitService.getPrecision(defaultUnit);
        const defaultValue = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unitService.formatNumber(defaultValue, precision);
    }

    public onCollapsedChanged() {
        this.setMinimumHeight();
        if (this.resize3d != null) {
            this.resize3d();
        }
    }

    private setMinimumHeight() {
        const minimumHeight = !this.collapsed
            ? LoadsPunchComponent.loadsRowHeight * 3 + LoadsPunchComponent.resizedAndBorderPadding
            : 0;

        this.loadsContainer.style.minHeight = minimumHeight + 'px';
    }

    public onLoadChanged(rowData: LoadCombinationRowData) {
        this.loadCombinations[rowData.index] = rowData.loadCombination;
        this.loadCombinationsChange.emit(this.loadCombinations);
    }
}
