import { Subscription } from 'rxjs';
import { mergeMap, skip } from 'rxjs/operators';
import {  Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DefinitionOfSidelapConnectors } from './../../../entities/decking-code-list/enums/definition-sidelap-connectors';
import { BaseDiaphragmDesignSettingsComponent } from './../../../components/common/diaphragm-design-settings/diaphragm-design-settings.component';
import { LocalizationService } from './../../../services/external/localization.service';
import { DeckingDesignService } from './../../../services/decking-design/decking-design.service';
import { DesignSettings } from './../../../entities/settings/design-settings';
import { DeckingDesignSettingsService } from './../../../services/decking-design/settings/decking-design-settings.service';
import { DesignMethodConverter } from './../../../entities/decking-code-list/enums/helpers/design-method-converter';
import { RelevantLoadsConverter } from './../../../entities/decking-code-list/enums/helpers/relevant-loads-converter';
import { DeckingDefaultFactoryService } from './../../../services/decking-design/factory/decking-default-factory.service';
import { DeckingCodeListService } from './../../../services/decking-code-list/decking-code-list.service';
import { DeckingUrlPath } from './../../../entities/module-constants';
import { RoutingService } from './../../../services/external/routing.service';
import { DeckingUserSettingsService } from './../../../services/decking-user-settings/user-settings.service';
import { FeatureVisibilityService } from './../../../services/external/feature-visibility.service';
import { CountryCode, Region } from './../../../entities/enums/decking-settings-region';
import { DeckingMainService } from 'src/decking/services/decking-main/decking-main.service';
import { DeckingDesignModeType } from 'src/decking/entities/enums/decking-design-mode-type';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { SubstitutionSettings } from 'src/decking/entities/settings/substitution-settings';
import { LoggerService } from 'src/decking/services/external/logger.service';
import { RadioButtonProps } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { BaseDesign } from '../../../entities/decking-design/base-design';
import { CheckboxButtonItem } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { IDesignTemplateDocument } from '@profis-engineering/pe-ui-common/services/design-template.common';
import { DesignTypeId } from 'src/decking/entities/enums/design-types';
import { CommonCodeListService } from 'src/decking/services/external/common-code-list.service';
import { DesignTemplateService } from 'src/decking/services/external/design-template.service';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { DeckingDesignSignalrService } from 'src/decking/services/decking-design-signalr/decking-design-signalr.service';
import { DesignStandardConverter } from 'src/decking/entities/decking-code-list/enums/helpers/design-standard-converter';

type CombinedDesignSettings = DesignSettings | SubstitutionSettings;

@Component({
  templateUrl: './decking-diaphragm-design-settings.component.html',
  styleUrls: ['./decking-diaphragm-design-settings.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class DeckingDiaphragmDesignSettingsComponent extends BaseDiaphragmDesignSettingsComponent implements OnInit, OnDestroy, OnChanges {

  readonly ONE_INCH_IN_MM: number = 25.4;
  readonly maxValueSidelapIncrement: number = 20 * this.ONE_INCH_IN_MM;
  readonly maxValueSidelapToInput: number = 100 * this.ONE_INCH_IN_MM;

  @Input()
  public selectedRegion: CommonRegion;

  @Input()
  public customName: string;

  @Input()
  public documentId: string = null;

  @Input()
  public isTemplate = false;

  @Input()
  public addEditType!: AddEditType;

  // loading Settings
  @Output() loadingSettings = new EventEmitter<boolean>();

  // state
  internalDeckingSettings: DesignSettings;
  originalDeckingSettings: DesignSettings;
  loadingDeckingSettings : boolean;

  sidelapConnectorsType = DefinitionOfSidelapConnectors;
  isSidelapConnectorChanged = false;
  ConnectorsUnitFromLength: Unit;

  currentDesignSubscription: Subscription;
  currentSubstitutionSubscription: Subscription;
  saveSignalSubscription: Subscription;
  designCalculated: Subscription;

  zoneInputCheckboxesCheckedItems: Set<number> = new Set();
  substitutionZoneInputCheckboxesCheckedItems: Set<number> = new Set();
  zoneInputMapping = {
    requiredShearStiffness: 1,
    calculateIntegration: 2,
    windAndSeismicLoadsAtZoneLevel: 3,
    requiredUpliftSubmittal: 4,
  };

  substitutionZoneInputMapping = {
    substitutionRequiredShearStiffness: 1,
    calculateIntegration: 2,
    substitutionWindAndSeismicLoadsAtZoneLevel: 3,
    substitutionRequiredUpliftSubmittal: 4,
  };

  isSubstitutionEnabled = false;
  PercentUnit: Unit = Unit.percent;
  isnewCustomDesignOpen = false;
  isMultiCodeEnabled = false;

  @Input()
  public openAdvancedSettings = false;

  public collapseRegion: {
    UnitsAndParams: boolean;
    DesignReference: boolean;
    DataEntry: boolean;
    ZoneInput: boolean;
    designMode: boolean;
  };
  public modeSelectorOptions!: RadioButtonProps<DeckingDesignModeType>;
  public modeSelected: DeckingDesignModeType;
  public addEditTypeEnum = AddEditType;
  public deckingDesignModeType = DeckingDesignModeType;
  originalZoneInputCheckboxesItems: CheckboxButtonItem<number>[];
  isDesignMode = true;
  hideSubstitutionDesignSetting = false;

  constructor(
    localizationService: LocalizationService,
    deckingUserSettingsService: DeckingUserSettingsService,
    featureVisibilityService: FeatureVisibilityService,
    private deckingDesignService: DeckingDesignService,
    private deckingDesignSettingsService: DeckingDesignSettingsService,
    private deckingDefaultFactoryService: DeckingDefaultFactoryService,
    private deckingCodeListService: DeckingCodeListService,
    private routingService: RoutingService,
    public deckingMainService: DeckingMainService,
    private deckingSubstitutionService: DeckingSubstitutionService,
    private loggerService: LoggerService,
    private codeListCommon: CommonCodeListService,
    private designTemplate: DesignTemplateService,
    private deckingDesignSignalrService: DeckingDesignSignalrService,
  ) {
    super(localizationService, deckingUserSettingsService, featureVisibilityService);
  }

  ngOnInit(): void {
    this.originalZoneInputCheckboxesItems = this.zoneInputCheckboxesItems;
    this.loadingDeckingSettings = true;
    this.loadingSettings.emit(this.loadingDeckingSettings);
    this.collapseRegion = {
      UnitsAndParams: this.openAdvancedSettings,
      DesignReference: this.openAdvancedSettings,
      DataEntry: this.openAdvancedSettings,
      ZoneInput: this.openAdvancedSettings,
      designMode: this.openAdvancedSettings
    };
    this.isSubstitutionEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_SubstitutionBuilder');
    this.isMultiCodeEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_MultiCodes');
    //setting this true for new homepage template creation
    this.hideSubstitutionDesignSetting = this.userSettingsService.isCreateTemplate;
    this.modeSelectorOptions = {
      id: 'decking-modeselection-options',
      selectedValue: this.modeSelected,
      items: [
          {
              id: 'decking-mode-diaphragm',
              text: this.localization.getString('Agito.Hilti.Profis3.Decking.ModeSelectorModal.NewDesignText'),
              description: this.localization.getString('Agito.Hilti.Profis3.Decking.ModeSelectorModal.NewDesignDescriptionText'),
              value: DeckingDesignModeType.DesignMode,
              disabled: false
          },
          {
              id: 'decking-mode-substitution',
              text: this.localization.getString('Agito.Hilti.Profis3.Decking.ModeSelectorModal.NewSubstitutionText'),
              description: this.localization.getString('Agito.Hilti.Profis3.Decking.ModeSelectorModal.NewSubstitutionDescriptionText'),
              value: DeckingDesignModeType.SubstitutionBuilderMode,
              disabled: false
          }
      ]
    };

    switch (this.deckingMainService.getSelectedModeType()) {
      case DeckingDesignModeType.DesignMode:
          this.designSettingsSubscription();
          break;
      case DeckingDesignModeType.SubstitutionBuilderMode:
          this.substitutionSettingsSubscription();
          break;
      default:
          this.loggerService.logServiceError(`Invalid design Type : ${this.deckingMainService.getSelectedModeType()}`, 'DeckingDiaphragmDesignSettingsComponent', 'ngOnInit');
          break;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if(changes['selectedRegion'] && !this.deckingDocumentExists) {
      await this.deckingCodeListService.init();
      this.measureUnitsUpdated(this.selectedRegion.countryCode === CountryCode.US || this.selectedRegion.countryCode === CountryCode.Mexico, true);
    }
    if(this.addEditType == AddEditType.add) {
      this.modeSelected = this.deckingDesignModeType.DesignMode;
      this.isDesignMode = true;
      this.isnewCustomDesignOpen = true;
      this.loadZoneInputCheckboxes();
      this.substitutionLoadZoneInputCheckboxes();
    } else if(this.addEditType == AddEditType.edit) {
      this.isnewCustomDesignOpen = false;
      this.modeSelected = this.deckingMainService.getSelectedModeType();
    }
    this.deckingMainService.setSelectedModeType(this.modeSelected);
  }

  public get deckingDocumentExists(): boolean {
    switch (this.deckingMainService.getSelectedModeType()) {
      case DeckingDesignModeType.DesignMode:
          return !!this.documentId ||  !!this.deckingDesignService.documentId;
      case DeckingDesignModeType.SubstitutionBuilderMode:
          return !!this.documentId ||  !!this.deckingSubstitutionService.documentId;
      default:
          this.loggerService.logServiceError(`Invalid design Type : ${this.deckingMainService.getSelectedModeType()}`, 'DeckingDiaphragmDesignSettingsComponent', 'deckingDocumentExists');
          return false;
    }
  }

  setInternalDesignSettings(settings: CombinedDesignSettings): void {
    this.internalDeckingSettings = cloneDeep(settings);

    this.isImperial = settings?.isImperial?.value ?? undefined;
    this.isDesignMode ? this.loadZoneInputCheckboxes() : this.substitutionLoadZoneInputCheckboxes();
    this.onLengthInputChanged();

    if(this.addEditType !== undefined) {
      if(this.addEditType == AddEditType.add) {
        this.modeSelected = this.modeSelected ?? this.deckingDesignModeType.DesignMode;
      } else if(this.addEditType == AddEditType.edit) {
        this.modeSelected = this.deckingMainService.getSelectedModeType();
      }
      this.deckingMainService.setSelectedModeType(this.modeSelected);
    }
  }

  designSettingsSubscription(): void {
    this.isDesignMode = true;
    this.currentDesignSubscription = this.deckingDesignService.currentSettings$.pipe(mergeMap(async (settings: DesignSettings) => {
      // cloning decking settings as an internal state for current component
      this.originalDeckingSettings = settings;
      let tempSettings = settings;
      if (!(settings && this.deckingDocumentExists)) {
        // new custom settings
        await this.deckingCodeListService.init();
        tempSettings = this.internalDeckingSettings ? this.internalDeckingSettings : this.deckingDefaultFactoryService.buildDefaultDeckingDesign(null).settings;
        this.loadingSettings.emit(!this.loadingDeckingSettings);
      }
      this.setInternalDesignSettings(tempSettings);
      if(tempSettings) {this.loadingSettings.emit(!this.loadingDeckingSettings);}
    })).subscribe();

    this.saveSignalSubscription = this.deckingDesignSettingsService.saveSignal$.pipe(skip(1)).subscribe((projectId: string) => {
      if (this.isTemplate) {
        this.deckingDesignService.updateDesignTemplateSettings(this.internalDeckingSettings, this.customName);
        return;
      }

      if (projectId && this.deckingDocumentExists) {
        const isCalculationRequired = this.isCalculationRequired();
        const isRelevantLoadsDifferent = this.internalDeckingSettings.relevantLoads.id !== this.originalDeckingSettings.relevantLoads.id;
        this.deckingDesignService.updateDesignSettings(this.internalDeckingSettings, projectId, this.customName, isCalculationRequired, isRelevantLoadsDifferent);
      } else if (projectId) {
        this.createNewDesignFromSettings(projectId, this.internalDeckingSettings);
      }
    });
  }

  substitutionSettingsSubscription(): void {
    this.isDesignMode = false;
    this.zoneInputCheckboxesItems = this.originalZoneInputCheckboxesItems;
    this.currentSubstitutionSubscription = this.deckingSubstitutionService.currentSettings$.pipe(mergeMap(async (settings: SubstitutionSettings) => {
        if (settings && (settings.requiredUpliftSubmittal?.value ?? null) === null) {
          settings.requiredUpliftSubmittal = { value: true };
        }

        // cloning decking substitution settings as an internal state for the current component
        this.originalDeckingSettings = settings;
        let tempSettings = settings;

        if (!(settings && this.deckingDocumentExists)) {
          // new custom settings for substitution
          await this.deckingCodeListService.init();
          tempSettings = this.internalDeckingSettings ? this.internalDeckingSettings : this.deckingDefaultFactoryService.buildDefaultDeckingDesign(null).settings;
          this.loadingSettings.emit(!this.loadingDeckingSettings);
        }
        this.setInternalDesignSettings(tempSettings);
        if(tempSettings) {this.loadingSettings.emit(!this.loadingDeckingSettings);}
      })
    ).subscribe();

    this.saveSignalSubscription = this.deckingDesignSettingsService.saveSignal$.pipe(skip(1)).subscribe((projectId: string) => {
      if (this.isTemplate) {
        this.deckingSubstitutionService.updateDesignTemplateSettings(this.internalDeckingSettings, this.customName);
        return;
      }

      if (projectId && this.deckingDocumentExists) {
        const isCalculationRequired = this.isCalculationRequired();
        const isRelevantLoadsDifferent = this.internalDeckingSettings.relevantLoads.id !== this.originalDeckingSettings.relevantLoads.id;
        this.deckingSubstitutionService.updateSubstitutionSettings(this.internalDeckingSettings, projectId, this.customName, isCalculationRequired, isRelevantLoadsDifferent);
      } else if (projectId) {
        this.createNewDesignFromSettings(projectId, this.internalDeckingSettings);
      }
    });
  }

  public async createNewDesignFromSettings(projectId: string, deckingDesignSettings: DesignSettings) {
    let deckingDesign: BaseDesign;

    deckingDesignSettings.region = {
      id: this.selectedRegion.id,
      index: ((): number => {
        return this.userSettingsService.getSelectedRegionIndex(this.selectedRegion.countryCode);
      })(),
      value: this.selectedRegion.countryCode.toUpperCase()
    };

    const newName = this.customName ?? null;
    if(this.modeSelected == DeckingDesignModeType.DesignMode) {
      deckingDesign = await this.deckingDesignService.createNewDesignWithSettings(projectId, deckingDesignSettings, newName);
    } else if(this.modeSelected == DeckingDesignModeType.SubstitutionBuilderMode) {
      deckingDesign = await this.deckingSubstitutionService.createNewSubstitutionWithSettings(projectId, deckingDesignSettings, newName);
    } else {
      this.loggerService.logServiceError(`Invalid Project Type : ${this.deckingMainService.getSelectedModeType()}`, 'DeckingDiaphragmDesignSettingsComponent', 'createNewDesignFromSettings');
      return;
    }
    this.deckingDesignService.launchedFromDashboard = true;
    await this.routingService.navigateToUrl(DeckingUrlPath.mainDecking + deckingDesign.id);

      //create template
      if (this.userSettingsService.isCreateTemplate) {
          this.designCalculated = this.deckingDesignSignalrService.designCalculated$.subscribe((deckingDesign) => {
              if (deckingDesign.id) {
                  this.createNewTemplate(deckingDesign.id, projectId);
              }
          });
      }
  }

     async createNewTemplate(templateId: string, projectId: string): Promise<void> {
        //get decking design
        const design = this.deckingDesignService.getCurrentDesign();

        //create template in decking design service
        const newTemplate = await this.deckingDesignService.createDesignTemplate(templateId, true, design.name, projectId);
        const designTemplateDocument = this.getDesignTemplateDocument(newTemplate.id);
        designTemplateDocument.templateName = design.name;
        designTemplateDocument.templateFolderId = this.userSettingsService.templateFolderId;

        //create template in document service
         await this.designTemplate.create(designTemplateDocument, design.documentId).then((templateId) => {
             newTemplate.documentId = templateId;
             this.deckingDesignService.setDesign(newTemplate);
             this.userSettingsService.setIsCreateTemplate(false);
             this.designCalculated.unsubscribe();
         });
    }

    getDesignTemplateDocument(id: string): IDesignTemplateDocument {
        return {
            designTypeId: DesignTypeId.DiaphragmDesign,
            designStandardId: 0,
            regionId: this.getRegionByCountryCode(this.userSettingsService.deckingSettings.region.countryCode).id,
            anchorName: undefined,
            approvalNumber: '',
            projectDesign: `{ "designId": "${id}" }`
        };
    }

    getRegionByCountryCode(countryCode: string) {
        const regionCodeList = this.codeListCommon.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(region => region.countryCode == countryCode);
    }

  onLengthInputChanged() {
    this.ConnectorsUnitFromLength = this.internalDeckingSettings.length.id;
  }

  onSidelapConnectorToInputChanged(): void {
    if (this.internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingIncrement.value > this.internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingTo.value) {
      this.internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingIncrement.value = this.internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingTo.value;
    }
  }

  ngOnDestroy(): void {
    this.currentDesignSubscription?.unsubscribe();
    this.currentSubstitutionSubscription?.unsubscribe();
    this.saveSignalSubscription.unsubscribe();
  }

  onDesignSettingInputChanged(selectedItem: number,
    settingToUpdate: 'area' | 'moment' | 'forcePerLength' | 'momentPerLength' | 'shearStiffness' | 'force' | 'stress' | 'length') {
    const selectedItemValue = this.userSettingsService.deckingSettingsCollection[settingToUpdate].find(item => item.index === selectedItem);
    if (selectedItemValue && settingToUpdate in this.internalDeckingSettings) {
      this.internalDeckingSettings[settingToUpdate] = {
        id: selectedItemValue.unitValue,
        value: selectedItemValue.value,
        index: selectedItemValue.index
      };
    } else {
      console.error(`something went wrong while updating ${settingToUpdate}`);
    }
  }

  getDesignMethod(selectedItem: number) {
    const selectedItemValue = this.designMethodItems.find(item => item.value === selectedItem);
    this.internalDeckingSettings.designMethod = {
      id: DesignMethodConverter.getDesignMethodFromString(selectedItemValue.text),
      index: selectedItemValue.value,
      value: selectedItemValue.text
    };
  }
  getDesignStandard(selectedItem: number) {
    const selectedItemValue = this.designStandardItems.find(item => item.value === selectedItem);
    this.internalDeckingSettings.designStandard = {
      id: DesignStandardConverter.getDesignStandardFromString(selectedItemValue.text),
      index: selectedItemValue.value,
      value: selectedItemValue.text
    };
  }

  getRelevantLoads(selectedItem: number) {
    const selectedItemValue = this.relevantLoadItems.find(item => item.value === selectedItem);
    this.internalDeckingSettings.relevantLoads = {
      id: RelevantLoadsConverter.getRelevantLoadFromString(selectedItemValue.value),
      index: selectedItemValue.value,
      value: selectedItemValue.text
    };
  }

  getDefinitionOfSidelapConnectors(selectedItem: number) {
    const selectedItemValue = this.sidelapConnectorItems.find(item => item.value === selectedItem);
    this.internalDeckingSettings.definitionOfSidelapConnectors = {
      id: selectedItemValue.value,
      value: selectedItemValue.text,
      index: selectedItemValue.value
    };

    let documentId = false;
    if (this.deckingMainService.getSelectedModeType() === DeckingDesignModeType.DesignMode) {
      documentId = !!this.deckingDesignService.documentId;
    } else if (this.deckingMainService.getSelectedModeType() === DeckingDesignModeType. SubstitutionBuilderMode) {
      documentId = !!this. deckingSubstitutionService.documentId;
    } else {
      this.loggerService.logServiceError(`Invalid design Type : ${this.deckingMainService.getSelectedModeType()}`, 'DeckingDiaphragmDesignSettingsComponent', 'getDefinitionOfSidelapConnectors');
    }

    this.isSidelapConnectorChanged =
            documentId && this.internalDeckingSettings.definitionOfSidelapConnectors.index !== this.originalDeckingSettings.definitionOfSidelapConnectors.index;

  }

  onZoneInputCheckboxesChanged(val: Set<number>): void {
    for (const key in this.zoneInputMapping) {
      if (key in this.internalDeckingSettings) {
        // TYPESCRIPT UPDATE: remove "as any" and fix type issue
        (this.internalDeckingSettings as any)[key].value = val.has((this.zoneInputMapping as any)[key]);
      }
    }
  }

  onSubstitutionZoneInputCheckboxesChanged(val: Set<number>): void {
    for (const key in this.substitutionZoneInputMapping) {
      if (key in this.internalDeckingSettings) {
        // TYPESCRIPT UPDATE: remove "as any" and fix type issue
        (this.internalDeckingSettings as any)[key].value = val.has((this.substitutionZoneInputMapping as any)[key]);
      }
    }
  }

  loadZoneInputCheckboxes(): void {
    this.zoneInputCheckboxesCheckedItems.clear();
    for (const key in this.zoneInputMapping) {
      // TYPESCRIPT UPDATE: remove "as any" and fix type issue
      const designSetting = !!(this.internalDeckingSettings as any)[key]?.value;
      if (designSetting) {
        this.zoneInputCheckboxesCheckedItems.add((this.zoneInputMapping as any)[key]);
      }
    }
  }

  substitutionLoadZoneInputCheckboxes(): void {
    this.substitutionZoneInputCheckboxesCheckedItems.clear();
    for (const key in this.substitutionZoneInputMapping) {
      // TYPESCRIPT UPDATE: remove "as any" and fix type issue
      const designSetting = !!(this.internalDeckingSettings as any)[key]?.value;
      if (designSetting) {
        this.substitutionZoneInputCheckboxesCheckedItems.add((this.substitutionZoneInputMapping as any)[key]);
      }
    }
  }

  measureUnitsUpdated(isImperial: boolean, hasRegionChanged = false): void {
   
    const tempSettings = isImperial 
    ? cloneDeep(this.userSettingsService.deckingSettingsCollection.regionDefault.filter(e => e.region.index==Region.US)[0])
    : cloneDeep(this.userSettingsService.deckingSettingsCollection.regionDefault.filter(e => e.region.index==Region.Canada)[0]);

    if (tempSettings) {
      const tempDeckingSettings = this.deckingDefaultFactoryService.buildDeckingDesignFromGeneralSettings(null, tempSettings).settings;
      tempDeckingSettings.region = cloneDeep(this.internalDeckingSettings.region);
      tempDeckingSettings.requiredShearStiffness = cloneDeep(this.internalDeckingSettings.requiredShearStiffness);
      tempDeckingSettings.requiredUpliftSubmittal = cloneDeep(this.internalDeckingSettings.requiredUpliftSubmittal);
      tempDeckingSettings.substitutionRequiredShearStiffness = cloneDeep(this.internalDeckingSettings.substitutionRequiredShearStiffness);
      tempDeckingSettings.substitutionRequiredUpliftSubmittal = cloneDeep(this.internalDeckingSettings.substitutionRequiredUpliftSubmittal);
      tempDeckingSettings.substitutionWindAndSeismicLoadsAtZoneLevel = cloneDeep(this.internalDeckingSettings.substitutionWindAndSeismicLoadsAtZoneLevel);
      tempDeckingSettings.calculateIntegration = cloneDeep(this.internalDeckingSettings.calculateIntegration);
      tempDeckingSettings.windAndSeismicLoadsAtZoneLevel = cloneDeep(this.internalDeckingSettings.windAndSeismicLoadsAtZoneLevel);
      tempDeckingSettings.relevantLoads = cloneDeep(this.internalDeckingSettings.relevantLoads);
      if (!hasRegionChanged) {
        tempDeckingSettings.designMethod = cloneDeep(this.internalDeckingSettings.designMethod);
        tempDeckingSettings.designStandard = cloneDeep(this.internalDeckingSettings.designStandard);
      }
      tempDeckingSettings.isImperial = { value: isImperial };
      this.setInternalDesignSettings(tempDeckingSettings);
    }
  }

  resetUnits(): void {
    if (typeof (this.isImperial) !== 'undefined') {
      this.measureUnitsUpdated(this.isImperial);
    }
  }

  public wasteFactorSelectedValueChange(selectedValue: number): void {
    if (this.internalDeckingSettings.wasteFactor) {
      this.internalDeckingSettings.wasteFactor.value = selectedValue;
    }
  }

  private isCalculationRequired(): boolean {
    const isDesignMethodDifferent = this.internalDeckingSettings.designMethod.id !== this.originalDeckingSettings.designMethod.id;
    const isDesignStandardDifferent = this.internalDeckingSettings.designStandard.id !== this.originalDeckingSettings.designStandard.id;
    const isRelevantLoadsDifferent = this.internalDeckingSettings.relevantLoads.id !== this.originalDeckingSettings.relevantLoads.id;
    const isDefinitionOfSidelapConnectorsDifferent = this.internalDeckingSettings.definitionOfSidelapConnectors.id !== this.originalDeckingSettings.definitionOfSidelapConnectors.id;
    const isSidelapSpacingFromDifferent = this.internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingFrom.value !== this.originalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingFrom.value;
    const isSidelapSpacingIncrementDifferent = this.internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingIncrement.value !== this.originalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingIncrement.value;
    const isSidelapSpacingToDifferent = this.internalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingTo.value !== this.originalDeckingSettings.sidelapsSpacingSettings.sidelapSpacingTo.value;
    const isSidelapNumberFromDifferent = this.internalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsFrom.value !== this.originalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsFrom.value;
    const isSidelapNumberIncrementDifferent = this.internalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsIncrement.value !== this.originalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsIncrement.value;
    const isSidelapNumberToDifferent = this.internalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsTo.value !== this.originalDeckingSettings.sidelapsNumberSettings.numberOfSidelapsTo.value;
    const isWasteFactorDifferent = this.isSubstitutionEnabled && (!this.internalDeckingSettings.wasteFactor || this.internalDeckingSettings.wasteFactor.value !== this.originalDeckingSettings.wasteFactor.value);

    return isDesignMethodDifferent ||isDesignStandardDifferent||
      isRelevantLoadsDifferent ||
      isDefinitionOfSidelapConnectorsDifferent ||
      isSidelapSpacingFromDifferent ||
      isSidelapSpacingIncrementDifferent ||
      isSidelapSpacingToDifferent ||
      isSidelapNumberFromDifferent ||
      isSidelapNumberIncrementDifferent ||
      isSidelapNumberToDifferent ||
      isWasteFactorDifferent;
  }

  modeSelectedValueChange(event: Event){
    const customEvent: CustomEvent = event as CustomEvent;
    this.modeSelected = customEvent.detail;
    if (this.modeSelected === DeckingDesignModeType.DesignMode) {
      this.isDesignMode = true;
    } else {
      this.isDesignMode = false;
    }
    this.deckingMainService.setSelectedModeType(this.modeSelected);
  }

    showSubstitutionConfiguration(): boolean {
        if (this.hideSubstitutionDesignSetting) {
            return false;
        }
        return !this.isDesignMode || (this.isnewCustomDesignOpen && this.isSubstitutionEnabled);
    }
}
