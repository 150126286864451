import * as React from 'react';

import {
    IItemButtonProps
} from '@profis-engineering/pe-ui-common/entities/main-menu/item-button-props';

import {
    getMenuControlImageReactData, IMenuControlImageData
} from '../../../../helpers/react-helpers';
import { ControlHeader } from '../../ControlHeader';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';

export class ItemButton extends React.PureComponent<IItemButtonProps> {
    constructor(props?: IItemButtonProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const buttonId = `${this.props.controlId}-item-button`;
        const tooltip = this.props.title != null && this.props.title != ''
            ? null
            : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);

        let imageData: IMenuControlImageData | undefined = undefined;
        let useResponsiveStyle = false;
        if (this.props.sprite != null) {
            imageData = getMenuControlImageReactData(
                this.props.sprite,
                this.props.spriteStyle?.elementStyle,
                this.props.spriteStyle?.afterElementStyle
            );
            useResponsiveStyle = this.props.spriteStyle?.afterElementStyle != null;
        }

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        return (
            <div data-control-id={this.props.controlId} className={`react-item-button-control control ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className='control-container'>
                    <button
                        data-control-id={buttonId}
                        className='item-button button-rich'
                        type='button'
                        disabled={this.props.disabled}
                        onClick={this.onClick}
                        data-tip={tooltip}
                        data-html={tooltip != null ? true : null}>
                        <div className='wrapper button-rich-content'>
                            <div className='image-container button-rich-image'>
                                <div className='image-wrapper'>
                                    <span className={`image sprite sprite-responsive ${this.props.sprite ?? ''}`} style={imageData?.elementStyle}>
                                        {
                                            useResponsiveStyle
                                                ? <span className='sprite-responsive-after' style={imageData.elementAfterStyle}></span>
                                                : null
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className='button-rich-seperator' />
                            <div className='content-wrapper button-rich-text'>
                                <p className='name' data-tip={this.props.name}>{this.props.name}</p>
                            </div>
                            <span className='sprite sprite-arrow-right button-rich-caret'></span>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    private onClick(_event: React.MouseEvent) {
        if (this.props.clicked != null) {
            this.props.clicked();
        }
    }
}
