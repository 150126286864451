import { ContolsStyleSheets, IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import styles from './react-information-panel.css';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { getReactCSSProperties } from '../../../helpers/main-menu-helpers/react-helpers';
import { getSpriteAsIconStyleResponsive } from '../../../sprites';
import { PureComponent } from '../react-import';

let sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

const ControlHeader = (window as any).pe.reactComponents.ControlHeader;

export interface IInformationPanelProps extends IControlProps {
    value?: number;
    panelText?: string;
    panelImage?: string;
    imageStyle?: IIconStyle;
}
class _InformationPanel extends PureComponent<IInformationPanelProps> {

    public static styleSheets = [sheet];
    private anchorPhotoImageData: React.CSSProperties | undefined;
    constructor(props: IInformationPanelProps) {
        super(props);
        if (this.props.panelImage != undefined)
            this.anchorPhotoImageData = getReactCSSProperties(getSpriteAsIconStyleResponsive(this.props.panelImage));
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const panelId = `${this.props.controlId}-panel`;

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;
        const information = this.props.panelText;
        const panelImage = this.props.panelImage;

        return (
            <div data-control-id={this.props.controlId} className={`react-information-panel control ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={panelId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className='control-container'>
                    <div className={`image-container`}>
                        <span className={`image sprite ${panelImage}`} style={this.anchorPhotoImageData}></span>
                    </div>
                    <div className='information-container'>
                        <span>
                            {information}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export const InformationPanel: typeof _InformationPanel & ContolsStyleSheets = _InformationPanel;