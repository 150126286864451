

import { Injectable, Injector, StaticProvider, Type } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    IConfirmChangeProps
} from '@profis-engineering/pe-ui-common/components/confirm-change/confirm-change.common';
import {
    IAddEditDesignComponentFromModuleInput
} from '@profis-engineering/pe-ui-common/entities/add-edit-design-component';
import { IApplicationError } from '@profis-engineering/pe-ui-common/entities/application-error';
import {
    IDesignSectionExportComponentInput
} from '@profis-engineering/pe-ui-common/entities/design-section-export';
import { IModalOpened, ModalOptions } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ModalServiceBase } from '@profis-engineering/pe-ui-common/services/modal.common';

import { ChangedConvertedProperties } from './design.service';
import { LocalizationService } from './localization.service';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private baseService!: ModalServiceBase;

    constructor(
        private readonly localizationService: LocalizationService,
        private readonly injector: Injector,
        private readonly ngbModal: NgbModal,
    ) { }

    public setBaseService(baseService: ModalServiceBase): void {
        this.baseService = baseService;
    }

    public openSpecificationText(specificationText: string, showPerfectSpec?: boolean) {
        this.baseService.openSpecificationText(specificationText, showPerfectSpec);
    }

    /**
     * @deprecated Use openComponentModal instead.
     */
    public openWebComponentModal(componentName: string, options?: ModalOptions, inputValues?: object): IModalOpened {
        return this.baseService.openWebComponentModal(componentName, options, inputValues);
    }

    public openComponentModal(component: Type<unknown>, options?: ModalOptions, providers?: StaticProvider[]): NgbModalRef {
        const modalInstanceInjector = Injector.create({
            providers: providers ?? [],
            parent: this.injector
        });

        options = options ?? {};

        const modalOpts: NgbModalOptions = {
            animation: (options.noAnimation !== true),
            size: options.size,
            windowClass: options.windowClass ?? 'default-modal generic-modal',
            backdrop: 'static',
            injector: modalInstanceInjector
        };

        const modal = this.ngbModal.open(component, modalOpts);

        // Supress 'Error: Uncaught (in promise)'
        modal.result
            .then(null, () => {
                return;
            })
            .catch(() => {
                return;
            });

        return modal;
    }

    public openConfirmChange(props?: IConfirmChangeProps): IModalOpened {
        return this.baseService.openConfirmChange(props);
    }

    public openAlertWarning(title: string, message: string): IModalOpened {
        return this.baseService.openAlertWarning(title, message);
    }

    public openAlertError(title: string, message: string, applicationError?: IApplicationError): IModalOpened {
        return this.baseService.openAlertError(title, message, applicationError);
    }

    public openDesignSectionExport(props?: IDesignSectionExportComponentInput): void {
        this.baseService.openDesignSectionExport(props);
    }

    public openAlertGLError(): IModalOpened {
        return this.baseService.openAlertGLError();
    }

    public openVirtualTourPopup(selectTab?: (tab: string) => void): void {
        this.baseService.openVirtualTourPopup(selectTab);
    }

    public openAddEditDesignFromModule(props?: IAddEditDesignComponentFromModuleInput): IModalOpened {
        return this.baseService.openAddEditDesignFromModule(props);
    }

    public openSaveAsTemplate(props?: object): void {
        this.baseService.openSaveAsTemplate(props);
    }

    public openGeneralNotes(text: string, copyText: string): void {
        this.baseService.openGeneralNotes(text, copyText);
    }

    public openSupport(applicationError?: IApplicationError, projectDesign?: object): void {
        this.baseService.openSupport(applicationError, projectDesign);
    }

    public openExportReport(props?: object): void {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        this.openWebComponentModal('sp-export-report', { size: 'lg' }, props);
    }

    public async openConfirmDialog(id: string, message: string): Promise<boolean> {
        let result = false;
        return await this.openConfirmChange({
            id: id,
            title: this.localizationService.getString('SP.General.Warning'),
            message: message,
            confirmButtonText: this.localizationService.getString('SP.General.OK'),
            cancelButtonText: this.localizationService.getString('SP.General.Cancel'),
            onConfirm: (modal) => {
                modal.close();
                result = true;
            },
            onCancel: (modal) => {
                modal.close();
            }
        }).closed.then(() => result);
    }

    public showConvertWarningDialog(changedProperties: ChangedConvertedProperties[]): void {
        if (changedProperties === undefined || changedProperties.length == 0) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-deprecated
        this.openWebComponentModal('sp-design-verification-changes', undefined, { changedProperties: changedProperties });
    }

    public showRequiresConfirmationDialog(warnings: string): void {
        if (warnings === '') {
            return;
        }

        this.openConfirmChange({
            id: 'sp-design-convert-dialog-warnings',
            title: this.localizationService.getString('SP.General.Warning'),
            message: warnings,
            confirmButtonText: this.localizationService.getString('SP.General.OK'),
            onConfirm: modal => modal.close(),
        });
    }

    public openUnsupportedDesignModal(message: string): void {
        this.openConfirmChange({
            id: 'sp-unsupported-design-dialog',
            title: this.localizationService.getString('SP.UnsupportedDesign.Dialog.Title'),
            message: this.localizationService.getString(message),
            confirmButtonText: this.localizationService.getString('SP.General.OK'),
            onConfirm: modal => modal.close(),
        });
    }
}
