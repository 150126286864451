export class BaseInput {
  camera;
  enabled;
  checkInputs;
  renderNextFrame;
  eventNotifier;
  constructor(eventNotifier) {
    this.eventNotifier = eventNotifier;
  }
}
