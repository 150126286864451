import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewEncapsulation } from '@angular/core';
import openingImage from '../../../images/opening-image.png' with { loader: 'file' };
import { InfoDialogBaseComponent } from '../info-dialog-base.component';
import { imageUrl } from '../../../image';
import { L10nDirective } from '../../../directives/l10n.directive';

@Component({
    templateUrl: './info-dialog-punch-using-opening.component.html',
    styleUrls: ['../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [L10nDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InfoDialogPunchOpeningComponent extends InfoDialogBaseComponent {
    public openingImage = imageUrl(openingImage);

    public getTitle(): string {
        return this.translate('SP.General.Warning');
    }
}
