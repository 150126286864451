export class CacheItem {
  items = {};
  create(name, create) {
    let item = this.items[name];
    if (item === undefined) {
      const value = create(name);
      item = this.items[name] = value;
    }
    return item;
  }
  get(name) {
    return this.items[name];
  }
  has(name) {
    return this.get(name) !== undefined;
  }
  clear(...names) {
    if (names == null || names.length == 0) {
      names = Object.keys(this.items);
    }
    for (const name of names) {
      this.items[name] = undefined;
    }
  }
  dispose() {
    for (const item of Object.values(this.items)) {
      if (item != null && item.dispose != null && typeof item.dispose == 'function') {
        try {
          item.dispose();
        } catch (error) {
          console.error(error);
        }
      }
    }
    this.items = {};
  }
}
