export class DefaultEventNotifier {
  webGLContextLost() {
    // not needed
  }
  webGLContextRestored() {
    // not needed
  }
  zoomChange() {
    // not needed
  }
  fontsLoaded() {
    // not needed
  }
  componentSelected() {
    // not needed
  }
  registerCurrentStateUndoRedoAction() {
    // not needed
  }
  positionsChanged2d() {
    // not needed
  }
  draggingSelectionChanged2d() {
    // not needed
  }
}
