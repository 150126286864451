import {
    Component, ElementRef, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import {
    IModalGridComponentInput, IModalGridItem
} from '@profis-engineering/pe-ui-common/entities/modal-grid';
import {
    UnitGroup, UnitType as Unit
} from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ModalInstance, ModalOptions, MODAL_DISMISS_REASON_BACKDROP, MODAL_DISMISS_REASON_ESC } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import {
    UIProperty
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    AnchorFilterType,
    DesignStandard
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';
import { DropdownItem, MultiSelectDropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { Options } from '@angular-slider/ngx-slider';
import { SliderType } from '@profis-engineering/pe-ui-common/components/slider/slider.common';
import { SmartAnchorOrderService } from '../../services/smart-anchor-order.service';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { IAdvancedInputsPopupComponentInput } from '../../../shared/components/advanced-inputs-popup';

export enum ReinforcementTensionOption {
    Option1,
    Option2
}

interface EdgeReinforcementOption{
    id: string;
    value: number;
    name: string;
}

@Component({
    templateUrl: './advanced-inputs.component.html',
    styleUrls: ['./advanced-inputs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AdvancedInputsComponent implements OnInit, OnChanges {
    @Input()
    public modalInstance!: ModalInstance<IAdvancedInputsPopupComponentInput>;

    public shortTermTemperatue!: number;
    public longTermTemperatue!: number;
    public galvanizedSteel!: boolean;
    public sheradized!: boolean;
    public mechanicallyGalvanized!: boolean;
    public stainlessSteel!: boolean;
    public corrosionResistant!: boolean;
    public maxUtilization!: number;
    public drillingMethod!: number;
    public holeType!: number;
    public concreteType!: number;
    public edgeType!: number;
    public reinforcementControlCracking!: boolean;
    public standOffType!: number;
    public standOffDistance!: number;
    public standOffRestraint!: number;
    public standOffCompresiveStrenght!: number;
    public profilePosition!: number;
    public anchorPosition!: number;
    public baseplateThickness!: number;
    public optimizeType!: boolean;
    public concreteLambda!: number;
    public edgeReinforcement!: number;
    public reinforcementTensionCondition!: number;
    public reinforcementShearCondition!: number;
    public lightweightConcrete!: boolean;

    public drillingMethodOpen = false;
    public holeTypeOpen = false;
    public concreteTypeOpen = false;
    public edgeTypeOpen = false;
    public constructionMaterialOptions: DropdownItem<AnchorFilterType>[] = [];
    public constructionMaterialOptionsDdl: MultiSelectDropdownProps<AnchorFilterType> = {
        id: 'construction-design-options',
        items: [],
        selectedValues: [],
    };
    public utilizationOptions!: Options;
    public slider: SliderType = SliderType.Single;
    public filterMap = new Map<UIProperty, AnchorFilterType>([
        [UIProperty.ConstructionOptions_GalvanizedSteel, AnchorFilterType.SteelGalvanized],
        [UIProperty.ConstructionOptions_CorrosionResistant, AnchorFilterType.HighCorrosionResistantSteel],
        [UIProperty.ConstructionOptions_Sheradized, AnchorFilterType.SheradizedHotDipGalvanized],
        [UIProperty.ConstructionOptions_MechanicallyGalvanized, AnchorFilterType.SheradizedHotDipMechanicallyGalvanized],
        [UIProperty.ConstructionOptions_StainlessSteel, AnchorFilterType.StainlessSteel]
    ]);
    public temperatureUnit?: Unit;
    public lengthUnit?: Unit;
    public compressiveStrengthUnit?: Unit;

    @ViewChild('sliderText', { static: false }) sliderText!: ElementRef;
    standOffCompresiveStrenghtVisible = true;
    public edgeReinforcementOptions!: EdgeReinforcementOption[];
    public reinforcementTensionOption = ReinforcementTensionOption;

    constructor(
        private userService: UserService,
        private unitService: UnitService,
        private modalService: ModalService,
        private localizationService: LocalizationService,
        private calculationService: CalculationServicePE,
        private elementRef: ElementRef<HTMLElement>,
        private smartAnchorOrderService: SmartAnchorOrderService
    ) { }

    public get isAsadAndACI() {
        const isAsadVisible = this.userService.design.model[UIProperty.SmartAnchor_Enabled] as boolean;
        const isACIStandard = this.userService.design.designStandard.id == DesignStandard.ACI;
        return isAsadVisible && isACIStandard;
    }

    public get isACI() {
        return this.userService.design.designStandard.id == DesignStandard.ACI
            || this.userService.design.designStandard.id == DesignStandard.TW401
            || this.userService.design.designStandard.id == DesignStandard.KR
            || this.userService.design.designStandard.id == DesignStandard.TH
            || this.userService.design.designStandard.id == DesignStandard.CSA;
    }


    ngOnInit(): void {
        this.modalInstance.setOnClosing((result) => {
            // Dismissal prevented, e.g. when opened from Virtual Tour
            const preventDismiss = this.modalInstance.input?.preventDismiss ?? false;
            if (preventDismiss && (result == MODAL_DISMISS_REASON_BACKDROP || result == MODAL_DISMISS_REASON_ESC)) {
                return false;
            }

            return true;
        });

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-standoff-none',
            'sprite-standoff',
            'sprite-standoff-clamping',
            'sprite-standoff-grouting',
            'sprite-arrow-right'
        );

        this.shortTermTemperatue = this.userService.design.model[UIProperty.BaseMaterial_TemperatureShortTerm] as number;
        this.longTermTemperatue = this.userService.design.model[UIProperty.BaseMaterial_TemperatureLongTerm] as number;
        this.galvanizedSteel = this.userService.design.model[UIProperty.ConstructionOptions_GalvanizedSteel] as boolean;
        this.sheradized = this.userService.design.model[UIProperty.ConstructionOptions_Sheradized] as boolean;
        this.mechanicallyGalvanized = this.userService.design.model[UIProperty.ConstructionOptions_MechanicallyGalvanized] as boolean;
        this.stainlessSteel = this.userService.design.model[UIProperty.ConstructionOptions_StainlessSteel] as boolean;
        this.corrosionResistant = this.userService.design.model[UIProperty.ConstructionOptions_CorrosionResistant] as boolean;
        this.maxUtilization = this.userService.design.model[UIProperty.SmartAnchor_OptimizationOptions_UtilizationSlider] == 0 ? 100 : this.userService.design.model[UIProperty.SmartAnchor_OptimizationOptions_UtilizationSlider] as number;
        this.drillingMethod = this.userService.design.model[UIProperty.BaseMaterial_DrillingMethod] as number;
        this.holeType = this.userService.design.model[UIProperty.BaseMaterial_HoleType] as number;
        this.concreteType = this.userService.design.model[UIProperty.SupplementaryReinforcement_ConcreteReinforcement] as number;
        this.edgeType = this.userService.design.model[UIProperty.SupplementaryReinforcement_EdgeReinforcement] as number;
        this.reinforcementControlCracking = this.userService.design.model[UIProperty.SupplementaryReinforcement_IsSplittingReinforcement] as boolean;
        this.standOffType = this.userService.design.model[UIProperty.AnchorPlate_StandoffType] as number;
        this.standOffDistance = this.userService.design.model[UIProperty.AnchorPlate_StandoffDistance] as number;
        this.standOffRestraint = this.userService.design.model[UIProperty.AnchorPlate_StandoffRestraintLevel] as number;
        this.standOffCompresiveStrenght = this.userService.design.model[UIProperty.AnchorPlate_CustomGroutCompressiveStrength] as number;
        this.standOffCompresiveStrenghtVisible = !this.userService.design.properties.get(PropertyMetaData.AnchorPlate_CustomGroutCompressiveStrength_Asad.id).hidden;
        this.profilePosition = this.userService.design.model[UIProperty.SmartAnchor_OptimizationOptions_ProfilePosition] == 0 ? 1 : this.userService.design.model[UIProperty.SmartAnchor_OptimizationOptions_ProfilePosition] as number;
        this.anchorPosition = this.userService.design.model[UIProperty.SmartAnchor_OptimizationOptions_AnchorPosition] == 0 ? 2 : this.userService.design.model[UIProperty.SmartAnchor_OptimizationOptions_AnchorPosition] as number;
        this.baseplateThickness = this.userService.design.model[UIProperty.BaseplateThickness] as number;
        this.optimizeType = this.userService.design.model[UIProperty.OptimizeOnEmbedmentDepth] as boolean;
        if (this.isACI) {
            this.initEdgeReinforcementOptions();
            this.edgeReinforcement = this.userService.design.model[UIProperty.BaseMaterial_EdgeReinforcementHNA] as number;
            this.reinforcementTensionCondition = this.userService.design.model[UIProperty.BaseMaterial_ReinforcementConditionTension] as number;
            this.reinforcementShearCondition = this.userService.design.model[UIProperty.BaseMaterial_ReinforcementConditionShear] as number;
            this.lightweightConcrete = this.userService.design.model[UIProperty.BaseMaterial_IsLightweightConcrete] as boolean;
            this.concreteLambda = this.userService.design.model[UIProperty.BaseMaterial_LightweightConcreteLambdaA] as number;
        }

        this.setConstructionMaterialOptions();
        this.temperatureUnit = this.getDefaultUnit(UnitGroup.Temperature);
        this.lengthUnit = this.getDefaultUnit(UnitGroup.Length);
        this.compressiveStrengthUnit = this.getDefaultUnit(UnitGroup.Stress);
    }

    ngOnChanges() {
        this.utilizationOptions = {
            step: 1,
            showTicksValues: false,
            showSelectionBar: true,
            animate: false,
            animateOnMove: false,
            noSwitching: true,
            floor: 0,
            ceil: 100,
            translate: (value: number): string => {
                return value.toFixed(0) + '%';
            }
        };
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public setDrillingMethod(value: number) {
        this.drillingMethod = value;
        this.drillingMethodOpen = false;
    }

    public holeTypeButton() {
        this.holeTypeOpen = !this.holeTypeOpen;
    }

    public setHoleType(value: number) {
        this.holeType = value;
        this.holeTypeOpen = false;
    }

    public setConcreteType(value: number) {
        this.concreteType = value;
        this.concreteTypeOpen = false;
    }

    public setEdgeType(value: number) {
        this.edgeType = value;
        this.edgeTypeOpen = false;
    }

    public getDefaultUnit(unitGroup: UnitGroup) {
        return this.unitService.getDefaultUnit(unitGroup);
    }

    public async setLightWeight() {
        this.setModelValue(UIProperty.BaseMaterial_IsLightweightConcrete, this.lightweightConcrete);
        await this.calculationService.calculateAsync(this.userService.design).then(() => {
            this.concreteLambda = this.userService.design.model[UIProperty.BaseMaterial_LightweightConcreteLambdaA] as number;
        });
    }

    public async apply() {
        this.setModelValue(UIProperty.BaseMaterial_TemperatureShortTerm_Asad, this.shortTermTemperatue);
        this.setModelValue(UIProperty.BaseMaterial_TemperatureLongTerm_Asad, this.longTermTemperatue);
        this.setModelValue(UIProperty.BaseMaterial_DrillingMethod_Asad, this.drillingMethod);
        this.setModelValue(UIProperty.BaseMaterial_HoleType_Asad, this.holeType);
        this.setModelValue(UIProperty.SupplementaryReinforcement_ConcreteReinforcement_Asad, this.concreteType);
        this.setModelValue(UIProperty.SupplementaryReinforcement_EdgeReinforcement_Asad, this.edgeType);
        this.setModelValue(UIProperty.SupplementaryReinforcement_IsSplittingReinforcement_Asad, this.reinforcementControlCracking);
        this.setModelValue(UIProperty.AnchorPlate_StandoffType_Asad, this.standOffType);
        this.setModelValue(UIProperty.AnchorPlate_StandoffDistance_Asad, this.standOffDistance);
        this.setModelValue(UIProperty.AnchorPlate_StandoffRestraintLevel_Asad, this.standOffRestraint);
        this.setModelValue(UIProperty.AnchorPlate_CustomGroutCompressiveStrength_Asad, this.standOffCompresiveStrenght);
        this.setModelValue(UIProperty.SmartAnchor_OptimizationOptions_ProfilePosition, this.profilePosition);
        this.setModelValue(UIProperty.SmartAnchor_OptimizationOptions_AnchorPosition, this.anchorPosition);
        this.setModelValue(UIProperty.SmartAnchor_OptimizationOptions_UtilizationSlider, this.maxUtilization);
        this.setModelValue(UIProperty.BaseplateThickness, this.baseplateThickness);
        this.setModelValue(UIProperty.OptimizeOnEmbedmentDepth, this.optimizeType);

        if (this.isACI) {
            this.setModelValue(UIProperty.BaseMaterial_EdgeReinforcementHNA, this.edgeReinforcement);
            this.setModelValue(UIProperty.BaseMaterial_ReinforcementConditionShear, this.reinforcementShearCondition);
            this.setModelValue(UIProperty.BaseMaterial_ReinforcementConditionTension, this.reinforcementTensionCondition);
            this.setModelValue(UIProperty.BaseMaterial_IsLightweightConcrete, this.lightweightConcrete);
        }

        for (const [key, value] of this.filterMap) {
            this.updateAnchorType(value, key);
        }

        await this.calculationService.calculateAsync(this.userService.design);
    }

    public updateAnchorType(anchorType: AnchorFilterType, property: UIProperty) {
        if (this.constructionMaterialOptionsDdl.selectedValues?.find(x => {
            if (x == anchorType) {
                return true;
            }
            return false;
        })) {
            this.setModelValue(property, true);
        }
        else
            this.setModelValue(property, false);
    }

    public save() {
        this.apply();
        this.close();
    }

    public standOffTypeButton() {

        const item1: IModalGridItem<number> = { image: 'sprite-standoff-none', name: this.translate('Agito.Hilti.Profis3.CodeList.StandOffEntity.None'), value: 1 };
        const item2: IModalGridItem<number> = { image: 'sprite-standoff', name: this.translate('Agito.Hilti.Profis3.CodeList.StandOffEntity.WithoutClamping'), value: 2 };
        const item3: IModalGridItem<number> = { image: 'sprite-standoff-clamping', name: this.translate('Agito.Hilti.Profis3.CodeList.StandOffEntity.WithClamping'), value: 3 };
        const item4: IModalGridItem<number> = { image: 'sprite-standoff-grouting', name: this.translate('Agito.Hilti.Profis3.CodeList.StandOffEntity.WithGrouting'), value: 4 };
        const allItems: IModalGridItem<number>[] = [item1, item2, item3, item4];

        const modalProps: IModalGridComponentInput<IModalGridItem<number>> = {
            popupTitle: this.translate('Agito.Hilti.Profis3.Navigation.TabBasePlate.RegionStandOff.ControlStandoffType.Title'),
            items: allItems,
            selectedItem: allItems.find((item) => item.value == this.standOffType),
            onSelect: (item) => {
                this.standOffType = item.value ?? 0;
                this.standOffDistance = Number(this.userService.design.model[UIProperty.AnchorPlate_StandoffDistance] != null ? this.userService.design.model[UIProperty.AnchorPlate_StandoffDistance] : 30);
                this.standOffRestraint = Number(this.userService.design.model[UIProperty.AnchorPlate_StandoffRestraintLevel] != null ? this.userService.design.model[UIProperty.AnchorPlate_StandoffRestraintLevel] : 2);
                this.standOffCompresiveStrenght = Number(this.userService.design.model[UIProperty.AnchorPlate_CustomGroutCompressiveStrength] != null ? this.userService.design.model[UIProperty.AnchorPlate_CustomGroutCompressiveStrength] : 30);
            }
        };

        const modalOpts: ModalOptions = {};
        modalOpts.size = 'lg';

        this.modalService.openModalGrid(modalProps, modalOpts);
    }

    onIncrementValue() {
        const unitValue = this.unitService.parseUnknownUnitValue(this.concreteLambda.toString());
        unitValue.unit = Unit.None;

        const incrementValue = this.unitService.incDecValueByUnit(unitValue.unit);
        unitValue.value += incrementValue;

        this.setModelValue(UIProperty.BaseMaterial_LightweightConcreteLambdaA, unitValue.value);
        this.calculationService.calculateAsync(this.userService.design).finally(() => { this.concreteLambda = this.userService.design.model[UIProperty.BaseMaterial_LightweightConcreteLambdaA] as number; });
    }

    onDecrementValue() {
        const unitValue = this.unitService.parseUnknownUnitValue(this.concreteLambda.toString());
        unitValue.unit = Unit.None;

        const incrementValue = this.unitService.incDecValueByUnit(unitValue.unit);
        unitValue.value -= incrementValue;

        this.setModelValue(UIProperty.BaseMaterial_LightweightConcreteLambdaA, unitValue.value);
        this.calculationService.calculateAsync(this.userService.design).finally(() => { this.concreteLambda = this.userService.design.model[UIProperty.BaseMaterial_LightweightConcreteLambdaA] as number; });
    }

    public setModelValue(property: UIProperty, value: unknown) {
        if (value != null) {
            this.userService.design.model[property] = value;
        }
    }

    public constructionMaterialSelectionChange(e: AnchorFilterType[]) {
        this.constructionMaterialOptionsDdl.selectedValues = e;
    }

    public utilizationChange(e: number) {
        this.maxUtilization = e;
    }

    public getSelectedValues() {
        const select = new Array<AnchorFilterType>();

        for (const [key, value] of this.filterMap) {
            if (this.userService.design.model[key] as boolean)
                select.push(value);
        }

        return select;
    }

    public setConstructionMaterialOptions() {
        this.constructionMaterialOptionsDdl.items = this.smartAnchorOrderService.constructionOptions;
        this.constructionMaterialOptionsDdl.selectedValues = this.getSelectedValues();
        this.constructionMaterialOptionsDdl.title = this.translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.Type');
        this.constructionMaterialOptionsDdl.notSelectedText = this.translate('Agito.Hilti.Profis3.Select');
    }

    public getReinforcementTensionOption(reinforcementTensionOption: ReinforcementTensionOption) {
        switch (reinforcementTensionOption) {
            case ReinforcementTensionOption.Option1:
                if (this.userService.design.designStandard.id == DesignStandard.KR
                    || this.userService.design.designStandard.id == DesignStandard.CSA
                    || this.userService.design.designStandard.id == DesignStandard.TH) {
                    return this.translate('Agito.Hilti.Profis3.CodeList.ReinforcementCondition.A');
                }
                else
                    return this.translate('Agito.Hilti.Profis3.CodeList.ReinforcementTensionCondition.Present');
            case ReinforcementTensionOption.Option2:
                if (this.userService.design.designStandard.id == DesignStandard.KR
                    || this.userService.design.designStandard.id == DesignStandard.CSA
                    || this.userService.design.designStandard.id == DesignStandard.TH) {
                    return this.translate('Agito.Hilti.Profis3.CodeList.ReinforcementCondition.B');
                }
                else
                    return this.translate('Agito.Hilti.Profis3.CodeList.ReinforcementTensionCondition.NotPresent');
            default:
                return 'Undefined';
        }
    }

    initEdgeReinforcementOptions() {
        if (this.userService.design.designStandard.id == DesignStandard.KR) {
            this.edgeReinforcementOptions =
                [
                    {
                        id: 'Edge-Reinforcement-None',
                        value: 10,
                        name: this.translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.KR.None')
                    },
                    {
                        id: 'Edge-Reinforcement-Straight',
                        value: 11,
                        name: this.translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.KR.Straight')
                    },
                    {
                        id: 'Edge-Reinforcement-Straight-Close-Mesh',
                        value: 12,
                        name: this.translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.KR.StraightCloseMesh')
                    }
                ];
        }
        else if(this.userService.design.designStandard.id == DesignStandard.CSA)
        {
            this.edgeReinforcementOptions =
            [
                {
                    id: 'Edge-Reinforcement-None',
                    value: 7,
                    name: this.translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.CSA.None')
                },
                {
                    id: 'Edge-Reinforcement-Straight',
                    value: 8,
                    name: this.translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.CSA.Straight')
                },
                {
                    id: 'Edge-Reinforcement-Straight-Close-Mesh',
                    value: 9,
                    name: this.translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.CSA.StraightCloseMesh')
                }
            ];
        }
        else {
            this.edgeReinforcementOptions =
                [
                    {
                        id: 'Edge-Reinforcement-None',
                        value: 4,
                        name: this.translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.ACI.None')
                    },
                    {
                        id: 'Edge-Reinforcement-Straight',
                        value: 5,
                        name: this.translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.ACI.Straight')
                    },
                    {
                        id: 'Edge-Reinforcement-Straight-Close-Mesh',
                        value: 6,
                        name: this.translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.ACI.StraightCloseMesh')
                    }
                ];
        }
    }
}
