import { SpacingModeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class SpacingMode extends CodeList {

    public static fromService(codeList: SpacingModeEntityC2C) {
        return new SpacingMode(CodeList.baseFromService('SpacingModeEntity', codeList, {
            id: codeList.id,
            tooltipDisplayKey: codeList.tooltipTitleDisplayKey,
            tooltipDisabledDisplayKey: codeList.tooltipDisabledDisplayKey,
            tooltipTitleDisplayKey: codeList.tooltipTitleDisplayKey
        }));
    }

    public override cloneDeep(): SpacingMode {
        return super.cloneDeep() as SpacingMode;
    }
}
