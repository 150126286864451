import {
    ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    IAppSettingsConcreteComponent, IConcreteFixingUpdate
} from '@profis-engineering/pe-ui-common/entities/app-settings-concrete-component';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    AppSettingsNumericTextBoxProps, QuickStartType, SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import {
    format, formatKeyValue
} from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { IAdvancedCalculationInputs } from '../../../shared/entities/advanced-calculation-inputs';
import {
    ConcreteSafetyFactorGammaC
} from '../../../shared/entities/code-lists/concrete-safety-factor-gamma-C';
import { DesignMethodGroup } from '../../../shared/entities/code-lists/design-method-group';
import { DesignStandard } from '../../../shared/entities/code-lists/design-standard';
import { Region } from '../../../shared/entities/code-lists/region';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    DesignMethodGroup as DesignMethodGroupEnum, DesignStandard as DesignStandardEnum, DesignType,
    SteelGuideline as SteelCalculationMethod, SteelGuideline,
    ConcreteSafetyFactorGammaC as ConcreteSafetyFactorGammaCEnum
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    FactorType, IConcreteFixingData, reductionFactorSP
} from '../../../shared/helpers/app-settings-helper';
import { getGuidelineOnlyDescriptionText, isDesignStandardEurocodeBased } from '../../../shared/helpers/design-standard-helper';
import {
    findSteelCalculationMethod, getAllowedSteelGuidelines
} from '../../../shared/helpers/steel-guideline-helper';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { AppSettingsHelperWithUnit } from '../../helpers/app-settings-helper';
import { AbpService } from '../../services/abp.service';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    templateUrl: './app-settings-concrete-fixing.component.html',
    styleUrls: ['./app-settings-concrete-fixing.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AppSettingsConcreteFixingComponent implements IAppSettingsConcreteComponent<IConcreteFixingData>, OnChanges {
    @Input()
    public selectedRegionId!: number;

    @Input()
    public decimalSeparatorId!: number;

    @Input()
    public thousandsSeparatorId!: number;

    @Input()
    public submitted = false;

    @Input()
    public advancedBaseplateInputsData!: IAdvancedCalculationInputs;

    @Input()
    public settings: IConcreteFixingData = {
        concreteRegion: KnownRegion.Unknown,
        concreteEtagEnOnly: false
    };

    public get concreteFixing() {
        return this.settings;
    }

    @Output()
    public concreteFixingValueChange = new EventEmitter<IConcreteFixingUpdate>();



    public concreteRegionDropdown!: DropdownProps<number>;
    public concreteLengthDropdown!: DropdownProps<number>;
    public concreteAreaDropdown!: DropdownProps<number>;
    public concreteStressDropdown!: DropdownProps<number>;
    public concreteForceDropdown!: DropdownProps<number>;
    public concreteMomentDropdown!: DropdownProps<number>;
    public concreteTemperatureDropdown!: DropdownProps<number>;
    public concreteForcePerLengthDropdown!: DropdownProps<number>;
    public concreteMomentPerLengthDropdown!: DropdownProps<number>;
    public concreteDensityDropdown!: DropdownProps<number>;
    public concreteAnchorPlateFactor!: AppSettingsNumericTextBoxProps;
    public concreteSafetyFactorForPermanentLoads!: AppSettingsNumericTextBoxProps;
    public concreteSafetyFactorForVariableLoads!: AppSettingsNumericTextBoxProps;
    public concreteEdgeDistanceDisplay!: number;
    public concreteEtagEnOnly!: CheckboxButtonProps<boolean>;
    public concreteSTOSteelSafetyFactorGammaM!: AppSettingsNumericTextBoxProps;
    public concreteDesignStandardDropdown!: DropdownProps<number>;
    public concreteDesignMethodDropdown!: DropdownProps<number>;
    public concreteCalculationMethodDropdown!: DropdownProps<number>;
    public concreteSteelCalculationMethodDropdown!: DropdownProps<SteelCalculationMethod>;
    public concreteMinimumAnchorToProfileDistance!: AppSettingsNumericTextBoxProps;
    public concreteSteelSafetyFactorGammaM0!: AppSettingsNumericTextBoxProps;
    public concreteSteelSafetyFactorGammaM1!: AppSettingsNumericTextBoxProps;
    public concreteSteelSafetyFactorGammaM2!: AppSettingsNumericTextBoxProps;
    public concreteMaterialSafetyFactor!: AppSettingsNumericTextBoxProps;
    public concreteConcreteCapacityFactor!: AppSettingsNumericTextBoxProps;
    public concreteSteelCapacityFactor!: AppSettingsNumericTextBoxProps;
    public concreteWeldsCapacityFactor!: DropdownProps<number>;
    public concreteMinimumConcreteCover!: AppSettingsNumericTextBoxProps;
    public concreteInSteelSafetyFactorGammaM0!: AppSettingsNumericTextBoxProps;
    public concreteInSteelSafetyFactorGammaM1!: AppSettingsNumericTextBoxProps;
    public concreteInSteelSafetyFactorGammaMw!: AppSettingsNumericTextBoxProps;
    public concreteInSteelSafetyFactorGammaMb!: AppSettingsNumericTextBoxProps;
    public concreteSafetyFactorGammaC!: DropdownProps<number>;
    public materialSafetyFactor!: AppSettingsNumericTextBoxProps;
    public dataLoaded = false;

    private regionDefaultValues!: {
        materialSafetyFactor?: number;
    };

    private readonly appSettingsHelper!: AppSettingsHelperWithUnit;

    private selectedSteelGuideline!: SteelCalculationMethod;

    constructor(
        private readonly codeList: CodeListService,
        private readonly commonCodeList: CommonCodeListService,
        private readonly unit: UnitService,
        private readonly localization: LocalizationService,
        private readonly userSettings: UserSettingsService,
        private readonly abp: AbpService,
        private readonly numberService: NumberService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly sharedEnvironmentService: SharedEnvironmentService
    ) {
        this.appSettingsHelper = new AppSettingsHelperWithUnit(this.localization, this.userSettings, this.codeList, this.commonCodeList, this.unit, this.numberService, this.sharedEnvironmentService.data?.useDevFeatures ?? false);
    }

    public get selectedConcreteDesignStandardDescription(): string | undefined {
        return this.appSettingsHelper.getDesignStandardDescription(this.localization, DesignType.Concrete, this.concreteDesignStandard);
    }

    public get selectedConcreteDesignMethodDescription(): string | undefined {
        return this.appSettingsHelper.getDesignMethodDescription(this.concreteDesignMethodGroup, this.concreteFixing.concreteDesignStandardId);
    }

    public get isConcreteNone() {
        return this.concreteFixing.concreteRegion != SpecialRegion.None;
    }

    public get concreteDesignStandardEnBased() {
        return this.appSettingsHelper.isConcreteDesignStandardEnBased(this.concreteFixing.concreteSteelCalculationMethod);
    }

    public get concreteDesignStandardAsBased() {
        return this.appSettingsHelper.isConcreteDesignStandardAsBased(this.concreteFixing.concreteSteelCalculationMethod);
    }

    public get concreteDesignStandardAiscBased() {
        return this.appSettingsHelper.isConcreteDesignStandardAiscBased(this.concreteFixing.concreteSteelCalculationMethod);
    }

    public get concreteDesignStandardStoBased() {
        return this.appSettingsHelper.isConcreteDesignStandardStoBased(this.concreteFixing.concreteSteelCalculationMethod);
    }

    public get concreteDesignStandardInBased() {
        return this.appSettingsHelper.isConcreteDesignStandardInBased(this.concreteFixing.concreteSteelCalculationMethod);
    }

    public get concreteDesignStandardHkBased() {
        return this.concreteFixing.concreteSteelCalculationMethod == SteelCalculationMethod.HK;
    }

    public get isConcreteSafetyFactorGammaCDropdownVisible() {
        if (this.concreteFixing.concreteDesignStandardId == null) {
            return false;
        }

        if (!isDesignStandardEurocodeBased(this.concreteFixing.concreteDesignStandardId)) {
            return false;
        }

        return true;
    }

    public get isConcreteSafetyFactorGammaCDropdownDisabled() {
        if (this.concreteFixing.concreteDesignStandardId == DesignStandardEnum.CN && this.concreteFixing.concreteDesignMethodGroupId == DesignMethodGroupEnum.HiltiJGJ145) {
            return true;
        }

        return this.submitted;
    }

    public get allowConcreteQuickStart() {
        const nonDefaultValue = this.concreteFixing.concreteRegion < 0
            ? this.selectedRegionId
            : this.concreteFixing.concreteRegion;
        return this.appSettingsHelper.designTypeConcrete?.allowedDesignStandardRegions?.some((row) => row.RegionId == nonDefaultValue);
    }

    public get allowConcreteStatus() {
        let translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Concrete.Status.Ok');

        if (this.allowConcreteQuickStart) {
            translation = format(translation, this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.None'));
            return translation;
        }
        else {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Concrete.Status.Error');
            const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);
            translation = formatKeyValue(translation, { region: region.getTranslatedNameText(getCodeListTextDeps(this.localization, this.numberService)) ?? '' });
        }

        return translation;
    }

    public get isConcreteEtagEnOnlyCheckboxAvailable() {
        return (this.concreteFixing.concreteDesignStandardId == DesignStandardEnum.EC2
            || this.concreteFixing.concreteDesignStandardId == DesignStandardEnum.IS
            || this.concreteFixing.concreteDesignStandardId == DesignStandardEnum.MS
            || this.concreteFixing.concreteDesignStandardId == DesignStandardEnum.NZ
            || this.concreteFixing.concreteDesignStandardId == DesignStandardEnum.HK
            || this.concreteFixing.concreteDesignStandardId == DesignStandardEnum.CN && this.concreteFixing.concreteDesignMethodGroupId != DesignMethodGroupEnum.HiltiJGJ145)
            && this.concreteFixing.concreteDesignMethodGroupId != DesignMethodGroupEnum.SOFA_Based;
    }

    public get getNumericTextBoxDecimalSeparator() {
        return this.appSettingsHelper.getNumericTextBoxDecimalSeparatorById(this.decimalSeparatorId);
    }

    public get getNumericTextBoxGroupSeparator() {
        return this.appSettingsHelper.getNumericTextBoxGroupSeparatorById(this.thousandsSeparatorId);
    }

    public get isAdvancedCalculationPossible() {
        const regionId = this.concreteFixing.concreteRegion == SpecialRegion.Default ? this.selectedRegionId : this.concreteFixing.concreteRegion;
        return this.abp.isAdvancedCalculationPossibleById(
            DesignType.Concrete,
            this.concreteFixing.concreteDesignStandardId ?? 0,
            regionId
        );
    }

    private get concreteDesignStandard(): DesignStandard | undefined {
        return this.appSettingsHelper.getDesignStandardById(this.concreteFixing.concreteDesignStandardId);
    }

    private get concreteDesignMethodGroup(): DesignMethodGroup | undefined {
        return this.appSettingsHelper.getDesignMethodGroupById(this.concreteFixing.concreteDesignMethodGroupId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['advancedBaseplateInputsData'] && this.advancedBaseplateInputsData.selectedSteelGuideline) {
            this.selectedSteelGuideline = this.advancedBaseplateInputsData.selectedSteelGuideline;
        }

        if (!this.dataLoaded) {
            this.createControlsRegion();
            this.createControlsGeneral();

            this.createControlCalculation();

            this.updateQuickStartConcrete(
                this.concreteFixing.concreteRegion == SpecialRegion.Default
                    ? this.selectedRegionId ?? KnownRegion.Unknown
                    : this.concreteFixing.concreteRegion,
                false
            );

            this.dataLoaded = true;
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public showPermanentOrVariableLoad() {
        return this.concreteFixing.concreteDesignStandardId == DesignStandardEnum.ETAG;
    }

    public isAnchorPlateFactorAvailable(designStandard: DesignStandardEnum, designMethodGroupEnum: DesignMethodGroupEnum, regionId: number) {
        const isAnchorplateFactorVisible = this.isAnchorplateFactorVisible(designStandard, designMethodGroupEnum, regionId);
        return designStandard != DesignStandardEnum.STO && isAnchorplateFactorVisible;
    }

    public isMinimumAnchorToProfileDistanceAvailable(designStandard: DesignStandardEnum, designMethodGroupEnum: DesignMethodGroupEnum, regionId: number) {
        const isAnchorplateFactorVisible = this.isAnchorplateFactorVisible(designStandard, designMethodGroupEnum, regionId);
        return this.appSettingsHelper.isMinimumAnchorToProfileDistanceAvailable(designStandard) && isAnchorplateFactorVisible;
    }

    public isConcreteCoverAvailable(designStandard: DesignStandardEnum, designMethodGroup: DesignMethodGroupEnum, regionId: number) {
        const isAnchorplateFactorVisible = this.isAnchorplateFactorVisible(designStandard, designMethodGroup, regionId);

        return (designStandard == DesignStandardEnum.EC2 || designStandard == DesignStandardEnum.IS || designStandard == DesignStandardEnum.MS
            || (designStandard == DesignStandardEnum.CN && designMethodGroup != DesignMethodGroupEnum.HiltiJGJ145)) && isAnchorplateFactorVisible;
    }

    public getNumericTextBoxUnit(property: string) {
        const regionId = this.concreteFixing.concreteRegion == SpecialRegion.Default
            ? this.selectedRegionId ?? KnownRegion.Unknown
            : this.concreteFixing.concreteRegion;
        const region = this.appSettingsHelper.getRegionById(regionId);
        switch (property) {
            case 'defaultAnchorPlateThickness':
            case 'defaultMinimumAnchorToProfileDistance':
            case 'defaultMinimumConcreteCover':
                return region.defaultUnitLength;

            case 'concreteMinimumAnchorToProfileDistance':
            case 'concreteMinimumConcreteCover':
                return this.concreteFixing.concreteLength;

            default:
                return Unit.None;
        }
    }

    public getNumericTextBoxPlaceholder(property: string): number | string {
        const region = this.appSettingsHelper.getRegionById(this.concreteFixing.concreteRegion);
        const selectedRegion = this.appSettingsHelper.getRegionById(this.selectedRegionId);

        switch (property) {
            case 'defaultAnchorPlateThickness':
                return region.thickness;

            case 'defaultVariableLoads':
                return region.safetyFactorForVariableLoads;

            case 'defaultPermanentLoads':
                return region.safetyFactorForPermamentLoads;

            case 'defaultAnchorPlateFactor':
                return region.anchorPlateFactor;

            case 'concreteMinimumAnchorToProfileDistance':
                return this.appSettingsHelper.bindMinimumAnchorToProfileDistanceDefault(this.concreteFixing.concreteDesignStandardId, this.concreteFixing.concreteLength);

            case 'concreteAnchorPlateFactor':
                return this.appSettingsHelper.bindDefaultFactor(this.concreteFixing.concreteRegion, selectedRegion, FactorType.AnchorPlateFactor, this.userSettings.settings);

            case 'concreteSafetyFactorForPermanentLoads':
                return this.appSettingsHelper.bindDefaultFactor(this.concreteFixing.concreteRegion, selectedRegion, FactorType.SafetyFactorPermanentLoads, this.userSettings.settings);

            case 'concreteSafetyFactorForVariableLoads':
                return this.appSettingsHelper.bindDefaultFactor(this.concreteFixing.concreteRegion, selectedRegion, FactorType.SafetyFactorVariableLoads, this.userSettings.settings);

            case 'materialSafetyFactor':
            case 'concreteMaterialSafetyFactor':
                return this.appSettingsHelper.setSteelCalculationPlaceholder((this.regionDefaultValues.materialSafetyFactor || PropertyMetaData.Option_MaterialSafetyFactor.defaultValue) ?? 0);

            case 'steelCapacityFactor':
            case 'concreteSteelCapacityFactor':
                return this.appSettingsHelper.setSteelCalculationPlaceholder(PropertyMetaData.Option_SteelCapacityFactor.defaultValue ?? 0);

            case 'concreteCapacityFactor':
            case 'concreteConcreteCapacityFactor':
                return this.appSettingsHelper.setSteelCalculationPlaceholder(PropertyMetaData.Option_ConcreteCapacityFactor.defaultValue ?? 0);

            case 'minimumConcreteCover':
            case 'concreteMinimumConcreteCover':
                return PropertyMetaData.Option_MinimumConcreteCover.defaultValue ?? 0;

            default:
                return this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }
    }

    public getConcreteMaterialSafetyFactorTitle() {
        let translation = '';

        if (this.selectedSteelGuideline == SteelGuideline.KR) {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MaterialSafetyFactor.KR');
        }
        else if (this.selectedSteelGuideline == SteelGuideline.TW) {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MaterialSafetyFactor.TW');
        }
        else {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MaterialSafetyFactor');
        }

        return translation;
    }

    public onConcreteRegionDropdownChange(regionId: number) {
        this.concreteFixing.concreteRegion = regionId;
        this.updateQuickStartConcrete(this.concreteFixing.concreteRegion, true);
        this.changeDetectorRef.detectChanges();
    }

    public onConcreteLengthDropdownChange(lengthUnit: number) {
        this.concreteFixing.concreteLength = lengthUnit;
        this.advancedBaseplateInputsData.lengthUnit = lengthUnit;
    }

    public onConcreteDesignStandardDropdownChange(designStandardId: number) {
        this.updateConcreteDesignStandard(designStandardId);
        this.changeDetectorRef.detectChanges();
    }

    public onConcreteDesignMethodChange(designMethodGroupId: number) {
        this.concreteFixing.concreteDesignMethodGroupId = designMethodGroupId;

        // Some design method groups doesn't support EtagEnOnly -> guideline only
        this.concreteFixing.concreteEtagEnOnly = this.isConcreteEtagEnOnlyCheckboxAvailable
            ? SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.concreteEtagEnOnly) ?? false
            : false;

        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.concreteEtagEnOnly, this.concreteFixing.concreteEtagEnOnly);

        // Concrete Safety Factor GammaC
        if (this.isConcreteSafetyFactorGammaCDropdownVisible && this.concreteFixing.concreteDesignStandardId == DesignStandardEnum.IS) {
            const regionId = this.concreteFixing.concreteRegion == SpecialRegion.Default
                ? this.selectedRegionId ?? KnownRegion.Unknown
                : this.concreteFixing.concreteRegion;
            this.updateConcreteSafetyFactorGammaCDropdown(regionId);
        }

        this.changeDetectorRef.detectChanges();
    }

    public onConcreteSteelCalculationMethodDropdownChange(steelCalculationMethod: SteelCalculationMethod) {
        this.concreteFixing.concreteSteelCalculationMethod = steelCalculationMethod;
        this.advancedBaseplateInputsData.selectedSteelGuideline = steelCalculationMethod;
        this.selectedSteelGuideline = steelCalculationMethod;
        this.outputData();
        this.changeDetectorRef.detectChanges();
    }

    public onConcreteEtagOnlyChange() {
        this.concreteFixing.concreteEtagEnOnly = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.concreteEtagEnOnly) ?? false;
        if (this.concreteFixing.concreteEtagEnOnly && this.concreteDesignMethodGroup?.id == DesignMethodGroupEnum.SOFA_Based) {
            // SOFA_Based can not be selected when Guideline only is selected
            // set default design method group
            const concreteRegion = this.appSettingsHelper.getRegionById(this.concreteFixing.concreteRegion < 0
                ? this.selectedRegionId
                : this.concreteFixing.concreteRegion);
            this.appSettingsHelper.setDefaultConcreteDesignMethodGroup(
                this.concreteFixing,
                concreteRegion);
        }
        this.modifyConcreteDesignMethods();
        this.outputData();
        this.changeDetectorRef.detectChanges();
    }

    private setConcreteMaterialSafetyFactorValues(minValue: number) {
        this.concreteMaterialSafetyFactor.minValue = minValue;

        if (this.materialSafetyFactor.value != null &&
            this.materialSafetyFactor.value < minValue) {
            this.materialSafetyFactor.value = minValue;
        }
    }

    private setConcreteSteelCalculationMethod(designStandardId: number, regionId: number, steelGuideline?: SteelCalculationMethod) {
        if (!this.allowConcreteQuickStart) {
            return;
        }

        const region = this.appSettingsHelper.getRegionById(regionId);

        if (steelGuideline == null) {
            steelGuideline = findSteelCalculationMethod(this.codeList.projectCodeLists, designStandardId, region);
        }

        if (this.concreteSteelCalculationMethodDropdown) {
            const steelGuidelines = getAllowedSteelGuidelines(this.codeList.projectCodeLists, designStandardId, regionId);
            const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
            this.concreteSteelCalculationMethodDropdown.items = steelGuidelines.map(sg => {
                return {
                    value: sg.id as SteelCalculationMethod,
                    text: sg.getTranslatedNameText(codeListDeps),
                    disabled: steelGuidelines.length <= 1
                } as DropdownItem<number>;
            });

            this.concreteFixing.concreteSteelCalculationMethod = steelGuideline;
            this.concreteSteelCalculationMethodDropdown.disabled = steelGuidelines.length <= 1;

            if (this.advancedBaseplateInputsData) {
                this.advancedBaseplateInputsData.selectedSteelGuideline = steelGuideline;
                this.selectedSteelGuideline = steelGuideline;
            }
        }

        this.outputData();
    }

    private createControlsGeneral() {
        this.createControlsUnit();

        this.regionDefaultValues = { materialSafetyFactor: undefined };

        this.materialSafetyFactor = this.appSettingsHelper.createSteelCalculationComponent(
            QuickStartType.Concrete, 'materialSafetyFactor', PropertyMetaData.Option_MaterialSafetyFactor);

        this.concreteAnchorPlateFactor = this.appSettingsHelper.createAnchorPlateFactorComponent('concrete-anchor-plate-factor', 'Concrete.AnchorPlateFactor');
        this.concreteAnchorPlateFactor.tooltip = {
            title: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip.Title'),
            content: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip')
        };
        this.concreteSafetyFactorForPermanentLoads = this.appSettingsHelper.createPermanentLoadsComponent('concrete-permanent-loads', 'Concrete.SafetyFactorForPermamentLoads');
        this.concreteSafetyFactorForVariableLoads = this.appSettingsHelper.createVariableLoadsComponent('concrete-variable-loads', 'Concrete.SafetyFactorForVariableLoads');
        this.concreteSteelSafetyFactorGammaM0 = this.appSettingsHelper.createGammaComponent(QuickStartType.Concrete, 'M0');
        this.concreteSteelSafetyFactorGammaM1 = this.appSettingsHelper.createGammaComponent(QuickStartType.Concrete, 'M1');
        this.concreteSteelSafetyFactorGammaM2 = this.appSettingsHelper.createGammaComponent(QuickStartType.Concrete, 'M2');
        this.concreteSTOSteelSafetyFactorGammaM = this.appSettingsHelper.createStoSteelSafetyFactorGammaMComponent('concrete-stoSteelSafetyFactorGammaM');
        this.concreteInSteelSafetyFactorGammaM0 = this.appSettingsHelper.createInGammaComponent(QuickStartType.Concrete, 'M0');
        this.concreteInSteelSafetyFactorGammaM1 = this.appSettingsHelper.createInGammaComponent(QuickStartType.Concrete, 'M1');
        this.concreteInSteelSafetyFactorGammaMw = this.appSettingsHelper.createInGammaComponent(QuickStartType.Concrete, 'Mw');
        this.concreteInSteelSafetyFactorGammaMb = this.appSettingsHelper.createInGammaComponent(QuickStartType.Concrete, 'Mb');
        this.concreteMaterialSafetyFactor = this.appSettingsHelper.createSteelCalculationComponent(
            QuickStartType.Concrete, 'materialSafetyFactor', PropertyMetaData.Option_MaterialSafetyFactor);
        this.concreteSteelCapacityFactor = this.appSettingsHelper.createSteelCalculationComponent(
            QuickStartType.Concrete, 'steelCapacityFactor', PropertyMetaData.Option_SteelCapacityFactor);
        this.concreteWeldsCapacityFactor = this.appSettingsHelper.createCapacityFactorDropdownControl(
            QuickStartType.Concrete, 'weldsCapacityFactor');
        this.concreteConcreteCapacityFactor = this.appSettingsHelper.createSteelCalculationComponent(
            QuickStartType.Concrete, 'concreteCapacityFactor', PropertyMetaData.Option_ConcreteCapacityFactor);
        this.concreteMinimumConcreteCover = this.appSettingsHelper.createNumericTextBox(
            'concrete-minimum-concrete-cover', 'MinimumConcreteCover', undefined, PropertyMetaData.Option_MinimumConcreteCover.minValue, PropertyMetaData.Option_MinimumConcreteCover.maxValue
        );
        this.concreteMinimumAnchorToProfileDistance =
            this.appSettingsHelper.createMinimumAnchorToProfileDistanceComponent('-concrete');

        this.concreteSafetyFactorGammaC = this.appSettingsHelper.createDropdownComponent(
            'concrete-safety-factor-gamma-c',
            'ConcreteSafetyFactorGammaC'
        );
        this.concreteSafetyFactorGammaC.tooltip = ({
            title: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.ConcreteSafetyFactorGammaC.Tooltip.Title'),
            content: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.ConcreteSafetyFactorGammaC.Tooltip')
        });

        if (this.isConcreteSafetyFactorGammaCDropdownVisible) {
            const regionId = this.concreteFixing.concreteRegion == SpecialRegion.Default
                ? this.selectedRegionId ?? KnownRegion.Unknown
                : this.concreteFixing.concreteRegion;

            this.updateConcreteSafetyFactorGammaCDropdown(regionId);
        }

        const steelGuideLines = this.codeList.projectCodeLists[ProjectCodeList.SteelGuidelines];

        this.concreteSteelCalculationMethodDropdown = this.appSettingsHelper.createDropdownComponent(
            'concrete-steel-calculation-method', '',
            steelGuideLines.map(steelGuideline => ({
                value: steelGuideline.id,
                text: this.localization.getString(steelGuideline.nameResourceKey ?? ''),
            }) as DropdownItem<number>));
        this.concreteSteelCalculationMethodDropdown.disabled = true;
        this.concreteEtagEnOnly = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.concreteFixing.concreteEtagEnOnly,
            itemId: 'add-edit-design-concrete-etag-en-only',
            itemText: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.EtagEnOnly'),
            itemDescription: getGuidelineOnlyDescriptionText(this.concreteFixing.concreteDesignStandardId, this.localization),
        });
    }

    private createControlsUnit() {
        this.concreteForcePerLengthDropdown = this.appSettingsHelper.createUnitDropdown('concrete-force-per-length', 'Concrete.ForcePerLength', CommonCodeList.UnitForcePerLength);
        this.concreteMomentPerLengthDropdown = this.appSettingsHelper.createUnitDropdown('concrete-moment-per-length', 'Concrete.MomentPerLength', CommonCodeList.UnitMomentPerLength);
        this.concreteLengthDropdown = this.appSettingsHelper.createUnitDropdown('concrete-length', 'Concrete.Length', CommonCodeList.UnitLength);
        this.concreteAreaDropdown = this.appSettingsHelper.createUnitDropdown('concrete-area', 'Concrete.Area', CommonCodeList.UnitArea);
        this.concreteStressDropdown = this.appSettingsHelper.createUnitDropdown('concrete-stress', 'Concrete.Stress', CommonCodeList.UnitStress);
        this.concreteForceDropdown = this.appSettingsHelper.createUnitDropdown('concrete-force', 'Concrete.Force', CommonCodeList.UnitForce);
        this.concreteMomentDropdown = this.appSettingsHelper.createUnitDropdown('concrete-moment', 'Concrete.Moment', CommonCodeList.UnitMoment);
        this.concreteTemperatureDropdown = this.appSettingsHelper.createUnitDropdown('concrete-temperature', 'Concrete.Temperature', CommonCodeList.UnitTemperature);
        this.concreteDensityDropdown = this.appSettingsHelper.createUnitDropdown('concrete-density', 'Concrete.Density', CommonCodeList.UnitDensity);

        const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);
        this.concreteFixing.concreteForcePerLength = this.appSettingsHelper.ensureUnitSupported(this.concreteForcePerLengthDropdown.items, this.concreteFixing.concreteForcePerLength, region.defaultUnitForcePerLength);
        this.concreteFixing.concreteMomentPerLength = this.appSettingsHelper.ensureUnitSupported(this.concreteMomentPerLengthDropdown.items, this.concreteFixing.concreteMomentPerLength, region.defaultUnitMomentPerLength);
        this.concreteFixing.concreteLength = this.appSettingsHelper.ensureUnitSupported(this.concreteLengthDropdown.items, this.concreteFixing.concreteLength, region.defaultUnitLength);
        this.concreteFixing.concreteArea = this.appSettingsHelper.ensureUnitSupported(this.concreteAreaDropdown.items, this.concreteFixing.concreteArea, region.defaultUnitArea);
        this.concreteFixing.concreteStress = this.appSettingsHelper.ensureUnitSupported(this.concreteStressDropdown.items, this.concreteFixing.concreteStress, region.defaultUnitStress);
        this.concreteFixing.concreteForce = this.appSettingsHelper.ensureUnitSupported(this.concreteForceDropdown.items, this.concreteFixing.concreteForce, region.defaultUnitForce);
        this.concreteFixing.concreteMoment = this.appSettingsHelper.ensureUnitSupported(this.concreteMomentDropdown.items, this.concreteFixing.concreteMoment, region.defaultUnitMoment);
        this.concreteFixing.concreteTemperature = this.appSettingsHelper.ensureUnitSupported(this.concreteTemperatureDropdown.items, this.concreteFixing.concreteTemperature, region.defaultUnitTemperature);
        this.concreteFixing.concreteDensity = this.appSettingsHelper.ensureUnitSupported(this.concreteDensityDropdown.items, this.concreteFixing.concreteDensity, region.defaultUnitDensity);

        this.appSettingsHelper.setUnitDropdownValue(this.concreteForcePerLengthDropdown, this.concreteFixing.concreteForcePerLength);
        this.appSettingsHelper.setUnitDropdownValue(this.concreteMomentPerLengthDropdown, this.concreteFixing.concreteMomentPerLength);
        this.appSettingsHelper.setUnitDropdownValue(this.concreteLengthDropdown, this.concreteFixing.concreteLength);
        this.appSettingsHelper.setUnitDropdownValue(this.concreteAreaDropdown, this.concreteFixing.concreteArea);
        this.appSettingsHelper.setUnitDropdownValue(this.concreteStressDropdown, this.concreteFixing.concreteStress);
        this.appSettingsHelper.setUnitDropdownValue(this.concreteForceDropdown, this.concreteFixing.concreteForce);
        this.appSettingsHelper.setUnitDropdownValue(this.concreteMomentDropdown, this.concreteFixing.concreteMoment);
        this.appSettingsHelper.setUnitDropdownValue(this.concreteTemperatureDropdown, this.concreteFixing.concreteTemperature);
        this.appSettingsHelper.setUnitDropdownValue(this.concreteDensityDropdown, this.concreteFixing.concreteDensity);
    }

    private createControlsRegion() {
        this.concreteRegionDropdown = this.appSettingsHelper.createDropdownComponent(
            'concrete-region',
            'Concrete.Region',
            this.appSettingsHelper.getAvailableRegions(this.appSettingsHelper.designTypeConcrete)
                .map(region => ({
                    value: region.id,
                    text: this.localization.getString(region.nameResourceKey)
                }) as DropdownItem<number>)
        );
    }

    private createControlCalculation() {
        this.concreteCalculationMethodDropdown = this.createConcreteCalculationMethodDropdown();

        // Concrete design standard
        this.concreteDesignStandardDropdown = this.appSettingsHelper.createDropdownComponent<number>(
            'concrete-design-standard-dropdown', 'Concrete.DesignStandard');
        this.modifyConcreteDesignStandards();

        // Concrete design method
        this.concreteDesignMethodDropdown = this.appSettingsHelper.createDropdownComponent<number>(
            'concrete-design-method-group-dropdown', 'Concrete.DesignMethod');
        this.modifyConcreteDesignMethods();

        const regionId = this.concreteFixing.concreteRegion == SpecialRegion.Default
            ? this.selectedRegionId ?? KnownRegion.Unknown
            : this.concreteFixing.concreteRegion;
        this.setConcreteSteelCalculationMethod(this.concreteFixing.concreteDesignStandardId ?? DesignStandardEnum.Unknown, regionId, this.concreteFixing.concreteSteelCalculationMethod);
    }

    private createConcreteCalculationMethodDropdown() {
        const dropdown: DropdownProps<number> = {
            id: `add-edit-design-concrete-method-dropdown`,
            title: undefined,
            items: [
                ({
                    value: 1,
                    text: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.ConcreteCalculationMethod')
                }) as DropdownItem<number>
            ],
            selectedValue: 1
        };
        dropdown.disabled = true;

        return dropdown;
    }

    private updateConcreteDesignStandard(designStandardId: number) {
        this.concreteFixing.concreteDesignStandardId = designStandardId;

        const concreteRegionId = this.concreteFixing.concreteRegion == SpecialRegion.Default || this.concreteFixing.concreteRegion == null
            ? this.selectedRegionId ?? KnownRegion.Unknown
            : this.concreteFixing.concreteRegion;

        this.setConcreteSteelCalculationMethod(designStandardId, concreteRegionId);
        this.advancedBaseplateInputsData.designStandardId = designStandardId;

        // Design standards
        this.modifyConcreteDesignStandards();

        // Design methods
        this.modifyConcreteDesignMethods();

        // Concrete Safety Factor GammaC
        if (this.isConcreteSafetyFactorGammaCDropdownVisible) {
            this.updateConcreteSafetyFactorGammaCDropdown(concreteRegionId);
        }
        if (this.concreteEtagEnOnly.items) {
            this.concreteEtagEnOnly.items[0].description = getGuidelineOnlyDescriptionText(this.concreteFixing.concreteDesignStandardId, this.localization);
        }
    }

    private updateConcreteSafetyFactorGammaCDropdown(regionId: number) {
        const decimalSeparator = this.appSettingsHelper.getNumericTextBoxDecimalSeparatorById(this.decimalSeparatorId);
        const codeList = this.codeList.projectCodeLists[ProjectCodeList.ConcreteSafetyFactorGammaC] as unknown as ConcreteSafetyFactorGammaC[];
        const concreteSafetyFactorGammaCItems = codeList.filter((item) => item.allowedForRegions.some(i => i == regionId));
        const items = concreteSafetyFactorGammaCItems
            .filter((item) => item.allowedForRegions.some(i => i == regionId))
            .map(item => ({
                value: item.id,
                text: `${this.unit.formatNumber(item.factor ?? 0, this.unit.getPrecision(Unit.None), null, decimalSeparator)}: ${this.localization.getString(item.nameResourceKey ?? '')}`
            }) as DropdownItem<number>);

        let selectedItem = this.appSettingsHelper.getConcreteSafetyFactorGammaCEntity(this.concreteFixing.concreteSafetyFactorGammaCId);
        if (
            selectedItem == null
            ||
            !items.some((item) => item.value == selectedItem?.id)
        ) {
            // Value not selected or selected value not available, use default
            selectedItem = this.appSettingsHelper.getConcreteSafetyFactorGammaCEntity(PropertyMetaData.Option_ConcreteSafetyFactorGammaC.defaultValue);
        }

        this.concreteSafetyFactorGammaC.items = (this.concreteFixing.concreteDesignStandardId == DesignStandardEnum.IS && this.concreteFixing.concreteDesignMethodGroupId == DesignMethodGroupEnum.IS_XXX) ? items.filter(x => x.value == ConcreteSafetyFactorGammaCEnum.Default) : items;
        this.concreteSafetyFactorGammaC.selectedValue = (this.concreteFixing.concreteDesignStandardId == DesignStandardEnum.IS && this.concreteFixing.concreteDesignMethodGroupId == DesignMethodGroupEnum.IS_XXX) ? ConcreteSafetyFactorGammaCEnum.Default : selectedItem.id;
        this.concreteFixing.concreteSafetyFactorGammaCId = this.concreteSafetyFactorGammaC.selectedValue;
    }

    private updateQuickStartConcrete(regionId: number, regionChanged: boolean) {
        if (regionId != SpecialRegion.None || this.concreteFixing.concreteRegion == null) {
            regionId = regionId < 0
                ? this.selectedRegionId ?? KnownRegion.Unknown
                : regionId;
            const region = this.appSettingsHelper.getRegionById(regionId);

            if (regionChanged) {
                this.appSettingsHelper.setDefaultConcreteUnits(this.concreteFixing, region);
                this.appSettingsHelper.setDefaultConcreteDesignStandard(this.concreteFixing, region);
                this.appSettingsHelper.setDefaultDimensioningStartPreference(this.concreteFixing, region);
                this.updateConcreteDesignStandard(this.concreteFixing.concreteDesignStandardId ?? DesignStandardEnum.Unknown);
                this.appSettingsHelper.setDefaultConcreteDesignMethodGroup(this.concreteFixing, region);
                this.setConcreteSteelCalculationMethod(this.concreteFixing.concreteDesignStandardId ?? DesignStandardEnum.Unknown, region.id ?? KnownRegion.Unknown);

            }

            this.setMaterialSafetyFactorValues(region);

            this.concreteWeldsCapacityFactor.selectedValue = reductionFactorSP;

            // Concrete Safety Factor GammaC
            if (this.isConcreteSafetyFactorGammaCDropdownVisible) {
                this.updateConcreteSafetyFactorGammaCDropdown(region.id ?? KnownRegion.Unknown);
            }

            this.advancedBaseplateInputsData.defaultAdvancedCalculationValues.concreteInCompressionMethod = region.defaultConcreteInCompressionMethod;
        }
    }

    private setMaterialSafetyFactorValues(region: Region) {
        // TODO: get min/max from DB.
        // min/max
        const minValue: number = this.appSettingsHelper.getMaterialSafetyFactorMinValue(region.id ?? KnownRegion.Unknown) ?? 0;
        this.setConcreteMaterialSafetyFactorValues(minValue);

        // set region specific placeholder
        this.regionDefaultValues.materialSafetyFactor = region.defaultMaterialSafetyFactor;
    }

    private modifyConcreteDesignStandards(): void {
        const concreteDesignStandards = this.appSettingsHelper.getDesignStandardsByDesignType(this.appSettingsHelper.designTypeConcrete, this.concreteFixing.concreteRegion, this.selectedRegionId);

        this.concreteDesignStandardDropdown.items = concreteDesignStandards.map(designStandard => ({
            value: designStandard.id,
            text: this.appSettingsHelper.getDesignStandardTitle(this.localization, DesignType.Concrete, designStandard),
        }) as DropdownItem<number>);

        this.concreteDesignStandardDropdown.selectedValue = this.concreteFixing.concreteDesignStandardId;
    }

    private modifyConcreteDesignMethods(): void {
        const designMethodGroups = this.appSettingsHelper.getDesignMethodGroupsByDesignType(
            this.appSettingsHelper.designTypeConcrete,
            this.concreteFixing.concreteDesignStandardId,
            this.concreteFixing.concreteRegion,
            this.selectedRegionId,
            SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.concreteEtagEnOnly));

        if (!designMethodGroups.some(designMethodGroup => designMethodGroup.id == this.concreteFixing.concreteDesignMethodGroupId)) {
            const concreteRegionId = this.concreteFixing.concreteRegion < 0
                ? this.selectedRegionId ?? KnownRegion.Unknown
                : this.concreteFixing.concreteRegion;
            const concreteRegion = this.appSettingsHelper.getRegionById(concreteRegionId);

            this.appSettingsHelper.setDefaultConcreteDesignMethodGroup(this.concreteFixing, concreteRegion);
        }

        this.concreteDesignMethodDropdown.items = designMethodGroups.map((designMethodGroup) => ({
            value: designMethodGroup.id,
            text: this.appSettingsHelper.getDesignMethodGroupTitle(designMethodGroup, this.concreteFixing.concreteDesignStandardId),
        }) as DropdownItem<number>);

        this.concreteDesignMethodDropdown.selectedValue = this.concreteFixing.concreteDesignMethodGroupId;
    }

    /*
    Currently Advanced anchor plate calculation settings are dependant on selected steel guideline and design (PE specific).
    In order to keep this working the code below is a workaround.
    This should be removed with https://jira.hilti.com/browse/BUDQBP-30905.
    */
    private outputData() {
        this.concreteFixingValueChange.emit({
            concreteRegion: this.concreteFixing.concreteRegion,
            concreteDesignStandardId: this.concreteFixing.concreteDesignStandardId,
            concreteSteelCalculationMethod: this.concreteFixing.concreteSteelCalculationMethod
        });
    }

    public isAnchorplateFactorVisible(designStandard: DesignStandardEnum, designMethodGroupEnum: DesignMethodGroupEnum, regionId: number): boolean {
        regionId = regionId == SpecialRegion.Default ? this.selectedRegionId ?? KnownRegion.Unknown : regionId;
        return !this.userSettings.isDefaultEnAndSofaBasedDesignMethod(designStandard, designMethodGroupEnum, regionId);
    }
}
