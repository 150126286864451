import { HttpRequest } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CheckboxButtonProps } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { SupportApplicationType, SupportRequest } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.SupportService.Shared.Entities';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { SimpleCheckboxButtonHelper } from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { BrowserService } from '../../services/browser.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { LoggerService } from '../../services/logger.service';
import { OfflineService } from '../../services/offline.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

export enum Reaction {
    None,
    Unhappy,
    Neutral,
    Happy,
}

export enum RequestType {
    Support,
    Feedback,
}

export interface Feedback {
    reaction?: Reaction;
    additonalFeedback?: string;
    allowToContact?: boolean;
}

export interface FeedbackRequest extends SupportRequest {
    reaction?: Reaction;
    allowToContact?: boolean;
    requestType?: RequestType;
}

@Component({
    selector: 'app-feedback-form',
    templateUrl: './feedback-form.component.html',
    styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit {
    public allowToContact!: CheckboxButtonProps<boolean>;
    public isFormSubmitted = false;
    public loading = false;
    public react = Reaction;
    public reactionType = Reaction.None;
    public feedBack: Feedback = {
        reaction: Reaction.None,
        additonalFeedback: '',
        allowToContact: false,
    };
    public applicationVersion?: string;
    public operatingSystem?: string;
    public browserVersion?: string;

    private currentDateTime: Date;


    constructor(
        private readonly modalInstance: ModalInstance,
        public localization: LocalizationService,
        private readonly userSettings: UserSettingsService,
        private readonly user: UserService,
        private readonly license: LicenseService,
        private readonly offlineService: OfflineService,
        private readonly apiService: ApiService,
        private readonly logger: LoggerService,
        private readonly browserService: BrowserService
    ) {}


    public get country() {
        return this.user.authentication.countryOfResidence
            ? this.user.authentication.countryOfResidence
            : this.user.authentication.country;
    }

    public get licenseType() {
        return this.license.getLicense();
    }

    public get currentDate() {
        return this.currentDateTime.toUTCString();
    }

    public get regionId() {
        const regionId = this.userSettings.settings.application.general.regionId.value;
        return regionId;
    }

    ngOnInit() {
        this.allowToContact = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: false,
            itemId: 'feedback-form-allow-to-contact',
            itemText: this.localization.getString(
                'Agito.Hilti.Profis3.Feedback.AllowToContact'
            ),
            itemDescription: '',
        });

        this.currentDateTime = new Date();

        this.applicationVersion = environment.applicationVersion;

        // uai
        const uai = this.browserService.getBrowserData();
        this.operatingSystem = uai.os;
        this.browserVersion = uai.browser;
    }

    public close() {
        this.modalInstance.close();
    }

    public onReact(value: Reaction) {
        this.feedBack.reaction = value;
        this.reactionType = value;
    }

    public onSubmit() {
        this.isFormSubmitted = true;
        this.loading = true;
        const additonalFeedback = this.feedBack.additonalFeedback;
        const allowToContact = this.feedBack.allowToContact;
        const reaction = this.feedBack.reaction;

        const data: FeedbackRequest = {
            Subject: null,
            Message: additonalFeedback,
            Design: null,
            DesignName: null,
            Logs: null,
            ApplicationVersion: this.applicationVersion,
            BrowserVersion: this.browserVersion,
            OperatingSystemVersion: this.operatingSystem,
            DateTime: this.currentDate,
            RegionId: this.regionId,
            ApplicationType: SupportApplicationType.ProfisEngineering,
            Country: this.country,
            LicenseType: this.licenseType,
            CorrelationId: null,
            reaction: reaction,
            allowToContact: allowToContact,
            requestType: RequestType.Feedback,
        };

        this.sendFeedbackMessage(data);
    }

    public reacted() {
        if (this.feedBack.reaction != this.react.None) {
            return false;
        }

        return true;
    }

    public updateFeedback() {
        this.feedBack.allowToContact = this.allowToContact.selectedValues.size != 0;
    }

    public sendFeedbackMessage(data: FeedbackRequest) {
        let url: string;
        if (this.offlineService.isOffline) {
            url = `${environment.baseplateApplicationWebServiceUrl}Support`;
        } else {
            url = `${environment.supportServiceUrl}send`;
        }

        this.apiService
            .request(new HttpRequest('POST', url, data))
            .finally(() => {
                this.loading = false;
            })
            .catch((response) => {
                this.isFormSubmitted = false;
                this.logger.logServiceError(
                    response,
                    'Feedback',
                    'SendFeedbackEmail'
                );
            });
    }
}
