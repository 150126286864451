import { FireDurationEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';
export class FireDuration extends CodeList {

    public static fromService(codeList: FireDurationEntityC2C) {
        return new FireDuration(CodeList.baseFromService('FireDurationEntity', codeList));
    }

    public override cloneDeep(): FireDuration {
        return super.cloneDeep() as FireDuration;
    }
}
