import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { IVirtualTour } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    NavigationTourStatus, Step, TourServiceInjected
} from '@profis-engineering/pe-ui-common/services/tour.common';

import { environment } from '../../environments/environmentPe';
import {
    UtilizationValueEntity
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    LoadCombination, UIProperty
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignType, LoadType
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    concatFilterLoadCombinations, loadCombinationHasValue
} from '../helpers/load-combination-helper';
import {
    isSmartAnchorAllowed, isSmartAnchorToggleAllowed, mockHtml
} from '../helpers/smart-anchor-helper';
import {
    getApplicationBuilderMockData, getMinimumRequirementsMockData, getSearchSolutionsMockData,
    getSuggestedAnchorsMockData
} from '../helpers/smart-anchor-mock-data';
import { CalculationServicePE } from './calculation-pe.service';
import { GuidService } from './guid.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { RoutingService } from './routing.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';
import { OfflineService } from './offline.service';
import { CommonTrackingService } from './common-tracking.service';

@Injectable({
    providedIn: 'root'
})
export class TourService extends TourServiceInjected {
    private preferredAnchorsPopupOpened: IModalOpened | undefined;
    private advancedInputPopupOpened: IModalOpened | undefined;
    private is3DModel = false;
    private isSearchSolutions = false;
    private isSearchSolutionsFilters = false;
    private isUtilizations = false;
    private customSubject = new BehaviorSubject<any>(false);
    public customObservable = this.customSubject.asObservable();

    constructor(
        public localizationService: LocalizationService,
        public sharedEnvironmentService: SharedEnvironmentService,
        private guid: GuidService,
        private modalService: ModalService,
        private userSettingsService: UserSettingsService,
        private userService: UserService,
        private calculationService: CalculationServicePE,
        private routingService: RoutingService,
        private offlineService: OfflineService,
        private commonTrackingService: CommonTrackingService
    ) {
        super();
    }

    public applicationBuilder: any;
    public anchors: any;
    public minRequirements: any;
    public isApplicationBuilderReplaced = false;
    public isAnchorsReplaced = false;
    public isMinRequirementsReplaced = false;
    public isAsadEnabled = false;

    public openDisplayOptionsDropdownFn!: () => void;
    public closeDisplayOptionsDropdownFn!: () => void;
    public openExportReportFn!: () => void;
    public closeExportReportFn!: () => void;

    private openCreateReport = true;

    public get isNavigationTourInProgress() {
        return this.navigationTourInProgress != NavigationTourStatus.none;
    }

    public get smartAnchorEnabled() {
        return this.userService.design.model[UIProperty.SmartAnchor_Enabled];
    }

    private get design() {
        return this.userService.design;
    }

    private get rootElement() {
        return document.documentElement.querySelector('pe-main')?.shadowRoot;
    }

    public getVirtualTours(): IVirtualTour[] {
        return [
            // Concrete virtual tour
            {
                name: 'ConcreteWalktrough',
                order: 1,
                buttonText: this.translate('Agito.Hilti.Profis3.VirtualTour.ConcreteWalktrough'),
                openTour: this.concreteTour.bind(this),
                isAvailable: () => {
                    // BUDQBP-32398: With new virtual tour enabled we do not need separate concrete tour. But we leave the code until cleanup, if we need to disable new tour.
                    return false;
                },
                alreadySeen: () => {
                    return this.userSettingsService.settings.concretevirtualtourSeen.value ?? false;
                },
                markAsSeen: async () => {
                    this.userSettingsService.settings.concretevirtualtourSeen.value = true;
                    await this.userSettingsService.save();
                }
            },
            // Handrail virtual tour
            {
                name: 'HandrailWalktrough',
                order: 2,
                buttonText: this.translate('Agito.Hilti.Profis3.VirtualTour.HandrailWalktrough'),
                openTour: this.handRailTour.bind(this),
                isAvailable: () => {
                    return this.userService.design?.designType?.id == DesignType.Handrail;
                },
                alreadySeen: () => {
                    return this.userSettingsService.settings.handrailvirtualtourSeen.value ?? false;
                },
                markAsSeen: async () => {
                    this.userSettingsService.settings.handrailvirtualtourSeen.value = true;
                    await this.userSettingsService.save();
                }
            },
            // Smart anchor virtual tour
            {
                name: 'SmartAnchorWalkthrough',
                order: 3,
                buttonText: this.translate('Agito.Hilti.Profis3.VirtualTour.SmartAnchorWalkthrough'),
                openTour: this.smartAnchorTour.bind(this),
                isAvailable: () => {
                    return isSmartAnchorAllowed(this.userService.design);
                },
                alreadySeen: () => {
                    return this.userSettingsService.settings.smartanchorvirtualtourSeen.value ?? false;
                },
                markAsSeen: async () => {
                    this.userSettingsService.settings.smartanchorvirtualtourSeen.value = true;
                    await this.userSettingsService.save();
                }
            },
            // Smart design virtual tour
            {
                name: 'SmartDesignWalkthrough',
                order: 4,
                buttonText: this.translate('Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough'),
                openTour: this.smartDesignTour.bind(this),
                isAvailable: () => {
                    return isSmartAnchorToggleAllowed(this.userService.design) && this.isAsadEnabled;
                },
                alreadySeen: () => {
                    return true;
                },
                markAsSeen: async () => {
                    this.userSettingsService.settings.smartdesignvirtualtourseen.value = true;
                    await this.userSettingsService.save();
                }
            }
        ];
    }

    public handRailTour(selectTab: (tab: string) => void) {
        const title = 'Agito.Hilti.Profis3.VirtualTour.HandrailWalktrough';
        const numberOfSteps = 8;
        const menuContainer = this.rootElement?.querySelector('.menu-container')?.shadowRoot ?? undefined;

        return this.start({
            highlightClass: 'no-background',
            steps: [
                {
                    element: () => menuContainer?.querySelector('[data-control-id="tab-ProfilesTab"] [data-control-id="region-ProfileRegion"]') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourHandrail.Profile', numberOfSteps, 1),
                    position: 'right',
                    onBeforeChange: () => {
                        selectTab('ProfilesTab');
                        setTimeout(() => menuContainer?.querySelector('[data-control-id="tab-ProfilesTab"] [data-control-id="region-ProfileRegion"]')?.scrollIntoView());
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: () => menuContainer?.querySelector('[data-control-id="tab-ApplicationTab"] [data-control-id="region-HandrailRegion"]') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourHandrail.Application', numberOfSteps, 2),
                    position: 'right',
                    onBeforeChange: () => {
                        selectTab('ApplicationTab');
                        setTimeout(() => menuContainer?.querySelector('[data-control-id="tab-ApplicationTab"] [data-control-id="region-HandrailRegion"]')?.scrollIntoView());
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: () => menuContainer?.querySelector('[data-control-id="tab-HandrailLoadsTab"] [data-control-id="region-BuildingTypeRegion"]') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourHandrail.Loads', numberOfSteps, 3),
                    position: 'right',
                    onBeforeChange: () => {
                        selectTab('HandrailLoadsTab');
                        setTimeout(() => menuContainer?.querySelector('[data-control-id="tab-HandrailLoadsTab"] [data-control-id="region-BuildingTypeRegion"]')?.scrollIntoView());
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: () => menuContainer?.querySelector('[data-control-id="tab-HandrailLoadsTab"] [data-control-id="region-EnvironmentRegion"]') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourHandrail.Wind', numberOfSteps, 4),
                    position: 'right',
                    onBeforeChange: () => {
                        setTimeout(() => menuContainer?.querySelector('[data-control-id="tab-HandrailLoadsTab"] [data-control-id="region-EnvironmentRegion"]')?.scrollIntoView());
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: () => this.rootElement?.querySelector('[data-id="122"]') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourHandrail.Utilization', numberOfSteps, 5),
                    position: 'left',
                    onBeforeChange: () => {
                        setTimeout(() => this.rootElement?.querySelector('[data-id="122"]')?.scrollIntoView(false));
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: this.rootElement?.querySelector('.info-section-container') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourHandrail.Anchor', numberOfSteps, 6),
                    position: 'left',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: this.rootElement?.querySelector('.design-section-wrapper') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourHandrail.Design', numberOfSteps, 7),
                    position: 'left',
                    onBeforeChange: () => {
                        setTimeout(() => this.rootElement?.querySelector('.design-section-wrapper')?.scrollIntoView());
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: this.rootElement?.querySelector('.main-header') as HTMLElement,
                    intro: this.introStepTemplate('Agito.Hilti.Profis3.VirtualTour.ReadyToBegin', 'Agito.Hilti.Profis3.TourHandrail.Menu', numberOfSteps, 8),
                    position: 'bottom',
                    tooltipClass: 'virtual-tour-popup-modal last-tooltip-offset'
                }
            ]
        });
    }

    public concreteTour(selectTab: (tab: string) => void) {
        const title = 'Agito.Hilti.Profis3.VirtualTour.ConcreteWalktrough';
        const numberOfSteps = 5;
        const menuContainer = this.rootElement?.querySelector('.menu-container')?.shadowRoot ?? undefined;
        return this.start({
            highlightClass: 'no-background',
            steps: [
                {
                    element: this.rootElement?.querySelector('.main-header') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourMainPage.MessageHeader', numberOfSteps, 1),
                    position: 'bottom-middle-aligned',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: menuContainer?.querySelector('.tab-menu') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourMainPage.TabsNavigationMenu', numberOfSteps, 2),
                    position: 'right',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: menuContainer?.querySelector('.tabs-wrapper') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourMainPage.TabsMenu', numberOfSteps, 3),
                    position: 'right',
                    onBeforeChange: () => {
                        selectTab('AnchorLayoutTab');
                        setTimeout(() => menuContainer?.querySelector('[data-control-id="region-AnchorRegion"]')?.scrollIntoView());
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: this.rootElement?.querySelector('.model-view-options') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourMainPage.ModelOptions', numberOfSteps, 4),
                    position: 'bottom-middle-aligned',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: this.rootElement?.querySelector('.main-content-right') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.TourMainPage.Results', numberOfSteps, 5),
                    position: 'left',
                    tooltipClass: 'virtual-tour-popup-modal'
                }
            ]
        });
    }

    public smartAnchorTour(selectTab: (tab: string) => void) {
        const title = 'Agito.Hilti.Profis3.VirtualTour.SmartAnchorWalkthrough';
        const numberOfSteps = 8;

        let smartAnchorPopupOpened: IModalOpened | undefined;
        const closeSmartAnchorPopupFn = () => {
            if (smartAnchorPopupOpened != null) {
                smartAnchorPopupOpened.close();
                smartAnchorPopupOpened = undefined;
            }
        };

        const menu = this.rootElement?.querySelector('.menu-container')?.shadowRoot;
        const tour = this.start({
            steps: [
                {
                    element: () => this.rootElement?.querySelector('.main-header') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartAnchorWalkthrough.Step1', numberOfSteps, 1),
                    position: 'bottom-middle-aligned',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: menu?.querySelector('.tab-menu') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartAnchorWalkthrough.Step2', numberOfSteps, 2),
                    position: 'smart-anchor-tab',
                    onBeforeChange: () => {
                        selectTab('SmartAnchorTab');
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: menu?.querySelector('.tabs-wrapper') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartAnchorWalkthrough.Step3', numberOfSteps, 3),
                    position: 'right',
                    onBeforeChange: () => {
                        setTimeout(() => menu?.querySelector('[data-control-id="region-ApplicationBuilderRegion"]')?.scrollIntoView());
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: () => menu?.querySelector('[data-control-id="SmartAnchorTab-SmartAnchor.Category-group"]') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartAnchorWalkthrough.Step4', numberOfSteps, 4),
                    position: 'right',
                    onBeforeChange: () => {
                        setTimeout(() => menu?.querySelector('[data-control-id="SmartAnchorTab-SmartAnchor.Category-group"]')?.scrollIntoView());
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: () => menu?.querySelector('[data-control-id="SmartAnchorTab-SmartAnchor.Application-group"]') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartAnchorWalkthrough.Step5', numberOfSteps, 5),
                    position: 'right',
                    onBeforeChange: () => {
                        setTimeout(() => menu?.querySelector('[data-control-id="SmartAnchorTab-SmartAnchor.Application-group"]')?.scrollIntoView());
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: () => menu?.querySelector('[data-control-id="SmartAnchorTab-SmartAnchor.SuggestedAnchorFamily-group"]') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartAnchorWalkthrough.Step6', numberOfSteps, 6),
                    position: 'right',
                    onBeforeChange: () => {
                        setTimeout(() => menu?.querySelector('[data-control-id="SmartAnchorTab-SmartAnchor.SuggestedAnchorFamily-group"]')?.scrollIntoView());
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: () => menu?.querySelector('div.header-container.bulb') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartAnchorWalkthrough.Step7', numberOfSteps, 7),
                    position: 'right',
                    onBeforeChange: () => {
                        closeSmartAnchorPopupFn();
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: () => document.documentElement.querySelector('.anchor-need-solution .modal-dialog') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartAnchorWalkthrough.Step8', numberOfSteps, 8),
                    position: 'modal-right',
                    onBeforeChange: () => {
                        if (!smartAnchorPopupOpened) {
                            smartAnchorPopupOpened = this.modalService.openAnchorNeedSolutionPopup(true);
                        }
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                }
            ]
        });

        tour.oncomplete(() => {
            document.documentElement?.querySelector('app-main')?.querySelector('pe-main')?.shadowRoot?.querySelector('.main-content')?.querySelector('.menu-container')?.shadowRoot?.querySelector('.tabs-scroll')?.classList.remove('introjs-fixParent');
            closeSmartAnchorPopupFn();
        });

        return tour;
    }

    public smartDesignTour(selectTab?: (tab: string) => void) {
        const smartAnchorMenu = this.rootElement?.querySelector('.menu-container')?.shadowRoot;
        const floatingDropdowns = document.documentElement?.querySelector('app-main')?.querySelector('pe-main')?.shadowRoot?.querySelector('.main-content')?.querySelector('.main-content-center')?.querySelector('#floating-dropdowns');
        const tour = this.start({
            steps: this.generateSmartDesignTourSteps(selectTab)
        });

        tour.oncomplete(() => {
            this.changeApplicationBuilderHtml(true);
            this.changeAnchorsHtml(true);
            this.minRequirementsHtml(true);
            this.onTourClose(smartAnchorMenu, floatingDropdowns);
            this.commonTrackingService.trackVirtualTour('SmartDesignAuto', this.tour.currentStep(), this.tour.stepsCount());
        });

        tour.onexit(() => {
            this.onSmartDesignTourExit();
            this.onTourClose(smartAnchorMenu, floatingDropdowns);
            this.commonTrackingService.trackVirtualTour('SmartDesignAuto', this.tour.currentStep(), this.tour.stepsCount());
        });

        return tour;
    }

    public onOpenCreateReport() {
        if (this.tour != null) {
            this.openCreateReport = false;
            this.tour.goToStep(this.offlineService.isOffline ? 11 : 12);
        }
    }

    public onCreateReport() {
        if (this.tour != null) {
            this.tour.goToStep(this.offlineService.isOffline ? 12 : 13);
        }
    }

    public showDesignSectionExportLocationTour() {
        const title = this.localizationService.getString('Agito.Hilti.Profis3.GenerateReportFollowUpActions.Location.Title');

        return this.start({
            highlightClass: 'no-background',
            steps: [
                {
                    element: this.rootElement
                        ?.querySelector<HTMLElement>('.design-section-wrapper .design-section')
                        ?.shadowRoot
                        ?.querySelector('.export-button.button-design-section') as HTMLElement,
                    intro: `<div class="stepTitle">${title}</div>`,
                    position: 'left',
                    onBeforeChange: () => {
                        setTimeout(() => this.rootElement?.querySelector('.design-section-wrapper')?.scrollIntoView());
                    },
                    tooltipClass: 'virtual-tour-popup-modal'
                }
            ]
        });
    }


    private generateSmartDesignTourSteps(selectTab?: (tab: string) => void) {
        const smartAnchorMenu = this.rootElement?.querySelector('.menu-container')?.shadowRoot;
        const floatingDropdowns = document.documentElement?.querySelector('app-main')?.querySelector('pe-main')?.shadowRoot?.querySelector('.main-content')?.querySelector('.main-content-center')?.querySelector('#floating-dropdowns');
        const title = 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Title';
        const numberOfSteps = 12;

        return [
            {
                element: smartAnchorMenu?.querySelector('.tab-menu') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step1', numberOfSteps, 1),
                position: 'smart-anchor-tab',
                onBeforeChange: () => {
                    if (selectTab) {
                        selectTab('SmartAnchorTab');
                    }
                },
                tooltipClass: this.smartAnchorEnabled ? 'virtual-tour-popup-modal smart-design-menu-position' : 'virtual-tour-popup-modal'
            },
            {
                element: () => smartAnchorMenu?.querySelector('[data-control-id="region-SmartBaseplateSelection"]') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step2', numberOfSteps, 2),
                position: 'right',
                onBeforeChange: () => {
                    setTimeout(() => {
                        smartAnchorMenu?.querySelector('[data-control-id="region-SmartBaseplateSelection"]')?.scrollIntoView();
                        smartAnchorMenu?.querySelector('[data-control-id="region-SmartBaseplateSelection"]')?.getElementsByClassName('toggleContainer')[0].classList.add('smart-design-toggle');
                    });
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => smartAnchorMenu?.querySelector('[data-control-id="region-ApplicationBuilderRegion"]') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step3', numberOfSteps, 3),
                position: 'right',
                onBeforeChange: () => {
                    this.changeApplicationBuilderHtml();
                    setTimeout(() => smartAnchorMenu?.querySelector('[data-control-id="region-ApplicationBuilderRegion"]')?.scrollIntoView());
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => smartAnchorMenu?.querySelector('[data-control-id="region-SuggestedAnchors"]') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step4', numberOfSteps, 4),
                position: 'right',
                onBeforeChange: () => {
                    this.closeSmartDesignTourPopupFn(floatingDropdowns);
                    this.changeAnchorsHtml();
                    setTimeout(() => smartAnchorMenu?.querySelector('[data-control-id="region-SuggestedAnchors"]')?.scrollIntoView());
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => document.documentElement.querySelector('pe-multiselect-anchor')?.shadowRoot?.querySelector('pe-modal-body')?.querySelector('.right-side') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step5', numberOfSteps, 5),
                position: 'left',
                onBeforeChange: () => {
                    if (!this.preferredAnchorsPopupOpened) {
                        this.preferredAnchorsPopupOpened = this.modalService.openMultiselectAnchor();
                        setTimeout(() => document.documentElement.querySelector('pe-multiselect-anchor')?.shadowRoot?.querySelector('pe-modal-body')?.querySelector('.right-side')?.setAttribute('style', 'z-index:10000000;'));
                    }
                    setTimeout(() => document.documentElement.querySelector('pe-multiselect-anchor')?.shadowRoot?.querySelector('pe-modal-body')?.querySelector('.right-side')?.scrollIntoView());
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => smartAnchorMenu?.querySelector('[data-control-id="region-MinimumRequirements"]') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step6', numberOfSteps, 6),
                position: 'right',
                onBeforeChange: () => {
                    this.closeSmartDesignTourPopupFn(floatingDropdowns);
                    this.minRequirementsHtml();
                    setTimeout(() => smartAnchorMenu?.querySelector('[data-control-id="region-MinimumRequirements"]')?.scrollIntoView());
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => document.documentElement.querySelector('.advanced-inputs .modal-dialog') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step7', numberOfSteps, 7),
                position: 'modal-right',
                onBeforeChange: () => {
                    if (!this.advancedInputPopupOpened) {
                        this.advancedInputPopupOpened = this.modalService.openAdvancedInputs(true);
                    }
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => document.documentElement?.querySelector('pe-main')?.shadowRoot?.querySelector('.loads-container-wrapper') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step8', numberOfSteps, 8),
                position: 'top',
                onBeforeChange: () => {
                    this.closeSmartDesignTourPopupFn(floatingDropdowns);
                    document.documentElement?.querySelector('pe-main')?.shadowRoot?.querySelector('.loads-container-wrapper')?.scrollIntoView();
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => document.documentElement?.querySelector('pe-main')?.shadowRoot?.querySelector('.model-view-container') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step9', numberOfSteps, 9),
                position: 'right',
                onBeforeChange: () => {
                    if (!this.is3DModel) {
                        document.documentElement?.querySelector('pe-main')?.shadowRoot?.querySelector('.model-view-container')?.setAttribute('style', 'z-index: 10000000;');
                        document.documentElement?.querySelector('.introjs-tooltipReferenceLayer')?.querySelector('.introjs-tooltip')?.setAttribute('style', 'width: 245px;');
                        this.is3DModel = true;
                    }
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => floatingDropdowns as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step10', numberOfSteps, 10),
                position: 'left',
                onBeforeChange: () => {
                    this.closeSmartDesignTourPopupFn(floatingDropdowns);
                    if (this.smartAnchorEnabled) {
                        floatingDropdowns?.querySelector('#smartAnchorSearchSolutions')?.setAttribute('style', 'display: none');
                    }
                    if (!this.isSearchSolutions) {
                        this.customSubject.next(true);
                        this.isSearchSolutions = true;
                        document.documentElement?.querySelector('.introjs-helperLayer ')?.setAttribute('style', 'height: calc(100vh - 291px) important;');
                    }
                    setTimeout(() => floatingDropdowns?.querySelector('#smartSolutionsVirtualTour')?.querySelector('#smart-baseplate-section')?.scrollIntoView());
                    setTimeout(() => this.changeSearchSolutionsHtml());
                    setTimeout(() => floatingDropdowns?.querySelector('#smartSolutionsVirtualTour')?.querySelector('#smart-baseplate-section')?.querySelector('pe-resizer')?.querySelector('.filter-panel')?.setAttribute('style', 'opacity: 0.7;'));
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => floatingDropdowns?.querySelector('#smartSolutionsVirtualTour')?.querySelector('#smart-baseplate-section')?.querySelector('pe-resizer')?.querySelector('.filter-panel') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step11', numberOfSteps, 11),
                position: 'left',
                onBeforeChange: () => {
                    this.closeSmartDesignTourPopupFn(floatingDropdowns);
                    if (!this.isSearchSolutionsFilters) {
                        this.customSubject.next(true);
                        this.isSearchSolutionsFilters = true;
                        document.documentElement?.querySelector('.introjs-helperLayer ')?.setAttribute('style', 'height: calc(100vh - 291px) important;');
                    }
                    floatingDropdowns?.querySelector('#smartSolutionsVirtualTour')?.querySelector('#smart-baseplate-section')?.querySelector('pe-resizer')?.querySelector('.filter-panel')?.scrollIntoView();
                    floatingDropdowns?.setAttribute('style', 'z-index: auto;');
                    floatingDropdowns?.querySelector('#smartSolutionsVirtualTour')?.querySelector('#smart-baseplate-section')?.querySelector('pe-resizer')?.querySelector('.filter-panel')?.setAttribute('style', 'z-index: 9999999 !important;');
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: () => document.documentElement?.querySelector('app-main')?.querySelector('pe-main')?.shadowRoot?.querySelector('.main-content')?.querySelector('.main-content-right-scroll') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.SmartDesignWalkthrough.Step12', numberOfSteps, 12),
                position: 'left',
                onBeforeChange: () => {
                    this.closeSmartDesignTourPopupFn(floatingDropdowns);
                    if (!this.isUtilizations) {
                        this.customSubject.next(true);
                        this.isUtilizations = true;
                    }
                },
                tooltipClass: 'virtual-tour-popup-modal'
            }
        ];
    }

    private changeSearchSolutionsHtml() {
        const parent = document.documentElement?.querySelector('app-main')?.querySelector('pe-main')?.shadowRoot?.querySelector('.main-content')?.querySelector('.main-content-center')?.querySelector('#floating-dropdowns')?.querySelector('#smartSolutionsVirtualTour');
        const child = parent?.childNodes[0] as HTMLElement;
        const data = document.createElement('div');
        data.setAttribute('class', 'click-reset');
        data.innerHTML = getSearchSolutionsMockData(this.localizationService);
        parent?.replaceChild(data, child);
    }

    // BUDQBP-32646: this tour will replace concrete tour
    public newConcreteTour(selectTab: (tab: string) => void, mainHeaderShowVirtualTourFn?: () => void) {
        const title = 'Agito.Hilti.Profis3.VirtualTour.Concrete.Title';
        const numberOfSteps = this.offlineService.isOffline ? 11 : 12;
        let currentStep = 2;
        const menuContainer = this.rootElement?.querySelector('.menu-container')?.shadowRoot ?? undefined;
        const steps: Step[] = [
            {
                intro: this.introStepTemplate(title, '', numberOfSteps)
            }
        ];
        if (!this.offlineService.isOffline) {
            steps.push({
                intro: this.introStepTemplate(title, '', numberOfSteps, currentStep++)
            });
        }
        steps.push(
            {
                intro: this.introStepTemplate(title, '', numberOfSteps, currentStep++),
                onBeforeChange: () => {
                    // move to main menu and open navigation tour on slide 3
                    this.exitTour = true;
                    this.navigationTourInProgress = NavigationTourStatus.continue;
                },
                onAfterChange: () => {
                    this.routingService.navigateToUrl(UrlPath.projectAndDesign);
                }
            },
            {
                element: menuContainer?.querySelector('.tabs-wrapper') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.Concrete.Step3', numberOfSteps, currentStep++),
                position: 'right',
                onBeforeChange: () => {
                    selectTab('BaseMaterialTab');
                    setTimeout(() => menuContainer?.querySelector('[data-control-id="region-BaseMaterialRegion"]')?.scrollIntoView());
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: menuContainer?.querySelector('.tabs-wrapper') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.Concrete.Step4', numberOfSteps, currentStep++),
                position: 'right',
                onBeforeChange: () => {
                    selectTab('BasePlateTabTab');
                    setTimeout(() => menuContainer?.querySelector('[data-control-id="region-RigidAnchorPlateRegion"]')?.scrollIntoView());
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: menuContainer?.querySelector('.tabs-wrapper') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.Concrete.Step5', numberOfSteps, currentStep++),
                position: 'right',
                onBeforeChange: () => {
                    selectTab('AnchorLayoutTab');
                    setTimeout(() => menuContainer?.querySelector('[data-control-id="region-AnchorRegion"]')?.scrollIntoView());
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: menuContainer?.querySelector('.tabs-wrapper') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.Concrete.Step6', numberOfSteps, currentStep++),
                position: 'right',
                onBeforeChange: () => {
                    selectTab('ProfilesTab');
                    setTimeout(() => menuContainer?.querySelector('[data-control-id="region-ProfileRegion"]')?.scrollIntoView());
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: menuContainer?.querySelector('.tabs-wrapper') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.Concrete.Step7', numberOfSteps, currentStep++),
                position: 'right',
                onBeforeChange: () => {
                    this.closeDisplayOptionsDropdownFn();
                    selectTab('LoadsTab');
                    setTimeout(() => menuContainer?.querySelector('[data-control-id="region-CalculationTypeRegion"]')?.scrollIntoView());
                },
                tooltipClass: 'virtual-tour-popup-modal'
            },
            {
                element: this.rootElement?.querySelector('.main-content-center-right-top') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.Concrete.Step8', numberOfSteps, currentStep++),
                position: 'modal-right',
                onBeforeChange: () => {
                    // open view options
                    this.openDisplayOptionsDropdownFn();
                },
                tooltipClass: 'virtual-tour-popup-modal',
                disableInteraction: false
            },
            {
                element: this.rootElement?.querySelector('.loads-container-wrapper')?.shadowRoot?.querySelector('.loads-container') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.Concrete.Step9', numberOfSteps, currentStep++),
                position: 'top',
                onBeforeChange: () => {
                    this.closeDisplayOptionsDropdownFn();
                },
                tooltipClass: 'virtual-tour-popup-modal',
                disableInteraction: false
            },
            {
                element: this.rootElement?.querySelector('.design-section-wrapper .design-section')?.shadowRoot?.querySelector('.generate-report-button') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.Concrete.Step10', numberOfSteps, currentStep++),
                position: 'left',
                onBeforeChange: () => {
                    // check if loads are present, if not put values in
                    this.setLoads();
                    this.closeExportReportFn();
                    this.openCreateReport = true;
                },
                tooltipClass: 'virtual-tour-popup-modal',
                disableInteraction: false
            },
            {
                element: document.documentElement.querySelector('pe-export-report')?.shadowRoot?.querySelector('pe-modal-footer') as HTMLElement,
                intro: this.introStepTemplate(title, 'Agito.Hilti.Profis3.VirtualTour.Concrete.Step11', numberOfSteps, currentStep++),
                position: 'bottom-middle-aligned',
                onBeforeChange: () => {
                    // open create report if it's not opened before
                    if (this.openCreateReport) {
                        this.openExportReportFn();
                        setTimeout(() => document.documentElement.querySelector('pe-export-report')?.shadowRoot?.querySelector('pe-modal-footer'));
                    }
                    this.openCreateReport = true;
                },
                tooltipClass: 'virtual-tour-popup-modal',
                disableInteraction: false
            });

        const tour = this.start({
            highlightClass: 'no-background',
            steps: steps
        }, 'WelcomeToPROFISEngineering');

        tour.onexit(() => {
            this.closeDisplayOptionsDropdownFn();
            const mainHeader = document.documentElement.querySelector('pe-main')?.shadowRoot?.querySelector<HTMLElement>('pe-main-header') as HTMLElement;
            setTimeout(() => { this.afterExitingVirtualTour(mainHeader, mainHeaderShowVirtualTourFn); });
        });

        tour.goToStep(this.offlineService.isOffline ? 3 : 4);
        // navigation tour is started
        this.navigationTourInProgress = NavigationTourStatus.none;
        return tour;
    }

    private changeApplicationBuilderHtml(revert = false) {
        const smartAnchorMenu = this.rootElement?.querySelector('.menu-container')?.shadowRoot;
        const parent = smartAnchorMenu?.querySelector('.regions') as HTMLElement;

        if (!revert && !this.isApplicationBuilderReplaced) {
            const mockChild = mockHtml(getApplicationBuilderMockData(this.localizationService), 'region-ApplicationBuilderRegion');
            const child = smartAnchorMenu?.querySelector('[data-control-id="region-ApplicationBuilderRegion"]') as HTMLElement;
            this.applicationBuilder = parent?.replaceChild(mockChild, child);
            this.isApplicationBuilderReplaced = true;
        }
        if (revert) {
            const replaceNode = smartAnchorMenu?.querySelector('[data-control-id="region-ApplicationBuilderRegion"]') as HTMLElement;
            parent?.replaceChild(this.applicationBuilder, replaceNode);
            this.isApplicationBuilderReplaced = false;
        }
    }

    private changeAnchorsHtml(revert = false) {
        const smartAnchorMenu = this.rootElement?.querySelector('.menu-container')?.shadowRoot;
        const parent = smartAnchorMenu?.querySelector('.regions') as HTMLElement;

        if (!revert && !this.isAnchorsReplaced) {
            const mockChild = mockHtml(getSuggestedAnchorsMockData(this.localizationService), 'region-SuggestedAnchors');
            const child = smartAnchorMenu?.querySelector('[data-control-id="region-SuggestedAnchors"]') as HTMLElement;
            this.anchors = parent?.replaceChild(mockChild, child);
            this.isAnchorsReplaced = true;
        }
        if (revert) {
            const replaceNode = smartAnchorMenu?.querySelector('[data-control-id="region-SuggestedAnchors"]') as HTMLElement;
            parent?.replaceChild(this.anchors, replaceNode);
            this.isAnchorsReplaced = false;
        }
    }

    private minRequirementsHtml(revert = false) {
        const smartAnchorMenu = this.rootElement?.querySelector('.menu-container')?.shadowRoot;
        const parent = smartAnchorMenu?.querySelector('.regions') as HTMLElement;

        if (!revert && !this.isMinRequirementsReplaced) {
            const mockChild = mockHtml(getMinimumRequirementsMockData(this.localizationService), 'region-MinimumRequirements');
            const child = smartAnchorMenu?.querySelector('[data-control-id="region-MinimumRequirements"]') as HTMLElement;
            this.minRequirements = this.smartAnchorEnabled ? parent?.replaceChild(mockChild, child) : parent?.appendChild(mockChild);
            this.isMinRequirementsReplaced = true;
        }
        if (revert) {
            const replaceNode = smartAnchorMenu?.querySelector('[data-control-id="region-MinimumRequirements"]') as HTMLElement;
            this.smartAnchorEnabled ? parent?.replaceChild(this.minRequirements, replaceNode) : parent?.removeChild(replaceNode);
            this.isMinRequirementsReplaced = false;
        }
    }

    private setLoads() {
        const notEmptyLoadCombination = this.design.loadCombinations.find(x => loadCombinationHasValue(x, false));
        if (notEmptyLoadCombination == null) {
            const firstLoadCombination = this.design.loadCombinations.find(x => x.Id);
            const newLoads: LoadCombination[] = [];
            newLoads.push({
                Name: firstLoadCombination?.Name as string,
                Description: '',
                Id: this.guid.new(),
                ForceX: 5000,
                ForceY: 0,
                ForceZ: 10000,
                MomentX: 0,
                MomentY: 0,
                MomentZ: 0,
                DynamicForceX: null as unknown as number,
                DynamicForceY: null as unknown as number,
                DynamicForceZ: null as unknown as number,
                DynamicMomentX: null as unknown as number,
                DynamicMomentY: null as unknown as number,
                DynamicMomentZ: null as unknown as number,
                ActiveLoadType: LoadType.Static,
                Tension: null as unknown as UtilizationValueEntity,
                Shear: null as unknown as UtilizationValueEntity,
                Combination: null as unknown as UtilizationValueEntity,
                ResultMessages: [],
                IsWizardGenerated: false,
                HasSustainedLoads: false,
                LoadCharacteristic: null as unknown as number
            });

            this.calculationService.calculateAsync(this.userService.design,
                (design) => {
                    // Filter out empty load combinations and consider max number of combinations
                    design.loadCombinations = concatFilterLoadCombinations(design.loadCombinations, newLoads, environment.maxLoadCombinations, false);
                },
                {
                    suppressLoadingFlag: true,
                    importingLoadCases: true
                }
            );
        }
    }

    private onSmartDesignTourExit() {
        if (this.isApplicationBuilderReplaced) {
            this.changeApplicationBuilderHtml(true);
        }
        if (this.isAnchorsReplaced) {
            this.changeAnchorsHtml(true);
        }
        if (this.isMinRequirementsReplaced) {
            this.minRequirementsHtml(true);
        }
    }

    private closeSmartDesignTourPopupFn(floatingDropdowns: Element | null | undefined) {
        if (this.preferredAnchorsPopupOpened != null) {
            document.documentElement.querySelector('pe-multiselect-anchor')?.shadowRoot?.querySelector('pe-modal-body')?.querySelector('.right-side')?.removeAttribute('style');
            this.preferredAnchorsPopupOpened.close();
            this.preferredAnchorsPopupOpened = undefined;
        }
        if (this.advancedInputPopupOpened != null) {
            this.advancedInputPopupOpened.close();
            this.advancedInputPopupOpened = undefined;
        }
        if (this.is3DModel) {
            document.documentElement?.querySelector('pe-main')?.shadowRoot?.querySelector('.model-view-container')?.removeAttribute('style');
            document.documentElement?.querySelector('.introjs-tooltipReferenceLayer')?.querySelector('.introjs-tooltip')?.removeAttribute('style');
            this.is3DModel = false;
        }
        if (this.isSearchSolutions) {
            this.customSubject.next(false);
            this.isSearchSolutions = false;
            floatingDropdowns?.querySelector('#smartSolutionsVirtualTour')?.querySelector('#smart-baseplate-section')?.querySelector('pe-resizer')?.querySelector('.filter-panel')?.removeAttribute('style');
            document.documentElement?.querySelector('.introjs-helperLayer ')?.removeAttribute('style');
        }
        if (this.isSearchSolutionsFilters) {
            this.customSubject.next(false);
            this.isSearchSolutionsFilters = false;
            floatingDropdowns?.removeAttribute('style');
            floatingDropdowns?.querySelector('#smartSolutionsVirtualTour')?.querySelector('#smart-baseplate-section')?.querySelector('pe-resizer')?.querySelector('.filter-panel')?.removeAttribute('style');
            document.documentElement?.querySelector('.introjs-helperLayer ')?.removeAttribute('style');
        }
        if (this.isUtilizations) {
            this.customSubject.next(false);
            this.isUtilizations = false;
        }
    }

    private onTourClose(smartAnchorMenu: ShadowRoot | null | undefined, floatingDropdowns: Element | null | undefined) {
        smartAnchorMenu?.querySelector('[data-control-id="region-SmartBaseplateSelection"]')?.getElementsByClassName('toggleContainer')[0].classList.remove('smart-design-toggle');
        document.documentElement?.querySelector('app-main')?.querySelector('pe-main')?.shadowRoot?.querySelector('.main-content')?.querySelector('.menu-container')?.shadowRoot?.querySelector('.tabs-scroll')?.classList.remove('introjs-fixParent');
        this.closeSmartDesignTourPopupFn(floatingDropdowns);
        if (this.smartAnchorEnabled) {
            floatingDropdowns?.querySelector('#smartAnchorSearchSolutions')?.removeAttribute('style');
        }
    }

    private translate(key: string) {
        return this.localizationService.getString(key);
    }
}
