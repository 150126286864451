import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import { ContolsStyleSheets } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IMainMenuComponent, IMenu, IMenuProps } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { BaseControl } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import { LocalizationServiceBase } from '@profis-engineering/pe-ui-common/services/localization.common';
import { createElement } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { FavoritesService } from '../../services/favorites.service';
import { LocalizationService } from '../../services/localization.service';
import { MenuService } from '../../services/menu.service';
import { RegionOrderService } from '../../services/region-order.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { MainMenu } from './MainMenu';
import { isControlHidden } from './MainMenuHelper';

@Component({
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss', './MainMenu.scss', './Controls/controls.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MainMenuComponent implements OnDestroy, OnInit, IMainMenuComponent, OnChanges {

    @Input()
    disableDragToMoveText: boolean;

    @Input()
    tabsHidden: boolean;

    @Input()
    public initMenu3d = (design: Design, tabSelected: () => void, commands?: Record<string, (navigationControl?: BaseControl) => void>) => this.initMenu3dInternal(design, tabSelected, commands);

    @Input()
    public initMenu2d = (createMenuFn: () => IMenu) => this.initMenu2dInternal(createMenuFn);

    @Input()
    public setMainMenuState = (fn?: (prevMenu: IMenu) => IMenu) => this.setMainMenuStateInternal(fn);

    @Input()
    public getSelectedMenu = () => this.selectedMenu;

    @Input()
    public selectTab = (tabName: string) => this.selectTabInternal(tabName);

    @Input()
    public isTabEmptyOrHidden = (tabId: string) => this.isTabEmptyOrHiddenInternal(tabId);

    @Input()
    private moduleLocalizationService?: LocalizationServiceBase;

    private _root: Root;
    private selectedMenu: IMenu;
    private appliedControlStyles: Set<React.ComponentClass<any>> = new Set();

    private get root() {
        if (this._root == null) {
            this._root = createRoot(this.elementRef.nativeElement.shadowRoot);
        }

        return this._root;
    }

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly localizationService: LocalizationService,
        private readonly favoritesService: FavoritesService,
        private readonly userSettingsService: UserSettingsService,
        private readonly regionOrderService: RegionOrderService,
        private readonly menuService: MenuService
    ) {
        this.collapseChanged = this.collapseChanged.bind(this);
        this.regionOrderUpdate = this.regionOrderUpdate.bind(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['tabsHidden'] && !changes['tabsHidden'].isFirstChange()) {
            this.setMainMenuStateInternal();
        }
    }

    ngOnInit(): void {
        this.setMainMenuState = this.setMainMenuState.bind(this);
    }

    public ngOnDestroy(): void {
        this.root?.unmount();
    }

    public get profis3KBUrlAci() {
        return this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value).profis3KBUrlAci;
    }

    public collapseChanged(id: string, collapsed: boolean) {
        this.userSettingsService.settings.applicationCollapsedState.value[id] = collapsed;
        this.userSettingsService.save();
    }

    public regionOrderUpdate(order: string[], menuId: number) {
        this.regionOrderService.update(order.map((r) => r != null ? this.favoritesService.getRegionId(r) : null).filter((f) => f != null), menuId);
    }

    private isTabEmptyOrHiddenInternal(tabId: string): boolean {
        const tab = this.selectedMenu.tabs[tabId];
        if (tab == null) {
            return true;
        }

        const regions = tab.regionsOrder.map(regionId => this.selectedMenu.regions[regionId]);
        for (const region of regions) {
            for (const controlId of region.controlsOrder) {
                const control = this.selectedMenu.controls[controlId];

                if (!isControlHidden(control)) {
                    return false;
                }
            }
        }

        return true;
    }

    private initMenu3dInternal(design: Design, tabSelected: () => void, commands?: Record<string, (navigationControl?: BaseControl) => void>) {
        this.disposeMenu();

        this.selectedMenu = this.menuService.createMainMenu(
            MenuType.Menu3D,
            tabSelected,
            this.setMainMenuState,
            design,
            commands,
            {},
            {}
        );

        const props: IMenuProps = {
            menu: this.selectedMenu,
            setState: this.setMainMenuState,
            localization: this.localization,
            disableDragToMoveText: this.disableDragToMoveText,
            favorites: this.favoritesService.favorites,
            regionOrderUpdate: this.regionOrderUpdate,
            collapseChanged: this.collapseChanged,
            profis3KBUrlAci: this.profis3KBUrlAci,
            tabsHidden: this.tabsHidden,
            applyControlsStyle: this.applyControlsStyle.bind(this)
        };

        this.root.render(createElement(MainMenu, props));

        return this.selectedMenu;
    }

    private initMenu2dInternal(createMenuFn: () => IMenu) {
        this.disposeMenu();
        this.selectedMenu = createMenuFn();

        const props: IMenuProps = {
            menu: this.selectedMenu,
            setState: this.setMainMenuState,
            localization: this.localization,
            disableDragToMoveText: this.disableDragToMoveText,
            favorites: this.favoritesService.favorites,
            regionOrderUpdate: this.regionOrderUpdate,
            collapseChanged: this.collapseChanged,
            profis3KBUrlAci: this.profis3KBUrlAci,
            tabsHidden: this.tabsHidden,
            applyControlsStyle: this.applyControlsStyle.bind(this)
        };

        this.root.render(createElement(MainMenu, props));

        return this.selectedMenu;
    }

    private disposeMenu() {
        if (this.selectedMenu != undefined) {
            this.selectedMenu.dispose();
            this.selectedMenu = undefined as unknown as IMenu;
        }
    }

    private setMainMenuStateInternal(fn?: (prevMenu: IMenu) => IMenu) {

        const localFn = () =>  {
            if (fn != null) {
                this.selectedMenu = fn(this.selectedMenu);
            }

            return this.selectedMenu;
        };

        localFn();
        const props: IMenuProps = {
            menu: this.selectedMenu,
            setState: this.setMainMenuState,
            localization: this.localization,
            disableDragToMoveText: this.disableDragToMoveText,
            favorites: this.favoritesService.favorites,
            regionOrderUpdate: this.regionOrderUpdate,
            collapseChanged: this.collapseChanged,
            profis3KBUrlAci: this.profis3KBUrlAci,
            tabsHidden: this.tabsHidden,
            applyControlsStyle: this.applyControlsStyle.bind(this)
        };

        this.root.render(createElement(MainMenu, props));
        return this.selectedMenu;
    }

    private applyControlsStyle(control: React.ComponentClass<any> & ContolsStyleSheets) {
        if (control.styleSheets == undefined || this.appliedControlStyles.has(control)) {
            return;
        }

        this.elementRef.nativeElement.shadowRoot.adoptedStyleSheets = [...this.elementRef.nativeElement.shadowRoot.adoptedStyleSheets, ...control.styleSheets];
        this.appliedControlStyles.add(control);
    }

    private selectTabInternal(tabName: string): void {
        if (this.selectedMenu.tabs[tabName]?.disabled) {
            return;
        }

        this.setMainMenuStateInternal(() => {
            return {
                ...this.selectedMenu,
                selectedTab: tabName
            }
        });
    }

    private get localization() {
        return this.moduleLocalizationService ?? this.localizationService;
    }
}
