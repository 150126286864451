import { CodeList } from './code-list';
import { RebarDiameterModeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class RebarDiameterMode extends CodeList {

    public static fromService(codeList: RebarDiameterModeEntityC2C) {
        return new RebarDiameterMode(CodeList.baseFromService('RebarDiameterModeEntity', codeList, {
            id: codeList.id,
            tooltipDisplayKey: codeList.tooltipTitleDisplayKey,
            tooltipDisabledDisplayKey: codeList.tooltipDisabledDisplayKey,
            tooltipTitleDisplayKey: codeList.tooltipTitleDisplayKey
        }));
    }

    public override cloneDeep(): RebarDiameterMode {
        return super.cloneDeep() as RebarDiameterMode;
    }
}
