import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Unit } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import {
    ApplicationSettingsCommon, GeneralValue, IApplicationImportLoadsBase,
    IDefaultApplicationSettingsBase, IGeneralApplicationSettingsBase, IQuickStartSettingsBase,
    UserSettings as UserSettingsCommon
} from '@profis-engineering/pe-ui-common/entities/user-settings';
import {
    IQuickStartUserSettings
} from '@profis-engineering/pe-ui-common/services/user-settings.common';

import {
    ConcreteInCompressionMethod, ConcreteSafetyFactorGammaC, SteelGuideline
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { DesignMethodGroup } from './code-lists/design-method-group';
import { DesignStandard } from './code-lists/design-standard';
import { EdgeDistanceDisplayType } from './code-lists/edge-distance-display-type';

export interface QuickStartSettings extends IQuickStartUserSettings {
    general_region: CommonRegion;
    units_area?: Unit;
    units_force?: Unit;
    units_length?: Unit;
    units_length_large?: Unit;
    units_moment?: Unit;
    units_stress?: Unit;
    units_stress_small?: Unit;
    units_temperature?: Unit;
    units_force_per_length?: Unit;
    units_moment_per_length?: Unit;
    units_density?: Unit;
    minimum_anchor_to_profile_distance?: number;
    concreteSafetyFactorGammaC?: ConcreteSafetyFactorGammaC;
    concreteSafetyFactorGammaCFreeValue?: number;
    anchor_plate_factor?: number;
    variable_loads?: number;
    permenent_loads?: number;
    calculation_designStandard: DesignStandard;
    calculation_designMethodGroup?: DesignMethodGroup;
    calculation_etagEnOnly: boolean;
    calculation_etaData: boolean;
    calculation_ostInputsData: boolean;
    edgeDistanceDisplayType?: EdgeDistanceDisplayType;
    steelSafetyFactorGammaM0?: number;
    steelSafetyFactorGammaM1?: number;
    steelSafetyFactorGammaM2?: number;
    steelSafetyFactorGammaM5?: number;
    materialSafetyFactor?: number;
    weldsCapacityFactor?: number;
    steelCapacityFactor?: number;
    concreteCapacityFactor?: number;
    handrailDisplacementLimit?: number;
    handrailDisplacementLimitBridges?: number;
    inSteelSafetyFactorGammaM0?: number;
    inSteelSafetyFactorGammaM1?: number;
    inSteelSafetyFactorGammaMw?: number;
    inSteelSafetyFactorGammaMb?: number;
    steelGuideLine?: SteelGuideline;
    minimumConcreteCover?: number;
    ConcreteInCompressionMethod?: ConcreteInCompressionMethod;
    measureBaseMaterialEdgeFrom?: number;
    measureAnchorPlate?: number;
}

export interface IQuickStartSettingsBasePe extends IQuickStartSettingsBase {
    anchorPlateFactor: GeneralValue<number | null>;
    edgeDistanceDisplayTypeId: GeneralValue<number | null>;
    minimumAnchorToProfileDistance: GeneralValue<number | null>;
    steelSafetyFactorGammaM0: GeneralValue<number | null>;
    steelSafetyFactorGammaM1: GeneralValue<number | null>;
    steelSafetyFactorGammaM2: GeneralValue<number | null>;
    steelSafetyFactorGammaM5: GeneralValue<number | null>;
}

export interface IConcreteQuickStartSettings extends IQuickStartSettingsBasePe {
    calculationEtagEnOnly: GeneralValue<boolean | null>;
    concreteCapacityFactor: GeneralValue<number | null>;
    concreteSafetyFactorGammaC: GeneralValue<number | null>;
    concreteSafetyFactorGammaCFreeValue: GeneralValue<number | null>;
    inSteelSafetyFactorGammaM0: GeneralValue<number | null>;
    inSteelSafetyFactorGammaM1: GeneralValue<number | null>;
    inSteelSafetyFactorGammaMb: GeneralValue<number | null>;
    inSteelSafetyFactorGammaMw: GeneralValue<number | null>;
    materialSafetyFactor: GeneralValue<number | null>;
    minimumConcreteCover: GeneralValue<number | null>;
    permenentLoads: GeneralValue<number | null>;
    steelCapacityFactor: GeneralValue<number | null>;
    steelGuideline: GeneralValue<number | null>;
    unitsDensityId: GeneralValue<number | null>;
    unitsForcePerLengthId: GeneralValue<number | null>;
    unitsMomentPerLengthId: GeneralValue<number | null>;
    variableLoads: GeneralValue<number | null>;
    weldsCapacityFactor: GeneralValue<number | null>;
    measureBaseMaterialEdgeFrom?: GeneralValue<number | null>;
    measureAnchorPlate?: GeneralValue<number | null>;
}

export interface IMasonryQuickStartSettings extends IQuickStartSettingsBasePe {
    calculationEtaData: GeneralValue<boolean | null>;
    calculationHiltiTechnicalData: GeneralValue<boolean | null>;
    calculationOstInputsData: GeneralValue<boolean | null>;
    minimumAnchorToProfileDistance: GeneralValue<number | null>;
    permenentLoads: GeneralValue<number | null>;
    variableLoads: GeneralValue<number | null>;
}

export interface IHandrailQuickStartSettings extends IQuickStartSettingsBasePe {
    calculationEtagEnOnly: GeneralValue<boolean | null>;
    displacementLimit: GeneralValue<number | null>;
    displacementLimitBridges: GeneralValue<number | null>;
    permenentLoads: GeneralValue<number | null>;
    unitsForcePerLengthId: GeneralValue<number | null>;
    unitsLengthLargeId: GeneralValue<number | null>;
    unitsStressSmallId: GeneralValue<number | null>;
    variableLoads: GeneralValue<number | null>;
}

export interface IMetalDeckQuickStartSettings extends IQuickStartSettingsBasePe {
    materialSafetyFactor: GeneralValue<number | null>;
    steelGuideline: GeneralValue<number | null>;
    unitsDensityId: GeneralValue<number | null>;
    unitsForcePerLengthId: GeneralValue<number | null>;
    unitsMomentPerLengthId: GeneralValue<number | null>;
}

export interface IQuickStartSettingsPe {
    concrete: IConcreteQuickStartSettings;
    masonry: IMasonryQuickStartSettings;
    handrail: IHandrailQuickStartSettings;
    metalDeck: IMetalDeckQuickStartSettings;
}

export class ApplicationSettingsPe extends ApplicationSettingsCommon<IGeneralApplicationSettingsBase, IDefaultApplicationSettingsBase> {
    // Empty for now, but we can create our own user settings here
}

export interface IApplicationModelDisplayOptionsPe {
    baseplateDimensions: GeneralValue<boolean>;
    concreteDimensions: GeneralValue<boolean>;
    handrailDimensions: GeneralValue<boolean>;
    profileMeasurments: GeneralValue<boolean>;
    anchorLayoutMeasurments: GeneralValue<boolean>;
    anchorLayoutMeasurments2d: GeneralValue<boolean>;
    loads: GeneralValue<boolean>;
    loadsHeight: GeneralValue<boolean>;
    anchorNumbering: GeneralValue<boolean>;
    plateNumbering: GeneralValue<boolean>;
    rotation: GeneralValue<boolean>;
    anchorSpacing: GeneralValue<boolean>;
    anchorSpacing2d: GeneralValue<boolean>;
    baseMaterialSpacing: GeneralValue<boolean>;
    profileCenterToConcreteEdge: GeneralValue<boolean>;
    profileEdgeToConcreteEdge: GeneralValue<boolean>;
    profileEdgeToBaseplateEdge: GeneralValue<boolean>;
    transparentBaseMaterial: GeneralValue<boolean>;
    profileCenterToBaseplateCenter: GeneralValue<boolean>;
    mesh: GeneralValue<boolean>;
    transparentProfile: GeneralValue<boolean>;
    stiffener2d: GeneralValue<boolean>;
    supplementaryReinforcementDimensions: GeneralValue<boolean>;
    toolTips: GeneralValue<boolean>;
    anchorCenterToConcreteEdge: GeneralValue<boolean>;
    shearLugMeasurements: GeneralValue<boolean>;
}

export class UserSettingsPe extends UserSettingsCommon<IApplicationImportLoadsBase, IQuickStartSettingsPe, ApplicationSettingsPe, IApplicationModelDisplayOptionsPe> {
    public profileSizeFavorites: GeneralValue<{ [profileSizeId: number]: void }>;
    public steelTypeFavorites: GeneralValue<{ [steelTypeId: number]: void }>;
    public anchorFavorites: GeneralValue<{ [anchorId: number]: void }>;

    public concretevirtualtourSeen: GeneralValue<boolean | null>;
    public handrailvirtualtourSeen: GeneralValue<boolean | null>;
    public smartanchorvirtualtourSeen: GeneralValue<boolean | null>;
    public searchSolutionsCount: GeneralValue<number | null>;
    public smartdesignvirtualtourseen:  GeneralValue<boolean | null>;

    public generateReportFollowUpActionsConcreteHidden: GeneralValue<boolean | null>;

    constructor(translate: boolean) {
        super();

        this.quickStart = {
            concrete: {
                generalRegionId: new GeneralValue(null),
                permenentLoads: new GeneralValue(null),
                variableLoads: new GeneralValue(null),
                anchorPlateFactor: new GeneralValue(null),
                minimumAnchorToProfileDistance: new GeneralValue(null),
                concreteSafetyFactorGammaC: new GeneralValue(null),
                concreteSafetyFactorGammaCFreeValue: new GeneralValue(null),
                materialSafetyFactor: new GeneralValue(null),
                edgeDistanceDisplayTypeId: new GeneralValue(null),
                calculationDesignStandardId: new GeneralValue(null),
                calculationDesignMethodGroupId: new GeneralValue(null),
                calculationEtagEnOnly: new GeneralValue(null),
                steelCapacityFactor: new GeneralValue(null),
                weldsCapacityFactor: new GeneralValue(null),
                concreteCapacityFactor: new GeneralValue(null),
                steelGuideline: new GeneralValue(null),
                minimumConcreteCover: new GeneralValue(null),
                inSteelSafetyFactorGammaM0: new GeneralValue(null),
                inSteelSafetyFactorGammaM1: new GeneralValue(null),
                inSteelSafetyFactorGammaMb: new GeneralValue(null),
                inSteelSafetyFactorGammaMw: new GeneralValue(null),
                steelSafetyFactorGammaM0: new GeneralValue(null),
                steelSafetyFactorGammaM1: new GeneralValue(null),
                steelSafetyFactorGammaM2: new GeneralValue(null),
                steelSafetyFactorGammaM5: new GeneralValue(null),
                unitsAreaId: new GeneralValue(null),
                unitsDensityId: new GeneralValue(null),
                unitsForceId: new GeneralValue(null),
                unitsForcePerLengthId: new GeneralValue(null),
                unitsLengthId: new GeneralValue(null),
                unitsMomentId: new GeneralValue(null),
                unitsMomentPerLengthId: new GeneralValue(null),
                unitsStressId: new GeneralValue(null),
                unitsTemperatureId: new GeneralValue(null),
                measureBaseMaterialEdgeFrom: new GeneralValue(null),
                measureAnchorPlate: new GeneralValue(null),
            },

            masonry: {
                generalRegionId: new GeneralValue(null),
                permenentLoads: new GeneralValue(null),
                variableLoads: new GeneralValue(null),
                anchorPlateFactor: new GeneralValue(null),
                minimumAnchorToProfileDistance: new GeneralValue(null),
                calculationDesignStandardId: new GeneralValue(null),
                calculationDesignMethodGroupId: new GeneralValue(null),
                calculationEtaData: new GeneralValue(null),
                calculationHiltiTechnicalData: new GeneralValue(null),
                calculationOstInputsData: new GeneralValue(null),
                edgeDistanceDisplayTypeId: new GeneralValue(null),
                steelSafetyFactorGammaM0: new GeneralValue(null),
                steelSafetyFactorGammaM1: new GeneralValue(null),
                steelSafetyFactorGammaM2: new GeneralValue(null),
                steelSafetyFactorGammaM5: new GeneralValue(null),
                unitsAreaId: new GeneralValue(null),
                unitsForceId: new GeneralValue(null),
                unitsLengthId: new GeneralValue(null),
                unitsMomentId: new GeneralValue(null),
                unitsStressId: new GeneralValue(null),
                unitsTemperatureId: new GeneralValue(null)
            },

            handrail: {
                generalRegionId: new GeneralValue(null),
                permenentLoads: new GeneralValue(null),
                variableLoads: new GeneralValue(null),
                anchorPlateFactor: new GeneralValue(null),
                minimumAnchorToProfileDistance: new GeneralValue(null),
                displacementLimit: new GeneralValue(null),
                calculationDesignStandardId: new GeneralValue(null),
                calculationDesignMethodGroupId: new GeneralValue(null),
                edgeDistanceDisplayTypeId: new GeneralValue(null),
                displacementLimitBridges: new GeneralValue(null),
                calculationEtagEnOnly: new GeneralValue(null),
                steelSafetyFactorGammaM0: new GeneralValue(null),
                steelSafetyFactorGammaM1: new GeneralValue(null),
                steelSafetyFactorGammaM2: new GeneralValue(null),
                steelSafetyFactorGammaM5: new GeneralValue(null),
                unitsAreaId: new GeneralValue(null),
                unitsForceId: new GeneralValue(null),
                unitsForcePerLengthId: new GeneralValue(null),
                unitsLengthId: new GeneralValue(null),
                unitsLengthLargeId: new GeneralValue(null),
                unitsMomentId: new GeneralValue(null),
                unitsStressId: new GeneralValue(null),
                unitsStressSmallId: new GeneralValue(null),
                unitsTemperatureId: new GeneralValue(null)
            },

            metalDeck: {
                generalRegionId: new GeneralValue(null),
                anchorPlateFactor: new GeneralValue(null),
                minimumAnchorToProfileDistance: new GeneralValue(null),
                edgeDistanceDisplayTypeId: new GeneralValue(null),
                calculationDesignStandardId: new GeneralValue(null),
                calculationDesignMethodGroupId: new GeneralValue(null),
                materialSafetyFactor: new GeneralValue(null),
                steelGuideline: new GeneralValue(null),
                steelSafetyFactorGammaM0: new GeneralValue(null),
                steelSafetyFactorGammaM1: new GeneralValue(null),
                steelSafetyFactorGammaM2: new GeneralValue(null),
                steelSafetyFactorGammaM5: new GeneralValue(null),
                unitsAreaId: new GeneralValue(null),
                unitsDensityId: new GeneralValue(null),
                unitsForceId: new GeneralValue(null),
                unitsForcePerLengthId: new GeneralValue(null),
                unitsLengthId: new GeneralValue(null),
                unitsMomentId: new GeneralValue(null),
                unitsMomentPerLengthId: new GeneralValue(null),
                unitsStressId: new GeneralValue(null),
                unitsTemperatureId: new GeneralValue(null)
            }
        };

        this.application = {
            general: UserSettingsCommon.getBaseGeneralApplicationSettingsDefaults(translate),
            defaults: UserSettingsCommon.getBaseDefaultApplicationSettingsDefaults(),
            controls: UserSettingsCommon.getBaseControlsSettingsDefaults(),
            advancedCalculation: UserSettingsCommon.getBaseAdvancedCalculationApplicationSettingsDefaults(),
        };

        this.profileSizeFavorites = new GeneralValue({});
        this.steelTypeFavorites = new GeneralValue({});
        this.anchorFavorites = new GeneralValue({});
        this.applicationImportLoads = UserSettingsCommon.getBaseApplicationImportLoadsDefaults();

        this.concretevirtualtourSeen = new GeneralValue(null);
        this.handrailvirtualtourSeen = new GeneralValue(null);
        this.smartanchorvirtualtourSeen = new GeneralValue(null);
        this.searchSolutionsCount = new GeneralValue(null);
        this.smartdesignvirtualtourseen = new GeneralValue(null);

        this.generateReportFollowUpActionsConcreteHidden = new GeneralValue(null);

        this.applicationModelDisplayOptions = {
            baseplateDimensions: new GeneralValue(true),
            concreteDimensions: new GeneralValue(true),
            handrailDimensions: new GeneralValue(true),
            profileMeasurments: new GeneralValue(false),
            anchorLayoutMeasurments: new GeneralValue(true),
            anchorLayoutMeasurments2d: new GeneralValue(true),
            loads: new GeneralValue(true),
            loadsHeight: new GeneralValue(true),
            anchorNumbering: new GeneralValue(false),
            plateNumbering: new GeneralValue(true),
            rotation: new GeneralValue(true),
            anchorSpacing: new GeneralValue(true),
            anchorSpacing2d: new GeneralValue(true),
            baseMaterialSpacing: new GeneralValue(true),
            profileCenterToConcreteEdge: new GeneralValue(true),
            profileEdgeToConcreteEdge: new GeneralValue(false),
            profileEdgeToBaseplateEdge: new GeneralValue(false),
            transparentBaseMaterial: new GeneralValue(true),
            profileCenterToBaseplateCenter: new GeneralValue(true),
            mesh: new GeneralValue(false),
            transparentProfile: new GeneralValue(false),
            stiffener2d: new GeneralValue(true),
            supplementaryReinforcementDimensions: new GeneralValue(true),
            toolTips: new GeneralValue(false),
            anchorCenterToConcreteEdge: new GeneralValue(true),
            shearLugMeasurements: new GeneralValue(true)
        };
    }
}
