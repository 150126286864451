import { Component, Input } from '@angular/core';
import { ChangedConvertedProperties } from '../../../services/design.service';

@Component({
    selector: 'app-design-verification-changes-loop',
    styleUrls: ['./design-verification-loop-helper.component.scss'],
    templateUrl: './design-verification-loop-helper.component.html',
})
export class DesignVerificationChangesLoopComponent {
    @Input()
    public changedProperties: ChangedConvertedProperties[] | undefined = [];
}
