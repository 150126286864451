import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewEncapsulation } from '@angular/core';
import effectiveHeightImage from '../../../../images/a_s-and-d.png' with { loader: 'file' };
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';
import { imageUrl } from '../../../../image';
import { L10nDirective } from '../../../../directives/l10n.directive';

@Component({
    templateUrl: './info-dialog-effective-height.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [L10nDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InfoDialogEffectiveHeighComponent extends InfoDialogBaseComponent {
    public effectiveHeightImage = imageUrl(effectiveHeightImage);

    public getTitle(): string {
        return this.translate('SP.ExistingReinforcement.d.InfoPopUp.Title');
    }
}
