import { CodeList } from './code-list';
export class DesignType extends CodeList {
  allowedDesignStandardRegions;
  allowedDesignMethodGroupRegions;
  constructor(codeList) {
    super(codeList);
    if (codeList != null) {
      this.allowedDesignStandardRegions = codeList.allowedDesignStandardRegions;
      this.allowedDesignMethodGroupRegions = codeList.allowedDesignMethodGroupRegions;
    }
  }
  cloneDeep() {
    return super.cloneDeep();
  }
}
