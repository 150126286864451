import { ChangeDetectorRef, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { CheckboxButtonProps } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { IAppSettingsComponent } from '@profis-engineering/pe-ui-common/entities/app-settings-component';
import { getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { AppSettingsNumericTextBoxProps, SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { format, formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    DesignStandard as DesignStandardEnum, DesignType
} from '../../../shared/generated-modules//Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { FactorType, IMasonryFixingData } from '../../../shared/helpers/app-settings-helper';
import { AppSettingsHelperWithUnit } from '../../helpers/app-settings-helper';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';

const MasonryETAData = 1;
const MasonryOstInputsData = 2;

@Component({
    templateUrl: './app-settings-masonry-fixing.component.html',
    styleUrls: ['./app-settings-masonry-fixing.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AppSettingsMasonryFixingComponent implements IAppSettingsComponent<IMasonryFixingData>, OnChanges {
    @Input()
    public selectedRegionId!: number;

    @Input()
    public decimalSeparatorId!: number;

    @Input()
    public thousandsSeparatorId!: number;

    @Input()
    public submitted = false;

    @Input()
    public settings: IMasonryFixingData = {
        masonryRegion: KnownRegion.Unknown
    };

    public get masonryFixing() {
        return this.settings;
    }

    public masonryRegionDropdown!: DropdownProps<number>;
    public masonryLengthDropdown!: DropdownProps<number>;
    public masonryAreaDropdown!: DropdownProps<number>;
    public masonryStressDropdown!: DropdownProps<number>;
    public masonryForceDropdown!: DropdownProps<number>;
    public masonryMomentDropdown!: DropdownProps<number>;
    public masonryTemperatureDropdown!: DropdownProps<number>;
    public masonryTechnicalData!: CheckboxButtonProps<number>;
    public masonryAnchorPlateFactor!: AppSettingsNumericTextBoxProps;
    public masonrySafetyFactorForPermanentLoads!: AppSettingsNumericTextBoxProps;
    public masonrySafetyFactorForVariableLoads!: AppSettingsNumericTextBoxProps;
    public masonryEdgeDistanceDisplay!: DropdownProps<number>;
    public masonryDesignStandardDropdown!: DropdownProps<number>;
    public masonryDesignMethodDropdown!: DropdownProps<number>;
    public masonryMinimumAnchorToProfileDistance!: AppSettingsNumericTextBoxProps;

    private technicalData!: boolean;
    private readonly appSettingsHelper!: AppSettingsHelperWithUnit;

    public dataLoaded = false;

    constructor(
        private readonly localization: LocalizationService,
        private readonly codeList: CodeListService,
        private readonly commonCodeList: CommonCodeListService,
        private readonly userSettings: UserSettingsService,
        private readonly unit: UnitService,
        private readonly numberService: NumberService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        sharedEnvironmentService: SharedEnvironmentService,
    ) {
        this.appSettingsHelper = new AppSettingsHelperWithUnit(
            this.localization,
            this.userSettings,
            this.codeList,
            this.commonCodeList,
            this.unit,
            this.numberService,
            sharedEnvironmentService.data?.useDevFeatures ?? false
        );
    }

    public get allowMasonryStatus() {
        let translation = '';

        if (this.allowMasonryQuickStart) {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Masonry.Status.Ok');
            translation = format(translation, this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.None'));
        }
        else {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Masonry.Status.Error');
            const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);
            translation = formatKeyValue(translation, { region: region.getTranslatedNameText(getCodeListTextDeps(this.localization, this.numberService)) ?? '' });
        }

        return translation;
    }

    public get allowMasonryQuickStart() {
        const nonDefaultValue = this.masonryFixing.masonryRegion < 0
            ? this.selectedRegionId
            : this.masonryFixing.masonryRegion;

        return this.appSettingsHelper.designTypeMasonry?.allowedDesignStandardRegions?.some((row) => row.RegionId == nonDefaultValue);
    }

    public get isMasonryNone() {
        return this.masonryFixing.masonryRegion != SpecialRegion.None;
    }

    public get areSafetyFactorsForMasonryVisible() {
        return this.masonryFixing.masonryDesignStandardId != DesignStandardEnum.ACI;
    }

    public get isHiltiTechnicalDataRelevantMasonry() {
        return this.masonryFixing.masonryDesignStandardId == DesignStandardEnum.EOTA;
    }

    public get selectedMasonryDesignStandardDescription() {
        return this.appSettingsHelper.getDesignStandardDescription(this.localization, DesignType.Masonry, this.masonryDesignStandard);
    }

    public get selectedMasonryDesignMethodDescription() {
        return this.appSettingsHelper.getDesignMethodDescription(this.masonryDesignMethodGroup);
    }

    public get getNumericTextBoxDecimalSeparator() {
        return this.appSettingsHelper.getNumericTextBoxDecimalSeparatorById(this.decimalSeparatorId);
    }

    public get getNumericTextBoxGroupSeparator() {
        return this.appSettingsHelper.getNumericTextBoxGroupSeparatorById(this.thousandsSeparatorId);
    }

    private get masonryDesignStandard() {
        return this.appSettingsHelper.getDesignStandardById(this.masonryFixing.masonryDesignStandardId);
    }

    private get masonryDesignMethodGroup() {
        return this.appSettingsHelper.getDesignMethodGroupById(this.masonryFixing.masonryDesignMethodGroupId);
    }

    ngOnChanges(): void {
        if (!this.dataLoaded) {
            this.createControlsGeneral();
            this.createControlsRegion();

            const masonryRegionId = this.userSettings.settings.quickStart.masonry.generalRegionId.value ?? SpecialRegion.Default;
            if (masonryRegionId == SpecialRegion.Default) {
                // Default region
                const region = this.appSettingsHelper.getRegionById(this.userSettings.settings.application.general.regionId.value ?? SpecialRegion.Default);
                this.technicalData = region.noHiltiTechnicalDataBrick ?? false;
            }
            else {
                // Overriden region region
                const region = this.appSettingsHelper.getRegionById(masonryRegionId);
                this.technicalData = this.userSettings.settings.quickStart.masonry.calculationHiltiTechnicalData.value ?? region.noHiltiTechnicalDataBrick ?? false;
            }

            if (this.masonryFixing.masonryTechnicalDataEtaData && this.masonryTechnicalData.selectedValues && this.masonryTechnicalData.items) {
                this.masonryTechnicalData.selectedValues.add(MasonryETAData);
                this.masonryTechnicalData.items[0].disabled = this.technicalData;
            }

            if (this.masonryFixing.masonryTechnicalDataOSTInput && this.masonryTechnicalData.selectedValues) {
                this.masonryTechnicalData.selectedValues.add(MasonryOstInputsData);
            }

            this.createControlCalculation();

            this.dataLoaded = true;
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public isMinimumAnchorToProfileDistanceAvailable(designStandard?: DesignStandardEnum) {
        return this.appSettingsHelper.isMinimumAnchorToProfileDistanceAvailable(designStandard);
    }

    public onMasonryRegionDropdownChange(regionId: number) {
        this.masonryFixing.masonryRegion = regionId;
        this.updateQuickStartMasonry(regionId);
        this.changeDetectorRef.detectChanges();
    }

    public onMasonryTechnicalDataChange() {
        this.masonryFixing.masonryTechnicalDataOSTInput = this.masonryTechnicalData.selectedValues?.has(MasonryOstInputsData) ?? false;
        this.masonryFixing.masonryTechnicalDataEtaData = this.masonryTechnicalData.selectedValues?.has(MasonryETAData) ?? false;
        this.changeDetectorRef.detectChanges();
    }

    public getNumericTextBoxUnit(property: string) {
        const regionId = this.masonryFixing.masonryRegion == SpecialRegion.Default
            ? this.selectedRegionId
            : this.masonryFixing.masonryRegion;
        const region = this.appSettingsHelper.getRegionById(regionId ?? SpecialRegion.Default);
        switch (property) {
            case 'defaultAnchorPlateThickness':
            case 'defaultMinimumAnchorToProfileDistance':
            case 'defaultMinimumConcreteCover':
                return region.defaultUnitLength;

            case 'masonryMinimumAnchorToProfileDistance':
                return this.masonryFixing.masonryLength;

            default:
                return Unit.None;
        }
    }

    public getNumericTextBoxPlaceholder(property: string): number | string {
        const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);

        switch (property) {
            case 'defaultAnchorPlateThickness':
                return region.thickness;

            case 'defaultVariableLoads':
                return region.safetyFactorForVariableLoads;

            case 'defaultPermanentLoads':
                return region.safetyFactorForPermamentLoads;

            case 'defaultAnchorPlateFactor':
                return region.anchorPlateFactor;

            case 'masonryMinimumAnchorToProfileDistance':
                return this.appSettingsHelper.bindMinimumAnchorToProfileDistanceDefault(this.masonryFixing.masonryDesignStandardId, this.masonryFixing.masonryLength);

            case 'masonryAnchorPlateFactor':
                return this.appSettingsHelper.bindDefaultFactor(this.masonryFixing.masonryRegion, region, FactorType.AnchorPlateFactor, this.userSettings.settings);

            case 'masonrySafetyFactorForPermanentLoads':
                return this.appSettingsHelper.bindDefaultFactor(this.masonryFixing.masonryRegion, region, FactorType.SafetyFactorPermanentLoads, this.userSettings.settings);

            case 'masonrySafetyFactorForVariableLoads':
                return this.appSettingsHelper.bindDefaultFactor(this.masonryFixing.masonryRegion, region, FactorType.SafetyFactorVariableLoads, this.userSettings.settings);

            default:
                return this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }
    }

    public onMasonryDesignStandardChange(designStandardId?: number) {
        this.masonryFixing.masonryDesignStandardId = designStandardId;

        // Design standards
        this.modifyMasonryDesignStandardDropdown();

        // Design methods
        this.modifyMasonryDesignMethodDropdown();

        this.changeDetectorRef.detectChanges();
    }

    public onMasonryDesignMethodChange(designMethodGroupId: number) {
        this.masonryFixing.masonryDesignMethodGroupId = designMethodGroupId;
        this.changeDetectorRef.detectChanges();
    }

    private updateQuickStartMasonry(regionId: number) {
        if (regionId != SpecialRegion.None || this.masonryFixing.masonryRegion == null) {
            regionId = regionId < 0
                ? this.selectedRegionId ?? SpecialRegion.Default
                : regionId;

            const region = this.appSettingsHelper.getRegionById(regionId);
            this.appSettingsHelper.setDefaultMasonryUnits(this.masonryFixing, region);
            this.appSettingsHelper.setDefaultMasonryDesignStandard(this.masonryFixing, region);
            this.appSettingsHelper.setDefaultMasonryDesignMethodGroup(this.masonryFixing, region);
            this.onMasonryDesignStandardChange(this.masonryFixing.masonryDesignStandardId);

            if (!this.masonryTechnicalData.selectedValues?.has(MasonryETAData)) {
                this.masonryTechnicalData.selectedValues?.add(MasonryETAData);
            }

            if (!this.masonryTechnicalData.selectedValues?.has(MasonryOstInputsData)) {
                this.masonryTechnicalData.selectedValues?.add(MasonryOstInputsData);
            }

            this.technicalData = region.noHiltiTechnicalDataBrick ?? false;
            if (this.masonryTechnicalData.items) {
                this.masonryTechnicalData.items[0].disabled = this.technicalData;
            }
        }
    }

    private modifyMasonryDesignMethodDropdown() {
        const designMethodGroups = this.appSettingsHelper.getDesignMethodGroupsByDesignType(
            this.appSettingsHelper.designTypeMasonry,
            this.masonryFixing.masonryDesignStandardId,
            this.masonryFixing.masonryRegion,
            this.selectedRegionId);

        if (!designMethodGroups.some((designMethodGroup) => designMethodGroup.id == this.masonryFixing.masonryDesignMethodGroupId)) {
            const masonryRegion = this.appSettingsHelper.getRegionById(this.masonryFixing.masonryRegion == SpecialRegion.Default ? this.selectedRegionId : this.masonryFixing.masonryRegion);
            this.appSettingsHelper.setDefaultMasonryDesignMethodGroup(this.masonryFixing, masonryRegion);
        }

        this.masonryDesignMethodDropdown.items = designMethodGroups.map((designMethodGroup) => ({
            value: designMethodGroup.id,
            text: this.appSettingsHelper.getDesignMethodGroupTitle(designMethodGroup),
        }) as DropdownItem<number>);

        this.masonryDesignMethodDropdown.selectedValue = this.masonryFixing.masonryDesignStandardId;
    }

    private modifyMasonryDesignStandardDropdown() {
        const masonryDesignStandards = this.appSettingsHelper.getDesignStandardsByDesignType(this.appSettingsHelper.designTypeMasonry, this.masonryFixing.masonryRegion, this.selectedRegionId);

        this.masonryDesignStandardDropdown.items = masonryDesignStandards.map(designStandard => ({
            value: designStandard.id,
            text: this.appSettingsHelper.getDesignStandardTitle(this.localization, DesignType.Masonry, designStandard),
        }) as DropdownItem<number>);

        this.masonryDesignStandardDropdown.selectedValue = this.masonryFixing.masonryDesignMethodGroupId;
    }

    private createControlsGeneral() {
        this.masonryMinimumAnchorToProfileDistance = this.appSettingsHelper.createMinimumAnchorToProfileDistanceComponent('-masonry');

        this.createControlsUnit();

        this.masonryEdgeDistanceDisplay = this.appSettingsHelper.createPeCodeListDropdown(ProjectCodeList.EdgeDistanceDisplayType, 'MasonryEdgeDistance');
        this.masonryEdgeDistanceDisplay.tooltip = ({
            title: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MasonryEdgeDistance.Tooltip.Title'),
            content: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MasonryEdgeDistance.Tooltip')
        });

        this.masonryAnchorPlateFactor = this.appSettingsHelper.createAnchorPlateFactorComponent('masonry-anchor-plate-factor', 'Masonry.AnchorPlateFactor');
        this.masonryAnchorPlateFactor.tooltip = {
            title: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip.Title'),
            content: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip')
        };
        this.masonrySafetyFactorForPermanentLoads = this.appSettingsHelper.createPermanentLoadsComponent('masonry-permanent-loads', 'Masonry.SafetyFactorForPermamentLoads');
        this.masonrySafetyFactorForVariableLoads = this.appSettingsHelper.createVariableLoadsComponent('masonry-variable-loads', 'Masonry.SafetyFactorForVariableLoads');

        this.masonryTechnicalData = {
            items: [
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Masonry.ETAData'),
                    value: MasonryETAData,
                    disabled: this.technicalData,
                    description: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Masonry.ETAData.Description', { optional: true }),
                    id: 'add-edit-design-eta-data'
                },
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Masonry.OSTInputsData'),
                    value: MasonryOstInputsData,
                    description: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Masonry.OSTInputsData.Description', { optional: true }),
                    id: 'add-edit-design-ost-inputs-data'
                }
            ],
            selectedValues: new Set()
        };
    }

    private createControlsUnit() {
        this.masonryLengthDropdown = this.appSettingsHelper.createUnitDropdown('masonry-length', 'Masonry.Length', CommonCodeList.UnitLength);
        this.masonryAreaDropdown = this.appSettingsHelper.createUnitDropdown('masonry-area', 'Masonry.Area', CommonCodeList.UnitArea);
        this.masonryStressDropdown = this.appSettingsHelper.createUnitDropdown('masonry-stress', 'Masonry.Stress', CommonCodeList.UnitStress);
        this.masonryForceDropdown = this.appSettingsHelper.createUnitDropdown('masonry-force', 'Masonry.Force', CommonCodeList.UnitForce);
        this.masonryMomentDropdown = this.appSettingsHelper.createUnitDropdown('masonry-moment', 'Masonry.Moment', CommonCodeList.UnitMoment);
        this.masonryTemperatureDropdown = this.appSettingsHelper.createUnitDropdown('masonry-temperature', 'Masonry.Temperature', CommonCodeList.UnitTemperature);

        const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);
        this.masonryFixing.masonryLength = this.appSettingsHelper.ensureUnitSupported(this.masonryLengthDropdown.items, this.masonryFixing.masonryLength, region.defaultUnitLength);
        this.masonryFixing.masonryArea = this.appSettingsHelper.ensureUnitSupported(this.masonryAreaDropdown.items, this.masonryFixing.masonryArea, region.defaultUnitArea);
        this.masonryFixing.masonryStress = this.appSettingsHelper.ensureUnitSupported(this.masonryStressDropdown.items, this.masonryFixing.masonryStress, region.defaultUnitStress);
        this.masonryFixing.masonryForce = this.appSettingsHelper.ensureUnitSupported(this.masonryForceDropdown.items, this.masonryFixing.masonryForce, region.defaultUnitForce);
        this.masonryFixing.masonryMoment = this.appSettingsHelper.ensureUnitSupported(this.masonryMomentDropdown.items, this.masonryFixing.masonryMoment, region.defaultUnitMoment);
        this.masonryFixing.masonryTemperature = this.appSettingsHelper.ensureUnitSupported(this.masonryTemperatureDropdown.items, this.masonryFixing.masonryTemperature, region.defaultUnitTemperature);

        this.appSettingsHelper.setUnitDropdownValue(this.masonryLengthDropdown, this.masonryFixing.masonryLength);
        this.appSettingsHelper.setUnitDropdownValue(this.masonryAreaDropdown, this.masonryFixing.masonryArea);
        this.appSettingsHelper.setUnitDropdownValue(this.masonryStressDropdown, this.masonryFixing.masonryStress);
        this.appSettingsHelper.setUnitDropdownValue(this.masonryForceDropdown, this.masonryFixing.masonryForce);
        this.appSettingsHelper.setUnitDropdownValue(this.masonryMomentDropdown, this.masonryFixing.masonryMoment);
        this.appSettingsHelper.setUnitDropdownValue(this.masonryTemperatureDropdown, this.masonryFixing.masonryTemperature);
    }

    private createControlsRegion() {
        this.masonryRegionDropdown = this.appSettingsHelper.createDropdownComponent(
            'masonry-region',
            'Masonry.Region',
            this.appSettingsHelper.getAvailableRegions(this.appSettingsHelper.designTypeMasonry)
                .map(region => ({
                    value: region.id,
                    text: this.localization.getString(region.nameResourceKey)
                }) as DropdownItem<number>)
        );
    }

    private createControlCalculation() {
        // Masonry design standard
        this.masonryDesignStandardDropdown = this.appSettingsHelper.createDropdownComponent<number>(
            'masonry-design-standard-dropdown', 'Masonry.DesignStandard', []);
        this.modifyMasonryDesignStandardDropdown();

        // Masonry design method
        this.masonryDesignMethodDropdown = this.appSettingsHelper.createDropdownComponent<number>(
            'masonry-design-method-group-dropdown', 'Masonry.DesignMethod', []);
        this.modifyMasonryDesignMethodDropdown();
    }
}
