import { CodeList } from './code-list';
import { ZonesNumberEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class ZonesNumber extends CodeList {

    public static fromService(codeList: ZonesNumberEntityC2C) {
        return new ZonesNumber(CodeList.baseFromService('ZonesNumberEntity', codeList));
    }

    public override cloneDeep(): ZonesNumber {
        return super.cloneDeep() as ZonesNumber;
    }
}
