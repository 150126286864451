import { Matrix } from '@babylonjs/core/Maths/math.vector.js';
import { CacheItem } from './cache-item.js';
import { CommonCache } from './common-cache.js';
export class MatrixCache extends CacheItem {
  get rotationY45() {
    return this.create('Matrix.rotationY45', () => Matrix.RotationY(Math.PI / 4));
  }
  get rotationY90() {
    return this.create('Matrix.rotationY90', () => Matrix.RotationY(Math.PI / 2));
  }
  get rotationY180() {
    return this.create('Matrix.rotationY180', () => Matrix.RotationY(Math.PI));
  }
  get rotationY270() {
    return this.create('Matrix.rotationY270', () => Matrix.RotationY(Math.PI + Math.PI / 2));
  }
  get rotationX90() {
    return this.create('Matrix.rotationX90', () => Matrix.RotationX(Math.PI / 2));
  }
  get rotationX180() {
    return this.create('Matrix.rotationX180', () => Matrix.RotationX(Math.PI));
  }
  get rotationX270() {
    return this.create('Matrix.rotationX270', () => Matrix.RotationX(Math.PI + Math.PI / 2));
  }
  get rotationZ90() {
    return this.create('Matrix.rotationZ90', () => Matrix.RotationZ(Math.PI / 2));
  }
  get translateYCylinderSizeHalf() {
    return this.create('Matrix.translateYCylinderSizeHalf', () => Matrix.Translation(0, CommonCache.cylinderSizeHalf, 0));
  }
  get translateMinusYCylinderSize() {
    return this.create('Matrix.translateMinusYCylinderSize', () => Matrix.Translation(0, -CommonCache.cylinderSize, 0));
  }
  get identity() {
    return this.create('Matrix.identity', () => Matrix.Identity());
  }
}
