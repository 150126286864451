import {
    Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, SimpleChanges, ViewEncapsulation
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    IAddEditDesignComponent, IDetailedDisplayDesignFromModule
} from '@profis-engineering/pe-ui-common/entities/add-edit-design-component';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Unit as UnitItem } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import {
    ISaveDesign, ISaveDesignResult
} from '@profis-engineering/pe-ui-common/entities/save-design';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import {
    getNumberDecimalSeparator, getNumberGroupSeparator
} from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { UnitType as Unit, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';

import { IAdvancedRebarSettingsInputsData } from '../../../shared/entities/advanced-rebar-settings';
import {
    MethodGroupConnTypeStandardRegion
} from '../../../shared/entities/code-lists/connection-type-method-standard-regions';
import { DesignMethodGroup } from '../../../shared/entities/code-lists/design-method-group';
import { DesignStandard } from '../../../shared/entities/code-lists/design-standard';
import { DesignType as DesignTypeEntity } from '../../../shared/entities/code-lists/design-type';
import { UIProperty } from '../../../shared/entities/code-lists/UI-property';
import {
    IConcreteMaterialInputsData, setConcreteMaterialValuesToDesign,
    setConcreteMaterialValuesToNewDesign
} from '../../../shared/entities/concrete-material-inputs-data';
import {
    IConcreteMaterialSettingsInputsData
} from '../../../shared/entities/concrete-material-settings';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import {
    detailedDisplayDesignToProjectDesignC2C, IDetailedDisplayDesign,
    projectDesignToDetailedDisplayDesign
} from '../../../shared/entities/display-design';
import {
    IInterfaceShearCalculationData, setInterfaceShearCalculationValuesToDesign,
    setInterfaceShearCalculationValuesToNewDesign
} from '../../../shared/entities/interface-shear-calculation-data';
import {
    IOptimizationStrategyInputsData, setOptimizationStrategyValuesToDesign,
    setOptimizationStrategyValuesToNewDesign
} from '../../../shared/entities/optimization-strategy-inputs-data';
import {
    IRebarCalulationInputsData, setRebarCalculationValuesToDesign,
    setRebarCalculationValuesToNewDesign
} from '../../../shared/entities/rebar-calulation-inputs-data';
import {
    IReductionFactorsInputsData, setReductionFactorsValuesToDesign,
    setReductionFactorsValuesToNewDesign
} from '../../../shared/entities/reduction-factors-inputs-data';
import {
    ISafetyFactorsInputsData, setSafetyFactorsValuesToDesign, setSafetyFactorsValuesToNewDesign
} from '../../../shared/entities/safety-factors-inputs-data';
import {
    ISelectConnectionApplicationData, loadSelectConnectionApplicationDataDefaults
} from '../../../shared/entities/select-connection-application-data';
import {
    ISteelMaterialInputsData, setSteelMaterialValuesToDesign, setSteelMaterialValuesToNewDesign
} from '../../../shared/entities/steel-material-inputs-data';
import {
    IStrutTiesInputsData, setStrutTiesModelCalculationValuesToDesign,
    setStrutTiesModelCalculationValuesToNewDesign
} from '../../../shared/entities/strut-ties-inputs-data';
import { DesignType } from '../../../shared/entities/tracking-data';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    NewDesignDataEntityC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    DesignOptionsASbased
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options';
import {
    InterfaceShearCalculationParametersAs, OptimizationStrategyOptionsAs,
    RebarCalculationParametersAs
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';
import {
    RebarCalculationParametersBaseAs
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.AsBased.Base';
import {
    ApplicationType, ConnectionType, DesignMethodGroup as DesignMethodGroupEnum,
    DesignStandard as DesignStandardEnum, MaximumAreaReinforcement, MetricEquation,
    MinimumAreaReinforcement, RebarDiameterMode, ReportPaperSize, ReportType, SpacingMaximum
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import {
    setAdvancedRebarSettingsValuesToDesign, setAdvancedRebarSettingsValuesToNewDesign
} from '../../../shared/helpers/advanced-rebar-settings';
import {
    setConcreteMaterialSettingsValuesToDesign, setConcreteMaterialSettingsValuesToNewDesign
} from '../../../shared/helpers/concrete-material-settings';
import { isOnlyOverlay } from '../../../shared/helpers/connection-type-helper';
import {
    getUKDesignMethodKey, getUKDesignStandardKey
} from '../../../shared/helpers/design-standard-methods-helper';
import { defaultUiPropertyValue } from '../../../shared/helpers/ui-property-helpers';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { IDesignInfo } from '../../entities/module-initial-data';
import { AppSettingsHelperC2C } from '../../helpers/app-settings-helper';
import { DesignMethodGroupHelper } from '../../helpers/design-method-group-helper';
import { getDesignTypeSpecificKey } from '../../helpers/localization-helper';
import { ApplicationProviderService } from '../../services/application-provider.service';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentServiceC2C } from '../../services/document.service';
import { FeaturesVisibilityService } from '../../services/features-visibility.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

export enum Step {
    basic,
    connectionApplication
}

export enum AfterOpenInstruction {
    ScrollToRebarCalculationSection = 2
}

interface IInitUnits {
    unitLength?: Unit;
    unitArea?: Unit;
    unitStress?: Unit;
    unitForce?: Unit;
    unitMoment?: Unit;
    unitTemperature?: Unit;
    unitForcePerLength?: Unit;
    unitMomentPerLength?: Unit;
    unitDensity?: Unit;
    unitAreaPerLength?: Unit;
}

@Component({
    templateUrl: './add-edit-design.component.html',
    styleUrls: ['./add-edit-design.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AddEditDesignComponent implements IAddEditDesignComponent, OnChanges {
    @Input()
    public designInfo?: IDesignInfo;

    @Input()
    public selectedRegionId!: number;

    @Input()
    public addEditType!: AddEditType;

    @Input()
    public submitted!: boolean;

    @Input()
    public afterOpenInstructions: number[] = [];

    @Input()
    public changeComponentModule?: EventEmitter<IDesignInfo>;

    @Input()
    public displayDesignType!: DisplayDesignType;

    @Input()
    public detailedDisplayDesignFromModule?: IDetailedDisplayDesignFromModule;

    @Input()
    public save = (saveDesign: ISaveDesign) => {
        // this save function should only be called from pe-ui
        return NgZone.isInAngularZone()
            ? this.saveInternal(saveDesign)
            : this.ngZone.run(() => {
                return this.saveInternal(saveDesign);
            });
    };

    @Input()
    public close = () => {
        // this close function should only be called from pe-ui
        NgZone.isInAngularZone()
            ? this.closeInternal()
            : this.ngZone.run(() => {
                return this.closeInternal();
            });
    };

    public connectionType: number = ConnectionType.ConcreteOverlay;
    public isLoaded = false;
    public designStandardId!: number | undefined;
    public designMethodGroupId!: number | undefined;
    public isDesignMethodVisible!: boolean;
    public isUseGermanNationalAnnexVisible!: boolean;

    public AddEditType = AddEditType;
    public stepEnum = Step;
    public step!: Step;
    public requiredValidator = Validators.required;

    public collapseRegion!: {
        MethodAndApprovals: boolean;
        UnitsAndParams: boolean;
        ReductionFactors: boolean;
        AdvancedRebarSettings: boolean;
        ConcreteMaterialSettings: boolean;
        ConcreteMaterial: boolean;
        SteelMaterial: boolean;
        SafetyFactor: boolean;
        RebarCalculation: boolean;
        InterfaceShearCalculation: boolean;
        StrutTiesModelCalculation: boolean;
        OptimizationStrategy: boolean;
    };

    public selectConnectionApplication!: ISelectConnectionApplicationData;
    public reductionFactorsInputsData!: IReductionFactorsInputsData;
    public rebarCalculationInputsData!: IRebarCalulationInputsData;
    public concreteMaterialInputsData!: IConcreteMaterialInputsData;
    public steelMaterialInputsData!: ISteelMaterialInputsData;
    public safetyFactorsInputsData!: ISafetyFactorsInputsData;
    public interfaceShearCalculationInputsData!: IInterfaceShearCalculationData;
    public strutTiesInputsData!: IStrutTiesInputsData;
    public optimizationStrategyInputsData!: IOptimizationStrategyInputsData;
    public advancedRebarSettingsInputsData!: IAdvancedRebarSettingsInputsData;
    public concreteMaterialSettingsInputsData!: IConcreteMaterialSettingsInputsData;

    // dropdowns
    public projectDesignStandardDropdown!: DropdownProps<number>;
    public metricEquationDropdown!: DropdownProps<number>;
    public stressDropdown!: DropdownProps<UnitItem>;
    public projectDesignMethodDropdown!: DropdownProps<number>;
    public lengthDropdown!: DropdownProps<UnitItem>;
    public areaDropdown!: DropdownProps<UnitItem>;
    public forceDropdown!: DropdownProps<UnitItem>;
    public momentDropdown!: DropdownProps<UnitItem>;
    public temperatureDropdown!: DropdownProps<UnitItem>;
    public forcePerLengthDropdown!: DropdownProps<UnitItem>;
    public momentPerLengthDropdown!: DropdownProps<UnitItem>;
    public densityDropdown!: DropdownProps<UnitItem>;
    public areaPerLengthDropdown!: DropdownProps<UnitItem>;
    // checkboxes
    public useGermanNationalAnnexCheckbox!: CheckboxButtonProps<boolean>;


    private fromQuickStart = false;
    private detailedDisplayDesign!: IDetailedDisplayDesign;
    private _designType!: DesignTypeEntity;
    private appSettingsHelper: AppSettingsHelperC2C;
    private _initUnits?: IInitUnits;

    constructor(
        private localization: LocalizationService,
        private codeList: CodeListService,
        private userService: UserService,
        private featureVisibilityService: FeaturesVisibilityService,
        private userSettingsService: UserSettingsService,
        private numberService: NumberService,
        private commonCodeList: CommonCodeListService,
        private calculationServiceC2C: CalculationServiceC2C,
        private documentService: DocumentServiceC2C,
        private sharedEnvironmentService: SharedEnvironmentService,
        private designTemplateService: DesignTemplateService,
        private ngZone: NgZone,
        private elementRef: ElementRef,
        private applicationProviderService: ApplicationProviderService
    ) {
        this.appSettingsHelper = new AppSettingsHelperC2C(this.localization, this.userSettingsService, this.commonCodeList, this.codeList, this.numberService);
    }

    public get designStandard() {
        if (this.designStandardId == null) {
            return null;
        }
        const designStandards = this.codeList.projectCodeListsC2C[ProjectCodeList.DesignStandardsC2C] as DesignStandard[];

        return designStandards.find((designStandard) => designStandard.id == this.designStandardId);
    }

    public get selectedDesignStandardDescription() {
        if (!this.designStandard) {
            return null;
        }

        return this.designStandard.descriptionResourceKey;
    }

    public get isHNAC2C() {
        return this.designStandard?.id == DesignStandardEnum.ACI
            || this.designStandard?.id == DesignStandardEnum.CSA;
    }

    public get isPirEuConnectionTypeSelected(): boolean {
        return this.isPirConnectionTypeSelected && this.designStandard?.id == DesignStandardEnum.ETAG;
    }

    public get isPirAusConnectionTypeSelected(): boolean {
        return this.isPirConnectionTypeSelected && this.designStandard?.id == DesignStandardEnum.ASBased;
    }

    public get isPirConnectionTypeSelected(): boolean {
        return [ConnectionType.Splices, ConnectionType.StructuralJoints].includes(this.selectConnectionApplication?.selectedConnectionType ?? ConnectionType.Unknown);
    }

    public get selectedDesignMethodDescription() {
        if (!this.designMethodGroup) {
            return null;
        }
        const uktaMethodGroup = getUKDesignMethodKey(this.selectedRegionId, this.designMethodGroup, this.featureVisibilityService.isFeatureEnabled('C2C_UKTA'));

        return uktaMethodGroup.nameResourceKey;
    }

    public get isOverlayOnly() {
        return isOnlyOverlay(this.codeList, this.selectedRegionId);
    }

    public get isAreaPerLengthDropdownAvailable() {
        return [ConnectionType.Splices, ConnectionType.StructuralJoints].some(x => x == this.currentConnectionType);
    }

    public get isMetricEquationDropdownAvailable() {
        return this.designStandard?.id == DesignStandardEnum.ACI;
    }

    public get isPirHnaC2C() {
        return this.isHNAC2C && this.isPirConnectionTypeSelected;
    }

    private get designMethodGroup() {
        if (this.designMethodGroupId == null) {
            return undefined;
        }
        const designMethods = this.codeList.projectCodeListsC2C[ProjectCodeList.DesignMethodGroupsC2C] as DesignMethodGroup[];
        return designMethods.find((method) => method.id == this.designMethodGroupId);
    }

    private get designStandards() {
        return this.appSettingsHelper.getDesignStandards(this.selectedRegionId, this.currentConnectionType);
    }

    private get designMethodGroups() {
        if (this.designStandardId == null) {
            return [] as DesignMethodGroup[];
        }

        const allMethodGroups = this.codeList.projectCodeListsC2C != null ?
            this.codeList.projectCodeListsC2C[ProjectCodeList.DesignMethodGroupsC2C] as DesignMethodGroup[] :
            [];

        const methodGroupConnTypeStandardRegions = this.codeList.projectCodeListsC2C != null ?
            this.codeList.projectCodeListsC2C[ProjectCodeList.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[] :
            [];

        let designMethodGroups = allMethodGroups
            .filter((methodGroup) => methodGroupConnTypeStandardRegions.some(x => methodGroup.id == x.designMethodGroupId && x.regionId == this.selectedRegionId &&
                x.designStandardId == this.designStandardId && x.connectionTypeId == this.currentConnectionType));


        if (designMethodGroups.some(x => x.id == DesignMethodGroupEnum.HiltiMethodTR066)) {
            const allowedApplications = this.featureVisibilityService.getFeatureValue('C2C_HiltiTR066Applications', []);
            if (!allowedApplications.some(app => app == this.currentApplicationType)) {
                designMethodGroups = designMethodGroups.filter(x => x.id != DesignMethodGroupEnum.HiltiMethodTR066);
            }
        }

        return designMethodGroups;
    }

    private get currentConnectionType(): number | undefined {
        let connectionType: number | undefined;
        if (this.selectConnectionApplication) {
            connectionType = this.selectConnectionApplication.selectedConnectionType;
        }
        else if (this.design) {
            connectionType = this.design.connectionType;
        }

        return connectionType;
    }

    private get currentApplicationType(): number | undefined {
        let applicationType: number | undefined;
        if (this.selectConnectionApplication) {
            applicationType = this.selectConnectionApplication.selectedApplicationType;
        }
        else if (this.design) {
            applicationType = this.design.applicationType;
        }

        return applicationType;
    }

    private get design() {
        return this.detailedDisplayDesign;
    }

    private get defaultMetricEquationForStress(): number {
        switch (this.stressDropdown.selectedValue?.id) {
            case Unit.Nmm2:
                return MetricEquation.SiMetric;
            case Unit.kgfcm2:
                return MetricEquation.MksMetric;
            default:
                return MetricEquation.UsCustomary;
        }
    }

    private get getUseGermanNationalAnnex() {
        if (!this.isUseGermanNationalAnnexVisible) {
            return false;
        }

        if ([AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)) {
            return true;
        }

        switch (this.selectConnectionApplication.selectedConnectionType) {
            case ConnectionType.Splices:
            case ConnectionType.StructuralJoints:
                return this.design.useGermanNationalAnnex ?? false;
            default:
                return false;
        }
    }

    private get designType() {
        if (this._designType == null) {
            const desingTypeCodeList = this.codeList.projectCodeListsC2C[ProjectCodeList.DesignTypeC2C] as DesignTypeEntity[];
            const designTypeId = this.addEditType != AddEditType.edit
                ? this.design.designType ?? DesignType.Concrete2Concrete
                : this.design.designType;

            this._designType = desingTypeCodeList.find((desingTypeCodeListItem) => desingTypeCodeListItem.id == designTypeId) as DesignTypeEntity;
        }

        return this._designType;
    }

    private get selectedRegion() {
        return this.getRegionById(this.selectedRegionId) as CommonRegion;
    }

    private get projectDesign() {
        if (this.detailedDisplayDesignFromModule?.design != null) {
            return this.detailedDisplayDesignFromModule?.design as DesignC2C;
        }
        return this.userService.design;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.isLoaded) {
            this.collapseRegion = {
                MethodAndApprovals: false,
                UnitsAndParams: false,
                ReductionFactors: false,
                AdvancedRebarSettings: false,
                ConcreteMaterialSettings: false,
                ConcreteMaterial: true,
                SteelMaterial: true,
                SafetyFactor: false,
                RebarCalculation: true,
                InterfaceShearCalculation: true,
                StrutTiesModelCalculation: true,
                OptimizationStrategy: true
            };

            this.initControls();

            this.isLoaded = true;
        }
        else if (changes['selectedRegionId']) {
            this.onRegionChanged();
        }
    }

    public isPIREU(): boolean {
        return !this.isHNAC2C && this.isPirEuConnectionTypeSelected;
    }

    public isPIRAUS(): boolean {
        return !this.isHNAC2C && this.isPirAusConnectionTypeSelected;
    }

    public isPirEuOrAus(): boolean {
        return this.isPIREU() || this.isPIRAUS();
    }

    public isPirAusEn(): boolean {
        return this.isPIRAUS() && this.designMethodGroupId == DesignMethodGroupEnum.EN199211;
    }

    public onDesignStandardDropdownSelectedValueChange(designStandardId?: number) {
        const oldDesignStandardId = this.projectDesignStandardDropdown.selectedValue;

        if (!this.designStandardId || designStandardId == oldDesignStandardId) {
            return;
        }

        const existingDesignStandard = this.designStandards.find((it) => {
            return it.id == designStandardId;
        });

        if (existingDesignStandard) {
            this.projectDesignStandardDropdown.selectedValue = designStandardId;
        }
        else {
            this.projectDesignStandardDropdown.selectedValue = this.designStandards[0].id;
        }

        this.onDesignStandardIdChange(this.projectDesignStandardDropdown.selectedValue);
        this.designMethodGroupId = this.projectDesignStandardDropdown.selectedValue;

        this.setDefaultDesignMethodGroup();
        this.modifyProjectDesignMethodItems();

        this.setDesignMethodAndTechnicalData();

        this.applicationProviderService.designStandardId = designStandardId;
        this.designStandardChangedFromEtagToAci(oldDesignStandardId);
    }

    private designStandardChangedFromEtagToAci(oldDesignStandardId?: number) {

        const etagToAci = oldDesignStandardId == DesignStandardEnum.ETAG && this.designStandardId == DesignStandardEnum.ACI;
        const aciToEtag = oldDesignStandardId == DesignStandardEnum.ACI && this.designStandardId == DesignStandardEnum.ETAG;

        if (!(etagToAci || aciToEtag)) {
            this.checkIfDesignInfoAvailable();
            return;
        }

        let designInfo: IDesignInfo;

        if (etagToAci)
        {
            designInfo = this.applicationProviderService.concrete2ConcreteDesignInfo();
        }
        else if (aciToEtag && this.connectionType == ConnectionType.ConcreteOverlay)
        {
            designInfo = this.applicationProviderService.concreteOverlayDesignInfo();
        }
        else {
            designInfo = this.applicationProviderService.postInstallRebarDesignInfo();
        }

        designInfo.selectedOptions = {
            regionId: this.selectedRegion.id,
            designStandardId: this.designStandardId,
            designMethodGroupId: this.designMethodGroupId,
            connectionType: this.connectionType
        };

        this.changeComponentModule?.emit(designInfo);
    }

    private checkIfDesignInfoAvailable() {
        if (!this.designInfo?.isAvailable(this.selectedRegionId)) {
            const availableDesignInfo = this.applicationProviderService.getDesignInfo().find(x => x.connectionType?.includes(this.connectionType) && x.isAvailable(this.selectedRegionId));
            this.changeComponentModule?.emit(availableDesignInfo);
        }
    }

    public onSelectedConnectionTypeChange(type: number) {
        this.resetConnectionTypeSettings(type);
        this.connectionType = type;
    }

    public onSelectedApplicationTypeChange(type: number) {
        if (type == ApplicationType.ThinOverlay) {
            this.setDefaultDesignMethodGroup(ApplicationType.ThinOverlay);
        } else if (!this.designMethodGroups.some(x => x.id == this.designMethodGroupId)) {
            if (this.step == Step.connectionApplication) {
                const standardAndMethod = this.getDesignStandardAndMethod(this.currentConnectionType ?? 0);
                this.designMethodGroupId = standardAndMethod.designMethodId ?? undefined;
            }
            else {
                this.setDefaultDesignMethodGroup();
            }
        }

        this.modifyProjectDesignMethodItems();
    }

    public onDesignMethodDropdownSelectedValueChange(designMethodGroupId: number | undefined) {
        const oldDesignMethodGroupId = this.projectDesignMethodDropdown.selectedValue;

        if (!designMethodGroupId || designMethodGroupId == oldDesignMethodGroupId) {
            return;
        }

        this.setProjectDesignMethod(designMethodGroupId);
    }

    public onLengthDropdownChange() {
        this.concreteMaterialInputsData.lengthUnit = this.lengthDropdown.selectedValue?.id as number;
        this.rebarCalculationInputsData.lengthUnit = this.lengthDropdown.selectedValue?.id as number;
    }

    public onAreaDropdownChange() {
        this.rebarCalculationInputsData.areaUnit = this.areaDropdown.selectedValue?.id as number;
    }

    public onAreaPerLengthDropdownChange() {
        this.interfaceShearCalculationInputsData.areaPerLengthUnit = this.areaPerLengthDropdown.selectedValue?.id as number;
    }

    public onStressDropdownChange() {
        this.steelMaterialInputsData.unit = this.stressDropdown.selectedValue?.id as number;
        this.rebarCalculationInputsData.stressUnit = this.stressDropdown.selectedValue?.id as number;
    }

    private resetConnectionTypeSettings(connectionTypeId: number) {
        this.setDesignMethodAndTechnicalData();
        this.modifyProjectDesignStandardItems(false);
        this.setDefaultDesignMethodGroup();
        this.setDesignStandardAndMethod(connectionTypeId);
        this.modifyProjectDesignMethodItems();
        this.modifyReductionFactors(connectionTypeId);
    }

    private modifyProjectDesignStandardItems(init: boolean) {
        this.projectDesignStandardDropdown.items = this.designStandards.map(
            (designStandard) => {
                getUKDesignStandardKey(this.selectedRegionId, designStandard, this.featureVisibilityService.isFeatureEnabled('C2C_UKTA'));
                return {
                    id: `add-edit-design-project-design-standard-c2c-dropdown-item-${designStandard.id}`,
                    value: designStandard.id,
                    text: this.localization.getString(getDesignTypeSpecificKey(this.localization, designStandard.nameResourceKey ?? '', DesignType.Concrete2Concrete))
                };
            }
        );

        if (init) {
            this.projectDesignStandardDropdown.selectedValue = this.designStandardId;
        }
        else {
            this.onDesignStandardDropdownSelectedValueChange(this.designStandardId);
        }
    }

    private onDesignStandardIdChange(designStandardId: number | undefined) {
        const oldDesignStandardId = this.designStandardId;
        this.designStandardId = designStandardId;

        if (designStandardId !== oldDesignStandardId && designStandardId) {
            this.setDefaultDesignMethodGroup();
        }

        this.selectConnectionApplication.designStandard = this.designStandardId as number;

        if (this.step != Step.connectionApplication || !this.selectConnectionApplication.designStandardPIR) {
            this.selectConnectionApplication.designStandardPIR = this.designStandardId as number;
        }
        if ([AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)) {
            this.metricEquationDropdown.selectedValue = this.defaultMetricEquationForStress;
        }

        this.concreteMaterialSettingsInputsData.designStandardId = designStandardId as number;
    }

    private setDefaultDesignMethodGroup(applicationType?: number) {
        const designMethodDefault = defaultUiPropertyValue(PropertyMetaDataC2C.Option_C2C_DesignMethodGroup.id, this.codeList, this.selectedRegionId, this.designStandardId, undefined, applicationType);
        if (this.designMethodGroups.length > 0) {
            this.designMethodGroupId = this.designMethodGroups.find(x => x.id == designMethodDefault)?.id ?? this.designMethodGroups[0].id;
        }
    }

    private setDesignStandardAndMethod(connectionTypeId: number) {
        if (this.step != Step.connectionApplication) {
            return;
        }

        const { designStandardId, designMethodId } = this.getDesignStandardAndMethod(connectionTypeId);

        // Find default design method if there is no method saved in the user settings
        const c2cUIProperties = this.codeList.projectCodeListsC2C[ProjectCodeList.UIPropertiesC2C] as UIProperty[];
        const propertyValues = c2cUIProperties.find((property) => property.id == PropertyMetaDataC2C.Option_C2C_DesignStandard.id);

        let propertyValue = propertyValues?.propertyValues?.find((property) => property.regionId == this.selectedRegionId);
        if (!propertyValue) {
            propertyValue = propertyValues?.propertyValues?.find((property) => property.regionId == 0);
        }

        const defaultMethod = propertyValue?.designMethodGroupId != 0 ? propertyValue?.designMethodGroupId : propertyValue.defaultValue;

        this.onDesignStandardIdChange(designStandardId ?? this.designStandardId);
        this.designMethodGroupId = (designMethodId ?? defaultMethod) as number;
    }

    private getDesignStandardAndMethod(connectionTypeId: number) {
        const designStandardId = this.userSettingsService.settings.quickStart.concrete2Concrete.calculationDesignStandardId.value;
        const designMethodId = this.userSettingsService.settings.quickStart.concrete2Concrete.calculationDesignMethodGroupId.value;

        if (connectionTypeId == ConnectionType.ConcreteOverlay) {
            return {
                designStandardId: this.userSettingsService.settings.quickStart.concrete2Concrete.calculationOverlayDesignStandardId.value ?? designStandardId,
                designMethodId: this.userSettingsService.settings.quickStart.concrete2Concrete.calculationOverlayDesignMethodGroupId.value ?? designMethodId
            };
        }
        return { designStandardId, designMethodId };
    }

    private modifyProjectDesignMethodItems() {
        this.projectDesignMethodDropdown.items = this.designMethodGroups.map(
            (methodGroup) => {
                const updatedMethodGroup = getUKDesignMethodKey(this.selectedRegionId, methodGroup, this.featureVisibilityService.isFeatureEnabled('C2C_UKTA'));
                return {
                    id: `add-edit-design-project-design-method-c2c-dropdown-item-${methodGroup.id}`,
                    value: methodGroup.id,
                    text: this.localization.getString(updatedMethodGroup.nameResourceKey ?? '')
                };
            }
        );
        this.onDesignMethodDropdownSelectedValueChange(this.designMethodGroupId);
    }

    private modifyReductionFactors(connectionType: number) {
        this.reductionFactorsInputsData.connectionType = connectionType;
    }

    private setDesignMethodAndTechnicalData() {
        this.isDesignMethodVisible = !this.isPIREU();
        this.isUseGermanNationalAnnexVisible = DesignMethodGroupHelper.isGermanNationalAnnexAvailable(this.isPIREU(), this.selectedRegionId);
        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.useGermanNationalAnnexCheckbox, this.getUseGermanNationalAnnex);
    }

    private setProjectDesignMethod(methodGroupId: number) {
        const designMethodGroups = this.designMethodGroups;

        const designMethod = designMethodGroups.find((it) => {
            return it.id == methodGroupId;
        });

        if (designMethod) {
            this.projectDesignMethodDropdown.selectedValue = designMethod.id;
        }
        else {
            this.projectDesignMethodDropdown.selectedValue = designMethodGroups[0].id;
        }

        this.designMethodGroupId = this.projectDesignMethodDropdown.selectedValue;
    }

    private initControls() {
        if (this.designInfo?.selectedOptions && this.selectedRegionId != this.designInfo?.selectedOptions?.regionId) {
            this.designInfo.selectedOptions = undefined;
            this.applicationProviderService.designStandardId = undefined;
            this.changeComponentModule?.emit(this.designInfo);
        }

        this.connectionType = this.applicationProviderService.getDesignInfo()?.find(x => x.id == this.designInfo?.id)?.connectionType?.at(0) ?? ConnectionType.ConcreteOverlay;

        this.step = this.addEditType == AddEditType.addFromQuickStart ? Step.connectionApplication : Step.basic;

        this.initDetailedDisplayDesign();

        this.initCheckboxOptions();

        this.initUnits();

        this.fromQuickStart = this.addEditType == AddEditType.addFromQuickStart;

        this.initAdvancedRebarSettings();
        this.initConcreteMaterialSettings();

        this.initConnectionApplicationControls(this.design);

        this.initMaterialAndSafetyFactors();

        // Design standard and method need to be set prior to
        // dropdown init as dropdowns need those values!
        this.setDesignStandardAndDefaultDesignMethodGroup();

        // Project design standard and method values dropdown
        // In reverse order since initProjectDesignDropdown() needs projectDesignMethodDropdown!
        this.initProjectDesignMethodDropdown();
        this.initProjectDesignDropdown();

        this.loadDataFromSettingsOrDesign();

        if (this.fromQuickStart) {
            // If started from Quick Start design method group for C2C needs to be set
            this.resetConnectionTypeSettings(this.selectConnectionApplication.selectedConnectionType ?? ConnectionType.Unknown);
        }

        this.setDesignMethodAndTechnicalData();

        if (this.afterOpenInstructions != null) {
            this.executeAfterOpenInstructions();
        }
    }

    private initDetailedDisplayDesign() {
        if (this.addEditType == AddEditType.addFromQuickStart) {
            this.initUnitsFromQuickStart();
        } else if (this.addEditType == AddEditType.edit) {
            this.initDetailedDisplayDesignFromEdit();
        }
    }

    private initUnitsFromQuickStart() {
        const quickStartSettings = this.userSettingsService.quickStartConcrete2ConcreteSettings;
        this._initUnits = {
            unitLength: this.getUnitId(quickStartSettings.units_length),
            unitArea: this.getUnitId(quickStartSettings.units_area),
            unitStress: this.getUnitId(quickStartSettings.units_stress),
            unitForce: this.getUnitId(quickStartSettings.units_force),
            unitMoment: this.getUnitId(quickStartSettings.units_moment),
            unitTemperature: this.getUnitId(quickStartSettings.units_temperature),
            unitForcePerLength: this.getUnitId(quickStartSettings.units_force_per_length),
            unitMomentPerLength: this.getUnitId(quickStartSettings.units_moment_per_length),
            unitDensity: this.getUnitId(quickStartSettings.units_density),
            unitAreaPerLength: this.getUnitId(quickStartSettings.units_area_per_length),
        };
    }

    private getUnitId(unitItem: UnitItem | undefined): number | undefined {
        return unitItem != null ? unitItem.id : undefined;
    }

    private initDetailedDisplayDesignFromEdit() {
        let documentDesign: DesignTemplateEntity | IDesignListItem;
        if (this.displayDesignType == DisplayDesignType.template) {
            documentDesign = this.designTemplateService.findById(this.projectDesign.templateId ?? '');
        }
        else {
            documentDesign = this.documentService.findDesignById(this.projectDesign.id);
        }

        this.detailedDisplayDesign = projectDesignToDetailedDisplayDesign(
            this.projectDesign.designData.projectDesignC2C,
            documentDesign,
            this.codeList,
            this.commonCodeList,
            this.localization
        );
    }

    private initCheckboxOptions() {
        this.useGermanNationalAnnexCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemId: 'add-edit-design-project-use-german-national-anex-checkbox',
            itemText: this.localization.getString('Agito.Hilti.C2C.Loads.UseGermanNationalAnnex')
        });
    }

    private initUnits() {
        this.lengthDropdown = this.createUnitDropdown(
            'Length',
            CommonCodeList.UnitLength,
            () => this.design.unitLength,
            this._initUnits?.unitLength
        );
        this.areaDropdown = this.createUnitDropdown(
            'Area',
            CommonCodeList.UnitArea,
            () => this.design.unitArea,
            this._initUnits?.unitArea
        );
        this.stressDropdown = this.createUnitDropdown(
            'Stress',
            CommonCodeList.UnitStress,
            () => this.design.unitStress,
            this._initUnits?.unitStress
        );
        this.forceDropdown = this.createUnitDropdown(
            'Force',
            CommonCodeList.UnitForce,
            () => this.design.unitForce,
            this._initUnits?.unitForce
        );
        this.momentDropdown = this.createUnitDropdown(
            'Moment',
            CommonCodeList.UnitMoment,
            () => this.design.unitMoment,
            this._initUnits?.unitMoment
        );
        this.temperatureDropdown = this.createUnitDropdown(
            'Temperature',
            CommonCodeList.UnitTemperature,
            () => this.design.unitTemperature,
            this._initUnits?.unitTemperature
        );
        this.forcePerLengthDropdown = this.createUnitDropdown(
            'ForcePerLength',
            CommonCodeList.UnitForcePerLength,
            () => this.design.unitForcePerLength,
            this._initUnits?.unitForcePerLength
        );
        this.momentPerLengthDropdown = this.createUnitDropdown(
            'MomentPerLength',
            CommonCodeList.UnitMomentPerLength,
            () => this.design.unitMomentPerLength,
            this._initUnits?.unitMomentPerLength
        );
        this.densityDropdown = this.createUnitDropdown(
            'Density',
            CommonCodeList.UnitDensity,
            () => this.design.unitDensity,
            this._initUnits?.unitDensity
        );
        this.areaPerLengthDropdown = this.createUnitDropdown(
            'AreaPerLength',
            CommonCodeList.UnitAreaPerLength,
            () => this.design.unitAreaPerLength,
            this._initUnits?.unitAreaPerLength
        );
    }

    private initAdvancedRebarSettings() {
        this.advancedRebarSettingsInputsData = {
            design: this.addEditType == AddEditType.edit ? () => this.design : undefined,
            settings: [AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType) ? () => this.userSettingsService.settings : undefined,
            regionId: this.selectedRegionId
        };
    }

    private initConcreteMaterialSettings() {
        this.concreteMaterialSettingsInputsData = {
            design: this.addEditType == AddEditType.edit ? () => this.design : undefined,
            settings: [AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType) ? () => this.userSettingsService.settings : undefined,
            designStandardId: this.designStandardId as number
        };
    }

    private initConnectionApplicationControls(design: IDetailedDisplayDesign) {
        const { designStandard, designStandardPIR } = this.getInitialDesignStandardAndDesignStandardPIR();

        const selectConnectionApplication: ISelectConnectionApplicationData = {
            selectedConnectionType: this.addEditType == AddEditType.edit
                ? design.connectionType ?? undefined
                : this.connectionType,
            selectedApplicationType: this.addEditType == AddEditType.edit
                ? design.applicationType ?? undefined
                : undefined,
            inEditMode: this.addEditType == AddEditType.edit,
            designStandard: this.addEditType == AddEditType.edit
                ? design.designStandardC2C?.id as number
                : designStandard,
            designStandardPIR: this.addEditType == AddEditType.edit
                ? design.designStandardC2C?.id as number
                : designStandardPIR,
            regionId: this.selectedRegionId
        };

        this.selectConnectionApplication = selectConnectionApplication;
        loadSelectConnectionApplicationDataDefaults(
            this.selectConnectionApplication,
            this.isOverlayOnly,
            this.codeList
        );

        if (this.designInfo?.selectedOptions) {
            this.selectConnectionApplication.selectedConnectionType = this.designInfo.selectedOptions.connectionType;
        }

        this.initMetricEquationDropdown();
    }

    private getInitialDesignStandardAndDesignStandardPIR() {
        if (this.step == Step.basic) {
            const designStandard = this.getDesignStandardForRegion(this.selectedRegionId) as number;
            return {
                designStandard: designStandard,
                designStandardPIR: designStandard
            };
        } else {
            return {
                designStandard: this.getDesignStandardAndMethod(this.connectionType).designStandardId as number,
                designStandardPIR: this.userSettingsService.settings.quickStart.concrete2Concrete.calculationDesignStandardId.value as number
            };
        }
    }

    private initMetricEquationDropdown() {
        const metricEquationItems = this.codeList.projectCodeListsC2C[ProjectCodeList.MetricEquationC2C];
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        this.metricEquationDropdown = {
            id: 'add-edit-design-metric-equation-dropdown',
            title: this.translateDropdownTitle('MetricEquation'),
            items: metricEquationItems.map((item) => {
                return {
                    value: item.id,
                    text: item.getTranslatedNameText(codeListDeps) ?? ''
                };
            }),
            selectedValue: [AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)
                ? this.defaultMetricEquationForStress
                : this.design.metricEquation
        };
    }

    private initMaterialAndSafetyFactors() {
        this.reductionFactorsInputsData = {
            design: undefined,
            settings: undefined,
            connectionType: this.currentConnectionType
        };

        this.safetyFactorsInputsData = {
            design: undefined,
            settings: undefined,
            connectionType: this.currentConnectionType,
            safetyFactorsAs: {
                defaults: {}
            }
        };

        this.concreteMaterialInputsData = {
            design: undefined,
            settings: undefined,
            connectionType: this.currentConnectionType,
            lengthUnit: this.lengthDropdown.selectedValue?.id as number,
            concreteMaterialOptionsAs: {
                defaults: {}
            }
        };

        this.steelMaterialInputsData = {
            design: undefined,
            settings: undefined,
            connectionType: this.currentConnectionType,
            unit: this.stressDropdown.selectedValue?.id,
            steelMaterialOptionsAs: {
                defaults: {}
            }
        };

        this.rebarCalculationInputsData = {
            design: undefined,
            settings: undefined,
            connectionType: this.currentConnectionType,
            stressUnit: this.stressDropdown.selectedValue?.id as number,
            areaUnit: this.areaDropdown.selectedValue?.id as number,
            lengthUnit: this.lengthDropdown.selectedValue?.id as number,
            addEditType: this.addEditType,
            rebarCalculationParametersAs: {
                defaults: {
                    maximumAreaReinforcement: MaximumAreaReinforcement.Calculated,
                    minimumAreaReinforcement: MinimumAreaReinforcement.Calculated,
                    spacingMaximum: SpacingMaximum.Calculated
                } as RebarCalculationParametersBaseAs
            } as RebarCalculationParametersAs
        };

        this.interfaceShearCalculationInputsData = {
            design: undefined,
            settings: undefined,
            connectionType: this.currentConnectionType,
            areaPerLengthUnit: this.areaPerLengthDropdown.selectedValue?.id as number,
            addEditType: this.addEditType,
            interfaceShearCalculationParametersAs: {
                defaults: {
                    shearReinforcementInNewElement: false
                }
            } as InterfaceShearCalculationParametersAs
        };

        this.strutTiesInputsData = {
            design: undefined,
            settings: undefined,
            connectionType: this.currentConnectionType,
            addEditType: this.addEditType
        };

        this.optimizationStrategyInputsData = {
            design: undefined,
            settings: undefined,
            connectionType: this.currentConnectionType,
            addEditType: this.addEditType,
            optimizationStrategyOptionsAs: {} as OptimizationStrategyOptionsAs
        };

        if (this.addEditType == AddEditType.edit) {
            this.reductionFactorsInputsData.design = () => this.design;
            this.safetyFactorsInputsData.design = () => this.design;
            this.concreteMaterialInputsData.design = () => this.design;
            this.steelMaterialInputsData.design = () => this.design;
            this.rebarCalculationInputsData.design = () => this.design;
            this.interfaceShearCalculationInputsData.design = () => this.design;
            this.strutTiesInputsData.design = () => this.design;
            this.optimizationStrategyInputsData.design = () => this.design;
        }
        else if ([AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)) {
            this.reductionFactorsInputsData.settings = () => this.userSettingsService.settings;
            this.safetyFactorsInputsData.settings = () => this.userSettingsService.settings;
            this.concreteMaterialInputsData.settings = () => this.userSettingsService.settings;
            this.steelMaterialInputsData.settings = () => this.userSettingsService.settings;
            this.rebarCalculationInputsData.settings = () => this.userSettingsService.settings;
            this.interfaceShearCalculationInputsData.settings = () => this.userSettingsService.settings;
            this.strutTiesInputsData.settings = () => this.userSettingsService.settings;
            this.optimizationStrategyInputsData.settings = () => this.userSettingsService.settings;
        }
    }

    private setDesignStandardAndDefaultDesignMethodGroup() {

        if (this.designInfo?.selectedOptions) {
            this.designStandardId = this.designInfo?.selectedOptions.designStandardId;
            this.designMethodGroupId = this.designInfo?.selectedOptions.designMethodGroupId;
            return;
        }

        if ([AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)) {
            let designStandard = this.getDesignStandardForRegion(this.selectedRegionId) || this.designStandards[0].id;
            if (this.applicationProviderService.designStandardId && this.designStandards.some(x => x.id == this.applicationProviderService.designStandardId)) {
                designStandard = this.applicationProviderService.designStandardId;
            }

            this.onDesignStandardIdChange(designStandard != null ? designStandard : undefined);

            this.setDefaultDesignMethodGroup();
            return;
        }

        this.designStandardId = this.design.designStandardC2C?.id;
        this.designMethodGroupId = this.design.designMethodGroupC2C?.id;
    }

    private initProjectDesignMethodDropdown() {
        this.projectDesignMethodDropdown = {
            id: 'add-edit-design-project-design-method-c2c-dropdown',
            title: this.localization.getString('Agito.Hilti.C2C.AddEditDesign.Dropdown.DesignMethodC2C.Title'),
            notSelectedText: this.localization.getString('Agito.Hilti.C2C.AddEditDesign.Dropdown.DesignMethodC2C.Placeholder'),
            validators: [this.requiredValidator]
        };

        this.modifyProjectDesignMethodItems();
    }

    private initProjectDesignDropdown() {
        this.projectDesignStandardDropdown = {
            id: 'add-edit-design-project-design-standard-c2c-dropdown',
            title: this.localization.getString('Agito.Hilti.C2C.AddEditDesign.Dropdown.DesignStandardC2C.Title'),
            notSelectedText: this.localization.getString('Agito.Hilti.C2C.AddEditDesign.Dropdown.DesignStandardC2C.Placeholder'),
            validators: [this.requiredValidator]
        };

        this.modifyProjectDesignStandardItems(this.addEditType == AddEditType.edit);
    }

    private loadDataFromSettingsOrDesign() {
        if ([AddEditType.add].includes(this.addEditType)) {
            this.setDefaultUnits(this.selectedRegion, true);

            return;
        }

        this.onDesignStandardIdChange(this.designStandardId);
    }

    private createUnitDropdown(key: string, codeList: CommonCodeList, designUnit: () => Unit, newUnit?: Unit) {
        const retVal: DropdownProps<UnitItem> = {
            id: 'add-edit-design-unit-' + key.toLowerCase() + '-dropdown',
            title: this.translateDropdownTitle(key),
        };
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        retVal.items = (this.commonCodeList.commonCodeLists[codeList] as UnitItem[]).map(
            (unit) => {
                return {
                    value: unit,
                    text: unit.getTranslatedNameText(codeListDeps) ?? ''
                };
            }
        );

        const selectedValue = this.addEditType != AddEditType.edit
            ? newUnit
            : designUnit();

        if (selectedValue != null) {
            retVal.selectedValue = this.commonCodeList.commonCodeLists[codeList].find((cl) => cl.id == selectedValue);
        }
        else if (retVal.items.length > 0) {
            retVal.selectedValue = retVal.items[0].value;
        }
        else {
            retVal.selectedValue = undefined;
        }
        return retVal;
    }

    private translateDropdownTitle(key: string): string {
        return this.localization.getString(`Agito.Hilti.C2C.AddEditDesign.${key}`);
    }

    private getDesignStandardForRegion(regionId: number) {
        let designStandard = undefined;

        const propertyValue = this.codeList.getPropertyValue(PropertyMetaDataC2C.Option_C2C_DesignStandard.id, regionId, this.connectionType);
        const designStandardsC2C = this.codeList.projectCodeListsC2C[ProjectCodeList.DesignStandardsC2C] as DesignStandard[];
        designStandard = designStandardsC2C.find((ds) => ds.id == propertyValue.defaultValue);

        if (designStandard) {
            if (this.selectConnectionApplication) {
                this.selectConnectionApplication.designStandard = designStandard.id;
            }
            return designStandard.id;
        }

        const designStandardRegion = this.designType?.allowedDesignStandardRegions?.find((row) => row.RegionId == regionId);

        if (designStandardRegion != null && designStandardRegion.DesignStandards.length > 0) {
            return designStandardRegion.DesignStandards[0];
        }

        return undefined;
    }

    private getRegionById(regionId: number) {
        const regionCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private setDefaultUnits(region: CommonRegion, setFromProperty?: boolean) {
        this.setDefaultUnit(this.lengthDropdown,
            CommonCodeList.UnitLength,
            this.getRegionDefaultUnit(region.defaultUnitLength as UnitType, region.id, PropertyMetaDataC2C.Option_UnitLength.id),
            setFromProperty ? this.design?.unitLength : null);

        this.setDefaultUnit(this.areaDropdown,
            CommonCodeList.UnitArea,
            this.getRegionDefaultUnit(region.defaultUnitArea as UnitType, region.id, PropertyMetaDataC2C.Option_UnitArea.id),
            setFromProperty ? this.design?.unitArea : null);

        this.setDefaultUnit(this.stressDropdown,
            CommonCodeList.UnitStress,
            region.defaultUnitStress,
            setFromProperty ? this.design?.unitStress : null);

        this.setDefaultUnit(this.forceDropdown,
            CommonCodeList.UnitForce,
            region.defaultUnitForce,
            setFromProperty ? this.design?.unitForce : null);

        this.setDefaultUnit(this.momentDropdown,
            CommonCodeList.UnitMoment,
            region.defaultUnitMoment,
            setFromProperty ? this.design?.unitMoment : null);

        this.setDefaultUnit(this.temperatureDropdown,
            CommonCodeList.UnitTemperature,
            region.defaultUnitTemperature,
            setFromProperty ? this.design?.unitTemperature : null);

        this.setDefaultUnit(this.forcePerLengthDropdown,
            CommonCodeList.UnitForcePerLength,
            region.defaultUnitForcePerLength,
            setFromProperty ? this.design?.unitForcePerLength : null);

        this.setDefaultUnit(this.momentPerLengthDropdown,
            CommonCodeList.UnitMomentPerLength,
            region.defaultUnitMomentPerLength,
            setFromProperty ? this.design?.unitMomentPerLength : null);

        this.setDefaultUnit(this.densityDropdown,
            CommonCodeList.UnitDensity,
            region.defaultUnitDensity,
            setFromProperty ? this.design?.unitDensity : null);

        this.setDefaultUnit(this.areaPerLengthDropdown,
            CommonCodeList.UnitAreaPerLength,
            this.getRegionDefaultUnit(region.defaultUnitAreaPerLength as UnitType, region.id, PropertyMetaDataC2C.Option_UnitAreaPerLength.id),
            setFromProperty ? this.design?.unitAreaPerLength : null);

        this.metricEquationDropdown.selectedValue = this.defaultMetricEquationForStress;
    }

    private setDefaultUnit(dropdown: DropdownProps<UnitItem>, unitCodeList: CommonCodeList, defaultUnit: Unit | undefined, unit: Unit | null) {
        if (unit == null) {
            dropdown.selectedValue = this.commonCodeList.commonCodeLists[unitCodeList].find((codeList) => codeList.id == defaultUnit);
        }
    }

    private getRegionDefaultUnit(regionDefaultUnit: Unit, regionId: number, propertyId: number): Unit {
        return this.appSettingsHelper.getRegionDefaultUnit(regionDefaultUnit, regionId, propertyId);
    }

    private executeAfterOpenInstructions() {
        if (this.afterOpenInstructions.includes(AfterOpenInstruction.ScrollToRebarCalculationSection)) {
            this.collapseRegion.RebarCalculation = false;
            this.scrollToSection('section-rebar-calculation');
        }
    }

    private scrollToSection(sectionClass: string) {
        setTimeout(() => {
            const element = this.elementRef.nativeElement.querySelector(`.${sectionClass}`) as HTMLElement | null;
            element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 150);
    }

    private closeInternal(): void {
        // reset selected options and selected design standard id when closing
        if (this.designInfo) {
            this.designInfo.selectedOptions = undefined;
        }
        this.applicationProviderService.designStandardId = undefined;
    }

    private saveInternal(saveDesign: ISaveDesign): Promise<ISaveDesignResult> {
        if ([AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)) {
            const dataEntityC2C: NewDesignDataEntityC2C = {
                regionId: this.selectedRegionId,
                designStandard: this.designStandard?.id as number,
                designMethod: this.designMethodGroup?.id as number,
                projectName: saveDesign.projectName,
                projectId: saveDesign.projectId,
                designName: saveDesign.designName,
                unitLength: this.lengthDropdown.selectedValue?.id as number,
                unitArea: this.areaDropdown.selectedValue?.id as number,
                unitStress: this.stressDropdown.selectedValue?.id as number,
                unitForce: this.forceDropdown.selectedValue?.id as number,
                unitMoment: this.momentDropdown.selectedValue?.id as number,
                unitTemperature: this.temperatureDropdown.selectedValue?.id as number,
                unitForcePerLength: this.forcePerLengthDropdown.selectedValue?.id as number,
                unitMomentPerLength: this.momentPerLengthDropdown.selectedValue?.id as number,
                unitDensity: this.densityDropdown.selectedValue?.id as number,
                unitAreaPerLength: this.areaPerLengthDropdown.selectedValue?.id as number,
                partialReductionFactorConcrete: null as unknown as number,
                partialReductionFactorSteel: null as unknown as number,
                partialReductionFactorFire: null as unknown as number,
                alphaCC: null as unknown as number,
                alphaCT: null as unknown as number,
                language: null as unknown as string,
                numberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettingsService),
                numberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettingsService),
                applicationType: this.selectConnectionApplication.selectedApplicationType as ApplicationType,
                connectionType: this.selectConnectionApplication.selectedConnectionType as ConnectionType,
                reinforcementControlCracking: null as unknown as boolean,
                reinforcementControlCrackingNew: null as unknown as boolean,
                topRebarDiameter: null as unknown as number,
                topRebarDiameterMode: null as unknown as RebarDiameterMode,
                reportFirstPage: 1,
                reportLanguageId: this.userSettingsService.settings.application.general.languageId.value,
                reportPaperSize: ReportPaperSize.Unknown,
                reportType: ReportType.Unknown,
                advancedRebarSettingsSteelStrainLimit: null as unknown as number,
                concreteResistanceCompression: null as unknown as number,
                epsilonC2: null as unknown as number,
                epsilonCU2: null as unknown as number,
                minConcreteCover: null as unknown as number,
                e: null as unknown as number,
                epsilonUd: null as unknown as number,
                partialSafetyFactorConcrete: null as unknown as number,
                partialSafetyFactorSteel: null as unknown as number,
                existingReinforcementAlpha3: null as unknown as number,
                maximumAreaReinforcement: 0,
                maximumAreaReinforcementInput: null as unknown as number,
                minimumAreaReinforcement: 0,
                minimumAreaReinforcementInput: null as unknown as number,
                optimizationStrategy: 0,
                shearInclination: null as unknown as number,
                shearReinforcementConcreteMember: false,
                shearStrutAngle: null as unknown as number,
                spacingMaximum: 0,
                spacingMaximumInput: null as unknown as number,
                strutTies: 0,
                strutTiesInput: null as unknown as number,
                transversePressure: null as unknown as number,
                enableDebugMode: this.sharedEnvironmentService.data?.debugModeEnabled ?? false,
                gammaRd: null as unknown as number,
                useGermanNationalAnnex: (this.isUseGermanNationalAnnexVisible && SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.useGermanNationalAnnexCheckbox)) ?? false,
                metricEquation: this.metricEquationDropdown.selectedValue as number,
                designOptionsAs: {} as DesignOptionsASbased
            };

            setReductionFactorsValuesToNewDesign(dataEntityC2C, this.reductionFactorsInputsData);
            setAdvancedRebarSettingsValuesToNewDesign(dataEntityC2C, this.advancedRebarSettingsInputsData);
            setConcreteMaterialSettingsValuesToNewDesign(dataEntityC2C, this.concreteMaterialSettingsInputsData);

            this.setPirEuAsNewDesignOptions(dataEntityC2C);

            if (this.fromQuickStart) {
                // always save selection
                this.userSettingsService.settings.application.concrete2Concrete.selectedApplicationTypeId.value = this.selectConnectionApplication.selectedApplicationType as ApplicationType;
                this.userSettingsService.settings.application.concrete2Concrete.selectedConnectionTypeId.value = this.selectConnectionApplication.selectedConnectionType as ConnectionType;
            }

            return this.userSettingsService.save()
                .then(() =>
                    // create design c2c
                    this.calculationServiceC2C.createAndOpenC2C(dataEntityC2C))
                .then((design) => {
                    return {
                        designId: design.id,
                        path: `${UrlPath.main}`,
                        design: design,
                        success: true
                    } as ISaveDesignResult;
                })
                .catch(() => {
                    return {
                        designId: '',
                        path: '',
                        design: null as unknown,
                        success: false
                    } as ISaveDesignResult;
                });
        }
        else {
            this.updateDesign(saveDesign);

            return this.userSettingsService.save()
                .then(() => {
                    if (this.design.displayDesignType == DisplayDesignType.template) {
                        // save template
                        const templateProjectDesign = detailedDisplayDesignToProjectDesignC2C(this.projectDesign.designData.projectDesignC2C, this.design);
                        this.designTemplateService.update({
                            designTemplateDocumentId: this.projectDesign.templateId,
                            designTypeId: DesignType.Concrete2Concrete,
                            designStandardId: templateProjectDesign.options.designStandard,
                            regionId: templateProjectDesign.options.regionId,
                            templateName: this.design.templateName,
                            projectDesign: JSON.stringify(templateProjectDesign)
                        });
                    }
                    else {
                        // save design
                        const project = this.documentService.findProjectById(saveDesign.projectId);
                        this.userService.changeDesign(project, this.projectDesign);
                        this.userService.mapDisplayDesignToDesign(this.design);
                    }
                })
                .then(() => {
                    return {
                        designId: this.design.id,
                        path: `${UrlPath.main}`,
                        design: this.projectDesign,
                        detailedDisplayDesign: this.design,
                        success: true
                    } as ISaveDesignResult;
                });
        }
    }

    private onRegionChanged() {
        const region = this.selectedRegion;
        const designStandard = this.getDesignStandardForRegion(this.selectedRegionId);

        // Handle design standard id
        this.onDesignStandardIdChange(designStandard);

        // Adjust project design standard items
        this.modifyProjectDesignStandardItems(false);

        if (this.isOverlayOnly) {
            this.selectConnectionApplication.selectedConnectionType = ConnectionType.ConcreteOverlay;
        }

        this.concreteMaterialInputsData.connectionType = this.selectConnectionApplication.selectedConnectionType;
        this.safetyFactorsInputsData.connectionType = this.selectConnectionApplication.selectedConnectionType;

        if (designStandard != null) {
            // Handle design method id
            this.setDefaultDesignMethodGroup();
            this.modifyProjectDesignMethodItems();
        }

        this.setDefaultUnits(region);

        this.selectConnectionApplication.regionId = region.id;
        loadSelectConnectionApplicationDataDefaults(
            this.selectConnectionApplication,
            this.isOverlayOnly,
            this.codeList
        );
        this.reductionFactorsInputsData.connectionType = this.selectConnectionApplication.selectedConnectionType;
        if ([AddEditType.add, AddEditType.addFromQuickStart].includes(this.addEditType)) {
            this.metricEquationDropdown.selectedValue = this.defaultMetricEquationForStress;
        }

        this.setDesignMethodAndTechnicalData();
    }

    private setPirEuAsDesignOptions() {
        if (!this.isPirEuOrAus())
            return;

        setSafetyFactorsValuesToDesign(this.design, this.safetyFactorsInputsData);
        setConcreteMaterialValuesToDesign(this.design, this.concreteMaterialInputsData);
        setSteelMaterialValuesToDesign(this.design, this.steelMaterialInputsData);
        setRebarCalculationValuesToDesign(this.design, this.rebarCalculationInputsData);
        setInterfaceShearCalculationValuesToDesign(this.design, this.interfaceShearCalculationInputsData);
        setOptimizationStrategyValuesToDesign(this.design, this.optimizationStrategyInputsData);
        setStrutTiesModelCalculationValuesToDesign(this.design, this.strutTiesInputsData);
    }

    private setPirEuAsNewDesignOptions(dataEntity: NewDesignDataEntityC2C) {
        if (!this.isPirEuOrAus())
            return;

        setSafetyFactorsValuesToNewDesign(dataEntity, this.safetyFactorsInputsData);
        setConcreteMaterialValuesToNewDesign(dataEntity, this.concreteMaterialInputsData);
        setSteelMaterialValuesToNewDesign(dataEntity, this.steelMaterialInputsData);
        setRebarCalculationValuesToNewDesign(dataEntity, this.rebarCalculationInputsData);
        setInterfaceShearCalculationValuesToNewDesign(dataEntity, this.interfaceShearCalculationInputsData);
        setOptimizationStrategyValuesToNewDesign(dataEntity, this.optimizationStrategyInputsData);
        setStrutTiesModelCalculationValuesToNewDesign(dataEntity, this.strutTiesInputsData);
    }

    private updateDesign(saveDesign: ISaveDesign): void {
            // rename document
            this.design.name = saveDesign.designName;

            if (this.design.displayDesignType == DisplayDesignType.template) {
                this.design.templateName = saveDesign.designName;
            }
            else {
                // change project
                this.design.projectId = saveDesign.projectId;
                this.design.projectName = saveDesign.projectName;
            }

            this.design.unitLength = this.lengthDropdown.selectedValue?.id as UnitType;
            this.design.unitArea = this.areaDropdown.selectedValue?.id as UnitType;
            this.design.unitStress = this.stressDropdown.selectedValue?.id as UnitType;
            this.design.unitForce = this.forceDropdown.selectedValue?.id as UnitType;
            this.design.unitMoment = this.momentDropdown.selectedValue?.id as UnitType;
            this.design.unitTemperature = this.temperatureDropdown.selectedValue?.id as UnitType;
            this.design.unitDensity = this.densityDropdown.selectedValue?.id as UnitType;

            if (this.isAreaPerLengthDropdownAvailable) {
                this.design.unitAreaPerLength = this.areaPerLengthDropdown.selectedValue?.id as Unit;
            }

            this.design.unitForcePerLength = this.forcePerLengthDropdown.selectedValue?.id as Unit;
            this.design.unitMomentPerLength = this.momentPerLengthDropdown.selectedValue?.id as Unit;

            this.design.designMethodGroupC2C = this.designMethodGroup;

            setReductionFactorsValuesToDesign(this.design, this.reductionFactorsInputsData);
            setAdvancedRebarSettingsValuesToDesign(this.design, this.advancedRebarSettingsInputsData);
            setConcreteMaterialSettingsValuesToDesign(this.design, this.concreteMaterialSettingsInputsData);

            this.setPirEuAsDesignOptions();

            this.design.designMethodGroupC2C = this.designMethodGroup;

            this.design.useGermanNationalAnnex = this.isUseGermanNationalAnnexVisible && SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.useGermanNationalAnnexCheckbox);
            this.design.metricEquation = this.metricEquationDropdown.selectedValue;
    }
}
