import { ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { EmbedmentDepthEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList, ICodeListC2CConstructor } from './code-list';

export interface IEmbedmentDepthC2CConstructor extends ICodeListC2CConstructor {
    depth: number;
}

export class EmbedmentDepth extends CodeList {
    public depth?: number;

    constructor(codeList: IEmbedmentDepthC2CConstructor) {
        super(codeList);

        if (codeList != null) {
            this.depth = codeList.depth;
        }
    }

    public static fromService(codeList: EmbedmentDepthEntityC2C) {
        return new EmbedmentDepth(CodeList.baseFromService<IEmbedmentDepthC2CConstructor>('EmbedmentDepthEntity', codeList, {
            depth: codeList.depth
        }));
    }

    public override cloneDeep(): EmbedmentDepth {
        const codeList = super.cloneDeep() as EmbedmentDepth;

        codeList.depth = this.depth;

        return codeList;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayKey;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
