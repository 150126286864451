import {
    ApplicationTypeEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList, ICodeListC2CConstructor } from './code-list';

export interface IApplicationTypeConstructor extends ICodeListC2CConstructor {
    designStandards: number[];
    hidden: boolean;
}

export class ApplicationType extends CodeList {
    public designStandards?: number[];
    public hidden?: boolean;

    constructor(codeList: IApplicationTypeConstructor) {
        super(codeList);

        if (codeList != null) {
            this.designStandards = codeList.designStandards;
            this.hidden = codeList.hidden;
        }
    }

    public static fromService(codeList: ApplicationTypeEntityC2C) {
        return new ApplicationType(CodeList.baseFromService('ApplicationTypeEntity', codeList, {
            designStandards: codeList.designStandards,
            hidden: codeList.hidden,
            image: codeList.image
        } as IApplicationTypeConstructor));
    }

    public override cloneDeep(): ApplicationType {
        return super.cloneDeep() as ApplicationType;
    }
}
