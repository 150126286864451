import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AppSettings, IDesignInfo, IDesignManagement, IImportDesignProvider, IModuleInitialData, IModulePreInitialData,
    IQuickStartApplication, IServiceNameMapping, ITrackingInfo, IUserSettingsInfo, IVirtualTour
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { IModuleVersionInfoProvider } from '@profis-engineering/pe-ui-common/entities/service-version';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';

import { environment } from '../../environments/environmentPe';
import { IDesignListInfo } from '../../shared/entities/module-initial-data';
import {
    ProjectDesignCodeLists
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    FeatureVisibilityInfoEntity, InitialDataEntity
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { Navigation } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Navigation';
import { ApiService } from './api.service';
import { ApplicationProviderService } from './application-provider.service';
import { CodeListService } from './code-list.service';
import { ImportDesignProviderService } from './import-design-provider.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { TourService } from './tour.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataService {
    navigationSourceData!: Navigation;
    featuresPeVisibilityInfo!: FeatureVisibilityInfoEntity[];
    supportedUnitIds?: number[];

    constructor(
        private readonly applicationProviderService: ApplicationProviderService,
        private readonly importDesignProviderService: ImportDesignProviderService,
        private readonly tourService: TourService,
        private readonly userService: UserService,
        private readonly apiService: ApiService,
        private readonly userSettingsService: UserSettingsService,
        private readonly sharedEnvironmentService: SharedEnvironmentService,
        private readonly codeListService: CodeListService
    ) { }

    /**
     * Gets the pre initial data (static data) which pe-ui needs before anything else like authenticationRequiredServices and serviceNameMapping
     */
    public getPreInitialData(): IModulePreInitialData {
        return {
            // Common
            authenticationRequiredServices: this.getAuthenticationRequiredServices(),
            serviceNameMapping: this.getServiceNameMapping(),
            userSettingsInfo: this.getUserSettingsInfo(),
            moduleVersionInfoProvider: this.getModuleVersionInfoProvider()
        };
    }

    /**
     * Gets the initial data like quick start buttons, design info, app settings data,...
     */
    public getInitialData(): IModuleInitialData {
        const quickStartApplications = this.getQuickStartApplications();

        return {
            // Quick start
            quickStartApplications: quickStartApplications,

            // App settings
            appSettingsData: this.getAppSettingsData(),

            // Virtual tours
            virtualTours: this.getVirtualTours(),

            // Design info
            designInfo: this.getDesignInfo(),

            // List of designs
            designListInfo: this.getDesignListInfo(),


            // Advanced baseplate info
            abpInfo: this.getAbpInfo(),

            // Integrations info
            integrationsInfo: this.getIntegrationsInfo(quickStartApplications),

            // Design types
            designTypes: this.applicationProviderService.getDesignTypes,

            // Favorites
            favoritesInfo: this.applicationProviderService.getFavoritesInfo(),

            // Import design provider
            importDesignProvider: this.getImportDesignProvider(),

            // Tracking info
            trackingInfo: this.getTrackingInfo(),

            // Design management
            designManagement: this.getDesignManagementInfo(),
        };
    }

    private getAuthenticationRequiredServices() {
        return [
            environment.baseplateCADWebServiceUrl,
            environment.baseplateApplicationWebServiceUrl,
            environment.baseplateApplicationWebServiceUiApiUrl,
            environment.baseplateCalculationWebServiceUrl,
            environment.trackingWebServiceUrl,

            environment.signalRServerUrl,
            environment.asadServiceUrl,
            environment.asadSignalRServerUrl,
        ];
    }

    private getServiceNameMapping(): IServiceNameMapping {
        const mapping: IServiceNameMapping = {};

        mapping[environment.cadClickWebServiceUrl] = 'cad-click-service';
        mapping[environment.baseplateCADWebServiceUrl] = 'baseplate-cad-service';

        mapping[environment.baseplateApplicationWebServiceUrl] = 'baseplate-application-service';
        mapping[environment.baseplateCalculationWebServiceUrl] = 'baseplate-calculation-service';
        mapping[environment.trackingWebServiceUrl] = 'tracking-service';

        mapping[environment.asadServiceUrl] = 'asad-service';

        return mapping;
    }

    private getQuickStartApplications(): IQuickStartApplication[] {
        return this.applicationProviderService.getQuickStartApplications();
    }

    private getAppSettingsData(): AppSettings[] {
        return this.applicationProviderService.getAppSettings();
    }

    private getVirtualTours(): IVirtualTour[] {
        return this.tourService.getVirtualTours();
    }

    private getDesignInfo(): IDesignInfo[] {
        return this.applicationProviderService.getDesignInfo();
    }

    private getDesignListInfo(): IDesignListInfo[] {
        return this.applicationProviderService.getDesignListInfo();
    }

    private getAbpInfo() {
        return this.applicationProviderService.getAbpInfo();
    }

    private getIntegrationsInfo(quickStartApplications: IQuickStartApplication[]) {
        return this.applicationProviderService.getIntegrationsInfo(quickStartApplications);
    }

    private getImportDesignProvider(): IImportDesignProvider {
        return this.importDesignProviderService;
    }

    private getUserSettingsInfo(): IUserSettingsInfo {
        return this.applicationProviderService.getUserSettingsInfo();
    }

    private getModuleVersionInfoProvider(): IModuleVersionInfoProvider {
        return this.applicationProviderService.getModuleVersionInfoProvider();
    }

    private getTrackingInfo(): ITrackingInfo[] {
        return this.applicationProviderService.getTrackingInfo();
    }

    private getDesignManagementInfo(): IDesignManagement[] {
        return this.applicationProviderService.getDesignManagementInfo();
    }

    public async initCodeList(options?: ApiOptions) {
        const asadQuery = environment.asadQuery;
        const useDevFeatures = this.sharedEnvironmentService.data?.useDevFeatures ?? false;
        const forceFreeLicense = this.userSettingsService.settings?.application.general.forceFreeLicense.value ?? false;
        const translate = this.sharedEnvironmentService.data?.translate ?? false;
        const url = `${environment.baseplateApplicationWebServiceUrl}GetProjectInitialData?pseudoTranslate=${translate}&asadEnabled=${asadQuery}&useDevFeatures=${useDevFeatures}&forceFreeLicense=${forceFreeLicense}`;

        const { body: dataEntity } = await this.apiService.request<InitialDataEntity>(new HttpRequest('GET', url), options);

        if (!dataEntity)
            return;

        // init codelists
        this.codeListService.initialize(dataEntity.ProjectDesignCodeLists ?? ({} as ProjectDesignCodeLists));

        // init pe-ui-pe module
        this.initialize(dataEntity.NavigationStructure, dataEntity.FeaturesVisibilityInfo, dataEntity.SupportedUnitIds);

        // asad
        this.userService.isAsadForSingleEnabled = dataEntity.IsAnchorAiEnabled ?? false;
        this.userService.isAsadDebugEnabled = dataEntity.IsAnchorAiDebugEnabled ?? false;
        this.userService.allowedAnchorAIRegions = dataEntity.AllowedAnchorAIRegions ?? [];
    }

    public initialize(navigation: Navigation, featuresPeVisibilityInfo: FeatureVisibilityInfoEntity[], supportedUnitIds?: number[]) {
        this.navigationSourceData = navigation;
        this.featuresPeVisibilityInfo = featuresPeVisibilityInfo;

        if (supportedUnitIds != null) {
            this.supportedUnitIds = supportedUnitIds;
        }
    }
}
