import { Color4 } from '@babylonjs/core/Maths/math.color.js';
import { Scene } from '@babylonjs/core/scene.js';
import { CacheItem } from './cache-item.js';
export class SceneCache extends CacheItem {
  engineCache;
  constructor(engineCache) {
    super();
    this.engineCache = engineCache;
  }
  get default3d() {
    return this.create('default3d', () => {
      const scene = new Scene(this.engineCache.default);
      scene.clearColor = new Color4(1, 1, 1, 1);
      scene.preventDefaultOnPointerDown = false;
      scene.preventDefaultOnPointerUp = false;
      return scene;
    });
  }
  get default2d() {
    return this.create('default2d', () => {
      const scene = new Scene(this.engineCache.default);
      scene.clearColor = new Color4(1, 1, 1, 1);
      scene.preventDefaultOnPointerDown = false;
      scene.preventDefaultOnPointerUp = false;
      return scene;
    });
  }
}
