import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { DotnetProvider } from '../dotnet.provider';
import { substringAfterLast } from '../helpers/string-helper';
import { AppData } from './data.service';
import {
    ApiDesignCreateRequest, ApiDesignReportGenerateOptions, ApiDesignUpdateRequest,
    ApiDesignUpdateResponse, CalculationResult, ConvertAndCalculateResult, CreateAndCalculateResult,
    DesignConvertResult, DesignDetailsData, DesignServiceUpdateDesignOptions, DesignTypeId,
    designTypes, ProjectDesign, PunchApiDesignCreateRequest, PunchApiDesignReportGenerateOptions,
    PunchApiDesignUpdateRequest, PunchApiDesignUpdateResponse, PunchCalculationResult,
    PunchConvertAndCalculateResult, PunchCreateAndCalculateResult, PunchDesignDetailsData,
    PunchDesignServiceUpdateDesignOptions, PunchProjectDesign, PunchUpdateAndCalculateResult,
    StrengthApiDesignCreateRequest, StrengthApiDesignReportGenerateOptions,
    StrengthApiDesignUpdateRequest, StrengthApiDesignUpdateResponse, StrengthCalculationResult,
    StrengthConvertAndCalculateResult, StrengthCreateAndCalculateResult, StrengthDesignDetailsData,
    StrengthDesignServiceUpdateDesignOptions, StrengthProjectDesign,
    StrengthUpdateAndCalculateResult, UpdateAndCalculateResult
} from './design.service';
import { FeatureVisibilityService } from './features-visibility.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { UserSettingsService } from './user-settings.service';

export interface DotnetApiOptions {
    supressErrorMessage?: boolean;
}

export interface ApiConvertResult {
    projectDesign: ProjectDesign;
    designDetails: DesignDetailsData;
    calculationResult: CalculationResult | undefined;
    invalidDesignMessageKey: string | undefined;
}

export interface Feature {
    key: string;
    multivariantValue?: string;
    enabled: boolean;
}

interface FeatureFlagsConfig {
    enableFeatureQuery: boolean;
    features: Feature[];
}

interface ServicesConfiguration {
    featureFlagsConfig: FeatureFlagsConfig;
    showDevVersionTextInReport: boolean;
    disableStaticContentHashInReport: boolean;
}

interface ApiAddTranslationsInput {
    languageId: string;
    translations: Record<string, string>;
}

type ApiSetTemplatesInput = Record<string, Record<string, string>>;

interface TemplateDetails {
    templatePath: string;
    template: string;
}

function getHashUrl(uri: string): string {
    const dotnetManifest = window.dotnetManifestSP;
    if (dotnetManifest != null) {
        const hash = dotnetManifest[uri];
        const extensionIndex = uri.lastIndexOf('.');
        const extension = uri.substring(extensionIndex + 1);
        const nameWithoutExtension = uri.substring(0, extensionIndex);

        return `${nameWithoutExtension}.${hash}.${extension}`;
    }

    return uri;
}

export interface StrengthDotnetApi {
    design: {
        convert: (projectDesign: StrengthProjectDesign, apiOptions?: DotnetApiOptions) => Promise<StrengthProjectDesign>;
        create: (designCreateRequest: StrengthApiDesignCreateRequest, apiOptions?: DotnetApiOptions) => Promise<StrengthProjectDesign>;
        details: (projectDesign: StrengthProjectDesign, apiOptions?: DotnetApiOptions) => Promise<StrengthDesignDetailsData>;
        update: (updateDesignOptions: StrengthDesignServiceUpdateDesignOptions, apiOptions?: DotnetApiOptions) => Promise<StrengthApiDesignUpdateResponse>;
    };
    calculation: {
        calculate: (projectDesign: StrengthProjectDesign, apiOptions?: DotnetApiOptions) => Promise<StrengthCalculationResult>;
    };
    report: {
        generateHtml: (designReportGenerateOptions: StrengthApiDesignReportGenerateOptions, apiOptions?: DotnetApiOptions) => Promise<string>;
    };
    core: {
        createAndCalculate: (designCreateRequest: StrengthApiDesignCreateRequest, apiOptions?: DotnetApiOptions) => Promise<StrengthCreateAndCalculateResult>;
        convertAndCalculate: (projectDesign: StrengthProjectDesign, apiOptions?: DotnetApiOptions) => Promise<StrengthConvertAndCalculateResult>;
        updateAndCalculate: (designUpdateRequest: StrengthApiDesignUpdateRequest, apiOptions?: DotnetApiOptions) => Promise<StrengthUpdateAndCalculateResult>;
    };
}

export interface PunchDotnetApi {
    design: {
        convert: (projectDesign: PunchProjectDesign, apiOptions?: DotnetApiOptions) => Promise<PunchProjectDesign>;
        create: (designCreateRequest: PunchApiDesignCreateRequest, apiOptions?: DotnetApiOptions) => Promise<PunchProjectDesign>;
        details: (projectDesign: PunchProjectDesign, apiOptions?: DotnetApiOptions) => Promise<PunchDesignDetailsData>;
        update: (updateDesignOptions: PunchDesignServiceUpdateDesignOptions, apiOptions?: DotnetApiOptions) => Promise<PunchApiDesignUpdateResponse>;
    };
    calculation: {
        calculate: (projectDesign: PunchProjectDesign, apiOptions?: DotnetApiOptions) => Promise<PunchCalculationResult>;
    };
    report: {
        generateHtml: (designReportGenerateOptions: PunchApiDesignReportGenerateOptions, apiOptions?: DotnetApiOptions) => Promise<string>;
    };
    core: {
        createAndCalculate: (designCreateRequest: PunchApiDesignCreateRequest, apiOptions?: DotnetApiOptions) => Promise<PunchCreateAndCalculateResult>;
        convertAndCalculate: (projectDesign: PunchProjectDesign, apiOptions?: DotnetApiOptions) => Promise<PunchConvertAndCalculateResult>;
        updateAndCalculate: (designUpdateRequest: PunchApiDesignUpdateRequest, apiOptions?: DotnetApiOptions) => Promise<PunchUpdateAndCalculateResult>;
    };
}

@Injectable({
    providedIn: 'root'
})
export class DotnetService {
    private addedTranslationNames: Record<string, null> = {};
    private templatesAdded = false;

    private readonly exportName = {
        [designTypes.strength.id]: 'Strength',
        [designTypes.punch.id]: 'Punch',
    };

    constructor(
        private readonly featureVisibilityService: FeatureVisibilityService,
        private readonly localizationService: LocalizationService,
        private readonly modalService: ModalService,
        private readonly userSettingsService: UserSettingsService,
    ) {
        this.strengthApi = this.api as unknown as StrengthDotnetApi;
        this.punchApi = this.api as unknown as PunchDotnetApi;
    }

    // make sure to initialize this after the constructor
    private dotnetProvider!: DotnetProvider;

    public init() {
        this.dotnetProvider = new DotnetProvider({
            servicesConfiguration: this.getServicesConfiguration(),
            dotnetValidateScopes: environment.dotnetValidateScopes
        });
    }

    public strengthApi: StrengthDotnetApi;
    public punchApi: PunchDotnetApi;

    public api = {
        app: {
            data: async (apiOptions?: DotnetApiOptions): Promise<AppData> => {
                return await this.handleError(async () => {
                    // load AppData from S3
                    // this way we do not need to load .NET at the app start
                    const disableNoCacheFetch = this.getDisableNoCacheFetch();

                    const response = await fetch(`cdn/pe-ui-sp/dotnet/${getHashUrl('data.json')}`, {
                        method: 'GET',
                        cache: disableNoCacheFetch ? undefined : 'no-cache'
                    });
                    if (!response.ok) {
                        throw new Error('sp data.json error response', { cause: response });
                    }

                    return await response.json() as AppData;
                }, apiOptions);
            }
        },
        design: {
            convert: async (projectDesign: ProjectDesign, apiOptions?: DotnetApiOptions): Promise<DesignConvertResult | undefined> => {
                return await this.handleError(async () => {
                    await this.dotnetProvider.initialize();

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                    const result = JSON.parse(this.getExport(projectDesign.designTypeId).DesignApiConvert(JSON.stringify(projectDesign))) as DesignConvertResult;
                    if (result.invalidDesignMessageKey != null) {
                        this.modalService.openUnsupportedDesignModal(result.invalidDesignMessageKey);
                        return undefined;
                    }

                    return result;
                }, apiOptions);
            },
            create: async (designCreateRequest: ApiDesignCreateRequest, apiOptions?: DotnetApiOptions): Promise<ProjectDesign> => {
                return await this.handleError(async () => {
                    await this.dotnetProvider.initialize();

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                    return JSON.parse(this.getExport(designCreateRequest.designTypeId).DesignApiCreate(JSON.stringify(designCreateRequest))) as ProjectDesign;
                }, apiOptions);
            },
            details: async (projectDesign: ProjectDesign, apiOptions?: DotnetApiOptions): Promise<DesignDetailsData> => {
                return await this.handleError(async () => {
                    await this.dotnetProvider.initialize();

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                    return JSON.parse(this.getExport(projectDesign.designTypeId).DesignApiDetails(JSON.stringify(projectDesign))) as DesignDetailsData;
                }, apiOptions);
            },
            update: async (updateDesignOptions: DesignServiceUpdateDesignOptions, apiOptions?: DotnetApiOptions): Promise<ApiDesignUpdateResponse> => {
                return await this.handleError(async () => {
                    await this.dotnetProvider.initialize();

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                    return JSON.parse(this.getExport(updateDesignOptions.projectDesign.designTypeId).DesignApiUpdate(JSON.stringify(updateDesignOptions))) as ApiDesignUpdateResponse;
                }, apiOptions);
            }
        },
        calculation: {
            calculate: async (projectDesign: ProjectDesign, apiOptions?: DotnetApiOptions): Promise<CalculationResult> => {
                return await this.handleError(async () => {
                    await this.dotnetProvider.initialize();

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                    return JSON.parse(this.getExport(projectDesign.designTypeId).CalculationApiCalculate(JSON.stringify(projectDesign))) as CalculationResult;
                }, apiOptions);
            }
        },
        report: {
            generateHtml: async (designReportGenerateOptions: ApiDesignReportGenerateOptions, apiOptions?: DotnetApiOptions): Promise<string> => {
                return await this.handleError(async () => {
                    await this.dotnetProvider.initialize();

                    const reportLanguagePromise = this.addReportLanguage(designReportGenerateOptions.localization.language);
                    const reportTemplatesPromise = this.addReportTemplates();

                    await Promise.all([reportLanguagePromise, reportTemplatesPromise]);

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                    return JSON.parse(this.getExport(designReportGenerateOptions.projectDesign.designTypeId).ReportApiGenerateHtml(JSON.stringify(designReportGenerateOptions))) as string;
                }, apiOptions);
            }
        },
        core: {
            createAndCalculate: async (designCreateRequest: ApiDesignCreateRequest, apiOptions?: DotnetApiOptions): Promise<CreateAndCalculateResult> => {
                return await this.handleError(async () => {
                    await this.dotnetProvider.initialize();

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                    return JSON.parse(this.getExport(designCreateRequest.designTypeId).CoreApiCreateAndCalculate(JSON.stringify(designCreateRequest))) as CreateAndCalculateResult;
                }, apiOptions);
            },
            convertAndCalculate: async (projectDesign: ProjectDesign, apiOptions?: DotnetApiOptions): Promise<ConvertAndCalculateResult | undefined> => {
                return await this.handleError(async () => {
                    await this.dotnetProvider.initialize();

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                    const result = JSON.parse(this.getExport(projectDesign.designTypeId).CoreApiConvertAndCalculate(JSON.stringify(projectDesign))) as ApiConvertResult;
                    if (result.invalidDesignMessageKey != null) {
                        this.modalService.openUnsupportedDesignModal(result.invalidDesignMessageKey);
                        return undefined;
                    }

                    return result;
                }, apiOptions);
            },
            updateAndCalculate: async (designUpdateRequest: ApiDesignUpdateRequest, apiOptions?: DotnetApiOptions): Promise<UpdateAndCalculateResult> => {
                return await this.handleError(async () => {
                    await this.dotnetProvider.initialize();

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                    return JSON.parse(this.getExport(designUpdateRequest.projectDesign.designTypeId).CoreApiUpdateAndCalculate(JSON.stringify(designUpdateRequest))) as UpdateAndCalculateResult;
                }, apiOptions);
            }
        },
    };



    private getDisableNoCacheFetch(): boolean {
        return window.dotnetManifestSP != null;
    }

    private async handleError<T>(fn: () => Promise<T>, apiOptions: DotnetApiOptions | undefined) {
        try {
            return await fn();
        }
        catch (error) {
            if (!apiOptions?.supressErrorMessage) {
                this.openAlertError();
            }

            // at some point, we stopped getting the stack trace with errors
            // temp solution to get errors with stack trace until we find out root cause
            const innerError = error as { message: string; stack: string };
            innerError.message += innerError.stack;
            // eslint-disable-next-line @typescript-eslint/only-throw-error
            throw innerError;
        }
    }

    private getServicesConfiguration(): ServicesConfiguration {
        // this is done this way beacuse getAllEnabledFlags() returns array of strings of all enabled flags (which is ok only for boolean flags)
        // TODO TEAM: getAllEnabledFlags() should support returning array of Feature objects, no matter what variant value a feature holds
        const allEnabled = this.featureVisibilityService.getAllEnabledFlags()
            .map(feature => ({ key: substringAfterLast(feature, '_') ?? feature, enabled: true }));

        // temp solution for regions until above TODO is resolved
        allEnabled.push({
            key: 'strengthregions',
            multivariantValue: JSON.stringify(this.featureVisibilityService.getFeatureValue<number[] | null>('SP_StrengthRegions', [])),
            enabled: true
        } as Feature);
        allEnabled.push({
            key: 'punchregions',
            multivariantValue: JSON.stringify(this.featureVisibilityService.getFeatureValue<number[] | null>('SP_PunchRegions', [])),
            enabled: true
        } as Feature);

        return {
            featureFlagsConfig: {
                enableFeatureQuery: environment.featureFlagsQueryEnabled,
                features: allEnabled
            },
            showDevVersionTextInReport: environment.dotnetShowDevVersionTextInReport,
            disableStaticContentHashInReport: environment.dotnetDisableStaticContentHashInReport
        };
    }

    private async addReportLanguage(language: string): Promise<void> {
        // add translations to wasm if not already added
        if (!(language in this.addedTranslationNames)) {
            const addTranslationsInput: ApiAddTranslationsInput = {
                languageId: language,
                translations: await this.localizationService.filterReportTranslations(language),
            };
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            this.dotnetProvider.exports.Hilti.SP.Wasm.Export.App.ReportApiAddTranslations(JSON.stringify(addTranslationsInput));

            this.addedTranslationNames[language] = null;
        }
    }

    private async addReportTemplates(): Promise<void> {
        // add templates to wasm if not already added
        if (!this.templatesAdded) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            const templatesPath = JSON.parse(this.dotnetProvider.exports.Hilti.SP.Wasm.Export.App.ReportApiGetTemplatesPath()) as string[];

            const setTemplatesInput = await this.fetchAllTemplates(templatesPath);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            this.dotnetProvider.exports.Hilti.SP.Wasm.Export.App.ReportApiSetTemplates(JSON.stringify(setTemplatesInput));

            this.templatesAdded = true;
        }
    }

    private async fetchAllTemplates(templatesPath: string[]): Promise<ApiSetTemplatesInput> {
        const disableNoCacheFetch = this.getDisableNoCacheFetch();
        const fetchTemplatePromises: Promise<TemplateDetails>[] = [];

        for (const templatePath of templatesPath) {
            fetchTemplatePromises.push(this.fetchTemplate(disableNoCacheFetch, templatePath));
        }

        const resolvedTemplatePromises = await Promise.allSettled(fetchTemplatePromises);

        const setTemplatesInput: ApiSetTemplatesInput = {};
        for (const resolvedTemplatePromise of resolvedTemplatePromises) {
            if (resolvedTemplatePromise.status == 'rejected') {
                throw new Error(resolvedTemplatePromise.reason as string);
            }

            const templateDetails = resolvedTemplatePromise.value;
            const templatePath = templateDetails.templatePath;
            const templateNameIndex = templatePath.lastIndexOf('/');
            const templateDirectory = templatePath.substring(0, templateNameIndex);
            const templateName = templatePath.substring(templateNameIndex + 1);

            const templatesDirectoryDetails = setTemplatesInput[templateDirectory] = setTemplatesInput[templateDirectory] ?? {};
            templatesDirectoryDetails[templateName] = templateDetails.template;
        }

        return setTemplatesInput;
    }

    private async fetchTemplate(disableNoCacheFetch: boolean, templatePath: string): Promise<TemplateDetails> {
        const response = await fetch(`cdn/pe-ui-sp/dotnet/${getHashUrl(templatePath)}`, {
            method: 'GET',
            cache: disableNoCacheFetch ? undefined : 'no-cache'
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch template: ${templatePath} - ${response.statusText}`);
        }

        const template = await response.text();

        return {
            templatePath,
            template
        };
    }

    private openAlertError(): void {
        const title = this.localizationService.getString('Agito.Hilti.Profis3.ServerErrorAlert.Title', { defaultString: 'ERROR' });
        const message = this.localizationService.getString('Agito.Hilti.Profis3.ServerErrorAlert.Message', { defaultString: 'Please try again later. If the problem persists contact us at' });

        const commonRegion = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value ?? 0);
        const contactUrl = commonRegion?.contactUrl ? commonRegion.contactUrl.replace('mailto:', '') : 'info@info';

        const fullMessage = message + '\n' + contactUrl;

        this.modalService.openAlertError(title, fullMessage);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getExport(designTypeId: DesignTypeId): any {
        const apiName = this.exportName[designTypeId];
        if (apiName == null) {
            throw new Error('unknown DesignTypeId');
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return this.dotnetProvider.exports.Hilti.SP.Wasm.Export[apiName];
    }
}
