import { ContolsStyleSheets, IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { SuggestedAnchor } from '../suggested-anchor/SuggestedAnchor';
import styles from './react-suggested-anchor-group.css';
import { PureComponent } from '../react-import';

let sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

const ControlHeader = (window as any).pe.reactComponents.ControlHeader;

export interface ISuggestedAnchorGroupItem {
    value: number;
    text: string;
    tooltip?: string;
    tooltipTitle?: string;
    uiPropertyId?: number;
    disabled: boolean;
    isNew?: boolean;
    buttonText?: string;
    buttonImage?: string;
    buttonImageStyle?: IResponsiveIconStyle;
    tag?: string;
}

export interface ISuggestedAnchorGroupProps extends IControlProps {
    checkedValue?: number;
    isSmartBaseplateEnable?: boolean;
    checkedValues?: number[];
    popup?: string;
    items?: ISuggestedAnchorGroupItem[];
    initialItems?: ISuggestedAnchorGroupItem[];
    valueChanged?: (value: number, checked: boolean) => void;
    infoClicked?: () => void;
}
class _SuggestedAnchorGroup extends PureComponent<ISuggestedAnchorGroupProps> {
    public static styleSheets = [sheet];

    constructor(props: ISuggestedAnchorGroupProps) {
        super(props);

        this.onItemCheck = this.onItemCheck.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const groupId = `${this.props.controlId}-group`;
        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;
        let bulbClassName = '';
        if(this.props.isSmartBaseplateEnable){
            bulbClassName = 'sprite-info-tooltip';
        }
        else {
            bulbClassName = this.props.checkedValue == null ? 'sprite-light-bulb' : 'sprite-light-bulb-on';
        }
        return (
            <div data-control-id={this.props.controlId} className={`react-suggested-anchor-group control ${this.props.sizeClass}`}>
                <div className='header-container bulb'>
                    <ControlHeader
                        text={this.props.title}
                        controlId={groupId}
                        tooltip={tooltipText}
                        tooltipTitle={tooltipTitle}
                        localization={this.props.localization}
                    />

                    <div className='bulb-icon'>
                        {
                            <button
                                type='button'
                                className={`control-tooltip-popup sprite ${bulbClassName}`}
                                onClick={this.onInfoClick}
                                disabled={this.props.disabled}>
                            </button>
                        }
                    </div>
                </div>
                <div
                    className='control-container'
                    data-control-id={groupId}>
                    {
                        this.props.items?.map((item) =>
                            <SuggestedAnchor
                                controlId={`${this.props.controlId}-${item.value}`}
                                key={`${this.props.controlId}-${item.value}`}
                                value={item.value}
                                checked={this.props.isSmartBaseplateEnable ? this.props.checkedValues?.includes(item.value) ?? false : item.value == this.props.checkedValue}
                                type={this.props.type}
                                title={null as any}
                                size={this.props.size}
                                disabled={item.disabled || this.props.disabled}
                                hidden={this.props.hidden}
                                localization={this.props.localization}
                                checkedChanged={this.onItemCheck}
                                isNew={item.isNew}
                                isSmartBaseplateEnable={this.props.isSmartBaseplateEnable}
                                isUserAnchorChosen={true}
                                buttonText={item.buttonText}
                                buttonImage={item.buttonImage}
                                buttonImageStyle={item.buttonImageStyle}
                                tag={item.tag}
                            />)
                    }
                </div>
            </div>
        );
    }


    private onItemCheck(checked: boolean, value: number) {
        if (this.props.valueChanged != null) {
            this.props.valueChanged(value, checked);
        }
    }

    private onInfoClick() {

        if (this.props.infoClicked != null && (((this.props.checkedValue != null) && !this.props.isSmartBaseplateEnable) || (!this.props.disabled && this.props.isSmartBaseplateEnable))) {
            this.props.infoClicked();
        }
    }
}

export const SuggestedAnchorGroup: typeof _SuggestedAnchorGroup & ContolsStyleSheets = _SuggestedAnchorGroup