import { ButtonSize, ButtonType } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
import { ContolsStyleSheets, IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import styles from './react-profile-size.css';
import { PureComponent } from '../react-import';

const sheet = new CSSStyleSheet();
sheet.replaceSync(styles);
const ControlHeader = (window as any).pe.reactComponents.ControlHeader;
const Button = (window as any).pe.reactComponents.Button;

export interface IProfileSizeProps extends IControlProps {
    selectedValue?: number;
    buttonText?: string;
    clicked?: (selectedValue: number) => void;
}

class _ProfileSize extends PureComponent<IProfileSizeProps> {
    public static styleSheets = [sheet];

    constructor(props: IProfileSizeProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const buttonId = `${this.props.controlId}-button`;

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        return (
            <div
                data-control-id={this.props.controlId} className={`react-profile-size-control control ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className='control-container'>
                    <Button
                        controlId={buttonId}
                        buttonSize={ButtonSize.Full}
                        buttonType={ButtonType.Default}
                        disabled={this.props.disabled}
                        hidden={this.props.hidden}
                        image={null}
                        text={this.props.buttonText}
                        type={Button}
                        title={null}
                        size={null}
                        localization={this.props.localization}
                        clicked={this.onClick}
                        tooltip={this.props.title != null && this.props.title != '' ? null : this.props.tooltip}
                        tooltipTitle={this.props.title != null && this.props.title != '' ? null : this.props.tooltipTitle}
                    />
                </div>
            </div>
        );
    }

    private onClick() {
        if (this.props.clicked != null) {
            this.props.clicked(this.props.selectedValue!);
        }
    }
}

export const ProfileSize: typeof _ProfileSize & ContolsStyleSheets = _ProfileSize;