import { CodeList } from './code-list';
import { ShapeTypeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class ShapeType extends CodeList {

    public static fromService(codeList: ShapeTypeEntityC2C) {
        return new ShapeType(CodeList.baseFromService('ShapeTypeEntityC2C', codeList));
    }

    public override cloneDeep(): ShapeType {
        return super.cloneDeep() as ShapeType;
    }
}
