import { BaseUpdate } from '@profis-engineering/c2c-gl-model/base-update';
import {
    ApplicationTypeC2C, LoadTypeC2C, OverlayPosition
} from '@profis-engineering/c2c-gl-model/components/base-materials-c2c';
import {
    CrossSectionShape
} from '@profis-engineering/c2c-gl-model/components/base-structure-helper-c2c';
import { IModelC2C } from '@profis-engineering/c2c-gl-model/gl-model';
import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';

import { DesignC2C } from '../../../../shared/entities/design-c2c';
import {
    LoadCombinationC2C, UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    CrossSectionType, DesignForYield, LoadDefinitionSection, LoadDefinitionType,
    LoadingDefinitionType, LongitudinalReinforcementOption
} from '../../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';

export class BaseMaterialsC2CUpdate extends BaseUpdate {

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged()
    private OnPropertiesChange(model: IModelC2C, design: DesignC2C) {
        if (!model.baseMaterialsC2C) {
            throw new Error('model baseMaterialsC2C is undefined');
        }

        model.baseMaterialsC2C.perBarLoadType = design.designData.projectDesignC2C?.loads.loadDefinitionType == LoadDefinitionType.PerBar &&
            (design.designData.projectDesignC2C.loads.designForYield == DesignForYield.WithoutDesignForYield);

        model.baseMaterialsC2C.perBarLoadSection = design.designData.projectDesignC2C?.loads.loadDefinitionSection == LoadDefinitionSection.PerBar;
        model.baseMaterialsC2C.reinforcementArrangement = design.designData.projectDesignC2C?.product.reinforcementOption == LongitudinalReinforcementOption.ReinforcementArrangement
            || design.designData.projectDesignC2C?.product.reinforcementOption == LongitudinalReinforcementOption.Single
            || design.designData.projectDesignC2C?.product.reinforcementOption == LongitudinalReinforcementOption.IrregularPlacement;
        model.baseMaterialsC2C.shearStressLoadDefinitionType = design.designData.projectDesignC2C?.loads.loadingDefinitionType == LoadingDefinitionType.ShearStress;
    }

    @propertyValueChanged(UIProperty.General_C2C_ConnectionType)
    private General_ConnectionType(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.connectionType = value;
        }
    }

    @propertyValueChanged(UIProperty.Application_C2C_OverlayCrossSection)
    private Application_OverlayCrossSection(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.overlayCrossSection = value;
        }
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_FlangeThickness)
    private Overlay_FlangeThickness(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.existingStructure.flangeHeight = value;
        }
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_ConcreteHeightHold)
    @propertyValueChanged(UIProperty.ExistingStructure_C2C_Length)
    @propertyValueChanged(UIProperty.ExistingStructure_C2C_Height)
    @propertyValueChanged(UIProperty.NewStructure_ExistingConcrete_C2C_Length)
    private Overlay_ConcreteHeightExisting(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.existingStructure.height = value;
        }
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_ConcreteHeightHNew)
    @propertyValueChanged(UIProperty.NewStructure_C2C_Thickness)
    @propertyValueChanged(UIProperty.NewStructure_NewConcrete_C2C_Height)
    private Overlay_ConcreteHeightNew(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.newStructure.height = value;
        }
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_SlabLength)
    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_GenericLength)
    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_BeamLength)
    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_WallHeight)
    private Overlay_ConcreteSlabLength(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.existingStructure.depth = value;
            model.baseMaterialsC2C.newStructure.depth = value;
        }
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_SlabWidth)
    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_GenericWidth)
    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_BeamWidth)
    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_WallWidth)
    private Overlay_ConcreteSlabWidth(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.existingStructure.width = value;
            model.baseMaterialsC2C.newStructure.width = value;
        }
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_OverlayPosition_Position)
    private Overlay_ConcretePosition(value: OverlayPosition, model: IModelC2C) {
        if (!model.baseMaterialsC2C) {
            throw new Error('model baseMaterialsC2C is undefined');
        }

        model.baseMaterialsC2C.overlayPosition = value;
    }

    @propertyValueChanged(UIProperty.ExistingStructure_C2C_DepthPositive)
    private ExistingStructure_C2C_DepthPositive(value: number, model: IModelC2C) {
        if (!model.baseMaterialsC2C) {
            throw new Error('model baseMaterialsC2C is undefined');
        }

        model.baseMaterialsC2C.existingStructure.depthPositive = value;
    }

    @propertyValueChanged(UIProperty.ExistingStructure_C2C_DepthNegative)
    private ExistingStructure_C2C_DepthNegative(value: number, model: IModelC2C) {
        if (!model.baseMaterialsC2C) {
            throw new Error('model baseMaterialsC2C is undefined');
        }

        model.baseMaterialsC2C.existingStructure.depthNegative = value;
    }

    @propertyValueChanged(UIProperty.ExistingStructure_C2C_Width)
    @propertyValueChanged(UIProperty.NewStructure_ExistingConcrete_C2C_Width)
    private ExistingStructure_C2C_Width(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.existingStructure.width = value;
        }
    }

    @propertyValueChanged(UIProperty.ExistingStructure_C2C_Thickness)
    @propertyValueChanged(UIProperty.NewStructure_ExistingConcrete_C2C_Height)
    private ExistingStructure_C2C_Thickness(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.existingStructure.depth = value;
        }
    }

    @propertyValueChanged(UIProperty.NewStructure_C2C_Width)
    @propertyValueChanged(UIProperty.NewStructure_NewConcrete_C2C_Width)
    private NewStructure_C2C_Width(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.newStructure.width = value;
        }
    }

    @propertyValueChanged(UIProperty.NewStructure_C2C_Offset)
    private NewStructure_C2C_Offset(value: number, model: IModelC2C) {
        if (!model.baseMaterialsC2C) {
            throw new Error('model baseMaterialsC2C is undefined');
        }

        model.baseMaterialsC2C.newStructure.offset = value;
    }

    @propertyValueChanged(UIProperty.NewStructure_C2C_Depth)
    private NewStructure_C2C_Depth(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.newStructure.depth = value;
        }
    }

    @propertyValueChanged(UIProperty.SupportStructure_C2C_Depth)
    private SupportStructure_C2C_Depth(value: number, model: IModelC2C) {
        if (!model.baseMaterialsC2C) {
            throw new Error('model baseMaterialsC2C is undefined');
        }

        model.baseMaterialsC2C.supportStructure.depth = value;
    }

    @propertyValueChanged(UIProperty.SupportStructure_C2C_Width)
    private SupportStructure_C2C_Width(value: number, model: IModelC2C) {
        if (!model.baseMaterialsC2C) {
            throw new Error('model baseMaterialsC2C is undefined');
        }

        model.baseMaterialsC2C.supportStructure.width = value;
    }

    @propertyValueChanged(UIProperty.SupportStructure_C2C_Height)
    private SupportStructure_C2C_Height(value: number, model: IModelC2C) {
        if (!model.baseMaterialsC2C) {
            throw new Error('model baseMaterialsC2C is undefined');
        }

        model.baseMaterialsC2C.supportStructure.height = value;

    }

    @propertyValueChanged(UIProperty.Loads_C2C_LoadType)
    private Loads_C2C_LoadTYpe(value: LoadTypeC2C, model: IModelC2C) {
        if (!model.baseMaterialsC2C) {
            throw new Error('model baseMaterialsC2C is undefined');
        }

        model.baseMaterialsC2C.loadType = value;
    }

    @propertyValueChanged(UIProperty.NewStructure_NewConcrete_C2C_OffsetBottom)
    private NewStructure_NewConcrete_C2C_OffsetBottom(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.newStructure.offsetBottom = value;
        }
    }

    @propertyValueChanged(UIProperty.NewStructure_NewConcrete_C2C_OffsetLeft)
    private NewStructure_NewConcrete_C2C_OffsetLeft(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.newStructure.offsetLeft = value;
        }
    }

    @propertyValueChanged(UIProperty.NewStructure_NewConcrete_C2C_OffsetRight)
    private NewStructure_NewConcrete_C2C_OffsetRight(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.newStructure.offsetRight = value;
        }
    }

    @propertyValueChanged(UIProperty.NewStructure_NewConcrete_C2C_OffsetTop)
    private NewStructure_NewConcrete_C2C_OffsetTop(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.newStructure.offsetTop = value;
        }
    }

    @propertyValueChanged(UIProperty.NewStructure_ExistingConcrete_C2C_ExtensionAtSupport)
    @propertyValueChanged(UIProperty.Application_C2C_Connection_IsExtensionAtSupport)
    private NewStructure_ExistingConcrete_C2C_ExtensionAtSupport(value: boolean, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.atSupport = value;
        }
    }

    @propertyValueChanged(UIProperty.NewStructure_ExistingConcrete_C2C_ExtensionAtIntersection)
    private NewStructure_ExistingConcrete_C2C_ExtensionAtIntersection(value: boolean, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.atIntersection = value;
        }
    }

    @propertyValueChanged(UIProperty.NewStructure_Rotation_C2C_Angle)
    @propertyValueChanged(UIProperty.Application_C2C_Connection_Angle)
    private NewStructure_Rotation_C2C_Angle(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.newStructure.angle = value;
        }
    }

    @propertyValueChanged(UIProperty.Overlay_Rotation_C2C_Angle)
    private ConcreteBlock_Rotation_C2C_Angle(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.model3dRotation = value;
        }
    }

    @propertyValueChanged(UIProperty.Application_C2C_Connection_CrossSectionExistingStructure)
    private Application_C2C_Connection_CrossSectionExistingStructure(value: CrossSectionType, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.existingStructure.crossSection = value as unknown as CrossSectionShape;
        }
    }

    @propertyValueChanged(UIProperty.Application_C2C_Connection_CrossSectionNewStructure)
    private Application_C2C_Connection_CrossSectionNewStructure(value: CrossSectionType, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.newStructure.crossSection = value as unknown as CrossSectionShape;
        }
    }

    @propertyValueChanged(UIProperty.General_C2C_ApplicationType)
    private General_C2C_ApplicationType(value: ApplicationTypeC2C, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.applicationType = value;
        }
    }

    @propertyValueChanged(UIProperty.Loads_C2C_LoadCombinations)
    private Loads_C2C_LoadCombinations(value: LoadCombinationC2C[], model: IModelC2C) {
        if (value != null) {
            model.loadCombinationForce = {
                loads: []
            };
            value?.forEach(load => {
                model.loadCombinationForce?.loads.push({
                    id: load.id,
                    name: load.name,
                    forceX: load.forceX,
                    forceY: load.forceY,
                    forceZ: load.forceZ,
                    momentX: load.momentX,
                    momentY: load.momentY,
                    momentZ: load.momentZ,
                    sustainedForceZ: load.sustainedForceZ,
                    sustainedMomentX: load.sustainedMomentX,
                    sustainedMomentY: load.sustainedMomentY,
                    dynamicZone1: load.dynamicZone1,
                    dynamicZone2: load.dynamicZone2,
                    dynamicZone3: load.dynamicZone3,
                    dynamicZone4: load.dynamicZone4,
                    dynamicZoneGeneric: load.dynamicZoneGeneric,
                    tedX: load.tedX,
                    tedY: load.tedY,
                    zone1: load.zone1,
                    zone2: load.zone2,
                    zone3: load.zone3,
                    zone4: load.zone4,
                    zoneGeneric: load.zoneGeneric
                });
            });
        }
    }

    @propertyValueChanged(UIProperty.Application_C2C_Connection_IsXDirectionContinuous)
    private Application_C2C_Connection_IsXDirectionContinuous(value: boolean, model: IModelC2C) {
        if (!model.baseMaterialsC2C) {
            throw new Error('model baseMaterialsC2C is undefined');
        }

        model.baseMaterialsC2C.isxDirectionContinuous = value;
    }

    @propertyValueChanged(UIProperty.NewStructure_NewConcrete_C2C_AdditionalOffset)
    private NewStructure_NewConcrete_C2C_AdditionalOffset(value: number, model: IModelC2C) {
        if (!model.baseMaterialsC2C) {
            throw new Error('model baseMaterialsC2C is undefined');
        }

        model.baseMaterialsC2C.additionalOffsetDistance = value;
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Geometry_ExistingWidth)
    private Overlay_ConcreteExistingWidth(value: number, model: IModelC2C) {
        if (value != null && model.baseMaterialsC2C) {
            model.baseMaterialsC2C.existingStructure.existingWidth = value;
        }
    }
}
