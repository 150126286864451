import { addText2DToGlobal, removeText2DFromGlobal } from '../consoleUpdatePropertyMode.js';
import { Text2D } from './text-2d.js';
export class UnitText2D extends Text2D {
  internalValue;
  displayedValue;
  unitGroup;
  property;
  extraPrecision;
  onValueEntered;
  setValueInternalCall;
  setValueOnLineInternalCall;
  dot;
  r;
  formatFn;
  precision = 7;
  unitConverter;
  mathCalculator;
  propertyInfo;
  formatChangedRemoveEvent;
  disabledChangedRemoveEvent;
  showTextEditorBase;
  constructor(ctor) {
    super(ctor);
    this.unitGroup = ctor.unitGroup;
    this.property = ctor.property;
    this.onValueEntered = ctor.onValueEntered;
    this.cache = ctor.cache;
    this.unitConverter = ctor.unitConverter;
    this.mathCalculator = ctor.mathCalculator;
    this.propertyInfo = ctor.propertyInfo;
    this.extraPrecision = ctor.extraPrecision ?? false;
    this.showTextEditorBase = this.showTextEditor;
    this.showTextEditor = this.showTextEditorUnit.bind(this);
    this.refreshText = this.refreshText.bind(this);
    this.setValueInternalCall = false;
    this.setValueOnLineInternalCall = false;
    this.formatChangedRemoveEvent = this.unitConverter.formatChanged(this.refreshText);
  }
  static createForDisplay(ctor) {
    return new UnitText2D({
      property: undefined,
      camera: ctor.camera,
      scene: ctor.scene,
      sceneEvents: ctor.sceneEvents,
      context3d: ctor.context3d,
      unitGroup: ctor.unitGroup,
      renderNextFrame: ctor.renderNextFrame,
      showTextEditor: undefined,
      editable: false,
      cache: ctor.cache,
      extraPrecision: ctor.extraPrecision,
      isDropDown: ctor.isDropDown,
      glFontTexture: ctor.glFontTexture,
      unitConverter: ctor.unitConverter,
      mathCalculator: ctor.mathCalculator,
      propertyInfo: ctor.propertyInfo
    });
  }
  static createForProperty(ctor, property, update, ignorePropertyEditable) {
    const text = new UnitText2D({
      property,
      camera: ctor.camera,
      scene: ctor.scene,
      sceneEvents: ctor.sceneEvents,
      context3d: ctor.context3d,
      unitGroup: ctor.unitGroup,
      renderNextFrame: ctor.renderNextFrame,
      showTextEditor: ctor.showTextEditor,
      editable: true,
      cache: ctor.cache,
      extraPrecision: ctor.extraPrecision,
      isDropDown: ctor.isDropDown,
      glFontTexture: ctor.glFontTexture,
      unitConverter: ctor.unitConverter,
      mathCalculator: ctor.mathCalculator,
      propertyInfo: ctor.propertyInfo
    });
    UnitText2D.setForProperty(text, property, update, ignorePropertyEditable);
    addText2DToGlobal(text);
    return text;
  }
  static setForProperty(text, property, update, ignorePropertyEditable) {
    text.property = property;
    text.onValueEntered = (source, internalValue) => {
      const updatedInternalValue = update != null ? update(text, internalValue) : internalValue;
      if (updatedInternalValue !== undefined && text.property != undefined) {
        text.propertyInfo.setPropertyValue(text.property, updatedInternalValue);
      }
    };
    if (text.disabledChangedRemoveEvent != null) {
      text.disabledChangedRemoveEvent();
      text.disabledChangedRemoveEvent = undefined;
    }
    text.editable = ignorePropertyEditable ? true : !text.propertyInfo.isPropertyDisabled(text.property);
    text.disabledChangedRemoveEvent = text.propertyInfo.propertyDisabledChanged(text.property, () => {
      text.editable = ignorePropertyEditable ? true : !text.propertyInfo.isPropertyDisabled(property);
    });
  }
  setEditable(editable) {
    this.editable = editable;
  }
  getEditable() {
    return this.editable;
  }
  setText(text) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    if (this.setValueInternalCall === false && this.setValueOnLineInternalCall === false) {
      throw new Error('Use setValue.');
    }
    super.setText(text);
  }
  setValue(internalValue, dot, r, formatFn, forceRefreshText) {
    try {
      this.setValueInternalCall = true;
      this.formatFn = formatFn;
      if (formatFn != null) {
        this.internalValue = internalValue;
        this.dot = false;
        this.setText(formatFn(this, internalValue));
      } else if (forceRefreshText || this.internalValue != internalValue || this.dot != dot || this.r != r) {
        this.internalValue = internalValue;
        this.dot = dot;
        this.r = r;
        if (internalValue != null) {
          let valueString = this.unitConverter.format(internalValue, this.unitGroup, this.property);
          if (dot) {
            valueString += '*';
          }
          if (r) {
            valueString += 'ᴿ';
          }
          this.setText(valueString);
        }
      }
      // hide/show mesh
      this.setEnabled(this.internalValue != null);
    } finally {
      this.setValueInternalCall = false;
    }
  }
  refreshText() {
    if (this.mesh != null) {
      this.setValue(this.internalValue, this.dot, this.r, this.formatFn, true);
    }
  }
  setTextOnLine(text, start, end, up, rotationY, rotationZ, alignX, alignY, skipRepos) {
    if (!this.setValueOnLineInternalCall) {
      throw new Error('Use setValueOnLine');
    }
    super.setTextOnLine(text, start, end, up, rotationY, rotationZ, alignX, alignY, skipRepos);
  }
  setValueOnLine(internalValue, start, end, up, options, formatFn) {
    try {
      this.setValueOnLineInternalCall = true;
      options = options ?? {};
      this.setValue(internalValue, options.dot, options.r, formatFn);
      this.setTextOnLine(this.text, start, end, up, options.rotationY, options.rotationZ, options.alignX, options.alignY, options.skipRepos);
    } finally {
      this.setValueOnLineInternalCall = false;
    }
  }
  dispose() {
    this.formatChangedRemoveEvent();
    removeText2DFromGlobal(this);
    super.dispose();
  }
  onTextEditorClose(exactText) {
    exactText = exactText?.trim();
    if (this.onValueEntered != null && exactText) {
      const calcValue = this.mathCalculator.tryComputeExactUnitValue(exactText, this.unitGroup, this.precision);
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      const internalValue = this.unitConverter.parse(calcValue === null ? exactText : calcValue, this.unitGroup);
      if (internalValue != null && !Number.isNaN(internalValue)) {
        if (internalValue !== this.internalValue) {
          if (!this.internalValue || this.displayedValue !== calcValue) {
            this.onValueEntered(this, internalValue);
          }
        }
      }
    }
  }
  showTextEditorUnit(exactText, displayText, point, isDropDown, uiPropertyId, onChange, onClose) {
    if (this.showTextEditorBase != null) {
      displayText = this.internalValue != null ? this.unitConverter.formatWithUnit(this.internalValue, this.unitGroup, this.property) : '';
      exactText = this.internalValue != null ? this.unitConverter.getNumberWithUnit(this.internalValue, this.unitGroup) : '';
      const uiPropertyId = +(this.property ?? '');
      this.showTextEditorBase(exactText, displayText, point, isDropDown, uiPropertyId, onChange, onClose);
      this.displayedValue = this.mathCalculator.tryComputeExactUnitValue(exactText, this.unitGroup, this.precision);
    }
  }
}
