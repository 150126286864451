import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { CreatePlane } from '@babylonjs/core/Meshes/Builders/planeBuilder';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { StrengthComponent, StrengthComponentConstructor } from '../../strength-component';
import { OpeningModel } from '../../strength-gl-model';

export function calculateHoleDiameter(anchorDiameter: number) {
    return anchorDiameter * 3;
}

const enum SidePosition {
    Left,
    Top,
    Right,
    Bottom
}

interface SideInfo {
    name: string;
    index: number;
    sideDimension: SideDimension;
    color: Color3;
}

interface SideDimension {
    rotation: number;
    x: number;
    y: number;
    length: number;
}

export class Opening extends StrengthComponent {
    private sideMeshes: Mesh[];

    constructor(ctor: StrengthComponentConstructor) {
        super(ctor);
        this.sideMeshes = new Array<Mesh>(4);
    }

    public update(): void {
        this.ensureMesh();
    }

    private ensureMesh() {
        for (const zoneMesh of this.sideMeshes) {
            zoneMesh?.setEnabled(false);
        }

        if (!this.model.opening.defineOpening) {
            return;
        }

        for (const side of this.getSideInfo()) {
            this.sideMeshes[side.index - 1] = this.createSideMesh(side);
        }
    }

    private getSideInfo() {
        const numberOfSides = 4;
        const opening = this.model.opening;
        const sideInfos = new Array<SideInfo>(numberOfSides);

        const baseMaterialColor = Color3.FromHexString('#cbcabf');

        for (let i = 0; i < numberOfSides; i++) {
            const sideNumber = (i + 1);

            sideInfos[i] = {
                name: 'Side' + sideNumber,
                index: sideNumber,
                color: baseMaterialColor,
                sideDimension: this.getSideDimension(i, opening)
            };
        }

        return sideInfos;
    }

    private getSideDimension(sidePosition: SidePosition, opening: OpeningModel): SideDimension {
        switch (sidePosition) {
            case SidePosition.Left:
                return {
                    x: opening.openingOriginX,
                    y: opening.openingOriginY + (opening.openingWidth / 2),
                    length: opening.openingLength,
                    rotation: 0,
                };
            case SidePosition.Top:
                return {
                    x: opening.openingOriginX - (opening.openingLength / 2),
                    y: opening.openingOriginY,
                    length: opening.openingWidth,
                    rotation: -Math.PI / 2,
                };
            case SidePosition.Right:
                return {
                    x: opening.openingOriginX,
                    y: opening.openingOriginY - (opening.openingWidth / 2),
                    length: opening.openingLength,
                    rotation: Math.PI,
                };
            case SidePosition.Bottom:
                return {
                    x: opening.openingOriginX + (opening.openingLength / 2),
                    y: opening.openingOriginY,
                    length: opening.openingWidth,
                    rotation: Math.PI / 2,
                };
            default:
                throw new Error('Unknown side position');
        }
    }

    private createSideMesh(sideInfo: SideInfo): Mesh {
        const name = sideInfo.name;
        const mesh = this.cache.meshCache.create(name, () => CreatePlane(name, {}, this.scene));
        const materialName = sideInfo.name + 'Material';
        const material = this.cache.materialCache.create(materialName, () => new StandardMaterial(materialName, this.scene));

        material.diffuseColor = sideInfo.color;
        material.alpha = this.model.visibilityModel.TransparentConcrete ? 0.75 : 1;

        mesh.setEnabled(true);
        mesh.material = material;
        mesh.scaling.x = sideInfo.sideDimension.length;
        mesh.scaling.y = this.model.baseMaterial.height;
        mesh.rotation.y = sideInfo.sideDimension.rotation;
        mesh.position.set(sideInfo.sideDimension.x, 0, sideInfo.sideDimension.y);

        // rotation
        mesh.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);

        return mesh;
    }
}
