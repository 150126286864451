/**
 * Browser supports Fetch API.
 */
export const supportsFetchApi = fetch != null;
/**
 * Browser supports keepalive flag in requests.
 */
export let supportsReqKeepalive = false;
try {
  supportsReqKeepalive = 'keepalive' in new Request('');
} catch (_err) {
  console.error(_err);
}
/**
 * Sending data using Fetch API. Fallback to XHR if not successful.
 * @param url URL
 * @param httpHeaders HTTP headers
 * @param jsonData Data (JSON)
 * @param logger Logger service
 */
export async function trySendingUsingFetch(url, httpHeaders, jsonData, logger) {
  const headers = [];
  Object.entries(httpHeaders).forEach(([key, value]) => {
    headers.push([key, value]);
  });
  headers.push(['Accept', 'application/json;odata=verbose']);
  headers.push(['Content-Type', 'application/json;charset=UTF-8']);
  // Browser support
  logger?.log(`Browser: Fetch API supported: ${supportsFetchApi}, keepalive supported: ${supportsReqKeepalive}`, 0 /* LogType.debug */);
  if (supportsFetchApi && supportsReqKeepalive) {
    // Browser supports Fetch API and keepalive
    return await sendFetch(url, headers, jsonData, logger);
  }
  return await sendXhr(url, headers, jsonData, logger);
}
async function sendFetch(url, headers, jsonData, logger) {
  logger?.log(`Using Fetch API for ${url}.`, 0 /* LogType.debug */);
  const req = {
    method: 'POST',
    headers,
    body: jsonData,
    cache: 'no-cache',
    redirect: 'follow',
    credentials: 'include',
    keepalive: true,
    mode: 'cors'
  };
  try {
    const response = await fetch(url, req);
    if (response.ok !== true) {
      throw response;
    }
  } catch (error) {
    if (error != null && typeof error == 'object' && 'message' in error && error.message == 'Failed to fetch') {
      console.error(error);
      // Some browsers (Chrome, Chromium?) do not support combining
      // CORS with keepalive. Fallback to the XHR method.
      await sendXhr(url, headers, jsonData, logger);
    } else {
      throw error;
    }
  }
}
async function sendXhr(url, headers, jsonData, logger) {
  logger?.log(`Using Sync XHR for ${url}.`, 0 /* LogType.debug */);
  const xhr = new XMLHttpRequest();
  xhr.open('POST', url, false);
  headers.forEach(([key, value]) => xhr.setRequestHeader(key, value));
  if (jsonData != null) {
    xhr.send(jsonData);
  } else {
    xhr.send();
  }
  if (xhr.readyState != 4 || xhr.status >= 400) {
    throw xhr;
  }
}
