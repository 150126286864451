import { TooltipEntityBaseC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class HoleCondition extends CodeList {

    public static fromService(codeList: TooltipEntityBaseC2C) {
        return new HoleCondition(CodeList.baseFromService('HoleConditionEntity', codeList));
    }

    public override cloneDeep(): HoleCondition {
        return super.cloneDeep() as HoleCondition;
    }
}
