import earcut from 'earcut';
import { Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData.js';
class PolygonPoints {
  elements = [];
  add(originalPoints) {
    const result = [];
    originalPoints.forEach(point => {
      if (result.length === 0 || !point.equalsWithEpsilon(result[0])) {
        const newPoint = new Vector2(point.x, point.y);
        result.push(newPoint);
        this.elements.push(newPoint);
      }
    });
    return result;
  }
  computeBounds() {
    const lmin = new Vector2(this.elements[0].x, this.elements[0].y);
    const lmax = new Vector2(this.elements[0].x, this.elements[0].y);
    this.elements.forEach(point => {
      // x
      if (point.x < lmin.x) {
        lmin.x = point.x;
      } else if (point.x > lmax.x) {
        lmax.x = point.x;
      }
      // y
      if (point.y < lmin.y) {
        lmin.y = point.y;
      } else if (point.y > lmax.y) {
        lmax.y = point.y;
      }
    });
    return {
      min: lmin,
      max: lmax,
      width: lmax.x - lmin.x,
      height: lmax.y - lmin.y
    };
  }
}
export class PolygonBuilder {
  vertexDataList;
  points;
  outlinepoints;
  epoints;
  eholes;
  constructor(contours) {
    this.points = new PolygonPoints();
    this.outlinepoints = new PolygonPoints();
    this.epoints = [];
    this.eholes = [];
    let points;
    if (typeof contours == 'object' && 'getPoints' in contours && typeof contours.getPoints == 'function') {
      points = contours.getPoints();
    } else {
      points = contours;
    }
    this.addToepoint(points);
    this.points.add(points);
    this.outlinepoints.add(points);
  }
  build(depth) {
    this.vertexDataList = [];
    const normals = [];
    const positions = [];
    const uvs = [];
    const bounds = this.points.computeBounds();
    this.points.elements.forEach(p => {
      normals.push(0, 1.0, 0);
      positions.push(p.x, 0, p.y);
      uvs.push((p.x - bounds.min.x) / bounds.width, (p.y - bounds.min.y) / bounds.height);
    });
    const indices = [];
    const res = earcut(this.epoints, this.eholes, 2);
    for (const resValue of res) {
      indices.push(resValue);
    }
    this.addVertexData(indices, positions, normals, uvs);
    if (depth != null && depth >= 0) {
      const tempNormals = [];
      const tempPositions = [];
      const tempUvs = [];
      const tempIndices = [];
      this.points.elements.forEach(p => {
        tempNormals.push(0, -1.0, 0);
        tempPositions.push(p.x, -depth, p.y);
        tempUvs.push(1 - (p.x - bounds.min.x) / bounds.width, (p.y - bounds.min.y) / bounds.height);
      });
      const totalCount = indices.length;
      for (let i = 0; i < totalCount; i += 3) {
        const i0 = indices[i + 0];
        const i1 = indices[i + 1];
        const i2 = indices[i + 2];
        tempIndices.push(i2);
        tempIndices.push(i1);
        tempIndices.push(i0);
      }
      this.addVertexData(tempIndices, tempPositions, tempNormals, tempUvs);
      this.addSide(bounds, this.outlinepoints, depth, false);
    }
    const vertexDataMerged = this.newVertexData();
    for (const vertexData of this.vertexDataList) {
      vertexDataMerged.merge(vertexData);
    }
    return vertexDataMerged;
  }
  addSide(bounds, points, depth, flip) {
    const normals = [];
    const positions = [];
    const uvs = [];
    let StartIndex = 0;
    const indices = [];
    let ulength = 0;
    for (let i = 0; i < points.elements.length; i++) {
      const p = points.elements[i];
      let p1;
      if (i + 1 > points.elements.length - 1) {
        p1 = points.elements[0];
      } else {
        p1 = points.elements[i + 1];
      }
      positions.push(p.x, 0, p.y);
      positions.push(p.x, -depth, p.y);
      positions.push(p1.x, 0, p1.y);
      positions.push(p1.x, -depth, p1.y);
      const v1 = new Vector3(p.x, 0, p.y);
      const v2 = new Vector3(p1.x, 0, p1.y);
      const v3 = v2.subtract(v1);
      const v4 = new Vector3(0, 1, 0);
      let vn = Vector3.Cross(v3, v4);
      vn = vn.normalize();
      uvs.push(ulength / bounds.width, 0);
      uvs.push(ulength / bounds.width, 1);
      ulength += v3.length();
      uvs.push(ulength / bounds.width, 0);
      uvs.push(ulength / bounds.width, 1);
      if (!flip) {
        normals.push(-vn.x, -vn.y, -vn.z);
        normals.push(-vn.x, -vn.y, -vn.z);
        normals.push(-vn.x, -vn.y, -vn.z);
        normals.push(-vn.x, -vn.y, -vn.z);
        indices.push(StartIndex);
        indices.push(StartIndex + 1);
        indices.push(StartIndex + 2);
        indices.push(StartIndex + 1);
        indices.push(StartIndex + 3);
        indices.push(StartIndex + 2);
      } else {
        normals.push(vn.x, vn.y, vn.z);
        normals.push(vn.x, vn.y, vn.z);
        normals.push(vn.x, vn.y, vn.z);
        normals.push(vn.x, vn.y, vn.z);
        indices.push(StartIndex);
        indices.push(StartIndex + 2);
        indices.push(StartIndex + 1);
        indices.push(StartIndex + 1);
        indices.push(StartIndex + 2);
        indices.push(StartIndex + 3);
      }
      StartIndex += 4;
    }
    this.addVertexData(indices, positions, normals, uvs);
  }
  addToepoint(points) {
    for (const p of points) {
      this.epoints.push(p.x, p.y);
    }
  }
  newVertexData(indices = [], positions = [], normals = [], uvs = []) {
    const vertexData = new VertexData();
    vertexData.positions = positions;
    vertexData.normals = normals;
    vertexData.uvs = uvs;
    vertexData.indices = indices;
    return vertexData;
  }
  addVertexData(indices, positions, normals, uvs) {
    const vertexData = this.newVertexData(indices, positions, normals, uvs);
    if (this.vertexDataList == null) {
      throw new Error('this.vertexDataList should not be null at this point');
    }
    this.vertexDataList.push(vertexData);
  }
}
