import { DeckingFieldState } from 'src/decking/entities/decking-design/decking-field-state';
import { environment } from '../../../environments/environmentDecking';
import { esrFrameFasteners, esrSideLapConnectors } from '../products/products';

export interface IApprovalInfo {
    name: string;
    url: string;
}

export class ApprovalHelper {
    public static readonly approvalUrlPart = 'cdn/pe-ui-decking/approvals/';

    public static readonly approvalEsr2197 = 'ESR-2197.pdf';
    public static readonly approvalEsr2776 = 'ESR-2776.pdf';
    public static readonly approvalEsr3693 = 'ESR-3693.pdf';

    public static getApprovalFilename(
        frameFastener: DeckingFieldState<number, string>,
        sidelapConnector: DeckingFieldState<number, string>
    ): string {
        if (!frameFastener || !sidelapConnector){
            return ApprovalHelper.approvalEsr3693;
        }

        if (
            frameFastener.id === esrFrameFasteners.ENP19 ||
            frameFastener.id === esrFrameFasteners.HSN24
        ) {
            if (
                sidelapConnector.id === esrSideLapConnectors.SLC01 ||
                sidelapConnector.id === esrSideLapConnectors.SLC02 ||
                sidelapConnector.id === esrSideLapConnectors.VSC2
            ){
                return ApprovalHelper.approvalEsr2776;
            }

            return ApprovalHelper.approvalEsr2197;
        }
        return ApprovalHelper.approvalEsr3693;
    }

    public static getApprovalInfo(language: string, filename: string): IApprovalInfo {
        return {
            name: filename,
            url: `${environment.baseUrl}${ApprovalHelper.approvalUrlPart}${language}/${filename}`
        };
    }
}
