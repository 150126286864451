<div class="content-group" *ngIf="dataLoaded">
    <div class="content design-region">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.Concrete.General')"></p>

        <div class="content-controls">
            <pe-dropdown class="control" [id]="concreteRegionDropdown.id" [title]="concreteRegionDropdown.title"
                [items]="concreteRegionDropdown.items" [selectedValue]="concreteFixing.concreteRegion"
                (selectedValueChange)="onConcreteRegionDropdownChange($any($event).detail)" [disabled]="submitted">
            </pe-dropdown>

            <div class="design-type-status control-row info">
                <p [class.error]="!allowConcreteQuickStart" [innerHtml]="allowConcreteStatus"></p>
            </div>
        </div>

        <div class="content-controls">
            <span class="local-steel-material-disclaimer"
                [innerHtml]="translate('Agito.Hilti.Profis3.AddEditDesign.LocalSteelMaterialDisclaimer')"></span>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="isConcreteNone && allowConcreteQuickStart">
        <p class="title-strike" [innerHtml]="translate(isAnchorplateFactorVisible(concreteFixing.concreteDesignStandardId ?? 0, concreteFixing.concreteDesignMethodGroupId ?? 0, concreteFixing.concreteRegion)
            ? 'Agito.Hilti.Profis3.ApplicationSettings.Concrete.Units'
            : 'Agito.Hilti.Profis3.ApplicationSettings.Concrete.Units.WithoutGammaC')">
        </p>

        <div class="content-controls">
            <pe-dropdown class="control" [id]="concreteLengthDropdown.id" [title]="concreteLengthDropdown.title"
                [items]="concreteLengthDropdown.items" [selectedValue]="concreteFixing.concreteLength"
                (selectedValueChange)="onConcreteLengthDropdownChange($any($event).detail)" [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control" [id]="concreteAreaDropdown.id" [title]="concreteAreaDropdown.title"
                [items]="concreteAreaDropdown.items" [selectedValue]="concreteFixing.concreteArea"
                (selectedValueChange)="concreteFixing.concreteArea = $any($event).detail" [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control" [id]="concreteStressDropdown.id" [title]="concreteStressDropdown.title"
                [items]="concreteStressDropdown.items" [selectedValue]="concreteFixing.concreteStress"
                (selectedValueChange)="concreteFixing.concreteStress = $any($event).detail" [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control" [id]="concreteForceDropdown.id" [title]="concreteForceDropdown.title"
                [items]="concreteForceDropdown.items" [selectedValue]="concreteFixing.concreteForce"
                (selectedValueChange)="concreteFixing.concreteForce = $any($event).detail" [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control" [id]="concreteMomentDropdown.id" [title]="concreteMomentDropdown.title"
                [items]="concreteMomentDropdown.items" [selectedValue]="concreteFixing.concreteMoment"
                (selectedValueChange)="concreteFixing.concreteMoment = $any($event).detail" [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control" [id]="concreteTemperatureDropdown.id"
                [title]="concreteTemperatureDropdown.title" [items]="concreteTemperatureDropdown.items"
                [selectedValue]="concreteFixing.concreteTemperature"
                (selectedValueChange)="concreteFixing.concreteTemperature = $any($event).detail" [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control" [id]="concreteForcePerLengthDropdown.id"
                [title]="concreteForcePerLengthDropdown.title" [items]="concreteForcePerLengthDropdown.items"
                [selectedValue]="concreteFixing.concreteForcePerLength"
                (selectedValueChange)="concreteFixing.concreteForcePerLength = $any($event).detail"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control" [id]="concreteMomentPerLengthDropdown.id"
                [title]="concreteMomentPerLengthDropdown.title" [items]="concreteMomentPerLengthDropdown.items"
                [selectedValue]="concreteFixing.concreteMomentPerLength"
                (selectedValueChange)="concreteFixing.concreteMomentPerLength = $any($event).detail"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control" [id]="concreteDensityDropdown.id" [title]="concreteDensityDropdown.title"
                [items]="concreteDensityDropdown.items" [selectedValue]="concreteFixing.concreteDensity"
                (selectedValueChange)="concreteFixing.concreteDensity = $any($event).detail" [disabled]="submitted">
            </pe-dropdown>

            <div class="control-row concrete-anchor-plate-factor" *ngIf="isAnchorPlateFactorAvailable(concreteFixing.concreteDesignStandardId ?? 0,
                 concreteFixing.concreteDesignMethodGroupId ?? 0, concreteFixing.concreteRegion)">
                <pe-numeric-text-box [id]="concreteAnchorPlateFactor.id" [title]="concreteAnchorPlateFactor.title"
                    [tooltip]="concreteAnchorPlateFactor.tooltip"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteAnchorPlateFactor')"
                    [value]="concreteFixing.concreteAnchorPlateFactor"
                    (valueChange)="concreteFixing.concreteAnchorPlateFactor = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteAnchorPlateFactor')"
                    [minValue]="concreteAnchorPlateFactor.minValue" [maxValue]="concreteAnchorPlateFactor.maxValue"
                    [disabled]="submitted" [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="control-row concrete-permanent-load" *ngIf="showPermanentOrVariableLoad()">
                <pe-numeric-text-box [id]="concreteSafetyFactorForPermanentLoads.id"
                    [title]="concreteSafetyFactorForPermanentLoads.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteSafetyFactorForPermanentLoads')"
                    [value]="concreteFixing.concreteSafetyFactorForPermanentLoads"
                    (valueChange)="concreteFixing.concreteSafetyFactorForPermanentLoads = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteSafetyFactorForPermanentLoads')"
                    [minValue]="concreteSafetyFactorForPermanentLoads.minValue"
                    [maxValue]="concreteSafetyFactorForPermanentLoads.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="control-row concrete-variable-load" *ngIf="showPermanentOrVariableLoad()">
                <pe-numeric-text-box [id]="concreteSafetyFactorForVariableLoads.id"
                    [title]="concreteSafetyFactorForVariableLoads.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteSafetyFactorForVariableLoads')"
                    [value]="concreteFixing.concreteSafetyFactorForVariableLoads"
                    (valueChange)="concreteFixing.concreteSafetyFactorForVariableLoads = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteSafetyFactorForVariableLoads')"
                    [minValue]="concreteSafetyFactorForVariableLoads.minValue"
                    [maxValue]="concreteSafetyFactorForVariableLoads.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-placeholder-container control-row" *ngIf="isMinimumAnchorToProfileDistanceAvailable(concreteFixing.concreteDesignStandardId ?? 0,
                 concreteFixing.concreteDesignMethodGroupId ?? 0, concreteFixing.concreteRegion)">
                <pe-numeric-text-box [id]="concreteMinimumAnchorToProfileDistance.id"
                    [title]="concreteMinimumAnchorToProfileDistance.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteMinimumAnchorToProfileDistance')"
                    [value]="concreteFixing.concreteMinimumAnchorToProfileDistance"
                    (valueChange)="concreteFixing.concreteMinimumAnchorToProfileDistance = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteMinimumAnchorToProfileDistance')"
                    [minValue]="concreteMinimumAnchorToProfileDistance.minValue"
                    [maxValue]="concreteMinimumAnchorToProfileDistance.maxValue" [disabled]="submitted">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-placeholder-container control-row" *ngIf="isConcreteCoverAvailable(concreteFixing.concreteDesignStandardId ?? 0,
                concreteFixing.concreteDesignMethodGroupId ?? 0, concreteFixing.concreteRegion)">
                <pe-numeric-text-box [id]="concreteMinimumConcreteCover.id" [title]="concreteMinimumConcreteCover.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteMinimumConcreteCover')"
                    [value]="concreteFixing.concreteMinimumConcreteCover"
                    (valueChange)="concreteFixing.concreteMinimumConcreteCover = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteMinimumConcreteCover')"
                    [minValue]="concreteMinimumConcreteCover.minValue"
                    [maxValue]="concreteMinimumConcreteCover.maxValue" [disabled]="submitted">
                </pe-numeric-text-box>
            </div>

            <pe-dropdown class="control" *ngIf="isConcreteSafetyFactorGammaCDropdownVisible && isAnchorplateFactorVisible(concreteFixing.concreteDesignStandardId ?? 0,
                concreteFixing.concreteDesignMethodGroupId ?? 0, concreteFixing.concreteRegion)"
                [id]="concreteSafetyFactorGammaC.id" [title]="concreteSafetyFactorGammaC.title"
                [items]="concreteSafetyFactorGammaC.items" [tooltip]="concreteSafetyFactorGammaC.tooltip"
                [selectedValue]="concreteFixing.concreteSafetyFactorGammaCId"
                (selectedValueChange)="concreteFixing.concreteSafetyFactorGammaCId = $any($event).detail"
                [disabled]="isConcreteSafetyFactorGammaCDropdownDisabled">
            </pe-dropdown>
        </div>
        <div class="design-type-status control-row info red-text" *ngIf="!isAnchorplateFactorVisible(concreteFixing.concreteDesignStandardId ?? 0, concreteFixing.concreteDesignMethodGroupId ?? 0, concreteFixing.concreteRegion)">
            <p [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.Concrete.Note')"></p>
        </div>
    </div>

    <div class="content calculation" *ngIf="isConcreteNone && allowConcreteQuickStart">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.AnchorCalculation')"></p>

        <div class="content-controls">
            <div class="design-standard control-row column">
                <div class="content">
                    <pe-dropdown class="control control-design-standard-method" [id]="concreteDesignStandardDropdown.id"
                        [title]="concreteDesignStandardDropdown.title" [items]="concreteDesignStandardDropdown.items"
                        [selectedValue]="concreteFixing.concreteDesignStandardId"
                        (selectedValueChange)="onConcreteDesignStandardDropdownChange($any($event).detail)"
                        [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-standard-note" *ngIf="selectedConcreteDesignStandardDescription != null"
                        [innerHtml]="translate(selectedConcreteDesignStandardDescription)">
                    </div>
                </div>
            </div>

            <div class="design-method-group control-row column">
                <div class="content">
                    <pe-dropdown class="control control-design-standard-method" [id]="concreteDesignMethodDropdown.id"
                        [title]="concreteDesignMethodDropdown.title" [items]="concreteDesignMethodDropdown.items"
                        [selectedValue]="concreteFixing.concreteDesignMethodGroupId"
                        (selectedValueChange)="onConcreteDesignMethodChange($any($event).detail)"
                        [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-method-note" *ngIf="selectedConcreteDesignMethodDescription != null"
                        [innerHtml]="translate(selectedConcreteDesignMethodDescription)">
                    </div>
                </div>

                <pe-checkbox-button *ngIf="isConcreteEtagEnOnlyCheckboxAvailable" class="checkbox"
                    [items]="concreteEtagEnOnly.items" [selectedValues]="concreteEtagEnOnly.selectedValues"
                    (selectedValuesChange)="concreteEtagEnOnly.selectedValues = $any($event).detail; onConcreteEtagOnlyChange()"
                    [disabled]="submitted">
                </pe-checkbox-button>

            </div>

            <div class="technical-data control-row column">
                <label class="control-label sub-title"
                    [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.Concrete.TechnicalData')"></label>
            </div>
        </div>
    </div>

    <div class="content fixed-inputs"
        *ngIf="isConcreteNone && allowConcreteQuickStart && (isAdvancedCalculationPossible || concreteDesignStandardEnBased)">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.AddEditDesign.SteelCalculation')"></p>

        <div class="content fixed-inputs" *ngIf="isAdvancedCalculationPossible">
            <pe-dropdown class="control" [id]="concreteSteelCalculationMethodDropdown.id"
                [title]="concreteSteelCalculationMethodDropdown.title"
                [items]="concreteSteelCalculationMethodDropdown.items"
                [selectedValue]="concreteFixing.concreteSteelCalculationMethod"
                (selectedValueChange)="onConcreteSteelCalculationMethodDropdownChange($any($event).detail)"
                [disabled]="concreteSteelCalculationMethodDropdown.disabled || submitted">
            </pe-dropdown>
        </div>

        <div class="content-controls" *ngIf="concreteDesignStandardEnBased">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteSteelSafetyFactorGammaM0.id"
                    [title]="concreteSteelSafetyFactorGammaM0.title"
                    [value]="concreteFixing.concreteSteelSafetyFactorGammaM0"
                    (valueChange)="concreteFixing.concreteSteelSafetyFactorGammaM0 = $any($event).detail"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteSteelSafetyFactorGammaM0')"
                    [unit]="getNumericTextBoxUnit('steelSafetyFactorGammaM0')"
                    [minValue]="concreteSteelSafetyFactorGammaM0.minValue"
                    [maxValue]="concreteSteelSafetyFactorGammaM0.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteSteelSafetyFactorGammaM1.id"
                    [title]="concreteSteelSafetyFactorGammaM1.title"
                    [value]="concreteFixing.concreteSteelSafetyFactorGammaM1"
                    (valueChange)="concreteFixing.concreteSteelSafetyFactorGammaM1 = $any($event).detail"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteSteelSafetyFactorGammaM1')"
                    [unit]="getNumericTextBoxUnit('concreteSteelSafetyFactorGammaM1')"
                    [minValue]="concreteSteelSafetyFactorGammaM1.minValue"
                    [maxValue]="concreteSteelSafetyFactorGammaM1.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteSteelSafetyFactorGammaM2.id"
                    [title]="concreteSteelSafetyFactorGammaM2.title"
                    [value]="concreteFixing.concreteSteelSafetyFactorGammaM2"
                    (valueChange)="concreteFixing.concreteSteelSafetyFactorGammaM2 = $any($event).detail"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteSteelSafetyFactorGammaM2')"
                    [unit]="getNumericTextBoxUnit('concreteSteelSafetyFactorGammaM2')"
                    [minValue]="concreteSteelSafetyFactorGammaM2.minValue"
                    [maxValue]="concreteSteelSafetyFactorGammaM2.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="content-controls" *ngIf="concreteDesignStandardAiscBased">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteMaterialSafetyFactor.id"
                    [title]="getConcreteMaterialSafetyFactorTitle()"
                    [value]="concreteFixing.concreteMaterialSafetyFactor"
                    (valueChange)="concreteFixing.concreteMaterialSafetyFactor = $any($event).detail"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteMaterialSafetyFactor')"
                    [unit]="getNumericTextBoxUnit('concreteMaterialSafetyFactor')"
                    [minValue]="concreteMaterialSafetyFactor.minValue"
                    [maxValue]="concreteMaterialSafetyFactor.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="content-controls" *ngIf="concreteDesignStandardAsBased">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteSteelCapacityFactor.id" [title]="concreteSteelCapacityFactor.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteSteelCapacityFactor')"
                    [value]="concreteFixing.concreteSteelCapacityFactor"
                    (valueChange)="concreteFixing.concreteSteelCapacityFactor = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteSteelCapacityFactor')"
                    [minValue]="concreteSteelCapacityFactor.minValue" [maxValue]="concreteSteelCapacityFactor.maxValue"
                    [disabled]="submitted" [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-container control-row">
                <pe-dropdown [id]="concreteWeldsCapacityFactor.id" [title]="concreteWeldsCapacityFactor.title"
                    [items]="concreteWeldsCapacityFactor.items"
                    [selectedValue]="concreteFixing.concreteWeldsCapacityFactor"
                    (selectedValueChange)="concreteFixing.concreteWeldsCapacityFactor = $any($event).detail"
                    [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteConcreteCapacityFactor.id"
                    [title]="concreteConcreteCapacityFactor.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteConcreteCapacityFactor')"
                    [value]="concreteFixing.concreteConcreteCapacityFactor"
                    (valueChange)="concreteFixing.concreteConcreteCapacityFactor = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteConcreteCapacityFactor')"
                    [minValue]="concreteConcreteCapacityFactor.minValue"
                    [maxValue]="concreteConcreteCapacityFactor.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="content-controls" *ngIf="concreteDesignStandardStoBased">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteSTOSteelSafetyFactorGammaM.id"
                    [title]="concreteSTOSteelSafetyFactorGammaM.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteSTOSteelSafetyFactorGammaM')"
                    [value]="concreteSTOSteelSafetyFactorGammaM.value"
                    (valueChange)="concreteSTOSteelSafetyFactorGammaM.value = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteSTOSteelSafetyFactorGammaM')"
                    [minValue]="concreteSTOSteelSafetyFactorGammaM.minValue"
                    [maxValue]="concreteSTOSteelSafetyFactorGammaM.maxValue"
                    [disabled]="concreteSTOSteelSafetyFactorGammaM.disabled"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="content-controls" *ngIf="concreteDesignStandardInBased">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteInSteelSafetyFactorGammaM0.id"
                    [title]="concreteInSteelSafetyFactorGammaM0.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteInSteelSafetyFactorGammaM0')"
                    [value]="concreteFixing.concreteInSteelSafetyFactorGammaM0"
                    (valueChange)="concreteFixing.concreteInSteelSafetyFactorGammaM0 = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteInSteelSafetyFactorGammaM0')"
                    [minValue]="concreteInSteelSafetyFactorGammaM0.minValue"
                    [maxValue]="concreteInSteelSafetyFactorGammaM0.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteInSteelSafetyFactorGammaM1.id"
                    [title]="concreteInSteelSafetyFactorGammaM1.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteInSteelSafetyFactorGammaM1')"
                    [value]="concreteFixing.concreteInSteelSafetyFactorGammaM1"
                    (valueChange)="concreteFixing.concreteInSteelSafetyFactorGammaM1 = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteInSteelSafetyFactorGammaM1')"
                    [minValue]="concreteInSteelSafetyFactorGammaM1.minValue"
                    [maxValue]="concreteInSteelSafetyFactorGammaM1.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteInSteelSafetyFactorGammaMw.id"
                    [title]="concreteInSteelSafetyFactorGammaMw.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteInSteelSafetyFactorGammaMw')"
                    [value]="concreteFixing.concreteInSteelSafetyFactorGammaMw"
                    (valueChange)="concreteFixing.concreteInSteelSafetyFactorGammaMw = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteInSteelSafetyFactorGammaMw')"
                    [minValue]="concreteInSteelSafetyFactorGammaMw.minValue"
                    [maxValue]="concreteInSteelSafetyFactorGammaMw.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteInSteelSafetyFactorGammaMb.id"
                    [title]="concreteInSteelSafetyFactorGammaMb.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteInSteelSafetyFactorGammaMb')"
                    [value]="concreteFixing.concreteInSteelSafetyFactorGammaMb"
                    (valueChange)="concreteFixing.concreteInSteelSafetyFactorGammaMb = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteInSteelSafetyFactorGammaMb')"
                    [minValue]="concreteInSteelSafetyFactorGammaMb.minValue"
                    [maxValue]="concreteInSteelSafetyFactorGammaMb.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="content-controls" *ngIf="concreteDesignStandardHkBased">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteSteelSafetyFactorGammaM1.id"
                    [title]="concreteSteelSafetyFactorGammaM1.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteSteelSafetyFactorGammaM1')"
                    [value]="concreteFixing.concreteSteelSafetyFactorGammaM1"
                    (valueChange)="concreteFixing.concreteSteelSafetyFactorGammaM1 = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteSteelSafetyFactorGammaM1')"
                    [minValue]="concreteSteelSafetyFactorGammaM1.minValue"
                    [maxValue]="concreteSteelSafetyFactorGammaM1.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="concreteSteelSafetyFactorGammaM2.id"
                    [title]="concreteSteelSafetyFactorGammaM2.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteSteelSafetyFactorGammaM2')"
                    [value]="concreteFixing.concreteSteelSafetyFactorGammaM2"
                    (valueChange)="concreteFixing.concreteSteelSafetyFactorGammaM2 = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteSteelSafetyFactorGammaM2')"
                    [minValue]="concreteSteelSafetyFactorGammaM2.minValue"
                    [maxValue]="concreteSteelSafetyFactorGammaM2.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div class="content fixed-inputs"
        *ngIf="isConcreteNone && allowConcreteQuickStart && isAdvancedCalculationPossible && concreteDesignStandardHkBased">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.AddEditDesign.ConcreteMethod')"></p>

        <div class="content-group">
            <pe-dropdown class="control" [id]="concreteCalculationMethodDropdown.id"
                [title]="concreteCalculationMethodDropdown.title" [items]="concreteCalculationMethodDropdown.items"
                [selectedValue]="concreteCalculationMethodDropdown.selectedValue"
                (selectedValueChange)="concreteCalculationMethodDropdown.selectedValue = $any($event).detail"
                [disabled]="concreteCalculationMethodDropdown.disabled">
            </pe-dropdown>
        </div>
    </div>
</div>