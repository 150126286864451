export class SceneEvents {
  scene;
  callbacks = {
    'onBeforeCameraRenderObservable': new Set()
  };
  observers;
  constructor(scene) {
    this.scene = scene;
    this.observers = {
      'onBeforeCameraRenderObservable': this.createObserver('onBeforeCameraRenderObservable')
    };
  }
  addEventListener(event, listener) {
    this.callbacks[event].add(listener);
  }
  removeEventListener(event, listener) {
    this.callbacks[event].delete(listener);
  }
  dispose() {
    for (const event in this.observers) {
      this.scene[event].remove(this.observers[event]);
    }
    this.callbacks = undefined;
    this.observers = undefined;
    this.scene = undefined;
  }
  createObserver(event) {
    return this.scene[event].add((eventData, eventState) => {
      for (const eventCallback of this.callbacks[event]) {
        eventCallback(eventData, eventState);
      }
    });
  }
}
