import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environmentDecking';
import { DeckingUserSettings, IDeckingSettingsCollection, IDeckingUserSettings, UserSettingsInitialData } from '../../entities/settings/decking-user-settings';
import { ApiService } from '../external/api.service';
import { LocalizationService } from '../external/localization.service';
import { LoggerService } from '../external/logger.service';
import { UserService } from '../external/user.service';
import { CountryCode, Region } from '../../entities/enums/decking-settings-region';

@Injectable({
  providedIn: 'root'
})
export class DeckingUserSettingsService {

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private loggerService: LoggerService,
    private localizationService: LocalizationService
  ) {}

  protected deckingUserSettingsData: DeckingUserSettings;
  protected deckingSettingsCollectionData: IDeckingSettingsCollection;

  public deckingSettingsLodaded  = false;

  /**
      * Returns the Decking User Settings.
      */
  public get deckingSettings(): IDeckingUserSettings {
    if (!this.deckingSettingsLodaded ) {
      throw new Error('Settings are not loaded!');
    }

    return this.deckingUserSettingsData;
  }

  /**
   * Set the Decking User Settings.
   */
  public set deckingSettings(value: IDeckingUserSettings) {
    this.deckingUserSettingsData = value;
  }

  /**
   * Returns the Decking Settings Collection.
   */
  public get deckingSettingsCollection(): IDeckingSettingsCollection {
    if (!this.deckingSettingsLodaded ) {
      throw new Error('Settings are not loaded!');
    }

    return this.deckingSettingsCollectionData;
  }

  /**
   * Set the Decking User Settings Collection.
   */
  public set deckingSettingsCollection(value: IDeckingSettingsCollection) {
    this.deckingSettingsCollectionData = value;
  }

    public get templateFolderId() {
        return this.userService?.templateFolder?.templateFolderId;
    }

    public setIsCreateTemplate(value: boolean) {
        this.userService.setIsCreateTemplate(value);
    }

    public get isCreateTemplate() {
        return this.userService.isCreateTemplate;
    }

  public async initialize() {
    const deckingData: UserSettingsInitialData = await this.getInitialData();
    this.deckingSettings = deckingData.userSettings;
    this.deckingSettingsCollection = deckingData.settingsCollection;
    this.copyDeckingUserSettingsValues(deckingData.userSettings);
    this.setDefaultsForEmptyOrNonExistingValues();
    this.deckingSettingsLodaded  = true;
  }

  public async getInitialData(): Promise<UserSettingsInitialData> {
    let userSettings: IDeckingUserSettings = null;
    let settingsCollection: IDeckingSettingsCollection = null;

    const urlDeckingUserSettings = `${environment.deckingUserSettingsServiceUrl}api/UserSettingQuery/${this.userService.authentication.userId.replace('|', '')}/${this.localizationService.selectedLanguage ?? 'en-US'}`;
    const urlDeckingSettingsCollection = `${environment.deckingUserSettingsServiceUrl}api/settingsquery/${this.localizationService.selectedLanguage ?? 'en-US'}`;
    userSettings = (await this.apiService.request<IDeckingUserSettings>(new HttpRequest('GET', urlDeckingUserSettings))).body;
    settingsCollection = (await this.apiService.request<IDeckingSettingsCollection>(new HttpRequest('GET', urlDeckingSettingsCollection))).body;
    if (userSettings && (userSettings.requiredUpliftSubmittal?.value ?? null) === null) {
      userSettings.requiredUpliftSubmittal = { value: true };
    }
    this.validateBackwardDeckingUserSettingsCompatibility(userSettings, settingsCollection);

    return { userSettings, settingsCollection };
  }

  private validateBackwardDeckingUserSettingsCompatibility(deckingUserSettingEntity: IDeckingUserSettings, deckingSettingsCollectionEntity: IDeckingSettingsCollection) {
    if (deckingUserSettingEntity != null && (deckingUserSettingEntity.shearStiffness == null || deckingUserSettingEntity.shearStiffness === undefined)) {
      const defaultSetting = deckingSettingsCollectionEntity.regionDefault.find(e => e.region.countryCode == CountryCode.US);
      deckingUserSettingEntity.shearStiffness = defaultSetting.shearStiffness;
    }
  }

  public async save(): Promise<void> {
    try {
      if (!this.userService.authenticated) {
        throw new Error('Unauthenticated');
      }

      if (this.deckingUserSettingsData?.isDirty) {
        //  Save current user setting into service
        const urlToSave = `${environment.deckingUserSettingsServiceUrl}api/UserSettingCommand`;
        delete this.deckingUserSettingsData.isDirty;
        const postData: any = this.deckingUserSettingsData;
        postData.userId = this.userService.authentication.userId;
        //  The value inside this.userService.authentication.userId is guid with a wrong format and need to be parsed
        //  before sending to the service.
        const response = await this.apiService.request<IDeckingUserSettings>(new HttpRequest('POST', urlToSave, postData));

        //  If the response was successfully then we need to save the eTag
        this.copyDeckingUserSettingsValues(response.body);
        this.logServiceResponse('DeckingTesting', response);
      }
    }
    catch (e: any) {
      this.loggerService.logServiceError(e, 'user-settings-service', 'DeckingTesting');
      console.error(e);
      throw e;
    }
  }

  private copyDeckingUserSettingsValues(from: IDeckingUserSettings): void {
    if (from != null) {
      this.deckingUserSettingsData.region = from.region;
      this.deckingUserSettingsData.area = from.area;
      this.deckingUserSettingsData.length = from.length;
      this.deckingUserSettingsData.stress = from.stress;
      this.deckingUserSettingsData.moment = from.moment;
      this.deckingUserSettingsData.force = from.force;
      this.deckingUserSettingsData.forcePerLength = from.forcePerLength;
      this.deckingUserSettingsData.momentPerLength = from.momentPerLength;
      this.deckingUserSettingsData.designMethod = from.designMethod;
      this.deckingUserSettingsData.designStandard = from.designStandard;
      this.deckingUserSettingsData.relevantLoads = from.relevantLoads;
      this.deckingUserSettingsData.sidelapsNumber = from.sidelapsNumber;
      this.deckingUserSettingsData.sidelapsSpacing = from.sidelapsSpacing;
      this.deckingUserSettingsData.requiredShearStiffness = from.requiredShearStiffness;
      this.deckingUserSettingsData.requiredUpliftSubmittal = from.requiredUpliftSubmittal;
      this.deckingUserSettingsData.substitutionRequiredShearStiffness = from.substitutionRequiredShearStiffness;
      this.deckingUserSettingsData.substitutionRequiredUpliftSubmittal = from.substitutionRequiredUpliftSubmittal;
      this.deckingUserSettingsData.substitutionWindAndSeismicLoadsAtZoneLevel = from.substitutionWindAndSeismicLoadsAtZoneLevel;
      this.deckingUserSettingsData.calculateIntegration = from.calculateIntegration;
      this.deckingUserSettingsData.definitionOfSidelapConnectors = from.definitionOfSidelapConnectors;
      this.deckingUserSettingsData.windAndSeismicLoadsAtZoneLevel = from.windAndSeismicLoadsAtZoneLevel;
      this.deckingUserSettingsData.shearStiffness = from.shearStiffness;
      this.deckingUserSettingsData.eTag = from.eTag;
      this.deckingUserSettingsData.settings3d = from.settings3d;
      this.deckingUserSettingsData.designMode = from.designMode;
      this.deckingUserSettingsData.wasteFactor = from.wasteFactor;
    }
  }

  private setDefaultsForEmptyOrNonExistingValues(): void {
    if (this.deckingUserSettingsData == null || this.deckingUserSettingsData === undefined) {
      this.deckingUserSettingsData = new DeckingUserSettings();

      const defaultSetting = this.deckingSettingsCollectionData.regionDefault.filter(e => e.region.countryCode == CountryCode.US)[0];

      this.deckingUserSettingsData.region = defaultSetting.region;
      this.deckingUserSettingsData.area = defaultSetting.area;
      this.deckingUserSettingsData.length = defaultSetting.length;
      this.deckingUserSettingsData.stress = defaultSetting.stress;
      this.deckingUserSettingsData.moment = defaultSetting.moment;
      this.deckingUserSettingsData.force = defaultSetting.force;
      this.deckingUserSettingsData.forcePerLength = defaultSetting.forcePerLength;
      this.deckingUserSettingsData.shearStiffness = defaultSetting.shearStiffness;
      this.deckingUserSettingsData.momentPerLength = defaultSetting.momentPerLength;
      this.deckingUserSettingsData.designMethod = defaultSetting.designMethod;
      this.deckingUserSettingsData.designStandard = defaultSetting.designStandard;
      this.deckingUserSettingsData.relevantLoads = defaultSetting.relevantLoads;
      this.deckingUserSettingsData.definitionOfSidelapConnectors = defaultSetting.definitionOfSidelapConnectors;
      this.deckingUserSettingsData.sidelapsNumber = defaultSetting.sidelapsNumber;
      this.deckingUserSettingsData.sidelapsSpacing = defaultSetting.sidelapsSpacing;
      this.deckingUserSettingsData.requiredShearStiffness = defaultSetting.requiredShearStiffness;
      this.deckingUserSettingsData.requiredUpliftSubmittal = defaultSetting.requiredUpliftSubmittal;
      this.deckingUserSettingsData.substitutionRequiredShearStiffness = defaultSetting.substitutionRequiredShearStiffness;
      this.deckingUserSettingsData.substitutionRequiredUpliftSubmittal = defaultSetting.substitutionRequiredUpliftSubmittal;
      this.deckingUserSettingsData.substitutionWindAndSeismicLoadsAtZoneLevel = defaultSetting.substitutionWindAndSeismicLoadsAtZoneLevel;
      this.deckingUserSettingsData.calculateIntegration = defaultSetting.calculateIntegration;
      this.deckingUserSettingsData.windAndSeismicLoadsAtZoneLevel = defaultSetting.windAndSeismicLoadsAtZoneLevel;
      this.deckingUserSettingsData.designMode = defaultSetting.designMode;
      this.deckingUserSettingsData.wasteFactor = defaultSetting.wasteFactor;
      this.deckingUserSettingsData = defaultSetting;
      this.deckingUserSettingsData.eTag = '';
    }
  }

  protected logServiceResponse(fnName: string, ...args: any[]): void {
    this.loggerService.logServiceResponse('user-settings-service', fnName, ...args);
  }

  public getSelectedRegionIndex(countryCode: string) {
    switch (countryCode) {
      case CountryCode.Canada:
        return Region.Canada;
      case CountryCode.Mexico:
        return Region.Mexico;
      default:
        return Region.US;
    }
  }
}
