import { combineLatestWith, distinctUntilChanged, map, Observable } from 'rxjs';
import { Component, ElementRef, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DeckingUnitsHelperService } from './../../../services/decking-units-helper/decking-units-helper.service';
import { DeckingDesignVerificationService } from './../../../services/decking-design/calculation/verification/decking-design-verification.service';
import { DeckingDesignService } from './../../../services/decking-design/decking-design.service';
import { AreaModel } from './../../../entities/decking-design/area-model';
import { DesignSettings } from './../../../entities/settings/design-settings';
import { TextBoxDisplay, TextBoxAlign } from './../../../components/common/text-box/text-box.common';
import { LocalizationService } from 'src/decking/services/external/localization.service';
import { includeSprites } from 'src/decking/sprites';

@Component({
    selector: 'app-deflection-calculation',
    templateUrl: './deflection-calculation.component.html',
    styleUrls: ['./deflection-calculation.component.scss']
})
export class DeflectionCalculationComponent implements OnInit {
    currentArea$: Observable<AreaModel>;
    currentLengthSetting$: Observable<Unit>;
    currentForcePerLengthSetting$: Observable<Unit>;
    currentMomentOfInertiaValue$: Observable<number>;

    shownLengthUnit$: Observable<Unit>;
    shownAreaUnit$: Observable<Unit>;
    shownForcePerLengthUnit$: Observable<Unit>;
    shownMomentOfInertiaValue$: Observable<string>;
    customizeLengthUnit$: Observable<Unit>;

    saving$: Observable<boolean>;

    lengthUnit: Unit;
    areaUnit: Unit;
    forcePerLengthUnit: Unit;

    isMessageInfoVisible = false;

    public numericTextBoxDisplay = TextBoxDisplay.Normal;
    public numericTextBoxAlign = TextBoxAlign.Start;

    constructor(
        private activeModal: NgbActiveModal,
        private deckingDesignService: DeckingDesignService,
        private verificationService: DeckingDesignVerificationService,
        private deckingUnitsHelperService: DeckingUnitsHelperService,
        public localization: LocalizationService,
        private readonly elementRef: ElementRef<HTMLElement>
    ) {
        includeSprites(
            this.elementRef.nativeElement,
            'sprite-deflection-calculation-equations',
        );
        this.initializeStreams();
    }

    public ngOnInit(): void {
        this.setUnits();
        this.currentArea$.pipe(map((currentArea: AreaModel) => this.updateAreaDeflection(currentArea)));
    }

    private initializeStreams() {
        this.currentArea$ = this.deckingDesignService.currentArea$;
        this.currentLengthSetting$ = this.deckingDesignService.currentSettings$.pipe(map((settings: DesignSettings) => settings.length.id), distinctUntilChanged());
        this.currentForcePerLengthSetting$ = this.deckingDesignService.currentSettings$.pipe(map((settings: DesignSettings) => settings.forcePerLength.id), distinctUntilChanged());
        this.currentMomentOfInertiaValue$ = this.currentArea$.pipe(map((currentArea: AreaModel) => currentArea?.deflectionCalculation.momentOfInertiaI?.value), distinctUntilChanged());
        this.saving$ = this.verificationService.saving$;
    }

    private setUnits() {
        this.shownLengthUnit$ = this.currentLengthSetting$.pipe(map((length: Unit) => { return this.deckingUnitsHelperService.isInternationalSystemUnit(length) ? Unit.m : Unit.ft; }));
        this.shownAreaUnit$ = this.currentLengthSetting$.pipe(map((length: Unit) => this.getAreaUnit(length)));
        this.shownForcePerLengthUnit$ = this.currentForcePerLengthSetting$.pipe(map((forcePerLength: Unit) => this.getForcePerLengthUnit(forcePerLength)));
        this.shownMomentOfInertiaValue$ = this.currentLengthSetting$.pipe(combineLatestWith(this.currentMomentOfInertiaValue$), map(([unit, value]) => this.getMomentOfInertiaValue(value, unit)));
        this.customizeLengthUnit$ = this.deckingDesignService.currentSettings$.pipe(map((settings: DesignSettings) => { return settings.isImperial.value ? Unit.inch : Unit.cm; }));
    }

    updateAreaDeflection(area: AreaModel) {
        this.deckingDesignService.updateCurrentArea(area, true, true);
    }

    close(): void {
        this.activeModal.close();
    }

    public toggleClicked(): void {
        this.isMessageInfoVisible = !this.isMessageInfoVisible;
    }

    private getLengthUnit(lengthUnit: Unit): Unit {
        return this.deckingUnitsHelperService.getStandardLengthUnit(lengthUnit);
    }

    private getAreaUnit(lengthUnit: Unit): Unit {
        return this.deckingUnitsHelperService.getDeflectionCalculationAreaUnit(lengthUnit);
    }

    private getForcePerLengthUnit(forcePerLengthUnit: Unit): Unit {
        return this.deckingUnitsHelperService.getDeflectionCalculationForcePerLenghtUnit(forcePerLengthUnit);
    }

    private getMomentOfInertiaValue(value: number, lengthUnit: Unit): string {
        return this.deckingUnitsHelperService.getMomentOfInertiaValue(value, lengthUnit);
    }
}
