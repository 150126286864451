import { NumberOfLayersEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class NumberOfLayers extends CodeList {

    public static fromService(codeList: NumberOfLayersEntityC2C) {
        return new NumberOfLayers(CodeList.baseFromService('NumberOfLayersEntity', codeList));
    }

    public override cloneDeep(): NumberOfLayers {
        return super.cloneDeep() as NumberOfLayers;
    }
}
