import { LocalizationService } from './services/localization.service';

export function CreateMessageFromKeys(keys: string[], localizationService: LocalizationService): string {
    if (keys.length == 0 || keys == undefined) {
        return '';
    }

    let message = '';

    for (let i = 0; i < keys.length; i++) {
        message += localizationService.getString(keys[i]);
        if (i < keys.length - 1) {
            message += ' \n\r';
        }
    }

    return message;
}
