import {
    DrillingAidEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class DrillingAid extends CodeList {

    public static fromService(codeList: DrillingAidEntityC2C) {
        return new DrillingAid(CodeList.baseFromService('DrillingAidEntity', codeList));
    }

    public override cloneDeep(): DrillingAid {
        return super.cloneDeep() as DrillingAid;
    }
}
