import { Color3 } from '@babylonjs/core/Maths/math.color.js';
import { Scalar } from '@babylonjs/core/Maths/math.scalar.js';
import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
const constants = {
  lineDimensionOffsetXZ: 50,
  lineDimensionOffsetY: 1,
  arrowSizeX: 15,
  arrowSizeXMin: Number.NaN,
  arrowSizeY: 5,
  arrowSizeYMin: Number.NaN,
  defaultLineColor: Color3.FromHexString('#111111'),
  dimensionLinesColor: Color3.FromHexString('#333333')
};
constants.arrowSizeXMin = constants.arrowSizeX * 2.25;
constants.arrowSizeYMin = constants.arrowSizeY * 2.25;
export const lineConstants = constants;
const {
  arrowSizeX,
  arrowSizeY,
  arrowSizeXMin
} = lineConstants;
export function createLeftArrowXZLine(point, size = 1) {
  return [new Vector3(point.x + arrowSizeX * size, point.y, point.z + arrowSizeY * size), point, new Vector3(point.x + arrowSizeX * size, point.y, point.z - arrowSizeY * size)];
}
export function createLeftArrowXYLine(point, size = 1) {
  return [new Vector3(point.x + arrowSizeX * size, point.y + arrowSizeY * size, point.z), point, new Vector3(point.x + arrowSizeX * size, point.y - arrowSizeY * size, point.z)];
}
export function createLeftArrowXYLineSplit(point, size = 1) {
  return [[new Vector3(point.x + arrowSizeX * size, point.y + arrowSizeY * size, point.z), point], [point, new Vector3(point.x + arrowSizeX * size, point.y - arrowSizeY * size, point.z)]];
}
export function createLeftArrowYZLine(point, size = 1) {
  return [new Vector3(point.x, point.y + arrowSizeY * size, point.z - arrowSizeX * size), point, new Vector3(point.x, point.y - arrowSizeY * size, point.z - arrowSizeX * size)];
}
export function createLeftArrowXYZLine(point, size = 1) {
  const arrowSizeAngle = arrowSizeX * size / Math.sqrt(2);
  return [new Vector3(point.x + arrowSizeAngle, point.y + arrowSizeY * size, point.z + arrowSizeAngle), point, new Vector3(point.x + arrowSizeAngle, point.y - arrowSizeY * size, point.z + arrowSizeAngle)];
}
export function createLeftArrowXYZ90Line(point, size = 1) {
  const arrowSizeAngle = arrowSizeX * size / Math.sqrt(2);
  return [new Vector3(point.x + arrowSizeAngle, point.y + arrowSizeY * size, point.z - arrowSizeAngle), point, new Vector3(point.x + arrowSizeAngle, point.y - arrowSizeY * size, point.z - arrowSizeAngle)];
}
export function createRightArrowXZLine(point, size = 1) {
  return [new Vector3(point.x - arrowSizeX * size, point.y, point.z + arrowSizeY * size), point, new Vector3(point.x - arrowSizeX * size, point.y, point.z - arrowSizeY * size)];
}
export function createRightArrowXYLine(point, size = 1) {
  return [new Vector3(point.x - arrowSizeX * size, point.y + arrowSizeY * size, point.z), point, new Vector3(point.x - arrowSizeX * size, point.y - arrowSizeY * size, point.z)];
}
export function createRightArrowXYLineSplit(point, size = 1) {
  return [[new Vector3(point.x - arrowSizeX * size, point.y + arrowSizeY * size, point.z), point], [point, new Vector3(point.x - arrowSizeX * size, point.y - arrowSizeY * size, point.z)]];
}
export function createRightArrowYZLine(point, size = 1) {
  return [new Vector3(point.x, point.y + arrowSizeY * size, point.z + arrowSizeX * size), point, new Vector3(point.x, point.y - arrowSizeY * size, point.z + arrowSizeX * size)];
}
export function createRightArrowXYZLine(point, size = 1) {
  const arrowSizeAngle = arrowSizeX * size / Math.sqrt(2);
  return [new Vector3(point.x - arrowSizeAngle, point.y + arrowSizeY * size, point.z - arrowSizeAngle), point, new Vector3(point.x - arrowSizeAngle, point.y - arrowSizeY * size, point.z - arrowSizeAngle)];
}
export function createRightArrowXYZ90Line(point, size = 1) {
  const arrowSizeAngle = arrowSizeX * size / Math.sqrt(2);
  return [new Vector3(point.x - arrowSizeAngle, point.y + arrowSizeY * size, point.z + arrowSizeAngle), point, new Vector3(point.x - arrowSizeAngle, point.y - arrowSizeY * size, point.z + arrowSizeAngle)];
}
export function createTopArrowXZLine(point, size = 1) {
  return [new Vector3(point.x + arrowSizeY * size, point.y, point.z - arrowSizeX * size), point, new Vector3(point.x - arrowSizeY * size, point.y, point.z - arrowSizeX * size)];
}
export function createTopArrowXYLine(point, size = 1) {
  return [new Vector3(point.x - arrowSizeY * size, point.y - arrowSizeX * size, point.z), point, new Vector3(point.x + arrowSizeY * size, point.y - arrowSizeX * size, point.z)];
}
export function createTopArrowXYLineSplit(point, size = 1) {
  return [[new Vector3(point.x - arrowSizeY * size, point.y - arrowSizeX * size, point.z), point], [point, new Vector3(point.x + arrowSizeY * size, point.y - arrowSizeX * size, point.z)]];
}
export function createTopArrowYZLine(point, size = 1) {
  return [new Vector3(point.x, point.y - arrowSizeX * size, point.z + arrowSizeY * size), point, new Vector3(point.x, point.y - arrowSizeX * size, point.z - arrowSizeY * size)];
}
export function createTopArrowXYZLine(point, size = 1) {
  const arrowSizeAngle = arrowSizeY * size / Math.sqrt(2);
  return [new Vector3(point.x - arrowSizeAngle, point.y - arrowSizeX * size, point.z - arrowSizeAngle), point, new Vector3(point.x + arrowSizeAngle, point.y - arrowSizeX * size, point.z + arrowSizeAngle)];
}
export function createTopArrowXYZ90Line(point, size = 1) {
  const arrowSizeAngle = arrowSizeY * size / Math.sqrt(2);
  return [new Vector3(point.x - arrowSizeAngle, point.y - arrowSizeX * size, point.z + arrowSizeAngle), point, new Vector3(point.x + arrowSizeAngle, point.y - arrowSizeX * size, point.z - arrowSizeAngle)];
}
export function createBottomArrowXZLine(point, size = 1) {
  return [new Vector3(point.x + arrowSizeY * size, point.y, point.z + arrowSizeX * size), point, new Vector3(point.x - arrowSizeY * size, point.y, point.z + arrowSizeX * size)];
}
export function createBottomArrowXYLine(point, size = 1) {
  return [new Vector3(point.x - arrowSizeY * size, point.y + arrowSizeX * size, point.z), point, new Vector3(point.x + arrowSizeY * size, point.y + arrowSizeX * size, point.z)];
}
export function createBottomArrowXYLineSplit(point, size = 1) {
  return [[new Vector3(point.x - arrowSizeY * size, point.y + arrowSizeX * size, point.z), point], [point, new Vector3(point.x + arrowSizeY * size, point.y + arrowSizeX * size, point.z)]];
}
export function createBottomArrowYZLine(point, size = 1) {
  return [new Vector3(point.x, point.y + arrowSizeX * size, point.z + arrowSizeY * size), point, new Vector3(point.x, point.y + arrowSizeX * size, point.z - arrowSizeY * size)];
}
export function createBottomArrowXYZLine(point, size = 1) {
  const arrowSizeAngle = arrowSizeY * size / Math.sqrt(2);
  return [new Vector3(point.x - arrowSizeAngle, point.y + arrowSizeX * size, point.z - arrowSizeAngle), point, new Vector3(point.x + arrowSizeAngle, point.y + arrowSizeX * size, point.z + arrowSizeAngle)];
}
export function createBottomArrowXYZ90Line(point, size = 1) {
  const arrowSizeAngle = arrowSizeY * size / Math.sqrt(2);
  return [new Vector3(point.x - arrowSizeAngle, point.y + arrowSizeX * size, point.z + arrowSizeAngle), point, new Vector3(point.x + arrowSizeAngle, point.y + arrowSizeX * size, point.z - arrowSizeAngle)];
}
export function createArrowXZLine(from, to) {
  if (from.z == to.z) {
    const length = to.x - from.x;
    const size = Scalar.Clamp(length / arrowSizeXMin, -1, 1);
    // x aligned
    return [createLeftArrowXZLine(from, size), createRightArrowXZLine(to, size)];
  } else {
    const length = to.z - from.z;
    const size = Scalar.Clamp(length / arrowSizeXMin, -1, 1);
    // z aligned
    return [createBottomArrowXZLine(from, size), createTopArrowXZLine(to, size)];
  }
}
export function createArrowXYLine(from, to, scale, split) {
  scale = scale ?? 1;
  if (from.y == to.y) {
    const length = to.x - from.x;
    const size = Scalar.Clamp(length / arrowSizeXMin, -scale, scale);
    // x aligned
    return [...(split ? createLeftArrowXYLineSplit(from, size) : [createLeftArrowXYLine(from, size)]), ...(split ? createRightArrowXYLineSplit(to, size) : [createRightArrowXYLine(to, size)])];
  } else {
    const length = to.y - from.y;
    const size = Scalar.Clamp(length / arrowSizeXMin, -scale, scale);
    // y aligned
    return [...(split ? createBottomArrowXYLineSplit(from, size) : [createBottomArrowXYLine(from, size)]), ...(split ? createTopArrowXYLineSplit(to, size) : [createTopArrowXYLine(to, size)])];
  }
}
export function createArrowYZLine(from, to) {
  if (from.y == to.y) {
    const length = Math.abs(to.z - from.z);
    const size = Scalar.Clamp(length / arrowSizeXMin, -1, 1);
    // y aligned
    return [createLeftArrowYZLine(from, size), createRightArrowYZLine(to, size)];
  } else {
    const length = to.y - from.y;
    const size = Scalar.Clamp(length / arrowSizeXMin, -1, 1);
    // z aligned
    return [createBottomArrowYZLine(from, size), createTopArrowYZLine(to, size)];
  }
}
export function createArrowXYZLine(from, to) {
  const length = to.subtract(from).length();
  const size = Scalar.Clamp(length / arrowSizeXMin, -1, 1);
  if (from.y == to.y) {
    // x aligned
    return [createLeftArrowXYZLine(from, size), createRightArrowXYZLine(to, size)];
  } else {
    // z aligned
    return [createBottomArrowXYZLine(from, size), createTopArrowXYZLine(to, size)];
  }
}
export function createArrowXYZ90Line(from, to) {
  const length = to.subtract(from).length();
  const size = Scalar.Clamp(length / arrowSizeXMin, -1, 1);
  if (from.y == to.y) {
    // x aligned
    return [createLeftArrowXYZ90Line(from, size), createRightArrowXYZ90Line(to, size)];
  } else {
    // z aligned
    return [createBottomArrowXYZ90Line(from, size), createTopArrowXYZ90Line(to, size)];
  }
}
export function createArrowXZAngleLine(from, to) {
  const direction = to.subtract(from);
  const angle = Math.atan2(direction.x, direction.z);
  const rotationMatrix = Matrix.RotationY(angle);
  const size = Scalar.Clamp(direction.length() / arrowSizeXMin, -1, 1);
  const fromArrow = createBottomArrowXZLine(Vector3.Zero(), size);
  const toArrow = createTopArrowXZLine(Vector3.Zero(), size);
  for (const arrowPart of fromArrow) {
    Vector3.TransformCoordinatesToRef(arrowPart, rotationMatrix, arrowPart);
    arrowPart.addInPlace(from);
  }
  for (const arrowPart of toArrow) {
    Vector3.TransformCoordinatesToRef(arrowPart, rotationMatrix, arrowPart);
    arrowPart.addInPlace(to);
  }
  return [fromArrow, toArrow];
}
export function createArrowXYAngleLine(from, to, size) {
  const direction = to.subtract(from);
  const angle = Math.atan2(direction.y, direction.x);
  const rotationMatrix = Matrix.RotationZ(angle - Math.PI / 2);
  if (size == null) {
    size = Scalar.Clamp(direction.length() / arrowSizeXMin, -1, 1);
  }
  const fromArrow = createBottomArrowXYLine(Vector3.Zero(), size);
  const toArrow = createTopArrowXYLine(Vector3.Zero(), size);
  for (const arrowPart of fromArrow) {
    Vector3.TransformCoordinatesToRef(arrowPart, rotationMatrix, arrowPart);
    arrowPart.addInPlace(from);
  }
  for (const arrowPart of toArrow) {
    Vector3.TransformCoordinatesToRef(arrowPart, rotationMatrix, arrowPart);
    arrowPart.addInPlace(to);
  }
  return [fromArrow, toArrow];
}
export function createCircleXY(center, radius, numberOfPoints) {
  const points = [];
  const angleStep = Math.PI * 2 / numberOfPoints;
  for (let i = 0; i <= numberOfPoints; i++) {
    points.push(new Vector3(center.x + radius * Math.cos(angleStep * i), center.y + radius * Math.sin(angleStep * i), center.z));
  }
  return points;
}
export function createCircleXZ(center, radius, numberOfPoints) {
  const points = [];
  const angleStep = Math.PI * 2 / numberOfPoints;
  for (let i = 0; i <= numberOfPoints; i++) {
    points.push(new Vector3(center.x + radius * Math.cos(angleStep * i), center.y, center.z + radius * Math.sin(angleStep * i)));
  }
  return points;
}
export function createCircleYZ(center, radius, numberOfPoints) {
  const points = [];
  const angleStep = Math.PI * 2 / numberOfPoints;
  for (let i = 0; i <= numberOfPoints; i++) {
    points.push(new Vector3(center.x, center.y + radius * Math.cos(angleStep * i), center.z + radius * Math.sin(angleStep * i)));
  }
  return points;
}
