@if (changedProperties != null) {
    @for (item of changedProperties; track item.name) {
        <ul>
            <li>
                <span [innerHTML]="item.name"></span>
                <ul>
                    @if (item.children != null && item.children.length > 0) {
                        <app-design-verification-changes-loop
                            [changedProperties]="item.children">
                        </app-design-verification-changes-loop>
                    }
                </ul>
            </li>
        </ul>
    }
}
