import { ReportDesignRequestC2C, ReportResponseEntityC2C } from '../generated-modules/Hilti.PE.ReportService.Shared.Entities';
import { UploadedPictureType } from '../generated-modules/Hilti.PE.ReportService.Shared.Enums';

export interface IUploadedPicture {
    type: UploadedPictureType;
    content: string;
}

export abstract class ReportServiceBase {

    public abstract getCustomImagesC2C(imageNames: string[]): Promise<string[]>;
    public abstract uploadImagesC2C(designId: string, images: IUploadedPicture[]): Promise<string[]>;
    public abstract generateAndDownloadReportC2C(request: ReportDesignRequestC2C): Promise<ReportResponseEntityC2C>;
}

export class ReportServiceInjected extends ReportServiceBase {
    protected baseService!: ReportServiceBase;

    public setBaseService(baseService: ReportServiceBase) {
        this.baseService = baseService;
        this.getCustomImagesC2C = baseService.getCustomImagesC2C.bind(baseService);
        this.uploadImagesC2C = baseService.uploadImagesC2C.bind(baseService);
        this.generateAndDownloadReportC2C = baseService.generateAndDownloadReportC2C.bind(baseService);
    }

    // ApiServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public getCustomImagesC2C(_imageNames: string[]): Promise<string[]> {
        return {} as Promise<string[]>;
    }

    public uploadImagesC2C(designId: string, images: IUploadedPicture[]): Promise<string[]> {
        return {} as Promise<string[]>;
    }

    public generateAndDownloadReportC2C(_request: ReportDesignRequestC2C): Promise<ReportResponseEntityC2C> {
        return {} as Promise<ReportResponseEntityC2C>;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
