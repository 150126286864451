import { createTexture } from '../data-image.js';
export class FontTexture {
  image;
  charInfo;
  sampling;
  /**
   * FontTexture
   * @param image url (webpack), base64 image or GLImage (NodeJS)
   * @param charInfo charInfo
   * @param sampling sampling
   */
  constructor(image, charInfo, sampling = 3) {
    this.image = image;
    this.charInfo = charInfo;
    this.sampling = sampling;
  }
  async createGlFontTexture(scene) {
    const texture = await createTexture(this.image, scene);
    texture.hasAlpha = true;
    return {
      fontTexture: this,
      texture
    };
  }
  getCharInfo(char) {
    if (char == null || char.length != 1) {
      throw new Error('char not provided');
    }
    if (this.charInfo[char] == null) {
      throw new Error('char not found');
    }
    return this.charInfo[char];
  }
}
