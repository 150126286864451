<div class="grid-image-select">
    <div class="items" *ngIf='!isVertical' [ngStyle]="{ 'width': itemsWidth +'%' }">
        <div class="item-wrapper"
            *ngFor="let item of items; index as i; trackBy: trackItemById"
            [ngClass]="'column-number-' + numberOfColumns"
            [ngStyle]="{ 'width': (100 / numberOfColumns) + '%' }">
            <div class="item grid-button" tabindex="0"
                [id]="'add-edit-design-' + item.displayKey?.toLowerCase()"
                [class.selected-item]="item.id === selectedValue"
                [class.disabled]="item.disabled"
                [class.no-right-border]="isLastColumn()"
                [class.no-bottom-border]="isLastRow()"
                [class.no-left-border]="!isFirstColumn(i)"
                (click)="select(item)">
                <div class="item-container" [ngClass]="'column-number-' + numberOfColumns">
                    <span *ngIf="item.new" class="text new-badge" l10n="Common.QuickStartButtons.NewStatus"></span>
                    <div class="image-container" [ngClass]="'column-number-' + numberOfColumns">
                        <div class="sprite sprite-responsive">
                            <img [src]="item.image" alt="image" />
                        </div>
                    </div>
                    <div class="content-container" [ngClass]="'column-number-' + numberOfColumns">
                        <div class="name-container">
                            <p class="name">
                                {{item.name}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="items-vertical" *ngIf='isVertical'>
        <div class="item-wrapper"
            *ngFor="let item of items; index as i; trackBy: trackItemById"
            [ngClass]="'column-number-' + numberOfColumns">
            <div class="item grid-button" tabindex="0"
                [id]="'add-edit-design-' + item.displayKey?.toLowerCase()"
                [class.selected-item]="item.id === selectedValue"
                [class.disabled]="item.disabled"
                (click)="select(item)">
                <div class="item-container-vertical" [ngClass]="'column-number-' + numberOfColumns">
                    <span *ngIf="item.new" class="text new-badge" l10n="Common.QuickStartButtons.NewStatus"></span>
                    <div class="image-container-vertical" [ngClass]="'column-number-' + numberOfColumns">
                        <div class="sprite sprite-responsive">
                            <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-{{item.image}}"></span>
                        </div>
                    </div>
                    <div class="content-container-vertical" [ngClass]="'column-number-' + numberOfColumns">
                        <div class="name-container">
                            <p class="name">
                                {{item.name}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
