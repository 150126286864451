import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';

import { VertexBuffer } from '@babylonjs/core/Buffers';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Matrix, Vector2, Vector3 } from '@babylonjs/core/Maths/math';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { CreateCylinder } from '@babylonjs/core/Meshes/Builders/cylinderBuilder';
import { CreateDashedLines, CreateLines } from '@babylonjs/core/Meshes/Builders/linesBuilder';
import { CSG } from '@babylonjs/core/Meshes/csg';
import { InstancedMesh } from '@babylonjs/core/Meshes/instancedMesh';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Scene } from '@babylonjs/core/scene';
import { CommonCache } from '@profis-engineering/gl-model/cache/common-cache';
import { cloneVertexData } from '@profis-engineering/gl-model/vertex-data-helper';
import { PunchComponent, PunchComponentConstructor } from '../../punch-component';
import { PunchBaseMaterialModel, PunchPostInstalledElementModel, VisibilityModel } from '../../punch-gl-model';
import { numberOfSides } from '../strength/post-installed-element-helper';
import { getWasherZPosition, translateKernelPointTo3dPoint } from './punch-helper';

const elementColor = Color3.FromHexString('#ee0011');
const washerColor = Color3.FromHexString('#ffdc40');

interface PuchPostInstalledElementUpdateData {
    baseMaterial: PunchBaseMaterialModel;
    postInstalledElement: PunchPostInstalledElementModel;
    visibilityModel: VisibilityModel;
}

export const anchorProtuberance = 20;
const elementTesseletaion = 16;
const perimeterVerticalOffset = 5;
export const washerFillHolesHeight = 5;
const fillHolesHolePoints = 8;
const fillHolesHoleScale = 1 / 7.5;
const fillHolesHolePosition = new Vector2(-CommonCache.cylinderSizeHalf * (2 / 3), 0);

const showPostInstalledElement = true;
const extendLineMultiplier = 1000;
const vectorNormalizationMultiplier = 1000;

export class PunchPostInstalledElement extends PunchComponent {
    private elementMesh: Mesh | undefined;
    private washerMesh: Mesh | undefined;
    private elementMeshes: InstancedMesh[];
    private washerMeshes: InstancedMesh[];
    private controlPerimetersTop: LinesMesh[];
    private outerPerimetersTop: LinesMesh[];
    private outerPerimetersTopVirtual: LinesMesh[];
    private controlPerimetersBottom: LinesMesh[];
    private outerPerimetersBottom: LinesMesh[];
    private influenceVectorsTop: Mesh[];
    private influenceVectorsBottom: Mesh[];
    private elementMaterial!: StandardMaterial;
    private washerMaterial!: StandardMaterial;
    private postInstalledElementModelUpdateData: PuchPostInstalledElementUpdateData;

    constructor(ctor: PunchComponentConstructor) {
        super(ctor);
        this.elementMeshes = [];
        this.washerMeshes = [];
        this.controlPerimetersTop = [];
        this.outerPerimetersTop = [];
        this.outerPerimetersTopVirtual = [];
        this.controlPerimetersBottom = [];
        this.outerPerimetersBottom = [];
        this.influenceVectorsTop = [];
        this.influenceVectorsBottom = [];
        this.postInstalledElementModelUpdateData = {} as PuchPostInstalledElementUpdateData;
        this.initMaterial();
    }

    private initMaterial() {
        this.elementMaterial = new StandardMaterial('PunchPostInstalledElementMaterial', this.scene);
        this.elementMaterial.diffuseColor = elementColor;

        this.washerMaterial = new StandardMaterial('PunchPostInstalledWasherMaterial', this.scene);
        this.washerMaterial.diffuseColor = washerColor;
    }

    public update(): void {
        // Only update if the relevant values have actually changed
        const modelUpdateData: PuchPostInstalledElementUpdateData = {
            baseMaterial: this.model.baseMaterial,
            postInstalledElement: this.model.punchPostInstalledElement,
            visibilityModel: this.model.visibilityModel
        };
        if (isEqual(this.postInstalledElementModelUpdateData, modelUpdateData)) {
            return;
        }
        this.postInstalledElementModelUpdateData = cloneDeep(modelUpdateData);

        this.ensureMesh();
    }

    private ensureMesh() {
        this.disposeMeshes(this.elementMeshes);
        this.disposeMeshes(this.washerMeshes);
        this.disposePerimeterMeshes();

        this.createElementMesh();
        this.createWasherMesh();

        if (this.ShowPerimeters) {
            this.ensureControlPerimeterMesh();
            this.ensureOuterPerimeterMesh();
            this.ensureInfluenceVectorsMesh();
        }

        // get points from all perimeters as single array
        const pointsFlat = this.model.punchPostInstalledElement.perimeters.flatMap(p => p.points);

        for (const pointFlat of pointsFlat) {
            this.elementMeshes.push(this.createElementMeshInstance(pointFlat));
            this.washerMeshes.push(this.createWasherMeshInstance(pointFlat));
        }
    }

    private createElementMesh() {
        this.elementMesh = this.cache.meshCache.create('PunchPostInstalledElement', () => CreateCylinder('PunchPostInstalledElement', { tessellation: elementTesseletaion }, this.scene));
        this.elementMesh.material = this.elementMaterial;
        this.elementMesh.setEnabled(false);
    }

    private createWasherMesh() {
        this.washerMesh = this.cache.meshCache.create('PunchPostInstalledWasher', () => {
            const fillHolesVertexData = cloneVertexData(this.cache.vertexDataCache.cylinder(numberOfSides).vertexData);
            fillHolesVertexData.transform(Matrix.Scaling(1, washerFillHolesHeight / CommonCache.cylinderSize, 1));

            // eslint-disable-next-line @typescript-eslint/no-deprecated
            const fillHolesCSG = CSG.FromVertexData(fillHolesVertexData);
            const fillHolesSubtractVertexData = cloneVertexData(this.cache.vertexDataCache.cylinder(fillHolesHolePoints).vertexData);

            let fillHolesSubtractMatrix = Matrix.Scaling(fillHolesHoleScale, 2, fillHolesHoleScale);
            fillHolesSubtractMatrix = fillHolesSubtractMatrix.multiply(Matrix.Translation(fillHolesHolePosition.x, 0, fillHolesHolePosition.y));
            fillHolesSubtractVertexData.transform(fillHolesSubtractMatrix);

            // eslint-disable-next-line @typescript-eslint/no-deprecated
            const fillHolesSubtractCSG = CSG.FromVertexData(fillHolesSubtractVertexData);

            fillHolesCSG.subtractInPlace(fillHolesSubtractCSG);

            return fillHolesCSG.toMesh('PunchPostInstalledWasher', this.washerMaterial, this.scene, false);
        });
        this.washerMesh.setEnabled(false);
    }

    private createElementMeshInstance(point: Vector2) {
        const name = `PunchPostInstalledElement_${point.x}_${point.y}`;
        const diameter = this.model.punchPostInstalledElement.anchorDiameter;

        const mesh = this.elementMesh?.createInstance(name);
        mesh!.scaling = new Vector3(diameter, this.anchorLength, diameter);
        mesh!.position = translateKernelPointTo3dPoint(this.model, point, -this.anchorZPosition);
        mesh!.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);
        mesh!.rotation.x = Math.PI / 2;
        mesh!.setEnabled(true);

        return mesh!;
    }

    private createWasherMeshInstance(point: Vector2) {
        const name = `PunchPostInstalledWasher_${point.x}_${point.y}`;
        const radius = (this.model.punchPostInstalledElement.anchorDiameter * 3) / 2;

        const mesh = this.washerMesh?.createInstance(name);
        mesh!.scaling = new Vector3(radius / CommonCache.cylinderSizeHalf, 1, radius / CommonCache.cylinderSizeHalf);
        mesh!.position = translateKernelPointTo3dPoint(this.model, point, getWasherZPosition(this.model));
        mesh!.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);
        mesh!.rotationQuaternion = null;
        mesh!.rotation.x = Math.PI / 2;
        mesh!.setEnabled(showPostInstalledElement);

        return mesh!;
    }

    private get ShowPerimeters() {
        return this.model.visibilityModel.CriticalPerimeter && this.model.punchPostInstalledElement.defineStrengtheningElement;
    }

    private ensureControlPerimeterMesh() {
        if (this.model.punchPostInstalledElement.controlPerimeters != null) {
            for (let i = 0; i < this.model.punchPostInstalledElement.controlPerimeters.length; i++) {
                const points: Vector3[] = [];
                this.model.punchPostInstalledElement.controlPerimeters[i].forEach((point) => {
                    const tmpPoint = translateKernelPointTo3dPoint(this.model, point, 0);
                    points.push(new Vector3(tmpPoint.x, 0, tmpPoint.y));
                });

                const options = {
                    points: points,
                    updatable: true
                };

                this.controlPerimetersTop[i] = CreateLines('ControlPerimeterTop' + i, options, this.scene);
                this.controlPerimetersTop[i].color = Color3.Red();
                this.controlPerimetersTop[i].setEnabled(this.ShowPerimeters);
                this.controlPerimetersTop[i].position.y = this.model.baseMaterial.thickness / 2 + perimeterVerticalOffset;

                this.controlPerimetersBottom[i] = CreateLines('ControlPerimeterBottom' + i, options, this.scene);
                this.controlPerimetersBottom[i].color = Color3.Red();
                this.controlPerimetersBottom[i].setEnabled(this.ShowPerimeters);
                this.controlPerimetersBottom[i].position.y = -this.model.baseMaterial.thickness / 2 - perimeterVerticalOffset;
            }
        }
    }

    private ensureOuterPerimeterMesh() {
        if (this.model.punchPostInstalledElement.outerPerimeters != null) {
            for (let i = 0; i < this.model.punchPostInstalledElement.outerPerimeters.length; i++) {
                const points: Vector3[] = [];
                this.model.punchPostInstalledElement.outerPerimeters[i].forEach((point) => {
                    const tmpPoint = translateKernelPointTo3dPoint(this.model, point, 0);
                    points.push(new Vector3(tmpPoint.x, 0, tmpPoint.y));
                });
                const options = {
                    points: points,
                    updatable: true,
                    gapSize: 24,
                    dashSize: 24
                };

                this.outerPerimetersTopVirtual[i] = CreateLines('OuterPerimeterTopVirtual' + i, options, this.scene);
                this.outerPerimetersTopVirtual[i].position.y = this.model.baseMaterial.thickness / 2 + perimeterVerticalOffset;

                this.outerPerimetersTop[i] = CreateDashedLines('OuterPerimeterTop' + i, options, this.scene);
                this.outerPerimetersTop[i].setEnabled(this.ShowPerimeters);
                this.outerPerimetersTop[i].color = Color3.Black();
                this.outerPerimetersTop[i].position.y = this.model.baseMaterial.thickness / 2 + perimeterVerticalOffset;

                this.outerPerimetersBottom[i] = CreateDashedLines('OuterPerimeterBottom' + i, options, this.scene);
                this.outerPerimetersBottom[i].setEnabled(this.ShowPerimeters);
                this.outerPerimetersBottom[i].color = Color3.Black();
                this.outerPerimetersBottom[i].position.y = -this.model.baseMaterial.thickness / 2 - perimeterVerticalOffset;
            }
        }
    }

    private ensureInfluenceVectorsMesh() {
        if (!this.model.punchPostInstalledElement?.influenceVectors) {
            return;
        }

        for (let count = 0; count < this.model.punchPostInstalledElement.influenceVectors.length; count++) {
            this.ensureInfluenceVectorToOuterPerimeter(count, this.model.punchPostInstalledElement.influenceVectors[count]);
        }

        // dispose virtual outer perimeter mesh used to find intersections before next calculation
        this.outerPerimetersTopVirtual.forEach(element => element.dispose());
    }

    /**
     * TODO: potential performance improvement
     * Whole intersection logic could be removed if kernel would return
     * actual interesction points between influence vector and outer perimeter.
     * https://hilti.atlassian.net/browse/PSP-2433
     */
    private ensureInfluenceVectorToOuterPerimeter(count: number, kernelPoint: Vector2) {
        kernelPoint = new Vector2(kernelPoint.x * vectorNormalizationMultiplier, kernelPoint.y * vectorNormalizationMultiplier);

        // extend original influence vector to find intersections with outer perimeter
        const linePoints: Vector3[] = [];
        linePoints.push(new Vector3(0, 0, 0));

        const extendedIntersectionPoint = this.tryFindExtendedIntersection(kernelPoint);

        if (extendedIntersectionPoint) {
            linePoints.push(new Vector3(extendedIntersectionPoint.x, 0, extendedIntersectionPoint.z));
        }
        else {
            const virtualIntersectionPoint = this.tryFindVirtualIntersection(kernelPoint);
            linePoints.push(virtualIntersectionPoint !== null ?
                new Vector3(virtualIntersectionPoint.x, 0, virtualIntersectionPoint.z) :
                this.extendVector(new Vector3(0, 0, 0), new Vector3(kernelPoint.x, 0, kernelPoint.y), this.getMaxDistanceToOuterPerimeter()));
        }

        // draw top influence vector
        const lineTop = this.createDashDotLine(`influenceVector_top${count}`, linePoints, this.scene);
        lineTop.setEnabled(this.ShowPerimeters);
        lineTop.position.y = this.model.baseMaterial.thickness / 2 + perimeterVerticalOffset;

        // draw bottom influence vector
        const lineBottom = this.createDashDotLine(`influenceVector_bottom${count}`, linePoints, this.scene);
        lineBottom.setEnabled(this.ShowPerimeters);
        lineBottom.position.y = -this.model.baseMaterial.thickness / 2 - perimeterVerticalOffset;

        this.influenceVectorsTop.push(lineTop);
        this.influenceVectorsBottom.push(lineBottom);
    }

    /** Extend kernel vector and try to find intersection with outer perimeter line */
    private tryFindExtendedIntersection(kernelPoint: Vector2): Vector3 | null {
        const points: Vector3[] = [];
        points.push(new Vector3(0, 0, 0));
        points.push(new Vector3(kernelPoint.x * extendLineMultiplier, 0, kernelPoint.y * extendLineMultiplier));

        const lineExtended = this.createDashDotLine('influenceVectorExtended', points, this.scene);
        const extendedIntersectionPoint = this.findIntersectionsBetweenInfluenceVectorAndOuterPerimeter(lineExtended);
        lineExtended.dispose();

        return extendedIntersectionPoint;
    }

    private tryFindVirtualIntersection(kernelPoint: Vector2): Vector3 | null {
        const pointPlus = this.getVirtualKernelIntersectionPoint(kernelPoint, 1, 0);
        const pointMinus = this.getVirtualKernelIntersectionPoint(kernelPoint, -1, 0);
        const points: Vector3[] = [];
        points.push(new Vector3(0, 0, 0));
        points.push(pointPlus);

        const lineVirtual = this.createDashDotLine('influenceVectorVirtual1', points, this.scene);
        const intersection = this.findIntersectionsBetweenInfluenceVectorAndOuterPerimeter(lineVirtual);
        lineVirtual.dispose();

        if (intersection) {
            return intersection;
        }

        points.splice(1, 1);
        points.push(pointMinus);
        const lineVirtual2 = this.createDashDotLine('influenceVectorVirtual2', points, this.scene);
        const intersection2 = this.findIntersectionsBetweenInfluenceVectorAndOuterPerimeter(lineVirtual2);
        lineVirtual2.dispose();

        if (intersection2) {
            return intersection2;
        }
        return null;
    }

    private createDashDotLine(name: string, points: Vector3[], scene: Scene): Mesh {
        const dashSize = 2;
        const gapSize = 2;
        const numTotalDashed = 50;

        const dashOptions = {
            points: points,
            dashSize: dashSize,
            gapSize: gapSize,
            dashNb: numTotalDashed,
            updatable: true
        };
        const dotOptions = {
            points: points,
            dashSize: dashSize / 5,
            gapSize: gapSize / 2,
            dashNb: numTotalDashed * 4,
            updatable: true
        };

        const dashLine = CreateDashedLines(name, dashOptions, scene);
        const dotLine = CreateDashedLines(name, dotOptions, scene);
        const dashDotMergedMesh = Mesh.MergeMeshes([dashLine, dotLine], true, true, undefined, false, false);

        dashLine.dispose();
        dotLine.dispose();

        if (!dashDotMergedMesh) {
            throw new Error('Failed to merge meshes');
        }

        return dashDotMergedMesh;
    }

    private get anchorZPosition(): number {
        const trueZero = this.model.baseMaterial.thickness / 2;
        return this.anchorLength - trueZero - anchorProtuberance + this.model.punchPostInstalledElement.depthOfRecess;
    }

    private get anchorLength(): number {
        return this.model.punchPostInstalledElement.drillLength / 2 + anchorProtuberance / 2;
    }

    private getVirtualKernelIntersectionPoint(kernelPoint: Vector2, deltaX: number, deltaY: number): Vector3 {
        return new Vector3((kernelPoint.x + deltaX) * extendLineMultiplier, 0, (kernelPoint.y + deltaY) * extendLineMultiplier);
    }

    private findIntersectionsBetweenInfluenceVectorAndOuterPerimeter(linesMesh: LinesMesh | Mesh): Vector3 | null {
        const influenceVectorExtendedPoints = this.getPointsFromLinesMesh(linesMesh);
        const intersections: Vector3[] = [];

        for (let i = 0; i < this.outerPerimetersTop.length; i++) {
            const outerPerimeterPoints = this.getPointsFromLinesMesh(this.outerPerimetersTopVirtual[i]);

            for (let i = 0; i < influenceVectorExtendedPoints.length - 1; i++) {
                for (let j = 0; j < outerPerimeterPoints.length - 1; j++) {
                    const intersection = this.getLineIntersection(influenceVectorExtendedPoints[i], influenceVectorExtendedPoints[i + 1], outerPerimeterPoints[j], outerPerimeterPoints[j + 1]);
                    if (intersection) {
                        intersections.push(intersection);
                    }
                }
            }
        }

        return intersections.length > 0 ? intersections[0] : null;
    }

    private getLineIntersection(p0: Vector3, p1: Vector3, p2: Vector3, p3: Vector3): Vector3 | null {
        const s1 = p1.subtract(p0);
        const s2 = p3.subtract(p2);
        const s = (-s1.z * (p0.x - p2.x) + s1.x * (p0.z - p2.z)) / (-s2.x * s1.z + s1.x * s2.z);
        const t = (s2.x * (p0.z - p2.z) - s2.z * (p0.x - p2.x)) / (-s2.x * s1.z + s1.x * s2.z);

        if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
            // Intersection detected
            return new Vector3(
                p0.x + (t * s1.x),
                p0.y + (t * s1.y),
                p0.z + (t * s1.z)
            );
        }

        return null;
    }

    private extendVector(center: Vector3, target: Vector3, maxLength: number): Vector3 {
        const direction = target.subtract(center).normalize();
        const extended = direction.scale(maxLength).add(center);
        return extended;
    }

    private getMaxDistanceToOuterPerimeter() {
        let maxDistance = 0;
        const zeroPoint = new Vector3(0, 0, 0);

        if (this.model.punchPostInstalledElement.outerPerimeters != null) {
            this.model.punchPostInstalledElement.outerPerimeters.forEach((perimeter) => {
                perimeter.forEach((point) => {
                    const distance = Vector3.Distance(zeroPoint, new Vector3(point.x, 0, point.y));
                    if (distance > maxDistance) {
                        maxDistance = distance;
                    }
                });
            });
        }
        return maxDistance;
    }

    private getPointsFromLinesMesh(linesMesh: LinesMesh | Mesh): Vector3[] {
        const positions = linesMesh.getVerticesData(VertexBuffer.PositionKind);

        if (positions == null) {
            return [];
        }

        const points: Vector3[] = [];
        for (let i = 0; i < positions.length; i += 3) {
            points.push(new Vector3(positions[i], positions[i + 1], positions[i + 2]));
        }
        return points;
    }

    private disposeMeshes(meshes: InstancedMesh[]) {
        for (const mesh of meshes) {
            mesh.setEnabled(false);
            mesh.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);
        }
        meshes = [];
    }

    private disposePerimeterMeshes() {
        this.outerPerimetersTop.forEach(element => element.dispose());
        this.outerPerimetersBottom.forEach(element => element.dispose());
        this.controlPerimetersTop.forEach(element => element.dispose());
        this.controlPerimetersBottom.forEach(element => element.dispose());
        this.influenceVectorsTop.forEach(element => element.dispose());
        this.influenceVectorsBottom.forEach(element => element.dispose());
    }
}
