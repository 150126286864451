import {
    ConnectorLengthEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class ConnectorLength extends CodeList {
    public length?: number;

    constructor(codeList: ConnectorLengthEntityC2C) {
        super(codeList);

        if (codeList != null) {
            this.length = codeList.length;
        }
    }

    public static fromService(codeList: ConnectorLengthEntityC2C) {
        return new ConnectorLength(CodeList.baseFromService('ConnectorLengthEntity', codeList, {
            id: codeList.id,
            length: codeList.length,
            displayKey: codeList.displayKey
        }));
    }

    public override cloneDeep(): ConnectorLength {
        const codeList = super.cloneDeep() as ConnectorLength;

        codeList.length = this.length;

        return codeList;
    }
}
