import { LoadDefinitionSectionEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class LoadDefinitionSection extends CodeList {

    public static fromService(codeList: LoadDefinitionSectionEntityC2C) {
        return new LoadDefinitionSection(CodeList.baseFromService('LoadDefinitionSectionEntityC2C', codeList));
    }

    public override cloneDeep(): LoadDefinitionSection {
        return super.cloneDeep() as LoadDefinitionSection;
    }
}

