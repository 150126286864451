import { ApplicationRef, NgZone, Type } from '@angular/core';

import { registerGlobalStyles } from './global-styles';
import { createApplication } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { QueryService } from './sp/services/query.service';
import { createCustomElement } from '@angular/elements';
import { UiInitComponent } from './sp/components/ui-init/ui-init.component';
import { AddEditDesignStrengthComponent } from './sp/components/add-edit-design-strength/add-edit-design-strength.component';
import { AddEditDesignPunchComponent } from './sp/components/add-edit-design-punch/add-edit-design-punch.component';
import { AppSettingsStrengthComponent } from './sp/components/app-settings-strength/app-settings-strength.component';
import { AppSettingsPunchComponent } from './sp/components/app-settings-punch/app-settings-punch.component';
import { MainComponent } from './sp/components/main/main.component';
import { ExportReportComponent } from './sp/components/export-report/export-report.component';
import { ProductSelectionComponent } from './sp/components/product-selection/product-selection.component';
import { InfoDialogDrillingAidComponent } from './sp/components/info-dialogs/installation-conditions/drilling-aid/info-dialog-drilling-aid.component';
import { InfoDialogDepthOfRecessComponent } from './sp/components/info-dialogs/installation-conditions/depth-of-recess/info-dialog-depth-of-recess.component';
import { InfoDialogCrossSectionalAreaComponent } from './sp/components/info-dialogs/existing-reinforcement/total-cross-sectional-area/info-dialog-as.component';
import { InfoDialogCoverComponent } from './sp/components/info-dialogs/existing-reinforcement/cover/info-dialog-cover.component';
import { InfoDialogEffectiveHeighComponent } from './sp/components/info-dialogs/existing-reinforcement/effective-height/info-dialog-effective-height.component';
import { InfoDialogInstallationDirectionComponent } from './sp/components/info-dialogs/post-installed-element/installation-direction/info-dialog-installation-direction.component';
import { InfoDialogTransverseEccentricityComponent } from './sp/components/info-dialogs/post-installed-element/transverse-eccentricity/info-dialog-transverse-eccentricity.component';
import { InfoDialogReinforcementEffectivenessComponent } from './sp/components/info-dialogs/loads/reinforcement-effectiveness/info-dialog-reinforcement-effectiveness.component';
import { InfoDialogPunchOpeningComponent } from './sp/components/info-dialogs/opening-using/info-dialog-punch-using-opening.component';
import { DesignVerificationChangesComponent } from './sp/components/design-verification-changes-popup/design-verification-changes.component';

interface Zone {
    root: NgZone;
    current: Zone;
    get: (name: string) => unknown;
    _properties: {
        zoneName: string;
    };
}

interface InternalNgZone extends NgZone {
    _inner: Zone;
}

declare const Zone: Zone;
declare const ngDevMode: boolean;

// correctly check if we are in pe-ui-sp zone
NgZone.isInAngularZone = () => Zone.current.get('zoneName') === 'pe-ui-sp';

registerGlobalStyles();

declare global {
    interface Window {
        modules: Module[];
    }
}

interface Module {
    name: string;
    bootstrap: () => void;
}

const modules = window.modules = window.modules ?? [];

modules.push({
    name: 'pe-ui-sp',
    bootstrap
});

function bootstrap() {
    // mark the zone with pe-ui-sp flag so we can correctly check in NgZone.isInAngularZone
    const ngZone = new NgZone({
        enableLongStackTrace: typeof ngDevMode === 'undefined' ? false : !!ngDevMode,
        shouldCoalesceEventChangeDetection: false,
        shouldCoalesceRunChangeDetection: false,
    });
    ngZone.runOutsideAngular = <T>(fn: (...args: unknown[]) => T): T => {
        return Zone.root.run(fn);
    };

    const zone = (ngZone as InternalNgZone)._inner;
    zone._properties.zoneName = 'pe-ui-sp';

    createApplication({
        providers: [
            { provide: APP_BASE_HREF, useValue: '/cdn/pe-ui-sp/' },
            { provide: NgZone, useValue: ngZone },
        ]
    })
        .then(appRef => configure(appRef))
        .catch((error: unknown) => console.error(error));
}

function configure(appRef: ApplicationRef) {
    const injector = appRef.injector;
    const ngbTooltipConfig = injector.get(NgbTooltipConfig);
    const queryService = injector.get(QueryService);

    configureTooltip(ngbTooltipConfig);

    registerCustomElement('ui-init', UiInitComponent);
    registerCustomElement('add-edit-design-strength', AddEditDesignStrengthComponent);
    registerCustomElement('add-edit-design-punch', AddEditDesignPunchComponent);
    registerCustomElement('app-settings-strength', AppSettingsStrengthComponent);
    registerCustomElement('app-settings-punch', AppSettingsPunchComponent);
    registerCustomElement('main', MainComponent);

    // TODO: popup components do not need to be web components once we fix common popup
    registerCustomElement('export-report', ExportReportComponent);
    registerCustomElement('product-selection', ProductSelectionComponent);
    registerCustomElement('info-dialog-drilling-aid', InfoDialogDrillingAidComponent);
    registerCustomElement('info-dialog-depth-of-recess', InfoDialogDepthOfRecessComponent);
    registerCustomElement('info-dialog-as', InfoDialogCrossSectionalAreaComponent);
    registerCustomElement('info-dialog-cover', InfoDialogCoverComponent);
    registerCustomElement('info-dialog-effective-height', InfoDialogEffectiveHeighComponent);
    registerCustomElement('info-dialog-installation-direction', InfoDialogInstallationDirectionComponent);
    registerCustomElement('info-dialog-transverse-eccentricity', InfoDialogTransverseEccentricityComponent);
    registerCustomElement('info-dialog-reinforcement-effectiveness', InfoDialogReinforcementEffectivenessComponent);
    registerCustomElement('info-dialog-punch-using-opening', InfoDialogPunchOpeningComponent);
    registerCustomElement('design-verification-changes', DesignVerificationChangesComponent);

    queryService.initQuery();

    function registerCustomElement(componentName: string, component: Type<unknown>) {
        const customElement = createCustomElement(component, { injector: injector });
        customElements.define(`sp-${componentName}`, customElement);
    }
}

function configureTooltip(config: NgbTooltipConfig) {
    config.container = 'body';
    config.triggers = 'hover';
    config.openDelay = 500;
}
