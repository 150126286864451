import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { Observable } from 'rxjs/internal/Observable';
import { ZoneAlternative } from '../../../../entities/decking-design/zone-alternative-model';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { DesignSettings } from '../../../../entities/settings/design-settings';
import { DeckingUnitsHelperService } from '../../../../services/decking-units-helper/decking-units-helper.service';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { DeckingCodeListService } from '../../../../services/decking-code-list/decking-code-list.service';
import { MultiSelectDropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { LocalizationService } from '../../../../services/external/localization.service';
import { UnitService } from '../../../../services/external/unit.service';
import { FeatureVisibilityService } from '../../../../services/external/feature-visibility.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { Subscription } from 'rxjs';
import { CalculatingIndicatorService } from 'src/decking/services/decking-design/calculation/common/indicator/calculating-indicator.service';

export const FILTER_KEYS = {
    DECK_GAUGE: 'deckGauge',
    PATTERN: 'pattern',
    FRAME_FASTENER: 'frameFastener',
    SIDELAP_CONNECTOR: 'sidelapConnector',
    SIDE: 'side',
};

@Component({
    template: '',
})
export abstract class CompareZoneAlternativesCommonComponent implements OnDestroy{
    @ViewChild('deckGauge_DD', { static: false }) deckGauge_DD: ElementRef;
    @ViewChild('pattern_DD', { static: false }) pattern_DD: ElementRef;
    @ViewChild('frameFastener_DD', { static: false }) frameFastener_DD: ElementRef;
    @ViewChild('sidelapConnector_DD', { static: false }) sidelapConnector_DD: ElementRef;
    @ViewChild('sidelapSpacing_DD', { static: false }) sidelapSpacing_DD: ElementRef;
    
    @Input()
    public modalInstance!: ModalInstance;

    deckGaugeOptionDdl: MultiSelectDropdownProps<string> = {
        id: 'deck-gauge-design-options',
        items: [],
        selectedValues: [],
    };
    patternOptionDdl: MultiSelectDropdownProps<string> = {
        id: 'pattern-design-options',
        items: [],
        selectedValues: [],
    };
    frameFastenerOptionDdl: MultiSelectDropdownProps<string> = {
        id: 'frame-fastener-design-options',
        items: [],
        selectedValues: [],
    };
    sidelapConnectorOptionDdl: MultiSelectDropdownProps<string> = {
        id: 'sidelap-connector-design-options',
        items: [],
        selectedValues: [],
    };
    sidelapSpacingOptionDdl: MultiSelectDropdownProps<string> = {
        id: 'sidelap-spacing-design-options',
        items: [],
        selectedValues: [],
    };

    isSubmitting = false;
    alternatives: ZoneAlternative[] = [];
    alternativesMasterListForFilters: ZoneAlternative[] = [];
    matchingAlternatives: ZoneAlternative[] = [];
    selectedAlternatives: Set<string> = new Set();
    originalIndexMap: Map<string, number> = new Map(); // Map to store original indices of alternatives
    readonly maxAllowedSelection: number = 3;
    currentZoneAlternatives$: Observable<ZoneAlternative[]>;
    isSolutionsFilterEnabled = false;
    spacingUnit: Unit;
    bySpacing = false;
    _spacingUnit$: Subscription;
    _bySpacing$: Subscription;
    dominatingFilter = '';
    filterDictionary = new Map<any, any[]>();
    isAlternativesLoading = false;

    // Units
    public currentSettings$: Observable<DesignSettings>;
    public isImperialUnit$: Observable<boolean>;
    public lengthUnit$: Observable<Unit>;
    public shearStiffnessUnit$: Observable<Unit>;
    public sidelapSpacingUnit$: Observable<Unit>;
    public isBySpacing$: Observable<boolean>;

    public DECK_GAUGE_UNIT = '';

    constructor(
        protected deckingUnitsHelperService: DeckingUnitsHelperService,
        protected deckingCodeListService: DeckingCodeListService,
        protected localizationService: LocalizationService,
        protected unitService: UnitService,
        protected featureVisibilityService: FeatureVisibilityService,
        protected calculatingIndicatorService: CalculatingIndicatorService
    ) {
        this.isSolutionsFilterEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_Alternative_Solutions_Filter');
        this.DECK_GAUGE_UNIT = this.deckingUnitsHelperService.DECK_GAUGE_UNIT;
        this.deckGaugeOptionDdl.notSelectedText = this.translate('Agito.Hilti.Profis3.Decking.Zones.Subheader.DeckGauge');
        this.patternOptionDdl.notSelectedText = this.translate('Agito.Hilti.Profis3.Decking.Zones.Subheader.Pattern');
        this.frameFastenerOptionDdl.notSelectedText = this.translate('Agito.Hilti.Profis3.Decking.Zones.Subheader.FrameFastener');
        this.sidelapConnectorOptionDdl.notSelectedText = this.translate('Agito.Hilti.Profis3.Decking.Zones.Subheader.SidelapFastener');
        this.sidelapSpacingOptionDdl.notSelectedText = 'Agito.Hilti.Profis3.Decking.Zones.Subheader.';
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public async loadData(currentZone: any) {
        this.isAlternativesLoading = true;
        this.isImperialUnit$ = this.currentSettings$.pipe(map(s => s.length.id),
            distinctUntilChanged(),
            map((unitValue: Unit) => {
                return !this.deckingUnitsHelperService.isInternationalSystemUnit(unitValue);
            }));
        this.initUnits();
        await this.loadAlternatives(currentZone.alternatives);

        this.isAlternativesLoading = false;

        this.setFilterDDHeight();
    }

    public selectAlternative(alternative: ZoneAlternative, checked: boolean): void {
        if (checked && this.selectedAlternatives.size < this.maxAllowedSelection) {
            alternative.selected = true;
            if(this.isSolutionsFilterEnabled) {
                this.alternativesMasterListForFilters.find(x => x.id === alternative.id).selected = true;
            }
            this.selectedAlternatives.add(alternative.id);
        } else {
            alternative.selected = false;
            if(this.isSolutionsFilterEnabled) {
                this.alternativesMasterListForFilters.find(x => x.id === alternative.id).selected = false;
            }
            this.selectedAlternatives.delete(alternative.id);
        }
        if(this.isSolutionsFilterEnabled) {
            this.unselectNonFilteredRows(this.matchingAlternatives);
            this.sortAlternatives();
        }
    }

    public getFastenerName(fastenerId: number): string {
        return this.deckingCodeListService.getFastenerTranslatedName(fastenerId);
    }

    public getSidelapName(sidelapId: number): string {
        return this.deckingCodeListService.getSidelapTranslatedName(sidelapId);
    }

    protected initUnits(): void {
        this.initLengthUnit();
        this.sidelapSpacingUnit$ = combineLatest([this.lengthUnit$, this.isBySpacing$]).pipe(
            map(([lengthUnit, isBySpacing]) => isBySpacing ? lengthUnit : Unit.None)
        );
        this.shearStiffnessUnit$ = this.currentSettings$.pipe(map(s => s.shearStiffness.id), distinctUntilChanged());

        this._spacingUnit$ = this.sidelapSpacingUnit$.subscribe((data) => {this.spacingUnit = data;});
        this._bySpacing$ = this.isBySpacing$.subscribe((data) => {this.bySpacing = data;});
    }

    public async loadAlternatives(zoneAlternatives?: ZoneAlternative[]): Promise<void> {
        if (zoneAlternatives && zoneAlternatives.length > 0) {
            this.alternatives = cloneDeep(zoneAlternatives);
            this.alternatives.forEach((alternative, index) => {
                // Populate originalIndexMap
                this.originalIndexMap.set(alternative.id, index);
                if (alternative.selected) {
                    this.selectedAlternatives.add(alternative.id);
                }
            });
            this.setFilterOption();
            return;
        }
        await this.loadAlternativesFromBackend();
        this.setFilterOption();
    }

    public mapOriginalIndexOfAlternatives() {
        this.alternatives.forEach((alternative, index) => {
            // Populate originalIndexMap
            this.originalIndexMap.set(alternative.id, index);
        });
    }

    public deckGaugeOptionChange(e: string[]) {
        const isDominatingFilterApplied = this.setDominatingFilter(FILTER_KEYS.DECK_GAUGE, e);
        this.deckGaugeOptionDdl.selectedValues = e;
    
        let selectedValues: number[];
        if(e.length > 0) {
            selectedValues = e.map(Number);
        }
    
        this.updateAlternatives(FILTER_KEYS.DECK_GAUGE, selectedValues, isDominatingFilterApplied, x => x.deckGauge.value);
    }

    public patternOptionChange(e: string[]) {
        const isDominatingFilterApplied = this.setDominatingFilter(FILTER_KEYS.PATTERN, e);
        this.patternOptionDdl.selectedValues = e;
        this.updateAlternatives(FILTER_KEYS.PATTERN, e, isDominatingFilterApplied, x => x.pattern.value);
    }

    public frameFastenerOptionChange(e: string[]) {
        const isDominatingFilterApplied = this.setDominatingFilter(FILTER_KEYS.FRAME_FASTENER, e);
        this.frameFastenerOptionDdl.selectedValues = e;
        this.updateAlternatives(FILTER_KEYS.FRAME_FASTENER, e, isDominatingFilterApplied, x => x.frameFastener.value);
    }

    public sidelapConnectorOptionChange(e: string[]) {
        const isDominatingFilterApplied = this.setDominatingFilter(FILTER_KEYS.SIDELAP_CONNECTOR, e);
        this.sidelapConnectorOptionDdl.selectedValues = e;
        this.updateAlternatives(FILTER_KEYS.SIDELAP_CONNECTOR, e, isDominatingFilterApplied, x => x.sidelapConnector.value);
    }
    
    public sidelapSpacingOptionChange(e: string[]) {
        const isDominatingFilterApplied = this.setDominatingFilter(FILTER_KEYS.SIDE, e);
        this.sidelapSpacingOptionDdl.selectedValues = e;
    
        let selectedValues: number[];
        if (!this.bySpacing) {
            selectedValues = e.map(value => Number(value));
        } else {
            selectedValues = this.getSelectedValuesForSpacing(e);
        }
    
        this.updateAlternatives(FILTER_KEYS.SIDE, selectedValues, isDominatingFilterApplied, x => x.side.value);
    }

    private updateAlternatives(filterKey: string, selectedValues: any[], isDominatingFilterApplied: boolean, valueExtractor: (x: any) => any) {
        if (selectedValues.length > 0) {
            this.filterDictionary.set(filterKey, selectedValues);
            this.alternatives = isDominatingFilterApplied
                ? [...this.handleFilteredRows(this.getMatchingAlternatives(filterKey, true).filter(x => selectedValues.includes(valueExtractor(x))))]
                : [...this.handleFilteredRows(this.getMatchingAlternatives(filterKey).filter(x => selectedValues.includes(valueExtractor(x))))];
            this.sortAlternatives();
            this.updateFilterOptions();
        } else {
            this.filterDictionary.delete(filterKey);
            this.alternatives = isDominatingFilterApplied
                ? [...this.alternativesMasterListForFilters]
                : [...this.handleFilteredRows(this.getMatchingAlternatives(filterKey))];
            this.sortAlternatives();
            if (!isDominatingFilterApplied) {
                this.updateFilterOptions();
            }
        }
    }

    private setFilterOption(isFilterApplied = false) {
        if (!this.isSolutionsFilterEnabled || this.alternatives.length === 0) {
            return;
        }
    
        this.sortAlternatives();
        if (!isFilterApplied) {
            this.alternativesMasterListForFilters = cloneDeep(this.alternatives);
        }
    
        // Helper method to generate dropdown items
        const generateDropdownItems = (key: string): any[] => {
            const uniqueValues = Array.from(new Set(this.alternativesMasterListForFilters.map((item: any) => item[key]?.value)));
            return uniqueValues.map((value, index) => ({ id: index, value, text: value }));
        };
    
        // Populate dropdowns using a map of key-value pairs
        const dropdownMapping = {
            deckGauge: this.deckGaugeOptionDdl,
            pattern: this.patternOptionDdl,
            frameFastener: this.frameFastenerOptionDdl,
            sidelapConnector: this.sidelapConnectorOptionDdl,
        };
    
        Object.entries(dropdownMapping).forEach(([key, dropdown]) => {
            dropdown.items = generateDropdownItems(key);
        });
    
        // Handle sidestep spacing dropdown
        let spacingItems = generateDropdownItems(FILTER_KEYS.SIDE);
        if (this.bySpacing) {
            spacingItems = spacingItems.map(item => ({
                id: item.id,
                value: `${this.getSpacingFilterConvertedValues(item.value)} ${item.value}`,
                text: this.getSpacingFilterConvertedValues(item.value),
            }));
        }
        this.sidelapSpacingOptionDdl.items = spacingItems;

        this.sortFilterValues();
    }

    private updateFilterOptions(): void {
        // Helper function to check if a filter is applicable
        const isFilterApplicable = (key: string): boolean =>
            this.dominatingFilter !== key && !this.filterDictionary.has(key);
    
        // Helper function to populate dropdowns
        const populateDropdown = (key: string, dropdown: any, valueTransformer?: (item: any) => any): void => {
            if (!isFilterApplicable(key)) return;
    
            let items = generateFilteredDropdownItems(key);
            if (valueTransformer) {
                items = items.map(valueTransformer);
            }
            dropdown.items = items;
        };
    
        // Reusable function to generate filtered dropdown items
        const generateFilteredDropdownItems = (key: string): any[] => {
            const uniqueValues = Array.from(new Set(this.alternatives.map((item: any) => item[key]?.value)));
            return uniqueValues.map((value, index) => ({ id: index, value, text: value }));
        };
    
        // Populate dropdowns
        populateDropdown(FILTER_KEYS.DECK_GAUGE, this.deckGaugeOptionDdl);
        populateDropdown(FILTER_KEYS.PATTERN, this.patternOptionDdl);
        populateDropdown(FILTER_KEYS.FRAME_FASTENER, this.frameFastenerOptionDdl);
        populateDropdown(FILTER_KEYS.SIDELAP_CONNECTOR, this.sidelapConnectorOptionDdl);
    
        // Handle sidestep spacing with additional logic
        populateDropdown(FILTER_KEYS.SIDE, this.sidelapSpacingOptionDdl, item => {
            const transformedValue = this.bySpacing 
                ? `${this.getSpacingFilterConvertedValues(item.value)} ${item.value}` 
                : item.value;
        
            return {
                id: item.id,
                value: transformedValue,
                text: this.bySpacing ? this.getSpacingFilterConvertedValues(item.value) : item.value,
            };
        });

        this.sortFilterValues();
    }

    private getSpacingFilterConvertedValues(value: number) {
        const convertedValue = this.getConvertedSpacingValue(value);
        return this.unitService.formatUnitValueArgs(convertedValue.value, convertedValue.unit, 0, '', '',
            null, true, false);
    }

    private getConvertedSpacingValue(value: number) {
        const unitGroup = this.unitService.getUnitGroupFromUnit(this.spacingUnit);
        const internalUnit = this.unitService.getInternalUnit(unitGroup);
        return this.unitService.convertUnitValueToUnit({
            unit: internalUnit,
            value: value
        }, this.spacingUnit);
    }

    private setFilterDDHeight() {
        if (this.deckGauge_DD && this.isSolutionsFilterEnabled && !this.isAlternativesLoading) {
            this.deckGauge_DD.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[0].style.height = 'auto';
            this.pattern_DD.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[0].style.height = 'auto';
            this.frameFastener_DD.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[0].style.height = 'auto';
            this.sidelapConnector_DD.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[0].style.height = 'auto';
            this.sidelapSpacing_DD.nativeElement.shadowRoot.querySelector('.dropdown-wrapper').childNodes[1].childNodes[0].style.height = 'auto';
        } else {
            // Retry after a delay if the element is not available
            setTimeout(() => this.setFilterDDHeight());
        }
    }

    private getSelectedValuesForSpacing(e: string[]): (number | null)[] {
        const regex = /in\s+(\d+(\.\d+)?)/i;
    
        return e.map(item => {
            const match = regex.exec(item); // Executes the regex and retrieves the match
            return match ? parseFloat(match[1]) : null; // Parse the matched number or return null
        });
    }

    private getMatchingAlternatives(currentFilterKey = '', fromDominatingFilter = false): ZoneAlternative[] {
        const tempAlternatives: ZoneAlternative[] = [];
        let alternatives: ZoneAlternative[] = [];
        const filterKeys = Array.from(this.filterDictionary.keys());
        let index = filterKeys.length > 1
                        ? filterKeys.indexOf(currentFilterKey) - 1
                        : filterKeys.indexOf(filterKeys[0]);
        
        if(fromDominatingFilter && index < 0){
            index = 0;
        }
    
        // Helper function to filter alternatives based on a key
        const filterAlternatives = (source: ZoneAlternative[], key: string, values: any[]): ZoneAlternative[] => {
            return source.filter(x => {
                const property = x[key as keyof ZoneAlternative];
                if (property && typeof property === 'object' && 'value' in property) {
                    return values.includes((property as any).value);
                }
                return values.includes(property);
            });
        };
    
        // Iterate over the filter dictionary
        this.filterDictionary.forEach((value, key) => {
            if (key === currentFilterKey && !fromDominatingFilter) {
                return;
            }
    
            const isDominatingFilter = key === this.dominatingFilter;
    
            if (isDominatingFilter) {
                if (fromDominatingFilter && this.filterDictionary.size > 1) {
                    index += 1;
                }
                tempAlternatives.push(...filterAlternatives(this.alternativesMasterListForFilters, key, value));
            } else if (alternatives.length === 0) {
                alternatives.push(...filterAlternatives(tempAlternatives, key, value));
            } else {
                alternatives = filterAlternatives(alternatives, key, value);
            }
        });

        let result: ZoneAlternative[];

        // Determine the result based on the filterKeys and index values
        if (filterKeys.length > 0) {
            result = index === 0 ? tempAlternatives : alternatives;
        } else {
            result = this.alternativesMasterListForFilters;
        }

        this.matchingAlternatives = [...result];

        return result;
    }
    

    private setDominatingFilter(filter: string, filterValue: string[] = []): boolean {
        if (this.dominatingFilter === filter && filterValue.length === 0) {
            const filters = Array.from(this.filterDictionary.keys());
            const nextFilter = filters[filters.indexOf(filter) + 1];
    
            if (!nextFilter) {
                this.resetFilters();
            } else {
                this.filterDictionary.delete(filter);
                this.dominatingFilter = nextFilter;
            }
            return false;
        }
    
        if (this.dominatingFilter === '') {
            this.dominatingFilter = filter;
            return true;
        } else if (this.dominatingFilter === filter) {
            return true;
        }
    
        return false;
    }
    

    private unselectNonFilteredRows(rows: ZoneAlternative[]): ZoneAlternative[] {
        // Unselect rows not in the filter criteria
        this.alternatives.forEach(alternative => {
            if (rows.length > 0 && rows.filter(x => x.id == alternative.id).length == 0 && !alternative.selected) {
                const index = this.alternatives.indexOf(this.alternatives.filter(x => x.id == alternative.id)[0]);

                if (index > -1) {
                    this.alternatives.splice(index, 1); // Remove from selected set
                }
            }
        });

        this.alternativesMasterListForFilters.forEach(alternative => {
            if (rows.length > 0 && rows.filter(x => x.id == alternative.id).length == 0 && !alternative.selected) {
                alternative.selected = false;
            }
        });

        return [...rows];
    }

    private handleFilteredRows(rows: ZoneAlternative[]): ZoneAlternative[] {
        // Filter items from the master list where selected = true
        const selectedItems = this.alternativesMasterListForFilters.filter(item => item.selected);
    
        // Append selected items to rows only if they don't already exist in rows
        const updatedRows = [
            ...rows,
            ...selectedItems.filter(selectedItem =>
                !rows.some(row => row.id === selectedItem.id)
            )
        ];
    
        return updatedRows;
    }

    // Sort selected rows to the top
    private sortAlternatives(): void {
        this.alternatives.sort((a, b) => {
          const aSelected = this.selectedAlternatives.has(a.id.toString());
          const bSelected = this.selectedAlternatives.has(b.id.toString());
    
          if (aSelected && !bSelected) return -1;
          if (!aSelected && bSelected) return 1;
          
          // Use originalIndexMap for unselected rows
          return (this.originalIndexMap.get(a.id) ?? 0) - (this.originalIndexMap.get(b.id) ?? 0);
        });
    }

    // Sort the filters in ascending order
    private sortFilterValues(): void {
        const regex = /\d+/;

        this.deckGaugeOptionDdl.items.sort((a, b) => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
        });

        this.sidelapSpacingOptionDdl.items.sort((a, b) => {
            const parseTextToNumber = (text: string): number => {
                const match = regex.exec(text);
                return match ? parseInt(match[0], 10) : 0;
            };
        
            // Parse numbers for both `a` and `b`
            const numA = parseTextToNumber(a.text);
            const numB = parseTextToNumber(b.text);
        
            // Compare based on numbers
            return numA - numB;
        });
    }

    public resetFilters(): void {
        this.dominatingFilter = '';
        this.filterDictionary.clear();
        this.deckGaugeOptionDdl.selectedValues = [];
        this.patternOptionDdl.selectedValues = [];
        this.frameFastenerOptionDdl.selectedValues = [];
        this.sidelapConnectorOptionDdl.selectedValues = [];
        this.sidelapSpacingOptionDdl.selectedValues = [];
        this.alternatives = [...this.alternativesMasterListForFilters];

        this.setFilterOption();
    }

    protected abstract loadAlternativesFromBackend(): Promise<void>;

    protected abstract initLengthUnit(): void;

    ngOnDestroy(): void {
        this._spacingUnit$?.unsubscribe();
        this._bySpacing$?.unsubscribe();
        if(this.isSolutionsFilterEnabled) {
            this.resetFilters();
        }
    }
}
