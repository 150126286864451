import { DesignMethodConnectionTypeMethodGroupEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList, ICodeListC2CConstructor } from './code-list';

export interface IDesignMethodConnectionTypeMethodGroupC2CConstructor extends ICodeListC2CConstructor {
    designMethodId: number;
    connectionTypeId: number;
    designMethodGroupId: number;
    loadTypeId: number;
    correspondingChapterId?: number;
}

export class DesignMethodConnectionTypeMethodGroup extends CodeList {
    public designMethodId?: number;
    public connectionTypeId?: number;
    public designMethodGroupId?: number;
    public loadTypeId?: number;
    public correspondingChapterId?: number;

    constructor(codeList: IDesignMethodConnectionTypeMethodGroupC2CConstructor) {
        super(codeList);

        if (codeList != null) {
            this.designMethodId = codeList.designMethodId;
            this.connectionTypeId = codeList.connectionTypeId;
            this.designMethodGroupId = codeList.designMethodGroupId;
            this.loadTypeId = codeList.loadTypeId;
            this.correspondingChapterId = codeList.correspondingChapterId;
        }
    }

    public static fromService(codeList: DesignMethodConnectionTypeMethodGroupEntityC2C) {
        return new DesignMethodConnectionTypeMethodGroup(CodeList.baseFromService<IDesignMethodConnectionTypeMethodGroupC2CConstructor>('DesignMethodConnectionTypeMethodGroupEntity', codeList, {
            designMethodId: codeList.designMethodId,
            connectionTypeId: codeList.connectionTypeId,
            designMethodGroupId: codeList.designMethodGroupId,
            loadTypeId: codeList.loadTypeId,
            correspondingChapterId: codeList.correspondingChapterId
        }));
    }

    public override cloneDeep() {
        const codeList: DesignMethodConnectionTypeMethodGroup = <DesignMethodConnectionTypeMethodGroup>super.cloneDeep();
        codeList.designMethodId = this.designMethodId;
        codeList.connectionTypeId = this.connectionTypeId;
        codeList.designMethodGroupId = this.designMethodGroupId;
        codeList.loadTypeId = this.loadTypeId;
        codeList.correspondingChapterId = this.correspondingChapterId;

        return codeList;
    }
}