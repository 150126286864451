import { ChangeDetectorRef, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    AppSettingsNumericTextBoxProps, QuickStartType, SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import {
    format, formatKeyValue
} from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { IAppSettingsComponent } from '@profis-engineering/pe-ui-common/entities/app-settings-component';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    DesignMethodGroup as DesignMethodGroupEnum, DesignStandard as DesignStandardEnum, DesignType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    FactorType, IHandrailFixingData
} from '../../../shared/helpers/app-settings-helper';
import { AppSettingsHelperWithUnit } from '../../helpers/app-settings-helper';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    templateUrl: './app-settings-handrail-fixing.component.html',
    styleUrls: ['./app-settings-handrail-fixing.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AppSettingsHandrailFixingComponent implements IAppSettingsComponent<IHandrailFixingData>, OnChanges {
    @Input()
    public selectedRegionId!: number;

    @Input()
    public decimalSeparatorId!: number;

    @Input()
    public thousandsSeparatorId!: number;

    @Input()
    public submitted = false;

    @Input()
    public settings: IHandrailFixingData = {
        handrailRegion: KnownRegion.Unknown
    };

    public get handrailFixing() {
        return this.settings;
    }

    public handrailRegionDropdown!: DropdownProps<number>;
    public handrailLengthDropdown!: DropdownProps<number>;
    public handrailLengthLargeDropdown!: DropdownProps<number>;
    public handrailAreaDropdown!: DropdownProps<number>;
    public handrailStressDropdown!: DropdownProps<number>;
    public handrailStressSmallDropdown!: DropdownProps<number>;
    public handrailForceDropdown!: DropdownProps<number>;
    public handrailMomentDropdown!: DropdownProps<number>;
    public handrailTemperatureDropdown!: DropdownProps<number>;
    public handrailForcePerLengthDropdown!: DropdownProps<number>;
    public handrailAnchorPlateFactor!: AppSettingsNumericTextBoxProps;
    public handrailEdgeDistanceDisplay!: DropdownProps<number>;
    public handrailEtagEnOnly!: CheckboxButtonProps<boolean>;
    public handrailDesignStandardDropdown!: DropdownProps<number>;
    public handrailDesignMethodDropdown!: DropdownProps<number>;
    public handrailMinimumAnchorToProfileDistance!: AppSettingsNumericTextBoxProps;
    public handrailSteelSafetyFactorGammaM0!: AppSettingsNumericTextBoxProps;
    public handrailSteelSafetyFactorGammaM1!: AppSettingsNumericTextBoxProps;
    public handrailSteelSafetyFactorGammaM2!: AppSettingsNumericTextBoxProps;
    public handrailDisplacementLimit!: AppSettingsNumericTextBoxProps;
    public handrailDisplacementLimitBridges!: AppSettingsNumericTextBoxProps;

    private readonly appSettingsHelper!: AppSettingsHelperWithUnit;

    public dataLoaded = false;

    constructor(
        private readonly localization: LocalizationService,
        private readonly codeList: CodeListService,
        private readonly commonCodeList: CommonCodeListService,
        private readonly userSettings: UserSettingsService,
        private readonly unit: UnitService,
        private readonly numberService: NumberService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        sharedEnvironmentService: SharedEnvironmentService
    ) {
        this.appSettingsHelper = new AppSettingsHelperWithUnit(this.localization, this.userSettings, this.codeList, this.commonCodeList, this.unit, this.numberService, sharedEnvironmentService.data?.useDevFeatures ?? false);
    }

    public get allowHandrailStatus() {
        let translation = '';

        if (this.allowHandrailQuickStart) {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Handrail.Status.Ok');
            translation = format(translation, this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.None'));
        }
        else {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Handrail.Status.Error');
            const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);
            translation = formatKeyValue(translation, { region: region?.getTranslatedNameText(getCodeListTextDeps(this.localization, this.numberService)) ?? '' });
        }

        return translation;
    }

    public get getNumericTextBoxDecimalSeparator() {
        return this.appSettingsHelper.getNumericTextBoxDecimalSeparatorById(this.decimalSeparatorId);
    }

    public get getNumericTextBoxGroupSeparator() {
        return this.appSettingsHelper.getNumericTextBoxGroupSeparatorById(this.thousandsSeparatorId);
    }

    public get allowHandrailQuickStart() {
        const nonDefaultValue = this.handrailFixing?.handrailRegion < 0
            ? this.selectedRegionId
            : this.handrailFixing.handrailRegion;

        return this.appSettingsHelper.designTypeHandrail?.allowedDesignStandardRegions?.some((row) => row.RegionId == nonDefaultValue);
    }

    public get isHandrailNone() {
        return this.handrailFixing.handrailRegion != SpecialRegion.None;
    }

    public get areSafetyFactorsForHandrailVisible() {
        return this.handrailFixing.handrailDesignStandardId == DesignStandardEnum.EC2 || this.handrailFixing.handrailDesignStandardId == DesignStandardEnum.ETAG;
    }

    public get selectedHandrailDesignStandardDescription() {
        return this.appSettingsHelper.getDesignStandardDescription(this.localization, DesignType.Handrail, this.handrailDesignStandard);
    }

    public get selectedHandrailDesignMethodDescription() {
        return this.appSettingsHelper.getDesignMethodDescription(this.handrailDesignMethodGroup);
    }

    public get isHandrailEtagEnOnlyCheckboxAvailable() {
        return (this.handrailFixing.handrailDesignStandardId == DesignStandardEnum.EC2
            || this.handrailFixing.handrailDesignStandardId == DesignStandardEnum.ETAG)
            && this.handrailFixing.handrailDesignMethodGroupId != DesignMethodGroupEnum.SOFA_Based;
    }

    public get isDeflectionLimitsVisible() {
        return this.selectedHandrailRegion?.id == KnownRegion.Hungary;
    }

    private get selectedHandrailRegion() {
        return this.appSettingsHelper.getRegionById(this.handrailFixing.handrailRegion == SpecialRegion.Default ? this.selectedRegionId : this.handrailFixing.handrailRegion);
    }

    private get handrailDesignStandard() {
        return this.appSettingsHelper.getDesignStandardById(this.handrailFixing.handrailDesignStandardId);
    }

    private get handrailDesignMethodGroup() {
        return this.appSettingsHelper.getDesignMethodGroupById(this.handrailFixing.handrailDesignMethodGroupId);
    }

    ngOnChanges(): void {
        if (!this.dataLoaded) {
            this.createControlsGeneral();
            this.createControlsRegion();

            this.createControlsCalculation();

            this.updateQuickStartHandrail(
                this.handrailFixing.handrailRegion == SpecialRegion.Default
                    ? this.selectedRegionId ?? KnownRegion.Unknown
                    : this.handrailFixing.handrailRegion,
                false
            );

            this.dataLoaded = true;
        }
    }

    public onHandrailRegionDropdownChange(regionId: number) {
        this.handrailFixing.handrailRegion = regionId;
        this.updateQuickStartHandrail(regionId, true);
        this.changeDetectorRef.detectChanges();
    }

    public onHandrailDesignStandardChange(designStandardId?: number) {
        this.handrailFixing.handrailDesignStandardId = designStandardId;
        this.modifyHandrailDesignStandardDropdown();
        this.modifyHandrailDesignMethodDropdown();
        this.changeDetectorRef.detectChanges();
    }

    public onHandrailDesignMethodChange(designMethodGroupId: number) {
        this.handrailFixing.handrailDesignMethodGroupId = designMethodGroupId;
        this.changeDetectorRef.detectChanges();
    }

    public onHandrailEtagEnOnlyChange() {
        this.handrailFixing.handrailEtagEnOnly = this.isHandrailEtagEnOnlyCheckboxAvailable
            ? SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.handrailEtagEnOnly) ?? false
            : false;
        if (this.handrailFixing.handrailEtagEnOnly && this.handrailDesignMethodGroup?.id == DesignMethodGroupEnum.SOFA_Based) {
            // SOFA_Based can not be selected when Guideline only is selected
            // set default design method group
            const handrailRegion = this.appSettingsHelper.getRegionById(this.handrailFixing.handrailRegion < 0 ? this.selectedRegionId : this.handrailFixing.handrailRegion);
            this.appSettingsHelper.setDefaultHandrailDesignMethodGroup(
                this.handrailFixing,
                handrailRegion
            );
        }
        this.modifyHandrailDesignMethodDropdown();
        this.changeDetectorRef.detectChanges();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public getNumericTextBoxUnit(property: string) {
        const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);

        switch (property) {
            case 'defaultAnchorPlateThickness':
            case 'defaultMinimumAnchorToProfileDistance':
            case 'defaultMinimumConcreteCover':
                return region.defaultUnitLength;

            case 'handrailMinimumAnchorToProfileDistance':
                return this.handrailFixing.handrailLength;

            case 'handrailDisplacementLimit': {
                if (this.selectedHandrailRegion?.isDisplacementLimitAllowed
                    || this.handrailFixing.handrailRegion == SpecialRegion.None
                ) {
                    return Unit.None;
                }

                return this.handrailFixing.handrailLength;
            }

            default:
                return Unit.None;
        }
    }

    public getNumericTextBoxPlaceholder(property: string): number | string {
        const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);

        switch (property) {
            case 'defaultAnchorPlateThickness':
                return region.thickness;

            case 'defaultVariableLoads':
                return region.safetyFactorForVariableLoads;

            case 'defaultPermanentLoads':
                return region.safetyFactorForPermamentLoads;

            case 'defaultAnchorPlateFactor':
                return region.anchorPlateFactor;

            case 'handrailMinimumAnchorToProfileDistance':
                return this.appSettingsHelper.bindMinimumAnchorToProfileDistanceDefault(this.handrailFixing.handrailDesignStandardId, this.handrailFixing.handrailLength);

            case 'handrailAnchorPlateFactor':
                return this.appSettingsHelper.bindDefaultFactor(this.handrailFixing.handrailRegion, region, FactorType.AnchorPlateFactor, this.userSettings.settings);

            case 'handrailDisplacementLimit':
                return this.handrailDisplacementPlaceHolder() ?? '';

            case 'handrailDisplacementLimitBridges':
                return 200;

            default:
                return this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }
    }

    public isMinimumAnchorToProfileDistanceAvailable(designStandard?: DesignStandardEnum | number) {
        return this.appSettingsHelper.isMinimumAnchorToProfileDistanceAvailable(designStandard);
    }

    public handrailDisplacementPlaceHolder() {
        if (this.selectedHandrailRegion?.isDisplacementLimitAllowed) {
            this.handrailDisplacementLimit.prefix = 'L / ';
            return 150;
        }

        this.handrailDisplacementLimit.prefix = undefined;

        if (this.handrailFixing.handrailRegion == SpecialRegion.None) {
            return undefined;
        }

        return this.selectedHandrailRegion?.defaultHandrailDisplacement;
    }

    private getDisplacementLimitMax(isBridgesLimit?: boolean) {
        if (this.selectedHandrailRegion?.isDisplacementLimitAllowed || isBridgesLimit) {
            return 600;
        }

        return 1000;
    }

    private getDisplacementLimitMin(isBridgesLimit?: boolean) {
        if (this.selectedHandrailRegion?.isDisplacementLimitAllowed || isBridgesLimit) {
            return 10;
        }

        return 1;
    }

    private modifyHandrailDesignStandardDropdown(): void {
        const handrailDesignStandards = this.appSettingsHelper.getDesignStandardsByDesignType(this.appSettingsHelper.designTypeHandrail, this.handrailFixing.handrailRegion, this.selectedRegionId);

        this.handrailDesignStandardDropdown.items = handrailDesignStandards.map(designStandard => ({
            value: designStandard.id,
            text: this.appSettingsHelper.getDesignStandardTitle(this.localization, DesignType.Handrail, designStandard),
        }) as DropdownItem<number>);

        this.handrailDesignStandardDropdown.selectedValue = this.handrailFixing.handrailDesignStandardId;
    }

    private modifyHandrailDesignMethodDropdown(): void {
        const handrailRegion = this.appSettingsHelper.getRegionById(this.handrailFixing.handrailRegion == SpecialRegion.Default ? this.selectedRegionId : this.handrailFixing.handrailRegion);

        const designMethodGroups = this.appSettingsHelper.getDesignMethodGroupsByDesignType(
            this.appSettingsHelper.designTypeHandrail,
            this.handrailFixing.handrailDesignStandardId,
            this.handrailFixing.handrailRegion,
            this.selectedRegionId,
            SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.handrailEtagEnOnly) ?? false);

        if (!designMethodGroups.some(designMethodGroup => designMethodGroup.id == this.handrailFixing.handrailDesignMethodGroupId)) {
            this.appSettingsHelper.setDefaultHandrailDesignMethodGroup(
                this.handrailFixing,
                handrailRegion
            );
        }

        this.handrailDesignMethodDropdown.items = designMethodGroups.map((designMethodGroup) => ({
            value: designMethodGroup.id,
            text: this.appSettingsHelper.getDesignMethodGroupTitle(designMethodGroup),
        }) as DropdownItem<number>);

        this.handrailDesignMethodDropdown.selectedValue = this.handrailFixing.handrailDesignMethodGroupId;
    }

    private checkHandrailDisplacementLimitMinMax(value?: number, isBridgesLimit?: boolean) {
        if (value == undefined) {
            return value;
        }

        const limitMax = this.getDisplacementLimitMax(isBridgesLimit);
        const limitMin = this.getDisplacementLimitMin(isBridgesLimit);

        if (value > limitMax) {
            value = limitMax;
        }
        if (value < limitMin) {
            value = limitMin;
        }

        return value;
    }

    private updateQuickStartHandrail(regionId: number, regionChanged: boolean) {
        if (regionId != SpecialRegion.None || this.handrailLengthDropdown?.selectedValue == null) {
            regionId = regionId < 0
                ? (this.selectedRegionId ?? SpecialRegion.Default)
                : regionId;
            const region = this.appSettingsHelper.getRegionById(regionId);

            if (regionChanged) {
                this.appSettingsHelper.setDefaultHandrailUnits(this.handrailFixing, region);
                this.appSettingsHelper.setDefaultHandrailDesignStandard(this.handrailFixing, region);
                this.appSettingsHelper.setDefaultHandrailDesignMethodGroup(this.handrailFixing, region);
                this.onHandrailDesignStandardChange(this.handrailFixing.handrailDesignStandardId);
            }

            this.handrailDisplacementLimit.value = this.checkHandrailDisplacementLimitMinMax(this.handrailDisplacementLimit.value);
            this.handrailDisplacementLimit.minValue = this.getDisplacementLimitMin();
            this.handrailDisplacementLimit.maxValue = this.getDisplacementLimitMax();
            this.handrailDisplacementLimitBridges.value = this.checkHandrailDisplacementLimitMinMax(this.handrailDisplacementLimitBridges.value, true);
        }
    }

    private createControlsGeneral() {
        this.handrailMinimumAnchorToProfileDistance = this.appSettingsHelper.createMinimumAnchorToProfileDistanceComponent('-handrail');

        this.createControlsUnit();

        this.handrailEdgeDistanceDisplay = this.appSettingsHelper.createPeCodeListDropdown(ProjectCodeList.EdgeDistanceDisplayType, 'HandrailEdgeDistance');
        this.handrailEdgeDistanceDisplay.tooltip = ({
            title: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.HandrailEdgeDistance.Tooltip.Title'),
            content: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.HandrailEdgeDistance.Tooltip')
        });

        this.handrailAnchorPlateFactor = this.appSettingsHelper.createAnchorPlateFactorComponent('handrail-anchor-plate-factor', 'Handrail.AnchorPlateFactor');
        this.handrailAnchorPlateFactor.tooltip = {
            title: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip.Title'),
            content: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip')
        };
        this.handrailSteelSafetyFactorGammaM0 = this.appSettingsHelper.createGammaComponent(QuickStartType.Handrail, 'M0');
        this.handrailSteelSafetyFactorGammaM1 = this.appSettingsHelper.createGammaComponent(QuickStartType.Handrail, 'M1');
        this.handrailSteelSafetyFactorGammaM2 = this.appSettingsHelper.createGammaComponent(QuickStartType.Handrail, 'M2');

        this.handrailEtagEnOnly = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.handrailFixing.handrailEtagEnOnly,
            itemId: 'add-edit-design-handrail-etag-en-only',
            itemText: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.EtagEnOnly'),
            itemDescription: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.EtagEnOnly.Description'),
        });

        this.handrailDisplacementLimit = this.appSettingsHelper.createNumericTextBox(
            'handrail-displacement-limit',
            'DisplacementLimit',
            undefined,
            1,
            1000,
            false
        );

        this.handrailDisplacementLimit.infoPopupTooltip = this.localization.getString('Agito.Hilti.Profis3.DeflectionLimits.Tooltip');
        this.handrailDisplacementLimit.infoPopup = {
            content: {
                componentName: 'pe-deflection-limits'
            },
            options: {
                size: 'lg'
            }
        };

        this.handrailDisplacementLimitBridges = this.appSettingsHelper.createNumericTextBox(
            'handrail-displacement-limit-bridges',
            'DisplacementLimit.Bridges',
            undefined,
            10,
            600,
            false
        );
        this.handrailDisplacementLimitBridges.prefix = 'H / ';

        this.handrailDisplacementLimit.minValue = this.getDisplacementLimitMin();
        this.handrailDisplacementLimit.maxValue = this.getDisplacementLimitMax();

        this.handrailDisplacementLimitBridges.minValue = this.getDisplacementLimitMin(true);
        this.handrailDisplacementLimitBridges.maxValue = this.getDisplacementLimitMax(true);
    }

    private createControlsUnit() {
        this.handrailLengthDropdown = this.appSettingsHelper.createUnitDropdown('handrail-length', 'Handrail.Length', CommonCodeList.UnitLength);
        this.handrailLengthLargeDropdown = this.appSettingsHelper.createUnitDropdown('handrail-length-large', 'Handrail.LengthLarge', CommonCodeList.UnitLength);
        this.handrailAreaDropdown = this.appSettingsHelper.createUnitDropdown('handrail-area', 'Handrail.Area', CommonCodeList.UnitArea);
        this.handrailStressDropdown = this.appSettingsHelper.createUnitDropdown('handrail-stress', 'Handrail.Stress', CommonCodeList.UnitStress);
        this.handrailStressSmallDropdown = this.appSettingsHelper.createUnitDropdown('handrail-stress-small', 'Handrail.StressSmall', CommonCodeList.UnitStress);
        this.handrailForceDropdown = this.appSettingsHelper.createUnitDropdown('handrail-force', 'Handrail.Force', CommonCodeList.UnitForce);
        this.handrailMomentDropdown = this.appSettingsHelper.createUnitDropdown('handrail-moment', 'Handrail.Moment', CommonCodeList.UnitMoment);
        this.handrailTemperatureDropdown = this.appSettingsHelper.createUnitDropdown('handrail-temperature', 'Handrail.Temperature', CommonCodeList.UnitTemperature);
        this.handrailForcePerLengthDropdown = this.appSettingsHelper.createUnitDropdown('handrail-force-per-length', 'Handrail.ForcePerLength', CommonCodeList.UnitForcePerLength);

        const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);
        this.handrailFixing.handrailLength = this.appSettingsHelper.ensureUnitSupported(this.handrailLengthDropdown.items, this.handrailFixing.handrailLength, region.defaultUnitLength);
        this.handrailFixing.handrailLengthLarge = this.appSettingsHelper.ensureUnitSupported(this.handrailLengthLargeDropdown.items, this.handrailFixing.handrailLengthLarge, region.defaultUnitLengthLarge);
        this.handrailFixing.handrailArea = this.appSettingsHelper.ensureUnitSupported(this.handrailAreaDropdown.items, this.handrailFixing.handrailArea, region.defaultUnitArea);
        this.handrailFixing.handrailStress = this.appSettingsHelper.ensureUnitSupported(this.handrailStressDropdown.items, this.handrailFixing.handrailStress, region.defaultUnitStress);
        this.handrailFixing.handrailStressSmall = this.appSettingsHelper.ensureUnitSupported(this.handrailStressSmallDropdown.items, this.handrailFixing.handrailStressSmall, region.defaultUnitStressSmall);
        this.handrailFixing.handrailForce = this.appSettingsHelper.ensureUnitSupported(this.handrailForceDropdown.items, this.handrailFixing.handrailForce, region.defaultUnitForce);
        this.handrailFixing.handrailMoment = this.appSettingsHelper.ensureUnitSupported(this.handrailMomentDropdown.items, this.handrailFixing.handrailMoment, region.defaultUnitMoment);
        this.handrailFixing.handrailTemperature = this.appSettingsHelper.ensureUnitSupported(this.handrailTemperatureDropdown.items, this.handrailFixing.handrailTemperature, region.defaultUnitTemperature);
        this.handrailFixing.handrailForcePerLength = this.appSettingsHelper.ensureUnitSupported(this.handrailForcePerLengthDropdown.items, this.handrailFixing.handrailForcePerLength, region.defaultUnitForcePerLength);

        this.appSettingsHelper.setUnitDropdownValue(this.handrailLengthDropdown, this.handrailFixing.handrailLength);
        this.appSettingsHelper.setUnitDropdownValue(this.handrailLengthLargeDropdown, this.handrailFixing.handrailLengthLarge);
        this.appSettingsHelper.setUnitDropdownValue(this.handrailAreaDropdown, this.handrailFixing.handrailArea);
        this.appSettingsHelper.setUnitDropdownValue(this.handrailStressDropdown, this.handrailFixing.handrailStress);
        this.appSettingsHelper.setUnitDropdownValue(this.handrailStressSmallDropdown, this.handrailFixing.handrailStressSmall);
        this.appSettingsHelper.setUnitDropdownValue(this.handrailForceDropdown, this.handrailFixing.handrailForce);
        this.appSettingsHelper.setUnitDropdownValue(this.handrailMomentDropdown, this.handrailFixing.handrailMoment);
        this.appSettingsHelper.setUnitDropdownValue(this.handrailTemperatureDropdown, this.handrailFixing.handrailTemperature);
        this.appSettingsHelper.setUnitDropdownValue(this.handrailForcePerLengthDropdown, this.handrailFixing.handrailForcePerLength);
    }

    private createControlsRegion() {
        this.handrailRegionDropdown = this.appSettingsHelper.createDropdownComponent(
            'handrail-region',
            'Handrail.Region',
            this.appSettingsHelper.getAvailableRegions(this.appSettingsHelper.designTypeHandrail)
                .map(region => ({
                    value: region.id,
                    text: this.localization.getString(region.nameResourceKey)
                }) as DropdownItem<number>)
        );
    }

    private createControlsCalculation() {
        // Handrail design standard
        this.handrailDesignStandardDropdown = this.appSettingsHelper.createDropdownComponent<number>(
            'handrail-design-standard-dropdown', 'Handrail.DesignStandard', []);
        this.modifyHandrailDesignStandardDropdown();

        // Handrail design method
        this.handrailDesignMethodDropdown = this.appSettingsHelper.createDropdownComponent<number>(
            'handrail-design-method-group-dropdown', 'Handrail.DesignMethod', []);
        this.modifyHandrailDesignMethodDropdown();
    }
}
