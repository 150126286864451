import { Injectable, OnDestroy } from '@angular/core';
import { smartAnchorDefaultScopeChecks } from '../helpers/smart-anchor-helper';
import { ScopeCheckResultItem } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import { ScopeCheckFlags } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import { UserService } from './user.service';
import { UIProperty } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SmartDesignSearchSolutionService implements OnDestroy {
    private isOptimizingEnabled = false;
    private optimizing = false;

    private readonly moduleSmartDesignOptimize = new Subject<boolean>();
    private readonly moduleSmartDesignOptimizeObservable = this.moduleSmartDesignOptimize.asObservable();

    public get smartDesignOptimize(): Observable<boolean> {
        return this.moduleSmartDesignOptimizeObservable;
    }

    constructor(
        private readonly userService: UserService,
    ) {
    }

    ngOnDestroy(): void {
        this.moduleSmartDesignOptimize.complete();
    }

    private get design() {
        return this.userService.design;
    }

    public get SearchSolutionButtonHidden() {
        return this.design?.model[UIProperty.SmartAnchor_Enabled] !== true;
    }

    public get IsLoading(): boolean {
        return this.design?.loading;
    }

    public optimize() {
        this.moduleSmartDesignOptimize.next(true);
    }

    public optimizeDone() {
        this.moduleSmartDesignOptimize.next(false);
    }

    public get IsOptimizingEnabled(): boolean {
        return this.isOptimizingEnabled;
    }

    public set IsOptimizingEnabled(value: boolean) {
        this.isOptimizingEnabled = value;
    }

    public get IsOptimizingDisabled(): boolean {
        return this.optimizing || !this.isAsadCalculable || this.IsLoading;
    }

    public get isAsadCalculable() {
        return this.design?.model[UIProperty.SmartAnchor_Application] != null && this.isValidLoad && this.anySmartAnchorSelected;
    }

    public get Optimizing(): boolean {
        return this.optimizing;
    }

    public set Optimizing(value: boolean) {
        this.optimizing = value;
    }

    private get isValidLoad(): boolean {
        return !this.alertScopeChecks.some(sc => smartAnchorDefaultScopeChecks().includes(sc.ScopeCheckId));
    }

    public get alertScopeChecks(): ScopeCheckResultItem[] {
        return this.design?.designData.reportData?.ScopeCheckResultItems?.filter(sc => this.isScopeCheckAlert(sc)) ?? [];
    }

    private isScopeCheckAlert(scopeCheck: ScopeCheckResultItem) {
        return scopeCheck.IndicatesCalculationError || (scopeCheck.SpecialFlags & ScopeCheckFlags.DisplayAsCalculationError);
    }

    private get anySmartAnchorSelected() {
        if (this.design.smartAnchorUserChosenVisible) {
            return this.design.smartAnchorHiltiRecommendedAnchors?.length > 0;
        } else {
            return !!this.design.smartAnchorUserSelected;
        }
    }
}
