import { CodeList } from './code-list';
import { LoadingDefinitionTypeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class LoadingDefinitionType extends CodeList {

    public static fromService(codeList: LoadingDefinitionTypeEntityC2C) {
        return new LoadingDefinitionType(CodeList.baseFromService('LoadingDefinitionTypeEntityC2C', codeList));
    }

    public override cloneDeep(): LoadingDefinitionType {
        return super.cloneDeep() as LoadingDefinitionType;
    }
}
