import { CodeList } from './code-list';
import { SurfaceTreatmentEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class SurfaceTreatment extends CodeList {

    public static fromService(codeList: SurfaceTreatmentEntityC2C) {
        return new SurfaceTreatment(CodeList.baseFromService('SurfaceTreatment', codeList));
    }

    public override cloneDeep(): SurfaceTreatment {
        return super.cloneDeep() as SurfaceTreatment;
    }
}
