export class DefaultTooltip {
  showTranslation() {
    // not needed
  }
  hideTranslation() {
    // not needed
  }
  showText() {
    // not needed
  }
  hideText() {
    // not needed
  }
  getTranslation(key) {
    return key;
  }
}
