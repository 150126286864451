export class DateWithTimezone {
  timestamp;
  /*
  * Negative if timezone is before UTC and positive if it is ahead (-date.getTimezoneOffset())
  */
  timezoneOffset;
}
export function getJSONDateWithTimezoneOffset() {
  const timestamp = new Date();
  const date = new DateWithTimezone();
  date.timestamp = timestamp.toISOString();
  // The timezone offset. Value should be correctly assigned ( negative if timezone is before UTC and positive if it is ahead )
  date.timezoneOffset = -timestamp.getTimezoneOffset();
  return date;
}
