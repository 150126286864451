import { ShearInterfaceTypeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class InterfaceShearType extends CodeList {

    public static fromService(codeList: ShearInterfaceTypeEntityC2C) {
        return new InterfaceShearType(CodeList.baseFromService('ShearInterfaceTypeEntityC2C', codeList));
    }

    public override cloneDeep(): InterfaceShearType {
        return super.cloneDeep() as InterfaceShearType;
    }
}
