import {
    OptimizationTypeEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class OptimizationType extends CodeList {

    public static fromService(codeList: OptimizationTypeEntityC2C) {
        return new OptimizationType(CodeList.baseFromService('OptimizationTypeEntity', codeList));
    }

    public override cloneDeep(): OptimizationType {
        return super.cloneDeep() as OptimizationType;
    }
}
