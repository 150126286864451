<pe-main-header class="main-header"
    [designName]="title"
    [designRegion]="commonRegion"
    [beforeLogout]="beforeLogout"
    [openDesignSettings]="openDesignSettings"
    [openSaveAsTemplate]="openSaveAsTemplate"
    [startTour]="startTour"
    [openGeneralNotes]="openGeneralNotes"
    (menuOpened)="menuOpened()"
    (hiltiDataPrivacyUrlOpened)="hiltiDataPrivacyUrlOpened()"
    (regionLinkOpened)="regionLinkOpened()">
</pe-main-header>

<div class="main-content">
    <div class="main-content-left" [class.width-extended]="hasExtendedWidth"
        [class.main-content-left-hidden]="hideLeftMenu">
        <div id="main-content-left-container" class="main-content-left-container">
            <div class="main-content-left-black-bar"></div>
            @if (selectedMenu != null) {
                <pe-main-menu #mainMenuRef
                    class="menu-container"
                    [disableDragToMoveText]="false"
                    [tabsHidden]="hideLeftMenu"
                    [moduleLocalizationService]="localizationService">
                </pe-main-menu>
            }
        </div>
    </div>

    <div class="main-content-center-right" #mainContentCenterRightRef>
        <div class="main-content-center-right-top" #mainContentCenterRightTopRef>
            <div class="main-content-center">
                <pe-button id="hide-left-menu" class="hide-menu-button hide-menu-button-left" [look]="'DefaultIcon'"
                    (buttonClick)="toggleLeftMenu()"
                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.CollapseMenu' | l10n: selectedLanguage">
                    <span class="pe-ui-sp-sprite hide-arrow-sprite"
                        [class.pe-ui-sp-sprite-arrow-left-medium]="!hideLeftMenu"
                        [class.pe-ui-sp-sprite-arrow-right-medium]="hideLeftMenu"></span>
                </pe-button>

                <pe-button id="hide-right-menu" class="hide-menu-button hide-menu-button-right" [look]="'DefaultIcon'"
                    (buttonClick)="toggleRightMenu()"
                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.CollapseMenu' | l10n: selectedLanguage">
                    <span class="pe-ui-sp-sprite hide-arrow-sprite"
                        [class.pe-ui-sp-sprite-arrow-right-medium]="!hideRightMenu"
                        [class.pe-ui-sp-sprite-arrow-left-medium]="hideRightMenu"></span>
                </pe-button>

                <div class="main-content-position">
                    <div class="model-view-options click-through">
                        <div class="options-left">
                            <div class="button-group undo-redo-section click-reset" role="group">
                                <div class="buttons join">
                                    <div class="tooltip-wrapper" [ngbTooltip]="undoTooltip" placement="auto top">
                                        <pe-button id="main-undo-button" [look]="'DefaultIcon'" (buttonClick)="undo()"
                                            [disabled]="!canUndo()">
                                            <span class="pe-ui-sp-sprite pe-ui-sp-sprite-undo"></span>
                                        </pe-button>
                                    </div>
                                    <div class="tooltip-wrapper" [ngbTooltip]="redoTooltip" placement="auto top">
                                        <pe-button id="main-redo-button" [look]="'DefaultIcon'" (buttonClick)="redo()"
                                            [disabled]="!canRedo()">
                                            <span class="pe-ui-sp-sprite pe-ui-sp-sprite-redo"></span>
                                        </pe-button>
                                    </div>
                                </div>
                            </div>

                            <div class="button-group click-reset" role="group">
                                <pe-numeric-text-box [value]="modelViewZoom"
                                    (valueChange)="zoomPercentageChange($any($event).detail)" [unit]="UnitPercent"
                                    [minValue]="0" [maxValue]="100" [updateOnBlur]="true" [required]="true" [width]="80"
                                    [height]="32">
                                </pe-numeric-text-box>
                            </div>

                            <div class="button-group click-reset" role="group">
                                <pe-button id="main-zoom-to-fit-button" [look]="'DefaultIcon'"
                                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.ZoomToFit' | l10n: selectedLanguage"
                                    (buttonClick)="zoomToFit()">
                                    <span class="pe-ui-sp-sprite pe-ui-sp-sprite-search"></span>
                                </pe-button>
                            </div>

                            <div class="button-group click-reset" role="group">
                                <pe-button id="main-reset-camera-button" [look]="'DefaultIcon'"
                                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.ResetCamera' | l10n: selectedLanguage"
                                    (buttonClick)="resetCamera()">
                                    <span class="pe-ui-sp-sprite pe-ui-sp-sprite-center"></span>
                                </pe-button>
                            </div>
                            <div class="button-group click-reset" role="group" ngbDropdown [autoClose]="false"
                                closeOutsideNgbDropdown>
                                <pe-button id="main-model-display-options-button" ngbDropdownToggle
                                    [look]="'DefaultIcon'"
                                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.ShowHideElements' | l10n: selectedLanguage">
                                    <span class="pe-ui-sp-sprite pe-ui-sp-sprite-view"></span>
                                </pe-button>
                                <div id="display-options-dropdown" ngbDropdownMenu>
                                    <pe-checkbox-button [items]="displayOptionsCheckbox.items"
                                        [selectedValues]="displayOptionsCheckbox.selectedValues"
                                        (itemToggle)="displayOptionsCheckboxItemToggle($any($event).detail)">
                                    </pe-checkbox-button>
                                </div>
                            </div>
                        </div>

                        <div class="options-left">
                            <pe-loading class="update-pending" [class.show]="updatePending"
                                [color]="'#524f53'"></pe-loading>
                        </div>
                    </div>

                    <div class="model-view-container">
                        @if (glModel != null) {
                            <app-gl-model #glModelRef [continuousRender]="glModel.continuousRender"
                                [model]="glModel.model" [designDetails]="designDetails"
                                (fontsLoaded)="glModel.onFontsLoaded()" (zoom)="glModel.onZoom($event)"
                                (selectTab)="glModel.onSelectTab($event)"
                                (propertyChange)="glModel.propertyChange($event)">
                            </app-gl-model>
                        }
                    </div>
                </div>
            </div>
        </div>

        <!-- LOADS -->
         @if (loadsVisible()) {
            <ng-container>
                @if (designDetails.designTypeId == strengthDesignTypeId) {
                    <app-loads-strength
                        class="loads-container-wrapper"
                        [resize3d]="resize3d"
                        [unitForce]="strengthDesignDetails.properties.unitForce"
                        [numberOfZones]="strengthDesignDetails.properties.zonesNumberId"
                        [loadCombinations]="loadCombinations"
                        (loadCombinationsChange)="onLoadCombinationsChanged($event)">
                    </app-loads-strength>
                }

                @if (designDetails.designTypeId == punchDesignTypeId) {
                    <app-loads-punch
                        class="loads-container-wrapper"
                        [resize3d]="resize3d"
                        [unitForce]="punchDesignDetails.properties.unitForce"
                        [unitMoment]="punchDesignDetails.properties.unitMoment"
                        [betaId]="punchDesignDetails.properties.betaId"
                        [loadCombinations]="punchLoadCombinations"
                        (loadCombinationsChange)="onLoadCombinationsChanged($event)">
                    </app-loads-punch>
                }
            </ng-container>
        }
    </div>

    @if (!hideRightMenu) {
        <div class="main-content-right-sidebar">
            @if (rightSideLoaded) {
                <div class="main-content-right">
                    <div class="main-content-right-container">
                        <div class="main-content-right-scroll">
                            <div class="design-section-wrapper">
                                <app-design-section #designSectionRef
                                    [designDetails]="designDetails"
                                    [trackingDetails]="trackingDetails"
                                    [createScreenshot3D]="createScreenshot3D"
                                    [propertyChange]="propertyChange">
                                </app-design-section>
                            </div>

                            <div class="main-content-right-util" #mainContentRightUtilRef [nxtSortablejs]="" [config]="sortableMenu3DRightOptions" (init)="sortMenu3DRight($event)">
                                <div>
                                    @if (designDetails.designTypeId == strengthDesignTypeId) {
                                        <app-zone-utilization-panel
                                            [hidden]="!isCalculationValid"
                                            [utilizations]="this.strengthDesignDetails.calculateResult?.utilizationResults"
                                            [design]="this.design"
                                            [designDetails]="strengthDesignDetails">
                                        </app-zone-utilization-panel>
                                    }

                                    @if (designDetails.designTypeId == punchDesignTypeId) {
                                        <app-utilization-panel
                                            componentId="punch-utilization-panel"
                                            [hidden]="!isCalculationValid"
                                            [utilizations]="this.punchDesignDetails.calculateResult?.utilizationResults">
                                        </app-utilization-panel>
                                    }
                                </div>
                            </div>

                            <div [attr.data-id]="CollapsingControls.NotificationsDesign">
                                <app-notifications
                                    [isVisible]="notificationComponentInputs.isVisible()"
                                    [inputs]="notificationComponentInputs">
                                </app-notifications>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    }
</div>
