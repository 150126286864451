import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { ApprovalsService } from '../../services/approval.service';
import { DataService } from '../../services/data.service';
import { DesignService, GenericUtilizationResults, PunchDesignDetails, UtilizationTypeNumber } from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { L10nPipe } from '../../pipes/l10n.pipe';

interface IInputElement {
    value: string;
    label: string;
}

const noImageAvailable = 'sprite-product-dialog-no-photo-available';

const PunchUtilization = 'PunchUtilization';
const StrengthenedMember = 'StrengthenedMember';
const DrillLength = 'DrillLength';

@Component({
    selector: 'app-export-report-summary-punch',
    templateUrl: './export-report-summary-punch.component.html',
    styleUrls: ['./export-report-summary-punch.component.scss'],
    imports: [L10nPipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ExportReportSummaryPunchComponent implements OnInit {
    @Input()
    public collapsed = false;

    @Input()
    public designDetails!: PunchDesignDetails;

    @Input()
    public modelImage = '';

    public baseMaterial!: IInputElement;
    public strengthElementType!: IInputElement;
    public strengthElementSize!: IInputElement;
    public approval!: IInputElement;
    public proof!: string;
    public drillLength!: string;
    public strengtheningElementImage!: string;
    public approvalIssuedValid!: string;

    constructor(
        public localizationService: LocalizationService,
        public approvalsService: ApprovalsService,
        public unitService: UnitService,
        public dataService: DataService,
        public designService: DesignService
    ) {}

    public ngOnInit(): void {
        this.initSummarySection();
    }

    private initSummarySection() {
        // TODO determine actual fields and add actual values (from nola DB) once available
        this.strengthElementType = {
            value: this.getFastenerFamilyRegionalName(this.designDetails.properties.fastenerFamilyId, this.designDetails.regionId),
            label: this.localizationService.getString('SP.ExportReport.Summary.StrengtheningElementType')
        };
        this.strengthElementSize = {
            value: this.dataService.fastenersById[this.designDetails.properties.fastenerId].name,
            label: this.localizationService.getString('SP.ExportReport.Summary.StrengtheningElementSize')
        };

        this.baseMaterial = {
            value: this.dataService.getItemName(this.dataService.baseMaterialsById[this.designDetails.properties.baseMaterialId]),
            label: this.localizationService.getString('SP.ExportReport.Summary.BaseMaterial')
        };

        this.approval = {
            value: this.approvalsService.getApprovalNumber(this.designDetails.properties.approval),
            label: this.localizationService.getString('SP.Approval.Title')
        };
        this.approvalIssuedValid = this.getApprovalIssuedValid();

        this.proof = this.getDesignMethod();

        this.drillLength = this.getDrillLength(this.designDetails.calculateResult!.utilizationResults) ?? '-';

        this.setStrengtheningElementPhoto(this.designDetails.properties.fastenerFamilyGroupId);
    }
    private getDesignMethod(): string {
        const designMethodName = this.designDetails.properties.designMethodName;
        const approvalName = this.localizationService.getString(this.dataService.postInstalledReinforcementDesignsById[this.designDetails.properties.postInstalledReinforcementDesignId].nameKey);

        return (this.designService.isCalculationValid(this.designDetails.calculateResult) && designMethodName && approvalName)
            ? `${designMethodName} + ${approvalName}`
            : this.localizationService.getString('SP.DesignMethod.None');
    }

    public getFastenerFamilyRegionalName(fastenerFamilyId: number, regionId: number): string {
        const fastenerFamily = this.dataService.fastenerFamiliesById[fastenerFamilyId];

        return fastenerFamily.regionalNames?.[regionId] ?? fastenerFamily.name;
    }

    public setStrengtheningElementPhoto(fastenerFamilyGroupId: number) {
        const fastenerFamilyGroup = this.dataService.fastenerFamilyGroupsById[fastenerFamilyGroupId];

        if (!fastenerFamilyGroup?.image) {
            this.strengtheningElementImage = noImageAvailable;
        }
        else {
            this.strengtheningElementImage = 'sprite-product-dialog-' + fastenerFamilyGroup.image;
        }
    }

    public getDrillLength(utilizationResult: GenericUtilizationResults | undefined): string | null {
        if (utilizationResult == null) {
            return null;
        }

        const punchUtilization = utilizationResult.sections.find(x => x.id === PunchUtilization);

        if (punchUtilization == null) {
            return null;
        }

        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Length);
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Length);
        const defaultPrecision = this.unitService.getPrecision(defaultUnit);

        const strengthenedMemberSubsection = punchUtilization.subsections.find(x => x.id === StrengthenedMember);

        if (strengthenedMemberSubsection == null) {
            return null;
        }

        const drillLengthItem = strengthenedMemberSubsection.generalUtilizationItems.find(x => x.id === DrillLength) as UtilizationTypeNumber;

        if (drillLengthItem == null) {
            return null;
        }

        const drillLength = drillLengthItem.propertyValue;

        if (drillLength == null) {
            return null;
        }

        const valueToFormat = this.unitService.convertUnitValueArgsToUnit(drillLength, internalUnit, defaultUnit, true);
        return this.unitService.formatNumber(valueToFormat, defaultPrecision) + ' ' + this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Length));
    }

    public getApprovalIssuedValidTitle(): string {
        const approvalIssuedDateFormatted = this.approvalsService.getApprovalIssuedDateFormatted(this.designDetails.properties.approval);
        const approvalValidDateFormatted = this.approvalsService.getApprovalValidDateFormatted(this.designDetails.properties.approval);

        const issuedTranslation = this.localizationService.getString('SP.Approval.Issued');
        const validTranslation = this.localizationService.getString('SP.Approval.Valid');

        const issuedTitle = approvalIssuedDateFormatted ? issuedTranslation : '';
        const validTitle = approvalValidDateFormatted ? validTranslation : '';

        const combinedTranslation = [issuedTitle, validTitle].filter(Boolean).join(' - ');
        return combinedTranslation || `${issuedTranslation} - ${validTranslation}`;
    }

    public getApprovalIssuedValid(): string {
        const approvalIssuedDateFormatted = this.approvalsService.getApprovalIssuedDateFormatted(this.designDetails.properties.approval);
        const approvalValidDateFormatted = this.approvalsService.getApprovalValidDateFormatted(this.designDetails.properties.approval);

        const combinedIssuedValid = [approvalIssuedDateFormatted, approvalValidDateFormatted].filter(x => x != null && x != '').join(' - ');

        return combinedIssuedValid != null && combinedIssuedValid != '' ? combinedIssuedValid : '-';
    }
}
