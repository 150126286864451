import { Injectable, NgZone } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ZoneService {
    constructor(
        private ngZone: NgZone
    ) {}

    public runInZoneIfNeeded<T>(fn: () => T) {
        return NgZone.isInAngularZone()
            ? fn()
            : this.ngZone.run(fn);
    }
}
