@if (dataLoaded) {
    <div class="loads-row loads-row-first">

        <!-- Active -->
        <div class="column-selected">
            <pe-radio-button id="LC-table-select-combination-radio-button-{{loadIndex != null ? loadIndex : 'new-load'}}"
                class="selected-container selected-radio-button"
                [items]="selectedLoadItems" [selectedValue]="selectedLoadItemIndex">
            </pe-radio-button>
        </div>

        <!-- Rank -->
        <div class="column-rank">
            <div class="name-container" [ngbTooltip]="load.loadCombinationName">
                <div class="text">{{ loadIndex != null ? loadIndex + 1 : 0 }}</div>
            </div>
        </div>

        <!-- Name -->
        <div class="column-name bordered-column">
            <div id="LC-table-combination-name-textbox-{{loadIndex != null ? loadIndex : 'new-load'}}"
                class="name-container" [ngbTooltip]="load.loadCombinationName != null ? load.loadCombinationName : ''">
                <pe-text-box
                    id="{{loadIndex != null ? 'LC-table-combination-name-textbox-' + loadIndex : 'LC-table-add-new-combination-name-textbox'}}"
                    class="input name-input clip" [borderTop]="'None'" [borderBottom]="'None'" [borderLeft]="'None'"
                    [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay" [value]="load.loadCombinationName"
                    (inputBlur)="loadNameChanged($any($event).detail)" [updateOnBlur]="true">
                </pe-text-box>
            </div>
        </div>

        <!-- Load type -->
        <div class="column-type" [style.flex-basis.px]="helper.columnTypeWidth">
            <pe-toggle-button-group class="type-buttons" [model]="load.loadTypeId"
                (modelChange)="loadActiveLoadTypeChange($any($event).detail)" [items]="loadTypeToggleItems">
            </pe-toggle-button-group>
        </div>

        <!-- Zones -->
         @if (helper.columnVisibilities.zone1) {
            <div class="column-zone column-zone-1"
                [style.flex]="helper.zoneOneFlex">
                <div class="general-number">
                    <div class="number-container">
                        <pe-numeric-text-box
                            id="{{loadIndex != null ? 'LC-table-zone-1-' + loadIndex : 'LC-table-add-new-combination-zone-1-textbox'}}"
                            class="input zone-1-input number-input clip" [borderTop]="'None'" [borderBottom]="'None'"
                            [borderLeft]="'None'" [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay"
                            [value]="load.zoneLoads[0].load" (valueChange)="zoneLoadsValueChange(0, $any($event).detail)"
                            [showStepper]="false" [unit]="helper.unitForce" [precision]="helper.getPrecision(helper.unitForce)"
                            [appendUnit]="false" [updateOnBlur]="true" [required]="true">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        }

        @if (helper.columnVisibilities.zone2) {
            <div class="column-zone column-zone-2">
                <div class="general-number">
                    <div class="number-container">
                        <pe-numeric-text-box
                            id="{{loadIndex != null ? 'LC-table-zone-2-' + loadIndex : 'LC-table-add-new-combination-zone-2-textbox'}}"
                            class="input zone-2-input number-input clip" [borderTop]="'None'" [borderBottom]="'None'"
                            [borderLeft]="'None'" [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay"
                            [value]="load.zoneLoads[1].load" (valueChange)="zoneLoadsValueChange(1, $any($event).detail)"
                            [showStepper]="false" [unit]="helper.unitForce" [precision]="helper.getPrecision(helper.unitForce)"
                            [appendUnit]="false" [updateOnBlur]="true" [required]="true">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        }

        @if (helper.columnVisibilities.zone3) {
            <div class="column-zone column-zone-3">
                <div class="general-number">
                    <div class="number-container">
                        <pe-numeric-text-box
                            id="{{loadIndex != null ? 'LC-table-zone-3-' + loadIndex : 'LC-table-add-new-combination-zone-3-textbox'}}"
                            class="input zone-3-input number-input clip" [borderTop]="'None'" [borderBottom]="'None'"
                            [borderLeft]="'None'" [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay"
                            [value]="load.zoneLoads[2].load" (valueChange)="zoneLoadsValueChange(2, $any($event).detail)"
                            [showStepper]="false" [unit]="helper.unitForce" [precision]="helper.getPrecision(helper.unitForce)"
                            [appendUnit]="false" [updateOnBlur]="true" [required]="true">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        }

        @if (helper.columnVisibilities.zone4) {
            <div class="column-zone column-zone-4">
                <div class="general-number">
                    <div class="number-container">
                        <pe-numeric-text-box
                            id="{{loadIndex != null ? 'LC-table-zone-4-' + loadIndex : 'LC-table-add-new-combination-zone-4-textbox'}}"
                            class="input zone-4-input number-input clip" [borderTop]="'None'" [borderBottom]="'None'"
                            [borderLeft]="'None'" [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay"
                            [value]="load.zoneLoads[3].load" (valueChange)="zoneLoadsValueChange(3, $any($event).detail)"
                            [showStepper]="false" [unit]="helper.unitForce" [precision]="helper.getPrecision(helper.unitForce)"
                            [appendUnit]="false" [updateOnBlur]="true" [required]="true">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        }

        @if (helper.columnVisibilities.zone5) {
            <div class="column-zone column-zone-5"
                [style.flex]="helper.zoneOneFlex">
                <div class="general-number">
                    <div class="number-container">
                        <pe-numeric-text-box
                            id="{{loadIndex != null ? 'LC-table-zone-5-' + loadIndex : 'LC-table-add-new-combination-zone-5-textbox'}}"
                            class="input zone-5-input number-input clip" [borderTop]="'None'" [borderBottom]="'None'"
                            [borderLeft]="'None'" [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay"
                            [value]="load.zoneLoads[4].load" (valueChange)="zoneLoadsValueChange(4, $any($event).detail)"
                            [showStepper]="false" [unit]="helper.unitForce" [precision]="helper.getPrecision(helper.unitForce)"
                            [appendUnit]="false" [updateOnBlur]="true" [required]="true">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        }

        @if (helper.columnVisibilities.zone6) {
            <div class="column-zone column-zone-6">
                <div class="general-number">
                    <div class="number-container">
                        <pe-numeric-text-box
                            id="{{loadIndex != null ? 'LC-table-zone-6-' + loadIndex : 'LC-table-add-new-combination-zone-6-textbox'}}"
                            class="input zone-6-input number-input clip" [borderTop]="'None'" [borderBottom]="'None'"
                            [borderLeft]="'None'" [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay"
                            [value]="load.zoneLoads[5].load" (valueChange)="zoneLoadsValueChange(5, $any($event).detail)"
                            [showStepper]="false" [unit]="helper.unitForce" [precision]="helper.getPrecision(helper.unitForce)"
                            [appendUnit]="false" [updateOnBlur]="true" [required]="true">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        }

        @if (helper.columnVisibilities.zone7) {
            <div class="column-zone column-zone-7">
                <div class="general-number">
                    <div class="number-container">
                        <pe-numeric-text-box
                            id="{{loadIndex != null ? 'LC-table-zone-7-' + loadIndex : 'LC-table-add-new-combination-zone-7-textbox'}}"
                            class="input zone-7-input number-input clip" [borderTop]="'None'" [borderBottom]="'None'"
                            [borderLeft]="'None'" [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay"
                            [value]="load.zoneLoads[6].load" (valueChange)="zoneLoadsValueChange(6, $any($event).detail)"
                            [showStepper]="false" [unit]="helper.unitForce" [precision]="helper.getPrecision(helper.unitForce)"
                            [appendUnit]="false" [updateOnBlur]="true" [required]="true">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        }

        @if (helper.columnVisibilities.zone8) {
            <div class="column-zone column-zone-8">
                <div class="general-number">
                    <div class="number-container">
                        <pe-numeric-text-box
                            id="{{loadIndex != null ? 'LC-table-zone-8-' + loadIndex : 'LC-table-add-new-combination-zone-8-textbox'}}"
                            class="input zone-8-input number-input clip" [borderTop]="'None'" [borderBottom]="'None'"
                            [borderLeft]="'None'" [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay"
                            [value]="load.zoneLoads[7].load" (valueChange)="zoneLoadsValueChange(7, $any($event).detail)"
                            [showStepper]="false" [unit]="helper.unitForce" [precision]="helper.getPrecision(helper.unitForce)"
                            [appendUnit]="false" [updateOnBlur]="true" [required]="true">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        }

        <div class="column-messages"></div>

        <div class="column-delete"></div>

    </div>
}
