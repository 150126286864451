<div class="interface-shear-calculation content-group">
    <div class="content fixed-inputs">
        <div class="content-controls">
            @if (isSIADesignStandard(this.designStandardId)) {
                <div class="control-interfaceShearCalculation">
                    <pe-numeric-text-box [id]="KcTextBox.id" [title]="KcTextBox.title"
                        [placeholder]="KcTextBox.placeholder" [value]="Kc"
                        (valueChange)="KcChange.emit(Kc = $any($event).detail)"
                        [minValue]="KcTextBox.minValue"
                        [maxValue]="KcTextBox.maxValue" [disabled]="disabled" >
                    </pe-numeric-text-box>
                </div>
            }
        </div>
    </div>
</div>
