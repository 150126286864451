import { CodeList } from './code-list';
import { ReinforcedInterfaceEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class ReinforcedInterfaces extends CodeList {

    public static fromService(codeList: ReinforcedInterfaceEntityC2C) {
        return new ReinforcedInterfaces(CodeList.baseFromService('ReinforcedInterfaceEntityC2C', codeList));
    }

    public override cloneDeep(): ReinforcedInterfaces {
        return super.cloneDeep() as ReinforcedInterfaces;
    }
}
