@if (glModel3d == null || glModel3d.isHidden) {
    <div class="hide-model"></div>
}

<div class="gl-model-text-editor stepper-container"
    [style]="glModel3d != null ? glModel3d.textEditorStyle : undefined" [style.display]="glModel3d != null && glModel3d.textEditorVisible ? 'block' : 'none'">

    @if (glModel3d != null) {
        <input
            class="input stepper-input"
            type="text"
            [(ngModel)]="glModel3d.textEditorValue"
            (blur)="glModel3d.onTextEditorBlur()"
            (keyup.enter)="glModel3d.hideTextEditor()"
            (input)="glModel3d.textEditorValueChange()" autocomplete="off" autocorrect="off" autocapitalize="off"
            spellcheck="false" />
    }

    <div class="stepper">
        <button class="stepper-button increment button" tabindex="-1" type="button"
            (blur)="glModel3d.onTextEditorBlur();"
            (click)="stepperClick(Stepper.increment)"
            [ngbTooltip]="'Agito.Hilti.Profis3.TextBox.StepperIncrement' | l10n: selectedLanguage">+</button>
        <button class="stepper-button decrement button" tabindex="-1" type="button"
            (blur)="glModel3d.onTextEditorBlur();"
            (click)="stepperClick(Stepper.decrement)"
            [ngbTooltip]="'Agito.Hilti.Profis3.TextBox.StepperDecrement' | l10n: selectedLanguage">-</button>
    </div>
</div>
