/**
 * A base class for all can't perform action responses.
 */
export class CantPerformActionReason {}
/**
 * Instance of class derived from this class are sometimes be returned when opening a design insted of the design.
 */
export class CantOpenDesignReason extends CantPerformActionReason {}
/**
 * CantOpen design because another user has locked it.
 */
export class CantOpenDesignBecauseLockedByOtherUser extends CantOpenDesignReason {
  /**
   * User-name of the user that locked the design.
   */
  username;
}
/**
 * Cant archive design because another user has locked it.
 */
export class CantArchiveDesignBecauseLockedByOtherUser extends CantOpenDesignReason {
  /**
   * User-name of the user that locked the design.
   */
  username;
}
/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantDeleteProjectsBecauseDocumentInUse extends CantPerformActionReason {
  /**
   * A list of documents locked together with document names and users that have the files locked.
   */
  documentsInQuestion = [];
}
/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantRestoreProjectsBecauseDocumentInUse extends CantPerformActionReason {
  /**
   * A list of documents locked together with document names and users that have the files locked.
   */
  documentsInQuestion = [];
}
/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantArchiveProjectsBecauseDocumentInUse extends CantPerformActionReason {
  /**
   * A list of documents locked together with document names and users that have the files locked.
   */
  documentsInQuestion = [];
}
/**
 * Just holding information for each locked document that cussed the problem
 */
export class CantPerformActionReasonProblematicDocumentInformation {
  designId;
  username;
  document;
}
/**
 * Document service base.
 */
export class DocumentServiceBase {}
export class DocumentServiceInjected extends DocumentServiceBase {
  baseService;
  get projects() {
    return this.baseService.projects;
  }
  get projectsFlat() {
    return this.baseService.projectsFlat;
  }
  get draftsProject() {
    return this.baseService.draftsProject;
  }
  get defferedRequests() {
    return this.baseService['defferedRequests'];
  }
  setBaseService(baseService) {
    this.baseService = baseService;
    this.findProjectById = baseService.findProjectById.bind(baseService);
    this.findProjectByDesignId = baseService.findProjectByDesignId.bind(baseService);
    this.findDesignById = baseService.findDesignById.bind(baseService);
    this.publish = baseService.publish.bind(baseService);
    this.updateDesignThumbnailImage = baseService.updateDesignThumbnailImage.bind(baseService);
    this.uploadDesignImage = baseService.uploadDesignImage.bind(baseService);
    this.copyDesign = baseService.copyDesign.bind(baseService);
    this.moveDesign = baseService.moveDesign.bind(baseService);
    this.getSessionKeyForDesign = baseService.getSessionKeyForDesign.bind(baseService);
    this.createUniqueName = baseService.createUniqueName.bind(baseService);
    this.openDesignCommon = baseService.openDesignCommon.bind(baseService);
    this.openDesignExclusiveBase = baseService.openDesignExclusiveBase.bind(baseService);
    this.openDesignExclusive = baseService.openDesignExclusive.bind(baseService);
    this.addDesignBase = baseService.addDesignBase.bind(baseService);
    this.addDesignCommon = baseService.addDesignCommon.bind(baseService);
    this.getDesignContentBase = baseService.getDesignContentBase.bind(baseService);
    this.updateDesignWithNewContentBase = baseService.updateDesignWithNewContentBase.bind(baseService);
    this.updateDesignWithNewContentCommon = baseService.updateDesignWithNewContentCommon.bind(baseService);
    this.smallDesignChangeBase = baseService.smallDesignChangeBase.bind(baseService);
    this.smallDesignChangeCommon = baseService.smallDesignChangeCommon.bind(baseService);
  }
  // DocumentServiceBase methods
  /* eslint-disable @typescript-eslint/no-unused-vars */
  findProjectById(_projectId) {
    return {};
  }
  findProjectByDesignId(_designId) {
    return {};
  }
  findDesignById(_designId) {
    return {};
  }
  publish(_id) {
    return {};
  }
  updateDesignThumbnailImage(_designId, _thumbnailContent, _respond) {
    return {};
  }
  uploadDesignImage(_designId, _imageContent, _thumbnailContent) {
    return {};
  }
  copyDesign(_documentId, _documentName, _projectId) {
    return {};
  }
  moveDesign(_documentId, _projectId) {
    return {};
  }
  getSessionKeyForDesign(_designId) {
    return '';
  }
  createUniqueName(_oldName, _usedNames) {
    return '';
  }
  openDesignCommon(_design, _obtainDesignContent) {
    return {};
  }
  openDesignExclusiveBase(_design, _adjustContent, _obtainDesignContent) {
    return {};
  }
  openDesignExclusive(_design, _adjustContent) {
    return {};
  }
  addDesignBase(_options) {
    return {};
  }
  addDesignCommon(_projectId, _design, _canGenerateUniqueName, _ignoreConflict) {
    return {};
  }
  getDesignContentBase(_options) {
    return {};
  }
  updateDesignWithNewContentBase(_options) {
    return {};
  }
  updateDesignWithNewContentCommon(_design, _displayDesign, _unlock, _exclusiveLock, _documentAccessMode) {
    return {};
  }
  smallDesignChangeBase(_options) {
    return {};
  }
  smallDesignChangeCommon(_internalDesign, _projectDesign, _data, _metaData) {
    return {};
  }
}
