export class DefaultUnitConverter {
  getNumberWithUnit(internalValue) {
    return this.numberToString(internalValue);
  }
  format(internalValue) {
    return this.numberToString(internalValue);
  }
  formatWithUnit(internalValue) {
    return this.numberToString(internalValue);
  }
  parse(value) {
    if (value == '∞') {
      return Number.POSITIVE_INFINITY;
    }
    if (value == '-∞') {
      return Number.NEGATIVE_INFINITY;
    }
    return parseFloat(value);
  }
  round(value, decimals = 0) {
    return +(Math.round(`${value}e+${decimals}`) + `e-${decimals}`);
  }
  formatChanged() {
    return () => {
      // no event to remove
    };
  }
  equals(firstValue, secondValue, precision) {
    return this.round(firstValue, precision) == this.round(secondValue, precision);
  }
  numberToString(value) {
    if (value == null) {
      return '';
    }
    if (value == Number.POSITIVE_INFINITY) {
      return '∞';
    }
    if (value == Number.NEGATIVE_INFINITY) {
      return '-∞';
    }
    return value.toString();
  }
}
