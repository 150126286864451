import {
    EmbedmentDepthOverlayEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class EmbedmentDepthOverlay extends CodeList {
    public depth?: number;

    constructor(codeList: any) {
        super(codeList);

        if (codeList != null) {
            this.depth = codeList.depth;
        }
    }

    public static fromService(codeList: EmbedmentDepthOverlayEntityC2C) {
        return new EmbedmentDepthOverlay(CodeList.baseFromService('EmbedmentDepthOverlayEntity', codeList, {
            id: codeList.id,
            depth: codeList.depth
        }));
    }

    public override cloneDeep(): EmbedmentDepthOverlay {
        const codeList = super.cloneDeep() as EmbedmentDepthOverlay;

        codeList.depth = this.depth;

        return codeList;
    }
}
