import { CodeList } from './code-list';
import { ConcreteShearInterfaceModeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class ConcreteShearInterfaceMode extends CodeList {

    public static fromService(codeList: ConcreteShearInterfaceModeEntityC2C) {
        return new ConcreteShearInterfaceMode(CodeList.baseFromService('ConcreteShearInterfaceModeEntity', codeList, {
            id: codeList.id,
            tooltipDisplayKey: codeList.tooltipTitleDisplayKey,
            tooltipDisabledDisplayKey: codeList.tooltipDisabledDisplayKey,
            tooltipTitleDisplayKey: codeList.tooltipTitleDisplayKey
        }));
    }

    public override cloneDeep(): ConcreteShearInterfaceMode {
        return super.cloneDeep() as ConcreteShearInterfaceMode;
    }
}
