import { Component, ElementRef, OnInit } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';
import cloneDeep from 'lodash-es/cloneDeep';
import { DeckingUnitsHelperService } from 'src/decking/services/decking-units-helper/decking-units-helper.service';
import { DeckingCodeListService } from 'src/decking/services/decking-code-list/decking-code-list.service';
import { DefinitionOfSidelapConnectors } from 'src/decking/entities/decking-code-list/enums/definition-sidelap-connectors';
import { includeSprites } from 'src/decking/sprites';
import { DeckingSubstitutionService } from 'src/decking/services/decking-design/decking-substitution.service';
import { SubstitutionZoneModel } from 'src/decking/entities/decking-substitution/substitution-zone';
import { DeckingSubstitutionZonesAlternativesService } from 'src/decking/services/decking-zones/alternatives/decking-substitution-zones-alternatives.service';
import { CompareZoneAlternativesCommonComponent } from 'src/decking/components/decking-main-bottom/decking-zones/compare-zone-alternatives/compare-zone-alternatives-common.component';
import { LocalizationService } from '../../../../../services/external/localization.service';
import { UnitService } from '../../../../../services/external/unit.service';
import { FeatureVisibilityService } from '../../../../../services/external/feature-visibility.service';
import { CalculatingIndicatorService } from 'src/decking/services/decking-design/calculation/common/indicator/calculating-indicator.service';

@Component({
    templateUrl: './compare-substitution-zone-alternatives.component.html',
    styleUrls: ['./compare-substitution-zone-alternatives.component.scss']
})
export class CompareSubstitutionZoneAlternativesComponent extends CompareZoneAlternativesCommonComponent implements OnInit {
    currentSubstitutionZone: SubstitutionZoneModel;

    constructor(
        private deckingSubstitutionService: DeckingSubstitutionService,
        private deckingSubstitutionZonesAlternativesService: DeckingSubstitutionZonesAlternativesService,
        protected override deckingUnitsHelperService: DeckingUnitsHelperService,
        protected override deckingCodeListService: DeckingCodeListService,
        protected elementRef: ElementRef<HTMLElement>,
        protected override localizationService: LocalizationService,
        protected override unitService: UnitService,
        protected override featureVisibilityService: FeatureVisibilityService,
        protected override calculatingIndicatorService: CalculatingIndicatorService
    ) {
        super(deckingUnitsHelperService, deckingCodeListService, localizationService, unitService, featureVisibilityService, calculatingIndicatorService);
    }

    async ngOnInit(): Promise<void> {
        includeSprites(this.elementRef.nativeElement,
            'sprite-checkbox'
        );
        this.isBySpacing$ = this.deckingSubstitutionService.currentArea$.pipe(
            map(s => s.definitionOfSidelapConnectors.id === DefinitionOfSidelapConnectors.ByConnectionSpacing),
            distinctUntilChanged()
        );

        this.currentSubstitutionZone = this.deckingSubstitutionService.currentZone;
        this.currentSettings$ = this.deckingSubstitutionService.currentSettings$;
        await this.loadData(cloneDeep(this.currentSubstitutionZone));
    }

    public async includeInReport() {
        if (this.selectedAlternatives.size <= this.maxAllowedSelection) {
            this.isSubmitting = true;
            this.calculatingIndicatorService.setCalculating(true);
            this.currentSubstitutionZone.alternatives.forEach(substitutionAlternative => {
                substitutionAlternative.selected = false;
                const matchingSubstitutionAlternatives = this.alternatives.find(a => a.id === substitutionAlternative.id);
                if (matchingSubstitutionAlternatives) {
                    substitutionAlternative.selected = matchingSubstitutionAlternatives.selected;
                }
            });
            this.deckingSubstitutionService.updateCurrentZone(this.currentSubstitutionZone);
            await this.deckingSubstitutionZonesAlternativesService.setAlternatives(this.deckingSubstitutionService.getCurrentSubstitution());
            this.calculatingIndicatorService.setCalculating(false);
            this.close();
        }
    }
    
    protected override initLengthUnit(): void {
        this.lengthUnit$ = this.deckingSubstitutionService.currentSettings$.pipe(
            map(s => s.length.id),
            distinctUntilChanged(),
        );
    }

    protected override async loadAlternativesFromBackend(): Promise<void> {
        const currentDesign = cloneDeep(this.deckingSubstitutionService.getCurrentSubstitution());
        const zoneAlternatives = await this.deckingSubstitutionZonesAlternativesService.getAlternatives(currentDesign);
        this.alternatives = cloneDeep(zoneAlternatives);
        this.currentSubstitutionZone.alternatives = cloneDeep(zoneAlternatives);
        this.mapOriginalIndexOfAlternatives();
    }
}
