import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewEncapsulation } from '@angular/core';
import coverImage from '../../../../images/c_vl.png' with { loader: 'file' };
import { StrengthDesignDetails } from '../../../../services/design.service';
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';
import { imageUrl } from '../../../../image';
import { L10nDirective } from '../../../../directives/l10n.directive';

export interface InfoDialogCoverInput {
    strengthDesignDetails: StrengthDesignDetails;
}

@Component({
    templateUrl: './info-dialog-cover.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [L10nDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InfoDialogCoverComponent extends InfoDialogBaseComponent<InfoDialogCoverInput> {
    public coverImage = imageUrl(coverImage);
    public getTitle(): string {
        // Title must match with Cover control label
        return this.translate('SP.ExistingReinforcement.Cover.InfoPopUp.Title');
    }
}
