import { Injectable } from '@angular/core';
import {
    Button, ButtonGroup, CheckBox, CheckboxGroup, DropDown, ImageNameSelectionGroup, Label, Menu, Navigation, RadioButtonGroup,
    Region, Rotate, SelectAnchor, Tab, TabGroup, TextBox, ToggleButton, ToggleButtonGroup, ToggleImageButton
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { environment } from '../../environments/environmentPe';
import { MainMenuCommandPE } from '../../shared/entities/main-menu';
import { PointsTable } from '../../shared/entities/main-menu-controls';
import { Navigation as NavigationSrcPe } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Navigation';
import { fromService } from '../helpers/navigation';
import { CalculationServicePE } from './calculation-pe.service';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { NumberService } from './number.service';
import { SmartDesignSearchSolutionService } from './smart-design-search-solutions.service';
import { UnitService } from './unit.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

export type Control = PointsTable | TextBox<MainMenuCommandPE> | DropDown<MainMenuCommandPE> | CheckBox<MainMenuCommandPE> | ToggleButtonGroup<MainMenuCommandPE>
    | ToggleButton<MainMenuCommandPE> | SelectAnchor<MainMenuCommandPE> | Button<MainMenuCommandPE> | ButtonGroup<MainMenuCommandPE> | RadioButtonGroup<MainMenuCommandPE>
    | ToggleImageButton<MainMenuCommandPE> | Rotate<MainMenuCommandPE> | ImageNameSelectionGroup<MainMenuCommandPE> | TabGroup<MainMenuCommandPE> | Label<MainMenuCommandPE>
    | CheckboxGroup<MainMenuCommandPE>;
export type NavigationPE = Navigation<Control, MainMenuCommandPE>;
export type MenuPE = Menu<Control, MainMenuCommandPE>;
export type TabPE = Tab<Control, MainMenuCommandPE>;
export type RegionPE = Region<Control, MainMenuCommandPE>;

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private fromServiceData: NavigationSrcPe | undefined;
    private readonly smartAnchorTab = 'SmartAnchorTab';
    private readonly smartBaseplateSelection = 'SmartBaseplateSelection';
    private readonly smartAnchorEnable = 'SmartAnchor.Enabled';

    constructor(
        private localizationService: LocalizationService,
        private modalService: ModalService,
        private userService: UserService,
        private unitService: UnitService,
        private calculationService: CalculationServicePE,
        private commonCodeListService: CommonCodeListService,
        private codelistService: CodeListService,
        private loggerService: LoggerService,
        private numberService: NumberService,
        private userSettingsService: UserSettingsService,
        private smartDesignSearchSolutionService: SmartDesignSearchSolutionService
    ) { }

    public setDataFromService(data: NavigationSrcPe) {
        this.fromServiceData = data;
        this.ajustSmartAnchorTab(this.userSettingsService.settings?.application.general.regionId.value ?? 0);
    }

    public initialize(): NavigationPE {
        if (this.fromServiceData == undefined) {
            throw new Error('nevigation data from service not set');
        }

        this.ajustSmartAnchorTab(this.userService.design.designData.projectDesign.Options.RegionId);
        return fromService(this.fromServiceData, this.userService.design, this.localizationService, this.modalService, this.unitService, this.calculationService, this.commonCodeListService, this.codelistService, this.loggerService, this.numberService, this.smartDesignSearchSolutionService);
    }

    private ajustSmartAnchorTab(regionId: number) {
        if (!this.fromServiceData?.Menus) {
            return;
        }

        this.fromServiceData.Menus.forEach(menu => {
            menu.Tabs.forEach(tab => {
                if (tab.Name === this.smartAnchorTab) {
                    this.processTabRegions(tab.TabRegions, regionId);
                }
            });
        });
    }

    private processTabRegions(tabRegions: any[], regionId: number) {
        tabRegions.forEach(tabRegion => {
            if (tabRegion.Name === this.smartBaseplateSelection && tabRegion.Controls && tabRegion.Controls.length > 0) {
                this.processControls(tabRegion.Controls, regionId);
            }
        });
    }

    private processControls(controls: any[], regionId: number) {
        controls.forEach(control => {
            if (control.Name === this.smartAnchorEnable) {
                control.Hidden = !(this.userService.isAsadForSingleEnabled || this.userService.allowedAnchorAIRegions.includes(regionId));

                if (environment.asadQuery) {
                    control.Hidden = false;
                }

                this.userService.isAsadEnabled = !control.Hidden;
            }
        });
    }
}
