<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.SelectAnchor.Title')"
    [closeButtonDisabled]="calculateAllLoading"
    [closeButtonId]="'select-anchor-close-button'">
</pe-modal-header>

<pe-modal-body>
    <div class="no-padding">
        <div class="modal-body-content" [class.loaded]="loaded">
            <div class="left-side">
                <div class="filters-title-row input-filters">
                    <div class="input-filter-row">
                        <div class="input-filter-content">
                            <div class="input-filter-content-column">
                                <p class="checkbox-filters-title" [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.CheckboxFilters')"></p>
                            </div>
                            <div class="input-filter-content-column">
                                <pe-button
                                    class="modal-button input-filter-content-column-button"
                                    [look]="'Default'"
                                    (buttonClick)="onClearAllButtonClick()"
                                    [text]="translate('Agito.Hilti.Profis3.SelectAnchor.ClearAllFilters')">
                                </pe-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="checkbox-filters" *ngIf="mode == selectAnchorModeEnum.calculateAll">
                    <p class="checkbox-group-title title-strike">
                        <span class="checkbox-group-title-label"
                            [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculationFilters')">
                        </span>
                    </p>

                    <pe-checkbox-button
                        [items]="filtersCalculateAllCheckbox.items"
                        [selectedValues]="filtersCalculateAllCheckbox.selectedValues"
                        (selectedValuesChange)="filtersCalculateAllCheckbox.selectedValues = $any($event).detail; onFilterCheckboxChange()">
                    </pe-checkbox-button>
                </div>

                <div class="input-filters" *ngIf="designType?.id != designTypeId.Masonry">
                    <div class="input-filter-row">
                        <p class="input-filter-title title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.FixtureThicknes')"></p>

                        <div class="input-filter-content">
                            <div class="input-filter-content-column">
                                <pe-numeric-text-box
                                    [title]="translate('Agito.Hilti.Profis3.SelectAnchor.FixtureThicknes.Min')"
                                    [value]="fixtureThicknessMin"
                                    (valueChange)="filterInputValueChanged($any($event).detail, 'fixtureThicknessMin')"
                                    [unit]="unitLength"
                                    [minValue]="0"
                                    [maxValue]="500"
                                    [updateOnBlur]="true"
                                    [debounceStepperChanges]="true">
                                </pe-numeric-text-box>
                            </div>

                            <div class="input-filter-content-column">
                                <pe-numeric-text-box
                                    [title]="translate('Agito.Hilti.Profis3.SelectAnchor.FixtureThicknes.Max')"
                                    [value]="fixtureThicknessMax"
                                    (valueChange)="filterInputValueChanged($any($event).detail, 'fixtureThicknessMax')"
                                    [unit]="unitLength"
                                    [minValue]="0"
                                    [maxValue]="500"
                                    [updateOnBlur]="true"
                                    [debounceStepperChanges]="true">
                                </pe-numeric-text-box>
                            </div>
                        </div>
                    </div>

                    <div class="input-filter-row">
                        <p class="input-filter-title title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.HoleDiameter')"></p>

                        <div class="input-filter-content">
                            <div class="input-filter-content-column">
                                <pe-numeric-text-box
                                    [title]="translate('Agito.Hilti.Profis3.SelectAnchor.HoleDiameter.Min')"
                                    [value]="holeDiameterMin"
                                    (valueChange)="filterInputValueChanged($any($event).detail, 'holeDiameterMin')"
                                    [unit]="unitLength"
                                    [minValue]="0"
                                    [maxValue]="100"
                                    [updateOnBlur]="true"
                                    [debounceStepperChanges]="true">
                                </pe-numeric-text-box>
                            </div>

                            <div class="input-filter-content-column">
                                <pe-numeric-text-box
                                    [title]="translate('Agito.Hilti.Profis3.SelectAnchor.HoleDiameter.Max')"
                                    [value]="holeDiameterMax"
                                    (valueChange)="filterInputValueChanged($any($event).detail, 'holeDiameterMax')"
                                    [unit]="unitLength"
                                    [minValue]="0"
                                    [maxValue]="100"
                                    [updateOnBlur]="true"
                                    [debounceStepperChanges]="true">
                                </pe-numeric-text-box>
                            </div>
                        </div>
                    </div>

                    <div class="input-filter-row">
                        <p class="input-filter-title title-strike"
                            [ngbTooltip]=""
                            [disableTooltip]="true">
                            <span
                                class="input-filter-title-label" [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.Torquing')"></span>
                            <button type="button" class="control-tooltip-popup pe-ui-pe-sprite pe-ui-pe-sprite-info-tooltip"
                                *ngIf="true"
                                (click)="onTorquingInfoClicked()"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.ControlTooltip.Popup')"></button>
                        </p>

                        <div class="input-filter-custom-checkbox">
                            <pe-checkbox-button
                                *ngIf="true"
                                [items]="manualTorquingCheckbox.items"
                                [selectedValues]="manualTorquingCheckbox.selectedValues"
                                (selectedValuesChange)="onTorquingChange($any($event).detail, true)"
                                [disabled]="true">
                            </pe-checkbox-button>
                        </div>
                        <div class="input-filter-custom-checkbox">
                            <pe-checkbox-button
                                *ngIf="true"
                                [items]="adaptiveTorquingCheckbox.items"
                                [selectedValues]="adaptiveTorquingCheckbox.selectedValues"
                                (selectedValuesChange)="onTorquingChange($any($event).detail, true)"
                                [disabled]="false">
                            </pe-checkbox-button>
                        </div>
                    </div>

                    <div class="input-filter-row">
                        <p class="input-filter-title title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.BoreholeConditions')"></p>
                            <div class="input-filter-content">
                                <pe-dropdown
                                    class="full-width"
                                    [id]="boreholeConditionDropdown.id"
                                    [title]="boreholeConditionDropdown.title"
                                    [notSelectedText]="boreholeConditionDropdown.notSelectedText"
                                    [items]="boreholeConditionDropdown.items"
                                    [selectedValue]="boreholeConditionDropdown.selectedValue"
                                    (selectedValueChange)="boreholeConditionDropdown.selectedValue = $any($event).detail; onBoreholeConditionChange($any($event).detail, true)"
                                    [disabled]="isMetalDeckInclinedFlute">
                                </pe-dropdown>
                            </div>
                    </div>

                    <div class="input-filter-row">
                        <p class="input-filter-title title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.DrillingAndCleaning')"></p>
                        <div class="input-filter-content">
                            <pe-dropdown
                                class="full-width"
                                [id]="drillingAndCleaningDropdown.id"
                                [title]="drillingAndCleaningDropdown.title"
                                [notSelectedText]="drillingAndCleaningDropdown.notSelectedText"
                                [items]="drillingAndCleaningDropdown.items"
                                [selectedValue]="drillingAndCleaningDropdown.selectedValue"
                                (selectedValueChange)="onDrillingAndCleaningDropdownChange($any($event).detail, true)"
                                [disabled]="isMetalDeckInclinedFlute">
                            </pe-dropdown>
                        </div>
                    </div>
                </div>

                <div class="checkbox-filters">
                    <div class="checkbox-group" *ngFor="let filterGroup of filterGroups; trackBy:identifyFilterGroup">
                        <ng-template #filterGroupTooltipTemplate>
                            <div class="control-tooltip">
                                <p class="title" [innerHtml]="filterGroup.infoPopup?.title" *ngIf="filterGroup.infoPopup?.title != null"></p>
                                <p class="content" [innerHtml]="filterGroup.infoPopup?.content" *ngIf="filterGroup.infoPopup?.content != null"></p>
                            </div>
                        </ng-template>

                        <p class="checkbox-group-title title-strike"
                            [ngbTooltip]="filterGroupTooltipTemplate"
                            [disableTooltip]="!filterGroup.infoPopup?.enabled">
                            <span
                                class="checkbox-group-title-label" [innerHtml]="translate(filterGroup.titleKey ?? '')"></span>
                            <button type="button" class="control-tooltip-popup pe-ui-pe-sprite pe-ui-pe-sprite-info-tooltip"
                                *ngIf="filterGroup.infoPopup?.show" (click)="openFilterGroupInfoPopup(filterGroup)"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.ControlTooltip.Popup')"></button>
                        </p>

                        <div class="checkbox-item checkbox" *ngFor="let filterItem of filterGroup.items; trackBy:identifyFilterGroupItem">
                            <pe-checkbox-button
                                [id]="getFilterItemCheckbox(filterItem.checkbox).id"
                                [items]="getFilterItemCheckbox(filterItem.checkbox).items"
                                [selectedValues]="getFilterItemCheckbox(filterItem.checkbox).selectedValues"
                                (selectedValuesChange)="getFilterItemCheckbox(filterItem.checkbox).selectedValues = $any($event).detail; onFilterCheckboxChange(filterItem, filterGroup)"
                                [disabled]="getFilterItemCheckbox(filterItem.checkbox).disabled">
                            </pe-checkbox-button>
                            <div class="muted-label"
                                *ngIf="showCompressedAirCleaningFilterNotification(filterItem.id)"
                                [innerHtml]="translate(compressedAirCleaningNotificationValue)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="right-side">
                <div class="top-side">
                    <div class="filter-input-container">
                        <pe-text-box
                            [id]="'select-anchor-sort-anchor-name-textbox'"
                            [placeholder]="filterPlaceholder"
                            [value]="filter"
                            (valueChange)="onFilterChange($any($event).detail)"
                            [debounceChanges]="true">
                        </pe-text-box>

                        <button id="select-anchor-sort-cancel-button" type="button" class="cancel-filter"
                            (click)="resetFilter()" *ngIf="filter">
                            <span class="image pe-ui-pe-sprite pe-ui-pe-sprite-x-circle"></span>
                        </button>
                    </div>

                    <div class="sort-dropdown">
                        <pe-dropdown
                            [id]="sortDropdown.id"
                            [items]="sortDropdown.items"
                            [selectedValue]="sortDropdown.selectedValue"
                            (selectedValueChange)="onSortChange($any($event).detail)">
                        </pe-dropdown>
                    </div>

                    <div class="tooltip-wrapper" [ngbTooltip]="getCalcAllTooltip">
                        <pe-button
                            class="modal-button calculate-all-button"
                            [look]="'Cta'"
                            width="128px"
                            [disabled]="calculateAllDisabled || anchors == null || anchors.length < 1"
                            (buttonClick)="onCalculateAllButtonClick()">
                            <pe-loading-button
                                [loading]="calculateAllLoading"
                                [text]="translate(calculateAllButtonKey)">
                            </pe-loading-button>
                        </pe-button>
                    </div>
                </div>

                <div class="anchors" *ngIf="mode == null || mode == selectAnchorModeEnum.normal">
                    <virtual-scroller #scrollAnchors
                        [items]="anchors" [parentScroll]="scrollElement" [scrollAnimationTime]="0"
                        [ngStyle]="{ 'height': (anchors.length * anchorRowHeight) + 'px' }">
                        <div [id]="'select-anchor-' + anchor.id + '-row'"
                            *ngFor="let anchor of scrollAnchors.viewPortItems; trackBy:identifyAnchor"
                            class="anchor modal-grid-button"
                            [class.selected]="selectedAnchorFamilyId == anchor.id || isAnchorSelected(anchor)"
                            (click)="selectAnchor(anchor)">
                            <div class="image-container">
                                <div class="no-image-container">
                                    <span class="no-image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-anchor-family-no-photo-available"></span>
                                </div>
                                <span class="anchor-image pe-ui-pe-sprite sprite-responsive" [ngClass]="'pe-ui-pe-' + anchor.image"></span>
                            </div>
                            <div class="text-container">
                                <div class="text-wrapper">
                                    <p class="anchor-name" [innerHtml]="anchor.name"></p>
                                    <p class="anchor-description" [innerHtml]="anchor.description"></p>
                                    <p class="anchor-tags" *ngIf="(anchor.tag || anchor.isNew || anchor.internalPortfolioOnly)">
                                        <span class="tag" *ngIf="anchor.tag" [innerHtml]="anchor.tag"></span>
                                        <span class="new" *ngIf="anchor.isNew" [innerHtml]="translate('Agito.Hilti.Profis3.Anchors.New')"></span>
                                        <span class="tag" *ngIf="anchor.internalPortfolioOnly"
                                            [ngbTooltip]="translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio.Tooltip')"
                                            [innerHtml]="translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio')"></span>
                                    </p>
                                </div>
                            </div>
                            <div class="favorite-container">
                                <button class="favorite-button"
                                    (click)="$event.preventDefault(); $event.stopPropagation(); favoriteToggle(anchor.id)"
                                    type="button"
                                    [ngbTooltip]="favoriteTooltip">
                                    <span class="favorite-image pe-ui-pe-sprite" [ngClass]="getFavoriteSprite(anchor.id)"></span>
                                </button>
                            </div>
                            <div class="icons-container">
                                <div class="icons-wrapper">
                                    <span *ngFor="let icon of icons; trackBy:identifyIcon"
                                        class="icon pe-ui-pe-sprite"
                                        [ngClass]="icon.image"
                                        [class.visible]="getProperty(anchor, icon.visibleExpression)"
                                        [ngbTooltip]="getIconTooltip(icon.tooltip)"
                                        [openDelay]="0"></span>
                                </div>
                            </div>
                        </div>
                    </virtual-scroller>
                </div>

                <div class="calculating" *ngIf="calculateAllLoading">
                    <p class="calculating-title" [innerHtml]="translate('Agito.Hilti.Profis3.CalculateAll.Calculating.Title')"></p>

                    <pe-circle-loader [loadingProgress]="progress"></pe-circle-loader>
                </div>

                <div class="calculate-all" *ngIf="mode == selectAnchorModeEnum.calculateAll && !calculateAllLoading">
                    <div class="calculate-all-anchors">
                        <virtual-scroller #scrollCalculateAllAnchors
                            [items]="calculateAllAnchors" [parentScroll]="scrollElement" [scrollAnimationTime]="0"
                            [ngStyle]="{ 'height': (calculateAllAnchors.length * calculateAllAnchorRowHeight) + 'px' }">
                            <div class="calculate-all-anchors-row"
                                *ngFor="let anchor of scrollCalculateAllAnchors.viewPortItems; trackBy:identifyCalculateAllAnchor">
                                <div [ngSwitch]="anchor.type">
                                    <div class="calculate-all-anchor"
                                        *ngSwitchCase="calculateAllAnchorUiItemTypeEnum.Header">
                                        <div class="agt-column value-column name-column">
                                            <span class="value" [ngbTooltip]="anchor.name" [innerHtml]="anchor.name"></span>
                                        </div>
                                        <div class="agt-column value-column size-column">
                                            <span class="value"
                                                [ngbTooltip]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Size')"
                                                [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Size')"></span>
                                        </div>
                                        <div class="agt-column value-column embedment-depth-column">
                                            <span class="value"
                                                [ngbTooltip]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.EmbedmentDepth')"
                                                [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.EmbedmentDepth')"></span>
                                        </div>
                                        <div class="agt-column value-column utilisation-column">
                                            <span class="value"
                                                [ngbTooltip]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Utilisation')"
                                                [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Utilisation')"></span>
                                        </div>
                                        <div class="agt-column value-column geometry-column"
                                            *ngIf="designType?.id != designTypeId.Masonry">
                                            <span class="value"
                                                [ngbTooltip]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Geometry')"
                                                [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Geometry')"></span>
                                        </div>
                                    </div>

                                    <div class="calculate-all-anchor-detailed modal-grid-button"
                                        *ngSwitchCase="calculateAllAnchorUiItemTypeEnum.Item"
                                        [class.selected]="anchor.details?.id == selectedAnchorFamilySizeId"
                                        (click)="selectAnchorDetailed(anchor.id, anchor.details)">
                                        <div class="agt-column value-column name-column">
                                            <span class="value"
                                                [ngbTooltip]="anchor.details?.name" [innerHtml]="anchor.details?.name"></span>
                                        </div>
                                        <div class="agt-column value-column size-column">
                                            <span class="value"
                                                [ngbTooltip]="anchor.details?.size" [innerHtml]="anchor.details?.size"></span>
                                        </div>
                                        <div class="agt-column value-column embedment-depth-column">
                                            <span class="value"
                                                [ngbTooltip]="anchor.details?.embedmentDepthFormated" [innerHtml]="anchor.details?.embedmentDepthFormated"></span>
                                        </div>
                                        <div class="agt-column value-column utilisation-column"
                                            [class.invalid]="!isTscOk(anchor.details)"
                                            [class.ok]="isTscOk(anchor.details)">
                                            <span class="value"
                                                *ngIf="anchor.details?.isGeometryOk"
                                                [ngbTooltip]="utilizationTooltipTemplate"
                                                tooltipClass="tooltip-transparent-085"
                                                [openDelay]="0"
                                                [innerHtml]="getMaxTsc(anchor.details)"></span>
                                        </div>
                                        <div class="agt-column icon-column geometry-column"
                                            *ngIf="designType?.id != designTypeId.Masonry">
                                            <span *ngIf="anchor.details?.isGeometryOk"
                                                class="image pe-ui-pe-sprite pe-ui-pe-sprite-notification-ok geometry-image"></span>
                                            <span *ngIf="!anchor.details?.isGeometryOk"
                                                class="image pe-ui-pe-sprite pe-ui-pe-sprite-notification-alert geometry-image"
                                                [ngbTooltip]="geometryTooltipTemplate"
                                                tooltipClass="tooltip-transparent-085"
                                                [openDelay]="0"></span>
                                        </div>

                                        <ng-template #utilizationTooltipTemplate>
                                            <div class="utilisation-container" style="text-align: left;">
                                                <div style="display: flex">
                                                    <span [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Utilisation.Tooltip.Tension').replace('{tension}', anchor.details?.tFormated ?? '')"></span>
                                                    <span style="margin-left: 8px;" class="sprite sprite-warning-gray" *ngIf="(anchor.details?.t ?? 0) > 100"></span>
                                                </div>

                                                <div style="display: flex">
                                                    <span [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Utilisation.Tooltip.Shear').replace('{shear}', anchor.details?.sFormated ?? '')"></span>
                                                    <span style="margin-left: 8px;" class="sprite sprite-warning-gray" *ngIf="(anchor.details?.s ?? 0) > 100"></span>
                                                </div>

                                                <div style="display: flex">
                                                    <span [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Utilisation.Tooltip.Combination').replace('{combination}', anchor.details?.cFormated ?? '')"></span>
                                                    <span style="margin-left: 8px;" class="sprite sprite-warning-gray" *ngIf="(anchor.details?.c ?? 0) > 100"></span>
                                                </div>
                                            </div>
                                        </ng-template>

                                        <ng-template #geometryTooltipTemplate>
                                            <div class="utilisation-container" style="text-align: left;">
                                                <div style="display: flex" *ngIf="!anchor.details?.isAnchorPlateSizeOk">
                                                    <span class="sprite sprite-warning-gray" style="margin-right: 8px;"></span>
                                                    <span [innerHtml]="translate('Agito.Hilti.Profis3.BoundaryConditions.PlateSize')"></span>
                                                </div>
                                                <div style="display: flex" *ngIf="!anchor.details?.isAnchorPlatePositionOk">
                                                    <span class="sprite sprite-warning-gray" style="margin-right: 8px;"></span>
                                                    <span [innerHtml]="translate('Agito.Hilti.Profis3.BoundaryConditions.PlatePosition')"></span>
                                                </div>
                                                <div style="display: flex" *ngIf="!anchor.details?.isEdgeDistanceOk">
                                                    <span class="sprite sprite-warning-gray" style="margin-right: 8px;"></span>
                                                    <span [innerHtml]="translate('Agito.Hilti.Profis3.BoundaryConditions.EdgeDistance')"></span>
                                                </div>
                                                <div style="display: flex" *ngIf="!anchor.details?.isAxialSpacingOk">
                                                    <span class="sprite sprite-warning-gray" style="margin-right: 8px;"></span>
                                                    <span [innerHtml]="translate('Agito.Hilti.Profis3.BoundaryConditions.AxialSpacing')"></span>
                                                </div>
                                                <div style="display: flex" *ngIf="!anchor.details?.isBaseMaterialThicknessOk">
                                                    <span class="sprite sprite-warning-gray" style="margin-right: 8px;"></span>
                                                    <span [innerHtml]="translate('Agito.Hilti.Profis3.BoundaryConditions.BaseMaterialThickness')"></span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </virtual-scroller>
                    </div>
                </div>
            </div>
        </div>

        <div class="loading-indicator" [class.loaded]="loaded">
            <pe-loading [color]="'#524f53'"></pe-loading>
        </div>
    </div>
</pe-modal-body>
