import { ContolsStyleSheets, IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { SuggestedAnchor } from '../suggested-anchor/SuggestedAnchor';
import styles from './react-user-selected-anchor-group.css';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { PureComponent } from '../react-import';

let sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

const ControlHeader = (window as any).pe.reactComponents.ControlHeader;

export interface IUserSelectedAnchorGroupItem {
    value: number;
    text: string;
    tooltip?: string;
    tooltipTitle?: string;
    uiPropertyId?: number;
    disabled: boolean;
    isNew?: boolean;
    buttonText?: string;
    buttonImage?: string;
    buttonImageStyle?: IResponsiveIconStyle;
    tag?: string;
}

export interface IUserSelectedAnchorGroupProps extends IControlProps {
    checkedValue?: number;
    isSmartBaseplateEnable?: boolean;
    isUserAnchorChosen?: boolean;
    checkedValues?: number[];
    popup?: string;
    items?: IUserSelectedAnchorGroupItem[];
    deleteIconClicked?: (value: number) => void;
}
class _UserSelectedAnchorGroup extends PureComponent<IUserSelectedAnchorGroupProps> {
    public static styleSheets = [sheet];

    constructor(props: IUserSelectedAnchorGroupProps) {
        super(props);
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const groupId = `${this.props.controlId}-group`;
        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;
        const itemsCount = this.props.items ? this.props.items.length : 0;
        const headerTitle = itemsCount < 3 ? formatKeyValue(this.props.title, { userSelectedAnchorCount: itemsCount.toString() }) : this.props.localization?.getString('Agito.Hilti.Profis3.Navigation.TabSmartAnchor.SuggestedAnchorsRegion.UserSelectedAnchors.LimitReached');
        return (
            <div data-control-id={this.props.controlId} className={`react-suggested-anchor-group control ${this.props.sizeClass}`}>
                <div className='header-container bulb'>
                    <ControlHeader
                        text={headerTitle}
                        controlId={groupId}
                        tooltip={tooltipText}
                        tooltipTitle={tooltipTitle}
                        localization={this.props.localization}
                    />
                </div>
                <div
                    className='control-container'
                    data-control-id={groupId}>
                    {
                        this.props.items?.map((item) =>
                            <SuggestedAnchor
                                controlId={`${this.props.controlId}-${item.value}`}
                                key={`${this.props.controlId}-${item.value}`}
                                value={item.value}
                                checked={true}
                                type={this.props.type}
                                title={null as any}
                                size={this.props.size}
                                disabled={item.disabled || this.props.disabled}
                                hidden={this.props.hidden}
                                localization={this.props.localization}
                                isNew={item.isNew}
                                isSmartBaseplateEnable={this.props.isSmartBaseplateEnable}
                                isUserAnchorChosen={this.props.isUserAnchorChosen}
                                buttonText={item.buttonText}
                                buttonImage={item.buttonImage}
                                buttonImageStyle={item.buttonImageStyle}
                                tag={item.tag}
                                deleteIconClicked={this.props.deleteIconClicked}
                            />)
                    }
                </div>
            </div>
        );
    }
}

export const UserSelectedAnchorGroup: typeof _UserSelectedAnchorGroup & ContolsStyleSheets = _UserSelectedAnchorGroup