import uniqBy from 'lodash-es/uniqBy';

import {
    CodeList, getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    Design, ICalculateOptions, IDesignStateBase
} from '@profis-engineering/pe-ui-common/entities/design';
import {
    IButtonGroupItem
} from '@profis-engineering/pe-ui-common/entities/main-menu/button-group-props';
import { IButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import { IDropdownProps } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import {
    IImageNameRadioGroupItem
} from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-radio-group-props';
import {
    IImageNameSelectionGroupItem, IImageNameSelectionGroupProps
} from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-selection-group-props';
import { IMenu, ITab } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    BaseControl, Button, DropDown, ImageNameSelectionGroup, Menu, TextBox, ToggleButtonGroup,
    UIPropertyBaseControl
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import {
    IPopupGridPartialProps
} from '@profis-engineering/pe-ui-common/entities/main-menu/popup-grid-props';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import {
    IToggleButtonGroupItem
} from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props';
import {
    IToggleButtonProps
} from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-props';
import {
    IToggleImageButtonProps
} from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-image-button-props';
import {
    IModalGridComponentInput, IModalGridItem
} from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { IModalOpened, ModalOptions } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ICalculationResult } from '@profis-engineering/pe-ui-common/services/calculation.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    IMenuServiceCodeListIdWithItems, IMenuServiceDropdownItemProps, IMenuServiceExtensions, TabItem
} from '@profis-engineering/pe-ui-common/services/menu.common';

import {
    ApplicationType as ApplicationTypeCodelist
} from '../../shared/entities/code-lists/application-type';
import { ConnectorLength } from '../../shared/entities/code-lists/connector-length';
import { DesignMethod as DesignMethodEntity } from '../../shared/entities/code-lists/design-method';
import {
    DesignMethodGroup as DesignMethodGroupEntity
} from '../../shared/entities/code-lists/design-method-group';
import { EmbedmentDepthExisting } from '../../shared/entities/code-lists/embedment-depth-existing';
import { EmbedmentDepthOverlay } from '../../shared/entities/code-lists/embedment-depth-overlay';
import { Roughness } from '../../shared/entities/code-lists/roughness';
import { SurfaceTreatment } from '../../shared/entities/code-lists/surface-treatment';
import { DesignC2C, IDesignState } from '../../shared/entities/design-c2c';
import { DesignCodeList } from '../../shared/entities/design-code-list';
import { DesignType } from '../../shared/entities/tracking-data';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import {
    ApplicationType, ConnectionType, DesignMethod, DesignMethodGroup, DesignStandard,
    LoadingDefinitionType, LoadType
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { PropertyMetaDataC2C } from '../../shared/properties/properties';
import { isHnaBasedDesignStandard } from '../../shared/services/calculation.common';
import { CorrespondingChapterHelper } from '../helpers/corresponding-chapter-helper';
import { getSpriteAsIconStyle } from '../sprites';
import { CalculationServiceC2C } from './calculation-c2c.service';
import { CodeListService } from './code-list.service';
import { FavoritesService } from './favorites.service';
import { FeaturesVisibilityService } from './features-visibility.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { StaticMenuService } from './static-menu.service';
import { UnitService } from './unit.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

export class MenuServiceExtensions implements IMenuServiceExtensions {
    private correspondingChapterHelper!: CorrespondingChapterHelper;

    constructor(
        private codeListService: CodeListService,
        private calculationService: CalculationServiceC2C,
        private featureVisibilityService: FeaturesVisibilityService,
        private localizationService: LocalizationService,
        private loggerService: LoggerService,
        private modalService: ModalService,
        private staticMenuService: StaticMenuService,
        private unitService: UnitService,
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private favoritesService: FavoritesService
    ) {
        this.correspondingChapterHelper = new CorrespondingChapterHelper(
            this.codeListService,
            this.userService.design.designData.designCodeListsC2C,
            this.localizationService
        );
     }

    // Data
    public validate(design: DesignC2C) {
        if (design.designData.designCodeListsC2C == null) {
            throw new Error('Design code lists not set. Probably design is not created yet.');
        }
    }

    public getMenuStructure(design: DesignC2C): Menu<BaseControl<string>, string> | undefined {
        return this.staticMenuService.initializeMenu(design);
    }

    public getMenuCommands(): Record<string, (navigationControl?: BaseControl<string> | undefined) => void> {
        return {};
    }

    public getMenuRegionCommands(): Record<string, () => void> {
        return {
            ['OpenCastInSplicedReinforcementPopup']: () => this.modalService.openInfoDialogC2C('OpenCastInSplicedReinforcementPopup'),
            ['OpenAnchorTheoryDataPopup']: () => this.modalService.openInfoDialogC2C('OpenAnchorTheoryDataPopup'),
            ['OpenCrackWidthPopup']: () => this.modalService.openInfoDialogC2C('OpenCrackWidthPopup',  'lg')
        };
    }

    public getMenuModals(): Record<number, (input?: object | undefined) => IModalOpened> {
        return {};
    }

    // Common
    public calculateAsync(design: DesignC2C, changeFn?: ((design: DesignC2C) => void) | undefined, options?: ICalculateOptions | undefined): Promise<ICalculationResult> {
        return this.calculationService.calculateAsync(design, changeFn, options);
    }

    public trackUsage() {
        /* Nothing to do. */
    }


    public getDesignCodeList(codelistName: string): number {
        return DesignCodeList[codelistName as keyof typeof DesignCodeList];
    }

    public getProjectCodeList(codelistName: string): number {
        return ProjectCodeList[codelistName as keyof typeof ProjectCodeList];
    }

    public getCodeListItems(navigationControl: UIPropertyBaseControl<string>, design: DesignC2C, codeList: number): CodeList[] | undefined {
        let codeListItems: CodeList[];

        if (codeList != null && (codeListItems = design.designData.designCodeListsC2C[codeList]) != null) {
            return codeListItems;
        }

        this.logMissingCodeList(navigationControl, design);
        return undefined;
    }

    public getCodeListIdWithItems(design: DesignC2C, navigationControl: UIPropertyBaseControl<string>, codelistName?: string | undefined): IMenuServiceCodeListIdWithItems | undefined {
        const designCodeListC2C = this.getDesignCodeList(codelistName ?? navigationControl.CodelistName ?? '');
        const projectCodeListC2C = this.getProjectCodeList(codelistName ?? navigationControl.CodelistName ?? '');

        let codeListItems: CodeList[] | undefined = undefined;
        if (designCodeListC2C != null) {
            codeListItems = this.getCodeListItems(navigationControl, design, designCodeListC2C);
        }
        else if (projectCodeListC2C != null) {
            codeListItems = this.getProjectCodeListItems(navigationControl, design, projectCodeListC2C);
        }

        return {
            codeList: designCodeListC2C,
            codeListItems: codeListItems ?? []
        };
    }

    public getCodeListItemText(design: DesignC2C, codeList: number, codeListItem: CodeList): string | undefined {
        // Leave at the top
        const deps = getCodeListTextDeps(this.localizationService);

        switch (codeList) {
            case DesignCodeList.LoadingDefinitionTypes:
            case DesignCodeList.FireInputTypes:
                if (design.designStandardC2C?.id == DesignStandard.ACI || design.designStandardC2C?.id == DesignStandard.CSA) {
                    return this.localizationService.getString(codeListItem.nameResourceKey + '.HNA');
                }
                break;
            case DesignCodeList.FireDuration:
            case DesignCodeList.CrackWidth:
            case DesignCodeList.NumberOfLayers:
                return codeListItem.displayKey;
            case DesignCodeList.ConnectorLength:
                return this.getConnectorLengthText(codeListItem as ConnectorLength, design.unitLength);
            case DesignCodeList.EmbedmentDepthExisting:
                return this.getEmbedmentDepthTextC2C(codeListItem as EmbedmentDepthExisting, design.unitLength);
            case DesignCodeList.EmbedmentDepthOverlay:
                return this.getEmbedmentDepthTextC2C(codeListItem as EmbedmentDepthOverlay, design.unitLength);
            case DesignCodeList.SurfaceTreatment:
                return this.getSurfaceTreatmentC2C(codeListItem as SurfaceTreatment, design);
            case DesignCodeList.ApplicationTypesC2C:
                return this.getFireApplicationTypeTextC2C(codeListItem as ApplicationTypeCodelist, design);
            case DesignCodeList.DesignMethodGroupsC2C:
                return this.getDesignMethodGroupTextC2C(codeListItem as DesignMethodGroupEntity, design);
            case DesignCodeList.DesignMethods:
                return this.getDesignMethodText(codeListItem as DesignMethodEntity, design);
            case DesignCodeList.Roughness:
                return this.getRoughnessTextC2C(codeListItem as Roughness, design);
        }

        return codeListItem.getTranslatedNameText(deps);
    }

    public getDesignStandard(design: DesignC2C): CodeList | undefined {
        return this.codeListService.projectCodeListsC2C[ProjectCodeList.DesignStandardsC2C].find(d => d.id == design.designStandardC2C?.id);
    }


    public getTabControlId(name: string): string | undefined {
        return name;
    }

    public updateTabDisplayKey(tab: ITab): void {
        if (tab.controlId == 'existingreinforcement-tab') {
            tab.displayKey = this.userService.design.connectionType == ConnectionType.StructuralJoints && this.userService.design.designStandardC2C?.id == DesignStandard.ETAG ?
                'Agito.Hilti.C2C.Navigation.Reinforcement.ConcreteReinforcement' : 'Agito.Hilti.C2C.Navigation.ExistingReinforcement';
        }
    }

    public updateTabs() {
        /* Nothing to do. */
    }

    public isFavoritesTabHidden() {
        return false;
    }

    isTabDisabled(): boolean {
        return false;
    }

    public getRegionDisplayId(tabName: string, regionName: string): string | undefined {
        return `${tabName}-${regionName}`;
    }

    public getRegionId(tabName: string, regionName: string): string | undefined {
        return `${tabName}-${regionName}`;
    }

    public getRegionFavoritesId(id: string): string | undefined {
        return this.favoritesService.getMenuRegionIdFavorites(id, DesignType.Concrete2Concrete);
    }

    public setRegionKbFields() {
        /* Nothing to do. */
    }


    public updateControlKBNumberAci(design: DesignC2C, navigationControl: UIPropertyBaseControl<string>): void {
        const kbNumberRegions = design.designData.designCodeListsC2C[DesignCodeList.KBNumberControlRegion]?.find(x => x.id == navigationControl.UIPropertyId)?.kbNumberRegion;
        if (kbNumberRegions) {
            navigationControl.KBNumberAciRegion = kbNumberRegions;
        }
    }


    public clientHidden() {
        return false;
    }


    public formatDisplayStringModule(textKey: string, design: DesignC2C, codeList?: number | undefined, uiPropertyId?: number | undefined): string | undefined {
        if (codeList) {
            return this.formatStringByCodeListC2C(textKey, design, codeList);
        }

        if (uiPropertyId) {
            return this.formatStringByUiPropertyIdC2C(textKey, design, uiPropertyId);
        }

        return this.localizationService.getString(textKey);
    }


    public getFormattedKBLinkByRegionSpecificTemplate(design: DesignC2C, kbNumberRegion: { [key: string]: string }): string | undefined {
        const designStandardId = design.designStandardC2C?.id;

        if ((designStandardId == DesignStandard.ACI || designStandardId == DesignStandard.CSA) && kbNumberRegion != null) {
            const selectedRegion = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value as number);
            const kbNumber = kbNumberRegion[selectedRegion.id] ?? kbNumberRegion[0];
            if (kbNumber != null && selectedRegion.profis3KBUrlAci != null) {
                return this.createKBLink(selectedRegion.profis3KBUrlAci, kbNumber);
            }
        }

        return undefined;
    }

    public getLocalizedStringWithTranslationFormat() {
        return '';
    }

    // Controls
    public createMainMenuControlModule() {
        return undefined as any;
    }

    // TextBox
    public overrideTextBoxProps(design: Design, controlProps: ITextBoxProps, navigationControl: TextBox): void {
        controlProps.unitService = this.unitService;

        const value = navigationControl.UIPropertyId != null
                ? design.model[navigationControl.UIPropertyId] as any
                : null;

        controlProps.value = this.formatTextboxValue(value, navigationControl.UnitGroup, navigationControl.UIPropertyId as number, undefined, navigationControl.FixedDecimals);
        controlProps.valueTitle = this.formatTextboxValueTitle(value, navigationControl.UnitGroup, navigationControl.UIPropertyId as number);
    }

    private formatTextboxValue(value: any, unitGroup: UnitGroup | undefined, uiPropertyId: number, precision?: number, toFixed?: boolean) {
        if (unitGroup) {
            const unitValue = this.unitService.convertInternalValueToDefaultUnitValue(value as number, unitGroup);
            return this.unitService.formatUnitValue(unitValue, precision ?? this.unitService.getPrecision(unitValue.unit, uiPropertyId), undefined, undefined, uiPropertyId, toFixed);
        }

        return this.unitService.formatNumber(this.unitService.parseNumber(value as string), precision ?? this.unitService.getPrecision(UnitType.None, uiPropertyId), toFixed);
    }

    private formatTextboxValueTitle(value: any, unitGroup: UnitGroup | undefined, uiPropertyId: number) {
        const displayedUnitValue = this.formatTextboxValue(value, unitGroup, uiPropertyId);

        const maxPrecision = this.unitService.getDefaultPrecision() + 1;  // Same as used in server code (UnitHelper.ConvertUnitTo)!
        const exactUnitValue = this.formatTextboxValue(value, unitGroup, uiPropertyId, maxPrecision);

        if (displayedUnitValue?.length < exactUnitValue?.length) {
            return exactUnitValue;
        }

        return undefined;
    }

    public overrideTextBoxUnitGroup(design: DesignC2C, controlProps: ITextBoxProps, navigationControl: TextBox<string>, setState?: (() => void) | undefined): void {
        controlProps.beforeValueChanged = () => {
            if (navigationControl.UIPropertyId == PropertyMetaDataC2C.Loads_C2C_NormalStressACI.id ||
                navigationControl.UIPropertyId == PropertyMetaDataC2C.Loads_C2C_NormalStressCSA.id) {
                controlProps.unitGroup = design.designData.projectDesignC2C?.loads.loadingDefinitionType == LoadingDefinitionType.ShearStress
                    ? UnitGroup.Stress
                    : UnitGroup.Force;
                setState?.();
            }
        };
    }

    public setTextBoxOnStateChanged(state: IDesignStateBase, controlProps: ITextBoxProps, navigationControl: TextBox<string>): void {
        if (navigationControl.UIPropertyId == PropertyMetaDataC2C.Loads_C2C_NormalStressACI.id ||
            navigationControl.UIPropertyId == PropertyMetaDataC2C.Loads_C2C_NormalStressCSA.id) {
                const stateC2C = state as IDesignState;
                navigationControl.UnitGroup = stateC2C.projectDesignC2C.loads.loadingDefinitionType == LoadingDefinitionType.ShearStress
                    ? UnitGroup.Stress
                    : UnitGroup.Force;
                controlProps.unitGroup = navigationControl.UnitGroup;
        }

        const value = navigationControl.UIPropertyId != null
                ? state.model[navigationControl.UIPropertyId] as any
                : null;

        controlProps.value = this.formatTextboxValue(value, navigationControl.UnitGroup, navigationControl.UIPropertyId as number, undefined, navigationControl.FixedDecimals);
        controlProps.valueTitle = this.formatTextboxValueTitle(value, navigationControl.UnitGroup, navigationControl.UIPropertyId as number);
    }

    // Dropdown
    public overrideDropdownProps() {
        /* Nothing to do. */
    }

    public getDropdownItemProps(): IMenuServiceDropdownItemProps | undefined {
        return {};
    }

    public setDropdownOnAllowedValuesChanged() {
        /* Nothing to do. */
    }

    public setDropdownOnStateChanged(
        design: DesignC2C,
        designCodeList: number,
        state: IDesignStateBase,
        _menu: IMenu,
        navigationControl: DropDown<string>,
        _controlProps: IDropdownProps,
        onStateChangeFn: (formatTextFn: (codeListItem: CodeList, unit?: UnitType | undefined) => string, unit?: UnitType | undefined) => IDropdownProps
    ): IDropdownProps | undefined {
        switch (designCodeList) {
            case DesignCodeList.ConnectorLength:
                return onStateChangeFn(this.getConnectorLengthText.bind(this), design.unitLength);
            case DesignCodeList.EmbedmentDepthExisting:
            case DesignCodeList.EmbedmentDepthOverlay:
                return onStateChangeFn(this.getEmbedmentDepthTextC2C.bind(this), design.unitLength);
            default:
                return ({ selectedValue: state.model[navigationControl.UIPropertyId as number] } as any) as IDropdownProps;
        }
    }

    // Checkbox
    public overrideCheckboxProps(_design: DesignC2C, controlProps: ICheckboxProps) {
        if (controlProps.iconImage != null) {
            controlProps.iconImageStyle = getSpriteAsIconStyle(controlProps.iconImage);
            controlProps.iconImageSelectedStyle = getSpriteAsIconStyle(`${controlProps.iconImage}-selected`);
        }
    }

    // CheckboxGroup
    public overrideCheckboxGroupProps() {
        /* Nothing to do. */
    }

    // RadioButton
    public overrideRadioButtonProps() {
        /* Nothing to do. */
    }

    // RadioButtonGroup
    public overrideRadioButtonGroupProps() {
        /* Nothing to do. */
    }

    public applyProperSingleImage(items: IImageNameRadioGroupItem[], design: DesignC2C): void {
        // update image prop for 'single' ApplicationType
        this.applyProperSingleApplicationTypeImage(items, design);
    }

    public getApplySortOrderItemIds(): number[] | undefined {
        return undefined;
    }

    public getRadioGroupParentId(): number | undefined {
        return undefined;
    }

    // Button
    public overrideButtonProps(_design: DesignC2C, controlProps: IButtonProps) {
        if (controlProps.image != null) {
            controlProps.imageStyle = getSpriteAsIconStyle(controlProps.image);
        }
    }

    public getButtonDisplayText(design: DesignC2C, navigationControl: Button<string>): string | undefined {
        const isViewApproval = [PropertyMetaDataC2C.Product_C2C_ViewApproval.id, PropertyMetaDataC2C.Product_C2C_ConnectorViewApproval.id].find(x => x == navigationControl.UIPropertyId);

        const displayUIProperty = navigationControl.DisplayUIPropertyId != null ? design.model[navigationControl.DisplayUIPropertyId] as any : null;
        let translationKeySuffix = '';
        if (isViewApproval) {
            translationKeySuffix = isHnaBasedDesignStandard(design.designStandardC2C?.id)
                ? '.HNA'
                : '.ETA';
        }

        const displayText = navigationControl.DisplayKey != null && navigationControl.DisplayKey != '' ? this.localizationService.getString(navigationControl.DisplayKey + translationKeySuffix) : null;
        return displayUIProperty ?? displayText;
    }

    // ButtonGroup
    public overrideButtonGroupProps() {
        /* Nothing to do. */
    }

    public updateButtonGroupItemProps(_design: DesignC2C, _navigationControl: Button, item: IButtonGroupItem) {
        if (item.image != null && item.image != '') {
            item.imageStyle = getSpriteAsIconStyle(item.image);
        }
    }

    // ToggleButton
    public overrideToggleButtonProps(_design: DesignC2C, controlProps: IToggleButtonProps) {
        controlProps.imageStyle = this.getIconStyleForImage(controlProps.image);
    }

    // ToggleImageButton
    public overrideToggleImageButtonProps(_design: DesignC2C, controlProps: IToggleImageButtonProps) {
        if (controlProps.image != null) {
            controlProps.imageStyle = getSpriteAsIconStyle(controlProps.image);
        }

        if (controlProps.alternateImage != null) {
            controlProps.alternateImageStyle = getSpriteAsIconStyle(controlProps.alternateImage);
        }
    }

    // ToggleButtonGroup
    public overrideToggleButtonGroupProps() {
        /* Nothing to do. */
    }

    public updateToggleButtonGroupItemCodeListProps(design: DesignC2C, navigationControl: ToggleButtonGroup<string>, codeListItem: CodeList): void {
        if (design.isC2CHNA && navigationControl.UIPropertyId != null && navigationControl.UIPropertyId == PropertyMetaDataC2C.Loads_C2C_LoadType.id) {
            codeListItem.tooltipTitleDisplayKey += '.HNA';
            codeListItem.tooltipDisplayKey = codeListItem.tooltipDisplayKey + '.HNA';

            if (codeListItem.tooltipDisplayKey.includes('Seismic')) {
                switch (design.designMethodGroup?.id) {
                    case DesignMethodGroup.ACI31811:
                        codeListItem.tooltipDisplayKey += '.ACI31811';
                        break;
                    case DesignMethodGroup.ACI31814:
                        codeListItem.tooltipDisplayKey += '.ACI31814';
                        break;
                    case DesignMethodGroup.ACI31819:
                        codeListItem.tooltipDisplayKey += '.ACI31819';
                        break;
                    case DesignMethodGroup.CSAA23314:
                        codeListItem.tooltipDisplayKey += '.CSAA23314';
                        break;
                    case DesignMethodGroup.CSAA23319:
                        codeListItem.tooltipDisplayKey += '.CSAA23319';
                        break;
                }
            }
        }
    }

    public updateToggleButtonGroupItemProps(__design: DesignC2C, _navigationControl: ToggleButtonGroup, item: IToggleButtonGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public getToggleButtonGroupAllowedValues(_codeList: number, allowedValues: number[]): number[] {
        return allowedValues;
    }

    // Group
    public overrideGroupProps() {
        /* Nothing to do. */
    }

    public setGroupChildProps(design: DesignC2C, childNavigationControl: UIPropertyBaseControl<string>): void {
        const kbNumberRegions = design.designData.designCodeListsC2C[DesignCodeList.KBNumberControlRegion]?.find(x => x.id == childNavigationControl.UIPropertyId)?.kbNumberRegion;
        if (kbNumberRegions) {
            childNavigationControl.KBNumberAciRegion = kbNumberRegions;
        }
    }

    // Label
    public overrideLabelProps() {
        /* Nothing to do. */
    }

    // Rotate
    public overrideRotateProps() {
        /* Nothing to do. */
    }

    // PopupGrid
    public overridePopupGridProps() {
        /* Nothing to do. */
    }

    public customizePopupGridModal(_modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions): void {
        modalOpts.size = 'lg';
    }

    public customizePopupGridItems(items: IModalGridItem<number>[]): IModalGridItem<number>[] {
        items?.forEach(item => {
            if (item.image != null && item.image != '') {
                item.imageStyle = {
                    elementStyle: getSpriteAsIconStyle(item.image)
                };
            }
        });

        return items;
    }

    public getPopupGridHideShowDescriptionOnButton() {
        return false;
    }

    // PopupGridPartial
    public overridePopupGridPartialProps() {
        /* Nothing to do. */
    }

    public customizePopupGridPartialControl(controlProps: IPopupGridPartialProps) {
        controlProps.numberOfButtons = 3;
    }

    public customizePopupGridPartialItems(items: IModalGridItem<number>[]): IModalGridItem<number>[] {
        items?.forEach(item => {
            if (item.image != null && item.image != '') {
                item.imageStyle = {
                    elementStyle: getSpriteAsIconStyle(item.image)
                };
            }
        });

        return items;
    }

    public customizePopupGridPartialModal(_modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions) {
        modalOpts.size = 'lg';
    }

    // Integrations
    public overrideDlubalImportExportProps() {
        /* Nothing to do. */
    }

    public overrideSAP2000ImportExportProps() {
        /* Nothing to do. */
    }

    public overrideRobotImportExportProps() {
        /* Nothing to do. */
    }

    public overrideETABSImportExportProps() {
        /* Nothing to do. */
    }

    public overrideStaadProImportExportProps() {
        /* Nothing to do. */
    }

    // ImageNameRadioGroup
    public overrideImageNameRadioGroupProps() {
        /* Nothing to do. */
    }

    public updateImageNameRadioGroupItemProps(_design: DesignC2C, _navigationControl: UIPropertyBaseControl, item: IImageNameRadioGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public updateImageNameRadioGroupSortOrder(): Promise<void> {
        return Promise.resolve();
    }

    // ImageNameSelectionGroup
    public overrideImageNameSelectionGroupProps() {
        /* Nothing to do. */
    }

    public updateImageNameSelectionGroupItemProps(_design: DesignC2C, _navigationControl: ImageNameSelectionGroup, item: IImageNameSelectionGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image, true);
    }

    // SwitchWithDescription
    public overrideSwitchWithDescriptionProps(design: DesignC2C, controlProps: IImageNameSelectionGroupProps) {
        controlProps.items?.forEach(item => {
            if (item.imageStyle != undefined) {
                item.imageStyle = this.getIconStyleForImage(item.image);
            }
        });
    }

    // RangeSlider
    public overrideRangeSliderProps() {
        /* Nothing to do. */
    }

    // TabGroup
    public overrideTabGroupProps() {
        /* Nothing to do. */
    }

    public overrideTabGroupItems() {
        /* Nothing to do. */
    }

    public getTabGroupItemsByTag(childNavigationControls: UIPropertyBaseControl<string>[], tab: TabItem): UIPropertyBaseControl<string>[] {
        return childNavigationControls.filter(x => x.ParentControlTag == tab.Tag);
    }

    public setFooterControlVisibility(design: DesignC2C, menu: IMenu): IMenu | undefined {
        return menu;
    }

    // Helpers
    private getProjectCodeListItems(navigationControl: UIPropertyBaseControl, design: Design, codeList: ProjectCodeList) {
        let codeListItems: CodeList[];
        if (codeList != null && (codeListItems = this.codeListService.projectCodeListsC2C[codeList]) != null) {
            const uniqCodeListItems = uniqBy(codeListItems, x => x.id);
            if (uniqCodeListItems.length != codeListItems.length) {
                console.error(`Duplicate id found in codelist ${ProjectCodeList[codeList]} for control ${navigationControl.Name})`);
            }

            return uniqCodeListItems;
        }

        this.logMissingCodeList(navigationControl, design);
        return undefined;
    }

    private logMissingCodeList(navigationControl: UIPropertyBaseControl, design: Design) {
        const isVisible = navigationControl.UIPropertyId == null || !design.properties.get(navigationControl.UIPropertyId).hidden;

        if (isVisible) {
            this.loggerService.log(`Missing code list: ${navigationControl.CodelistName}`, LogType.warn);
        }
    }

    private getConnectorLengthText(codeListItem: ConnectorLength, unit?: UnitType) {
        unit = unit || this.userService.design.unitLength;

        const defaultUnit: UnitType = unit;
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Length);

        return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(codeListItem.length as number, internalUnit, defaultUnit), defaultUnit);
    }

    private getEmbedmentDepthTextC2C(codeListItem: EmbedmentDepthExisting | EmbedmentDepthOverlay, unit?: UnitType) {
        unit = unit || this.userService.design.unitLength;

        const defaultUnit: UnitType = unit;
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Length);

        return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(codeListItem.depth as number, internalUnit, defaultUnit), defaultUnit);
    }

    private getSurfaceTreatmentC2C(codeListItem: SurfaceTreatment, design: DesignC2C) {
        let tkey = codeListItem.nameResourceKey;
        if (design.isC2CHNA) {
            tkey = tkey + '.HNA';

            if (this.localizationService.getKeyExists(tkey)) {
                codeListItem.nameResourceKey = tkey;
            }
        }

        return this.localizationService.getString(codeListItem.nameResourceKey ?? '');
    }

    private getRoughnessTextC2C(codeListItem: Roughness, design: DesignC2C): string {
        let tkey = codeListItem.nameResourceKey;
        if (design.isPirASAS) {
            tkey = `${tkey}.AS`;

            if (this.localizationService.getKeyExists(tkey)) {
                return this.localizationService.getString(tkey);
            }
        }

        return this.localizationService.getString(codeListItem.nameResourceKey ?? '');
    }

    private getFireApplicationTypeTextC2C(codeListItem: ApplicationTypeCodelist, design: DesignC2C) {
        const textKey = codeListItem.nameResourceKey ?? '';
        if (!(design.isC2CHNA && design.loadTypeC2C == LoadType.Fire)) {
            return this.localizationService.getString(textKey);
        }

        const textKeyBase = 'Agito.Hilti.C2C.CodeList.ApplicationTypeEntity.';
        const relevantApplicationTypes = ['SlabToSlab', 'BeamToBeam', 'BeamToSlab'];
        const relevantApplicationTypeKeys = relevantApplicationTypes.map(type => textKeyBase.concat(type));
        const isExtensionAtSupport = design.designData.projectDesignC2C?.newStructure.existingConcrete_ExtensionAtSupport;

        if (relevantApplicationTypeKeys.includes(textKey) && isExtensionAtSupport) {
            return this.localizationService.getString(`${textKey}.Support`);
        }

        return this.localizationService.getString(textKey);
    }

    private getDesignMethodGroupTextC2C(designMethod: DesignMethodGroupEntity, design: DesignC2C): string {
        if (design.region.id == 5 && this.featureVisibilityService.isFeatureEnabled('C2C_UKTA') && designMethod.id == DesignMethodGroup.TR066ETA) {
            return this.localizationService.getString('Agito.Hilti.C2C.CodeList.DesignMethodGroupEntity.TR066.Uk');
        }
        return this.localizationService.getString(designMethod.nameResourceKey ?? '');
    }

    private getDesignMethodText(designMethod: DesignMethodEntity, design: DesignC2C): string {
        if (!designMethod.nameResourceKey)
            return '';

        const designMethodName = this.localizationService.getString(designMethod.nameResourceKey);

        if (designMethod.description)
            return `${designMethodName}, ${this.localizationService.getString(designMethod.description)}`;

        if (design.designMethodGroup && designMethod.designMethodConnectionTypeMethodGroups != null && designMethod.designMethodConnectionTypeMethodGroups.length > 0) {
            const ignoreChapterPart = design.designMethodGroup.id == DesignMethodGroup.HiltiRebarDesign || designMethod.id == DesignMethod.HiltiMethodRebarDesign || !design.isC2CHNA;
            if (ignoreChapterPart)
                return designMethodName;

            const chapterString = this.correspondingChapterHelper.getCorrespondingChapterString({
                connectionTypeId: design.projectDesign.options.connectionType,
                designMethodGroupId: design.projectDesign.options.designMethodGroup,
                designMethodId: designMethod.id,
                loadTypeId: design.projectDesign.loads.loadType
            });

            let designMethodGroupChapterText = ', ';
            const methodGroupText = this.getDesignMethodGroupTextC2C(design.designMethodGroup, design);
            const modifiedTranslation = this.localizationService.getString('Agito.Hilti.C2C.CodeList.DesignMethodEntity.General.ModifiedFrom');

            designMethodGroupChapterText += designMethod.isResearchBased ? `${modifiedTranslation} ${methodGroupText}` : methodGroupText;
            if (chapterString != '')
                designMethodGroupChapterText += `, ${chapterString}`;

            return `${designMethodName}${designMethodGroupChapterText}`;
        }

        return designMethodName;
    }

    private formatStringByCodeListC2C(textKey: string, design: DesignC2C, codeList: DesignCodeList) {
        switch (codeList) {
            case DesignCodeList.LoadingDefinitionTypes:
                if (design.designStandardC2C?.id == DesignStandard.ACI || design.designStandardC2C?.id == DesignStandard.CSA) {
                    textKey = textKey + '.HNA';
                    if (textKey.includes('Title')) {
                        return this.localizationService.getString(textKey);
                    }
                    else {
                        const defaultUnit = textKey.includes('Stress') ? this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Stress)) : this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Force));
                        return formatKeyValue(this.localizationService.getString(textKey), { unit: defaultUnit });
                    }
                }
                break;
            case DesignCodeList.OptimizationType:
                if (design.designStandardC2C?.id == DesignStandard.ACI || design.designStandardC2C?.id == DesignStandard.CSA) {
                    textKey = textKey + '.HNA';
                    return this.localizationService.getString(textKey);
                }
                break;
        }

        return this.localizationService.getString(textKey);
    }

    private formatStringByUiPropertyIdC2C(textKey: string, design: DesignC2C, uiPropertyId: number) {
        switch (uiPropertyId) {
            case PropertyMetaDataC2C.Product_C2C_OptimizedLayout.id:
            case PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id:
                if (design.isC2CHNA && design.isC2COverlay && design.loadCombinationsC2C && design.loadCombinationsC2C.length > 1) {
                    return this.localizationService.getString('Agito.Hilti.C2C.Features.Design.Optimized.LoadCombination.Tooltip');
                }

                if (design.properties.get(PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id).disabled) {
                    return undefined;
                }

                return this.localizationService.getString(design.isC2CHNA ? textKey + '.HNA' : textKey);
        }

        return this.localizationService.getString(textKey);
    }

    private createKBLink(kbLinkTemplate: string, kbNumber: string) {
        if (kbLinkTemplate == null) {
            return undefined;
        }

        if (kbNumber == null) {
            return kbLinkTemplate;
        }

        return formatKeyValue(kbLinkTemplate, { KBNUMBER: kbNumber });
    }

    private applyProperSingleApplicationTypeImage(items: IImageNameRadioGroupItem[], design: DesignC2C) {
        const applicationTypeSingleItem = items.find(item =>
            item.uiPropertyId == PropertyMetaDataC2C.General_C2C_ApplicationType.id &&
            item.value == ApplicationType.Single);

        if (applicationTypeSingleItem != null) {
            applicationTypeSingleItem.image = `single${design.connectionType == ConnectionType.StructuralJoints ? '-intersections' : ''}`;
        }
    }

    private getIconStyleForImage(image: string | undefined, supressSpriteWarnings?: boolean): IIconStyle | undefined {
        if (image == null || image == '') {
            return undefined;
        }

        return getSpriteAsIconStyle(image, supressSpriteWarnings);
    }
}