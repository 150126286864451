import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignMethodHNA, FatigueLoadType, LoadType, StaticLoadType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import { DesignPe } from '../../../../shared/entities/design-pe';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';

export class ForceUpdate extends BaseUpdate {

    @propertyValueChanged(UIProperty.Loads_LoadType)
    private Loads_LoadType(_loadType: LoadType, model: IModelPe, design: DesignPe) {
        if (!model.force) {
            throw new Error('model force is undefined');
        }

        model.force.hasVariableLoads = design.isVariableLoadsPresent;
        model.force.alternatingLoads = design.alternatingLoads;
        model.force.isDynamicFatigue = design.isDynamicFatigue;
        model.force.isFatigueExpertMode = design.isFatigueExpertMode;
    }

    @propertyValueChanged(UIProperty.Loads_StaticLoadType)
    private Loads_StaticLoadType(_staticLoadType: StaticLoadType, model: IModelPe, design: DesignPe) {
        if (!model.force) {
            throw new Error('model force is undefined');
        }

        model.force.hasVariableLoads = design.isVariableLoadsPresent;
    }

    @propertyValueChanged(UIProperty.Loads_FatigueLoadType)
    private Loads_FatigueLoadType(_fatigueLoadType: FatigueLoadType, model: IModelPe, design: DesignPe) {
        if (!model.force) {
            throw new Error('model force is undefined');
        }

        model.force.alternatingLoads = design.alternatingLoads;
    }

    @propertyValueChanged(UIProperty.Loads_DesignMethodHNA)
    private Loads_DesignMethodHNA(_value: DesignMethodHNA, model: IModelPe, design: DesignPe) {
        if (!model.force) {
            throw new Error('model force is undefined');
        }

        model.force.hasSustainedLoads = design.isSustainedLoadsPresent;
    }

    @propertyValueChanged(UIProperty.AnchorLayout_AnchorFamily)
    private AnchorLayout_AnchorFamily(_value: number, model: IModelPe, design: DesignPe) {
        if (model.force) {
            model.force.hasSustainedLoads = design.isSustainedLoadsPresent;
        }
    }

    @propertyValueChanged(UIProperty.Loads_ForceX)
    private Loads_ForceX(value: number, model: IModelPe) {
        if (!model.force?.loads) {
            throw new Error('model force loads are undefined');
        }

        model.force.loads.design.X = value;
    }

    @propertyValueChanged(UIProperty.Loads_ForceY)
    private Loads_ForceY(value: number, model: IModelPe) {
        if (!model.force?.loads) {
            throw new Error('model force loads are undefined');
        }

        model.force.loads.design.Y = value;
    }

    @propertyValueChanged(UIProperty.Loads_ForceZ)
    private Loads_ForceZ(value: number, model: IModelPe) {
        if (!model.force?.loads) {
            throw new Error('model force loads are undefined');
        }

        model.force.loads.design.Z = value;
    }

    @propertyValueChanged(UIProperty.Loads_ForceXVariable)
    private Loads_ForceXVariable(value: number, model: IModelPe) {
        if (!model.force?.loads) {
            throw new Error('model force loads are undefined');
        }

        model.force.loads.variable.X = value;
    }

    @propertyValueChanged(UIProperty.Loads_ForceYVariable)
    private Loads_ForceYVariable(value: number, model: IModelPe) {
        if (!model.force?.loads) {
            throw new Error('model force loads are undefined');
        }

        model.force.loads.variable.Y = value;
    }

    @propertyValueChanged(UIProperty.Loads_ForceZVariable)
    private Loads_ForceZVariable(value: number, model: IModelPe) {
        if (!model.force?.loads) {
            throw new Error('model force loads are undefined');
        }

        model.force.loads.variable.Z = value;
    }

    @propertyValueChanged(UIProperty.Loads_ForceZSustained)
    private Loads_ForceZSustained(value: number, model: IModelPe) {
        if (!model.force?.loads) {
            throw new Error('model force loads are undefined');
        }

        model.force.loads.sustained.Z = value;
    }

    @propertyValueChanged(UIProperty.Loads_IsFatigueExpertMode)
    private Loads_IsFatigueExpertMode(value: boolean, model: IModelPe, design: DesignPe) {
        if (!model.force) {
            throw new Error('model force is undefined');
        }

        model.force.isDynamicFatigue = design.isDynamicFatigue;
        model.force.isFatigueExpertMode = value;
    }

    @propertyValueChanged(UIProperty.Loads_LoadsPositionZ)
    private Loads_LoadsPositionZ(value: number, model: IModelPe) {
        if (!model.force) {
            throw new Error('model force is undefined');
        }

        model.force.loadsPositionZ = value;
    }
}

export function mapCheckbotBeamsLoads(model: IModelPe, design: DesignPe) {
    model.beamsLoads = [];
    if (design.projectDesign?.Beams?.length == 0 || !design.isCBFEMCalculation) {
        return;
    }

    const activeLoad = getCombination(design)?.Id;

    for (const key in design.beamLoadCombinations) {
        const load = design.beamLoadCombinations[key];

        let activeBeamLoad = load.find(l => l.ConnectedLoadCombinationId == activeLoad);
        if (activeBeamLoad == undefined) {
            activeBeamLoad = load[0];
        }

        model.beamsLoads.push({
            beamId: Number(key),
            x: activeBeamLoad.ForceX,
            y: activeBeamLoad.ForceY,
            z: activeBeamLoad.ForceZ,
            mX: activeBeamLoad.MomentX,
            mY: activeBeamLoad.MomentY,
            mZ: activeBeamLoad.MomentZ
        });
    }
}

function getCombination(design: DesignPe) {
    const loadCombinationsVisibility = design.loadCombinations != null && design.loadCombinations?.length > 1 && design.isLoadCombinationActive;
    const loadCombinationsSelected = design.loadCombinations?.find((loadCombination) => loadCombination.Id == design.reportLoadCombination);

    if (loadCombinationsVisibility) {
        design.reportLoadCombination = loadCombinationsSelected?.Id ?? '';
    }

    const combinationsVisibility = loadCombinationsVisibility ? loadCombinationsSelected : null;
    return combinationsVisibility;
}