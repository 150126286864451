import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';

import {
    ICodeLists
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    CalculationType, Design as DesignCommon, DesignEvent, IBaseDesign, IProperty, IUndoRedoAction, Properties, StateChange,
    UIPropertyTexts
} from '@profis-engineering/pe-ui-common/entities/design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { TrackChanges } from '@profis-engineering/pe-ui-common/entities/track-changes';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import {
    getNumberDecimalSeparator, getNumberGroupSeparator
} from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { trim } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import {
    ICalculateInternalOptionsBase,
    IPropertyChanges
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import {
    Change, ChangesServiceBase as ChangesService
} from '@profis-engineering/pe-ui-common/services/changes.common';
import {
    IDesignListItem,
    IDesignListItem as IDesignListItemCommon
} from '@profis-engineering/pe-ui-common/services/document.common';
import {
    LogMessage
} from '@profis-engineering/pe-ui-common/services/logger.common';
import { ApiService } from '../api.service';
import { BrowserService } from '../browser.service';
import { LocalizationService } from '../localization.service';
import { LoggerService } from '../logger.service';
import { ModalService } from '../modal.service';
import { OfflineService } from '../offline.service';
import { UnitService } from '../unit.service';
import { UserSettingsService } from '../user-settings.service';
import { ICalculationResult } from '../../../shared/services/calculation.common';
import { DocumentServiceC2C as DocumentService, LocalDocument } from '../document.service';
import { DesignC2C as Design, ICodeListsC2C, IDesignState } from '../../../shared/entities/design-c2c';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { ProjectOpenType } from '../../../shared/entities/tracking-data';
import { UIPropertyConfigC2C } from '../../../shared/generated-modules/Hilti.PE.UserInterfaceProperties';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import {
    ConnectionType, DesignStandard as DesignStandardEnumC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { getMetaDataFromDesign } from '../../../shared/entities/design-external-metadata';
import { SignalRService } from '../signalr.service';

const maxStates = 51;

export enum Status { Canceled = 'canceled' }
export type LogChange = {
    modelChange?: Change;
    isHidden?: boolean;
    isDisabled?: boolean;
    allowedValues?: number[];
    disabledValues?: number[];
    min?: number;
    max?: number;
    displayKey?: string;
    title?: string;
    toolTip?: string;
    titleDisplayKey?: string;
    itemsTexts?: { [id: number]: UIPropertyTexts };
};

export type ICalculationModel = { [property: number]: unknown };

export interface BaseCalculateDesignRequestData {
    calculateAll: boolean;
    importingLoadCases: boolean;
    calculateLongRunning: boolean;
    isReportCalculation: boolean;
}

export function triggerDesignEvent(design: Design, propertyChanges: IPropertyChanges) {
    if (propertyChanges.updatedAllowedValues != null && propertyChanges.updatedAllowedValues.length > 0) {
        design.trigger(DesignEvent.allowedValuesChanged, design, propertyChanges.updatedAllowedValues);
    }

    if (propertyChanges.updatedDisabledValues != null && propertyChanges.updatedDisabledValues.length > 0) {
        design.trigger(DesignEvent.disabledValuesChanged, design, propertyChanges.updatedDisabledValues);
    }

    if (Object.keys(propertyChanges.updatedHidden).length > 0) {
        design.trigger(DesignEvent.hiddenChanged, design);
    }

    if (Object.keys(propertyChanges.updatedDisabled).length > 0) {
        design.trigger(DesignEvent.disabledChanged, design);
    }
}

export async function calculateAsyncHelper(
    logger: LoggerService,
    calculateFn: (design: Design, calculateId: string, options: BaseCalculateDesignRequestData) => void,
    design: Design,
    modal: ModalService,
    localization: LocalizationService,
    changeFn?: (design: Design) => void,
    options?: ICalculateInternalOptionsBase): Promise<ICalculationResult> {
    changeFn?.(design);

    setCalculationLoader(design, modal, localization, options);

    design.setPendingCalculation(true);
    const calculateId = design.calculateId = design.guid.new();

    const calculateDefer = design.calculateDefer;

    options = {
        calculateAll: false,
        forceCalculation: false,
        importingLoadCases: false,
        calculateLongRunning: false,
        ...options
    };
    const { calculateAll, forceCalculation, importingLoadCases, calculateLongRunning } = options;

    design.modelChanges.observe();

    // trigger event that might change some data before we do the calculation
    design.trigger(DesignEvent.beforeCalculate, design, design.modelChanges.changes);

    design.modelChanges.observe();
    if (options.changes != null) {
        design.modelChanges.changes.push(...options.changes);
    }

    design.cancelCalculationRequest();

    const anyModelChanges = design.modelChanges.changes != null && design.modelChanges.changes.length > 0;

    if (anyModelChanges) {
        for (const change of design.modelChanges.changes) {
            design.usageCounterC2C.trackModelUIProperyChanged(change.name as any as number);
        }
    }

    if (anyModelChanges || calculateAll && design.designData.calculateAllData == null || forceCalculation) {
        calculateFn(design, calculateId, { calculateAll: calculateAll ?? false, importingLoadCases: importingLoadCases ?? false, calculateLongRunning: calculateLongRunning ?? false, isReportCalculation: false });
    }
    // remove loading flag
    else if (design.calculateId == calculateId) {
        design.loading = false;
        design.setPendingCalculation(false);

        // resolve
        design.calculateDefer.resolve({ design, calculationCanceled: true, messagesClosed: Promise.resolve(), reportData: null });
        design.calculateDefer = new Deferred<ICalculationResult>();
    }

    try {
        return await calculateDefer.promise;
    }
    finally {
        modal.loadingCustomClose();
        design.trigger(DesignEvent.afterCalculation, design, design.modelChanges.changes);
    }
}

export function logAfterCalculation(design: Design, calculateAll: boolean, logger: LoggerService) {
    // print changes
    if (!calculateAll && design.modelChanges.changes != null && design.modelChanges.changes.length > 0) {
        logger.logGroup(new LogMessage({
            message: 'Calculate'
        }), design.modelChanges.changes.map((change) => {
            const metaData = PropertyMetaDataC2C.getById(parseInt(change.name, 10));

            return new LogMessage({
                message: (metaData != null ? metaData.name : change.name) + ': %o => %o',
                args: [trim(change.oldValue), trim(change.newValue)]
            });
        }));
    }
}

export function catchCalculationException(err: any, design: Design, isRequestCanceled: boolean, calculateId: string, logger: LoggerService) {
    // is request canceled
    if (isRequestCanceled) {
        return Status.Canceled;
    }

    // if request was canceled don't reject the promise since a new request was started
    logger.logServiceError(err, 'CalculationService', 'CalculateDesign');

    // reload state if we are not changing the page (babylon breaks if you load a texture and dispose the engine before the texture is loaded)
    if (SignalRService.getStatusCode(err) != 401) {
        design.reloadState();
    }

    // reject calculate promise
    if (design.calculateId == calculateId) {
        design.calculateDefer.reject(err);
        design.calculateDefer = new Deferred<ICalculationResult>();
    }

    // must be simple return so we get to the last .then which acts as finally with argument
    return err;
}

export function afterCalculationUpdates(
    updateCalculationDataFn: (
        design: Design,
        data: any,
        calculationLanguage: string,
        calculationType: CalculationType,
        messagesClosedDeferred?: Deferred<void>,
        disableCalcMessages?: boolean,
        keepMissingProperties?: boolean
    ) => { [property: string]: Change } | null,
    userSettings: UserSettingsService,
    localization: LocalizationService,
    trackingFn: () => void,
    isRequestCanceled: boolean,
    response: any,
    design: Design,
    calculateId: string,
    selectedLanguage: string) {
    // is request canceled
    if (isRequestCanceled) {
        return Status.Canceled;
    }

    // swap 2d menu for 3d menu
    design.trigger(DesignEvent.beforeUpdate);

    // set data
    const messagesClosedDeferred = new Deferred();

    const modelChanges = updateCalculationDataFn(design, response, selectedLanguage, CalculationType.valueChange, messagesClosedDeferred, undefined);

    const combinedChanges = design.changes.chainChanges(design.lastModelChanges ?? [], modelChanges != null ? Object.values(modelChanges) : []);

    if (combinedChanges != null && combinedChanges.length > 0) {
        // save state if validation was ok
        saveState(userSettings, localization, design, StateChange.server);
    }

    trackingFn();

    // trigger calculate event
    if (modelChanges != null) {
        design.trigger(DesignEvent.calculate, design, Object.values(modelChanges));
    }

    // resolve calculate promise
    if (design.calculateId == calculateId) {
        design.calculateDefer.resolve({ design, calculationCanceled: false, messagesClosed: messagesClosedDeferred.promise, reportData: response.ReportData });
        design.calculateDefer = new Deferred<ICalculationResult>();
    }

    return response;
}

export function removeLoadingFlag(response: any, design: Design, calculateId: string) {
    // remove loading flag if request is not canceled
    if (response != Status.Canceled) {
        design.cancellationToken = undefined;
        design.lastModelChanges = undefined;

        if (design.calculateId == calculateId) {
            design.setPendingCalculation(false);
            design.loading = false;
        }
    }
}

export function createOnDocumentServiceAndTrack(
    documentService: DocumentService,
    signalr: SignalRService,
    design: Design,
    projectId: string,
    openType: ProjectOpenType,
    canGenerateUniqueName: boolean,
    ignoreConflict: boolean,
    trackingFn: () => void
) {
    design.usageCounterC2C.DateAccessed = new Date();

    const promise = documentService.addDesignBase({
        projectId,

        designType: design.designTypeId,
        design,

        canGenerateUniqueName,
        ignoreConflict,

        setProjectDesign: (newDesign: IDesignListItemCommon, designCommon: DesignCommon) => {
            const newDesign2 = newDesign as IDesignListItem & LocalDocument;
            const design2 = designCommon as Design;

            newDesign2.projectDesignC2C = design2.designData.projectDesignC2C;
        },
        adjustDesignListItemContents: (newDesign: IDesignListItemCommon, designCommon: DesignCommon, project: Project) => {
            newDesign.integrationDocument = designCommon.integrationDocument;
            newDesign.projectName = project.name ?? '';
            newDesign.isSharedByMe = project.isSharedByMe;

            documentService.setDesignPropertiesC2C(design, newDesign);
        },
        getDesignMetadata: (designType: number, design: object) => {
            return getMetaDataFromDesign(design as Design);
        },
        getDesignObject: (designCommon: DesignCommon) => {
            const design = designCommon as Design;
            const data = {...design.designData.projectDesignC2C};
            delete data.designName;
            delete data.projectName;
            return data;
        }
    });

    return promise.then(() => {
            design.projectOpenType = openType;

            signalr.setHubConnectionsC2C();

            trackingFn();

            return design;
        });
}

function checkChangeAndCreateEmptyLog(changes: { [propertyId: string]: LogChange }, propertyId: any) {
    if (changes[propertyId]) {
        return;
    }
    changes[propertyId] = createEmptyLogChange();
}

export function logUpdateFromProperties(properties: Properties, modelChanges: any, propertyChanges: any, logger: LoggerService) {
    // print changes
    // join changes
    let changes: { [propertyId: string]: LogChange } = {};

    // model changes
    for (const modelChangeKey in modelChanges) {
        const propertyId = modelChangeKey as any as number;
        const modelChange = modelChanges[modelChangeKey];

        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].modelChange = modelChange;
    }

    // allowed values changes
    for (const propertyId of propertyChanges.updatedAllowedValues) {
        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].allowedValues = properties.get(propertyId).allowedValues ?? [];
    }

    // disabled values changes
    for (const propertyId of propertyChanges.updatedDisabledValues) {
        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].disabledValues = properties.get(propertyId).disabledValues ?? [];
    }

    // disabled changes
    for (const propertyId of propertyChanges.updatedDisabled) {
        const isDisabled = properties.get(propertyId).disabled;

        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].isDisabled = isDisabled;
    }

    // hidden changes
    for (const propertyId of propertyChanges.updatedHidden) {
        const isHidden = properties.get(propertyId).hidden;

        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].isHidden = isHidden;
    }

    // min changes
    for (const propertyId of propertyChanges.updatedMin) {
        const min = properties.get(propertyId).min;

        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].min = min as number;
    }

    // max changes
    for (const propertyId of propertyChanges.updatedMin) {
        const max = properties.get(propertyId).max;

        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].max = max as number;
    }

    // display key
    for (const propertyId of propertyChanges.updatedDisplayKey) {
        const displayKey = properties.get(propertyId).displayKey;

        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].displayKey = displayKey as string;
    }

    // title
    for (const propertyId of propertyChanges.updatedTooltipTitle) {
        const title = properties.get(propertyId).tooltipTitle;

        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].title = title as string;
    }

    // toolTip
    for (const propertyId of propertyChanges.updatedTooltip) {
        const toolTip = properties.get(propertyId).tooltip;

        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].toolTip = toolTip as string;
    }

    // updatedTitleDisplayKey
    for (const propertyId of propertyChanges.updatedTitleDisplayKey) {
        const titleDisplayKey = properties.get(propertyId).titleDisplayKey;

        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].titleDisplayKey = titleDisplayKey as string;
    }

    // updated ItemsTooltip
    for (const propertyId of propertyChanges.updatedItemsTooltip) {
        const itemsTexts = properties.get(propertyId).itemsTexts;

        checkChangeAndCreateEmptyLog(changes, propertyId);

        changes[propertyId].itemsTexts = itemsTexts;
    }

    // remove empty changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changes = Object.fromEntries(Object.entries(changes).filter(([_, change]) => !isEmptyLogChange(change)));

    createChangeLogMessages(changes, logger);
}

function createChangeLogMessages(changes: { [propertyId: string]: LogChange }, logger: LoggerService) {
    if (Object.keys(changes).length <= 0) {
        return;
    }

    logger.logGroup(new LogMessage({
        message: 'Update'
    }), sortBy(Object.entries(changes).map(([propertyId, change]) => {
        const metaData = PropertyMetaDataC2C.getById(parseInt(propertyId, 10));
        let message = (metaData != null ? metaData.name : propertyId) + ':';
        const args: any[] = [];

        if (change.modelChange != null) {
            message += ' %o => %o |';
            args.push(trim(change.modelChange.oldValue), trim(change.modelChange.newValue));
        }

        if (change.isDisabled != null) {
            message += ' editable: %o |';
            args.push(!change.isDisabled);
        }

        if (change.isHidden != null) {
            message += ' visible: %o |';
            args.push(!change.isHidden);
        }

        if (change.min !== undefined) {
            message += ' min: %o |';
            args.push(change.min);
        }

        if (change.max !== undefined) {
            message += ' max: %o |';
            args.push(change.max);
        }

        if (change.displayKey !== undefined) {
            message += ' displayKey: %o |';
            args.push(change.displayKey);
        }

        if (change.title !== undefined) {
            message += ' title: %o |';
            args.push(change.title);
        }

        if (change.toolTip !== undefined) {
            message += ' toolTip: %o |';
            args.push(change.toolTip);
        }

        if (change.titleDisplayKey !== undefined) {
            message += ' titleDisplayKey: %o |';
            args.push(change.titleDisplayKey);
        }

        if (change.allowedValues != null) {
            message += ' allowedValues: %O |';
            args.push(change.allowedValues);
        }
        if (change.disabledValues != null) {
            message += ' disabledValues: %O |';
            args.push(change.disabledValues);
        }

        if (change.itemsTexts != null && Object.keys(change.itemsTexts).length > 0) {
            message += ' itemsTooltip: %O |';
            args.push(change.itemsTexts);
        }

        message = message.substring(0, message.length - 2);

        return new LogMessage({
            message,
            args
        });
    }), (log) => log.message));
}

export function updatePropertyValues(design: Design, propertyConfigs: UIPropertyConfigC2C[], keepMissingProperties?: boolean): IPropertyChanges {
    const updatedProperties: { [property: number]: IProperty } = {};
    const uiPropertyIds: { [property: number]: boolean } = {};

    const updatedAllowedValues: number[] = [];
    const updatedDisabledValues: number[] = [];
    const updatedDisabled: number[] = [];
    const updatedHidden: number[] = [];
    const updatedMin: number[] = [];
    const updatedMax: number[] = [];
    const updatedDisplayKey: number[] = [];
    const updatedTooltipTitle: number[] = [];
    const updatedTooltip: number[] = [];
    const updatedTitleDisplayKey: number[] = [];
    const updatedItemsTooltip: number[] = [];
    const updatedItemsTooltipTitle: number[] = [];
    const updatedSize: number[] = [];

    propertyConfigs.forEach(propertyConfig => {
        if (!propertyConfig) {
            return;
        }

        uiPropertyIds[propertyConfig.property] = true;

        let newProperty = updatedProperties[propertyConfig.property];
        const property = design.properties.get(propertyConfig.property);

        const allowedValues = propertyConfig.allowedValues;
        const disabledValues = propertyConfig.disabledValues;
        const allowedValuesUniqueId = propertyConfig.allowedValuesUniqueId;

        const disabled = propertyConfig.editable !== true;
        const hidden = propertyConfig.visible !== true;
        const size = propertyConfig.size;

        // allowed values
        if (property.allowedValuesUniqueId != allowedValuesUniqueId) {
            newProperty = newProperty || { ...property };
            newProperty.allowedValues = allowedValues;
            newProperty.allowedValuesUniqueId = allowedValuesUniqueId;

            updatedAllowedValues.push(propertyConfig.property);
        }

        // disabled values
        if (!isEqual(property.disabledValues, disabledValues)) {
            newProperty = newProperty || { ...property };
            newProperty.disabledValues = disabledValues;
        }

        // disabled
        if (property.disabled !== disabled) {
            newProperty = newProperty || { ...property };
            newProperty.disabled = disabled;

            updatedDisabled.push(propertyConfig.property);
        }

        // hidden
        if (property.hidden !== hidden) {
            newProperty = newProperty || { ...property };
            newProperty.hidden = hidden;

            updatedHidden.push(propertyConfig.property);
        }

        // size
        if (property.size !== size) {
            newProperty = newProperty || { ...property };
            newProperty.size = size;

            updatedSize.push(propertyConfig.property);
        }

        // display key
        if (property.displayKey !== propertyConfig.texts.displayKey) {
            newProperty = newProperty || { ...property };
            newProperty.displayKey = propertyConfig.texts.displayKey;

            updatedDisplayKey.push(propertyConfig.property);
        }

        // title
        if (property.titleDisplayKey !== propertyConfig.texts.titleDisplayKey) {
            newProperty = newProperty || { ...property };
            newProperty.titleDisplayKey = propertyConfig.texts.titleDisplayKey;

            updatedTitleDisplayKey.push(propertyConfig.property);
        }

        // tooltip title
        if (property.tooltipTitle !== propertyConfig.texts.tooltipTitle) {
            newProperty = newProperty || { ...property };
            newProperty.tooltipTitle = propertyConfig.texts.tooltipTitle;

            updatedTooltipTitle.push(propertyConfig.property);
        }

        // tooltip
        if (property.tooltip !== propertyConfig.texts.tooltip) {
            newProperty = newProperty || { ...property };
            newProperty.tooltip = propertyConfig.texts.tooltip;

            updatedTooltip.push(propertyConfig.property);
        }

        // items tooltip
        if (!isEqual(property.itemsTexts, propertyConfig.itemsTexts)) {
            newProperty = newProperty || { ...property };
            newProperty.itemsTexts = cloneDeep(propertyConfig.itemsTexts);

            updatedItemsTooltip.push(propertyConfig.property);
        }

        // save change
        if (newProperty != null) {
            updatedProperties[propertyConfig.property] = newProperty;
        }
    });

    // hide properties that were not returned
    if (!keepMissingProperties) {
        for (const key in design.properties.properties) {
            const propertyId = key as any as number;
            const property = design.properties.get(propertyId);

            let newProperty = updatedProperties[propertyId];

            if (!property.hidden && uiPropertyIds[propertyId] === undefined) {
                newProperty = newProperty || { ...property };

                newProperty.hidden = true;
            }

            // save change
            if (newProperty != null) {
                updatedProperties[propertyId] = newProperty;
            }
        }
    }

    // update properties
    design.properties = design.properties.update(updatedProperties);

    return {
        updatedAllowedValues,
        updatedDisabledValues,
        updatedDisabled,
        updatedHidden,
        updatedMin,
        updatedMax,
        updatedTitleDisplayKey,
        updatedTooltipTitle,
        updatedTooltip,
        updatedItemsTooltip,
        updatedItemsTooltipTitle,
        updatedDisplayKey,
        updatedSize
    };
}

/**
 * Change the model without triggering changes.
 * @param fn The function that changes the model.
 */
export function changeModel(model: ICalculationModel, modelChanges: TrackChanges, changesService: ChangesService, fn: (model: ICalculationModel) => ICalculationModel | void) {
    let currentModel = model;
    const oldModel = cloneDeep(model);
    let changes: { [property: string]: Change };

    try {
        const updatedModel = fn(currentModel);
        if (updatedModel !== undefined) {
            currentModel = updatedModel;
        }
    }
    finally {
        changes = changesService.getShallowChanges(oldModel, currentModel);

        // set track changes values
        if (Object.keys(changes).length > 0) {
            for (const propertyId in changes) {
                modelChanges.setOriginalProperty(propertyId, cloneDeep(changes[propertyId].newValue));
            }
        }
    }

    return changes;
}

export function updateDesignCodeLists(design: Design, codeLists: ICodeLists, isC2C: boolean, clear?: boolean) {
    if (clear) {
        design.designData.designCodeListsC2C = {};
    }

    const codeListUpdated: ICodeLists | ICodeListsC2C = {};

    for (const key in codeLists || {}) {
        const codeListKey = key as any as DesignCodeList;

        const codeList = codeLists[codeListKey];

        if (codeList != null && codeList.length > 0) {
            design.designData.designCodeListsC2C[codeListKey] = codeList;

            codeListUpdated[codeListKey] = codeList;
        }
    }

    return codeListUpdated;
}

export function resetModel(design: Design) {
    changeModel(design.model, design.modelChanges, design.changes, () => {
        // update properties
        design.properties = design.properties.update({});
        return {};
    });
}

export function download(
    apiService: ApiService,
    browser: BrowserService,
    localization: LocalizationService,
    document: DocumentService,
    offline: OfflineService,
    design: Design,
    overwrite?: boolean) {

    const path = overwrite ? design.offlineDesignPath : null;
    return generateBlob(design, apiService, browser, document)
        .then(blob => {
            const filename = generateFileName(design, localization, document, offline);

            return browser.downloadBlob(
                blob,
                filename,
                false,
                false,
                path as string)
                .then((response) => {
                    // response is null when saving cancelled
                    if (overwrite && response != null) {
                        design.offlineDesignPath = response;
                    }

                    return response;
                });
        });
}

export function generateFileName(design: Design, localization: LocalizationService, document: DocumentService, offline: OfflineService): string {
    if (offline.isOffline) {
        return `${design.designName}.pe`;
    }

    let baseName = '';
    if (!design.isTemplate) {
        const project = document.findProjectByDesignId(design.id);
        baseName = project?.name as string;
    }
    else {
        baseName = localization.getString('Agito.Hilti.Profis3.Main.TemplateProjectName');
    }

    return `${baseName}_${design.designName}.pe`;
}

export function registerCurrentStateUndoRedoAction(design: Design, action: IUndoRedoAction) {
    // remove undo redo actions with the same name
    design.currentState.undoRedoActions = design.currentState.undoRedoActions.filter(pendingAction => pendingAction.name != action.name);

    design.currentState.undoRedoActions.push(action);
}

export function saveState(userSettings: UserSettingsService, localization: LocalizationService, design: Design, stateChange?: StateChange) {
    const oldState = design.currentState;

    // clear old states
    const index = design.states.findIndex((state) => state === design.currentState);

    if (index >= 0 && index < design.states.length - 1) {
        design.states.splice(index + 1, design.states.length - (index + 1));
    }

    if (design.states.length >= maxStates) {
        design.states.splice(0, design.states.length - maxStates + 1);
    }


    // save state
    design.currentState = {
        properties: design.properties,
        model: cloneDeep(design.model),
        projectDesignC2C: design.designData.projectDesignC2C,
        fastenerFamiliesC2C: design.fastenerFamilies,
        reportDataC2C: design.designData.reportDataC2C,
        calculationLanguage: design.calculationLanguage as string,
        undoRedoActions: design.pendingUndoRedoActions,
        decimalSeparator: getNumberDecimalSeparator(localization.numberFormat(), userSettings),
        groupSeparator: getNumberGroupSeparator(localization.numberFormat(), userSettings),
        isReadOnlyDesign: design.isReadOnlyDesign,
    };

    design.states.push(design.currentState);

    design.pendingUndoRedoActions = [];
    design.snapshotStateId = design.guid.new();

    // trigger state changed event
    design.trigger(DesignEvent.stateChanged, design, design.currentState, oldState, stateChange);
}

export function generateBlob(design: Design, apiService: ApiService, browser: BrowserService, document: DocumentService) {
    if (!design.designData.projectDesignC2C) {
        throw new Error('ProjectDesign(or C2C) not set.');
    }

    if (design.designName == null || design.designName == '') {
        throw new Error('designName not set.');
    }

    if (!design.isTemplate) {
        const project = document.findProjectByDesignId(design.id);
        if (project == null) {
            throw new Error('Design is not in any projects.');
        }
    }

    return Promise.resolve(browser.base64toBlob(browser.toBase64(design.designData.projectDesignC2C), 'application/json'));
}

export function saveDesignStateInternal(
    design: Design,
    messagesClosedDeferred: Deferred<void>,
    userSettings: UserSettingsService,
    localization: LocalizationService): ICalculationResult {
    // save state
    saveState(userSettings, localization, design);

    // trigger create design event
    design.trigger(DesignEvent.createDesign);

    return {
        design,
        calculationCanceled: false,
        messagesClosed: messagesClosedDeferred.promise
    } as ICalculationResult;
}

function createEmptyLogChange(): LogChange {
    return {
        modelChange: undefined,
        isHidden: undefined,
        isDisabled: undefined,
        allowedValues: undefined,
        disabledValues: undefined,
        min: undefined,
        max: undefined,
        displayKey: undefined,
        title: undefined,
        toolTip: undefined,
        titleDisplayKey: undefined,
        itemsTexts: {}
    };
}

function isEmptyLogChange(logChange: LogChange) {
    return !logChange || (
        !logChange.modelChange &&
        !logChange.isHidden &&
        !logChange.isDisabled &&
        !logChange.allowedValues &&
        !logChange.disabledValues &&
        logChange.min === undefined &&
        logChange.max === undefined &&
        !logChange.displayKey &&
        !logChange.title &&
        !logChange.toolTip &&
        !logChange.titleDisplayKey &&
        Object.keys(logChange.itemsTexts ?? {}).length == 0);
}

export function loadStateBase(
    unitService: UnitService,
    localizationService: LocalizationService,
    userSettingsService: UserSettingsService,
    design: Design,
    index: number,
    updateModelFn: (design: Design) => void
) {
    const oldState = design.currentState;
    const oldIndex = design.states.findIndex(state => state === design.currentState);
    design.currentState = design.states[index];

    let updatedAllowedValues: number[] = [];
    let updatedHidden: number[] = [];
    let updatedDisabled: number[] = [];

    // update dependent state data on decimal an group separators
    updateStateModelToCurrentSeparator(unitService, localizationService, userSettingsService, oldState);
    updateStateModelToCurrentSeparator(unitService, localizationService, userSettingsService, design.currentState);

    // update design values
    changeModel(design.model, design.modelChanges, design.changes, () => {
        updateModelFn(design);

        // below updates are for both C2C and PE
        const update = design.updateProperties(design.currentState.properties, true);
        updatedAllowedValues = update.updatedAllowedValues;
        updatedHidden = update.updatedHidden;
        updatedDisabled = update.updatedDisabled;

        design.updateIsReadOnlyDesign(design.currentState.isReadOnlyDesign);
        design.calculationLanguage = design.currentState.calculationLanguage;
        design.fastenerFamilies = design.currentState.fastenerFamiliesC2C;
        runUndoRedoActions(design, oldIndex, index);
    });

    if (updatedAllowedValues != null && updatedAllowedValues.length > 0) {
        design.trigger(DesignEvent.allowedValuesChanged, design, updatedAllowedValues);
    }

    if (updatedHidden != null && updatedHidden.length > 0) {
        design.trigger(DesignEvent.hiddenChanged, design);
    }

    if (updatedDisabled != null && updatedDisabled.length > 0) {
        design.trigger(DesignEvent.disabledChanged, design);
    }

    design.modelChanges.clear();

    // trigger state changed event
    design.trigger(DesignEvent.stateChanged, design, design.currentState, oldState);
}

export function isHnaBasedDesignStandard(value: number) {
    switch (value) {
        case DesignStandardEnumC2C.ACI:
        case DesignStandardEnumC2C.CSA:
            return true;

        default:
            return false;
    }
}

export function setCalculationLoader(design: Design, modal: ModalService, localization: LocalizationService, options?: ICalculateInternalOptionsBase) {
    if (design.isC2C && design.connectionType == ConnectionType.ConcreteOverlay &&
        options?.forceCalculation && options?.calculateLongRunning) {
        modal.loadingCustomOpen(
            localization.getString('Agito.Hilti.C2C.Loader.Info.ConcreteOverlay'),
            () => { design.resolveCalculation(); design.setPendingCalculation(false); }
        );
    }
    else {
        design.loading = true;
    }
}

export function setDesignFromDocumentDesign(design: Design, documentDesign: IBaseDesign) {
    design.id = documentDesign.id;
    design.createDate = documentDesign.createDate;
    design.designName = documentDesign.designName;
    design.projectId = documentDesign.projectId;
    design.projectName = documentDesign.projectName;
    design.changeDate = documentDesign.changeDate;
    design.locked = documentDesign.locked;
    design.lockedUser = documentDesign.lockedUser;
    design.owner = documentDesign.owner;
}

function runUndoRedoActions(design: Design, oldIndex: number, newIndex: number) {
    if (oldIndex == newIndex) {
        // run pending undo actions
        for (const action of design.pendingUndoRedoActions) {
            action.undo();
        }

        design.pendingUndoRedoActions = [];
    }
    else if (newIndex < oldIndex) {
        // undo
        for (let currentIndex = oldIndex; currentIndex > newIndex; currentIndex--) {
            const state = design.states[currentIndex];

            for (const action of state.undoRedoActions) {
                action.undo();
            }
        }
    }
    else {
        // redo
        for (let currentIndex = oldIndex + 1; currentIndex <= newIndex; currentIndex++) {
            const state = design.states[currentIndex];

            for (const action of state.undoRedoActions) {
                action.redo();
            }
        }
    }
}

function updateStateModelToCurrentSeparator(unit: UnitService, localization: LocalizationService, userSettings: UserSettingsService, state: IDesignState) {
    const currentDecimalSeparator = getNumberDecimalSeparator(localization.numberFormat(), userSettings);
    const currentGroupSeparator = getNumberGroupSeparator(localization.numberFormat(), userSettings);

    if (state.decimalSeparator != currentDecimalSeparator || state.groupSeparator != currentGroupSeparator) {
        for (const key in state.model) {
            state.model[key] = unit.transformWithSeparator(
                state.model[key] as any,
                state.decimalSeparator,
                state.groupSeparator,
                currentDecimalSeparator,
                currentGroupSeparator,
                undefined,
                parseInt(key, 10));
        }

        state.decimalSeparator = currentDecimalSeparator;
        state.groupSeparator = currentGroupSeparator;
    }
}
