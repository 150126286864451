import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CodeListResponseC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    DesignType as DesignTypePE
} from '@profis-engineering/pe-ui-shared/entities/code-lists/design-type';
import {
    DesignTypeEntity, ProjectDesignCodeLists
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    InitialDataEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';

import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { CodeListServiceC2C } from '../../services/code-list-c2c.service';
import { CodeListService } from '../../services/code-list.service';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { InitialDataService } from '../../services/initial-data.service';
import { ModulesService } from '../../services/modules.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { FeaturesHelper } from './features-helper';

/**
 * @deprecated DO NOT USE FOR NEW CODE. Will be removed with cleanup task: C2C-10693
 */
@Injectable({
    providedIn: 'root'
})
export class DataServiceBwCompat {

    constructor(
        private codeListService: CodeListService,
        private userSettingsService: UserSettingsService,
        private userService: UserService,
        private apiService: ApiService,
        private codeListServiceC2C: CodeListServiceC2C,
        private featureVisibilityService: FeatureVisibilityService,
        private initialDataService: InitialDataService,
        private modulesService: ModulesService,
    ) {
    }

    /**
     * @deprecated backwards compatibility, removed with C2C-10693
     */
    public registerC2CDemoFeatures() {
        if (!environment.c2cDemoFeatures || !FeaturesHelper.isC2CModuleLoaded() || FeaturesHelper.C2CSupports('loadingInitialData'))
            return;

        this.featureVisibilityService.registerFeature('C2C_TR069Seismic', true);
        this.featureVisibilityService.registerFeature('C2C_HnaFire', true);
        this.featureVisibilityService.registerFeature('C2C_MultiLoadPirEu', true);
        this.featureVisibilityService.registerFeature('C2C_UKTA', true);
        this.featureVisibilityService.registerFeature('C2C_WoodModuleESPT', true);
        this.featureVisibilityService.registerFeature('C2C_EnableAciMeta', true);
        this.featureVisibilityService.registerFeature('BulkReport', true);
        this.featureVisibilityService.registerFeature('C2C_WoodModuleE2', true);
    }

    /**
     * @deprecated backwards compatibility, removed with C2C-10693
     */
    public async getInitialDataOtherModules() {
        if (FeaturesHelper.isPeModuleLoaded() && !FeaturesHelper.PeSupports('loadingInitialData')) {
            await this.getInitialDataPEInternal({ supressErrorMessage: true });
        }

        if (FeaturesHelper.isC2CModuleLoaded() && !FeaturesHelper.C2CSupports('loadingInitialData')) {
            await this.getInitialDataC2CInternal({ supressErrorMessage: true });
        }

        if (FeaturesHelper.isDeckingModuleLoaded() && !FeaturesHelper.DeckingSupports('loadingInitialData')) {
            this.getInitialDataDeckingInternal();
        }
    }

    /**
     * @deprecated moved to pe-ui-pe
     */
    private async getInitialDataPEInternal(options?: ApiOptions) {
        const asadQuery = environment.asadQuery;
        const useDevFeatures = environment.useDevFeatures;
        const forceFreeLicense = this.userSettingsService.settings.application.general.forceFreeLicense.value;
        const url = `${environment.baseplateApplicationWebServiceUrl}GetProjectInitialData?pseudoTranslate=${environment.translate || false}&asadEnabled=${asadQuery}&useDevFeatures=${useDevFeatures}&forceFreeLicense=${forceFreeLicense || false}`;

        const { body: dataEntity } = await this.apiService.request<InitialDataEntity>(new HttpRequest('GET', url), options);

        // init codelists
        this.codeListService.initialize(dataEntity.ProjectDesignCodeLists || ({} as ProjectDesignCodeLists));

        // MODULARIZATION: this file can be removed once loading initial data is moved from pe-ui to modules.
        // init pe-ui-pe module
        this.initialDataService.initialize(dataEntity.NavigationStructure, dataEntity.FeaturesVisibilityInfo, dataEntity.SupportedUnitIds);

        // asad
        this.userService.isAsadEnabled = dataEntity.IsAnchorAiEnabled ?? false;
        this.userService.isAsadDebugEnabled = dataEntity.IsAnchorAiDebugEnabled ?? false;
    }

    /**
     * @deprecated moved to pe-ui-c2c
     */
    private async getInitialDataC2CInternal(options?: ApiOptions) {
        if (!environment.c2cEnabled && !environment.c2cOverlayDemoEnabled) {
            return;
        }

        const url = `${environment.c2cCodeListServiceUrl}CodeList/projectcodelist`;

        const { body: dataEntity } = await this.apiService.request<CodeListResponseC2C>(new HttpRequest('GET', url), options);

        // init codelists
        this.codeListServiceC2C.initialize(dataEntity || ({} as CodeListResponseC2C));
    }

    /**
     * @deprecated moved to pe-ui-decking
     */
    private getInitialDataDeckingInternal() {
        if (this.deckingEnabled) {
            const designTypes = DesignTypePE.fromService({
                Id: 106,
                DisplayKey: 'DiaphragmDesign',
                Image: 'diaphragm-design',
                ProfisAnchorValue: 3,
                DesignStandardRegions: [{ RegionId: 6, DesignStandards: [] }, { RegionId: 80, DesignStandards: [] }],
            } as DesignTypeEntity);
            this.modulesService.addDesignTypes([designTypes]);
        }
    }

    /**
     * @deprecated used only for backwards compatibility, removed with C2C-10693
     */
    private get deckingEnabled() {
        return this.featureVisibilityService.isFeatureEnabled('Decking_Global') || environment.deckingEnabled;
    }
}
