export class SafeFunctionInvokerHelper {
  static safeInvoke(fn, defaultValue) {
    if (fn == null) {
      return defaultValue;
    }
    try {
      return fn();
    } catch (err) {
      console.error('Error happened while calling provided function.', err);
      return defaultValue;
    }
  }
}
