import { Component, ElementRef, OnInit } from '@angular/core';
import { DeckingZonesAlternativesService } from '../../../../services/decking-zones/alternatives/decking-zones-alternatives.service';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { DeckingUnitsHelperService } from '../../../../services/decking-units-helper/decking-units-helper.service';
import { DeckingCodeListService } from '../../../../services/decking-code-list/decking-code-list.service';
import { DeckingDesignService } from './../../../../services/decking-design/decking-design.service';
import { includeSprites } from '../.././../../sprites';
import { DefinitionOfSidelapConnectors } from './../../../../entities/decking-code-list/enums/definition-sidelap-connectors';
import { CompareZoneAlternativesCommonComponent } from './compare-zone-alternatives-common.component';
import cloneDeep from 'lodash-es/cloneDeep';
import { ZoneModel } from 'src/decking/entities/decking-design/zone-model';
import { LocalizationService } from '../../../../services/external/localization.service';
import { UnitService } from '../../../../services/external/unit.service';
import { FeatureVisibilityService } from '../../../../services/external/feature-visibility.service';
import { CalculatingIndicatorService } from 'src/decking/services/decking-design/calculation/common/indicator/calculating-indicator.service';

@Component({
    templateUrl: './compare-zone-alternatives.component.html',
    styleUrls: ['./compare-zone-alternatives.component.scss']
})
export class CompareZoneAlternativesComponent extends CompareZoneAlternativesCommonComponent implements OnInit {
    currentZone: ZoneModel;

    constructor(
        private deckingDesignService: DeckingDesignService,
        private deckingZonesAlternativesService: DeckingZonesAlternativesService,
        protected override deckingUnitsHelperService: DeckingUnitsHelperService,
        protected override deckingCodeListService: DeckingCodeListService,
        protected elementRef: ElementRef<HTMLElement>,
        protected override localizationService: LocalizationService,
        protected override unitService: UnitService,
        protected override featureVisibilityService: FeatureVisibilityService,
        protected override calculatingIndicatorService: CalculatingIndicatorService
    ) {
        super(deckingUnitsHelperService, deckingCodeListService, localizationService, unitService, featureVisibilityService, calculatingIndicatorService);
    }

    async ngOnInit(): Promise<void> {
        includeSprites(this.elementRef.nativeElement,
            'sprite-checkbox'
        );
        this.isBySpacing$ = this.deckingDesignService.currentArea$.pipe(
            map(s => s.definitionOfSidelapConnectors.id === DefinitionOfSidelapConnectors.ByConnectionSpacing),
            distinctUntilChanged()
        );

        this.currentZone = this.deckingDesignService.currentZone;
        this.currentSettings$ = this.deckingDesignService.currentSettings$;
        await this.loadData(cloneDeep(this.currentZone));
    }

    public async includeInReport() {
        if (this.selectedAlternatives.size <= this.maxAllowedSelection) {
            this.isSubmitting = true;
            this.calculatingIndicatorService.setCalculating(true);
            this.currentZone.alternatives.forEach(alternative => {
                alternative.selected = false;
                const matchingAlternative = this.alternatives.find(a => a.id === alternative.id);
                if (matchingAlternative) {
                    alternative.selected = matchingAlternative.selected;
                }
            });
            this.deckingDesignService.updateCurrentZone(this.currentZone);
            await this.deckingZonesAlternativesService.setAlternatives(this.deckingDesignService.getCurrentDesign());
            this.calculatingIndicatorService.setCalculating(false);
            this.close();
        }
    }
    
    protected override initLengthUnit(): void { 
        this.lengthUnit$ = this.deckingDesignService.currentSettings$.pipe(
            map(s => s.length.id),
            distinctUntilChanged(),
        );
    }

    protected override async loadAlternativesFromBackend(): Promise<void> {
        const currentDesign = cloneDeep(this.deckingDesignService.getCurrentDesign());
        const zoneAlternatives = await this.deckingZonesAlternativesService.getAlternatives(currentDesign);
        this.alternatives = cloneDeep(zoneAlternatives);
        this.currentZone.alternatives = cloneDeep(zoneAlternatives);
        this.mapOriginalIndexOfAlternatives();
    }
}
