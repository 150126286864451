<div class="modal-header" id="decking-zones-compare-alternatives-dialog">
    <h3
        class="modal-title"
        l10n="Agito.Hilti.Profis3.Decking.Zones.Buttons.CompareAlternatives"
    ></h3>

    <button
        type="button"
        class="close"
        (click)="close()"
        [disabled]="isSubmitting"
    >
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <ng-container *ngIf="currentZone; else noZone">
        <div class="title">
            <strong>{{currentZone.name.value}}</strong>
        </div>
        <table class="table-archive" aria-label="Zone Alternatives table" >
            <thead>
                <tr class="table-archive-row-header">
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.DeckGauge"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Pattern"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.FrameFastener"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.SidelapFastener"></th>
                    <th class="fastener-spacing" [l10n]="'Agito.Hilti.Profis3.Decking.Zones.Subheader.' | sidelapConnectorTitle"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.ShearUtilization"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.UpliftUtilization"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.ShearStiffness"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Cost"></th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-archive-row">
                    <td>{{(currentZone?.deckGauge?.value ?? '-') + DECK_GAUGE_UNIT}}</td>
                    <td>{{currentZone?.pattern?.value ?? '-'}}</td>
                    <td>{{getFastenerName(currentZone?.frameFastener?.id) ?? '-'}}</td>
                    <td>{{getSidelapName(currentZone?.sidelapConnector?.id) ?? '-'}}</td>
                    <td><decking-unit-value-display [value]="currentZone?.side?.value" [precision]="0" [unit]="sidelapSpacingUnit$ | async" [appendUnit]="isBySpacing$ | async"></decking-unit-value-display></td>
                    <td>{{currentZone?.result?.q?.value | percent:'1.0-2'}}</td>
                    <td>{{currentZone?.result?.w?.value | percent:'1.0-2'}}</td>
                    <td><decking-unit-value-display [value]="currentZone.result?.g?.value" [unit]="shearStiffnessUnit$ | async" [appendUnit]="true"></decking-unit-value-display></td>
                    <td>{{currentZone?.result?.cost?.value ?? '-'}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>
    <ng-template #noZone>
        No zone selected
    </ng-template>


    <div class="title" [ngClass]="{ 'alternative-solutions-title': isSolutionsFilterEnabled}">
        <strong l10n="Agito.Hilti.Profis3.Decking.Zones.AlternativeSolutions"></strong>
        <div *ngIf="isSolutionsFilterEnabled" class="reset-filters-button">
            <button
                type="button"
                id="reset-filters-button"
                class="modal-button button button-default"
                l10n="Agito.Hilti.Profis3.Decking.Zones.AlternativeSolutions.ResetFilters"
                (click)="resetFilters()"
                [disabled]="dominatingFilter == ''">
            </button>
        </div>
    </div>
    <div class="tableWrap">
        <div class="loading-container" *ngIf="isAlternativesLoading">
            <pe-loading [color]="'#524f53'"></pe-loading>
        </div>
        <table *ngIf="!isAlternativesLoading" class="table-archive" aria-label="Zone Alternatives table" >
            <thead>
                <tr class="table-archive-row-header">
                    <th>
                        <label class="checkbox">
                            <span class="checkbox-wrapper">
                                <span class="checkbox-label"></span>
                            </span>
                        </label>
                    </th>
                    <th *ngIf="isSolutionsFilterEnabled">
                        <div>
                            <pe-multiselect-dropdown
                                #deckGauge_DD
                                [id]="deckGaugeOptionDdl.id"
                                [notSelectedText]="deckGaugeOptionDdl.notSelectedText"
                                [items]="deckGaugeOptionDdl.items"
                                [selectedValue]="deckGaugeOptionDdl.selectedValue"
                                [selectedValues]="deckGaugeOptionDdl.selectedValues"
                                (selectedValuesChange)="deckGaugeOptionChange($any($event).detail)"
                                [allowSelectedAll]="false"
                                [defaultSelectedText]="deckGaugeOptionDdl.notSelectedText">
                            </pe-multiselect-dropdown>
                        </div>
                    </th>
                    <th *ngIf="!isSolutionsFilterEnabled" l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.DeckGauge"></th>
                    <th *ngIf="isSolutionsFilterEnabled">
                        <div>
                            <pe-multiselect-dropdown
                                #pattern_DD
                                [id]="patternOptionDdl.id"
                                [notSelectedText]="patternOptionDdl.notSelectedText"
                                [items]="patternOptionDdl.items"
                                [selectedValue]="patternOptionDdl.selectedValue"
                                [selectedValues]="patternOptionDdl.selectedValues"
                                (selectedValuesChange)="patternOptionChange($any($event).detail)"
                                [allowSelectedAll]="false"
                                [defaultSelectedText]="patternOptionDdl.notSelectedText">
                            </pe-multiselect-dropdown>
                        </div>
                    </th>
                    <th *ngIf="!isSolutionsFilterEnabled" l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Pattern"></th>
                    <th *ngIf="isSolutionsFilterEnabled">
                        <div>
                            <pe-multiselect-dropdown
                                #frameFastener_DD
                                [id]="frameFastenerOptionDdl.id"
                                [notSelectedText]="frameFastenerOptionDdl.notSelectedText"
                                [items]="frameFastenerOptionDdl.items"
                                [selectedValue]="frameFastenerOptionDdl.selectedValue"
                                [selectedValues]="frameFastenerOptionDdl.selectedValues"
                                (selectedValuesChange)="frameFastenerOptionChange($any($event).detail)"
                                [allowSelectedAll]="false"
                                [defaultSelectedText]="frameFastenerOptionDdl.notSelectedText">
                            </pe-multiselect-dropdown>
                        </div>
                    </th>
                    <th *ngIf="!isSolutionsFilterEnabled" l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.FrameFastener"></th>
                    <th *ngIf="isSolutionsFilterEnabled">
                        <div>
                            <pe-multiselect-dropdown
                                #sidelapConnector_DD
                                [id]="sidelapConnectorOptionDdl.id"
                                [notSelectedText]="sidelapConnectorOptionDdl.notSelectedText"
                                [items]="sidelapConnectorOptionDdl.items"
                                [selectedValue]="sidelapConnectorOptionDdl.selectedValue"
                                [selectedValues]="sidelapConnectorOptionDdl.selectedValues"
                                (selectedValuesChange)="sidelapConnectorOptionChange($any($event).detail)"
                                [allowSelectedAll]="false"
                                [defaultSelectedText]="sidelapConnectorOptionDdl.notSelectedText">
                            </pe-multiselect-dropdown>
                        </div>
                    </th>
                    <th *ngIf="!isSolutionsFilterEnabled" l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.SidelapFastener"></th>
                    <th *ngIf="isSolutionsFilterEnabled">
                        <div>
                            <pe-multiselect-dropdown
                                #sidelapSpacing_DD
                                [id]="sidelapSpacingOptionDdl.id"
                                [notSelectedText]="translate(sidelapSpacingOptionDdl.notSelectedText | sidelapConnectorTitle)"
                                [items]="sidelapSpacingOptionDdl.items"
                                [selectedValue]="sidelapSpacingOptionDdl.selectedValue"
                                [selectedValues]="sidelapSpacingOptionDdl.selectedValues"
                                (selectedValuesChange)="sidelapSpacingOptionChange($any($event).detail)"
                                [allowSelectedAll]="false"
                                [defaultSelectedText]="translate(sidelapSpacingOptionDdl.notSelectedText | sidelapConnectorTitle)">
                            </pe-multiselect-dropdown>
                        </div>
                    </th>
                    <th *ngIf="!isSolutionsFilterEnabled" class="fastener-spacing" [l10n]="'Agito.Hilti.Profis3.Decking.Zones.Subheader.' | sidelapConnectorTitle"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.ShearUtilization"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.UpliftUtilization"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.ShearStiffness"></th>
                    <th l10n="Agito.Hilti.Profis3.Decking.Zones.Subheader.Cost"></th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-archive-row" [ngClass]="{selected: alternative.selected}" *ngFor="let alternative of alternatives">
                    <td class="checkbox-padding">
                        <label class="checkbox">
                            <span class="checkbox-wrapper">
                                <input
                                    [checked]="alternative.selected"
                                    [disabled]="selectedAlternatives.size >= maxAllowedSelection && !alternative.selected"
                                    (change)="selectAlternative(alternative, $any($event).target.checked)"
                                    type="checkbox"
                                    class="checkbox-input">
                                <span  [ngClass]="alternative.selected ? 'checkbox-button decking-sprite decking-sprite-checkbox' : 'checkbox-button'"></span>
                                <span class="checkbox-label"></span>
                            </span>
                        </label>
                    </td>
                    <td>{{(alternative.deckGauge?.value ?? '-') + DECK_GAUGE_UNIT}}</td>
                    <td>{{alternative.pattern?.value ?? '-'}}</td>
                    <td>{{getFastenerName(alternative.frameFastener?.id) ?? '-'}}</td>
                    <td>{{getSidelapName(alternative.sidelapConnector?.id) ?? '-'}}</td>
                    <td><decking-unit-value-display [value]="alternative.side?.value" [precision]="0" [unit]="sidelapSpacingUnit$ | async" [appendUnit]="isBySpacing$ | async"></decking-unit-value-display></td>
                    <td>{{alternative.result?.q?.value | percent:'1.0-2'}}</td>
                    <td>{{alternative.result?.w?.value | percent:'1.0-2'}}</td>
                    <td><decking-unit-value-display [value]="alternative.result?.g?.value" [unit]="shearStiffnessUnit$ | async" [appendUnit]="true"></decking-unit-value-display></td>
                    <td>{{alternative.result?.cost?.value | number : '1.2-2' ?? '-'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal-footer">
    <div id="counter">
        {{ selectedAlternatives.size }}
        <span l10n="Agito.Hilti.Profis3.Decking.Zones.AlternativeSelected"></span> &nbsp;
        <span *ngIf="selectedAlternatives.size >= maxAllowedSelection" l10n="Agito.Hilti.Profis3.Decking.Zones.MaximumReached"></span>
    </div>
    <button
        type="button"
        id="add-edit-design-cancel-button"
        class="modal-button button button-default"
        l10n="Agito.Hilti.Profis3.AddEditDesign.Cancel"
        (click)="close()"
        [disabled]="isSubmitting"
    ></button>

    <button
        type="submit"
        id="add-edit-design-save-button"
        class="modal-button button button-CTA"
        (click)="includeInReport()"
        [disabled]="isSubmitting"
    >
        <pe-loading-button
            [loading]="isSubmitting"
            l10n="Agito.Hilti.Profis3.Decking.Zones.Buttons.IncludeInReport">
        </pe-loading-button>
    </button>
</div>
