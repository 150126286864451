import { CodeList } from './code-list';
import { LoadTypeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class LoadType extends CodeList {

    public static fromService(codeList: LoadTypeEntityC2C) {
        return new LoadType(CodeList.baseFromService('LoadTypeEntity', codeList, {
            nameResourceKey: undefined
        }));
    }

    public override cloneDeep(): LoadType {
        return super.cloneDeep() as LoadType;
    }
}
