

import {
    ConcreteTypeEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class ConcreteType extends CodeList {

    public static fromService(codeList: ConcreteTypeEntityC2C) {
        return new ConcreteType(CodeList.baseFromService('ConcreteTypeEntity', codeList));
    }

    public override cloneDeep(): ConcreteType {
        return super.cloneDeep() as ConcreteType;
    }
}
