import clone from 'lodash-es/clone';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';
import { Subscription } from 'rxjs';

import {
    Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit,
    Output, SimpleChanges, TrackByFunction, ViewEncapsulation
} from '@angular/core';
import {
    Tooltip
} from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import {
    DropdownItem, DropdownProps, DropdownTag, TagType
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    ToggleButtonGroupItem, ToggleButtonGroupProps
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { DesignEvent } from '@profis-engineering/pe-ui-common/entities/design';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { hasProperty } from '@profis-engineering/pe-ui-common/helpers/object-helper';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CodeList as CodeListC2C } from '../../../shared/entities/code-lists/code-list';
import {
    ConnectorLength as ConnectorLengthC2C
} from '../../../shared/entities/code-lists/connector-length';
import {
    EmbedmentDepthExisting
} from '../../../shared/entities/code-lists/embedment-depth-existing';
import { EmbedmentDepthOverlay } from '../../../shared/entities/code-lists/embedment-depth-overlay';
import {
    ProductFilter as RebarFilterEntity
} from '../../../shared/entities/code-lists/product-filter';
import { ProductFilterGroup } from '../../../shared/entities/code-lists/product-filter-group';
import { DesignCodeList as DesignCodeListC2C } from '../../../shared/entities/design-code-list';
import {
    UIProperty as UIPropertyC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    UIProductFilterInputC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities.ProductFilter';
import {
    DesignStandard, DesignStandard as DesignStandardC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { isHnaBasedDesignStandard } from '../../../shared/services/calculation.common';
import { ApprovalHelper } from '../../helpers/approval-helper';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { FeaturesVisibilityService } from '../../services/features-visibility.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { getSpriteAsIconStyle, includeSprites, Sprite } from '../../sprites';
import { SelectRebarSortBy } from '../select-rebar/select-rebar.component';

interface IRebar {
    id: number;
    image: string;
    name: string;
    tag: string;
    defaultSortOrder: number;
    allowedInProductFilters: { [key: string]: number[] };
    holeDiameters: number[];
    tested: boolean;
}

interface IFiltersGroupNumber {
    [groupId: number]: number;
}

interface ProductFilterDisplay {
    id: number;
    title?: string;
}

interface RebarFilterDisplay extends ProductFilterDisplay {
    filterGroupId?: number;
}

interface ISelectRebarFilterGroupConstructor {
    id?: number;
    items?: SelectRebarFilterGroupItem[];
}

class SelectRebarFilterGroup {
    public id?: number;
    public items: SelectRebarFilterGroupItem[] = [];

    constructor(ctor?: ISelectRebarFilterGroupConstructor) {
        if (ctor != null) {
            this.id = ctor.id;
            this.items = ctor.items ?? [];
        }
    }
}

interface ISelectRebarFilterGroupItemConstructor {
    id?: number;
    textKey?: string;
    disabled?: boolean;
    selected?: boolean;
    nameKey?: string;
}

class SelectRebarFilterGroupItem implements SelectRebarFilterGroupItem {
    public id?: number;
    public textKey?: string;
    public nameKey?: string;
    public disabled?: boolean;
    public selected?: boolean;

    constructor(ctor?: ISelectRebarFilterGroupItemConstructor) {
        if (ctor != null) {
            this.id = ctor.id;
            this.textKey = ctor.textKey;
            this.disabled = ctor.disabled;
            this.selected = ctor.selected;
            this.nameKey = ctor.nameKey;
        }
    }
}

@Component({
    templateUrl: './info-section.component.html',
    styleUrls: ['./info-section.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoSectionComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public collapsed = false;

    @Input()
    public parentElement?: HTMLElement;

    @Output()
    public collapsedChange = new EventEmitter<boolean>();

    public propertyMetaDataEnumC2C = PropertyMetaDataC2C;
    public typeConcreteDropdown!: DropdownProps<number>;
    public sizeConcreteDropdown!: DropdownProps<number>;
    public anchorLengthDropdown!: DropdownProps<number>;
    public embedmentDepthTypeConcreteToggleButtonGroup!: ToggleButtonGroupProps<number>;
    public embedmentDepthValueDropdownC2C!: DropdownProps<number>;
    public embedmentDepthNewValueDropdownC2C!: DropdownProps<number>;
    public rebarTypeDropdown!: DropdownProps<number>;
    public rebarStandardDropdown!: DropdownProps<number>;
    public rebarSizeDropdown!: DropdownProps<number>;

    public articleNumberMechanicalTooltip!: Tooltip;

    public rebars: IRebar[] = [];

    public filtersOpened = false;

    public filterButtonTooltip!: string;

    public viewUKTAApprovalTranslationKey = 'Agito.Hilti.C2C.ViewApproval.UKTA';

    private rebarFilterGroups: SelectRebarFilterGroup[] = [];

    private rebarFilters?: RebarFilterDisplay[];

    private displayedRebarFilters: RebarFilterDisplay[] = [];

    private holeDiameterMin?: number;
    private holeDiameterMax?: number;

    private rebarsData: IRebar[] = [];

    private infoSectionHeight!: number;
    private allowedValuesChangedFn?: () => void;
    private initializedSpriteImages: string[] = [];
    private localizationChangeSubscription?: Subscription;
    private userSettingsSavingSubscription?: Subscription;
    private oldRebarFavorites?: { [rebarId: number]: void } | null;

    constructor(
        private readonly localization: LocalizationService,
        private readonly user: UserService,
        private readonly userSettingsService: UserSettingsService,
        private readonly unit: UnitService,
        private readonly offline: OfflineService,
        private readonly modal: ModalService,
        private readonly calculationService: CalculationServiceC2C,
        private readonly numberService: NumberService,
        private readonly featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private readonly featureVisibilityService: FeaturesVisibilityService,
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly ngZone: NgZone
    ) {
    }

    public get infoSectionContainer() {
        return this.elementRef.nativeElement.shadowRoot?.querySelector('#resize-info-section') as HTMLElement;
    }

    public get productName() {
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        const rebar = this.design.rebarFamily;
        return rebar != null
            ? rebar.getTranslatedNameText(codeListDeps)
            : this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.SelectAnchor.NotSelected');
    }

    public get design() {
        return this.user.design;
    }

    public get mechArticleNumberLabelC2C() {
        return [DesignStandardC2C.ACI, DesignStandardC2C.CSA].includes(this.design?.designStandardC2C?.id ?? 0)
            ? this.translate('Agito.Hilti.C2C.Navigation.TabProduct.Connector.RebarItem')
            : this.translate('Agito.Hilti.C2C.Navigation.TabProduct.Connector.ConnectorItem');
    }

    public get selectedProductId() {
        return this.design.model[this.design.globalMetaProperties.sourceMetaProperty] as number;
    }

    public get productHidden() {
        return this.design?.properties.get(this.design.globalMetaProperties.sourceMetaProperty).hidden ?? false;
    }

    public get approvalProperty() {
        if (this.design.globalMetaProperties.isConcreteOverlay) {
            return this.design.properties.get(PropertyMetaDataC2C.Product_C2C_ConnectorViewApproval.id);
        }
        return this.design.properties.get(PropertyMetaDataC2C.Product_C2C_ViewApproval.id);
    }

    public get uktaApprovalProperty() {
        if (this.featureVisibilityService.isFeatureEnabled('C2C_UKTA')) {
            if (this.design.globalMetaProperties.isConcreteOverlay) {
                return this.design.properties.get(PropertyMetaDataC2C.Product_C2C_ConnectorViewUKTAApproval.id);
            }

            return this.design.properties.get(PropertyMetaDataC2C.Product_C2C_ViewUKTAApproval.id);
        }

        return {
            disabled: true,
            hidden: true
        };
    }

    public get connectorEmbedmentDepthTextboxHidden() {
        return this.design.properties.get(PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepth.id).hidden;
    }

    public get connectorEmbedmentDepthTextboxWithFlagHidden() {
        return this.design.properties.get(PropertyMetaDataC2C.Product_C2C_OverlayEmbedmentDepthExisting.id).hidden;
    }

    public get connectorEmbedmentDepthDropdownWithFlagHidden() {
        return this.design.properties.get(PropertyMetaDataC2C.Product_C2C_OverlayEmbedmentDepthExistingSelection.id).hidden;
    }

    public get connectorEmbedmentDepthDropdownHidden() {
        return this.design.properties.get(PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthSelection.id).hidden;
    }

    public get filtersTitle() {
        return this.displayedRebarFilters.length + ' ' + this.translate(this.displayedRebarFilters.length == 1
            ? 'Agito.Hilti.C2C.InfoSection.RebarSection.SingleRebarFilterTitle'
            : 'Agito.Hilti.C2C.InfoSection.RebarSection.MultipleAnchorFilterTitle');
    }

    public get displayedFilters() {
        return this.displayedRebarFilters;
    }

    public get favoriteTooltip() {
        return this.featuresVisibilityInfo.tooltip(Feature.Menu_Favorites)
            || this.translate('Agito.Hilti.Profis3.Main.Region.AddRemoveFromFavorites');
    }

    public get viewApprovalTranslationKey() {
        if (isHnaBasedDesignStandard(this.design.designStandardC2C?.id ?? DesignStandard.Unknown)) {
            return 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ViewApproval.HNA';
        }

        return 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ViewApproval.ETA';
    }

    public get hideApprovalButton() {
        return this.approvalProperty.hidden;
    }

    public get showApprovalButton() {
        return (!this.design.globalMetaProperties.isConcreteOverlay && !this.hideApprovalButton);
    }

    public get hideUKTAApprovalButton() {
        return this.uktaApprovalProperty.hidden;
    }

    public get showUKTAApprovalButton() {
        return (!this.design.globalMetaProperties.isConcreteOverlay && !this.hideUKTAApprovalButton);
    }

    public get typeConcreteDropdownTags() {
        const type = this.design.fastenerType;
        const tags: DropdownTag[] = [];

        if (!type?.tested) {
            tags.push({
                type: TagType.Tag,
                text: this.translate('Agito.Hilti.C2C.Rebars.Untested.Tag')
            });
        }

        // To prevent Angular exceeding $digest iterations
        // we compare generated array with the one already
        // stored; if there is no change, old array is returned.
        if (!isEqual(this.typeConcreteDropdown.tags, tags)) {
            this.typeConcreteDropdown.tags = tags;
        }

        return this.typeConcreteDropdown.tags;
    }

    public get sizeConcreteDropdownTags() {
        const size = this.design.fastenerSize;
        const tags: DropdownTag[] = [];

        if (!size?.tested) {
            tags.push({
                type: TagType.Tag,
                text: this.translate('Agito.Hilti.C2C.Rebars.Untested.Tag')
            });
        }

        // To prevent Angular exceeding $digest iterations
        // we compare generated array with the one already
        // stored; if there is no change, old array is returned.
        if (!isEqual(this.sizeConcreteDropdown.tags, tags)) {
            this.sizeConcreteDropdown.tags = tags;
        }

        return this.sizeConcreteDropdown.tags;
    }

    public get isCalculateAllDisabled() {
        return this.rebars == null || this.rebars.length < 1 || this.design.isReadOnlyDesignMode;
    }

    private get uktaApprovalFiles(): string[] {
        const isPirEu = this.design.globalMetaProperties.sourceMetaPropertyApproval == PropertyMetaDataC2C.Product_C2C_ViewApproval.id;
        return isPirEu ? this.user.design.model[PropertyMetaDataC2C.Product_C2C_ViewUKTAApproval.id] as string[] : this.user.design.model[PropertyMetaDataC2C.Product_C2C_ConnectorViewUKTAApproval.id] as string[];
    }

    public initializeControls() {
        this.initControls();
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-arrow-down',
            'sprite-arrow-up',
            'sprite-favorite-false',
            'sprite-favorite-true',
            'sprite-x',
            'sprite-filter',
            'sprite-rebar-icon',
            'sprite-anchor-family-no-photo-available'
        );

        this.initializeControls();
        this.initTooltips();

        this.updateDropdownAnchorEmbedmentDepthText();

        this.allowedValuesChangedFn = this.allowedValuesChanged.bind(this);
        this.design.onAllowedValuesChanged(this.allowedValuesChangedFn);

        this.onResize = this.onResize.bind(this);

        this.design.onStateChanged((_design, state, oldState) => {
            // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
            const onStateChanged = () => {
                if (!isEqual(oldState.model[PropertyMetaDataC2C.Product_C2C_ProductFilters.id], state.model[PropertyMetaDataC2C.Product_C2C_ProductFilters.id])) {
                    this.getSavedGroupsAndFilters();
                    this.refreshProducts();
                }

                this.updateDropdownAnchorEmbedmentDepthText();
                this.updateToggleButtonGroupItemsConnectorEmbedmentDepthMode();
            };
            return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
        });

        this.getSavedGroupsAndFilters();

        this.loadProducts();

        this.localizationChangeSubscription = this.localization.localizationChange.subscribe(this.updateProductNameText.bind(this));

        this.oldRebarFavorites = clone(this.userSettingsService.settings.rebarFavorites.value);
        this.userSettingsSavingSubscription = this.userSettingsService.userSettingsSaving.subscribe(this.onUserSettingsSaved.bind(this));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['collapsed'] != null) {
            this.onCollapsedChanged(this.collapsed);
        }
    }

    ngOnDestroy(): void {
        if (this.localizationChangeSubscription != null) {
            this.localizationChangeSubscription.unsubscribe();
        }

        if (this.userSettingsSavingSubscription != null) {
            this.userSettingsSavingSubscription.unsubscribe();
        }

        if (this.allowedValuesChangedFn != null) {
            this.design?.off(DesignEvent.allowedValuesChanged, this.allowedValuesChangedFn);
            this.allowedValuesChangedFn = undefined;
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        if (!this.collapsed) {
            this.calculateRebarElementHeights();
        }
    }

    public onResize() {
        setTimeout(() => {
            this.infoSectionHeight = this.infoSectionContainer.offsetHeight;
            this.resizeInfoSection(false);
        });
    }

    public openSelectProduct() {
        this.modal.openSelectRebar();
    }

    public openCalculateAll() {
        this.modal.openSelectRebar({
            calculateAll: true
        });
    }

    public removeProductFilter(filterId: number) {
        if (filterId >= 1000) {
            if (filterId == 1002) {
                this.holeDiameterMin = undefined;
            }

            else if (filterId == 1003) {
                this.holeDiameterMax = undefined;
            }
        }

        const rebarFilters = this.design.designData.designCodeListsC2C[DesignCodeListC2C.ProductFilter] as RebarFilterEntity[];
        const rebarFilterGroups = this.design.designData.designCodeListsC2C[DesignCodeListC2C.ProductFilterGroup] as ProductFilterGroup[];

        const newRebarFilters = this.rebarFilters?.filter((filter) => filter.id != filterId);
        const oldRebarFilters = this.rebarFilterGroups.flatMap(grp => grp.items);

        const newRebarFilterGroups = rebarFilterGroups.map((rebarFilterGroup) => new SelectRebarFilterGroup({
            id: rebarFilterGroup.id,
            items: rebarFilters.filter((rebarFilter) => rebarFilter.filterGroupId == rebarFilterGroup.id).map((rebarFilter) => new SelectRebarFilterGroupItem({
                id: rebarFilter.id,
                nameKey: rebarFilter.displayKey,
                textKey: rebarFilter.nameResourceKey,
                selected: newRebarFilters != null && newRebarFilters.find((filter) => filter.id == rebarFilter.id) != null,
                disabled: oldRebarFilters.find(old => old.id == rebarFilter?.id)?.disabled
            }))
        }));

        const filteredRebars = this.filterRebars(newRebarFilterGroups);

        if (this.selectedProductId != null && !filteredRebars.map(rebar => rebar.id).includes(this.selectedProductId)) {
            this.modal.openConfirmChange({
                id: 'anchor-not-on-filtered-list',
                title: this.translate('Agito.Hilti.C2C.InfoSection.ConfirmRebarFiltering.Title'),
                message: this.translate('Agito.Hilti.C2C.InfoSection.ConfirmRebarFiltering.Message'),
                confirmButtonText: this.translate('Agito.Hilti.C2C.InfoSection.ConfirmRebarFiltering.Confirm'),
                cancelButtonText: this.translate('Agito.Hilti.C2C.InfoSection.ConfirmRebarFiltering.Cancel'),
                onConfirm: (modal) => {
                    modal.close();
                    this.applyFilters(newRebarFilters ?? [], newRebarFilterGroups);
                },
                onCancel: (modal) => {
                    modal.close();
                }
            });
        }
        else {
            this.applyFilters(newRebarFilters ?? [], newRebarFilterGroups);
        }
    }

    public getFavoriteSpriteRebar(rebarId: number) {
        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.design.region.id)) {
            return 'pe-ui-c2c-sprite-favorite-false disabled';
        }

        return hasProperty(this.userSettingsService?.settings?.rebarFavorites?.value ?? {}, rebarId.toString())
            ? 'pe-ui-c2c-sprite-favorite-true'
            : 'pe-ui-c2c-sprite-favorite-false';
    }

    public favoriteToggle(rebarId: number) {
        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.design.region.id)) {
            return;
        }

        if (hasProperty(this.userSettingsService?.settings?.rebarFavorites?.value ?? {}, rebarId.toString())) {
            this.userSettingsService.settings.rebarFavorites.value = Object.fromEntries(Object.entries(
                this.userSettingsService?.settings?.rebarFavorites?.value ?? {}).filter(([favoriteId]) => favoriteId != rebarId.toString()));
        }
        else {
            this.userSettingsService.settings.rebarFavorites.value = {
                ...this.userSettingsService.settings.rebarFavorites.value,
                [rebarId]: undefined
            };
        }

        this.userSettingsService.save();
    }

    public setProduct(productId: number) {
        if (this.design.isReadOnlyDesignMode) {
            return;
        }

        this.calculationService.calculateAsync(this.design,
            (design) => {
                design.model[this.design.globalMetaProperties.sourceMetaProperty] = productId;
            }
        );
    }

    public translate(key?: string) {
        return this.localization.getString(key ?? '');
    }

    public getProductFamilyId() {
        return this.design?.rebarFamily?.id;
    }

    public openApproval(isUKTAApproval = false) {
        const files = isUKTAApproval ?
            this.uktaApprovalFiles :
            this.user.design.model[this.design.globalMetaProperties.sourceMetaPropertyApproval] as string[];

        const approvalUrls = isUKTAApproval ?
            this.user.design.model[PropertyMetaDataC2C.Product_C2C_UKTAApprovalUrls.id] as string[] :
            this.user.design.model[PropertyMetaDataC2C.Product_C2C_ApprovalUrls.id] as string[];

        if (files.length > 1) {
            this.modal.openMultipleApprovals(files, approvalUrls);
        }
        else {
            this.design.usageCounterC2C.Approval++;

            const approvalInfo = ApprovalHelper.getApprovalInfo(approvalUrls[0], files[0]);
            this.offline.nativeLocalPathOpen(approvalInfo.url, approvalInfo.name, true, true);
        }
    }

    public openUKTAApproval() {
        this.openApproval(true);
    }

    public onInfoSectionCollapse(collapsed: boolean) {
        this.collapsedChange.emit(collapsed);
    }

    public runCalculation() {
        this.calculationService.calculateAsync(this.design);
    }

    public shortTag(tag: string) {
        return tag != null && tag.length > 0
            ? tag.charAt(0)
            : undefined;
    }

    public shortTagC2C(tested: boolean) {
        return !tested ?
            this.translate('Agito.Hilti.C2C.Rebars.Untested.Tag').charAt(0)
            : undefined;
    }

    public selectedRebarTag(type: string): DropdownTag[] {
        const codeList = type == 'type' ? DesignCodeListC2C.FastenerType : DesignCodeListC2C.FastenerSize;
        const propertyId = type == 'type' ? PropertyMetaDataC2C.Product_C2C_RebarType.id : PropertyMetaDataC2C.Product_C2C_RebarSize.id;
        const items = this.getCodeListDropdownItems(this.getC2CCodeListItems(codeList, propertyId), false);
        const selectedItem = items.filter(x => x.value == this.design.model[propertyId])[0];

        return selectedItem?.tags ?? [];
    }

    public getPrecision(uiProperty: UIPropertyC2C) {
        return this.unit.getPrecision(this.design.unitLength, uiProperty);
    }

    public trackFilterById: TrackByFunction<ProductFilterDisplay> = (_: number, filter: ProductFilterDisplay) => filter.id;

    public trackRebarById: TrackByFunction<IRebar> = (_: number, rebar: IRebar) => rebar.id;

    private initControls() {
        this.typeConcreteDropdown = {
            title: this.translate('Agito.Hilti.C2C.InfoSection.ConnectorSection.Type.Title'),
            items: this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.FastenerType, PropertyMetaDataC2C.Product_C2C_ConnectorType.id)),
            tooltip: {
                title: this.translate('Agito.Hilti.C2C.Connector.Type.Tooltip'),
                content: this.design.isC2CHNA
                    ? this.translate('Agito.Hilti.C2C.Connector.Type.Tooltip.HNA')
                    : this.translate('Agito.Hilti.C2C.Connector.Type.Tooltip')
            },
            tags: []
        };

        this.sizeConcreteDropdown = {
            title: this.translate('Agito.Hilti.C2C.InfoSection.ConnectorSection.Size.Title'),
            items: this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.FastenerSize, PropertyMetaDataC2C.Product_C2C_ConnectorSize.id)),
            tooltip: {
                title: this.design.isC2CHNA
                    ? this.translate('Agito.Hilti.C2C.Connector.Size.Title.HNA')
                    : this.translate('Agito.Hilti.C2C.Connector.Size.Title.Tooltip'),
                content: this.design.isC2CHNA
                    ? this.translate('Agito.Hilti.C2C.Connector.Size.Tooltip.HNA')
                    : this.translate('Agito.Hilti.C2C.Connector.Size.Title.Tooltip')
            },
            tags: []
        };

        this.anchorLengthDropdown = {
            title: this.translate('Agito.Hilti.C2C.InfoSection.AnchorLength.Title'),
            items: this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.ConnectorLength, PropertyMetaDataC2C.Product_C2C_AnchorLength.id))
        };

        this.embedmentDepthTypeConcreteToggleButtonGroup = {
            id: 'product-portfolio-connector-embedment-depth-type',
            items: this.getToggleButtonGroupItemsFromCodeList(
                this.getC2CCodeListItems(DesignCodeListC2C.EmbedmentDepthMode, PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id),
                true,
                'product-portfolio-connector-embedment-depth-type',
                this.design.properties.get(PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id).disabled,
                PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id
            )
        };

        this.embedmentDepthValueDropdownC2C = {
            items: this.getDropdownItems(this.getEmbedmentDepthSelectionCodeListItems()),
            notSelectedText: ''
        };

        this.embedmentDepthNewValueDropdownC2C = {
            items: this.getDropdownItems(this.getEmbedmentDepthNewSelectionCodeListItems()),
            notSelectedText: ''
        };

        if (this.design.isC2CHNA) {
            this.rebarTypeDropdown = {
                title: this.translate('Agito.Hilti.C2C.Connector.Type.Title'),
                items: this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.FastenerType, PropertyMetaDataC2C.Product_C2C_RebarType.id)),
                notSelectedText: '',
                tooltip: {
                    title: this.translate('Agito.Hilti.C2C.Connector.Type.Tooltip'),
                    content: this.translate('Agito.Hilti.C2C.Connector.Type.Tooltip.HNA')
                },
                tags: []
            };

            this.rebarStandardDropdown = {
                title: this.translate('Agito.Hilti.C2C.Product.RebarStandard.Title'),
                items: this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.RebarMaterial, this.getC2CRebarStandardUiPropertyId())),
                notSelectedText: '',
                tooltip: {
                    title: this.translate('Agito.Hilti.C2C.Product.RebarStandard.Title'),
                    content: this.translate('Agito.Hilti.C2C.Product.RebarStandard.Title')
                },
                tags: []
            };

            this.rebarSizeDropdown = {
                title: this.translate('Agito.Hilti.C2C.Connector.Size.Title'),
                items: this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.FastenerSize, PropertyMetaDataC2C.Product_C2C_RebarSize.id)),
                notSelectedText: '',
                tooltip: {
                    title: this.translate('Agito.Hilti.C2C.Connector.Size.Tooltip'),
                    content: this.translate('Agito.Hilti.C2C.Connector.Size.Tooltip.HNA')
                }
            };
        }
    }

    private initTooltips() {
        this.articleNumberMechanicalTooltip = {
            title: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberMechanical.Tooltip.Title'),
            content: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberMechanical.Tooltip')
        };

        this.filterButtonTooltip = this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.SelectAnchor.Tooltip.Title');
    }

    private updateProductNameText() {
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        this.rebars.forEach(rebarData => {
            const rebar = this.design.fastenerFamilies.find((r) => r.id == rebarData.id);
            rebarData.name = rebar?.getTranslatedNameText(codeListDeps) as string;
        });
    }

    private numberOfFiltersPerGroup(productFilters: RebarFilterEntity[] | RebarFilterDisplay[]) {
        return productFilters?.length > 0
            ? (productFilters as any[])
                .reduce((obj: IFiltersGroupNumber, a: RebarFilterEntity | RebarFilterDisplay) =>
                    ({ ...obj, [a.filterGroupId as number]: obj[a.filterGroupId as number] == null ? 1 : obj[a.filterGroupId as number] + 1 }),
                    {} as IFiltersGroupNumber
                )
            : 0;
    }

    private updateDisplayedFilters() {
        if (this.rebarFilters != null) {
            const filtersPerGroup = this.numberOfFiltersPerGroup(this.design.designData.designCodeListsC2C[DesignCodeListC2C.ProductFilter] as RebarFilterEntity[]);
            const selectedPerGroup = this.numberOfFiltersPerGroup(this.rebarFilters);

            this.displayedRebarFilters = this.rebarFilters.filter(a =>
                filtersPerGroup[a.filterGroupId as number] == null
                || selectedPerGroup[a.filterGroupId as number] < filtersPerGroup[a.filterGroupId as number]
                || filtersPerGroup[a.filterGroupId as number] == 1
            );
        }
    }

    private resizeInfoSection(recalculateHeight: boolean) {
        this.calculateRebarsMinHeight();

        if (!this.collapsed) {
            this.setInfoSectionHeight(recalculateHeight);
        }
    }

    private calculateRebarsMinHeight() {
        if (this.rebars && this.rebars.length > 0) {
            this.calculateRebarElementHeights();

            const productLists = (this.elementRef.nativeElement.shadowRoot?.querySelector('.info-section-anchor-detailed-anchors') as HTMLElement);
            const productListChildren = productLists?.children ?? ([] as HTMLElement[]);
            if (this.rebars.length >= 1 && productLists?.style) {
                productLists.style.minHeight = Math.min(this.rebars.length, 2) * ((productListChildren[0] as HTMLElement).offsetHeight) + 'px';
            }
        }
    }

    private calculateRebarElementHeights() {
        const rebarList = (this.elementRef.nativeElement.shadowRoot?.querySelectorAll('.info-section-anchor-detailed-anchor-select-button-container') as NodeListOf<HTMLElement>);
        rebarList.forEach((rebarElt) => {
            const rebarNameElements = (rebarElt.getElementsByClassName('info-section-anchor-detailed-anchor-name') as HTMLCollectionOf<HTMLElement>);
            if (rebarNameElements.length > 0) {
                rebarElt.style.height = (rebarNameElements[0].offsetHeight + 6) + 'px';
            }
        });
    }

    private setInfoSectionHeight(recalculateHeight: boolean) {
        const infoSection = this.infoSectionContainer;

        if (recalculateHeight) {
            let neededHeight = 4 /* resizer handle */ + 5 /* top + bottom border + reserve */;

            // Header
            const header = (infoSection?.getElementsByClassName('box-section-header') as HTMLCollectionOf<HTMLElement>);
            if (header.length > 0) {
                neededHeight += header[0].offsetHeight;
            }

            const infoContent = (this.elementRef.nativeElement.shadowRoot?.querySelector('#info-section-content') as HTMLElement)?.children;
            if (infoContent != null) {
                // Content (without filler and products)
                for (let i = 0; i < infoContent.length; i++) {
                    if (infoContent[i].classList.contains('info-section-space-filler')) {
                        // Skip filler
                        continue;
                    }

                    if (infoContent[i].classList.contains('info-section-anchor-detailed-anchors')) {
                        // Height of all anchors will be calculated separately
                        continue;
                    }

                    neededHeight += (infoContent[i] as HTMLElement).offsetHeight;
                }

                // Products
                const productLists = (infoSection?.getElementsByClassName('info-section-anchor-detailed-anchors') as HTMLCollectionOf<HTMLElement>);
                for (let i = 0; i < productLists.length; i++) {
                    if (productLists[i].children) {
                        const products = Array.from(productLists[i].children) as HTMLElement[];
                        products.forEach(product => neededHeight += product.offsetHeight);
                    }
                }
            }

            this.infoSectionHeight = neededHeight;
        }

        infoSection.style.height = this.infoSectionHeight + 'px';
    }

    private loadProducts() {
        let rebars = this.design.fastenerFamilies;
        const allowedValues = this.design.properties.get(this.design.globalMetaProperties.sourceMetaProperty).allowedValues;

        if (allowedValues != null) {
            rebars = rebars.filter((rebar) => allowedValues.includes(rebar.id ?? 0));
        }

        const rebarIds = rebars.map((rebar) => rebar.id);

        this.setRebarsData(rebarIds);
        this.refreshProducts();
    }

    private async applyFilters(productFilters: RebarFilterDisplay[], productFilterGroups: SelectRebarFilterGroup[]) {
        this.rebarFilters = productFilters;
        this.rebarFilterGroups = productFilterGroups ?? [];

        this.design.one(DesignEvent.calculate, () => {
            this.refreshProducts();
        });

        this.setFiltersToProperties();

        await this.calculationService.calculateAsync(this.design);

        this.updateDisplayedFilters();
    }

    private setFiltersToProperties() {
        const filteredGroups = this.rebarFilterGroups.filter((filterGroup) => filterGroup.items.some((filterItem) => filterItem.selected));
        this.design.model[PropertyMetaDataC2C.Product_C2C_ProductFilters.id] = {
            holeDiameterMin: this.holeDiameterMin,
            holeDiameterMax: this.holeDiameterMax,
            checkboxFilters: filteredGroups.flatMap(group => group.items.filter(item => item.selected).map(item => item.id)),
        } as UIProductFilterInputC2C;
    }

    private getDropdownItems(codeListItems: CodeListC2C[]) {
        return this.getCodeListDropdownItems(codeListItems, true);
    }

    private getCodeListDropdownItems(codeListItems: CodeListC2C[], useShortTag: boolean) {
        const retVal: DropdownItem<number>[] = [];
        if (codeListItems != null) {
            const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

            for (const codeListItem of codeListItems) {
                const tags: DropdownTag[] = [];
                if (codeListItem.tag != null && codeListItem.tag.length > 0) {
                    const tag = this.translate(codeListItem.tag);
                    tags.push({
                        type: TagType.Tag,
                        text: useShortTag ? this.shortTag(tag) : tag
                    });
                }

                retVal.push({
                    value: codeListItem.id as number,
                    text: codeListItem.getTranslatedNameText(codeListDeps) ?? '',
                    tags
                });
            }
        }

        return retVal;
    }

    private getC2CCodeListItems(codeListProperty: DesignCodeListC2C, targetProperty: number) {
        let items = this.design.designData.designCodeListsC2C[codeListProperty];
        const allowedValues = this.design.properties.get(targetProperty).allowedValues;

        if (items != null && allowedValues != null) {
            items = items.filter(item => allowedValues.includes(item?.id ?? 0));
        }

        return items;
    }

    private getEmbedmentDepthSelectionCodeListItems() {
        let items = this.design.designData.designCodeListsC2C[DesignCodeListC2C.EmbedmentDepthExisting] as EmbedmentDepthExisting[];
        const allowedValues = this.design.properties.get(PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthSelection.id).allowedValues;

        if (items != null && allowedValues != null) {
            items = items.filter(item => allowedValues.includes(item?.id ?? 0));
        }

        return items;
    }

    public getEmbedmentDepthNewSelectionCodeListItems() {
        let items = this.design.designData.designCodeListsC2C[DesignCodeListC2C.EmbedmentDepthOverlay] as EmbedmentDepthOverlay[];
        const allowedValues = this.design.properties.get(PropertyMetaDataC2C.Product_C2C_OverlayEmbedmentDepthNewSelection.id).allowedValues;

        if (items != null && allowedValues != null) {
            items = items.filter(item => allowedValues.includes(item?.id ?? 0));
        }

        return items;
    }

    private getToggleButtonGroupItemsFromCodeList(
        codeList: CodeListC2C[] = [],
        hideChildDisplayText = false,
        controlId?: string,
        controlDisabled = false,
        uiPropertyId?: number
    ) {
        const toggleButtonGroupItems: ToggleButtonGroupItem[] = [];
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        for (const codeListItem of codeList) {
            toggleButtonGroupItems.push({
                id: controlId ? `${controlId}-${codeListItem.id}` : undefined,
                value: codeListItem.id,
                text: hideChildDisplayText ? undefined : codeListItem.getTranslatedNameText(codeListDeps),
                image: codeListItem.image != null && codeListItem.image != '' ? getSpriteAsIconStyle(codeListItem.image as Sprite) : {},
                tooltip: this.getToggleButtonGroupItemTooltip(codeListItem, controlDisabled, uiPropertyId),
                disabled: controlDisabled
            });
        }

        return toggleButtonGroupItems;
    }

    private getToggleButtonGroupItemTooltip(codeListItem: CodeListC2C | undefined, controlDisabled: boolean, uiPropertyId?: number) {
        if (codeListItem == null) {
            return undefined;
        }

        let title = '';
        const titleKey = controlDisabled
            ? codeListItem.tooltipDisabledTitleDisplayKey
            : codeListItem.tooltipTitleDisplayKey;
        if (titleKey != null && titleKey != '') {
            title = this.translate(titleKey);
        }

        let content = '';
        const contentKey = controlDisabled
            ? codeListItem.tooltipDisabledDisplayKey
            : codeListItem.tooltipDisplayKey;
        if (contentKey != null && contentKey != '') {
            content = this.formatDisplayKey(contentKey, uiPropertyId);
        }

        if (title != null && title != '' || content != null && content != '') {
            return {
                title,
                content
            };
        }

        return undefined;
    }

    private formatDisplayKey(displayKey: string, uiPropertyId?: number): string {
        if (uiPropertyId) {
            if (uiPropertyId == PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id) {
                if (this.design.isC2CHNA && this.design.isC2COverlay && this.design.loadCombinationsC2C && this.design.loadCombinationsC2C.length > 1) {
                    return this.translate('Agito.Hilti.C2C.Features.Design.Optimized.LoadCombination.Tooltip');
                }

                if (this.design.properties.get(PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id).disabled) {
                    return '';
                }

                return this.translate(this.design.isC2CHNA ? displayKey + '.HNA' : displayKey);
            }
        }

        return this.translate(displayKey);
    }

    private getC2CRebarStandardUiPropertyId(): number {
        return this.design.isC2COverlay ? PropertyMetaDataC2C.Overlay_C2C_Product_RebarStandard.id : PropertyMetaDataC2C.ExistingStructure_C2C_Product_RebarStandard.id;
    }

    private setItemsFromCodeList() {
        this.typeConcreteDropdown.items = this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.FastenerType, PropertyMetaDataC2C.Product_C2C_ConnectorType.id));
        this.sizeConcreteDropdown.items = this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.FastenerSize, PropertyMetaDataC2C.Product_C2C_ConnectorSize.id));
        this.anchorLengthDropdown.items = this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.ConnectorLength, PropertyMetaDataC2C.Product_C2C_AnchorLength.id));
        this.embedmentDepthValueDropdownC2C.items = this.getDropdownItems(this.getEmbedmentDepthSelectionCodeListItems());
        this.embedmentDepthNewValueDropdownC2C.items = this.getDropdownItems(this.getEmbedmentDepthNewSelectionCodeListItems());

        if (this.design.isC2CHNA) {
            this.rebarTypeDropdown.items = this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.FastenerType, PropertyMetaDataC2C.Product_C2C_RebarType.id));
            this.rebarStandardDropdown.items = this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.RebarMaterial, this.getC2CRebarStandardUiPropertyId()));
            this.rebarSizeDropdown.items = this.getDropdownItems(this.getC2CCodeListItems(DesignCodeListC2C.FastenerSize, PropertyMetaDataC2C.Product_C2C_RebarSize.id));
        }
    }

    private allowedValuesChanged() {
        if (!this.productHidden) {
            this.setItemsFromCodeList();
            this.getSavedGroupsAndFilters();
            this.loadProducts();
        }
    }

    public updateDropdownAnchorEmbedmentDepthText() {
        if (this.anchorLengthDropdown.items != null) {
            for (const item of this.anchorLengthDropdown.items) {
                const codeListItem = (this.design.designData.designCodeListsC2C[DesignCodeListC2C.ConnectorLength] as ConnectorLengthC2C[]).find(cli => cli.id == item.value);
                item.text = this.getConnectorLengthText(codeListItem, this.design.unitLength);
            }
        }

        if (this.embedmentDepthValueDropdownC2C.items != null) {
            for (const item of this.embedmentDepthValueDropdownC2C.items) {
                const codeListItem = (this.design.designData.designCodeListsC2C[DesignCodeListC2C.EmbedmentDepthExisting] as EmbedmentDepthExisting[]).find(cli => cli.id == item.value);
                item.text = this.getEmbedmentDepthTextC2C(codeListItem, this.design.unitLength);
            }
        }

        if (this.embedmentDepthNewValueDropdownC2C.items != null) {
            for (const item of this.embedmentDepthNewValueDropdownC2C.items) {
                const codeListItem = (this.design.designData.designCodeListsC2C[DesignCodeListC2C.EmbedmentDepthOverlay] as EmbedmentDepthOverlay[]).find(cli => cli.id == item.value);
                item.text = this.getEmbedmentDepthTextC2C(codeListItem, this.design.unitLength);
            }
        }
    }

    private getConnectorLengthText(codeListItem?: ConnectorLengthC2C, unit?: Unit) {
        if (codeListItem == null) {
            return '';
        }

        unit = unit || this.design.unitLength;

        const defaultUnit: Unit = unit;
        const internalUnit = this.unit.getInternalUnit(UnitGroup.Length);

        return this.unit.formatUnitValueArgs(this.unit.convertUnitValueArgsToUnit(codeListItem.length ?? 0, internalUnit, defaultUnit), defaultUnit);
    }

    private getEmbedmentDepthTextC2C(codeListItem?: EmbedmentDepthExisting | EmbedmentDepthOverlay, unit?: Unit) {
        if (codeListItem == null) {
            return '';
        }

        unit = unit || this.design.unitLength;

        const defaultUnit: Unit = unit;
        const internalUnit = this.unit.getInternalUnit(UnitGroup.Length);

        return this.unit.formatUnitValueArgs(this.unit.convertUnitValueArgsToUnit(codeListItem.depth ?? 0, internalUnit, defaultUnit), defaultUnit);
    }

    private updateToggleButtonGroupItemsConnectorEmbedmentDepthMode() {
        if (this.embedmentDepthTypeConcreteToggleButtonGroup.items != null) {
            const disabled = this.design.properties.get(PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id).disabled;
            for (const item of this.embedmentDepthTypeConcreteToggleButtonGroup.items) {
                const codeListItem = (this.design.designData.designCodeListsC2C[DesignCodeListC2C.EmbedmentDepthMode]).find(codeList => codeList.id == item.value);
                item.tooltip = this.getToggleButtonGroupItemTooltip(codeListItem, disabled, PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id);
                item.disabled = this.design.properties.get(PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id).disabled;
            }
        }
    }

    private setRebarsData(rebarIds?: (number | undefined)[]) {
        const rebars = this.design.fastenerFamilies.filter((fastener) => rebarIds?.some((rebarId) => rebarId == fastener.id));
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        this.rebarsData = rebars.map((rebar): IRebar => ({
            id: rebar.id as number,
            defaultSortOrder: rebar.sortOrder as number,
            image: rebar.image as string,
            tested: rebar.tested ?? false,
            name: rebar.getTranslatedNameText(codeListDeps),
            tag: rebar.tag != null && rebar.tag.length > 0 ? this.translate(rebar.tag) : '',
            allowedInProductFilters: rebar.allowedFilterInputs ?? {},
            holeDiameters: rebar.holeDiameters ?? []
        }));
    }

    private refreshProducts() {
        this.rebars = this.filterRebars();
        this.initializeAnchorFamilySprites();

        setTimeout(() => {
            this.resizeInfoSection(true);
        });
    }

    private filterRebars(rebarFilterGroups?: SelectRebarFilterGroup[]) {
        if (rebarFilterGroups == null) {
            rebarFilterGroups = this.rebarFilterGroups;
        }

        let rebars = this.rebarsData.slice();

        // allowed valies
        const allowedValues = this.design.properties.get(this.design.globalMetaProperties.sourceMetaProperty).allowedValues;
        if (allowedValues != null) {
            rebars = rebars.filter((rebar) => allowedValues.includes(rebar.id));
        }

        // search filter - min max
        rebars = this.filterByMinMax(rebars, this.holeDiameterMin, this.holeDiameterMax);

        // checkbox filters
        rebars = this.filterRebarsByCheckboxes(rebars, rebarFilterGroups);

        // sort by
        rebars = this.sortRebarFamilyBy(rebars ?? [], SelectRebarSortBy.default);

        return rebars;
    }

    private filterByMinMax(rebars: IRebar[], holeDiameterMin?: number, holeDiameterMax?: number) {
        return rebars.filter(r => {
            if (holeDiameterMin != null && holeDiameterMax != null) {
                if (r.holeDiameters.length == 0 || r.holeDiameters.every((holeDiameter) => (holeDiameter < holeDiameterMin) || (holeDiameter > holeDiameterMax))) {
                    return false;
                }
            }
            else if (holeDiameterMin != null) {
                if (r.holeDiameters.length == 0 || r.holeDiameters.every((holeDiameter) => holeDiameter < holeDiameterMin)) {
                    return false;
                }
            }
            else if (holeDiameterMax != null) {
                if (r.holeDiameters.length == 0 || r.holeDiameters.every((holeDiameter) => holeDiameter > holeDiameterMax)) {
                    return false;
                }
            }

            return true;
        });
    }

    private filterRebarsByCheckboxes(rebars: IRebar[], rebarFilterGroups: SelectRebarFilterGroup[]) {
        if (rebarFilterGroups == null) {
            return rebars;
        }

        const selectedRebarGroups = rebarFilterGroups.filter((filterGroup) => filterGroup.items.some((filterItem) => filterItem.selected));
        return rebars.filter(anchorDetailed => {
            const allowed = anchorDetailed.allowedInProductFilters;

            const holeDiameterMin = this.holeDiameterMin;
            const holeDiameterMax = this.holeDiameterMax;

            // is allowed in 'or' groups
            return selectedRebarGroups.every(groups => {
                const allowedGroup = groups.items.filter((item) => item.selected);

                return allowedGroup.some(item => {
                    if (item.nameKey != null && allowed[item.nameKey]) {
                        if (holeDiameterMin != null && holeDiameterMax != null) {
                            if (allowed[item.nameKey].length == 0 || allowed[item.nameKey].every((holeDiameter) => (holeDiameter < holeDiameterMin || holeDiameter > holeDiameterMax))) {
                                return false;
                            }
                        }
                        else if (holeDiameterMin != null) {
                            if (allowed[item.nameKey].length == 0 || allowed[item.nameKey].every((holeDiameter) => holeDiameter < holeDiameterMin)) {
                                return false;
                            }
                        }
                        else if (holeDiameterMax != null) {
                            if (allowed[item.nameKey].length == 0 || allowed[item.nameKey].every((holeDiameter) => holeDiameter > holeDiameterMax)) {
                                return false;
                            }
                        }

                        return true;
                    }

                    return false;
                });
            });
        });
    }

    private sortRebarFamilyBy(rebars: IRebar[], selectBy: SelectRebarSortBy) {
        if (selectBy == null) {
            return rebars ?? [];
        }

        switch (selectBy) {
            case SelectRebarSortBy.default:
                return sortBy(rebars,
                    rebar => hasProperty(this.userSettingsService?.settings?.rebarFavorites?.value ?? {}, rebar.id.toString()) ? 0 : 1,
                    rebar => rebar.defaultSortOrder);

            case SelectRebarSortBy.name:
                return sortBy(rebars,
                    rebar => hasProperty(this.userSettingsService?.settings?.rebarFavorites?.value ?? {}, rebar.id.toString()) ? 0 : 1,
                    rebar => rebar.name.toLowerCase());

            default:
                throw new Error('Invalid sort by.');
        }
    }

    private getSavedGroupsAndFilters() {
        const rebarFilters = this.design.designData.designCodeListsC2C[DesignCodeListC2C.ProductFilter] as RebarFilterEntity[];
        const rebarFilterGroups = this.design.designData.designCodeListsC2C[DesignCodeListC2C.ProductFilterGroup] as ProductFilterGroup[];

        const savedRebarFilters = this.design.model[PropertyMetaDataC2C.Product_C2C_ProductFilters.id] as UIProductFilterInputC2C;

        this.rebarFilters = savedRebarFilters != null ? savedRebarFilters.checkboxFilters.map((checkboxFilter): RebarFilterDisplay => ({
            id: checkboxFilter,
            title: rebarFilters.find(filter => filter.id == checkboxFilter)?.nameResourceKey,
            filterGroupId: rebarFilters.find(filter => filter.id == checkboxFilter)?.filterGroupId
        })) : undefined;

        this.holeDiameterMin = savedRebarFilters != null ? savedRebarFilters.holeDiameterMin : undefined;
        this.holeDiameterMax = savedRebarFilters != null ? savedRebarFilters.holeDiameterMax : undefined;

        if (this.holeDiameterMin != null) {
            this.rebarFilters = this.rebarFilters || [];

            this.rebarFilters.push({
                id: 1002,
                title: 'Agito.Hilti.Profis3.SelectAnchor.HoleDiameterMin',
                filterGroupId: -1
            });
        }

        if (this.holeDiameterMax != null) {
            this.rebarFilters = this.rebarFilters || [];

            this.rebarFilters.push({
                id: 1003,
                title: 'Agito.Hilti.Profis3.SelectAnchor.HoleDiameterMax',
                filterGroupId: -1
            });
        }

        // create filter structure
        this.rebarFilterGroups = rebarFilterGroups?.map((rebarFilterGroup) => new SelectRebarFilterGroup({
            id: rebarFilterGroup.id,
            items: rebarFilters.filter((rebarFilter) => rebarFilter.filterGroupId == rebarFilterGroup.id).map((rebarFilter) => new SelectRebarFilterGroupItem({
                id: rebarFilter.id,
                textKey: rebarFilter.nameResourceKey,
                nameKey: rebarFilter.displayKey,
                selected: this.rebarFilters != null && this.rebarFilters.find((filter) => filter.id == rebarFilter.id) != null,
            }))
        }));

        this.updateDisplayedFilters();
        this.filtersOpened = this.rebarFilters != null && this.displayedRebarFilters.length < 5;
    }

    private onUserSettingsSaved() {
        // Rebar favorites
        const rebarFavorites = this.userSettingsService.settings.rebarFavorites.value;
        if (!isEqual(this.oldRebarFavorites, rebarFavorites)) {
            this.refreshProducts();
            this.oldRebarFavorites = clone(rebarFavorites);
        }
    }

    private onCollapsedChanged(value: boolean) {
        const el = this.infoSectionContainer;
        if (value) {
            // clear custom height on element when closed
            el.style.height = '';
        }
        else {
            // set size to include just header ...
            el.style.height = '34px';
            setTimeout(() => {
                // and recalculate height.
                el.style.height = '';
                this.resizeInfoSection(true);
            });
        }
    }

    private initializeAnchorFamilySprites() {
        this.rebars.forEach(rebar => {
            if (rebar.image && !this.initializedSpriteImages.includes(rebar.image)) {
                includeSprites(this.elementRef.nativeElement.shadowRoot, rebar.image as Sprite);
                this.initializedSpriteImages.push(rebar.image);
            }
        });
    }
}

