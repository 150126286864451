import { CodeList } from './code-list';
import { TemperatureInstallationEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class TemperatureInstallation extends CodeList {

    public static fromService(codeList: TemperatureInstallationEntityC2C) {
        return new TemperatureInstallation(CodeList.baseFromService('TemperatureInstallationEntity', codeList));
    }

    public override cloneDeep(): TemperatureInstallation {
        return super.cloneDeep() as TemperatureInstallation;
    }
}
