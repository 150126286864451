import { EnvironmentHelper } from '@profis-engineering/pe-ui-common/helpers/environment-helper';
import { QueryStringHelper } from '@profis-engineering/pe-ui-common/helpers/query-string-helper';
import type schema from './environment.local.json';

export type Environment = typeof schema & {
    baseUrl: string;
    debugGlModel: boolean;
    /** contains files hashes like translations */
    manifest?: Record<string, string>;
};

const qsHelper = new QueryStringHelper(window.location.search);

if (window.environmentSP) {
    window.environmentSP.webassembly = window.environmentSP.webassembly || qsHelper.getBoolean('wasm');
}

export const environment: Environment = getEnvironmentSettings();

// setBaseUrl
environment.baseUrl = document.getElementsByTagName('base')[0]?.href ?? '/';

if (qsHelper.getBoolean('sphtmlreport')) {
    environment.exportHtmlReport = true;
}

// set debug
if (qsHelper.getBoolean('debug')) {
    environment.debugGlModel = true;
}

declare global {
    interface Window {
        environmentSP: Environment;
    }
}

function getEnvironmentSettings(): Environment {
    const env: Environment = window.environmentSP;
    const helper = new EnvironmentHelper(window.location.host);
    const [serviceVersion] = helper.getServiceVersions();

    if (serviceVersion != '') {
        env.htmlReportServiceUrl = helper.formatServiceVersion(env.htmlReportServiceUrl, serviceVersion);

        // this is required only when not using webassembly for application
        if (!env.webassembly) {
            env.designServiceUrl = helper.formatServiceVersion(env.designServiceUrl, serviceVersion);
            env.calculationServiceUrl = helper.formatServiceVersion(env.calculationServiceUrl, serviceVersion);
            env.reportServiceUrl = helper.formatServiceVersion(env.reportServiceUrl, serviceVersion);
        }
    }

    return env ?? {};
}
