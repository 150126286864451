import { ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    FastenerSizeEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class FastenerSize extends CodeList {
    public size?: number;
    public displayName?: string;
    public tested?: boolean;

    constructor(codeList: FastenerSizeEntityC2C) {
        super(codeList);

        if (codeList != null) {
            this.size = codeList.size;
            this.displayName = codeList.displayName;
            this.tested = codeList.tested;
            this.name = codeList.displayName;
        }
    }

    public static fromService(codeList: FastenerSizeEntityC2C) {
        return new FastenerSize(CodeList.baseFromService('FastenerSizeEntity', codeList, {
            id: codeList.id,
            size: codeList.size,
            displayName: codeList.displayName,
            tested: codeList.tested,
            displayKey: codeList.displayKey
        }));
    }

    public override cloneDeep(): FastenerSize {
        const codeList = super.cloneDeep() as FastenerSize;

        codeList.size = this.size;
        codeList.displayName = this.displayName;
        codeList.tested = this.tested;

        return codeList;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayName;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
