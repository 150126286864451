import {
    OverstrengthEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class Overstrength extends CodeList {

    public static fromService(codeList: OverstrengthEntityC2C) {
        return new Overstrength(CodeList.baseFromService('OverstrengthEntity', codeList, {
            name: codeList.displayKey
        }));
    }

    public override cloneDeep(): Overstrength {
        return super.cloneDeep() as Overstrength;
    }
}
