import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ApprovalHelper, IApprovalInfo } from '../../helpers/approval-helper';
import { LocalizationService } from '../../services/localization.service';
import { OfflineService } from '../../services/offline.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

interface IMultipleApprovalsComponentInput {
    files: string[];
    availableLanguages?: string[][];
}

@Component({
    templateUrl: './multiple-approvals.component.html',
    styleUrls: ['./multiple-approvals.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MultipleApprovalsComponent {
    @Input()
    public modalInstance!: ModalInstance<IMultipleApprovalsComponentInput>;

    public get files(): string[] {
        return this.modalInstance.input?.files ?? [];
    }

    private get availableLanguages(): string[][] {
        return this.modalInstance.input?.availableLanguages ?? [];
    }


    constructor(
        private readonly user: UserService,
        private readonly userSettingsService: UserSettingsService,
        private readonly offline: OfflineService,
        private readonly localizationService: LocalizationService
    ) { }


    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public openApproval(index: number) {
        this.user.design.usageCounter.Approval++;
        const approvalInfo = this.getApprovalInfo(index);
        this.offline.nativeLocalPathOpen(approvalInfo.url, approvalInfo.name, true, true);
    }

    private getApprovalInfo(index: number): IApprovalInfo {
        const culture = this.userSettingsService.getLanguage().culture ?? '';
        let approvalLang = '';

        if (this.availableLanguages.length > index) {
            approvalLang = ApprovalHelper.getApprovalLanguage(this.availableLanguages[index], culture);
        }
        else {
            const cultureParts = culture?.toUpperCase().split('-');
            approvalLang = cultureParts[0];

            if (!this.availableLanguages.some(row => row.includes(approvalLang))) {
                approvalLang = ApprovalHelper.defaultApprovalLanguage;
            }
        }

        return ApprovalHelper.getApprovalInfo(approvalLang, this.files[index]);
    }
}
