import { CodeList } from './code-list';
import { DrillingTypeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class DrillingType extends CodeList {

    public static fromService(codeList: DrillingTypeEntityC2C) {
        return new DrillingType(CodeList.baseFromService('DrillingTypeEntity', codeList));
    }

    public override cloneDeep(): DrillingType {
        return super.cloneDeep() as DrillingType;
    }
}
