import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ApiOptions, ApiServiceBase } from '@profis-engineering/pe-ui-common/services/api.common';
import { QueryService } from './query.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
    private baseService!: ApiServiceBase;

    private queryService: QueryService;

    constructor(queryService: QueryService) {
        this.queryService = queryService;
    }

    public setBaseService(baseService: ApiServiceBase): void {
        this.baseService = baseService;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async request<T = void>(httpRequest: HttpRequest<any>, options?: ApiOptions): Promise<HttpResponse<T>> {
        NgZone.assertInAngularZone();

        httpRequest = httpRequest.clone({
            params: this.queryService.getFeatureHttpParams()
        });
        const response = await this.baseService.request<T>(httpRequest, options);
        NgZone.assertInAngularZone();

        return response;
    }
}
