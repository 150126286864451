import cloneDeepWith from 'lodash-es/cloneDeepWith.js';
import { Quaternion, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { KeyboardInput } from '../inputs/keyboard-input.js';
import { lineConstants } from '../line-helper.js';
import { notNull } from '../nullable.js';
import { Text2D } from '../text/text-2d.js';
import { UnitText2D } from '../text/unit-text-2d.js';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode.js';
import { areEqual, cloneComparableValues } from './comparable-values-helper.js';
import { createLinesMesh, sizeLinesMesh } from './lines-mesh-helper.js';
export class BaseComponent {
  disposed = false;
  maxPointDistance;
  model;
  updateModel;
  cache;
  scene;
  sceneEvents;
  camera;
  context3d;
  canvas;
  glFontTexture;
  renderNextFrame;
  showTextEditor;
  refreshModel;
  setCameraRadius;
  unitConverter;
  eventNotifier;
  propertyInfo;
  mathCalculator;
  tooltip;
  images;
  frontLight;
  backLight;
  mousePosition;
  addMouseMoveListener;
  removeMouseMoveListener;
  addDragStartListener;
  removeDragStartListener;
  addDragMoveListener;
  removeDragMoveListener;
  addDragEndListener;
  removeDragEndListener;
  registerUpdateMethod;
  setMousePosition;
  removeOnHover;
  fnCache = new Map();
  constructor(ctor) {
    // This shouldn't be set here
    this.maxPointDistance = 15000;
    this.model = ctor.model;
    this.cache = ctor.cache;
    this.scene = ctor.scene;
    this.sceneEvents = ctor.sceneEvents;
    this.camera = ctor.camera;
    this.context3d = ctor.context3d;
    this.canvas = this.scene.getEngine().getRenderingCanvas() ?? undefined;
    this.glFontTexture = ctor.glFontTexture;
    this.renderNextFrame = ctor.renderNextFrame;
    this.showTextEditor = ctor.showTextEditor;
    this.refreshModel = ctor.refreshModel;
    this.setCameraRadius = ctor.setCameraRadius;
    this.unitConverter = ctor.unitConverter;
    this.eventNotifier = ctor.eventNotifier;
    this.propertyInfo = ctor.propertyInfo;
    this.mathCalculator = ctor.mathCalculator;
    this.tooltip = ctor.tooltip;
    this.images = ctor.images;
    this.frontLight = ctor.frontLight;
    this.backLight = ctor.backLight;
    this.mousePosition = ctor.mousePosition;
    this.addMouseMoveListener = ctor.addMouseMoveListener;
    this.removeMouseMoveListener = ctor.removeMouseMoveListener;
    this.addDragStartListener = ctor.addDragStartListener;
    this.removeDragStartListener = ctor.removeDragStartListener;
    this.addDragMoveListener = ctor.addDragMoveListener;
    this.removeDragMoveListener = ctor.removeDragMoveListener;
    this.addDragEndListener = ctor.addDragEndListener;
    this.removeDragEndListener = ctor.removeDragEndListener;
    this.registerUpdateMethod = ctor.registerUpdateMethod;
    this.setMousePosition = ctor.setMousePosition;
    this.removeOnHover = ctor.removeOnHover;
    if (ctor.updateModelCtor) {
      this.updateModel = new ctor.updateModelCtor(this.unitConverter, this.mathCalculator, this.propertyInfo, this.cache, this.camera3dPositionUpdate.bind(this));
    }
  }
  dispose() {
    this.disposed = true;
  }
  propertyValueChanged(changes, update, ...args) {
    if (this.updateModel) {
      this.updateModel.propertyValueChanged(changes, this.model, update, ...args);
    }
  }
  scopeCheckChanged(scopeChecks, ...args) {
    if (this.updateModel) {
      this.updateModel.scopeCheckChanged(scopeChecks, this.model, ...args);
    }
  }
  setTextMeshParent(text, mesh) {
    if (text.mesh) {
      text.mesh.parent = mesh.parent;
    }
  }
  getBoundingBox() {
    return undefined;
  }
  getBoundingBoxes() {
    return [];
  }
  ensureNotDisposed() {
    if (this.disposed) {
      throw new Error('Component already disposed.');
    }
  }
  cacheFn(fn, args) {
    if (this.fnCache.has(fn) && areEqual(args, this.fnCache.get(fn).args)) {
      return this.fnCache.get(fn).result;
    } else {
      const result = fn(args);
      this.fnCache.set(fn, {
        args: cloneDeepWith(args, cloneComparableValues),
        result
      });
      return result;
    }
  }
  highlight(pickingInfos, highlightedDimension, isHightlighted, filterOutPickedMeshName) {
    // ignore text and filter our picked meshes
    pickingInfos = filterOutPickedMeshName === '' || filterOutPickedMeshName === undefined ? pickingInfos.filter(pickingInfo => !pickingInfo.pickedMesh?.isText) : pickingInfos.filter(pickingInfo => !pickingInfo.pickedMesh?.isText && pickingInfo.pickedMesh?.id.includes(filterOutPickedMeshName));
    const pickedMesh = pickingInfos[0]?.pickedMesh ?? undefined;
    notNull(this.model.highlightedDimensions);
    const wasHighlighted = this.model.highlightedDimensions[highlightedDimension];
    const isHighlighted = this.model.highlightedDimensions[highlightedDimension] = isHightlighted(pickedMesh);
    if (wasHighlighted != isHighlighted) {
      // CR: do we need immediately here?
      this.refreshModel(true);
    }
    return {
      wasHighlighted,
      isHighlighted,
      updated: wasHighlighted != isHighlighted,
      pickedMesh
    };
  }
  isHighlighted(highlightedDimension) {
    notNull(this.model.highlightedDimensions);
    return this.model.highlightedDimensions[highlightedDimension] || !Object.values(this.model.highlightedDimensions).includes(true);
  }
  createText2D(options) {
    return new Text2D(this.createIText2DConstructor(options));
  }
  createUnitText2D(options) {
    return new UnitText2D(this.createUnitText2DConstructor(options));
  }
  createUnitText2DForProperty(options, property, update, ignorePropertyEditable) {
    return UnitText2D.createForProperty(this.createIUnitText2DCreateOptions(options), property, update, ignorePropertyEditable);
  }
  createUnitText2DForDisplay(options) {
    return UnitText2D.createForDisplay({
      ...options,
      cache: this.cache,
      camera: this.camera,
      context3d: this.context3d,
      glFontTexture: this.glFontTexture,
      scene: this.scene,
      sceneEvents: this.sceneEvents,
      renderNextFrame: this.renderNextFrame,
      showTextEditor: this.showTextEditor,
      isDropDown: options.isDropDown,
      mathCalculator: this.mathCalculator,
      propertyInfo: this.propertyInfo,
      unitConverter: this.unitConverter
    });
  }
  registerCamera3dPositionUpdate(offset) {
    this.registerUpdateMethod('PositionView', () => {
      this.camera3dPositionUpdate(offset);
    });
  }
  camera3dPositionUpdate(offset, registerUndoRedo = true) {
    this.changeCameraPosition(offset);
    if (registerUndoRedo) {
      this.eventNotifier.registerCurrentStateUndoRedoAction({
        name: 'PositionView',
        redo: () => this.changeCameraPosition(offset),
        undo: () => this.changeCameraPosition(new Vector3(-offset.x, -offset.y, 0))
      });
    }
  }
  registerCamera2dOffsetUpdate(offset) {
    this.registerUpdateMethod('OffsetView', () => {
      this.changeCameraTargetScreenOffset(this.camera.targetScreenOffset.add(offset), this.mousePosition.subtract(offset));
      this.eventNotifier.registerCurrentStateUndoRedoAction({
        name: 'OffsetView',
        redo: () => this.changeCameraTargetScreenOffset(this.camera.targetScreenOffset.add(offset), this.mousePosition.subtract(offset)),
        undo: () => this.changeCameraTargetScreenOffset(this.camera.targetScreenOffset.subtract(offset), this.mousePosition.add(offset))
      });
    });
  }
  /**
   * Returns the cached TransformNode that is updated with correct rotation (transformNode.rotationQuaternion *= rotation)
   * @param name
   * @param scene
   * @param rotation
   * @param transform
   * @returns cached transform node
   */
  calculateTransformNodeWithRotation(name, scene, rotation, transform) {
    return BaseComponent.calculateTransformNodeWithRotation(this.cache, name, scene, rotation, transform);
  }
  static calculateTransformNodeWithRotation(cache, name, scene, rotation, transform) {
    const transformNodeInfo = cache.meshCache.create(name, name => {
      const transformNode = new TransformNode(name, scene);
      transform?.(transformNode);
      return {
        transformNode: transformNode,
        rotation: Quaternion.Identity()
      };
    });
    // only update if rotation changes
    if (!transformNodeInfo.rotation.equals(rotation)) {
      const transformNode = transformNodeInfo.transformNode;
      transformNode.resetLocalMatrix(false);
      transformNode.rotationQuaternion = rotation.clone();
      transform?.(transformNode);
      transformNodeInfo.rotation = rotation.clone();
    }
    return transformNodeInfo.transformNode;
  }
  createLinesMesh(values, calculateLinesFn, name) {
    return createLinesMesh(this.scene, this.cache.meshCache, values, calculateLinesFn, name, lineConstants.dimensionLinesColor, 3000);
  }
  sizeLinesMesh(linesMeshInfo, values, calculatePointsFn) {
    sizeLinesMesh(linesMeshInfo, values, calculatePointsFn);
  }
  changeCameraPosition(offset) {
    const sina = Math.sin(this.camera.alpha);
    const cosa = Math.cos(this.camera.alpha);
    const sinb = Math.sin(this.camera.beta);
    const cosb = Math.cos(this.camera.beta);
    this.camera.targetScreenOffset.x += sina * offset.x;
    this.camera.targetScreenOffset.y += cosa * cosb * offset.x + sinb * offset.y;
    this.setCameraRadius(this.camera.radius + cosa * sinb * offset.x - cosb * offset.y, true);
    this.refreshModel();
  }
  changeCameraTargetScreenOffset(targetScreenOffset, mousePosition) {
    this.camera.targetScreenOffset = targetScreenOffset;
    this.setMousePosition(mousePosition);
    this.refreshModel();
  }
  createIText2DConstructor(options) {
    return {
      cache: this.cache,
      camera: this.camera,
      context3d: this.context3d,
      glFontTexture: this.glFontTexture,
      scene: this.scene,
      sceneEvents: this.sceneEvents,
      renderNextFrame: this.renderNextFrame,
      showTextEditor: this.showTextEditor,
      isDropDown: options?.isDropDown,
      capacity: options?.capacity,
      editable: options?.editable,
      noRotation: options?.noRotation,
      noZoom: options?.noZoom,
      onTextEntered: options?.onTextEntered,
      zoomTextScale: options?.zoomTextScale
    };
  }
  createUnitText2DConstructor(options) {
    return {
      ...this.createIText2DConstructor(options),
      mathCalculator: this.mathCalculator,
      propertyInfo: this.propertyInfo,
      unitConverter: this.unitConverter,
      unitGroup: options.unitGroup,
      property: options.property,
      extraPrecision: options.extraPrecision,
      isDropDown: options.isDropDown,
      onValueEntered: options.onValueEntered
    };
  }
  createIUnitText2DCreateOptions(options) {
    return {
      unitConverter: this.unitConverter,
      mathCalculator: this.mathCalculator,
      propertyInfo: this.propertyInfo,
      camera: this.camera,
      scene: this.scene,
      sceneEvents: this.sceneEvents,
      context3d: this.context3d,
      unitGroup: options.unitGroup,
      renderNextFrame: this.renderNextFrame,
      showTextEditor: this.showTextEditor,
      cache: this.cache,
      glFontTexture: this.glFontTexture,
      extraPrecision: options.extraPrecision,
      isDropDown: options.isDropDown
    };
  }
}
export class BaseComponent2d extends BaseComponent {
  get keyboardModifiers() {
    return this.camera.inputs.attached[KeyboardInput.simpleName].modifiers;
  }
  positionsChanged(components) {
    this.eventNotifier.positionsChanged2d(components);
  }
  draggingSelectionChanged(dragging) {
    this.eventNotifier.draggingSelectionChanged2d(dragging);
  }
  createLinesMesh(values, calculateLinesFn, name) {
    return createLinesMesh(this.scene, this.cache.meshCache, values, calculateLinesFn, name, lineConstants.defaultLineColor);
  }
}
