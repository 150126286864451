import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DesignFromCheckbotComponent } from '../components/checkbot/design-from-checkbot/design-from-checkbot.component';
import { ErrorComponent } from '../components/error/error.component';
import { HomeSwitchComponent } from '../components/home-switch/home-switch.component';
import { MainComponent } from '../components/main/main.component';
import { NoopComponent } from '../components/noop/noop.component';
import { VersionComponent } from '../components/version/version.component';
import { DeckingMainComponent } from '../decking/components/decking-main/decking-main.component';
import { deckingMainGuard } from '../decking/guards/decking-main.guard';
import { authenticationGuard } from '../guards/authentication.guard';
import { canDeactivateMainComponentGuard } from '../guards/can-deactivate-main-component.guard';
import { combineGuards } from '../guards/combined.guard';
import { errorGuard } from '../guards/error.guard';
import { initializationGuard } from '../guards/initialization.guard';
import { logoutCallbackGuard } from '../guards/logout-callback.guard';
import { mainGuard } from '../guards/main.guard';
import { noInitialDataGuard } from '../guards/no-initial-data.guard';
import { oauthCallbackGuard } from '../guards/oauth-callback.guard';
import { registrationCallbackGuard } from '../guards/registration-callback.guard';
import { trimbleConnectAuthCallbackGuard } from '../guards/trimble-connect-auth-callback.guard';

const routes: Routes = [
  { path: 'design/:id', canActivate: [combineGuards(authenticationGuard, initializationGuard, mainGuard)], canDeactivate: [canDeactivateMainComponentGuard], component: MainComponent },
  { path: 'decking/:id', canActivate: [combineGuards(authenticationGuard, initializationGuard, deckingMainGuard)], component: DeckingMainComponent },
  { path: '', canActivate: [combineGuards(authenticationGuard, initializationGuard)], pathMatch: 'full', component: HomeSwitchComponent },
  { path: 'checkbot', canActivate: [combineGuards(authenticationGuard, initializationGuard)], component: DesignFromCheckbotComponent },

  { path: 'oauth_callback', canActivate: [oauthCallbackGuard], component: NoopComponent },
  { path: 'registration_callback', canActivate: [registrationCallbackGuard], component: NoopComponent },
  { path: 'logout', canActivate: [logoutCallbackGuard], component: NoopComponent },
  { path: 'error', canActivate: [errorGuard], component: ErrorComponent },
  { path: 'tc_auth_callback', canActivate: [trimbleConnectAuthCallbackGuard], component: NoopComponent },
  { path: 'version', canActivate: [combineGuards(authenticationGuard, noInitialDataGuard)], component: VersionComponent },

  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
