import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ApprovalHelper } from '../../helpers/approval-helper';
import { LocalizationService } from '../../services/localization.service';
import { OfflineService } from '../../services/offline.service';
import { UserService } from '../../services/user.service';

interface IMultipleApprovalsComponentInput {
    files: string[];
    approvalLinks?: string[];
}

@Component({
    templateUrl: './multiple-approvals.component.html',
    styleUrls: ['./multiple-approvals.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MultipleApprovalsComponent {

    @Input()
    public modalInstance!: ModalInstance<IMultipleApprovalsComponentInput>;

    public get files() {
        return this.modalInstance.input?.files ?? [];
    }

    private get approvalLinks() {
        return this.modalInstance.input?.approvalLinks ?? [];
    }

    constructor(
        private readonly user: UserService,
        private readonly offline: OfflineService,
        private readonly localizationService: LocalizationService
    ) { }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public openApproval(index: number) {
        const design = this.user.design;
        design.usageCounterC2C.Approval++;

        const approvalInfo = ApprovalHelper.getApprovalInfo(this.approvalLinks[index], this.files[index]);
        this.offline.nativeLocalPathOpen(approvalInfo.url, approvalInfo.name, true, true);
    }
}
