import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewEncapsulation } from '@angular/core';
import depthOfRecessImage from '../../../../images/recess.png' with { loader: 'file' };
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';
import { imageUrl } from '../../../../image';
import { L10nDirective } from '../../../../directives/l10n.directive';

@Component({
    templateUrl: './info-dialog-depth-of-recess.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [L10nDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InfoDialogDepthOfRecessComponent extends InfoDialogBaseComponent {
    public depthOfRecessImage = imageUrl(depthOfRecessImage);

    public getTitle(): string {
        return this.translate('SP.InstallationConditions.DepthOfRecess.InfoPopUp.Title');
    }
}
