import { ZoneNumber } from '../../../../services/data.service';
import { StrengthModel } from '../../../strength-gl-model';
import { GetZoneLength, GetZoneLengthProperty, GetZoneWidth, GetZoneWidthProperty } from '../zones-helper';
import { StrengthMeasurementData, StrengthMeasurementPlaneRowData } from './strength-measurements';

export function ZonesXMeasurement(model: StrengthModel): StrengthMeasurementPlaneRowData {
    const opening = model.opening.defineOpening;
    const directionMod = opening ? 1 : -1;
    const numberOfZones = model.zones.numberOfZones;
    const measurements: StrengthMeasurementData[] = [];

    let xBaseOffset = directionMod * model.baseMaterial.length / 2;

    for (let i = 1; i <= numberOfZones; i++) {
        if (!opening || (i == 5 || i == 6 || i == 7)) {
            const zoneNumber = i as ZoneNumber;
            const zoneLength = GetZoneLength(model, zoneNumber);
            measurements.push({
                x1: xBaseOffset,
                x2: xBaseOffset - directionMod * zoneLength,
                y1: model.baseMaterial.width / 2,
                y2: model.baseMaterial.width / 2,
                property: GetZoneLengthProperty(zoneNumber),
                custom: opening
                    ? { value: zoneLength }
                    : false
            });
            xBaseOffset = xBaseOffset - directionMod * zoneLength;
        }
    }

    return {
        enabled: model.visibilityModel.ZonesDimensionsVisible,
        measurements: measurements
    };
}

export function ZonesYMeasurement(model: StrengthModel): StrengthMeasurementPlaneRowData {
    const opening = model.opening.defineOpening;

    const numberOfZones = model.zones.numberOfZones;
    const measurements: StrengthMeasurementData[] = [];
    let yBaseOffset = model.baseMaterial.width / 2;

    for (let i = 1; i <= numberOfZones; i++) {
        if (opening && (i == 3 || i == 4 || i == 5)) {
            const zoneNumber = i as ZoneNumber;
            const zoneWidth = GetZoneWidth(model, zoneNumber);
            measurements.push({
                x1: yBaseOffset,
                x2: yBaseOffset - zoneWidth,
                y1: model.baseMaterial.length / 2,
                y2: model.baseMaterial.length / 2,
                property: GetZoneWidthProperty(zoneNumber),
                custom: {
                    value: zoneWidth
                }
            });
            yBaseOffset -= zoneWidth;
        }
    }

    return {
        enabled: model.visibilityModel.ZonesDimensionsVisible && opening,
        measurements: measurements
    };
}
