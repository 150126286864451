export class QueryStringHelper {
  urlSearchParams;
  constructor(search) {
    this.urlSearchParams = new URLSearchParams(search);
  }
  queryParamExists(name, caseInsensitive = true) {
    return this.get(name, caseInsensitive) != null;
  }
  getBoolean(name, caseInsensitive = true) {
    const val = this.get(name, caseInsensitive);
    if (val != null && val.toLowerCase() == 'true') {
      return true;
    }
    return false;
  }
  get(name, caseInsensitive = true) {
    if (!caseInsensitive) {
      // URLSearchParams is case sensitive by default.
      return this.urlSearchParams.get(name);
    }
    const nameLc = name.toLowerCase();
    let retVal = null;
    this.urlSearchParams.forEach((value, key) => {
      if (retVal == null && key.toLowerCase() == nameLc) {
        retVal = value;
      }
    });
    return retVal;
  }
}
