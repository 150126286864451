<div class="section" *ngIf="isLoaded">
    <div class="specification-text-title-wrapper">
        <pe-control-title
            class="specification-text-title"
            [title]="'Agito.Hilti.Profis3.ExportReport.SpecificationText.Subtitle' | l10n: localizationService.selectedLanguage">
        </pe-control-title>

        <pe-perfect-spec-logo class="specification-text-logo"></pe-perfect-spec-logo>
    </div>

    <pe-text-area
        [id]="'export-report-specification-text-section-textbox'"
        [value]="specificationText"
        (valueChange)="handleSpecificationTextChanged($any($event).detail)"
        [disabled]="submitted"
        [maxLength]="700"
        [height]="80">
    </pe-text-area>

    <pe-button
        class="default-button"
        [id]="'export-report-specification-text-default-button'"
        [look]="'Default'"
        (buttonClick)="setDefaultSpecificationText()"
        [text]="'Agito.Hilti.Profis3.ExportReport.SpecificationText.DefaultButton' | l10n: localizationService.selectedLanguage"
        [disabled]="submitted || !specificationTextChanged">
    </pe-button>
</div>
