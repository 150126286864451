import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewEncapsulation } from '@angular/core';
import installationDirectionAImage from '../../../../images/install-dir-a.png' with { loader: 'file' };
import installationDirectionBImage from '../../../../images/install-dir-b.png' with { loader: 'file' };
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';
import { imageUrl } from '../../../../image';
import { L10nDirective } from '../../../../directives/l10n.directive';

@Component({
    templateUrl: './info-dialog-installation-direction.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [L10nDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InfoDialogInstallationDirectionComponent extends InfoDialogBaseComponent {
    public installationDirectionAImage = imageUrl(installationDirectionAImage);
    public installationDirectionBImage = imageUrl(installationDirectionBImage);

    public getTitle(): string {
        return this.translate('SP.PostInstalled.InstallationDirection.InfoPopUp.Title');
    }
}
