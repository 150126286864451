import type schema from './environment.local.json';
import { EnvironmentHelper } from '@profis-engineering/pe-ui-common/helpers/environment-helper';
import { QueryStringHelper } from '@profis-engineering/pe-ui-common/helpers/query-string-helper';

export type Environment = typeof schema & {
    c2cDemoFeatures: boolean;
    baseUrl: string;
    debugGlModel: boolean;
    supportedFeatures: string[];
};

export const environment: Environment = getEnvironmentSettings();

const qsHelper = new QueryStringHelper(window.location.search);

environment.supportedFeatures = ['loadingInitialData', 'calculationService'];

// setBaseUrl
environment.baseUrl = document.getElementsByTagName('base')[0].href;

// set debug
environment.debugGlModel = environment.enableDebugGlModelQuery && qsHelper.getBoolean('debug');

if (environment.enableC2CQuery) {
    environment.c2cDemoFeatures = qsHelper.getBoolean('c2cdemo');
}

// setC2CForceAvailableCountriesEnabled
if (environment.c2cEnabled && environment.enableC2CQuery && environment.c2cForceAvailableCountriesEnabled) {
    environment.c2cForceAvailableCountriesEnabled = !qsHelper.getBoolean('c2cdemo');
}

function getEnvironmentSettings(): Environment {
    const env: Environment = (window as any).environmentC2C;
    const helper = new EnvironmentHelper(window.location.host);
    const [serviceVersion] = helper.getServiceVersions();

    if (serviceVersion != '') {
        env.c2cCodeListServiceUrl = helper.formatServiceVersion(env.c2cCodeListServiceUrl, serviceVersion);
        env.c2cCalculationServiceUrl = helper.formatServiceVersion(env.c2cCalculationServiceUrl, serviceVersion);
        env.c2cCalculationServiceSignalRCoreUrl = helper.formatServiceVersion(env.c2cCalculationServiceSignalRCoreUrl, serviceVersion);
        env.c2cCalculationServiceSignalRServer = helper.formatServiceVersion(env.c2cCalculationServiceSignalRServer, serviceVersion);
        env.c2cReportServiceUrl = helper.formatServiceVersion(env.c2cReportServiceUrl, serviceVersion);
        env.c2cTranslationsServiceUrl = helper.formatServiceVersion(env.c2cTranslationsServiceUrl, serviceVersion);
    }

    return env ?? {};
}
