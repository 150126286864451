import {
    DesignMethodGroupEntityC2C, DesignMethodGroupLoadTypeDesignStandardConnectionTypeEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList, ICodeListC2CConstructor } from './code-list';

export interface IDesignMethodGroupC2CConstructor extends ICodeListC2CConstructor {
    id: number;
    displayKey: string;
    groupId: number;
    designMethodGroupLoadTypeDesignStandards: DesignMethodGroupLoadTypeDesignStandardConnectionTypeEntityC2C[];
}

export class DesignMethodGroup extends CodeList {
    public groupId?: number;
    public designMethodGroupLoadTypeDesignStandards?: DesignMethodGroupLoadTypeDesignStandardConnectionTypeEntityC2C[];

    constructor(codeList: IDesignMethodGroupC2CConstructor) {
        super(codeList);

        if (codeList != null) {
            this.id = codeList.id;
            this.displayKey = codeList.displayKey;
            this.groupId = codeList.groupId;
            this.designMethodGroupLoadTypeDesignStandards = codeList.designMethodGroupLoadTypeDesignStandards;
        }
    }

    public static fromService(codeList: DesignMethodGroupEntityC2C) {
        return new DesignMethodGroup(CodeList.baseFromService<IDesignMethodGroupC2CConstructor>('DesignMethodGroupEntity', codeList, {
            id: codeList.id,
            displayKey: codeList.displayKey,
            groupId: codeList.groupId,
            designMethodGroupLoadTypeDesignStandards : codeList.designMethodGroupLoadTypeDesignStandardConnectionTypes
        }));
    }

    public override cloneDeep() {
        const codeList: DesignMethodGroup = <DesignMethodGroup>super.cloneDeep();
        codeList.id = this.id;
        codeList.displayKey = this.displayKey;
        codeList.groupId = this.groupId;
        codeList.designMethodGroupLoadTypeDesignStandards = this.designMethodGroupLoadTypeDesignStandards;

        return codeList;
    }
}
