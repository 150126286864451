import { FireInputTypeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class FireInputTypes extends CodeList {

    public static fromService(codeList: FireInputTypeEntityC2C) {
        return new FireInputTypes(CodeList.baseFromService('FireInputTypeEntity', codeList));
    }

    public override cloneDeep(): FireInputTypes {
        return super.cloneDeep() as FireInputTypes;
    }
}
