import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { randomString } from '@profis-engineering/pe-ui-common/helpers/random';
import { Tooltip, ngbTooltipTemplate } from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import { IMenuItem } from '@profis-engineering/pe-ui-common/components/context-menu/context-menu.common';
import cloneDeep from 'lodash-es/cloneDeep';
import { IProjectAndDesignId } from '@profis-engineering/pe-ui-common/entities/design';

export interface ITreeViewItem<T> {
  id: string;
  name: string;
  tooltip?: Tooltip;
  parentId?: string;
  subItems?: ITreeViewItem<T>[];
  expanded: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  dropActive?: boolean;
  icon?: string;
  details?: T;
  contextMenu?: IMenuItem<T>[];
  level?: number;
  onSelect?: (node: ITreeViewItem<T>) => void | Promise<void>;
  onExpanded?: (node: ITreeViewItem<T>) => void | Promise<void>;
  onDrop?: (target: string, element: IProjectAndDesignId) => void | Promise<void>;
}

export enum NodeType {
  Parent,
  Child,
  Leaf
}

// A component used to display a tree view of items.
@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent<T> implements OnChanges, AfterViewInit {
  // Define the NODE_PADDING constant
  readonly NODE_PADDING = 15;

  @Input()
  public selectedItem: ITreeViewItem<T> | undefined;

  @Input()
  public treeData?: ITreeViewItem<T>;

  @Input()
  public disabled = false;

  @Input()
  public id = randomString(8);

  @Input()
  isTreeSelected: boolean;

  @Input()
  searchText: string | undefined = undefined;

  @Input()
  virtaulTourInProgress = false;

  @Input()
  onMenuToggled?: (opened: boolean) => void;

  /**
  * The property to fix the position of the dropdown menu.
  */
  @Input()
  public dropdownMenufixedPosition = false;

  showContextMenu = false;
  public filteredData?: ITreeViewItem<T>;

  public ngbTooltipTemplate = ngbTooltipTemplate;

  ngOnChanges() {
    this.filteredData = this.treeData;

    if (this.virtaulTourInProgress) {
      return;
    }

    if (this.treeData?.subItems?.length && this.searchText) {
      this.filteredData = this.filterTreeByName(cloneDeep(this.treeData), this.searchText);
    }

    if (!this.isTreeSelected) {
      this.selectedItem = undefined;
    }
    else if (!this.selectedItem || !this.treeData?.subItems?.length) {
      this.selectedItem = this.treeData;
    }

    // Set timeout is needed for scrolling selected element after filtering
    setTimeout(() => {
      this.scrollSelectedItemIntoView();
    });
  }

  ngAfterViewInit(): void {
    this.scrollSelectedItemIntoView();
  }

  /**
   * Scroll the tree container to make selected item visible.
   */
  private scrollSelectedItemIntoView(): void {
    if (this.isTreeSelected && this.selectedItem) {
      const el = document.querySelector(`[id="${this.filteredData.id + '-' + this.selectedItem.id}"]`);

      if (el?.getBoundingClientRect().bottom > window.innerHeight) {
        //  The bottom of the target will be aligned to the bottom of the visible area of the scrollable parent.
        el.scrollIntoView(false);
      }

      // Target is outside the view from the top
      if (el?.getBoundingClientRect().top < 0) {
        // The top of the target will be aligned to the top of the visible area of the scrollable parent
        el.scrollIntoView();
      }
    }
  }

  /**
   * Filters the tree based on search name provided.
   * @param tree - The tree to filter.
   * @param name - The search text.
   * @returns Filtered tree.
   */
  filterTreeByName(tree: ITreeViewItem<T>, name: string): ITreeViewItem<T> {
    const filter = (tree: ITreeViewItem<T>[], name: string) => {
      return tree?.filter(item => {
        if (item.name.trim().toLowerCase().includes(name.trim().toLowerCase())) {
          // If the item's name matches the filter, include it
          return true;
        }
        if (item.subItems && item.subItems.length > 0) {
          // If the item has subItems, recursively filter them
          item.subItems = filter(item.subItems, name);
          // Include the item if any of its subItems match the filter
          return item.subItems.length > 0;
        }
        // Exclude the item if it doesn't match the filter and doesn't have any matching subItems
        return false;
      });
    }

    const filtered = tree;
    filtered.subItems = filter(tree.subItems, name);

    return filtered;
  }

  /**
   * Get Node from the tree based on search id provided.
   * @param tree - The tree to filter.
   * @param idOrName - The search text.
   * @returns Filtered tree.
   */
  getNodeFromTreeByIdOrName(tree: ITreeViewItem<T>, idOrName: string): ITreeViewItem<T> | undefined {
    if (tree.id === idOrName || tree.name === idOrName) {
      return tree;
    }

    if (tree.subItems) {
      for (const subItem of tree.subItems) {
        const result = this.getNodeFromTreeByIdOrName(subItem, idOrName);
        if (result) {
          return result;
        }
      }
    }

    return undefined;
  }

  /**
   * Toggles the expanded state of a node and calls the `onExpanded` callback if it exists.
   * @param node - The node to toggle.
   */
  toggleExpand = (node: ITreeViewItem<T>) => {
    if (node.disabled || this.disabled) {
      return;
    }

    // Toggle the expanded state of the node
    node.expanded = !node.expanded;

    // Call the onExpanded callback if it exists
    if (node.onExpanded) {
      node.onExpanded(node);
    }
  }



  /**
   * Checks if a node has subItems.
   * @param node - The node to check.
   * @returns True if the node has subItems, false otherwise.
   */
  hasChild = (node: ITreeViewItem<T>) => {
    return node.subItems?.length > 0;
  }

  /**
   * Sets the selected item to the clicked node and calls the `onSelect` callback if it exists.
   * @param node - The node to select.
   */
  selectNode = (node: ITreeViewItem<T>) => {
    if (node.disabled) {
      return;
    }

    // Set the selected item to the clicked node
    this.selectedItem = node;

    // Call the onSelect callback if it exists
    if (node.onSelect) {
      node.onSelect(node);
    }
  }

  /**
   * Checks if a node is selected.
   * @param node - The node to check.
   * @returns True if the node is selected, false otherwise.
   */
  isSelected = (node: ITreeViewItem<T>): boolean => {
    return this.selectedItem?.id === node.id || this.isVirtualTourMenuSelected(node);
  }

  isVirtualTourMenuSelected(node: ITreeViewItem<T>) {
    return this.virtaulTourInProgress && (node.id === 'my-sub-project-3' || node.id === 'shared-sub-project-3');
  }

  /**
   * Calls the `onDrop` callback of the selected item.
   * @param event - The drag event.
   */
  dropEvent = (event: DragEvent) => {
    if (!event && event.currentTarget == null && event.dataTransfer.getData('Text') == ''){
      return;
    }

    const targetProjectId = (event.currentTarget as HTMLElement).dataset['projectId'];
    const transferDesign: IProjectAndDesignId = JSON.parse(event.dataTransfer.getData('Text'));
    if (transferDesign.projectId == targetProjectId) {
        return;
    }
    const node = this.getNodeFromTreeByIdOrName(this.treeData, targetProjectId);
    if (node != undefined) {
      node.onDrop(node.id, transferDesign);
    }
  }

  /**
   * Clears the selected item.
   */
  clearSelected = () => {
    // Clear the selected item
    this.selectedItem = undefined;
  }

  /**
   * Checks if a node is selected and has a context menu.
   * @param node - The node to check.
   * @returns True if the node is selected and has a context menu, false otherwise.
   */
  isMenuAvailable = (node: ITreeViewItem<T>) => {
    // Check if the node is selected and has a context menu
    return this.isSelected(node) && node.contextMenu?.length > 0;
  }
}
