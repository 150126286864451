export class LogMessage {
  message;
  args;
  constructor(logMessage) {
    this.message = logMessage?.message ?? '';
    this.args = logMessage?.args ?? [];
  }
}
export class LoggerServiceBase {}
export class LoggerServiceInjected extends LoggerServiceBase {
  setBaseService(baseService) {
    this.log = baseService.log.bind(baseService);
    this.logAlways = baseService.logAlways.bind(baseService);
    this.logServiceError = baseService.logServiceError.bind(baseService);
    this.logServiceRequest = baseService.logServiceRequest.bind(baseService);
    this.logServiceResponse = baseService.logServiceResponse.bind(baseService);
    this.logGroup = baseService.logGroup.bind(baseService);
    this.logGroupFn = baseService.logGroupFn.bind(baseService);
    this.logTrace = baseService.logTrace.bind(baseService);
    this.logTraceAsync = baseService.logTraceAsync.bind(baseService);
    this.logTraceMessage = baseService.logTraceMessage.bind(baseService);
  }
  // LoggerServiceBase methods
  /* eslint-disable @typescript-eslint/no-unused-vars */
  log(_message, _logType, ..._args) {
    return;
  }
  logAlways(_message, _logType, ..._args) {
    return;
  }
  logServiceError(_response, _serviceName, _requestName) {
    return;
  }
  logServiceRequest(_serviceName, _fnName, ..._args) {
    return;
  }
  logServiceResponse(_serviceName, _fnName, ..._args) {
    return;
  }
  logGroup(_group, _logs, _logType, _noTimestamp) {
    return;
  }
  logGroupFn(_group, _fn, _logType) {
    return;
  }
  logTrace(_name, _action, _options) {
    return {};
  }
  logTraceAsync(_name, _action, _options) {
    return {};
  }
  logTraceMessage(_name, _action, _options) {
    return {};
  }
}
