import { Color3 } from '@babylonjs/core/Maths/math.color.js';
import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { CreateLineSystem } from '@babylonjs/core/Meshes/Builders/linesBuilder.js';
import { Mesh } from '@babylonjs/core/Meshes/mesh.js';
import { vector3Extensions } from '../babylon-extensions.js';
import { BaseComponent } from './base-component.js';
// line size
const lineSize = 0.4;
// text size
const textSize = 0.3;
// entire cs margin from bottom left corner
const marginX = 0.57;
export const marginY = 0.5;
// line colors
const xLineColor = Color3.FromHexString('#ff0000');
const yLineColor = Color3.FromHexString('#0000ff');
const zLineColor = Color3.FromHexString('#00ff00');
export class SceneCoordinateSystem extends BaseComponent {
  meshInfo;
  xLineText;
  yLineText;
  zLineText;
  constructor(ctor) {
    super(ctor);
    this.beforeCameraRender = this.beforeCameraRender.bind(this);
    this.sceneEvents.addEventListener('onBeforeCameraRenderObservable', this.beforeCameraRender);
    const lineTextCtor = {
      noZoom: true,
      noRotation: true
    };
    this.xLineText = this.createText2D(lineTextCtor);
    this.xLineText.setText('X');
    this.xLineText.mesh.scaling = new Vector3(0.01 * textSize, 0.01 * textSize, 1);
    this.yLineText = this.createText2D(lineTextCtor);
    this.yLineText.setText('Z');
    this.yLineText.mesh.scaling = new Vector3(0.01 * textSize, 0.01 * textSize, 1);
    this.zLineText = this.createText2D(lineTextCtor);
    this.zLineText.setText('Y');
    this.zLineText.mesh.scaling = new Vector3(0.01 * textSize, 0.01 * textSize, 1);
    // set model
    this.model.sceneCoordinateSystem = {
      hidden: false,
      report: false,
      ...this.model.sceneCoordinateSystem
    };
  }
  get rotationMatrix() {
    const view = this.camera.getViewMatrix();
    // we need rotation matrix only so that camera offset and projection are not used
    const rotation = Matrix.Invert(Matrix.FromValues(view.m[0], view.m[4], view.m[8], 0, view.m[1], view.m[5], view.m[9], 0, view.m[2], view.m[6], view.m[10], 0, 0, 0, 0, 1));
    return rotation;
  }
  update() {
    this.ensureMesh();
  }
  dispose() {
    this.sceneEvents.removeEventListener('onBeforeCameraRenderObservable', this.beforeCameraRender);
    if (this.xLineText != null) {
      this.xLineText.dispose();
      this.xLineText = undefined;
    }
    if (this.yLineText != null) {
      this.yLineText.dispose();
      this.yLineText = undefined;
    }
    if (this.zLineText != null) {
      this.zLineText.dispose();
      this.zLineText = undefined;
    }
    super.dispose();
  }
  beforeCameraRender() {
    this.ensureMesh();
  }
  ensureMesh() {
    this.meshInfo?.parentMesh.setEnabled(false);
    // no model
    if (this.model.sceneCoordinateSystem == null || this.model.sceneCoordinateSystem.hidden) {
      return;
    }
    this.meshInfo = this.createMesh();
    // enable meshes
    this.meshInfo.parentMesh.setEnabled(true);
    this.meshInfo.xMesh.setEnabled(true);
    this.meshInfo.yMesh.setEnabled(true);
    this.meshInfo.zMesh.setEnabled(true);
    const size = this.calculateSize();
    this.sizeMesh(size);
    // set text position
    const xMesh = this.xLineText.mesh;
    xMesh.position.x = size.x.x * 1.2;
    xMesh.position.y = size.x.y * 1.2;
    const yMesh = this.yLineText.mesh;
    yMesh.position.x = size.y.x * 1.2;
    yMesh.position.y = size.y.y * 1.2;
    const zMesh = this.zLineText.mesh;
    zMesh.position.x = size.z.x * 1.2;
    zMesh.position.y = size.z.y * 1.2;
    if (this.model.sceneCoordinateSystem.report) {
      const result = this.getScaleAndPositionForReport?.() ?? {
        scale: 1,
        position: Vector3.Zero()
      };
      this.meshInfo.parentMesh.scaling.x *= result.scale;
      this.meshInfo.parentMesh.scaling.y *= result.scale;
      this.meshInfo.parentMesh.scaling.z *= result.scale;
      this.meshInfo.parentMesh.position.x += result.position.x;
      this.meshInfo.parentMesh.position.y += result.position.y;
      this.meshInfo.parentMesh.position.z += result.position.z;
    }
  }
  createMesh() {
    const info = this.cache.meshCache.create('SceneCoordinateSystem.Mesh', () => {
      // parent mesh
      const parentMesh = new Mesh('SceneCoordinateSystem', this.scene);
      parentMesh.isPickable = false;
      parentMesh.parent = this.camera;
      // x line
      const xLine = [Vector3.Zero(), Vector3.Zero()];
      const xArrow = [Vector3.Zero(), Vector3.Zero(), Vector3.Zero()];
      const xMesh = CreateLineSystem('SceneCoordinateSystem:xLine', {
        lines: [xLine, xArrow],
        updatable: true
      }, this.scene);
      xMesh.isPickable = false;
      xMesh.color = xLineColor;
      xMesh.parent = parentMesh;
      // y line
      const yLine = [Vector3.Zero(), Vector3.Zero()];
      const yArrow = [Vector3.Zero(), Vector3.Zero(), Vector3.Zero()];
      const yMesh = CreateLineSystem('SceneCoordinateSystem:yLine', {
        lines: [yLine, yArrow],
        updatable: true
      }, this.scene);
      yMesh.isPickable = false;
      yMesh.color = yLineColor;
      yMesh.parent = parentMesh;
      // z line
      const zLine = [Vector3.Zero(), Vector3.Zero()];
      const zArrow = [Vector3.Zero(), Vector3.Zero(), Vector3.Zero()];
      const zMesh = CreateLineSystem('SceneCoordinateSystem:zLine', {
        lines: [zLine, zArrow],
        updatable: true
      }, this.scene);
      zMesh.isPickable = false;
      zMesh.color = zLineColor;
      zMesh.parent = parentMesh;
      return {
        parentMesh,
        xMesh,
        yMesh,
        zMesh,
        size: {
          x: Vector3.Zero(),
          y: Vector3.Zero(),
          z: Vector3.Zero(),
          position: Vector3.Zero()
        }
      };
    });
    this.xLineText.mesh.parent = info.parentMesh;
    this.yLineText.mesh.parent = info.parentMesh;
    this.zLineText.mesh.parent = info.parentMesh;
    return info;
  }
  sizeMesh(size) {
    if (!vector3Extensions.equals(this.meshInfo.size.position, size.position)) {
      this.meshInfo.size.position = size.position;
      this.meshInfo.parentMesh.position = size.position;
    }
    if (!vector3Extensions.equals(this.meshInfo.size.x, size.x)) {
      this.meshInfo.size.x = size.x;
      this.createLineMesh(size.x.x, size.x.y, size.x, size.y, this.meshInfo.xMesh);
    }
    if (!vector3Extensions.equals(this.meshInfo.size.y, size.y)) {
      this.meshInfo.size.y = size.y;
      this.createLineMesh(size.y.x, size.y.y, size.y, size.x, this.meshInfo.yMesh);
    }
    if (!vector3Extensions.equals(this.meshInfo.size.z, size.z)) {
      this.meshInfo.size.z = size.z;
      this.createLineMesh(size.z.x, size.z.y, size.z, size.x, this.meshInfo.zMesh);
    }
  }
  createLineMesh(lineX, lineY, arrowX, arrowY, mesh) {
    const xLine = [Vector3.Zero(), new Vector3(lineX, lineY, 0)];
    const xArrow = this.createArrow(arrowX, arrowY);
    CreateLineSystem(undefined, {
      lines: [xLine, xArrow],
      instance: mesh,
      updatable: undefined
    }, undefined);
  }
  calculateSize() {
    const canvasWidth = this.canvas?.width ?? this.context3d.width;
    const canvasHeight = this.canvas?.height ?? this.context3d.height;
    const canvasScale = 1330 / canvasWidth;
    // position to bottom left
    const position = new Vector3(
    // multiply by 10 to compensate for z offset
    -Math.tan(this.camera.fov / 2) * 10 + marginX * canvasScale, -Math.tan(this.camera.fov / 2) * 10 * (canvasHeight / canvasWidth) + this.getMarginVertical * canvasScale, 10);
    // create coordinate system vectors
    const xcs = Vector3.TransformCoordinates(new Vector3(1, 0, 0), this.csRotation);
    const ycs = Vector3.TransformCoordinates(new Vector3(0, 1, 0), this.csRotationY);
    const zcs = Vector3.TransformCoordinates(new Vector3(0, 0, 1), this.csRotationZ);
    // rotate coordinate system vectors for camera rotation
    const x3d = Vector3.TransformCoordinates(xcs, this.rotationMatrix);
    const y3d = Vector3.TransformCoordinates(ycs, this.rotationMatrix);
    const z3d = Vector3.TransformCoordinates(zcs, this.rotationMatrix);
    // scale lines
    x3d.scaleInPlace(lineSize);
    y3d.scaleInPlace(lineSize);
    z3d.scaleInPlace(lineSize);
    this.meshInfo.parentMesh.scaling.x = canvasScale;
    this.meshInfo.parentMesh.scaling.y = canvasScale;
    this.meshInfo.parentMesh.scaling.z = 1;
    return {
      position,
      x: x3d,
      y: y3d,
      z: z3d
    };
  }
  createArrow(main, off) {
    // arrow dimensions as fractions of scene coordinate axes
    const arrowLength = 1 / 20;
    const arrowWidth = 1 / 8;
    const center = Vector3.Zero();
    const side1 = Vector3.Zero();
    const side2 = Vector3.Zero();
    Vector3.TransformCoordinatesToRef(center, this.csRotation, center);
    Vector3.TransformCoordinatesToRef(center, this.rotationMatrix, center);
    center.addInPlace(new Vector3(main.x, main.y, 0));
    Vector3.TransformCoordinatesToRef(side1, this.csRotationY, side1);
    Vector3.TransformCoordinatesToRef(side1, this.rotationMatrix, side1);
    side1.addInPlace(new Vector3(off.x * arrowLength + main.x * (1 - arrowWidth), off.y * arrowLength + main.y * (1 - arrowWidth), 0));
    Vector3.TransformCoordinatesToRef(side2, this.csRotationZ, side2);
    Vector3.TransformCoordinatesToRef(side2, this.rotationMatrix, side2);
    side2.addInPlace(new Vector3(-off.x * arrowLength + main.x * (1 - arrowWidth), -off.y * arrowLength + main.y * (1 - arrowWidth), 0));
    return [side1, center, side2];
  }
}
