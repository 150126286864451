import { ContolsStyleSheets, IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { SmartDesignSearchSolutionService } from '../../../services/smart-design-search-solutions.service';
import styles from './react-smart-design-search-solutions.css';
import { PureComponent } from '../react-import';

let sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

export interface ISmartDesignSearchSolutionsProps extends IControlProps {
    optimizeButtonText?: string;
    optimizingButtonText?: string;
    smartDesignSearchSolutionService?: SmartDesignSearchSolutionService;
    optimizing?: boolean;
    clicked?: () => void;
    cancelClicked?: () => void;
}
class _SmartDesignSearchSolutions extends PureComponent<ISmartDesignSearchSolutionsProps> {

    public static readonly styleSheets = [sheet];

    constructor(props: ISmartDesignSearchSolutionsProps) {

        super(props);

        this.onClick = this.onClick.bind(this);
        this.onCancelOptimization = this.onCancelOptimization.bind(this);
    }

    public override render() {
        if (this.props.hidden || this.props.smartDesignSearchSolutionService?.SearchSolutionButtonHidden) {
            return null;
        }

        return this.props.optimizing ? this.getOptimizingButton() : this.getOptimizeButton();
    }

    private getOptimizeButton() {
        const spriteSearch = this.props.smartDesignSearchSolutionService?.IsOptimizingDisabled ? `image sprite sprite-search` : `image sprite sprite-search-white`;
        const searchDisabled = this.props.smartDesignSearchSolutionService?.IsOptimizingDisabled || this.props.smartDesignSearchSolutionService?.Optimizing;

        return (
            <div data-control-id={this.props.controlId} className={`react-search-solution-control control size-100`}>
                <div className='control-container'>
                    <button className="react-control-button control button-element button size-full button-anchor-ai red-button" onClick={this.onClick} disabled={searchDisabled ? true : undefined}>
                        <span className={spriteSearch}></span>
                        <span>{this.props.optimizeButtonText}</span>
                    </button>
                </div>
            </div>
        );
    }

    private getOptimizingButton() {
        return (
            <div data-control-id={this.props.controlId} className={`react-search-solution-control control size-100`}>
                <div className='control-container'>
                    <div className="smart-baseplate-design-content">
                        <div className="box-section-content">
                            <div className="buttons">
                                <button className="react-control-button control button-element button size-full button-anchor-ai" disabled>
                                    <span className="margin-left-10">{this.props.optimizingButtonText}</span>
                                </button>
                                <button className="button-anchor-ai cancel-searching" onClick={this.onCancelOptimization}>
                                    <span>&times;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onCancelOptimization() {
        if (this.props.cancelClicked != null) {
            this.props.cancelClicked();
        }
    }

    private onClick() {
        if (this.props.clicked != null) {
            this.props.clicked();
        }
    }

}

export const SmartDesignSearchSolutions: typeof _SmartDesignSearchSolutions & ContolsStyleSheets = _SmartDesignSearchSolutions;
