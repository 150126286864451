import { Component, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Table from 'easy-table';
import * as clipboard from 'clipboard-polyfill';
import { distinctUntilChanged, map, Observable, Subject, takeUntil } from 'rxjs';
import { LocalizationService } from './../../../services/external/localization.service';
import { DeckingDesign } from './../../../entities/decking-design/decking-design';
import { ZoneModel } from './../../../entities/decking-design/zone-model';
import { DeckingDesignService } from './../../../services/decking-design/decking-design.service';
import { DefinitionOfSidelapConnectors } from './../../../entities/decking-code-list/enums/definition-sidelap-connectors';
import { SpecificationTextService } from 'src/decking/services/specification-text/specification-text.service';
import { AreaModel } from './../../../entities/decking-design/area-model';


@Component({
  selector: 'app-specification-text',
  templateUrl: './specification-text.component.html',
  styleUrls: ['./specification-text.component.scss']
})
export class SpecificationTextComponent implements OnInit, OnDestroy {

  public copying = false;
  public currentDeckingDesign: DeckingDesign;
  public lengthUnit$: Observable<UnitType>;
  private lengthUnit: UnitType;
  private unsubscribe$ = new Subject<void>();

  @ViewChild('specificationTextDiv', { static: false }) specificationTextDiv: ElementRef;


  public sidelapSpacingUnit$: Observable<UnitType>;
  public isBySpacing$: Observable<boolean>;
  public appendUnit: boolean;
  public connectorSpacingTitle = '';

  constructor(
    private activeModal: NgbActiveModal,
    private deckingDesignService: DeckingDesignService,
    public localizationService: LocalizationService,
    public specificationTextService: SpecificationTextService
  ) {}

  ngOnInit(): void {
    this.initializeData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeData() {
    this.deckingDesignService.currentDeckingDesign$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data: DeckingDesign) => {
      this.currentDeckingDesign = data;
    });

    this.lengthUnit$ = this.deckingDesignService.currentSettings$.pipe(
      map(s => s.length.id),
      distinctUntilChanged(),
    );

    this.lengthUnit$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((unit: UnitType) => {
      this.lengthUnit = unit;
    });
  }

  private getSpecificationPlainTextData(): string {
    let text = '';
    text = text.concat(this.localizationService.getString(this.getSpecificationTextKeyForCurrentRegion()));
    text = text.concat('\n\n');
    text = text.concat(this.getTablesData());
    return text;
  }

  private getTablesData(): string {
    let tablesText = '';
    this.currentDeckingDesign.areas.forEach(area => {
      tablesText = tablesText.concat(`${area.name.value}\n`);
      tablesText = tablesText.concat(this.getRowsData(area.zones));
      tablesText = tablesText.concat('\n');
    });
    return tablesText;
  }

  private getRowsData(zones: ZoneModel[]): string {
    const table = new Table;
    let index = 1;
    zones.forEach(zone => {
      table.cell(this.localizationService.getString('Agito.Hilti.Profis3.Decking.SpecificationTextModal.Table.Header.Zone'), `${index++} ${zone.name.value}`);
      table.cell(this.localizationService.getString('Agito.Hilti.Profis3.Decking.SpecificationTextModal.Table.Header.DeckGauge'), zone.deckGauge?.value ? zone.deckGauge?.value + ' ga' : '-');
      table.cell(this.localizationService.getString('Agito.Hilti.Profis3.Decking.SpecificationTextModal.Table.Header.Pattern'), zone.pattern?.value ?? '-');
      table.cell(this.localizationService.getString('Agito.Hilti.Profis3.Decking.SpecificationTextModal.Table.Header.FrameFastener'), zone.frameFastener?.value ?? '-');
      table.cell(this.localizationService.getString('Agito.Hilti.Profis3.Decking.SpecificationTextModal.Table.Header.SidelapConnector'), zone.sidelapConnector?.value ?? '-');
      table.cell(this.localizationService.getString(this.connectorSpacingTitle), zone.side?.value ? this.getSideFormatted(zone) : '-');
      table.newRow();
    });
    return table.toString();
  }

  private getSideFormatted(zone: ZoneModel): string {
    return this.appendUnit ? this.specificationTextService.getConvertedValue(zone.side.value, this.lengthUnit) : zone.side.value.toString();
  }

  public getSpecificationTextKeyForCurrentRegion(): string {
    return this.specificationTextService.getSpecificationTextKeyForCurrentRegion(this.currentDeckingDesign);
  }

  public async copyToClipboard() {
    this.copying = true;
    const richText = this.specificationTextService.getSpecificationRichTextData(this.specificationTextDiv);
    const plainText = this.getSpecificationPlainTextData();
    try {
      const item = new clipboard.ClipboardItem({
        'text/html': new Blob(
          [richText],
          { type: 'text/html' }
        ),
        'text/plain': new Blob(
          [plainText],
          { type: 'text/plain' }
        ),
      });
      await clipboard.write([item]);
      this.copying = false;
    } catch (err) {
      console.error('Copy to clipboard failed', err);
      this.copying = false;
    }
  }

  public close(): void {
    this.activeModal.close();
  }

  public isBySpacingUnit(area: AreaModel):  Observable<boolean> {
    this.isBySpacing$ = new Observable<boolean>(observer => {
      const result = area?.definitionOfSidelapConnectors?.id === DefinitionOfSidelapConnectors.ByConnectionSpacing;
      observer.next(result);
      observer.complete();
    });
    
    return this.isBySpacing$;
  }

  public getUnit(area : AreaModel ) : UnitType {
    return area?.definitionOfSidelapConnectors?.id === DefinitionOfSidelapConnectors.ByConnectionSpacing ? this.lengthUnit : UnitType.None;
  }
}
