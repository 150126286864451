import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { Unit as UnitItem } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import { UserSettings } from '@profis-engineering/pe-ui-common/entities/user-settings';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList, CommonCodeListServiceBase } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import { NumberServiceBase } from '@profis-engineering/pe-ui-common/services/number.common';
import {
    UserSettingsServiceBase
} from '@profis-engineering/pe-ui-common/services/user-settings.common';
import { DesignPe } from '../../shared/entities/design-pe';
import { AppSettingsHelperWithUnit as AppSettingsHelperWithUnitShared } from '../../shared/helpers/app-settings-helper';
import { isHnaBasedDesignStandard } from '../../shared/helpers/design-standard-helper';
import { CodeListServiceBase } from '../../shared/services/code-list.service.base';
import { UnitService } from '../services/unit.service';

export class AppSettingsHelperWithUnit extends AppSettingsHelperWithUnitShared {
    constructor(
        localization: LocalizationServiceBase,
        userSettings: UserSettingsServiceBase<UserSettings>,
        codeList: CodeListServiceBase,
        commonCodeList: CommonCodeListServiceBase,
        private readonly unitService: UnitService,
        numberService: NumberServiceBase,
        useDevFeatures: boolean
    ) {
        super(localization, userSettings, codeList, commonCodeList, unitService, numberService, useDevFeatures);
    }

    public createUnitDropdown(id: string, translationKey: string, codeList: CommonCodeList) {
        const dropdown = this.createDropdownComponent<number>(
            `unit-${id}`,
            translationKey
        );

        dropdown.items = (this.commonCodeList.commonCodeLists[codeList] as UnitItem[])
            .filter(u => this.unitService.supportedUnitIds.indexOf(u.id) > -1)
            .map(unit => ({
                value: unit.id,
                text: unit.name
            }) as DropdownItem<number>);

        return dropdown;
    }

    public ensureUnitSupported(supportedUnits?: DropdownItem<number>[], value?: number, regionDefaultValue?: number) {
        if (!supportedUnits?.length) {
            return value;
        }

        if (supportedUnits.findIndex(u => u.value == value) < 0 && regionDefaultValue != null) {
            // Unit not supported
            return regionDefaultValue;
        }

        return value;
    }

    public setUnitDropdownValue(control: DropdownProps<number>, value?: number) {
        if (value == null || value == undefined) {
            control.selectedValue = undefined;
            return;
        }

        control.selectedValue = value;
    }

    public bindMinimumAnchorToProfileDistanceDefault(designStandardId: number | undefined, defaultUnit: Unit | undefined) {
        if (isHnaBasedDesignStandard(designStandardId) && defaultUnit) {
            return this.unit.convertUnitValueArgsToUnit(DesignPe.minimumAnchorToProfileDistanceInch, Unit.inch, defaultUnit);
        }

        return this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MinimumAnchorToProfileDistance.Placeholder');
    }
}
