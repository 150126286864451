import { ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    FastenerTypeEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CodeList } from './code-list';

export class FastenerType extends CodeList {
    public displayName?: string;
    public tested?: boolean;

    constructor(codeList: FastenerTypeEntityC2C) {
        super(codeList);

        if (codeList != null) {
            this.displayName = codeList.displayName;
            this.tested = codeList.tested;
        }
    }

    public static fromService(codeList: FastenerTypeEntityC2C) {
        return new FastenerType(CodeList.baseFromService('FastenerTypeEntity', codeList, {
            id: codeList.id,
            displayName: codeList.displayName,
            tested: codeList.tested,
            displayKey: codeList.displayKey
        }));
    }

    public override cloneDeep(): FastenerType {
        const codeList = super.cloneDeep() as FastenerType;

        codeList.displayName = this.displayName;
        codeList.tested = this.tested;

        return codeList;
    }

    public override getTranslatedNameText(codeListDeps: ICodeListTextDeps) {
        if (codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        const years = codeListDeps.localizationService.getString('Agito.Hilti.C2C.Product.Years');
        return formatKeyValue(this.displayName ?? '', { years });
    }
}
