import { DesignWorkingLifeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class DesignWorkingLife extends CodeList {

    public static fromService(codeList: DesignWorkingLifeEntityC2C) {
        return new DesignWorkingLife(CodeList.baseFromService('DesignWorkingLifeEntityC2C', codeList));
    }

    public override cloneDeep(): DesignWorkingLife {
        return super.cloneDeep() as DesignWorkingLife;
    }
}

