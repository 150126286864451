export class CancellationTokenSource {
  isCanceled = false;
  fns = [];
  token = {
    register: fn => {
      this.fns.push(fn);
    },
    isCanceled: false
  };
  cancel() {
    this.isCanceled = true;
    this.token.isCanceled = true;
    for (const fn of this.fns) {
      fn();
    }
  }
}
/**
 * Api service base
 */
export class ApiServiceBase {}
export class ApiServiceInjected extends ApiServiceBase {
  baseService;
  setBaseService(baseService) {
    this.baseService = baseService;
    this.request = baseService.request.bind(baseService);
  }
  // ApiServiceBase methods
  /* eslint-disable @typescript-eslint/no-unused-vars */
  getServiceName(_url) {
    return '';
  }
  request(_httpRequest, _options) {
    return {};
  }
}
