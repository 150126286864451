import '@babylonjs/core/Meshes/instancedMesh';

import { MaterialCache } from '@profis-engineering/gl-model/cache/material-cache';
import { UnitText2D as UnitText2DCommon } from '@profis-engineering/gl-model/text/unit-text-2d';
import { Text2D as Text2DCommon } from '@profis-engineering/gl-model/text/text-2d';
import { MeshCache } from './cache/mesh-cache';
import { BaseUpdate as BaseUpdateCommon, BaseUpdateCtor as BaseUpdateCtorCommon, Change, Update } from '@profis-engineering/gl-model/base-update';
import { GLModel, IGLModelConstructor, IModel, IScreenShotSettings, LoadsVisibilityInfo } from '@profis-engineering/gl-model/gl-model';
import { EventNotifier } from '@profis-engineering/gl-model/external/event-notifier';
import { BaseComponent, IBaseComponentConstructor, Highlight, ScopeCheck } from '@profis-engineering/gl-model/components/base-component';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { PickingInfo } from '@babylonjs/core/Collisions/pickingInfo';
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Node } from '@babylonjs/core/node';
import { UpdateServices } from '../components/gl-model/update';
import { DesignDetails } from '../services/design.service';
import { DeepPartial } from 'ts-essentials';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

export interface Model extends IModel<''> {
    // TODO FILIP: highlightedDimensions should be in IModel with the correct generic parameter
    highlightedDimensions: Record<string, boolean>;
    units: UnitsModel;
}
export type PartialModel = DeepPartial<Model>;

export type UnitText2D<TPropertyId extends string> = UnitText2DCommon<TPropertyId, MaterialCache, MeshCache>;
export type Text2D = Text2DCommon<MaterialCache, MeshCache>;

export abstract class BaseUpdate<TPropertyId extends string> extends BaseUpdateCommon<
    TPropertyId,
    MaterialCache,
    MeshCache,
    ''
> { }

export type ModelUpdateClass<TPropertyId extends string> = BaseUpdateCtorCommon<
    TPropertyId,
    MaterialCache,
    MeshCache,
    ''
>;

export type ScreenshotSettings = IScreenShotSettings<LoadsVisibilityInfo>;

export interface UnitsModel {
    force: UnitType;
}

export interface GlModelConstructor<
    TModel extends Model,
    TPropertyId extends string,
    TTooltipKey extends string,
    TModelUpdate>
        extends IGLModelConstructor<
        TModel,
        TPropertyId,
        EventNotifier,
        MaterialCache,
        MeshCache,
        TTooltipKey,
        ''
> {
    modelUpdate?: TModelUpdate;
    textEditorChange?: () => void;
}

export type BaseComponentConstructor<
    TModel extends Model,
    TPropertyId extends string,
    TTooltipKey extends string
> = IBaseComponentConstructor<
    TModel,
    TPropertyId,
    EventNotifier,
    MaterialCache,
    MeshCache,
    TTooltipKey,
    ''
>;

export abstract class Component<
    TModel extends Model,
    TPropertyId extends string,
    TTooltipKey extends string
> extends BaseComponent<
    TModel,
    TPropertyId,
    EventNotifier,
    MaterialCache,
    MeshCache,
    TTooltipKey,
    ''
> {
    // TODO FILIP: HighlightedDimensions should be part of class generic
    protected declare highlight: (
        pickingInfos: PickingInfo[],
        highlightedDimension: string,
        isHightlighted: (pickedMesh?: AbstractMesh) => boolean,
        filterOutPickedMeshName?: string
    ) => Highlight;

    protected declare isHighlighted: (highlightedDimension: string) => boolean;
}

export type ComponentType<
    TModel extends Model,
    TPropertyId extends string,
    TTooltipKey extends string
> = BaseComponent<
    TModel,
    TPropertyId,
    EventNotifier,
    MaterialCache,
    MeshCache,
    TTooltipKey,
    ''
>;

const cameraMinZ = 40;
const cameraMaxZ = 60000;

export abstract class GlModel<
    TModel extends Model = Model,
    TPropertyId extends string = string,
    TTooltipKey extends string = string,
    TModelUpdate = unknown,
    TDesignDetails extends DesignDetails = DesignDetails
> extends GLModel<
    TModel,
    TPropertyId,
    EventNotifier,
    MaterialCache,
    MeshCache,
    TTooltipKey,
    LoadsVisibilityInfo,
    IScreenShotSettings<LoadsVisibilityInfo>,
    ''
> {
    protected componentDefinitions: {
        componentType: new(ctor: BaseComponentConstructor<TModel, TPropertyId, TTooltipKey>) => ComponentType<TModel, TPropertyId, TTooltipKey>;
        options?: Partial<BaseComponentConstructor<TModel, TPropertyId, TTooltipKey>>;
    }[] = [];

    private components: ComponentType<TModel, TPropertyId, TTooltipKey>[] = [];

    constructor(
        ctor: GlModelConstructor<TModel, TPropertyId, TTooltipKey, TModelUpdate>
    ) {
        super(ctor);

        this.textEditorChange = ctor.textEditorChange;

        this.initializeComponentDefinitions(ctor.modelUpdate);
        this.initialize();
    }

    public clearSelected(): void {
        // not needed
    }

    public async renderWhenReady(): Promise<void> {
        await this.scene3d.whenReadyAsync(true);
        this.render();
    }

    protected abstract initializeComponentDefinitions(modelUpdate: TModelUpdate | undefined): void;

    protected override resetCamera3d(): void {
        super.resetCamera3d();

        this.camera3d.maxZ = cameraMaxZ;
    }

    protected override initCamera(): void {
        super.initCamera();

        const camera = this.cache.cameraCache.get<ArcRotateCamera>('Main3dCamera');

        camera.minZ = cameraMinZ;
        camera.maxZ = cameraMaxZ;
    }

    /**
     * Creates a list of bounding info to be used in scaling for screenshot
     */
    protected override getBoundingBoxes(): BoundingInfo[] {
        this.ensureNotDisposed();

        let boundingInfo = new BoundingInfo(Vector3.Zero(), Vector3.Zero());
        for (const mesh of this.scene3d.meshes) {
            if (mesh.isEnabled() && mesh.isVisible && !this.isSceneCoordinateSystemOrText(mesh)) {
                boundingInfo = boundingInfo.encapsulateBoundingInfo(mesh.getBoundingInfo());
            }
        }

        return [boundingInfo];
    }

    protected isSceneCoordinateSystemOrText(mesh: AbstractMesh) {
        let node: Node | null | undefined = mesh;

        while (node?.name != null) {
            if (node.name == 'SceneCoordinateSystem' || node.name == 'TextSide' || (node as unknown as { isText: unknown }).isText === true) {
                return true;
            }

            node = node.parent;
        }

        return false;
    }

    protected override propertyValueChangedInternal(changes: Change[], scopeChecks: ScopeCheck[], update: Update, model: TModel, services: UpdateServices, designDetails: TDesignDetails): void {
        // property value changes
        for (const component of this.components) {
            component.propertyValueChanged(changes, update, services, designDetails);
        }

        // scope check changes
        for (const component of this.components) {
            component.scopeCheckChanged(scopeChecks);
        }
    }

    protected override updateComponentsInternal(): void {
        for (const component of this.components) {
            component.update();
        }
    }

    protected override initComponentsInternal(componentCtor: BaseComponentConstructor<TModel, TPropertyId, TTooltipKey>): void {
        for (const componentDefinition of this.componentDefinitions) {
            this.components.push(new componentDefinition.componentType({ ...componentCtor, ...componentDefinition.options }));
        }
    }

    protected override disposeComponents(): void {
        for (const component of this.components) {
            component.dispose();
        }

        this.components = [];
    }

    protected override setComponentsInternal(): void {
        // nothing to do
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected override calculateViewCenter(zoomed: boolean, info?: LoadsVisibilityInfo): Vector3 {
        // TODO TEAM: implement and remove eslint disabled
        return Vector3.Zero();
    }

    protected override resetKeyboardInput(): void {
        // not needed
    }
}
