@if (isVisible) {
    <div class="notifications box-section box-section-white">
        <div class="box-section-header-with-img">
            <div class="image">
                <div class="pe-ui-sp-sprite pe-ui-sp-sprite-info"></div>
            </div>

            <span class="box-section-title notification-title drag-handle-static"
                [l10n]="'Agito.Hilti.Profis3.Notifications'"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.DragToMove' | l10n: localizationService.selectedLanguage"
                [class.flex]="collapsed">
            </span>

            @if (collapsed) {
                <div class="notification-indicator-wrapper">
                    @if (getNotificationCount(NotificationType.info) > 0) {
                        <div class="notification-indicator-container notification-{{NotificationTypeName[NotificationType.info]}}">
                            <div class="notification-indicator-icon pe-ui-sp-sprite pe-ui-sp-sprite-{{NotificationTypeImage[NotificationType.info]}}"></div>
                            <div class="notification-indicator-content">{{getNotificationCount(NotificationType.info)}}</div>
                        </div>
                    }

                    @if (getNotificationCount(NotificationType.alert) > 0) {
                        <div class="notification-indicator-container notification-{{NotificationTypeName[NotificationType.alert]}}">
                            <div class="notification-indicator-icon pe-ui-sp-sprite pe-ui-sp-sprite-{{NotificationTypeImage[NotificationType.alert]}}"></div>
                            <div class="notification-indicator-content">{{getNotificationCount(NotificationType.alert)}}</div>
                        </div>
                    }
                </div>
            }

            <pe-collapse
                [container]="'box-section-content'"
                [collapsed]="collapsed"
                (collapsedChange)="onCollapsedChanged($any($event).detail)">
            </pe-collapse>
        </div>

        <div class="box-section-content">
            @if (hasInfoMessageVisible) {
                <div>
                    @for (notification of beforeSCNotifications; track $index) {
                        <div class="buttons">
                            <pe-notification [notification]="notification"></pe-notification>
                        </div>
                    }
                </div>
            }

            @if (hasAlertScopeChecks) {
                <div class="scope-checks">
                    @for (scopeCheck of alertScopeChecks; track $index) {
                        <div>
                            <pe-notification-scope-check [scopeCheck]="scopeCheck"></pe-notification-scope-check>
                        </div>
                    }
                </div>
            }

            @if (hasInfoMessageVisible) {
                <div>
                    @for (notification of betweenSCNotifications; track $index) {
                        <div class="buttons">
                            <pe-notification [notification]="notification"></pe-notification>
                        </div>
                    }
                </div>
            }

            @if (hasInfoScopeChecks && hasInfoMessageVisible) {
                <div class="scope-checks">
                    @for (scopeCheck of infoScopeChecks; track $index) {
                        <div>
                            <pe-notification-scope-check [scopeCheck]="scopeCheck"></pe-notification-scope-check>
                        </div>
                    }
                </div>
            }

            @if (hasInfoMessageVisible) {
                <div>
                    @for (notification of afterSCNotifications; track $index) {
                        <div  class="buttons">
                            <pe-notification [notification]="notification"></pe-notification>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
}
