// shared environment variables from pe-ui
export interface SharedEnvironmentData {
    readonly translate: boolean;
    readonly baseUrl: string;
    readonly useDevFeatures: boolean;
    readonly debugModeEnabled: boolean;
    readonly applicationVersion: string;
    readonly peTrackingServiceUrl: string; // this is common tracking url, just named weirdly
    readonly accessToken: string;
    readonly signalRCoreInitSessionUrl: string;
    readonly signalRTimeoutInMilliseconds: number;
    readonly isLogEnabled: boolean;
}

export function fromService(data: SharedEnvironmentData): SharedEnvironmentData {
    return {
        translate: data.translate,
        baseUrl: data.baseUrl,
        useDevFeatures: data.useDevFeatures,
        debugModeEnabled: data.debugModeEnabled,
        applicationVersion: data.applicationVersion,
        peTrackingServiceUrl: data.peTrackingServiceUrl,
        accessToken: data.accessToken,
        signalRCoreInitSessionUrl: data.signalRCoreInitSessionUrl,
        signalRTimeoutInMilliseconds: data.signalRTimeoutInMilliseconds,
        isLogEnabled: data.isLogEnabled
    };
}
