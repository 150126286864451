import { LabelRole } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { ButtonGroupType, ButtonSize, ButtonType, CustomControl, NavigationTabWidth, TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { MainMenuCommandC2C } from '../../../shared/entities/command';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import { ISelectRebarProps } from '../../../shared/entities/main-menu/main-menu-controls';
import { RegionC2C, TabC2C } from '../../../shared/services/static-menu.service.base';
import { FeatureFlagTypes } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import { FeaturesVisibilityService } from '../features-visibility.service';
import { LocalizationService } from '../localization.service';
import { ModalService } from '../modal.service';
import { initializeSelectRebar } from './custom-controls-helper/select-rebar';
import { getCommandConditional, getPopupTooltipConditional, isACICSA, isASBased, isPirAS, isPirEU } from './static-menu-helper';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { getSpriteAsIconStyle } from '../../sprites';

export function createC2CMenu_Product(
    design: DesignC2C,
    featureVisibilityService: FeaturesVisibilityService,
    localizationService: LocalizationService,
    modalService: ModalService): TabC2C {
    return {
        DisplayKey: isPirEU(design) ? 'Agito.Hilti.C2C.Navigation.TabPIR' : 'Agito.Hilti.C2C.Navigation.TabProduct',
        Id: 6,
        Name: isPirEU(design) || isPirAS(design) ? 'pir-tab' : 'product-tab',
        Image: 'rebar-icon',
        IconImage: getSpriteAsIconStyle('sprite-rebar-icon'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createC2CMenu_Product_Rebar(design, featureVisibilityService, localizationService, modalService),
            createC2CMenu_Product_TopReinforcement(),
            createC2CMenu_Product_BottomReinforcement(),
            createC2CMenu_Product_ShearConnector(design, featureVisibilityService, localizationService, modalService),
            createC2CMenu_Product_OverlayConnectorPosition(),
            createC2CMenu_Product_OverlayOptimization(),
            createC2CMenu_Product_Zone1(design),
            createC2CMenu_Product_Zone2(),
            createC2CMenu_Product_Zone3(),
            createC2CMenu_Product_Zone4(),
            createC2CMenu_Product_CalculationMode(),
            createC2CMenu_Product_Reinforcement(design),
            createC2CMenu_Product_TransverseReinforcement()
        ]
    };
}

function createC2CMenu_Product_Rebar(
    design: DesignC2C,
    featureVisibilityService: FeaturesVisibilityService,
    localizationService: LocalizationService,
    modalService: ModalService,
): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.Rebar',
        Id: 20,
        Name: 'rebar-section',
        Controls: [
            {
                ControlType: 'CustomControl',
                initialize: (controlProps: ISelectRebarProps, _modals: Record<number, (input?: object) => IModalOpened>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) => initializeSelectRebar(controlProps, localizationService, modalService, design, 10031, setState),
                Name: 'selectedfamilyrebar',
                UIPropertyId: 10031,
                CodelistName: 'RebarFamily',
            } as unknown as CustomControl<ISelectRebarProps, MainMenuCommandC2C>,
            {
                CodelistName: 'FastenerType',
                ControlType: 'Dropdown',
                Name: 'rebar-type-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Connector.Type.Title',
                UIPropertyId: 10440,
                TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Connector.Type.Tooltip.HNA' : 'Agito.Hilti.C2C.Connector.Type.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Connector.Type.Tooltip'
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'RebarMaterial',
                Name: 'rebar-standard-dropdown',
                UIPropertyId: 10715,
                TitleDisplayKey: 'Agito.Hilti.C2C.Product.RebarStandard.Title'
            },
            {
                CodelistName: 'FastenerSize',
                ControlType: 'Dropdown',
                Name: 'rebar-size-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Connector.Size.Title',
                UIPropertyId: 10035,
                TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Connector.Size.Tooltip.HNA' : 'Agito.Hilti.C2C.Connector.Size.Title.Tooltip',
                TooltipTitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Connector.Size.Title.HNA' : 'Agito.Hilti.C2C.Connector.Size.Title.Tooltip'
            },
            {
                CodelistName: 'Overstrength',
                ControlType: 'Dropdown',
                Name: 'overstrength-dropdown',
                UIPropertyId: 10449,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overstrength.Title',
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenOverstrengthPopup', isACICSA(design))
            },
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.None,
                Name: 'overstrength-value-textbox',
                UIPropertyId: 10450,
                TitleDisplayKey: 'Agito.Hilti.C2C.CustomOverstrength.Title',
                FixedDecimals: true
            },
            {
                ControlType: 'Button',
                ButtonSize: ButtonSize.Full,
                ButtonType: ButtonType.Default,
                Name: 'viewetaapproval',
                UIPropertyId: 10032,
                Command: 'OpenApprovalC2C',
                DisplayKey: 'Agito.Hilti.C2C.ViewApproval'
            },
            {
                ControlType: 'Button',
                ButtonSize: ButtonSize.Full,
                ButtonType: ButtonType.Default,
                Name: 'viewuktaapproval',
                UIPropertyId: 10757,
                Command: 'OpenApprovalC2C',
                DisplayKey: 'Agito.Hilti.C2C.ViewApproval.UKTA',
                Hidden: !featureVisibilityService.isFeatureEnabled('C2C_UKTA')
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.EpoxyCoated',
                Name: 'rebar-epoxycoated',
                UIPropertyId: 10448,
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenRebarEpoxyCoatedPopup', isACICSA(design))
            },
            {
                ControlType: 'Checkbox',
                Name: 'rebarsplicetoexistingreinforcement',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.SpliceToExistingReinforcement',
                UIPropertyId: 10453,
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenSpliceToExistingReinforcementPopup', isACICSA(design)),
                Hidden: design.isFeatureEnabled(FeatureFlagTypes.HnaDesignMethods)
            },
            {
                ControlType: 'RadioButtonGroup',
                Name: 'splicesclasses-radio-buttons',
                UIPropertyId: 10454,
                Size: 50,
                CodelistName: 'SpliceClass',
                DisplayKey: 'splice-classes-radio-buttons',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.SpliceClasses',
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenSpliceClassesPopup', isACICSA(design)),
                Hidden: design.isFeatureEnabled(FeatureFlagTypes.HnaDesignMethods)
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.ExcessReinforcement',
                Name: 'excessreinforcement',
                UIPropertyId: 10451
            },
            {
                ControlType: 'TextBox',
                Name: 'excess-reinforcement-as-req',
                Size: 100,
                UIPropertyId: 10748,
                UnitGroup: UnitGroup.Area,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.ExcessReinforcement.AsReq'
            },
            {
                ControlType: 'TextBox',
                Name: 'excess-reinforcement-as-prov',
                Size: 100,
                UIPropertyId: 10749,
                UnitGroup: UnitGroup.Area,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.ExcessReinforcement.AsProv'
            },
            {
                ControlType: 'TextBox',
                Name: 'excess-reinforcement-as-req-prov',
                Size: 100,
                UIPropertyId: 10750,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.ExcessReinforcement.AsReqProv'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.ExcessReinforcement.OverwriteCalculatedAsProv',
                Name: 'excess-reinforcement-overwrite-calculated-as-prov',
                UIPropertyId: 10751
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Product.CompressionConfinement',
                Name: 'compression-confinement',
                UIPropertyId: 10714,
                TooltipType: TooltipType.Popup,
                Command: 'OpenCompressionConfinementPopup'
            },
            {
                ControlType: 'Label',
                Name: 'adhesiveitem-number',
                TitleDisplayKey: 'Agito.Hilti.Profis3.InfoSection.AnchorSection.ArticleNumberChemical',
                UIPropertyId: 10034,
            },
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.Stress,
                Name: 'reinforcement-yield-strength',
                UIPropertyId: 10592,
                TitleDisplayKey: isASBased(design) ? 'Agito.Hilti.C2C.Reinforcement.Fsy' : 'Agito.Hilti.C2C.Reinforcement.ReinforcementYieldStrengthFyk'
            },
            {
                ControlType: 'Checkbox',
                TitleDisplayKey: 'Agito.Hilti.C2C.SelectRebar.FilterGroups.DesignBasis',
                DisplayKey: 'Agito.Hilti.C2C.CodeList.ProductFilterEntity.UKTAAllowed',
                Name: 'Rebar.ViewUKTAApprovalCheckBox',
                UIPropertyId: 10759,
                Hidden: !featureVisibilityService.isFeatureEnabled('C2C_UKTA')
            },
        ]
    };
}

function createC2CMenu_Product_TopReinforcement(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.TopReinforcement',
        Id: 18,
        Name: 'topreinforcement-section',
        Controls: [
            {
                ControlType: 'Group',
                Name: 'rebardiameter',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.RebarDiameter.Title',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Reinforcement.RebarDiameter.Title',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.RebarDiameter.Title',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.RebarDiameter.Title',
                UIPropertyId: 10036
            },
            {
                CodelistName: 'FastenerSize',
                ControlType: 'Dropdown',
                Name: 'topreinforcement-rebardiameter-dropdown',
                UIPropertyId: 10019,
                ParentControlName: 'rebardiameter'
            },
            {
                ControlType: 'Group',
                Name: 'embedmentdepth',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.EmbedmentDepth.Title',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Reinforcement.EmbedmentDepth.Title',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.EmbedmentDepth.Title',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.EmbedmentDepth.Title',
                UIPropertyId: 10037
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-embedmentdepthhef',
                UIPropertyId: 10027,
                ParentControlName: 'embedmentdepth',
                UnitGroup: UnitGroup.Length
            },

            {
                ControlType: 'Group',
                Name: 'topspacing',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Spacing.Title',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Spacing.Title',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Spacing.Title',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.Spacing.Title',
                UIPropertyId: 10038
            }
        ]
    };
}

function createC2CMenu_Product_BottomReinforcement(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.BottomReinforcement',
        Id: 19,
        Name: 'bottomreinforcement-section',
        Controls: [
            {
                ControlType: 'Group',
                Name: 'bottomreinforcement',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Reinforcement.RebarDiameter.Title',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.RebarDiameter.Title',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.RebarDiameter.Title',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.RebarDiameter.Title',
                UIPropertyId: 10039
            },
            {
                CodelistName: 'FastenerSize',
                ControlType: 'Dropdown',
                Name: 'bottomreinforcement-rebardiameter-dropdown',
                UIPropertyId: 10021,
                ParentControlName: 'bottomreinforcement'
            },
            {
                ControlType: 'Group',
                Name: 'bottomreinforcement-embedmentdepth',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Reinforcement.EmbedmentDepth.Title',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.EmbedmentDepth.Title',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.EmbedmentDepth.Title',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.EmbedmentDepth.Title',
                UIPropertyId: 10040
            },
            {
                UnitGroup: UnitGroup.Length,
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-embedmentdepthhef',
                UIPropertyId: 10028,
                ParentControlName: 'bottomreinforcement-embedmentdepth'
            },
            {
                ControlType: 'Group',
                Name: 'bottomspacing',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Spacing.Title',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Spacing.Title',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Spacing.Title',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.Spacing.Title',
                UIPropertyId: 10041
            }
        ]
    };
}

function createC2CMenu_Product_ShearConnector(
    design: DesignC2C,
    featureVisibilityService: FeaturesVisibilityService,
    localizationService: LocalizationService,
    modalService: ModalService): RegionC2C {
    return {
        Id: 43,
        DisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabProduct.Rebar' : 'Agito.Hilti.C2C.Navigation.TabProduct.ShearConnector',
        Name: 'product-tab-shearconnector-section',
        Controls: [
            {
                ControlType: 'CustomControl',
                initialize: (controlProps: ISelectRebarProps, _modals: Record<number, (input?: object) => IModalOpened>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) => initializeSelectRebar(controlProps, localizationService, modalService, design, 10067, setState),
                Name: 'connector-family-select',
                UIPropertyId: 10067,
            } as unknown as CustomControl<ISelectRebarProps, MainMenuCommandC2C>,
            {
                CodelistName: 'FastenerType',
                ControlType: 'Dropdown',
                Name: 'type-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Connector.Type.Title',
                UIPropertyId: 10070,
                TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Connector.Type.Tooltip.HNA' : 'Agito.Hilti.C2C.Connector.Type.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Connector.Type.Tooltip'
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'RebarMaterial',
                Name: 'standard-dropdown',
                UIPropertyId: 10716,
                TitleDisplayKey: 'Agito.Hilti.C2C.Product.RebarStandard.Title'
            },
            {
                CodelistName: 'FastenerSize',
                ControlType: 'Dropdown',
                Name: 'size-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Connector.Size.Title',
                UIPropertyId: 10071,
                TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Connector.Size.Tooltip.HNA' : 'Agito.Hilti.C2C.Connector.Size.Tooltip',
                TooltipTitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Connector.Size.Title.HNA' : 'Agito.Hilti.C2C.Connector.Size.Title'
            },
            {
                ControlType: 'TextBox',
                Name: 'connector-fyk-yield-strength',
                Size: 50,
                UIPropertyId: 10497,
                UnitGroup: UnitGroup.Stress,
                TitleDisplayKey: 'Agito.Hilti.C2C.TabProduct.Fyk.Title'
            },
            {
                ControlType: 'TextBox',
                Name: 'connector-fuk-ultimate-strength',
                Size: 50,
                UIPropertyId: 10498,
                UnitGroup: UnitGroup.Stress,
                TitleDisplayKey: 'Agito.Hilti.C2C.TabProduct.Fuk.Title'
            },
            {
                CodelistName: 'ConnectorLength',
                ControlType: 'Dropdown',
                Name: 'anchor-length-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Anchor.Length.Title',
                UIPropertyId: 10205,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                CodelistName: 'Overstrength',
                ControlType: 'Dropdown',
                Name: 'connector-overstrength-dropdown',
                UIPropertyId: 10455,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overstrength.Title',
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenOverstrengthPopup', isACICSA(design))
            },
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.None,
                Name: 'connector-overstrength-value-textbox',
                UIPropertyId: 10456,
                TitleDisplayKey: 'Agito.Hilti.C2C.CustomOverstrength.Title',
                FixedDecimals: true
            },
            {
                ControlType: 'Button',
                ButtonSize: ButtonSize.Full,
                ButtonType: ButtonType.Default,
                Name: 'connector-viewetaapproval',
                UIPropertyId: 10068,
                Command: 'OpenApprovalC2C',
                DisplayKey: 'Agito.Hilti.C2C.ViewApproval'
            },
            {
                ControlType: 'Button',
                ButtonSize: ButtonSize.Full,
                ButtonType: ButtonType.Default,
                Name: 'connector-viewuktaapproval',
                UIPropertyId: 10758,
                Command: 'OpenApprovalC2C',
                DisplayKey: 'Agito.Hilti.C2C.ViewApproval.UKTA',
                Hidden: !featureVisibilityService.isFeatureEnabled('C2C_UKTA')
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.EpoxyCoated',
                Name: 'epoxycoated',
                UIPropertyId: 10265,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Reinforcement.EpoxyCoated.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.EpoxyCoated.Tooltip.Title',
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenRebarEpoxyCoatedPopup', isACICSA(design))
            },
            {
                ControlType: 'Label',
                Name: 'fastenerroditem-number',
                TitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabProduct.Connector.RebarItem' : 'Agito.Hilti.C2C.Navigation.TabProduct.Connector.ConnectorItem',
                TooltipType: TooltipType.Normal,
                UIPropertyId: 10063,
            },
            {
                ControlType: 'Label',
                Name: 'fasteneradhesiveitem-number',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.Rebar.ArticleNumberChemical.Tooltip.Title',
                TooltipType: TooltipType.Normal,
                UIPropertyId: 10069,
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.OptimizedLayout',
                Name: 'optimized-layout',
                UIPropertyId: 10481,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Reinforcement.OptimizedLayout.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.OptimizedLayout.Tooltip.Title'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.Length,
                Name: 'existing-minimum-concrete-cover',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.MinSideCover',
                UIPropertyId: 10704,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.MinSideCover'
            },
            {
                ControlType: 'Group',
                TitleDisplayKey: 'Agito.Hilti.C2C.Connector.EmbedmentDepth.Title',
                DisplayKey: 'Agito.Hilti.C2C.Connector.EmbedmentDepth.Title',
                Name: 'product-tab-connector',
                TooltipType: TooltipType.Normal,
                UIPropertyId: 10066
            },
            {
                ControlType: 'ToggleButtonGroup',
                ButtonGroupType: ButtonGroupType.Single,
                ButtonGroupUnselectable: true,
                Name: 'connector-embedmentdepth-mode',
                UIPropertyId: 10065,
                CodelistName: 'EmbedmentDepthMode',
                ParentControlName: 'product-tab-connector'
            },
            {
                UnitGroup: UnitGroup.Length,
                ControlType: 'TextBox',
                Name: 'connector-embedmentdepth',
                UIPropertyId: 10064,
                ParentControlName: 'product-tab-connector'
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'EmbedmentDepthExisting',
                Name: 'connector-embedmentdepth-selection',
                UIPropertyId: 10218,
                ParentControlName: 'product-tab-connector'
            },
            {
                UnitGroup: UnitGroup.Length,
                ControlType: 'TextBox',
                Name: 'connector-embedmentdepth-new',
                UIPropertyId: 10274,
                TitleDisplayKey: 'Agito.Hilti.C2C.Connector.EmbedmentDepthNew.Title',
            },
            {
                ControlType: 'Checkbox',
                TitleDisplayKey: 'Agito.Hilti.C2C.SelectRebar.FilterGroups.DesignBasis',
                DisplayKey: 'Agito.Hilti.C2C.CodeList.ProductFilterEntity.UKTAAllowed',
                Name: 'connector-viewuktaapproval-checkBox',
                UIPropertyId: 10760,
                Hidden: !featureVisibilityService.isFeatureEnabled('C2C_UKTA')
            }
        ]
    };
}

function createC2CMenu_Product_OverlayOptimization(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RegionOptimization',
        Id: 95,
        Name: 'overlay-optimization-section',
        Controls: [{
            ControlType: 'RadioButtonGroup',
            Name: 'optimizationtype-radio-buttons',
            UIPropertyId: 10016,
            CodelistName: 'OptimizationType',
            DisplayKey: 'optimizationtype-radio-buttons',
            TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RegionOptimization.OptimizationType.Title'
        }]
    };
}

function createC2CMenu_Product_OverlayConnectorPosition(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RegionConnectorPosition',
        Id: 44,
        Name: 'product-overlayconnectorposition',
        IsNew: true,
        Controls: [
            {
                ControlType: 'Dropdown',
                Name: 'overlay-calculation-mode-dropdown',
                UIPropertyId: 10762,
                CodelistName: 'CalculationMode',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RegionConnectorPosition',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RegionConnectorPosition',
                TooltipDisabledDisplayKey: 'Agito.Hilti.C2C.Loads.AutomaticPositioningNotAllowed.Tooltip',
                TooltipType: TooltipType.Popup,
                Command: 'OpenConnectorPositionOverlayPopup'
            },
            {
                ControlType: 'RadioButtonGroup',
                Name: 'overlay-optimizationtype-radio-buttons',
                UIPropertyId: 10763,
                CodelistName: 'OptimizationType',
                DisplayKey: 'overlay-optimizationtype-radio-buttons',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RegionOptimization.OptimizationType.Title'
            },
            {
                ControlType: 'Group',
                TitleDisplayKey: 'Agito.Hilti.C2C.Connector.EmbedmentDepth.Title',
                DisplayKey: 'Agito.Hilti.C2C.Connector.EmbedmentDepth.Title',
                Name: 'overlay-product-tab-connector',
                TooltipType: TooltipType.Normal
            },
            {
                ControlType: 'ToggleButtonGroup',
                ButtonGroupType: ButtonGroupType.Single,
                ButtonGroupUnselectable: true,
                Name: 'overlay-connector-embedmentdepth-mode',
                UIPropertyId: 10764,
                CodelistName: 'EmbedmentDepthMode',
                ParentControlName: 'overlay-product-tab-connector'
            },
            {
                UnitGroup: UnitGroup.Length,
                ControlType: 'TextBox',
                Name: 'overlay-connector-embedmentdepth',
                UIPropertyId: 10765,
                ParentControlName: 'overlay-product-tab-connector'
            },
            {
                UnitGroup: UnitGroup.Length,
                ControlType: 'TextBox',
                Name: 'overlay-connector-embedmentdepth-new',
                UIPropertyId: 10766,
                TitleDisplayKey: 'Agito.Hilti.C2C.Connector.EmbedmentDepthNew.Title',
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'EmbedmentDepthExisting',
                Name: 'overlay-connector-embedmentdepth-existing-selection',
                UIPropertyId: 10780,
                ParentControlName: 'overlay-product-tab-connector'
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'EmbedmentDepthOverlay',
                Name: 'overlay-connector-embedmentdepth-overlay-selection',
                UIPropertyId: 10781,
                TitleDisplayKey: 'Agito.Hilti.C2C.Connector.EmbedmentDepthNew.Title',
            }
        ]
    };
}

function createC2CMenu_Product_Zone1(design: DesignC2C): RegionC2C {
    return {
        Id: 53,
        DisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabProduct.PostInstalledRebarLayout' : 'Agito.Hilti.C2C.Navigation.TabProduct.Zone1',
        Name: 'concrete-zone1-section',
        Controls: [
            {
                ControlType: 'Group',
                Name: 'concrete-zone1-group',
                MultilineGroup: true,
                UIPropertyId: 10775
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone1-rows',
                MultilineGroup: true,
                ParentControlName: 'concrete-zone1-group',
                UIPropertyId: 10269
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-rowsnumber-x',
                UIPropertyId: 10094,
                ParentControlName: 'concrete-zone1-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-rowsnumber-y',
                UIPropertyId: 10095,
                ParentControlName: 'concrete-zone1-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY'
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone1-spacing',
                MultilineGroup: true,
                ParentControlName: 'concrete-zone1-group',
                UIPropertyId: 10270,
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-spacing-x',
                UIPropertyId: 10097,
                ParentControlName: 'concrete-zone1-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-spacing-y',
                UIPropertyId: 10098,
                ParentControlName: 'concrete-zone1-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone1-edgedistance',
                ParentControlName: 'concrete-zone1-group',
                MultilineGroup: true,
                UIPropertyId: 10271,
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edgedistance-x',
                UIPropertyId: 10100,
                ParentControlName: 'concrete-zone1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistXHNA' : 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edgedistance-y',
                UIPropertyId: 10101,
                ParentControlName: 'concrete-zone1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistYHNA' : 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edgedistance-x-opposite',
                UIPropertyId: 10510,
                ParentControlName: 'concrete-zone1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistXOppositeHNA',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edgedistance-y-opposite',
                UIPropertyId: 10511,
                ParentControlName: 'concrete-zone1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistYOppositeHNA',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'zone1edgearea',
                MultilineGroup: true,
                CollapsableGroup: true,
                ButtonType: ButtonType.Primary,
                UIPropertyId: 10472
            },
            {
                ControlType: 'Group',
                Name: 'zone1edgearea-edge1',
                MultilineGroup: true,
                ParentControlName: 'zone1edgearea',
                UIPropertyId: 10767
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone1-edge1-rows',
                MultilineGroup: true,
                ParentControlName: 'zone1edgearea-edge1',
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge1-rowsnumber-x',
                UIPropertyId: 10291,
                ParentControlName: 'concrete-zone1-edge1-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge1-rowsnumber-y',
                UIPropertyId: 10292,
                ParentControlName: 'concrete-zone1-edge1-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY'
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone1-edge1-spacing',
                MultilineGroup: true,
                ParentControlName: 'zone1edgearea-edge1',
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge1-spacing-x',
                UIPropertyId: 10294,
                ParentControlName: 'concrete-zone1-edge1-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge1-spacing-y',
                UIPropertyId: 10295,
                ParentControlName: 'concrete-zone1-edge1-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone1-edge1-edgedistance',
                MultilineGroup: true,
                ParentControlName: 'zone1edgearea-edge1'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge1-edgedistance-x',
                UIPropertyId: 10297,
                ParentControlName: 'concrete-zone1-edge1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge1-edgedistance-y',
                UIPropertyId: 10298,
                ParentControlName: 'concrete-zone1-edge1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'zone1edgearea-edge2',
                MultilineGroup: true,
                ParentControlName: 'zone1edgearea',
                UIPropertyId: 10768
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone1-edge2-rows',
                MultilineGroup: true,
                ParentControlName: 'zone1edgearea-edge2'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge2-rowsnumber-x',
                UIPropertyId: 10300,
                ParentControlName: 'concrete-zone1-edge2-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge2-rowsnumber-y',
                UIPropertyId: 10301,
                ParentControlName: 'concrete-zone1-edge2-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY'
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone1-edge2-spacing',
                MultilineGroup: true,
                ParentControlName: 'zone1edgearea-edge2'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge2-spacing-x',
                UIPropertyId: 10303,
                ParentControlName: 'concrete-zone1-edge2-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge2-spacing-y',
                UIPropertyId: 10304,
                ParentControlName: 'concrete-zone1-edge2-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone1-edge2-edgedistance',
                MultilineGroup: true,
                ParentControlName: 'zone1edgearea-edge2'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge2-edgedistance-x',
                UIPropertyId: 10306,
                ParentControlName: 'concrete-zone1-edge2-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone1-edge2-edgedistance-y',
                UIPropertyId: 10307,
                ParentControlName: 'concrete-zone1-edge2-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY',
                Size: 50
            }
        ]
    };
}

function createC2CMenu_Product_Zone2(): RegionC2C {
    return {
        Id: 66,
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.Zone2',
        Name: 'concrete-zone2-section',
        Controls: [
            {
                ControlType: 'Group',
                Name: 'concrete-zone2-group',
                MultilineGroup: true,
                UIPropertyId: 10776
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone2-rows',
                MultilineGroup: true,
                UIPropertyId: 10269,
                ParentControlName: 'concrete-zone2-group'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-rowsnumber-x',
                UIPropertyId: 10103,
                ParentControlName: 'concrete-zone2-rows',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX',
                Size: 50,
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-rowsnumber-y',
                UIPropertyId: 10104,
                ParentControlName: 'concrete-zone2-rows',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY',
                Size: 50,
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone2-spacing',
                MultilineGroup: true,
                UIPropertyId: 10270,
                ParentControlName: 'concrete-zone2-group'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-spacing-x',
                UIPropertyId: 10106,
                ParentControlName: 'concrete-zone2-spacing',
                UnitGroup: UnitGroup.Length,
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-spacing-y',
                UIPropertyId: 10107,
                ParentControlName: 'concrete-zone2-spacing',
                UnitGroup: UnitGroup.Length,
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY'
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone2-edgedistance',
                MultilineGroup: true,
                UIPropertyId: 10271,
                ParentControlName: 'concrete-zone2-group'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edgedistance-x',
                UIPropertyId: 10109,
                ParentControlName: 'concrete-zone2-edgedistance',
                UnitGroup: UnitGroup.Length,
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edgedistance-y',
                UIPropertyId: 10110,
                ParentControlName: 'concrete-zone2-edgedistance',
                UnitGroup: UnitGroup.Length,
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY'
            },
            {
                ControlType: 'Group',
                Name: 'zone2edgearea',
                MultilineGroup: true,
                CollapsableGroup: true,
                ButtonType: ButtonType.Primary,
                UIPropertyId: 10472
            },
            {
                ControlType: 'Group',
                Name: 'zone2edgearea-edge1',
                MultilineGroup: true,
                ParentControlName: 'zone2edgearea',
                UIPropertyId: 10769
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone2-edge1-rows',
                MultilineGroup: true,
                ParentControlName: 'zone2edgearea-edge1',
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge1-rowsnumber-x',
                UIPropertyId: 10309,
                ParentControlName: 'concrete-zone2-edge1-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge1-rowsnumber-y',
                UIPropertyId: 10310,
                ParentControlName: 'concrete-zone2-edge1-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY'
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone2-edge1-spacing',
                MultilineGroup: true,
                ParentControlName: 'zone2edgearea-edge1',
                UIPropertyId: 10371
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge1-spacing-x',
                UIPropertyId: 10312,
                ParentControlName: 'concrete-zone2-edge1-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge1-spacing-y',
                UIPropertyId: 10313,
                ParentControlName: 'concrete-zone2-edge1-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone2-edge1-edgedistance',
                MultilineGroup: true,
                ParentControlName: 'zone2edgearea-edge1',
                UIPropertyId: 10371
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge1-edgedistance-x',
                UIPropertyId: 10315,
                ParentControlName: 'concrete-zone2-edge1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge1-edgedistance-y',
                UIPropertyId: 10316,
                ParentControlName: 'concrete-zone2-edge1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'zone2edgearea-edge2',
                MultilineGroup: true,
                ParentControlName: 'zone2edgearea',
                UIPropertyId: 10770
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone2-edge2-rows',
                MultilineGroup: true,
                ParentControlName: 'zone2edgearea-edge2',
                UIPropertyId: 10372
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge2-rowsnumber-x',
                UIPropertyId: 10318,
                ParentControlName: 'concrete-zone2-edge2-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge2-rowsnumber-y',
                UIPropertyId: 10319,
                ParentControlName: 'concrete-zone2-edge2-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY'
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone2-edge2-spacing',
                MultilineGroup: true,
                ParentControlName: 'zone2edgearea-edge2',
                UIPropertyId: 10372
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge2-spacing-x',
                UIPropertyId: 10321,
                ParentControlName: 'concrete-zone2-edge2-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge2-spacing-y',
                UIPropertyId: 10322,
                ParentControlName: 'concrete-zone2-edge2-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone2-edge2-edgedistance',
                MultilineGroup: true,
                ParentControlName: 'zone2edgearea-edge2',
                UIPropertyId: 10372
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge2-edgedistance-x',
                UIPropertyId: 10324,
                ParentControlName: 'concrete-zone2-edge2-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone2-edge2-edgedistance-y',
                UIPropertyId: 10325,
                ParentControlName: 'concrete-zone2-edge2-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY',
                Size: 50
            }
        ]
    };
}

function createC2CMenu_Product_Zone3(): RegionC2C {
    return {
        Id: 79,
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.Zone3',
        Name: 'concrete-zone3-section',
        Controls: [
            {
                ControlType: 'Group',
                Name: 'concrete-zone3-group',
                MultilineGroup: true,
                UIPropertyId: 10777
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone3-rows',
                MultilineGroup: true,
                UIPropertyId: 10269,
                ParentControlName: 'concrete-zone3-group'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-rowsnumber-x',
                UIPropertyId: 10112,
                ParentControlName: 'concrete-zone3-rows',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX',
                Size: 50,
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-rowsnumber-y',
                UIPropertyId: 10113,
                ParentControlName: 'concrete-zone3-rows',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY',
                Size: 50,
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone3-spacing',
                MultilineGroup: true,
                UIPropertyId: 10270,
                ParentControlName: 'concrete-zone3-group'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-spacing-x',
                UIPropertyId: 10115,
                ParentControlName: 'concrete-zone3-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX',
                Size: 50,
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-spacing-y',
                UIPropertyId: 10116,
                ParentControlName: 'concrete-zone3-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY',
                Size: 50,
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone3-edgedistance',
                MultilineGroup: true,
                UIPropertyId: 10271,
                ParentControlName: 'concrete-zone3-group'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edgedistance-x',
                UIPropertyId: 10118,
                ParentControlName: 'concrete-zone3-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX',
                Size: 50,
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edgedistance-y',
                UIPropertyId: 10119,
                ParentControlName: 'concrete-zone3-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY',
                Size: 50,
            },
            {
                ControlType: 'Group',
                Name: 'zone3edgearea',
                MultilineGroup: true,
                CollapsableGroup: true,
                ButtonType: ButtonType.Primary,
                UIPropertyId: 10472,
            },
            {
                ControlType: 'Group',
                Name: 'zone3edgearea-edge1',
                MultilineGroup: true,
                ParentControlName: 'zone3edgearea',
                UIPropertyId: 10771
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone3-edge1-rows',
                MultilineGroup: true,
                ParentControlName: 'zone3edgearea-edge1',
                UIPropertyId: 10373
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge1-rowsnumber-x',
                UIPropertyId: 10327,
                ParentControlName: 'concrete-zone3-edge1-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge1-rowsnumber-y',
                UIPropertyId: 10328,
                ParentControlName: 'concrete-zone3-edge1-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY'
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone3-edge1-spacing',
                MultilineGroup: true,
                ParentControlName: 'zone3edgearea-edge1',
                UIPropertyId: 10373
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge1-spacing-x',
                UIPropertyId: 10330,
                ParentControlName: 'concrete-zone3-edge1-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge1-spacing-y',
                UIPropertyId: 10331,
                ParentControlName: 'concrete-zone3-edge1-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone3-edge1-edgedistance',
                MultilineGroup: true,
                ParentControlName: 'zone3edgearea-edge1',
                UIPropertyId: 10373
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge1-edgedistance-x',
                UIPropertyId: 10333,
                ParentControlName: 'concrete-zone3-edge1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge1-edgedistance-y',
                UIPropertyId: 10334,
                ParentControlName: 'concrete-zone3-edge1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'zone3edgearea-edge2',
                MultilineGroup: true,
                ParentControlName: 'zone3edgearea',
                UIPropertyId: 10772
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone3-edge2-rows',
                MultilineGroup: true,
                ParentControlName: 'zone3edgearea-edge2',
                UIPropertyId: 10374
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge2-rowsnumber-x',
                UIPropertyId: 10336,
                ParentControlName: 'concrete-zone3-edge2-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge2-rowsnumber-y',
                UIPropertyId: 10337,
                ParentControlName: 'concrete-zone3-edge2-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY'
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone3-edge2-spacing',
                MultilineGroup: true,
                ParentControlName: 'zone3edgearea-edge2',
                UIPropertyId: 10374
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge2-spacing-x',
                UIPropertyId: 10339,
                ParentControlName: 'concrete-zone3-edge2-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge2-spacing-y',
                UIPropertyId: 10340,
                ParentControlName: 'concrete-zone3-edge2-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone3-edge2-edgedistance',
                MultilineGroup: true,
                ParentControlName: 'zone3edgearea-edge2',
                UIPropertyId: 10374
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge2-edgedistance-x',
                UIPropertyId: 10342,
                ParentControlName: 'concrete-zone3-edge2-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone3-edge2-edgedistance-y',
                UIPropertyId: 10343,
                ParentControlName: 'concrete-zone3-edge2-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY',
                Size: 50
            }
        ]
    };
}

function createC2CMenu_Product_Zone4(): RegionC2C {
    return {
        Id: 92,
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.Zone4',
        Name: 'concrete-zone4-section',
        Controls: [
            {
                ControlType: 'Group',
                Name: 'concrete-zone4-group',
                MultilineGroup: true,
                UIPropertyId: 10778
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone4-rows',
                MultilineGroup: true,
                UIPropertyId: 10269,
                ParentControlName: 'concrete-zone4-group'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-rowsnumber-x',
                UIPropertyId: 10121,
                ParentControlName: 'concrete-zone4-rows',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX',
                Size: 50,
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-rowsnumber-y',
                UIPropertyId: 10122,
                ParentControlName: 'concrete-zone4-rows',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY',
                Size: 50,
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone4-spacing',
                MultilineGroup: true,
                UIPropertyId: 10270,
                ParentControlName: 'concrete-zone4-group'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-spacing-x',
                UIPropertyId: 10124,
                ParentControlName: 'concrete-zone4-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX',
                Size: 50,
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-spacing-y',
                UIPropertyId: 10125,
                ParentControlName: 'concrete-zone4-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY',
                Size: 50,
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone4-edgedistance',
                MultilineGroup: true,
                UIPropertyId: 10271,
                ParentControlName: 'concrete-zone4-group'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edgedistance-x',
                UIPropertyId: 10127,
                ParentControlName: 'concrete-zone4-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX',
                Size: 50,
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edgedistance-y',
                UIPropertyId: 10128,
                ParentControlName: 'concrete-zone4-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY',
                Size: 50,
            },
            {
                ControlType: 'Group',
                Name: 'zone4edgearea',
                MultilineGroup: true,
                CollapsableGroup: true,
                ButtonType: ButtonType.Primary,
                UIPropertyId: 10472
            },
            {
                ControlType: 'Group',
                Name: 'zone4edgearea-edge1',
                MultilineGroup: true,
                ParentControlName: 'zone4edgearea',
                UIPropertyId: 10773
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone4-edge1-rows',
                MultilineGroup: true,
                ParentControlName: 'zone4edgearea-edge1',
                UIPropertyId: 10375
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge1-rowsnumber-x',
                UIPropertyId: 10345,
                ParentControlName: 'concrete-zone4-edge1-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge1-rowsnumber-y',
                UIPropertyId: 10346,
                ParentControlName: 'concrete-zone4-edge1-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY'
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone4-edge1-spacing',
                MultilineGroup: true,
                ParentControlName: 'zone4edgearea-edge1',
                UIPropertyId: 10375
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge1-spacing-x',
                UIPropertyId: 10348,
                ParentControlName: 'concrete-zone4-edge1-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge1-spacing-y',
                UIPropertyId: 10349,
                ParentControlName: 'concrete-zone4-edge1-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone4-edge1-edgedistance',
                MultilineGroup: true,
                ParentControlName: 'zone4edgearea-edge1',
                UIPropertyId: 10375
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge1-edgedistance-x',
                UIPropertyId: 10351,
                ParentControlName: 'concrete-zone4-edge1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge1-edgedistance-y',
                UIPropertyId: 10352,
                ParentControlName: 'concrete-zone4-edge1-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'zone4edgearea-edge2',
                MultilineGroup: true,
                ParentControlName: 'zone4edgearea',
                UIPropertyId: 10774
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone4-edge2-rows',
                MultilineGroup: true,
                ParentControlName: 'zone4edgearea-edge2',
                UIPropertyId: 10376
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge2-rowsnumber-x',
                UIPropertyId: 10354,
                ParentControlName: 'concrete-zone4-edge2-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsX'
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge2-rowsnumber-y',
                UIPropertyId: 10355,
                ParentControlName: 'concrete-zone4-edge2-rows',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.RowsY'
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone4-edge2-spacing',
                MultilineGroup: true,
                ParentControlName: 'zone4edgearea-edge2',
                UIPropertyId: 10376
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge2-spacing-x',
                UIPropertyId: 10357,
                ParentControlName: 'concrete-zone4-edge2-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge2-spacing-y',
                UIPropertyId: 10358,
                ParentControlName: 'concrete-zone4-edge2-spacing',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.SpacingY',
                Size: 50
            },
            {
                ControlType: 'Group',
                Name: 'concrete-zone4-edge2-edgedistance',
                MultilineGroup: true,
                ParentControlName: 'zone4edgearea-edge2',
                UIPropertyId: 10376
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge2-edgedistance-x',
                UIPropertyId: 10360,
                ParentControlName: 'concrete-zone4-edge2-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistX',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'concrete-zone4-edge2-edgedistance-y',
                UIPropertyId: 10361,
                ParentControlName: 'concrete-zone4-edge2-edgedistance',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.EdgeDistY',
                Size: 50
            }
        ]
    };
}

function createC2CMenu_Product_CalculationMode(): RegionC2C {
    return {
        Id: 93,
        Name: 'calculation-mode',
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.CalculationMode',
        Controls: [
            {
                ControlType: 'Dropdown',
                Name: 'calculation-mode-dropdown',
                CodelistName: 'CalculationMode',
                UIPropertyId: 10570,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.CalculationMode.RebarPosition',
                TooltipType: TooltipType.Popup,
                Command: 'OpenRebarPositionPopup'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.Length,
                Name: 'CalculationMode.MinConcreteCoverOptimization',
                TitleDisplayKey: 'Agito.Hilti.C2C.Product.MinConcreteCoverOptimization',
                UIPropertyId: 10689
            }
        ]
    };
}

function createC2CMenu_Product_Reinforcement(design: DesignC2C): RegionC2C {
    return {
        Id: 94,
        Name: 'reinforcement',
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.Reinforcement',
        Controls: [
            {
                ControlType: 'Dropdown',
                Name: 'reinforcement-option-dropdown',
                CodelistName: 'LongitudinalReinforcementOption',
                UIPropertyId: 10571
            },
            {
                ControlType: 'Label',
                Name: '2d-mode-disclaimer-label',
                UIPropertyId: 10703,
                Role: LabelRole.Regular
            },
            {
                ControlType: 'TabGroup',
                Name: 'reinforcement-layers-tab',
                Tabs: [
                    {
                        DisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopLayer',
                        Name: 'reinforcement-layers-tab-top-layer',
                        Tag: 'top-layer'
                    },
                    {
                        DisplayKey: 'Agito.Hilti.C2C.Reinforcement.BottomLayer',
                        Name: 'reinforcement-layers-tab-bottom-layer',
                        Tag: 'bottom-layer'
                    }
                ]
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopLayer',
                Name: 'show-toplayer',
                UIPropertyId: 10627,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                ControlType: 'Dropdown',
                Name: 'toplayers-count',
                UIPropertyId: 10685,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.NumberOfLayers',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                CodelistName: 'NumberOfLayers'
            },
            {
                CodelistName: 'BondCondition',
                ControlType: 'RadioButtonGroup',
                Name: 'bondcondition-toplayers-radio-buttons',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.BondCondition',
                UIPropertyId: 10578,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                ControlType: 'Label',
                Name: 'layerlabel-toplayer1',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerOne',
                UIPropertyId: 10629,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Role: LabelRole.SubHeading
            },
            {
                CodelistName: 'FastenerSize',
                ControlType: 'Dropdown',
                Name: 'diameter-toplayer1-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                UIPropertyId: 10572,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                CodelistName: 'SpacingBarsOption',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                ControlType: 'RadioButtonGroup',
                Name: 'spacingbarsoptions-toplayer1-radio-buttons',
                UIPropertyId: 10573,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'numberofbars-toplayer1',
                UIPropertyId: 10619,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'spacing-toplayer1',
                UIPropertyId: 10620,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'topcover-toplayer1',
                UIPropertyId: 10574,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'sidecover-toplayer1',
                UIPropertyId: 10691,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'minsidecover-toplayer1',
                UIPropertyId: 10724,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'Label',
                Name: 'layerlabel-toplayer2',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerTwo',
                UIPropertyId: 10630,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Role: LabelRole.SubHeading
            },
            {
                CodelistName: 'FastenerSize',
                ControlType: 'Dropdown',
                Name: 'diameter-toplayer-2-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                UIPropertyId: 10575,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                CodelistName: 'SpacingBarsOption',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                ControlType: 'RadioButtonGroup',
                Name: 'spacingbarsoptions-toplayer2-radio-buttons',
                UIPropertyId: 10576,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'numberofbars-toplayer2',
                UIPropertyId: 10621,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'spacing-toplayer2',
                UIPropertyId: 10622,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'topcover-toplayer2',
                UIPropertyId: 10577,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Reinforcement.ClearSpacingBetweenRows' : 'Agito.Hilti.C2C.Reinforcement.TopCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'sidecover-toplayer2',
                UIPropertyId: 10692,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'minsidecover-toplayer2',
                UIPropertyId: 10725,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.BottomLayer',
                Name: 'show-bottomlayer',
                UIPropertyId: 10628,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                ControlType: 'Dropdown',
                Name: 'bottomlayers-count',
                UIPropertyId: 10686,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.NumberOfLayers',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                CodelistName: 'NumberOfLayers'
            },
            {
                CodelistName: 'BondCondition',
                ControlType: 'RadioButtonGroup',
                Name: 'bondcondition-bottomlayers-radio-buttons',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.BondCondition',
                UIPropertyId: 10585,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                ControlType: 'Label',
                Name: 'layerlabel-bottomlayer1',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerOne',
                UIPropertyId: 10631,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Role: LabelRole.SubHeading
            },
            {
                CodelistName: 'FastenerSize',
                ControlType: 'Dropdown',
                Name: 'diameter-bottomlayer-1-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                UIPropertyId: 10579,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                CodelistName: 'SpacingBarsOption',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                ControlType: 'RadioButtonGroup',
                Name: 'spacingbarsoptions-bottomlayer1-radio-buttons',
                UIPropertyId: 10580,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'numberofbars-bottomlayer1',
                UIPropertyId: 10623,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'spacing-bottomlayer1',
                UIPropertyId: 10624,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'topcover-bottomlayer1',
                UIPropertyId: 10581,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'sidecover-bottomlayer1',
                UIPropertyId: 10693,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'minsidecover-bottomlayer1',
                UIPropertyId: 10726,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'Label',
                Name: 'layerlabel-bottomlayer2',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerTwo',
                UIPropertyId: 10632,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Role: LabelRole.SubHeading
            },
            {
                CodelistName: 'FastenerSize',
                ControlType: 'Dropdown',
                Name: 'diameter-bottomlayer-2-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                UIPropertyId: 10582,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                CodelistName: 'SpacingBarsOption',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                ControlType: 'RadioButtonGroup',
                Name: 'spacingbarsoptions-bottomlayer2-radio-buttons',
                UIPropertyId: 10583,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'numberofbars-bottomlayer2',
                UIPropertyId: 10625,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'spacing-bottomlayer2',
                UIPropertyId: 10626,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'topcover-bottomlayer2',
                UIPropertyId: 10584,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.TransverseReinforcement.ClearSpacingBetweenRows' : 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'sidecover-bottomlayer2',
                UIPropertyId: 10694,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'minsidecover-bottomlayer2',
                UIPropertyId: 10727,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                ParentControlName: 'reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                CodelistName: 'BondCondition',
                ControlType: 'RadioButtonGroup',
                Name: 'bond-condition-arrangements-radio-buttons',
                Size: 100,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.BondCondition',
                UIPropertyId: 10590,
            },
            {
                CodelistName: 'FastenerSize',
                ControlType: 'Dropdown',
                Name: 'diameter-arrangements-dropdown',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                UIPropertyId: 10586,
                UnitGroup: UnitGroup.Length
            },
            {
                ControlType: 'TextBox',
                Name: 'numberofbars-arrangements',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBars',
                UIPropertyId: 10656,
            },
            {
                ControlType: 'TextBox',
                Name: 'numberofbars-x-arrangements',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBarsX',
                UIPropertyId: 10587,
            },
            {
                ControlType: 'TextBox',
                Name: 'numberofbars-y-arrangements',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBarsY',
                UIPropertyId: 10588,
            },
            {
                ControlType: 'TextBox',
                Name: 'cover-arrangements',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Cover',
                UIPropertyId: 10589,
                UnitGroup: UnitGroup.Length,
            },
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10756,
                Name: 'pir-rebar-spacing',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Product.PirRebarSpacing',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Product.PirRebarSpacing.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Product.PirRebarSpacing',
                TooltipType: TooltipType.Popup,
                Command: 'OpenRebarSpacingPopup'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Product.Reinforcement.IncludeReinforcementCompression',
                Name: 'includereinforcementcompression',
                UIPropertyId: 10591,
                TooltipType: TooltipType.Popup,
                Command: 'OpenIncludeReinforcementCompressionPopup',
            },
            {
                ControlType: 'Button',
                ButtonSize: ButtonSize.Full,
                ButtonType: ButtonType.Default,
                Name: 'defineotherparameters',
                DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.DefineOtherParameters',
                UIPropertyId: 10633,
                Command: 'OpenDefineOtherRebarParameters'
            },
            {
                ControlType: 'TextBox',
                UIPropertyId: 10742,
                Name: 'distance-cip-pir',
                TitleDisplayKey: 'Agito.Hilti.C2C.Product.DistanceCipPir',
                UnitGroup: UnitGroup.Length,
                TooltipType: TooltipType.Popup,
                Command: 'OpenDistanceCipPir'
            }
        ]
    };
}

function createC2CMenu_Product_TransverseReinforcement(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TransverseReinforcement',
        Id: 95,
        Name: 'product-transverse-reinforcement-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Define',
                Name: 'product-transverse-reinforcement-define',
                UIPropertyId: 10820,
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'GenericRebarDiameter',
                Name: 'transverse-reinforcement-stirrup-diameter-id',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10821
            },
            {
                ControlType: 'TextBox',
                Name: 'product-transverse-reinforcement-spacing',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                UIPropertyId: 10823,
                Size: 50,
                UnitGroup: UnitGroup.Length
            },
            {
                ControlType: 'TextBox',
                Name: 'product-transverse-reinforcement-number-of-stirrup-legs',
                TitleDisplayKey: 'Agito.Hilti.C2C.ProductTransverseReinforcement.NumberOfStirrupLegs',
                UIPropertyId: 10822,
                Size: 50,
            }
        ]
    };
}
