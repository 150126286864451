import cloneDeep from 'lodash-es/cloneDeep';
import filter from 'lodash-es/filter';
import { Subscription } from 'rxjs';
import {
    IIMportDesign, ImportDesignComponent, ImportDesignMode
} from 'src/app/components/import-design/import-design.component';

import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Tooltip } from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import {
    IMenuItem, MENU_SEPERATOR
} from '@profis-engineering/pe-ui-common/components/context-menu/context-menu.common';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { IProjectAndDesignId } from '@profis-engineering/pe-ui-common/entities/design';
import { IMainHeaderComponent } from '@profis-engineering/pe-ui-common/entities/main-header';
import { IQuickStartApplication } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { ProjectType } from '@profis-engineering/pe-ui-common/entities/project';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { Feature } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { ProjectExpandedState } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import { DocumentModel } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import { DocumentIntegrationType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { SafeFunctionInvokerHelper } from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { ButtonEventType } from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import {
    CantArchiveProjectsBecauseDocumentInUse, IDesignListItem
} from '@profis-engineering/pe-ui-common/services/document.common';
import { ProjectAndDesignView as DesignView } from '@profis-engineering/pe-ui-common/services/user.common';
import { IDeckingDesignListInfo } from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design-list-info';
import sortBy from 'lodash-es/sortBy';
import split from 'lodash-es/split';
import { environment } from '../../../environments/environment';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { Design } from '../../entities/design';
import { IDisplayDesign } from '../../entities/display-design';
import {
    IDesignExtended, Project, ProjectDownloadPayLoadModel, ProjectGetProjectContentResponseModel
} from '../../entities/project';
import { urlPath } from '../../module-constants';
import { ApiService } from '../../services/api.service';
import { BrowserService } from '../../services/browser.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentService } from '../../services/document.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { GuidService } from '../../services/guid.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { DesignTypeId, ModulesService } from '../../services/modules.service';
import { NpsService } from '../../services/nps.service';
import { OfflineService } from '../../services/offline.service';
import { PendingActionService } from '../../services/pending-action.service';
import { ProductInformationService } from '../../services/product-information.service';
import { RoutingService } from '../../services/routing.service';
import { TourService } from '../../services/tour.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { ModalDialogType } from '../add-edit-design/add-edit-design-models';
import { ComparisonTable } from '../license-comparison-hol/license-comparison-hol.component';
import { IConfirmChangeInputProps } from './confirm-change-input/confirm-change-input.component';
import {
    DocumentType, IDisplayDesignChangeOutput, IDisplayDesignInput, IDisplayProject,
    IGroupedDisplayDesign, ILeftNavigationSelectedState, ISectionCollapsed, ISectionsCollapsed,
    LeftNavigationPrimaryButtons, LeftNavigationSecondaryButtons, MenuOptions, PendingAction, ProjectType as FolderType
} from './home-page.common';
import { getVirtualTourMyProjectsTreeData, getVirtualTourSharedProjectsTreeData, normalizeArray } from './home-page.helper';
import { DesignTemplateFolderDetail, TemplateFolderRequestModel } from './template-folder';
import { ITreeViewItem } from './tree-view/tree-view.component';

export const MAX_TREE_DEPTH = 3;

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
    leftNavigationButtons = LeftNavigationPrimaryButtons;
    drafts: ITreeViewItem<string>;
    allDesigns: ITreeViewItem<string>;
    anchorDesign: ITreeViewItem<string>;
    favouritesTree: ITreeViewItem<string>;
    templatesTree: ITreeViewItem<string>;
    companyProjectsTree: ITreeViewItem<string>;
    sharedTree: ITreeViewItem<string>;
    myProjectsTree: ITreeViewItem<string>;
    menuOptionsList: { [key: number]: IMenuItem<string> } = {};
    public disabled: boolean;
    public pendingAction: PendingAction;
    public pendingActionDesign: IProjectAndDesignId;
    public pendingActionQuickStart: string;
    public groupedDesigns: IGroupedDisplayDesign[];
    public projects: IDisplayProject[] = [];
    public deckingEnabled = false;
    public isInternalLicenseSwitchAvailable = false;
    public projectItemCount: { [key: string]: number } = {};
    public overviewItemCount: { [key: number]: number } = {};
    public projectFilterResult: { [id: string]: boolean };
    public groupedDesignsLimit: { [key: number]: number } = {};
    public _projectFilterString: string;
    public importDesign: IIMportDesign;
    public _designView: DesignView = DesignView.allDesigns;
    private _selectedProject: IDisplayProject;
    private _importing: boolean;
    private designListInfoProvidedSubscription: Subscription;
    archiveTree: ITreeViewItem<string>;
    regions: any; // TODO Why any?
    private favouritesClickCounter: number;
    public displayDesignInput: IDisplayDesignInput = {
        leftNavigationSelectedState:
        {
            primarySelection: LeftNavigationPrimaryButtons.AllDesigns
        }
    };
    selectedTreeItem: ITreeViewItem<string>;
    // pendingActionSubscription: Subscription;
    searchProjectText: string;
    private _selectedTemplateFolder: DesignTemplateFolderDetail;

    public virtaulTourInProgress = false;
    public virtaulTourExpandShared = false;
    public designDropActive = false;
    operationCompleteSubscription: Subscription
    public sectionsCollapsed: ISectionsCollapsed = {
        favorites: { collapsed: false, control: CollapsingControls.Favorites },
        templates: { collapsed: false, control: CollapsingControls.Templates },
        companyProjects: { collapsed: false, control: CollapsingControls.CompanyProjects },
        shared: { collapsed: false, control: CollapsingControls.Shared },
        sharedByMe: { collapsed: false, control: CollapsingControls.SharedByMe },
        sharedWithMe: { collapsed: false, control: CollapsingControls.SharedWithMe },
        myProjects: { collapsed: false, control: CollapsingControls.MyProjects },
        templatesSharedByMe: { collapsed: false, control: CollapsingControls.TemplatesSharedByMe },
        templatesSharedWithMe: { collapsed: false, control: CollapsingControls.TemplatesSharedWithMe },
    };
    @ViewChild('importDesign') importDesignComponent: ImportDesignComponent;
    @ViewChild('createNewDropdown') public createNewDropdownRef!: NgbDropdown;
    @ViewChild('createNewDropdownElementRef') public createNewDropdownElementRef!: ElementRef<HTMLElement>;
    @ViewChild('mainHeaderRef') public mainHeaderComponentRef!: ElementRef<IMainHeaderComponent>;
    private localizationChangeSubscription: Subscription;

    constructor(
        private tour: TourService,
        private modal: ModalService,
        private productInformation: ProductInformationService,
        public userSettingsService: UserSettingsService,
        private routingService: RoutingService,
        public offlineService: OfflineService,
        private commonTracking: CommonTrackingService,
        private documentService: DocumentService,
        public user: UserService,
        private modulesService: ModulesService,
        public localization: LocalizationService,
        private codeListCommon: CommonCodeListService,
        private onServiceErrorHandler: ErrorHandlerService,
        private guid: GuidService,
        private featureVisibilityService: FeatureVisibilityService,
        private apiService: ApiService,
        private browser: BrowserService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private templateService: DesignTemplateService,
        public license: LicenseService,
        private npsService: NpsService,
        private pendingActionService: PendingActionService,
        private designTemplateService: DesignTemplateService
    ) {
        this.setMenuOptions();
    }

    async ngOnInit(): Promise<void> {
        this.deckingEnabled = this.featureVisibilityService.isFeatureEnabled('Decking_Global') || environment.deckingEnabled;
        this.favouritesClickCounter = 0;
        this.startTour = this.startTour.bind(this);
        this.openApplicationSettings = this.openApplicationSettings.bind(this);
        this.onDesignImporting = this.onDesignImporting.bind(this);
        this.newDesignFromDesignTypeId = this.newDesignFromDesignTypeId.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        document.addEventListener('keydown', this.onKeyDown, false);
        this.localizationChangeSubscription = this.localization.localizationChange.subscribe(() => {
                this.setMenuOptions();
                this.setTreeData();
        });
        this.regions = normalizeArray(this.codeListCommon.commonCodeLists[CommonCodeList.Region] as CommonRegion[], 'id');
        document.body.classList.add(`project-and-design-view-body-${this.offlineService.isOffline ? 'offline' : 'online'}`);
        this.operationCompleteSubscription = this.pendingActionService.pendingActions.subscribe((res: PendingAction) => {
            this.pendingAction = res;
            if (res == PendingAction.createTemplateSuccess) {
                setTimeout(() => {
                    this.templatesTree.onSelect(this.templatesTree);
                    this.clearPendingAction(PendingAction.createTemplateSuccess);
                }, 0);
            }

            if (res == PendingAction.RefershTree) {
                setTimeout(() => {
                    this.clearPendingAction(PendingAction.RefershTree);
                    this.refreshDesigns();
                }, 0);

            }
        });
        this.importDesign = {
            disabled: this.disabled,
            mode: this.offlineService.isOffline ? ImportDesignMode.compact : ImportDesignMode.normal,
            importing: false,
            selectImportFile: () => { this.importDesignComponent.selectImportFile() }
        };

        this.designListInfoProvidedSubscription = this.modulesService.designListInfoProvided.subscribe(() => {
            this.selectedProject = this.toDisplayProject(this.user.project || this.documentService.draftsProject);
        });

        if (this.user.project && this.documentService.draftsProject.id) {
            const flatProject = this.documentService.findProject(this.user.project?.id || this.documentService.draftsProject.id, this.documentService.projects);
            this.user.changeDesign(undefined, null);
            this.selectedProject = this.toDisplayProject(flatProject);
        }
        if(this.user.templateFolder) {
            this.selectedTemplateFolder = this.designTemplateService.findTemplateFolderById(this.user.templateFolder?.templateFolderId);
        }
        this.selectLeftNavigationItem(this.user.projectAndDesignViewV2.primarySelection, this.user.projectAndDesignViewV2.secondarySelection);
        this.refreshDesigns();

        this.setTreeData();

        await this.handleLandingPageModals();

        this.isInternalLicenseSwitchAvailable = this.user.isInternalLicenseSwitchAvailable;
    }

    private setTreeData() {
        this.setAllDesignsTree();
        this.setAnchorDesign();
        this.setDraftsTree();
        this.setArchiveTree();
        this.setSectionCollapsedState();
        this.setMyProjectsTreeData();
        this.setMyCompanyProjectsTreeData();
        this.setTemplateTreeData();
        this.setSharedAndFavoritesTreeData();
    }
    private setSharedAndFavoritesTreeData() {
        this.setFavoritesTreeData();
        this.setSharedProjectsTreeData();
    }

    private setAnchorDesign() {
        this.anchorDesign = {
            id: LeftNavigationPrimaryButtons.AllDesigns,
            name: this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.AnchorDesign'),
            icon: 'all-designs',
            expanded: false,
        };
    }

    private setAllDesignsTree() {
        this.allDesigns = {
            id: LeftNavigationPrimaryButtons.AllDesigns,
            name: this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.AllDesigns'),
            icon: 'all-designs',
            expanded: false,
            onSelect: () => {
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.AllDesigns);
                this.selectedProject = this.toDisplayProject(this.documentService.draftsProject);
                this.selectedTemplateFolder = undefined;
            }
        };
    }

    private setDraftsTree() {
        this.drafts = {
            id: LeftNavigationPrimaryButtons.Drafts,
            name: this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.Drafts'),
            icon: 'drafts',
            expanded: false,
            contextMenu: this.getAllDesignsMenu(),
            onSelect: () => {
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Drafts);
                this.selectedProject = this.toDisplayProject(this.documentService.draftsProject);
                this.selectedTemplateFolder = undefined;
            },
            onDrop: (targetProjectId: string, transferDesign: IProjectAndDesignId) => {
                this.dropEvent(targetProjectId, transferDesign);
            },
            dropActive: true
        };
    }

    private setArchiveTree() {
        this.archiveTree = {
            id: LeftNavigationPrimaryButtons.Archive,
            name: this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.Projects.Archive'),
            icon: "archive",
            details: null,
            expanded: false,
            onSelect: () => {
                this.selectedProject = undefined;
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Archive);
                this.selectedTemplateFolder = undefined;
            }
        };
    }

    private async handleLandingPageModals() {
        if (this.license.displayTrialInfo) {
            this.license.displayTrialInfo = false;

            let requestFailed: boolean;
            let comparisonData: ComparisonTable;
            let holLicenseLanguageId = this.userSettingsService.getLanguage().id;
            const userRegion = this.userSettingsService.getRegionByCountryCode(this.user.authentication.country);
            // For trial license we use old popup where we show when license will expire.
            if (userRegion != null && !this.license.isTrial()) {
                const licenseComparisonPageLink = this.productInformation.regionLinksForUser(userRegion.id)?.LicenseComparisonPageLink;
                if (licenseComparisonPageLink != undefined && licenseComparisonPageLink.trim() != '') {
                    try {
                        /*
                        * BUDQBP-32961:
                        * The language of the license page is selected based on the region and language mapping we received from the HOL team.
                        * We also select a holLicense LanguageId for a specific region, which is used to translate our translation keys into the same language supported by HOL.
                        * If we did not get a mapping for a specific region, then the language list does not exist. In this case, we are trying to get data based on culture
                        */
                        let language: string;
                        if (userRegion.licensePageLanguages != null) {
                            const licensePageLanguage = userRegion.licensePageLanguages.find(x => x.LcId == holLicenseLanguageId) ?? userRegion.licensePageLanguages.find(x => x.DefaultForRegion);
                            language = licensePageLanguage.HolLanguageCode;
                            holLicenseLanguageId = licensePageLanguage.LcId;
                        }
                        else {
                            const cultureLanguage = this.userSettingsService.getCountryCodeCulture(this.user.authentication.country)
                            language = split(cultureLanguage, '-')[0];
                        }
                        const url = licenseComparisonPageLink.replace("{language}", language);
                        const request = new HttpRequest('GET', url);

                        comparisonData = (await this.apiService.request<ComparisonTable>(request, { supressErrorMessage: true, forceIncludeAuthenticationHeaders: true })).body;
                    }
                    catch (error) {
                        console.warn(error);
                        requestFailed = true;
                    }
                }
            }

            if (!requestFailed && comparisonData != null) {
                this.modal.openLicenseComparisonHol(comparisonData, holLicenseLanguageId).closed
                    .then(async () => {
                        await this.npsService.handleSurvey();
                        this.handleTourAndMarketingCampaigns();
                    })
            }
            else {
                this.modal.openTrialBanner().closed
                    .then(async () => {
                        await this.npsService.handleSurvey();
                        this.handleTourAndMarketingCampaigns();
                    });
            }
        }
        else {
            await this.npsService.handleSurvey();
            this.handleTourAndMarketingCampaigns();
        }

        // If user has floating license and all seats are taken, show him a message
        if (this.license.displayFloatingLimitReachedInfo) {
            this.license.displayFloatingLimitReachedInfo = false;
            this.modal.openConfirmChange({
                id: 'floating-license-limit-reached-popup',
                title: this.translate('Agito.Hilti.Profis3.License.Floating.LimitReached.Title'),
                message: this.translate('Agito.Hilti.Profis3.License.Floating.LimitReached.Text'),
                confirmButtonText: this.translate('Agito.Hilti.Profis3.ControlTooltip.Ok'),
                onConfirm: (modal) => {
                    modal.close();
                }
            });
        }

        if (environment.alertTestingEnvironment) {
            this.modal.openAlertTestingEnvironment();
        }
    }

    private handleTourAndMarketingCampaigns() {
        const virtualtourSeen = this.userSettingsService.settings.homePagevirtualtourSeen.value;
        if (this.tour.isNavigationTourInProgress ||
            !virtualtourSeen) {
            // Navigation tour opening after closing design view requires timeout
            setTimeout(() => {
                if (this.tour.startNewHomePageTour || !virtualtourSeen) {
                    this.startTour(!virtualtourSeen);
                }
                else {
                    this.tour.openNavigationTour(this.newDesignFromDesignTypeId);
                }
            }, 1000);

            this.userSettingsService.settings.homePagevirtualtourSeen.value = true;
            this.userSettingsService.save();
        }
        else {
            this.productInformation.openOnLaunchCampaigns();
        }
    }

    private newDesignFromDesignTypeId(designTypeId: number) {
        const quickStartApplication = sortBy(this.modulesService.getQuickStartApplications(), p => p.order).find(x => x.designType == designTypeId);
        if (quickStartApplication != null) {
            this.newDesignFromQuickStart(quickStartApplication);
        }
    }
    private newDesignFromQuickStart(quickStartApplication: IQuickStartApplication) {
        if (SafeFunctionInvokerHelper.safeInvoke(quickStartApplication.isDesignTypeDisabled, true)) {
            return;
        }

        // close any tour if it's open
        this.tour.onNewDesignFromQuickStart();

        return this.newDesign(quickStartApplication);
    }

    private onKeyDown(event: KeyboardEvent) {
        const target = event.target as HTMLElement;

        // keydown should already be processed by input
        if (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement) {
            return;
        }

        // open import design popup
        if (event.ctrlKey === true && (event.key === 'I' || event.key === 'i')) {
            event.stopPropagation();
            event.preventDefault();

            this.importDesign.selectImportFile();
        }

        // open new design popup
        if (event.ctrlKey === true && (event.key === 'M' || event.key === 'm')) {
            event.stopPropagation();
            event.preventDefault();

            this.newCustomDesignClick();
        }
    }

    private setSectionCollapsedState(): void {
        this.sectionsCollapsed.favorites.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.favorites.control);
        this.sectionsCollapsed.templates.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.templates.control);
        this.sectionsCollapsed.companyProjects.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.companyProjects.control);
        this.sectionsCollapsed.shared.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.shared.control);
        this.sectionsCollapsed.sharedByMe.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.sharedByMe.control);
        this.sectionsCollapsed.sharedWithMe.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.sharedWithMe.control);
        this.sectionsCollapsed.myProjects.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.myProjects.control);
        this.sectionsCollapsed.templatesSharedByMe.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.templatesSharedByMe.control);
        this.sectionsCollapsed.templatesSharedWithMe.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.templatesSharedWithMe.control);
    }

    public projectMenuToggled(opened: boolean) {
        const projectsScroll = document.querySelector<HTMLElement>('.tree-scroll');

        if (opened) {
            // Disable scroll
            const currentPosition = projectsScroll.scrollTop;
            projectsScroll.onwheel = (e) => { e.preventDefault(); };
            projectsScroll.onscroll = () => { projectsScroll.scrollTop = currentPosition; };
        }
        else {
            // Enable scroll
            projectsScroll.onscroll = () => undefined;
            projectsScroll.onwheel = () => undefined;
        }
    }

    public onSectionCollapsedChange(section: ISectionCollapsed, collapsed: boolean) {
        section.collapsed = collapsed;
        this.userSettingsService.setSectionCollapsed(section.control, collapsed);
    }

    private refreshDesigns() {
        this.displayDesignInput = {
            leftNavigationSelectedState: this.user.projectAndDesignViewV2,
            selectedProject: this.selectedProject,
            selectedTemplateFolder: this.selectedTemplateFolder
        };
    }

    private showVirtualTour() {
        this.mainHeaderComponentRef.nativeElement.showVirtualTour();
    }

    setSelectedTreeItem() {
        if (!this.selectedProject && !this.selectedTemplateFolder) {
            return;
        }

        switch (this.selectedTree) {
            case LeftNavigationPrimaryButtons.Favourites:
                this.selectedTreeItem = this.favouritesTree ? this.findTreeItem(this.selectedProject.id, this.favouritesTree) : null;
                break;

            case LeftNavigationPrimaryButtons.Templates:
                this.selectedTreeItem = this.templatesTree && this.selectedTemplateFolder ? this.findTreeItem(this.selectedTemplateFolder.templateFolderId, this.templatesTree) : null;
                break;

            case LeftNavigationPrimaryButtons.CompanyProjects:
                this.selectedTreeItem = this.companyProjectsTree ? this.findTreeItem(this.selectedProject.id, this.companyProjectsTree) : null;
                break;

            case LeftNavigationPrimaryButtons.Shared:
                this.selectedTreeItem = this.sharedTree ? this.findTreeItem(this.selectedProject.id, this.sharedTree) : null;
                break;

            case LeftNavigationPrimaryButtons.MyProjects:
                this.selectedTreeItem = this.myProjectsTree ? this.findTreeItem(this.selectedProject.id, this.myProjectsTree) : null;
                break;

            default:
                this.selectedTreeItem = null;
                break;
        }
    }

    public onSelectedProjectChangeFromDesignView(project: IDisplayProject) {
        if (this.selectedTree != LeftNavigationPrimaryButtons.Shared) {
            this.selectedProject = project;
        }

        if (project.isCompanyProject) {
            this.setMyCompanyProjectsTreeData();
        }
        if (!project.isCompanyProject) {
            this.setMyProjectsTreeData();
        }

        this.setSharedProjectsTreeData();
        this.refreshDesigns();
    }

    private findTreeItem(id: string, tree: ITreeViewItem<string>): ITreeViewItem<string> {
        if (tree?.id == id) {
            return tree;
        }

        const items: ITreeViewItem<string>[] = tree?.subItems ?? [];

        for (const e of items) {
            const matched = this.findTreeItem(id, e);
            if (matched) {
                return matched;
            }
        }

        return null;
    }
    public markAsFavoriteClicked() {
        this.favouritesClickCounter++;
    }
    public translate(key: string) {
        return this.localization.getString(key);
    }

    public searchInputBlur() {
        if (this.searchProjectText != null) {
            this.searchProjectText = this.searchProjectText.trim();
        }
    }

    @HostListener('window:beforeunload')
    public ngOnDestroy(): void {
        this.localizationChangeSubscription?.unsubscribe();
        this.designListInfoProvidedSubscription?.unsubscribe();
        this.operationCompleteSubscription?.unsubscribe();
        document.body.classList.remove(`project-and-design-view-body-${this.offlineService.isOffline ? 'offline' : 'online'}`);
        document.removeEventListener('keydown', this.onKeyDown, false);
        if (this.favouritesClickCounter > 0) {
            this.commonTracking.trackFavoriteClicks(this.favouritesClickCounter);
        }
    }

    private onSelectedProjectChange(newVal: IDisplayProject) {
        if (!this.offlineService.isOffline) {
            if (!newVal) {
                this.user.unsetProject();
                return;
            }
            this.user.changeDesign(this.documentService.findProjectById(newVal.id));
            if (newVal.id != this.documentService.draftsProject.id && this.designView != DesignView.projects) {
                this.designView = DesignView.projects;
            }
        }
    }

    private toDisplayProject(project: Project) {
        if (project == null) {
            return null;
        }

        const displayProject: IDisplayProject = {
            id: project.id,
            name: project.name,
            parentId: project.parentId,
            rawProject: project,
            subProjects: Object.values(project.subProjects).map((subProject) => this.toDisplayProject(subProject)),
            designs: Object.values(project.designs).map((design) => this.toDisplayDesign(design as IDesignListItem)).filter(design => design != undefined),
            created: project.createDate,
            owner: project.owner,
            isCompanyProject: project.isCompanyProject,
            expanded: project.expanded,
            readOnly: project.readOnly,
            isSharedByMe: project.isSharedByMe,
        };

        return displayProject;
    }

    private toDisplayDesign(design: IDesignListItem): IDisplayDesign {
        // Decking Modularization move to specific module
        // TODO - to add decking condition

        const designInfo = this.modulesService.getDesignListInfo().find(x => x.designTypeId == design?.metaData?.designType);
        if (designInfo?.toDisplayDesign) {
            return designInfo.toDisplayDesign(design, null) as IDisplayDesign;
        }

        return undefined;
    }

    /* #region Design Import */
    public onDesignImporting() {
        return this.selectedProject?.id ? this.documentService.findProjectById(this.selectedProject.id) : this.documentService.draftsProject;
    }

    public onDesignImported($event: { project: Project; design: Design; renameFile?: boolean; }) {
        this.user.changeDesign($event.project, $event.design);
        this.user.renameFile = $event.renameFile;
        this.navigate(urlPath.main + $event.design.id);
        return true;
    }
    /* #endregion */

    /* #region Topbar */
    public get showElearningLink() {
        const betLink = this.productInformation.regionLinksForUser(this.regionId)?.BetLearningLink;
        return betLink != null && betLink != '';
    }

    public startTour(isFirstTimeUser?: boolean) {

        if (!this.offlineService.isOffline) {
            // save the current state of the tree
            const favouritesTreeExpanded = this.favouritesTree?.expanded;
            const companyProjectsTreeExpanded = this.companyProjectsTree?.expanded;
            const sharedTreeExpanded = this.sharedTree?.expanded;

            // Prepare virtual tour data
            this.virtaulTourInProgress = true; // Use fake data for virtual tour
            if (this.favouritesTree) {
                this.favouritesTree.expanded = false;
            }
            if (this.companyProjectsTree) {
                this.companyProjectsTree.expanded = false;
            }
            if (this.sharedTree) {
                this.sharedTree.expanded = false;
            }


            const openOrCloseNewDropdownFn = (action: 'open' | 'close') => {
                if (action == 'open') {
                    this.createNewDropdownElementRef.nativeElement.setAttribute('data-preventClose', 'true');
                    this.createNewDropdownRef.open();
                }
                else {
                    this.createNewDropdownElementRef.nativeElement.removeAttribute('data-preventClose');
                    this.createNewDropdownRef.close();
                }

            }

            const virtualTourProjectsActionFn = (action: 'exit' | 'expandMyProject' | 'expandSharedProject') => {
                switch (action) {
                    case 'exit':
                        if (this.favouritesTree) {
                            this.favouritesTree.expanded = favouritesTreeExpanded;
                        }
                        if (this.companyProjectsTree) {
                            this.companyProjectsTree.expanded = companyProjectsTreeExpanded;
                        }
                        if (this.sharedTree) {
                            this.sharedTree.expanded = sharedTreeExpanded;
                        }
                        this.virtaulTourInProgress = false;
                        this.virtaulTourExpandShared = false;
                        break;
                    case 'expandMyProject':
                        this.virtaulTourExpandShared = false;
                        break;
                    case 'expandSharedProject':
                        this.virtaulTourExpandShared = true;
                        break;
                }
            }
            if (this.displayDesignInput.leftNavigationSelectedState.primarySelection == LeftNavigationPrimaryButtons.Archive) {
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.AllDesigns, null, true);
                this.designView = DesignView.allDesigns;
                this.displayDesignInput = {
                    leftNavigationSelectedState: { primarySelection: LeftNavigationPrimaryButtons.AllDesigns } as ILeftNavigationSelectedState
                };
                setTimeout(() => {
                    this.tour.homepageTour(isFirstTimeUser ?? false, openOrCloseNewDropdownFn, virtualTourProjectsActionFn, this.mainHeaderComponentRef.nativeElement.openVirtualTourMenu, this.mainHeaderComponentRef.nativeElement.closeVirtualTourMenu);
                }, 1000);
            }
            else {
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.MyProjects, null, true);
                this.designView = DesignView.projects;
                this.displayDesignInput = {
                    leftNavigationSelectedState: { primarySelection: LeftNavigationPrimaryButtons.MyProjects } as ILeftNavigationSelectedState,
                    selectedProject: undefined,
                    selectedTemplateFolder: undefined
                };
                this.tour.homepageTour(isFirstTimeUser ?? false, openOrCloseNewDropdownFn, virtualTourProjectsActionFn, this.mainHeaderComponentRef.nativeElement.openVirtualTourMenu, this.mainHeaderComponentRef.nativeElement.closeVirtualTourMenu);
            }
        }
        else if (this.offlineService.isOffline) {
            this.tour.openNavigationTour(this.newDesignFromDesignTypeId, this.showVirtualTour);
        }

    }

    public openApplicationSettings() {
        this.modal.openApplicationSettings();
    }

    public openELearning() {
        this.userSettingsService.settings.elearnDoceboSeen.value = true;
        this.userSettingsService.save();
        const betLearnLink = this.productInformation.regionLinksForUser(this.regionId).BetLearningLink;
        const region = this.getRegionById(this.regionId);
        this.offlineService.nativeExternalLinkOpen(betLearnLink);
        this.commonTracking.trackOnButtonClick(ButtonEventType.ELearnClicked, region.displayKey);
    }
    /* #endregion */

    /* #region Helper Method */

    get isStandardUser() {
        return this.user.hasFreeLicense || this.user.hasfloatingLimitReached;
    }

    private navigate(path: string) {
        this.routingService.navigateToUrl(path);
    }

    private getRegionById(regionId: number) {
        const regionCodeList = this.codeListCommon.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private clearPendingActions(): void {
        this.pendingAction = null;
        this.pendingActionDesign = null;
    }
    private clearPendingAction(pendingAction: PendingAction) {
        if (this.pendingAction == pendingAction) {
            this.pendingActionService.setPendingAction(null);
        }
    }

    public refreshAllProjectTrees() {
        this.documentService.sortAllProjectList();
        this.setMyProjectsTreeData();
        this.setMyCompanyProjectsTreeData();
        this.setFavoritesTreeData();
        this.setSharedProjectsTreeData();
    }

    public refreshTrees(projectType?: FolderType) {
        this.refreshAllProjectTrees();
        if (projectType === FolderType.Templates) {
            this.setTemplateTreeData();
        }
    }

    /* #endregion */

    /* #region New Button clicks */
    newCustomDesignClick(isCompanyProject?: boolean) {
        if (this.importing || this.pendingAction != null) {
            return;
        }

        const selectedDefaultRegion = this.regionId;
        const region = this.regions[selectedDefaultRegion];
        const designType = this.modulesService.designTypes.length > 0 ? this.modulesService.designTypes[0].id : undefined;
        const designInfo = this.modulesService.getDesignInfoForDesignType(designType, selectedDefaultRegion, null);

        this.modal.openAddEditDesignFromModule({
            selectedModuleDesignInfo: designInfo,
            addEditType: AddEditType.add,
            design: {
                designType: designType,
                region: region,
                projectId: this.selectedProject ? this.selectedProject.id : this.documentService.draftsProject.id,
                projectName: this.selectedProject ? this.selectedProject.name : this.documentService.draftsProject.name,
                isCompanyProject: isCompanyProject
            }
        });
    }

    newProjectClick(parentId: string = null) {
        if (this.importing || this.pendingAction != null) {
            return;
        }

        this.modal.openConfirmChangeInput({
            id: 'confirm-add-new-project',
            title: this.translate('Agito.Hilti.Profis3.HomePage.Project.AddProject'),
            message: this.translate('Agito.Hilti.Profis3.HomePage.Project.ProjectName'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Save'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Cancel'),
            details: parentId,
            onConfirm: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                this.createProjectOrCompanyProject(modal);
            },
            onCancel: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                modal.close();
            }
        });
    }

    newCompanyProjectClick(parentId: string = null) {
        this.modal.openConfirmChangeInput({
            id: 'confirm-add-new-company-project',
            title: this.translate('Agito.Hilti.Profis3.HomePage.CompanyProject.AddCompanyProject'),
            message: this.translate('Agito.Hilti.Profis3.HomePage.CompanyProject.CompanyProjectName'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Save'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Cancel'),
            details: parentId,
            onConfirm: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                this.createProjectOrCompanyProject(modal, true);
            },
            onCancel: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                modal.close();
            }
        });
    }

    newSubFolderClick(parentId: string = null, isCompanyProject?: boolean) {
        this.modal.openConfirmChangeInput({
            id: 'confirm-add-new-sub-folder',
            title: this.translate('Agito.Hilti.Profis3.HomePage.Project.AddSubProject'),
            message: this.translate('Agito.Hilti.Profis3.HomePage.Project.SubProject.Name'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Save'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Cancel'),
            details: parentId,
            onConfirm: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                this.createProjectOrCompanyProject(modal, isCompanyProject);
            },
            onCancel: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                modal.close();
            }
        });
    }

    private createProjectOrCompanyProject(modal: ModalInstance<IConfirmChangeInputProps>, isCompanyProject = false) {
        if (this.pendingAction) {
            return;
        }
        const newProjectName = modal.input.input.trim();
        const parentId = modal.input.details as string ?? null;

        if (newProjectName) {
            // TODO - can be implemented after projects get loaded in this page
            if (this.documentService.projectNameExists(newProjectName, parentId)) {
                this.onServiceErrorHandler.showExistingProjectNameModal();
                modal.close();
            }
            else {
                const project = new Project({
                    id: this.guid.new(),
                    name: newProjectName,
                    owner: true,
                    isCompanyProject: isCompanyProject,
                    expanded: true,
                    parentId: parentId,
                    changeDate: new Date(),
                    createDate: new Date()
                });

                this.pendingActionService.setPendingAction(PendingAction.newProject);
                this.documentService.saveProject(project, ModalDialogType.project)
                    .then(() => {
                        const parentProject = this.documentService.findProjectById(parentId);
                        if (parentProject != undefined) {
                            parentProject.expanded = true;
                            if (parentProject.expandedState == undefined) {
                                parentProject.expandedState = { isExpanded: true };
                            }
                            else {
                                parentProject.expandedState.isExpanded = true;
                            }
                            this.onSectionCollapsedChange(isCompanyProject ? this.sectionsCollapsed.companyProjects : this.sectionsCollapsed.myProjects, false);

                        }
                        this.selectedProject = this.toDisplayProject(project);
                        this.selectLeftNavigationItem(isCompanyProject ? LeftNavigationPrimaryButtons.CompanyProjects : LeftNavigationPrimaryButtons.MyProjects);

                        this.refreshAllProjectTrees();
                    })
                    .finally(() => {
                        isCompanyProject ? this.setMyCompanyProjectsTreeData() : this.setMyProjectsTreeData();
                        this.setSharedProjectsTreeData();
                        modal.close();
                        this.clearPendingAction(PendingAction.newProject);
                    });
            }
        }
        modal.close();
    }

    /* #endregion */

    /* #region Getters/Setters */

    public get selectedTree(): LeftNavigationPrimaryButtons {
        return this.user.projectAndDesignViewV2.primarySelection;
    }

    public get designView(): DesignView {
        return this._designView;
    }

    public set designView(value: DesignView) {
        if (value !== this._designView) {
            this._designView = value;
        }
    }

    public get selectedTemplateFolder(): DesignTemplateFolderDetail {
        return this.user.templateFolder;
    }

    public set selectedTemplateFolder(value: DesignTemplateFolderDetail) {
        if(value !== this._selectedTemplateFolder) {
            this._selectedTemplateFolder = value;
            this.user.setTemplateFolder(value);
        }
    }

    public get selectedProject(): IDisplayProject {
        return this._selectedProject;
    }

    public set selectedProject(value: IDisplayProject) {
        if (value !== this._selectedProject) {
            const idChanged = value?.id !== this._selectedProject?.id;
            this._selectedProject = value;
            if (idChanged) {
                this.onSelectedProjectChange(value);
            }
        }
    }

    public get importing() {
        return this._importing;
    }

    public set importing(value) {
        if (this._importing !== value) {
            this._importing = value;
            if (this._importing) {
                this.pendingActionService.setPendingAction(PendingAction.import);
            }
            else if (this.pendingAction == PendingAction.import) {
                this.clearPendingAction(PendingAction.import);
            }
        }
    }

    public get regionId() {
        return this.userSettingsService.settings.application.general.regionId.value;
    }

    public get globalRegion() {
        return this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value);
    }

    public get addNewCompanyProjectVisible() {
        return this.user.authentication != null && !this.user.hasTrialLicense
            && (this.user.authentication.customerOriginId && this.user.authentication.country);
    }

    public get companySectionVisible() {
        return this.user.authentication?.customerOriginId && this.user.authentication?.country;
    }

    public get companyProjectDisabled() {
        return this.user.hasFreeLicense || this.user.hasfloatingLimitReached;
    }

    public get getCompanyDisabledTooltip() {
        if (!this.companyProjectDisabled) {
            return null;
        }

        return this.translate('Agito.Hilti.Profis3.Convert.Disabled.ToolTip');
    }

    public get getSharedTreeDisabled() {
        return this.user.hasFreeLicense || this.user.hasfloatingLimitReached;
    }

    public get getSharedTreeDisabledTooltip() {
        if (!this.getSharedTreeDisabled) {
            return null;
        }
        return this.translate('Agito.Hilti.Profis3.Features.Application.FileSharing.Tooltip');
    }

    /* #endregion */

    /* #region My Projects */
    setMyProjectsTreeData(): void {
        this.myProjectsTree = {
            id: LeftNavigationPrimaryButtons.MyProjects,
            icon: 'my-projects',
            name: this.translate('Agito.Hilti.Profis3.HomePage.MyProjects'),
            expanded: !this.sectionsCollapsed.myProjects.collapsed,
            subItems: this.createTree(Object.fromEntries(Object.entries(this.documentService.projects).filter(([_, { owner }]) => owner)), 0, false),
            contextMenu: this.getMyProjectsMenu(),
            onSelect: () => {
                this.selectedProject = undefined;
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.MyProjects);
            },
            onExpanded: (node: ITreeViewItem<string>) => {
                this.onSectionCollapsedChange(this.sectionsCollapsed.myProjects, !node.expanded);
            }
        };

        this.setSelectedTreeItem();
    }

    public isShareProjectHidden() {
        return this.featuresVisibilityInfo.isHidden(Feature.Application_FileSharing, this.globalRegion.id);
    }

    public isShareProjectDisabled() {
        return this.featuresVisibilityInfo.isDisabled(Feature.Application_FileSharing, this.globalRegion.id);
    }

    public shareProjectTooltip() {
        return this.featuresVisibilityInfo.tooltip(Feature.Application_FileSharing);
    }

    getMyProjectsMenu = (id?: string) => {
        const addProject = (item: IMenuItem<string>) => {
            this.newProjectClick(item.details)
        };

        const downloadProjects = () => {
            const projectIds: string[] = [];
            this.myProjectsTree.subItems.forEach(i => {
                projectIds.push(i.id);
            });
            const projects = this.generateProjectDownloadBody(projectIds);
            this.downloadProjectsClick(projects, this.translate('Agito.Hilti.Profis3.HomePage.MyProjects'));
        };

        const menuAddProject: IMenuItem<string>[] = [
            { onClick: addProject, details: id, ...this.menuOptionsList[MenuOptions.NewProject] },
            { onClick: downloadProjects, details: id, ...this.menuOptionsList[MenuOptions.Download] },
        ];

        return menuAddProject;
    }

    getCompanyProjectsMenu = (id?: string) => {
        const addCompanyProject = (item: IMenuItem<string>) => {
            this.newCompanyProjectClick(item.details);
        };
        const menuCompanyProject: IMenuItem<string>[] = [];

        const downloadCompanyProjects = () => {
            const projectIds: string[] = [];
            this.companyProjectsTree.subItems.forEach(i => {
                projectIds.push(i.id);
            });

            const projects = this.generateProjectDownloadBody(projectIds);
            this.downloadProjectsClick(projects, this.translate('Agito.Hilti.Profis3.HomePage.CompanyProjects'));
        };

        if (this.addNewCompanyProjectVisible) {
            menuCompanyProject.push({
                onClick: addCompanyProject, disabled: this.companyProjectDisabled,
                toolTip: this.getCompanyDisabledTooltip, details: id, ...this.menuOptionsList[MenuOptions.NewCompanyProject]
            });
        }

        menuCompanyProject.push({ onClick: downloadCompanyProjects, details: id, ...this.menuOptionsList[MenuOptions.Download] });

        return menuCompanyProject;
    }

    public get designFavoriteDisabled() {
        return this.user.hasFreeLicense || this.user.hasfloatingLimitReached;
    }

    public get getDesignFavoriteDisabledTooltip() {
        if (!this.designFavoriteDisabled) {
            return null;
        }
        return this.translate('Agito.Hilti.Profis3.Features.Menu.Favorites.Tooltip');
    }

    getMyProjectsChildMenu = (node?: ITreeViewItem<string>, level?: number, project?: Project, isCompanyProject?: boolean) => {
        const id = node.id;
        if (!project) {
            return [];
        }
        const menu: IMenuItem<string>[] = [];

        if (level < MAX_TREE_DEPTH) {
            const addSubFolder = (item: IMenuItem<string>) => {
                this.newSubFolderClick(item.details, isCompanyProject)
            };
            menu.push({ onClick: addSubFolder, details: id, disabled: this.companyProjectDisabled && isCompanyProject, toolTip: isCompanyProject ? this.getCompanyDisabledTooltip : null, ...this.menuOptionsList[MenuOptions.AddSubFolder] });
        }
        const newCustomDesignMenu = () => {
            this.newCustomDesignClick(isCompanyProject);
        };

        const convertToCompanyProject = (item: IMenuItem<string>) => {
            this.convertToCompanyProjectClick(item.details);
        };

        const renameProject = (item: IMenuItem<string>) => {
            this.renameClick(item.details, level);
        };

        const archiveProject = (item: IMenuItem<string>) => {
            this.archiveProjectClick(item.details, isCompanyProject);
        };

        const downloadProject = (item: IMenuItem<string>) => {
            const projects = this.generateProjectDownloadBody([item.details]);
            this.downloadProjectsClick(projects, null);
        };

        const addToFavorite = () => {
            this.addToFavoriteClick(project);
        };

        const removeFromFavorite = () => {
            this.removeFromFavoriteClick(project);
        };

        const shareProject = (item: IMenuItem<string>) => {
            this.shareProjectsClick(item.details);
        };

        menu.push({ onClick: newCustomDesignMenu, details: id, ...this.menuOptionsList[MenuOptions.CreateDesign] });

        if (project.owner && this.addNewCompanyProjectVisible && !isCompanyProject) {
            menu.push({ onClick: convertToCompanyProject, details: id, disabled: this.companyProjectDisabled, toolTip: this.getCompanyDisabledTooltip, ...this.menuOptionsList[MenuOptions.ConvertToCompanyProject] });
        }
        menu.push({ ...this.menuOptionsList[MenuOptions.MENU_SEPERATOR] });
        if ((project.owner || project.isCompanyProject) && !this.isShareProjectHidden()) {
            menu.push({ onClick: shareProject, disabled: this.isShareProjectDisabled(), details: id, toolTip: this.shareProjectTooltip(), ...this.menuOptionsList[MenuOptions.Sharing] });
        }

        menu.push({ onClick: addToFavorite, disabled: this.designFavoriteDisabled || !Object.values(project.designs).some(x => !x.isFavorite), details: id, toolTip: this.getDesignFavoriteDisabledTooltip, ...this.menuOptionsList[MenuOptions.AddToFavorite] });
        menu.push({ onClick: removeFromFavorite, disabled: this.designFavoriteDisabled || !Object.values(project.designs).some(x => x.isFavorite), details: id, toolTip: this.getDesignFavoriteDisabledTooltip, ...this.menuOptionsList[MenuOptions.RemoveFromFavorite] });
        menu.push({ onClick: renameProject, details: id, ...this.menuOptionsList[MenuOptions.Rename] });
        menu.push({ ...this.menuOptionsList[MenuOptions.MENU_SEPERATOR] });
        menu.push({ onClick: downloadProject, disabled: project.designs == null || Object.keys(project.designs).length == 0, details: id, ...this.menuOptionsList[MenuOptions.Download] });
        menu.push({ onClick: archiveProject, disabled: !project.owner, details: id, ...this.menuOptionsList[MenuOptions.Archive] });

        return menu;
    }

    createTree = (project: Record<string, Project>, level: number, isCompanyProject: boolean): ITreeViewItem<string>[] => {
        const result = [];

        for (const item of Object.values(project)) {
            if (item.isCompanyProject == isCompanyProject && item.projectType == ProjectType.common) {
                const { id, parentId, name } = item;
                const node: ITreeViewItem<string> = {
                    id: id,
                    parentId: parentId,
                    icon: 'folder',
                    details: item.id,
                    name: name,
                    expanded: item.expandedState?.isExpanded,
                    subItems: [],
                    onSelect: () => {
                        this.selectedTemplateFolder = undefined;
                        this.selectedProject = this.toDisplayProject(this.documentService.projectsFlat[id]);
                        this.selectLeftNavigationItem(isCompanyProject ? LeftNavigationPrimaryButtons.CompanyProjects : LeftNavigationPrimaryButtons.MyProjects);
                    },
                    onExpanded: (node: ITreeViewItem<string>) => {
                        const project = this.documentService.findProject(id, this.documentService.projects);
                        project.expandedState.isExpanded = node.expanded;
                        this.documentService.toggleExpandProjectV2(id, { isExpanded: node.expanded } as ProjectExpandedState)
                    },
                    onDrop: (targetProjectId: string, transferDesign: IProjectAndDesignId) => {
                        this.dropEvent(targetProjectId, transferDesign);
                    },
                    dropActive: true
                };
                node.level = level;
                node.contextMenu = this.getMyProjectsChildMenu(node, level, item, isCompanyProject);
                result.push(node);
                if (Object.keys(item.subProjects).length > 0) {
                    node.subItems = this.createTree(item.subProjects, level + 1, isCompanyProject);
                }
            }
        }
        return result;
    }

    /* #endregion */

    createSharedTree = (projects: Record<string, Project>, level: number, isSharedByMe?: boolean): ITreeViewItem<string>[] => {
        const result = [];
        for (const item of Object.values(projects)) {

            if (isSharedByMe && !item.isSharedByMe) {
                continue;
            }

            const { id, parentId, name } = item;

            const node: ITreeViewItem<string> = {
                id: id,
                parentId: parentId,
                icon: 'folder',
                details: item.id,
                name: name,
                expanded: item.expandedState?.isExpandedInShared,
                subItems: [],
                readOnly: item.readOnly,
                onSelect: () => {
                    this.selectedTemplateFolder = undefined;
                    this.selectedProject = this.toDisplayProject(this.documentService.projectsFlat[id]);

                    // don't load the design view if project is shared with me and read only
                    const refreshDesigns = !this.selectedProject?.owner ? !this.selectedProject?.readOnly : true;
                    this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Shared,
                        item.owner ? LeftNavigationSecondaryButtons.SharedByMe : LeftNavigationSecondaryButtons.SharedWithMe,
                        refreshDesigns);
                },
                onExpanded: (node: ITreeViewItem<string>) => {
                    const project = this.documentService.findProject(id, this.documentService.projects);
                    project.expandedState.isExpandedInShared = node.expanded;
                    this.documentService.toggleExpandProjectV2(id, { isExpandedInShared: node.expanded } as ProjectExpandedState)
                },
                onDrop: (targetProjectId: string, transferDesign: IProjectAndDesignId) => {
                    this.dropEvent(targetProjectId, transferDesign);
                },
                dropActive: true
            };
            node.contextMenu = item.readOnly ? null : this.getSharedMenu(node.id, item, null);
            node.level = level;
            result.push(node);
            if (Object.keys(item.subProjects).length > 0) {
                node.subItems = this.createSharedTree(item.subProjects, level + 1, isSharedByMe);
            }
        }
        return result;
    }

    /* #endregion */

    /* #region Shared Projects */
    setSharedProjectsTreeData(): void {
        const subItems: ITreeViewItem<string>[] = [];

        if (!this.isStandardUser) {
            subItems.push({
                id: LeftNavigationSecondaryButtons.SharedByMe,
                icon: 'shared-by-me',
                name: this.translate('Agito.Hilti.Profis3.HomePage.Shared.SharedByMe'),
                expanded: !this.sectionsCollapsed.sharedByMe.collapsed,
                subItems: this.createSharedTree(Object.fromEntries(Object.entries(this.documentService.projects).filter(([_, { isSharedByMe }]) => isSharedByMe)), 1, true),
                contextMenu: this.getSharedMenu(LeftNavigationSecondaryButtons.SharedByMe, null, this.translate('Agito.Hilti.Profis3.HomePage.Shared.SharedByMe')),
                onSelect: () => {
                    this.selectedTemplateFolder = undefined;
                    this.selectedProject = undefined;
                    this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Shared, LeftNavigationSecondaryButtons.SharedByMe);
                },
                onExpanded: (node: ITreeViewItem<string>) => {
                    this.onSectionCollapsedChange(this.sectionsCollapsed.sharedByMe, !node.expanded);
                },
            });
        }

        subItems.push({
            id: LeftNavigationSecondaryButtons.SharedWithMe,
            icon: 'shared-with-me',
            name: this.translate('Agito.Hilti.Profis3.HomePage.Shared.SharedWithMe'),
            expanded: !this.sectionsCollapsed.sharedWithMe.collapsed,
            subItems: this.createSharedTree(Object.fromEntries(Object.entries(this.documentService.projects).filter(([_, { owner }]) => !owner)), 1),
            contextMenu: this.getSharedMenu(LeftNavigationSecondaryButtons.SharedWithMe, null, this.translate('Agito.Hilti.Profis3.HomePage.Shared.SharedWithMe')),
            onSelect: () => {
                this.selectedTemplateFolder = undefined;
                this.selectedProject = undefined;
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Shared, LeftNavigationSecondaryButtons.SharedWithMe);
            },
            onExpanded: (node: ITreeViewItem<string>) => {
                this.onSectionCollapsedChange(this.sectionsCollapsed.sharedWithMe, !node.expanded);
            }
        });

        this.sharedTree = {
            id: LeftNavigationPrimaryButtons.Shared,
            name: this.translate('Agito.Hilti.Profis3.HomePage.Shared'),
            icon: 'shared',
            expanded: !this.sectionsCollapsed.shared.collapsed,
            subItems: subItems,
            contextMenu: this.getSharedMenu(LeftNavigationPrimaryButtons.Shared, null, this.translate('Agito.Hilti.Profis3.HomePage.Shared')),
            onSelect: () => {
                this.selectedTemplateFolder = undefined;
                this.selectedProject = undefined;
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Shared);
            },
            onExpanded: (node: ITreeViewItem<string>) => {
                this.onSectionCollapsedChange(this.sectionsCollapsed.shared, !node.expanded);
            }
        };
        this.setSelectedTreeItem();
    }

    getSharedMenu = (id?: string, project?: Project, title?: string) => {

        const downloadSharedFolder = () => {
            const projects = this.generateSharedDownloadBody(id);
            this.downloadProjectsClick(projects, title);
        };

        const renameProject = (item: IMenuItem<string>) => {
            const level = this.documentService.projectsFlat[item.details].parentId ? 1 : 0;
            this.renameClick(item.details, level);
        };

        const menu: IMenuItem<string>[] = [
            { onClick: downloadSharedFolder, details: id, ...this.menuOptionsList[MenuOptions.Download] }
        ];

        const addToFavorite = () => {
            this.addToFavoriteClick(project);
        };

        const removeFromFavorite = () => {
            this.removeFromFavoriteClick(project);
        };

        if (project && !project?.owner) {
            menu.push({ onClick: addToFavorite, disabled: this.designFavoriteDisabled || !Object.values(project.designs).some(x => !x.isFavorite), details: id, toolTip: this.getDesignFavoriteDisabledTooltip, ...this.menuOptionsList[MenuOptions.AddToFavorite] });
            menu.push({ onClick: removeFromFavorite, disabled: this.designFavoriteDisabled || !Object.values(project.designs).some(x => x.isFavorite), details: id, toolTip: this.getDesignFavoriteDisabledTooltip, ...this.menuOptionsList[MenuOptions.RemoveFromFavorite] });
        }

        if (project && project?.owner) {
            menu.push({ onClick: renameProject, details: id, ...this.menuOptionsList[MenuOptions.Rename] });
        }
        return menu;
    }

    private generateSharedDownloadBody(id: string): ProjectGetProjectContentResponseModel {
        const result = new ProjectGetProjectContentResponseModel();
        const sharedByMe = new ProjectDownloadPayLoadModel();
        const sharedWithMe = new ProjectDownloadPayLoadModel();
        const sharedByMeId = this.guid.new();
        const sharedWithMeId = this.guid.new();

        if (id == LeftNavigationPrimaryButtons.Shared
            || id == LeftNavigationSecondaryButtons.SharedByMe
            || id == LeftNavigationSecondaryButtons.SharedWithMe) {
            sharedByMe.id = sharedByMeId;
            sharedByMe.projectid = sharedByMeId;
            sharedByMe.SubProjectList = [];
            sharedByMe.name = this.translate('Agito.Hilti.Profis3.HomePage.Shared.SharedByMe');
            filter(this.documentService.projects, (project) => project.isSharedByMe).forEach((project) => {
                const matchedResult = this.getConvertedProject(project, null, true);
                matchedResult.parentprojectid = sharedByMeId;
                sharedByMe.SubProjectList.push(matchedResult);
            });

            sharedWithMe.id = sharedWithMeId;
            sharedWithMe.projectid = sharedWithMeId;
            sharedWithMe.name = this.translate('Agito.Hilti.Profis3.HomePage.Shared.SharedWithMe');
            sharedWithMe.SubProjectList = [];
            filter(this.documentService.projects, (project) => !project.owner).forEach((project) => {
                const matchedResult = this.getConvertedProject(project);
                matchedResult.parentprojectid = sharedWithMeId;
                sharedWithMe.SubProjectList.push(matchedResult);
            });
        }

        switch (id) {
            case LeftNavigationPrimaryButtons.Shared:
                result.projects = [sharedByMe, sharedWithMe];
                break;
            case LeftNavigationSecondaryButtons.SharedWithMe:
                result.projects = [sharedWithMe];
                break;
            case LeftNavigationSecondaryButtons.SharedByMe:
                result.projects = [sharedByMe];
                break;
            default:
                {
                    result.projects = [];
                    const isSharedByMe = this.user.projectAndDesignViewV2.secondarySelection === LeftNavigationSecondaryButtons.SharedByMe;
                    const matched = cloneDeep(this.documentService.findProject(id, this.documentService.projects));
                    const matchedResult = this.getConvertedProject(matched, null, isSharedByMe);
                    result.projects.push(matchedResult);
                    break;
                }
        }
        return result;
    }
    /* #endregion */

    /* #region Company Projects */

    setMyCompanyProjectsTreeData(): void {
        this.companyProjectsTree = {
            id: LeftNavigationPrimaryButtons.CompanyProjects,
            icon: 'company-projects',
            name: this.translate('Agito.Hilti.Profis3.HomePage.CompanyProjects'),
            expanded: !this.sectionsCollapsed.companyProjects.collapsed,
            subItems: this.createTree(this.documentService.projects, 0, true),
            contextMenu: this.getCompanyProjectsMenu(),
            onSelect: () => {
                this.selectedProject = undefined;
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.CompanyProjects);
            },
            onExpanded: (node: ITreeViewItem<string>) => {
                this.onSectionCollapsedChange(this.sectionsCollapsed.companyProjects, !node.expanded);
            }
        };
        this.setSelectedTreeItem();
    }
    /* #endregion */

    /* #region Template */
    public get isTemplatesDisabled() {
        return this.user.hasFreeLicense || this.user.hasfloatingLimitReached;
    }

    public get getTemplateDisabledTooltip() {
        if (!this.isTemplatesDisabled) {
            return null;
        }

        return this.translate('Agito.Hilti.Profis3.Features.Menu.Templates.Tooltip');
    }

    setTemplateTreeData(): void {
        const subItems: ITreeViewItem<string>[] = [];
        const allTemplates = this.templateService.buildTemplateTree(this.templateService.templatesFoldersFlat);
        const sortedTemplates = this.templateService.getSortedTemplateFolders(allTemplates);
        subItems.push({
            id: LeftNavigationSecondaryButtons.TemplatesSharedByMe,
            icon: 'shared-by-me',
            name: this.translate('Agito.Hilti.Profis3.HomePage.Shared.TemplatesSharedByMe'),
            expanded: !this.sectionsCollapsed.templatesSharedByMe.collapsed,
            subItems: this.createSharedTemplateTree(Object.fromEntries(Object.entries(sortedTemplates).filter(([_, { isSharedByMe }]) => isSharedByMe)), 0, true),
            //contextMenu: this.getSharedMenu(LeftNavigationSecondaryButtons.SharedByMe, null, this.translate('Agito.Hilti.Profis3.HomePage.Shared.SharedByMe')),
            onSelect: () => {
                this.selectedTemplateFolder = null;
                this.selectedProject = undefined;
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Templates, LeftNavigationSecondaryButtons.TemplatesSharedByMe);
            },
            onExpanded: (node: ITreeViewItem<string>) => {
                this.onSectionCollapsedChange(this.sectionsCollapsed.templatesSharedByMe, !node.expanded);
            },
        });

        subItems.push({
            id: LeftNavigationSecondaryButtons.TemplatesSharedWithMe,
            icon: 'shared-with-me',
            name: this.translate('Agito.Hilti.Profis3.HomePage.Shared.TemplatesSharedWithMe'),
            expanded: !this.sectionsCollapsed.templatesSharedWithMe.collapsed,
            subItems: this.createSharedTemplateTree(Object.fromEntries(Object.entries(sortedTemplates).filter(([_, { owner }]) => !owner)), 0),
            onSelect: () => {
                this.selectedTemplateFolder = null;
                this.selectedProject = undefined;
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Templates, LeftNavigationSecondaryButtons.TemplatesSharedWithMe);
            },
            onExpanded: (node: ITreeViewItem<string>) => {
                this.onSectionCollapsedChange(this.sectionsCollapsed.templatesSharedWithMe, !node.expanded);
            }
        });

        this.templatesTree = {
            id: LeftNavigationPrimaryButtons.Templates,
            name: this.translate('Agito.Hilti.Profis3.HomePage.Templates'),
            icon: 'templates-hp',
            expanded: !this.sectionsCollapsed.templates.collapsed && !this.isTemplatesDisabled,
            subItems: this.offlineService?.isOffline ? [] : [...subItems, ...this.createTemplateTree(Object.fromEntries(Object.entries(sortedTemplates).filter(([_, { owner }]) => owner)), 0)],
            contextMenu: this.getTemplatesMenu(),
            disabled: this.isTemplatesDisabled,
            tooltip: this.isTemplatesDisabled ?
                { title: null, content: this.getTemplateDisabledTooltip } as Tooltip
                : null,
            onSelect: () => {
                this.selectedTemplateFolder = undefined;
                this.selectedProject = undefined;
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Templates, null)
            },
            onExpanded: (node: ITreeViewItem<string>) => {
                this.onSectionCollapsedChange(this.sectionsCollapsed.templates, !node.expanded);
            }
        };

        this.setSelectedTreeItem();
    }

    createTemplateTree(tree: { [key: string]: DesignTemplateFolderDetail }, level: number): ITreeViewItem<string>[] {
        const result = [];
        for (const item of Object.values(tree)) {

            const { templateFolderId, parentTemplateFolderId, name } = item;
            const node: ITreeViewItem<string> = {
                id: templateFolderId,
                parentId: parentTemplateFolderId,
                icon: 'folder',
                details: item.templateFolderId,
                name: name,
                expanded: item.expandedState?.isExpanded ?? false,
                readOnly: item.readOnly ?? false,
                subItems: [],
                onSelect: () => {
                    this.selectedTemplateFolder = item;
                    this.selectedProject = undefined;
                    this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Templates);
                },
                onExpanded: (node: ITreeViewItem<string>) => {
                    const folder = this.templateService.findTemplateFolderById(templateFolderId);
                    folder.expandedState.isExpanded = node.expanded;
                    this.templateService.toggleExpandTemplateFolder(templateFolderId, { isExpanded: node.expanded } as ProjectExpandedState)
                }
            };
            node.level = level;
            node.contextMenu = this.getTemplatesFolderChildMenu(node, level, item);
            result.push(node);
            if (item.subFolders && Object.keys(item.subFolders).length > 0) {
                node.subItems = this.createTemplateTree(item.subFolders, level + 1);
            }

        }

        return result;
    }

    createSharedTemplateTree(tree: { [key: string]: DesignTemplateFolderDetail }, level: number, isSharedByMe?: boolean): ITreeViewItem<string>[] {
        const result = [];
        for (const item of Object.values(tree)) {

            if (isSharedByMe && !item.isSharedByMe) {
                continue;
            }

            const { templateFolderId, parentTemplateFolderId, name } = item;
            const node: ITreeViewItem<string> = {
                id: 'shared-' + templateFolderId,
                parentId: parentTemplateFolderId,
                icon: 'folder',
                details: item.templateFolderId,
                name: name,
                expanded: item.expandedState?.isExpandedInShared ?? false,
                readOnly: item.readOnly ?? false,
                subItems: [],
                onSelect: () => {
                    this.selectedTemplateFolder = item;
                    this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Templates,
                        item.owner ? LeftNavigationSecondaryButtons.TemplatesSharedByMe : LeftNavigationSecondaryButtons.TemplatesSharedWithMe);
                },
                onExpanded: (node: ITreeViewItem<string>) => {
                    const folder = this.templateService.findTemplateFolderById(templateFolderId);
                    folder.expandedState.isExpandedInShared = node.expanded;
                    this.templateService.toggleExpandTemplateFolder(templateFolderId, { isExpandedInShared: node.expanded } as ProjectExpandedState)
                }
            };
            node.level = level;
            node.contextMenu = this.getSharedTemplatesFolderChildMenu(node, level, item);
            result.push(node);
            if (item.subFolders && Object.keys(item.subFolders).length > 0) {
                node.subItems = this.createSharedTemplateTree(item.subFolders, level + 1, isSharedByMe);
            }
        }

        return result;
    }

    getTemplatesMenu = (id?: string) => {
        const addTemplate = () => {
            this.newTemplateClick()
        };

        const addNewFolder = (item: IMenuItem<string>) => {
            this.addNewFolderClick(item.details);
        };

        const menuAddProject: IMenuItem<string>[] = [
            { onClick: addTemplate, details: id, ...this.menuOptionsList[MenuOptions.NewTemplate] },
            { onClick: addNewFolder, details: id, ...this.menuOptionsList[MenuOptions.AddFolder] },
        ];

        return menuAddProject;
    }

    getSharedTemplatesFolderChildMenu = (node?: ITreeViewItem<string>, level?: number, folder?: DesignTemplateFolderDetail) => {
        const id = folder?.templateFolderId;
        if (!folder) {
            return [];
        }
        const menu: IMenuItem<string>[] = [];

        const renameTemplateFolder = (item: IMenuItem<string>) => {
            this.renameTemplateFolderClick(item.details, level);
        };

        const downloadTemplateFolder = (item: IMenuItem<string>) => {
            this.downloadTemplateFolderClick([item.details]);
        };

        if (folder.owner) {
            menu.push({ onClick: renameTemplateFolder, details: id, ...this.menuOptionsList[MenuOptions.Rename] });
        }
        menu.push({ onClick: downloadTemplateFolder, disabled: false, details: id, ...this.menuOptionsList[MenuOptions.Download] });

        return menu;
    }

    getTemplatesFolderChildMenu = (node?: ITreeViewItem<string>, level?: number, folder?: DesignTemplateFolderDetail) => {
        const id = node.id;
        if (!folder) {
            return [];
        }
        const menu: IMenuItem<string>[] = [];


        const addTemplate = () => {
            this.newTemplateClick()
        };

        const renameTemplateFolder = (item: IMenuItem<string>) => {
            this.renameTemplateFolderClick(item.details, level);
        };

        const archiveTemplateFolder = (item: IMenuItem<string>) => {
            this.archiveTemplateFolderClick(item.details);
        };

        const downloadTemplateFolder = (item: IMenuItem<string>) => {
            this.downloadTemplateFolderClick([item.details]);
        };

        const shareTemplateFolder = (item: IMenuItem<string>) => {
            this.shareTemplateFolderClick(item.details);
        };


        menu.push({ onClick: addTemplate, details: id, ...this.menuOptionsList[MenuOptions.NewTemplate] });

        if (level < MAX_TREE_DEPTH) {
            const addSubFolder = (item: IMenuItem<string>) => {
                this.newTemplateSubFolderClick(item.details)
            };
            menu.push({ onClick: addSubFolder, details: id, ...this.menuOptionsList[MenuOptions.AddSubFolder] });
        }

        menu.push({ ...this.menuOptionsList[MenuOptions.MENU_SEPERATOR] });
        if ((folder.owner)) {
            menu.push({ onClick: shareTemplateFolder, disabled: this.isShareProjectDisabled(), details: id, toolTip: this.shareProjectTooltip(), ...this.menuOptionsList[MenuOptions.Sharing] });
        }

        menu.push({ onClick: renameTemplateFolder, details: id, ...this.menuOptionsList[MenuOptions.Rename] });
        menu.push({ ...this.menuOptionsList[MenuOptions.MENU_SEPERATOR] });
        menu.push({ onClick: downloadTemplateFolder, disabled: false, details: id, ...this.menuOptionsList[MenuOptions.Download] });
        menu.push({ ...this.menuOptionsList[MenuOptions.MENU_SEPERATOR] });
        menu.push({ onClick: archiveTemplateFolder, disabled: !folder.owner, details: id, ...this.menuOptionsList[MenuOptions.Archive] });

        return menu;
    }

    newTemplateClick() {

        if (this.importing || this.pendingAction != null) {
            return;
        }

        const selectedDefaultRegion = this.regionId;
        const region = this.regions[selectedDefaultRegion];
        const designType = this.modulesService.designTypes.length > 0 ? this.modulesService.designTypes[0].id : undefined;
        const designInfo = this.modulesService.getDesignInfoForDesignType(designType, selectedDefaultRegion, null);

        this.modal.openAddEditDesignFromModule({
            selectedModuleDesignInfo: designInfo,
            addEditType: AddEditType.add,
            design: {
                designType: designType,
                region: region,
                projectId: this.documentService.draftsProject.id,
                projectName: this.documentService.draftsProject.name,
                isCreateTemplate: true
            }
        });

    }
    addNewFolderClick(parentId: string) {
        this.modal.openConfirmChangeInput({
            id: 'confirm-add-new-template-folder',
            title: this.translate('Agito.Hilti.Profis3.HomePage.Template.AddNewFolder'),
            message: this.translate('Agito.Hilti.Profis3.HomePage.Template.FolderName'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Save'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Cancel'),
            details: parentId,
            onConfirm: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                this.createTemplateFolder(modal);
            },
            onCancel: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                modal.close();
            }
        });
    }

    newTemplateSubFolderClick(parentId: string) {
        this.modal.openConfirmChangeInput({
            id: 'confirm-add-new-template-sub-folder',
            title: this.translate('Agito.Hilti.Profis3.HomePage.Template.AddNewSubFolder'),
            message: this.translate('Agito.Hilti.Profis3.HomePage.Folder.Name'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Save'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Cancel'),
            details: parentId,
            onConfirm: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                this.createTemplateFolder(modal);
            },
            onCancel: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                modal.close();
            }
        });
    }

    private createTemplateFolder(modal: ModalInstance<IConfirmChangeInputProps>) {
        if (this.pendingAction) {
            return;
        }
        const newTemplateName = modal.input.input;
        const parentId = modal.input.details as string ?? null;

        if (newTemplateName) {
            if (this.templateService.checkExistingTemplateFolder(newTemplateName.trim(), parentId)) {
                this.onServiceErrorHandler.showExistingTemplateFolderNameModal();
                modal.close();
            } else {
                const template: TemplateFolderRequestModel = {
                    name: newTemplateName.trim(),
                    parentTemplateId: parentId,
                };

                this.pendingActionService.setPendingAction(PendingAction.newTemplateFolder);
                this.templateService.createTemplateFolder(template).then(e => {
                    this.setTemplateTreeData();
                    modal.close();
                }).catch(err => {
                    if (err instanceof Error) {
                        console.error(err);
                    }
                    modal.close();
                }).finally(() => {
                    this.clearPendingAction(PendingAction.newTemplateFolder);
                });

            }
        }
    }

    shareTemplateFolderClick(id: string) {
        if (this.isShareProjectHidden() || this.isShareProjectDisabled()) {
            return;
        }
        const selectedTemplateFolder = this.templateService.findTemplateFolderById(id);
        selectedTemplateFolder.loadUsers(this.templateService)
            .then(() => this.modal.openShareTemplateFolder({
                selectedTemplateFolder,
                onRemoveTemplateFolder: () => {
                    this.templateService.deleteTemplateFolderLocal(this.selectedTemplateFolder.templateFolderId);
                    this.setTemplateTreeData();
                },
                onConfirm: (res: boolean) => {
                    if (res) {
                        this.setTemplateTreeData();
                    }
                },

            }));
    }

    renameTemplateFolderClick(id: string, level: number) {
        const item = this.templateService.findTemplateFolderById(id);
        const itemName = item.name.trim();
        this.modal.openConfirmChangeInput({
            id: 'confirm-rename-sub-folder',
            title: level == 0 ? this.translate('Agito.Hilti.Profis3.HomePage.TemplateFolder.Rename') : this.translate('Agito.Hilti.Profis3.HomePage.TemplateSubFolder.Rename'),
            message: level == 0 ? this.translate('Agito.Hilti.Profis3.HomePage.Template.FolderName') : this.translate('Agito.Hilti.Profis3.HomePage.Template.SubFolderName'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Save'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Cancel'),
            details: id,
            input: item.name,
            onConfirm: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                const newRenameText = modal.input.input.trim();
                if (!newRenameText) {
                    return;
                }

                // cancel rename of the item
                if (newRenameText == itemName) {
                    modal.close();
                } else if (this.templateService.checkExistingTemplateFolder(
                    newRenameText,
                    item.parentTemplateFolderId)) {
                    modal.close();
                    this.onServiceErrorHandler.showExistingTemplateFolderNameModal();
                } else {
                    const templateFolder = this.templateService.findTemplateFolderById(id);
                    templateFolder.name = newRenameText;

                    this.pendingActionService.setPendingAction(PendingAction.renameTemplateFolder)

                    this.templateService.renameTemplateFolder(templateFolder).then(() => {
                        // Update Project Object
                        item.name = newRenameText;
                        item.dateChanged = new Date();
                        item.dateCreated = new Date();
                        item.expandedState = { isExpanded: true } as ProjectExpandedState;

                    }).catch((err) => {
                        if (err instanceof Error) {
                            console.error(err);
                        }
                        item.name = itemName;
                        templateFolder.name = itemName;
                    }).finally(() => {
                        this.clearPendingAction(PendingAction.renameTemplateFolder);
                        modal.close();
                        this.setTemplateTreeData();
                    });
                }
            },
            onCancel: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                modal.close();
            }
        });
    }

    public archiveTemplateFolderClick(deletedTemplateFolder: string) {
        if (this.pendingAction) {
            return;
        }

        this.pendingActionService.setPendingAction(PendingAction.archiveTemplateFolder)
        this.templateService.archiveExistingFolderTemplate(deletedTemplateFolder)
            .finally(() => {
                this.clearPendingAction(PendingAction.archiveTemplateFolder);
            })
            .then(() => {
                this.user.setTemplateFolder(null);
                this.setTemplateTreeData();
                this.templatesTree.onSelect(this.templatesTree);
            })
            .catch((e) => {
                if (e instanceof Error) {
                    console.error(e);
                }

                if (e instanceof CantArchiveProjectsBecauseDocumentInUse) {
                    const cantEx: CantArchiveProjectsBecauseDocumentInUse = e;
                    const descriptionForDocument = this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.DescriptionForSpecificDocument');
                    let description = this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.Description');
                    description += '\n';
                    let docCount = 0;
                    for (const doc of cantEx.documentsInQuestion) {
                        docCount++;
                        if (docCount > 5) {
                            description += '\n\t\t' + this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.DescriptionForTooMuchDocuments');
                            break;
                        }
                        description += '\n\t\t' + descriptionForDocument.replace('{document}', doc.document).replace('{user}', doc.username);
                    }
                    description += '\n';

                    this.modal.openAlertWarning(
                        this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.Title'),
                        description
                    );
                }
            });
    }

    public downloadTemplateFolderClick(templateId: string[]) {
        if (this.pendingAction) {
            return;
        }

        if (!templateId || templateId.length == 0) {
            return;
        }

        const url = `${environment.baseplateApplicationWebServiceUrl}GetTemplatesZip`;

        this.pendingActionService.setPendingAction(PendingAction.downloadTemplateFolder)
        // TODO - to be identify which project is downloading and show progress there only.
        const name = templateId.length == 1 ? this.templateService.findTemplateFolderById(templateId[0]).name : 'Templates';

        const list = this.templateService.findTemplateFolderById(templateId[0]);

        const downloadBody = [list];
        const body = downloadBody;
        const request = new HttpRequest('POST', url, body, {
            headers: new HttpHeaders({
                Accept: 'application/zip'
            }),
            responseType: 'blob'
        });

        this.apiService.request<Blob>(request, { supressErrorMessage: true })
            .then(response => {
                const zip = response.body;

                this.browser.downloadBlob(zip, name + '.zip', false, false);
            })
            .catch(response => {
                if (response instanceof Error) {
                    console.error(response);
                }

                if (response.status == 404) {
                    this.onServiceErrorHandler.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modal.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            })
            .finally(() => {
                this.clearPendingAction(PendingAction.downloadTemplateFolder);
            });
    }

    /* #endregion */

    /* #region AllDesigns */

    getAllDesignsMenu() {
        const newDesign = () => {
            this.selectedProject = this.toDisplayProject(this.documentService.draftsProject);
            this.newCustomDesignClick();
        };
        const download = () => {
            const draftProjId = this.documentService.draftsProject.id;
            const projects = this.generateProjectDownloadBody([draftProjId]);
            this.downloadProjectsClick(projects, null);
        };
        const menuAllDesigns: IMenuItem<string>[] = [
            { onClick: newDesign, ...this.menuOptionsList[MenuOptions.CreateDesign] },
            { onClick: download, ...this.menuOptionsList[MenuOptions.Download] },
        ];

        return menuAllDesigns;
    }
    /* #endregion */

    /* #region MenuOptions */

    setMenuOptions() {
        this.menuOptionsList = {
            [MenuOptions.MENU_SEPERATOR]: { label: MENU_SEPERATOR },
            [MenuOptions.NewProject]: { label: this.translate('Agito.Hilti.Profis3.HomePage.MyProjects.Menu.NewProject'), icon: 'add-folder' },
            [MenuOptions.NewCompanyProject]: { label: this.translate('Agito.Hilti.Profis3.HomePage.CompanyProjects.Menu.NewCompanyProject'), icon: 'add-company-project' },
            [MenuOptions.AddSubFolder]: { label: this.translate('Agito.Hilti.Profis3.HomePage.MyProjects.Menu.SubProject'), icon: 'add-sub-folder' },
            [MenuOptions.Download]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Download'), icon: 'download' },
            [MenuOptions.CreateDesign]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.CreateDesign'), icon: 'add-document' },
            [MenuOptions.Share]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Share'), icon: 'share-icon' },
            [MenuOptions.ManageSharing]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.ManageSharing'), icon: 'share-icon' },
            [MenuOptions.Sharing]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Sharing'), icon: 'share-icon' },
            [MenuOptions.AddToFavorite]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.AddToFavourite'), icon: 'favorite' },
            [MenuOptions.RemoveFromFavorite]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.RemoveFromFavourites'), icon: 'favorite' },
            [MenuOptions.Rename]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Rename'), icon: 'edit-pencil' },
            [MenuOptions.Archive]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Archive'), icon: 'archive' },
            [MenuOptions.ConvertToCompanyProject]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.ConvertToCompanyProject'), icon: 'convert-company-project' },
            [MenuOptions.NewTemplate]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Template.Create'), icon: 'template-create' },
            [MenuOptions.AddFolder]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Template.AddNewFolder'), icon: 'add-sub-folder' },

        };
    }

    selectLeftNavigationItem = (primarySelecteditem: LeftNavigationPrimaryButtons, secondarySelectedItem?: LeftNavigationSecondaryButtons, refreshDesigns = true) => {
        const selectedState: ILeftNavigationSelectedState =
        {
            primarySelection: primarySelecteditem,
            secondarySelection: secondarySelectedItem
        };

        this.user.projectAndDesignViewV2 = selectedState;

        if (refreshDesigns) {
            this.refreshDesigns();
        }
    }

    convertToCompanyProjectClick(details: string) {
        if (this.companyProjectDisabled) {
            return;
        }

        this.modal.openConfirmChange({
            id: 'delete-project-popup',
            title: this.translate('Agito.Hilti.Profis3.ConvertProject.Title'),
            message: this.translate('Agito.Hilti.Profis3.ConvertProject.Content'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.Convert'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.Cancel'),
            onConfirm: (modal) => {
                this.documentService.convertProject(details)
                    .then(() => {
                        this.setMyProjectsTreeData();
                        this.setMyCompanyProjectsTreeData();
                        this.myProjectsTree.onSelect(this.myProjectsTree);
                    });
                modal.close(true);
            },
            onCancel: (modal) => {
                modal.close(false);
            }
        });
    }

    renameClick(id: string, level: number) {
        const item = this.documentService.findProject(id, this.documentService.projects);
        const itemName = item.name.trim();
        this.modal.openConfirmChangeInput({
            id: 'confirm-rename-sub-folder',
            title: level == 0 ? this.translate('Agito.Hilti.Profis3.HomePage.Project.Project.Rename') : this.translate('Agito.Hilti.Profis3.HomePage.Project.SubProject.Rename'),
            message: level == 0 ? this.translate('Agito.Hilti.Profis3.HomePage.Project.Project.Name') : this.translate('Agito.Hilti.Profis3.HomePage.Folder.Name'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Save'),
            cancelButtonText: this.translate('Agito.Hilti.Profis3.HomePage.Popup.Cancel'),
            details: id,
            input: item.name,
            onConfirm: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                const newRenameText = modal.input.input.trim();
                if (!newRenameText) {
                    modal.close();
                    return;
                }

                // cancel rename of the item
                if (newRenameText == itemName) {
                    modal.close();
                } else if (this.documentService.projectNameExists(
                    newRenameText,
                    item.parentId)) {
                    modal.close();
                    this.onServiceErrorHandler.showExistingProjectNameModal();
                } else {
                    const project = this.documentService.findProjectById(id);
                    project.name = newRenameText;

                    this.pendingActionService.setPendingAction(PendingAction.renameProject)

                    const projectLevelType = item.parentId ? ModalDialogType.subproject : ModalDialogType.project;
                    this.documentService.saveProject(project, projectLevelType).then(() => {
                        // Update Project Object
                        item.name = newRenameText;
                        item.changeDate = new Date();
                        item.createDate = new Date();
                        item.expanded = true;
                        this.refreshAllProjectTrees();
                        //update display design
                        this.selectedProject = this.toDisplayProject(project);
                        this.refreshDesigns();
                    }).catch((err) => {
                        if (err instanceof Error) {
                            console.error(err);
                        }
                        item.name = itemName;
                        project.name = itemName;
                    }).finally(() => {
                        this.clearPendingAction(PendingAction.renameProject);
                        modal.close();
                    });
                }
            },
            onCancel: (modal: ModalInstance<IConfirmChangeInputProps>) => {
                modal.close();
            }
        });
    }

    public archiveProjectClick(deletedProjectId: string, isCompanyProject = false) {
        if (this.pendingAction) {
            return;
        }

        this.pendingActionService.setPendingAction(PendingAction.archiveProject)
        this.documentService.archiveProject(deletedProjectId)
            .finally(() => {
                this.clearPendingAction(PendingAction.archiveProject);
            })
            .then(() => {
                if (!isCompanyProject) {
                    this.setMyProjectsTreeData();
                    this.myProjectsTree.onSelect(this.myProjectsTree);
                }
                if (isCompanyProject) {
                    this.setMyCompanyProjectsTreeData();
                    this.companyProjectsTree.onSelect(this.companyProjectsTree);
                }
                this.setSharedProjectsTreeData();
                this.setFavoritesTreeData();
            })
            .catch((e) => {
                if (e instanceof Error) {
                    console.error(e);
                }

                if (e instanceof CantArchiveProjectsBecauseDocumentInUse) {
                    const cantEx: CantArchiveProjectsBecauseDocumentInUse = e;
                    const descriptionForDocument = this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.DescriptionForSpecificDocument');
                    let description = this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.Description');
                    description += '\n';
                    let docCount = 0;
                    for (const doc of cantEx.documentsInQuestion) {
                        docCount++;
                        if (docCount > 5) {
                            description += '\n\t\t' + this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.DescriptionForTooMuchDocuments');
                            break;
                        }
                        description += '\n\t\t' + descriptionForDocument.replace('{document}', doc.document).replace('{user}', doc.username);
                    }
                    description += '\n';

                    this.modal.openAlertWarning(
                        this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotRemoveProjectWithLockedDocuments.Title'),
                        description
                    );
                }
            });
    }

    public downloadProjectsClick(projects: ProjectGetProjectContentResponseModel, title: string = null) {
        if (this.pendingAction) {
            return;
        }

        if (!projects?.projects || projects.projects.length == 0) {
            return;
        }

        const url = `${environment.baseplateApplicationWebServiceUrl}DownloadProjectZip`;

        this.pendingActionService.setPendingAction(PendingAction.downloadProject)
        const name = projects.projects.length == 1 ? projects.projects[0].name : title;
        const body = projects;
        const request = new HttpRequest('POST', url, body, {
            headers: new HttpHeaders({
                Accept: 'application/zip'
            }),
            responseType: 'blob'
        });

        this.apiService.request<Blob>(request, { supressErrorMessage: true })
            .then(response => {
                const zip = response.body;

                this.browser.downloadBlob(zip, name + '.zip', false, false);
            })
            .catch(response => {
                if (response instanceof Error) {
                    console.error(response);
                }

                if (response.status == 404) {
                    this.onServiceErrorHandler.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modal.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            })
            .finally(() => {
                this.clearPendingAction(PendingAction.downloadProject);
            });
    }

    private generateProjectDownloadBody(projectIds: string[]): ProjectGetProjectContentResponseModel {

        const projectResult: ProjectDownloadPayLoadModel[] = [];
        projectIds.forEach(projectId => {
            const matched: ProjectDownloadPayLoadModel = this.documentService.findProject(projectId, this.documentService.projects);
            const matchedResult = this.getConvertedProject(matched);
            projectResult.push(matchedResult);
        });
        return { projects: projectResult };
    }

    private getConvertedProject(project: ProjectDownloadPayLoadModel, isFavorite?: boolean, isSharedByMe?: boolean): ProjectDownloadPayLoadModel {
        // Convert designs dictionary to array
        let designList = (Object.values(project.designs || {}) as IDesignExtended[]).map(design => {
            design.documentid = design.id;
            return design;
        });
        if (isFavorite) {
            designList = designList.filter(a => a.isFavorite);
        }
        if (isSharedByMe) {
            designList = designList.filter(a => a.isSharedByMe);
        }
        const subProjectList = Object.values(project.subProjects || {});
        // Convert subProjects dictionary to array and recursively convert each subProject
        subProjectList.forEach(s => {
            this.getConvertedProject(s, isFavorite, isSharedByMe);
        });

        // Return the converted project
        project.projectid = project.id;
        project.parentprojectid = project.parentId;
        project.DesignList = designList;
        project.SubProjectList = subProjectList;

        return project;
    }

    private async addToFavoriteClick(project: Project) {
        const projectId = project.id;
        if (!projectId || this.pendingAction) {
            return;
        }

        this.pendingActionService.setPendingAction(PendingAction.addToFavorite);
        const documentType = DocumentType.Document;
        const documentIds = this.documentService.getDesignIdsByProjectId(projectId);
        try {
            await this.documentService.addToFavorite(documentIds, documentType);
            this.updateFavoriteDesigns((Object.values(project.designs)), true);
            this.markAsFavoriteClicked();
        } catch (error) {
            console.error(error);
        } finally {
            this.clearPendingAction(PendingAction.addToFavorite);
        }
    }

    private async removeFromFavoriteClick(project: Project) {
        const projectId = project.id;
        if (!projectId || this.pendingAction) {
            return;
        }

        const documentType = DocumentType.Document;
        this.pendingActionService.setPendingAction(PendingAction.removeFromFavorite);
        const documentIds = this.documentService.getDesignIdsByProjectId(projectId);
        try {
            await this.documentService.removeFromFavorite(documentIds, documentType);
            this.updateFavoriteDesigns(Object.values(project.designs), false);
        } catch (error) {
            console.error(error);
        } finally {
            this.clearPendingAction(PendingAction.removeFromFavorite);
        }
    }

    private updateFavoriteDesigns(designs: IDesignListItem[], isFavorite: boolean): void {
        designs.forEach(design => {
            design.isFavorite = isFavorite;
        });

        this.refreshAllProjectTrees();

        // Unselect the project once there are no favorite designs
        if (this.selectedTree == LeftNavigationPrimaryButtons.Favourites) {
            const isAnyFavDesignInProject = Object.values(this.documentService.designsFlat).filter(x => x.projectId == designs[0].projectId).some(x => x.isFavorite);

            if (!isAnyFavDesignInProject) {
                this.selectedTreeItem = this.favouritesTree;
                this.selectedProject = undefined;
            }
        }

        this.refreshDesigns();
    }

    shareProjectsClick(projectId: string) {
        if (this.isShareProjectHidden() || this.isShareProjectDisabled()) {
            return;
        }
        const selectedProject = this.documentService.findProjectById(projectId);
        selectedProject.loadUsers(this.documentService)
            .then(() => this.modal.openShareProject({
                selectedProject,
                onRemoveProject: () => {
                    this.documentService.deleteProjectLocal(this.selectedProject.id);

                    const selectedProject = Object.values(this.documentService.projects).find((project) => project.projectType == ProjectType.draft);
                    this.selectedProject = this.toDisplayProject(selectedProject);
                },
                onConfirm: (res: boolean) => {
                    if (res) {
                        this.setSharedProjectsTreeData();
                    }
                },
            }));
    }

    onDesignStateChangedfromDesignView(changeSet?: IDisplayDesignChangeOutput) {
        if (!changeSet) {
            this.refreshAllProjectTrees();
            return;
        }

        let designs: IDesignListItem[] = [];

        if (changeSet.displayDesigns) {
            const project = this.documentService.findProject(changeSet.displayDesigns[0].projectId, this.documentService.projects);
            const designIds = changeSet?.displayDesigns.map(x => x.id);
            designs = Object.values(project?.designs ?? [])?.filter(x => designIds.includes(x.id));
        }

        switch (changeSet.action) {
            case PendingAction.addToFavorite:
                this.updateFavoriteDesigns(designs, true);
                break;

            case PendingAction.removeFromFavorite:
                this.updateFavoriteDesigns(designs, false);
                break;
            case PendingAction.shareDesign:
                this.setTemplateTreeData();
                this.setSharedProjectsTreeData();
                // Unselect the project once there are no shared designs
                if (this.selectedTree == LeftNavigationPrimaryButtons.Shared) {
                    const isAnySharedDesignInProject = Object.values(this.documentService.designsFlat).filter(x => x.projectId == designs[0].projectId).some(x => x.isSharedByMe);

                    if (!isAnySharedDesignInProject) {
                        this.selectedTreeItem = this.sharedTree;
                        this.selectedProject = undefined;
                    }
                    this.refreshDesigns();
                }

                if (this.selectedTree == LeftNavigationPrimaryButtons.Templates &&
                    this.user.projectAndDesignViewV2.secondarySelection == LeftNavigationSecondaryButtons.TemplatesSharedByMe) {
                    const isAnySharedTemplateInFolder = Object.values(this.templateService.templateV2).filter(x => x.templateFolderId == this.selectedTemplateFolder?.templateFolderId).some(x => x.isSharedByMe);

                    if (!isAnySharedTemplateInFolder) {
                        this.selectedTreeItem = this.templatesTree;
                        this.selectedTemplateFolder = undefined;
                        this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Templates, null, true);
                    }
                }

                this.refreshDesigns();
                break;
            case PendingAction.deleteTemplate:
            case PendingAction.editTemplate:
                this.setTemplateTreeData();
                break;
            case PendingAction.createDesign:
                if (this.user?.design) {
                    const project = this.documentService.findProjectById(this.user?.design?.projectId);
                    project?.projectType === ProjectType.common ? project?.isCompanyProject ? this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.CompanyProjects) : this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.MyProjects) : this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Drafts);
                }
                break;
            default:
                this.refreshAllProjectTrees();
                break;
        }
    }

    /* #endregion */

    /* #region Favorites Tree */

    setFavoritesTreeData() {
        this.favouritesTree = {
            id: LeftNavigationPrimaryButtons.Favourites,
            name: this.translate('Agito.Hilti.Profis3.HomePage.Favorite'),
            icon: 'favorite',
            expanded: !this.sectionsCollapsed.favorites.collapsed && !this.isFavoriteTreeDisabled,
            subItems: this.createFavoritesTree(this.documentService.projectsFlat, 0),
            contextMenu: this.getFavoritesMenu(),
            disabled: this.isFavoriteTreeDisabled,
            tooltip: this.isFavoriteTreeDisabled ?
                { title: null, content: this.translate('Agito.Hilti.Profis3.Features.Menu.Favorites.Tooltip') } as Tooltip
                : null,
            onSelect: () => {
                this.selectedProject = undefined;
                this.selectedTemplateFolder = undefined;
                this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Favourites);
            },
            onExpanded: (node: ITreeViewItem<string>) => {
                this.onSectionCollapsedChange(this.sectionsCollapsed.favorites, !node.expanded);
            }
        };
    }

    get isFavoriteTreeDisabled() {
        return this.user.hasFreeLicense || this.user.hasfloatingLimitReached;
    }

    createFavoritesTree = (project: Record<string, Project>, level: number): ITreeViewItem<string>[] => {
        const result: ITreeViewItem<string>[] = [];
        const projects = Object.values(project).filter(x => x.projectType == ProjectType.common || x.projectType == ProjectType.draft);
        const projectIdsWithFavDocuments = Object.values(this.documentService.designsFlat).filter(x => x.isFavorite).map(x => x.projectId);

        projects.forEach(project => {
            if (projectIdsWithFavDocuments.includes(project.id)) {
                const { id, parentId, name, isCompanyProject } = project;
                const node: ITreeViewItem<string> = {
                    id: id,
                    parentId: parentId,
                    icon: isCompanyProject ? 'company-projects' : 'folder',
                    details: project.id,
                    name: name,
                    expanded: project.expandedState?.isExpanded,
                    subItems: [],
                    onSelect: () => {
                        this.selectedTemplateFolder = undefined;
                        this.selectedProject = this.toDisplayProject(this.documentService.projectsFlat[id]);
                        this.selectLeftNavigationItem(LeftNavigationPrimaryButtons.Favourites);
                    },
                    onDrop: (targetProjectId: string, transferDesign: IProjectAndDesignId) => {
                        this.dropEvent(targetProjectId, transferDesign);
                    },
                    dropActive: true
                };
                node.level = level;
                node.contextMenu = this.getFavoritesMenu(node.id);
                result.push(node);
            }
        })

        return result;
    }

    getFavoritesMenu = (id?: string) => {

        const downloadProjects = () => {
            const projectIds: string[] = [];
            if (id) {
                projectIds.push(id);
            } else {
                this.favouritesTree.subItems.forEach(i => {
                    projectIds.push(i.id);
                });
            }

            const projects = this.generateFavouriteDownloadBody(projectIds);
            this.downloadProjectsClick(projects, this.translate('Agito.Hilti.Profis3.HomePage.Favorite'));
        };

        const menuAddProject: IMenuItem<string>[] = [
            { onClick: downloadProjects, ...this.menuOptionsList[MenuOptions.Download] },
        ];

        return menuAddProject;
    }

    private generateFavouriteDownloadBody(projectIds: string[]): ProjectGetProjectContentResponseModel {

        const projectResult: ProjectDownloadPayLoadModel[] = [];
        projectIds.forEach(projectId => {
            const matched: ProjectDownloadPayLoadModel = cloneDeep(this.documentService.findProject(projectId, this.documentService.projects));
            matched.subProjects = {};
            const matchedResult = this.getConvertedProject(matched, true);
            projectResult.push(matchedResult);
        });
        return { projects: projectResult };
    }

    /* #endregion */
    public openNewDesignFromIntegrationDocument($event: { document: DocumentModel, documentType: DocumentIntegrationType }) {
        const quickStartApplication = this.modulesService.getQuickStartApplicationForIntegration($event.documentType);
        if (quickStartApplication != null) {
            this.newDesign(quickStartApplication, $event.document);
        }
    }

    public newDesign(quickStartApplication: IQuickStartApplication, integrationDocument?: DocumentModel) {
        if (this.pendingAction != null) {
            return;
        }

        // For designs created from integration documents (RISA, RAM) a Drafts project needs to be selected
        // as defined in BUDQBP-9667
        const projectId = (!integrationDocument) ? this.selectedProject.id : this.documentService.draftsProject.id;
        const project = this.documentService.findProjectById(projectId);

        let name: string;
        if (quickStartApplication?.getNewDesignName != null) {
            try {
                name = quickStartApplication.getNewDesignName();
            }
            catch (err) {
                console.error(err);
                this.modal.openAlertWarning(
                    this.translate('Agito.Hilti.Profis3.ProjectAndDesign.Alerts.CannotCreateNewDesign.Title'),
                    this.translate('Agito.Hilti.Profis3.ProjectAndDesign.Alerts.CannotCreateNewDesign.Description')
                );
                return;
            }
        }

        this.handleProjectDesignName(name, project, integrationDocument)
            .then(async (newName) => {
                if (newName == null) {
                    return;
                }

                name = newName;

                if (project != null) {
                    const projectForName = project.parentId != null
                        ? this.documentService.findProjectById(project.parentId)
                        : project;
                    name = this.documentService.createUniqueName(name, Object.values(projectForName.designs).map((item) => item.designName));
                }

                // Modularization: implement newDesignFromModuleQuickStart function for PE and C2C module
                if (quickStartApplication?.createNewDesign != null) {
                    await this.newDesignFromModuleQuickStart(quickStartApplication, name, project, integrationDocument);
                }
            }
            );
    }

    private async newDesignFromModuleQuickStart(quickStartApplication: IQuickStartApplication, designName: string, project: Project, integrationDocument: DocumentModel) {
        this.pendingAction = PendingAction.createQuickStartDesign;
        this.pendingActionQuickStart = quickStartApplication.idQuickStart;

        try {
            const saveResultData = await quickStartApplication.createNewDesign({ designName, projectName: project.name, projectId: project.id, integrationDocument });
            this.user.changeDesign(project, saveResultData.design as Design);
            this.routingService.navigateToUrl(`${saveResultData.path}${saveResultData.designId}`);
        }
        catch {
            /* Nothing to do. */
        }
        finally {
            this.pendingAction = null;
            this.pendingActionQuickStart = undefined;
        }
    }

    private async handleProjectDesignName(name: string, project: Project, integrationDocument: DocumentModel): Promise<string> {
        if (integrationDocument && integrationDocument.IntegrationType == DocumentIntegrationType.Risa) {
            name = integrationDocument.DocumentName;

            if (Object.values(project.designs).some(x => x.designName == integrationDocument.DocumentName)) {
                await this.modal.openConfirmChange({
                    id: 'change-risa-design-name',
                    title: this.localization.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Title'),
                    message: format(this.localization.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Message'), integrationDocument.DocumentName),
                    confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Button.Content.ReplaceExisting'),
                    cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Integrations.DesignNameExists.Button.Content.KeepBoth'),
                    onConfirm: async (modal) => {
                        // on replace existing design
                        const previousDesign = Object.values(project.designs).filter(x => x.designName == integrationDocument.DocumentName)[0];

                        try {
                            const displayDesign = this.toDisplayDesign(previousDesign as IDesignListItem)
                            await this.confirmDeleteDesign(displayDesign.id, displayDesign.projectId, displayDesign.designType);
                        }
                        finally {
                            modal.close();
                        }
                    },
                    onCancel: (modal) => {
                        // on keep both designs
                        modal.close();
                    }
                }).closed;
            }
        }

        return name;
    }

    private async confirmDeleteDesign(designId: string, projectId: string, designType: DesignTypeId): Promise<void> {
        if (this.deckingEnabled && designType === DesignTypeId.DiaphragmDesign) {
            const designsInfo = this.modulesService.getDesignListInfoByDesignType(designType) as IDeckingDesignListInfo;
            if (designsInfo) await designsInfo.deleteDesign(designId);
        }

        this.pendingAction = PendingAction.deleteDesign;
        this.pendingActionDesign = ({ designId: designId, projectId: projectId } as IProjectAndDesignId);

        try {
            await this.documentService.removeDesigns([designId]);

            if (!this.offlineService.isOffline) {
                this.refreshAllProjectTrees();
            }
        }
        finally {
            this.pendingAction = null;
            this.pendingActionDesign = null;
        }
    }

    public get virtualTourMyProjectsTreeData(): ITreeViewItem<string> {
        return getVirtualTourMyProjectsTreeData(this.localization, !this.virtaulTourExpandShared);
    }
    public get virtualTourSharedProjectsTreeData(): ITreeViewItem<string> {
        return getVirtualTourSharedProjectsTreeData(this.localization, this.virtaulTourExpandShared);
    }

    private async getDesign(designTypeId: number, designId: string): Promise<Design> {
        const designInfo = this.modulesService.getDesignListInfo().find(x => x.designTypeId == designTypeId);
        if (designInfo?.getDesign) {
            try {
                return await designInfo.getDesign(designId) as unknown as Design;
            }
            catch (err) {
                console.error(err);
                throw new Error(`get design has thrown an error for design type ${designTypeId}`)
            }
        }

        throw new Error(`get design not supported for design type ${designTypeId}`)
    }

    public async dropEvent(targetProjectId: string, transferDesign: IProjectAndDesignId) {
        const targetProject = (targetProjectId === LeftNavigationPrimaryButtons.Drafts) ? this.documentService.draftsProject : this.documentService.findProject(targetProjectId, this.documentService.projects);
        const sourceProject = this.documentService.findProject(transferDesign.projectId, this.documentService.projects);
        const design = this.documentService.findDesignById(transferDesign.designId);

        // Never move the shared designs to Drafts project
        if (targetProjectId === LeftNavigationPrimaryButtons.Drafts && (design.isSharedByMe || !design.owner)) {
            return;
        }
        const isSourceAndTargetProjectHierarchyMatched = this.documentService.isProjectPresentInTargetProjectHierarchy(sourceProject, targetProject);

        if (design != null && !isSourceAndTargetProjectHierarchyMatched && this.documentService.designNameExistsOnNew(targetProject, design.designName)) {
            const isDecking = design.metaData.designType === DesignTypeId.DiaphragmDesign;
            if (isDecking) {
                await this.modal.openDeckingRenameDesign({
                    documentId: design.id,
                    dropProject: targetProject
                }).closed;
            } else {
                await this.modal.openRenameDesign({
                    design: await this.getDesign(design.metaData.designType, design.id),
                    dropProject: targetProject
                }).closed;
            }
        } else {
            await this.documentService.moveDesign(transferDesign.designId, targetProject.id);
        }
        this.selectedProject = this.toDisplayProject(sourceProject);
        this.refreshDesigns();
        this.setSharedAndFavoritesTreeData();
    }

}
