import { Camera } from '@babylonjs/core/Cameras/camera.js';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo.js';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial.js';
import { Color3 } from '@babylonjs/core/Maths/math.color.js';
import { Quaternion, Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { VertexBuffer } from '@babylonjs/core/Meshes/buffer.js';
import { Mesh } from '@babylonjs/core/Meshes/mesh.js';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData.js';
import { SubMesh } from '@babylonjs/core/Meshes/subMesh.js';
import { vector3Extensions } from '../babylon-extensions.js';
import { GLTemp } from '../cache/gl-temp.js';
import { cloneVertexData } from '../vertex-data-helper.js';
const glTemp = new GLTemp({
  vector3: 6,
  quaternion: 1,
  matrix: 3
});
export class Text2D {
  static defaultCapacity = 10;
  static scaleFactor = 2000;
  static offsetY = 0;
  static lineOffsetY = 0;
  // ~ 0.001°
  static rotationMargin = 0.00001745329;
  static d90 = Math.PI * 0.5;
  static d180 = Math.PI;
  static d270 = Math.PI * 1.5;
  static d360 = Math.PI * 2;
  glFontTexture;
  mesh;
  width = 0;
  height = 0;
  text = '';
  scene;
  sceneEvents;
  camera;
  context3d;
  renderNextFrame;
  onTextEntered;
  showTextEditor;
  zoomTextScale;
  cache;
  minStartCapacity;
  noZoom;
  noRotation;
  lineStart;
  lineEnd;
  lineUp;
  lineRotationY;
  lineRotationZ;
  lineAlignX;
  lineAlignY;
  linePosition;
  skipRepos;
  meshInfo;
  isUpsideDown;
  color;
  alpha;
  _editable;
  isDropDown;
  constructor(ctor) {
    this.scene = ctor.scene;
    this.sceneEvents = ctor.sceneEvents;
    this.camera = ctor.camera;
    this.context3d = ctor.context3d;
    this.renderNextFrame = ctor.renderNextFrame;
    this.onTextEntered = ctor.onTextEntered;
    this.showTextEditor = ctor.showTextEditor;
    this.zoomTextScale = ctor.zoomTextScale;
    this._editable = ctor.editable ?? false;
    this.color = Color3.Black();
    this.alpha = 1.0;
    this.minStartCapacity = ctor.capacity ?? Text2D.defaultCapacity;
    this.noZoom = ctor.noZoom ?? false;
    this.noRotation = ctor.noRotation ?? false;
    this.cache = ctor.cache;
    this.glFontTexture = ctor.glFontTexture;
    this.isDropDown = ctor.isDropDown ?? false;
    this.beforeCameraRender = this.beforeCameraRender.bind(this);
    this.sceneEvents.addEventListener('onBeforeCameraRenderObservable', this.beforeCameraRender);
    this.onTextEditorClose = this.onTextEditorClose.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  static printUsage(cache) {
    console.debug(`Text2D.textCount: ${Text2D.getTextCount(cache)}`);
    console.debug(`Text2D.reservedText: ${Text2D.calculateReservedCount(cache)}`);
    console.debug(`Text2D.releasedText: ${Text2D.getMeshes(cache).length}`);
  }
  static calculateReservedCount(cache) {
    return Text2D.getTextCount(cache) - Text2D.getMeshes(cache).length;
  }
  static isEverythingDisposed(cache) {
    return Text2D.calculateReservedCount(cache) == 0;
  }
  static getMeshes(cache) {
    return cache.meshCache.create('Text2D.Meshes', () => {
      return [];
    });
  }
  static getTextCount(cache) {
    return cache.textCache.textMeta.textCount;
  }
  get meshOffsetY() {
    return this.height * Text2D.offsetY / (4 / this.glFontTexture.fontTexture.sampling);
  }
  get editable() {
    return this._editable;
  }
  set editable(editable) {
    if (this._editable !== editable) {
      this._editable = editable;
      // enable/disable the meshes
      if (this.meshInfo?.frontMesh != null) {
        this.meshInfo.frontMesh.isPickable = this.editable;
      }
      if (this.meshInfo?.backMesh != null) {
        this.meshInfo.backMesh.isPickable = this.editable;
      }
    }
  }
  setText(text) {
    if (this.text != text) {
      this.text = text;
      if (this.text) {
        this.reserveMesh();
        this.updateMeshInfoText(text);
        if (this.meshInfo == null) {
          throw new Error('this.meshInfo should be set by this.reserveMesh()');
        }
        this.text = this.meshInfo.text;
        this.width = this.meshInfo.width;
        this.height = this.meshInfo.height;
      }
      this.renderNextFrame();
    }
    // hide/show mesh
    this.setEnabled(this.text ? true : false);
    if (this.mesh != null && this.mesh.isEnabled() && this.meshInfo != null) {
      this.width = this.meshInfo.width;
      this.height = this.meshInfo.height;
    } else {
      this.width = 0;
      this.height = 0;
    }
  }
  setColor(color) {
    this.color = color;
    this.setMaterial();
  }
  setAlpha(alpha) {
    this.alpha = alpha;
    this.setMaterial();
  }
  setMaterial(color = this.color, alpha = this.alpha) {
    this.color = color;
    this.alpha = alpha;
    if (this.meshInfo != null) {
      const material = this.getMaterial();
      this.meshInfo.frontMesh.material = material;
      this.meshInfo.backMesh.material = material;
    }
  }
  // CR: if we don't have a mesh yet alpha index should be set when a mesh is created
  setAlphaIndex(alphaIndex) {
    if (this.meshInfo != null) {
      this.meshInfo.frontMesh.alphaIndex = alphaIndex;
      this.meshInfo.backMesh.alphaIndex = alphaIndex;
    }
  }
  setEnabled(value) {
    if (this.mesh != null) {
      this.mesh.setEnabled(value);
    }
  }
  setRenderingGroupId(id) {
    if (this.meshInfo != null) {
      this.meshInfo.frontMesh.renderingGroupId = id;
      this.meshInfo.backMesh.renderingGroupId = id;
    }
  }
  setTextOnLine(text, start, end, up, rotationY, rotationZ, alignX, alignY, skipRepos) {
    this.setText(text);
    if (this.mesh == null || !text) {
      return;
    }
    rotationY = rotationY ?? 0;
    rotationZ = rotationZ ?? 0;
    alignX = alignX ?? 1 /* Align.Center */;
    alignY = alignY ?? 2 /* Align.End */;
    if (this.text != text || this.lineStart == null || !vector3Extensions.equals(this.lineStart, start) || this.lineEnd == null || !vector3Extensions.equals(this.lineEnd, end) || this.lineUp == null || !vector3Extensions.equals(this.lineUp, up) || this.lineRotationY != rotationY || this.lineRotationZ != rotationZ || this.lineAlignX != alignX || this.lineAlignY != alignY || this.skipRepos != skipRepos) {
      const widthHalf = this.width / 2;
      const heightHalf = this.height / 2;
      const line = glTemp.vector3[0];
      end.subtractToRef(start, line);
      const xAxis = line;
      const yAxis = up;
      const zAxis = glTemp.vector3[2];
      Vector3.CrossToRef(xAxis, yAxis, zAxis);
      // offset X
      const alignOffsetX = alignX == 0 /* Align.Start */ ? widthHalf : alignX == 2 /* Align.End */ ? xAxis.length() - widthHalf : xAxis.length() / 2;
      start.addToRef(glTemp.vector3[4].copyFrom(xAxis).normalize().scaleInPlace(alignOffsetX), this.mesh.position);
      // offset Y
      if (this.noZoom && (this.noRotation || this.camera.mode == Camera.ORTHOGRAPHIC_CAMERA)) {
        const alignOffsetY = alignY == 0 /* Align.Start */ ? -this.height : alignY == 2 /* Align.End */ ? 0 : -heightHalf;
        this.mesh.position.addInPlace(glTemp.vector3[4].copyFrom(yAxis).normalize().scaleInPlace(alignOffsetY));
      }
      // rotate by axes (start, end and up)
      const lineRotation = glTemp.vector3[4];
      Vector3.RotationFromAxisToRef(xAxis, yAxis, zAxis, lineRotation);
      Quaternion.RotationYawPitchRollToRef(lineRotation.y, lineRotation.x, lineRotation.z, this.mesh.rotationQuaternion = this.mesh.rotationQuaternion ?? Quaternion.Identity());
      // rotate by rotation input (rotationY, rotationZ)
      const rotation = glTemp.quaternion[0];
      Quaternion.RotationYawPitchRollToRef(rotationY, 0, rotationZ, rotation);
      this.mesh.rotationQuaternion.multiplyToRef(rotation, this.mesh.rotationQuaternion);
      // save state
      this.lineStart = start.clone();
      this.lineEnd = end.clone();
      this.lineRotationY = rotationY;
      this.lineRotationZ = rotationZ;
      this.lineAlignX = alignX;
      this.lineAlignY = alignY;
      this.skipRepos = skipRepos;
      // CR: still don't know what skipRepos is???
      if (skipRepos) {
        this.lineUp = undefined;
        this.linePosition = undefined;
      } else {
        this.lineUp = up.clone();
        this.linePosition = this.mesh.position.clone();
      }
    }
  }
  clearTextOnLine() {
    if (this.mesh != null) {
      this.mesh.position = Vector3.Zero();
      this.mesh.rotationQuaternion = Quaternion.Identity();
    }
    // save state
    this.lineStart = undefined;
    this.lineEnd = undefined;
    this.lineUp = undefined;
    this.lineRotationY = undefined;
    this.lineRotationZ = undefined;
    this.lineAlignX = undefined;
    this.lineAlignY = undefined;
    this.linePosition = undefined;
    this.skipRepos = undefined;
  }
  dispose() {
    this.sceneEvents.removeEventListener('onBeforeCameraRenderObservable', this.beforeCameraRender);
    this.releaseMesh();
  }
  getBoundingInfoWorld() {
    if (this.meshInfo != null && this.mesh != null) {
      const mesh = this.meshInfo.frontMesh.isEnabled() ? this.meshInfo.frontMesh : this.meshInfo.backMesh;
      this.setMeshRotationAndZoom(mesh);
      const meshWorldMatrix = mesh.getWorldMatrix();
      const matrix = meshWorldMatrix.getRotationMatrix();
      const bb = mesh.getBoundingInfo().boundingBox;
      const minimum = Vector3.TransformCoordinates(bb.minimum.multiply(mesh.scaling), matrix);
      const maximum = Vector3.TransformCoordinates(bb.maximum.multiply(mesh.scaling), matrix);
      return new BoundingInfo(bb.centerWorld.add(minimum), bb.centerWorld.add(maximum));
    }
    return undefined;
  }
  beforeCameraRender() {
    if (this.meshInfo != null && this.meshInfo.mesh.isEnabled() && this.meshInfo.capacity > 0) {
      const visibleSide = this.calculateVisibleSide(this.meshInfo.frontMesh);
      // hide front or back
      this.meshInfo.frontMesh.setEnabled(visibleSide == 0 /* Text2DSide.Front */);
      this.meshInfo.backMesh.setEnabled(visibleSide == 1 /* Text2DSide.Back */);
      // rotate
      this.setMeshRotationAndZoom(this.meshInfo.frontMesh.isEnabled() ? this.meshInfo.frontMesh : this.meshInfo.backMesh);
    }
  }
  getScreenPosition(mesh) {
    const viewport = this.camera.viewport;
    const transformMatrix = this.scene.getTransformMatrix();
    const worldMatrix = mesh.getWorldMatrix();
    const viewMatrix = glTemp.matrix[0];
    worldMatrix.multiplyToRef(transformMatrix, viewMatrix);
    const screenPosition = Vector3.Project(glTemp.vector3[1].copyFromFloats(0, 0, 0), worldMatrix, transformMatrix, viewport);
    return new Vector2(screenPosition.x, screenPosition.y);
  }
  getFontTextureChar(char) {
    const charInfo = this.glFontTexture.fontTexture.getCharInfo(char);
    // sometimes charinfo is fliped, why?, corrected manualy
    if (charInfo.tl.x > charInfo.br.x) {
      charInfo.tl.x = 1 - charInfo.tl.x;
      charInfo.br.x = 1 - charInfo.br.x;
    }
    return charInfo;
  }
  getMaterial(color = this.color, alpha = this.alpha) {
    return this.cache.materialCache.create(`Text2D.Material:${color.toHexString()}:${alpha}:${this.scene.uid}`, () => {
      const material = new StandardMaterial('Text2D', this.scene);
      material.diffuseTexture = this.glFontTexture.texture;
      material.emissiveColor = color;
      material.alpha = alpha;
      material.useAlphaFromDiffuseTexture = true;
      material.disableLighting = true;
      return material;
    });
  }
  calculateVisibleSide(mesh) {
    const transformMatrix = this.scene.getTransformMatrix();
    const vertices = mesh.getVerticesData(VertexBuffer.PositionKind);
    const worldMatrix = mesh.getWorldMatrix();
    const viewMatrix = glTemp.matrix[0];
    worldMatrix.multiplyToRef(transformMatrix, viewMatrix);
    const topLeft = glTemp.vector3[0];
    Vector3.TransformCoordinatesFromFloatsToRef(vertices[0], vertices[1], vertices[2], viewMatrix, topLeft);
    const topRight = glTemp.vector3[1];
    Vector3.TransformCoordinatesFromFloatsToRef(vertices[3], vertices[4], vertices[5], viewMatrix, topRight);
    const bottomLeft = glTemp.vector3[2];
    Vector3.TransformCoordinatesFromFloatsToRef(vertices[6], vertices[7], vertices[8], viewMatrix, bottomLeft);
    const top = glTemp.vector3[3];
    topRight.subtractToRef(topLeft, top);
    const left = glTemp.vector3[4];
    bottomLeft.subtractToRef(topLeft, left);
    const front = glTemp.vector3[5];
    Vector3.CrossToRef(top, left, front);
    return front.z < 0 ? 0 /* Text2DSide.Front */ : 1 /* Text2DSide.Back */;
  }
  onTextEditorClose(text) {
    if (this.onTextEntered != null) {
      this.onTextEntered(this, text);
    }
  }
  setMeshRotationAndZoom(innerMesh) {
    if (this.meshInfo == null) {
      throw new Error('this.meshInfo should be set at this point');
    }
    if (this.mesh == null) {
      throw new Error('this.mesh should be set at this point');
    }
    const canvas = this.scene.getEngine().getRenderingCanvas() ?? undefined;
    const transformMatrix = this.scene.getTransformMatrix();
    const canvasWidth = this.context3d.width;
    const canvasHeight = this.context3d.height;
    const cameraViewMatrix = this.camera.getViewMatrix();
    const meshWorldMatrix = this.meshInfo.mesh.getWorldMatrix();
    let computeInnerMeshWorldMatrix = false;
    const noRotation = this.noRotation || this.camera.mode == Camera.ORTHOGRAPHIC_CAMERA;
    let rotationAngle;
    let meshWorldViewMatrix;
    let meshWorldViewMatrixInvert;
    // rotation
    if (noRotation) {
      // rotate (180 degrees) so that the text is not upside down
      const vertices = innerMesh.getVerticesData(VertexBuffer.PositionKind);
      const worldMatrix = innerMesh.getWorldMatrix();
      const viewMatrix = glTemp.matrix[0];
      worldMatrix.multiplyToRef(transformMatrix, viewMatrix);
      const topLeft = glTemp.vector3[0];
      Vector3.TransformCoordinatesFromFloatsToRef(vertices[0], vertices[1], vertices[2], viewMatrix, topLeft);
      const topRight = glTemp.vector3[1];
      Vector3.TransformCoordinatesFromFloatsToRef(vertices[3], vertices[4], vertices[5], viewMatrix, topRight);
      const dir = glTemp.vector3[2];
      topLeft.subtractToRef(topRight, dir);
      let angle = Math.atan2(dir.y * canvasHeight, dir.x * canvasWidth);
      if (innerMesh.rotation.z == Math.PI) {
        angle -= Math.PI;
      }
      angle = (Text2D.d360 + angle) % Text2D.d360;
      const rotation = angle < Text2D.d90 - Text2D.rotationMargin || angle > Text2D.d270 + Text2D.rotationMargin ? Math.PI : 0;
      if (innerMesh.rotation.z != rotation) {
        innerMesh.rotation.z = rotation;
        computeInnerMeshWorldMatrix = true;
      }
    } else {
      // rotate so that the text is always facing the camera and is aligned to the line
      meshWorldViewMatrix = meshWorldMatrix.multiply(cameraViewMatrix);
      meshWorldViewMatrixInvert = meshWorldViewMatrix.invert();
      let rotation = Quaternion.FromRotationMatrix(meshWorldViewMatrixInvert.getRotationMatrix());
      if (innerMesh.rotationQuaternion == null || !rotation.equals(innerMesh.rotationQuaternion)) {
        innerMesh.rotationQuaternion = rotation;
        computeInnerMeshWorldMatrix = true;
      }
      // positioned on line
      if (this.lineStart != null && this.lineEnd != null && this.lineUp != null) {
        const lineWorldMatrix = this.mesh.parent != null ? this.mesh.parent.getWorldMatrix() : this.cache.matrixCache.identity;
        const lineWorldTransformMatrix = glTemp.matrix[2];
        lineWorldMatrix.multiplyToRef(transformMatrix, lineWorldTransformMatrix);
        const lineStart = glTemp.vector3[0];
        Vector3.TransformCoordinatesToRef(this.lineStart, lineWorldTransformMatrix, lineStart);
        const lineEnd = glTemp.vector3[1];
        Vector3.TransformCoordinatesToRef(this.lineEnd, lineWorldTransformMatrix, lineEnd);
        const line = glTemp.vector3[2];
        lineEnd.subtractToRef(lineStart, line);
        rotationAngle = Math.atan2(line.y * canvasHeight, line.x * canvasWidth);
        rotationAngle = (Text2D.d360 + rotationAngle) % Text2D.d360;
        if (rotationAngle < Text2D.d90 - Text2D.rotationMargin || rotationAngle > Text2D.d270 + Text2D.rotationMargin) {
          this.isUpsideDown = false;
        }
        if (rotationAngle > Text2D.d90 + Text2D.rotationMargin && rotationAngle < Text2D.d270 - Text2D.rotationMargin) {
          this.isUpsideDown = true;
        }
        if (this.isUpsideDown) {
          rotationAngle = (Text2D.d360 + Text2D.d180 + rotationAngle) % Text2D.d360;
        }
        Quaternion.RotationYawPitchRollToRef(0, 0, rotationAngle, glTemp.quaternion[0]);
        rotation = innerMesh.rotationQuaternion.multiply(glTemp.quaternion[0]);
        if (!rotation.equals(innerMesh.rotationQuaternion)) {
          innerMesh.rotationQuaternion = rotation;
          computeInnerMeshWorldMatrix = true;
        }
      }
    }
    // calculate zoom
    let scale = 1;
    if (!this.noZoom) {
      if (this.camera.mode == Camera.PERSPECTIVE_CAMERA) {
        const transformMatrix = glTemp.matrix[0];
        meshWorldMatrix.multiplyToRef(cameraViewMatrix, transformMatrix);
        const modelZoomTextScale = this.zoomTextScale?.() ?? 1;
        Vector3.TransformCoordinatesFromFloatsToRef(0, 0, 0, transformMatrix, glTemp.vector3[0]);
        scale = Math.max(glTemp.vector3[0].length() / Text2D.scaleFactor * modelZoomTextScale / this.meshInfo.mesh.scaling.x, 1);
      } else {
        // should only use this.context3d.width
        const canvasWidthWidth = canvas?.width ?? this.context3d.width;
        scale = Math.max(2 * this.camera.orthoRight / canvasWidthWidth, 1);
      }
    }
    // position on line (only if we have zoom or rotation)
    if ((!this.noZoom || !noRotation) && this.lineUp != null && this.linePosition != null) {
      const scaledHeight = this.height * scale;
      const positionOffset = this.lineUp.clone().normalize().scaleInPlace(scaledHeight / 2 + scaledHeight * Text2D.lineOffsetY);
      const position = positionOffset.add(this.linePosition);
      if (!this.meshInfo.mesh.position.equals(position)) {
        this.meshInfo.mesh.position = position;
        this.meshInfo.mesh.computeWorldMatrix(true);
        computeInnerMeshWorldMatrix = true;
      }
    }
    // set zoom
    if (!this.noZoom) {
      if (innerMesh.scaling.x != scale) {
        innerMesh.scaling.x = scale;
        computeInnerMeshWorldMatrix = true;
      }
      if (innerMesh.scaling.y != scale) {
        innerMesh.scaling.y = scale;
        computeInnerMeshWorldMatrix = true;
      }
      const offsetY = scale * this.height * Text2D.offsetY;
      if (innerMesh.position.y != offsetY) {
        innerMesh.position.y = offsetY;
        computeInnerMeshWorldMatrix = true;
      }
    }
    // only update the inner mesh word matrix if anything changed
    if (computeInnerMeshWorldMatrix) {
      innerMesh.computeWorldMatrix(true);
    }
  }
  reserveMesh() {
    if (this.meshInfo == null) {
      const meshInfos = Text2D.getMeshes(this.cache);
      let meshInfo;
      // find mesh by text
      meshInfo = meshInfos.find(m => m.text == this.text && m.mesh.getScene() === this.scene);
      // find mesh by capacity
      if (meshInfo == null) {
        meshInfo = meshInfos.find(m => m.capacity == this.minStartCapacity && m.mesh.getScene() === this.scene);
      }
      // remove or create a new mesh
      if (meshInfo != null) {
        const meshInfoIndex = meshInfos.indexOf(meshInfo);
        if (meshInfoIndex != -1) {
          meshInfos.splice(meshInfoIndex, 1);
        }
      } else {
        meshInfo = this.createNewMeshInfo(this.minStartCapacity, this.text);
      }
      // save info
      this.meshInfo = meshInfo;
      this.mesh = this.meshInfo.mesh;
      this.text = this.meshInfo.text;
      this.width = this.meshInfo.width;
      this.height = this.meshInfo.height;
      // reset mesh
      this.mesh.position = Vector3.Zero();
      this.mesh.scaling = new Vector3(1, 1, 1);
      this.mesh.scalingDeterminant = 1;
      this.mesh.rotationQuaternion = Quaternion.Identity();
      this.mesh.parent = null;
      this.mesh.name = `Text "${this.text}"`;
      this.mesh.computeWorldMatrix(true);
      this.meshInfo.frontMesh.renderingGroupId = 0;
      this.meshInfo.frontMesh.isPickable = this.editable;
      this.meshInfo.frontMesh.onClick = this.onClick;
      this.meshInfo.frontMesh.scaling = new Vector3(1, 1, 1);
      this.meshInfo.backMesh.renderingGroupId = 0;
      this.meshInfo.backMesh.isPickable = this.editable;
      this.meshInfo.backMesh.onClick = this.onClick;
      this.meshInfo.backMesh.scaling = new Vector3(1, 1, 1);
      this.setMaterial();
      this.setAlphaIndex(Number.MAX_VALUE);
    }
  }
  releaseMesh() {
    if (this.meshInfo != null) {
      Text2D.getMeshes(this.cache).push(this.meshInfo);
      this.meshInfo = undefined;
      this.mesh = undefined;
    }
  }
  onClick(pickingInfos) {
    if (this.editable && this.showTextEditor != null) {
      if (this.meshInfo == null || this.mesh == null || this.text == null) {
        throw new Error('this.meshInfo == null || this.mesh == null || this.text == null');
      }
      for (const pickingInfo of pickingInfos) {
        if (pickingInfo.pickedMesh != null) {
          if (pickingInfo.pickedMesh === this.meshInfo.frontMesh || pickingInfo.pickedMesh === this.meshInfo.backMesh) {
            this.showTextEditor(this.text, this.text, this.getScreenPosition(this.mesh), this.isDropDown, undefined, undefined, this.onTextEditorClose);
          } else if (pickingInfo.pickedMesh.material == null || pickingInfo.pickedMesh.material.alpha < 1) {
            continue;
          }
          break;
        }
      }
    }
  }
  createNewMeshInfo(minStartCapacity, text) {
    text = text ?? '';
    // capacity
    let capacity = minStartCapacity;
    if (text.length > 0) {
      while (capacity < text.length) {
        capacity *= 2;
      }
    } else {
      capacity = 0;
    }
    const textSize = this.calculateTextSize(text);
    // create meshes
    const mesh = new Mesh('Text', this.scene);
    mesh.isText = true;
    const vertexData = capacity > 0 ? this.calculateVertexData(capacity, text, textSize.width, textSize.height) : undefined;
    const frontMesh = this.createNewMesh(vertexData, text, textSize.height, mesh);
    const backMesh = this.createNewMesh(vertexData != null ? cloneVertexData(vertexData) : undefined, text, textSize.height, mesh);
    backMesh.rotationQuaternion = Quaternion.RotationAxis(glTemp.vector3[0].copyFromFloats(0, 1, 0), Math.PI);
    this.cache.textCache.textMeta.textCount++;
    return {
      mesh,
      frontMesh,
      backMesh,
      capacity,
      text,
      width: textSize.width,
      height: textSize.height
    };
  }
  createNewMesh(vertexData, text, height, parentMesh) {
    // mesh
    const mesh = new Mesh('TextSide', this.scene);
    if (vertexData != null) {
      vertexData.applyToMesh(mesh, true);
      mesh.subMeshes = [];
      new SubMesh(0, 0, text.length * 4, 0, text.length * 6, mesh);
    }
    mesh.parent = parentMesh;
    mesh.material = this.getMaterial();
    mesh.position.y = height * Text2D.offsetY;
    // CR: can't we just use mesh.name instead of a new property?
    mesh.isText = true;
    return mesh;
  }
  calculateVertexData(capacity, text, width, height) {
    const positions = [];
    const indices = [];
    const normals = [];
    const uvs = [];
    const bottomY = -height / 2;
    const leftX = -width / 2;
    let lastX = 0;
    // add text
    let charInfo;
    let charTopY;
    let charLeftX;
    let charWidth;
    let charIndex = 0;
    for (const char of text) {
      charInfo = this.getFontTextureChar(char);
      charTopY = bottomY + charInfo.h / this.glFontTexture.fontTexture.sampling;
      charLeftX = leftX + lastX;
      charWidth = charInfo.w / this.glFontTexture.fontTexture.sampling;
      // positions
      positions.push(charLeftX, charTopY, 0);
      positions.push(charLeftX + charWidth, charTopY, 0);
      positions.push(charLeftX, bottomY, 0);
      positions.push(charLeftX + charWidth, bottomY, 0);
      // indices
      indices.push(charIndex + 0, charIndex + 3, charIndex + 1);
      indices.push(charIndex + 0, charIndex + 2, charIndex + 3);
      // normals
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      // uvs
      uvs.push(charInfo.tl.x, charInfo.tl.y);
      uvs.push(charInfo.br.x, charInfo.tl.y);
      uvs.push(charInfo.tl.x, charInfo.br.y);
      uvs.push(charInfo.br.x, charInfo.br.y);
      lastX += charWidth;
      charIndex += 4;
    }
    // add other vertex data to capacity
    for (let i = text.length; i < capacity; i++) {
      // positions
      positions.push(0, 0, 0);
      positions.push(0, 0, 0);
      positions.push(0, 0, 0);
      positions.push(0, 0, 0);
      // indices
      indices.push(charIndex + 0, charIndex + 3, charIndex + 1);
      indices.push(charIndex + 0, charIndex + 2, charIndex + 3);
      // normals
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      // uvs
      uvs.push(0, 0);
      uvs.push(0, 0);
      uvs.push(0, 0);
      uvs.push(0, 0);
      charIndex += 4;
    }
    const vertexData = new VertexData();
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.normals = normals;
    vertexData.uvs = uvs;
    return vertexData;
  }
  calculateTextSize(text) {
    let height = 0;
    let width = 0;
    for (const char of text) {
      const charInfo = this.glFontTexture.fontTexture.getCharInfo(char);
      height = Math.max(height, charInfo.h / this.glFontTexture.fontTexture.sampling);
      width += charInfo.w / this.glFontTexture.fontTexture.sampling;
    }
    return {
      width,
      height
    };
  }
  updateMeshInfoText(text) {
    if (this.meshInfo == null) {
      throw new Error('this.meshInfo should be set at this point');
    }
    if (this.text == null) {
      throw new Error('this.text should be set at this point');
    }
    text = text ?? '';
    if (this.meshInfo.text != text) {
      const textSize = this.calculateTextSize(text);
      if (this.meshInfo.capacity >= this.text.length) {
        this.updateMeshText(this.meshInfo.frontMesh, text, textSize.width, textSize.height);
        this.updateMeshText(this.meshInfo.backMesh, text, textSize.width, textSize.height);
      } else {
        if (this.meshInfo.capacity <= 0) {
          this.meshInfo.capacity = this.minStartCapacity;
        }
        while (this.meshInfo.capacity < text.length) {
          this.meshInfo.capacity *= 2;
        }
        this.updateMeshCapacity(this.meshInfo.capacity, this.meshInfo.frontMesh, text, textSize.width, textSize.height);
        this.updateMeshCapacity(this.meshInfo.capacity, this.meshInfo.backMesh, text, textSize.width, textSize.height);
      }
      this.meshInfo.text = text;
      this.meshInfo.width = textSize.width;
      this.meshInfo.height = textSize.height;
      this.meshInfo.mesh.name = `Text "${text}"`;
    }
  }
  updateMeshText(mesh, text, width, height) {
    const positions = mesh.getVerticesData(VertexBuffer.PositionKind);
    const uvs = mesh.getVerticesData(VertexBuffer.UVKind);
    const bottomY = -height / 2;
    const leftX = -width / 2;
    let lastX = 0;
    // add text
    let char;
    let charInfo;
    let charTopY;
    let charLeftX;
    let charWidth;
    for (let i = 0, pi = 0, uvi = 0; i < text.length; i++, pi += 12, uvi += 8) {
      char = text[i];
      charInfo = this.getFontTextureChar(char);
      charTopY = bottomY + charInfo.h / this.glFontTexture.fontTexture.sampling;
      charLeftX = leftX + lastX;
      charWidth = charInfo.w / this.glFontTexture.fontTexture.sampling;
      // positions
      positions[pi] = charLeftX;
      positions[pi + 1] = charTopY;
      positions[pi + 3] = charLeftX + charWidth;
      positions[pi + 4] = charTopY;
      positions[pi + 6] = charLeftX;
      positions[pi + 7] = bottomY;
      positions[pi + 9] = charLeftX + charWidth;
      positions[pi + 10] = bottomY;
      // uvs
      uvs[uvi] = charInfo.tl.x;
      uvs[uvi + 1] = charInfo.tl.y;
      uvs[uvi + 2] = charInfo.br.x;
      uvs[uvi + 3] = charInfo.tl.y;
      uvs[uvi + 4] = charInfo.tl.x;
      uvs[uvi + 5] = charInfo.br.y;
      uvs[uvi + 6] = charInfo.br.x;
      uvs[uvi + 7] = charInfo.br.y;
      lastX += charWidth;
    }
    mesh.updateVerticesData(VertexBuffer.PositionKind, positions, false, false);
    mesh.updateVerticesData(VertexBuffer.UVKind, uvs, false, false);
    mesh.releaseSubMeshes();
    new SubMesh(0, 0, text.length * 4, 0, text.length * 6, mesh, undefined, true);
    mesh.setBoundingInfo(mesh.subMeshes[0].getBoundingInfo());
    mesh.position.y = height * Text2D.offsetY;
  }
  updateMeshCapacity(capacity, mesh, text, width, height) {
    const positions = [];
    const indices = [];
    const normals = [];
    const uvs = [];
    const bottomY = -height / 2;
    const leftX = -width / 2;
    let lastX = 0;
    // add text
    let charInfo;
    let charTopY;
    let charLeftX;
    let charWidth;
    let charIndex = 0;
    for (const char of text) {
      charInfo = this.getFontTextureChar(char);
      charTopY = bottomY + charInfo.h / this.glFontTexture.fontTexture.sampling;
      charLeftX = leftX + lastX;
      charWidth = charInfo.w / this.glFontTexture.fontTexture.sampling;
      // positions
      positions.push(charLeftX, charTopY, 0);
      positions.push(charLeftX + charWidth, charTopY, 0);
      positions.push(charLeftX, bottomY, 0);
      positions.push(charLeftX + charWidth, bottomY, 0);
      // indices
      indices.push(charIndex + 0, charIndex + 3, charIndex + 1);
      indices.push(charIndex + 0, charIndex + 2, charIndex + 3);
      // normals
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      // uvs
      uvs.push(charInfo.tl.x, charInfo.tl.y);
      uvs.push(charInfo.br.x, charInfo.tl.y);
      uvs.push(charInfo.tl.x, charInfo.br.y);
      uvs.push(charInfo.br.x, charInfo.br.y);
      lastX += charWidth;
      charIndex += 4;
    }
    // add other vertex data to capacity
    for (let i = text.length; i < capacity; i++) {
      // positions
      positions.push(0, 0, 0);
      positions.push(0, 0, 0);
      positions.push(0, 0, 0);
      positions.push(0, 0, 0);
      // indices
      indices.push(charIndex + 0, charIndex + 3, charIndex + 1);
      indices.push(charIndex + 0, charIndex + 2, charIndex + 3);
      // normals
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      normals.push(0, 0, -1);
      // uvs
      uvs.push(0, 0);
      uvs.push(0, 0);
      uvs.push(0, 0);
      uvs.push(0, 0);
      charIndex += 4;
    }
    mesh.setIndices(indices);
    mesh.setVerticesData(VertexBuffer.UVKind, uvs, true);
    mesh.setVerticesData(VertexBuffer.NormalKind, uvs);
    mesh.setVerticesData(VertexBuffer.PositionKind, positions, true);
    mesh.releaseSubMeshes();
    new SubMesh(0, 0, text.length * 4, 0, text.length * 6, mesh, undefined, true);
    mesh.setBoundingInfo(mesh.subMeshes[0].getBoundingInfo());
    mesh.position.y = height * Text2D.offsetY;
  }
}
