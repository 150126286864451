import { CodeList } from './code-list';
import { LoadTypeCategoryEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class LoadTypeCategory extends CodeList {

    public static fromService(codeList: LoadTypeCategoryEntityC2C) {
        return new LoadTypeCategory(CodeList.baseFromService('LoadTypeCategoryEntity', codeList));
    }

    public override cloneDeep(): LoadTypeCategory {
        return super.cloneDeep() as LoadTypeCategory;
    }
}
