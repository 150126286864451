import { ChangeDetectorRef, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    AppSettingsNumericTextBoxProps, QuickStartType, SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import {
    format, formatKeyValue
} from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { IAppSettingsComponent } from '@profis-engineering/pe-ui-common/entities/app-settings-component';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { Region } from '../../../shared/entities/code-lists/region';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    DesignStandard as DesignStandardEnum, DesignType, SteelGuideline as SteelCalculationMethod
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    FactorType, IMetalDeckFixing
} from '../../../shared/helpers/app-settings-helper';
import {
    findSteelCalculationMethod, getAllowedSteelGuidelines
} from '../../../shared/helpers/steel-guideline-helper';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { AppSettingsHelperWithUnit } from '../../helpers/app-settings-helper';
import { AbpService } from '../../services/abp.service';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    templateUrl: './app-settings-comd-fixing.component.html',
    styleUrls: ['./app-settings-comd-fixing.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AppSettingsComdFixingComponent implements IAppSettingsComponent<IMetalDeckFixing>, OnChanges {
    @Input()
    public selectedRegionId!: number;

    @Input()
    public decimalSeparatorId!: number;

    @Input()
    public thousandsSeparatorId!: number;

    @Input()
    public submitted = false;

    @Input()
    public settings: IMetalDeckFixing = {
        metalDeckRegion: KnownRegion.Unknown
    };

    public get metalDeckFixing() {
        return this.settings;
    }

    public metalDeckRegionDropdown!: DropdownProps<number>;
    public metalDeckLengthDropdown!: DropdownProps<number>;
    public metalDeckAreaDropdown!: DropdownProps<number>;
    public metalDeckStressDropdown!: DropdownProps<number>;
    public metalDeckForceDropdown!: DropdownProps<number>;
    public metalDeckMomentDropdown!: DropdownProps<number>;
    public metalDeckTemperatureDropdown!: DropdownProps<number>;
    public metalDeckForcePerLengthDropdown!: DropdownProps<number>;
    public metalDeckMomentPerLengthDropdown!: DropdownProps<number>;
    public metalDeckDensityDropdown!: DropdownProps<number>;
    public metalDeckAnchorPlateFactor!: AppSettingsNumericTextBoxProps;
    public metalDeckDesignStandardDropdown!: DropdownProps<number>;
    public metalDeckDesignMethodDropdown!: DropdownProps<number>;
    public metalDeckSteelCalculationMethodDropdown!: DropdownProps<SteelCalculationMethod>;
    public metalDeckMinimumAnchorToProfileDistance!: AppSettingsNumericTextBoxProps;
    public metalDeckMaterialSafetyFactor!: AppSettingsNumericTextBoxProps;

    public dataLoaded = false;

    private readonly regionDefaultValues!: {
        materialSafetyFactor?: number;
    };

    private readonly appSettingsHelper!: AppSettingsHelperWithUnit;

    constructor(
        private readonly codeList: CodeListService,
        private readonly unit: UnitService,
        private readonly localization: LocalizationService,
        private readonly userSettings: UserSettingsService,
        private readonly abp: AbpService,
        private readonly numberService: NumberService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly commonCodeList: CommonCodeListService,
        sharedEnvironmentService: SharedEnvironmentService
    ) {
        this.regionDefaultValues = { materialSafetyFactor: undefined };
        this.appSettingsHelper = new AppSettingsHelperWithUnit(this.localization, this.userSettings, this.codeList, this.commonCodeList, this.unit, this.numberService, sharedEnvironmentService.data?.useDevFeatures ?? false);
    }

    public get selectedMetalDeckDesignStandardDescription() {
        return this.appSettingsHelper.getDesignStandardDescription(this.localization, DesignType.MetalDeck, this.metalDeckDesignStandard);
    }

    public get selectedMetalDeckDesignMethodDescription() {
        return this.appSettingsHelper.getDesignMethodDescription(this.metalDeckDesignMethodGroup);
    }

    public get allowMetalDeckStatus() {
        let translation = '';

        if (this.allowMetalDeckQuickStart) {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MetalDeck.Status.Ok');
            translation = format(translation, this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.None'));
        }
        else {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MetalDeck.Status.Error');
            const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);
            translation = formatKeyValue(translation, { region: region.getTranslatedNameText(getCodeListTextDeps(this.localization, this.numberService)) ?? '' });
        }

        return translation;
    }

    public get getNumericTextBoxDecimalSeparator() {
        return this.appSettingsHelper.getNumericTextBoxDecimalSeparatorById(this.decimalSeparatorId);
    }

    public get getNumericTextBoxGroupSeparator() {
        return this.appSettingsHelper.getNumericTextBoxGroupSeparatorById(this.thousandsSeparatorId);
    }

    public get allowMetalDeckQuickStart() {
        const nonDefaultValue = this.metalDeckFixing.metalDeckRegion < 0
            ? this.selectedRegionId
            : this.metalDeckFixing.metalDeckRegion;

        return this.appSettingsHelper.designTypeMetalDeck?.allowedDesignStandardRegions?.some((row) => row.RegionId == nonDefaultValue);
    }

    public get isMetalDeckNone() {
        return this.metalDeckFixing.metalDeckRegion != SpecialRegion.None;
    }

    public get metalDeckDesignStandardAiscBased() {
        return this.metalDeckFixing.metalDeckSteelCalculationMethod == SteelCalculationMethod.ACI;
    }

    public get isAdvancedCalculationPossible() {
        const regionId = this.metalDeckFixing.metalDeckRegion == SpecialRegion.Default
            ? this.selectedRegionId
            : this.metalDeckFixing.metalDeckRegion;

        return this.abp.isAdvancedCalculationPossibleById(
            DesignType.MetalDeck,
            this.metalDeckFixing.metalDeckDesignStandardId ?? 0,
            regionId
        );
    }

    private get metalDeckDesignStandard() {
        return this.appSettingsHelper.getDesignStandardById(this.metalDeckFixing.metalDeckDesignStandardId);
    }

    private get metalDeckDesignMethodGroup() {
        return this.appSettingsHelper.getDesignMethodGroupById(this.metalDeckFixing.metalDeckDesignMethodGroupId);
    }

    ngOnChanges(): void {
        if (!this.dataLoaded) {
            this.createControlsGeneral();
            this.createControlsRegion();

            this.createControlsCalculation();

            this.updateQuickStartMetalDeck(
                this.metalDeckFixing.metalDeckRegion == SpecialRegion.Default
                    ? this.selectedRegionId ?? KnownRegion.Unknown
                    : this.metalDeckFixing.metalDeckRegion,
                false
            );

            this.dataLoaded = true;
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public getNumericTextBoxUnit(property: string) {
        const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);

        switch (property) {
            case 'defaultAnchorPlateThickness':
            case 'defaultMinimumAnchorToProfileDistance':
            case 'defaultMinimumConcreteCover':
                return region.defaultUnitLength;

            case 'metalDeckMinimumAnchorToProfileDistance':
                return this.metalDeckFixing.metalDeckLength;

            default:
                return Unit.None;
        }
    }

    public getNumericTextBoxPlaceholder(property: string): number | string {
        const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);

        switch (property) {
            case 'defaultAnchorPlateThickness':
                return region.thickness;

            case 'defaultVariableLoads':
                return region.safetyFactorForVariableLoads;

            case 'defaultPermanentLoads':
                return region.safetyFactorForPermamentLoads;

            case 'defaultAnchorPlateFactor':
                return region.anchorPlateFactor;

            case 'metalDeckMinimumAnchorToProfileDistance':
                return this.appSettingsHelper.bindMinimumAnchorToProfileDistanceDefault(this.metalDeckFixing.metalDeckDesignStandardId, this.metalDeckFixing.metalDeckLength);

            case 'metalDeckAnchorPlateFactor':
                return this.appSettingsHelper.bindDefaultFactor(this.metalDeckFixing.metalDeckRegion, region, FactorType.AnchorPlateFactor, this.userSettings.settings);

            case 'metalDeckMaterialSafetyFactor':
                return this.appSettingsHelper.setSteelCalculationPlaceholder(this.regionDefaultValues.materialSafetyFactor ?? PropertyMetaData.Option_MaterialSafetyFactor.defaultValue ?? 0);

            case 'steelCapacityFactor':
            case 'concreteSteelCapacityFactor':
                return this.appSettingsHelper.setSteelCalculationPlaceholder(PropertyMetaData.Option_SteelCapacityFactor.defaultValue ?? 0);

            default:
                return this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }
    }

    public isMinimumAnchorToProfileDistanceAvailable(designStandard: DesignStandardEnum) {
        return this.appSettingsHelper.isMinimumAnchorToProfileDistanceAvailable(designStandard);
    }

    public onMetalDeckDesignStandardChange(designStandardId: number) {
        this.metalDeckFixing.metalDeckDesignStandardId = designStandardId;

        // design standard
        this.modifyMetalDeckDesignStandardDropdown();

        // design method
        this.modifyMetalDeckDesignMethodDropdown();

        const metalDeckRegionId = this.metalDeckFixing.metalDeckRegion == SpecialRegion.Default
            ? this.selectedRegionId
            : this.metalDeckFixing.metalDeckRegion;
        const metalDeckRegion = this.appSettingsHelper.getRegionById(metalDeckRegionId);
        this.setMetalDeckSteelCalculationMethod(designStandardId, metalDeckRegion.id ?? KnownRegion.Unknown);

        this.changeDetectorRef.detectChanges();
    }

    public onMetalDeckDesignMethodChange(designMethodGroupId: number) {
        this.metalDeckFixing.metalDeckDesignMethodGroupId = designMethodGroupId;
    }

    public onMetalDeckRegionDropdownChange(regionId: number) {
        this.metalDeckFixing.metalDeckRegion = regionId;
        this.updateQuickStartMetalDeck(regionId, true);

        this.changeDetectorRef.detectChanges();
    }

    private setMetalDeckMaterialSafetyFactorValues(minValue: number) {
        this.metalDeckMaterialSafetyFactor.minValue = minValue;

        if (this.metalDeckMaterialSafetyFactor.value != null &&
            this.metalDeckMaterialSafetyFactor.value < minValue) {
            this.metalDeckMaterialSafetyFactor.value = minValue;
        }
    }

    private setMetalDeckSteelCalculationMethod(designStandardId: number, regionId: number, steelGuideline?: SteelCalculationMethod) {
        if (!this.allowMetalDeckQuickStart) {
            return;
        }

        const region = this.appSettingsHelper.getRegionById(regionId);

        if (steelGuideline == null) {
            steelGuideline = findSteelCalculationMethod(this.codeList.projectCodeLists, designStandardId, region);
        }

        if (this.metalDeckSteelCalculationMethodDropdown) {
            const steelGuidelines = getAllowedSteelGuidelines(this.codeList.projectCodeLists, designStandardId, regionId);
            const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
            this.metalDeckSteelCalculationMethodDropdown.items = steelGuidelines.map(sg => {
                return {
                    value: sg.id as SteelCalculationMethod,
                    text: sg.getTranslatedNameText(codeListDeps)
                } as DropdownItem<number>;
            });

            this.metalDeckSteelCalculationMethodDropdown.disabled = steelGuidelines.length <= 1;
            this.metalDeckSteelCalculationMethodDropdown.selectedValue = steelGuideline;
            this.metalDeckFixing.metalDeckSteelCalculationMethod = steelGuideline;
        }
    }

    private updateQuickStartMetalDeck(regionId: number, regionChanged: boolean) {
        if (regionId != SpecialRegion.None || this.metalDeckFixing.metalDeckRegion == null) {
            regionId = regionId < 0
                ? this.selectedRegionId ?? KnownRegion.Unknown
                : regionId;
            const region = this.appSettingsHelper.getRegionById(regionId);

            if (regionChanged) {
                this.appSettingsHelper.setDefaultMetalDeckUnits(this.metalDeckFixing, region);
                this.appSettingsHelper.setDefaultMetalDeckDesignStandard(this.metalDeckFixing, region);
                this.appSettingsHelper.setDefaultMetalDeckDesignMethodGroup(this.metalDeckFixing, region);

                this.onMetalDeckDesignStandardChange(this.metalDeckFixing.metalDeckDesignStandardId ?? DesignStandardEnum.Unknown);
                this.setMetalDeckSteelCalculationMethod(this.metalDeckFixing.metalDeckDesignStandardId ?? DesignStandardEnum.Unknown, regionId);
            }

            this.setMaterialSafetyFactorValues(region);
        }
    }

    private setMaterialSafetyFactorValues(region: Region) {
        // TODO: get min/max from DB.
        // min/max
        const minValue = this.appSettingsHelper.getMaterialSafetyFactorMinValue(region.id ?? KnownRegion.Unknown) ?? 0;
        this.setMetalDeckMaterialSafetyFactorValues(minValue);

        // set region specific placeholder
        this.regionDefaultValues.materialSafetyFactor = region.defaultMaterialSafetyFactor;
    }

    private modifyMetalDeckDesignStandardDropdown() {
        const metalDeckDesignStandards = this.appSettingsHelper.getDesignStandardsByDesignType(this.appSettingsHelper.designTypeMetalDeck, this.metalDeckFixing.metalDeckRegion, this.selectedRegionId);

        this.metalDeckDesignStandardDropdown.items = metalDeckDesignStandards.map(designStandard => ({
            value: designStandard.id,
            text: this.appSettingsHelper.getDesignStandardTitle(this.localization, DesignType.MetalDeck, designStandard),
        }) as DropdownItem<number>);

        this.metalDeckDesignStandardDropdown.selectedValue = this.metalDeckFixing.metalDeckDesignStandardId;
    }

    private modifyMetalDeckDesignMethodDropdown(): void {
        const designMethodGroups = this.appSettingsHelper.getDesignMethodGroupsByDesignType(
            this.appSettingsHelper.designTypeMetalDeck,
            this.metalDeckFixing.metalDeckDesignStandardId,
            this.metalDeckFixing.metalDeckRegion,
            this.selectedRegionId);

        if (!designMethodGroups.some(designMethodGroup => designMethodGroup.id == this.metalDeckFixing.metalDeckDesignMethodGroupId)) {
            const metalDeckRegionId = this.metalDeckFixing.metalDeckRegion < 0
                ? this.selectedRegionId ?? KnownRegion.Unknown
                : this.metalDeckFixing.metalDeckRegion;
            const metalDeckRegion = this.appSettingsHelper.getRegionById(metalDeckRegionId);

            this.appSettingsHelper.setDefaultMetalDeckDesignMethodGroup(this.metalDeckFixing, metalDeckRegion);
        }

        this.metalDeckDesignMethodDropdown.items = designMethodGroups.map((designMethodGroup) => ({
            value: designMethodGroup.id,
            text: this.appSettingsHelper.getDesignMethodGroupTitle(designMethodGroup),
        }) as DropdownItem<number>);

        this.metalDeckDesignMethodDropdown.selectedValue = this.metalDeckFixing.metalDeckDesignMethodGroupId;
    }

    private createControlsRegion() {
        this.metalDeckRegionDropdown = this.appSettingsHelper.createDropdownComponent(
            'metal-deck-region',
            'MetalDeck.Region',
            this.appSettingsHelper.getAvailableRegions(this.appSettingsHelper.designTypeMetalDeck)
                .map(region => ({
                    value: region.id,
                    text: this.localization.getString(region.nameResourceKey)
                }) as DropdownItem<number>)
        );
    }

    private createControlsGeneral() {
        this.createControlsUnit();

        this.metalDeckAnchorPlateFactor = this.appSettingsHelper.createAnchorPlateFactorComponent('metal-deck-anchor-plate-factor', 'MetalDeck.AnchorPlateFactor');
        this.metalDeckAnchorPlateFactor.tooltip = {
            title: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip.Title'),
            content: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip')
        };

        this.metalDeckMaterialSafetyFactor = this.appSettingsHelper.createSteelCalculationComponent(
            QuickStartType.MetalDeck, 'materialSafetyFactor', PropertyMetaData.Option_MaterialSafetyFactor);

        this.metalDeckMinimumAnchorToProfileDistance = this.appSettingsHelper.createMinimumAnchorToProfileDistanceComponent('-metal-deck');
    }

    private createControlsUnit() {
        this.metalDeckForcePerLengthDropdown = this.appSettingsHelper.createUnitDropdown('metal-deck-force-per-length', 'MetalDeck.ForcePerLength', CommonCodeList.UnitForcePerLength);
        this.metalDeckMomentPerLengthDropdown = this.appSettingsHelper.createUnitDropdown('metal-deck-moment-per-length', 'MetalDeck.MomentPerLength', CommonCodeList.UnitMomentPerLength);
        this.metalDeckLengthDropdown = this.appSettingsHelper.createUnitDropdown('metal-deck-length', 'MetalDeck.Length', CommonCodeList.UnitLength);
        this.metalDeckAreaDropdown = this.appSettingsHelper.createUnitDropdown('metal-deck-area', 'MetalDeck.Area', CommonCodeList.UnitArea);
        this.metalDeckStressDropdown = this.appSettingsHelper.createUnitDropdown('metal-deck-stress', 'MetalDeck.Stress', CommonCodeList.UnitStress);
        this.metalDeckForceDropdown = this.appSettingsHelper.createUnitDropdown('metal-deck-force', 'MetalDeck.Force', CommonCodeList.UnitForce);
        this.metalDeckMomentDropdown = this.appSettingsHelper.createUnitDropdown('metal-deck-moment', 'MetalDeck.Moment', CommonCodeList.UnitMoment);
        this.metalDeckTemperatureDropdown = this.appSettingsHelper.createUnitDropdown('metal-deck-temperature', 'MetalDeck.Temperature', CommonCodeList.UnitTemperature);
        this.metalDeckDensityDropdown = this.appSettingsHelper.createUnitDropdown('metal-deck-density', 'MetalDeck.Density', CommonCodeList.UnitDensity);

        const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);
        this.metalDeckFixing.metalDeckForcePerLength = this.appSettingsHelper.ensureUnitSupported(this.metalDeckForcePerLengthDropdown.items, this.metalDeckFixing.metalDeckForcePerLength, region.defaultUnitForcePerLength);
        this.metalDeckFixing.metalDeckMomentPerLength = this.appSettingsHelper.ensureUnitSupported(this.metalDeckMomentPerLengthDropdown.items, this.metalDeckFixing.metalDeckMomentPerLength, region.defaultUnitMomentPerLength);
        this.metalDeckFixing.metalDeckLength = this.appSettingsHelper.ensureUnitSupported(this.metalDeckLengthDropdown.items, this.metalDeckFixing.metalDeckLength, region.defaultUnitLength);
        this.metalDeckFixing.metalDeckArea = this.appSettingsHelper.ensureUnitSupported(this.metalDeckAreaDropdown.items, this.metalDeckFixing.metalDeckArea, region.defaultUnitArea);
        this.metalDeckFixing.metalDeckStress = this.appSettingsHelper.ensureUnitSupported(this.metalDeckStressDropdown.items, this.metalDeckFixing.metalDeckStress, region.defaultUnitStress);
        this.metalDeckFixing.metalDeckForce = this.appSettingsHelper.ensureUnitSupported(this.metalDeckForceDropdown.items, this.metalDeckFixing.metalDeckForce, region.defaultUnitForce);
        this.metalDeckFixing.metalDeckMoment = this.appSettingsHelper.ensureUnitSupported(this.metalDeckMomentDropdown.items, this.metalDeckFixing.metalDeckMoment, region.defaultUnitMoment);
        this.metalDeckFixing.metalDeckTemperature = this.appSettingsHelper.ensureUnitSupported(this.metalDeckTemperatureDropdown.items, this.metalDeckFixing.metalDeckTemperature, region.defaultUnitTemperature);
        this.metalDeckFixing.metalDeckDensity = this.appSettingsHelper.ensureUnitSupported(this.metalDeckDensityDropdown.items, this.metalDeckFixing.metalDeckDensity, region.defaultUnitDensity);

        this.appSettingsHelper.setUnitDropdownValue(this.metalDeckForcePerLengthDropdown, this.metalDeckFixing.metalDeckForcePerLength);
        this.appSettingsHelper.setUnitDropdownValue(this.metalDeckMomentPerLengthDropdown, this.metalDeckFixing.metalDeckMomentPerLength);
        this.appSettingsHelper.setUnitDropdownValue(this.metalDeckLengthDropdown, this.metalDeckFixing.metalDeckLength);
        this.appSettingsHelper.setUnitDropdownValue(this.metalDeckAreaDropdown, this.metalDeckFixing.metalDeckArea);
        this.appSettingsHelper.setUnitDropdownValue(this.metalDeckStressDropdown, this.metalDeckFixing.metalDeckStress);
        this.appSettingsHelper.setUnitDropdownValue(this.metalDeckForceDropdown, this.metalDeckFixing.metalDeckForce);
        this.appSettingsHelper.setUnitDropdownValue(this.metalDeckMomentDropdown, this.metalDeckFixing.metalDeckMoment);
        this.appSettingsHelper.setUnitDropdownValue(this.metalDeckTemperatureDropdown, this.metalDeckFixing.metalDeckTemperature);
        this.appSettingsHelper.setUnitDropdownValue(this.metalDeckDensityDropdown, this.metalDeckFixing.metalDeckDensity);
    }

    private createControlsCalculation() {
        const steelGuideLines = this.codeList.projectCodeLists[ProjectCodeList.SteelGuidelines];

        this.metalDeckSteelCalculationMethodDropdown = this.appSettingsHelper.createDropdownComponent(
            'metal-deck-steel-calculation-method',
            '',
            steelGuideLines.map(steelGuideline => ({
                id: `application-settings-metal-deck-steel-calculation-method-dropdown-item-${steelGuideline.id}`,
                value: steelGuideline.id,
                text: this.localization.getString(steelGuideline.nameResourceKey ?? ''),
            }) as DropdownItem<SteelCalculationMethod>));
        this.metalDeckSteelCalculationMethodDropdown.disabled = true;

        // Metal deck design standard
        this.metalDeckDesignStandardDropdown = this.appSettingsHelper.createDropdownComponent<number>(
            'metal-deck-design-standard-dropdown', 'MetalDeck.DesignStandard');
        this.modifyMetalDeckDesignStandardDropdown();

        // Metal deck design method
        this.metalDeckDesignMethodDropdown = this.appSettingsHelper.createDropdownComponent<number>(
            'metal-deck-design-method-group-dropdown', 'MetalDeck.DesignMethod');
        this.modifyMetalDeckDesignMethodDropdown();

        this.setMetalDeckSteelCalculationMethod(
            this.metalDeckFixing.metalDeckDesignStandardId ?? DesignStandardEnum.Unknown,
            this.metalDeckFixing.metalDeckRegion == SpecialRegion.Default
                ? this.selectedRegionId ?? KnownRegion.Unknown
                : this.metalDeckFixing.metalDeckRegion,
            this.metalDeckFixing.metalDeckSteelCalculationMethod
        );
    }
}
