import {
    LoadDefinitionTypeEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class LoadDefinitionType extends CodeList {

    public static fromService(codeList: LoadDefinitionTypeEntityC2C) {
        return new LoadDefinitionType(CodeList.baseFromService('LoadDefinitionTypeEntityC2C', codeList));
    }

    public override cloneDeep(): LoadDefinitionType {
        return super.cloneDeep() as LoadDefinitionType;
    }

}
