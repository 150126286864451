import { CodeList } from './code-list';
import { CrossSectionEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class CrossSection extends CodeList {

    public static fromService(codeList: CrossSectionEntityC2C) {
        return new CrossSection(CodeList.baseFromService('CrossSectionEntity', codeList, {
            nameResourceKey: undefined
        }));
    }

    public override cloneDeep(): CrossSection {
        return super.cloneDeep() as CrossSection;
    }
}
