<div class="utilization">

    <div class="utilization-info-content" [id]="controlId">
        <div class="info-content">

            <!-- Numeric utilization with header property title -->
            @if (showNumberContainer) {
                <div class="number-container">
                    <div class="details-line">
                        <p class="text" [innerHTML]="title" [id]="getComponentId('header')"></p>

                        @if (hasValue) {
                            <p [class]="numberContainerStyle" [id]="getComponentId('percentage')">{{ value }}</p>
                        }
                    </div>
                </div>
            }

            <!-- Percentage bar -->
            <div class="text-progress-container">
                @if (showPercentBar) {
                    <div [class]="percentageBarStyle">
                        <div class="progress-percent" [style.width.%]="percentValue"></div>
                    </div>
                }
            </div>

        </div>
    </div>

</div>
