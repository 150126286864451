import { CodeList } from './code-list';
import { RowsNumberModeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class RowsNumberMode extends CodeList {

    public static fromService(codeList: RowsNumberModeEntityC2C) {
        return new RowsNumberMode(CodeList.baseFromService('RowsNumberModeEntityC2C', codeList, {
            id: codeList.id,
            tooltipDisplayKey: codeList.tooltipTitleDisplayKey,
            tooltipDisabledDisplayKey: codeList.tooltipDisabledDisplayKey,
            tooltipTitleDisplayKey: codeList.tooltipTitleDisplayKey
        }));
    }

    public override cloneDeep(): RowsNumberMode {
        return super.cloneDeep() as RowsNumberMode;
    }
}
