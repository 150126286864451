import { CameraCache } from './camera-cache.js';
import { CommonCache } from './common-cache.js';
import { EngineCache } from './engine-cache.js';
import { LightCache } from './light-cache.js';
import { MatrixCache } from './matrix-cache.js';
import { SceneCache } from './scene-cache.js';
import { TextCache } from './text-cache.js';
import { TextureCache } from './texture-cache.js';
import { VertexDataCache } from './vertex-data-cache.js';
export class Cache {
  materialCacheType;
  meshCacheType;
  engineCache;
  sceneCache;
  materialCache;
  lightCache;
  cameraCache;
  textureCache;
  matrixCache;
  commonCache;
  vertexDataCache;
  meshCache;
  textCache;
  constructor(engine, materialCacheType, meshCacheType) {
    this.materialCacheType = materialCacheType;
    this.meshCacheType = meshCacheType;
    this.engineCache = new EngineCache(engine);
    this.sceneCache = new SceneCache(this.engineCache);
    this.materialCache = new materialCacheType(this.sceneCache);
    this.lightCache = new LightCache();
    this.cameraCache = new CameraCache();
    this.textureCache = new TextureCache();
    this.matrixCache = new MatrixCache();
    this.commonCache = new CommonCache();
    this.vertexDataCache = new VertexDataCache(this.commonCache, this.matrixCache);
    this.meshCache = new meshCacheType(this.materialCache, this.sceneCache, this.vertexDataCache);
    this.textCache = new TextCache();
  }
  dispose() {
    this.engineCache.dispose();
    this.sceneCache.dispose();
    this.materialCache.dispose();
    this.lightCache.dispose();
    this.cameraCache.dispose();
    this.textureCache.dispose();
    this.matrixCache.dispose();
    this.commonCache.dispose();
    this.vertexDataCache.dispose();
    this.meshCache.dispose();
    this.textCache.dispose();
  }
}
