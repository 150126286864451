import { Subscription } from 'rxjs';
import { randomString } from 'src/common/helpers/random';

import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input,
    OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, ViewChild, ViewEncapsulation
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { customErrorValidator } from '../../../common/validators/custom-error.validator';
import { IValidationAdditionalData, IValidatorFn } from '../../helpers/validation-helper';
import { LocalizationService } from '../../services/localization.service';
import { ngbTooltipTemplate, Tooltip } from '../content-tooltip/content-tooltip.common';
import { ControlTitleLook, InfoPopup } from '../control-title/control-title.common';
import {
    DropDownAlignment, DropdownBorder, DropdownItem, DropdownLook, DropdownTag
} from '../dropdown/dropdown.common';

const keyUp = 'Up';
const keyArrowUp = 'ArrowUp';
const keyDown = 'Down';
const keyArrowDown = 'ArrowDown';
const keyEnter = 'Enter';
const keyTab = 'Tab';
const keySpace = ' ';

@Component({
    templateUrl: './multiselect-dropdown.component.html',
    styleUrls: ['./multiselect-dropdown.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MultiselectDropdownComponent<TValue> implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    public isHiltiStyle = false;
    public controlTitleStyle = ControlTitleLook.Normal;
    public ngbTooltipTemplate = ngbTooltipTemplate;

    // Internal
    @ViewChild('controlContainer')
    controlContainerElement!: ElementRef;

    @ViewChild('dropdownItems')
    dropdownItemsElement?: ElementRef;

    @ViewChild('dropdownButton')
    buttonElement?: ElementRef;

    @ViewChild('ngModel')
    public ngModel?: NgModel;

    @Input()
    public id = randomString(8);

    @Input()
    public title?: string;

    @Input()
    public items?: DropdownItem<TValue>[];

    @Input()
    public disabled = false;

    @Input()
    public allowSelectedAll = true;

    @Input()
    public selectedValues: TValue[] | undefined;

    @Output()
    public selectedValuesChange = new EventEmitter<any>();

    @Input()
    public notSelectedText?: string;

    @Input()
    public selectAllText?: string;

    @Input()
    public tooltip?: Tooltip;

    @Input()
    public infoPopup?: InfoPopup;

    @Input()
    public infoPopupTooltip?: Tooltip;

    @Input()
    public submitted = false;

    @Output()
    public submittedChange = new EventEmitter<any>();

    // Look
    @Input()
    public borderTop = DropdownBorder.Normal;

    @Input()
    public borderBottom = DropdownBorder.Normal;

    @Input()
    public itemsMaxHeight = 336;

    @Input()
    public look = DropdownLook.Normal;

    @Input()
    public alignment = DropDownAlignment.Left;

    @Input()
    public openUp = false;

    @Input()
    public tags?: DropdownTag[];

    // Validation
    @Input()
    public validators?: IValidatorFn[];

    @Input()
    public validationData?: IValidationAdditionalData;

    @Input()
    public defaultSelectedText = '';

    @Output()
    public isValid = new EventEmitter<boolean>();

    public additionalClasses = '';
    public selectedItem?: DropdownItem<TValue>;
    public isAllSelected?: boolean;
    public options = new Array<DropdownItem<TValue>>;
    public readonly allOptionValue = 99999999;
    private opened = false;
    private selectedItems = new Array<DropdownItem<TValue>>();
    private searchText?: string;
    private searchTimeout?: number;
    private form: NgForm;
    private ngSubmitSubscription?: Subscription;

    constructor(
        private localizationService: LocalizationService,
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() form: NgForm
    ) {
        this.form = form;
    }

    public get isOpen() {
        return this.opened;
    }

    public get text() {
        const selectedItems = this.getSelectedItems();

        if (selectedItems?.length) {
            const [firstItem] = selectedItems;
            let spanText = '';
            if(this.defaultSelectedText.length > 0){
                spanText = '<span class="label-text">' + this.defaultSelectedText + '</span>';
            }
            else {
                spanText = '<span class="label-text">' + firstItem.text + '</span>' +
                (
                    selectedItems?.length > 1 ?
                        '<span class="chip">' + '+' + (selectedItems?.length - 1) + '</span>' : ''
                );
            }

            return (spanText);
        }
        else {
            return this.notSelectedText ? this.notSelectedText :
                this.localizationService.getString('Agito.Hilti.Profis3.Dropdown.NoneSelected');
        }
    }

    public get showValidationErrors() {
        if (!this.validationData?.showValidationErrors) {
            return false;
        }

        if (
            this.ngModel == null
            || this.ngModel.disabled
            || this.ngModel.valid
            || !this.ngModel.touched && !this.submitted
        ) {
            return false;
        }

        return true;
    }

    ngOnInit(): void {
        this.opened = false;
        this.selectedItems = this.getSelectedItems();
    }

    ngAfterViewInit(): void {
        // add control to the form and sync submitted
        if (this.form != null) {
            if (this.ngModel != null) {
                this.form.addControl(this.ngModel);
            }

            this.ngSubmitSubscription = this.form.ngSubmit.subscribe(() => {
                if (this.submitted != this.form.submitted) {
                    this.submitted = this.form.submitted;
                    this.submittedChange.emit(this.submitted);
                }
            });
        }

        this.synchronizeNgModel();
        this.updateValidations();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['validationData'] != null || changes['validators'] != null
        ) {
            this.updateValidations();
        }

        this.isHiltiStyle = this.look == DropdownLook.HiltiStyled;
        this.controlTitleStyle = this.isHiltiStyle
            ? ControlTitleLook.HiltiStyled
            : ControlTitleLook.Normal;

        // CSS classes
        const cssClasses: string[] = [];

        switch (this.borderTop) {
            case DropdownBorder.None:
                cssClasses.push('no-top-border');
                break;

            case DropdownBorder.Normal:
            default:
                break;
        }

        switch (this.borderBottom) {
            case DropdownBorder.None:
                cssClasses.push('no-bottom-border');
                break;

            case DropdownBorder.Normal:
            default:
                break;
        }

        this.additionalClasses = cssClasses.join(' ');
        this.prepareOptions();
    }

    private prepareOptions() {
        this.options = [];
        if(this.allowSelectedAll){
            const selectAllText = this.selectAllText
            ? this.selectAllText
            : this.localizationService.getString('Agito.Hilti.Profis3.MultiSelectDropdown.SelectAll');

            const allCheckbox = {
                id: this.id + `-all-option`,
                value: this.allOptionValue,
                text: selectAllText
            } as DropdownItem<TValue>;

            this.options?.push(allCheckbox);
        }

        if (this.items) {
            this.options?.push(...this.items);
        }
    }

    public ngOnDestroy(): void {
        // remove control from form and unsubscribe from ngSubmit
        if (this.form != null) {
            if (this.ngModel != null) {
                this.form.removeControl(this.ngModel);
            }

            this.ngSubmitSubscription?.unsubscribe();
        }
    }

    @HostListener('document:click', ['$event.composedPath()[0]'])
    clickedOut(target: HTMLElement) {
        const dropDownContainer = this.controlContainerElement.nativeElement as HTMLElement;
        if (!dropDownContainer.contains(target) && this.opened) {
            this.opened = false;
            this.searchTimeout = undefined;
        }
    }

    public itemSelected(item: DropdownItem<TValue>) {

        if (this.selectedValues) {
            if (item.value == this.allOptionValue) {
                return this.selectedValues?.length > 0 &&
                    this.selectedValues.length == this.items?.filter(x => !x.disabled).length;
            }

            if (this.selectedItems) {
                return this.selectedValues?.includes(item.value);
            }
        }

        return false;
    }

    public onClick() {
        if (this.items != null && this.items.length > 0) {
            this.opened = !this.opened;

            if (this.opened) {
                this.searchText = '';
                this.selectedItems = this.getSelectedItems();

                if (this.selectedItem != null) {
                    setTimeout(() => this.scrollIntoView(this.selectedItem as TValue), 100);
                }
            }
            else if (this.ngModel) {
                this.ngModel.control.markAsTouched();
            }
        }
    }

    public onKeyPress(event: KeyboardEvent) {
        if (!this.opened) {
            return;
        }

        event.preventDefault();

        if (this.items != null && this.items.length > 0) {
            const pressedKey = event.key;

            if (this.searchTimeout != null) {
                window.clearTimeout(this.searchTimeout);
            }

            this.searchTimeout = window.setTimeout(() => {
                this.searchTimeout = undefined;
                this.searchText = '';
            }, 1000);

            this.searchText += pressedKey;

            const itemToSelect = this.items.find(item => item.text.substring(0, this.searchText?.length).toLowerCase() == this.searchText?.toLowerCase()) as DropdownItem<TValue>;
            if (itemToSelect != null) {
                this.updateSelectedItem(itemToSelect);
            }
        }
    }

    public onKeyDown(event: KeyboardEvent) {
        if (!this.opened) {
            return;
        }

        if (this.items != null && this.items.length > 0) {
            const eventCode = event.key;

            const selectionKeys = [keyEnter, keyTab, keySpace];
            if (selectionKeys.indexOf(eventCode) > -1) {
                event.preventDefault();
                this.enterTabSpaceKeyEvent();
                return;
            }

            const downSelectionKeys = [keyDown, keyArrowDown];
            if (downSelectionKeys.indexOf(eventCode) > -1) {
                event.preventDefault();
                this.downKeyEvent();
                return;
            }

            const upSelectionKeys = [keyUp, keyArrowUp];
            if (upSelectionKeys.indexOf(eventCode) > -1) {
                event.preventDefault();
                this.upKeyEvent();
            }
        }
    }

    public isOptionDisabled(item: DropdownItem<TValue>) {

        if (item.value == this.allOptionValue) {
            return !this.items?.some(item => !item.disabled);
        }

        return item.disabled;
    }

    public onItemClick(item: DropdownItem<TValue>) {
        this.clearCustomErrors();
        this.selectedItem = item;

        if (!this.selectedValues) {
            this.selectedValues = Array<TValue>();
        }

        if (this.selectedItem.value == this.allOptionValue) {
            this.allSelected();
        }
        else if (!this.selectedItem.disabled) {

            if (this.selectedValues?.includes(this.selectedItem.value)) {
                this.selectedValues = this.selectedValues.filter(item => item !== this.selectedItem?.value);
            }
            else {
                this.selectedValues?.push(this.selectedItem.value);
            }
        }

        this.selectedItems = this.getSelectedItems();
        this.isAllSelected = this.selectedValues?.length == this.items?.filter(x => !x.disabled).length;
        this.selectedValuesChange.emit(this.selectedValues);
    }

    public allSelected() {
        this.isAllSelected = !this.isAllSelected;

        if (this.isAllSelected) {
            this.selectedValues = this.items?.filter(x => !x.disabled).map(x => x.value);
        }
        else {
            this.selectedValues = [];
        }

        this.selectedItems = this.getSelectedItems();
    }

    public isIndeterminate(item: DropdownItem<TValue>) {

        const isIndeterminate =
            (
                this.selectedValues &&
                item.value == this.allOptionValue &&
                this.selectedValues?.length > 0 &&
                this.selectedValues?.length != this.items?.filter(item => !item.disabled).length
            );

        if (isIndeterminate) {
            this.isAllSelected = false;
        }

        return isIndeterminate;
    }

    private updateValidations() {
        if (this.ngModel != null) {
            const validations = [...(this.validators ?? [])];
            if (this.validationData?.customErrorMessage != null) {
                validations.push(customErrorValidator(this.validationData?.customErrorMessage));
            }

            this.ngModel.control.setValidators(validations);
            this.ngModel.control.updateValueAndValidity();
            this.changeDetectorRef.detectChanges();
        }
    }

    private updateSelectedItem(item: DropdownItem<TValue>) {
        if (item == null) {
            return;
        }

        this.selectedItem = item;
        this.scrollIntoView(this.selectedItem.value);
    }

    private getSelectedItems() {
        let selectedItems: DropdownItem<TValue>[] = [];

        if (this.selectedValues && this.items != null) {

            selectedItems = this.items.filter(item => this.selectedValues?.includes(item.value));
        }

        return selectedItems;
    }

    private clearCustomErrors() {
        if (this.validationData?.customErrorMessage) {
            this.validationData.customErrorMessage = undefined;
            this.updateValidations();
        }
    }

    private synchronizeNgModel() {
        if (this.ngModel != null && this.ngModel != undefined) {
            if (this.ngModel.statusChanges) {
                this.ngModel.statusChanges.subscribe(() => {
                    if (this.ngModel?.disabled != null && this.ngModel.valid != undefined) {
                        this.isValid.emit(this.ngModel.disabled || this.ngModel.valid);
                    }
                });
            }
        }
    }

    private enterTabSpaceKeyEvent() {
        if (this.selectedItem != null) {
            this.clearCustomErrors();
            this.onItemClick(this.selectedItem);
        }

        this.ngModel?.control?.markAsTouched();
    }

    private downKeyEvent() {
        if (this.options != null) {
            const selectedIndex = this.selectedItem != null
                ? this.options.findIndex(item => item.value == this.selectedItem?.value)
                : -1;

            let nextItem: DropdownItem<TValue>;
            if (selectedIndex != -1) {
                if (selectedIndex < this.options.length - 1) {
                    nextItem = this.options[selectedIndex + 1];

                    this.updateSelectedItem(nextItem);
                }
            }
            else if (this.items != null && this.items.length > 0) {
                // select first if none is selected
                nextItem = this.items[0];

                this.updateSelectedItem(nextItem);
            }
        }
    }

    private upKeyEvent() {
        if (this.options != null) {
            const selectedIndex = this.selectedItems != null
                ? this.options.findIndex(item => item.value == this.selectedItem?.value)
                : -1;

            let previousItem: DropdownItem<TValue>;
            if (selectedIndex != -1) {
                if (selectedIndex > 0) {
                    previousItem = this.options[selectedIndex - 1];
                    this.updateSelectedItem(previousItem);
                }
            }
            else if (this.options != null && this.options.length > 0) {
                // select first if none is selected
                previousItem = this.options[0];
                this.updateSelectedItem(previousItem);
            }
        }
    }

    private scrollIntoView(value: TValue) {
        const offset = 2;

        if (this.options != null) {
            const selectedValueIndex = this.options.findIndex(i => i.value === value);
            if (selectedValueIndex != -1) {
                const optionsHTMLElement = this.dropdownItemsElement?.nativeElement as HTMLElement;

                const selectedHtmlElement = optionsHTMLElement.querySelectorAll('.dropdown-item')[selectedValueIndex] as HTMLElement;
                const dropdownFullHeight = optionsHTMLElement.scrollHeight;
                const dropdownHeight = optionsHTMLElement.offsetHeight;

                if (dropdownFullHeight > dropdownHeight) {
                    const selectedElementHeight = selectedHtmlElement.offsetHeight;
                    const dropdownTop = optionsHTMLElement.scrollTop;
                    const dropdownBottom = optionsHTMLElement.scrollTop + dropdownHeight;

                    if (selectedHtmlElement.offsetTop < dropdownTop) {
                        optionsHTMLElement.scrollTop = selectedHtmlElement.offsetTop - offset;
                    }
                    else if (selectedHtmlElement.offsetTop + selectedElementHeight + offset * 2 > dropdownBottom) {
                        optionsHTMLElement.scrollTop = selectedHtmlElement.offsetTop - dropdownHeight + selectedElementHeight + offset * 2;
                    }
                }
            }
        }
    }
}
