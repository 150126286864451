import clone from 'lodash-es/clone';
import difference from 'lodash-es/difference';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';
import { Subscription } from 'rxjs';

import {
    AfterViewInit,
    Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit,
    Output, SimpleChanges, TrackByFunction, ViewEncapsulation
} from '@angular/core';
import {
    Tooltip
} from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import {
    DropdownItem, DropdownProps, DropdownTag, TagType
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    ToggleButtonGroupItem, ToggleButtonGroupProps
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import {
    CodeList, getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { DesignEvent } from '@profis-engineering/pe-ui-common/entities/design';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { hasProperty } from '@profis-engineering/pe-ui-common/helpers/object-helper';
import {
    UnitGroup, UnitType as Unit
} from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { AnchorEmbedmentDepth } from '../../../shared/entities/code-lists/anchor-embedment-depth';
import { AnchorFamily } from '../../../shared/entities/code-lists/anchor-family';
import {
    AnchorFilter as AnchorFilterEntity
} from '../../../shared/entities/code-lists/anchor-filter';
import { AnchorFilterGroup } from '../../../shared/entities/code-lists/anchor-filter-group';
import { AnchorSize as AnchorSizeEntity } from '../../../shared/entities/code-lists/anchor-size';
import { AnchorType as AnchorTypeEntity } from '../../../shared/entities/code-lists/anchor-type';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import {
    AnchorFilterGroupOperator as GroupOperator
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists.Enums';
import {
    AnchorFilter, UIProperty
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    CheckboxFilter
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    DesignMethodGroup, DesignStandard, TorquingType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    filterAnchorFilterGroups,
    filterAnchorFilters,
    isAnchorFamilyMarkedAsNew, isAnchorTypeMarkedAsNew
} from '../../../shared/helpers/anchor-helper';
import { DesignMethodGroupHelper } from '../../../shared/helpers/design-method-group-helper';
import { isHnaBasedDesignStandard } from '../../../shared/helpers/design-standard-helper';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { ApprovalHelper } from '../../helpers/approval-helper';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { getSpriteAsIconStyle, includeSprites, Sprite } from '../../sprites';
import { SelectAnchorSortBy } from '../select-anchor/select-anchor.common';

export interface IAnchor {
    id: number;
    image: string;
    name: string;
    tag: string;
    tested: boolean;
    defaultSortOrder: number;
    allowedInAnchorFilters: number[];
    holeDiameters: number[];
    fixtureThicknesMin: number;
    fixtureThicknesMax: number;
    isNew: boolean;
    internalPortfolioOnly: boolean;
    adaptiveTorquing: boolean;
}

interface IFiltersGroupNumber {
    [groupId: number]: number;
}

interface ProductFilterDisplay {
    id: number;
    title?: string;
}

interface AnchorFilterDisplay extends ProductFilterDisplay {
    anchorFilterGroupId?: number;
}

interface ISelectAnchorFilterGroupConstructor {
    groupOperator?: GroupOperator;
    items?: SelectAnchorFilterGroupItem[];
}

class SelectAnchorFilterGroup {
    public groupOperator?: GroupOperator;
    public items: SelectAnchorFilterGroupItem[] = [];

    constructor(ctor?: ISelectAnchorFilterGroupConstructor) {
        if (ctor != null) {
            this.groupOperator = ctor.groupOperator;
            this.items = ctor.items ?? [];
        }
    }
}

interface ISelectAnchorFilterGroupItemConstructor {
    id?: number;
    textKey?: string;
    disabled?: boolean;
    selected?: boolean;
}

class SelectAnchorFilterGroupItem {
    public id?: number;
    public textKey?: string;
    public disabled?: boolean;
    public selected?: boolean;

    constructor(ctor?: ISelectAnchorFilterGroupItemConstructor) {
        if (ctor != null) {
            this.id = ctor.id;
            this.textKey = ctor.textKey;
            this.disabled = ctor.disabled;
            this.selected = ctor.selected;
        }
    }
}

@Component({
    templateUrl: './info-section.component.html',
    styleUrls: ['./info-section.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoSectionComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    static readonly DropdownItemsDefaultMaxHeight = 330;

    @Input()
    public collapsed = false;

    @Input()
    public parentElement?: HTMLElement;

    @Output()
    public collapsedChange = new EventEmitter<boolean>();

    public propertyMetaDataEnum = PropertyMetaData;
    public typeDropdown!: DropdownProps<number>;
    public sizeDropdown!: DropdownProps<number>;
    public embedmentDepthTypeToggleButtonGroup!: ToggleButtonGroupProps<number>;
    public embedmentDepthValueDropdown!: DropdownProps<number>;

    public articleNumberMechanicalTooltip!: Tooltip;
    public articleNumberChemicalTooltip!: Tooltip;
    public articleNumberInsertTooltip!: Tooltip;
    public articleNumberInsertAlternativeTooltip!: Tooltip;
    public articleNumberCapsuleTooltip!: Tooltip;
    public articleNumberSieveSleeveTooltip!: Tooltip;
    public embedmentDepthTooltip!: Tooltip;

    public anchors: IAnchor[] = [];

    public filtersOpened = false;

    public filterButtonTooltip!: string;

    public viewApprovalTranslationKeyUKTA = 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ViewApproval.UKTA';

    private anchorFilterGroups: SelectAnchorFilterGroup[] = [];

    private anchorFilters?: AnchorFilterDisplay[];

    private displayedAnchorFilters: AnchorFilterDisplay[] = [];

    private fixtureThicknesMin?: number;
    private fixtureThicknesMax?: number;
    private holeDiameterMin?: number;
    private holeDiameterMax?: number;

    private adaptiveTorquing?: boolean;

    private anchorsData: IAnchor[] = [];

    private infoSectionHeight!: number;
    private isInfoSectionHeightManuallyResizing!: boolean;
    private allowedValuesChangedFn?: { (): void };
    private initializedSpriteImages: string[] = [];
    private localizationChangeSubscription?: Subscription;
    private userSettingsSavingSubscription?: Subscription;
    private oldAnchorFavorites?: { [anchorId: number]: void } | null;

    public resizeObserver!: ResizeObserver;


    constructor(
        private readonly localization: LocalizationService,
        private readonly user: UserService,
        private readonly userSettingsService: UserSettingsService,
        private readonly unit: UnitService,
        private readonly offline: OfflineService,
        private readonly modal: ModalService,
        private readonly calculationService: CalculationServicePE,
        private readonly numberService: NumberService,
        private readonly featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly ngZone: NgZone
    ) { }


    public get infoSectionContainer() {
        return this.elementRef.nativeElement.shadowRoot?.querySelector('#resize-info-section') as HTMLElement;
    }

    public get productName() {
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        const embedmentDepth = this.embedmentDepth;
        return this.selectedProductId != null && embedmentDepth != null
            ? this.design?.anchorType?.getTranslatedNameText(codeListDeps) + ' ' + this.design?.anchorSize?.name + ' ' + embedmentDepth
            : this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.SelectAnchor.NotSelected');
    }

    public get design() {
        return this.user.design;
    }

    public get embedmentDepthHidden() {
        return this.design?.properties?.get(PropertyMetaData.AnchorLayout_EmbedmentDepthOptimizationType.id)?.hidden
            && this.design?.properties?.get(PropertyMetaData.AnchorLayout_EmbedmentDepthVariable.id)?.hidden
            && this.design?.properties?.get(PropertyMetaData.AnchorLayout_EmbedmentDepthFixedMultiple.id)?.hidden;
    }

    public get typeHidden() {
        return DesignMethodGroupHelper.IsLrfdBased(this.design?.designMethodGroup?.id)
            ? this.design?.properties?.get(PropertyMetaData.AnchorLayout_TypeAC58.id)?.hidden
            : this.design?.properties?.get(PropertyMetaData.AnchorLayout_Type.id)?.hidden;
    }

    public get sizeHidden() {
        return DesignMethodGroupHelper.IsLrfdBased(this.design?.designMethodGroup?.id)
            ? this.design?.properties?.get(PropertyMetaData.AnchorLayout_SizeAC58.id)?.hidden
            : this.design?.properties?.get(PropertyMetaData.AnchorLayout_Size.id)?.hidden;
    }

    public get productHidden() {
        return this.design?.properties?.get(this.design?.globalMetaProperties?.sourceMetaProperty)?.hidden ?? true;
    }

    public get approvalProperty() {
        return this.design?.properties?.get(PropertyMetaData.AnchorLayout_ViewApproval.id);
    }

    public get approvalStoProperty() {
        return this.design?.properties?.get(PropertyMetaData.AnchorLayout_ViewApproval_STO.id);
    }

    public get approvalUktaProperty() {
        return this.design?.properties?.get(PropertyMetaData.AnchorLayout_ViewApproval_UKTA.id);
    }

    public get approvalCSTBProperty() {
        return this.design?.properties?.get(PropertyMetaData.AnchorLayout_ViewCSTBDocument.id);
    }

    public get filtersTitle() {
        return this.displayedAnchorFilters.length + ' ' + this.translate(this.displayedAnchorFilters.length == 1
            ? 'Agito.Hilti.Profis3.InfoSection.AnchorSection.SingleAnchorFilterTitle'
            : 'Agito.Hilti.Profis3.InfoSection.AnchorSection.MultipleAnchorFilterTitle');
    }

    public get displayedFilters() {
        return this.displayedAnchorFilters;
    }

    public get favoriteTooltip() {
        return this.featuresVisibilityInfo.tooltip(Feature.Menu_Favorites)
            || this.translate('Agito.Hilti.Profis3.Main.Region.AddRemoveFromFavorites');
    }

    public get getCalcAllTooltip() {
        return this.design?.isCBFEMCalculation || this.design?.isHandrailCBFEMCalculation
            ? this.translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.CalculateAll.Tooltip.IdeaCalculation')
            : null;
    }

    public get viewApprovalTranslationKey() {
        if (isHnaBasedDesignStandard(this.designStandard)) {
            return 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ViewApproval.HNA';
        }

        if (this.design.isFatigueDesign && this.design.isConcreteTypeSFRC) {
            return 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ViewApproval.SFRC';
        }

        return 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ViewApproval.ETA';
    }

    public get viewCSTBButtonTranslationKey() {
        return 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ViewCSTBDocument';
    }

    public get viewApprovalTranslationKeyRussianBased() {
        if (DesignMethodGroupHelper.isRussianSofaOrSp63(this.designStandard, this.designMethodGroup)) {
            return 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ViewApproval.SP63';
        }

        return 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ViewApproval.STO';
    }

    public get showApprovalButton() {
        return !(this.approvalProperty.hidden || DesignMethodGroupHelper.isRussianSofaOrSp63(this.designStandard, this.designMethodGroup));
    }

    public get typeDropdownTags() {
        const type = this.design?.anchorType;
        const tags: DropdownTag[] = [];

        if (type?.tag != null) {
            tags.push({
                type: TagType.Tag,
                text: this.translate(type.tag)
            });
        }

        if (this.isAnchorTypeMarkedAsNew(type?.id as number)) {
            tags.push({
                type: TagType.New,
                text: this.translate('Agito.Hilti.Profis3.Anchors.New')
            });
        }

        if (type?.internalPortfolioOnly) {
            tags.push({
                type: TagType.Tag,
                text: this.translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio'),
                tooltip: {
                    title: '',
                    content: this.translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio.Tooltip')
                }
            });
        }

        // To prevent Angular exceeding $digest iterations
        // we compare generated array with the one already
        // stored; if there is no change, old array is returned.
        if (!isEqual(this.typeDropdown.tags, tags)) {
            this.typeDropdown.tags = tags;
        }

        return this.typeDropdown.tags;
    }

    public get sizeDropdownTags() {
        const size = this.design?.anchorSize;
        const tags: DropdownTag[] = [];

        if (size?.tag != null) {
            tags.push({
                type: TagType.Tag,
                text: this.translate(size.tag)
            });
        }

        if (size?.internalPortfolioOnlyRegions?.includes(this.design?.region?.id as number)) {
            tags.push({
                type: TagType.Tag,
                text: this.translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio'),
                tooltip: {
                    title: '',
                    content: this.translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio.Tooltip')
                }
            });
        }

        // To prevent Angular exceeding $digest iterations
        // we compare generated array with the one already
        // stored; if there is no change, old array is returned.
        if (!isEqual(this.sizeDropdown.tags, tags)) {
            this.sizeDropdown.tags = tags;
        }

        return this.sizeDropdown.tags;
    }

    public get isCalculateAllDisabled() {
        return this.design?.designData?.reportData?.CalculateAllDisabled
            || this.anchors == null
            || this.anchors.length < 1
            || this.design?.isReadOnlyDesignMode;
    }

    private get embedmentDepth() {
        if (!this.user.design?.properties?.get(PropertyMetaData.AnchorLayout_EmbedmentDepthVariable.id).hidden) {
            const depthProperty = this.design?.model[PropertyMetaData.AnchorLayout_EmbedmentDepthVariable.id];
            const unitValue = this.unit.convertInternalValueToDefaultUnitValue(depthProperty as number, UnitGroup.Length);

            return unitValue == null
                ? depthProperty
                : this.unit.formatUnitValueArgs(unitValue.value, unitValue.unit, undefined, undefined, undefined, UIProperty.AnchorLayout_EmbedmentDepthVariable);
        }

        const depth = this.user?.design?.model[PropertyMetaData.AnchorLayout_EmbedmentDepthFixedMultiple.id];
        return depth == null ? depth : this.getAnchorEmbedmentDepthText(this.getEmbedmentDepthValueCodeListItems().find(item => item.id == depth) as AnchorEmbedmentDepth);
    }

    private get selectedProductId() {
        return this.design?.model[this.design?.globalMetaProperties?.sourceMetaProperty] as number;
    }

    private get designStandard() {
        return this.design.designStandard?.id ?? DesignStandard.Unknown;
    }

    private get designMethodGroup() {
        return this.design.designMethodGroup?.id ?? DesignMethodGroup.Unknown;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-arrow-down',
            'sprite-arrow-up',
            'sprite-favorite-false',
            'sprite-favorite-true',
            'sprite-x',
            'sprite-anchor-search',
            'sprite-filter',
            'sprite-anchor-family-no-photo-available'
        );

        this.initControls();
        this.initTooltips();

        this.updateDropdownAnchorEmbedmentDepthText();

        this.allowedValuesChangedFn = this.allowedValuesChanged.bind(this);
        this.design?.onAllowedValuesChanged(this.allowedValuesChangedFn);

        this.onResize = this.onResize.bind(this);

        this.design?.onStateChanged((_design, state, oldState) => {
            // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
            const onStateChanged = () => {
                if (!isEqual(oldState.model[PropertyMetaData.AnchorLayout_AnchorFilters.id], state.model[PropertyMetaData.AnchorLayout_AnchorFilters.id])) {
                    this.getSavedGroupsAndFilters();
                    this.refreshProducts();
                }

                this.updateDropdownAnchorEmbedmentDepthText();
            };
            return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
        });

        this.getSavedGroupsAndFilters();

        this.loadProducts();

        this.localizationChangeSubscription = this.localization.localizationChange.subscribe(this.updateProductNameText.bind(this));

        this.oldAnchorFavorites = clone(this.userSettingsService.settings.anchorFavorites.value);
        this.userSettingsSavingSubscription = this.userSettingsService.userSettingsSaving.subscribe(this.onUserSettingsSaved.bind(this));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['collapsed'] != null) {
            this.onCollapsedChanged(this.collapsed);
        }
    }

    ngOnDestroy(): void {
        if (this.localizationChangeSubscription != null) {
            this.localizationChangeSubscription.unsubscribe();
        }

        if (this.userSettingsSavingSubscription != null) {
            this.userSettingsSavingSubscription.unsubscribe();
        }

        if (this.allowedValuesChangedFn != null) {
            this.design?.off(DesignEvent.allowedValuesChanged, this.allowedValuesChangedFn);
            this.allowedValuesChangedFn = undefined;
        }

        if (this.resizeObserver != null) {
            this.resizeObserver.disconnect();
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        if (!this.collapsed) {
            this.calculateAnchorElementHeights();
        }
    }

    ngAfterViewInit() {
        // Use ResizeObserver, to trigger onResize, when info section height is changed (example: resizing/collapsing loads section)
        this.resizeObserver = new ResizeObserver(() => {
            if (!this.isInfoSectionHeightManuallyResizing) {
                this.resizeInfoSection(false);
            }
            this.isInfoSectionHeightManuallyResizing = false;
        });
        this.resizeObserver.observe(this.infoSectionContainer);
    }

    public onResize() {
        // Unobserve ensures that onResize is called before ResizeObserver callback (ngAfterViewInit)
        this.resizeObserver.unobserve(this.infoSectionContainer);

        setTimeout(() => {
            this.infoSectionHeight = this.infoSectionContainer.offsetHeight;
            this.resizeInfoSection(false);
            this.isInfoSectionHeightManuallyResizing = true;

            this.resizeObserver.observe(this.infoSectionContainer);
        });
    }

    public openSelectProduct() {
        this.modal.openSelectAnchor();
    }

    public openCalculateAll() {
        this.modal.openSelectAnchor({
            calculateAll: true
        });
    }

    public removeProductFilter(filterId: number) {
        const anchorFilters = this.design?.designData?.designCodeLists[DesignCodeList.AnchorFilter] as AnchorFilterEntity[];
        const anchorFilterGroups = this.design?.designData?.designCodeLists[DesignCodeList.AnchorFilterGroup] as AnchorFilterGroup[];

        if (filterId >= 1000) {
            if (filterId == 1000) {
                this.fixtureThicknesMin = undefined;
            }

            else if (filterId == 1001) {
                this.fixtureThicknesMax = undefined;
            }

            else if (filterId == 1002) {
                this.holeDiameterMin = undefined;
            }

            else if (filterId == 1003) {
                this.holeDiameterMax = undefined;
            }

            else if (filterId == 1004) {
                this.adaptiveTorquing = false;
                this.design.torquingType = TorquingType.Manual;
            }
        }

        const newAnchorFilters = this.anchorFilters?.filter((filter) => filter.id != filterId);
        const oldAnchorFilters = this.anchorFilterGroups.flatMap(grp => grp.items);

        // check if selected anchor is not on the list of allowed values.
        const newAnchorFilterGroups = anchorFilterGroups.map((anchorFilterGroup) => new SelectAnchorFilterGroup({
            groupOperator: anchorFilterGroup.groupOperator,
            items: anchorFilters.filter((anchorFilter) => anchorFilter.anchorFilterGroupId == anchorFilterGroup.id).map((anchorFilter) => new SelectAnchorFilterGroupItem({
                id: anchorFilter.id,
                textKey: anchorFilter.nameResourceKey,
                selected: newAnchorFilters != null && newAnchorFilters.find((filter) => filter.id == anchorFilter.id) != null,
                disabled: oldAnchorFilters.find(old => old.id == anchorFilter.id)?.disabled
            }))
        }));

        const filteredAnchors = this.filterAnchors(newAnchorFilterGroups);

        if (this.selectedProductId != null && !filteredAnchors.map(anchor => anchor.id).includes(this.selectedProductId)) {
            this.modal.openConfirmChange({
                id: 'anchor-not-on-filtered-list',
                title: this.translate('Agito.Hilti.Profis3.InfoSection.ConfirmAnchorFiltering.Title'),
                message: this.translate('Agito.Hilti.Profis3.InfoSection.ConfirmAnchorFiltering.Message'),
                confirmButtonText: this.translate('Agito.Hilti.Profis3.InfoSection.ConfirmAnchorFiltering.Confirm'),
                cancelButtonText: this.translate('Agito.Hilti.Profis3.InfoSection.ConfirmAnchorFiltering.Cancel'),
                onConfirm: (modal) => {
                    modal.close();
                    this.applyFilters(newAnchorFilters, newAnchorFilterGroups);
                },
                onCancel: (modal) => {
                    modal.close();
                }
            });
        }
        else {
            this.applyFilters(newAnchorFilters, newAnchorFilterGroups);
        }
    }

    public getFavoriteSpriteAnchor(anchorId: number) {
        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.design?.region?.id)) {
            return 'pe-ui-pe-sprite-favorite-false disabled';
        }

        return hasProperty(this.userSettingsService?.settings?.anchorFavorites?.value ?? {}, anchorId.toString())
            ? 'pe-ui-pe-sprite-favorite-true'
            : 'pe-ui-pe-sprite-favorite-false';
    }

    public favoriteToggle(anchorId: number) {
        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.design?.region?.id)) {
            return;
        }

        if (hasProperty(this.userSettingsService?.settings?.anchorFavorites?.value ?? {}, anchorId.toString())) {
            this.userSettingsService.settings.anchorFavorites.value = Object.fromEntries(Object.entries(
                this.userSettingsService?.settings?.anchorFavorites?.value ?? {}).filter(([favoriteId]) => favoriteId != anchorId.toString()));
        }
        else {
            this.userSettingsService.settings.anchorFavorites.value = {
                ...this.userSettingsService.settings.anchorFavorites.value,
                [anchorId]: undefined
            };
        }

        this.userSettingsService.save();
    }

    public setProduct(productId: number | undefined) {
        if (this.design?.isReadOnlyDesignMode) {
            return;
        }

        this.setFiltersToProperties();

        this.design.usageCounter.AnchorChangedInInfoSection++;

        this.calculationService.calculateAsync(this.design,
            (design) => {
                design.model[this.design?.globalMetaProperties?.sourceMetaProperty] = productId;
            }
        );
    }

    public translate(key?: string) {
        return this.localization.getString(key ?? '');
    }

    public getProductFamilyId() {
        return this.design?.anchorFamily?.id;
    }

    public openApproval(isSTOApproval = false, isUKTAApproval = false) {
        let files = this.user.design?.model[PropertyMetaData.AnchorLayout_ViewApproval.id] as string[];
        let availableLanguages = this.user.design?.model[PropertyMetaData.AnchorLayout_ApprovalLanguages.id] as string[][];

        if (isSTOApproval) {
            files = (this.user.design?.model[PropertyMetaData.AnchorLayout_ViewApproval_STO.id] as string[]);
            availableLanguages = (this.user.design?.model[PropertyMetaData.AnchorLayout_ApprovalLanguages_STO.id] as string[][]);
        }

        if (isUKTAApproval) {
            files = (this.user.design?.model[PropertyMetaData.AnchorLayout_ViewApproval_UKTA.id] as string[]);
            availableLanguages = (this.user.design?.model[PropertyMetaData.AnchorLayout_ApprovalLanguages_UKTA.id] as string[][]);
        }

        // Open popup with multiple approvals showing allowing user to open each separately
        // and preventing browser to block second tab-opening as popup
        if (files.length > 1) {
            this.modal.openMultipleApprovals(files, availableLanguages);
        }
        // Open tab otherwise
        else {
            const approvalLang = ApprovalHelper.getApprovalLanguage(availableLanguages[0], this.userSettingsService.getLanguage().culture);
            const approvalInfo = ApprovalHelper.getApprovalInfo(approvalLang, files[0]);
            this.offline.nativeLocalPathOpen(approvalInfo.url, approvalInfo.name, true, true);
        }
    }

    public openSTOApproval() {
        this.openApproval(true);
    }

    public openUKTAApproval() {
        this.openApproval(false, true);
    }

    public onInfoSectionCollapse(collapsed: boolean) {
        this.collapsedChange.emit(collapsed);
    }

    public runCalculation() {
        this.calculationService.calculateAsync(this.design);
    }

    public shortTag(tag: string) {
        return tag != null && tag.length > 0
            ? tag.charAt(0)
            : undefined;
    }

    public trackFilterById: TrackByFunction<ProductFilterDisplay> = (_: number, filter: ProductFilterDisplay) => filter.id;

    public trackAnchorById: TrackByFunction<IAnchor> = (_: number, anchor: IAnchor) => anchor.id;

    private initControls() {
        this.typeDropdown = {
            title: this.translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.Type'),
            items: this.getTypeDropdownItems(),
            notSelectedText: '',
            tooltip: {
                title: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.AnchorType.Tooltip.Title'),
                content: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.AnchorType.Tooltip')
            },
            tags: []
        };

        this.sizeDropdown = {
            title: this.translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.Size'),
            items: this.getSizeDropdownItems(),
            notSelectedText: '',
            tooltip: {
                title: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.AnchorSize.Tooltip.Title'),
                content: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.AnchorSize.Tooltip')
            },
            tags: []
        };

        this.embedmentDepthTypeToggleButtonGroup = {
            items: this.getToggleButtonGroupItemsFromCodeList(
                undefined,
                this.getEmbedmentDepthTypeCodeListItems(),
                true,
                this.design?.properties?.get(PropertyMetaData.AnchorLayout_EmbedmentDepthOptimizationType.id).disabled
            )
        };

        this.embedmentDepthValueDropdown = {
            items: this.getDropdownItems(this.getEmbedmentDepthValueCodeListItems()),
            notSelectedText: ''
        };
    }

    private initTooltips() {
        this.articleNumberMechanicalTooltip = {
            title: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberMechanical.Tooltip.Title'),
            content: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberMechanical.Tooltip')
        };

        this.articleNumberChemicalTooltip = {
            title: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberChemical.Tooltip.Title'),
            content: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberChemical.Tooltip')
        };

        this.articleNumberInsertTooltip = {
            title: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberInsert.Tooltip.Title'),
            content: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberInsert.Tooltip')
        };

        this.articleNumberInsertAlternativeTooltip = {
            title: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberInsertAlternative.Tooltip.Title'),
            content: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberInsertAlternative.Tooltip')
        };

        this.articleNumberCapsuleTooltip = {
            title: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberCapsule.Tooltip.Title'),
            content: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberCapsule.Tooltip')
        };

        this.articleNumberSieveSleeveTooltip = {
            title: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberSieveSleeve.Tooltip.Title'),
            content: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ArticleNumberSieveSleeve.Tooltip')
        };

        this.embedmentDepthTooltip = {
            title: this.translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.EmbedmentDepth'),
            content: this.translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.EmbedmentDepth')
        };

        this.filterButtonTooltip = this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.SelectAnchor.Tooltip.Title');
    }

    private updateProductNameText() {
        this.typeDropdown.items = this.getTypeDropdownItems();
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        this.anchors.forEach(anchorData => {
            const anchor = (this.design?.designData?.designCodeLists[this.design.globalMetaProperties.productCodeListProperty] as AnchorFamily[]).find((a) => a.id == anchorData.id);
            anchorData.name = anchor?.getTranslatedNameText(codeListDeps) as string;
        });
    }

    private getAnchorEmbedmentDepthText(codeListItem: AnchorEmbedmentDepth, unit?: Unit) {
        unit = unit || this.user.design?.unitLength;

        const defaultUnit: Unit = unit;
        const internalUnit = this.unit.getInternalUnit(UnitGroup.Length);

        return this.unit.formatUnitValueArgs(this.unit.convertUnitValueArgsToUnit(codeListItem.hef as number, internalUnit, defaultUnit), defaultUnit);
    }

    private numberOfFiltersPerGroup(productFilters: AnchorFilterEntity[] | AnchorFilterDisplay[]) {
        return (productFilters as any[])
            .reduce((obj: IFiltersGroupNumber, a: AnchorFilterEntity | AnchorFilterDisplay) =>
                ({ ...obj, [a.anchorFilterGroupId as number]: obj[a.anchorFilterGroupId as number] == null ? 1 : obj[a.anchorFilterGroupId as number] + 1 }),
                {} as IFiltersGroupNumber
            );
    }

    private updateDisplayedFilters() {
        if (this.anchorFilters != null) {
            const filtersPerGroup = this.numberOfFiltersPerGroup(this.design?.designData?.designCodeLists[DesignCodeList.AnchorFilter] as AnchorFilterEntity[]);
            const selectedPerGroup = this.numberOfFiltersPerGroup(this.anchorFilters);

            this.displayedAnchorFilters = this.anchorFilters.filter(a =>
                filtersPerGroup[a.anchorFilterGroupId as number] == null
                || selectedPerGroup[a.anchorFilterGroupId as number] < filtersPerGroup[a.anchorFilterGroupId as number]
                || filtersPerGroup[a.anchorFilterGroupId as number] == 1
            );
        }
    }

    private resizeInfoSection(recalculateHeight: boolean) {
        this.calculateAnchorsMinHeight();

        if (!this.collapsed) {
            this.setInfoSectionHeight(recalculateHeight);
        }
    }

    private calculateAnchorsMinHeight() {
        if (this.anchors && this.anchors.length > 0) {
            this.calculateAnchorElementHeights();

            const productLists = (this.elementRef.nativeElement.shadowRoot?.querySelector('.info-section-anchor-detailed-anchors') as HTMLElement);
            const productListChildren = productLists?.children ?? ([] as HTMLElement[]);
            if (this.anchors.length >= 1 && productLists?.style) {
                productLists.style.minHeight = Math.min(this.anchors.length, 2) * ((productListChildren[0] as HTMLElement).offsetHeight) + 'px';
            }
        }

        this.setTypeDropdownUiProps();
        this.setSizeDropdownUiProps();
    }

    private calculateAnchorElementHeights() {
        const anchorList = (this.elementRef.nativeElement.shadowRoot?.querySelectorAll('.info-section-anchor-detailed-anchor-select-button-container') as NodeListOf<HTMLElement>);
        anchorList.forEach((anchorElt) => {
            const anchorNameElements = (anchorElt.getElementsByClassName('info-section-anchor-detailed-anchor-name') as HTMLCollectionOf<HTMLElement>);
            if (anchorNameElements.length > 0) {
                anchorElt.style.height = (anchorNameElements[0].offsetHeight + 6) + 'px';
            }
        });
    }

    private setTypeDropdownUiProps() {
        this.typeDropdown.openUp = true;
        this.typeDropdown.itemsMaxHeight = undefined;

        if (this.collapsed || (this.anchors?.length ?? 0) < 1) {
            return;
        }

        // Check if we can open dropdown items upwards
        const availableHeight = this.getElementsHeights(
            this.infoSectionContainer,
            [
                'info-section-calculate',
                'info-section-active-anchor-filters-title',
                'design-right-collapse-container',
                'info-section-anchor-filters',
                'info-section-anchor-detailed-anchors'
            ]
        );
        const requiredHeight = Math.min(
            InfoSectionComponent.DropdownItemsDefaultMaxHeight,
            (this.typeDropdown.items?.length ?? 0) * 50
        );

        if (availableHeight >= requiredHeight) {
            this.typeDropdown.itemsMaxHeight = requiredHeight;
            return;
        }

        // We might be in a situation where typeDropdown items cannot fit above.
        // Open it downwards ...
        this.typeDropdown.openUp = false;

        // ... and limit the size
        const anchorDetailsContainerHeight = -65   // 65: roughly dropdown height + reservation
            + this.getElementsHeights(
                this.infoSectionContainer,
                [
                    'info-section-anchor-detailed-type-size-embedment-depth-container'
                ]
            );
        this.typeDropdown.itemsMaxHeight = anchorDetailsContainerHeight > 0
            ? anchorDetailsContainerHeight
            : undefined;
    }

    private setSizeDropdownUiProps() {
        this.sizeDropdown.openUp = true;
        this.sizeDropdown.itemsMaxHeight = undefined;

        if (this.collapsed || (this.anchors?.length ?? 0) < 1) {
            return;
        }

        // Check if we can open dropdown items upwards
        const availableHeight = this.getElementsHeights(
            this.infoSectionContainer,
            [
                'info-section-calculate',
                'info-section-active-anchor-filters-title',
                'design-right-collapse-container',
                'info-section-anchor-filters',
                'info-section-anchor-detailed-anchors',
                'info-section-anchor-detailed-dropdown-type'
            ]
        );

        const requiredHeight = Math.min(
            InfoSectionComponent.DropdownItemsDefaultMaxHeight,
            (this.sizeDropdown.items?.length ?? 0) * 50
        );

        if (availableHeight >= requiredHeight) {
            this.sizeDropdown.itemsMaxHeight = requiredHeight;
            return;
        }

        // We might be in a situation where sizeDropdown items cannot fit above.
        // Open it downwards ...
        this.sizeDropdown.openUp = false;

        // ... and limit the size
        const anchorDetailsContainerHeight = -65   // 65: roughly dropdown height + reservation
            + this.getElementsHeights(
                this.infoSectionContainer,
                [
                    'info-section-anchor-detailed-type-size-embedment-depth-container'
                ]
            )
            - this.getElementsHeights(
                this.infoSectionContainer,
                [
                    'info-section-anchor-detailed-dropdown-type'
                ]
            );
        this.sizeDropdown.itemsMaxHeight = anchorDetailsContainerHeight > 0
            ? anchorDetailsContainerHeight
            : undefined;
    }

    private getElementsHeights(container: HTMLElement, cssClasses: string[]) {
        let height = 0;

        for (const cssClass of cssClasses) {
            const elts = container?.getElementsByClassName(cssClass) as HTMLCollectionOf<HTMLElement>;
            if (elts?.length > 0) {
                height += elts[0].offsetHeight;
            }
        }

        return height;
    }

    private setInfoSectionHeight(recalculateHeight: boolean) {
        const infoSection = this.infoSectionContainer;

        if (recalculateHeight) {
            let neededHeight = 4 /* resizer handle */ + 5 /* top + bottom border + reserve */;

            // Header
            const header = (infoSection?.getElementsByClassName('box-section-header') as HTMLCollectionOf<HTMLElement>);
            if (header.length > 0) {
                neededHeight += header[0].offsetHeight;
            }

            const infoContent = (this.elementRef.nativeElement.shadowRoot?.querySelector('#info-section-content') as HTMLElement)?.children;
            if (infoContent != null) {
                // Content (without filler and products)
                for (let i = 0; i < infoContent.length; i++) {
                    if (infoContent[i].classList.contains('info-section-space-filler')) {
                        // Skip filler
                        continue;
                    }

                    if (infoContent[i].classList.contains('info-section-anchor-detailed-anchors')) {
                        // Height of all anchors will be calculated separately
                        continue;
                    }

                    neededHeight += (infoContent[i] as HTMLElement).offsetHeight;
                }

                // Products
                const productLists = (infoSection?.getElementsByClassName('info-section-anchor-detailed-anchors') as HTMLCollectionOf<HTMLElement>);
                for (let i = 0; i < productLists.length; i++) {
                    if (productLists[i].children) {
                        const products = Array.from(productLists[i].children) as HTMLElement[];
                        products.forEach(product => neededHeight += product.offsetHeight);
                    }
                }
            }

            this.infoSectionHeight = neededHeight;
        }

        infoSection.style.height = this.infoSectionHeight + 'px';
    }

    private loadProducts() {
        let anchors = this.design?.designData?.designCodeLists[this.design?.globalMetaProperties?.productCodeListProperty] as AnchorFamily[];

        const allowedValues = this.design?.properties?.get(this.design.globalMetaProperties.sourceMetaProperty).allowedValues;
        if (allowedValues != null) {
            anchors = anchors.filter((anchor) => allowedValues.includes(anchor.id as number));
        }

        const anchorIds = anchors.map((anchor) => anchor.id);
        this.setAnchorsData(anchorIds);

        this.refreshProducts();
    }

    private async applyFilters(productFilters: AnchorFilterDisplay[] | undefined, productFilterGroups: SelectAnchorFilterGroup[]) {
        this.anchorFilters = productFilters as AnchorFilterDisplay[];
        this.anchorFilterGroups = productFilterGroups;

        this.design?.one(DesignEvent.calculate, () => {
            this.refreshProducts();
        });

        this.setFiltersToProperties();

        await this.calculationService.calculateAsync(this.design);

        this.updateDisplayedFilters();
    }

    private setFiltersToProperties() {
        const filteredGroups = this.anchorFilterGroups.filter((filterGroup) => filterGroup.items.some((filterItem) => filterItem.selected));

        this.design.model[PropertyMetaData.AnchorLayout_AnchorFilters.id] = {
            MinMaxFilters: {
                FixtureThicknesMin: this.fixtureThicknesMin,
                FixtureThicknesMax: this.fixtureThicknesMax,
                HoleDiameterMin: this.holeDiameterMin,
                HoleDiameterMax: this.holeDiameterMax
            },
            CustomCheckboxFilters: {
                AdaptiveTorqueing: this.adaptiveTorquing
            },
            CheckboxFilters: filteredGroups.flatMap(group => group.items.filter(item => item.selected).map((item): CheckboxFilter => ({ Id: item.id as number })))
        } as AnchorFilter;
    }

    private getTypeCodeListItems() {
        let items = DesignMethodGroupHelper.IsLrfdBased(this.design?.designMethodGroup?.id)
            ? this.design?.designData?.designCodeLists[DesignCodeList.AnchorTypeAC58] as AnchorTypeEntity[]
            : this.design?.designData?.designCodeLists[DesignCodeList.AnchorType] as AnchorTypeEntity[];

        const allowedValues = this.design?.properties?.get(PropertyMetaData.AnchorLayout_Type.id).allowedValues;

        if (items != null && allowedValues != null) {
            items = items.filter(item => allowedValues.includes(item.id as number));
        }

        return items;
    }

    private getSizeCodeListItems() {
        let items = DesignMethodGroupHelper.IsLrfdBased(this.design?.designMethodGroup?.id)
            ? this.design?.designData?.designCodeLists[DesignCodeList.AnchorSizeAC58] as AnchorSizeEntity[]
            : this.design?.designData?.designCodeLists[DesignCodeList.AnchorSize] as AnchorSizeEntity[];

        const allowedValues = this.design?.properties?.get(PropertyMetaData.AnchorLayout_Size.id).allowedValues;

        if (items != null && allowedValues != null) {
            items = items.filter(item => allowedValues.includes(item.id as number));
        }

        return items;
    }

    private getDropdownItems(codeListItems: CodeList[]) {
        const retVal: DropdownItem<number>[] = [];
        if (codeListItems != null) {
            const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

            for (const codeListItem of codeListItems) {
                const tags: DropdownTag[] = [];
                if (codeListItem.tag != null && codeListItem.tag.length > 0) {
                    tags.push({
                        type: TagType.Tag,
                        text: this.shortTag(this.translate(codeListItem.tag))
                    });
                }

                retVal.push({
                    value: codeListItem.id as number,
                    text: codeListItem.getTranslatedNameText(codeListDeps) ?? '',
                    tags
                });
            }
        }

        return retVal;
    }

    private getTypeDropdownItems() {
        const items = this.getDropdownItems(this.getTypeCodeListItems());
        for (const item of items) {
            if (isAnchorTypeMarkedAsNew(item.value, this.design)) {
                item.tags?.push({
                    type: TagType.New,
                    text: this.translate('Agito.Hilti.Profis3.Anchors.New')
                });
            }

            const internalPortfolioOnly = this.getTypeCodeListItems().find((types) => types.id == item.value)?.internalPortfolioOnly;
            if (internalPortfolioOnly) {
                item.tags?.push({
                    type: TagType.Tag,
                    text: this.translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio'),
                    tooltip: {
                        title: '',
                        content: this.translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio.Tooltip')
                    }
                });
            }
        }
        return items;
    }

    private getSizeDropdownItems() {
        const items = this.getDropdownItems(this.getSizeCodeListItems());
        for (const item of items) {
            if (isAnchorTypeMarkedAsNew(item.value, this.design)) {
                item.tags?.push({
                    type: TagType.New,
                    text: this.translate('Agito.Hilti.Profis3.Anchors.New')
                });
            }

            const internalPortfolioOnly = this.getSizeCodeListItems().find((types) => types.id == item.value)
                ?.internalPortfolioOnlyRegions?.includes(this.design?.region?.id as number);

            if (internalPortfolioOnly) {
                item.tags?.push({
                    type: TagType.Tag,
                    text: this.translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio'),
                    tooltip: {
                        title: '',
                        content: this.translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio.Tooltip')
                    }
                });
            }
        }
        return items;
    }

    private getEmbedmentDepthTypeCodeListItems() {
        let items = this.design?.designData?.designCodeLists[DesignCodeList.EmbedmentOptimizationType];
        const allowedValues = this.design?.properties?.get(PropertyMetaData.AnchorLayout_EmbedmentDepthOptimizationType.id).allowedValues;

        if (items != null && allowedValues != null) {
            items = items.filter(item => allowedValues.includes(item.id ?? 0));
        }

        return items;
    }

    private getToggleButtonGroupItemsFromCodeList(
        controlId: string | undefined,
        codeList: CodeList[] = [],
        hideChildDisplayText = false,
        controlDisabled = false
    ) {
        const toggleButtonGroupItems: ToggleButtonGroupItem[] = [];
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        for (const codeListItem of codeList) {
            toggleButtonGroupItems.push({
                id: controlId ? `${controlId}-${codeListItem.id}` : '',
                value: codeListItem.id,
                text: hideChildDisplayText ? '' : codeListItem.getTranslatedNameText(codeListDeps),
                image: codeListItem.image != null && codeListItem.image != '' ? getSpriteAsIconStyle(codeListItem.image as Sprite) : {},
                tooltip: this.getToggleButtonGroupItemTooltip(codeListItem, controlDisabled),
                disabled: controlDisabled
            });
        }

        return toggleButtonGroupItems;
    }

    private getToggleButtonGroupItemTooltip(codeListItem: CodeList | undefined, controlDisabled: boolean) {
        if (codeListItem == null) {
            return undefined;
        }

        let title = '';
        const titleKey = controlDisabled
            ? codeListItem.tooltipDisabledTitleDisplayKey
            : codeListItem.tooltipTitleDisplayKey;
        if (titleKey != null && titleKey != '') {
            title = this.translate(titleKey);
        }

        let content = '';
        const contentKey = controlDisabled
            ? codeListItem.tooltipDisabledDisplayKey
            : codeListItem.tooltipDisplayKey;
        if (contentKey != null && contentKey != '') {
            content = this.formatDisplayKey(contentKey);
        }

        if (title != null && title != '' || content != null && content != '') {
            return {
                title,
                content
            };
        }

        return undefined;
    }

    private formatDisplayKey(displayKey: string): string {
        return this.translate(displayKey);
    }

    private getEmbedmentDepthValueCodeListItems() {
        const items = this.design?.designData?.designCodeLists[DesignCodeList.AnchorEmbedmentDepth] as AnchorEmbedmentDepth[];
        const allowedValues = this.design?.properties?.get(PropertyMetaData.AnchorLayout_EmbedmentDepthFixedMultiple.id).allowedValues;

        // allowedValues is null when dropdown is not visible
        if (items == null || allowedValues == null) {
            return [];
        }

        return items.filter(item => allowedValues.includes(item.id as number));
    }

    private allowedValuesChanged() {
        if (!this.productHidden) {
            this.typeDropdown.items = this.getTypeDropdownItems();
            this.sizeDropdown.items = this.getSizeDropdownItems();

            this.embedmentDepthTypeToggleButtonGroup.items =
                this.getToggleButtonGroupItemsFromCodeList(
                    undefined,
                    this.getEmbedmentDepthTypeCodeListItems(),
                    true,
                    this.design?.properties?.get(PropertyMetaData.AnchorLayout_EmbedmentDepthOptimizationType.id).disabled
                );

            this.embedmentDepthValueDropdown.items = this.getDropdownItems(this.getEmbedmentDepthValueCodeListItems());

            this.getSavedGroupsAndFilters();
            this.loadProducts();
        }
    }

    private updateDropdownAnchorEmbedmentDepthText() {
        if (this.embedmentDepthValueDropdown.items == null) {
            return;
        }

        for (const item of this.embedmentDepthValueDropdown.items) {
            const codeListItem = (this.design?.designData?.designCodeLists[DesignCodeList.AnchorEmbedmentDepth] as AnchorEmbedmentDepth[]).find(cli => cli.id == item.value);
            const unit = this.design?.unitLength;

            item.text = this.getAnchorEmbedmentDepthText(codeListItem as AnchorEmbedmentDepth, unit);
        }
    }

    private setAnchorsData(anchorIds: (number | undefined)[]) {
        const anchors = (this.design?.designData?.designCodeLists[this.design?.globalMetaProperties?.productCodeListProperty] as AnchorFamily[])
            .filter((anchor) => anchorIds.some((anchorId) => anchorId == anchor.id) && anchor.detailed != null);
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        this.anchorsData = anchors.map((anchor): IAnchor => ({
            id: anchor.id as number,
            name: anchor.getTranslatedNameText(codeListDeps) as string,
            image: anchor.image as string,
            allowedInAnchorFilters: anchor.detailed?.allowedInAnchorFilters ?? [],
            holeDiameters: anchor.detailed?.holeDiameters ?? [],
            fixtureThicknesMin: anchor.detailed?.fixtureThicknesMin as number,
            fixtureThicknesMax: anchor.detailed?.fixtureThicknesMax as number,
            defaultSortOrder: anchor.detailed?.defaultSortOrder as number,
            tag: anchor?.detailed?.tag != null && anchor.detailed.tag.length > 0
                ? this.translate(anchor.detailed.tag)
                : '',
            tested: anchor.detailed?.tested ?? false,
            isNew: isAnchorFamilyMarkedAsNew(anchor.id as number, this.design) ?? false,
            internalPortfolioOnly: anchor.internalPortfolioOnly ?? false,
            adaptiveTorquing: anchor.detailed?.isAutomaticCleaningApproved ?? false,
        }));
    }

    private refreshProducts() {
        this.anchors = this.filterAnchors();
        this.initializeAnchorFamilySprites();

        setTimeout(() => {
            this.resizeInfoSection(true);
        });
    }

    private filterAnchors(anchorFilterGroups?: SelectAnchorFilterGroup[]) {
        if (anchorFilterGroups == null) {
            anchorFilterGroups = this.anchorFilterGroups;
        }

        let anchors = this.anchorsData.slice();

        // allowed values
        const allowedValues = this.design?.properties?.get(this.design?.globalMetaProperties?.sourceMetaProperty).allowedValues;
        if (allowedValues != null) {
            anchors = anchors.filter((anchor) => allowedValues.includes(anchor.id));
        }

        // search filter - min max
        anchors = this.filterByMinMax(anchors, this.holeDiameterMin, this.holeDiameterMax, this.fixtureThicknesMin, this.fixtureThicknesMax);

        // search filter - custom checkboxes
        anchors = this.filterAnchorsByCustomCheckboxes(anchors, this.adaptiveTorquing);

        // checkbox filter
        anchors = this.filterAnchorsByCheckboxes(anchors, anchorFilterGroups);

        // sort by
        anchors = this.sortAnchorFamilyBy(anchors ?? [], SelectAnchorSortBy.default);

        return anchors;
    }

    private filterByMinMax(anchors: IAnchor[], holeDiameterMin?: number, holeDiameterMax?: number, fixtureThicknesMin?: number, fixtureThicknesMax?: number) {
        return anchors.filter((anchor) => {
            if (holeDiameterMin != null && holeDiameterMax != null) {
                if (anchor.holeDiameters?.length == 0 || anchor.holeDiameters?.every((holeDiameter) => holeDiameter < holeDiameterMin || holeDiameter > holeDiameterMax)) {
                    return false;
                }
            }
            else if (holeDiameterMin != null) {
                if (anchor.holeDiameters?.length == 0 || anchor.holeDiameters?.every((holeDiameter) => holeDiameter < holeDiameterMin)) {
                    return false;
                }
            }
            else if (holeDiameterMax != null) {
                if (anchor.holeDiameters?.length == 0 || anchor.holeDiameters?.every((holeDiameter) => holeDiameter > holeDiameterMax)) {
                    return false;
                }
            }

            if (fixtureThicknesMin != null && fixtureThicknesMin < anchor.fixtureThicknesMin) {
                return false;
            }

            if (fixtureThicknesMax != null && fixtureThicknesMax > anchor.fixtureThicknesMax) {
                return false;
            }

            return true;
        });
    }

    private filterAnchorsByCustomCheckboxes(anchors: IAnchor[], adaptiveTorquing?: boolean) {
        return anchors.filter((anchor) => {
            if (adaptiveTorquing != null && adaptiveTorquing && !anchor.adaptiveTorquing) {
                return false;
            }

            return true;
        });
    }

    private filterAnchorsByCheckboxes(anchors: IAnchor[], anchorFilterGroups: SelectAnchorFilterGroup[]) {
        const filteredAnchors: IAnchor[] = [];

        const selectedAnchorGroups = anchorFilterGroups.filter((filterGroup) => filterGroup.items.some((filterItem) => filterItem.selected));
        const orGroups = selectedAnchorGroups.filter((filterGroup) => filterGroup.groupOperator == GroupOperator.Or);
        const andGroups = selectedAnchorGroups.filter((filterGroup) => filterGroup.groupOperator == GroupOperator.And);

        for (const anchor of anchors) {
            if (orGroups.length == 0 && andGroups.length == 0) {
                filteredAnchors.push(anchor);
            }
            else {
                const allowed = anchor.allowedInAnchorFilters;
                let isAnchorAllowed = true;

                // is allowed in 'or' groups
                for (const orGroup of orGroups) {
                    const allowedGroup = orGroup.items.filter((item) => item.selected).map((item) => item.id);

                    if (difference(allowedGroup, allowed ?? []).length == allowedGroup.length) {
                        isAnchorAllowed = false;
                        break;
                    }
                }

                // is allowed in 'and' groups
                for (const andGroup of andGroups) {
                    const allowedGroup = andGroup.items.filter((item) => item.selected).map((item) => item.id);

                    if (difference(allowedGroup, allowed ?? []).length != 0) {
                        isAnchorAllowed = false;
                        break;
                    }
                }

                if (isAnchorAllowed) {
                    filteredAnchors.push(anchor);
                }
            }
        }

        return filteredAnchors;
    }

    private sortAnchorFamilyBy(anchors: IAnchor[], selectBy: SelectAnchorSortBy) {
        if (selectBy == null) {
            return anchors ?? [];
        }

        switch (selectBy) {
            case SelectAnchorSortBy.default:
                return sortBy(anchors,
                    anchor => hasProperty(this.userSettingsService?.settings?.anchorFavorites?.value ?? {}, anchor.id.toString()) ? 0 : 1,
                    anchor => anchor.defaultSortOrder);

            case SelectAnchorSortBy.name:
                return sortBy(anchors,
                    anchor => hasProperty(this.userSettingsService?.settings?.anchorFavorites?.value ?? {}, anchor.id.toString()) ? 0 : 1,
                    anchor => anchor.name.toLowerCase());

            default:
                throw new Error('Invalid sort by.');
        }
    }

    private getSavedGroupsAndFilters() {
        // load filter entities
        const anchorFilters = this.design?.designData?.designCodeLists[DesignCodeList.AnchorFilter] as AnchorFilterEntity[];
        const anchorFilterGroups = this.design?.designData?.designCodeLists[DesignCodeList.AnchorFilterGroup] as AnchorFilterGroup[];

        // load saved filters
        const savedAnchorFilters = this.design?.model[PropertyMetaData.AnchorLayout_AnchorFilters.id] as AnchorFilter;

        // remove not needed filters
        const filteredAnchorFilters = filterAnchorFilters(anchorFilters, savedAnchorFilters);
        const filteredAnchorFilterGroups = filterAnchorFilterGroups(anchorFilterGroups, filteredAnchorFilters, this.design.designTypeId);

        this.anchorFilters = savedAnchorFilters != null ? savedAnchorFilters.CheckboxFilters.map((checkboxFilter): AnchorFilterDisplay => ({
            id: checkboxFilter.Id,
            title: filteredAnchorFilters.find(filter => filter.id == checkboxFilter.Id)?.nameResourceKey,
            anchorFilterGroupId: filteredAnchorFilters.find(filter => filter.id == checkboxFilter.Id)?.anchorFilterGroupId
        })) : undefined;

        this.fixtureThicknesMin = savedAnchorFilters != null ? savedAnchorFilters.MinMaxFilters.FixtureThicknesMin : undefined;
        this.fixtureThicknesMax = savedAnchorFilters != null ? savedAnchorFilters.MinMaxFilters.FixtureThicknesMax : undefined;
        this.holeDiameterMin = savedAnchorFilters != null ? savedAnchorFilters.MinMaxFilters.HoleDiameterMin : undefined;
        this.holeDiameterMax = savedAnchorFilters != null ? savedAnchorFilters.MinMaxFilters.HoleDiameterMax : undefined;

        this.adaptiveTorquing = this.design.torquingType == TorquingType.Automatic;

        if (this.fixtureThicknesMin != null) {
            this.anchorFilters = this.anchorFilters || [];

            this.anchorFilters.push({
                id: 1000,
                title: 'Agito.Hilti.Profis3.SelectAnchor.FixtureThicknesMin',
                anchorFilterGroupId: -1
            });
        }

        if (this.fixtureThicknesMax != null) {
            this.anchorFilters = this.anchorFilters || [];

            this.anchorFilters.push({
                id: 1001,
                title: 'Agito.Hilti.Profis3.SelectAnchor.FixtureThicknesMax',
                anchorFilterGroupId: -1
            });
        }

        if (this.holeDiameterMin != null) {
            this.anchorFilters = this.anchorFilters || [];

            this.anchorFilters.push({
                id: 1002,
                title: 'Agito.Hilti.Profis3.SelectAnchor.HoleDiameterMin',
                anchorFilterGroupId: -1
            });
        }

        if (this.holeDiameterMax != null) {
            this.anchorFilters = this.anchorFilters || [];

            this.anchorFilters.push({
                id: 1003,
                title: 'Agito.Hilti.Profis3.SelectAnchor.HoleDiameterMax',
                anchorFilterGroupId: -1
            });
        }

        if (this.adaptiveTorquing) {
            this.anchorFilters = this.anchorFilters || [];

            this.anchorFilters.push({
                id: 1004,
                title: 'Agito.Hilti.Profis3.SelectAnchor.Torquing.Adaptive',
                anchorFilterGroupId: -1
            });
        }

        // create filter structure
        this.anchorFilterGroups = filteredAnchorFilterGroups?.map((anchorFilterGroup) => new SelectAnchorFilterGroup({
            groupOperator: anchorFilterGroup.groupOperator,
            items: filteredAnchorFilters.filter((anchorFilter) => anchorFilter.anchorFilterGroupId == anchorFilterGroup.id).map((anchorFilter) => new SelectAnchorFilterGroupItem({
                id: anchorFilter.id,
                textKey: anchorFilter.nameResourceKey,
                selected: this.anchorFilters != null && this.anchorFilters.find((filter) => filter.id == anchorFilter.id) != null,
                disabled: savedAnchorFilters.CheckboxFiltersDisabled.some(disabled => disabled.Id == anchorFilter.id)
            }))
        }));

        this.updateDisplayedFilters();
        this.filtersOpened = this.anchorFilters != null && this.displayedAnchorFilters.length < 5;
    }

    private isAnchorTypeMarkedAsNew(anchorTypeId: number) {
        return isAnchorTypeMarkedAsNew(anchorTypeId, this.design);
    }

    private onUserSettingsSaved() {
        // Anchor favorites
        const anchorFavorites = this.userSettingsService.settings.anchorFavorites.value;
        if (!isEqual(this.oldAnchorFavorites, anchorFavorites)) {
            this.refreshProducts();
            this.oldAnchorFavorites = clone(anchorFavorites);
        }
    }

    private onCollapsedChanged(value: boolean) {
        const el = this.infoSectionContainer;
        if (value) {
            // clear custom height on element when closed
            el.style.height = '';
        }
        else {
            // set size to include just header ...
            el.style.height = '34px';
            setTimeout(() => {
                // and recalculate height.
                el.style.height = '';
                this.resizeInfoSection(true);
            });
        }
    }

    private initializeAnchorFamilySprites() {
        this.anchors.forEach(anchor => {
            if (anchor.image && !this.initializedSpriteImages.includes(anchor.image)) {
                includeSprites(this.elementRef.nativeElement.shadowRoot, !anchor.tested, anchor.image as Sprite);
                this.initializedSpriteImages.push(anchor.image);
            }
        });
    }
}
