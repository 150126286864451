import {
    ProductFilterGroupEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class ProductFilterGroup extends CodeList {

    public static fromService(codeList: ProductFilterGroupEntityC2C) {
        return new ProductFilterGroup(CodeList.baseFromService('ProductFilterGroupEntity', codeList));
    }

    public override cloneDeep(): ProductFilterGroup {
        return super.cloneDeep() as ProductFilterGroup;
    }
}
