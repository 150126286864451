import {
    TrackingUsageCounter as TrackingUsageCounterBase
} from '@profis-engineering/pe-ui-common/entities/tracking-usage-counter';
import { LoggerServiceBase } from '@profis-engineering/pe-ui-common/services/logger.common';
import { UsageCounterC2C } from './tracking-data';

export class TrackingUsageCounterC2C extends TrackingUsageCounterBase {
    private thirdPartyExport!: number;
    private defineOtherRebarParameters!: number;
    private definitionOfMinMaxReinforcement!: number;
    private basePlateHoleDiameterFilter!: number;
    private calculateAll!: number;


    constructor(logger: LoggerServiceBase) {
        super(logger);
        this.resetCounter();
    }


    public get ThirdPartyExport(): number {
        return this.thirdPartyExport;
    }
    public set ThirdPartyExport(value: number) {
        this.logSimplePropertyChange('ThirdPartyExport', this.thirdPartyExport, value);
        this.thirdPartyExport = value;
    }

    public get DefineOtherRebarParameters(): number {
        return this.defineOtherRebarParameters;
    }
    public set DefineOtherRebarParameters(value: number) {
        this.logSimplePropertyChange('DefineOtherRebarParameters', this.defineOtherRebarParameters, value);
        this.defineOtherRebarParameters = value;
    }

    public get DefinitionOfMinMaxReinforcement(): number {
        return this.definitionOfMinMaxReinforcement;
    }
    public set DefinitionOfMinMaxReinforcement(value: number) {
        this.logSimplePropertyChange('DefinitionOfMinMaxReinforcement', this.definitionOfMinMaxReinforcement, value);
        this.definitionOfMinMaxReinforcement = value;
    }

    public get BasePlateHoleDiameterFilter(): number {
        return this.basePlateHoleDiameterFilter;
    }
    public set BasePlateHoleDiameterFilter(value: number) {
        this.logSimplePropertyChange('BasePlateHoleDiameterFilter', this.basePlateHoleDiameterFilter, value);
        this.basePlateHoleDiameterFilter = value;
    }

    public get CalculateAll(): number {
        return this.calculateAll;
    }
    public set CalculateAll(value: number) {
        this.logSimplePropertyChange('CalculateAll', this.calculateAll, value);
        this.calculateAll = value;
    }

    // public

    /**
     * Transfer to JSON object recognized by back end BL for C2C.
     */
    public toUsageCounterC2C(): UsageCounterC2C {
        return {
            approval: this.Approval,
            dateAccessed: this.DateAccessed,
            dateClosed: this.DateClosed,
            designExportedToDevice: this.DesignExportedToDevice,
            designExportedToTrimple: this.DesignExportedToTrimple,
            excelImportLoad: this.ExcelImportLoad,
            filterGroupsUsed: this.filterGroupsUsed,
            filterUsed: this.FilterUsed,
            importExistingDesign: this.ImportExistingDesign,
            menuOpened: this.MenuOpened,
            onlineTechnicalInformation: this.OnlineTechnicalInformation,
            redo: this.Redo,
            reportCreatedWithCustomTemplate: this.ReportCreatedWithCustomTemplate,
            reportCreatedWithDefaultTemplate: this.ReportCreatedWithDefaultTemplate,
            reportCreatedWithItemNumbers: this.ReportCreatedWithItemNumbers,
            reportCreatedWithUserTemplate: this.ReportCreatedWithUserTemplate,
            reportsCreated: this.ReportsCreated,
            shortReportsCreated: this.ShortReportsCreated,
            specificationTextExport: this.SpecificationTextExport,
            twoDEditor: this.TwoDEditor,
            undo: this.Undo,
            zoom: this.Zoom,
            defineOtherRebarParameters: this.DefineOtherRebarParameters,
            definitionOfMinMaxReinforcement: this.DefinitionOfMinMaxReinforcement,
            duplicateDesign: this.DuplicateDesign
        };
    }

    public override resetCounter() {
        super.resetCounter();

        this.thirdPartyExport = 0;
        this.defineOtherRebarParameters = 0;
        this.definitionOfMinMaxReinforcement = 0;
        this.basePlateHoleDiameterFilter = 0;
    }
}
