import '@babylonjs/core/Meshes/Builders/discBuilder.js';
import '@babylonjs/core/Meshes/Builders/cylinderBuilder.js';
import { Matrix, Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { Mesh } from '@babylonjs/core/Meshes/mesh.js';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData.js';
import { cloneVertexData, computeNormals, createSection, extrudeShape, flipFaces, mergeSection } from '../vertex-data-helper.js';
import { CacheItem } from './cache-item.js';
import { CommonCache } from './common-cache.js';
import { GLTemp } from './gl-temp.js';
import { Ray } from '@babylonjs/core/Culling/ray.js';
import { Plane } from '@babylonjs/core/Maths/math.plane.js';
import { CreateRibbon } from '@babylonjs/core/Meshes/Builders/ribbonBuilder.js';
import { CreateRibbonVertexData } from '@babylonjs/core/Meshes/Builders/ribbonBuilder.js';
import { CreateDiscVertexData } from '@babylonjs/core/Meshes/Builders/discBuilder.js';
import { CreateCylinderVertexData } from '@babylonjs/core/Meshes/Builders/cylinderBuilder.js';
const glTemp = new GLTemp({
  matrix: 1,
  vector3: 1
});
export class VertexDataCache extends CacheItem {
  commonCache;
  matrixCache;
  constructor(commonCache, matrixCache) {
    super();
    this.commonCache = commonCache;
    this.matrixCache = matrixCache;
  }
  get boxSide() {
    return this.create('VertexData.boxSide', () => {
      const boxSidePoint = this.commonCache.boxSidePoints[0];
      return CreateRibbonVertexData({
        pathArray: [[new Vector3(boxSidePoint.x, boxSidePoint.y, boxSidePoint.z), new Vector3(-boxSidePoint.x, boxSidePoint.y, boxSidePoint.z)], [new Vector3(boxSidePoint.x, -boxSidePoint.y, boxSidePoint.z), new Vector3(-boxSidePoint.x, -boxSidePoint.y, boxSidePoint.z)]]
      });
    });
  }
  get box() {
    return this.create('VertexData.box', () => {
      // top
      const topVertexData = cloneVertexData(this.boxSide);
      topVertexData.transform(this.matrixCache.rotationX270);
      // bottom
      const bottomVertexData = cloneVertexData(this.boxSide);
      bottomVertexData.transform(this.matrixCache.rotationX90);
      const vertexData = new VertexData();
      vertexData.positions = [];
      vertexData.indices = [];
      vertexData.normals = [];
      vertexData.uvs = [];
      vertexData.merge(this.boxWrapper);
      vertexData.merge(topVertexData);
      vertexData.merge(bottomVertexData);
      return vertexData;
    });
  }
  get boxWrapper() {
    return this.create('VertexData.boxWrapper', () => {
      const vertexData = new VertexData();
      vertexData.positions = [];
      vertexData.indices = [];
      vertexData.normals = [];
      vertexData.uvs = [];
      this.boxWrapperList.forEach(element => {
        vertexData.merge(element);
      });
      return vertexData;
    });
  }
  get boxWrapperList() {
    return this.create('VertexData.boxWrapperList', () => {
      // front
      const frontVertexData = this.boxSide;
      // left
      const leftVertexData = cloneVertexData(frontVertexData);
      leftVertexData.transform(this.matrixCache.rotationY90);
      // back
      const backVertexData = cloneVertexData(leftVertexData);
      backVertexData.transform(this.matrixCache.rotationY90);
      // right
      const rightVertexData = cloneVertexData(backVertexData);
      rightVertexData.transform(this.matrixCache.rotationY90);
      return [frontVertexData, leftVertexData, backVertexData, rightVertexData];
    });
  }
  get weld() {
    return this.create('VertexData.weld', () => {
      const positions = [0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, -1, 1, 0, -1, 0, 1, -1, 0, 1, 0, 1, 0, 0, 1, 0, -1, 0, 1, -1, 0, 0, 0, 0, 0, -1, 0, 1, -1, 0, 1, -1, 0, 1, 0, 0, 0, 0];
      const indices = [0, 1, 2, 3, 4, 5, 6, 8, 7, 6, 9, 8, 10, 11, 12, 13, 14, 15];
      const normals = [];
      VertexData.ComputeNormals(positions, indices, normals);
      const vertexData = new VertexData();
      vertexData.positions = positions;
      vertexData.indices = indices;
      vertexData.normals = normals;
      return vertexData;
    });
  }
  get rectangularHollow() {
    return this.create('VertexData.squareHollow', () => {
      // outer
      const outerVertexData = [];
      this.boxWrapperList.forEach(x => outerVertexData.push(cloneVertexData(x)));
      // inner
      const innerVertexData = [];
      outerVertexData.forEach(side => {
        const sideClone = cloneVertexData(side);
        sideClone.transform(Matrix.Scaling(0.5, 1, 0.5));
        flipFaces(sideClone, true);
        innerVertexData.push(sideClone);
      });
      // top
      const topVertexData = CreateRibbonVertexData({
        pathArray: [[new Vector3(-CommonCache.boxSizeHalf * 0.5, CommonCache.boxSizeHalf, CommonCache.boxSizeHalf * 0.5), new Vector3(-CommonCache.boxSizeHalf * 0.5, CommonCache.boxSizeHalf, -CommonCache.boxSizeHalf * 0.5), new Vector3(CommonCache.boxSizeHalf * 0.5, CommonCache.boxSizeHalf, -CommonCache.boxSizeHalf * 0.5), new Vector3(CommonCache.boxSizeHalf * 0.5, CommonCache.boxSizeHalf, CommonCache.boxSizeHalf * 0.5)], [new Vector3(-CommonCache.boxSizeHalf, CommonCache.boxSizeHalf, CommonCache.boxSizeHalf), new Vector3(-CommonCache.boxSizeHalf, CommonCache.boxSizeHalf, -CommonCache.boxSizeHalf), new Vector3(CommonCache.boxSizeHalf, CommonCache.boxSizeHalf, -CommonCache.boxSizeHalf), new Vector3(CommonCache.boxSizeHalf, CommonCache.boxSizeHalf, CommonCache.boxSizeHalf)]],
        closePath: true
      });
      // bottom
      const bottomVertexData = cloneVertexData(topVertexData);
      bottomVertexData.transform(Matrix.Translation(0, -CommonCache.boxSize, 0));
      flipFaces(bottomVertexData, true);
      // vertex data
      const vertexData = [];
      outerVertexData.forEach(side => vertexData.push(side));
      innerVertexData.forEach(side => vertexData.push(side));
      vertexData.push(topVertexData);
      vertexData.push(bottomVertexData);
      return vertexData;
    });
  }
  arrowHead(diameter, height, points) {
    const id = `${diameter}|${height}|${points}`;
    return this.create(`VertexData.arrowHead:${id}`, () => {
      return CreateCylinderVertexData({
        height,
        diameterTop: 0,
        diameterBottom: diameter,
        tessellation: points
      });
    });
  }
  arrow(headDiameter, headHeight, headPoints, lineDiameter, lineHeight, linePoints, arrowDirection, dashed) {
    const id = `${headDiameter}|${headHeight}|${headPoints}|${lineDiameter}|${lineHeight}|${linePoints}|${arrowDirection}|${(dashed ?? false).toString()}`;
    return this.create(`VertexData.arrow:${id}`, () => {
      const vertexData = new VertexData();
      vertexData.positions = [];
      vertexData.indices = [];
      vertexData.normals = [];
      vertexData.uvs = [];
      if (dashed) {
        const dashLength = lineHeight / 9;
        const lineVertexData = cloneVertexData(this.cylinder(linePoints, false, 2 /* Cap.TopBottom */).vertexData);
        lineVertexData.transform(Matrix.Scaling(lineDiameter / CommonCache.cylinderSize, dashLength / CommonCache.cylinderSize, lineDiameter / CommonCache.cylinderSize));
        const offset = (arrowDirection != 2 /* ArrowDirection.Both */ ? -headHeight / 2 : 0) - lineHeight / 2 + dashLength / 2;
        lineVertexData.transform(Matrix.Translation(0, offset, 0));
        vertexData.merge(cloneVertexData(lineVertexData));
        for (let i = 0; i < 4; i++) {
          lineVertexData.transform(Matrix.Translation(0, dashLength * 2, 0));
          vertexData.merge(lineVertexData);
        }
      } else {
        const lineVertexData = cloneVertexData(this.cylinder(linePoints, false, arrowDirection == 2 /* ArrowDirection.Both */ ? 3 /* Cap.None */ : 1 /* Cap.Bottom */).vertexData);
        lineVertexData.transform(Matrix.Scaling(lineDiameter / CommonCache.cylinderSize, lineHeight / CommonCache.cylinderSize, lineDiameter / CommonCache.cylinderSize));
        if (arrowDirection != 2 /* ArrowDirection.Both */) {
          lineVertexData.transform(Matrix.Translation(0, -headHeight / 2, 0));
        }
        vertexData.merge(lineVertexData);
      }
      const headVertexData = cloneVertexData(this.arrowHead(headDiameter, headHeight, headPoints));
      headVertexData.transform(Matrix.Translation(0, lineHeight / 2 + (arrowDirection == 2 /* ArrowDirection.Both */ ? headHeight / 2 : 0), 0));
      let doubleHeadVertexData = undefined;
      if (arrowDirection == 2 /* ArrowDirection.Both */) {
        doubleHeadVertexData = cloneVertexData(headVertexData);
        doubleHeadVertexData.transform(Matrix.RotationX(Math.PI));
      }
      vertexData.merge(headVertexData);
      if (arrowDirection == 2 /* ArrowDirection.Both */ && doubleHeadVertexData != null) {
        vertexData.merge(doubleHeadVertexData);
      }
      if (arrowDirection == 1 /* ArrowDirection.In */) {
        const axis = glTemp.vector3[0];
        Vector3.FromFloatsToRef(0, 0, 1, axis);
        const rotateIn = glTemp.matrix[0];
        Matrix.RotationAxisToRef(axis, Math.PI, rotateIn);
        vertexData.transform(rotateIn);
      }
      return vertexData;
    });
  }
  circleArrow(circleDiameter, circlePoints, headDiameter, headHeight, headPoints, lineDiameter, linePoints, arrowDirection, dashed) {
    const id = `${circleDiameter}|${circlePoints}|${headDiameter}|${headHeight}|${headPoints}|${lineDiameter}|${linePoints}|${arrowDirection}|${(dashed ?? false).toString()}`;
    return this.create(`VertexData.circleArrow:${id}`, () => {
      const gap = 1 / 10;
      let fullCirclePath = this.calculateCirclePoints(circlePoints, circleDiameter / 2);
      const fullCirclePathSkip = Math.ceil(fullCirclePath.length * gap);
      fullCirclePath = fullCirclePath.filter((point, index) => index >= fullCirclePathSkip && index < fullCirclePath.length - fullCirclePathSkip);
      if (arrowDirection == 2 /* ArrowDirection.Both */) {
        fullCirclePath.splice(fullCirclePath.length - 1, 1);
      }
      const circleShape = this.calculateCirclePoints(linePoints, lineDiameter / 2).map(point => new Vector3(point.x, point.y, 0));
      const circlePath = fullCirclePath.map(point => new Vector3(0, point.x, point.y));
      const vertexData = new VertexData();
      vertexData.positions = [];
      vertexData.indices = [];
      vertexData.normals = [];
      vertexData.uvs = [];
      if (dashed) {
        for (let i = 0; circlePath.length - i > 1; i += 2) {
          const path = circlePath.slice(i, i + 2);
          const circleVertexData = extrudeShape(circleShape, path, 1, 0, Mesh.CAP_ALL);
          circleVertexData.transform(Matrix.RotationX(Math.PI / circlePoints));
          vertexData.merge(circleVertexData);
        }
      } else {
        const circleVertexData = extrudeShape(circleShape, circlePath, 1, 0, arrowDirection == 2 /* ArrowDirection.Both */ ? Mesh.NO_CAP : Mesh.CAP_END);
        circleVertexData.transform(Matrix.RotationX(Math.PI / circlePoints));
        vertexData.merge(circleVertexData);
      }
      const arrowHeadVertexData = cloneVertexData(this.arrowHead(headDiameter, headHeight, headPoints));
      arrowHeadVertexData.transform(Matrix.Translation(0, headHeight / 2, 0));
      arrowHeadVertexData.transform(Matrix.RotationX(Math.PI * 2 * gap - Math.PI / 2));
      arrowHeadVertexData.transform(Matrix.Translation(0, fullCirclePath[0].x - 4, fullCirclePath[0].y + 3));
      let doubleArrowHeadVertexData = undefined;
      if (arrowDirection == 2 /* ArrowDirection.Both */) {
        doubleArrowHeadVertexData = cloneVertexData(this.arrowHead(headDiameter, headHeight, headPoints));
        doubleArrowHeadVertexData.transform(Matrix.Translation(0, headHeight / 2, 0));
        doubleArrowHeadVertexData.transform(Matrix.RotationX(-(Math.PI * 2 * gap - Math.PI / 2)));
        doubleArrowHeadVertexData.transform(Matrix.Translation(0, fullCirclePath[0].x - 4, -fullCirclePath[0].y - 3));
      }
      vertexData.merge(arrowHeadVertexData);
      if (doubleArrowHeadVertexData != null) {
        vertexData.merge(doubleArrowHeadVertexData);
      }
      if (arrowDirection == 1 /* ArrowDirection.In */) {
        const axis = glTemp.vector3[0];
        Vector3.FromFloatsToRef(0, 1, 0, axis);
        const rotateIn = glTemp.matrix[0];
        Matrix.RotationAxisToRef(axis, Math.PI, rotateIn);
        vertexData.transform(rotateIn);
      }
      return vertexData;
    });
  }
  forceArrow(dashed, arrowDirection = 0 /* ArrowDirection.Out */) {
    const id = `${arrowDirection}${dashed ? ':dashed' : ''}`;
    return this.create(`VertexData.forceArrow${id}`, () => {
      return this.arrow(12, CommonCache.forceArrowHeadLenght, 8, 4, CommonCache.forceArrowLineLenght, 6, arrowDirection, dashed);
    });
  }
  stressArrow(dashed, arrowDirection = 0 /* ArrowDirection.Out */) {
    const id = `${arrowDirection}${dashed ? ':dashed' : ''}`;
    return this.create(`VertexData.stressArrow${id}`, () => {
      return this.arrow(12, CommonCache.stressArrowHeadLenght, 8, 4, CommonCache.stressArrowLineLenght, 6, arrowDirection, dashed);
    });
  }
  momentCircleArrow(dashed, arrowDirection = 0 /* ArrowDirection.Out */) {
    const id = `${arrowDirection}${dashed ? ':dashed' : ''}`;
    return this.create(`VertexData.momentCircleArrow${id}`, () => {
      return this.circleArrow(CommonCache.momentCircleArrowDiameter, 32, 12, 15, 8, 4, 6, arrowDirection, dashed);
    });
  }
  cylinder(numberOfPoints, flatShaded, cap = 2 /* Cap.TopBottom */) {
    const id = `${numberOfPoints}:${cap}${flatShaded ? ':flat' : ''}`;
    return this.create(`VertexData.cylinder:${id}`, () => {
      const sideVertexData = this.cylinderSide(numberOfPoints, flatShaded);
      const topVertexData = CreateDiscVertexData({
        radius: CommonCache.cylinderSizeHalf,
        tessellation: numberOfPoints
      });
      let topVertexTransform = this.matrixCache.rotationX90;
      topVertexTransform = topVertexTransform.multiply(this.matrixCache.translateYCylinderSizeHalf);
      topVertexData.transform(topVertexTransform);
      const bottomVertexData = cloneVertexData(topVertexData);
      flipFaces(bottomVertexData, true);
      bottomVertexData.transform(this.matrixCache.translateMinusYCylinderSize);
      const hasTop = cap == null || cap == 2 /* Cap.TopBottom */ || cap == 0 /* Cap.Top */;
      const hasBottom = cap == null || cap == 2 /* Cap.TopBottom */ || cap == 1 /* Cap.Bottom */;
      const vertexData = new VertexData();
      vertexData.positions = [];
      vertexData.indices = [];
      vertexData.normals = [];
      vertexData.uvs = [];
      vertexData.merge(sideVertexData);
      if (hasTop) {
        vertexData.merge(topVertexData);
      }
      if (hasBottom) {
        vertexData.merge(bottomVertexData);
      }
      const sideSection = createSection(sideVertexData);
      let topSection = undefined;
      let bottomSection = undefined;
      if (hasTop) {
        topSection = mergeSection(sideSection, createSection(topVertexData));
      }
      if (hasBottom) {
        if (hasTop && topSection != null) {
          bottomSection = mergeSection(topSection, createSection(bottomVertexData));
        } else {
          bottomSection = mergeSection(sideSection, createSection(bottomVertexData));
        }
      }
      return {
        vertexData,
        side: sideSection,
        top: topSection,
        bottom: bottomSection
      };
    });
  }
  cylinderSide(numberOfPoints, flatShaded, closePath) {
    return this.create(`VertexData.cylinderSide:${numberOfPoints}${flatShaded ? ':flat' : ''}${closePath ? ':closePath' : ''}`, () => {
      const cylinderPoints = this.commonCache.getCylinderPoints(numberOfPoints);
      let vertexData;
      if (flatShaded) {
        vertexData = new VertexData();
        vertexData.positions = [];
        vertexData.indices = [];
        vertexData.normals = [];
        vertexData.uvs = [];
        const sideLength = cylinderPoints.top[1].subtract(cylinderPoints.top[0]).length();
        const sideRadius = Math.sqrt(Math.pow(CommonCache.cylinderSizeHalf, 2) - Math.pow(sideLength / 2, 2));
        let sizeMatrix = Matrix.Scaling(sideLength / CommonCache.boxSize, CommonCache.cylinderSize / CommonCache.boxSize, 1);
        sizeMatrix = sizeMatrix.multiply(Matrix.Translation(0, 0, CommonCache.boxSizeHalf - sideRadius));
        const side = cloneVertexData(this.boxSide);
        side.transform(sizeMatrix);
        let currentSide;
        for (let i = 0; i < cylinderPoints.top.length; i++) {
          currentSide = cloneVertexData(side);
          currentSide.transform(Matrix.RotationY(Math.PI * 2 * (i / cylinderPoints.top.length)));
          vertexData.merge(currentSide);
        }
      } else {
        vertexData = CreateRibbonVertexData({
          pathArray: [cylinderPoints.top, cylinderPoints.bottom],
          closePath
        });
        if (vertexData.indices == null || vertexData.indices instanceof Int32Array || vertexData.indices instanceof Uint32Array || vertexData.indices instanceof Uint16Array) {
          throw new Error('Not implemented');
        }
        // add last side
        if (!closePath) {
          vertexData.indices.push(numberOfPoints - 1);
          vertexData.indices.push(numberOfPoints * 2 - 1);
          vertexData.indices.push(0);
          vertexData.indices.push(0);
          vertexData.indices.push(numberOfPoints * 2 - 1);
          vertexData.indices.push(numberOfPoints);
        }
        // recalculate normals
        if (vertexData.positions != null && vertexData.normals != null) {
          computeNormals(vertexData.positions, vertexData.indices, vertexData.normals);
        }
      }
      return vertexData;
    });
  }
  mitredExtrude(shape, path, close = true) {
    const line = Vector3.Zero();
    const nextLine = Vector3.Zero();
    let axisZ;
    let axisX;
    let axisY;
    let nextAxisX;
    let nextAxisY;
    let nextAxisZ;
    let startPoint;
    const nextStartPoint = Vector3.Zero();
    let planeParallel;
    let planeNormal;
    let plane;
    const bisector = Vector3.Zero();
    let distance = 0;
    let ray;
    const allPaths = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < shape.length; i++) {
      path[1].subtractToRef(path[0], line);
      axisZ = line.clone().normalize();
      axisX = Vector3.Cross(Vector3.One(), axisZ).normalize();
      axisY = Vector3.Cross(axisZ, axisX);
      startPoint = path[0].add(axisX.scale(shape[i].x)).add(axisY.scale(shape[i].y));
      const ribbonPath = [startPoint.clone()];
      for (let j = 0; j < path.length - 2; j++) {
        path[j + 2].subtractToRef(path[j + 1], nextLine);
        nextAxisZ = nextLine.clone().normalize();
        nextAxisX = Vector3.Cross(Vector3.One(), nextAxisZ).normalize();
        nextAxisY = Vector3.Cross(nextAxisZ, nextAxisX);
        nextAxisZ.subtractToRef(axisZ, bisector);
        planeParallel = Vector3.Cross(nextAxisZ, axisZ);
        planeNormal = Vector3.Cross(planeParallel, bisector);
        plane = Plane.FromPositionAndNormal(path[j + 1], planeNormal);
        ray = new Ray(startPoint, axisZ);
        distance = ray.intersectsPlane(plane) ?? 0;
        startPoint.addToRef(axisZ.scale(distance), nextStartPoint);
        ribbonPath.push(nextStartPoint.clone());
        axisX = nextAxisX.clone();
        axisY = nextAxisY.clone();
        axisZ = nextAxisZ.clone();
        startPoint = nextStartPoint.clone();
      }
      // Close shape
      if (close) {
        path[0].subtractToRef(path[path.length - 1], nextLine);
        nextAxisZ = nextLine.clone().normalize();
        nextAxisX = Vector3.Cross(Vector3.One(), nextAxisZ).normalize();
        nextAxisY = Vector3.Cross(nextAxisZ, nextAxisX);
        nextAxisZ.subtractToRef(axisZ, bisector);
        planeParallel = Vector3.Cross(nextAxisZ, axisZ);
        planeNormal = Vector3.Cross(planeParallel, bisector);
        plane = Plane.FromPositionAndNormal(path[path.length - 1], planeNormal);
        ray = new Ray(startPoint, axisZ);
        distance = ray.intersectsPlane(plane) ?? 0;
        startPoint.addToRef(axisZ.scale(distance), nextStartPoint);
        ribbonPath.push(nextStartPoint.clone());
        axisX = nextAxisX.clone();
        axisY = nextAxisY.clone();
        axisZ = nextAxisZ.clone();
        startPoint = nextStartPoint.clone();
        path[1].subtractToRef(path[0], nextLine);
        nextAxisZ = nextLine.clone().normalize();
        nextAxisX = Vector3.Cross(Vector3.One(), nextAxisZ).normalize();
        nextAxisY = Vector3.Cross(nextAxisZ, nextAxisX);
        nextAxisZ.subtractToRef(axisZ, bisector);
        planeParallel = Vector3.Cross(nextAxisZ, axisZ);
        planeNormal = Vector3.Cross(planeParallel, bisector);
        plane = Plane.FromPositionAndNormal(path[0], planeNormal);
        ray = new Ray(startPoint, axisZ);
        distance = ray.intersectsPlane(plane) ?? 0;
        startPoint.addToRef(axisZ.scale(distance), nextStartPoint);
        ribbonPath.shift();
        ribbonPath.unshift(nextStartPoint.clone());
      } else {
        planeNormal = axisZ;
        plane = Plane.FromPositionAndNormal(path[path.length - 1], planeNormal);
        ray = new Ray(startPoint, axisZ);
        distance = ray.intersectsPlane(plane) ?? 0;
        startPoint.addToRef(axisZ.scale(distance), nextStartPoint);
        ribbonPath.push(nextStartPoint.clone());
      }
      allPaths.push(ribbonPath);
    }
    return allPaths;
  }
  stirrup(pathArray, name, scene) {
    const mesh = this.create(`VertexData.stirrup:${name}`, () => {
      return CreateRibbon(name, {
        pathArray: pathArray,
        sideOrientation: Mesh.DOUBLESIDE,
        closeArray: true,
        closePath: true,
        updatable: true
      }, scene);
    });
    return CreateRibbon(name, {
      pathArray: pathArray,
      instance: mesh,
      sideOrientation: Mesh.DOUBLESIDE,
      closeArray: true,
      closePath: true
    }, scene);
  }
  rectangularStirrup(name, numberOfPoints, width, height, diameter, scene) {
    const distanceX = height / 2 - diameter / 2;
    const distanceY = width / 2 - diameter / 2;
    const path = [new Vector3(distanceX, distanceY, 0), new Vector3(distanceX, -distanceY, 0), new Vector3(-distanceX, -distanceY, 0), new Vector3(-distanceX, distanceY, 0)];
    const shape = [];
    diameter = diameter / 2;
    for (let n = 0; n < numberOfPoints; n++) {
      shape.push(new Vector3(diameter * Math.cos(2 * n * Math.PI / numberOfPoints), diameter * Math.sin(2 * n * Math.PI / numberOfPoints), 0));
    }
    const pathArray = this.mitredExtrude(shape, path);
    return this.stirrup(pathArray, name, scene);
  }
  circularStirrup(name, numberOfSegments, numberOfPoints, radius, diameter, scene) {
    const path = [];
    radius = radius / 2;
    for (let n = 0; n < numberOfSegments; n++) {
      path.push(new Vector3(radius * Math.cos(2 * n * Math.PI / numberOfSegments), radius * Math.sin(2 * n * Math.PI / numberOfSegments), 0));
    }
    const shape = [];
    diameter = diameter / 2;
    for (let n = 0; n < numberOfPoints; n++) {
      shape.push(new Vector3(diameter * Math.cos(2 * n * Math.PI / numberOfPoints), diameter * Math.sin(2 * n * Math.PI / numberOfPoints), 0));
    }
    const pathArray = this.mitredExtrude(shape, path);
    return this.stirrup(pathArray, name, scene);
  }
  pipe(numberOfPoints) {
    return this.create(`VertexData.pipe:${numberOfPoints}`, () => {
      // outer
      const outerVertexData = this.cylinderSide(numberOfPoints, false, true);
      // inner
      const innerVertexData = cloneVertexData(outerVertexData);
      innerVertexData.transform(Matrix.Scaling(0.5, 1, 0.5));
      flipFaces(innerVertexData, true);
      // top
      const topVertexData = this.pipeCover(numberOfPoints);
      // bottom
      const bottomVertexData = cloneVertexData(topVertexData);
      bottomVertexData.transform(Matrix.Translation(0, -CommonCache.cylinderSize, 0));
      flipFaces(bottomVertexData, true);
      // vertex data
      const vertexData = new VertexData();
      vertexData.positions = [];
      vertexData.indices = [];
      vertexData.normals = [];
      vertexData.uvs = [];
      vertexData.merge(outerVertexData);
      vertexData.merge(innerVertexData);
      vertexData.merge(topVertexData);
      vertexData.merge(bottomVertexData);
      if (vertexData.indices instanceof Int32Array || vertexData.indices instanceof Uint32Array || vertexData.indices instanceof Uint16Array) {
        throw new Error('Not implemented');
      }
      this.computePipeNormals(vertexData.positions, vertexData.indices, vertexData.normals, numberOfPoints);
      return vertexData;
    });
  }
  pipeCover(numberOfPoints) {
    return this.create(`VertexData.pipeCover:${numberOfPoints}`, () => {
      const points = this.commonCache.getCylinderPoints(numberOfPoints);
      // covert
      const vertexData = CreateRibbonVertexData({
        pathArray: [points.top.map(point => new Vector3(point.x * 0.5, point.y, point.z * 0.5)), points.top],
        closePath: true
      });
      return vertexData;
    });
  }
  sizeBox(positions, width, height, depth, position, positionOffset) {
    position = position ?? Vector3.Zero();
    positionOffset = positionOffset ?? 0;
    const widthHalf = width / 2;
    const heightHalf = height / 2;
    const depthHalf = depth / 2;
    this.sizeBoxWrapper(positions, width, height, depth, position, positionOffset);
    let i = positionOffset + 12 * 4;
    // top
    positions[i] = -widthHalf + position.x;
    positions[i + 1] = heightHalf + position.y;
    positions[i + 2] = depthHalf + position.z;
    positions[i + 3] = widthHalf + position.x;
    positions[i + 4] = heightHalf + position.y;
    positions[i + 5] = depthHalf + position.z;
    positions[i + 6] = -widthHalf + position.x;
    positions[i + 7] = heightHalf + position.y;
    positions[i + 8] = -depthHalf + position.z;
    positions[i + 9] = widthHalf + position.x;
    positions[i + 10] = heightHalf + position.y;
    positions[i + 11] = -depthHalf + position.z;
    i += 12;
    // bottom
    positions[i] = -widthHalf + position.x;
    positions[i + 1] = -heightHalf + position.y;
    positions[i + 2] = -depthHalf + position.z;
    positions[i + 3] = widthHalf + position.x;
    positions[i + 4] = -heightHalf + position.y;
    positions[i + 5] = -depthHalf + position.z;
    positions[i + 6] = -widthHalf + position.x;
    positions[i + 7] = -heightHalf + position.y;
    positions[i + 8] = depthHalf + position.z;
    positions[i + 9] = widthHalf + position.x;
    positions[i + 10] = -heightHalf + position.y;
    positions[i + 11] = depthHalf + position.z;
  }
  sizeBoxWrapper(positions, width, height, depth, position, positionOffset) {
    position = position ?? Vector3.Zero();
    positionOffset = positionOffset ?? 0;
    const widthHalf = width / 2;
    const heightHalf = height / 2;
    const depthHalf = depth / 2;
    let i = positionOffset;
    this.sizeBoxWrapperBack(positions, i, position, widthHalf, heightHalf, depthHalf);
    i += 12;
    this.sizeBoxWrapperRight(positions, i, position, widthHalf, heightHalf, depthHalf);
    i += 12;
    this.sizeBoxWrapperFront(positions, i, position, widthHalf, heightHalf, depthHalf);
    i += 12;
    this.sizeBoxWrapperLeft(positions, i, position, widthHalf, heightHalf, depthHalf);
  }
  sizeBoxWrapperVertexData(vertexDataList, width, height, depth, position, positionOffset) {
    position = position ?? Vector3.Zero();
    positionOffset = positionOffset ?? 0;
    const widthHalf = width / 2;
    const heightHalf = height / 2;
    const depthHalf = depth / 2;
    const i = positionOffset;
    this.sizeBoxWrapperBack(vertexDataList[0].positions, i, position, widthHalf, heightHalf, depthHalf);
    this.sizeBoxWrapperRight(vertexDataList[1].positions, i, position, widthHalf, heightHalf, depthHalf);
    this.sizeBoxWrapperFront(vertexDataList[2].positions, i, position, widthHalf, heightHalf, depthHalf);
    this.sizeBoxWrapperLeft(vertexDataList[3].positions, i, position, widthHalf, heightHalf, depthHalf);
  }
  sizeRectangularHollow(positions, width, depth, thickness) {
    const widthHalf = width / 2;
    const depthHalf = depth / 2;
    const innerWidth = width - thickness * 2;
    const innerWidthHalf = innerWidth / 2;
    const innerDepth = depth - thickness * 2;
    const innerDepthHalf = innerDepth / 2;
    let i = 0;
    // outer
    this.sizeBoxWrapper(positions, width, CommonCache.boxSize, depth, undefined, i);
    i += 12 * 4;
    // inner
    this.sizeBoxWrapper(positions, innerWidth, CommonCache.boxSize, innerDepth, undefined, i);
    i += 12 * 4;
    // top inner
    positions[i] = -innerWidthHalf;
    positions[i + 2] = innerDepthHalf;
    positions[i + 3] = -innerWidthHalf;
    positions[i + 5] = -innerDepthHalf;
    positions[i + 6] = innerWidthHalf;
    positions[i + 8] = -innerDepthHalf;
    positions[i + 9] = innerWidthHalf;
    positions[i + 11] = innerDepthHalf;
    positions[i + 12] = positions[i];
    positions[i + 14] = positions[i + 2];
    i += 15;
    // top outer
    positions[i] = -widthHalf;
    positions[i + 2] = depthHalf;
    positions[i + 3] = -widthHalf;
    positions[i + 5] = -depthHalf;
    positions[i + 6] = widthHalf;
    positions[i + 8] = -depthHalf;
    positions[i + 9] = widthHalf;
    positions[i + 11] = depthHalf;
    positions[i + 12] = positions[i];
    positions[i + 14] = positions[i + 2];
    i += 15;
    // bottom inner
    positions[i] = -innerWidthHalf;
    positions[i + 2] = innerDepthHalf;
    positions[i + 3] = -innerWidthHalf;
    positions[i + 5] = -innerDepthHalf;
    positions[i + 6] = innerWidthHalf;
    positions[i + 8] = -innerDepthHalf;
    positions[i + 9] = innerWidthHalf;
    positions[i + 11] = innerDepthHalf;
    positions[i + 12] = positions[i];
    positions[i + 14] = positions[i + 2];
    i += 15;
    // bottom outer
    positions[i] = -widthHalf;
    positions[i + 2] = depthHalf;
    positions[i + 3] = -widthHalf;
    positions[i + 5] = -depthHalf;
    positions[i + 6] = widthHalf;
    positions[i + 8] = -depthHalf;
    positions[i + 9] = widthHalf;
    positions[i + 11] = depthHalf;
    positions[i + 12] = positions[i];
    positions[i + 14] = positions[i + 2];
  }
  sizeRectangularHollowVertexData(vertexDataList, width, depth, thickness) {
    const widthHalf = width / 2;
    const depthHalf = depth / 2;
    const innerWidth = width - thickness * 2;
    const innerWidthHalf = innerWidth / 2;
    const innerDepth = depth - thickness * 2;
    const innerDepthHalf = innerDepth / 2;
    // outer
    this.sizeBoxWrapperVertexData(vertexDataList.slice(0, 4), width, CommonCache.boxSize, depth, undefined, 0);
    // inner
    this.sizeBoxWrapperVertexData(vertexDataList.slice(4, 8), innerWidth, CommonCache.boxSize, innerDepth, undefined, 0);
    // top inner
    let i = 0;
    const top = vertexDataList[8].positions;
    top[i] = -innerWidthHalf;
    top[i + 2] = innerDepthHalf;
    top[i + 3] = -innerWidthHalf;
    top[i + 5] = -innerDepthHalf;
    top[i + 6] = innerWidthHalf;
    top[i + 8] = -innerDepthHalf;
    top[i + 9] = innerWidthHalf;
    top[i + 11] = innerDepthHalf;
    top[i + 12] = top[i];
    top[i + 14] = top[i + 2];
    i += 15;
    // top outer
    top[i] = -widthHalf;
    top[i + 2] = depthHalf;
    top[i + 3] = -widthHalf;
    top[i + 5] = -depthHalf;
    top[i + 6] = widthHalf;
    top[i + 8] = -depthHalf;
    top[i + 9] = widthHalf;
    top[i + 11] = depthHalf;
    top[i + 12] = top[i];
    top[i + 14] = top[i + 2];
    vertexDataList[8].positions = top;
    // bottom inner
    i = 0;
    const bottom = vertexDataList[9].positions;
    bottom[i] = -innerWidthHalf;
    bottom[i + 2] = innerDepthHalf;
    bottom[i + 3] = -innerWidthHalf;
    bottom[i + 5] = -innerDepthHalf;
    bottom[i + 6] = innerWidthHalf;
    bottom[i + 8] = -innerDepthHalf;
    bottom[i + 9] = innerWidthHalf;
    bottom[i + 11] = innerDepthHalf;
    bottom[i + 12] = bottom[i];
    bottom[i + 14] = bottom[i + 2];
    i += 15;
    // bottom outer
    bottom[i] = -widthHalf;
    bottom[i + 2] = depthHalf;
    bottom[i + 3] = -widthHalf;
    bottom[i + 5] = -depthHalf;
    bottom[i + 6] = widthHalf;
    bottom[i + 8] = -depthHalf;
    bottom[i + 9] = widthHalf;
    bottom[i + 11] = depthHalf;
    bottom[i + 12] = bottom[i];
    bottom[i + 14] = bottom[i + 2];
    vertexDataList[9].positions = bottom;
  }
  sizePipe(numberOfPoints, positions, indices, normals, width, height, thickness) {
    const cylinderSideVertexData = this.cylinderSide(numberOfPoints, false, true);
    const cylinderSideVertexDataPositions = cylinderSideVertexData.positions;
    let i = 0;
    // outer
    const outerWidthScale = height / CommonCache.cylinderSize;
    const outerDepthScale = width / CommonCache.cylinderSize;
    for (let j = 0; j < cylinderSideVertexDataPositions.length; i += 3, j += 3) {
      positions[i] = cylinderSideVertexDataPositions[j] * outerWidthScale;
      positions[i + 2] = cylinderSideVertexDataPositions[j + 2] * outerDepthScale;
    }
    // inner
    const innerWidthScale = (height - thickness * 2) / CommonCache.cylinderSize;
    const innerDepthScale = (width - thickness * 2) / CommonCache.cylinderSize;
    for (let j = 0; j < cylinderSideVertexDataPositions.length; i += 3, j += 3) {
      positions[i] = cylinderSideVertexDataPositions[j] * innerWidthScale;
      positions[i + 2] = cylinderSideVertexDataPositions[j + 2] * innerDepthScale;
    }
    // top inner
    for (let j = 0; j < cylinderSideVertexDataPositions.length / 2; i += 3, j += 3) {
      positions[i] = cylinderSideVertexDataPositions[j] * innerWidthScale;
      positions[i + 2] = cylinderSideVertexDataPositions[j + 2] * innerDepthScale;
    }
    // top outer
    for (let j = 0; j < cylinderSideVertexDataPositions.length / 2; i += 3, j += 3) {
      positions[i] = cylinderSideVertexDataPositions[j] * outerWidthScale;
      positions[i + 2] = cylinderSideVertexDataPositions[j + 2] * outerDepthScale;
    }
    // bottom inner
    for (let j = 0; j < cylinderSideVertexDataPositions.length / 2; i += 3, j += 3) {
      positions[i] = cylinderSideVertexDataPositions[j] * innerWidthScale;
      positions[i + 2] = cylinderSideVertexDataPositions[j + 2] * innerDepthScale;
    }
    // bottom outer
    for (let j = 0; j < cylinderSideVertexDataPositions.length / 2; i += 3, j += 3) {
      positions[i] = cylinderSideVertexDataPositions[j] * outerWidthScale;
      positions[i + 2] = cylinderSideVertexDataPositions[j + 2] * outerDepthScale;
    }
    this.computePipeNormals(positions, indices, normals, numberOfPoints);
  }
  setVertexDataPositionOffset(points, offsetX, OffsetY, offsetZ) {
    for (let i = 0; i < points?.length; i += 3) {
      points[0 + i] += offsetX;
      points[1 + i] += OffsetY;
      points[2 + i] += offsetZ;
    }
  }
  computePipeNormals(positions, indices, normals, numberOfPoints) {
    const cylinderSideVertexData = this.cylinderSide(numberOfPoints, false, true);
    const cylinderSideVertexDataPositions = cylinderSideVertexData.positions;
    const cylinderSideVertexDataIndices = cylinderSideVertexData.indices;
    const cylinderSideVertexDataNormals = cylinderSideVertexData.normals;
    // outer
    computeNormals(positions, indices, normals, {
      start: 0,
      end: cylinderSideVertexDataPositions.length
    }, {
      start: 0,
      end: cylinderSideVertexDataIndices.length
    }, {
      start: 0,
      end: cylinderSideVertexDataNormals.length
    });
    // outer top first point
    normals[0] = 1;
    normals[1] = 0;
    normals[2] = 0;
    // outer top last point
    normals[cylinderSideVertexDataPositions.length / 2 - 3] = 1;
    normals[cylinderSideVertexDataPositions.length / 2 - 2] = 0;
    normals[cylinderSideVertexDataPositions.length / 2 - 1] = 0;
    // outer bottom first point
    normals[cylinderSideVertexDataPositions.length / 2 + 0] = 1;
    normals[cylinderSideVertexDataPositions.length / 2 + 1] = 0;
    normals[cylinderSideVertexDataPositions.length / 2 + 2] = 0;
    // outer bottom last point
    normals[cylinderSideVertexDataPositions.length - 3] = 1;
    normals[cylinderSideVertexDataPositions.length - 2] = 0;
    normals[cylinderSideVertexDataPositions.length - 1] = 0;
    // inner
    computeNormals(positions, indices, normals, {
      start: cylinderSideVertexDataPositions.length,
      end: cylinderSideVertexDataPositions.length * 2
    }, {
      start: cylinderSideVertexDataIndices.length,
      end: cylinderSideVertexDataIndices.length * 2
    }, {
      start: cylinderSideVertexDataNormals.length,
      end: cylinderSideVertexDataNormals.length * 2
    });
    const innerOffset = cylinderSideVertexDataPositions.length;
    // inner top first point
    normals[innerOffset + 0] = -1;
    normals[innerOffset + 1] = 0;
    normals[innerOffset + 2] = 0;
    // inner top last point
    normals[innerOffset + cylinderSideVertexDataPositions.length / 2 - 3] = -1;
    normals[innerOffset + cylinderSideVertexDataPositions.length / 2 - 2] = 0;
    normals[innerOffset + cylinderSideVertexDataPositions.length / 2 - 1] = 0;
    // inner bottom first point
    normals[innerOffset + cylinderSideVertexDataPositions.length / 2 + 0] = -1;
    normals[innerOffset + cylinderSideVertexDataPositions.length / 2 + 1] = 0;
    normals[innerOffset + cylinderSideVertexDataPositions.length / 2 + 2] = 0;
    // inner bottom last point
    normals[innerOffset + cylinderSideVertexDataPositions.length - 3] = -1;
    normals[innerOffset + cylinderSideVertexDataPositions.length - 2] = 0;
    normals[innerOffset + cylinderSideVertexDataPositions.length - 1] = 0;
  }
  calculateCirclePoints(numberOfPoints, radius) {
    const points = [];
    let angle = 0;
    for (let i = 0; i <= numberOfPoints; i++) {
      angle = Math.PI * 2 * (i / numberOfPoints);
      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);
      points.push(new Vector2(x, y));
    }
    return points;
  }
  sizeBoxWrapperBack(points, positionOffset, position, widthHalf, heightHalf, depthHalf) {
    points[positionOffset] = widthHalf + position.x;
    points[positionOffset + 1] = heightHalf + position.y;
    points[positionOffset + 2] = depthHalf + position.z;
    points[positionOffset + 3] = -widthHalf + position.x;
    points[positionOffset + 4] = heightHalf + position.y;
    points[positionOffset + 5] = depthHalf + position.z;
    points[positionOffset + 6] = widthHalf + position.x;
    points[positionOffset + 7] = -heightHalf + position.y;
    points[positionOffset + 8] = depthHalf + position.z;
    points[positionOffset + 9] = -widthHalf + position.x;
    points[positionOffset + 10] = -heightHalf + position.y;
    points[positionOffset + 11] = depthHalf + position.z;
  }
  sizeBoxWrapperRight(points, positionOffset, position, widthHalf, heightHalf, depthHalf) {
    points[positionOffset] = widthHalf + position.x;
    points[positionOffset + 1] = heightHalf + position.y;
    points[positionOffset + 2] = -depthHalf + position.z;
    points[positionOffset + 3] = widthHalf + position.x;
    points[positionOffset + 4] = heightHalf + position.y;
    points[positionOffset + 5] = depthHalf + position.z;
    points[positionOffset + 6] = widthHalf + position.x;
    points[positionOffset + 7] = -heightHalf + position.y;
    points[positionOffset + 8] = -depthHalf + position.z;
    points[positionOffset + 9] = widthHalf + position.x;
    points[positionOffset + 10] = -heightHalf + position.y;
    points[positionOffset + 11] = depthHalf + position.z;
  }
  sizeBoxWrapperFront(points, positionOffset, position, widthHalf, heightHalf, depthHalf) {
    points[positionOffset] = -widthHalf + position.x;
    points[positionOffset + 1] = heightHalf + position.y;
    points[positionOffset + 2] = -depthHalf + position.z;
    points[positionOffset + 3] = widthHalf + position.x;
    points[positionOffset + 4] = heightHalf + position.y;
    points[positionOffset + 5] = -depthHalf + position.z;
    points[positionOffset + 6] = -widthHalf + position.x;
    points[positionOffset + 7] = -heightHalf + position.y;
    points[positionOffset + 8] = -depthHalf + position.z;
    points[positionOffset + 9] = widthHalf + position.x;
    points[positionOffset + 10] = -heightHalf + position.y;
    points[positionOffset + 11] = -depthHalf + position.z;
  }
  sizeBoxWrapperLeft(points, positionOffset, position, widthHalf, heightHalf, depthHalf) {
    points[positionOffset] = -widthHalf + position.x;
    points[positionOffset + 1] = heightHalf + position.y;
    points[positionOffset + 2] = depthHalf + position.z;
    points[positionOffset + 3] = -widthHalf + position.x;
    points[positionOffset + 4] = heightHalf + position.y;
    points[positionOffset + 5] = -depthHalf + position.z;
    points[positionOffset + 6] = -widthHalf + position.x;
    points[positionOffset + 7] = -heightHalf + position.y;
    points[positionOffset + 8] = depthHalf + position.z;
    points[positionOffset + 9] = -widthHalf + position.x;
    points[positionOffset + 10] = -heightHalf + position.y;
    points[positionOffset + 11] = -depthHalf + position.z;
  }
}
