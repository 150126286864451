export class GuidServiceBase {}
export class GuidService extends GuidServiceBase {
  new() {
    return `${this.s4() + this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4() + this.s4() + this.s4()}`;
  }
  removeHyphens(guid) {
    if (!guid) {
      return null;
    }
    return guid.replace(/-/g, '');
  }
  s4() {
    // tslint:disable-next-line:no-bitwise
    return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
  }
}
