<div id="LC-table" class="loads-container">

    <!-- Tooltips -->
    <ng-template #staticLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.staticTooltip"></pe-content-tooltip>
    </ng-template>
    <!-- Tooltips -->

    <div id="load-combinations" class="control loads">

        <!-- Collapsed -->
         @if (collapsed) {
            <div class="loads-header-collapsed">
                <div class="loads-top-header">
                    <div class="collapsed-loads-space-sp"></div>

                    <div class="column-name">
                        <div class="text" [l10n]="titleTranslation"></div>
                    </div>

                    <div class="column-type">
                        <div class="text" l10n="Agito.Hilti.Profis3.Loads.Type"></div>
                    </div>

                    <div class="column-forces" [style.flex]="helper.shearForceFlex">
                        <div class="text" [innerHTML]="shearForceHeader"></div>
                    </div>

                    @if (helper.isMomentVisible()) {
                        <div class="column-moments">
                            <div class="text" [innerHTML]="momentsHeader"></div>
                        </div>
                    }

                    <div class="column-collapse">
                        <button id="LC-table-showhide-arrow-button-1" class="collapse-button" type="button"
                            (click)="collapsed = !collapsed; onCollapsedChanged(); collapsedTooltip.disableTooltip = true"
                            [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n: localizationService.selectedLanguage"
                            #collapsedTooltip="ngbTooltip">
                            <span class="pe-ui-sp-sprite" [class.pe-ui-sp-sprite-lines]="collapsed"
                                [class.pe-ui-sp-sprite-lines-expanded]="!collapsed"></span>
                        </button>
                    </div>
                </div>

                <div class="loads-sub-header">
                    <div class="collapsed-loads-space-sp"></div>

                    <div class="column-name" [ngbTooltip]="loadCombinations[0].loadCombinationName">
                        <div class="text"><span class="clip">{{loadCombinations[0].loadCombinationName}}</span></div>
                    </div>

                    <div class="column-type clip">
                        <div class="text">{{selectedLoadTypeName}}</div>
                    </div>

                    <div class="column-force column-force-1 clip" [style.flex]="helper.shearForceFlex">
                        <div class="text">
                            <span l10n="SP.Loads.Header.Punch.Force.Ved"></span>: {{formatShearForce(selectedLoad.ved)}}
                        </div>
                    </div>

                    @if (helper.isMomentVisible()) {
                        <div class="column-moment column-moment-1 clip">
                            <div class="text">
                                <span l10n="SP.Loads.Header.Punch.Moments.Medx"></span>: {{formatMoment(selectedLoad.medX)}}
                            </div>
                        </div>

                        <div class="column-moment column-moment-2 clip">
                            <div class="text">
                                <span l10n="SP.Loads.Header.Punch.Moments.Medy"></span>: {{formatMoment(selectedLoad.medY)}}
                            </div>
                        </div>
                    }

                    <div class="column-delete"></div>
                </div>
            </div>
        }

        <!-- Expanded. -->
         @if (!collapsed) {
            <div class="resizer-wrapper">
                <div class="loads-header">
                    <div class="header-sizer">
                        <div class="column-selected"></div>

                        <div class="column-rank">
                            <div class="text">N°</div>
                        </div>

                        <div class="column-name">
                            <div class="text" l10n="Agito.Hilti.Profis3.Loads.Name"></div>
                        </div>

                        <div class="column-type" [style.flex-basis.px]="helper.columnTypeWidth">
                            <div class="text" l10n="Agito.Hilti.Profis3.Loads.Type"></div>
                        </div>

                        <div class="column-forces" [style.flex]="helper.shearForceFlex">
                            <div class="text" [innerHTML]="shearForceHeader"></div>
                        </div>

                        @if (helper.isMomentVisible()) {
                            <div class="column-moments">
                                <div class="text" [innerHTML]="momentsHeader"></div>
                            </div>
                        }

                        <div class="column-messages"></div>

                        <div class="column-collapse">
                            <button id="LC-table-showhide-arrow-button-3" class="collapse-button" type="button"
                                (click)="collapsed = !collapsed; onCollapsedChanged(); notCollapsedTooltip.disableTooltip = true"
                                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n: localizationService.selectedLanguage"
                                #notCollapsedTooltip="ngbTooltip">
                                <span class="pe-ui-sp-sprite" [class.pe-ui-sp-sprite-lines]="collapsed"
                                    [class.pe-ui-sp-sprite-lines-expanded]="!collapsed"></span>
                            </button>
                        </div>
                    </div>
                </div>

                <div id="loads-content" class="loads-content concrete">
                    <div class="loads-sub-header">
                        <div class="header-sizer">
                            <div class="column-selected"></div>

                            <div class="column-rank"></div>

                            <div id="LC-table-combination-name-section" class="column-name"></div>

                            <div id="LC-table-load-type-section" class="column-type" [style.flex-basis.px]="helper.columnTypeWidth">
                                <div class="type-buttons btn-group" role="group">
                                    <button id="LC-table-static-loadtype-button" type="button"
                                        class="type-button btn btn-default" [ngbTooltip]="staticLoadTooltip">
                                        <span class="pe-ui-sp-sprite pe-ui-sp-sprite-anchor-shock"></span>
                                    </button>
                                </div>
                            </div>

                            <div id="LC-table-force-1-column" class="column-force column-force-1" [style.flex]="helper.shearForceFlex">
                                <div class="text" l10n="SP.Loads.Header.Punch.Force.Ved"></div>
                            </div>

                            @if (helper.isMomentVisible()) {
                                <div id="LC-table-moment-1-column" class="column-moment column-moment-1">
                                    <div class="text" l10n="SP.Loads.Header.Punch.Moments.Medx"></div>
                                </div>

                                <div id="LC-table-moment-2-column" class="column-moment column-moment-2">
                                    <div class="text" l10n="SP.Loads.Header.Punch.Moments.Medy"></div>
                                </div>
                            }

                            <div class="column-messages"></div>

                            <div class="column-delete"></div>
                        </div>
                    </div>

                    <div class="loads-rows">
                        @for (load of loadCombinations; track $index) {
                            <app-loads-row-punch class="loads-row-component" [parentId]="'load-combinations'"
                                [load]="load" [loadIndex]="$index" [helper]="helper"
                                (onChangeLoad)="onLoadChanged($event)">
                            </app-loads-row-punch>
                        }
                    </div>

                </div>
            </div>
        }
    </div>
</div>
