import { environment } from '../../environments/environmentPe';

export interface IApprovalInfo {
    name: string;
    url: string;
}

export class ApprovalHelper {
    public static readonly defaultApprovalLanguage = 'EN';
    public static readonly approvalUrlPart = 'cdn/pe-ui-pe/approvals/';

    public static getApprovalLanguage(availableLanguages: string[] | undefined | null, culture: string | undefined) {
        if (availableLanguages == null ||availableLanguages.length < 1) {
            return ApprovalHelper.defaultApprovalLanguage;
        }

        const cultureParts = culture?.toUpperCase().split('-');
        let lang = cultureParts != null ? cultureParts[0] : ApprovalHelper.defaultApprovalLanguage;

        if (availableLanguages.find(l => l == lang) == null) {
            lang = ApprovalHelper.defaultApprovalLanguage;
        }

        // Default language should be always available.
        return lang;
    }

    public static getApprovalInfo(language: string, filename: string): IApprovalInfo {
        return {
            name: filename,
            url: `${environment.baseUrl}${ApprovalHelper.approvalUrlPart}${language}/${filename}`
        };
    }
}
