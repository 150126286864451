import kebabCase from 'lodash-es/kebabCase';
import sortBy from 'lodash-es/sortBy';

import {
    Component, Input, NgZone, OnChanges, SimpleChanges, ViewEncapsulation
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import {
    IAddEditDesignComponent, IDetailedDisplayDesignFromModule
} from '@profis-engineering/pe-ui-common/entities/add-edit-design-component';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Unit as UnitItem } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import {
    ISaveDesign, ISaveDesignResult
} from '@profis-engineering/pe-ui-common/entities/save-design';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import {
    DocumentModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import {
    getNumberDecimalSeparator, getNumberGroupSeparator
} from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { ICheckBotProjectInfo } from '../../../shared/components/design-from-checkbot';
import {
    ISteelCalculationDefaultValues, ISteelCalculationInputsData, ISteelCalculationValues,
    setSteelCalculationValuesToDesign, setSteelCalculationValuesToNewDesign
} from '../../../shared/components/steel-calculation-inputs';
import {
    IAdvancedCalculationData, IAdvancedCalculationDefaultValues, IAdvancedCalculationInputs
} from '../../../shared/entities/advanced-calculation-inputs';
import { BrickGroup } from '../../../shared/entities/code-lists/brick-group';
import {
    ConcreteSafetyFactorGammaC
} from '../../../shared/entities/code-lists/concrete-safety-factor-gamma-C';
import { DesignMethodGroup } from '../../../shared/entities/code-lists/design-method-group';
import { DesignStandard } from '../../../shared/entities/code-lists/design-standard';
import { DesignType } from '../../../shared/entities/code-lists/design-type';
import { Region } from '../../../shared/entities/code-lists/region';
import { SteelType } from '../../../shared/entities/code-lists/steel-type';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { DesignPe } from '../../../shared/entities/design-pe';
import {
    detailedDisplayDesignToProjectDesign, IDetailedDisplayDesign, projectDesignToDetailedDisplayDesign
} from '../../../shared/entities/display-design';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    CalculationMethodState, MeasureAnchorPlateMode, MeasureBaseMaterialEdgeFromMode
} from '../../../shared/generated-modules/Hilti.PE.Core.Common.Enums';
import {
    NewDesignDataEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    FeaturePe
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display.Enums';
import {
    AdvancedBaseplateCalculationDataEntity, ProjectDesignBaseEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    BrickDataSources, ConcreteSafetyFactorGammaC as ConcreteSafetyFactorGammaCEnum, DesignMethodGroup as DesignMethodGroupEnum,
    DesignStandard as DesignStandardEnum, DesignType as DesignTypeId, SteelGuideline
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    AdvancedCalculationInputsHelper
} from '../../../shared/helpers/advanced-calculation-inputs-helper';
import { FactorType } from '../../../shared/helpers/app-settings-helper';
import {
    getGuidelineOnlyDescriptionText,
    isDesignStandardEurocodeBased, isHnaBasedDesignStandard
} from '../../../shared/helpers/design-standard-helper';
import { defaultEdgeDistance } from '../../../shared/helpers/edge-distance-helper';
import { getDesignTypeSpecificKey } from '../../../shared/helpers/localization-helper';
import { findSteelCalculationMethod } from '../../../shared/helpers/steel-guideline-helper';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { AppSettingsHelperWithUnit } from '../../helpers/app-settings-helper';
import { AbpService } from '../../services/abp.service';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentService } from '../../services/document.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { showAdvancedPopup } from '../../helpers/design-helper';
import { ModalService } from '../../services/modal.service';

export enum AfterOpenInstruction {
    OpenAdvancedSettings = 1
}

@Component({
    templateUrl: './add-edit-design.component.html',
    styleUrls: ['./add-edit-design.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AddEditDesignComponent implements IAddEditDesignComponent<ICheckBotProjectInfo>, OnChanges {
    @Input()
    public selectedRegionId!: number;

    @Input()
    public addEditType!: AddEditType;

    @Input()
    public designTypeId!: number;

    @Input()
    public afterOpenInstructions: number[] = [];

    @Input()
    public displayDesignType!: DisplayDesignType;

    @Input()
    public checkBotProjectInfo?: ICheckBotProjectInfo;

    @Input()
    public detailedDisplayDesignFromModule?: IDetailedDisplayDesignFromModule;

    @Input()
    public save = (saveDesign: ISaveDesign) => {
        return NgZone.isInAngularZone() ? this.saveInternal(saveDesign) : this.ngZone.run(() => this.saveInternal(saveDesign));
    };


    @Input()
    public submitted!: boolean;
    public isLoaded = false;
    public AddEditType = AddEditType;

    public designStandardId!: number | undefined;
    public designMethodGroupId!: number;
    private _designType!: DesignType;
    public steelCalculationInputsData!: ISteelCalculationInputsData;
    public advancedBaseplateInputsData: IAdvancedCalculationInputs = {
        designTypeId: undefined as unknown as DesignTypeId,
        designStandardId: undefined as unknown as DesignStandardEnum,
        isApplicationSettings: undefined as unknown as boolean,
        selectedRegionId: undefined,
        defaultAdvancedCalculationValues: undefined as unknown as IAdvancedCalculationDefaultValues,
        selectedSteelGuideline: undefined as unknown as SteelGuideline,
        lengthUnit: undefined as unknown as number
    };
    public advancedBaseplateData: IAdvancedCalculationData = {
        alphaCC: undefined,
        concreteInCompressionMethod: undefined,
        divergentIterationsCount: undefined,
        divisionOfArcsOfRHS: undefined,
        divisionOfSurfaceOfCHS: undefined,
        effectiveArea: undefined,
        effectiveAreaAISC: undefined,
        jointCoefficientBj: undefined,
        limitPlasticStrain: undefined,
        loadDistributionAngle: undefined,
        maximumSizeOfElement: undefined,
        minimumSizeOfElement: undefined,
        numberOfAnalysisIterations: undefined,
        numberOfElementsOfEdge: undefined,
        useULSStresses: undefined
    };
    public requiredValidator = Validators.required;
    public steelSafetyFactorGammaM5!: number | undefined;

    // checkboxes
    public etagEnOnlyCheckbox!: CheckboxButtonProps<boolean>;
    public etaDataCheckbox!: CheckboxButtonProps<boolean>;
    public ostInputsDataCheckbox!: CheckboxButtonProps<boolean>;
    // dropdowns
    public lengthLargeDropdown!: DropdownProps<UnitItem>;
    public lengthDropdown!: DropdownProps<UnitItem>;
    public areaDropdown!: DropdownProps<UnitItem>;
    public stressDropdown!: DropdownProps<UnitItem>;
    public forceDropdown!: DropdownProps<UnitItem>;
    public momentDropdown!: DropdownProps<UnitItem>;
    public temperatureDropdown!: DropdownProps<UnitItem>;
    public forcePerLengthDropdown!: DropdownProps<UnitItem>;
    public momentPerLengthDropdown!: DropdownProps<UnitItem>;
    public densityDropdown!: DropdownProps<UnitItem>;
    public stressSmallDropdown!: DropdownProps<UnitItem>;
    public edgeDistanceDisplay!: DropdownProps<number>;
    public concreteMethod!: DropdownProps<number>;
    public concreteSafetyFactorGammaC!: DropdownProps<number>;
    public projectDesignMethodDropdown!: DropdownProps<number>;
    public projectDesignStandardDropdown!: DropdownProps<number>;
    // numeric text-boxes
    public safetyFactorForPermamentLoadsTextBox!: NumericTextBoxProps;
    public safetyFactorForVariableLoadsTextBox!: NumericTextBoxProps;
    public minimumAnchorToProfileDistanceTextBox!: NumericTextBoxProps;
    public minimumConcreteCoverTextBox!: NumericTextBoxProps;
    public handrailDisplacementLimitTextBox!: NumericTextBoxProps;
    public handrailDisplacementLimitBridgesTextBox!: NumericTextBoxProps;
    public anchorPlateFactorTextBox!: NumericTextBoxProps;
    public concreteSafetyFactorGammaCFreeValue!: NumericTextBoxProps;

    public collapseRegion!: {
        MethodAndApprovals: boolean;
        UnitsAndParams: boolean;
        SteelCalculation: boolean;
        ConcreteMethod: boolean;
        AdvancedSettings: boolean;
        SafetyFactors: boolean;
    };

    // TODO: BUDQBP-31668 - only PE design types should be in pe-core
    public DesignTypeId: Record<keyof typeof DesignTypeId, DesignTypeId> = {
        // only valid design types for this module/component are listed here
        Concrete: DesignTypeId.Concrete,
        Handrail: DesignTypeId.Handrail,
        Masonry: DesignTypeId.Masonry,
        MetalDeck: DesignTypeId.MetalDeck,

        Unknown: DesignTypeId.Unknown
    };

    private detailedDisplayDesign!: IDetailedDisplayDesign;
    private readonly appSettingsHelper: AppSettingsHelperWithUnit;

    constructor(
        private readonly localization: LocalizationService,
        private readonly commonCodeList: CommonCodeListService,
        private readonly numberService: NumberService,
        private readonly codeList: CodeListService,
        private readonly userService: UserService,
        private readonly userSettingsService: UserSettingsService,
        private readonly abpService: AbpService,
        private readonly unitService: UnitService,
        private readonly featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private readonly calculationServicePE: CalculationServicePE,
        private readonly sharedEnvironmentService: SharedEnvironmentService,
        private readonly documentService: DocumentService,
        private readonly designTemplateService: DesignTemplateService,
        private readonly ngZone: NgZone,
        private readonly modalService: ModalService
    ) {
        this.appSettingsHelper = new AppSettingsHelperWithUnit(this.localization, this.userSettingsService, this.codeList, this.commonCodeList, this.unitService, this.numberService, sharedEnvironmentService.data?.useDevFeatures ?? false);
    }

    public get isFromCheckBotDisplay() {
        return this.addEditType == AddEditType.addFromCheckbot;
    }

    public get designStandard() {
        return this.designStandards.find((designStandard) => designStandard.id == this.designStandardId);
    }

    public get selectedDesignStandardDescription() {
        if (!this.designStandard) {
            return undefined;
        }

        return getDesignTypeSpecificKey(this.localization, this.designStandard?.descriptionResourceKey ?? '', this.designType.id);
    }

    public get displayDesign() {
        return this.isFromCheckBotDisplay ? undefined : this.checkBotProjectInfo;
    }

    public get designType() {
        if (!this._designType) {
            const desingTypeCodeList = this.codeList.projectCodeLists[ProjectCodeList.DesignType] as DesignType[];
            const designTypeId = this.designTypeId;

            this._designType = desingTypeCodeList.find((desingTypeCodeListItem) => desingTypeCodeListItem.id == designTypeId) as DesignType;
        }

        return this._designType;
    }

    private get design() {
        return this.detailedDisplayDesign;
    }

    public get isForcePerLengthDropdownAvailable() {
        return this.designType.id == DesignTypeId.Concrete
            || this.designType.id == DesignTypeId.Handrail
            || this.designType.id == DesignTypeId.MetalDeck;
    }

    public get isMomentPerLengthDropdownAvailable() {
        return this.designType.id == DesignTypeId.Concrete
            || this.designType.id == DesignTypeId.MetalDeck;
    }

    public get isDensityDropdownAvailable() {
        return this.designType.id == DesignTypeId.Concrete
            || this.designType.id == DesignTypeId.MetalDeck;
    }

    public get isLengthLargeDropdownAvailable() {
        return this.designType.id == DesignTypeId.Handrail;
    }

    public get isStressSmallDropdownAvailable() {
        return this.designType.id == DesignTypeId.Handrail;
    }

    public get isEdgeDistanceAvailable() {
        return this.designType.id != DesignTypeId.Concrete;
    }

    public get isConcreteSafetyFactorGammaCDropdownVisible() {
        if (this.designType.id != DesignTypeId.Concrete
            || this.designStandardId == null
        ) {
            return false;
        }

        if (!isDesignStandardEurocodeBased(this.designStandardId)) {
            return false;
        }

        return true;
    }

    public get isConcreteSafetyFactorGammaCTextBoxFreeValueVisible() {
        return this.userSettingsService.isDefaultEnAndSofaBasedDesignMethod(this.designStandardId ?? 0, this.designMethodGroupId, this.selectedRegionId);
    }

    public get isEnBasedAndSofaBasedMethod() {
        return this.userSettingsService.isDefaultEnAndSofaBasedDesignMethod(this.designStandardId ?? 0, this.designMethodGroupId, this.selectedRegionId);
    }

    public get isDisplacementLimitAvailable() {
        return this.designType.id == DesignTypeId.Handrail;
    }

    public get isHiltiTechnicalDataRelevant() {
        return this.designType.id == DesignTypeId.Masonry &&
            (this.designStandardId == DesignStandardEnum.ETAG || this.designStandardId == DesignStandardEnum.EOTA);
    }

    public get isPermanentOrVariableLoadsDropdownAvailable() {
        if (this.designType.id == DesignTypeId.Handrail) {
            return false;
        }
        return this.designStandardId == DesignStandardEnum.ETAG ||
            this.designStandardId == DesignStandardEnum.EOTA ||
            this.designStandardId == DesignStandardEnum.EC2 ||
            this.designStandardId == DesignStandardEnum.IS ||
            this.designStandardId == DesignStandardEnum.MS ||
            this.designStandardId == DesignStandardEnum.NZ ||
            this.designStandardId == DesignStandardEnum.CN;
    }

    public get isMinimumAnchorToProfileDistanceAvailable() {
        return this.designStandardId != DesignStandardEnum.STO && !isHnaBasedDesignStandard(this.designStandardId);
    }

    public get isDisplacementLimitBridgesAvailable() {
        return this.isDisplacementLimitAvailable
            && this.selectedPeRegion?.isBridgesDisplacementLimitAllowed;
    }

    public get isMinimumConcreteCoverAvailable() {

        return this.designType.id == DesignTypeId.Concrete
            &&
            (
                this.designStandardId == DesignStandardEnum.EC2 || this.designStandardId == DesignStandardEnum.IS
                || this.designStandardId == DesignStandardEnum.MS
                || this.designStandardId == DesignStandardEnum.CN && this.designMethodGroupId != DesignMethodGroupEnum.HiltiJGJ145
            );
    }

    public get areAdvancedCalculationInputsVisible() {
        return this.abpService.isAdvancedCalculationPossible(this.designType.id, this.designStandard as DesignStandard, this.selectedPeRegion)
            || this.abpService.isHandrailAdvancedCalculationPossible(this.designType.id, this.designStandard as DesignStandard, this.selectedPeRegion);
    }

    public get steelCalculation() {
        // if advanced calculation or concrete/handrail with en design standard design
        // then show steel calculation values
        if (this.areAdvancedCalculationInputsVisible) {
            return true;
        }

        switch (this.designType.id) {
            case DesignTypeId.Concrete:
                return this.designStandardId == DesignStandardEnum.ETAG ||
                    this.designStandardId == DesignStandardEnum.EC2 ||
                    this.designStandardId == DesignStandardEnum.IS ||
                    this.designStandardId == DesignStandardEnum.MS ||
                    this.designStandardId == DesignStandardEnum.NZ ||
                    this.designStandardId == DesignStandardEnum.STO ||
                    this.designStandardId == DesignStandardEnum.CN;
            case DesignTypeId.Handrail:
                return this.designStandardId == DesignStandardEnum.ETAG ||
                    this.designStandardId == DesignStandardEnum.EC2;
            case DesignTypeId.Masonry:
            case DesignTypeId.MetalDeck:
                return false;
            default:
                throw new Error('Unsupported design type.');
        }
    }

    public get isEtagEnOnlyCheckboxAvailable() {
        return (this.designType.id == DesignTypeId.Concrete || this.designType.id == DesignTypeId.Handrail)
            &&
            (
                this.designStandardId == DesignStandardEnum.EC2 ||
                this.designStandardId == DesignStandardEnum.IS ||
                this.designStandardId == DesignStandardEnum.MS ||
                this.designStandardId == DesignStandardEnum.NZ ||
                this.designStandardId == DesignStandardEnum.HK ||
                this.designStandardId == DesignStandardEnum.CN && this.designMethodGroupId != DesignMethodGroupEnum.HiltiJGJ145
            )
            && this.designMethodGroupId != DesignMethodGroupEnum.SOFA_Based;
    }

    public get isConcreteSafetyFactorGammaCDropdownDisabled() {
        if (this.designStandardId == DesignStandardEnum.CN && this.designMethodGroupId == DesignMethodGroupEnum.HiltiJGJ145) {
            return true;
        }

        return false;
    }

    public get noHiltiTechnicalDataBrick() {
        return this.selectedPeRegion?.noHiltiTechnicalDataBrick ?? false;
    }

    public get selectedDesignMethodDescription() {
        if (!this.designMethodGroup) {
            return undefined;
        }
        //return this.designMethodGroup.descriptionResourceKey;
        return this.appSettingsHelper.getDesignMethodDescription(this.designMethodGroup, this.designStandardId);

    }

    public get defaultUnitLength() {
        if (this.lengthDropdown.selectedValue) {
            return this.lengthDropdown.selectedValue.id;
        }

        const commonRegion = this.userSettingsService.getCommonRegionById(this.selectedRegionId);
        if (commonRegion?.defaultUnitLength != null) {
            return commonRegion.defaultUnitLength;
        }

        return Unit.None;
    }

    public get isDeflectionLimitsVisible() {
        return this.selectedRegionId == KnownRegion.Hungary;
    }

    public get displacementPlaceHolder() {
        const selectedRegion = this.selectedPeRegion;
        if (selectedRegion == null) {
            return 0;
        }

        if (selectedRegion.isDisplacementLimitAllowed) {
            return 150;
        }

        return selectedRegion.defaultHandrailDisplacement;
    }

    public get displacementLimitUnit() {
        if (this.selectedPeRegion == null || this.selectedPeRegion.isDisplacementLimitAllowed) {
            return Unit.None;
        }

        return this.defaultUnitLength;
    }

    public get concreteCalculationMethodAvailable() {
        return this.areAdvancedCalculationInputsVisible
            && this.steelCalculationInputsData.steelGuideline == SteelGuideline.HK;
    }

    public get isAnchorPlateFactorAvailable() {
        return this.designStandardId != DesignStandardEnum.STO;
    }

    public get isSmartAnchorToggleON() {
        return this.projectDesign?.model[PropertyMetaData.SmartAnchor_Enabled.id];
    }

    private get designMethodGroup() {
        if (!this.designMethodGroupId) {
            return undefined;
        }

        return this.designMethodGroups.find((designMethodGroup) => designMethodGroup.id == this.designMethodGroupId);
    }

    private get designStandards() {
        let designStandards = this.codeList.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[];

        // filter STO
        if (!this.designType.isAllowedInDesignStandardSto) {
            designStandards = designStandards.filter((designStandard) => designStandard.id != DesignStandardEnum.STO);
        }

        // In case of Masonry design type, ETAG design standard is removed for new designs but can be used for old designs
        if (this.designType.id == this.DesignTypeId.Masonry) {
            designStandards = designStandards.filter((designStandard) => designStandard.id != DesignStandardEnum.ETAG);
        }

        // filter design standards by design type and region
        designStandards = designStandards
            .filter((designStandard) => this.designType?.allowedDesignStandardRegions
                ?.some((row) => row.RegionId == this.selectedRegionId &&
                    row.DesignStandards.includes(designStandard.id)));

        return sortBy(designStandards, designStandard => designStandard.sortOrder);
    }

    private get edgeDistanceType() {
        if (this.addEditType != AddEditType.edit) {
            return defaultEdgeDistance(this.designType.id);
        }
        else {
            return this.projectDesign?.projectDesign?.Options.EdgeDistanceDisplayType;
        }
    }

    private get steelType() {
        let steelType: SteelType | undefined;

        if (this.projectDesign?.projectDesign?.AnchorPlate?.SteelTypeId && this.projectDesign?.designData?.designCodeLists) {
            const codeList = this.projectDesign.designData.designCodeLists[DesignCodeList.SteelType] as SteelType[];
            steelType = codeList.find(steelType => steelType.id == this.projectDesign?.projectDesign?.AnchorPlate.SteelTypeId);
        }

        return steelType;
    }

    private get selectedPeRegion() {
        return this.getRegionById(this.selectedRegionId);
    }

    private get steelCalculationValues(): ISteelCalculationValues {
        let values!: ISteelCalculationValues;
        const baseSettings = this.userSettingsService.settings.application.defaults;
        let settings;
        switch (this._designType.id) {
            case DesignTypeId.Concrete:
                {
                    const checkbotSteelSetup = this.checkBotProjectInfo?.Details?.SteelSetup;
                    settings = this.userSettingsService.settings.quickStart.concrete;
                    values = {
                        concreteCapacityFactor: this.getSettingsValue(settings.concreteCapacityFactor.value, baseSettings.anchorPlateFactor.value),
                        materialSafetyFactor: this.getSettingsValue(settings.materialSafetyFactor.value, baseSettings.materialSafetyFactor.value),
                        steelCapacityFactor: checkbotSteelSetup?.SteelCapacityFactor ?? this.getSettingsValue(settings.steelCapacityFactor.value, baseSettings.steelCapacityFactor.value),
                        steelSafetyFactorGammaM0: checkbotSteelSetup?.SteelSafetyFactorGammaM0 ?? this.getSettingsValue(settings.steelSafetyFactorGammaM0.value, baseSettings.steelSafetyFactorGammaM0.value),
                        steelSafetyFactorGammaM1: checkbotSteelSetup?.SteelSafetyFactorGammaM1 ?? this.getSettingsValue(settings.steelSafetyFactorGammaM1.value, baseSettings.steelSafetyFactorGammaM1.value),
                        steelSafetyFactorGammaM2: checkbotSteelSetup?.SteelSafetyFactorGammaM2 ?? this.getSettingsValue(settings.steelSafetyFactorGammaM2.value, baseSettings.steelSafetyFactorGammaM2.value),
                        weldsCapacityFactor: checkbotSteelSetup?.WeldsCapacityFactor ?? this.getSettingsValue(settings.weldsCapacityFactor.value, baseSettings.weldsCapacityFactor.value),
                        inSteelSafetyFactorGammaM0: checkbotSteelSetup?.INSteelSafetyFactorGammaM0 ?? this.getSettingsValue(settings.inSteelSafetyFactorGammaM0.value, baseSettings.inSteelSafetyFactorGammaM0.value),
                        inSteelSafetyFactorGammaM1: checkbotSteelSetup?.INSteelSafetyFactorGammaM1 ?? this.getSettingsValue(settings.inSteelSafetyFactorGammaM1.value, baseSettings.inSteelSafetyFactorGammaM1.value),
                        inSteelSafetyFactorGammaMw: checkbotSteelSetup?.INSteelSafetyFactorGammaMw ?? this.getSettingsValue(settings.inSteelSafetyFactorGammaMw.value, baseSettings.inSteelSafetyFactorGammaMw.value),
                        inSteelSafetyFactorGammaMb: checkbotSteelSetup?.INSteelSafetyFactorGammaMb ?? this.getSettingsValue(settings.inSteelSafetyFactorGammaMb.value, baseSettings.inSteelSafetyFactorGammaMb.value)
                    };
                }
                break;
            case DesignTypeId.Masonry:
                settings = this.userSettingsService.settings.quickStart.masonry;
                values = {
                    concreteCapacityFactor: this.getSettingsValue(null, baseSettings.anchorPlateFactor.value),
                    materialSafetyFactor: this.getSettingsValue(null, baseSettings.materialSafetyFactor.value),
                    steelCapacityFactor: this.getSettingsValue(null, baseSettings.steelCapacityFactor.value),
                    steelSafetyFactorGammaM0: this.getSettingsValue(settings.steelSafetyFactorGammaM0.value, baseSettings.steelSafetyFactorGammaM0.value),
                    steelSafetyFactorGammaM1: this.getSettingsValue(settings.steelSafetyFactorGammaM1.value, baseSettings.steelSafetyFactorGammaM1.value),
                    steelSafetyFactorGammaM2: this.getSettingsValue(settings.steelSafetyFactorGammaM2.value, baseSettings.steelSafetyFactorGammaM2.value),
                    weldsCapacityFactor: this.getSettingsValue(null, baseSettings.weldsCapacityFactor.value),
                    inSteelSafetyFactorGammaM0: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaM0.value),
                    inSteelSafetyFactorGammaM1: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaM1.value),
                    inSteelSafetyFactorGammaMw: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaMw.value),
                    inSteelSafetyFactorGammaMb: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaMb.value)
                };
                break;
            case DesignTypeId.Handrail:
                settings = this.userSettingsService.settings.quickStart.handrail;
                values = {
                    concreteCapacityFactor: this.getSettingsValue(null, baseSettings.anchorPlateFactor.value),
                    materialSafetyFactor: this.getSettingsValue(null, baseSettings.materialSafetyFactor.value),
                    steelCapacityFactor: this.getSettingsValue(null, baseSettings.steelCapacityFactor.value),
                    steelSafetyFactorGammaM0: this.getSettingsValue(settings.steelSafetyFactorGammaM0.value, baseSettings.steelSafetyFactorGammaM0.value),
                    steelSafetyFactorGammaM1: this.getSettingsValue(settings.steelSafetyFactorGammaM1.value, baseSettings.steelSafetyFactorGammaM1.value),
                    steelSafetyFactorGammaM2: this.getSettingsValue(settings.steelSafetyFactorGammaM2.value, baseSettings.steelSafetyFactorGammaM2.value),
                    weldsCapacityFactor: this.getSettingsValue(null, baseSettings.weldsCapacityFactor.value),
                    inSteelSafetyFactorGammaM0: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaM0.value),
                    inSteelSafetyFactorGammaM1: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaM1.value),
                    inSteelSafetyFactorGammaMw: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaMw.value),
                    inSteelSafetyFactorGammaMb: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaMb.value)
                };
                break;
            case DesignTypeId.MetalDeck:
                settings = this.userSettingsService.settings.quickStart.metalDeck;
                values = {
                    concreteCapacityFactor: this.getSettingsValue(null, baseSettings.anchorPlateFactor.value),
                    materialSafetyFactor: this.getSettingsValue(settings.materialSafetyFactor.value, baseSettings.materialSafetyFactor.value),
                    steelCapacityFactor: this.getSettingsValue(null, baseSettings.steelCapacityFactor.value),
                    steelSafetyFactorGammaM0: this.getSettingsValue(settings.steelSafetyFactorGammaM0.value, baseSettings.steelSafetyFactorGammaM0.value),
                    steelSafetyFactorGammaM1: this.getSettingsValue(settings.steelSafetyFactorGammaM1.value, baseSettings.steelSafetyFactorGammaM1.value),
                    steelSafetyFactorGammaM2: this.getSettingsValue(settings.steelSafetyFactorGammaM2.value, baseSettings.steelSafetyFactorGammaM2.value),
                    weldsCapacityFactor: this.getSettingsValue(null, baseSettings.weldsCapacityFactor.value),
                    inSteelSafetyFactorGammaM0: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaM0.value),
                    inSteelSafetyFactorGammaM1: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaM1.value),
                    inSteelSafetyFactorGammaMw: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaMw.value),
                    inSteelSafetyFactorGammaMb: this.getSettingsValue(null, baseSettings.inSteelSafetyFactorGammaMb.value)
                };
                break;
            default:
                throw new Error('Unsupported design type.');
        }
        return values;
    }

    private get designMethodGroups() {
        if (!this.designStandardId) {
            return [] as DesignMethodGroup[];
        }

        let designMethodGroups = (this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[])
            .filter((designMethodGroup) => designMethodGroup.designStandards?.includes(this.designStandardId ?? DesignStandardEnum.Unknown));

        // In case of ETAG/EN guideline only, SOFA_Based should not be available
        if (SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.etagEnOnlyCheckbox)) {
            designMethodGroups = designMethodGroups.filter((designMethodGroup) => designMethodGroup.id != DesignMethodGroupEnum.SOFA_Based);
        }

        // filter design method groups by design type and region
        designMethodGroups = designMethodGroups.filter((designMethodGroup) => this.designType.allowedDesignMethodGroupRegions?.some((row) => row.RegionId == this.selectedRegionId && row.DesignMethodGroups.includes(designMethodGroup.id)));

        if (this.designType.id == DesignTypeId.Masonry) {

            const brickGroup = this.projectDesign ?
                (this.codeList.projectCodeLists[ProjectCodeList.BrickGroup] as BrickGroup[]).find((bg) => bg.id == this.projectDesign?.brickGroup?.id) :
                undefined;

            if (brickGroup && !brickGroup.coveredByETA) {
                // We use ETAG029 just in background for old bricks and doesn't allow to create new designs with it
                designMethodGroups = designMethodGroups.filter((designMethodGroup) => designMethodGroup.id != DesignMethodGroupEnum.TR054);
            }
            else {
                // In case of old bricks we allow ETAG029 but hide TR054
                designMethodGroups = designMethodGroups.filter((designMethodGroup) => designMethodGroup.id != DesignMethodGroupEnum.ETAG029);
            }
        }

        return designMethodGroups;
    }

    private get getConcreteSafetyFactorGammaC() {
        if (this.isConcreteSafetyFactorGammaCDropdownVisible) {
            return this.concreteSafetyFactorGammaC.selectedValue;
        }
        return undefined;
    }

    private get getConcreteSafetyFactorGammaCFreeValue() {
        if (this.isConcreteSafetyFactorGammaCTextBoxFreeValueVisible) {
            return this.concreteSafetyFactorGammaCFreeValue.value ?? PropertyMetaData.Option_ConcreteSafetyFactorGammaCFreeValue.defaultValue;
        }
        return undefined;
    }

    private get openAdvancedSettings() {
        return this.afterOpenInstructions.includes(AfterOpenInstruction.OpenAdvancedSettings);
    }

    private get projectDesign() {
        if (this.detailedDisplayDesignFromModule?.design != null) {
            return this.detailedDisplayDesignFromModule?.design as DesignPe;
        }
        return this.userService.design;
    }

    public get steelCalculationTooltip() {
        if (this.designStandardId == null) {
            return null;
        }

        const designStandardList = [DesignStandardEnum.EC2, DesignStandardEnum.NZ, DesignStandardEnum.MS];
        const isTooltipApplicable = designStandardList.includes(this.designStandardId ?? 0);

        return isTooltipApplicable ? this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.SteelCalculation.Tooltip') : null;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.isLoaded) {
            this.collapseRegion = {
                MethodAndApprovals: this.isFromCheckBotDisplay ? false : this.openAdvancedSettings,
                UnitsAndParams: this.isFromCheckBotDisplay ? false : this.openAdvancedSettings,
                SteelCalculation: this.isFromCheckBotDisplay ? false : this.openAdvancedSettings,
                AdvancedSettings: this.isFromCheckBotDisplay ? true : !this.openAdvancedSettings,
                ConcreteMethod: this.isFromCheckBotDisplay ? true : this.openAdvancedSettings,
                SafetyFactors: this.isFromCheckBotDisplay ? true : this.openAdvancedSettings
            };

            this.initControls();

            this.isLoaded = true;
        }
        else if (changes['selectedRegionId']) {
            this.onRegionChanged();
        }
    }

    public isDesignStandardDisabled(designStandard?: number, designType?: number) {
        if (this.isFromCheckBotDisplay) {
            return true;
        }
        if (!designStandard) {
            return undefined;
        }
        if (!designType) {
            designType = this.designType.id;
        }

        switch (designType) {
            case DesignTypeId.Concrete:
            case DesignTypeId.Handrail:
            case DesignTypeId.MetalDeck:
                return undefined;
            case DesignTypeId.Masonry:
                if (designStandard == DesignStandardEnum.ACI) {
                    return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Masonry_ACI, this.selectedRegionId);
                }
                return undefined;

            default:
                throw new Error('Unknown Design type.');
        }
    }

    public onDesignStandardDropdownSelectedValueChange(designStandardId: number) {
        const oldDesignStandardId = this.projectDesignStandardDropdown.selectedValue;

        if (!this.designStandardId || designStandardId == oldDesignStandardId) {
            return;
        }

        const existingDesignStandard = this.designStandards.find((it) => {
            return it.id == designStandardId;
        });

        // design standard dropdown select value
        if (existingDesignStandard) {
            this.projectDesignStandardDropdown.selectedValue = this.isFromCheckBotDisplay ? this.checkBotProjectInfo?.designStandard?.id ?? designStandardId : designStandardId;
        }
        else {
            this.projectDesignStandardDropdown.selectedValue = this.designStandards[0].id;
        }

        this.onDesignStandardIdChange(this.projectDesignStandardDropdown.selectedValue);
        this.designMethodGroupId = this.projectDesignMethodDropdown.selectedValue ?? DesignMethodGroupEnum.Unknown;

        // set default design method group
        const region = this.selectedPeRegion;
        this.setDefaultDesignMethodGroup(this.designType, region, this.designStandard);

        // Add project design method items
        this.modifyProjectDesignMethodItems();

        this.setConcreteSafetyFactorGammaC(this.selectedRegionId);

        this.setConcreteSafetyFactorGammaCFreeValue();

        if (this.etagEnOnlyCheckbox.items) {
            this.etagEnOnlyCheckbox.items[0].description = getGuidelineOnlyDescriptionText(this.designStandardId, this.localization);
        }
    }

    public onLengthDropdownChange() {
        this.advancedBaseplateInputsData.lengthUnit = this.lengthDropdown.selectedValue?.id as number;
    }

    public getDisplacementLimitMin(isBridgesLimit?: boolean) {
        if (this.selectedPeRegion == null) {
            return 0;
        }

        if (this.selectedPeRegion.isDisplacementLimitAllowed || isBridgesLimit) {
            return 10;
        }

        return 1;
    }

    public getDisplacementLimitMax(isBridgesLimit?: boolean) {
        if (this.selectedPeRegion == null) {
            return 0;
        }

        if (this.selectedPeRegion.isDisplacementLimitAllowed || isBridgesLimit) {
            return 600;
        }

        return 1000;
    }

    private initControls() {
        this.initDetailedDisplayDesign();

        this.initCheckboxOptions();

        this.initUnits();

        this.initSteelCalculationInputsData();

        this.initMaterialAndSafetyFactors();

        this.initDistanceAndDisplacementLimits();

        // Design standard and method need to be set prior to
        // dropdown init as dropdowns need those values!
        this.setDesignStandardAndDefaultDesignMethodGroup();

        // Project design standard and method values dropdown
        // In reverse order since initProjectDesignDropdown() needs projectDesignMethodDropdown!
        this.initProjectDesignMethodDropdown();
        this.initProjectDesignDropdown();

        this.loadDataFromSettingsOrDesign();

        this.setConcreteSafetyFactorGammaC(this.selectedRegionId);

        this.setConcreteSafetyFactorGammaCFreeValue();

        this.setAdvancedBaseplateDataInputs();
    }

    private initDetailedDisplayDesign() {
        if (this.addEditType == AddEditType.edit) {
            let documentDesign: DesignTemplateEntity | IDesignListItem;
            if (this.displayDesignType == DisplayDesignType.template) {
                documentDesign = this.designTemplateService.findById(this.projectDesign.templateId ?? '');
            }
            else {
                documentDesign = this.documentService.findDesignById(this.projectDesign.id);
            }
            this.detailedDisplayDesign = projectDesignToDetailedDisplayDesign(
                this.projectDesign.designData.projectDesign as ProjectDesignBaseEntity,
                documentDesign,
                this.codeList,
                this.commonCodeList,
                this.localization
            );
        }
    }

    private initCheckboxOptions() {
        this.etagEnOnlyCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemId: 'add-edit-design-etag-en-only-checkbox',
            itemText: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.EtagEnOnly'),
            itemDescription: getGuidelineOnlyDescriptionText(this.designStandardId ?? this.design?.designStandard?.id, this.localization)
        });

        this.etaDataCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemId: 'add-edit-design-eta-data-checkbox',
            itemText: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.ETAData'),
        });

        this.ostInputsDataCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemId: 'add-edit-design-ost-inputs-data-checkbox',
            itemText: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.OSTInputsData'),
        });
    }

    private initUnits() {
        this.lengthDropdown = this.createUnitDropdown(
            this.designType.id == DesignTypeId.Handrail
                ? 'LengthSmall'
                : 'Length',
            CommonCodeList.UnitLength,
            this.design?.unitLength
        );
        this.areaDropdown = this.createUnitDropdown(
            'Area',
            CommonCodeList.UnitArea,
            this.design?.unitArea
        );
        this.stressDropdown = this.createUnitDropdown(
            this.designType.id == DesignTypeId.Handrail
                ? 'StressLarge'
                : 'Stress',
            CommonCodeList.UnitStress,
            this.design?.unitStress
        );
        this.forceDropdown = this.createUnitDropdown(
            'Force',
            CommonCodeList.UnitForce,
            this.design?.unitForce
        );
        this.momentDropdown = this.createUnitDropdown(
            'Moment',
            CommonCodeList.UnitMoment,
            this.design?.unitMoment
        );
        this.temperatureDropdown = this.createUnitDropdown(
            'Temperature',
            CommonCodeList.UnitTemperature,
            this.design?.unitTemperature
        );
        this.forcePerLengthDropdown = this.createUnitDropdown(
            'ForcePerLength',
            CommonCodeList.UnitForcePerLength,
            this.design?.unitForcePerLength
        );
        this.momentPerLengthDropdown = this.createUnitDropdown(
            'MomentPerLength',
            CommonCodeList.UnitMomentPerLength,
            this.design?.unitMomentPerLength
        );
        this.densityDropdown = this.createUnitDropdown(
            'Density',
            CommonCodeList.UnitDensity,
            this.design?.unitDensity
        );
        this.lengthLargeDropdown = this.createUnitDropdown(
            'LengthLarge',
            CommonCodeList.UnitLength,
            this.design?.unitLengthLarge
        );
        this.stressSmallDropdown = this.createUnitDropdown(
            'StressSmall',
            CommonCodeList.UnitStress,
            this.design?.unitStressSmall
        );
        this.edgeDistanceDisplay = this.createPeCodelistDropdown(
            ProjectCodeList.EdgeDistanceDisplayType,
            'EdgeDistance',
            this.edgeDistanceType as number
        );
        this.edgeDistanceDisplay.tooltip = {
            title: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.EdgeDistance.Tooltip.Title'),
            content: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.EdgeDistance.Tooltip')
        };

        this.concreteMethod = {
            id: 'add-edit-design-concrete-method-dropdown',
            disabled: true,
            items: [
                {
                    value: 1,
                    text: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.ConcreteCalculationMethod')
                }
            ],
            selectedValue: 1
        };
    }

    private initSteelCalculationInputsData() {
        this.steelCalculationInputsData = {
            steelCalculationValues: this.addEditType == AddEditType.edit ? () => this.design : () => this.steelCalculationValues,
            editDesignSettings: this.addEditType == AddEditType.edit,
            regionId: this.selectedRegionId,
            designType: this.designType.id,
            designStandardId: this.designStandardId ?? DesignStandardEnum.Unknown,
            advancedCalculationInputsData: this.advancedBaseplateInputsData,
            defaultValues: this.getSteelCalcDefaultValues(this.selectedRegionId)
        };
    }

    private initDistanceAndDisplacementLimits() {
        this.minimumAnchorToProfileDistanceTextBox = {
            id: 'minimum-anchor-to-profile-distance',
            title: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MinimumAnchorToProfileDistance'),
            minValue: 0,
            maxValue: 100000
        };
        this.setMinimumAnchorToProfileDistanceTextBoxDefault();

        const minimumConcreteCoverPropertyMetaData = PropertyMetaData.Option_MinimumConcreteCover;
        this.minimumConcreteCoverTextBox = {
            id: 'minimum-concrete-cover',
            title: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MinimumConcreteCover'),
            minValue: minimumConcreteCoverPropertyMetaData.minValue,
            maxValue: minimumConcreteCoverPropertyMetaData.maxValue,
            defaultStepperValue: minimumConcreteCoverPropertyMetaData.defaultValue,
            placeholder: minimumConcreteCoverPropertyMetaData.defaultValue
        };

        this.handrailDisplacementLimitTextBox = {
            id: 'handrail-displacement-limit',
            title: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.DisplacementLimit'),
            infoPopup: {
                content: {
                    componentName: 'pe-deflection-limits'
                },
                options: {
                    size: 'lg'
                }
            },
            prefix: this.selectedPeRegion?.isDisplacementLimitAllowed ? 'L / ' : undefined,
            infoPopupTooltip: this.localization.getString('Agito.Hilti.Profis3.DeflectionLimits.Tooltip')
        };

        this.handrailDisplacementLimitBridgesTextBox = {
            id: 'handrail-displacement-limit-bridges',
            title: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.DisplacementLimit.Bridges'),
            prefix: 'H / ',
            placeholder: 200,
            defaultStepperValue: 200,
        };
    }

    private setDesignStandardAndDefaultDesignMethodGroup() {
        if (this.addEditType == AddEditType.addFromCheckbot || this.addEditType == AddEditType.add) {
            let designStandard = this.getDesignStandardForRegion(this.selectedPeRegion);
            if (designStandard == null && this.designStandards.length > 0) {
                designStandard = this.designStandards[0].id;
            }
            this.onDesignStandardIdChange(designStandard);
        }

        if (this.addEditType == AddEditType.addFromCheckbot) {
            return;
        }

        if (this.addEditType == AddEditType.add) {
            this.setDefaultDesignMethodGroup(this.designType, this.selectedPeRegion, this.designStandard);
            return;
        }

        this.designStandardId = this.design?.designStandard?.id;
        this.designMethodGroupId = this.design?.designMethodGroup?.id ?? DesignMethodGroupEnum.Unknown;
    }

    private initProjectDesignMethodDropdown() {
        this.projectDesignMethodDropdown = {
            id: 'add-edit-design-project-design-method-dropdown',
            title: this.localization.getString('Agito.Profis3.AddEditDesign.Dropdown.DesignMethod.Title'),
            notSelectedText: this.localization.getString('Agito.Profis3.AddEditDesign.Dropdown.DesignMethod.Placeholder'),
            validators: [this.requiredValidator]
        };

        this.modifyProjectDesignMethodItems();
    }

    private initProjectDesignDropdown() {
        this.projectDesignStandardDropdown = {
            id: 'add-edit-design-project-design-standard-dropdown',
            title: this.localization.getString('Agito.Profis3.AddEditDesign.Dropdown.DesignStandard.Title'),
            notSelectedText: this.localization.getString('Agito.Profis3.AddEditDesign.Dropdown.DesignStandard.Placeholder'),
            validators: [this.requiredValidator]
        };

        this.modifyProjectDesignStandardItems(this.addEditType == AddEditType.edit);
    }

    private loadDataFromSettingsOrDesign() {
        if (this.addEditType == AddEditType.add || this.addEditType == AddEditType.addFromCheckbot) {
            this.setDefaultUnits(this.selectedRegionId);
            this.setConcreteInCompressionMethod(this.selectedPeRegion);

            this.resetTechnicalData();

            this.anchorPlateFactorTextBox.value = this.userSettingsService.settings.application.defaults.anchorPlateFactor.value ?? undefined;
            this.safetyFactorForPermamentLoadsTextBox.value = this.userSettingsService.settings.application.defaults.permenentLoads.value ?? undefined;
            this.safetyFactorForVariableLoadsTextBox.value = this.userSettingsService.settings.application.defaults.variableLoads.value ?? undefined;
            this.minimumAnchorToProfileDistanceTextBox.value = this.userSettingsService.settings.application.defaults.minimumAnchorToProfileDistance.value ?? undefined;
            this.handrailDisplacementLimitTextBox.value = this.userSettingsService.settings.quickStart.handrail.displacementLimit.value ?? undefined;
            this.handrailDisplacementLimitBridgesTextBox.value = this.userSettingsService.settings.quickStart.handrail.displacementLimitBridges.value ?? undefined;
            this.steelSafetyFactorGammaM5 = this.userSettingsService.settings.application.defaults.steelSafetyFactorGammaM5.value ?? undefined;
            this.minimumConcreteCoverTextBox.value = this.userSettingsService.settings.application.defaults.minimumConcreteCover.value ?? undefined;
            return;
        }

        this.onDesignStandardIdChange(this.designStandardId);

        const etagEnOnlyChanged = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.etagEnOnlyCheckbox) != this.projectDesign?.projectDesign?.Options.EtagEnOnly;
        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.etagEnOnlyCheckbox, this.projectDesign?.projectDesign?.Options.EtagEnOnly ?? false);

        // Project design method dropdown and etagEnOnly checkbox are co-dependent so recheck dropdown items if there are changes
        if (etagEnOnlyChanged) {
            this.onEtagEnGuidelineOnlyChange();
        }

        if (this.isHiltiTechnicalDataRelevant) {
            SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(
                this.etaDataCheckbox,
                !this.noHiltiTechnicalDataBrick);

            SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(
                this.ostInputsDataCheckbox,
                this.projectDesign?.projectDesign?.Options.OstInputsEnabled ?? false);
        }

        this.anchorPlateFactorTextBox.value = this.projectDesign?.projectDesign?.Options.AnchorPlateFactor;
        this.safetyFactorForPermamentLoadsTextBox.value = this.projectDesign?.projectDesign?.Options.SafetyFactorForPermanentLoads;
        this.safetyFactorForVariableLoadsTextBox.value = this.projectDesign?.projectDesign?.Options.SafetyFactorForVariableLoads;
        this.minimumAnchorToProfileDistanceTextBox.value = this.projectDesign?.projectDesign?.Options.MinimumAnchorToProfileDistance;
        this.handrailDisplacementLimitTextBox.value = this.projectDesign?.projectDesign?.Options.HandrailDisplacementLimit;
        this.handrailDisplacementLimitBridgesTextBox.value = this.projectDesign?.projectDesign?.Options.HandrailDisplacementLimitBridges;
        this.steelSafetyFactorGammaM5 = this.projectDesign?.projectDesign?.Options.SteelSafetyFactorGammaM5;
        this.minimumConcreteCoverTextBox.value = this.projectDesign?.projectDesign?.Options.MinimumConcreteCover;
    }

    private setConcreteInCompressionMethod(region?: Region) {
        if (region == null) {
            return;
        }

        const newConcreteInCompressionMethod = region.defaultConcreteInCompressionMethod;
        if (!this.advancedBaseplateData.concreteInCompressionMethod && newConcreteInCompressionMethod) {
            this.advancedBaseplateData.concreteInCompressionMethod = newConcreteInCompressionMethod;
        }
    }

    private setAdvancedBaseplateDataInputs() {
        let steelGuideline = SteelGuideline.None;
        if (this.selectedPeRegion != null) {
            steelGuideline = findSteelCalculationMethod(this.codeList.projectCodeLists, this.designStandardId, this.selectedPeRegion);
        }

        if (this.addEditType == AddEditType.edit) {
            AdvancedCalculationInputsHelper.loadFromDesign(this.design, this.advancedBaseplateData);
        }
        else {
            this.advancedBaseplateData = AdvancedCalculationInputsHelper.loadAdvancedCalculationData(this.userSettingsService.settings.application.advancedCalculation, this.advancedBaseplateData);
        }
        this.advancedBaseplateInputsData.defaultAdvancedCalculationValues = this.defaultAdvancedCalculationDefaultValues();
        this.advancedBaseplateInputsData.designTypeId = this.designType.id;
        this.advancedBaseplateInputsData.designStandardId = this.designStandardId as number;
        this.advancedBaseplateInputsData.selectedRegionId = this.selectedRegionId;
        this.advancedBaseplateInputsData.isApplicationSettings = false;
        this.advancedBaseplateInputsData.selectedSteelGuideline = this.addEditType == AddEditType.edit ? this.projectDesign.steelGuideline : steelGuideline;
        this.advancedBaseplateInputsData.lengthUnit = this.lengthDropdown.selectedValue?.id as number;

        this.steelCalculationInputsData.steelGuideline = this.advancedBaseplateInputsData.selectedSteelGuideline;
    }

    private defaultAdvancedCalculationDefaultValues() {
        const advancedCalculationDefVal: IAdvancedCalculationDefaultValues = {};

        if (this.addEditType == AddEditType.add || this.addEditType == AddEditType.addFromCheckbot) {
            const region = (this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[]).find((row) => row.id == this.selectedRegionId);
            advancedCalculationDefVal.alphaCC = region?.defaultAlphaCC;
            advancedCalculationDefVal.concreteInCompressionMethod = region?.defaultConcreteInCompressionMethod;
        }
        else if (this.design) {
            const region = (this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[]).find((row) => row.id == this.design.region.id);
            advancedCalculationDefVal.alphaCC = region?.defaultAlphaCC;
            advancedCalculationDefVal.concreteInCompressionMethod = region?.defaultConcreteInCompressionMethod;
        }

        return advancedCalculationDefVal;
    }

    public onEtagEnGuidelineOnlyChange(): void {
        this.modifyProjectDesignMethodItems();
    }

    private resetTechnicalData() {
        let etaData = false;
        let ostInputsData = false;

        if (this.isHiltiTechnicalDataRelevant) {
            etaData = !this.userSettingsService.settings.quickStart.masonry.calculationEtaData.value
                ? true
                : this.userSettingsService.settings.quickStart.masonry.calculationEtaData.value;

            ostInputsData = !this.userSettingsService.settings.quickStart.masonry.calculationOstInputsData.value
                ? true
                : this.userSettingsService.settings.quickStart.masonry.calculationOstInputsData.value;
        }

        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.etaDataCheckbox, etaData);
        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.ostInputsDataCheckbox, ostInputsData);
    }

    private setDefaultUnits(regionId: number) {
        const region = this.userSettingsService.getCommonRegionById(regionId);
        if (region == null) {
            throw new Error('Unknown region!');
        }

        this.setDefaultUnit(this.lengthDropdown,
            CommonCodeList.UnitLength,
            region.defaultUnitLength);

        this.setDefaultUnit(this.lengthLargeDropdown,
            CommonCodeList.UnitLength,
            region.defaultUnitLengthLarge);

        this.setDefaultUnit(this.areaDropdown,
            CommonCodeList.UnitArea,
            region.defaultUnitArea);

        this.setDefaultUnit(this.stressDropdown,
            CommonCodeList.UnitStress,
            region.defaultUnitStress);

        this.setDefaultUnit(this.stressSmallDropdown,
            CommonCodeList.UnitStress,
            region.defaultUnitStressSmall);

        this.setDefaultUnit(this.forceDropdown,
            CommonCodeList.UnitForce,
            region.defaultUnitForce);

        this.setDefaultUnit(this.momentDropdown,
            CommonCodeList.UnitMoment,
            region.defaultUnitMoment);

        this.setDefaultUnit(this.temperatureDropdown,
            CommonCodeList.UnitTemperature,
            region.defaultUnitTemperature);

        this.setDefaultUnit(this.forcePerLengthDropdown,
            CommonCodeList.UnitForcePerLength,
            region.defaultUnitForcePerLength);

        this.setDefaultUnit(this.momentPerLengthDropdown,
            CommonCodeList.UnitMomentPerLength,
            region.defaultUnitMomentPerLength);

        this.setDefaultUnit(this.densityDropdown,
            CommonCodeList.UnitDensity,
            region.defaultUnitDensity);
    }

    private setDefaultUnit(dropdown: DropdownProps<UnitItem>, unitCodeList: CommonCodeList, defaultUnit: Unit | undefined) {
        dropdown.selectedValue = this.commonCodeList.commonCodeLists[unitCodeList].find((codeList) => codeList.id == defaultUnit);
    }

    private modifyProjectDesignStandardItems(init: boolean) {
        this.projectDesignStandardDropdown.items = this.designStandards.map(
            (designStandard) => {
                return {
                    id: `add-edit-design-project-design-standard-dropdown-item-${designStandard.id}`,
                    value: designStandard.id,
                    text: this.localization.getString(getDesignTypeSpecificKey(this.localization, designStandard.nameResourceKey ?? '', this.designType.id)),
                    disabled: this.projectDesignStandardDropdown?.selectedValue != undefined
                        && this.isDesignStandardDisabled(this.projectDesignStandardDropdown.selectedValue)
                };
            }
        );

        if (init) {
            this.projectDesignStandardDropdown.selectedValue = this.designStandardId;
        }
        else {
            this.onDesignStandardDropdownSelectedValueChange(this.designStandardId ?? DesignStandardEnum.Unknown);
        }
    }

    private modifyProjectDesignMethodItems(): void {
        this.projectDesignMethodDropdown.items = this.designMethodGroups.map(
            (designMethodGroup) => {
                return {
                    id: `add-edit-design-project-design-method-dropdown-item-${designMethodGroup.id}`,
                    value: designMethodGroup.id,
                    text: this.appSettingsHelper.getDesignMethodGroupTitle(designMethodGroup, this.designStandardId) ?? ''
                };
            }
        );
        this.onDesignMethodDropdownSelectedValueChange(this.designMethodGroupId);
    }

    private setConcreteSafetyFactorGammaC(regionId: number) {
        if (this.isConcreteSafetyFactorGammaCDropdownVisible) {
            const decimalSeparator = getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettingsService);
            const concreteSafetyFactorGammaCItems = this.codeList.projectCodeLists[ProjectCodeList.ConcreteSafetyFactorGammaC] as ConcreteSafetyFactorGammaC[];

            const items = concreteSafetyFactorGammaCItems
                .filter((item) => item.allowedForRegions.some(i => i == regionId))
                .map((item) => {
                    return {
                        value: item.id,
                        text: `${this.unitService.formatNumber(item.factor as number, this.unitService.getPrecision(Unit.None), null, decimalSeparator)}: ${this.localization.getString(item.nameResourceKey ?? '')}`
                    };
                }
                );
            this.concreteSafetyFactorGammaC.items = (this.designStandardId == DesignStandardEnum.IS && this.designMethodGroupId == DesignMethodGroupEnum.IS_XXX) ? items.filter(x => x.value == ConcreteSafetyFactorGammaCEnum.Default) : items;
            this.concreteSafetyFactorGammaC.selectedValue = (this.designStandardId == DesignStandardEnum.IS && this.designMethodGroupId == DesignMethodGroupEnum.IS_XXX) ? ConcreteSafetyFactorGammaCEnum.Default : (this.projectDesign?.projectDesign?.Options.ConcreteSafetyFactorGammaC ?? PropertyMetaData.Option_ConcreteSafetyFactorGammaC.defaultValue);
            this.concreteSafetyFactorGammaC.disabled = this.isConcreteSafetyFactorGammaCDropdownDisabled;
        }
    }

    private setConcreteSafetyFactorGammaCFreeValue() {
        if (this.isConcreteSafetyFactorGammaCTextBoxFreeValueVisible) {
            this.concreteSafetyFactorGammaCFreeValue.value = this.projectDesign?.projectDesign?.Options.ConcreteSafetyFactorGammaCFreeValue;
            this.concreteSafetyFactorGammaCFreeValue.disabled = this.isConcreteSafetyFactorGammaCDropdownDisabled;
        }
    }

    public onDesignMethodDropdownSelectedValueChange(designMethodGroupId: number) {
        const oldDesignMethodGroupId = this.projectDesignMethodDropdown.selectedValue;

        if (!designMethodGroupId || designMethodGroupId == oldDesignMethodGroupId) {
            return;
        }

        this.setProjectDesignMethod(designMethodGroupId);

        // Some design method groups doesn't support EtagEnOnly -> guideline only
        const etagEnOnlyChecked = this.isEtagEnOnlyCheckboxAvailable
            ? SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.etagEnOnlyCheckbox) ?? false
            : false;

        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.etagEnOnlyCheckbox, etagEnOnlyChecked);

        // Concrete Safety Factor GammaC
        if (this.isConcreteSafetyFactorGammaCDropdownVisible && this.designStandardId == DesignStandardEnum.IS) {
            this.setConcreteSafetyFactorGammaC(this.selectedRegionId);
        }

        if (this.isConcreteSafetyFactorGammaCTextBoxFreeValueVisible) {
            this.setConcreteSafetyFactorGammaCFreeValue();
        }
    }

    private onDesignStandardIdChange(designStandardId: number | undefined) {
        const oldDesignStandardId = this.designStandardId;
        this.designStandardId = designStandardId;

        if (designStandardId !== oldDesignStandardId && designStandardId != undefined) {
            this.setDefaultDesignMethodGroup(this.designType, this.selectedPeRegion, this.designStandard);
        }

        this.steelCalculationInputsData.designStandardId = this.designStandardId ?? DesignStandardEnum.Unknown;
        this.advancedBaseplateInputsData.designStandardId = this.designStandardId as number;
    }

    private setProjectDesignMethod(methodGroupId: number) {
        const designMethodGroups = this.designMethodGroups;

        const designMethod = designMethodGroups.find((it) => {
            return it.id == methodGroupId;
        });


        if (designMethod) {
            this.projectDesignMethodDropdown.selectedValue = designMethod.id;
        }
        else if (methodGroupId == DesignMethodGroupEnum.SOFA_Based) {
            // In case of Russian design standard with SOFA_Based design method show SP63 as selected/visible
            const sp63DesignMethod = designMethodGroups.find((it) => {
                return it.id == DesignMethodGroupEnum.SP63;
            });
            this.projectDesignMethodDropdown.selectedValue = sp63DesignMethod?.id as number;
        }
        else {
            this.projectDesignMethodDropdown.selectedValue = designMethodGroups[0].id;
        }

        this.designMethodGroupId = this.projectDesignMethodDropdown.selectedValue;
    }

    private setDefaultDesignMethodGroup(designType: DesignType, region: Region | undefined, designStandard?: DesignStandard) {
        if (region == null) {
            // Nothing to do.
            return;
        }

        if ((designType.id == DesignTypeId.Concrete || designType.id == DesignTypeId.Handrail)
            && designStandard?.id == DesignStandardEnum.ETAG
            && this.designMethodGroups.some(designMethodGroup => designMethodGroup.id == region.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard)) {

            this.designMethodGroupId = region.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard;
        }
        else if ((designType.id == DesignTypeId.Concrete || designType.id == DesignTypeId.Handrail)
            && (designStandard?.id == DesignStandardEnum.EC2 || designStandard?.id == DesignStandardEnum.CN)
            && this.designMethodGroups.some(designMethodGroup => designMethodGroup.id == region.defaultDesignMethodGroupForEurocodeDesignStandard)) {

            this.designMethodGroupId = region.defaultDesignMethodGroupForEurocodeDesignStandard;
        }
        else if ((designType.id == DesignTypeId.Concrete || designType.id == DesignTypeId.Handrail)
            && designStandard?.id == DesignStandardEnum.NZ
            && this.designMethodGroups.some(designMethodGroup => designMethodGroup.id == region.defaultDesignMethodGroupForNZDesignStandard)) {

            this.designMethodGroupId = region.defaultDesignMethodGroupForNZDesignStandard;
        }
        else if ((designType.id == DesignTypeId.Concrete || designType.id == DesignTypeId.Handrail || designType.id == DesignTypeId.MetalDeck)
            && designStandard?.id == DesignStandardEnum.ACI) {
            this.designMethodGroupId = region.ACIDesignMethodGroup;
        }
        else if (designType.id == DesignTypeId.Concrete && designStandard?.id == DesignStandardEnum.STO) {
            this.designMethodGroupId = region.defaultDesignMethodGroupForRussianDesignStandard;
        }
        else if ((designType.id == DesignTypeId.Concrete || designType.id == DesignTypeId.Handrail)
            && designStandard?.id == DesignStandardEnum.HK) {
            this.designMethodGroupId = region.defaultMethodGroupForHongKongDesignStandard;
        }
        else if ((designType.id == DesignTypeId.Concrete || designType.id == DesignTypeId.Handrail)
            && designStandard?.id == DesignStandardEnum.SATS) {
            this.designMethodGroupId = region.defaultMethodGroupForAustralianDesignStandard;
        }
        else if (designType.id == DesignTypeId.Concrete && designStandard?.id == DesignStandardEnum.CSA) {
            this.designMethodGroupId = region.defaultDesignMethodGroupForCsaDesignStandard;
        }
        else if (designType.id == DesignTypeId.Concrete && designStandard?.id == DesignStandardEnum.IS) {
            this.designMethodGroupId = region.defaultDesignMethodGroupForISDesignStandard;
        }
        else if (designType.id == DesignTypeId.Concrete && designStandard?.id == DesignStandardEnum.MS) {
            this.designMethodGroupId = region.defaultDesignMethodGroupForMSDesignStandard;
        }
        else if (this.designMethodGroups.length > 0) {
            this.designMethodGroupId = this.designMethodGroups[0].id;
        }
    }

    private getDesignStandardForRegion(region?: Region) {
        if (region) {
            let designStandard;

            if (this.designType.id == DesignTypeId.Concrete && region.defaultConcreteDesignStandardId) {
                designStandard = this.designStandards.find((designStandard) => designStandard.id == region.defaultConcreteDesignStandardId);

                // If the default design standard is not enabled yet, then use EC2.
                if (!designStandard && region.defaultConcreteDesignStandardId == DesignStandardEnum.IS) {
                    return DesignStandardEnum.EC2;
                }
            }

            if (this.designType.id == DesignTypeId.Masonry && region.defaultMasonryDesignStandardId) {
                designStandard = this.designStandards.find((designStandard) => designStandard.id == region.defaultMasonryDesignStandardId);
            }

            if (this.designType.id == DesignTypeId.Handrail && region.defaultHandrailDesignStandardId) {
                designStandard = this.designStandards.find((designStandard) => designStandard.id == region.defaultHandrailDesignStandardId);
            }

            if (this.designType.id == DesignTypeId.MetalDeck && region.defaultMetalDeckDesignStandardId) {
                designStandard = this.designStandards.find((designStandard) => designStandard.id == region.defaultMetalDeckDesignStandardId);
            }

            if (designStandard) {
                return designStandard.id;
            }
        }

        const designStandardRegion = this.designType.allowedDesignStandardRegions?.find((row) => row.RegionId == region?.id);
        if (designStandardRegion && designStandardRegion.DesignStandards.length > 0) {
            return designStandardRegion.DesignStandards[0];
        }

        return undefined;
    }

    private setMinimumAnchorToProfileDistanceTextBoxDefault() {
        if (isHnaBasedDesignStandard(this.designStandardId)) {
            const value = this.unitService.convertUnitValueArgsToUnit(DesignPe.minimumAnchorToProfileDistanceInch, Unit.inch, this.unitService.getInternalUnit(UnitGroup.Length));
            this.minimumAnchorToProfileDistanceTextBox.defaultStepperValue = value;
            this.minimumAnchorToProfileDistanceTextBox.placeholder = value;
            return;
        }

        this.minimumAnchorToProfileDistanceTextBox.placeholder = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MinimumAnchorToProfileDistance.Placeholder');
    }

    private createPeCodelistDropdown(codelist: ProjectCodeList, key: string, selectedId: number) {
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        const retVal: DropdownProps<number> = {
            id: `add-edit-design-${kebabCase(key)}-dropdown`,
            title: this.translateDropdownTitle(key),
            items: this.codeList.projectCodeLists[codelist].map((item) => {
                return {
                    value: item.id,
                    text: item.getTranslatedNameText(codeListDeps) ?? ''
                };
            }),
            selectedValue: selectedId
        };
        return retVal;
    }

    private setNumericTexboxDefault(textBox: NumericTextBoxProps, factorType: FactorType) {
        let value: number | undefined = 0;

        switch (factorType) {
            // Default parameters
            case FactorType.DefaultAnchorPlateFactor:
                value = this.selectedPeRegion?.anchorPlateFactor;
                break;
            case FactorType.DefaultSafetyFactorPermanentLoads:
                value = this.selectedPeRegion?.safetyFactorForPermamentLoads;
                break;
            case FactorType.DefaultSafetyFactorVariableLoads:
                value = this.selectedPeRegion?.safetyFactorForVariableLoads;
                break;
        }

        textBox.defaultStepperValue = value;
        textBox.placeholder = value;
    }

    private createUnitDropdown(key: string, codeList: CommonCodeList, selectedValue?: Unit) {
        const retVal: DropdownProps<UnitItem> = {
            id: 'add-edit-design-unit-' + key.toLowerCase() + '-dropdown',
            title: this.translateDropdownTitle(key),
        };

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        retVal.items = (this.commonCodeList.commonCodeLists[codeList] as UnitItem[])
            .filter(u => this.unitService.supportedUnitIds.indexOf(u.id) > -1)
            .map(
                (unit) => {
                    return {
                        value: unit,
                        text: unit.getTranslatedNameText(codeListDeps) ?? ''
                    };
                }
            );

        if (selectedValue && this.unitService.supportedUnitIds.indexOf(selectedValue) > -1) {
            retVal.selectedValue = (this.commonCodeList.commonCodeLists[codeList] as UnitItem[]).find(x => x.id == selectedValue);
        }
        else if (retVal.items.length > 0) {
            retVal.selectedValue = retVal.items[0].value;
        }
        else {
            retVal.selectedValue = undefined;
        }
        return retVal;
    }

    private translateDropdownTitle(key: string): string {
        return this.localization.getString(`Agito.Hilti.Profis3.AddEditDesign.${key}`);
    }

    private getSteelCalcDefaultValues(regionId: number) {
        const regionEntity = (this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[]).find(region => region.id == regionId);

        const defVal: ISteelCalculationDefaultValues = {
            materialSafetyFactor: regionEntity?.defaultMaterialSafetyFactor as number,
            steelSafetyFactorGammaM0: undefined,
            steelSafetyFactorGammaM1: undefined,
            steelSafetyFactorGammaM2: undefined,
            inSteelSafetyFactorGammaM0: PropertyMetaData.Option_INSteelSafetyFactorGammaM0.defaultValue,
            inSteelSafetyFactorGammaM1: PropertyMetaData.Option_INSteelSafetyFactorGammaM1.defaultValue,
            inSteelSafetyFactorGammaMw: PropertyMetaData.Option_INSteelSafetyFactorGammaMw.defaultValue,
            inSteelSafetyFactorGammaMb: PropertyMetaData.Option_INSteelSafetyFactorGammaMb.defaultValue
        };

        if (this.addEditType == AddEditType.edit) {
            if (this.steelType) {
                defVal.steelSafetyFactorGammaM0 = this.steelType.gamma_m0;
                defVal.steelSafetyFactorGammaM1 = this.steelType.gamma_m1;
                defVal.steelSafetyFactorGammaM2 = this.steelType.gamma_m2;
            }
        }

        return defVal;
    }

    private initMaterialAndSafetyFactors() {
        this.anchorPlateFactorTextBox = {
            id: 'anchor-plate-factor',
            title: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.AnchorPlateFactor'),
            tooltip: {
                title: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip.Title'),
                content: this.localization.getString('Agito.Hilti.Profis3.TextBox.SettingsAnchorPlateFactor.Tooltip')
            },
            minValue: 0,
            maxValue: 2
        };
        this.setNumericTexboxDefault(this.anchorPlateFactorTextBox, FactorType.DefaultAnchorPlateFactor);

        this.safetyFactorForPermamentLoadsTextBox = {
            id: 'permanent-loads-factor',
            title: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.SafetyFactorForPermamentLoads'),
            minValue: 0,
            maxValue: 2
        };
        this.setNumericTexboxDefault(this.safetyFactorForPermamentLoadsTextBox, FactorType.DefaultSafetyFactorPermanentLoads);

        this.safetyFactorForVariableLoadsTextBox = {
            id: 'variable-loads-factor',
            title: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.SafetyFactorForVariableLoads'),
            minValue: 0,
            maxValue: 2
        };
        this.setNumericTexboxDefault(this.safetyFactorForVariableLoadsTextBox, FactorType.DefaultSafetyFactorVariableLoads);

        this.concreteSafetyFactorGammaC = {
            id: `add-edit-design-concrete-safety-factor-gamma-c-dropdown`,
            title: this.translateDropdownTitle('ConcreteSafetyFactorGammaC'),
            tooltip: {
                title: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.ConcreteSafetyFactorGammaC.Tooltip.Title'),
                content: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.ConcreteSafetyFactorGammaC.Tooltip')
            }
        };

        this.concreteSafetyFactorGammaCFreeValue = {
            id: 'add-edit-design-concrete-safety-factor-gamma-c-free-value',
            title: this.concreteSafetyFactorGammaC.title,
            tooltip: this.concreteSafetyFactorGammaC.tooltip,
            minValue: PropertyMetaData.Option_ConcreteSafetyFactorGammaCFreeValue.minValue,
            maxValue: PropertyMetaData.Option_ConcreteSafetyFactorGammaCFreeValue.maxValue,
            defaultStepperValue: PropertyMetaData.Option_ConcreteSafetyFactorGammaCFreeValue.defaultValue,
            placeholder: PropertyMetaData.Option_ConcreteSafetyFactorGammaCFreeValue.defaultValue
        };
    }

    private getRegionById(regionId: number) {
        const regionCodeList = this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private onRegionChanged() {
        const peRegion = this.selectedPeRegion;
        const designStandard = this.getDesignStandardForRegion(peRegion);

        // Handle design standard id
        this.onDesignStandardIdChange(designStandard);

        // Adjust project design standard items
        this.modifyProjectDesignStandardItems(false);

        this.steelCalculationInputsData.regionId = this.selectedRegionId;

        if (designStandard) {
            // Handle design method id
            this.setDefaultDesignMethodGroup(this.designType, peRegion, this.designStandard);
            this.modifyProjectDesignMethodItems();
        }

        this.setDefaultUnits(this.selectedRegionId);
        this.steelCalculationInputsData.defaultValues = this.getSteelCalcDefaultValues(this.selectedRegionId);

        // if noHiltiTechnicalDataBrick and region has been changed (not design type changed)
        if (peRegion?.noHiltiTechnicalDataBrick == null || peRegion.noHiltiTechnicalDataBrick) {
            this.resetTechnicalData();
        }

        this.advancedBaseplateInputsData.selectedRegionId = this.selectedRegionId;

        this.setConcreteInCompressionMethod(peRegion);

        this.setConcreteSafetyFactorGammaC(this.selectedRegionId);

        this.setConcreteSafetyFactorGammaCFreeValue();

        // Update region-related defaults
        this.setNumericTexboxDefault(this.anchorPlateFactorTextBox, FactorType.DefaultAnchorPlateFactor);
        this.setNumericTexboxDefault(this.safetyFactorForPermamentLoadsTextBox, FactorType.DefaultSafetyFactorPermanentLoads);
        this.setNumericTexboxDefault(this.safetyFactorForVariableLoadsTextBox, FactorType.DefaultSafetyFactorVariableLoads);
        this.setMinimumAnchorToProfileDistanceTextBoxDefault();
        this.handrailDisplacementLimitTextBox.value = this.checkHandrailDisplacementLimitMinMax(this.handrailDisplacementLimitTextBox.value);
        this.handrailDisplacementLimitTextBox.prefix = peRegion?.isDisplacementLimitAllowed
            ? 'L / '
            : undefined;
        this.handrailDisplacementLimitBridgesTextBox.value = this.checkHandrailDisplacementLimitMinMax(this.handrailDisplacementLimitBridgesTextBox.value, true);
    }

    private checkHandrailDisplacementLimitMinMax(value?: number, isBridgesLimit?: boolean) {
        if (!value) {
            return value;
        }

        const limitMax = this.getDisplacementLimitMax(isBridgesLimit);
        if (value > limitMax) {
            value = limitMax;
        }

        const limitMin = this.getDisplacementLimitMin(isBridgesLimit);
        if (value < limitMin) {
            value = limitMin;
        }

        return value;
    }

    private async saveInternal(saveDesign: ISaveDesign): Promise<ISaveDesignResult> {
        // add or edit
        if ([AddEditType.add, AddEditType.addFromCheckbot].includes(this.addEditType)) {

            const dataEntity: NewDesignDataEntity = {
                DesignType: this.designType.id,
                RegionId: this.selectedRegionId,
                DesignStandard: this.designStandard?.id as number,
                DesignMethodGroup: this.designMethodGroup?.id as number,
                EtaData: (this.isHiltiTechnicalDataRelevant ? SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.etaDataCheckbox) : null) as boolean,
                EtagEnOnly: (this.isEtagEnOnlyCheckboxAvailable ? SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.etagEnOnlyCheckbox) : null) as boolean,
                OstInputsData: (this.isHiltiTechnicalDataRelevant ? SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.ostInputsDataCheckbox) : null) as boolean,
                Language: null as unknown as string,
                NumberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettingsService),
                NumberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettingsService),
                ProjectName: saveDesign.projectName,
                ProjectId: saveDesign.projectId,
                DesignName: saveDesign.designName,
                UnitLength: this.lengthDropdown.selectedValue?.id as number,
                UnitLengthLarge: this.lengthLargeDropdown.selectedValue?.id as number,
                UnitArea: this.areaDropdown.selectedValue?.id as number,
                UnitStress: this.stressDropdown.selectedValue?.id as number,
                UnitStressSmall: this.stressSmallDropdown.selectedValue?.id as number,
                UnitForce: this.forceDropdown.selectedValue?.id as number,
                UnitMoment: this.momentDropdown.selectedValue?.id as number,
                UnitTemperature: this.temperatureDropdown.selectedValue?.id as number,
                UnitForcePerLength: this.forcePerLengthDropdown.selectedValue?.id as number,
                UnitMomentPerLength: this.momentPerLengthDropdown.selectedValue?.id as number,
                UnitDensity: this.densityDropdown.selectedValue?.id as number,
                UserSettingsDefaults: this.userSettingsService.readServerSideSettingsEntity(),
                AnchorPlateFactor: this.anchorPlateFactorTextBox.value as number,
                SafetyFactorForPermamentLoads: this.safetyFactorForPermamentLoadsTextBox.value as number,
                SafetyFactorForVariableLoads: this.safetyFactorForVariableLoadsTextBox.value as number,
                EdgeDistanceDisplayType: this.edgeDistanceDisplay.selectedValue as number,
                MinimumAnchorToProfileDistance: this.minimumAnchorToProfileDistanceTextBox.value as number,
                ConcreteSafetyFactorGammaC: !this.isConcreteSafetyFactorGammaCTextBoxFreeValueVisible ? this.getConcreteSafetyFactorGammaC as number : undefined,
                ConcreteSafetyFactorGammaCFreeValue: this.isConcreteSafetyFactorGammaCTextBoxFreeValueVisible ? this.getConcreteSafetyFactorGammaCFreeValue as number : undefined,
                SteelSafetyFactorGammaM0: null as unknown as number,
                SteelSafetyFactorGammaM1: null as unknown as number,
                SteelSafetyFactorGammaM2: null as unknown as number,
                SteelSafetyFactorGammaM5: this.steelSafetyFactorGammaM5 as number,
                MaterialSafetyFactor: null as unknown as number,
                HandrailDisplacementLimit: this.handrailDisplacementLimitTextBox.value as number,
                AdvancedBaseplateCalculation: null as unknown as AdvancedBaseplateCalculationDataEntity,
                UseDevFeatures: this.sharedEnvironmentService.data?.useDevFeatures ?? false,
                IntegrationDocument: null as unknown as DocumentModel,
                WeldsCapacityFactor: null as unknown as number,
                SteelCapacityFactor: null as unknown as number,
                ConcreteCapacityFactor: null as unknown as number,
                HandrailDisplacementLimitBridges: this.handrailDisplacementLimitBridgesTextBox.value as number,
                INSteelSafetyFactorGammaM0: null as unknown as number,
                INSteelSafetyFactorGammaM1: null as unknown as number,
                INSteelSafetyFactorGammaMw: null as unknown as number,
                INSteelSafetyFactorGammaMb: null as unknown as number,
                CustomPictures: null as unknown as string[],
                SteelGuideLine: null as unknown as SteelGuideline,
                UIVersion: this.sharedEnvironmentService.data?.applicationVersion as string,
                CalculationMethodState: this.userSettingsService.settings.application.advancedCalculation.calculationMethodState.value as CalculationMethodState,
                MinimumConcreteCover: this.minimumConcreteCoverTextBox.value as number,
                CustomerOriginId: this.userService.authentication.customerOriginId as string,
                CountryCode: this.userService.authentication.country as string,
                ForceFreeLicense: this.userSettingsService.settings.application.general.forceFreeLicense.value as boolean,
                MeasureAnchorPlate: MeasureAnchorPlateMode.UsingOverallWidthAndHeight,
                MeasureBaseMaterialEdgeFrom: MeasureBaseMaterialEdgeFromMode.ProfileCenter,
                CreatedWithCheckbot: this.isFromCheckBotDisplay
            };

            if (this.areAdvancedCalculationInputsVisible) {
                dataEntity.AdvancedBaseplateCalculation = AdvancedCalculationInputsHelper.getAdvancedBaseplateEntity(this.advancedBaseplateData);
            }

            setSteelCalculationValuesToNewDesign(dataEntity, this.steelCalculationInputsData);

            // create design
            const designPromise = this.calculationServicePE.createAndOpen(dataEntity);

            return designPromise
                .then((design) => {
                    if (this.isFromCheckBotDisplay) {
                        design.dispose();
                    }
                    else {
                        showAdvancedPopup(design, this.abpService, this.featuresVisibilityInfo, this.modalService);
                    }

                    return {
                        designId: design.id,
                        path: `${UrlPath.main}`,
                        design: design,
                        success: true
                    };
                })
                .catch(() => {
                    this.submitted = false;

                    return {
                        designId: '',
                        path: '',
                        design: null as unknown as Design,
                        success: false
                    };
                });
        }
        else {
            // rename document
            this.design.name = saveDesign.designName;

            if (this.displayDesignType == DisplayDesignType.template) {
                this.design.templateName = saveDesign.designName;
            }
            else {
                // change project
                this.design.projectId = saveDesign.projectId;
                this.design.projectName = saveDesign.projectName;
            }

            this.design.unitLength = this.lengthDropdown.selectedValue?.id as number;
            this.design.unitArea = this.areaDropdown.selectedValue?.id as number;
            this.design.unitStress = this.stressDropdown.selectedValue?.id as number;
            this.design.unitForce = this.forceDropdown.selectedValue?.id as number;
            this.design.unitMoment = this.momentDropdown.selectedValue?.id as number;
            this.design.unitTemperature = this.temperatureDropdown.selectedValue?.id as number;
            this.design.unitDensity = this.densityDropdown.selectedValue?.id as number;

            if (this.isForcePerLengthDropdownAvailable) {
                this.design.unitForcePerLength = this.forcePerLengthDropdown.selectedValue?.id as number;
            }

            if (this.isMomentPerLengthDropdownAvailable) {
                this.design.unitMomentPerLength = this.momentPerLengthDropdown.selectedValue?.id as number;
            }

            if (this.isLengthLargeDropdownAvailable) {
                this.design.unitLengthLarge = this.lengthLargeDropdown.selectedValue?.id as number;
            }

            if (this.isStressSmallDropdownAvailable) {
                this.design.unitStressSmall = this.stressSmallDropdown.selectedValue?.id as number;
            }

            this.design.anchorPlateFactor = this.anchorPlateFactorTextBox.value as number;
            this.design.safetyFactorForPermanentLoads = this.safetyFactorForPermamentLoadsTextBox.value as number;
            this.design.safetyFactorForVariableLoads = this.safetyFactorForVariableLoadsTextBox.value as number;
            this.design.designMethodGroup = this.designMethodGroup as DesignMethodGroup;
            this.design.edgeDistanceDisplayType = this.edgeDistanceDisplay.selectedValue;
            this.design.minimumAnchorToProfileDistance = this.minimumAnchorToProfileDistanceTextBox.value;
            this.design.concreteSafetyFactorGammaC = this.getConcreteSafetyFactorGammaC;
            this.design.concreteSafetyFactorGammaCFreeValue = this.getConcreteSafetyFactorGammaCFreeValue;
            this.design.handrailDisplacementLimit = this.handrailDisplacementLimitTextBox.value;
            this.design.handrailDisplacementLimitBridges = this.handrailDisplacementLimitBridgesTextBox.value;
            this.design.steelSafetyFactorGammaM5 = this.steelSafetyFactorGammaM5;
            this.design.minimumConcreteCover = this.minimumConcreteCoverTextBox.value;

            if (this.designType.id == DesignTypeId.Concrete) {
                this.design.measureAnchorPlate = MeasureAnchorPlateMode.UsingOverallWidthAndHeight;
                this.design.measureBaseMaterialEdgeFrom = MeasureBaseMaterialEdgeFromMode.ProfileCenter;
            }
            if (this.areAdvancedCalculationInputsVisible) {
                AdvancedCalculationInputsHelper.updateDesign(this.design, this.advancedBaseplateData);
            }

            setSteelCalculationValuesToDesign(this.design, this.steelCalculationInputsData);

            this.design.EtagEnOnly = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.etagEnOnlyCheckbox);

            if (this.isHiltiTechnicalDataRelevant) {
                this.design.brickData |= SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.etaDataCheckbox)
                    ? BrickDataSources.ETA
                    : null as unknown as BrickDataSources;

                this.design.ostInputsEnabled = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.ostInputsDataCheckbox);
            }

            if (this.design.displayDesignType == DisplayDesignType.template) {
                // save template
                const templateProjectDesign = detailedDisplayDesignToProjectDesign(saveDesign.projectDesign as ProjectDesignBaseEntity, this.design);
                this.designTemplateService.update({
                    designTemplateDocumentId: saveDesign.designTemplateDocumentId,
                    designTypeId: templateProjectDesign.ProjectDesignType,
                    designStandardId: templateProjectDesign.Options.DesignStandard,
                    regionId: templateProjectDesign.Options.RegionId,
                    templateName: this.design.name,
                    anchorName: saveDesign.anchorName as string,
                    approvalNumber: saveDesign.approvalNumber as string,
                    projectDesign: JSON.stringify(templateProjectDesign),
                    templateFolderId: saveDesign.templateFolderId
                });
            }
            else {
                // save design
                const project = this.documentService.findProjectById(saveDesign.projectId);
                this.userService.changeDesign(project, this.projectDesign);
                this.userService.mapDisplayDesignToDesign(this.design);
            }

            return {
                designId: this.design.id,
                path: `${UrlPath.main}`,
                design: this.projectDesign,
                detailedDisplayDesign: this.design,
                success: true
            } as ISaveDesignResult;
        }
    }

    private getSettingsValue(value1: number | null, value2: number | null, defaultValue: number | undefined = undefined) {
        if (value1 != null) {
            return value1;
        }
        else if (value2 != null) {
            return value2;
        }
        else {
            return defaultValue;
        }
    }
}
