import {
    GenericRebarRegionEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class GenericRebar extends CodeList {
    public diameter?: number;
    public regionId?: number;

    constructor(codeList: GenericRebarRegionEntityC2C) {
        super(codeList);

        if (codeList != null) {
            this.diameter = codeList.diameter;
        }
    }

    public static fromService(codeList: GenericRebarRegionEntityC2C) {
        return new GenericRebar(CodeList.baseFromService('GenericRebarRegionEntityC2C', codeList, {
            id: codeList.id,
            regionId: codeList.regionId,
            diameter: codeList.diameter,
            name: codeList.name,
            displayKey: codeList.displayKey
        }));
    }

    public override cloneDeep(): GenericRebar {
        const codeList = super.cloneDeep() as GenericRebar;

        codeList.diameter = this.diameter;
        codeList.regionId = this.regionId;

        return codeList;
    }
}
