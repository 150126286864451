import { StrengthDesignDetails, UtilizationItem, UtilizationType } from '../../../services/design.service';
import { StrengthBaseUpdate, StrengthModel } from '../../../web-gl/strength-gl-model';
import { strengthPropertyValueChanged, UpdateServices } from '../update';

export class PostInstalledElementUpdate extends StrengthBaseUpdate {
    @strengthPropertyValueChanged('zone1StrengtheningElementDefinition')
    protected updateZone1StrengtheningElementDefinition(zone1StrengtheningElementDefinition: boolean, model: StrengthModel) {
        model.postInstalledElement.zone1StrengtheningElementDefinition = zone1StrengtheningElementDefinition;
    }

    @strengthPropertyValueChanged('zone1InstallationDirectionId')
    protected updateZone1InstallationDirection(zone1InstallationDirectionId: number | null, model: StrengthModel) {
        model.postInstalledElement.zone1InstallationDirectionId = zone1InstallationDirectionId;
    }

    @strengthPropertyValueChanged('zone1SpacingX')
    protected updateZone1SpacingX(zone1SpacingX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone1SpacingX = zone1SpacingX;
    }

    @strengthPropertyValueChanged('zone1SpacingY')
    protected updateZone1SpacingY(zone1SpacingY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone1SpacingY = zone1SpacingY;
    }

    @strengthPropertyValueChanged('zone1MinimumEdgeDistanceX')
    protected updateZone1MinimumEdgeDistanceX(zone1MinimumEdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone1MinimumEdgeDistanceX = zone1MinimumEdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone1MinimumEdgeDistanceY')
    protected updateZone1MinimumEdgeDistanceY(zone1MinimumEdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone1MinimumEdgeDistanceY = zone1MinimumEdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone1EdgeDistanceX')
    protected updateZone1EdgeDistanceX(zone1EdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone1EdgeDistanceX = zone1EdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone1EdgeDistanceY')
    protected updateZone1EdgeDistanceY(zone1EdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone1EdgeDistanceY = zone1EdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone1TransverseEccentricity')
    protected updateZone1TransverseEccentricity(zone1TransverseEccentricity: number | null, model: StrengthModel) {
        model.postInstalledElement.zone1TransverseEccentricity = zone1TransverseEccentricity;
    }

    @strengthPropertyValueChanged('zone2StrengtheningElementDefinition')
    protected updateZone2StrengtheningElementDefinition(zone2StrengtheningElementDefinition: boolean | null, model: StrengthModel) {
        model.postInstalledElement.zone2StrengtheningElementDefinition = zone2StrengtheningElementDefinition;
    }

    @strengthPropertyValueChanged('zone2InstallationDirectionId')
    protected updateZone2InstallationDirection(zone2InstallationDirectionId: number | null, model: StrengthModel) {
        model.postInstalledElement.zone2InstallationDirectionId = zone2InstallationDirectionId;
    }

    @strengthPropertyValueChanged('zone2SpacingX')
    protected updateZone2SpacingX(zone2SpacingX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone2SpacingX = zone2SpacingX;
    }

    @strengthPropertyValueChanged('zone2SpacingY')
    protected updateZone2SpacingY(zone2SpacingY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone2SpacingY = zone2SpacingY;
    }

    @strengthPropertyValueChanged('zone2MinimumEdgeDistanceX')
    protected updateZone2MinimumEdgeDistanceX(zone2MinimumEdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone2MinimumEdgeDistanceX = zone2MinimumEdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone2MinimumEdgeDistanceY')
    protected updateZone2MinimumEdgeDistanceY(zone2MinimumEdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone2MinimumEdgeDistanceY = zone2MinimumEdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone2EdgeDistanceX')
    protected updateZone2EdgeDistanceX(zone2EdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone2EdgeDistanceX = zone2EdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone2EdgeDistanceY')
    protected updateZone2EdgeDistanceY(zone2EdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone2EdgeDistanceY = zone2EdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone2TransverseEccentricity')
    protected updateZone2TransverseEccentricity(zone2TransverseEccentricity: number | null, model: StrengthModel) {
        model.postInstalledElement.zone2TransverseEccentricity = zone2TransverseEccentricity;
    }

    @strengthPropertyValueChanged('zone3StrengtheningElementDefinition')
    protected updateZone3StrengtheningElementDefinition(zone3StrengtheningElementDefinition: boolean | null, model: StrengthModel) {
        model.postInstalledElement.zone3StrengtheningElementDefinition = zone3StrengtheningElementDefinition;
    }

    @strengthPropertyValueChanged('zone3InstallationDirectionId')
    protected updateZone3InstallationDirection(zone3InstallationDirectionId: number | null, model: StrengthModel) {
        model.postInstalledElement.zone3InstallationDirectionId = zone3InstallationDirectionId;
    }

    @strengthPropertyValueChanged('zone3SpacingX')
    protected updateZone3SpacingX(zone3SpacingX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone3SpacingX = zone3SpacingX;
    }

    @strengthPropertyValueChanged('zone3SpacingY')
    protected updateZone3SpacingY(zone3SpacingY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone3SpacingY = zone3SpacingY;
    }

    @strengthPropertyValueChanged('zone3MinimumEdgeDistanceX')
    protected updateZone3MinimumEdgeDistanceX(zone3MinimumEdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone3MinimumEdgeDistanceX = zone3MinimumEdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone3MinimumEdgeDistanceY')
    protected updateZone3MinimumEdgeDistanceY(zone3MinimumEdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone3MinimumEdgeDistanceY = zone3MinimumEdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone3EdgeDistanceX')
    protected updateZone3EdgeDistanceX(zone3EdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone3EdgeDistanceX = zone3EdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone3EdgeDistanceY')
    protected updateZone3EdgeDistanceY(zone3EdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone3EdgeDistanceY = zone3EdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone3TransverseEccentricity')
    protected updateZone3TransverseEccentricity(zone3TransverseEccentricity: number | null, model: StrengthModel) {
        model.postInstalledElement.zone3TransverseEccentricity = zone3TransverseEccentricity;
    }

    @strengthPropertyValueChanged('zone4StrengtheningElementDefinition')
    protected updateZone4StrengtheningElementDefinition(zone4StrengtheningElementDefinition: boolean | null, model: StrengthModel) {
        model.postInstalledElement.zone4StrengtheningElementDefinition = zone4StrengtheningElementDefinition;
    }

    @strengthPropertyValueChanged('zone4InstallationDirectionId')
    protected updateZone4InstallationDirection(zone4InstallationDirectionId: number | null, model: StrengthModel) {
        model.postInstalledElement.zone4InstallationDirectionId = zone4InstallationDirectionId;
    }

    @strengthPropertyValueChanged('zone4SpacingX')
    protected updateZone4SpacingX(zone4SpacingX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone4SpacingX = zone4SpacingX;
    }

    @strengthPropertyValueChanged('zone4SpacingY')
    protected updateZone4SpacingY(zone4SpacingY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone4SpacingY = zone4SpacingY;
    }

    @strengthPropertyValueChanged('zone4MinimumEdgeDistanceX')
    protected updateZone4MinimumEdgeDistanceX(zone4MinimumEdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone4MinimumEdgeDistanceX = zone4MinimumEdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone4MinimumEdgeDistanceY')
    protected updateZone4MinimumEdgeDistanceY(zone4MinimumEdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone4MinimumEdgeDistanceY = zone4MinimumEdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone4EdgeDistanceX')
    protected updateZone4EdgeDistanceX(zone4EdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone4EdgeDistanceX = zone4EdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone4EdgeDistanceY')
    protected updateZone4EdgeDistanceY(zone4EdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone4EdgeDistanceY = zone4EdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone4TransverseEccentricity')
    protected updateZone4TransverseEccentricity(zone4TransverseEccentricity: number | null, model: StrengthModel) {
        model.postInstalledElement.zone4TransverseEccentricity = zone4TransverseEccentricity;
    }

    @strengthPropertyValueChanged('zone5StrengtheningElementDefinition')
    protected updateZone5StrengtheningElementDefinition(zone5StrengtheningElementDefinition: boolean, model: StrengthModel) {
        model.postInstalledElement.zone5StrengtheningElementDefinition = zone5StrengtheningElementDefinition;
    }

    @strengthPropertyValueChanged('zone5InstallationDirectionId')
    protected updateZone5InstallationDirection(zone5InstallationDirectionId: number | null, model: StrengthModel) {
        model.postInstalledElement.zone5InstallationDirectionId = zone5InstallationDirectionId;
    }

    @strengthPropertyValueChanged('zone5SpacingX')
    protected updateZone5SpacingX(zone5SpacingX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone5SpacingX = zone5SpacingX;
    }

    @strengthPropertyValueChanged('zone5SpacingY')
    protected updateZone5SpacingY(zone5SpacingY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone5SpacingY = zone5SpacingY;
    }

    @strengthPropertyValueChanged('zone5MinimumEdgeDistanceX')
    protected updateZone5MinimumEdgeDistanceX(zone5MinimumEdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone5MinimumEdgeDistanceX = zone5MinimumEdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone5MinimumEdgeDistanceY')
    protected updateZone5MinimumEdgeDistanceY(zone5MinimumEdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone5MinimumEdgeDistanceY = zone5MinimumEdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone5EdgeDistanceX')
    protected updateZone5EdgeDistanceX(zone5EdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone5EdgeDistanceX = zone5EdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone5EdgeDistanceY')
    protected updateZone5EdgeDistanceY(zone5EdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone5EdgeDistanceY = zone5EdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone5TransverseEccentricity')
    protected updateZone5TransverseEccentricity(zone5TransverseEccentricity: number | null, model: StrengthModel) {
        model.postInstalledElement.zone5TransverseEccentricity = zone5TransverseEccentricity;
    }

    @strengthPropertyValueChanged('zone6StrengtheningElementDefinition')
    protected updateZone6StrengtheningElementDefinition(zone6StrengtheningElementDefinition: boolean, model: StrengthModel) {
        model.postInstalledElement.zone6StrengtheningElementDefinition = zone6StrengtheningElementDefinition;
    }

    @strengthPropertyValueChanged('zone6InstallationDirectionId')
    protected updateZone6InstallationDirection(zone6InstallationDirectionId: number | null, model: StrengthModel) {
        model.postInstalledElement.zone6InstallationDirectionId = zone6InstallationDirectionId;
    }

    @strengthPropertyValueChanged('zone6SpacingX')
    protected updateZone6SpacingX(zone6SpacingX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone6SpacingX = zone6SpacingX;
    }

    @strengthPropertyValueChanged('zone6SpacingY')
    protected updateZone6SpacingY(zone6SpacingY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone6SpacingY = zone6SpacingY;
    }

    @strengthPropertyValueChanged('zone6MinimumEdgeDistanceX')
    protected updateZone6MinimumEdgeDistanceX(zone6MinimumEdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone6MinimumEdgeDistanceX = zone6MinimumEdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone6MinimumEdgeDistanceY')
    protected updateZone6MinimumEdgeDistanceY(zone6MinimumEdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone6MinimumEdgeDistanceY = zone6MinimumEdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone6EdgeDistanceX')
    protected updateZone6EdgeDistanceX(zone6EdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone6EdgeDistanceX = zone6EdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone6EdgeDistanceY')
    protected updateZone6EdgeDistanceY(zone6EdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone6EdgeDistanceY = zone6EdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone6TransverseEccentricity')
    protected updateZone6TransverseEccentricity(zone6TransverseEccentricity: number | null, model: StrengthModel) {
        model.postInstalledElement.zone6TransverseEccentricity = zone6TransverseEccentricity;
    }

    @strengthPropertyValueChanged('zone7StrengtheningElementDefinition')
    protected updateZone7StrengtheningElementDefinition(zone7StrengtheningElementDefinition: boolean, model: StrengthModel) {
        model.postInstalledElement.zone7StrengtheningElementDefinition = zone7StrengtheningElementDefinition;
    }

    @strengthPropertyValueChanged('zone7InstallationDirectionId')
    protected updateZone7InstallationDirection(zone7InstallationDirectionId: number | null, model: StrengthModel) {
        model.postInstalledElement.zone7InstallationDirectionId = zone7InstallationDirectionId;
    }

    @strengthPropertyValueChanged('zone7SpacingX')
    protected updateZone7SpacingX(zone7SpacingX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone7SpacingX = zone7SpacingX;
    }

    @strengthPropertyValueChanged('zone7SpacingY')
    protected updateZone7SpacingY(zone7SpacingY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone7SpacingY = zone7SpacingY;
    }

    @strengthPropertyValueChanged('zone7MinimumEdgeDistanceX')
    protected updateZone7MinimumEdgeDistanceX(zone7MinimumEdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone7MinimumEdgeDistanceX = zone7MinimumEdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone7MinimumEdgeDistanceY')
    protected updateZone7MinimumEdgeDistanceY(zone7MinimumEdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone7MinimumEdgeDistanceY = zone7MinimumEdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone7EdgeDistanceX')
    protected updateZone7EdgeDistanceX(zone7EdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone7EdgeDistanceX = zone7EdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone7EdgeDistanceY')
    protected updateZone7EdgeDistanceY(zone7EdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone7EdgeDistanceY = zone7EdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone7TransverseEccentricity')
    protected updateZone7TransverseEccentricity(zone7TransverseEccentricity: number | null, model: StrengthModel) {
        model.postInstalledElement.zone7TransverseEccentricity = zone7TransverseEccentricity;
    }

    @strengthPropertyValueChanged('zone8StrengtheningElementDefinition')
    protected updateZone8StrengtheningElementDefinition(zone8StrengtheningElementDefinition: boolean, model: StrengthModel) {
        model.postInstalledElement.zone8StrengtheningElementDefinition = zone8StrengtheningElementDefinition;
    }

    @strengthPropertyValueChanged('zone8InstallationDirectionId')
    protected updateZone8InstallationDirection(zone8InstallationDirectionId: number | null, model: StrengthModel) {
        model.postInstalledElement.zone8InstallationDirectionId = zone8InstallationDirectionId;
    }

    @strengthPropertyValueChanged('zone8SpacingX')
    protected updateZone8SpacingX(zone8SpacingX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone8SpacingX = zone8SpacingX;
    }

    @strengthPropertyValueChanged('zone8SpacingY')
    protected updateZone8SpacingY(zone8SpacingY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone8SpacingY = zone8SpacingY;
    }

    @strengthPropertyValueChanged('zone8MinimumEdgeDistanceX')
    protected updateZone8MinimumEdgeDistanceX(zone8MinimumEdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone8MinimumEdgeDistanceX = zone8MinimumEdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone8MinimumEdgeDistanceY')
    protected updateZone8MinimumEdgeDistanceY(zone8MinimumEdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone8MinimumEdgeDistanceY = zone8MinimumEdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone8EdgeDistanceX')
    protected updateZone8EdgeDistanceX(zone8EdgeDistanceX: number | null, model: StrengthModel) {
        model.postInstalledElement.zone8EdgeDistanceX = zone8EdgeDistanceX;
    }

    @strengthPropertyValueChanged('zone8EdgeDistanceY')
    protected updateZone8EdgeDistanceY(zone8EdgeDistanceY: number | null, model: StrengthModel) {
        model.postInstalledElement.zone8EdgeDistanceY = zone8EdgeDistanceY;
    }

    @strengthPropertyValueChanged('zone8TransverseEccentricity')
    protected updateZone8TransverseEccentricity(zone8TransverseEccentricity: number | null, model: StrengthModel) {
        model.postInstalledElement.zone8TransverseEccentricity = zone8TransverseEccentricity;
    }

    @strengthPropertyValueChanged('withoutNutAndWasher')
    protected updateWithoutNutAndWasher(withoutNutAndWasher: boolean, model: StrengthModel) {
        model.postInstalledElement.withoutNutAndWasher = withoutNutAndWasher;
    }

    @strengthPropertyValueChanged('fastenerId')
    protected updateFastenerId(fastenerId: number, model: StrengthModel, services: UpdateServices) {
        model.postInstalledElement.anchorDiameter = services.dataService.fastenersById[fastenerId].size;
    }

    @strengthPropertyValueChanged('depthOfRecess')
    protected updateDepthOfRecess(depthOfRecess: number, model: StrengthModel) {
        model.postInstalledElement.depthOfRecess = depthOfRecess;
    }

    @strengthPropertyValueChanged()
    protected updateDrillLength(model: StrengthModel, services: UpdateServices, designDetails: StrengthDesignDetails) {
        // If no results are coming from the kernel (e.g. pre-calculation scope checks failing), use a dummy value, e.g. 100 mm
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        const utilizationResult = designDetails.calculateResult?.utilizationResults.find(x => this.findUtilizationResult(x.utilizationItems) != null);
        const drillLength = this.findUtilizationResult(utilizationResult?.utilizationItems) ?? 100;
        model.postInstalledElement.drillLength = drillLength;
    }

    private findUtilizationResult(utilizationItems: UtilizationItem[] | undefined) {
        if (utilizationItems == null) {
            return null;
        }

        return utilizationItems.find(x => x.utilizationType == UtilizationType.StrengthenedMemberDrillLength)?.actualValue;
    }
}
