import {
    ProductFilterEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList, ICodeListC2CConstructor } from './code-list';

export interface IProductFilterConstructor extends ICodeListC2CConstructor {
    filterGroupId: number;
}

export class ProductFilter extends CodeList {
    public filterGroupId?: number;

    constructor(codeList: IProductFilterConstructor) {
        super(codeList);

        if (codeList != null) {
            this.filterGroupId = codeList.filterGroupId;
        }
    }

    public static fromService(codeList: ProductFilterEntityC2C) {
        return new ProductFilter(CodeList.baseFromService<IProductFilterConstructor>('ProductFilterEntity', codeList, {
            filterGroupId: codeList.filterGroupId
        }));
    }

    public override cloneDeep(): ProductFilter {
        const codeList: ProductFilter = super.cloneDeep() as ProductFilter;
        codeList.filterGroupId = this.filterGroupId;

        return codeList;
    }
}
