import { CodeList } from './code-list';
import { BondConditionEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class BondCondition extends CodeList {

    public static fromService(codeList: BondConditionEntityC2C) {
        return new BondCondition(CodeList.baseFromService('BondConditionEntity', codeList));
    }

    public override cloneDeep(): BondCondition {
        return super.cloneDeep() as BondCondition;
    }
}
