export function replace(value, find, replaceWith) {
  return value.replace(new RegExp(escapeRegExp(find), 'g'), replaceWith);
}
export function format(stringFormat, ...args) {
  return stringFormat.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
}
export function formatKeyValue(stringFormat, args) {
  let run = true;
  while (run) {
    run = false;
    stringFormat = stringFormat.replace(/{(.+?)}/g, (match, key) => {
      if (args[key]?.match(/{(.+?)}/g)) {
        run = true;
      }
      return typeof args[key] != 'undefined' ? args[key] : match;
    });
  }
  return stringFormat;
}
export function trim(value, length = 100) {
  if (value != null && typeof value == 'string') {
    return value.length > length ? value.substring(0, length) + ' ...' : value;
  }
  return value;
}
export function sortByUnicode(collection, property) {
  return [...collection].sort((a, b) => a[property].localeCompare(b[property]));
}
export function stringNullOrEmpty(value) {
  return value == null || value == '';
}
function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
}
