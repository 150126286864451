import {
    ConcreteDensityEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class ConcreteDensity extends CodeList {

    public static fromService(codeList: ConcreteDensityEntityC2C) {
        return new ConcreteDensity(CodeList.baseFromService('ConcreteDensityEntity', codeList));
    }

    public override cloneDeep(): ConcreteDensity {
        return super.cloneDeep() as ConcreteDensity;
    }
}
