import { Injectable } from '@angular/core';
import { SignalRConnectionOptions } from '@profis-engineering/pe-ui-common/services/signalr.common';
import { environment } from '../../environments/environmentC2C';
import {
    SignalRServiceC2CBase
} from '../../shared/services/signalr.service.base';
import { SharedEnvironmentService } from './shared-environment.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService extends SignalRServiceC2CBase {
    private baseService!: SignalRServiceC2CBase;

    constructor(
        private readonly sharedEnvironmentService: SharedEnvironmentService
    ) {
        super();
    }

    public get common() {
        return this.baseService.common;
    }

    public setBaseService(baseService: SignalRServiceC2CBase) {
        this.baseService = baseService;
    }

    public setHubConnectionsC2C() {
        const connectionOptions: SignalRConnectionOptions = {
            signalRCoreServerUrl: environment.c2cCalculationServiceSignalRCoreUrl,
            signalRCoreServerHub: 'calculationHub',
            legacySignalRServerUrl: environment.c2cCalculationServiceSignalRServer,
            legacySignalRCoreServerHub: 'CalculationHub',
            accessToken: this.sharedEnvironmentService.data?.accessToken as string,
            signalRCoreInitSessionUrl: this.sharedEnvironmentService.data?.signalRCoreInitSessionUrl as string,
            signalRTimeoutInMilliseconds: this.sharedEnvironmentService.data?.signalRTimeoutInMilliseconds as number,
            useHttpLongPolling: environment.c2cCalculationServiceSignalRCoreUrl == null
        };

        this.common.setHubConnections(connectionOptions);
    }
}
