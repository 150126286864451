import { Injectable } from '@angular/core';
import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { IImportData } from '@profis-engineering/pe-ui-common/entities/import-data';
import {
    IImportDesignProvider, IProjectDesignEntity
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { DesignService, designTypes, ProjectDesign } from './design.service';
import { DocumentService } from './document.service';
import { FeatureVisibilityService } from './features-visibility.service';
import { ZoneService } from './zone';

interface PassData extends IProjectDesignEntity {
    projectDesign: ProjectDesign;
    designName: string;
}

interface PossibleProjectDesign {
    designTypeId?: number;
}

@Injectable({
    providedIn: 'root'
})
export class ImportDesignProviderService implements IImportDesignProvider {
    public path = `/design/`;

    constructor(
        private readonly designService: DesignService,
        private readonly documentService: DocumentService,
        private readonly featureVisibilityService: FeatureVisibilityService,
        private readonly zone: ZoneService
    ) { }

    /** file extensions that we support */
    public getValidFileExtensions(): string[] {
        return ['.pe'];
    }

    /** is our file - return true or false */
    public checkFile(fileContent: string): boolean {
        let projectDesign: PossibleProjectDesign;
        try {
            projectDesign = JSON.parse(fileContent) as PossibleProjectDesign;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catch (error) {
            return false;
        }

        return projectDesign.designTypeId == designTypes.punch.id && this.featureVisibilityService.isFeatureEnabled('SP_ContentFeature_Punch') ||
            projectDesign.designTypeId == designTypes.strength.id && this.featureVisibilityService.isFeatureEnabled('SP_ContentFeature_Strength');
    }

    /** pe-ui just passes the same object to other functions in this file */
    public createProjectDesignEntity(projectDesign: Blob | File, projectName: string, fileNameProposal: string, designName: string, projectDesignContent?: string): Promise<IProjectDesignEntity> {
        // JSON parse check is already done in checkFile
        const passData: PassData = {
            projectDesign: JSON.parse(projectDesignContent!) as ProjectDesign,
            designName: fileNameProposal
        };
        return Promise.resolve(passData);
    }

    /** replace - called when we try to import a design with duplicate name */
    public updateDesignFromExternalFile(oldDesign: IBaseDesign, passData: IProjectDesignEntity): Promise<Design | undefined> {
        // called from pe-ui (main page) and sp-ui (common import component)
        return this.zone.runInZoneIfNeeded(async () => {
            const projectDesign = (passData as PassData).projectDesign;

            const documentDesign = this.documentService.findDesignById(oldDesign.id);
            await this.documentService.openDesignExclusive<ProjectDesign>(documentDesign);

            const result = await this.designService.updateDesignFromProjectDesign({
                designId: documentDesign.id,
                projectId: documentDesign.projectId,
                designName: documentDesign.designName,
                projectDesign,

                immediateRequest: true
            }, {
                // error popup is already shown by pe-ui if this function throws an error
                supressErrorMessage: true
            });

            if (result == null) {
                return undefined;
            }

            return this.designService.createPeDesignObject(result.designDetails, result.trackingDetails, result.convertChanges);
        });
    }

    /** keep as separate design - called when we try to import a design with duplicate name */
    public setDesignName(projectDesignEntity: IProjectDesignEntity, name: string): void {
        // call create design with autogenerate name on duplicate
        (projectDesignEntity as PassData).designName = name;
    }

    /** create the design - not called on replace design */
    public async createDesign(importData: IImportData, projectId: string): Promise<IImportData | undefined> {
        // called from pe-ui (main page) and sp-ui (common import component)
        return this.zone.runInZoneIfNeeded(async () => {
            const projectDesign = (importData.projectDesign as PassData).projectDesign;
            const designName = (importData.projectDesign as PassData).designName;

            // create design
            const result = await this.designService.createDesignFromProjectDesign({
                designName,
                projectId,
                projectDesign
            }, {
                // error popup is already shown by pe-ui if this function throws an error
                supressErrorMessage: true
            });

            if (result == null) {
                return undefined;
            }

            // return wrapper pe design object
            importData.design = this.designService.createPeDesignObject(result.designDetails, result.trackingDetails, result.convertChanges);

            return importData;
        });
    }
}
