import cloneDeep from 'lodash-es/cloneDeep';

import { Component, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { ToggleButtonGroupItem } from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import { getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Unit as UnitCodeListItem } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { replace } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { NumberType } from '@profis-engineering/pe-ui-common/services/number.common';
import { environment } from '../../../environments/environmentPe';
import { DesignPe } from '../../../shared/entities/design-pe';
import { ImportLoadsNumberType } from '../../../shared/enums/import-loads-number-type';
import { UtilizationValueEntity } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import { ImportLoadCombinations, LoadCombination, UIProperty } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignStandard as DesignStandardEnum, DesignType, LoadCharacteristic, LoadType, MetalDeckAnchorPosition
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { getDesignStandardNameByDesignStandardId } from '../../../shared/helpers/design-standard-helper';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { areAllLoadsForLedgerAngleAvailable, concatFilterLoadCombinations } from '../../helpers/load-combination-helper';
import { InternalLoadType, LoadsComponentHelper } from '../../helpers/loads-component-helper';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { GuidService } from '../../services/guid.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { getSpriteAsIconStyle } from '../../sprites';

const keyTab = 'Tab';

interface IParseResponse {
    loads: LoadCombination[];
    limitReached: boolean;
}

export enum ImportLoadsView {
    copyPaste,
    import
}

interface ILoadsDropdownHeader {
    index: number;
    class: string;
    dropdown: DropdownProps<ImportLoadsNumberType>;
}

@Component({
    templateUrl: './import-loads.component.html',
    styleUrls: ['./import-loads.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ImportLoadsComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;
    public view = ImportLoadsView.copyPaste;
    public importData!: string | null;
    public importedLoads: LoadCombination[] = [];
    public limitReached!: boolean;

    public forceUnitDropdown!: DropdownProps<Unit>;
    public momentUnitDropdown!: DropdownProps<Unit>;
    public loadsDropdownHeaders: ILoadsDropdownHeader[] = [];
    public loadTypeToggleItems!: ToggleButtonGroupItem<InternalLoadType>[];
    public helper: LoadsComponentHelper;

    public importedLoadRowHeight = 31;      // must be the same value as in css
    public scrollElement!: Element;

    // constants
    public viewEnum = ImportLoadsView;
    public loadTypeEnum = InternalLoadType;

    private isPasted = false;
    private copyPasteInput!: HTMLInputElement;
    private pendingSave = false;
    public requiredValidator = Validators.required;

    constructor(
        public localization: LocalizationService,
        modal: ModalService,
        private readonly user: UserService,
        private readonly userSettings: UserSettingsService,
        private readonly commonCodeList: CommonCodeListService,
        private readonly unit: UnitService,
        private readonly numberService: NumberService,
        private readonly guid: GuidService,
        private readonly calculationService: CalculationServicePE,
        translationFormatService: TranslationFormatService,
        private readonly elementRef: ElementRef<HTMLElement>
    ) {
        this.helper = new LoadsComponentHelper(
            localization,
            unit,
            modal,
            translationFormatService,
            guid,
            user.design as unknown as DesignPe,
            environment.maxLoadCombinations
        );
    }

    public get design() {
        return this.user.design;
    }

    public get hasSustainedLoads() {
        return (this.design.model[PropertyMetaData.Loads_ImportLoadCombinations.id] as ImportLoadCombinations).HasSustainedLoads;
    }

    // Moments should be hidden if metalDeckAnchorPosition is not set to top position
    public get areMomentsForMetalDeckAvailable() {
        return this.design.designType.id != DesignType.MetalDeck ?
            true :
            (this.design.model[UIProperty.BaseMaterial_MetalDeckAnchorPosition] as MetalDeckAnchorPosition) == MetalDeckAnchorPosition.Top;
    }

    public get areAllLoadsForLedgerAngleAvailable() {
        return areAllLoadsForLedgerAngleAvailable(this.design.region.hubId, this.design.isCBFEMCalculation, this.design.isLedgerAngle);
    }

    public get hasImportedLoads() {
        return this.importedLoads.length > 0;
    }

    private get isDynamicFatigueExpertMode() {
        return this.design.isDynamicFatigue && this.design.isFatigueExpertMode;
    }

    private get staticTooltipTitle() {
        return this.translate('Agito.Hilti.Profis3.LoadType.Static.Tooltip.Title');
    }

    private get staticTooltip() {
        return this.translate('Agito.Hilti.Profis3.LoadType.Static.Tooltip.' + getDesignStandardNameByDesignStandardId(this.design.designStandard?.id ?? 0));
    }

    private get seismicTooltipTitle() {
        return this.design.designType.id == DesignType.Masonry && this.design.designStandard?.id == DesignStandardEnum.ACI
            ? this.translate('Agito.Hilti.Profis3.LoadType.Seismic.TooltipTitle.Masonry.ACI')
            : this.translate('Agito.Hilti.Profis3.LoadType.Seismic.Tooltip.Title');
    }

    private get seismicTooltip() {
        return this.design.designType.id == DesignType.Masonry && this.design.designStandard?.id == DesignStandardEnum.ACI
            ? this.translate('Agito.Hilti.Profis3.LoadType.Seismic.Tooltip.Masonry.ACI')
            : this.translate('Agito.Hilti.Profis3.LoadType.Seismic.Tooltip.' + getDesignStandardNameByDesignStandardId(this.design.designStandard?.id ?? 0));
    }

    private get fireTooltipTitle() {
        return this.translate('Agito.Hilti.Profis3.LoadType.Fire.Tooltip.Title');
    }

    private get fireTooltip() {
        return this.translate('Agito.Hilti.Profis3.LoadType.Fire.Tooltip.' + getDesignStandardNameByDesignStandardId(this.design.designStandard?.id ?? 0));
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return !this.pendingSave;
        });

        this.scrollElement = document.querySelector('.modal') as Element;

        const forceUnit = this.userSettings.settings.applicationImportLoads.forceUnit.value ?? this.unit.getDefaultUnit(UnitGroup.Force);
        const momentUnit = this.userSettings.settings.applicationImportLoads.momentUnit.value ?? this.unit.getDefaultUnit(UnitGroup.Moment);

        // unit dropdowns
        this.forceUnitDropdown = this.createUnitDropdown('force-dropdown', 'ForceUnit', this.commonCodeList.commonCodeLists[CommonCodeList.UnitForce], forceUnit);
        this.momentUnitDropdown = this.createUnitDropdown('moment-dropdown', 'MomentUnit', this.commonCodeList.commonCodeLists[CommonCodeList.UnitMoment], momentUnit);

        // loads headers
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'one',
            dropdown: this.createNumberTypeDropdown('number-type-one', this.userSettings.settings.applicationImportLoads.numberTypeOne.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'two',
            dropdown: this.createNumberTypeDropdown('number-type-two', this.userSettings.settings.applicationImportLoads.numberTypeTwo.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'three',
            dropdown: this.createNumberTypeDropdown('number-type-three', this.userSettings.settings.applicationImportLoads.numberTypeThree.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'four',
            dropdown: this.createNumberTypeDropdown('number-type-four', this.userSettings.settings.applicationImportLoads.numberTypeFour.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'five',
            dropdown: this.createNumberTypeDropdown('number-type-five', this.userSettings.settings.applicationImportLoads.numberTypeFive.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'six',
            dropdown: this.createNumberTypeDropdown('number-type-six', this.userSettings.settings.applicationImportLoads.numberTypeSix.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'seven',
            dropdown: this.createNumberTypeDropdown('number-type-seven', this.userSettings.settings.applicationImportLoads.numberTypeSeven.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'eight',
            dropdown: this.createNumberTypeDropdown('number-type-eight', this.userSettings.settings.applicationImportLoads.numberTypeEight.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'nine',
            dropdown: this.createNumberTypeDropdown('number-type-nine', this.userSettings.settings.applicationImportLoads.numberTypeNine.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'ten',
            dropdown: this.createNumberTypeDropdown('number-type-ten', this.userSettings.settings.applicationImportLoads.numberTypeTen.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'eleven',
            dropdown: this.createNumberTypeDropdown('number-type-eleven', this.userSettings.settings.applicationImportLoads.numberTypeEleven.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twelve',
            dropdown: this.createNumberTypeDropdown('number-type-twelve', this.userSettings.settings.applicationImportLoads.numberTypeTwelve.value as ImportLoadsNumberType)
        });

        this.loadTypeToggleItems = [];
        if (this.isLoadTypeAllowed(InternalLoadType.Static)) {
            this.loadTypeToggleItems.push({
                value: InternalLoadType.Static,
                image: getSpriteAsIconStyle('sprite-anchor-shock'),
                class: 'sprite-anchor-shock',
                tooltip: {
                    title: this.staticTooltipTitle,
                    content: this.staticTooltip
                }
            });
        }
        if (this.isLoadTypeAllowed(InternalLoadType.Seismic)) {
            this.loadTypeToggleItems.push({
                value: InternalLoadType.Seismic,
                image: getSpriteAsIconStyle('sprite-anchor-seismic'),
                class: 'sprite-anchor-seismic',
                tooltip: {
                    title: this.seismicTooltipTitle,
                    content: this.seismicTooltip
                }
            });
        }

        if (this.isLoadTypeAllowed(InternalLoadType.Fire)) {
            this.loadTypeToggleItems.push({
                value: InternalLoadType.Fire,
                image: getSpriteAsIconStyle('sprite-anchor-fire-resistant'),
                class: 'sprite-anchor-fire-resistant',
                tooltip: {
                    title: this.fireTooltipTitle,
                    content: this.fireTooltip
                }
            });
        }

        if(this.design.model[UIProperty.SmartAnchor_Enabled]){
            this.loadTypeToggleItems.filter(x => x.value != InternalLoadType.Static).forEach(load => load.disabled = true);
        }

        setTimeout(() => {
            const element = this.elementRef.nativeElement.shadowRoot?.getElementById('import-loads-dialog') as HTMLInputElement;
            this.copyPasteInput = element.querySelector('.copy-paste-input') as HTMLInputElement;
            this.copyPasteInput.focus();
        });
    }

    public close() {
        this.modalInstance.close();
    }

    public cancel() {
        this.close();
    }

    public onImportPaste() {
        this.isPasted = true;
    }

    public get maxLoads() {
        return this.helper.maxLoads;
    }

    public get areLoadTypesAvailableForLoadCombinations() {
        return this.helper.areLoadTypesAvailableForLoadCombinations;
    }

    public onImportChange() {
        if (this.isPasted) {
            const maxLoads = Math.max(0, this.maxLoads - (this.design.loadCombinations == null ? 0 : this.design.loadCombinations.length));
            const parsed = this.parsePastedData(this.importData as string, maxLoads);

            this.limitReached = parsed.limitReached;
            this.importData = null;
            this.importedLoads = parsed.loads;
            this.view = ImportLoadsView.import;

        }
        else {
            this.importData = null;
        }
    }

    public onImportKeyDown(event: KeyboardEvent) {
        // prevent tab navigation
        if (event.key == keyTab) {
            event.preventDefault();
        }
    }

    public onImportBlur() {
        this.copyPasteInput.focus();
    }

    public virtualScrollChange() {
        // If input element had focus we need to blur it on virtual scroll change
        // (viewport end/start index changed)
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement != null && activeElement.tagName.toLowerCase() === 'input') {
            activeElement.blur();
        }
    }

    public identifyField: TrackByFunction<ILoadsDropdownHeader> = (_: number, field: ILoadsDropdownHeader) => field.index;

    // Returning index (inside virtual scroll's viewport) to ensure the same components are being reused (performance improvement!).
    public identifyLoad: TrackByFunction<LoadCombination> = (index: number) => index;

    public getLoadValue(load: LoadCombination, numberValue: number) {
        const numberType = this.loadsDropdownHeaders[numberValue].dropdown.selectedValue as ImportLoadsNumberType;
        return this.getLoadNumber(load, numberType);
    }

    public updateLoadValue(value: number, load: LoadCombination, numberValue: number) {
        const numberType = this.loadsDropdownHeaders[numberValue].dropdown.selectedValue as ImportLoadsNumberType;
        if (value != null && !Number.isNaN(value)) {
            this.setLoadNumber(load, numberType, value);
        }
    }

    public isColumnVisible(numberValue: number) {
        const numberType = this.loadsDropdownHeaders[numberValue].dropdown.selectedValue as ImportLoadsNumberType;
        return this.isLoadVisible(numberType);
    }

    public isLoadVisible(numberType: ImportLoadsNumberType) {
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
            case ImportLoadsNumberType.ForceXMinus:
            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYMinus:
            case ImportLoadsNumberType.ForceZ:
            case ImportLoadsNumberType.ForceZMinus:
            case ImportLoadsNumberType.MomentX:
            case ImportLoadsNumberType.MomentXMinus:
            case ImportLoadsNumberType.MomentY:
            case ImportLoadsNumberType.MomentYMinus:
            case ImportLoadsNumberType.MomentZ:
            case ImportLoadsNumberType.MomentZMinus:
                return true;
            case ImportLoadsNumberType.ForceZSustained:
            case ImportLoadsNumberType.ForceZSustainedMinus:
            case ImportLoadsNumberType.MomentXSustained:
            case ImportLoadsNumberType.MomentXSustainedMinus:
            case ImportLoadsNumberType.MomentYSustained:
            case ImportLoadsNumberType.MomentYSustainedMinus:
                return this.hasSustainedLoads;
            case ImportLoadsNumberType.ForceXVariable:
            case ImportLoadsNumberType.ForceXVariableMinus:
            case ImportLoadsNumberType.ForceYVariable:
            case ImportLoadsNumberType.ForceYVariableMinus:
            case ImportLoadsNumberType.MomentZVariable:
            case ImportLoadsNumberType.MomentZVariableMinus:
                return this.isDynamicFatigueExpertMode;
            default:
                throw new Error('Unknown number type.');
        }
    }

    public isColumnDisabled(numberValue: number) {
        const numberType = this.loadsDropdownHeaders[numberValue].dropdown.selectedValue as ImportLoadsNumberType;
        return this.isLoadDisabled(numberType);
    }

    public isLoadDisabled(numberType: ImportLoadsNumberType | undefined) {
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
            case ImportLoadsNumberType.ForceXMinus:
            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYMinus:
                return false;
            case ImportLoadsNumberType.ForceZ:
            case ImportLoadsNumberType.ForceZMinus:
                return !this.areAllLoadsForLedgerAngleAvailable;
            case ImportLoadsNumberType.MomentX:
            case ImportLoadsNumberType.MomentXMinus:
            case ImportLoadsNumberType.MomentY:
            case ImportLoadsNumberType.MomentYMinus:
            case ImportLoadsNumberType.MomentZ:
            case ImportLoadsNumberType.MomentZMinus:
                return !this.areMomentsForMetalDeckAvailable || !this.areAllLoadsForLedgerAngleAvailable;
            case ImportLoadsNumberType.ForceZSustained:
            case ImportLoadsNumberType.ForceZSustainedMinus:
                return !this.areAllLoadsForLedgerAngleAvailable;
            case ImportLoadsNumberType.MomentXSustained:
            case ImportLoadsNumberType.MomentXSustainedMinus:
            case ImportLoadsNumberType.MomentYSustained:
            case ImportLoadsNumberType.MomentYSustainedMinus:
                return !this.areMomentsForMetalDeckAvailable || !this.areAllLoadsForLedgerAngleAvailable;
            case ImportLoadsNumberType.ForceXVariable:
            case ImportLoadsNumberType.ForceXVariableMinus:
            case ImportLoadsNumberType.ForceYVariable:
            case ImportLoadsNumberType.ForceYVariableMinus:
                return false;
            case ImportLoadsNumberType.MomentZVariable:
            case ImportLoadsNumberType.MomentZVariableMinus:
                return !this.areMomentsForMetalDeckAvailable || !this.areAllLoadsForLedgerAngleAvailable;
            default:
                throw new Error('Unknown number type.');
        }
    }

    public saveLoadCombinations(form: NgForm) {
        return this.save(form);
    }

    public numberTypeDropdownSelectedValueChange(selectedValue: ImportLoadsNumberType, dropdown: DropdownProps<ImportLoadsNumberType>) {
        const oldSelectedValue = dropdown.selectedValue as number;
        dropdown.selectedValue = selectedValue;

        // swap numbers in load
        for (const load of this.importedLoads || []) {
            const loadNumber = this.getLoadNumber(load, oldSelectedValue);

            this.setLoadNumber(load, oldSelectedValue, this.getLoadNumber(load, selectedValue));
            this.setLoadNumber(load, selectedValue, loadNumber);
        }

        // swap dropdown values
        for (const header of this.loadsDropdownHeaders) {
            if (header.dropdown !== dropdown && this.isSameNumberType(header.dropdown.selectedValue as number, selectedValue as number)) {
                header.dropdown.selectedValue = oldSelectedValue - oldSelectedValue % 2;
            }
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    /*
     * Parse pasted loads from excel to array. This parser is needed  because cells in excel can be multiple line and need to be parsed correctly
     * Special lines:
     * "``~~??''^^{}[]<>¸¸¸°°˘˘ˇˇ^^ˇ\n dasdasdas"	0	1	3	4	5	2,
     * Combination 12	0	13	"27\n 11"	4	5	2
     */
    private CSVToArray(csvString: string) {
        const rows: string[] = [];
        const newLInes = csvString.split('\n').length;
        let line = '';
        for (let i = 0; i <= newLInes - 1; i++) {
            line += (i == newLInes - 1) ? csvString : csvString.substring(0, csvString.indexOf('\n') + 1);
            csvString = csvString.slice(csvString.indexOf('\n') + 1, csvString.length);

            /*
             *If line starts with a quote or '-->"' (\t") exist in line, it means that any of the cell is multiple line.
             *There are some issues like: if there is a quote(") in cell or row ends with ("\n), ... In excel is the same issue.
             */
            if ((i < newLInes - 1) && (line.startsWith('"') || line.indexOf('\t"') != -1)) {
                // Continiue reading line if \t" exist in line and count of \t" is not same as count of "\t
                /* eslint-disable-next-line no-useless-escape */
                if ((line.indexOf('\t"') != -1 && (line.match(/\t"/g) || []).length != (line.match(/"\t/g) || []).length)) {
                    continue;
                }
                // save line in array if \t exist in line or line has even number of quotes.
                else if ((line.indexOf('\t') != -1 || (line.match(/"/g)?.length ?? 0) % 2 == 0)) {
                    if (line.startsWith('"') && line[1] != '\n' && line.indexOf('\n') < line.length - 1) {// specil case for remove extra quote
                        line = line.substring(1).replace('"\t', '\t');
                    }
                    // remove all extra euotes and replace \n with space
                    /* eslint-disable-next-line no-useless-escape */
                    rows.push(line.replace(/\"\n/g, '').replace(/\n\"/g, '').replace(/\n/g, ' ').replace(/\"\"/g, '"'));
                    line = '';
                }
            }
            // Save normal line in array (Lines: Comb11  0	12	25	4	5	2, Comb11    0	12	"25 4	5!	2, ...),
            else {
                if (line.indexOf('\n') != -1 && line.startsWith('"')) {// special case for remove extra quote
                    /* eslint-disable-next-line no-useless-escape */
                    line = line.substring(1).replace(/\n\"/g, '').replace('"\t', '\t');
                }
                // remove all extra euotes and replace \n with space
                if (line.trim() != '') {
                    /* eslint-disable-next-line no-useless-escape */
                    rows.push(line.replace(/\"\n/g, '').replace(/\n\"/g, '').replace(/\n/g, ' ').replace(/\"\"/g, '"'));
                }
                line = '';
            }
        }
        return rows; // Return the parsed data Array
    }

    private parsePastedData(data: string, maxLoads: number): IParseResponse {
        const loads: LoadCombination[] = [];

        if (data == null || data == '') {
            return {
                loads,
                limitReached: false
            };
        }

        // normalize new lines
        data = replace(data, '\r\n', '\n');
        const rows = this.CSVToArray(data);

        let limitReached = false;

        for (const row of rows) {
            // max loads
            if (loads.length >= maxLoads) {
                limitReached = true;
                break;
            }

            const columns = row.split('\t');
            const hasName = columns.length >= (this.hasSustainedLoads ? 10 : 7) || this.getNumber(columns, 0) == null;
            const columnOffset = hasName ? 1 : 0;

            const getValidatedNumber = (columnIndex: number, numberType: ImportLoadsNumberType) => {
                if (!this.isLoadVisible(numberType)) {
                    return null;
                }
                if (this.isLoadDisabled(numberType)) {
                    return 0;
                }
                return this.getNumber(columns, columnIndex + columnOffset);
            };

            let forceX: number | null = null;
            let forceY: number | null = null;
            let forceZ: number | null = null;
            let momentX: number | null = null;
            let momentY: number | null = null;
            let momentZ: number | null = null;
            let forceZSustained: number | null = null;
            let momentXSustained: number | null = null;
            let momentYSustained: number | null = null;
            let momentZVariable: number | null = null;
            let forceXVariable: number | null = null;
            let forceYVariable: number | null = null;

            const visibleLoads = this.loadsDropdownHeaders.filter(x => this.isLoadVisible(x.dropdown.selectedValue as ImportLoadsNumberType));

            visibleLoads.forEach((item, key) => {
                const numberType = item.dropdown.selectedValue;

                switch (numberType) {
                    case ImportLoadsNumberType.ForceX:
                    case ImportLoadsNumberType.ForceXMinus:
                        forceX = getValidatedNumber(key, numberType); break;
                    case ImportLoadsNumberType.ForceY:
                    case ImportLoadsNumberType.ForceYMinus:
                        forceY = getValidatedNumber(key, numberType); break;
                    case ImportLoadsNumberType.ForceZ:
                    case ImportLoadsNumberType.ForceZMinus:
                        forceZ = getValidatedNumber(key, numberType); break;
                    case ImportLoadsNumberType.MomentX:
                    case ImportLoadsNumberType.MomentXMinus:
                        momentX = getValidatedNumber(key, numberType); break;
                    case ImportLoadsNumberType.MomentY:
                    case ImportLoadsNumberType.MomentYMinus:
                        momentY = getValidatedNumber(key, numberType); break;
                    case ImportLoadsNumberType.MomentZ:
                    case ImportLoadsNumberType.MomentZMinus:
                        momentZ = getValidatedNumber(key, numberType); break;
                    case ImportLoadsNumberType.ForceZSustained:
                    case ImportLoadsNumberType.ForceZSustainedMinus:
                        forceZSustained = getValidatedNumber(key, numberType); break;
                    case ImportLoadsNumberType.MomentXSustained:
                    case ImportLoadsNumberType.MomentXSustainedMinus:
                        momentXSustained = getValidatedNumber(key, numberType); break;
                    case ImportLoadsNumberType.MomentYSustained:
                    case ImportLoadsNumberType.MomentYSustainedMinus:
                        momentYSustained = getValidatedNumber(key, numberType); break;
                    case ImportLoadsNumberType.MomentZVariable:
                    case ImportLoadsNumberType.MomentZVariableMinus:
                        momentZVariable = getValidatedNumber(key, numberType); break;
                    case ImportLoadsNumberType.ForceXVariable:
                    case ImportLoadsNumberType.ForceXVariableMinus:
                        forceXVariable = getValidatedNumber(key, numberType); break;
                    case ImportLoadsNumberType.ForceYVariable:
                    case ImportLoadsNumberType.ForceYVariableMinus:
                        forceYVariable = getValidatedNumber(key, numberType); break;
                }
            });

            const name = hasName ? columns[0].trim() : '';

            if (name != '' || forceX != null || forceY != null || forceZ != null || momentX != null || momentY != null || momentZ != null ||
                (this.hasSustainedLoads && (forceZSustained != null || momentXSustained != null || momentYSustained != null))) {
                loads.push({
                    Id: this.guid.new(),
                    ActiveLoadType: this.isDynamicFatigueExpertMode ? LoadType.Fatigue : LoadType.Static,
                    Name: name,
                    Description: null as unknown as string,
                    ForceX: forceX ?? 0,
                    ForceY: forceY ?? 0,
                    ForceZ: forceZ ?? 0,
                    MomentX: momentX ?? 0,
                    MomentY: momentY ?? 0,
                    MomentZ: momentZ ?? 0,
                    DynamicForceX: this.design.isDynamicFatigue ? forceXVariable ?? 0 : null as unknown as number,
                    DynamicForceY: this.design.isDynamicFatigue ? forceYVariable ?? 0 : null as unknown as number,
                    DynamicForceZ: this.hasSustainedLoads ? forceZSustained ?? 0 : null as unknown as number,
                    DynamicMomentX: this.hasSustainedLoads ? momentXSustained ?? 0 : null as unknown as number,
                    DynamicMomentY: this.hasSustainedLoads ? momentYSustained ?? 0 : null as unknown as number,
                    DynamicMomentZ: this.design.isDynamicFatigue ? momentZVariable ?? 0 : null as unknown as number,
                    Tension: null as unknown as UtilizationValueEntity,
                    Shear: null as unknown as UtilizationValueEntity,
                    Combination: null as unknown as UtilizationValueEntity,
                    ResultMessages: [],
                    IsWizardGenerated: false,
                    HasSustainedLoads: false,
                    LoadCharacteristic: null as unknown as LoadCharacteristic
                });
            }
        }

        return {
            loads,
            limitReached
        };
    }

    private getNumber(columns: string[], index: number) {
        if (index < columns.length) {
            const value = columns[index];
            const num = this.numberService.parse(value, NumberType.real);

            if (num != null && !Number.isNaN(num)) {
                return num;
            }
        }

        return null;
    }

    private createUnitDropdown(id: string, titleKey: string, codeList: UnitCodeListItem[], defaultUnit: Unit) {
        const dropdown: DropdownProps<Unit> = {
            id: 'import-loads-' + id,
            title: this.translate(`Agito.Hilti.Profis3.ImportLoads.${titleKey}`),
            selectedValue: defaultUnit
        };

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        dropdown.items = codeList
            .filter(u => this.unit.supportedUnitIds.indexOf(u.id) > -1)
            .map((unit) => {
                return {
                    id: `import-loads-${id}-${unit.id}`,
                    value: unit.id,
                    text: unit.getTranslatedNameText(codeListDeps)
                } as DropdownItem<Unit>;
            });

        if (this.unit.supportedUnitIds.indexOf(defaultUnit) > -1) {
            dropdown.selectedValue = defaultUnit;
        }
        else if (dropdown.items.length > 0) {
            dropdown.selectedValue = dropdown.items[0].value;
        }

        return dropdown;
    }

    private createNumberTypeDropdown(id: string, selected: ImportLoadsNumberType) {
        const dropdown: DropdownProps<ImportLoadsNumberType> = {
            id: 'import-loads-' + id,
            selectedValue: selected
        };

        dropdown.items = [
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceX),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceXMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceY),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceYMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZ),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentX),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentXMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentY),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentYMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentZ),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentZMinus)
        ];

        if (this.hasSustainedLoads) {
            dropdown.items = [
                ...dropdown.items,
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZSustained),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZSustainedMinus),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentXSustained),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentXSustainedMinus),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentYSustained),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentYSustainedMinus)
            ];
        }

        if (this.isDynamicFatigueExpertMode) {
            dropdown.items = [
                ...dropdown.items,
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceXVariable),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceXVariableMinus),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceYVariable),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceYVariableMinus),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentZVariable),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentZVariableMinus)
            ];
        }

        return dropdown;
    }

    private createNumberDropdownItem(dropdown: DropdownProps<ImportLoadsNumberType>, numberType: ImportLoadsNumberType) {
        return {
            id: `import-loads-${dropdown.id}-${numberType}`,
            value: numberType,
            text: this.getNumberTypeString(numberType),
            disabled: this.isLoadDisabled(numberType)
        } as DropdownItem<ImportLoadsNumberType>;
    }

    private getNumberTypeString(numberType: ImportLoadsNumberType) {
        let key = 'Agito.Hilti.Profis3.ImportLoads.';
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
            case ImportLoadsNumberType.ForceXVariable:
                key += this.design.isDynamicFatigue ? 'Dynamic.VxPlus' : 'VxPlus';
                break;

            case ImportLoadsNumberType.ForceXMinus:
            case ImportLoadsNumberType.ForceXVariableMinus:
                key += this.design.isDynamicFatigue ? 'Dynamic.VxMinus' : 'VxMinus';
                break;

            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYVariable:
                key += this.design.isDynamicFatigue ? 'Dynamic.VyPlus' : 'VyPlus';
                break;

            case ImportLoadsNumberType.ForceYMinus:
            case ImportLoadsNumberType.ForceYVariableMinus:
                key += this.design.isDynamicFatigue ? 'Dynamic.VyMinus' : 'VyMinus';
                break;

            case ImportLoadsNumberType.ForceZ:
                key += this.design.isDynamicFatigue ? 'Dynamic.NPlus' : 'NPlus';
                break;

            case ImportLoadsNumberType.ForceZMinus:
                key += this.design.isDynamicFatigue ? 'Dynamic.NMinus' : 'NMinus';
                break;

            case ImportLoadsNumberType.MomentX:
                key += this.design.isDynamicFatigue ? 'Dynamic.MxPlus' : 'MxPlus';
                break;

            case ImportLoadsNumberType.MomentXMinus:
                key += this.design.isDynamicFatigue ? 'Dynamic.MxMinus' : 'MxMinus';
                break;

            case ImportLoadsNumberType.MomentY:
                key += this.design.isDynamicFatigue ? 'Dynamic.MyPlus' : 'MyPlus';
                break;

            case ImportLoadsNumberType.MomentYMinus:
                key += this.design.isDynamicFatigue ? 'Dynamic.MyMinus' : 'MyMinus';
                break;

            case ImportLoadsNumberType.MomentZ:
            case ImportLoadsNumberType.MomentZVariable:
                key += this.design.isDynamicFatigue ? 'Dynamic.MzPlus' : 'MzPlus';
                break;

            case ImportLoadsNumberType.MomentZMinus:
            case ImportLoadsNumberType.MomentZVariableMinus:
                key += this.design.isDynamicFatigue ? 'Dynamic.MzMinus' : 'MzMinus';
                break;

            case ImportLoadsNumberType.ForceZSustained:
                key += 'NSustainedPlus';
                break;

            case ImportLoadsNumberType.ForceZSustainedMinus:
                key += 'NSustainedMinus';
                break;

            case ImportLoadsNumberType.MomentXSustained:
                key += 'MxSustainedPlus';
                break;

            case ImportLoadsNumberType.MomentXSustainedMinus:
                key += 'MxSustainedMinus';
                break;

            case ImportLoadsNumberType.MomentYSustained:
                key += 'MySustainedPlus';
                break;

            case ImportLoadsNumberType.MomentYSustainedMinus:
                key += 'MySustainedMinus';
                break;

            default:
                throw new Error('Unknown number type.');
        }

        if (this.design.isDynamicFatigue) {
            const loadTranslation = this.translate(key);
            let dynamicExtensionTranslation = this.translate('Agito.Hilti.Profis3.Loads.FatigueExpertMode.UpperLoad');

            if (numberType == ImportLoadsNumberType.MomentZVariable || numberType == ImportLoadsNumberType.MomentZVariableMinus ||
                numberType == ImportLoadsNumberType.ForceXVariable || numberType == ImportLoadsNumberType.ForceXVariableMinus ||
                numberType == ImportLoadsNumberType.ForceYVariable || numberType == ImportLoadsNumberType.ForceYVariableMinus) {
                dynamicExtensionTranslation = this.translate('Agito.Hilti.Profis3.Loads.FatigueExpertMode.LowerLoad');
            }

            return loadTranslation.replace('{dynamicDirection}', dynamicExtensionTranslation);
        }
        else {
            return this.translate(key);
        }
    }

    private getLoadNumber(load: LoadCombination, numberType: ImportLoadsNumberType) {
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
            case ImportLoadsNumberType.ForceXMinus:
                return load.ForceX;
            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYMinus:
                return load.ForceY;
            case ImportLoadsNumberType.ForceZ:
            case ImportLoadsNumberType.ForceZMinus:
                return load.ForceZ;
            case ImportLoadsNumberType.MomentX:
            case ImportLoadsNumberType.MomentXMinus:
                return load.MomentX;
            case ImportLoadsNumberType.MomentY:
            case ImportLoadsNumberType.MomentYMinus:
                return load.MomentY;
            case ImportLoadsNumberType.MomentZ:
            case ImportLoadsNumberType.MomentZMinus:
                return load.MomentZ;
            case ImportLoadsNumberType.ForceZSustained:
            case ImportLoadsNumberType.ForceZSustainedMinus:
                return load.DynamicForceZ;
            case ImportLoadsNumberType.MomentXSustained:
            case ImportLoadsNumberType.MomentXSustainedMinus:
                return load.DynamicMomentX;
            case ImportLoadsNumberType.MomentYSustained:
            case ImportLoadsNumberType.MomentYSustainedMinus:
                return load.DynamicMomentY;
            case ImportLoadsNumberType.MomentZVariable:
            case ImportLoadsNumberType.MomentZVariableMinus:
                return load.DynamicMomentZ;
            case ImportLoadsNumberType.ForceXVariable:
            case ImportLoadsNumberType.ForceXVariableMinus:
                return load.DynamicForceX;
            case ImportLoadsNumberType.ForceYVariable:
            case ImportLoadsNumberType.ForceYVariableMinus:
                return load.DynamicForceY;
            default:
                return 0;
        }
    }

    private setLoadNumber(load: LoadCombination, numberType: ImportLoadsNumberType, value: number) {
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
            case ImportLoadsNumberType.ForceXMinus:
                load.ForceX = value;
                break;
            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYMinus:
                load.ForceY = value;
                break;
            case ImportLoadsNumberType.ForceZ:
            case ImportLoadsNumberType.ForceZMinus:
                load.ForceZ = value;
                break;
            case ImportLoadsNumberType.MomentX:
            case ImportLoadsNumberType.MomentXMinus:
                load.MomentX = value;
                break;
            case ImportLoadsNumberType.MomentY:
            case ImportLoadsNumberType.MomentYMinus:
                load.MomentY = value;
                break;
            case ImportLoadsNumberType.MomentZ:
            case ImportLoadsNumberType.MomentZMinus:
                load.MomentZ = value;
                break;
            case ImportLoadsNumberType.ForceZSustained:
            case ImportLoadsNumberType.ForceZSustainedMinus:
                load.DynamicForceZ = value;
                break;
            case ImportLoadsNumberType.MomentXSustained:
            case ImportLoadsNumberType.MomentXSustainedMinus:
                load.DynamicMomentX = value;
                break;
            case ImportLoadsNumberType.MomentYSustained:
            case ImportLoadsNumberType.MomentYSustainedMinus:
                load.DynamicMomentY = value;
                break;
            case ImportLoadsNumberType.MomentZVariable:
            case ImportLoadsNumberType.MomentZVariableMinus:
                load.DynamicMomentZ = value;
                break;
            case ImportLoadsNumberType.ForceXVariable:
            case ImportLoadsNumberType.ForceXVariableMinus:
                load.DynamicForceX = value;
                break;
            case ImportLoadsNumberType.ForceYVariable:
            case ImportLoadsNumberType.ForceYVariableMinus:
                load.DynamicForceY = value;
                break;
        }
    }

    private isSameNumberType(numberTypeOne: number, numberTypeTwo: number) {
        return Math.floor(numberTypeOne / 2) == Math.floor(numberTypeTwo / 2);
    }

    private isLoadTypeAllowed(loadType: InternalLoadType) {
        return this.design.properties.get(PropertyMetaData.Loads_LoadType.id).allowedValues?.includes(loadType);
    }

    private save(form: NgForm) {
        if (this.submitted || (form.enabled && !form.valid) || this.importedLoads.length == 0) {
            return;
        }

        this.design.usageCounter.ExcelImportLoad++;

        this.submitted = true;
        this.pendingSave = true;

        const importedLoads = cloneDeep(this.importedLoads);

        const forceUnit = this.forceUnitDropdown.selectedValue as Unit;
        const momentUnit = this.momentUnitDropdown.selectedValue as Unit;

        const internalForceUnit = this.unit.getInternalUnit(UnitGroup.Force);
        const internalMomentUnit = this.unit.getInternalUnit(UnitGroup.Moment);

        for (const load of importedLoads) {
            // convert to selected unit
            load.ForceX = this.unit.convertUnitValueArgsToUnit(load.ForceX, forceUnit, internalForceUnit);
            load.ForceY = this.unit.convertUnitValueArgsToUnit(load.ForceY, forceUnit, internalForceUnit);
            load.ForceZ = this.unit.convertUnitValueArgsToUnit(load.ForceZ, forceUnit, internalForceUnit);

            load.MomentX = this.unit.convertUnitValueArgsToUnit(load.MomentX, momentUnit, internalMomentUnit);
            load.MomentY = this.unit.convertUnitValueArgsToUnit(load.MomentY, momentUnit, internalMomentUnit);
            load.MomentZ = this.unit.convertUnitValueArgsToUnit(load.MomentZ, momentUnit, internalMomentUnit);

            if (this.hasSustainedLoads) {
                load.DynamicForceZ = this.unit.convertUnitValueArgsToUnit(load.DynamicForceZ, forceUnit, internalForceUnit);
                load.DynamicMomentX = this.unit.convertUnitValueArgsToUnit(load.DynamicMomentX, momentUnit, internalMomentUnit);
                load.DynamicMomentY = this.unit.convertUnitValueArgsToUnit(load.DynamicMomentY, momentUnit, internalMomentUnit);
            }
            if (this.isDynamicFatigueExpertMode) {
                load.DynamicForceX = this.unit.convertUnitValueArgsToUnit(load.DynamicForceZ, forceUnit, internalForceUnit);
                load.DynamicForceY = this.unit.convertUnitValueArgsToUnit(load.DynamicForceY, forceUnit, internalForceUnit);
                load.DynamicMomentZ = this.unit.convertUnitValueArgsToUnit(load.DynamicMomentZ, momentUnit, internalMomentUnit);
            }

            // negate if needed
            for (const header of this.loadsDropdownHeaders) {
                const numberType = header.dropdown.selectedValue as number;
                const loadNumber = this.getLoadNumber(load, numberType);

                if (numberType % 2 != 0 && loadNumber != null) {
                    this.setLoadNumber(load, numberType, -loadNumber);
                }
            }
        }

        // Filter out empty load combinations and consider max number of combinations
        this.design.loadCombinations = concatFilterLoadCombinations(this.design.loadCombinations, importedLoads, this.maxLoads, true);

        this.userSettings.settings.applicationImportLoads.numberTypeOne.value = this.loadsDropdownHeaders[0].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwo.value = this.loadsDropdownHeaders[1].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeThree.value = this.loadsDropdownHeaders[2].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeFour.value = this.loadsDropdownHeaders[3].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeFive.value = this.loadsDropdownHeaders[4].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeSix.value = this.loadsDropdownHeaders[5].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeSeven.value = this.loadsDropdownHeaders[6].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeEight.value = this.loadsDropdownHeaders[7].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeNine.value = this.loadsDropdownHeaders[8].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTen.value = this.loadsDropdownHeaders[9].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeEleven.value = this.loadsDropdownHeaders[10].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwelve.value = this.loadsDropdownHeaders[11].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.forceUnit.value = this.forceUnitDropdown.selectedValue as Unit;
        this.userSettings.settings.applicationImportLoads.momentUnit.value = this.momentUnitDropdown.selectedValue as Unit;

        this.userSettings.save();

        // calculate
        this.calculationService
            .calculateAsync(this.design, undefined, { suppressLoadingFlag: true, importingLoadCases: true })
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }
}
