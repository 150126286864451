import cloneDeep from 'lodash-es/cloneDeep.js';
import { areEqual, copyComparableValues } from './comparable-values-helper.js';
import { CreateLineSystem } from '@babylonjs/core/Meshes/Builders/linesBuilder.js';
export function sizeLinesMesh(linesMeshInfo, values, calculatePointsFn) {
  if (!areEqual(values, linesMeshInfo.values)) {
    const linesInfo = calculatePointsFn(values);
    CreateLineSystem(undefined, {
      lines: linesInfo.lines,
      instance: linesMeshInfo.linesMesh,
      updatable: undefined
    }, undefined);
    linesMeshInfo.linesMesh.refreshBoundingInfo();
    // save info
    copyComparableValues(linesMeshInfo, values, linesInfo);
  }
}
export function createLinesMesh(scene, meshCache, values, calculateLinesFn, name, color, alpha) {
  return meshCache.create(name, () => {
    const linesInfo = calculateLinesFn(values);
    const linesMesh = CreateLineSystem(name, {
      lines: linesInfo.lines,
      updatable: true
    }, scene);
    linesMesh.isPickable = false;
    linesMesh.color = color;
    if (alpha) {
      linesMesh.alphaIndex = alpha;
    }
    return {
      linesMesh,
      linesInfo,
      values: cloneDeep(values)
    };
  });
}
