import { DesignPe } from '../../shared/entities/design-pe';
import { AdvancedCalculationType } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { AbpService } from '../services/abp.service';
import { FeaturesVisibilityInfoService } from '../services/features-visibility-info.service';
import { ModalService } from '../services/modal.service';

export function showAdvancedPopup(design: DesignPe, abpService: AbpService, featuresVisibilityInfo: FeaturesVisibilityInfoService, modalService: ModalService) {
    const showPopup = abpService.showAdvancedCalculationPopup(design.designType.id, design.designStandardId, design.regionId, design.calculationType);
    if(!showPopup){
        return;
    }

    const popupType = featuresVisibilityInfo.hasAdvancedFeature ? AdvancedCalculationType.Realistic : AdvancedCalculationType.BPRigidityCheck;
    modalService.openDefaultCalculationMethod(design, popupType);
}