import { environment } from '../../environments/environmentC2C';

export interface IApprovalInfo {
    name: string;
    url: string;
}

export class ApprovalHelper {
    public static readonly approvalUrlPart = 'cdn/pe-ui-c2c/approvals/';

    public static getApprovalInfo(urlPart: string, filename: string): IApprovalInfo {
        return {
            name: filename,
            url: `${environment.baseUrl}${ApprovalHelper.approvalUrlPart}${urlPart}`
        };
    }
}
