import { ImportLoadsNumberTypeBase } from './import-loads-number-type-base';
export class UserSettingsValue {
  valueInternal;
  constructor(value) {
    this.valueInternal = value;
  }
  get value() {
    return this.valueInternal;
  }
  set value(value) {
    this.valueInternal = value;
  }
}
export class GeneralValue extends UserSettingsValue {
  parseValueToJSON() {
    return JSON.stringify(this.value);
  }
  parseJSONToValue(str) {
    this.value = JSON.parse(str);
  }
}
export class DateValue extends UserSettingsValue {
  parseValueToJSON() {
    return JSON.stringify(this.value);
  }
  parseJSONToValue(str) {
    const value = str?.trim();
    if (value != null && value != '') {
      this.value = new Date(Date.parse(value));
    } else {
      this.value = null;
    }
  }
}
export class LanguageValue extends GeneralValue {
  translate;
  constructor(languageId, translate) {
    super(languageId);
    this.translate = translate;
  }
  get value() {
    if (this.translate) {
      return 12297 /* LanguageCultureLcid.pseudoLanguage */;
    }
    return super.value;
  }
  set value(languageId) {
    if (12297 /* LanguageCultureLcid.pseudoLanguage */ != languageId) {
      super.value = languageId;
    }
  }
}
export class ApplicationSettingsCommon {
  general;
  defaults;
  controls;
  advancedCalculation;
}
export class UserSettings {
  quickStart;
  application;
  user;
  applicationImportLoads;
  applicationModelDisplayOptions;
  applicationCollapsedState;
  constructor() {
    this.user = UserSettings.getBaseUserSettingsDefaults();
  }
  static getBaseGeneralApplicationSettingsDefaults(translate = false) {
    return {
      languageId: new LanguageValue(null, translate),
      regionId: new GeneralValue(null),
      decimalSeparatorId: new GeneralValue(null),
      thousandsSeparatorId: new GeneralValue(null),
      userAgreement: new GeneralValue(null),
      userPrivacy: new GeneralValue(null),
      isNewUser: new GeneralValue(null),
      releaseNotesVersionDate: new DateValue(null),
      forceFreeLicense: new GeneralValue(null),
      // integrations
      dlubalEnabled: new GeneralValue(null),
      risaEnabled: new GeneralValue(null),
      sap2000Enabled: new GeneralValue(null),
      robotEnabled: new GeneralValue(null),
      etabsEnabled: new GeneralValue(null),
      staadProEnabled: new GeneralValue(null),
      ramEnabled: new GeneralValue(null),
      checkbotLvl2: new GeneralValue(null)
    };
  }
  static getBaseControlsSettingsDefaults() {
    return {
      rotate: new GeneralValue(null),
      pan: new GeneralValue(null),
      gridSpacing: new GeneralValue(null),
      showGrid: new GeneralValue(null),
      snapToGrid: new GeneralValue(null)
    };
  }
  static getBaseApplicationImportLoadsDefaults() {
    return {
      numberTypeOne: new GeneralValue(ImportLoadsNumberTypeBase.ForceX),
      numberTypeTwo: new GeneralValue(ImportLoadsNumberTypeBase.ForceY),
      numberTypeThree: new GeneralValue(ImportLoadsNumberTypeBase.ForceZ),
      numberTypeFour: new GeneralValue(ImportLoadsNumberTypeBase.MomentX),
      numberTypeFive: new GeneralValue(ImportLoadsNumberTypeBase.MomentY),
      numberTypeSix: new GeneralValue(ImportLoadsNumberTypeBase.MomentZ),
      numberTypeSeven: new GeneralValue(ImportLoadsNumberTypeBase.ForceZSustained),
      numberTypeEight: new GeneralValue(ImportLoadsNumberTypeBase.MomentXSustained),
      numberTypeNine: new GeneralValue(ImportLoadsNumberTypeBase.MomentYSustained),
      numberTypeTen: new GeneralValue(ImportLoadsNumberTypeBase.ForceXVariable),
      numberTypeEleven: new GeneralValue(ImportLoadsNumberTypeBase.ForceYVariable),
      numberTypeTwelve: new GeneralValue(ImportLoadsNumberTypeBase.MomentZVariable),
      forceUnit: new GeneralValue(null),
      momentUnit: new GeneralValue(null)
    };
  }
  static getBaseAdvancedCalculationApplicationSettingsDefaults() {
    return {
      jointCoefficientBj: new GeneralValue(null),
      effectiveArea: new GeneralValue(null),
      effectiveAreaAISC: new GeneralValue(null),
      loadDistributionAngle: new GeneralValue(null),
      limitPlasticStrain: new GeneralValue(null),
      divisionOfSurfaceOfCHS: new GeneralValue(null),
      divisionOfArcsOfRHS: new GeneralValue(null),
      numberOfElementsOfEdge: new GeneralValue(null),
      numberOfAnalysisIterations: new GeneralValue(null),
      divergentIterationsCount: new GeneralValue(null),
      minimumSizeOfElement: new GeneralValue(null),
      maximumSizeOfElement: new GeneralValue(null),
      useULSStresses: new GeneralValue(true),
      calculationMethodState: new GeneralValue(1),
      concreteInCompressionMethod: new GeneralValue(null),
      alphaCC: new GeneralValue(null)
    };
  }
  static getBaseDefaultApplicationSettingsDefaults() {
    return {
      anchorPlateThinckness: new GeneralValue(null),
      anchorPlateFactor: new GeneralValue(null),
      permenentLoads: new GeneralValue(null),
      variableLoads: new GeneralValue(null),
      minimumAnchorToProfileDistance: new GeneralValue(null),
      concreteSafetyFactorGammaC: new GeneralValue(null),
      concreteSafetyFactorGammaCFreeValue: new GeneralValue(null),
      steelSafetyFactorGammaM0: new GeneralValue(null),
      steelSafetyFactorGammaM1: new GeneralValue(null),
      steelSafetyFactorGammaM2: new GeneralValue(null),
      steelSafetyFactorGammaM5: new GeneralValue(null),
      materialSafetyFactor: new GeneralValue(null),
      weldsCapacityFactor: new GeneralValue(null),
      steelCapacityFactor: new GeneralValue(null),
      concreteCapacityFactor: new GeneralValue(null),
      inSteelSafetyFactorGammaM0: new GeneralValue(null),
      inSteelSafetyFactorGammaM1: new GeneralValue(null),
      inSteelSafetyFactorGammaMw: new GeneralValue(null),
      inSteelSafetyFactorGammaMb: new GeneralValue(null),
      minimumConcreteCover: new GeneralValue(null)
    };
  }
  static getBaseUserSettingsDefaults() {
    return {
      companyDetails: {
        companyName: new GeneralValue(null),
        address: new GeneralValue(null),
        phoneNumber: new GeneralValue(null),
        email: new GeneralValue(null),
        fax: new GeneralValue(null),
        website: new GeneralValue(null)
      },
      generalName: new GeneralValue(null)
    };
  }
}
