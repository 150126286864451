import { ContolsStyleSheets, IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { buildHtmlTooltip } from '@profis-engineering/pe-ui-common/helpers/tooltip-helper';
import { getMenuControlImageReactData } from '../../../helpers/main-menu-helpers/react-helpers';
import style from './react-select-profile.css';
import { PureComponent } from '../react-import';

const sheet = new CSSStyleSheet();
sheet.replaceSync(style);
const ControlHeader = (window as any).pe.reactComponents.ControlHeader;

export interface ISelectProfileProps extends IControlProps {
    selectedValue?: number;
    buttonText?: string;
    buttonImage?: string;
    buttonImageStyle?: IResponsiveIconStyle;
    clicked?: (selectedValue: number) => void;
}
class _SelectProfile extends PureComponent<ISelectProfileProps> {

    public static styleSheets = [sheet];

    constructor(props: ISelectProfileProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const buttonId = `${this.props.controlId}-button`;
        const tooltip = this.props.title != null && this.props.title != '' ? null : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        const imageData = getMenuControlImageReactData(
            this.props.buttonImage,
            this.props.buttonImageStyle?.elementStyle,
            this.props.buttonImageStyle?.afterElementStyle
        );
        const useResponsiveStyle = imageData.elementAfterStyle != null;

        return (
            <div  data-control-id={this.props.controlId} className={`react-select-profile-control control ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className='control-container'>
                    <button
                        data-control-id={buttonId}
                        className='select-profile-button button-rich'
                        type='button'
                        disabled={this.props.disabled}
                        onClick={this.onClick}
                        data-tip={tooltip}
                        data-html={tooltip != null ? true : null}>
                        <div className='wrapper button-rich-content'>
                            <div className='image-container button-rich-image'>
                                <span className={`image sprite sprite-responsive ${imageData.spriteName ?? ''}`} style={imageData.elementStyle}>
                                    {
                                        useResponsiveStyle
                                            ? <span className='sprite-responsive-after' style={imageData.elementAfterStyle}></span>
                                            : null
                                    }
                                </span>
                            </div>
                            <div className='button-rich-seperator' />
                            <div className='content-wrapper button-rich-text'>
                                <p className='name' data-tip={this.props.buttonText ?? ''} data-html={this.props.buttonText != null ? true : null}>{this.props.buttonText ?? ''}</p>
                            </div>
                            <span className='sprite sprite-arrow-right button-rich-caret'></span>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.clicked != null) {
            this.props.clicked(this.props.selectedValue!);
        }
    }
}

export const SelectProfile: typeof _SelectProfile & ContolsStyleSheets = _SelectProfile;