import { CodeList } from './code-list';
import { ExistingReinforcementDiameterEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class ExistingReinforcementDiameter extends CodeList {

    public static fromService(codeList: ExistingReinforcementDiameterEntityC2C) {
        return new ExistingReinforcementDiameter(CodeList.baseFromService('ExistingReinforcementDiameterEntity', codeList, {
            id: codeList.id,
            displayKey: codeList.displayKey,
            name: codeList.displayKey
        }));
    }

    public override cloneDeep(): ExistingReinforcementDiameter {
        return super.cloneDeep() as ExistingReinforcementDiameter;
    }
}
