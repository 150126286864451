import { Subscription } from 'rxjs';

import {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation
} from '@angular/core';
import {
    IModuleInitialData, IModulePreInitialData
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { ApiServiceBase } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    AuthenticationServiceBase
} from '@profis-engineering/pe-ui-common/services/authentication.common';
import { BrowserServiceBase } from '@profis-engineering/pe-ui-common/services/browser.common';
import { ChangesServiceBase } from '@profis-engineering/pe-ui-common/services/changes.common';
import {
    CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    CommonTrackingServiceBase
} from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import { DateTimeServiceBase } from '@profis-engineering/pe-ui-common/services/date-time.common';
import {
    DesignSettingsServiceBase
} from '@profis-engineering/pe-ui-common/services/design-settings.common';
import {
    DesignTemplateServiceBase
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import { FavoritesServiceBase } from '@profis-engineering/pe-ui-common/services/favorites.common';
import {
    FeaturesVisibilityInfoServiceBase
} from '@profis-engineering/pe-ui-common/services/features-visibility-info.common';
import { ImportServiceBase } from '@profis-engineering/pe-ui-common/services/import.common';
import { LicenseServiceBase } from '@profis-engineering/pe-ui-common/services/license.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import { LoggerServiceBase } from '@profis-engineering/pe-ui-common/services/logger.common';
import { MathServiceBase } from '@profis-engineering/pe-ui-common/services/math.common';
import { Menu2dServiceBase } from '@profis-engineering/pe-ui-common/services/menu-2d.common';
import { MenuServiceBase } from '@profis-engineering/pe-ui-common/services/menu.common';
import { ModalServiceBase } from '@profis-engineering/pe-ui-common/services/modal.common';
import { NumberServiceBase } from '@profis-engineering/pe-ui-common/services/number.common';
import { OfflineServiceBase } from '@profis-engineering/pe-ui-common/services/offline.common';
import {
    ProductInformationServiceBase
} from '@profis-engineering/pe-ui-common/services/product-information.common';
import { QueryServiceBase } from '@profis-engineering/pe-ui-common/services/query.common';
import {
    RegionOrderServiceBase
} from '@profis-engineering/pe-ui-common/services/region-order.common';
import {
    ReportTemplateServiceBase
} from '@profis-engineering/pe-ui-common/services/report-template.common';
import { RoutingServiceBase } from '@profis-engineering/pe-ui-common/services/routing.common';
import {
    SessionStorageServiceBase
} from '@profis-engineering/pe-ui-common/services/session-storage.common';
import { TooltipServiceBase } from '@profis-engineering/pe-ui-common/services/tooltip.common';
import { TourServiceBase } from '@profis-engineering/pe-ui-common/services/tour.common';
import {
    TrimbleConnectServiceBase
} from '@profis-engineering/pe-ui-common/services/trimble-connect.common';
import { UnitServiceBase } from '@profis-engineering/pe-ui-common/services/unit.common';
import {
    UserSettingsServiceBase
} from '@profis-engineering/pe-ui-common/services/user-settings.common';
import { UserServiceBase } from '@profis-engineering/pe-ui-common/services/user.common';

import { DesignPe } from '../../../shared/entities/design-pe';
import { UserSettingsPe } from '../../../shared/entities/user-settings';
import { CalculationServiceBasePE } from '../../../shared/services/calculation.common';
import { CodeListServiceBase } from '../../../shared/services/code-list.service.base';
import {
    IntegrationsDataServiceBase
} from '../../../shared/services/integrations-data.service.base';
import {
    IntegrationsDocumentServiceBase
} from '../../../shared/services/integrations-document.service.base';
import {
    IntegrationsNotificationServiceBase
} from '../../../shared/services/integrations-notification.service.base';
import { SignalRServicePeBase } from '../../../shared/services/signalr.service.base';
import {
    TranslationFormatServiceBase
} from '../../../shared/services/translation-format.service.base';
import { SharedEnvironmentData } from '../../entities/sharedEnvironment';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BrowserService } from '../../services/browser.service';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { ChangesService } from '../../services/changes.service';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { DateTimeService } from '../../services/date-time.service';
import { DesignSettingsService } from '../../services/design-settings.service';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentService, DocumentServicePeBase } from '../../services/document.service';
import { FavoritesService, IFavoritesData } from '../../services/favorites.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { FeaturesVisibilityService } from '../../services/features-visibility.service';
import { GuidService } from '../../services/guid.service';
import { ImportService } from '../../services/import.service';
import { InitialDataService } from '../../services/initial-data.service';
import { IntegrationsDataService } from '../../services/integrations-data.service';
import { IntegrationsDocumentService } from '../../services/integrations-document.service';
import { IntegrationsNotificationService } from '../../services/integrations-notification.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { LoggerService } from '../../services/logger.service';
import { MathService } from '../../services/math.service';
import { Menu2dService } from '../../services/menu-2d.service';
import { MenuService } from '../../services/menu.service';
import { ModalService } from '../../services/modal.service';
import { NavigationService } from '../../services/navigation.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { ProductInformationService } from '../../services/product-information.service';
import { QueryService } from '../../services/query.service';
import { RegionOrderService } from '../../services/region-order.service';
import { ReportTemplateService } from '../../services/report-template.service';
import { RoutingService } from '../../services/routing.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { SignalRService } from '../../services/signalr.service';
import { SmartAnchorOrderService } from '../../services/smart-anchor-order.service';
import { TooltipService } from '../../services/tooltip.service';
import { TourService } from '../../services/tour.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { TrimbleConnectService } from '../../services/trimble-connect.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

@Component({
    template: '',
    encapsulation: ViewEncapsulation.ShadowDom
})
export class UiPeInitComponent implements OnInit, OnDestroy {
    // #region Input
    @Input()
    public localizationService!: LocalizationServiceBase;

    @Input()
    public numberService!: NumberServiceBase;

    @Input()
    public mathService!: MathServiceBase;

    @Input()
    public tooltipService!: TooltipServiceBase;

    @Input()
    public commonCodeListService!: CommonCodeListServiceBase;

    @Input()
    public codeListService!: CodeListServiceBase;

    @Input()
    public modalService!: ModalServiceBase;

    @Input()
    public unitService!: UnitServiceBase;

    @Input()
    public browserService!: BrowserServiceBase;

    @Input()
    public apiService!: ApiServiceBase;

    @Input()
    public documentService!: DocumentServicePeBase;

    @Input()
    public designTemplateService!: DesignTemplateServiceBase;

    @Input()
    public licenseService!: LicenseServiceBase;

    @Input()
    public signalRService!: SignalRServicePeBase;

    @Input()
    public userService!: UserServiceBase<DesignPe>;

    @Input()
    public calculationService!: CalculationServiceBasePE;

    @Input()
    public integrationsDataService!: IntegrationsDataServiceBase;

    @Input()
    public featureVisibilityInfoService!: FeaturesVisibilityInfoServiceBase;

    @Input()
    public userSettingsService!: UserSettingsServiceBase<UserSettingsPe>;

    @Input()
    public translationFormatService!: TranslationFormatServiceBase;

    @Input()
    public offlineService!: OfflineServiceBase;

    @Input()
    public guidService!: GuidService;

    @Input()
    public routingService!: RoutingServiceBase;

    @Input()
    public tourService!: TourServiceBase;

    @Input()
    public queryService!: QueryServiceBase;

    @Input()
    public importService!: ImportServiceBase;

    @Input()
    public dateTimeService!: DateTimeServiceBase;

    @Input()
    public loggerService!: LoggerServiceBase;

    @Input()
    public reportTemplateService!: ReportTemplateServiceBase;

    @Input()
    public menuService!: MenuServiceBase;

    @Input()
    public menu2dService!: Menu2dServiceBase;

    @Input()
    public trimbleConnectService!: TrimbleConnectServiceBase;

    @Input()
    public authenticationService!: AuthenticationServiceBase;

    @Input()
    public changesService!: ChangesServiceBase;

    @Input()
    public favoritesService!: FavoritesServiceBase;

    @Input()
    public featureVisibilityService!: FeaturesVisibilityService;

    @Input()
    public integrationsDocumentService!: IntegrationsDocumentServiceBase;

    @Input()
    public integrationsNotificationService!: IntegrationsNotificationServiceBase;

    @Input()
    public productInformationService!: ProductInformationServiceBase;

    @Input()
    public regionOrderService!: RegionOrderServiceBase;

    @Input()
    public commonTrackingService!: CommonTrackingServiceBase;

    @Input()
    public designSettingsService!: DesignSettingsServiceBase;

    @Input()
    public sharedEnvironmentData!: SharedEnvironmentData;

    @Input()
    public sessionStorageService!: SessionStorageServiceBase;
    // #endregion

    @Output()
    public preInit = new EventEmitter<IModulePreInitialData | (() => IModulePreInitialData | Promise<IModulePreInitialData>)>();

    @Output()
    public init = new EventEmitter<IModuleInitialData>();

    private favoritesServiceDataChangeSubscription?: Subscription;

    private initFailed = false;

    constructor(
        private readonly localizationSvc: LocalizationService,
        private readonly mathSvc: MathService,
        private readonly tooltipSvc: TooltipService,
        private readonly numberSvc: NumberService,
        private readonly commonCodeListSvc: CommonCodeListService,
        private readonly codeListSvc: CodeListService,
        private readonly modalSvc: ModalService,
        private readonly unitSvc: UnitService,
        private readonly browserSvc: BrowserService,
        private readonly apiSvc: ApiService,
        private readonly licenseSvc: LicenseService,
        private readonly signalRSvc: SignalRService,
        private readonly documentSvc: DocumentService,
        private readonly designTemplateSvc: DesignTemplateService,
        private readonly userSvc: UserService,
        private readonly calculationSvc: CalculationServicePE,
        private readonly integrationsDataSvc: IntegrationsDataService,
        private readonly navigationSvc: NavigationService,
        private readonly featureVisibilityInfoSvc: FeaturesVisibilityInfoService,
        private readonly userSettingsSvc: UserSettingsService,
        private readonly translationFormatSvc: TranslationFormatService,
        private readonly offlineSvc: OfflineService,
        private readonly routingSvc: RoutingService,
        private readonly tourSvc: TourService,
        private readonly querySvc: QueryService,
        private readonly importSvc: ImportService,
        private readonly sharedEnvironmentSvc: SharedEnvironmentService,
        private readonly dateTimeSvc: DateTimeService,
        private readonly loggerSvc: LoggerService,
        private readonly reportTemplateSvc: ReportTemplateService,
        private readonly menuSvc: MenuService,
        private readonly menu2dSvc: Menu2dService,
        private readonly trimbleConnectSvc: TrimbleConnectService,
        private readonly initialDataSvc: InitialDataService,
        private readonly authenticationSvc: AuthenticationService,
        private readonly changesSvc: ChangesService,
        private readonly favoritesSvc: FavoritesService,
        private readonly featureVisibilitySvc: FeaturesVisibilityService,
        private readonly integrationsDocumentSvc: IntegrationsDocumentService,
        private readonly integrationsNotificationSvc: IntegrationsNotificationService,
        private readonly productInformationSvc: ProductInformationService,
        private readonly regionOrderSvc: RegionOrderService,
        private readonly commonTrackingSvc: CommonTrackingService,
        private readonly designSettingsSvc: DesignSettingsService,
        private readonly smartAnchorOrderService: SmartAnchorOrderService,
        private readonly sessionStorageSvc: SessionStorageService
    ) { }

    public ngOnInit(): void {
        this.preInit.emit(() => {
            try {
                const preInitData = this.initialDataSvc.getPreInitialData();

                preInitData.initialDataLoadedEvent = async () => {
                    if (this.initFailed) {
                        return;
                    }

                    this.preInitServices();
                    await this.loadData();
                    this.initServices();

                    this.init.emit(this.initialDataSvc.getInitialData());
                };

                return preInitData;
            }
            catch (error) {
                this.handleError(error, false);
                throw error;
            }
        });
    }

    /** Called before data is loaded */
    private preInitServices() {
        this.localizationSvc.setBaseService(this.localizationService);
        this.mathSvc.setBaseService(this.mathService);
        this.tooltipSvc.setBaseService(this.tooltipService);
        this.numberSvc.setBaseService(this.numberService);
        this.commonCodeListSvc.setBaseService(this.commonCodeListService);
        this.codeListSvc.setBaseService(this.codeListService);
        this.modalSvc.setBaseService(this.modalService);
        this.unitSvc.setBaseService(this.unitService);
        this.browserSvc.setBaseService(this.browserService);
        this.apiSvc.setBaseService(this.apiService);
        this.licenseSvc.setBaseService(this.licenseService);
        this.signalRSvc.setBaseService(this.signalRService);
        this.documentSvc.setBaseService(this.documentService);
        this.designTemplateSvc.setBaseService(this.designTemplateService);
        this.userSvc.setBaseService(this.userService);
        this.calculationSvc.setBaseService(this.calculationService);
        this.integrationsDataSvc.setBaseService(this.integrationsDataService);
        this.featureVisibilityInfoSvc.setBaseService(this.featureVisibilityInfoService);
        this.translationFormatService.getLocalizedStringWithTranslationFormat = this.translationFormatSvc.getLocalizedStringWithTranslationFormat.bind(this.translationFormatSvc);
        this.offlineSvc.setBaseService(this.offlineService);
        this.routingSvc.setBaseService(this.routingService);
        this.querySvc.setBaseService(this.queryService);
        this.importSvc.setBaseService(this.importService);
        this.dateTimeSvc.setBaseService(this.dateTimeService);
        this.loggerSvc.setBaseService(this.loggerService);
        this.reportTemplateSvc.setBaseService(this.reportTemplateService);
        this.menuSvc.setBaseService(this.menuService);
        this.menu2dSvc.setBaseService(this.menu2dService);
        this.trimbleConnectSvc.setBaseService(this.trimbleConnectService);
        this.tourSvc.setBaseService(this.tourService);
        this.authenticationSvc.setBaseService(this.authenticationService);
        this.changesSvc.setBaseService(this.changesService);
        this.favoritesSvc.setBaseService(this.favoritesService);
        this.favoritesServiceDataChangeSubscription = this.favoritesSvc.dataChange.subscribe({
            next: (data: object) => {
                if (this.initFailed) {
                    return;
                }

                this.safeInvoke(() => {
                    const peData = data as IFavoritesData;
                    this.favoritesSvc.initFavorites(peData);
                    this.smartAnchorOrderService.initialize(peData);
                });
            },
            error: (err) => this.handleError(err)
        });
        this.featureVisibilitySvc.setBaseService(this.featureVisibilityService);
        this.integrationsDocumentSvc.setBaseService(this.integrationsDocumentService);
        this.integrationsNotificationSvc.setBaseService(this.integrationsNotificationService);
        this.productInformationSvc.setBaseService(this.productInformationService);
        this.regionOrderSvc.setBaseService(this.regionOrderService);
        this.commonTrackingSvc.setBaseService(this.commonTrackingService);
        this.designSettingsSvc.setBaseService(this.designSettingsService);
        this.sharedEnvironmentSvc.initialize(this.sharedEnvironmentData);
        this.sessionStorageSvc.setBaseService(this.sessionStorageService);
        this.userSettingsSvc.setBaseService(this.userSettingsService);
    }

    /** Called after data is loaded */
    private initServices() {
        this.navigationSvc.setDataFromService(this.initialDataSvc.navigationSourceData);
        this.featureVisibilityInfoSvc.setDataFromService(this.initialDataSvc.featuresPeVisibilityInfo);
        this.unitSvc.setSupportedUnitIds(this.initialDataSvc.supportedUnitIds);
        this.userSettingsSvc.initUserSettingsValidation();
    }

    private async loadData() {
        const loaded = await Promise.allSettled([
            await this.initialDataSvc.initCodeList({ supressErrorMessage: true })
        ]);

        for (const result of loaded) {
            if (result.status === 'rejected') {
                throw result.reason;
            }
        }
    }

    public ngOnDestroy(): void {
        // unsubscribe from favoritesService.dataChange subject.
        if (this.favoritesServiceDataChangeSubscription != null) {
            this.favoritesServiceDataChangeSubscription.unsubscribe();
            this.favoritesServiceDataChangeSubscription = undefined;
        }
    }

    private handleError(error: any, logError = true) {
        this.initFailed = true;

        if (logError) {
            console.error(error);
        }
    }

    private safeInvoke(fn: () => void) {
        try {
            fn();
        }
        catch (error) {
            this.handleError(error);
        }
    }
}
