import { ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    ProductFamilyDetailedEntityC2C
} from '../../generated-modules/Hilti.PE.CalculationService.Shared.Entities.DesignCodeList';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CodeList } from './code-list';

export class FastenerFamily extends CodeList {
    public descriptionChem?: string;
    public descriptionMech?: string;
    public descriptionFormat?: string;
    public descriptionKeys?: string[];
    public displayName?: string;
    public hasTR069?: boolean;
    public isAutomaticCleaningApproved?: boolean;
    public isCleanTech?: boolean;
    public isCrackedConcreteAllowed?: boolean;
    public isDiamondDrillingSuitable?: boolean;
    public isFatigueAllowed?: boolean;
    public isFireAllowed?: boolean;
    public isNuclearApproved_EU?: boolean;
    public isNuclearApproved_HNA?: boolean;
    public isSeismicAllowed?: boolean;
    public isShallowEmbedmentDepth?: boolean;
    public isShock?: boolean;
    public isSmallEdgeDistanceAndSpacing?: boolean;
    public isStainlessSteelOrHCRAvailable?: boolean;
    public isVariableEmbedment?: boolean;
    public isZeroCleaningAllowed?: boolean;
    public returnPeriod?: string;
    public sortOrder?: number;
    public tested?: boolean;
    public type?: string;
    public allowedFilterInputs?: { [key: string]: number[] };
    public holeDiameters?: number[];

    constructor(codeList: ProductFamilyDetailedEntityC2C) {
        super(codeList);

        if (codeList != null) {
            this.descriptionChem = codeList.descriptionChem;
            this.descriptionMech = codeList.descriptionMech;
            this.descriptionFormat = codeList.descriptionFormat;
            this.descriptionKeys = codeList.descriptionKeys;
            this.displayName = codeList.displayName;
            this.hasTR069 = codeList.hasTR069;
            this.id = codeList.id;
            this.image = codeList.image;
            this.isAutomaticCleaningApproved = codeList.isAutomaticCleaningApproved;
            this.isCleanTech = codeList.isCleanTech;
            this.isCrackedConcreteAllowed = codeList.isCrackedConcreteAllowed;
            this.isDiamondDrillingSuitable = codeList.isDiamondDrillingSuitable;
            this.isFatigueAllowed = codeList.isFatigueAllowed;
            this.isFireAllowed = codeList.isFireAllowed;
            this.isNuclearApproved_EU = codeList.isNuclearApproved_EU;
            this.isNuclearApproved_HNA = codeList.isNuclearApproved_HNA;
            this.isSeismicAllowed = codeList.isSeismicAllowed;
            this.isShallowEmbedmentDepth = codeList.isShallowEmbedmentDepth;
            this.isShock = codeList.isShock;
            this.isSmallEdgeDistanceAndSpacing = codeList.isSmallEdgeDistanceAndSpacing;
            this.isStainlessSteelOrHCRAvailable = codeList.isStainlessSteelOrHCRAvailable;
            this.isVariableEmbedment = codeList.isVariableEmbedment;
            this.isZeroCleaningAllowed = codeList.isZeroCleaningAllowed;
            this.returnPeriod = codeList.returnPeriod;
            this.sortOrder = codeList.sortOrder;
            this.tested = codeList.tested;
            this.type = codeList.type;
            this.allowedFilterInputs = codeList.allowedFilterInputs;
            this.holeDiameters = codeList.holeDiameters;
        }
    }

    public static fromService(codeList: ProductFamilyDetailedEntityC2C) {
        return new FastenerFamily(CodeList.baseFromService('FastenerFamilyEntity', codeList, {
            image: codeList.image != null && codeList.image != '' ? 'sprite-anchor-family-' + codeList.image : 'sprite-anchor-family-no-photo-available',
            descriptionChem: codeList.descriptionChem,
            descriptionMech: codeList.descriptionMech,
            descriptionFormat: codeList.descriptionFormat,
            descriptionKeys: codeList.descriptionKeys,
            displayName: codeList.displayName,
            hasTR069: codeList.hasTR069,
            id: codeList.id,
            isAutomaticCleaningApproved: codeList.isAutomaticCleaningApproved,
            isCleanTech: codeList.isCleanTech,
            isCrackedConcreteAllowed: codeList.isCrackedConcreteAllowed,
            isDiamondDrillingSuitable: codeList.isDiamondDrillingSuitable,
            isFatigueAllowed: codeList.isFatigueAllowed,
            isFireAllowed: codeList.isFireAllowed,
            isNuclearApproved_EU: codeList.isNuclearApproved_EU,
            isNuclearApproved_HNA: codeList.isNuclearApproved_HNA,
            isSeismicAllowed: codeList.isSeismicAllowed,
            isShallowEmbedmentDepth: codeList.isShallowEmbedmentDepth,
            isShock: codeList.isShock,
            isSmallEdgeDistanceAndSpacing: codeList.isSmallEdgeDistanceAndSpacing,
            isStainlessSteelOrHCRAvailable: codeList.isStainlessSteelOrHCRAvailable,
            isVariableEmbedment: codeList.isVariableEmbedment,
            isZeroCleaningAllowed: codeList.isZeroCleaningAllowed,
            returnPeriod: codeList.returnPeriod,
            sortOrder: codeList.sortOrder,
            tested: codeList.tested,
            type: codeList.type,
            nameChem: codeList.nameChem,
            nameMech: codeList.nameMech,
            allowedFilterInputs: codeList.allowedFilterInputs,
            holeDiameters: codeList.holeDiameters,
            displayKey: codeList.displayKey
        }));
    }

    public override cloneDeep(): FastenerFamily {
        const codeList = super.cloneDeep() as FastenerFamily;

        codeList.descriptionChem = this.descriptionChem;
        codeList.descriptionMech = this.descriptionMech;
        codeList.descriptionFormat = this.descriptionFormat;
        codeList.descriptionKeys = this.descriptionKeys;
        codeList.displayName = this.displayName;
        codeList.hasTR069 = this.hasTR069;
        codeList.id = this.id;
        codeList.image = this.image;
        codeList.isAutomaticCleaningApproved = this.isAutomaticCleaningApproved;
        codeList.isCleanTech = this.isCleanTech;
        codeList.isCrackedConcreteAllowed = this.isCrackedConcreteAllowed;
        codeList.isDiamondDrillingSuitable = this.isDiamondDrillingSuitable;
        codeList.isFatigueAllowed = this.isFatigueAllowed;
        codeList.isFireAllowed = this.isFireAllowed;
        codeList.isNuclearApproved_EU = this.isNuclearApproved_EU;
        codeList.isNuclearApproved_HNA = this.isNuclearApproved_HNA;
        codeList.isSeismicAllowed = this.isSeismicAllowed;
        codeList.isShallowEmbedmentDepth = this.isShallowEmbedmentDepth;
        codeList.isShock = this.isShock;
        codeList.isSmallEdgeDistanceAndSpacing = this.isSmallEdgeDistanceAndSpacing;
        codeList.isStainlessSteelOrHCRAvailable = this.isStainlessSteelOrHCRAvailable;
        codeList.isVariableEmbedment = this.isVariableEmbedment;
        codeList.isZeroCleaningAllowed = this.isZeroCleaningAllowed;
        codeList.returnPeriod = this.returnPeriod;
        codeList.sortOrder = this.sortOrder;
        codeList.tested = this.tested;
        codeList.type = this.type;
        codeList.allowedFilterInputs = this.allowedFilterInputs;
        codeList.holeDiameters = this.holeDiameters;

        return codeList;
    }

    public override getTranslatedNameText(codeListDeps: ICodeListTextDeps) {
        if (codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        const years = codeListDeps.localizationService.getString('Agito.Hilti.C2C.Product.Years');
        return formatKeyValue(this.displayName ?? '', { years });
    }
}
