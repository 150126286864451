import cloneDeepWith from 'lodash-es/cloneDeepWith.js';
import { Color3 } from '@babylonjs/core/Maths/math.color.js';
import { Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { DataImage } from './data-image.js';
const constants = {
  cameraZoomFactor: 1.3,
  invalidColor: Color3.FromHexString('#ee0011'),
  validColor: Color3.Black(),
  highlightedDimensionsAlpha: 1,
  dimmedDimensionsAlpha: 0.3,
  bottomDimensionOffset: 200,
  rotate0: 0,
  rotate90: Math.PI * 0.5,
  rotate180: Math.PI,
  rotate270: Math.PI * 1.5,
  minSpacing: 1,
  defaultSpacing: 5,
  maxSpacing: 10000
};
export const glModelConstants = constants;
export function cloneModel(model) {
  return cloneDeepWith(model, cloneModelCustomizer);
}
export function cloneModelCustomizer(value) {
  if (value instanceof Vector2 || value instanceof Vector3 || value instanceof DataImage) {
    return value.clone();
  } else {
    return undefined;
  }
}
