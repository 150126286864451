import {
    KBNumberControlRegionEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class KBNumberControlRegion extends CodeList {

    public static fromService(codeList: KBNumberControlRegionEntityC2C) {
        return new KBNumberControlRegion(CodeList.baseFromService('KBNumberControlRegionEntity', codeList, {
            id: codeList.controlId,
            kbNumberRegion: codeList.kbNumberRegion
        }));
    }

    public override cloneDeep(): KBNumberControlRegion {
        return super.cloneDeep() as KBNumberControlRegion;
    }
}
