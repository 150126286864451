/**
 * kgf in N
 */
const kgfInN = 9.80665;
/**
 * N in kgf
 */
const NInKgf = 0.10197162129779283;
/**
 * kgfm in Nmm
 */
const kgfmInNmm = 9806.65;
/**
 * Nmm in kgfm
 */
const NmmInKgfm = 0.00010197162129779283;
/* eslint-disable no-sparse-arrays */
export const conversionMatrixLength = [[1, 0.1, 0.001, 1 / 25.4, 0.0393700787 / 12, 0.000000621371192], [10, 1, 0.01, 10 / 25.4, 0.393700787 / 12, 0.00000621371192], [1000, 100, 1, 1000 / 25.4, 39.3700787 / 12, 0.000621371192], [25.4, 2.54, 0.0254, 1, 1 / 12, 0.0000157828283], [304.8, 30.48, 0.3048, 12, 1, 0.000189393939], [1609344, 160934.4, 1609.344, 63360, 5280, 1]];
export const conversionMatrixForce = [[1, 0.1, 0.001, 0.224808943, 0.0002248089, NInKgf], [10, 1, 0.01, 2.24808943, 0.002248089, 10 * NInKgf], [1000, 100, 1, 224.808943, 0.224808943, 1000 * NInKgf], [1 / 0.224808943, 1 / 2.24808943, 1 / 224.808943, 1, 0.001, 1 / 0.224808943 * NInKgf], [1 / 0.000224808943, 1 / 0.00224808943, 1 / 0.224808943, 1000, 1, 1 / 0.000224808943 * NInKgf], [kgfInN, 0.1 * kgfInN, 0.001 * kgfInN, 0.224808943 * kgfInN, 0.0002248089 * kgfInN, 1], [9806.65, 980.665, 98.0665, 2204.622621852, 2.204622621852, 1000, 1]];
export const conversionMatrixMoment = [[1, 0.001, 0.0001, 0.000001, 0.000737562149 * 12, 0.000737562149, 0.000000737562149277266 * 12, 0.000000737562149277266, NmmInKgfm, 100 * NmmInKgfm], [1000, 1, 0.1, 0.001, 0.737562149 * 12, 0.737562149, 0.000737562149277266 * 12, 0.000737562149277266, 1000 * NmmInKgfm, 100000 * NmmInKgfm], [10000, 10, 1, 0.01, 7.37562149 * 12, 7.37562149, 0.00737562149277266 * 12, 0.00737562149277266, 10000 * NmmInKgfm, 1000000 * NmmInKgfm], [1000000, 1000, 100, 1, 737.562149 * 12, 737.562149, 0.737562149277266 * 12, 0.737562149277266, 1000000 * NmmInKgfm, 100000000 * NmmInKgfm], [1 / (0.000737562149 * 12), 1 / (0.737562149 * 12), 1 / (7.37562149 * 12), 1 / (737.562149 * 12), 1, 1 / 12, 0.001, 0.001 / 12, 1 / (0.000737562149 * 12) * NmmInKgfm, 1 / (0.000737562149 * 12) * 100 * NmmInKgfm], [1 / 0.000737562149, 1 / 0.737562149, 1 / 7.37562149, 1 / 737.562149, 12, 1, 0.012, 0.001, 1 / 0.000737562149 * NmmInKgfm, undefined, 1 / 0.000737562149 * 100 * NmmInKgfm], [1000 / (0.000737562149277266 * 12), 1000 / (0.737562149277266 * 12), 1000 / (7.37562149277266 * 12), 1000 / (737.562149277266 * 12), 1000, 1000 / 12, 1, 1 / 12, 1000 / (0.000737562149277266 * 12) * NmmInKgfm, undefined, 1000 / (0.000737562149277266 * 12) * 100 * NmmInKgfm], [1000 / 0.000737562149277266, 1000 / 0.737562149277266, 1000 / 7.37562149277266, 1000 / 737.562149277266, 12000, 1000, 12, 1, 1000 / 0.000737562149277266 * NmmInKgfm, 1000 / 0.000737562149277266 * 100 * NmmInKgfm], [kgfmInNmm, 0.001 * kgfmInNmm, 0.0001 * kgfmInNmm, 0.000001 * kgfmInNmm, 0.000737562149 * 12 * kgfmInNmm, 0.000737562149 * kgfmInNmm, 0.000000737562149277266 * 12 * kgfmInNmm, 0.000000737562149277266 * kgfmInNmm, 1, 100], [0.01 * kgfmInNmm, 0.00001 * kgfmInNmm, 0.000001 * kgfmInNmm, 0.00000001 * kgfmInNmm, 0.000737562149 * 12 * 0.01 * kgfmInNmm, 0.000737562149 * 0.01 * kgfmInNmm, 0.000000737562149277266 * 12 * 0.01 * kgfmInNmm, 0.000000737562149277266 * 0.01 * kgfmInNmm, 0.01, 1]];
export const conversionMatrixPercentage = [[1, 10], [0.1, 1]];
export const conversionMatrixStress = [[1, 145.037738, 0.145037738, 1000, 1, 1000000, 10.197162129779, 1 / 4.788e-5], [1 / 145.037738, 1, 0.001, 1 / 0.145037738, 1 / 145.037738, 1 / 0.000145037738, 0.070306957829636, 1 / (1 / 144)], [1 / 0.145037738, 1000, 1, 1 / 0.000145037738, 1 / 0.145037738, 1 / 0.000000145037738, 70.306957829636, 1 / (1 / (144 * 1000))], [0.001, 0.145037738, 0.000145037738, 1, 0.001, 1000, 0.010197162129779282, 1 / 0.04788], [1, 145.037738, 0.145037738, 1000, 1, 1000000, 10.197162129779, 1 / 4.788e-5], [0.000001, 0.000145037738, 0.000000145037738, 0.001, 0.000001, 1, 0.000010197162129779282, 1 / 47.88], [1 / 10.197162129779, 1 / 0.070306957829636, 1 / 70.306957829636, 1 / 0.010197162129779282, 1 / 10.197162129779, 1 / 0.000010197162129779282, 1, 1 / 4.788e-4], [4.788e-5, 1 / 144, 1 / (144 * 1000), 0.04788, 4.788e-5, 47.88, 4.788e-4, 1]];
export const conversionMatrixTime = [[1, 0.001, 1 / 60000, 1 / 3600000], [1000, 1, 1 / 60, 1 / 3600], [60000, 60, 1, 1 / 60], [3600000, 3600, 60, 1]];
export const conversionMatrixVelocity = [[1]];
export const conversionMatrixDensity = [[1, 0.0624279606], [16.01846337396, 1]];
export const conversionMatrixSpecificWeight = [[1, 1000000, 6365880.3356736], [0.000001, 1, 0.63658803356736], [6365880.3356736, 0.63658803356736, 1]];
/* eslint-enable no-sparse-arrays */
export class UnitValue {
  value;
  unit;
  constructor(value, unit) {
    this.value = value;
    this.unit = unit;
  }
}
export class UnitServiceBase {}
export class UnitServiceInjected extends UnitServiceBase {
  setBaseService(baseService) {
    this.getUnitGroupFromUnit = baseService.getUnitGroupFromUnit.bind(baseService);
    this.getInternalUnit = baseService.getInternalUnit.bind(baseService);
    this.convertUnitValueToUnit = baseService.convertUnitValueToUnit.bind(baseService);
    this.incDecValueByUnit = baseService.incDecValueByUnit.bind(baseService);
    this.formatUnitValue = baseService.formatUnitValue.bind(baseService);
    this.getPrecision = baseService.getPrecision.bind(baseService);
    this.formatNumber = baseService.formatNumber.bind(baseService);
    this.parseUnitValue = baseService.parseUnitValue.bind(baseService);
    this.formatUnitValueArgs = baseService.formatUnitValueArgs.bind(baseService);
    this.formatUnitAsDefault = baseService.formatUnitAsDefault.bind(baseService);
    this.convertUnitValueArgsToUnit = baseService.convertUnitValueArgsToUnit.bind(baseService);
    this.transformWithSeparator = baseService.transformWithSeparator.bind(baseService);
    this.getDefaultUnit = baseService.getDefaultUnit.bind(baseService);
    this.getUnitStrings = baseService.getUnitStrings.bind(baseService);
    this.formatInternalValueAsDefault = baseService.formatInternalValueAsDefault.bind(baseService);
    this.parseNumber = baseService.parseNumber.bind(baseService);
    this.formatUnit = baseService.formatUnit.bind(baseService);
    this.convertInternalValueToDefaultValue = baseService.convertInternalValueToDefaultValue.bind(baseService);
    this.getUnitGroupCodeList = baseService.getUnitGroupCodeList.bind(baseService);
    this.parseUnknownUnitValue = baseService.parseUnknownUnitValue.bind(baseService);
    this.getDefaultPrecision = baseService.getDefaultPrecision.bind(baseService);
    this.convertInternalValueToDefaultUnitValue = baseService.convertInternalValueToDefaultUnitValue.bind(baseService);
    this.convertUnitValueToInternalUnitValue = baseService.convertUnitValueToInternalUnitValue.bind(baseService);
    this.appendPrecisionLossSymbolToValueString = baseService.appendPrecisionLossSymbolToValueString.bind(baseService);
    this.appendUnitToValueString = baseService.appendUnitToValueString.bind(baseService);
  }
  // UnitServiceBase methods
  /* eslint-disable @typescript-eslint/no-unused-vars */
  getUnitGroupFromUnit(_unit) {
    return 0 /* UnitGroup.None */;
  }
  getInternalUnit(_unitGroup) {
    return 0 /* UnitGroup.None */;
  }
  convertUnitValueToUnit(_unitValue, unit) {
    return new UnitValue(0, unit);
  }
  incDecValueByUnit(_unit, stepValue) {
    return 0;
  }
  formatUnitValue(_value, _precision, _decimalSeparator, _groupSeparator, _uiProperty, _toFixed) {
    return '';
  }
  getPrecision(_unit, _uiProperty, _unitPrecision) {
    return 0;
  }
  formatNumber(_value, _decimals, _toFixed, _decimalSeparator, _groupSeparator) {
    return '';
  }
  parseUnitValue(_value, _unitGroup, _defaultUnit, _decimalSeparator, _groupSeparator) {
    return new UnitValue(0, 600 /* Unit.C */);
  }
  formatUnitValueArgs(_value, _unit, _precision, _decimalSeparator, _groupSeparator, _uiProperty, _appendUnit, _toFixed) {
    return '';
  }
  formatUnitAsDefault(_value, _unitGroup) {
    return '';
  }
  convertUnitValueArgsToUnit(_value, _unitFrom, _unitTo, _useDefaulPrecision) {
    return 0;
  }
  transformWithSeparator(_value, _fromDecimalSeparator, _fromGroupSeparator, _toDecimalSeparator, _toGroupSeparator, _precision, _uiProperty) {
    return '';
  }
  getDefaultUnit(_unitGroup, _design) {
    return 0 /* Unit.None */;
  }
  formatInternalValueAsDefault(_value, _unitGroup, _precision, _design) {
    return '';
  }
  getUnitStrings(_unit) {
    return [];
  }
  parseNumber(_value, _decimalSeparator, _groupSeparator) {
    return 0;
  }
  formatUnit(unit) {
    return '';
  }
  convertInternalValueToDefaultValue(_value, _unitGroup) {
    return 0;
  }
  getUnitGroupCodeList(_unitGroup) {
    return [];
  }
  parseUnknownUnitValue(_value, _decimalSeparator, _groupSeparator) {
    return new UnitValue(0, 0 /* Unit.None */);
  }
  getDefaultPrecision() {
    return 0;
  }
  convertInternalValueToDefaultUnitValue(_value, _unitGroup) {
    return new UnitValue(0, 0 /* Unit.None */);
  }
  convertUnitValueToInternalUnitValue(_unitValue) {
    return undefined;
  }
  appendPrecisionLossSymbolToValueString(_value, _valueString) {
    return '';
  }
  appendUnitToValueString(_value, _valueString, _unit) {
    return '';
  }
}
