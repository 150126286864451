import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { CreateLineSystem } from '@babylonjs/core/Meshes/Builders/linesBuilder';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { CreateIUnitText2DCreateOptions } from '@profis-engineering/gl-model/components/base-component';
import { UnitGroup } from '@profis-engineering/gl-model/external/unit-converter';
import { PunchPropertyId } from '../../../../services/design.service';
import { PunchComponent, PunchComponentConstructor } from '../../../punch-component';
import { PunchUnitText2D } from '../../../punch-gl-model';
import { GetMeasurementsAlphaIndex, MeasurementMetaData } from '../../alpha-index-helper';
import { ArrowDirection, CreateArrow, CustomMeasurementValue, dimensionLinesColor, GetPlaneHeightfromPlaneOriginVector, GetPlaneYFromPlaneOriginVector, GetYAxis, incrementalOffset, initialOffset, MeasurementPlane, Project2DPlaneInto3D } from '../../measurements-common/measurements-helper';
import { PunchLengthMeasurement, PunchWidthMeasurement, SpanXMeasurement, SpanYMeasurement, ThicknessMeasurement } from './punch-measurements-base-material';


/** A collection of measurements on a single plane */
interface PunchMeasurementsPlaneData {
    /** Mesh consisting of all measurements on the plane */
    linesMesh?: LinesMesh | undefined;
    /** Array of all Measurement rows on this plane */
    measurementsRows: PunchMeasurementPlaneRowData[];
    /** Specified plane this data is on */
    plane: MeasurementPlane;
    /** Origin point (0,0) of this plane */
    planeRoot: Vector3;
}

/** A collection of measurements in a single row */
export interface PunchMeasurementPlaneRowData {
    /** Is enabled/displayed indicator */
    enabled: boolean;
    /** A collection of measurements in a this row */
    measurements: PunchMeasurementData[];
}

/**
 * Data for a single measurement on 2D plane
 *       ___________________
 *      |(x1, y2)           | (x2, y2)
 *      |                   |
 *      |                   |
 * _____|___________________|_____
 *      ^ plane origin      |
 *      |                   |
 *      |                   |
 *       (x1, y1)            (x2, y1)
 *       starting root       ending root
 *
 *      y2 = calculated based in which row on the plane measurement lies
 */
export interface PunchMeasurementData {
    /** x value of the starting root of the measurement on 2D plane (point where measurement starts) */
    x1: number;
    /** x value of the ending root of the measurement on 2D plane (point where measurement ends) */
    x2: number;
    /** y value of the starting root measurement on 2D plane */
    y1: number;
    /** y value of the ending root measurement on 2D plane */
    y2: number;
    /** Property corresponding to this measurement */
    property: PunchPropertyId;
    /** Custom value of measurement; overrides property value and is unediable */
    custom: CustomMeasurementValue | false;
}

interface PunchTextData {
    text: PunchUnitText2D;
    metadata?: Record<string, unknown>;
}

export class PunchMeasurements extends PunchComponent {
    private measurementTextsData: Partial<Record<PunchPropertyId, PunchTextData>> = {};
    private measurementMeshes: Mesh[];
    private textCtor: CreateIUnitText2DCreateOptions;

    constructor(ctor: PunchComponentConstructor) {
        super(ctor);

        this.textCtor = { unitGroup: UnitGroup.Length };
        this.measurementMeshes = [];
        this.createTexts();
        this.beforeCameraRender = this.beforeCameraRender.bind(this);
        this.sceneEvents.addEventListener('onBeforeCameraRenderObservable', this.beforeCameraRender);
    }

    public update(): void {
        this.ensureMesh();
    }

    /**
     * Draws measurements based on MeasurementsData
     */
    private ensureMesh() {
        for (const key in this.measurementTextsData) {
            this.measurementTextsData[key as PunchPropertyId]?.text.setEnabled(false);

            this.measurementTextsData[key as PunchPropertyId]?.text.mesh?.setParent(this.cache.meshCache.getDefaultTransformNode());
        }

        for (const mesh of this.measurementMeshes) {
            mesh.setEnabled(false);
        }
        this.measurementMeshes = [];

        let index = 0;

        // TODO JANJ: if MeasurementsPlaneData from getMeasurementData doesn't change skip update since there is nothing to update
        // you can probably use lodash deep equal to compare MeasurementsPlaneData but you have to write a custom compare (compare by ref) for linesMesh property

        // Iterate through each plane defined in measurement data
        for (const plane of this.getMeasurementData()) {
            // Value of planes origin vector coresponding to planes normal vector dimension
            const planeHeight = GetPlaneHeightfromPlaneOriginVector(plane.planeRoot, plane.plane);

            // Indicator whether the plane extands into positive or negative direction on its Planes Y axis
            const positivePlaneDirection = GetPlaneYFromPlaneOriginVector(plane.planeRoot, plane.plane) > 0;
            const directionModifier = positivePlaneDirection ? 1 : -1;

            // Offset of measurement height on 2D plane it's drawn on
            // Each measurement row on plane is drawn above the previous ones
            let measurementHeight = initialOffset * directionModifier;

            // Iterate throught all measurement rows on measurement plane
            for (const measurementRow of plane.measurementsRows) {
                if (measurementRow.enabled) {
                    // Iterate through all measurements in measurement row
                    for (const measurement of measurementRow.measurements) {
                        const measurementY = GetPlaneYFromPlaneOriginVector(plane.planeRoot, plane.plane) + measurementHeight;
                        // rootStart and rootEnd are end points of measurement, origin points of measurement
                        const rootStart = Project2DPlaneInto3D(measurement.x1, measurement.y1, planeHeight, plane.plane);
                        const rootEnd = Project2DPlaneInto3D(measurement.x2, measurement.y2, planeHeight, plane.plane);
                        // measurementStart and measurementEnd are points of measurement line that holds the text and arrows
                        const measurementStart = Project2DPlaneInto3D(measurement.x1, measurementY, planeHeight, plane.plane);
                        const measurementEnd = Project2DPlaneInto3D(measurement.x2, measurementY, planeHeight, plane.plane);
                        const measurementName = measurement.property + 'Measurement' + index;

                        // metadata added for purposes of calculating alpha index
                        const metadata = MeasurementMetaData(plane.plane, plane.planeRoot);
                        index++;

                        const lines: Vector3[][] = [];
                        // add lines representing measurement and its arrows to lines array
                        lines.push([rootStart, measurementStart, measurementEnd, rootEnd]);
                        lines.push(CreateArrow(plane.plane, measurementStart, ArrowDirection.Start));
                        lines.push(CreateArrow(plane.plane, measurementEnd, ArrowDirection.End));

                        // add text to measurement
                        const data = this.getMeasurementText(measurement.property);
                        if (data == undefined) {
                            console.log('Missing UnitText2D for ' + measurement.property);
                        }
                        else {
                            const value = this.getMeasurementValue(measurement);
                            data.text.setValueOnLine(
                                value,
                                measurementStart,
                                measurementEnd,
                                GetYAxis(plane.plane, directionModifier)
                            );
                            data.text.setEditable(measurement.custom == false);
                            data.metadata = metadata;
                        }

                        // draw and update the measurement mesh
                        plane.linesMesh = this.cache.meshCache.create(measurementName, () => CreateLineSystem(measurementName, { lines: lines, updatable: true }, this.scene));
                        plane.linesMesh.setEnabled(true);
                        plane.linesMesh.color = dimensionLinesColor;
                        plane.linesMesh.metadata = metadata;

                        CreateLineSystem(null!, { lines: lines, instance: plane.linesMesh }, this.scene);
                        plane.linesMesh.refreshBoundingInfo();

                        // rotation
                        plane.linesMesh.parent = this.cache.meshCache.getDefaultTransformNode();

                        this.measurementMeshes.push(plane.linesMesh);
                    }
                    measurementHeight += incrementalOffset * directionModifier;
                }
            }
        }
    }

    /**
     * Gets the data representing all measurements
     */
    private getMeasurementData(): PunchMeasurementsPlaneData[] {
        return [
            {
                plane: MeasurementPlane.XY,
                measurementsRows: [
                    SpanXMeasurement(this.model)
                ],
                planeRoot: new Vector3(0, -this.model.baseMaterial.thickness / 2, -this.model.baseMaterial.spanNegY)
            },
            {
                plane: MeasurementPlane.ZX,
                measurementsRows: [
                    ThicknessMeasurement(this.model)
                ],
                planeRoot: new Vector3(this.model.baseMaterial.spanPosX, 0, -this.model.baseMaterial.spanNegY)
            },
            {
                plane: MeasurementPlane.YX,
                measurementsRows: [
                    SpanYMeasurement(this.model)
                ],
                planeRoot: new Vector3(this.model.baseMaterial.spanPosX, -this.model.baseMaterial.thickness / 2, -this.model.baseMaterial.spanNegY)
            },
            {
                plane: MeasurementPlane.XY,
                measurementsRows: [
                    PunchLengthMeasurement(this.model)
                ],
                planeRoot: new Vector3(-this.model.baseMaterial.punchLength, -this.model.baseMaterial.thickness / 2 - 1000, -this.model.baseMaterial.punchWidth / 2 - this.model.baseMaterial.punchLength / 2)
            },
            {
                plane: MeasurementPlane.YX,
                measurementsRows: [
                    PunchWidthMeasurement(this.model)
                ],
                planeRoot: new Vector3(this.model.baseMaterial.punchLength / 2 + this.model.baseMaterial.punchWidth / 2, -this.model.baseMaterial.thickness / 2 - 1000, -this.model.baseMaterial.punchWidth)
            },
        ];
    }

    private createTexts() {
        this.createText('spanPosX');
        this.createText('spanNegX');
        this.createText('spanPosY');
        this.createText('spanNegY');
        this.createText('thickness');
        this.createText('punchLength');
        this.createText('punchWidth');
    }

    private createText(property: PunchPropertyId) {
        this.measurementTextsData[property] = { text: this.createUnitText2DForProperty(this.textCtor, property) };
    }

    private getMeasurementValue(measurementData: PunchMeasurementData): number {
        if (measurementData.custom) {
            return measurementData.custom.value;
        }
        else {
            return this.getPropertyValue(measurementData.property);
        }
    }

    private getMeasurementText(property: PunchPropertyId | undefined) {
        if (property == undefined) {
            return undefined;
        }
        else {
            return this.measurementTextsData[property];
        }
    }

    private getPropertyValue(property: PunchPropertyId): number {
        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        switch (property) {
            case 'spanPosX': return this.model.baseMaterial.spanPosX;
            case 'spanNegX': return this.model.baseMaterial.spanNegX;
            case 'spanPosY': return this.model.baseMaterial.spanPosY;
            case 'spanNegY': return this.model.baseMaterial.spanNegY;
            case 'thickness': return this.model.baseMaterial.thickness;
            case 'punchLength': return this.model.baseMaterial.punchLength;
            case 'punchWidth': return this.model.baseMaterial.punchWidth;
            default:
                throw new Error('getPropertyValue undefined for ' + property);
        }
    }

    protected beforeCameraRender(): void {
        if (this.measurementMeshes != undefined) {
            for (const mesh of this.measurementMeshes) {
                // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-member-access
                mesh.alphaIndex = GetMeasurementsAlphaIndex(this.camera.position, mesh.metadata['MeasurementMetaData'] as MeasurementMetaData);
            }

            for (const key in this.measurementTextsData) {
                const data = this.measurementTextsData[key as PunchPropertyId];
                if (data?.metadata != undefined) {
                    data.text.setAlphaIndex(GetMeasurementsAlphaIndex(this.camera.position, data.metadata['MeasurementMetaData'] as MeasurementMetaData));
                }
            }
        }
    }

    public override dispose(): void {
        super.dispose();

        for (const key in this.measurementTextsData) {
            this.measurementTextsData[key as PunchPropertyId]?.text.dispose();
        }
    }
}
