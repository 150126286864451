// Script to call Texts on 3d/2d model without clicking on 3d. Used for selenium testing.
let availableGlobalTexts = [];
export function addText2DToGlobal(text) {
  availableGlobalTexts.push(text);
}
export function removeText2DFromGlobal(uiText) {
  availableGlobalTexts = availableGlobalTexts.filter(text => text != uiText);
}
export function getText2DFromGlobal(uiProperty, index) {
  const texts = availableGlobalTexts.filter(text => text.property == uiProperty && text.text != '');
  return texts[index ?? 0];
}
export function updateText2D(uiProperty, value, index) {
  const text = getText2DFromGlobal(uiProperty, index);
  if (text?.onValueEntered == null) {
    throw new Error('Text2D or onValueEntered is not defined');
  }
  text.onValueEntered(text, value);
}
export function getText2DString(uiProperty, index) {
  const text = getText2DFromGlobal(uiProperty, index);
  if (text?.text == null) {
    throw new Error('Text2D or text is not defined');
  }
  return text.text;
}
export function isText2DEnabled(uiProperty, index) {
  const text = getText2DFromGlobal(uiProperty, index);
  return text?.getEditable();
}
