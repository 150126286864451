<pe-main-header class="main-header"
    #mainHeaderRef
    [designName]="title"
    [designRegion]="design.region"
    [beforeLogout]="beforeLogout"
    [openDesignSettings]="openDesignSettings"
    [openSaveAsTemplate]="openSaveAsTemplate"
    [startTour]="startTour"
    [openGeneralNotes]="openGeneralNotes"
    (menuOpened)="menuOpened()"
    (hiltiDataPrivacyUrlOpened)="hiltiDataPrivacyUrlOpened()"
    (regionLinkOpened)="regionLinkOpened()">
</pe-main-header>

<div class="main-content">
    <!-- LEFT -->

    <div class="main-content-left" [class.width-extended]="hasExtendedWidth" [class.main-content-left-hidden]="hideLeftMenu">
        <div id="main-content-left-container" class="main-content-left-container">
            <div class="black-bar"></div>
            <pe-main-menu
                #mainMenuRef
                class="menu-container"
                [disableDragToMoveText]="false"
                [tabsHidden]="hideLeftMenu"
                *ngIf="selectedMenu != null">
            </pe-main-menu>
        </div>
    </div>

    <div class="main-content-center-right" #mainContentCenterRightRef>
        <div class="main-content-center-right-top" #mainContentCenterRightTopRef>
            <div class="model-view-options-empty-div-virtual-tour"></div>
            <!-- CENTER -->
            <div class="main-content-center">
                <pe-button id="hide-left-menu" class="hide-menu-button hide-menu-button-left"
                    [look]="'DefaultIcon'"
                    (buttonClick)="toggleLeftMenu()"
                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.CollapseMenu' | l10n: localizationService.selectedLanguage">
                    <span class="pe-ui-pe-sprite" [class.pe-ui-pe-sprite-arrow-left-medium]="!hideLeftMenu"
                        [class.pe-ui-pe-sprite-arrow-right-medium]="hideLeftMenu"></span>
                </pe-button>

                <pe-button id="hide-right-menu" class="hide-menu-button hide-menu-button-right"
                    [look]="'DefaultIcon'"
                    (buttonClick)="toggleRightMenu()"
                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.CollapseMenu' | l10n: localizationService.selectedLanguage">
                    <span class="pe-ui-pe-sprite" [class.pe-ui-pe-sprite-arrow-right-medium]="!hideRightMenu"
                        [class.pe-ui-pe-sprite-arrow-left-medium]="hideRightMenu"></span>
                </pe-button>

                <div class="main-content-position">
                    <div class="model-view-options click-through">
                        <div class="model-view-options-empty-div-virtual-tour model-view-options-empty-div-click-reset-virtual-tour" ></div>
                        <div class="options-left">
                            <div class="button-group undo-redo-section click-reset" role="group">
                                <div class="buttons join">
                                    <div class="tooltip-wrapper" [ngbTooltip]="undoTooltip" placement="auto top">
                                        <pe-button id="main-undo-button"
                                            [look]="'DefaultIcon'"
                                            (buttonClick)="undo()"
                                            [disabled]="!canUndo()">
                                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-undo"></span>
                                        </pe-button>
                                    </div>
                                    <div class="tooltip-wrapper" [ngbTooltip]="redoTooltip" placement="auto top">
                                        <pe-button id="main-redo-button"
                                            [look]="'DefaultIcon'"
                                            (buttonClick)="redo()"
                                            [disabled]="!canRedo()">
                                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-redo"></span>
                                        </pe-button>
                                    </div>
                                </div>
                            </div>

                            <div class="button-group click-reset" role="group">
                                <pe-numeric-text-box
                                    [value]="modelViewZoom"
                                    (valueChange)="zoomPercentageChange($any($event).detail)"
                                    [unit]="this.zoomUnit"
                                    [minValue]="0"
                                    [maxValue]="100"
                                    [updateOnBlur]="true"
                                    [required]="true"
                                    [width]="80"
                                    [height]="32">
                                </pe-numeric-text-box>
                            </div>

                            <div class="button-group click-reset" role="group" *ngIf="view == ViewType.View3d">
                                <pe-button id="main-zoom-to-fit-button"
                                    [look]="'DefaultIcon'"
                                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.ZoomToFit' | l10n: localizationService.selectedLanguage"
                                    (buttonClick)="zoomToFit()">
                                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-search"></span>
                                </pe-button>
                            </div>

                            <div class="button-group click-reset" role="group">
                                <pe-button id="main-reset-camera-button"
                                    [look]="'DefaultIcon'"
                                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.ResetCamera' | l10n: localizationService.selectedLanguage"
                                    (buttonClick)="resetCamera()">
                                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-center"></span>
                                </pe-button>
                            </div>
                            <div class="button-group click-reset" role="group" ngbDropdown [autoClose]="false" closeOutsideNgbDropdown #displayOptionsDropdown="ngbDropdown" #displayOptionsDropdownElementRef>
                                <pe-button id="main-model-display-options-button" ngbDropdownToggle
                                    [look]="'DefaultIcon'"
                                    [ngbTooltip]="displayOptionsTooltip">
                                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-view"></span>
                                </pe-button>

                                <div id="display-options-dropdown" ngbDropdownMenu>
                                    <div class="model-view-options-virtual-tour">
                                        <div class="text" l10n="Agito.Hilti.Profis3.VirtualTour.Concrete.Step8.ClickHere"></div>
                                        <div class="virtual-tour-arrow-right pe-ui-pe-sprite pe-ui-pe-sprite-virtual-tour-arrow-right"></div>
                                    </div>

                                    <pe-checkbox-button
                                        *ngIf="!useNewDisplayOptionsDropdown"
                                        [items]="displayOptionsCheckbox.items"
                                        [selectedValues]="displayOptionsCheckbox.selectedValues"
                                        (itemToggle)="displayOptionsCheckboxItemToggle($any($event).detail)">
                                    </pe-checkbox-button>

                                    <pe-checkbox-button-group
                                        *ngIf="useNewDisplayOptionsDropdown"
                                        [groupItems]="displayOptionsCheckboxConcrete.groupItems"
                                        [selectedValues]="displayOptionsCheckboxConcrete.selectedValues"
                                        (selectedValuesChange)="displayOptionsCheckboxItemToggle($any($event).detail)">
                                    </pe-checkbox-button-group>
                                </div>
                            </div>

                            <div class="btn-group button-group click-reset" role="group" *ngIf="view == ViewType.View2d">
                                <pe-toggle-button-group
                                    [look]="'Default'"
                                    [itemWidth]="40"
                                    [confirmProps]="switchToManualProps"
                                    [items]="mode2dToggleButtonGroup.items"
                                    [model]="mode2d"
                                    (modelChange)="updateMode($any($event).detail)">
                                </pe-toggle-button-group>
                            </div>

                            <pe-dropdown *ngIf="showCalculationOutputDropdown()"
                                id="advanced-calculation-output-display"
                                class="click-reset"
                                [selectedValue]="calculationOutputDropdown.selectedValue"
                                (selectedValueChange)="calculationOutputDropdown.selectedValue = $any($event).detail; onCalculationOutputValueChange()"
                                [items]="calculationOutputDropdown.items">
                            </pe-dropdown>

                            <div class="flex-line-break"></div>

                            <div class="bottom">
                                <div class="options-left-bottom">
                                    <div class="button-group" role="group"
                                        *ngIf="view != ViewType.View2d && design.designType.id != DesignTypeId.Masonry && design.designType.id != DesignTypeId.MetalDeck">
                                        <pe-button id="main-3D-editor-mode-button" class="open-close-3d click-reset"
                                            [look]="'Primary'"
                                            [width]="'124px'"
                                            [disabled]="selectedMenu == null || design.isReadOnlyDesignMode || is2DModeDisabled "
                                            (buttonClick)="openClose2d()"
                                            [ngbTooltip]="'Agito.Hilti.Profis3.Main.2D.Enter2DEditor' | l10n: localizationService.selectedLanguage">
                                            <span l10n="Agito.Hilti.Profis3.Main.2D.2DEditorMode"></span>
                                        </pe-button>
                                    </div>
                                    <div class="button-group" role="group" *ngIf="view == ViewType.View2d">
                                        <pe-button id="main-2D-editor-mode-button" class="open-close-2d click-reset"
                                            [look]="'Primary'"
                                            [width]="'124px'"
                                            (buttonClick)="openClose2d()"
                                            [ngbTooltip]="'Agito.Hilti.Profis3.Main.2D.Leave2DEditor' | l10n: localizationService.selectedLanguage">
                                            <div class="span-wrapper">
                                                <span class="pe-ui-pe-sprite pe-ui-pe-sprite-arrow-left-white button-rich-caret"></span>
                                                <span l10n="Agito.Hilti.Profis3.Main.3D.3DEditorMode"></span>
                                            </div>
                                        </pe-button>
                                    </div>
                                    <div class="flex-line-break"></div>
                                    <div class="loading-options">
                                        <div class="loading-container" [class.show-loading]="isLoading">
                                            <pe-loading [color]="'#524f53'"></pe-loading>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="selection-options click-through" *ngIf="view == ViewType.View2d && showDraggingTooltip">
                        <div class="text" [innerHtml]="draggingSelectionOptions"></div>
                    </div>

                    <div class="selection-options click-through" *ngIf="view == ViewType.View2d && showDraggingTooltipPlateDisabled">
                        <div class="text" [innerHtml]="draggingDisabledPlate"></div>
                    </div>

                    <div class="model-view-container">
                        <pe-gl-model #glModelRef *ngIf="glModel != null" [context3dKey]="Context3dKeyMain"
                            [continuousRender]="glModel.continuousRender" [model]="glModel.model"
                            [asadResetPropertyList]="asadResetPropertyList"
                            (fontsLoaded)="glModel.onFontsLoaded()" (zoom)="glModel.onZoom($any($event).detail)"
                            (selectTab)="glModel.onSelectTab($any($event).detail)"
                            (positionsChanged)="glModel.onPositionsChanged($any($event).detail)"
                            (draggingSelectionChanged)="glModel.onDraggingSelectionChanged($any($event).detail)"
                        ></pe-gl-model>
                    </div>

                    <pe-legend *ngIf="view == ViewType.View3d" class="model-view-legend click-through"
                        [loadsLegendType]="design.designData.reportData.LoadsLegendType"
                        [hasLoadsInWorstPosition]="hasLoadsInWorstPosition">
                    </pe-legend>

                    <pe-stress-check-legend *ngIf="view == ViewType.View3d" class="stress-check-legend-wrapper click-through"
                        [textureDisplay]="calculationOutputDropdown.selectedValue">
                    </pe-stress-check-legend>
                </div>

                <div id="floating-dropdowns" class="click-through" [class.click-through-asad-width]="(isAsad && isAsadVisible) || isSmartAnchorVirtualTour">
                    <pe-info-section
                        *ngIf="!isAnchorAIEnable && !isSmartAnchorVirtualTour"
                        class="info-section-container click-reset"
                        [collapsed]="floatingInfo != FloatingInfo.InfoSection"
                        (collapsedChange)="floatingInfoChange(FloatingInfo.InfoSection, $any($event).detail)"
                        [parentElement]="mainContentCenterRightTopRef">
                    </pe-info-section>

                    <pe-weld-curves
                        *ngIf="showWeldCurvesDropdown() && !isSmartAnchorVirtualTour"
                        class="click-reset"
                        [weldCurves]="weldCurves"
                        [updateGlModelSelectedWeldNameFn]="glModelComponentUpdate"
                        [onWeldClicked]="glModelComponent.weldClicked"
                        [collapsed]="floatingInfo != FloatingInfo.WeldCurves"
                        (collapsedChange)="floatingInfoChange(FloatingInfo.WeldCurves, $any($event).detail)">
                    </pe-weld-curves>

                    <div id="smartAnchorSearchSolutions" [attr.data-id]="CollapsingControls.SmartBaseplateDesign" class="asad-section" *ngIf="(isAsad && isAsadVisible)">
                        <pe-smart-baseplate-design class="click-reset"
                            [parentElement]="mainContentCenterRightTopRef"
                            [mainMenuElement]="mainMenuComponentElementRef">
                        </pe-smart-baseplate-design>
                    </div>

                    <div id="smartSolutionsVirtualTour" class="asad-section" *ngIf="isSmartAnchorVirtualTour">
                        <div id="virtualTourTempDiv"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- LOADS -->
        <pe-loads *ngIf="loadsVisible()" class="loads-container-wrapper"
            [resize3d]="resize3d"
            [selectedLoadId]="design.selectedLoadCombinationId"
            [decisiveLoadCombinationIndex]="design.designData.reportData.DecisiveLoadCombinationIndex"
            [decisiveLoadCombinationAnchorPlateIndex]="design.designData.reportData.DecisiveLoadCombinationIndexAnchorPlate"
            [decisiveLoadCombinationBaseMaterialIndex]="design.designData.reportData.DecisiveLoadCombinationIndexBaseMaterial"
            [decisiveLoadCombinationProfileIndex]="design.designData.reportData.DecisiveLoadCombinationIndexProfile"
            [decisiveLoadCombinationHandrailIndex]="design.designData.reportData.DecisiveLoadCombinationIndexHandrail"
            [decisiveLoadWeldIndex]="design.designData.reportData.DecisiveLoadCombinationIndexWelds"
            [decisiveLoadStiffenersIndex]="design.designData.reportData.DecisiveLoadCombinationIndexStiffeners"
            [disabled]="design.properties.get(PropertyMetaData.Loads_SelectedLoadCombination.id).disabled"
            [showPostAndRailDeflection]="design.showPostAndRailDeflection"
            [parentElement]="mainContentCenterRightRef"
            [isUtilizationVisible] = "isUtilizationInLoadsVisible">
        </pe-loads>

        <!-- CUSTOM STIFFENERS -->
        <pe-custom-stiffeners [resize3d]="resize3d" *ngIf="showCustomStiffenersControl"></pe-custom-stiffeners>
    </div>

    <div class="main-content-right-sidebar" *ngIf="!hideRightMenu">
        <!-- RIGHT -->
        <div class="main-content-right" *ngIf="rightSideLoaded">
            <div class="main-content-right-container">
                <div class="main-content-right-scroll">
                    <div class="design-section-wrapper">
                        <pe-design-section #designSectionRef class="design-section"
                            [exportReportSupportMethods]="exportReportSupportMethodsPe">
                        </pe-design-section>
                    </div>

                    <div  class="asad-debug-button" *ngIf="isAsadDebugEnabled && isConcreteDesign">
                        <pe-notification [notification]="asadDebugNotification"></pe-notification>
                    </div>

                    <div class="main-content-right-util" #mainContentRightUtilRef
                        sortablejs [sortablejsOptions]="sortableMenu3DRightOptions"
                        (sortablejsInit)="sortMenu3DRight($any($event))">

                        <div *ngIf="isAsad && isAsadVisible; else commonComponents">
                            <div *ngIf="!isAnchorAISolutionSelected">
                                <div class="smart-design-steps" [innerHtml]="translate('Agito.Hilti.Profis3.SmartDesign.Steps')">

                                </div>
                            </div>
                            <div *ngIf="isAnchorAISolutionSelected then commonComponents"></div>
                            <div *ngIf="alertScopeChecks.length > 0 || notificationComponentInputs.isInfoMessageVisible()" [attr.data-id]="CollapsingControls.NotificationsDesign">
                                <pe-notifications [isVisible]="notificationComponentInputs.isVisible()"
                                    [inputs]="notificationComponentInputs">
                                </pe-notifications>
                            </div>
                        </div>

                        <ng-template #commonComponents>
                            <div [attr.data-id]="CollapsingControls.AnchorLoads">
                                <pe-anchor-loads [id]="'anchor-loads'" [design]="design"
                                    [parentElement]="mainContentRightUtilRef">
                                </pe-anchor-loads>
                            </div>

                            <div [attr.data-id]="CollapsingControls.Utilization">
                                <pe-utilizations [id]="'utilization'" [tension]="design.designData.reportData.Tension"
                                    [shear]="design.designData.reportData.Shear"
                                    [combination]="design.designData.reportData.Combination"
                                    [isMasonry]="design.designType.id == DesignTypeId.Masonry" [design]="design">
                                </pe-utilizations>
                            </div>

                            <div [attr.data-id]="CollapsingControls.BaseplateUtilization">
                                <pe-baseplate-utilizations [id]="'baseplateutilization'" [design]="design"
                                    [baseplateDesignData]="design.baseplateDesignData">
                                </pe-baseplate-utilizations>
                            </div>

                            <div [attr.data-id]="CollapsingControls.WeldsUtilization">
                                <pe-welds-utilizations
                                    [id]="'weldsutilization'"
                                    [design]="design"
                                    [weldsUtilizations]="design.baseplateDesignData?.WeldsUtilizations">
                                </pe-welds-utilizations>
                            </div>

                            <div [attr.data-id]="CollapsingControls.StiffenersUtilization">
                                <pe-stiffeners-utilizations
                                    [id]="'stiffenersutilization'"
                                    [design]="design"
                                    [stiffenersUtilizations]="design.baseplateDesignData?.StiffenersUtilizations">
                                </pe-stiffeners-utilizations>
                            </div>

                            <div [attr.data-id]="CollapsingControls.ConcreteUtilization">
                                <pe-concrete-utilizations
                                    [id]="'concreteutilization'"
                                    [design]="design"
                                    [utilizationLoadBearing]="design.baseplateDesignData?.ConcreteUtilizations?.UtilizationLoadBearing">
                                </pe-concrete-utilizations>
                            </div>

                            <div [attr.data-id]="CollapsingControls.ProfileUtilization">
                                <pe-profile-utilizations
                                    [id]="'profileutilization'"
                                    [design]="design"
                                    [profileUtilizations]="design.baseplateDesignData?.ProfileUtilizations">
                                </pe-profile-utilizations>
                            </div>

                            <div [attr.data-id]="CollapsingControls.HandrailDesign">
                                <pe-handrail-design
                                    id="handraildesign"
                                    [design]="design"
                                    [rail]="design.designData.reportData.HRStructuralSafetyRail"
                                    [post]="design.designData.reportData.HRStructuralSafetyPost"
                                    [deflection]="design.designData.reportData.HRHorizontalDeflection">
                                </pe-handrail-design>
                            </div>

                            <div [attr.data-id]="CollapsingControls.AnchorPlateDesign">
                                <pe-anchor-plate-design id="anchorplatedesign"
                                    [moment]="design.designData.reportData.AnchorPlateMoment"
                                    [design]="design">
                                </pe-anchor-plate-design>
                            </div>
                        </ng-template>

                        <div *ngIf="!isAsadVisible" [attr.data-id]="CollapsingControls.NotificationsDesign">
                            <pe-notifications
                                [isVisible]="notificationComponentInputs.isVisible()"
                                [inputs]="notificationComponentInputs">
                            </pe-notifications>
                        </div>

                        <div [attr.data-id]="CollapsingControls.ValidationError">
                            <pe-design-validation-error [validationError]="design.validationError"
                                [menu]="selectedMenu">
                            </pe-design-validation-error>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- modals -->
<pe-loading-custom
    [loadingText]="modalService.loadingCustomLoadingText"
    [shown]="modalService.loadingCustomShown"
    [cancelFn]="modalService.loadingCustomOnCancel">
</pe-loading-custom>
