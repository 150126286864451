import { Injectable } from '@angular/core';
import { Control, Menu } from './static-menu.definitions';
import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { strengthCreateMenu } from './static-menu-strength/static-menu.strength';
import { StrengthMenu } from './static-menu-strength/static-menu.strength.definitions';
import { PunchMenu } from './static-menu-punch/static-menu.punch.definitions';
import { punchCreateMenu } from './static-menu-punch/static-menu.punch';

@Injectable({
    providedIn: 'root'
})
export class StaticMenuService {
    private strengthMenu?: StrengthMenu;
    private punchMenu?: PunchMenu;

    public getStrengthMenu() {
        if (this.strengthMenu == null) {
            this.strengthMenu = strengthCreateMenu();
            this.fillControlProperties(this.strengthMenu);
        }

        return this.strengthMenu;
    }

    public getPunchMenu() {
        if (this.punchMenu == null) {
            this.punchMenu = punchCreateMenu();
            this.fillControlProperties(this.punchMenu);
        }

        return this.punchMenu;
    }

    // TODO JANJ: remove this function and set the Size and TooltipType on all controls that need it
    private fillControlProperties(menu: Menu): void {
        const allControls = menu.Tabs.flatMap(tab => tab.TabRegions).flatMap(region => region.Controls);
        allControls.forEach((control) => {
            const unknownControl = control as { Size?: number; TooltipType?: TooltipType } & Control;

            unknownControl.Size = unknownControl.Size ?? 100;
            unknownControl.TooltipType = unknownControl.TooltipType ?? TooltipType.Normal;
        });
    }
}
