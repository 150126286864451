import { CodeList } from './code-list';
import { BarTypeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class BarType extends CodeList {

    public static fromService(codeList: BarTypeEntityC2C) {
        return new BarType(CodeList.baseFromService('BarTypeEntityC2C', codeList));
    }

    public override cloneDeep(): BarType {
        return super.cloneDeep() as BarType;
    }
}
