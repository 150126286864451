import { CodeList } from './code-list';
import { EdgeDistanceModeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class EdgeDistanceMode extends CodeList {

    public static fromService(codeList: EdgeDistanceModeEntityC2C) {
        return new EdgeDistanceMode(CodeList.baseFromService('EdgeDistanceModeEntityC2C', codeList, {
            id: codeList.id,
            tooltipDisplayKey: codeList.tooltipTitleDisplayKey,
            tooltipDisabledDisplayKey: codeList.tooltipDisabledDisplayKey,
            tooltipTitleDisplayKey: codeList.tooltipTitleDisplayKey
        }));
    }

    public override cloneDeep(): EdgeDistanceMode {
        return super.cloneDeep() as EdgeDistanceMode;
    }
}
