import '@babylonjs/core/Culling/ray.js';
import sortBy from 'lodash-es/sortBy.js';
import { Matrix, Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData.js';
export const textureExtensions = {
  isDisposed: texture => {
    return texture._engine == null;
  }
};
// vector 2
function numberToString(value, decimals) {
  if (decimals == null) {
    decimals = 0;
  }
  let stringValue = value.toString();
  if (Math.abs(value) < 1.0) {
    const e = parseInt(stringValue.split('e-')[1]);
    if (e) {
      value *= Math.pow(10, e - 1);
      const decimalsString = value.toString().substring(2);
      stringValue = (value < 0 ? '-0.' : '0.') + new Array(e).join('0') + decimalsString;
    }
  } else {
    let e = parseInt(stringValue.split('+')[1]);
    if (e > 20) {
      e -= 20;
      value /= Math.pow(10, e);
      stringValue = value + new Array(e + 1).join('0');
    }
  }
  // removes dot if there are no decimals
  stringValue = stringValue.replace(new RegExp('(\\d+)(\\.)$'), '$1');
  return stringValue;
}
function round(value, decimals) {
  if (decimals == null) {
    decimals = 0;
  }
  // infinity
  if (value == Number.POSITIVE_INFINITY) {
    return Number.POSITIVE_INFINITY;
  }
  if (value == Number.NEGATIVE_INFINITY) {
    return Number.NEGATIVE_INFINITY;
  }
  if (value >= 0) {
    return +(numberToString(Math.round(+(numberToString(value) + 'e+' + decimals))) + 'e-' + decimals);
  } else {
    return +('-' + numberToString(Math.round(+(numberToString(Math.abs(value)) + 'e+' + decimals))) + 'e-' + decimals);
  }
}
export const vector2Extensions = {
  round: (vector2, decimals) => {
    return new Vector2(round(vector2.x, decimals), round(vector2.y, decimals));
  },
  roundToRef: (vector2, decimals, vector) => {
    vector.x = round(vector2.x, decimals);
    vector.y = round(vector2.y, decimals);
  },
  equals: (vectorOne, vectorTwo) => {
    if (vectorOne === vectorTwo) {
      return true;
    }
    if (vectorOne == null || vectorTwo == null) {
      return false;
    }
    return vectorOne.equals(vectorTwo);
  },
  clone: vector => {
    return vector?.clone();
  }
};
export const vector3Extensions = {
  projectToRef: (vector, world, transform, viewport, result) => {
    const cw = viewport.width;
    const ch = viewport.height;
    const cx = viewport.x;
    const cy = viewport.y;
    const viewportMatrix = Vector3._viewportMatrixCache ? Vector3._viewportMatrixCache : Vector3._viewportMatrixCache = new Matrix();
    Matrix.FromValuesToRef(cw / 2.0, 0, 0, 0, 0, -ch / 2.0, 0, 0, 0, 0, 1, 0, cx + cw / 2.0, ch / 2.0 + cy, 0, 1, viewportMatrix);
    const matrix = Vector3._matrixCache ? Vector3._matrixCache : Vector3._matrixCache = new Matrix();
    world.multiplyToRef(transform, matrix);
    matrix.multiplyToRef(viewportMatrix, matrix);
    Vector3.TransformCoordinatesToRef(vector, matrix, result);
  },
  equals: (vectorOne, vectorTwo) => {
    if (vectorOne === vectorTwo) {
      return true;
    }
    if (vectorOne == null || vectorTwo == null) {
      return false;
    }
    return vectorOne.equals(vectorTwo);
  }
};
export const matrixExtensions = {
  equals: (matrixOne, matrixTwo) => {
    if (matrixOne === matrixTwo) {
      return true;
    }
    if (matrixOne == null || matrixTwo == null) {
      return false;
    }
    return matrixOne.equals(matrixTwo);
  }
};
export const sceneExtensions = {
  pickAll: (scene, absoluteX, absoluteY) => {
    const canvas = scene.getEngine().getRenderingCanvas();
    const canvasBoundingClientRect = canvas.getBoundingClientRect();
    const x = absoluteX - (canvasBoundingClientRect.left + window.scrollX);
    const y = absoluteY - (canvasBoundingClientRect.top + window.scrollY);
    const ray = scene.createPickingRay(x, y, null, null);
    const pickingInfos = scene.multiPickWithRay(ray) ?? [];
    return sortBy(pickingInfos, pickingInfo => pickingInfo.distance);
  }
};
// CSG
/* eslint-disable */
export const csgExtensions = {
  buildVertexData: csg => {
    const matrix = csg.matrix.clone();
    matrix.invert();
    const vertices = [];
    const indices = [];
    const normals = [];
    const uvs = [];
    const vertex = Vector3.Zero();
    const normal = Vector3.Zero();
    const uv = Vector2.Zero();
    const polygons = csg['_polygons'];
    const polygonIndices = [0, 0, 0];
    let polygon;
    const vertice_dict = {};
    let vertex_idx;
    let currentIndex = 0;
    const subMesh_dict = {};
    let subMesh_obj;
    for (let i = 0, il = polygons.length; i < il; i++) {
      polygon = polygons[i];
      // Building SubMeshes
      if (!subMesh_dict[polygon.shared.meshId]) {
        subMesh_dict[polygon.shared.meshId] = {};
      }
      if (!subMesh_dict[polygon.shared.meshId][polygon.shared.subMeshId]) {
        subMesh_dict[polygon.shared.meshId][polygon.shared.subMeshId] = {
          indexStart: +Infinity,
          indexEnd: -Infinity,
          materialIndex: polygon.shared.materialIndex
        };
      }
      subMesh_obj = subMesh_dict[polygon.shared.meshId][polygon.shared.subMeshId];
      for (let j = 2, jl = polygon.vertices.length; j < jl; j++) {
        polygonIndices[0] = 0;
        polygonIndices[1] = j - 1;
        polygonIndices[2] = j;
        for (let k = 0; k < 3; k++) {
          vertex.copyFrom(polygon.vertices[polygonIndices[k]].pos);
          normal.copyFrom(polygon.vertices[polygonIndices[k]].normal);
          uv.copyFrom(polygon.vertices[polygonIndices[k]].uv);
          const localVertex = Vector3.TransformCoordinates(vertex, matrix);
          const localNormal = Vector3.TransformNormal(normal, matrix);
          vertex_idx = vertice_dict[localVertex.x + ',' + localVertex.y + ',' + localVertex.z];
          // Check if 2 points can be merged
          if (!(typeof vertex_idx !== 'undefined' && normals[vertex_idx * 3] === localNormal.x && normals[vertex_idx * 3 + 1] === localNormal.y && normals[vertex_idx * 3 + 2] === localNormal.z && uvs[vertex_idx * 2] === uv.x && uvs[vertex_idx * 2 + 1] === uv.y)) {
            vertices.push(localVertex.x, localVertex.y, localVertex.z);
            uvs.push(uv.x, uv.y);
            normals.push(normal.x, normal.y, normal.z);
            vertex_idx = vertice_dict[localVertex.x + ',' + localVertex.y + ',' + localVertex.z] = vertices.length / 3 - 1;
          }
          indices.push(vertex_idx);
          subMesh_obj.indexStart = Math.min(currentIndex, subMesh_obj.indexStart);
          subMesh_obj.indexEnd = Math.max(currentIndex, subMesh_obj.indexEnd);
          currentIndex++;
        }
      }
    }
    const vertexData = new VertexData();
    vertexData.positions = vertices;
    vertexData.normals = normals;
    vertexData.uvs = uvs;
    vertexData.indices = indices;
    return vertexData;
  }
};
/* eslint-enable */
export const meshExtensions = {
  setEnabledWithChildMeshes: (mesh, value, directDecendantsOnly) => {
    mesh.setEnabled(value);
    const children = mesh.getChildMeshes(directDecendantsOnly);
    for (const child of children) {
      child.setEnabled(value);
    }
  },
  getBoundingInfosWithChildMeshes: (mesh, directDecendantsOnly) => {
    const boundingInfos = [];
    boundingInfos.push(mesh.getBoundingInfo());
    const children = mesh.getChildMeshes(directDecendantsOnly);
    for (const child of children) {
      boundingInfos.push(child.getBoundingInfo());
    }
    return boundingInfos;
  },
  createInstanceWithChildMeshes: (mesh, name, directDecendantsOnly) => {
    const instance = mesh.createInstance(name);
    const children = mesh.getChildMeshes(directDecendantsOnly);
    for (const child of children) {
      const childInstance = child.createInstance(name);
      childInstance.parent = instance;
    }
    return instance;
  }
};
