import { CacheItem } from './cache-item.js';
export class MeshCache extends CacheItem {
  sceneCache;
  materialCache;
  vertexDataCache;
  constructor(materialCache, sceneCache, vertexDataCache) {
    super();
    this.sceneCache = sceneCache;
    this.materialCache = materialCache;
    this.vertexDataCache = vertexDataCache;
  }
  dispose() {
    super.dispose();
  }
}
