import { Engine } from '@babylonjs/core/Engines/engine.js';
import { Cache } from './cache/cache.js';
export class CanvasContext3d {
  materialCacheType;
  meshCacheType;
  parentContainer;
  cache;
  canvas;
  engine;
  key;
  element;
  events = {};
  constructor(key, materialCacheType, meshCacheType, parentContainer) {
    this.materialCacheType = materialCacheType;
    this.meshCacheType = meshCacheType;
    this.key = key;
    this.parentContainer = parentContainer;
    this.canvas = document.createElement('canvas');
    this.canvas.className = 'context-3d';
    this.canvas.hidden = true;
    this.canvas.id = key;
    this.canvas.tabIndex = -1;
    document.body.appendChild(this.canvas);
    this.initEngine();
    this.cache = new Cache(this.engine, materialCacheType, meshCacheType);
    this.canvas.addEventListener('webglcontextlost', this.webGLContextLost.bind(this), false);
    this.canvas.addEventListener('webglcontextrestored', this.webGLContextRestored.bind(this), false);
  }
  _screenshot = false;
  set screenshot(value) {
    this._screenshot = value;
  }
  get width() {
    return this._screenshot ? this.canvas.width : this.canvas.clientWidth;
  }
  get height() {
    return this._screenshot ? this.canvas.height : this.canvas.clientHeight;
  }
  on(event, fn) {
    const events = this.events[event] = this.events[event]?.filter(x => x !== fn) ?? [];
    events.push(fn);
    return () => {
      this.events[event] = this.events[event].filter(x => x !== fn);
    };
  }
  triggerEvent(eventName) {
    for (const event of this.events[eventName] ?? []) {
      event();
    }
  }
  get isContextLost() {
    return this.engine == null || this.engine._gl.isContextLost();
  }
  lock() {
    this.canvas.remove();
    if (this.parentContainer.shadowRoot != null) {
      this.parentContainer.shadowRoot.appendChild(this.canvas);
    } else {
      this.parentContainer.appendChild(this.canvas);
    }
    this.canvas.hidden = false;
    this.element = this.parentContainer;
  }
  unlock() {
    if (this.element != null && this.engine != null) {
      this.canvas.hidden = true;
      this.engine.stopRenderLoop();
      this.canvas.remove();
      document.body.appendChild(this.canvas);
      this.element = undefined;
    }
  }
  resize(width, height) {
    this.canvas.width = width;
    this.canvas.height = height;
  }
  releaseEngine() {
    if (this.engine != null) {
      this.triggerEvent('onEngineWillRelease');
      this.cache.dispose();
      this.engine.stopRenderLoop();
      try {
        this.engine.dispose();
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catch (error) {
        // no need to log
      } finally {
        this.engine = undefined;
      }
    }
  }
  restoreEngine() {
    this.releaseEngine();
    this.initEngine();
    this.cache.engineCache.default = this.engine;
    this.triggerEvent('onEngineWasRestored');
  }
  initEngine() {
    this.engine = new Engine(this.canvas, true, {
      antialias: true,
      preserveDrawingBuffer: true,
      doNotHandleContextLost: true,
      doNotHandleTouchAction: true
    });
    console.debug('WebGL init engine: ' + this.key);
  }
  webGLContextLost(e) {
    console.warn('WebGL context was lost: ' + this.key);
    e.preventDefault();
    this.releaseEngine();
  }
  webGLContextRestored() {
    console.warn('WebGL context was restored: ' + this.key);
    this.restoreEngine();
  }
}
