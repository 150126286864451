import {
    EmbedmentDepthModeEntityC2C
} from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class EmbedmentDepthMode extends CodeList {

    public static fromService(codeList: EmbedmentDepthModeEntityC2C) {
        return new EmbedmentDepthMode(CodeList.baseFromService('EmbedmentDepthModeEntity', codeList, {
            id: codeList.id,
            tooltipDisplayKey: codeList.tooltipTitleDisplayKey,
            tooltipDisabledDisplayKey: codeList.tooltipDisabledDisplayKey,
            tooltipTitleDisplayKey: codeList.tooltipTitleDisplayKey,
            nameResourceKey: undefined
        }));
    }

    public override cloneDeep(): EmbedmentDepthMode {
        return super.cloneDeep() as EmbedmentDepthMode;
    }
}
