import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VirtualScrollerComponent, VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { L10nPipe } from '../../pipes/l10n.pipe';
import { LocalizationService } from '../../services/localization.service';
import { includeSprites, productUiSprites } from '../../sprites';

export class ProductSelectionInput {
    public selectedProductId: number = undefined!;
    public products: ProductSelectionItem[] = undefined!;
    public productSelected: (productId: number) => Promise<void> = undefined!;
}

export interface ProductSelectionItem {
    id: number;
    name: string;
    image?: string;
}

@Component({
    templateUrl: './product-selection.component.html',
    styleUrls: ['./product-selection.component.scss'],
    imports: [
        L10nPipe,
        VirtualScrollerModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductSelectionComponent implements OnInit {
    @ViewChild('virtualProductsComponentRef')
    public virtualProductsComponent?: VirtualScrollerComponent;

    public dataLoaded = false;

    public scrollElement!: Element;

    public productElementRowHeight = 90; // must be the same value as in css
    public preventDefaultClose = true;

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly productSelectionInput: ProductSelectionInput,
        public readonly localizationService: LocalizationService,
        public readonly ngbActiveModal: NgbActiveModal,
    ) { }

    public ngOnInit(): void {
        this.scrollElement = document.querySelector('.modal')!;
        includeSprites(this.elementRef.nativeElement, ...productUiSprites);

        this.dataLoaded = true;
    }

    public get selectedProductId() {
        return this.productSelectionInput.selectedProductId;
    }

    public get products(): ProductSelectionItem[] {
        return this.productSelectionInput.products;
    }

    public get virtualProducts(): ProductSelectionItem[] {
        return this.virtualProductsComponent?.viewPortItems as ProductSelectionItem[] ?? [];
    }

    public async selectProduct(product: ProductSelectionItem) {
        await this.productSelectionInput.productSelected(product.id);
        this.close();
    }

    public close() {
        this.ngbActiveModal.close();
    }
}
