import { CodeList } from './code-list';
import { RebarMaterialEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class RebarMaterial extends CodeList {

    public static fromService(codeList: RebarMaterialEntityC2C) {
        return new RebarMaterial(CodeList.baseFromService('RebarMaterialEntityC2C', codeList));
    }

    public override cloneDeep(): RebarMaterial {
        return super.cloneDeep() as RebarMaterial;
    }
}
