<decking-collapsable-up [defaultExpanded]="defaultExpanded" [expanded]="expanded$ | async" class="product-selector-collapsable" (toggleClicked)="toggleClicked($event)">
    <div class="collapsable-title" style="display: flex; align-items: center;">
        <span class="collapsable-title-tex">{{currentDeckingZone.zoneSubstituted.frameFastener?.value || ('Agito.Hilti.Profis3.Decking.ProductSelector.Title' | l10n:localization.selectedLanguage)}}</span>
    </div>
    <ng-template appCollapsableContent>
        <div class="collapsable-box">
            <decking-product-selector-list
                [title]="'Agito.Hilti.Profis3.Decking.ProductSelector.MainFrameFasterners' | l10n:localization.selectedLanguage"
                [products]="frameFasteners"
                [selectedValue]="currentDeckingZone.zoneSubstituted.frameFastener"
                (valueChanged)="onChangeFrameFastener($event)">
            </decking-product-selector-list>
            <div class="collapsable-box-button">
                <pe-dropdown
                    [id]="patternDropdown.id"
                    [alignment]="'SpaceBetween'"
                    [title]="'Agito.Hilti.Profis3.Decking.ProductSelector.FramePattern' | l10n:localization.selectedLanguage"
                    [items]="patternDropdown.items"
                    [selectedValue]="patternDropdown.selectedValue"
                    class="control"
                    (selectedValueChange)="onChangeFramePattern($any($event).detail)">
                </pe-dropdown>
            </div>
            <decking-product-selector-list
                [title]="'Agito.Hilti.Profis3.Decking.ProductSelector.SidelapFastener' | l10n:localization.selectedLanguage"
                [products]="sideLapFasteners"
                [selectedValue]="currentDeckingZone.zoneSubstituted.sidelapConnector"
                (valueChanged)="onChangeSidelapConnector($event)">
            </decking-product-selector-list>
            <div class="collapsable-box-button">
                <decking-substitution-zone-side
                    [id]="'product-selecto-sidelap-spacing-dropdown'"
                    [cssClass]="'product-selector-sidelap'"
                    [currentZone]="currentDeckingZone.zoneSubstituted"
                    [title]="'Agito.Hilti.Profis3.Decking.Zones.Subheader.' | substitutionSidelapConnectorTitle | l10n:localization.selectedLanguage"
                    class="control"
                    (valueChanged)="valueChanged()"
                    >
                </decking-substitution-zone-side>
            </div>


            <div class="collapsable-box-button">
                <button [disabled]="!IsViewESREnabled(this.currentDeckingZone.zoneSubstituted.frameFastener, this.currentDeckingZone.zoneSubstituted.sidelapConnector)" (click)="openExternalUrl(getApprovalDocumentUrl(this.currentDeckingZone.zoneSubstituted.frameFastener, this.currentDeckingZone.zoneSubstituted.sidelapConnector))">
                    <span>{{'Agito.Hilti.Profis3.Decking.ProductSelector.ViewESR' | l10n:localization.selectedLanguage}}</span>
                </button>
            </div>

            <div class="collapsable-box-button">
                <button [disabled]="!frameFastenerOnlinePage" (click)="trackAndOpenUrlForFrameFastener(frameFastenerOnlinePage)">
                    <span>{{'Agito.Hilti.Profis3.Decking.ProductSelector.ViewFrameFastenerInfo' | l10n:localization.selectedLanguage}}</span>
                </button>
            </div>

            <div class="collapsable-box-button">
                <button [disabled]="!sideLapFastenersOnlinePage" (click)="trackAndOpenUrlForSidelapFastener(sideLapFastenersOnlinePage)">
                    <span>{{'Agito.Hilti.Profis3.Decking.ProductSelector.ViewSidelapFastenerInfo' | l10n:localization.selectedLanguage}}</span>
                </button>
            </div>
        </div>

    </ng-template>
</decking-collapsable-up>
