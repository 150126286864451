<div class="application-loading" *ngIf="displayType == 'Loading'">
    <pe-loading [width]="192" [height]="48"></pe-loading>
</div>

<div class="general-error" *ngIf="displayType == 'GeneralError'">
    <div class="general-error-container">
        <div class="general-error-header">
            <span class="logo sprite sprite-hilti-logo"></span>
        </div>
        <div class="general-error-content">
            <p class="title-row-1" [innerHTML]="errorTitle1"></p>
            <p class="message-row" [innerHTML]="errorMessage"></p>
        </div>
        <div class="general-error-footer">
            <button id="general-error-logout-button"
                class="button button-default" (click)="logout()"
                type="button">{{logOutButtonText}}
            </button>
        </div>
    </div>
</div>

<app-maintenance *ngIf="displayType == 'MaintenancePage'"></app-maintenance>
<!--BUDQBP-37682 remove launchDarklyService.isInitialized && after cleanup -->
<ng-container *ngIf="launchDarklyService.isInitialized && (displayType == 'Loading' || displayType == 'Initialized')">
    <pe-ui-common-init
        [sharedEnvironmentData]="environment"
        [localizationService]="localizationService"
        [modalService]="modalService"
        [mathService]="mathService"
        [unitService]="unitService"
        [userSettingsService]="userSettingsService"
        [documentService]="documentService"
        [designTemplateService]="designTemplateService"
        [browserService]="browserService"
        [reportTemplateService]="reportTemplateService"
        [trimbleConnectService]="trimbleConnectService"
        [licenseService]="licenseService"
        [productInformationService]="productInformationService"
        [offlineService]="offlineService"
        [commonCodeListService]="commonCodeListService"
        [userService]="userService"
        [featureVisibilityInfoService]="featuresVisibilityInfoService"
        [featureVisibilityService]="featureVisibilityService"
        [commonTrackingService]="commonTrackingService"
        [marketingService]="marketingService"
        [authenticationService]="authenticationService"
        [apiService]="apiService"
        [routingService]="routingService"
        (preInit)="onModulePreInit($any($event).detail, 'pe-ui-common')">
    </pe-ui-common-init>

    <sp-ui-init
        [sharedEnvironmentData]="environment"
        [userSettingsServiceBase]="userSettingsService"
        [localizationServiceBase]="localizationService"
        [apiServiceBase]="apiService"
        [commonCodeListServiceBase]="commonCodeListService"
        [commonTrackingServiceBase]="commonTrackingService"
        [designTemplateServiceBase]="designTemplateService"
        [documentServiceBase]="documentService"
        [favoritesServiceBase]="favoritesService"
        [modalServiceBase]="modalService"
        [numberServiceBase]="numberService"
        [routingServiceBase]="routingService"
        [tourServiceBase]="tourService"
        [userServiceBase]="userService"
        [unitServiceBase]="unitService"
        [menuServiceBase]="menuService"
        [importServiceBase]="importService"
        [browserServiceBase]="browserService"
        [changesServiceBase]="changesService"
        [featuresVisibilityInfoServiceBase]="featuresVisibilityInfoService"
        [mathServiceBase]="mathService"
        [tooltipServiceBase]="tooltipService"
        [featureVisibilityServiceBase]="featureVisibilityService"
        [reportTemplateServiceBase]="reportTemplateService"
        [regionOrderServiceBase]="regionOrderService"
        (preInit)="onModulePreInit($any($event).detail, 'pe-ui-sp')"
        (init)="onModuleInit($any($event).detail)"
        (update)="onModuleUpdate($any($event).detail)">
    </sp-ui-init>

    <cw-ui-cw-init
        [localizationService]="localizationService"
        [numberService]="numberService"
        [apiService]="apiService"
        [commonCodeListService]="commonCodeListService"
        [unitService]="unitService"
        [modalService]="modalService"
        [userService]="userService"
        [userSettingsService]="userSettingsService"
        [loggerService]="loggerService"
        [guidService]="guidService"
        [changesService]="changesService"
        [offlineService]="offlineService"
        [featureVisibilityInfoService]="featuresVisibilityInfoService"
        [featureVisibilityService]="featureVisibilityService"
        [routingService]="routingService"
        [authenticationService]="authenticationService"
        [browserService]="browserService"
        [trimbleConnectService]="trimbleConnectService"
        [tooltipService]="tooltipService"
        [mathService]="mathService"
        [dateTimeService]="dateTimeService"
        [importService]="importService"
        [documentService]="documentService"
        [menuService]="menuService"
        [menu2dService]="menu2dService"
        [commonTrackingService]="commonTrackingService"
        [favoritesService]="favoritesService"
        [regionOrderService]="regionOrderService"
        [sharedEnvironmentData]="environment"
        [designTemplateService]="designTemplateService"
        [reportTemplateService]="reportTemplateService"
        [licenseService]="licenseService"
        (preInit)="onModulePreInit($any($event).detail, 'pe-ui-cw')"
        (init)="onModuleInit($any($event).detail)"
        (update)="onModuleUpdate($any($event).detail)">
    </cw-ui-cw-init>

    <glass-ui-init
        [sharedEnvironmentData]="environment"
        [userSettingsServiceBase]="userSettingsService"
        [localizationServiceBase]="localizationService"
        [apiServiceBase]="apiService"
        [commonCodeListServiceBase]="commonCodeListService"
        [commonTrackingServiceBase]="commonTrackingService"
        [designTemplateServiceBase]="designTemplateService"
        [documentServiceBase]="documentService"
        [favoritesServiceBase]="favoritesService"
        [modalServiceBase]="modalService"
        [numberServiceBase]="numberService"
        [routingServiceBase]="routingService"
        [tourServiceBase]="tourService"
        [userServiceBase]="userService"
        [unitServiceBase]="unitService"
        [menuServiceBase]="menuService"
        [importServiceBase]="importService"
        [browserServiceBase]="browserService"
        [changesServiceBase]="changesService"
        [featuresVisibilityInfoServiceBase]="featuresVisibilityInfoService"
        [mathServiceBase]="mathService"
        [tooltipServiceBase]="tooltipService"
        [featureVisibilityServiceBase]="featureVisibilityService"
        [reportTemplateServiceBase]="reportTemplateService"
        [regionOrderServiceBase]="regionOrderService"
        (preInit)="onModulePreInit($any($event).detail, 'pe-ui-glass')"
        (init)="onModuleInit($any($event).detail)">
    </glass-ui-init>

    <masonry-rnf-ui-init
        [sharedEnvironmentData]="environment"
        [userSettingsServiceBase]="userSettingsService"
        [localizationServiceBase]="localizationService"
        [apiServiceBase]="apiService"
        [commonCodeListServiceBase]="commonCodeListService"
        [commonTrackingServiceBase]="commonTrackingService"
        [designTemplateServiceBase]="designTemplateService"
        [documentServiceBase]="documentService"
        [favoritesServiceBase]="favoritesService"
        [modalServiceBase]="modalService"
        [numberServiceBase]="numberService"
        [routingServiceBase]="routingService"
        [tourServiceBase]="tourService"
        [userServiceBase]="userService"
        [unitServiceBase]="unitService"
        [menuServiceBase]="menuService"
        [importServiceBase]="importService"
        [browserServiceBase]="browserService"
        [changesServiceBase]="changesService"
        [featuresVisibilityInfoServiceBase]="featuresVisibilityInfoService"
        [mathServiceBase]="mathService"
        [tooltipServiceBase]="tooltipService"
        [featureVisibilityServiceBase]="featureVisibilityService"
        [reportTemplateServiceBase]="reportTemplateService"
        [regionOrderServiceBase]="regionOrderService"
        (preInit)="onModulePreInit($any($event).detail, 'pe-ui-masonry-rnf')"
        (init)="onModuleInit($any($event).detail)">
    </masonry-rnf-ui-init>

    <c2c-ui-c2c-init
        [localizationService]="localizationService"
        [mathService]="mathService"
        [tooltipService]="tooltipService"
        [numberService]="numberService"
        [commonCodeListService]="commonCodeListService"
        [codeListService]="codeListServiceC2C"
        [modalService]="modalService"
        [calculationService]="calculationServiceC2C"
        [authenticationService]="authenticationService"
        [signalRService]="signalRService"
        [unitService]="unitService"
        [userService]="userService"
        [documentService]="documentService"
        [designTemplateService]="designTemplateService"
        [userSettingsService]="userSettingsService"
        [offlineService]="offlineService"
        [guidService]="guidService"
        [featureVisibilityInfoService]="featuresVisibilityInfoService"
        [featureVisibilityService]="featureVisibilityService"
        [licenseService]="licenseService"
        [routingService]="routingService"
        [tourService]="tourService"
        [queryService]="queryService"
        [importService]="importService"
        [apiService]="apiService"
        [browserService]="browserService"
        [trimbleConnectService]="trimbleConnectService"
        [dateTimeService]="dateTimeService"
        [loggerService]="loggerService"
        [reportTemplateService]="reportTemplateService"
        [reportService]="reportService"
        [menuService]="menuService"
        [menu2dService]="menu2dService"
        [changesService]="changesService"
        [favoritesService]="favoritesService"
        [regionOrderService]="regionOrderService"
        [commonTrackingService]="commonTrackingService"
        [sharedEnvironmentData]="environment"
        (preInit)="onModulePreInit($any($event).detail, 'pe-ui-c2c')"
        (init)="onModuleInit($any($event).detail)"
        (update)="onModuleUpdate($any($event).detail)">
    </c2c-ui-c2c-init>

    <!-- remove *ngIf condition C2C-10693 -->
    <pe-ui-pe-init *ngIf="peSupportsLoadingInitialData"
        [localizationService]="localizationService"
        [mathService]="mathService"
        [tooltipService]="tooltipService"
        [numberService]="numberService"
        [commonCodeListService]="commonCodeListService"
        [codeListService]="codeListService"
        [modalService]="modalService"
        [unitService]="unitService"
        [browserService]="browserService"
        [apiService]="apiService"
        [licenseService]="licenseService"
        [signalRService]="signalRService"
        [userService]="userService"
        [documentService]="documentService"
        [designTemplateService]="designTemplateService"
        [calculationService]="calculationService"
        [integrationsDataService]="integrationsDataService"
        [initialDataService]="initialDataService"
        [featureVisibilityInfoService]="featuresVisibilityInfoService"
        [userSettingsService]="userSettingsService"
        [translationFormatService]="translationFormatService"
        [offlineService]="offlineService"
        [guidService]="guidService"
        [routingService]="routingService"
        [tourService]="tourService"
        [queryService]="queryService"
        [importService]="importService"
        [sharedEnvironmentData]="environment"
        [trimbleConnectService]="trimbleConnectService"
        [dateTimeService]="dateTimeService"
        [loggerService]="loggerService"
        [reportTemplateService]="reportTemplateService"
        [menuService]="menuService"
        [menu2dService]="menu2dService"
        [authenticationService]="authenticationService"
        [changesService]="changesService"
        [favoritesService]="favoritesService"
        [featureVisibilityService]="featureVisibilityService"
        [integrationsDocumentService]="integrationsDocumentService"
        [integrationsNotificationService]="integrationsNotificationService"
        [productInformationService]="productInformationService"
        [regionOrderService]="regionOrderService"
        [commonTrackingService]="commonTrackingService"
        [designSettingsService]="designSettingsService"
        [sessionStorageService]="sessionStorageService"
        (preInit)="onModulePreInit($any($event).detail, 'pe-ui-pe')"
        (init)="onModuleInit($any($event).detail)">
    </pe-ui-pe-init>
</ng-container>

<!--BUDQBP-37682 remove launchDarklyService.isInitialized && after cleanup -->
<ng-container *ngIf="launchDarklyService.isInitialized && displayType == 'Initialized'">
    <!-- Only for backwards compatibility, removed with task: C2C-10693 -->
    <pe-ui-pe-init *ngIf="!peSupportsLoadingInitialData"
        [localizationService]="localizationService"
        [mathService]="mathService"
        [tooltipService]="tooltipService"
        [numberService]="numberService"
        [commonCodeListService]="commonCodeListService"
        [codeListService]="codeListService"
        [modalService]="modalService"
        [unitService]="unitService"
        [browserService]="browserService"
        [apiService]="apiService"
        [licenseService]="licenseService"
        [signalRService]="signalRService"
        [userService]="userService"
        [documentService]="documentService"
        [designTemplateService]="designTemplateService"
        [calculationService]="calculationService"
        [integrationsDataService]="integrationsDataService"
        [initialDataService]="initialDataService"
        [featureVisibilityInfoService]="featuresVisibilityInfoService"
        [userSettingsService]="userSettingsService"
        [translationFormatService]="translationFormatService"
        [offlineService]="offlineService"
        [guidService]="guidService"
        [routingService]="routingService"
        [tourService]="tourService"
        [queryService]="queryService"
        [importService]="importService"
        [sharedEnvironmentData]="environment"
        [trimbleConnectService]="trimbleConnectService"
        [dateTimeService]="dateTimeService"
        [loggerService]="loggerService"
        [reportTemplateService]="reportTemplateService"
        [menuService]="menuService"
        [menu2dService]="menu2dService"
        [authenticationService]="authenticationService"
        [changesService]="changesService"
        [favoritesService]="favoritesService"
        [featureVisibilityService]="featureVisibilityService"
        [integrationsDocumentService]="integrationsDocumentService"
        [integrationsNotificationService]="integrationsNotificationService"
        [productInformationService]="productInformationService"
        [regionOrderService]="regionOrderService"
        [commonTrackingService]="commonTrackingService"
        [designSettingsService]="designSettingsService"
        [sessionStorageService]="sessionStorageService"
        (preInit)="onModulePreInit($any($event).detail, 'pe-ui-pe')"
        (init)="onModuleInit($any($event).detail)">
    </pe-ui-pe-init>

    <decking-ui-decking-init
        [sharedEnvironmentData]="environment"
        [localizationService]="localizationService"
        [designTemplateService]="designTemplateService"
        [mathService]="mathService"
        [apiService]="apiService"
        [userService]="userService"
        [unitService]="unitService"
        [loggerService]="loggerService"
        [reportTemplateService]="reportTemplateService"
        [commonCodeListService]="commonCodeListService"
        [documentService]="documentService"
        [commonUserSettingsService]="userSettingsService"
        [browserService]="browserService"
        [modalService]="modalService"
        [importService]="importService"
        [featuresVisibilityInfoService]="featuresVisibilityInfoService"
        [featureVisibilityService]="featureVisibilityService"
        [routingService]="routingService"
        [licenseService]="licenseService"
        [peCodeListService]="codeListService"
        (preInit)="onModulePreInit($any($event).detail, 'pe-ui-decking')"
        (init)="onModuleInit($any($event).detail)">
    </decking-ui-decking-init>
</ng-container>

<router-outlet></router-outlet>
