import { BaseAreaModel } from '../../entities/decking-design/area-model';
import { BaseUsageCounter, TrackingData } from '../../entities/decking-design/decking-tracking-model';
import { DesignType } from '../../entities/tracking/track-on-design-open-request';
import { UserDetails } from '../../entities/tracking/user-datails';
import { BrowserService } from '../external/browser.service';
import { CommonUserSettingsService } from '../external/common-user-settings.service';
import { LicenseService } from '../external/license.service';
import { UserService } from '../external/user.service';

export abstract class BaseDeckingTrackingService {
    protected diaphragmDesignType = DesignType.DiaphragmDesign;
    protected internalTrackingData: Partial<TrackingData>;
    protected usageCounter: BaseUsageCounter;

    constructor(
        protected userSettingsService: CommonUserSettingsService,
        protected userService: UserService,
        protected licenseService: LicenseService,
        protected browserService: BrowserService
    ) {}

    getUserDetails(timezoneOffset: number): UserDetails {
        // uai
        const uai = this.browserService.getBrowserData();

        return {
            browserType: uai.browser,
            operatingSystem: uai.os,
            timezoneOffset,
            ipAddress: '',
            userName: '', // will be set on server side
            userId: '00000000000000000000000000000000', // will be set on server side
            diagnosticsAgreement:
                this.userSettingsService.settings.application.general
                    .userPrivacy.value,
            customerId: this.userService.authentication.customerId,
            customerOriginId:
                this.userService.authentication.subscription_info
                    .AuthorizationEntryList[0].CustomerOriginID,
            salesOrg: '', // will be set on server side
            customerType: '', // will be set on server side
            license: this.userService.authentication.license,
            countryOfResidence:
                this.userService.authentication.countryOfResidence,
            customerCountry: '',
            buyingCustomerOriginId:
                this.userService.authentication.subscription_info
                    .AuthorizationEntryList[0].CustomerOriginID, // this gets overridden in pe-core if license.customer_id is available
            buyingSalesOrg: '',
            buyingCustomerType: '',
            isTrial: this.licenseService.isTrial(), // will be set on server side
        };
    }

    protected abstract countZones(areas: Array<BaseAreaModel>): number;

    addArea(areasToAdd = 1): void {
        this.usageCounter.totalAreas += areasToAdd;
    }

    removeArea(areasToRemove = 1): void {
        this.usageCounter.totalAreas -= areasToRemove;
    }

    addZone(zonesToAdd= 1): void {
        this.usageCounter.totalZones += zonesToAdd;
    }

    removeZone(zonesToRemove = 1): void {
        this.usageCounter.totalZones -= zonesToRemove;
    }

    addReportCreated(reportsToAdd = 1): void {
        this.usageCounter.reportCreated += reportsToAdd;
    }

    addReportPreviewed(reportsPreviewToAdd = 1): void {
        this.usageCounter.reportPreviewed += reportsPreviewToAdd;
    }

    addDesignExported(toAdd = 1): void {
        this.usageCounter.designExported += toAdd;
    }

    setFastenerEstimationUsed(isUsed: boolean): void {
        this.internalTrackingData.fastenerEstimationUsed = isUsed;
    }
}
