import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewEncapsulation } from '@angular/core';
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';
import { L10nDirective } from '../../../../directives/l10n.directive';

@Component({
    templateUrl: './info-dialog-reinforcement-effectiveness.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [L10nDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class InfoDialogReinforcementEffectivenessComponent extends InfoDialogBaseComponent {
    public getTitle(): string {
        return this.translate('SP.Loads.ReinforcementEffectiveness.InfoPopUp.Title');
    }
}
