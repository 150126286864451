@if (this.zoneUtilizationsAvailable) {
    <div id="zone-utilizations" class="utilizations box-section box-section-white">
        @for (zone of zones; track $index) {
            <div [id]="getComponentId(zone, 'utilization')">

                <!-- Tabs -->

                <!-- Primary utilization tab -->
                <div class="box-section-header box-section-header-with-img" [class]="typeClass(TabType.Primary)">

                    <div class="box-section-title drag-handle-static utilization-title-wrapper" [ngbTooltip]="getTooltipText()">

                        <!-- Rebar image -->
                        <div class="image" [id]="getComponentId(zone, 'utilization-image')">
                            <div class="pe-ui-sp-sprite pe-ui-sp-sprite-tab-supplementary-reinforcement"></div>
                        </div>

                        <!-- Zone bar title -->
                        <span class="bar-title" [id]="getComponentId(zone, 'utilization-header')">
                            {{ getHeaderTitle(zone) }}

                            @if (showNewLabel) {
                                <span class="new-label" l10n="Common.Tags.New"></span>
                            }
                        </span>

                        @if (!getZoneCollapseState(zone)) {
                            <span class="bar-subtitle"></span>
                        }
                    </div>

                    @if (getZoneCollapseState(zone)) {
                        <pe-utilization-bar
                            [id]="getComponentId(zone, 'utilization-bar')"
                            [collapsed]="getZoneCollapseState(zone)"
                            [utilizationInvalid]="utilizationsCalculated[zone]?.maxUtilizationInvalid"
                            [utilizationValue]="utilizationsCalculated[zone]?.maxUtilizationZone">
                        </pe-utilization-bar>
                    }

                    <!-- Collapse part -->
                    <pe-collapse
                        [id]="getZoneCollapsingControl(zone)"
                        [collapsed]="getZoneCollapseState(zone)"
                        (collapsedChange)="onCollapsedChanged($any($event).detail, zone, utilizationsCalculated[zone]!)">
                    </pe-collapse>

                </div>

                <!-- Secondary utilization tabs -> Existing Member && Strengthened Member -->
                @if (!zonesCollapsing[zone]!.header) {
                    @for (section of utilizationsCalculated[zone]!.sections; track section.id; let si = $index) {
                        @if (checkDefineStrengtheningElement(zone, section.section)) {
                            <div [id]="getComponentId(zone, 'secondary-type', si)"
                                class="box-section-header box-section-header-with-img"
                                [class]="typeClass(TabType.Secondary)">

                                <div class="box-section-title drag-handle-static utilization-title-wrapper" [ngbTooltip]="null">

                                    <!-- No image -> it's secondary tab -->
                                    <div class="no-image"></div>

                                    <!-- Zone bar title -->
                                    <span class="bar-title" [id]="getComponentId(zone, 'utilization-details-header', si)">
                                        {{ translate(section.header) }}

                                        @if (showNewLabel) {
                                            <span class="new-label" l10n="Common.Tags.New"></span>
                                        }
                                    </span>

                                    @if (!getSectionCollapseState(zone, si, section.section)) {
                                        <span class="bar-subtitle"></span>
                                    }
                                </div>

                                @if (section.hasPercentage && getSectionCollapseState(zone, si, section.section)) {
                                    <pe-utilization-bar
                                        [collapsed]="getSectionCollapseState(zone, si, section.section)"
                                        [utilizationInvalid]="section.maxUtilizationInvalid"
                                        [utilizationValue]="section.maxUtilization"
                                        [id]="getComponentId(zone, 'utilization-bar', si)">
                                    </pe-utilization-bar>
                                }

                                <!-- Collapse part -->
                                <pe-collapse
                                    [id]="getCollapseComponentId(zone, 'utilization-detail-worst', si)"
                                    [collapsed]="getSectionCollapseState(zone, si, section.section)"
                                    (collapsedChange)="onSectionCollapseChanged($any($event).detail, zone, si, section.section, section)">
                                </pe-collapse>

                            </div>
                        }

                        <!-- Section items part (properties) -->
                        @if (!zonesCollapsing[zone]!.header &&
                            !zonesCollapsing[zone]!.sections[si].header &&
                            section.properties != null &&
                            section.properties.length > 0) {
                            <div class="items box-section-content">
                                @for (detailProperty of section.properties; track $index; let dgi = $index) {
                                    @if (detailProperty.isVisible) {
                                        <div [id]="getComponentId(zone, 'utilization-group-details-property', si, dgi)">
                                            <app-zone-utilization-item
                                                [title]="getPropertyTitle(detailProperty.text, detailProperty.utilizationVariable)"
                                                [utilizationVariable]="getUtilizationVariable(detailProperty.utilizationVariable)"
                                                [value]="detailProperty.value"
                                                [controlId]="getComponentId(zone, 'utilization-detail-worst', si, dgi)"
                                                [showPercentBar]="detailProperty.isPercentage!"
                                                [percentValue]="detailProperty.percentValue!"
                                                [percentageBarStyle]="detailProperty.percentBarStyle!"
                                                [numberContainerStyle]="detailProperty.numberContainerStyle!">
                                            </app-zone-utilization-item>
                                        </div>
                                    }
                                }
                            </div>
                        }
                    }
                }

            </div>
        }
    </div>
}
