import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    Design, DesignEvent, IBaseDesign, ICalculateAllDetailed, ICommonUIProperties,
    IDesignDeps as IDesignDepsBase, IDesignStateBase, IGlobalMetaPropertiesBase, IUnitProviderObject
} from '@profis-engineering/pe-ui-common/entities/design';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    CalculationServiceBase
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import {
    ChangesServiceBase as ChangesService
} from '@profis-engineering/pe-ui-common/services/changes.common';
import {
    CommonCodeList,
    CommonCodeListServiceBase as CommonCodeListService
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { DesignExternalMetaData } from '@profis-engineering/pe-ui-common/services/document.common';
import {
    GuidServiceBase as GuidService
} from '@profis-engineering/pe-ui-common/services/guid.common';
import {
    LocalizationServiceBase as LocalizationService
} from '@profis-engineering/pe-ui-common/services/localization.common';
import {
    LoggerServiceBase as LoggerService
} from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    UserServiceBase as UserService
} from '@profis-engineering/pe-ui-common/services/user.common';

import { ProjectCodeList as ProjectCodeListC2C } from '../enums/project-code-list';
import {
    DesignReportDataClientEntityC2C as IDesignReportDataClientEntityC2C, DialogsEntityC2C,
    LoadCombinationC2C, ProjectDesignEntityC2C, ScopeCheckResultItemEntityC2C, UIProperty
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ApplicationType, ConnectionType, DesignMethod, DesignMethodGroup,
    DesignStandard as DesignStandardEnumC2C, LoadCombinationsCalculationType, LoadType,
    MaximumAreaReinforcement, MinimumAreaReinforcement, OptimizationStrategy, ReportPaperSize,
    ReportType as ReportTypeC2C, SpacingMaximum, StrutTies
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import {
    FeatureFlagTypes
} from '../generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import { ReportOptionEntityC2C } from '../generated-modules/Hilti.PE.ReportService.Shared.Entities';
import { PropertyMetaDataC2C } from '../properties/properties';
import {
    CalculationServiceBaseC2C as CalculationService, ICalculationResult, isHnaBasedDesignStandard
} from '../services/calculation.common';
import { CodeListServiceBase as CodeListService } from '../services/code-list.service.base';
import { CodeList as CodeListC2C } from './code-lists/code-list';
import { DesignMethodGroup as DesignMethodGroupC2C } from './code-lists/design-method-group';
import { DesignStandard as DesignStandardC2C } from './code-lists/design-standard';
import { DesignType as DesignTypeEntity } from './code-lists/design-type';
import { FastenerFamily } from './code-lists/fastener-family';
import { FastenerSize } from './code-lists/fastener-size';
import { FastenerType } from './code-lists/fastener-type';
import { DesignCodeList } from './design-code-list';
import { getMetaDataFromDesign } from './design-external-metadata';
import { ProjectOpenType } from './tracking-data';
import { TrackingUsageCounterC2C } from './tracking-usage-counter';

export interface DesignExternalMetaDataC2C extends DesignExternalMetaData {
    calculationType: number;
    handrailConnectionType: number;
}

export interface IGlobalMetaProperties extends IGlobalMetaPropertiesBase {
    productCodeListProperty: DesignCodeList;
    sourceMetaPropertySize: number;
    sourceMetaPropertyType: number;
    sourceMetaPropertySelectedProduct?: number;
    selectProductTitleTranslationKey?: string;
}

export interface ICalculateAllRebarsDetailed extends ICalculateAllDetailed {
    embedmentDepth: number;
    utilization: number;
    isGeometryOk: boolean;
    size: number;
}

export interface ICalculateAllShearConnectorsDetailed extends ICalculateAllRebarsDetailed {
    type: string;
    typeId: number;
    sizeId: number;
}

export interface ICalculateAllAnchor {
    id: number;
    name: string;
    detailed: ICalculateAllDetailed[];
}

export interface ICodeListsC2C {
    [codeList: number]: CodeListC2C[];
}

export interface ReportDataC2C extends IDesignReportDataClientEntityC2C {
    scopeChecks: ScopeCheckResultItemEntityC2C[];
}

export interface IDesignState extends IDesignStateBase {
    projectDesignC2C: ProjectDesignEntityC2C;
    reportDataC2C: IDesignReportDataClientEntityC2C;
    fastenerFamiliesC2C: FastenerFamily[];
}


export interface IDesignC2CDeps extends IDesignDepsBase {
    codeListC2C: CodeListService;
    calculationC2C: CalculationService;
}

export function getDesignC2CDeps(
    logger: LoggerService,
    guid: GuidService,
    changes: ChangesService,
    user: UserService<unknown>,
    localization: LocalizationService,
    codeList: CodeListService,
    commonCodeList: CommonCodeListService,
    calculation: CalculationService
) {
    return {
        logger,
        guid,
        changes,
        user,
        localization,
        codeListC2C: codeList,
        calculationC2C: calculation,
        commonCodeList
    } as IDesignC2CDeps;
}

export class DesignDataC2C {
    // Input
    public projectDesignC2C!: ProjectDesignEntityC2C;
    public designCodeListsC2C!: ICodeListsC2C;

    // Results
    public reportDataC2C!: ReportDataC2C;
    public calculateAllData?: ICalculateAllAnchor[];
    public enabledFeatures: FeatureFlagTypes[] = [];
    public dialogs?: DialogsEntityC2C;
}

export class DesignC2C extends Design<DesignDataC2C, IDesignState> implements IBaseDesign, IUnitProviderObject {
    public currentState: IDesignState;

    public calculateDefer: Deferred<ICalculationResult>;
    public snapshotStateId?: string = undefined;

    public usageCounterC2C: TrackingUsageCounterC2C;
    public projectOpenType = ProjectOpenType.Blank;

    // private tracking: TrackingService;
    private readonly codeListCommon: CommonCodeListService;
    private readonly codeListC2C: CodeListService;
    private readonly calculationServiceC2C: CalculationService;

    private pendingCloseDesign = false;

    public static get commonUiProperties(): ICommonUIProperties {
        return {
            regionPropertyId: UIProperty.Option_RegionId,
            designTypePropertyId: UIProperty.ProjectDesignType,
            designStandardPropertyId: UIProperty.Option_C2C_DesignStandard,

            connectionTypePropertyId: UIProperty.General_C2C_ConnectionType,
        };
    }

    constructor(designDeps: IDesignC2CDeps) {
        super(designDeps, DesignC2C.commonUiProperties);

        this.codeListCommon = designDeps.commonCodeList;
        this.codeListC2C = designDeps.codeListC2C;
        this.calculationServiceC2C = designDeps.calculationC2C;

        this.stateChanged = this.stateChanged.bind(this);

        this.states = [];
        this.currentState = {} as IDesignState;
        this.savedStateIdx = 0;

        this.onStateChanged(this.stateChanged);

        this.calculateDefer = new Deferred<ICalculationResult>();

        this.designData = {
            designCodeListsC2C: {},
            enabledFeatures: [],
            projectDesignC2C: {} as any,
            reportDataC2C: {} as any,
            dialogs: {} as any
        };

        this.usageCounterC2C = new TrackingUsageCounterC2C(this.logger);
    }

    public get projectDesign() {
        return this.designData.projectDesignC2C;
    }

    public get metaData() {
        return getMetaDataFromDesign(this);
    }

    public get designName() {
        return this.model[PropertyMetaDataC2C.Option_DesignName.id] as string;
    }
    public set designName(value) {
        this.model[PropertyMetaDataC2C.Option_DesignName.id] = value;
    }

    public get projectName() {
        return this.model[PropertyMetaDataC2C.ProjectName.id] as string;
    }
    public set projectName(value) {
        this.model[PropertyMetaDataC2C.ProjectName.id] = value;
    }

    public get canUndo() {
        return this.states.findIndex((state) => state === this.currentState) > 0;
    }
    public get canRedo() {
        const index = this.states.findIndex((state) => state === this.currentState);

        return index >= 0 && index < this.states.length - 1;
    }

    public get currentStateIdx() {
        return this.states.findIndex((state) => state === this.currentState);
    }

    public get isStateChanged() {
        return this.savedStateIdx != this.currentStateIdx;
    }

    public get globalMetaProperties(): IGlobalMetaProperties {
        switch (this.connectionType) {
            case ConnectionType.StructuralJoints:
            case ConnectionType.Splices:
                return DesignC2C.getSplicesStructuralJointsMetaProperties();
            case ConnectionType.ConcreteOverlay:
                return DesignC2C.getConcreteOverlayMetaProperties(this.designStandardC2C?.id ?? 0);
            default:
                return {} as IGlobalMetaProperties;
        }
    }

    public get displayAnchorTheoryC2C() {
        return (this.model[PropertyMetaDataC2C.AnchorTheory_C2C_IsAnchorTheoryDisplayed.id] || this.model[PropertyMetaDataC2C.Loads_C2C_IsAnchorTheoryDisplayed.id]) as boolean;
    }
    public set displayAnchorTheoryC2C(value: boolean) {
        if (this.isC2COverlay) {
            this.displayAnchorTheoryC2COverlay = value;
        }
        else {
            this.displayAnchorTheoryC2CNonOverlay = value;
        }
    }

    public get designMethodGroup() {
        const codeList = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignMethodGroupsC2C] as DesignMethodGroupC2C[];
        return codeList.find((designMethodGroup) => designMethodGroup.id == this.model[this.designMethodGroupPropertyC2C]);
    }
    public set designMethodGroup(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_DesignMethodGroup.id] = value != null ? value.id : null;
    }

    public get isC2C() {
        // TODO: Method must exsits because design.ts is still using this method. Can delete after modularization
        return true;
    }

    public get isC2CHNA() {
        return isHnaBasedDesignStandard(this.designStandardC2C?.id);
    }

    public get isPostInstalledRebarDesign() {
        // XXX: Ico: is this even needed?
        return this.isC2C && this.connectionType == ConnectionType.Splices || this.connectionType == ConnectionType.StructuralJoints;
    }

    public get isPirEu(): boolean {
        return (this.connectionType == ConnectionType.Splices || this.connectionType == ConnectionType.StructuralJoints)
            && (this.designStandardC2C?.id == DesignStandardEnumC2C.ETAG || this.isAusEN);
    }

    public get isAusEN(): boolean {
        return (this.designStandardC2C?.id == DesignStandardEnumC2C.ASBased && this.designMethodGroup?.id == DesignMethodGroup.EN199211);
    }

    public get isPirASAS(): boolean {
        return this.isPirAus && this.designMethodGroup?.id == DesignMethodGroup.AS3600ETA;
    }

    public get isPirEuOrAus(): boolean {
        return (this.connectionType == ConnectionType.Splices || this.connectionType == ConnectionType.StructuralJoints)
            && (this.designStandardC2C?.id == DesignStandardEnumC2C.ETAG || this.designStandardC2C?.id == DesignStandardEnumC2C.ASBased);
    }

    public get isPirAus() {
        return this.isPostInstalledRebarDesign && this.designStandardC2C?.id == DesignStandardEnumC2C.ASBased;
    }

    public get isPirHna(): boolean {
        return this.isC2CHNA && this.isPostInstalledRebarDesign;
    }

    public get isC2COverlay(): boolean {
        return this.connectionType == ConnectionType.ConcreteOverlay;
    }

    public get isC2CJoints(): boolean {
        return this.connectionType == ConnectionType.StructuralJoints;
    }

    public get isHnaJointsC2C(): boolean {
        return this.isC2CHNA && this.isC2CJoints;
    }

    public get isHnaExtensionsC2C(): boolean {
        return this.isC2CHNA && this.connectionType == ConnectionType.Splices;
    }

    public get isHnaJointsAnchorTheory(): boolean {
        return this.isHnaJointsC2C && this.isHnaAnchoringDesignMethod;
    }

    public get isHnaAnchoringDesignMethod(): boolean {
        const selectedDesignMethod = this.designData.projectDesignC2C.options.selectedDesignMethod;
        return this.isFeatureEnabled(FeatureFlagTypes.HnaDesignMethods) ?
            selectedDesignMethod == DesignMethod.AnchoringBarYield || selectedDesignMethod == DesignMethod.AnchoringExternalLoads
            : this.displayAnchorTheoryC2C;
    }

    public get connectionType() {
        return this.model[PropertyMetaDataC2C.General_C2C_ConnectionType.id] as ConnectionType;
    }
    public set connectionType(value) {
        this.model[PropertyMetaDataC2C.General_C2C_ConnectionType.id] = value;
    }

    public get designStandardC2C() {
        const codeList = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignStandardsC2C] as unknown as DesignStandardC2C[];

        return codeList.find((designStandardC2C) => designStandardC2C.id == this.model[PropertyMetaDataC2C.Option_C2C_DesignStandard.id]);
    }

    public get isSurfaceTreatmentDisabled(): boolean {
        if (!this.isC2CHNA) {
            return false;
        }

        const propertyId = this.isC2COverlay ? PropertyMetaDataC2C.Overlay_C2C_SurfaceTreatment_SurfaceTreatment.id : PropertyMetaDataC2C.ExistingStructure_C2C_SurfaceTreatment_SurfaceTreatment.id;
        const property = this.properties.get(propertyId);

        return property.disabled;
    }

    public get isViewRotated(): boolean {
        if (this.isC2CHNA && this.isC2COverlay)
            return this.model[PropertyMetaDataC2C.Overlay_Rotation_C2C_Angle.id] != 0;
        else if (this.isPostInstalledRebarDesign) {
            const rotation = this.isPirHna ? this.model[PropertyMetaDataC2C.NewStructure_Rotation_C2C_Angle.id] : this.model[PropertyMetaDataC2C.Application_C2C_Connection_Angle.id];
            return rotation != 0;
        }

        return false;
    }

    public get loadsCalculationModeC2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_LoadCombinationsCalculationType.id] as LoadCombinationsCalculationType | null;
    }
    public set loadsCalculationModeC2C(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_LoadCombinationsCalculationType.id] = value != null ? value : null;
    }

    public get selectedLoadCombinationIdC2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_SelectedLoadCombinationId.id] as string;
    }
    public set selectedLoadCombinationIdC2C(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_SelectedLoadCombinationId.id] = value;
    }

    public get selectedDesignMethodC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_SelectedDesignMethod.id] as DesignMethod;
    }

    public get reportLoadCombination() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportLoadCombinationId.id] as string;
    }
    public set reportLoadCombination(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportLoadCombinationId.id] = value;
    }

    public get decisiveLoadCombinationIdC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_DecisiveLoadCombinationId.id] as string;
    }

    private get designMethodGroupPropertyC2C(): number {
        return PropertyMetaDataC2C.Option_C2C_DesignMethodGroup.id;
    }

    private set displayAnchorTheoryC2COverlay(value: boolean) {
        this.model[PropertyMetaDataC2C.AnchorTheory_C2C_IsAnchorTheoryDisplayed.id] = value;
    }

    private set displayAnchorTheoryC2CNonOverlay(value: boolean) {
        this.model[PropertyMetaDataC2C.Loads_C2C_IsAnchorTheoryDisplayed.id] = value;
    }

    public get unitLength() {
        return this.model[PropertyMetaDataC2C.Option_UnitLength.id] as Unit;
    }
    public set unitLength(value) {
        this.model[PropertyMetaDataC2C.Option_UnitLength.id] = value != null ? value : null;
    }

    get unitLengthLarge(): Unit {
        throw new Error('Method not implemented (unitLengthLarge not needed in c2c).');
    }

    public get unitArea() {
        return this.model[PropertyMetaDataC2C.Option_UnitArea.id] as Unit;
    }
    public set unitArea(value) {
        this.model[PropertyMetaDataC2C.Option_UnitArea.id] = value != null ? value : null;
    }

    public get unitStress() {
        return this.model[PropertyMetaDataC2C.Option_UnitStress.id] as Unit;
    }
    public set unitStress(value) {
        this.model[PropertyMetaDataC2C.Option_UnitStress.id] = value != null ? value : null;
    }

    public get unitStressSmall(): Unit {
        throw new Error('Method not implemented (unitStressSmall not needed in c2c).');
    }

    public get unitForce() {
        return this.model[PropertyMetaDataC2C.Option_UnitForce.id] as Unit;
    }
    public set unitForce(value) {
        this.model[PropertyMetaDataC2C.Option_UnitForce.id] = value != null ? value : null;
    }

    public get unitMoment() {
        return this.model[PropertyMetaDataC2C.Option_UnitMoment.id] as Unit;
    }
    public set unitMoment(value) {
        this.model[PropertyMetaDataC2C.Option_UnitMoment.id] = value != null ? value : null;
    }

    public get unitTemperature() {
        return this.model[PropertyMetaDataC2C.Option_UnitTemperature.id] as Unit;
    }
    public set unitTemperature(value) {
        this.model[PropertyMetaDataC2C.Option_UnitTemperature.id] = value != null ? value : null;
    }

    public get unitForcePerLength() {
        return this.model[PropertyMetaDataC2C.Option_UnitForcePerLength.id] as Unit;
    }
    public set unitForcePerLength(value) {
        this.model[PropertyMetaDataC2C.Option_UnitForcePerLength.id] = value != null ? value : null;
    }

    public get unitMomentPerLength() {
        return this.model[PropertyMetaDataC2C.Option_UnitMomentPerLength.id] as Unit;
    }
    public set unitMomentPerLength(value) {
        this.model[PropertyMetaDataC2C.Option_UnitMomentPerLength.id] = value != null ? value : null;
    }

    public get unitDensity() {
        return this.model[PropertyMetaDataC2C.Option_UnitDensity.id] as Unit;
    }
    public set unitDensity(value) {
        this.model[PropertyMetaDataC2C.Option_UnitDensity.id] = value != null ? value : null;
    }

    public get unitAreaPerLength() {
        return this.model[PropertyMetaDataC2C.Option_UnitAreaPerLength.id] as Unit;
    }
    public set unitAreaPerLength(value) {
        this.model[PropertyMetaDataC2C.Option_UnitAreaPerLength.id] = value != null ? value : null;
    }

    public get loadTypeC2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_LoadType.id] as LoadType;
    }
    public set loadTypeC2C(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_LoadType.id] = value;
    }

    public get applicationType() {
        return this.model[PropertyMetaDataC2C.General_C2C_ApplicationType.id] as ApplicationType;
    }
    public set applicationType(value) {
        // XXX: Ico: is this even needed?
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.General_C2C_ApplicationType.id] = value;
        }
    }

    public get loadsZone1C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_Zone1.id] as number;
    }

    public get loadsZone2C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_Zone2.id] as number;
    }

    public get loadsZone3C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_Zone3.id] as number;
    }

    public get loadsZone4C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_Zone4.id] as number;
    }

    public get loadsDynamicZone1C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_DynamicZone1.id] as number;
    }

    public get loadsDynamicZone2C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_DynamicZone2.id] as number;
    }

    public get loadsDynamicZone3C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_DynamicZone3.id] as number;
    }

    public get loadsDynamicZone4C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_DynamicZone4.id] as number;
    }

    public get loadCombinationsC2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_LoadCombinations.id] as LoadCombinationC2C[];
    }
    public set loadCombinationsC2C(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_LoadCombinations.id] = value;
    }

    public get reportTypeId() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportType.id] as ReportTypeC2C;
    }
    public set reportTypeId(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportType.id] = value;
    }

    public get reportTemplateId() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportTemplateId.id] as number;
    }
    public set reportTemplateId(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportTemplateId.id] = value;
    }

    public get loadsInterfaceShearC2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_InterfaceShear.id] as boolean;
    }

    public get fastenerFamilies() {
        return this.designData.designCodeListsC2C[this.globalMetaProperties.productCodeListProperty] as FastenerFamily[];
    }

    public set fastenerFamilies(value: FastenerFamily[]) {
        this.designData.designCodeListsC2C[this.globalMetaProperties.productCodeListProperty] = value;
    }

    public get rebarFamily() {
        return this.fastenerFamilies.find((item) => item.id == this.model[this.globalMetaProperties.sourceMetaProperty]);
    }

    public get productNameC2C() {
        const rebarType = formatKeyValue(this.designData.reportDataC2C?.exportReport?.rebarType ?? '', { years: this.localization.getString('Agito.Hilti.C2C.Product.Years') }) ?? this.rebarFamily?.displayName;
        const rebarSize = this.designData?.reportDataC2C?.exportReport?.rebarSize;

        return (rebarType && rebarSize && !this.isPirEuOrAus)
            ? `${rebarType} ${rebarSize}` : rebarType;
    }

    public get fastenerType() {
        const codeListC2C = this.designData.designCodeListsC2C[DesignCodeList.FastenerType] as FastenerType[];
        return codeListC2C?.find((item) => item.id == this.model[PropertyMetaDataC2C.Product_C2C_ConnectorType.id]);
    }

    public get fastenerSize() {
        const codeListC2C = this.designData.designCodeListsC2C[DesignCodeList.FastenerSize] as FastenerSize[];
        return codeListC2C?.find((item) => item.id == this.model[PropertyMetaDataC2C.Product_C2C_ConnectorSize.id]);
    }

    public get customPicturesC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_CustomPictures.id] as string[];
    }
    public set customPicturesC2C(val: string[]) {
        this.model[PropertyMetaDataC2C.Option_C2C_CustomPictures.id] = val;
    }

    public get reportNotes() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportNotes.id] as string;
    }
    public set reportNotes(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportNotes.id] = value;
    }

    public get trimbleConnectLocation() {
        return this.model[PropertyMetaDataC2C.Option_C2C_TrimbleConnectLocation.id] as string;
    }
    public set trimbleConnectLocation(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_TrimbleConnectLocation.id] = value;
    }

    public get trimbleConnectReportName() {
        return this.model[PropertyMetaDataC2C.Option_C2C_TrimbleConnectReportName.id] as string;
    }
    public set trimbleConnectReportName(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_TrimbleConnectReportName.id] = value;
    }

    public get trimbleConnectFolderId() {
        return this.model[PropertyMetaDataC2C.Option_C2C_TrimbleConnectFolderId.id] as string;
    }
    public set trimbleConnectFolderId(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_TrimbleConnectFolderId.id] = value;
    }

    public get shearDesignMethods() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_ShearDesignMethods.id] as DesignMethod;
    }
    public set shearDesignMethods(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_ShearDesignMethods.id] = value;
    }

    public get trimbleConnectUpload() {
        return this.model[PropertyMetaDataC2C.Option_C2C_TrimbleConnectUpload.id] as boolean;
    }
    public set trimbleConnectUpload(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_TrimbleConnectUpload.id] = value;
    }

    public get reportLanguageId() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportLanguageId.id] as number;
    }
    public set reportLanguageId(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportLanguageId.id] = value;
    }

    public get reportPaperSizeId() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportPaperSize.id] as ReportPaperSize;
    }
    public set reportPaperSizeId(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportPaperSize.id] = value;
    }

    public get reportFirstPage() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportFirstPage.id] as number;
    }
    public set reportFirstPage(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportFirstPage.id] = value;
    }

    public get reportCompanyName() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportCompanyName.id] as string;
    }
    public set reportCompanyName(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportCompanyName.id] = value;
    }

    public get reportAddress() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportAddress.id] as string;
    }
    public set reportAddress(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportAddress.id] = value;
    }

    public get reportContactPerson() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportContactPerson.id] as string;
    }
    public set reportContactPerson(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportContactPerson.id] = value;
    }

    public get reportPhoneNumber() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportPhoneNumber.id] as string;
    }
    public set reportPhoneNumber(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportPhoneNumber.id] = value;
    }

    public get reportEmail() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportEmail.id] as string;
    }
    public set reportEmail(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportEmail.id] = value;
    }

    public get designType() {
        const codeList = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignTypeC2C] as DesignTypeEntity[];
        return codeList.find((designType) => designType.id == this.model[PropertyMetaDataC2C.ProjectDesignType.id]);
    }

    public get reportFateningPoint() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportRebarApplication.id] as string;
    }
    public set reportFateningPoint(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportRebarApplication.id] = value;
    }

    public get noteShearLoad() {
        return this.model[PropertyMetaDataC2C.Option_C2C_NoteShearLoad.id] as string;
    }
    public set noteShearLoad(val: string) {
        this.model[PropertyMetaDataC2C.Option_C2C_NoteShearLoad.id] = val;
    }

    public get noteInstallationData() {
        return this.model[PropertyMetaDataC2C.Option_C2C_NoteInstallationData.id] as string;
    }
    public set noteInstallationData(val: string) {
        this.model[PropertyMetaDataC2C.Option_C2C_NoteInstallationData.id] = val;
    }

    public get noteTensionLoad() {
        return this.model[PropertyMetaDataC2C.Option_C2C_NoteTensionLoad.id] as string;
    }
    public set noteTensionLoad(val: string) {
        this.model[PropertyMetaDataC2C.Option_C2C_NoteTensionLoad.id] = val;
    }

    public get noteLoadCaseResultingRebarForcesC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_NoteLoadCaseResultingRebarForces.id] as string;
    }
    public set noteLoadCaseResultingRebarForcesC2C(val: string) {
        this.model[PropertyMetaDataC2C.Option_C2C_NoteLoadCaseResultingRebarForces.id] = val;
    }

    public get reportLoadCombinationC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportLoadCombinationId.id] as string;
    }
    public set reportLoadCombinationC2C(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportLoadCombinationId.id] = value;
    }

    public get partialReductionFactorSteel() {
        return this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorSteel.id] as number;
    }
    public set partialReductionFactorSteel(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorSteel.id] = value;
    }

    public get partialReductionFactorFire() {
        return this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorFire.id] as number;
    }
    public set partialReductionFactorFire(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorFire.id] = value;
    }

    public get partialReductionFactorConcrete() {
        return this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorConcrete.id] as number;
    }
    public set partialReductionFactorConcrete(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorConcrete.id] = value;
    }

    public get alphaCcC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_AlphaCC.id] as number;
    }
    public set alphaCcC2C(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_AlphaCC.id] = value;
    }

    public get alphaCtC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_AlphaCT.id] as number;
    }
    public set alphaCtC2C(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_AlphaCT.id] = value;
    }

    public get advancedRebarSettingsSteelStrainLimitC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_AdvancedRebarSettingsSteelStrainLimit.id] as number;
    }
    public set advancedRebarSettingsSteelStrainLimitC2C(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_AdvancedRebarSettingsSteelStrainLimit.id] = value;
    }

    public get k2() {
        return this.model[PropertyMetaDataC2C.Option_C2C_K2.id] as number;
    }
    public set k2(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_K2.id] = value;
    }

    public get epsilonC2() {
        return this.model[PropertyMetaDataC2C.Option_C2C_EpsilonC2.id] as number;
    }
    public set epsilonC2(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_EpsilonC2.id] = value;
    }

    public get epsilonCU2() {
        return this.model[PropertyMetaDataC2C.Option_C2C_EpsilonCU2.id] as number;
    }
    public set epsilonCU2(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_EpsilonCU2.id] = value;
    }

    public get epsilonUD() {
        return this.model[PropertyMetaDataC2C.Option_C2C_EpsilonUD.id] as number;
    }
    public set epsilonUD(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_EpsilonUD.id] = value;
    }

    public get partialSafetyFactorConcrete() {
        return this.model[PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorConcrete.id] as number;
    }
    public set partialSafetyFactorConcrete(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorConcrete.id] = value;
    }

    public get partialSafetyFactorSteel() {
        return this.model[PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorSteel.id] as number;
    }
    public set partialSafetyFactorSteel(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorSteel.id] = value;
    }

    public get minConcreteCover() {
        return this.model[PropertyMetaDataC2C.Option_C2C_MinCoverTop.id] as number;
    }
    public set minConcreteCover(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_MinCoverTop.id] = value;
    }

    public get e() {
        return this.model[PropertyMetaDataC2C.Option_C2C_E.id] as number;
    }
    public set e(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_E.id] = value;
    }

    public get existingReinforcementAlpha3() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ExistingReinforcementAlpha3.id] as number;
    }

    public set existingReinforcementAlpha3(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ExistingReinforcementAlpha3.id] = value;
    }

    public get transversePressure() {
        return this.model[PropertyMetaDataC2C.Option_C2C_TransversePressure.id] as number;
    }

    public set transversePressure(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_TransversePressure.id] = value;
    }

    public get minimumAreaReinforcement() {
        return this.model[PropertyMetaDataC2C.Option_C2C_MinimumAreaReinforcement.id] as MinimumAreaReinforcement;
    }

    public set minimumAreaReinforcement(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_MinimumAreaReinforcement.id] = value;
    }

    public get minimumAreaReinforcementInput() {
        return this.model[PropertyMetaDataC2C.Option_C2C_MinimumAreaReinforcementInput.id] as number;
    }

    public set minimumAreaReinforcementInput(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_MinimumAreaReinforcementInput.id] = value;
    }

    public get maximumAreaReinforcement() {
        return this.model[PropertyMetaDataC2C.Option_C2C_MaximumAreaReinforcement.id] as MaximumAreaReinforcement;
    }

    public set maximumAreaReinforcement(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_MaximumAreaReinforcement.id] = value;
    }

    public get maximumAreaReinforcementInput() {
        return this.model[PropertyMetaDataC2C.Option_C2C_MaximumAreaReinforcementInput.id] as number;
    }

    public set maximumAreaReinforcementInput(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_MaximumAreaReinforcementInput.id] = value;
    }

    public get spacingMaximum() {
        return this.model[PropertyMetaDataC2C.Option_C2C_SpacingMaximum.id] as SpacingMaximum;
    }

    public set spacingMaximum(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_SpacingMaximum.id] = value;
    }

    public get spacingMaximumInput() {
        return this.model[PropertyMetaDataC2C.Option_C2C_SpacingMaximumInput.id] as number;
    }

    public set spacingMaximumInput(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_SpacingMaximumInput.id] = value;
    }

    public get shearReinforcementConcreteMember() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ShearReinforcementConcreteMember.id] as boolean;
    }

    public set shearReinforcementConcreteMember(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ShearReinforcementConcreteMember.id] = value;
    }

    public get shearStrutAngle() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ShearStrutAngle.id] as number;
    }

    public set shearStrutAngle(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ShearStrutAngle.id] = value;
    }

    public get shearInclination() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ShearInclination.id] as number;
    }

    public set shearInclination(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ShearInclination.id] = value;
    }

    public get strutTies() {
        return this.model[PropertyMetaDataC2C.Option_C2C_StrutTies.id] as StrutTies;
    }

    public set strutTies(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_StrutTies.id] = value;
    }

    public get strutTiesInput() {
        return this.model[PropertyMetaDataC2C.Option_C2C_StrutTiesInput.id] as number;
    }

    public set strutTiesInput(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_StrutTiesInput.id] = value;
    }

    public get optimizationStrategy() {
        return this.model[PropertyMetaDataC2C.Option_C2C_OptimizationStrategy.id] as OptimizationStrategy;
    }

    public set optimizationStrategy(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_OptimizationStrategy.id] = value;
    }

    public get gammaRd() {
        return this.model[PropertyMetaDataC2C.Option_C2C_GammaRd.id] as number;
    }

    public set gammaRd(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_GammaRd.id] = value;
    }

    public get useGermanNationalAnnex() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_UseGermanNationalAnnex.id] as boolean;
    }

    public set useGermanNationalAnnex(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_UseGermanNationalAnnex.id] = value;
    }

    public get metricEquation(): number {
        return this.model[PropertyMetaDataC2C.Options_C2C_MetricEquation.id] as number;
    }

    public set metricEquation(value: number) {
        this.model[PropertyMetaDataC2C.Options_C2C_MetricEquation.id] = value;
    }

    public get isExtensionAtSupport() {
        return this.model[PropertyMetaDataC2C.Application_C2C_Connection_IsExtensionAtSupport.id] as boolean;
    }

    public set isExtensionAtSupport(value) {
        this.model[PropertyMetaDataC2C.Application_C2C_Connection_IsExtensionAtSupport.id] = value;
    }

    public get isFireExtensionAtSupport() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_FireApplicationExtensionAtSupport.id] as boolean;
    }

    public set isFireExtensionAtSupport(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_FireApplicationExtensionAtSupport.id] = value;
    }

    public get isXDirectionContinuous() {
        return this.model[PropertyMetaDataC2C.Application_C2C_Connection_IsXDirectionContinuous.id] as boolean;
    }

    public set isXDirectionContinuous(value) {
        this.model[PropertyMetaDataC2C.Application_C2C_Connection_IsXDirectionContinuous.id] = value;
    }

    public get fireResistanceRequirement() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_FireResistanceRequirement.id] as boolean;
    }

    public set fireResistanceRequirement(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_FireResistanceRequirement.id] = value;
    }

    public get seismicConditions() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_SeismicConditions.id] as boolean;
    }

    public set seismicConditions(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_SeismicConditions.id] = value;
    }

    // Start of ASbased options
    public get AS_epsilonUd() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonUd.id] as number;
    }

    public set AS_epsilonUd(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonUd.id] = value;
    }

    public get AS_steelE() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_SteelE.id] as number;
    }

    public set AS_steelE(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_SteelE.id] = value;
    }

    public get AS_optimizationStrategy() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_OptimizationStrategy.id] as number;
    }

    public set AS_optimizationStrategy(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_OptimizationStrategy.id] = value;
    }

    public get AS_steelStress() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_SteelStressReduction.id] as number;
    }

    public set AS_steelStress(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_SteelStressReduction.id] = value;
    }

    public get AS_concreteStressCrossSection() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReduction.id] as number;
    }

    public set AS_concreteStressCrossSection(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReduction.id] = value;
    }

    public get AS_concreteStressShearInterface() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReductionVerification.id] as number;
    }

    public set AS_concreteStressShearInterface(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReductionVerification.id] = value;
    }

    public get AS_epsilonC2() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonC2.id] as number;
    }

    public set AS_epsilonC2(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonC2.id] = value;
    }

    public get AS_epsilonCu2() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonCu2.id] as number;
    }

    public set AS_epsilonCu2(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonCu2.id] = value;
    }

    public get AS_beta() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorBeta.id] as number;
    }

    public set AS_beta(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorBeta.id] = value;
    }

    public get AS_minConcreteCover() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteCover.id] as number;
    }

    public set AS_minConcreteCover(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteCover.id] = value;
    }

    public get AS_factorK4() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorK4.id] as number;
    }

    public set AS_factorK4(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorK4.id] = value;
    }

    public get AS_factorK6() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorK6.id] as number;
    }

    public set AS_factorK6(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorK6.id] = value;
    }

    public get AS_transversePressure() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_TransversePressure.id] as number;
    }

    public set AS_transversePressure(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_TransversePressure.id] = value;
    }

    public get AS_minimumAreaReinforcement() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_MinimumAreaReinforcement.id] as number;
    }

    public set AS_minimumAreaReinforcement(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_MinimumAreaReinforcement.id] = value;
    }

    public get AS_minimumAreaReinforcementInput() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_MinimumAreaReinforcementInput.id] as number;
    }

    public set AS_minimumAreaReinforcementInput(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_MinimumAreaReinforcementInput.id] = value;
    }

    public get AS_maximumAreaReinforcement() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_MaximumAreaReinforcement.id] as number;
    }

    public set AS_maximumAreaReinforcement(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_MaximumAreaReinforcement.id] = value;
    }

    public get AS_maximumAreaReinforcementInput() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_MaximumAreaReinforcementInput.id] as number;
    }

    public set AS_maximumAreaReinforcementInput(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_MaximumAreaReinforcementInput.id] = value;
    }

    public get AS_spacingMaximum() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_SpacingMaximum.id] as number;
    }

    public set AS_spacingMaximum(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_SpacingMaximum.id] = value;
    }

    public get AS_spacingMaximumInput() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_SpacingMaximumInput.id] as number;
    }

    public set AS_spacingMaximumInput(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_SpacingMaximumInput.id] = value;
    }

    public get AS_strutAngleNewConcrete() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_StrutAngleNewConcrete.id] as number;
    }

    public set AS_strutAngleNewConcrete(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_StrutAngleNewConcrete.id] = value;
    }

    public get AS_shearTensileAngle() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_InclinedShearTensileReinforementAngle.id] as number;
    }

    public set AS_shearTensileAngle(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_InclinedShearTensileReinforementAngle.id] = value;
    }
    // End of ASbased options

    public isFeatureEnabled(featureKey: FeatureFlagTypes) {
        return this.designData.enabledFeatures.includes(featureKey);
    }

    public setSavedStateIdx() {
        this.savedStateIdx = this.currentStateIdx;
    }

    public dispose() {
        CalculationServiceBase.cancelCalculationRequest(this, this.logger);

        this.calculateDefer.promise.catch(() => { /*Nothing to do*/ });
        this.calculateDefer.reject();
        this.calculateDefer = new Deferred<ICalculationResult>();

        this.off(DesignEvent.stateChanged, this.stateChanged);
    }

    public static getSplicesStructuralJointsMetaProperties(): IGlobalMetaProperties {
        return {
            isConcreteOverlay: false,
            sourceMetaPropertySelectedProduct: PropertyMetaDataC2C.Product_C2C_RebarFamily.id,
            sourceMetaPropertySize: PropertyMetaDataC2C.Product_C2C_RebarSize.id,
            sourceMetaPropertyType: PropertyMetaDataC2C.Product_C2C_ConnectorType.id,
            sourceMetaProperty: PropertyMetaDataC2C.Product_C2C_RebarFamily.id,
            productCodeListProperty: DesignCodeList.FastenerFamily,
            sourceMetaPropertyApproval: PropertyMetaDataC2C.Product_C2C_ViewApproval.id,
            infoSectionTranslationKey: 'Agito.Hilti.C2C.Navigation.TabProduct.Rebar',
            selectProductTitleTranslationKey: 'Agito.Hilti.C2C.SelectRebar.Title'
        };
    }

    public static getConcreteOverlayMetaProperties(designStandard: number): IGlobalMetaProperties {
        return {
            isConcreteOverlay: true,
            sourceMetaProperty: PropertyMetaDataC2C.Product_C2C_ConnectorFamily.id,
            sourceMetaPropertySize: PropertyMetaDataC2C.Product_C2C_ConnectorSize.id,
            sourceMetaPropertyType: PropertyMetaDataC2C.Product_C2C_ConnectorType.id,
            sourceMetaPropertySelectedProduct: PropertyMetaDataC2C.Product_C2C_FastenerItem.id,
            productCodeListProperty: DesignCodeList.FastenerFamily,
            sourceMetaPropertyApproval: PropertyMetaDataC2C.Product_C2C_ConnectorViewApproval.id,
            infoSectionTranslationKey: designStandard == DesignStandardEnumC2C.ETAG ?
                'Agito.Hilti.C2C.Navigation.TabProduct.ShearConnector' : 'Agito.Hilti.C2C.Navigation.TabProduct.Rebar',
            selectProductTitleTranslationKey: designStandard == DesignStandardEnumC2C.ETAG ?
                'Agito.Hilti.C2C.Product.SelectShearConnector.Title' : 'Agito.Hilti.C2C.SelectRebar.Title'
        };
    }

    public addModelChange(propertyId: number, noCollapse?: boolean, value?: unknown, oldValue?: unknown) {
        this.addModelChangeInternal(propertyId, noCollapse, value as object, oldValue as object);

        return this.calculationServiceC2C.calculateAsync(this);
    }

    public updateProjectDesignOptionsC2C(options: ReportOptionEntityC2C) {
        this.designData.projectDesignC2C.options.reportNotes = options.notes ?? '';
        this.designData.projectDesignC2C.options.reportRebarApplication = options.fasteningPoint ?? '';
        this.designData.projectDesignC2C.options.customPictures = options.customPictures;
        this.designData.projectDesignC2C.options.noteShearLoad = options.noteShearLoad ?? '';
        this.designData.projectDesignC2C.options.noteInstallationData = options.noteInstallationData ?? '';
        this.designData.projectDesignC2C.options.noteTensionLoad = options.noteTensionLoad ?? '';
        this.designData.projectDesignC2C.options.noteLoadCaseResultingRebarForces = options.noteLoadResultingAnchorForces ?? '';
        this.designData.projectDesignC2C.options.reportLanguageId = options.reportLanguageId;
        this.designData.projectDesignC2C.options.reportFirstPage = options.firstPage;
        this.designData.projectDesignC2C.options.reportTemplateId = options.reportTemplateId;
        this.designData.projectDesignC2C.options.reportType = options.type;
        this.designData.projectDesignC2C.options.reportPaperSize = options.paperSize;
        this.designData.projectDesignC2C.options.reportCompanyName = options.companyName;
        this.designData.projectDesignC2C.options.reportAddress = options.address;
        this.designData.projectDesignC2C.options.reportContactPerson = options.contactPerson;
        this.designData.projectDesignC2C.options.reportPhoneNumber = options.phoneNumber;
        this.designData.projectDesignC2C.options.reportEmail = options.email;
        this.designData.projectDesignC2C.options.trimbleConnectUpload = options.trimbleConnectUpload;
        this.designData.projectDesignC2C.options.trimbleConnectLocation = options.trimbleConnectLocation;
        this.designData.projectDesignC2C.options.trimbleConnectReportName = options.trimbleConnectReportName;
        this.designData.projectDesignC2C.options.trimbleConnectFolderId = options.trimbleConnectFolderId;
    }

    public reloadState() {
        this.calculationServiceC2C.loadState(this, this.states.findIndex((state) => state === this.currentState));
    }

    public updateProjectDesignC2C(projectDesignC2C: ProjectDesignEntityC2C) {
        this.designData.projectDesignC2C = projectDesignC2C;
        return this.designData.projectDesignC2C;
    }

    public updateModelFromModel(model: { [property: number]: unknown }) {
        this.model = model;

        return this.model;
    }

    public getLanguageById(languageId: number) {
        return (this.codeListCommon.commonCodeLists[CommonCodeList.Language] as Language[]).find(language => language.id == languageId);
    }

    public resolveCalculation() {
        this.loading = false;
        this.setPendingCalculation(false);

        this.calculateDefer.resolve({ design: this, calculationCanceled: true, messagesClosed: Promise.resolve(), reportData: null });
        this.calculateDefer = new Deferred<ICalculationResult>();

        this.cancelCalculationRequest();
    }

    public cancelCalculationRequest() {
        if (!this.cancellationToken) {
            return;
        }

        this.cancellationToken.resolve();
        this.cancellationToken = undefined;

        // get the canceled changes back
        if (this.lastModelChanges) {
            this.modelChanges.changes = this.lastModelChanges.concat(this.modelChanges.changes);
            this.lastModelChanges = undefined;

            this.modelChanges.observe();
        }

        // print
        this.logger.log('Calculate canceled');
    }

    public async processDesignClose(ignoreErrors: boolean): Promise<void> {
        if (this.pendingCloseDesign) {
            return;
        }

        this.pendingCloseDesign = true;

        // cancel all calculations
        this.resolveCalculation();

        const licensePromise = this.user.releaseAllFloatingLicenses(ignoreErrors);
        const trackingPromise = (this.isTemplate ? this.calculationServiceC2C.trackOnTemplateClose(this) : this.calculationServiceC2C.trackOnDesignClose(this))
            .then(() => this.usageCounterC2C.resetCounter());

        await Promise.all([trackingPromise, licensePromise]);
    }
}
