export class BaseUpdate {
  unitConverter;
  mathCalculator;
  propertyInfo;
  cache;
  camera3dPositionUpdate;
  constructor(unitConverter, mathCalculator, propertyInfo, cache, camera3dPositionUpdate) {
    this.unitConverter = unitConverter;
    this.mathCalculator = mathCalculator;
    this.propertyInfo = propertyInfo;
    this.cache = cache;
    this.camera3dPositionUpdate = camera3dPositionUpdate;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shouldUpdate(model, ...args) {
    return true;
  }
  propertyValueChanged(changes, model, update, ...args) {
    if (this.shouldUpdate(model, ...args)) {
      const updateMethods = getPropertyValueChangedMethods(this.constructor);
      // gloabl update method
      const globalMethodInfos = updateMethods[undefined];
      if (globalMethodInfos != null) {
        for (const propertyKey in globalMethodInfos) {
          const methodInfo = globalMethodInfos[propertyKey];
          this.callPropertyValueChangedMethod(undefined, model, update, methodInfo, true, args);
        }
      }
      // property update method
      if (changes != null) {
        for (const change of changes) {
          const methodInfos = updateMethods[change.name];
          if (methodInfos != null) {
            for (const propertyKey in methodInfos) {
              const methodInfo = methodInfos[propertyKey];
              this.callPropertyValueChangedMethod(change.newValue, model, update, methodInfo, false, args);
            }
          }
        }
      } else {
        for (const property in updateMethods) {
          if (property != null && property != 'undefined') {
            const methodInfos = updateMethods[property];
            if (methodInfos != null) {
              for (const propertyKey in methodInfos) {
                const methodInfo = methodInfos[propertyKey];
                // eslint-disable-next-line @typescript-eslint/no-deprecated
                this.callPropertyValueChangedMethod(this.propertyInfo.getPropertyValue(property), model, update, methodInfo, false, args);
              }
            }
          }
        }
      }
    }
  }
  scopeCheckChanged(scopeChecks, model, ...args) {
    if (this.shouldUpdate(model, ...args)) {
      const scopeCheckMethods = getScopeCheckChangedMethods(this.constructor);
      // scope check set
      const scopeCheckSet = {};
      for (const scopeCheck of scopeChecks ?? []) {
        scopeCheckSet[scopeCheck.measureResultType] = scopeCheck;
      }
      // call methods
      for (const key in scopeCheckMethods) {
        const measureResultType = key;
        const scopeCheckMethodInfos = scopeCheckMethods[measureResultType];
        if (scopeCheckMethodInfos != null) {
          // all scope checks
          if (measureResultType == null || measureResultType === 'undefined') {
            for (const propertyKey in scopeCheckMethodInfos) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
              this[propertyKey].apply(this, [scopeCheckSet, model, ...args]);
            }
          } else {
            const scopeCheck = scopeChecks.find(scopeCheck => scopeCheck.measureResultType == measureResultType);
            for (const propertyKey in scopeCheckMethodInfos) {
              this[propertyKey].apply(this, [scopeCheck, model, ...args]);
            }
          }
        }
      }
    }
  }
  callPropertyValueChangedMethod(value, model, update, methodInfo, globalUpdateMethod, args) {
    if (update == 1 /* Update.Client */ && (methodInfo.update == 1 /* Update.Client */ || methodInfo.update == 2 /* Update.ServerAndClient */) || update == 0 /* Update.Server */ && (methodInfo.update == 0 /* Update.Server */ || methodInfo.update == 2 /* Update.ServerAndClient */) || update == 2 /* Update.ServerAndClient */) {
      if (globalUpdateMethod) {
        this[methodInfo.updateMethod]?.apply(this, [model, ...args, update]);
      } else {
        this[methodInfo.updateMethod]?.apply(this, [value, model, ...args, update]);
      }
    }
  }
}
export function propertyValueChanged(property, update = 0 /* Update.Server */) {
  return (target, propertyKey) => {
    // if no property is specified it's called on any property change
    property = property ?? undefined;
    setPropertyValueChangedMethod(target.constructor, property, propertyKey, {
      updateMethod: propertyKey,
      update
    });
  };
}
export function scopeCheckChanged(scopeCheckType) {
  return (target, propertyKey) => {
    setScopeCheckChangedMethods(target.constructor, scopeCheckType, propertyKey, {
      scopeCheckMethod: propertyKey
    });
  };
}
const propertyValueChangedMethods = new WeakMap();
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function getPropertyValueChangedMethods(target) {
  let updateMethods = propertyValueChangedMethods.get(target);
  if (updateMethods == null) {
    updateMethods = {};
    propertyValueChangedMethods.set(target, updateMethods);
  }
  // we might have a base class that we also have to pick methods
  const baseTarget = Object.getPrototypeOf(target.prototype).constructor;
  if (baseTarget != BaseUpdate) {
    const baseUpdateMethods = getPropertyValueChangedMethods(baseTarget);
    updateMethods = {
      ...baseUpdateMethods,
      ...updateMethods
    };
  }
  return updateMethods;
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function setPropertyValueChangedMethod(target, property, propertyKey, info) {
  let updateMethods = propertyValueChangedMethods.get(target);
  if (updateMethods == null) {
    updateMethods = {};
    propertyValueChangedMethods.set(target, updateMethods);
  }
  (updateMethods[property] = updateMethods[property] ?? {})[propertyKey] = info;
}
const scopeCheckChangedMethods = new WeakMap();
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function getScopeCheckChangedMethods(target) {
  let scopeCheckMethods = scopeCheckChangedMethods.get(target);
  if (scopeCheckMethods == null) {
    scopeCheckMethods = {};
    scopeCheckChangedMethods.set(target, scopeCheckMethods);
  }
  // we might have a base class that we also have to pick methods
  const baseTarget = Object.getPrototypeOf(target.prototype)?.constructor;
  if (baseTarget != BaseUpdate) {
    const baseScopeCheckMethods = getScopeCheckChangedMethods(baseTarget);
    scopeCheckMethods = {
      ...baseScopeCheckMethods,
      ...scopeCheckMethods
    };
  }
  return scopeCheckMethods;
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function setScopeCheckChangedMethods(target, scopeCheckType, propertyKey, info) {
  let scopeCheckMethods = scopeCheckChangedMethods.get(target);
  if (scopeCheckMethods == null) {
    scopeCheckMethods = {};
    scopeCheckChangedMethods.set(target, scopeCheckMethods);
  }
  (scopeCheckMethods[scopeCheckType] = scopeCheckMethods[scopeCheckType] ?? {})[propertyKey] = info;
}
