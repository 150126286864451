import { Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { CacheItem } from './cache-item.js';
export class CommonCache extends CacheItem {
  static boxSize = 200;
  static boxSizeHalf = CommonCache.boxSize / 2;
  static cylinderSize = 20;
  static cylinderSizeHalf = CommonCache.cylinderSize / 2;
  static forceArrowHeadLenght = 24;
  static forceArrowLenght = 45 + CommonCache.forceArrowHeadLenght;
  static forceArrowLenghtHalf = CommonCache.forceArrowLenght / 2;
  static forceArrowLineLenght = CommonCache.forceArrowLenght - CommonCache.forceArrowHeadLenght;
  static forceArrowLineLenghtHalf = CommonCache.forceArrowLineLenght / 2;
  static stressArrowHeadLenght = 24;
  static stressArrowLenght = 45 * 1.75 + CommonCache.stressArrowHeadLenght;
  static stressArrowLenghtHalf = CommonCache.stressArrowLenght / 2;
  static stressArrowLineLenght = CommonCache.stressArrowLenght - CommonCache.stressArrowHeadLenght;
  static stressArrowLineLenghtHalf = CommonCache.stressArrowLineLenght / 2;
  static momentCircleArrowDiameter = 75;
  static momentCircleArrowDiameterHalf = CommonCache.momentCircleArrowDiameter / 2;
  get boxSidePoints() {
    return this.create('Common.boxSidePoints', () => {
      return [new Vector3(-CommonCache.boxSizeHalf, CommonCache.boxSizeHalf, -CommonCache.boxSizeHalf)];
    });
  }
  getCylinderPoints(numberOfPoints) {
    return this.create(`Common.cylinderPoints:${numberOfPoints}`, () => {
      const top = [];
      const bottom = [];
      let angle = 0;
      for (let i = 0; i < numberOfPoints; i++) {
        angle = Math.PI * 2 * (i / numberOfPoints);
        const x = CommonCache.cylinderSizeHalf * Math.cos(angle);
        const z = CommonCache.cylinderSizeHalf * Math.sin(angle);
        top.push(new Vector3(x, CommonCache.cylinderSizeHalf, z));
        bottom.push(new Vector3(x, -CommonCache.cylinderSizeHalf, z));
      }
      return {
        top,
        bottom
      };
    });
  }
  getCirclePoints(numberOfPoints, size) {
    return this.create(`Common.circlePoints:${numberOfPoints}:${size}`, () => {
      const points = [];
      const sizeHalf = size / 2;
      let angle = 0;
      for (let i = 0; i < numberOfPoints; i++) {
        angle = Math.PI * 2 * (i / numberOfPoints);
        const x = sizeHalf * Math.cos(angle);
        const z = sizeHalf * Math.sin(angle);
        points.push(new Vector2(x, z));
      }
      return points;
    });
  }
}
