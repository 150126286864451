import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ProjectDesignBaseEntity } from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { IDesignListItem as IDesignListItemCommon } from '@profis-engineering/pe-ui-common/services/document.common';

export interface ICheckbotMappingRequest {
    RegionId?: number;
}

export interface ICheckbotMappingResponse {
    ProjectName: string;
    DesignName: string;
    Mappings: IMapping[];
    Design: ProjectDesignBaseEntity;
}

export interface IMapping {
    Category: Category;
    Title: string;
    Message: string;
    Status: Status;
    IsComplexDesignMapping: boolean;
}

export enum Status {
    Success = 0,
    PartialSuccess,
    Failed,
    None
}

// Do not change order and spelling of the enum values as these are used in tracking.
export enum Category {
    ProfileType = 0,
    ProfileMaterial,
    BaseplateDimensions,
    BaseplateMaterial,
    Loads,
    BaseMaterialDimensions,
    Units,
    DesignStandard,
    AnchorLayout,
    BaseMaterialGrade,
    SafetyFactors,
    Anchor
}

export interface ICheckbotDesignMetadata {
    ProjectName: string;
    DesignName: string;
    DesignSourceApplicationType: IDesignSourceApplicationType;
    DesignSourceApplicationName: string;
    IOMVersion: number;
}

export enum IDesignSourceApplicationType {
    None = 0,
    CAD,
    FEA
}

export interface ICheckBotDisplay {
    icon: string;
    title: string;
    message: string;
    mappingStatus: string;
    isComplexDesignsMapping: boolean;
}

export interface ICheckbotMappingInputProps {
    mappingResponse: ICheckbotMappingResponse;
    onConfirm?: (modal?: ModalInstance<ICheckbotMappingInputProps>, mapWithComplexDesigns?: boolean) => Promise<IDesignListItemCommon>;
    onCancel?: (modal?: ModalInstance<ICheckbotMappingInputProps>) => Promise<void>;
}