import { CodeList } from './code-list';
import { ReinforcementTensionConditionEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export class ReinforcementTensionCondition extends CodeList {

    public static fromService(codeList: ReinforcementTensionConditionEntityC2C) {
        return new ReinforcementTensionCondition(CodeList.baseFromService('ReinforcementTensionConditionEntity', codeList));
    }

    public override cloneDeep(): ReinforcementTensionCondition {
        return super.cloneDeep() as ReinforcementTensionCondition;
    }
}
