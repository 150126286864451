export enum esrFrameFasteners {
    ENP19 = 1,
    HSN24 = 21,
    SMD = 20,
    SRT5 = 23,
}

export enum esrSideLapConnectors {
    SLC01 = 13,
    SLC02 = 9,
    VSC2 = 20,
    HiltiScrew10 = 2,
    HiltiScrew12 = 3,
    HiltiScrew14 = 4,
    Screw10 = 17,
    Screw12 = 18,
    Screw14 = 19,
    ButtonPunch = 8,
}
