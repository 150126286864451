import { EdgeReinforcementEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { CodeList } from './code-list';

export class EdgeReinforcement extends CodeList {

    public static fromService(codeList: EdgeReinforcementEntityC2C) {
        return new EdgeReinforcement(CodeList.baseFromService('EdgeReinforcementEntity', codeList));
    }

    public override cloneDeep(): EdgeReinforcement {
        return super.cloneDeep() as EdgeReinforcement;
    }
}
