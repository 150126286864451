import { CacheItem } from './cache-item.js';
export class TextCache extends CacheItem {
  get textMeta() {
    return this.create('Text.textMeta', () => {
      return {
        textCount: 0
      };
    });
  }
}
